import _ from 'lodash';

import { guid } from '../gen';

class EventManager {
  constructor() {
    this.events = {};
  }

  /**
   * subscribe
   * @param {String} type
   * @param {Function} fn
   * @returns {String} Returns unsubscription id
   */
  subscribe(type, fn) {
    const id = guid();

    this.events = {
      ...this.events,
      [id]: { type, fn }
    };

    return id;
  }

  unsubscribe(id) {
    delete this.events[id];
  }

  /**
   * subscribe
   * @param {Object} action with a type
   * @returns {function(): void}
   */
  emit(action) {
    const events = _.values(this.events);

    _.forEach(events, (event) => {
      if (_.getNonEmpty(action, 'type') === event.type) {
        event.fn(action);
      }
    });
  }
}

const PubSub = new EventManager();

const usePubSub = () => ({
  subscribe: PubSub.subscribe.bind(PubSub),
  unsubscribe: PubSub.subscribe.bind(PubSub),
  emit: PubSub.emit.bind(PubSub)
});

export default usePubSub;
