import EntityBaseModel from '../../EntityBaseModel';

class BillTextsModel {
  constructor() {
    this.note = '';
    this.signature = '';
    this.footer = '';
  }

  static getPayload(model) {
    let payload = { ...model };

    payload = EntityBaseModel.removeEntityMetadata(payload);

    return payload;
  }
}

export default BillTextsModel;
