import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useMathInterval } from '../../../../utils/hooks/mathIntervals';
import { getSafeTranslation } from '../../../../utils/language';
import { getNumbroDisplayValue } from '../I18nNumericInput';
import CustomI18nNumericWidget from './CustomI18nNumericWidget';

import { JsonSchemaWidgetOptionsShape } from '../../../../metadata/shapes/json-schema/JsonSchemaWidgetShape';

import { Div } from '../../HtmlComponents';

import Visible from '../../layout/Visible';

const CustomNumericLabelWidget = (props) => {
  const { value, disabled, options } = props;
  const labels = _.getNonEmpty(options, 'x-labels', {});
  const className = _.getNonEmpty(options, 'className', '');
  const findInterval = useMathInterval(labels);
  const includeValue = _.getNonEmpty(options, 'x-includeValue', false);

  if (!disabled) {
    return <CustomI18nNumericWidget {...props} />;
  }

  const formattedDisplayValue = getNumbroDisplayValue(value);

  const interval = findInterval(value);
  const label = _.getNonEmpty(interval, 'label', formattedDisplayValue);
  const semanticColor = _.getNonEmpty(interval, 'color', 'default');

  return (
    <div
      className={classNames(
        className,
        'custom-numeric-label-widget',
        'widget-label',
        `font-color-${semanticColor}`,
        `color-${semanticColor}`,
        { 'include-value': includeValue }
      )}
    >
      <Visible visible={includeValue} component={Div} className={'value'}>
        <CustomI18nNumericWidget {...props} />
      </Visible>

      {getSafeTranslation(label)}
    </div>
  );
};

CustomNumericLabelWidget.propTypes = {
  options: PropTypes.shape(JsonSchemaWidgetOptionsShape).isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

CustomNumericLabelWidget.defaultProps = {
  value: null,
  disabled: false
};

export default CustomNumericLabelWidget;
