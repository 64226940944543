import { createActionTypeArray } from '../../common/actionTypes';

export const GET_BILL_LIST = 'bill/GET_BILL_LIST';
export const SET_BILL_LIST_ORDER = 'bill/SET_BILL_LIST_ORDER';
export const SET_BILL_LIST_PAGINATION = 'bill/SET_BILL_LIST_PAGINATION';
export const SET_BILL_LIST_FILTER = 'bill/SET_BILL_LIST_FILTER';
export const SET_BILL_LIST_FILTERS = 'bill/SET_BILL_LIST_FILTERS';
export const CLEAR_BILL_LIST_FILTERS = 'bill/CLEAR_BILL_FILTER';
export const CLEAR_BILL_LIST_FILTERS_NO_POLL =
  'billing/CLEAR_BILL_LIST_FILTERS_NO_POLL';
export const SET_BILL_LIST_INCLUDE_PARAM =
  'billing/SET_BILL_LIST_INCLUDE_PARAM';

export const CLEAR_BILLING_ITEM_DESCRIPTION =
  'billing/CLEAR_BILLING_ITEM_DESCRIPTION';

export const [
  GET_BILL_LIST_IN_PROGRESS,
  GET_BILL_LIST_SUCCESS,
  GET_BILL_LIST_FAILURE
] = createActionTypeArray(GET_BILL_LIST);
