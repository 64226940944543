import produce from 'immer';
import _ from 'lodash';

import { ORDER_ASCENDING } from '../../utils/constants/tableConstants';
import moshiConfig from '../../utils/moshiConfig';

import {
  GET_CLOSED_DATES_FAILURE,
  GET_CLOSED_DATES_IN_PROGRESS,
  GET_CLOSED_DATES_SUCCESS
} from './closedDatesTypes';

import { LOGOUT } from '../auth/authReducer';

export const initialState = {
  list: [],
  loadingList: false,
  filter: {
    sortBy: 'dateFrom',
    order: ORDER_ASCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  }
};

// eslint-disable-next-line default-param-last
const closedDatesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_CLOSED_DATES_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_CLOSED_DATES_SUCCESS:
        const closedDates = _.getNonEmpty(payload, 'data', []);

        draft.list = closedDates;
        draft.loadingList = false;
        break;
      case GET_CLOSED_DATES_FAILURE:
        draft.list = [];
        draft.loadingList = false;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default closedDatesReducer;
