/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { CardTitle } from 'reactstrap';

import PropTypes from 'prop-types';

const CardTitleWrapper = ({ children, onClick, className, id }) => (
  <CardTitle onClick={onClick} className={className} id={id}>
    {children}
  </CardTitle>
);

CardTitleWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string
};

CardTitleWrapper.defaultProps = {
  onClick: undefined,
  className: undefined,
  id: undefined
};

export default CardTitleWrapper;
