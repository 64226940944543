import { combineEpics } from 'redux-observable';

import {
  organizationCacheEpic,
  locationsCacheEpic,
  documentsCacheEpic,
  questionnairesCacheEpic,
  flowsCacheEpic,
  waitingListConfigCacheEpic,
  waitingListEncountersCacheEpic,
  waitingListTodaysEventsCacheEpic,
  devicesListCacheEpic,
  categoriesListCacheEpic,
  billingUnitListCacheEpic,
  taxesListCacheEpic,
  billingCategoryListCacheEpic,
  getPremiseList,
  patientSchemaCacheEpic,
  calendarEventListCacheEpic,
  calendarWaitingListCacheEpic
} from './epics/cacheEpic';

const rootEpic = combineEpics(
  organizationCacheEpic,
  locationsCacheEpic,
  documentsCacheEpic,
  questionnairesCacheEpic,
  flowsCacheEpic,
  waitingListConfigCacheEpic,
  waitingListEncountersCacheEpic,
  waitingListTodaysEventsCacheEpic,
  devicesListCacheEpic,
  categoriesListCacheEpic,
  billingUnitListCacheEpic,
  taxesListCacheEpic,
  billingCategoryListCacheEpic,
  getPremiseList,
  patientSchemaCacheEpic,
  calendarEventListCacheEpic,
  calendarWaitingListCacheEpic
);

export default rootEpic;
