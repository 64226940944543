import _ from 'lodash';

import { translateKey } from '../../i18n';
import { FILTER_ALL_ID } from '../constants/dropdownConstants';

import { guid } from '../gen';
import logger from '../logger';

export const filterAll = () => ({
  id: FILTER_ALL_ID,
  name: translateKey('common:all')
});

export const dropdownItemMapper = (id = 'id', name = 'name') => (entity) => {
  const idValue = _.get(entity, id, guid());
  let nameValue;

  if (_.isString(name)) {
    nameValue = _.get(entity, name, 'MISSING_NAME');
  } else if (_.isFunction(name)) {
    nameValue = name(entity);
  } else {
    logger.error(
      `dropdownItemMapper: '${typeof name}' is not supported for name parameter`
    );
  }

  return {
    ...entity,
    id: idValue,
    name: nameValue
  };
};

/**
 * Used for grouping in dropdown menus. The Reactstrap dropdown item has
 * a bool prop called header. If an item has header:true it is rendered inside
 * the dropdown with class `.dropdown-header` and is disabled.
 * *
 * Example:
 *
 * const items = [
 *  {name: 'dr. Andrej', type: 'doctor'},
 *  {name: 'rentgen', type: 'resource}
 * ];
 *
 * dropdownGroupItemsMapper(items, 'type', 'calendar:somePath) =>
 * [
 *  {
 *    header: true,
 *    name:  translateKey(`calendar:somePath.doctor`),
 *    id: 'doctor',
 *    type: 'doctor'
 *  },
 *  {name: 'dr. Andrej', type: 'doctor'},
 *  {
 *    header: true,
 *    name:  translateKey(`calendar:somePath.resource`),
 *    id: 'resource',
 *    type: 'resource'
 *  },
 *  {name: 'rentgen', type: 'resource}
 * ]
 *
 * @param list
 * @param groupBy
 * @param headerTranslatePath
 * @returns {unknown[]}
 */
export const dropdownGroupItemsMapper = (
  list,
  groupBy,
  headerTranslatePath
) => {
  const mappedListForDropdown = list.map(dropdownItemMapper());
  const groupedItemObject = _.groupBy(mappedListForDropdown, groupBy);

  let groupedItemList = [];

  _.forIn(groupedItemObject, (subItemList, key) => {
    subItemList.unshift({
      header: true,
      name: translateKey(`${headerTranslatePath}.${key}`),
      type: key,
      id: key
    });

    groupedItemList = _.concat(groupedItemList, subItemList);
  });

  return _.sortBy(groupedItemList, groupBy);
};

export const dropdownGroupedItemMapper = (list, groupList, groupBy) => {
  const groupedItemObject = _.groupBy(list, groupBy);
  let groupedItemList = [];

  _.forIn(groupedItemObject, (subItemList, groupId) => {
    const foundGroup = _.find(groupList, { id: groupId });

    let newItems = [];

    if (_.isEmpty(foundGroup)) {
      newItems = subItemList;
    } else {
      const category = {
        id: foundGroup.id,
        name: foundGroup.name,
        subItems: subItemList
      };

      newItems.push(category);
    }
    groupedItemList = [...groupedItemList, ...newItems];
  });

  return groupedItemList;
};

export const radioItemMapper = (value = 'id', label = 'name') => (entity) => {
  const idValue = _.get(entity, value, guid());
  let nameValue;

  if (_.isString(label)) {
    nameValue = _.get(entity, label, 'MISSING_NAME');
  } else if (_.isFunction(label)) {
    nameValue = label(entity);
  } else {
    logger.error(
      `radioItemMapper: '${typeof label}' is not supported for name parameter`
    );
  }

  return {
    ...entity,
    value: idValue,
    label: nameValue
  };
};
