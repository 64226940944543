import { createActionTypeArray } from '../../common/actionTypes';

export const ADD_OUTBOUND_DOCUMENT = 'documents/ADD_OUTBOUND_DOCUMENT';
export const UPDATE_OUTBOUND_DOCUMENT = 'documents/UPDATE_OUTBOUND_DOCUMENT';
export const GET_OUTBOUND_DOCUMENT = 'documents/GET_OUTBOUND_DOCUMENT';
export const GET_OUTBOUND_PREVIEW = 'documents/GET_OUTBOUND_PREVIEW';
export const SET_OUTBOUND_PREVIEW_PDF_URL =
  'documents/SET_OUTBOUND_PREVIEW_PDF_URL';
export const CLEAR_OUTBOUND_PREVIEW_PDF_URL =
  'documents/CLEAR_OUTBOUND_PREVIEW_PDF_URL';
export const DELETE_OUTBOUND_DOCUMENT = 'documents/DELETE_OUTBOUND_DOCUMENT';

export const CLEAR_OUTBOUND_DETAILS = 'documents/CLEAR_OUTBOUND_DETAILS';

export const GET_OUTBOUND_DOCUMENT_HEADER =
  'documents/GET_OUTBOUND_DOCUMENT_HEADER';
export const GET_OUTBOUND_DOCUMENT_FOOTER =
  'documents/GET_OUTBOUND_DOCUMENT_FOOTER';
export const SET_OUTBOUND_PDF_GENERATION_STATUS =
  'documents/SET_OUTBOUND_PDF_GENERATING';

export const [
  ADD_OUTBOUND_DOCUMENT_IN_PROGRESS,
  ADD_OUTBOUND_DOCUMENT_SUCCESS,
  ADD_OUTBOUND_DOCUMENT_FAILURE
] = createActionTypeArray(ADD_OUTBOUND_DOCUMENT);

export const [
  UPDATE_OUTBOUND_DOCUMENT_IN_PROGRESS,
  UPDATE_OUTBOUND_DOCUMENT_SUCCESS,
  UPDATE_OUTBOUND_DOCUMENT_FAILURE
] = createActionTypeArray(UPDATE_OUTBOUND_DOCUMENT);

export const [
  GET_OUTBOUND_PREVIEW_SUCCESS,
  GET_OUTBOUND_PREVIEW_IN_PROGRESS,
  GET_OUTBOUND_PREVIEW_FAILURE
] = createActionTypeArray(GET_OUTBOUND_PREVIEW);

export const [
  GET_OUTBOUND_DOCUMENT_IN_PROGRESS,
  GET_OUTBOUND_DOCUMENT_SUCCESS,
  GET_OUTBOUND_DOCUMENT_FAILURE
] = createActionTypeArray(GET_OUTBOUND_DOCUMENT);

export const [
  DELETE_OUTBOUND_DOCUMENT_IN_PROGRESS,
  DELETE_OUTBOUND_DOCUMENT_SUCCESS,
  DELETE_OUTBOUND_DOCUMENT_FAILURE
] = createActionTypeArray(DELETE_OUTBOUND_DOCUMENT);

export const [
  GET_OUTBOUND_DOCUMENT_HEADER_IN_PROGRESS,
  GET_OUTBOUND_DOCUMENT_HEADER_SUCCESS,
  GET_OUTBOUND_DOCUMENT_HEADER_FAILURE
] = createActionTypeArray(GET_OUTBOUND_DOCUMENT_HEADER);

export const [
  GET_OUTBOUND_DOCUMENT_FOOTER_IN_PROGRESS,
  GET_OUTBOUND_DOCUMENT_FOOTER_SUCCESS,
  GET_OUTBOUND_DOCUMENT_FOOTER_FAILURE
] = createActionTypeArray(GET_OUTBOUND_DOCUMENT_FOOTER);
