import produce from 'immer';
import _ from 'lodash';

import { ORDER_DESCENDING } from '../../../utils/constants/tableConstants';
import moshiConfig from '../../../utils/moshiConfig';
import {
  getListPaginationFromResponse,
  setFilterToStoreDraft
} from '../../../utils/redux/listUtils';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../../utils/table';

import {
  GET_ALL_DOCUMENT_TYPES_LIST_SUCCESS,
  SET_ALL_DOCUMENT_TYPES_LIST_PAGINATION,
  SET_ALL_DOCUMENT_TYPES_LIST_ORDER,
  SET_ALL_DOCUMENT_TYPES_LIST_FILTER,
  CLEAR_ALL_DOCUMENT_TYPES_LIST_FILTERS,
  CLEAR_ALL_DOCUMENT_TYPES_LIST,
  GET_ALL_DOCUMENT_TYPES_LIST_FAILURE,
  GET_ALL_DOCUMENT_TYPES_LIST_IN_PROGRESS
} from './allDocumentTypesTypes';

import { LOGOUT } from '../../auth/authReducer';

export const initialState = {
  list: [],
  filter: {
    sortBy: ENTITY_FIELD_CREATED_AT_NAME,
    order: ORDER_DESCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  },
  loadingList: false
};

// eslint-disable-next-line default-param-last
const allDocumentTypesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_ALL_DOCUMENT_TYPES_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_ALL_DOCUMENT_TYPES_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case GET_ALL_DOCUMENT_TYPES_LIST_SUCCESS:
        const response = _.getNonEmpty(payload, 'response', {});
        const allDocumentTypesList = _.getNonEmpty(response, 'data', []);

        const { resultCount, pageCount } = getListPaginationFromResponse(
          response,
          draft.pagination.limit
        );

        draft.pagination.resultCount = resultCount;
        draft.pagination.pageCount = pageCount;
        draft.loadingList = false;
        draft.list = allDocumentTypesList;
        break;
      case SET_ALL_DOCUMENT_TYPES_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.getNonEmpty(payload, 'order', ORDER_DESCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_ALL_DOCUMENT_TYPES_LIST_PAGINATION:
        const name = _.getNonEmpty(payload, 'paginationName', 'page');
        const value = _.getNonEmpty(
          payload,
          'paginationValue',
          draft.pagination.page
        );

        draft.pagination[name] = value;
        break;
      case SET_ALL_DOCUMENT_TYPES_LIST_FILTER:
        const filters = _.getNonEmpty(payload, 'filters', []);

        setFilterToStoreDraft(draft, filters, initialState.pagination.page);
        break;
      case CLEAR_ALL_DOCUMENT_TYPES_LIST_FILTERS:
        draft.filter = initialState.filter;
        break;
      case CLEAR_ALL_DOCUMENT_TYPES_LIST:
        draft.list = initialState.list;
        draft.pagination = initialState.pagination;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default allDocumentTypesReducer;
