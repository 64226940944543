import React from 'react';

import moshiConfig, { ENV_DEVELOPMENT } from '../utils/moshiConfig';

if (moshiConfig.appEnv === ENV_DEVELOPMENT) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  whyDidYouRender(React, {
    trackAllPureComponents: false,
    trackHooks: true,
    trackExtraHooks: [[require('react-redux/lib'), 'useSelector']]
  });
}
