import { isValidPhoneNumber } from 'react-phone-number-input';

import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import CustomI18nNumericWidget from '../components/common/form/json-schema/CustomI18nNumericWidget';
import { storeDispatch, storeGetState } from '../configureStore';
import {
  WIDGET_TYPE_HOMUNCULUS,
  WIDGET_TYPE_QUESTIONNAIRE
} from '../widgets/widgetConstants';
import { ORDER_ASCENDING, ORDER_DESCENDING } from './constants/tableConstants';
import {
  INDEXES_CALCULATION_ERROR_WIDGET,
  WIDGET_CALCULATION_ERRORS,
  WIDGET_COMPONENT_HOMUNCULUS,
  WIDGET_COMPONENT_QUESTIONNAIRE
} from './constants/widgetConstants';

import { removePatientFile } from '../redux/patient/patientActions';

import { selectActiveEncounter } from '../redux/patient/encounter/encounterSelectors';
import {
  selectPatientMainDiagnosis,
  selectPatientClinicalOutlook
} from '../redux/patient/patientSelectors';
import {
  selectCurrentEncounterWidgetDataValue,
  selectWidgetByTypeAt,
  selectWidgetSchema
} from '../redux/patient/widget/widgetSelectors';
import {
  selectPastTherapyListBeforeDate,
  selectPastAndActiveTherapyListBeforeDate
} from '../redux/therapy/therapySelectors';

import CustomCKEditorWidget from '../components/common/form/json-schema/CustomCKEditorWidget';
import CustomCalendarDatepickerWidget from '../components/common/form/json-schema/CustomCalendarDatepickerWidget';
import CustomCheckboxesWidget from '../components/common/form/json-schema/CustomCheckboxesWidget';
import CustomCountriesDropdownWidget from '../components/common/form/json-schema/CustomCountriesDropdownWidget';
import CustomDataDropdownWidget from '../components/common/form/json-schema/CustomDataDropdownWidget';
import CustomDatepickerWidget from '../components/common/form/json-schema/CustomDatepickerWidget';
import CustomDefaultDatepickerWidget from '../components/common/form/json-schema/CustomDefaultDatepickerWidget';
import CustomDescriptionField from '../components/common/form/json-schema/CustomDescriptionField';
import CustomDropdownWidget from '../components/common/form/json-schema/CustomDropdownWidget';
import CustomFilesField from '../components/common/form/json-schema/CustomFilesWidget';
import CustomFlowWidget from '../components/common/form/json-schema/CustomFlowWidget';
import CustomHiddenWidget from '../components/common/form/json-schema/CustomHiddenWidget';
import CustomMoshiDatepickerWidget from '../components/common/form/json-schema/CustomMoshiDatepickerWidget';
import CustomNumericIconLabelWidget from '../components/common/form/json-schema/CustomNumericIconLabelWidget';
import CustomNumericLabelWidget from '../components/common/form/json-schema/CustomNumericLabelWidget';
import CustomPhoneNumberWidget from '../components/common/form/json-schema/CustomPhoneNumberWidget';
import CustomRadioWidget from '../components/common/form/json-schema/CustomRadioWidget';
import CustomScaleWidget from '../components/common/form/json-schema/CustomScaleWidget';
import CustomSignatureWidget from '../components/common/form/json-schema/CustomSignatureWidget';
import CustomTextareaAutosizeWidget from '../components/common/form/json-schema/CustomTextareaAutosizeWidget';
import CustomToggleWidget from '../components/common/form/json-schema/CustomToggleWidget';
import ErrorListTemplate from '../components/common/form/json-schema/ErrorListTemplate';
import FullWidthFilterDropdownWidget from '../components/common/form/json-schema/FullWidthFilterDropdownWidget';
import CustomWidgetFieldTemplate from '../components/common/form/json-schema/field-template/CustomWidgetFieldTemplate';
import CustomCalculationErrorWidget from '../components/common/form/json-schema/indexes/CustomCalculationErrorWidget';
import WidgetObjectTemplate from '../components/common/form/json-schema/object-template/WidgetObjectTemplate';
import QuestionnaireWidget from '../components/common/form/json-schema/questionnaire/QuestionnaireWidget';
import QuestionnaireWidgetWithFetch from '../components/common/form/json-schema/questionnaire/QuestionnaireWidgetWithFetch';
import HomunculusWidget from '../components/common/form/json-schema/rheumatology/HomunculusWidget';
import PatientTherapiesTextAreaWidget from '../components/common/form/json-schema/rheumatology/PatientTherapiesTextAreaWidget';

import { getTherapyEndDate, getTherapyListFilteredBy } from './data/therapy';
import { isValidDate } from './date';
import { NO_ID } from './form';
import { generateEhrId } from './gen';
import { jsonParse } from './jwt';
import { getSafeTranslation } from './language';
import logger from './logger';
import { WIDGET_FILE_DELETED } from './mappers/files-mapper';
import { orderByOrderExists } from './order';
import { countryCodePattern, guidPattern } from './regexPatterns';

export const getWidgetSchema = () => selectWidgetSchema(storeGetState());

export const MOSHI_OTHER_GROUP_ID = 'moshi/OTHER';

/* TODO think about creating a reserved group */
export const moshiOtherGroup = {
  id: MOSHI_OTHER_GROUP_ID,
  name: 'common:other',
  otherGroup: true,
  subItems: [],
  systemWidgetsGroup: false,
  widgets: []
};

export const getAllAvailableWidgetIdsArray = (allWidgetGroups) => {
  const allWidgetGroupsArray = _.isArray(allWidgetGroups)
    ? allWidgetGroups
    : [];
  const mapSubItemIds = (item) => _.map(item.subItems, (subItem) => subItem.id);
  const getSubItemIdsArrays = _.map(allWidgetGroupsArray, mapSubItemIds);

  return _.flatten(getSubItemIdsArrays);
};

export const otherGroupExists = (allWidgetGroups) => {
  const otherGroup = _.find(allWidgetGroups, { otherGroup: true });

  return _.isPlainObject(otherGroup);
};

export const getSplitWidgetType = (widgetType) => _.split(widgetType, '/');

export const getWidgetGroupName = (widgetType) => {
  const widgetGroupSplitter = getSplitWidgetType(widgetType).split('/');

  return _.first(widgetGroupSplitter);
};
export const getWidgetIdWithoutVersion = (widgetType) => {
  const [groupId, widgetName] = getSplitWidgetType(widgetType);

  return `${groupId}/${widgetName}`;
};

export const getWidgetFormSchema = (widgetType) => {
  const widgets = _.getNonEmpty(getWidgetSchema(), 'widgets', {});

  return _.getAndClone(widgets, `${widgetType}.formSchema`, {});
};

export const getLatestVersionWidget = (widgetType) => {
  const widgets = _.get(getWidgetSchema(), 'widgets', {});

  const filteredWidgets = _.filter(
    widgets,
    (widget) => getWidgetTypeWithoutVersion(widget.id) === widgetType
  );

  return _.first(_.orderBy(filteredWidgets, 'id', ORDER_DESCENDING));
};

export const getLatestVersionWidgetFormSchema = (widgetType) => {
  const latestVersionWidget = getLatestVersionWidget(widgetType);

  return _.get(latestVersionWidget, 'formSchema', {});
};

export const getWidgetUiSchema = (widgetType) => {
  const widgets = _.get(getWidgetSchema(), 'widgets', {});

  return _.get(widgets, `${widgetType}.uiSchema`, {});
};

export const getWidgetClassNames = (widgetType) => {
  const baseClasses = ['rjsf', 'card', 'widget'];
  const widgets = _.get(getWidgetSchema(), 'widgets', {});

  const customClass = _.get(
    widgets[widgetType],
    'uiSchema.x-customClass',
    'problem-description-widget'
  );

  baseClasses.push(customClass);

  return baseClasses;
};

export const questionnaireWidgets = {
  /**
   * NOTE: CheckboxesWidget and RadioWidget need to remain PascalCased as they are default overridden widgets
   */
  CheckboxesWidget: CustomCheckboxesWidget,
  RadioWidget: CustomRadioWidget,
  SignatureWidget: CustomSignatureWidget,
  customTextareaAutosizeWidget: CustomTextareaAutosizeWidget,
  customCalendarDatePickerWidget: CustomCalendarDatepickerWidget,
  customPhoneNumberWidget: CustomPhoneNumberWidget,
  customScaleWidget: CustomScaleWidget,
  dateWidget: CustomMoshiDatepickerWidget
};

export const getCustomWidgets = () => ({
  ...questionnaireWidgets,
  customDropdownWidget: CustomDropdownWidget,
  fullWidthFilterDropdownWidget: FullWidthFilterDropdownWidget,
  customDataDropdownWidget: CustomDataDropdownWidget,
  customFlowWidget: CustomFlowWidget,
  customDatepickerWidget: CustomDatepickerWidget,
  customI18nNumericWidget: CustomI18nNumericWidget,
  customCKEditorWidget: CustomCKEditorWidget,
  customQuestionnaireWidget: QuestionnaireWidgetWithFetch,
  customCountriesDropdownWidget: CustomCountriesDropdownWidget,
  customToggleWidget: CustomToggleWidget,
  hiddenWidget: CustomHiddenWidget,
  customDefaultDatePickerWidget: CustomDefaultDatepickerWidget,
  customCalculationErrorWidget: CustomCalculationErrorWidget,
  customNumericLabelWidget: CustomNumericLabelWidget,
  customNumericIconLabelWidget: CustomNumericIconLabelWidget,
  patientTherapiesTextAreaWidget: PatientTherapiesTextAreaWidget
});

export const getWidgetComponents = () => ({
  [WIDGET_COMPONENT_HOMUNCULUS]: HomunculusWidget,
  [WIDGET_COMPONENT_QUESTIONNAIRE]: QuestionnaireWidget
});

export const customFormats = {
  date: (value) => !value || isValidDate(value),
  phoneNumber: (value) => !value || isValidPhoneNumber(value),
  doctorID: guidPattern,
  countryCode: countryCodePattern
};

export const getCustomFields = () => ({
  customFilesField: CustomFilesField,
  DescriptionField: CustomDescriptionField
});

export const transformWidgetErrors = (errors) =>
  errors.map((error) => {
    switch (error.name) {
      case 'maximum':
        error.errorTranslationCode = 'validation:tooHigh';
        break;
      case 'minimum':
        error.errorTranslationCode = 'validation:tooLow';
        break;
      default:
        error.errorTranslationCode = 'validation:missingOrIncorrect';
        break;
    }

    return error;
  });

export const transformQuestionnaireErrors = (errors) =>
  errors.map((error) => {
    switch (error.name) {
      case 'maximum':
        error.message = getSafeTranslation('validation:tooHigh');
        break;
      case 'minimum':
        error.message = getSafeTranslation('validation:tooLow');
        break;
      case 'required':
        error.message = getSafeTranslation('validation:required');
        break;
      default:
        error.message = getSafeTranslation('validation:missingOrIncorrect');
        break;
    }

    return error;
  });

export const hasCalculationErrors = (formData) => {
  const calculationErrors = _.get(formData, 'x-errors');

  return !_.isEmpty(calculationErrors);
};

const handleCalculationErrors = (schema, uiSchema, formData, editMode) => {
  if (editMode || !hasCalculationErrors(formData)) {
    return { schema, uiSchema };
  }

  const errorSchema = getWidgetFormSchema(INDEXES_CALCULATION_ERROR_WIDGET);
  const errorUiSchema = getWidgetUiSchema(INDEXES_CALCULATION_ERROR_WIDGET);

  errorSchema.title = schema.title;
  errorSchema['x-observes'] = schema['x-observes'];

  const mergedUiSchema = _.nestedAssign(uiSchema, errorUiSchema);

  return { schema: errorSchema, uiSchema: mergedUiSchema };
};

export const getCommonCustomWidgetProps = (
  widgetType,
  editMode,
  className,
  formData
) => {
  let schema = getWidgetFormSchema(widgetType);
  let uiSchema = getWidgetUiSchema(widgetType);

  ({ schema, uiSchema } = handleCalculationErrors(
    schema,
    uiSchema,
    formData,
    editMode
  ));

  return {
    schema,
    uiSchema,
    ObjectFieldTemplate: WidgetObjectTemplate,
    FieldTemplate: CustomWidgetFieldTemplate,
    ErrorList: ErrorListTemplate,
    transformErrors: transformWidgetErrors,
    omitExtraData: true,
    liveOmit: true,
    widgets: getCustomWidgets(),
    fields: getCustomFields(),
    liveValidate: editMode && _.get(uiSchema, 'x-liveValidate', true),
    disabled: !editMode,
    className: classNames(
      getWidgetClassNames(widgetType),
      className,
      `form-${editMode ? 'enabled' : 'disabled'}`
    )
  };
};

export const getAllWidgetItems = (
  widgetGroup = null,
  onlyGroups = false,
  idListOfAddedWidgets = [],
  outdated = false
) => {
  let allItems = [];
  const widgets = getWidgetSchema();

  const idListOfAddedWidgetsWithoutVersion = _.map(
    idListOfAddedWidgets,
    getWidgetIdWithoutVersion
  );

  _.forEach(widgets.groups, (groupItem) => {
    const groupWidgetIds = _.map(groupItem.widgets, (item) => item.id);

    _.forOwn(widgets.widgets, (widget) => {
      if (_.includes(groupWidgetIds, widget.id)) {
        const groupInDropdown = {
          id: groupItem.id,
          systemWidgetGroup: groupItem.systemWidgetGroup,
          name: getSafeTranslation(groupItem.name),
          subItems: onlyGroups ? undefined : [],
          otherGroup: groupItem.otherGroup,
          groupWidgetsConfig: groupItem.widgets
        };

        allItems = _.pushUniqueBy(
          allItems,
          { id: groupItem.id },
          groupInDropdown
        );

        if (!_.isUndefined(groupInDropdown.subItems)) {
          if (!outdated && widget.outdated) {
            return;
          }

          _.forEach(allItems, (item) => {
            if (item.id === groupItem.id) {
              item.subItems = _.pushUniqueBy(
                item.subItems,
                { id: widget.id },
                {
                  name: getSafeTranslation(
                    _.get(widget, 'formSchema.title', '')
                  ),
                  id: widget.id
                }
              );
            }
          });
        }

        allItems = setWidgetOrdering(allItems);
      }
    });
  });

  let itemsToReturn = allItems;

  _.forEach(itemsToReturn, (itemToReturn) => {
    itemToReturn.subItems = _.sortBy(itemToReturn.subItems, 'order');

    _.forEach(itemToReturn.subItems, (subItem) => {
      const subItemIdWithoutVersion = getWidgetIdWithoutVersion(subItem.id);

      subItem.disabled = _.includes(
        idListOfAddedWidgetsWithoutVersion,
        subItemIdWithoutVersion
      );
    });
  });

  if (_.isString(widgetGroup)) {
    const filteredItems = _.find(allItems, { id: widgetGroup });

    itemsToReturn = _.get(filteredItems, 'subItems', []);
  }

  return itemsToReturn;
};

const setWidgetOrdering = (allItems) =>
  _.forEach(allItems, (item) => {
    _.forEach(item.subItems, (subItem) => {
      const foundObj = _.find(item.groupWidgetsConfig, {
        id: subItem.id
      });

      if (_.isPlainObject(foundObj)) {
        subItem.order = foundObj.order;
      }
    });
  });

const cleanupFormFiles = (newFormData, files, formFieldName, extraProps) => {
  const removePatientFileDispatch = (...props) =>
    storeDispatch(removePatientFile(...props));
  const patientId = _.get(extraProps, 'patientId', NO_ID);
  const targetStatus = _.get(extraProps, 'targetStatus', WIDGET_FILE_DELETED);

  if (patientId === NO_ID) {
    logger.error('Patient ID is required');

    return;
  }

  _.forEach(files, (file) => {
    const localStatus = _.get(file, 'localStatus', null);

    if (_.isNull(localStatus)) {
      return;
    }

    const foundFileIndex = _.findIndex(files, { id: file.id });

    _.set(
      newFormData,
      `${formFieldName}.${foundFileIndex}.localStatus`,
      undefined
    );

    if (localStatus === targetStatus) {
      _.remove(newFormData[formFieldName], { id: file.id });
      removePatientFileDispatch(file.id, patientId);
    }
  });
};

export const processFormData = (formData, extraProps = {}) => {
  const newFormData = _.cloneDeep(formData);

  _.forIn(formData, (formField, formFieldName) => {
    if (_.isArray(formField)) {
      cleanupFormFiles(newFormData, formField, formFieldName, extraProps);
    }
  });

  return newFormData;
};

export const getDataKeyFromWidgetUiSchema = (widgetId) => {
  let formDataKey;
  const widgets = _.get(getWidgetSchema(), 'widgets', {});

  const widgetUiSchema = _.get(widgets, `${widgetId}.uiSchema`, {});

  _.forOwn(widgetUiSchema, (value, key) => {
    if (key.startsWith('id') && key.length === 5) {
      formDataKey = _.get(value, 'x-defaultFormDataKey', null);
    }
  });

  return formDataKey;
};

export const getDefaultWidgets = (encounter, defaultWidgetIds, widgets) => {
  const defaultWidgets = [];

  _.forEach(defaultWidgetIds, (defaultWidgetId) => {
    const defaultFormDataKey = getDataKeyFromWidgetUiSchema(defaultWidgetId);

    const defaultWidget = {
      id: defaultWidgetId,
      widgetType: defaultWidgetId,
      widgetVersion: '1',
      data: {
        id001: _.get(encounter, defaultFormDataKey, '')
      }
    };

    const widgetAlreadyOnEncounter = _.find(widgets, {
      widgetType: defaultWidgetId
    });

    if (_.isEmpty(widgetAlreadyOnEncounter)) {
      defaultWidgets.push(defaultWidget);
    }
  });

  return defaultWidgets;
};

export const isReadOnlyWidget = (widgetType) => {
  const uiSchema = getWidgetUiSchema(widgetType);

  return _.get(uiSchema, 'x-readOnly', false);
};

export const isQuestionnaireWidget = (widgetType) => {
  const uiSchema = getWidgetUiSchema(widgetType);

  return (
    _.includes(widgetType, WIDGET_TYPE_QUESTIONNAIRE) ||
    _.getNonEmpty(uiSchema, 'x-widgetName', '') ===
      WIDGET_COMPONENT_QUESTIONNAIRE
  );
};

export const isHomunculusWidget = (widgetType) => {
  const uiSchema = getWidgetUiSchema(widgetType);

  return (
    _.includes(widgetType, WIDGET_TYPE_HOMUNCULUS) ||
    _.getNonEmpty(uiSchema, 'x-widgetName', '') === WIDGET_COMPONENT_HOMUNCULUS
  );
};

export const prepareWidgetListForRendering = (currentWidgets = []) => {
  const widgetGroups = [];

  /*
   * These are all of the widget groups with subItems that are
   * defined in the widgets config
   */
  const allWidgetGroups = getAllWidgetItems(null, false, undefined, true);

  // an array with all widget ids in widgets config
  const allAvailableWidgetsIds = getAllAvailableWidgetIdsArray(allWidgetGroups);

  /*
   * There has to be an "other" group if there are some widgets on encounter
   * that are missing in the widgets config
   */
  if (!otherGroupExists(allWidgetGroups)) {
    allWidgetGroups.push(moshiOtherGroup);
  }

  _.forEach(allWidgetGroups, (widgetGroup) => {
    /*
     * Find widgets that are on encounter. Checks if the widget id is inside
     * groups sub items
     */
    let foundWidgets = _.filter(currentWidgets, (widget) => {
      const widgetType = _.get(widget, 'widgetType', null);
      const widgetId = _.get(widget, 'id', null);
      const widgetGroupSubItemIds = _.map(
        widgetGroup.subItems,
        (item) => item.id
      );

      return _.includesAny(widgetGroupSubItemIds, [widgetType, widgetId]);
    });

    /* Finds missing widget (if it was deleted) by looking inside all of the ids array */
    const missingWidgets = _.filter(currentWidgets, (widget) => {
      const widgetId = _.get(widget, 'id', null);
      const widgetType = _.get(widget, 'widgetType', null);

      return !_.includesAny(allAvailableWidgetsIds, [widgetId, widgetType]);
    });

    /* if there are missing widgets, put them inside the "other" group */
    if (!_.isEmpty(missingWidgets)) {
      if (widgetGroup.otherGroup) {
        foundWidgets = _.concat(foundWidgets, missingWidgets);
      }
    }

    if (!_.isEmpty(foundWidgets)) {
      // sets the same order as in dropdowns
      _.map(foundWidgets, (foundWidget) => {
        const foundWidgetId = _.get(foundWidget, 'id', null);
        const foundWidgetType = _.get(foundWidget, 'widgetType', null);

        const foundWidgetOrder = _.find(
          widgetGroup.groupWidgetsConfig,
          (item) => item.id === foundWidgetType || item.id === foundWidgetId
        );

        if (_.isPlainObject(foundWidgetOrder)) {
          foundWidget.order = foundWidgetOrder.order;
          foundWidget.version = foundWidgetOrder.version;
        }

        return foundWidget;
      });

      widgetGroups.push({
        ...widgetGroup,
        widgets: _.sortBy(foundWidgets, 'order')
      });
    }
  });

  return widgetGroups;
};

export const getWidgetTemplateItems = () => {
  const templates = _.get(getWidgetSchema(), 'templates');

  return {
    id: 'templates',
    name: getSafeTranslation('widget:itemFinder.groups.templates'),
    order: 100,
    subItems: templates
  };
};

export const isTemplateItem = (item) => _.startsWith(item.id, 'template');

export const getAllGroupsByCurrentWidgetList = (widgetList) => {
  const widgetGroupIdList = _.map(widgetList, 'widgetType');
  const joinedWidgetGroupIdList = widgetGroupIdList.join(':');
  const allWidgetGroups = getAllWidgetItems(null, true, undefined, true);

  return _.filter(allWidgetGroups, (widgetGroup) =>
    _.includes(joinedWidgetGroupIdList, widgetGroup.id)
  );
};

export const getWidgetTypeWithoutVersion = (widgetType) =>
  _.split(widgetType, '/', 2).join('/');

export const getWidgetNamespace = (widgetType) => {
  const widgetTypeWithoutVersion = getWidgetTypeWithoutVersion(widgetType);
  const splitWidgetType = _.split(widgetTypeWithoutVersion, '/');

  return _.first(splitWidgetType);
};

export const getWidgetName = (widgetType) => {
  const widgetTypeWithoutVersion = getWidgetTypeWithoutVersion(widgetType);
  const splitWidgetType = _.split(widgetTypeWithoutVersion, '/');

  return _.last(splitWidgetType);
};

export const getCalculationsFromSchema = (props, prefix = '') =>
  _.reduce(
    props,
    (calculations, prop, key) => {
      let formKey = key;

      if (!_.isEmpty(prefix)) {
        formKey = `${prefix}.${key}`;
      }

      if (_.isArray(prop.properties)) {
        calculations.push(
          ...getCalculationsFromSchema(prop.properties, `${formKey}${key}`)
        );
      }

      const calculation = _.getNonEmpty(prop, 'x-calculated');

      if (_.isEmptySafe(calculation)) return calculations;

      const calculationObject = {
        dataPath: formKey,
        calculation
      };

      const order = _.getNonEmpty(prop, 'x-calculation-order');

      if (!_.isEmptySafe(order)) {
        calculationObject.order = order;
      }

      const numberPrecision = _.getNonEmpty(prop, 'x-number-precision');

      if (!_.isEmptySafe(numberPrecision)) {
        calculationObject.numberPrecision = numberPrecision;
      }

      calculations.push(calculationObject);

      return calculations;
    },
    []
  );

export const doSchemaPropertiesContainMeasurement = (schema) => {
  const properties = _.getNonEmpty(schema, 'properties', {});

  return _.some(properties, (value) => _.has(value, 'measurement'));
};

/**
 * Higher order function that handles getting value from other widgets form data of a widget.
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {array} processingErrors array of errors, it will be mutated inside function, designed as such since we have no control over where return is used
 *
 * @param {*} widgetType widgetType to be searched for
 * @param {*} dataPath data path for form data value
 * @param {*} defaultValue default value if data is not found
 */
const handleCalculationWidgetData = (store, processingErrors) => (
  widgetType,
  dataPath,
  defaultValue
) => {
  const value = selectCurrentEncounterWidgetDataValue(store, {
    widgetType,
    dataPath,
    defaultValue,
    ignoreVersion: true
  });

  const hasNoDefaultValue = _.isUndefined(defaultValue);
  const isDefaultValueApplied =
    !hasNoDefaultValue && _.isEqual(value, defaultValue);

  if (_.isEmptySafe(value) && !isDefaultValueApplied) {
    processingErrors.push({
      widgetType,
      dataPath,
      error: WIDGET_CALCULATION_ERRORS.NO_WIDGET_VALUE
    });
  }

  return value;
};

/**
 * Higher order function that handles getting value from form data of a widget.
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} formData widget form data
 * @param {array} processingErrors array of errors, it will be mutated inside function, designed as such since we have no control over where return is used
 *
 * @param {*} dataPath data path for form data value
 * @param {*} defaultValue default value if data is not found
 */
const handleCalculationWidgetFormData = (formData, processingErrors) => (
  dataPath,
  defaultValue
) => {
  const value = _.getNonEmpty(formData, dataPath, defaultValue);

  const hasNoDefaultValue = _.isUndefined(defaultValue);
  const isDefaultValueApplied =
    !hasNoDefaultValue && _.isEqual(value, defaultValue);

  if (_.isEmptySafe(value) && !isDefaultValueApplied) {
    processingErrors.push({
      dataPath,
      error: WIDGET_CALCULATION_ERRORS.NO_FORM_VALUE
    });
  }

  return value;
};

export const getProcessedWidgetFormDataByType = (
  widgetType,
  formData,
  store
) => {
  const formSchema = getWidgetFormSchema(widgetType);

  return getProcessedWidgetFormData(formSchema, formData, store);
};

export const parseAndGetWidgetData = (
  widget,
  dataPath = generateEhrId(),
  defaultValue = null
) => {
  const rawData = _.getNonEmpty(widget, 'data', {});
  const parsedData = jsonParse(rawData);

  return _.getNonEmpty(parsedData, dataPath, defaultValue);
};

/**
 * Higher order function that handles getting value from historic values of other widgets
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {array} processingErrors array of errors, it will be mutated inside function, designed as such since we have no control over where return is used
 *
 * @param {*} widgetType widgetType to be searched for
 * @param {*} dataPath data path for form data value
 * @param {*} date date in time where value should be searched for
 */
const handleCalculationWidgetDataAt = (store, processingErrors) => (
  widgetType,
  dataPath,
  date,
  defaultValue = undefined
) => {
  const widgetAtDate = selectWidgetByTypeAt(store, {
    widgetType,
    date,
    ignoreVersion: true
  });
  const value = parseAndGetWidgetData(widgetAtDate, dataPath, defaultValue);

  const hasNoDefaultValue = _.isUndefined(defaultValue);
  const isDefaultValueApplied =
    !hasNoDefaultValue && _.isEqual(value, defaultValue);

  if (_.isEmptySafe(value) && !isDefaultValueApplied) {
    processingErrors.push({
      widgetType,
      dataPath,
      date,
      error: WIDGET_CALCULATION_ERRORS.NO_HISTORY_WIDGET_VALUE
    });
  }

  return value;
};

/**
 * Higher order function that handles getting startDate for therapy with matching category
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {Date} encounterStartDate start date of encounter, will filter therapies to be started before encounterStartDate
 *
 * @param {*} drugCategoryID id to find active therapy by
 */
const handleCalculationTherapyStartDate = (store, encounterStartDate) => (
  drugCategoryIDs
) => {
  const therapies = selectPastAndActiveTherapyListBeforeDate(
    store,
    encounterStartDate
  );
  const therapy = _.find(therapies, (t) => {
    const drugCategoryId = _.getNonEmpty(t, 'drug.categoryID');

    return _.includes(drugCategoryIDs, drugCategoryId);
  });
  const therapyStartDate = _.getNonEmpty(therapy, 'startDate', null);

  return therapyStartDate;
};

/**
 * Higher order function that handles getting shortest therapy duration for therapies with matching category. If duration is not found 0 will be returned.
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {Date} encounterStartDate start date of encounter, will filter therapies to be started before encounterStartDate
 *
 * @param {*} drugCategoryIDs ids to find active therapy by
 * @param {string} therapiesToInclude whether to count `past`, `active` or all therapies
 * @param {*} unitOfTime unit of time to find lowest duration by (ex. week, day, month)
 */
const handleCalculationShortestTherapyDuration = (
  store,
  encounterStartDate
) => (drugCategoryIDs, therapiesToInclude, unitOfTime) => {
  const therapies = getTherapyListFilteredBy(store, {
    beforeDate: encounterStartDate,
    therapiesToInclude
  });

  const matchedTherapies = therapies.filter((therapy) => {
    const drugCategoryId = _.getNonEmpty(therapy, 'drug.categoryID');

    return _.includes(drugCategoryIDs, drugCategoryId);
  });

  const shortestDuration = matchedTherapies.reduce((shortestValue, therapy) => {
    const dateFrom = getTherapyEndDate(therapy, encounterStartDate);
    const duration = moment(dateFrom).diff(therapy.startDate, unitOfTime);

    if (!shortestValue || shortestValue > duration) {
      return duration;
    }

    return shortestValue;
  }, null);

  if (_.isEmptySafe(shortestDuration)) return 0;

  return shortestDuration;
};

/**
 * Higher order function that handles getting longest therapy duration for therapies with matching category. If duration is not found 0 will be returned.
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {Date} encounterStartDate start date of encounter, will filter therapies to be started before encounterStartDate
 *
 * @param {*} drugCategoryIDs ids to find active therapy by
 * @param {string} therapiesToInclude whether to count `past`, `active` or all therapies
 * @param {*} unitOfTime unit of time to find lowest duration by (ex. week, day, month)
 */
const handleCalculationLongestTherapyDuration = (store, encounterStartDate) => (
  drugCategoryIDs,
  therapiesToInclude,
  unitOfTime
) => {
  const therapies = getTherapyListFilteredBy(store, {
    beforeDate: encounterStartDate,
    therapiesToInclude
  });

  const matchedTherapies = therapies.filter((therapy) => {
    const drugCategoryId = _.getNonEmpty(therapy, 'drug.categoryID');

    return _.includes(drugCategoryIDs, drugCategoryId);
  });

  const longestDuration = matchedTherapies.reduce((longestValue, therapy) => {
    const dateFrom = getTherapyEndDate(therapy, encounterStartDate);
    const duration = moment(dateFrom).diff(therapy.startDate, unitOfTime);

    if (!longestValue || longestValue < duration) {
      return duration;
    }

    return longestValue;
  }, null);

  if (_.isEmptySafe(longestDuration)) return 0;

  return longestDuration;
};

/**
 * Higher order function that handles getting main diagnosis from patient details
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {array} processingErrors array of errors, it will be mutated inside function, designed as such since we have no control over where return is used
 */
const handleCalculationMainDiagnosis = (store, processingErrors) => () => {
  const mainDiagnosis = selectPatientMainDiagnosis(store);

  if (_.isEmptySafe(mainDiagnosis)) {
    processingErrors.push({
      error: WIDGET_CALCULATION_ERRORS.NO_MAIN_DIAGNOSIS
    });
  }

  return mainDiagnosis;
};

/**
 * Higher order function that handles getting clinical outlook from patient details/diagnosis
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {array} processingErrors array of errors, it will be mutated inside function, designed as such since we have no control over where return is used
 */
const handleCalculationClinicalOutlook = (store, processingErrors) => () => {
  const clinicalOutlook = selectPatientClinicalOutlook(store);

  if (_.isEmptySafe(clinicalOutlook)) {
    processingErrors.push({
      error: WIDGET_CALCULATION_ERRORS.NO_CLINICAL_OUTLOOK
    });
  }

  return clinicalOutlook;
};

/**
 * Higher order function that handles getting therapy count for therapies with matching category
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {Date} encounterStartDate start date of encounter, will filter therapies to be started before encounterStartDate
 *
 * @param {*} drugCategoryIDs ids to find active therapies by
 * @param {string} therapiesToInclude whether to count `past`, `active` or all therapies
 * @param {number} minimalDuration [optional] will count only therapies that have duration longer or equal to provided value
 * @param {string} unitOfTime [optional] works will minimalDuration, it provides unit of time for minimalDuration
 */
const handleCalculationTherapyCount = (store, encounterStartDate) => (
  drugCategoryIDs,
  therapiesToInclude,
  minimalDuration,
  unitOfTime
) => {
  const therapies = getTherapyListFilteredBy(store, {
    beforeDate: encounterStartDate,
    therapiesToInclude
  });

  let matchedTherapies = _.filter(therapies, (therapy) => {
    const drugCategoryId = _.getNonEmpty(therapy, 'drug.categoryID');

    return _.includes(drugCategoryIDs, drugCategoryId);
  });

  if (!_.isEmptySafe(minimalDuration)) {
    matchedTherapies = _.filter(matchedTherapies, (therapy) => {
      const dateFrom = getTherapyEndDate(therapy, encounterStartDate);
      const duration = moment(dateFrom).diff(therapy.startDate, unitOfTime);

      return duration >= minimalDuration;
    });
  }

  return matchedTherapies.length;
};

/**
 * Higher order function that handles getting longest duration from list of intakes matching criteria
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {Date} encounterStartDate start date of encounter, will filter therapies to be started before encounterStartDate
 *
 * @param {*} drugIdOrDrugCategoryId id to find therapy by
 * @param {number} quantityValue value of intake quantity (ex. 20)
 * @param {string} quantityScale scale for intake quantity (ex. mg)
 * @param {string} unitOfTime [optional] works will minimalDuration, it provides unit of time for minimalDuration
 */
const handleCalculationTherapyDrugIntakeDuration = (
  store,
  encounterStartDate
) => (drugIdOrDrugCategoryId, quantityValue, quantityScale, unitOfTime) => {
  const therapies = selectPastAndActiveTherapyListBeforeDate(
    store,
    encounterStartDate
  );
  const matchedTherapy = _.findOr(therapies, [
    { drugID: drugIdOrDrugCategoryId },
    { drug: { categoryID: drugIdOrDrugCategoryId } }
  ]);

  const intakeEvents = _.getNonEmpty(matchedTherapy, 'events', []);
  const sortedIntakeEvents = _.orderBy(
    intakeEvents,
    ['scheduledFor'],
    [ORDER_ASCENDING]
  );

  const findIntakeFn = (event) =>
    event.quantity.scale === quantityScale &&
    event.quantity.value >= quantityValue;

  const startIntake = _.find(sortedIntakeEvents, findIntakeFn);
  const endIntake = _.findLast(sortedIntakeEvents, findIntakeFn);

  if (!startIntake) return 0;

  const startDate = moment(startIntake.scheduledFor);
  let endDate = moment(_.getNonEmpty(endIntake, 'scheduledFor'));

  if (startDate.isAfter()) return 0;
  if (endDate.isAfter()) endDate = moment(encounterStartDate);

  return endDate.diff(startDate, unitOfTime);
};

/**
 * Higher order function that counts how many matching therapies were cancelled with provided reason
 *
 * Used by index calculation, return value has to comply to that.
 * @param {*} store application store
 * @param {Date} encounterStartDate start date of encounter, will filter therapies to be started before encounterStartDate
 *
 * @param {*} drugIdOrDrugCategoryId id to find therapy by
 * @param {number} reasonForCancellationId id of reason for cancellation to filter by
 */
const handleCalculationTherapyReasonForCancellationCount = (
  store,
  encounterStartDate
) => (drugIdOrDrugCategoryId, reasonForCancellationId) => {
  const therapies = selectPastTherapyListBeforeDate(store, encounterStartDate);
  const matchedTherapies = _.filterOr(therapies, [
    {
      drugId: drugIdOrDrugCategoryId,
      reasonForCancelation: reasonForCancellationId
    },
    {
      drug: { categoryID: drugIdOrDrugCategoryId },
      reasonForCancelation: reasonForCancellationId
    }
  ]);

  return matchedTherapies.length;
};

export const getProcessedWidgetFormData = (schema, formData, store) => {
  const processingErrors = [];

  const calculationVariables = _.getNonEmpty(
    schema,
    'x-calculation-variables',
    {}
  );

  const activeEncounter = selectActiveEncounter(store);
  const encounterStartDate = moment(
    _.getNonEmpty(activeEncounter, 'startedAt')
  ).startOf('day');

  const scope = {
    ...calculationVariables,
    widgetData: handleCalculationWidgetData(store, processingErrors),
    widgetDataAt: handleCalculationWidgetDataAt(store, processingErrors),
    therapyStartDate: handleCalculationTherapyStartDate(
      store,
      encounterStartDate
    ),
    shortestTherapyDuration: handleCalculationShortestTherapyDuration(
      store,
      encounterStartDate
    ),
    longestTherapyDuration: handleCalculationLongestTherapyDuration(
      store,
      encounterStartDate
    ),
    mainDiagnosis: handleCalculationMainDiagnosis(store, processingErrors),
    clinicalOutlook: handleCalculationClinicalOutlook(store, processingErrors),
    therapyCount: handleCalculationTherapyCount(store, encounterStartDate),
    therapyIntakeDuration: handleCalculationTherapyDrugIntakeDuration(
      store,
      encounterStartDate
    ),
    therapyReasonForCancellationCount: handleCalculationTherapyReasonForCancellationCount(
      store,
      encounterStartDate
    )
  };

  const schemaProperties = _.getNonEmpty(schema, 'properties', {});
  const calculations = getCalculationsFromSchema(schemaProperties);
  const processedFormData = _.cloneDeep(formData || {});

  const sortedCalculations = _.orderBy(
    calculations,
    [orderByOrderExists(true), 'order', 'dataPath'],
    [ORDER_ASCENDING, ORDER_ASCENDING, ORDER_ASCENDING]
  );

  _.forEach(
    sortedCalculations,
    ({ calculation, dataPath, numberPrecision }) => {
      try {
        scope.formData = handleCalculationWidgetFormData(
          processedFormData,
          processingErrors
        );

        let value = evalInContext(calculation, scope);

        if (_.isFinite(value)) {
          const precision = _.defaultTo(numberPrecision, 2);

          value = Number(value.toFixed(precision));
        }

        _.set(processedFormData, dataPath, value);
      } catch {
        processingErrors.push({
          dataPath,
          error: WIDGET_CALCULATION_ERRORS.CALCULATION_ERROR
        });
      }
    }
  );

  if (_.isEmptySafe(processingErrors)) {
    _.unset(processedFormData, 'x-errors');
  } else {
    _.set(processedFormData, 'x-errors', processingErrors);
  }

  return processedFormData;
};

/**
 * Evaluates javascript string and returns the result.
 *
 * !Important: use with caution.
 *
 * @param {*} js string of javascript code
 * @param {*} context object containing context to the javascript code snippet
 */
// eslint-disable-next-line no-new-func
const evalInContext = (js, context) => new Function(js).call(context);

export const getCalculationRefreshDataList = (widgetType) => {
  const formSchema = getWidgetFormSchema(widgetType);

  return _.getNonEmpty(formSchema, 'x-refresh-data', []);
};

export const isWidgetObservingAnother = (
  widgetDefinition,
  observedWidgetType
) => {
  const observers = _.get(widgetDefinition, `formSchema.x-observes`, []);
  const observedWidgetTypeWithoutVersion = getWidgetTypeWithoutVersion(
    observedWidgetType
  );

  return _.includes(observers, observedWidgetTypeWithoutVersion);
};

export const getWidgetVersion = (widgetType) => {
  const splitWidgetType = _.split(widgetType, '/');

  return _.last(splitWidgetType);
};

export const orderWidgetsByGroups = (widgets, state) => {
  const schema = selectWidgetSchema(state);
  const groups = _.getNonEmpty(schema, 'groups', []);

  const orderByGroups = (widget) => {
    const groupId = widget.widgetType.split('/')[0];

    return _.findIndex(groups, { id: groupId });
  };

  const orderByOrder = (widget) => {
    const groupIndex = orderByGroups(widget);
    const groupsWidgets = _.getNonEmpty(groups, `[${groupIndex}].widgets`, []);
    const widgetWithOrder = _.find(groupsWidgets, { id: widget.widgetType });

    return _.getNonEmpty(widgetWithOrder, 'order', 0);
  };

  return _.orderBy(widgets, [orderByGroups, orderByOrder], ORDER_ASCENDING);
};

export const widgetMapper = (widget, keepRawData = false) => {
  if (_.isEmptySafe(widget)) {
    return {};
  }

  if (keepRawData) {
    widget.rawData = _.cloneDeep(widget.data);
  }

  widget.data = jsonParse(widget.data);

  return widget;
};
