import { take, race, call, select } from 'redux-saga/effects';

import { getCreditNoteListUrl } from '../../../utils/api/apiUrlUtils';
import { getPaymentTypeFilterParametersPaylad } from '../../../utils/billing';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import {
  START_CREDIT_NOTE_POLLING,
  STOP_CREDIT_NOTE_POLLING,
  STOP_POLLING
} from '../../core/cache/cacheTypes';
import {
  CLEAR_CREDIT_NOTE_LIST_FILTER,
  GET_CREDIT_NOTE_LIST,
  SET_CREDIT_NOTE_LIST_FILTER,
  SET_CREDIT_NOTE_LIST_FILTERS,
  SET_CREDIT_NOTE_LIST_INCLUDE_PARAM,
  SET_CREDIT_NOTE_LIST_ORDER,
  SET_CREDIT_NOTE_LIST_PAGINATION
} from './creditNoteTypes';

import {
  selectCreditNoteFilter,
  selectCreditNotePagination,
  selectCreditNoteIncludeParam
} from './creditNoteSelectors';

function* pollCreditNotesSaga() {
  function* configGetter() {
    const state = yield select();
    const { limit, page } = selectCreditNotePagination(state);
    const include = selectCreditNoteIncludeParam(state);
    let customFilter = selectCreditNoteFilter(state);

    customFilter = getPaymentTypeFilterParametersPaylad(customFilter);

    return {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    };
  }

  yield pollGenerator(GET_CREDIT_NOTE_LIST, getCreditNoteListUrl, {
    retriggerActions: [
      SET_CREDIT_NOTE_LIST_FILTER,
      SET_CREDIT_NOTE_LIST_FILTERS,
      SET_CREDIT_NOTE_LIST_INCLUDE_PARAM,
      SET_CREDIT_NOTE_LIST_ORDER,
      SET_CREDIT_NOTE_LIST_PAGINATION,
      CLEAR_CREDIT_NOTE_LIST_FILTER
    ],
    configGetter
  });
}

export function* creditNoteSagaWatcher() {
  while (true) {
    yield take(START_CREDIT_NOTE_POLLING);
    yield race([
      call(pollCreditNotesSaga),
      take(STOP_POLLING),
      take(STOP_CREDIT_NOTE_POLLING)
    ]);
  }
}
