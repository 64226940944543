/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';

const CardWrapper = ({
  children,
  inverse,
  color,
  body,
  outline,
  innerRef,
  onClick,
  onDoubleClick,
  style,
  className,
  id,
  ...props
}) => (
  <Card
    inverse={inverse}
    color={color}
    body={body}
    outline={outline}
    innerRef={innerRef}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    className={className}
    id={id}
    style={style}
    {...props}
  >
    {children}
  </Card>
);

CardWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  inverse: PropTypes.bool,
  color: PropTypes.string,
  body: PropTypes.bool,
  outline: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func
  ]),
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  style: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string
};

CardWrapper.defaultProps = {
  children: undefined,
  inverse: undefined,
  color: undefined,
  body: undefined,
  outline: undefined,
  innerRef: undefined,
  onClick: undefined,
  onDoubleClick: undefined,
  style: undefined,
  className: undefined,
  id: undefined
};

export default CardWrapper;
