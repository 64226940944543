export default {
  login: {
    greetingTitle: 'Login to {{brandName}}.',
    greetingText: 'Enter you email and password.',
    forgotPassword: 'Forgot password?',
    register: 'Register',
    backToLogin: 'Back to login',
    dontHaveAccount: "Don't have an account?",
    problemLoggingIn: 'Have problem logging in?',
    loggingOut: 'Logging out',
    loggingOutDescription:
      'You currently have an active session with a different user name. To continue, we need to first log you out of the existing session.',
    loggingOutCountdown: 'Logging out in:'
  },
  resetPassword: {
    greetingTitle: 'Reset password',
    greetingText: 'To reset password, please enter your email.',
    successGreetingTitle: 'Request to reset the password has been sent',
    successGreetingText:
      'Shortly you will receive an email with the instructions on how to reset the password if the email address you have entered exists in our database.',
    resetPasswordSuccessTitle: 'Password was successfully reset.',
    retypePassword: 'Retype password'
  },
  register: {
    resendEmail: 'Resend email',
    greetingTitle: 'Create new account',
    greetingText:
      'Please enter your full name, organization name, your email and password.',
    successGreetingTitle: 'Your account has been created successfully',
    successGreetingText:
      'Shortly you will receive an email with the instructions on how to activate your account.',
    verifyingEmail: 'Verifying email address...',
    verifyEmailSuccess: 'Your email has been successfully verified.',
    emailAlreadyVerified: 'Your email is already verified.',
    emailVerificationFailed:
      'Your email verification failed. Please try again.',
    alreadyHaveAccount: 'Already have an account?',
    alert: {
      registrationSuccess:
        'Your account has been created successfully, check your email',
      verifyEmailSuccess: 'Your email has been successfully verified'
    }
  },
  changeEmail: {
    title: 'Change email',
    inProgress: 'Changing email address...',
    success: 'Email change was successfully confirmed.',
    failure:
      'Your request to change your email has expired or the link has already been used.'
  },
  acceptInvite: {
    description: 'Enter the password you will use to log in.',
    descriptionSuccess: 'Password set successfully.',
    descriptionFailure:
      'Your join request has expired or the link has already been used.'
  }
};
