export default {
  required: 'Obvezno polje',
  missingOrIncorrect:
    'Manjkajoča oziroma nepravilna vrednost v polju {{field}}.',
  tooShort: 'V polje {{field}} ste vnesli prekratko vrednost.',
  tooLong: 'V polje {{field}} ste vnesli predolgo vrednost.',
  tooShortMin: 'Najmanjše število znakov: {{min}}',
  tooLongMax: 'Največje število znakov: {{max}}',
  tooLow: 'V polje {{field}} ste vnesli prenizko vrednost.',
  tooHigh: 'V polje {{field}} ste vnesli previsoko vrednost.',
  maxValue: 'Največje število doseženo',
  NaN: 'V polje {{field}} je potrebno vnesti numerično vrednost',
  notAnInteger: 'Polje {{field}} ne dovoljuje decimalnih števil',
  notPositive: 'Polje {{field}} dovoljuje samo pozitivno število',
  notNegative: 'Polje {{field}} dovoljuje samo negativno število',
  maxDecimalPlaces: 'Največ decimalnih mest: {{maxDecimalPlaces}}',
  notAnEmail:
    'V polje {{field}} je potrebno vnesti elektronski naslov v ustrezni obliki. Na primer novak@gmail.com.',
  notANumber: 'Polje {{field}} ne sprejema številk.',
  notAPhoneNumber:
    'V polje {{field}} je potrebno vnesti veljavno obliko. Na primer +38640111222.',
  passwordsMustMatch: 'Gesli se morata ujemati.',
  devicePairingCodeInvalid: 'Vnešena koda {{field}} ni veljavna.',
  devicePairingCodeNotCorrectLength:
    'Vnesti je potrebno {{field}} 6-mestno kodo.',
  noSpace: 'Polje {{filed}} ne sprejema presledkov',
  duplicateValue: 'Podvojena vrednost',
  numberOnly: 'Dovoljeni znaki so številke',
  numberDashOnly: 'Dovoljeni znaki so številke in pomišljaji',
  letterNumberDashOnly:
    'Dovoljeni znaki so črke (brez šumnikov), številke in pomišljaji',
  letterNumberOnly: 'Dovoljeni znaki so črke in številke',
  billNumberExists: 'Številka že obstaja',
  higherThanPrepayment: 'Vrednost je višja od preostanka predplačila',
  endGreaterOrSameThanStart: 'Končni čas je enak ali pred začetnim časom',
  phoneNotValid: 'Telefonska številka ni veljavna',
  file: {
    maxSizeLimit:
      'Največja dovoljena velikost datoteke je {{ maxFileSize }} MB',
    fileTooLarge: 'Datoteka je prevelika'
  },
  passwordAlphaNumeric:
    'Geslo mora biti bodisi:  <br />-16 znakov dolgo  <br />- 8 znakov dolgo in vsebovati mora vsaj eno veliko črko, eno malo črko in vsaj eno številko',
  urlNotValid: 'Url ni veljaven.',
  document: {
    signatureOnly: 'Dokument ne sme vsebovati samo podpisa',
    fieldLength: 'Dokument mora vsebovati vsaj 1 vprašanje',
    optionLength: 'Polje mora vsebovati vsaj 2 možnosti'
  },
  atLeastOneLocationSelected: 'Vsaj ena lokacija mora biti izbrana',
  onlineBooking: {
    durationDividableBy5:
      'Vrednost mora biti večkratnik števila 5. Primer: 5, 10, 15, 20, 25, itd.',
    welcomeMessageTooLong: 'Dovoljena dolžina je največ 100 znakov',
    welcomeDetailsTooLong: 'Dovoljena dolžina je največ 2000 znakov'
  }
};
