import axios from 'axios';

import { getAllBillsListUrl } from '../../../utils/api/apiUrlUtils';
import { getDateFilterParametersPayload } from '../../../utils/billing';

import {
  CLEAR_BILL_LIST_FILTERS,
  CLEAR_BILL_LIST_FILTERS_NO_POLL,
  GET_BILL_LIST,
  SET_BILL_LIST_FILTER,
  SET_BILL_LIST_FILTERS,
  SET_BILL_LIST_INCLUDE_PARAM,
  SET_BILL_LIST_ORDER,
  SET_BILL_LIST_PAGINATION,
  CLEAR_BILLING_ITEM_DESCRIPTION
} from './billTypes';

import {
  selectBillFilter,
  selectBillIncludeParam,
  selectBillPagination
} from './billSelectors';

export const getBillList = () => (dispatch, getState) => {
  const store = getState();
  const allBillListUrl = getAllBillsListUrl(() => store);
  const include = selectBillIncludeParam(store);
  const { limit, page } = selectBillPagination(store);

  let customFilter = selectBillFilter(store);

  customFilter = getDateFilterParametersPayload(customFilter);

  return dispatch({
    type: GET_BILL_LIST,
    apiCall: axios.get(allBillListUrl, {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    })
  });
};

export const setBillListFilter = (filterName, filterValue) => (dispatch) => {
  dispatch({
    type: SET_BILL_LIST_FILTER,
    filterName,
    filterValue
  });
};

export const setBillListFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_BILL_LIST_FILTERS,
    filters
  });
};

export const setBillListIncludeParam = (includeValue) => (dispatch) =>
  dispatch({
    type: SET_BILL_LIST_INCLUDE_PARAM,
    includeValue
  });

export const setBillListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_BILL_LIST_ORDER,
    sortBy,
    order
  });
};

export const setBillListPagination = (paginationName, paginationValue) => (
  dispatch
) => {
  dispatch({
    type: SET_BILL_LIST_PAGINATION,
    paginationName,
    paginationValue
  });
};

export const clearBillListFilters = (refetchBillList = true) => (dispatch) => {
  if (refetchBillList) {
    dispatch({ type: CLEAR_BILL_LIST_FILTERS });
  } else {
    dispatch({ type: CLEAR_BILL_LIST_FILTERS_NO_POLL });
  }
};

// TODO: Remove once we move away from Formik lib
export const clearBillItemDescription = (billId = null) => (dispatch) =>
  dispatch({
    type: CLEAR_BILLING_ITEM_DESCRIPTION,
    billId
  });
