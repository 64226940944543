import moment from 'moment';
import { select, takeLatest, take } from 'redux-saga/effects';

import {
  FULL_WIDTH_TOAST_CONTAINER_ID,
  hideAlertWithId,
  MOSHI_FULL_WIDTH_TOAST_CLASS,
  showAlertInfo,
  showAlertWarning
} from '../../../utils/alert';
import { getVersionsUrl } from '../../../utils/api/apiUrlUtils';
import { guid } from '../../../utils/gen';
import { isAuthenticated } from '../../../utils/jwt';
import { pollGenerator } from '../../../utils/redux/sagaUtils';
import { safeGlobalDispatch } from '../../../utils/redux/storeUtils';

import { hideOfflineStatusBar } from './appActions';

import { POLL_VERSIONS, POLL_VERSIONS_SUCCESS } from '../cache/cacheTypes';
import {
  WEBSOCKET_CONNECT_FAILURE,
  WEBSOCKET_CONNECT_SUCCESS,
  WEBSOCKET_CONNECT_TIMEOUT
} from '../web-socket/webSocketTypes';

import { selectTokenString } from '../../auth/authSelectors';
import { selectIsNewVersionAvailable } from '../cache/cacheSelectors';
import { selectIsOfflineStatusBarHidden } from './appSelectors';

const NEW_VERSION_TOAST_ID = guid();

const loadNewAppVersion = () => {
  window.location.reload(true);
};

const showUpdateNotification = () => {
  showAlertInfo('app:newApplicationVersion', {
    closeIn: 0,
    toastId: NEW_VERSION_TOAST_ID,
    containerId: FULL_WIDTH_TOAST_CONTAINER_ID,
    buttons: [
      {
        text: 'common:refresh',
        onClick: loadNewAppVersion
      }
    ],
    otherParams: {
      position: 'top-center',
      closeOnClick: false,
      closeButton: false,
      className: MOSHI_FULL_WIDTH_TOAST_CLASS
    }
  });
};

const showAppOfflineNotification = (toastId) =>
  showAlertWarning('app:appOffline', {
    closeIn: 0,
    toastId,
    containerId: FULL_WIDTH_TOAST_CONTAINER_ID,
    otherParams: {
      position: 'top-center',
      closeOnClick: false,
      closeButton: true,
      className: MOSHI_FULL_WIDTH_TOAST_CLASS,
      onClose: () => {
        safeGlobalDispatch(hideOfflineStatusBar());
      }
    }
  });

function* handleNewVersion() {
  const newVersionAvailable = yield select(selectIsNewVersionAvailable);
  const tokenString = yield select(selectTokenString);

  if (newVersionAvailable) {
    if (isAuthenticated(tokenString)) {
      showUpdateNotification();
    } else {
      loadNewAppVersion();
    }
  }
}

export function* checkNewVersionSaga() {
  yield takeLatest(POLL_VERSIONS_SUCCESS, handleNewVersion);
}

export function* checkServerStatus() {
  const APP_OFFLINE_TOAST_ID = guid();
  let toastInstanceId;

  while (true) {
    // After app starts and everything is wired up, start listening for web socket failures
    yield take([WEBSOCKET_CONNECT_FAILURE, WEBSOCKET_CONNECT_TIMEOUT]);
    const isOfflineStatusBarHidden = yield select(
      selectIsOfflineStatusBarHidden
    );

    if (!isOfflineStatusBarHidden) {
      toastInstanceId = showAppOfflineNotification(APP_OFFLINE_TOAST_ID);

      // Wait for successful connection
      yield take(WEBSOCKET_CONNECT_SUCCESS);
      hideAlertWithId(toastInstanceId);
    }
  }
}

export function* pollAppVersionSaga() {
  yield pollGenerator(POLL_VERSIONS, getVersionsUrl, {
    timer: moment.duration(5, 'minutes').asMilliseconds()
  });
}
