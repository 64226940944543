export default {
  missingOrganizationId:
    'For some reason we are missing the information about your organization. Functionality might be impaired.',
  missingUserId:
    'For some reason we are missing the information about the logged in user. Functionality might be impaired.',
  missingPatientId:
    'For some reason we are missing the information about the patient. Functionality might be impaired.',
  missingLocationId:
    'For some reason we are missing the information about your selected location. Functionality might be impaired.',
  missingEncounterId:
    'For some reason we are missing the information about selected encounter. Functionality might be impaired.',
  missingCommentId:
    'For some reason we are missing information about the comment. Functionality might be impaired.',
  missingAssistRequestId:
    'For some reason we are missing the information about selected assistance request. Functionality might be impaired.',
  missingBillingUnitId:
    'For some reason we are missing the information about selected business. Functionality might be impaired.',
  missingRegistryId:
    'For some reason we are missing the information about selected code list. Functionality might be impaired.',
  authTokenExpired: 'Session expired, please log in again.',
  general: 'Something went wrong on the server. Try again in a few minutes',
  export: {
    alreadyScheduled:
      '$t(organizationSettings:export.validation.alreadyStarted)'
  },
  serverError: '$t(error:general)',
  internalServerError: '$t(error:general)',
  connection: 'Failed to connect to the server',
  invalidValue: 'The entered value is incorrect',
  patternError: 'The entered value is not in correct format',
  badRequest: 'Request is not properly formatted',
  planUpgradeRequired: 'Feature is not available in the current plan',
  forbidden:
    'You do not have the appropriate permissions to perform this action',
  notFound: 'Required entity cannot be found',
  tooManyRequests: 'Rate limit exceeded',
  internalError: 'Internal server error',
  unauthorized: 'Unauthorized Access',
  invalidUrl:
    'The URL address you entered is not valid, please check the address and retry',
  user: {
    alreadyMember: 'Selected email is already used by an existing user',
    notFound: 'The entered username and/or password is not correct',
    email: {
      conflict:
        'A user with this email address already exists. Please use a different email address.'
    }
  },
  operator: {
    fursMissing:
      'You cannot issue the invoice, because the user does not have the personal tax number added'
  },
  furs: {
    alreadyEnabled: 'Fiscal verification has already been activated',
    certificateOrPassphraseInvalid: 'Certificate file or passphrase invalid.'
  },
  invalidDate: 'The entered date is not valid.',
  organization: {
    slug: {
      conflict:
        'Your chosen organization name is already taken. Please use a different name.'
    },
    ipNotAllowed: 'Access from your IP address is not allowed.'
  },
  document: {
    notFiscalised: 'Fiscalisation of the document was not successful.',
    invoicingIDNotSet: 'The document does not have fiscalisation enabled.'
  },
  item: {
    quantityZero: 'Quantity cannot not be zero.'
  },
  billingUnit: {
    fiscalisationNotEnabled:
      'Fiscalisation is not enabled for this billing unit.',
    notActive: 'Billing unit is not active.'
  },
  token: {
    expired: 'You have been logged out due to inactivity.',
    unauthorized: '$t(error:forbidden)'
  },
  doctorID: {
    invalid: 'Assigned doctor on this patient does not exist.'
  },
  statusChange: {
    encounterExists:
      'The patient is already marked as arrived in your waiting room.'
  },
  code: {
    entityNotFound: '$t(auth:acceptInvite.descriptionFailure)'
  },
  password: {
    incorrect: 'Entered password is incorrect'
  },
  patient: {
    notAllowed: "You don't have permissions to view this patient",
    entityNotFound: 'This patient does not exist or was deleted'
  },
  therapy: {
    entityNotFound: 'This therapy does not exist or was deleted'
  },
  email: {
    invalid: 'Email address is invalid'
  },
  pauseAt: {
    beforeLastPause: "You can't pause the therapy twice in the same day"
  },
  resumeFrom: {
    beforePausedFrom: 'Therapy cannot be resumed before the therapy was paused.'
  }
};
