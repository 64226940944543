import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize, translateKey } from '../../../i18n';
import { handleBackendValidationErrors } from '../../../utils/form';

import TableColumnShape, {
  TableDataShape,
  CardMoreActionsShape
} from '../../../metadata/shapes/TableShape';

import { Card, Col, Label, Row } from '../../reactstrap';
import Icon from '../Icon';
import Spinner from '../Spinner';
import ConditionalWrap from '../layout/ConditionalWrap';
import Visible from '../layout/Visible';
import TableItem from './TableItem';

const renderColumn = (column) => {
  if (!_.isEmpty(column.displayKeyCode)) {
    column.headerComponent = () => translateKey(column.displayKeyCode);
  }

  return (
    <Col key={column.name} xs={column.colWidth} className={'column-header'}>
      {_.isFunction(column.headerComponent) && (
        <ConditionalWrap
          condition={!column.noHeaderLabel}
          wrap={(children) => <Label>{children}</Label>}
        >
          {column.headerComponent()}
        </ConditionalWrap>
      )}
    </Col>
  );
};

const EditComponentWrapper = ({
  onSubmit,
  onCancel,
  item,
  tableItemClass,
  template: Template
}) => (
  <Card className={classNames('full-width', 'table-row', tableItemClass)}>
    <Template onSubmit={onSubmit} onCancel={onCancel} item={item} />
  </Card>
);

EditComponentWrapper.propTypes = {
  template: PropTypes.func.isRequired,
  item: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tableItemClass: PropTypes.string.isRequired
};

EditComponentWrapper.defaultProps = {
  item: undefined
};

// eslint-disable-next-line max-lines-per-function
const TableComponent = ({
  t: translate,
  setAddingRef,
  columns,
  data,
  onMainCellClick,
  onRowClick,
  rowActions,
  showLoader,
  tableItemClass,
  emptyState,
  disableClickableCell,
  className,
  keyPath,
  displayTableHeader,
  getRowClassNames,
  editable,
  editConfig
}) => {
  const [editingId, setEditingId] = useState(null);
  const [addingNewItem, setAddingNewItem] = useState(false);
  const allRowActions = _.cloneDeep(rowActions);
  const EditTemplate = _.get(editConfig, 'editTemplate', _.noop);
  const onSubmit = _.get(editConfig, 'onSubmit', _.noop);

  useEffect(() => {
    if (_.isFunction(setAddingRef)) {
      setAddingRef(setAddingNewItem);
    }
  }, [setAddingRef, setAddingNewItem]);

  const EditComponent = (props) => (
    <EditComponentWrapper
      item={_.get(props, 'item', undefined)}
      onCancel={_.get(props, 'onCancel', _.noop)}
      template={EditTemplate}
      tableItemClass={tableItemClass}
      onSubmit={handleSubmit}
    />
  );

  if (editable) {
    allRowActions.unshift({
      text: translate('common:edit'),
      icon: () => <Icon name={'edit'} size={'md'} />,
      onClick: (item) => setEditingId(_.get(item, keyPath))
    });
  }

  const handleSubmit = (values, actions) => {
    const functionType = onSubmit(values, true);

    if (functionType instanceof Promise) {
      functionType
        .then(() => {
          setEditingId(null);
          setAddingNewItem(false);
        })
        .catch(handleBackendValidationErrors(actions))
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      setEditingId(null);
      setAddingNewItem(false);
    }
  };

  return (
    <div
      className={classNames(
        'table',
        { 'content-loading': showLoader, 'content-loaded': !showLoader },
        className
      )}
    >
      <Visible visible={displayTableHeader}>
        <Row className={'table-header'}>{columns.map(renderColumn)}</Row>
      </Visible>
      <Row>
        <Visible visible={addingNewItem}>
          <EditComponent onCancel={() => setAddingNewItem(false)} />
        </Visible>
        <Visible visible={!_.isEmpty(data)}>
          {data.map((item) => (
            <React.Fragment key={_.get(item, keyPath)}>
              {editingId === _.get(item, keyPath) ? (
                <EditComponent
                  item={item}
                  onCancel={() => setEditingId(null)}
                />
              ) : (
                <TableItem
                  item={item}
                  columns={columns}
                  onMainCellClick={onMainCellClick}
                  onRowClick={() => onRowClick(item)}
                  rowActions={allRowActions}
                  tableItemClass={classNames(
                    tableItemClass,
                    getRowClassNames(item)
                  )}
                  disableClickableCell={disableClickableCell}
                />
              )}
            </React.Fragment>
          ))}
        </Visible>
        <Visible visible={_.isEmpty(data) && !showLoader}>
          {_.isFunction(emptyState)
            ? emptyState()
            : translate('common:nothingHere')}
        </Visible>
      </Row>
      <Visible visible={showLoader}>
        <Spinner />
      </Visible>
    </div>
  );
};

TableComponent.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape(TableColumnShape)).isRequired,
  setAddingRef: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape(TableDataShape)),
  onMainCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  rowActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape)),
  showLoader: PropTypes.bool,
  tableItemClass: PropTypes.string,
  emptyState: PropTypes.func,
  disableClickableCell: PropTypes.bool,
  className: PropTypes.string,
  keyPath: PropTypes.string,
  displayTableHeader: PropTypes.bool,
  getRowClassNames: PropTypes.func,
  editable: PropTypes.bool,
  editConfig: PropTypes.shape({
    editTemplate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  })
};

TableComponent.defaultProps = {
  setAddingRef: undefined,
  data: [],
  onMainCellClick: undefined,
  onRowClick: _.noop,
  rowActions: [],
  showLoader: false,
  tableItemClass: undefined,
  emptyState: undefined,
  disableClickableCell: undefined,
  className: undefined,
  keyPath: 'id',
  displayTableHeader: true,
  getRowClassNames: _.noop,
  editable: false,
  editConfig: null
};

export default localize(TableComponent);
