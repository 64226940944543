import React, { useMemo } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { slugify } from '../../../utils/gen';

import MoshiRsMoreActionsDropdown from './../form/dropdown/rs-dropdown/MoshiRsMoreActionsDropdown';

import {
  TableDataShape,
  CardMoreActionsShape
} from '../../../metadata/shapes/TableShape';

import Visible from '../layout/Visible';

/* eslint-disable max-lines-per-function, max-statements */
const CardMoreActions = ({
  actions,
  item,
  index,
  className,
  toggleSize,
  tabIndex,
  onToggle,
  topPosition,
  rightPosition,
  iconClassName
}) => {
  const getMoreActionsId = useMemo(() => {
    if (_.has(item, 'widgetType')) {
      return `${_.kebabCase(item.widgetType)}-more-actions`;
    } else if (_.isNumber(index)) {
      return `card-more-actions-${index}`;
    }

    return undefined;
  }, [index, item]);

  // actions that will appear inline in the row
  const inlineActions = _.filter(actions, { inline: true });
  // actions that will be inside a dropdown
  const dropdownActions = _.filter(
    actions,
    (action) => !_.get(action, 'inline', false)
  );

  const dropdownItems = actions.map((action) => {
    if (_.isFunction(action.isVisible) && !action.isVisible(item)) {
      return null;
    }

    return {
      id: slugify(action.text),
      name: action.text,
      ...action
    };
  });

  let style = {};

  if (!_.isEmptySafe(topPosition)) {
    _.set(style, 'top', `${topPosition}px`);
  }

  if (!_.isEmptySafe(rightPosition)) {
    _.set(style, 'top', `${rightPosition}px`);
  }

  if (_.isEmptySafe(style)) {
    style = undefined;
  }

  return (
    <div
      id={getMoreActionsId}
      className={classNames('card-more-actions', className)}
      style={style}
    >
      <Visible visible={inlineActions.length > 0}>
        {inlineActions.map((action, actionIndex) => {
          if (_.isFunction(action.isVisible) && !action.isVisible(item)) {
            return null;
          } else {
            return (
              <Visible
                /* eslint-disable-next-line react/no-array-index-key */
                key={`action-${actionIndex}`}
                visible={_.isFunction(action.icon)}
              >
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    action.onClick(item);
                  }}
                  tabIndex={tabIndex}
                  className={classNames(
                    'row-action-btn',
                    'moshi-link',
                    action.className
                  )}
                  role={'button'}
                >
                  {action.icon()}
                </div>
              </Visible>
            );
          }
        })}
      </Visible>
      <Visible visible={dropdownActions.length > 0}>
        <MoshiRsMoreActionsDropdown
          onSelect={(action) => {
            action.onClick(item);
          }}
          onToggle={onToggle}
          toggleSize={toggleSize}
          items={dropdownItems}
          toggleClassName={'hoverable-icon more-toggle'}
          iconClassName={iconClassName}
        />
      </Visible>
    </div>
  );
};

CardMoreActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape)).isRequired,
  item: PropTypes.shape(TableDataShape).isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  toggleSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  tabIndex: PropTypes.number,
  onToggle: PropTypes.func,
  topPosition: PropTypes.number,
  rightPosition: PropTypes.number
};

CardMoreActions.defaultProps = {
  index: undefined,
  className: undefined,
  iconClassName: undefined,
  toggleSize: 'md',
  tabIndex: undefined,
  onToggle: _.noop,
  topPosition: undefined,
  rightPosition: undefined
};

export default CardMoreActions;
