import { push } from 'connected-react-router';

import axios from 'axios';
import _ from 'lodash';

import {
  showMissingLocationIdError,
  showMissingOrganizationIdError,
  showAlertSuccess
} from '../../../utils/alert';
import { getOrganizationUrl } from '../../../utils/api';
import {
  BILL_TYPE_ESTIMATE,
  getDateFilterParametersPayload
} from '../../../utils/billing';
import { EMPTY_GUID } from '../../../utils/gen';

import { patchPatient } from '../../patient/patientActions';
import { clearBillItemDescription } from '../bill/billActions';
import {
  getBillKeywordData,
  updateUserPreferredBillDataInput
} from '../billingActions';

import {
  GET_ESTIMATE_LIST,
  SET_ESTIMATE_LIST_FILTER,
  SET_ESTIMATE_LIST_FILTERS,
  SET_ESTIMATE_LIST_ORDER,
  GET_ESTIMATE_DETAIL,
  UPDATE_ESTIMATE,
  ADD_ESTIMATE,
  REMOVE_ESTIMATE,
  SET_ESTIMATE_LIST_PAGINATION,
  CLEAR_ESTIMATE_DETAIL,
  CLEAR_ESTIMATE_LIST_FILTER,
  PATCH_ESTIMATE,
  SEND_ESTIMATE_VIA_EMAIL,
  CLEAR_ESTIMATE_LIST_FILTER_NO_POLL
} from './estimateTypes';

import { selectCachedOrganizationId } from '../../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';
import { selectDefaultBillingLocale } from '../../organization/organizationSelectors';
import {
  selectEstimateDetail,
  selectEstimateFilter,
  selectEstimatePagination
} from './estimateSelectors';

import EstimateModel from '../../../metadata/model/billing/bill/EstimateModel';

export const clearEstimateDetail = () => (dispatch, getState) => {
  const bill = selectEstimateDetail(getState());
  const billId = _.getNonEmpty(bill, 'id', EMPTY_GUID);

  dispatch(clearBillItemDescription(billId));

  return dispatch({
    type: CLEAR_ESTIMATE_DETAIL
  });
};

export const getEstimateList = () => (dispatch, getState) => {
  const store = getState();
  const organizationUrl = getOrganizationUrl(() => store);
  const { limit, page } = selectEstimatePagination(store);

  let customFilter = selectEstimateFilter(store);

  customFilter = getDateFilterParametersPayload(customFilter);

  return dispatch({
    type: GET_ESTIMATE_LIST,
    apiCall: axios.get(`${organizationUrl}/billing/estimates`, {
      params: {
        ...customFilter,
        limit,
        page
      }
    })
  });
};

export const getEstimate = (estimateId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: GET_ESTIMATE_DETAIL,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/estimates/${estimateId}`
    ),
    onSuccess: () => {
      dispatch(getBillKeywordData(BILL_TYPE_ESTIMATE));
    }
  });
};

export const setEstimateListFilter = (filterName, filterValue) => (
  dispatch
) => {
  dispatch({
    type: SET_ESTIMATE_LIST_FILTER,
    filterName,
    filterValue
  });
};

export const setEstimateListFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATE_LIST_FILTERS,
    filters
  });
};

export const setEstimateListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_ESTIMATE_LIST_ORDER,
    sortBy,
    order
  });
};

export const setEstimateListPagination = (paginationName, paginationValue) => (
  dispatch
) => {
  dispatch({
    type: SET_ESTIMATE_LIST_PAGINATION,
    paginationName,
    paginationValue
  });
};

export const addEstimate = (estimate, customListAction, formId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const locationId = selectCurrentLocationId(state);

  if (_.isEmpty(locationId)) {
    return dispatch(showMissingLocationIdError());
  }

  const payload = EstimateModel.getPayload(estimate, defaultLocale);

  payload.locationID = locationId;
  // A unique form id that is generated when we open the add estimate form.
  payload.formID = formId;

  return dispatch({
    type: ADD_ESTIMATE,
    apiCall: axios.post(`${organizationUrl}/billing/estimates`, payload),
    onSuccess: () => {
      showAlertSuccess('billing:estimates.alerts.estimateCreated');

      /*
       * TODO: handle preferred device settings differently for estimates
       * dispatch(updateUserPreferredBillDataInput(estimate));
       */

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getEstimateList());
    }
  });
};

export const updateEstimate = (estimate, customListAction) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const payload = EstimateModel.getPayload(estimate, defaultLocale);

  return dispatch({
    type: UPDATE_ESTIMATE,
    apiCall: axios.put(
      `${organizationUrl}/billing/estimates/${estimate.id}`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:estimates.alerts.estimateUpdated');
      dispatch(updateUserPreferredBillDataInput(estimate));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getEstimateList());
    }
  });
};

export const addOrUpdateEstimate = (estimate, customListAction, formId) => (
  dispatch
) => {
  let promiseObject;

  if (_.isEmpty(estimate.id)) {
    promiseObject = dispatch(addEstimate(estimate, customListAction, formId));
  } else {
    promiseObject = dispatch(updateEstimate(estimate, customListAction));
  }

  return promiseObject;
};

export const patchEstimate = (
  estimateId,
  patchedFields,
  customListAction = undefined
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: PATCH_ESTIMATE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/estimates/${estimateId}/reassign`,
      patchedFields
    ),
    onSuccess: () => {
      showAlertSuccess(`billing:estimates.alerts.estimateUpdated`);

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getEstimateList());
    }
  });
};

export const removeEstimate = (estimateId, basePath, customAction = null) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_ESTIMATE,
    apiCall: axios.delete(
      `/organizations/${organizationId}/billing/estimates/${estimateId}`
    ),
    onSuccess: () => {
      showAlertSuccess('billing:estimates.alerts.estimateRemoved');
      dispatch(push(basePath));
      dispatch(clearEstimateDetail());

      if (_.isFunction(customAction)) {
        return dispatch(customAction());
      }

      return dispatch(getEstimateList());
    }
  });
};

export const sendEstimateViaEmail = (
  estimateId,
  email,
  note = undefined,
  patientIdToUpdate = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = { email, note };

  return dispatch({
    type: SEND_ESTIMATE_VIA_EMAIL,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/estimates/${estimateId}/sendPdf`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.pdfSentViaEmail');

      if (!_.isEmpty(patientIdToUpdate) && patientIdToUpdate !== EMPTY_GUID) {
        dispatch(patchPatient(patientIdToUpdate, { contact: { email } }));
      }
    }
  });
};

export const clearEstimateListFilters = (refetchEstimateList = true) => (
  dispatch
) => {
  if (refetchEstimateList) {
    dispatch({ type: CLEAR_ESTIMATE_LIST_FILTER });
  } else {
    dispatch({ type: CLEAR_ESTIMATE_LIST_FILTER_NO_POLL });
  }
};
