import _ from 'lodash';

/**
 * Orders a list of items depending on whether they have defined `order` property. Does not sort by order value.
 *
 * Example: [{ id: 1 }, {id: 2, order: 3}] would be sorted to [{id: 2, order: 3}, { id: 1 }]
 *
 * Example with _.orderBy (it will put items without order first, then order them by order property):
 * _.orderBy(
 *   items,
 *   [
 *     orderByOrderExists(true),
 *     'order',
 *   ],
 *   'asc'
 * );
 *
 * @param {boolean} undefinedFirst if true, objects without order property will be sorted before those with property
 * Example: [{ id: 1 }, {id: 2, order: 3}] would be sorted to [{ id: 1 },{id: 2, order: 3}]
 */
export const orderByOrderExists = (undefinedFirst) => (item) => {
  if (!_.has(item, 'order')) return undefinedFirst ? 0 : 1;

  return undefinedFirst ? 1 : 0;
};
