export default {
  validationErrors: {
    format: {
      email:
        'V polje je potrebno vnesti elektronski naslov v ustrezni obliki. Na primer novak@gmail.com.',
      phoneNumber: 'Telefonska številka ni veljavna.',
      date: 'V polje je potrebno vnesti datum v ustrezni obliki.'
    },
    required: 'Obvezno polje',
    uniqueItems: 'Podvojene vrednosti niso dovoljene'
  }
};
