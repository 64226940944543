import produce from 'immer';

import { LOGOUT } from '../../auth/authReducer';

export const SET_FORMIK_DATA = 'formik/SET_FORMIK_DATA';
export const SET_FORMIK_VALIDITY = 'formik/SET_FORMIK_VALIDITY';
export const CLEAR_FORMIK_STATE = 'formik/CLEAR_FORMIK_STATE';
export const TOGGLE_TEXTAREA_FOCUSED = 'formik/TOGGLE_TEXTAREA_FOCUSED';

const initialState = {
  isTextAreaFocused: false
};

// eslint-disable-next-line default-param-last
const formikReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case SET_FORMIK_DATA:
        draft.data = payload.formData;
        break;
      case SET_FORMIK_VALIDITY:
        draft.isValid = payload.isFormValid;
        break;
      case CLEAR_FORMIK_STATE:
        delete draft.data;
        delete draft.isValid;
        break;
      case TOGGLE_TEXTAREA_FOCUSED:
        draft.isTextAreaFocused = payload.focused;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default formikReducer;
