import React from 'react';

import classNames from 'classnames';

import { guid } from '../../../../utils/gen';

import { JsonSchemaWidgetShape } from '../../../../metadata/shapes/json-schema/JsonSchemaWidgetShape';

import ConditionalWrap from '../../layout/ConditionalWrap';

const CustomRadioWidget = ({
  value,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  options: { enumOptions, enumDisabled, inline }
}) => {
  const name = guid();

  return (
    <div className={'field-radio-group'}>
      {enumOptions.map((option, i) => {
        const checked = option.value === value;
        const itemDisabled =
          enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        const disabledCls =
          disabled || itemDisabled || readonly ? 'disabled' : '';
        const radio = (
          <label
            className={classNames('radio', {
              disabled: disabled || itemDisabled
            })}
          >
            <input
              type={'radio'}
              checked={checked}
              name={name}
              required={required}
              value={option.value}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && i === 0}
              onChange={() => onChange(option.value)}
            />
            <span className={'radio-icon'} />
            <span className={'radio-label'}>{option.label}</span>
          </label>
        );

        return (
          <ConditionalWrap
            key={option.value}
            condition={inline === true}
            wrap={(children) => (
              <div className={`radio ${disabledCls}`}>{children}</div>
            )}
          >
            {radio}
          </ConditionalWrap>
        );
      })}
    </div>
  );
};

CustomRadioWidget.propTypes = JsonSchemaWidgetShape;

CustomRadioWidget.defaultProps = {
  required: false,
  disabled: false,
  autofocus: false
};

export default CustomRadioWidget;
