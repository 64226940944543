import React from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { localize } from '../../../../i18n';
import { MOSHI_COLOR_PRIMARY_NAME } from '../../../../utils/color';
import { MOSHI_SIZE_SM } from '../../../../utils/constants/sizeConstants';

import Icon from '../../Icon';
import MoshiLink from '../../button/MoshiLink';

const ClearButton = ({ onClick }) => (
  <div className={'clear-button-wrapper'}>
    <MoshiLink
      size={MOSHI_SIZE_SM}
      className={'clear-button'}
      color={MOSHI_COLOR_PRIMARY_NAME}
      onClick={onClick}
    >
      <Icon name={'cancel'} size={'md'} className={'clear-button-icon'} />
    </MoshiLink>
  </div>
);

ClearButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

const componentComposer = compose(localize);

export default componentComposer(ClearButton);
