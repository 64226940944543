import produce from 'immer';
import _ from 'lodash';

import { SET_PREVIOUS_LOCATION } from './routerHistoryTypes';

const initialState = {
  previousLocation: {}
};

// eslint-disable-next-line default-param-last
const routerHistoryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case SET_PREVIOUS_LOCATION:
        const prevLocation = _.getNonEmpty(
          payload,
          'data',
          initialState.previousLocation
        );

        draft.previousLocation = prevLocation;
        break;

      default:
        return state;
    }
  });

export default routerHistoryReducer;
