import bloodGlucose from './bloodGlucose';
import bloodPressure from './bloodPressure';
import bodyHeight from './bodyHeight';
import bodyMass from './bodyMass';
import bodyMassIndex from './bodyMassIndex';
import bodyTemperature from './bodyTemperature';
import heartRate from './heartRate';
import oxygenSaturation from './oxygenSaturation';
import respirationRate from './respirationRate';

export default {
  groupId: 'vitalSign',
  groupName: 'encounter:widget.vitalSigns.title',
  widgets: {
    [bodyHeight.id]: bodyHeight,
    [bodyMass.id]: bodyMass,
    [bodyTemperature.id]: bodyTemperature,
    [heartRate.id]: heartRate,
    [oxygenSaturation.id]: oxygenSaturation,
    [respirationRate.id]: respirationRate,
    [bloodGlucose.id]: bloodGlucose,
    [bloodPressure.id]: bloodPressure,
    [bodyMassIndex.id]: bodyMassIndex
  }
};
