import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { doesTranslateKeyExist } from '../../../../../i18n';
import { isValidDate } from '../../../../../utils/date';

import { DateCellConfigShape } from '../../../../../metadata/shapes/TableCellShape';

/**
 * Displays date fields in shortDate format.
 *
 * @example ./__examples__/MoshiTableDateCellComponent.md
 */
export const MoshiTableDateCellComponent = ({
  item,
  fieldName,
  config,
  cellRef
}) => {
  const { t: translate } = useTranslation();
  const isoDateString = _.get(item, fieldName);
  let format = _.getNonEmpty(config, 'format');

  if (!isValidDate(isoDateString)) {
    return '/';
  }
  if (
    !_.includes(
      [
        'shortDate',
        'longDate',
        'longDateWithTime',
        'time',
        'shortDateWithTime'
      ],
      format
    )
  ) {
    format = 'shortDate';
  }

  let translationKey = `date:${format}`;

  if (!doesTranslateKeyExist(translationKey)) {
    translationKey = `date:${format}`;
  }

  return (
    <span ref={cellRef} className={_.get(config, 'className', '')}>
      {translate(translationKey, {
        date: moment(isoDateString)
      })}
    </span>
  );
};

MoshiTableDateCellComponent.propTypes = {
  /** Raw object representing a row in the table */
  item: PropTypes.shape().isRequired,
  /** Field name to be displayed (should be one of keys in item) */
  fieldName: PropTypes.string.isRequired,
  /** Cell-specific config: className (string) */
  config: PropTypes.shape(DateCellConfigShape),
  /** cellRef is used to detect ellipsis text */
  cellRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

MoshiTableDateCellComponent.defaultProps = {
  config: {},
  cellRef: undefined
};

export default MoshiTableDateCellComponent;
