import _ from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';

import { selectProps } from '../../../utils/redux/selectorHelpers';

export const selectRouterStore = (store) => _.getNonEmpty(store, 'router', {});

// PATHNAME SELECTORS
export const selectRouterLocation = createSelector(
  [selectRouterStore],
  (store) => _.getNonEmpty(store, 'location', {})
);

export const selectRouterQueryParam = createSelector(
  [selectRouterLocation, selectProps],
  (location, { param, defaultValue }) => {
    const search = _.getNonEmpty(location, 'search', '');
    const queryParams = queryString.parse(search);

    return _.getNonEmpty(queryParams, param, defaultValue);
  }
);

export const selectRouterPathname = createSelector(
  [selectRouterLocation],
  (routerLocation) => _.getNonEmpty(routerLocation, 'pathname', null)
);
