import testsUndertaken from './testsUndertaken';
import undertaken from './undertaken';

export default {
  groupId: 'procedures',
  groupName: 'encounter:widget.procedures.title',
  widgets: {
    [undertaken.id]: undertaken,
    [testsUndertaken.id]: testsUndertaken
  }
};
