import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

const InitialsBadgeComponent = ({ person, className }) => {
  const firstName = _.get(
    person,
    'identification.firstName',
    _.get(person, 'firstName'),
    ''
  );
  const lastName = _.get(
    person,
    'identification.lastName',
    _.get(person, 'lastName'),
    ''
  );

  return (
    <div className={classNames(className, 'circular-badge')}>
      <div className={'initials-wrapper'}>
        {`${firstName.charAt(0)}${lastName.charAt(0)}`}
      </div>
    </div>
  );
};

InitialsBadgeComponent.propTypes = {
  person: PropTypes.oneOfType([
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      identification: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired
      }).isRequired
    })
  ]).isRequired,
  className: PropTypes.string
};

InitialsBadgeComponent.defaultProps = {
  className: null
};

export default InitialsBadgeComponent;
