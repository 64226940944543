import _ from 'lodash';

import { ACTION_TYPE_SUCCESS } from '../../redux/common/actionTypes';

import { fetchDeletedSubEntities } from '../redux/archive';

const archiveMiddleware = (store) => (next) => (action) => {
  const hasActionResponsePayload = _.has(action, 'response');
  const hasActionDataPayload = _.has(action, 'data');
  const doesActionHaveData = hasActionResponsePayload || hasActionDataPayload;

  if (
    _.has(action, 'type') &&
    _.includes(action.type, ACTION_TYPE_SUCCESS) &&
    doesActionHaveData
  ) {
    const response = _.get(action, 'response.data', {});
    const data = _.get(action, 'data', response);
    const safeData = _.get(data, 'data', data);

    fetchDeletedSubEntities(safeData, store, action.type);
  }

  return next(action);
};

export default archiveMiddleware;
