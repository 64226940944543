import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import _ from 'lodash';

import { history } from './configureStore';
import {
  fullWidthToastConfig,
  fullWidthToastWithDefaultZIndexConfig,
  toastyConfig
} from './utils/alert';
import { guid } from './utils/gen';
import { googleAnalyticsRecordPageView } from './utils/googleAnalyticsConfig';
import { useActions, useMount, useUnmount } from './utils/hooks';
import { setOrganizationMeta } from './utils/organization';

import {
  applicationInit,
  applicationTerminate
} from './redux/auth/authActions';

import { selectIsAuthenticated } from './redux/auth/authSelectors';
import { selectRouterPathname } from './redux/core/router/routerSelectors';

import { EmptyComponent } from './components/common/HtmlComponents';
import PageMeta from './components/common/meta/PageMeta';
import ProtectedRoute from './components/common/navigation/ProtectedRoute';

import {
  LazyAutoLogoutWithRedirect,
  LazyConfirmPassword,
  LazyDownloadPage,
  LazyFeedbackForm,
  LazyLogin,
  LazyLogout,
  LazyProtectedApp,
  LazyRegister,
  LazyResetPassword,
  LazyVerifyChangedEmail,
  LazyVerifyEmail,
  LazyVerifyEmailAndPassword
} from './lazyComponentList';
import { baseRoutes, publicRoutes } from './routes';

const App = () => {
  const [applicationInitDispatch, applicationTerminateDispatch] = useActions([
    applicationInit,
    applicationTerminate
  ]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const pathname = useSelector(selectRouterPathname);
  const componentId = useMemo(() => guid(), []);

  useMount(() => {
    setOrganizationMeta();
    applicationInitDispatch(componentId);
  });

  useUnmount(() => {
    applicationTerminateDispatch(componentId);
  });

  useEffect(() => {
    if (!_.isEmptySafe(pathname)) {
      googleAnalyticsRecordPageView(pathname);
    }
  }, [pathname]);

  return (
    <ConnectedRouter history={history}>
      <ToastContainer enableMultiContainer {...toastyConfig} />
      <ToastContainer enableMultiContainer {...fullWidthToastConfig} />
      <ToastContainer
        enableMultiContainer
        {...fullWidthToastWithDefaultZIndexConfig}
      />
      <PageMeta />
      <Suspense fallback={<EmptyComponent />}>
        <Switch>
          {isAuthenticated && (
            <ProtectedRoute
              exact
              path={publicRoutes}
              component={LazyAutoLogoutWithRedirect}
            />
          )}
          <Route exact path={baseRoutes.login} component={LazyLogin} />
          <Route exact path={baseRoutes.logout} component={LazyLogout} />
          <Route
            exact
            path={baseRoutes.feedbackForm}
            component={LazyFeedbackForm}
          />
          <Route
            exact
            path={baseRoutes.resetPassword}
            component={LazyResetPassword}
          />
          <Route
            exact
            path={baseRoutes.confirmPassword}
            component={LazyConfirmPassword}
          />
          <Route exact path={baseRoutes.register} component={LazyRegister} />
          <Route
            exact
            path={baseRoutes.verifyEmail}
            component={LazyVerifyEmail}
          />
          <Route
            exact
            path={baseRoutes.verifyChangedEmail}
            component={LazyVerifyChangedEmail}
          />
          <Route
            exact
            path={baseRoutes.verifyEmailAndPassword}
            component={LazyVerifyEmailAndPassword}
          />
          <ProtectedRoute
            exact
            path={publicRoutes}
            component={LazyAutoLogoutWithRedirect}
          />
          <ProtectedRoute
            exact
            path={baseRoutes.download}
            component={LazyDownloadPage}
          />
          {/* IMPORTANT: Everything with sidebar should go under ProtectedApp */}
          <ProtectedRoute path={'/*'} component={LazyProtectedApp} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};

export default App;
