import {
  getCalendarAssigneesUrl,
  getCalendarResourcesUrl
} from '../../utils/api/apiUrlUtils';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import { POLL_RESOURCES } from '../core/cache/cacheTypes';
import { GET_CALENDAR_ASSIGNEES } from './calendarTypes';
import {
  ADD_RESOURCE_SUCCESS,
  DELETE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_SUCCESS
} from './resource/resourceTypes';

import {
  ADD_LOCATION_SUCCESS,
  REMOVE_LOCATION_SUCCESS
} from '../location/locationReducer';
import {
  DELETE_USER_SUCCESS,
  UPDATE_USER_ROLE_SUCCESS
} from '../user/userReducer';

export function* assigneesListSaga() {
  yield pollGenerator(GET_CALENDAR_ASSIGNEES, getCalendarAssigneesUrl, {
    retriggerActions: [
      ADD_RESOURCE_SUCCESS,
      UPDATE_RESOURCE_SUCCESS,
      ADD_LOCATION_SUCCESS,
      DELETE_RESOURCE_SUCCESS,
      UPDATE_USER_ROLE_SUCCESS,
      DELETE_USER_SUCCESS,
      REMOVE_LOCATION_SUCCESS
    ]
  });
}

export function* resourcesListSaga() {
  yield pollGenerator(POLL_RESOURCES, getCalendarResourcesUrl, {
    retriggerActions: [
      ADD_RESOURCE_SUCCESS,
      UPDATE_RESOURCE_SUCCESS,
      DELETE_RESOURCE_SUCCESS
    ]
  });
}
