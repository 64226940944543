import { all } from 'redux-saga/effects';

import { addWidgetSagas } from './addWidgetSagas';
import { indexCalculationWidgetSagas } from './indexCalculationWidgetSagas';
import { removeWidgetSagas } from './removeWidgetSagas';
import { updateWidgetSagas } from './updateWidgetSagas';
import { widgetHistorySagas } from './widgetHistorySagas';
import { widgetLocalDataSagas } from './widgetLocalDataSagas';

export function* widgetsSagaWatcher() {
  yield all([
    ...widgetLocalDataSagas,
    ...addWidgetSagas,
    ...updateWidgetSagas,
    ...removeWidgetSagas,
    ...widgetHistorySagas,
    ...indexCalculationWidgetSagas
  ]);
}
