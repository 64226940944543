import { useMemo, useCallback } from 'react';

import { getMatchingIntervalData, parseIntervals } from '../mathIntervals';

export const useMathInterval = (definitions) => {
  const intervals = useMemo(() => parseIntervals(definitions), [definitions]);

  const findData = useCallback(
    (value) => getMatchingIntervalData(intervals, value),
    [intervals]
  );

  return findData;
};
