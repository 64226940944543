export const COMMUNICATION_SMS_STATUS_ALL = 'all';
export const COMMUNICATION_SMS_STATUS_FAILED = 'failed';
export const COMMUNICATION_SMS_STATUS_SCHEDULED = 'scheduled';
export const COMMUNICATION_SMS_STATUS_SENT = 'sent';
export const COMMUNICATION_SMS_STATUS_UNREAD = 'unread';
export const COMMUNICATION_SMS_STATUS_RECEIVED = 'received';

export const COMMUNICATION_SMS_SOURCE_OUTGOING = 'outgoing';
export const COMMUNICATION_SMS_SOURCE_INCOMING = 'incoming';

export const GENERATED_SMS_TYPE_THERAPY_REMINDER = 'drugTherapyReminder';
export const GENERATED_SMS_TYPE_RSVP_RESPONSE = 'appointmentRsvpResponse';
export const GENERATED_SMS_TYPE_RSVP_PATIENT_RESPONSE =
  'appointmentRsvpResponsePatient';
export const GENERATED_SMS_TYPE_APPOINTMENT_ADDED = 'appointmentAdded';
export const GENERATED_SMS_TYPE_APPOINTMENT_NO_SHOW = 'appointmentNoShow';
export const GENERATED_SMS_TYPE_APPOINTMENT_CANCELED = 'appointmentCanceled';
export const GENERATED_SMS_TYPE_APPOINTMENT_REMINDER = 'appointmentReminder';
export const GENERATED_SMS_TYPE_APPOINTMENT_RSVP_RESPONSE =
  'appointmentRsvpResponseMoshi';
export const GENERATED_SMS_TYPE_UNKNOWN = 'unknown';
export const GENERATED_SMS_APPOINTMENT_FEEDBACK = 'appointmentFeedback';

export const SMS_TYPE_CUSTOM = 'custom';
export const SMS_TYPE_CUSTOM_INBOUND = 'customInbound';

export const GENERATED_SMS_REMINDER_TYPES = [
  GENERATED_SMS_TYPE_THERAPY_REMINDER,
  GENERATED_SMS_TYPE_RSVP_RESPONSE,
  GENERATED_SMS_TYPE_APPOINTMENT_ADDED,
  GENERATED_SMS_TYPE_APPOINTMENT_NO_SHOW,
  GENERATED_SMS_TYPE_APPOINTMENT_CANCELED,
  GENERATED_SMS_TYPE_APPOINTMENT_REMINDER,
  GENERATED_SMS_TYPE_RSVP_PATIENT_RESPONSE
];

export const COMMUNICATION_LIST_FILTER_FROM = 'from';
export const COMMUNICATION_LIST_FILTER_TO = 'to';
