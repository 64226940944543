export default {
  language: 'Language',
  timezone: 'Timezone',
  currency: 'Currency',
  defaultLanguage: 'Default language',
  defaultTimezone: 'Default timezone',
  tooltip: {
    defaultLanguage:
      'This will set the default language for the app. All users will have this language as default (every user can still change their own language later).',
    invoiceLanguage:
      'Invoices will be by default issued in the selected language.'
  }
};
