import { useCallback, useEffect, useState } from 'react';

import { registerEvents } from '../event';

/**
 * useWindowEvent - A hook which helps you register events on window
 * @param {String|String[]} events - List of events or a single event
 * @param {Function} callback - Callback which will be called once the event fires
 * @param initialShouldPause
 */
export const useWindowEvent = (
  events,
  callback,
  initialShouldPause = false
) => {
  const shouldPauseState = useState(initialShouldPause);
  const [shouldPause] = shouldPauseState;

  const modifiedCallback = useCallback(
    (e) => {
      if (!shouldPause) {
        callback(e);
      }
    },
    [shouldPause, callback]
  );

  useEffect(() => {
    registerEvents(window, events, modifiedCallback);

    return () => {
      // When component unmounts we need to unregister the events
      registerEvents(window, events, modifiedCallback, true);
    };
  }, [events, modifiedCallback]);

  return shouldPauseState;
};
