export default {
  title: 'Spletno naročanje',
  eventType: {
    bookingCreated: 'Ustvarjen',
    bookingCanceledByPatient: 'Preklican'
  },
  onlineBooking: {
    description:
      'Tukaj so prikazani vsi termini, ki so bili kreirani ali preklicani preko spletnega naročanja.'
  }
};
