import { createActionTypeArray } from '../../common/actionTypes';

export const GET_BILLING_CATEGORY_LIST =
  'billingCategory/GET_BILLING_CATEGORY_LIST';
export const ADD_BILLING_CATEGORY = 'billingCategory/ADD_BILLING_CATEGORY';
export const UPDATE_BILLING_CATEGORY = 'billingCategory/ADD_BILLING_CATEGORY';
export const REMOVE_BILLING_CATEGORY = 'billingCategory/ADD_BILLING_CATEGORY';
export const SET_BILLING_CATEGORY_LIST_ORDER =
  'billingCategory/SET_BILLING_CATEGORY_LIST_ORDER';

export const [
  GET_BILLING_CATEGORY_LIST_IN_PROGRESS,
  GET_BILLING_CATEGORY_LIST_SUCCESS,
  GET_BILLING_CATEGORY_LIST_FAILURE
] = createActionTypeArray(GET_BILLING_CATEGORY_LIST);

export const [
  ADD_BILLING_CATEGORY_IN_PROGRESS,
  ADD_BILLING_CATEGORY_SUCCESS,
  ADD_BILLING_CATEGORY_FAILURE
] = createActionTypeArray(ADD_BILLING_CATEGORY);

export const [
  UPDATE_BILLING_CATEGORY_IN_PROGRESS,
  UPDATE_BILLING_CATEGORY_SUCCESS,
  UPDATE_BILLING_CATEGORY_FAILURE
] = createActionTypeArray(UPDATE_BILLING_CATEGORY);

export const [
  REMOVE_BILLING_CATEGORY_IN_PROGRESS,
  REMOVE_BILLING_CATEGORY_SUCCESS,
  REMOVE_BILLING_CATEGORY_FAILURE
] = createActionTypeArray(REMOVE_BILLING_CATEGORY);
