/* eslint-disable camelcase */
export default {
  diagnosis: {
    title: 'Diagnoza',
    emptyText: 'Ni podatka',
    mainDiagnosis: {
      title: 'Glavna diagnoza',
      enum: {
        rheumatoid_arthritis: 'Revmatoidni artritis',
        spondyloarthritis: 'Spondiloartritis'
      }
    },
    clinicalOutlook: {
      title: 'Vodilna klinična slika',
      enum: {
        predominantly_axial_spondylarthritis:
          'Pretežno aksialni spondiloartritis',
        predominantly_peripheral_spondylarthritis:
          'Pretežno periferni spondiloartritis',
        psoriatic_arthritis: 'Psoriatični artritis',
        enteropathic_arthritis: 'Enteropatski artritis',
        reactive_arthritis: 'Reaktivni artritis'
      }
    },
    symptomsOnset: {
      title: 'Začetek simptomov'
    },
    diagnosisDate: {
      title: 'Datum diagnoze'
    }
  },
  socialDiagnosis: {
    title: 'Socialna anamneza',
    maritalStatus: {
      title: 'Zakonski status',
      enum: {
        single: 'Samski',
        married: 'Poročen',
        divorced: 'Ločen',
        widowed: 'Vdovel'
      }
    },
    education: {
      title: 'Stopnja izobrazbe',
      enum: {
        noFormalEducation: 'Brez formalne izobrazbe',
        fourYearsOfPrimarySchool: '4 razredi osnovne šole',
        primarySchool: 'Osnovna šola',
        middleTradeSchool: 'Srednja strokovna šola',
        highSchool: 'Gimnazija',
        higherEducation: 'Višja izobrazba',
        universityEducation: 'Univerzitetna izobrazba',
        masterOfScience: 'Magister znanosti',
        doctorOfScience: 'Doktor znanosti'
      }
    },
    employment: {
      title: 'Zaposlitveni status',
      enum: {
        retired: 'Upokojen',
        employed: 'Zaposlen',
        selfEmployed: 'Samozaposlen',
        student: 'Šolar',
        dependant: 'Vzdrževan',
        unemployed: 'Nezaposlen'
      }
    },
    disabilityRetirement: 'Invalidska upokojitev',
    householdMembers: 'Število oseb v gospodinjstvu',
    yearOfRetirement: 'Leto upokojitve'
  }
};
