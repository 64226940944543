import analytics from './analytics';
import app from './app';
import auth from './auth';
import billing from './billing';
import booking from './booking';
import calendar from './calendar';
import category from './category';
import closedDates from './closed-dates';
import common from './common';
import communication from './communication';
import country from './country';
// CUSTOM
import rheumatology from './custom/rheumatology';
import dashboard from './dashboard';
import date from './date';
import dentalChart from './dentalChart';
import document from './document';
import download from './download';
import drug from './drug';
import encounter from './encounter';
import error from './error';
import eventLog from './eventLog';
import flow from './flow';
import layout from './layout';
import localization from './localization';
import location from './location';
import modal from './modal';
import notification from './notification';
import organizationSettings from './organizationSettings';
import patient from './patient';
import reminder from './reminder';
import rjsf from './rjsf';
import settings from './settings';
import sidebar from './sidebar';
import table from './table';
import tags from './tags';
import therapy from './therapy';
import user from './user';
import validation from './validation';
import widget from './widget';
import workingHours from './working-hours';

/* eslint sort-keys: "error" */
export default {
  analytics,
  app,
  auth,
  billing,
  booking,
  calendar,
  category,
  closedDates,
  common,
  communication,
  country,
  dashboard,
  date,
  dentalChart,
  document,
  download,
  drug,
  encounter,
  error,
  eventLog,
  flow,
  layout,
  localization,
  location,
  modal,
  notification,
  organizationSettings,
  patient,
  reminder,
  rheumatology,
  rjsf,
  settings,
  sidebar,
  table,
  tags,
  therapy,
  user,
  validation,
  widget,
  workingHours
};
