import { formatting } from './date';

export default {
  itemFinder: {
    title: '$t(common:addData)',
    searchLabel: 'Filtrirajte predmete',
    groups: {
      templates: 'Predloge'
    }
  },
  administrativeData: {
    flow: { title: 'Razlog za obisk' }
  },
  questionnaire: {
    title: 'Vprašalnik'
  },
  vitalSigns: {
    bodyMassIndex: {
      title: 'Indeks telesne mase',
      labels: {
        underweight: 'Nedohranjenost',
        normalWeight: 'Normalna telesna teža',
        overweight: 'Povišana telesna teža',
        obesity: 'Debelost'
      }
    }
  },
  saveAll: {
    invalidData: 'Nepopolni ali neveljavni podatki.'
  },
  calculationError: {
    calculationError: 'Napaka med izračunom.',
    noWidgetValueMessage:
      'Napaka: Podatek {{ property }} iz {{ widget }} manjka.',
    noFormValueMessage: 'Napaka: Podatek {{ property }} manjka.',
    noHistoryWidgetValueMessage: `Napaka: Podatek {{ property }} iz {{ widget }} na datum {{ date, ${formatting.longDate} }} manjka.`,
    noTherapyStartDateMessage:
      'Napaka: Manjka podatek o začetku terapije (id zdravila: {{ drugCategoryIDs }}).',
    noMainDiagnosis: 'Napaka: Manjka glavna diagnoza',
    noClinicalOutlook: 'Napaka: Manjka vodilna klinična slika'
  }
};
