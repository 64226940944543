import _ from 'lodash';
import moment from 'moment';

import { storeGetState } from '../../../configureStore';
import {
  CALENDAR_EVENT_DEFAULT_DURATION,
  EVENT_STATUS_ARRIVED,
  EVENT_STATUS_CANCELED,
  EVENT_STATUS_CONFIRMED,
  EVENT_STATUS_DELETED,
  EVENT_STATUS_NO_SHOW,
  EVENT_STATUS_RESERVATION,
  EVENT_STATUS_SCHEDULED,
  EVENT_TYPE_ENCOUNTER,
  EVENT_TYPE_OTHER,
  EVENT_TYPE_QUEUE,
  EVENT_TYPE_REMINDER,
  PREFILL_EVENT_TYPE_DASHBOARD_PREFILL,
  PREFILL_EVENT_TYPE_DUPLICATE,
  PREFILL_EVENT_TYPE_PATIENT_PREFILL,
  PREFILL_EVENT_TYPE_RESCHEDULE
} from '../../constants/calendarConstants';

import { selectCategoryNameById } from '../../../redux/calendar/category/categorySelectors';

import {
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_TYPE_APPOINTMENT_ADDED,
  NOTIFICATION_TYPE_CANCEL,
  NOTIFICATION_TYPE_FEEDBACK,
  NOTIFICATION_TYPE_REMINDER
} from '../../data/notifications';
import { getDateDifferenceDuration, humanizeDuration } from '../../date';
import { getFullName } from '../common-mappers';

// Filters out sms or email reminders if either user or organization has them disabled
export const filterEventReminders = (reminders, reminderConfig) => {
  const newAppointmentSmsEnabled = _.getNonEmpty(
    reminderConfig,
    'newAppointmentSmsEnabled',
    false
  );
  const reminderSmsEnabled = _.getNonEmpty(
    reminderConfig,
    'reminderSmsEnabled',
    false
  );

  const newAppointmentEmailEnabled = _.getNonEmpty(
    reminderConfig,
    'newAppointmentEmailEnabled',
    false
  );
  const reminderEmailEnabled = _.getNonEmpty(
    reminderConfig,
    'reminderEmailEnabled',
    false
  );
  const patientEmailEnabled = _.getNonEmpty(
    reminderConfig,
    'patientEmailEnabled',
    false
  );
  const patientSmsEnabled = _.getNonEmpty(
    reminderConfig,
    'patientSmsEnabled',
    false
  );
  const appointmentCanceledSmsEnabled = _.getNonEmpty(
    reminderConfig,
    'appointmentCanceledSmsEnabled',
    false
  );
  const appointmentCanceledEmailEnabled = _.getNonEmpty(
    reminderConfig,
    'appointmentCanceledEmailEnabled',
    false
  );

  const appointmentFeedbackEnabled = _.getNonEmpty(
    reminderConfig,
    'appointmentFeedbackEnabled',
    false
  );

  const isArrivedStatus = _.getNonEmpty(
    reminderConfig,
    'isArrivedStatus',
    false
  );

  const filtredReminders = _.filter(reminders, (reminder) => {
    switch (reminder.deliveryType) {
      case NOTIFICATION_DELIVERY_TYPE_EMAIL:
        if (
          patientEmailEnabled &&
          (reminderEmailEnabled ||
            newAppointmentEmailEnabled ||
            appointmentCanceledEmailEnabled)
        ) {
          /**
           * TODO feedback: set `appointmentFeedbackEnabled` when email template is done.
           * Until then we do not display email reminders.
           */
          return isReminderEnabled(
            reminder.type,
            newAppointmentEmailEnabled,
            reminderEmailEnabled,
            appointmentCanceledEmailEnabled,
            false
          );
        } else {
          return false;
        }

      case NOTIFICATION_DELIVERY_TYPE_SMS:
        if (patientSmsEnabled) {
          /**
           * we do not show the feedback reminder if the status is not "Arrived"
           */
          if (
            reminder.type === NOTIFICATION_TYPE_FEEDBACK &&
            !isArrivedStatus
          ) {
            return false;
          }

          return isReminderEnabled(
            reminder.type,
            newAppointmentSmsEnabled,
            reminderSmsEnabled,
            appointmentCanceledSmsEnabled,
            appointmentFeedbackEnabled
          );
        } else {
          return false;
        }
      default:
        return true;
    }
  });

  return filtredReminders;
};

/**
 * Function check is reminder for selected type is enabled
 * @param {String} reminderType - Type of reminder
 * @param {Boolean} newAppointment - New appointment reminder enabled
 * @param {Boolean} appointmentReminder - Appointment reminder enabled
 *
 * @returns {bool}
 */
export const isReminderEnabled = (
  reminderType,
  newAppointment,
  appointmentReminder,
  appointmentCanceledEnabled,
  appointmentFeedbackEnabled
) => {
  switch (reminderType) {
    case NOTIFICATION_TYPE_APPOINTMENT_ADDED:
      return newAppointment;
    case NOTIFICATION_TYPE_REMINDER:
      return appointmentReminder;
    case NOTIFICATION_TYPE_CANCEL:
      return appointmentCanceledEnabled;
    case NOTIFICATION_TYPE_FEEDBACK:
      return appointmentFeedbackEnabled;
    default:
      return true;
  }
};

export const getEventStatus = (event) => {
  const eventStatus = _.getNonEmpty(event, 'status', null);
  const isConfirmed = eventStatus === EVENT_STATUS_CONFIRMED;
  const isCanceled = eventStatus === EVENT_STATUS_CANCELED;
  const isReservation = eventStatus === EVENT_STATUS_RESERVATION;
  const isArrived = eventStatus === EVENT_STATUS_ARRIVED;
  const isNoShow = eventStatus === EVENT_STATUS_NO_SHOW;
  const isScheduled = eventStatus === EVENT_STATUS_SCHEDULED;
  const isDeleted = eventStatus === EVENT_STATUS_DELETED;
  const isAutomatedBooking = _.getNonEmpty(event, 'automatedBooking', false);

  return {
    isConfirmed,
    isCanceled,
    isReservation,
    isArrived,
    isNoShow,
    isScheduled,
    isDeleted,
    isAutomatedBooking
  };
};

export const getEventType = (event) => {
  const eventData = _.getNonEmpty(event, 'event.extendedProps', event);
  const eventType = _.getNonEmpty(eventData, 'type', null);
  const isEncounter = eventType === EVENT_TYPE_ENCOUNTER;
  const isOther = eventType === EVENT_TYPE_OTHER;
  const isReminder = eventType === EVENT_TYPE_REMINDER;
  const isQueue = eventType === EVENT_TYPE_QUEUE;

  return {
    isEncounter,
    isOther,
    isReminder,
    isQueue
  };
};

export const getEventTitle = (event) => {
  const { isEncounter, isQueue } = getEventType(event);
  const eventTitle = _.getNonEmpty(event, 'title', '');

  return isEncounter || isQueue ? getFullName(event.patient) : eventTitle;
};

export const getEventStartTime = (event) => {
  const isTypeQueue = event.type === EVENT_TYPE_QUEUE;

  return isTypeQueue ? moment().toDate() : event.from;
};

export const getEventEndTime = (event) => {
  const isTypeQueue = event.type === EVENT_TYPE_QUEUE;
  const isTypeReminder = event.type === EVENT_TYPE_REMINDER;

  /**
   *   Set the to 15 for reminder only because of the timeline view.
   *   If the end time is the same as start time it is displayed too small
   *   on timeline view
   */
  if (isTypeReminder) {
    return moment(event.from).add(15, 'minutes').toDate();
  }

  return isTypeQueue ? moment().toDate() : event.to;
};

export const getEventDuration = (event, returnAsString = false) => {
  const isTypeQueue = event.type === EVENT_TYPE_QUEUE;
  const eventDuration = _.getNonEmpty(event, 'duration', 30);
  const durationFromDifference = getDateDifferenceDuration(
    event.from,
    event.to
  ).asMinutes();

  if (isTypeQueue) {
    return returnAsString ? eventDuration.toString() : eventDuration;
  } else {
    return returnAsString
      ? durationFromDifference.toString()
      : durationFromDifference;
  }
};

export const mapEventForPopup = (event) => ({
  timeText: _.getNonEmpty(event, 'timeText', ''),
  title: _.getNonEmpty(event, 'event.title', ''),
  end: _.get(event, 'event.end'),
  start: _.get(event, 'event.start'),
  id: _.getNonEmpty(event, 'event.id'),
  ..._.getNonEmpty(event, 'event.extendedProps', {})
});
export const mapPrefilledEventData = (event, prefillType) => {
  const categoryId = _.getNonEmpty(event, 'categoryID', null);
  const eventDuration = _.getNonEmpty(
    event,
    'eventDuration',
    CALENDAR_EVENT_DEFAULT_DURATION
  );
  const assignees = _.getNonEmpty(event, 'assignees', []);

  const assigneeNames = _.map(assignees, (item) =>
    _.getNonEmpty(item, 'assignee.name', '/')
  ).join(', ');

  const prefillData = {
    eventDuration,
    categoryID: _.getNonEmpty(event, 'categoryID', null),
    patientID: _.getNonEmpty(event, 'patientID', null),
    patient: _.getNonEmpty(event, 'patient', {}),
    assigneeIDs: _.getNonEmpty(event, 'assigneeIDs', []),
    assignees: _.getNonEmpty(event, 'assignees', []),
    type: _.getNonEmpty(event, 'type'),
    title: _.getNonEmpty(event, 'title', ''),
    status: _.getNonEmpty(event, 'status', EVENT_STATUS_CONFIRMED),
    note: _.getNonEmpty(event, 'note', ''),
    categoryName: selectCategoryNameById(storeGetState(), categoryId),
    readableDuration: humanizeDuration(eventDuration),
    readableAssignees: assigneeNames
  };

  switch (prefillType) {
    case PREFILL_EVENT_TYPE_RESCHEDULE:
      return {
        ...prefillData,
        id: _.getNonEmpty(event, 'id'),
        createdAt: _.getNonEmpty(event, 'createdAt', null)
      };
    case PREFILL_EVENT_TYPE_DUPLICATE:
      return prefillData;
    case PREFILL_EVENT_TYPE_PATIENT_PREFILL:
      return {
        patientID: _.getNonEmpty(event, 'patientID', null),
        title: _.getNonEmpty(event, 'title', '')
      };
    case PREFILL_EVENT_TYPE_DASHBOARD_PREFILL:
      return {
        ...prefillData,
        sourceEncounterID: _.getNonEmpty(event, 'encounterId', undefined),
        type: EVENT_TYPE_ENCOUNTER
      };
    default:
      return {
        patientID: _.getNonEmpty(event, 'patientID', null),
        title: _.getNonEmpty(event, 'title', '')
      };
  }
};

export const getEventShortcutActionTranslationParams = (prefilledData) => ({
  title: _.getNonEmpty(prefilledData, 'title', '/'),
  readableDuration: _.getNonEmpty(prefilledData, 'readableDuration', '/'),
  categoryName: _.getNonEmpty(prefilledData, 'categoryName', '/'),
  assigneeName: _.getNonEmpty(prefilledData, 'readableAssignees', '/'),
  note: _.getNonEmpty(prefilledData, 'note', '/')
});
