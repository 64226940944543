import _ from 'lodash';

import { getBillTotalUnused } from '../../../../utils/billing';
import { removeEmptyTaxFromBillingItem } from '../../../../utils/mappers/tax-mappers';

import EntityBaseModel from '../../EntityBaseModel';
import LocalizedStringModel from '../../LocalizedStringModel';
import BillingItemModel from '../BillingItemModel';

class BillItemModel extends BillingItemModel {
  constructor(id, name = '') {
    super(id, name);

    this.quantity = 1;
    this.discount = 0;
    this.taxes = [];
    this.taxChanged = false;
    this.nameLocalized = new LocalizedStringModel();
    this.descriptionLocalized = new LocalizedStringModel();
    this.unitLocalized = new LocalizedStringModel();

    delete this.createdBy;
    delete this.createdAt;
    delete this.updatedAt;
  }

  static getPayload(model, defaultLocale) {
    let payload = { ...model };

    payload = EntityBaseModel.removeEntityMetadata(payload);

    payload.id = model.id;

    delete payload.unbilledCreatedAt;
    delete payload.unbilledCreatedBy;
    delete payload.unused;

    payload.discount = _.getNonEmpty(model, 'discount', 0);
    payload.total = model.total;
    payload.totalTax = model.taxAmount;
    payload.totalDiscount = model.totalDiscount;

    if (!_.isEmptySafe(defaultLocale)) {
      payload.nameLocalized[defaultLocale] = payload.name;
      payload.descriptionLocalized[defaultLocale] = payload.description;
    }

    return removeEmptyTaxFromBillingItem(payload);
  }

  static fromUnbilledItem(unbilledItem) {
    return _.nestedAssign(BillItemModel, {
      ...unbilledItem.billingItem,
      unbilledCreatedAt: unbilledItem.createdAt,
      unbilledCreatedBy: unbilledItem.createdBy,
      unbilledItemID: unbilledItem.id
    });
  }

  static fromBill(bill, billType, translate) {
    const unused = getBillTotalUnused(bill);

    return _.nestedAssign(BillItemModel, {
      id: `bill-${_.get(bill, 'number', '')}`,
      referencedDocument: {
        id: _.get(bill, 'id'),
        number: _.get(bill, 'number'),
        type: billType
      },
      unused,
      name: translate(`billing:${_.camelCase(billType)}s.basedOn`, {
        number: _.get(bill, 'number', '')
      }),
      quantity: -1,
      price: unused,
      priceType: 'net'
    });
  }

  get tax() {
    return super.tax;
  }

  set tax(value) {
    this.taxChanged = true;
    super.tax = value;
  }

  get taxRate() {
    let taxRate;

    /**
     * IMPORTANT: This is some edge case where we want to display old bill documents
     * in the same state as they were when created.
     * "this.taxes" is empty only when we are creating a new bill,
     * in which case we want to pull taxRates from our redux store.
     * Also "this.taxChanged" covers a case where user can edit old bills
     * without accidentally updating tax rate if it has a new value since this bill was created
     */
    if (_.isEmpty(this.taxes) || this.taxChanged) {
      taxRate = super.taxRate;
    } else {
      taxRate = _.first(this.taxes).rate;
    }

    return taxRate;
  }

  get priceWithQuantity() {
    const quantity = _.isFinite(this.quantity) ? this.quantity : 1;

    const rawPriceWithQuantity = this.price * quantity;

    return _.round(rawPriceWithQuantity, 4);
  }

  get taxAmount() {
    return this.getTaxAmount(this.priceWithDiscount);
  }

  get totalDiscount() {
    if (!_.isFinite(this.discount) || !_.isFinite(this.priceWithQuantity)) {
      return 0;
    }

    const rawTotalDiscount = (this.discount / 100) * this.priceWithQuantity;

    return _.round(rawTotalDiscount, 4);
  }

  get priceWithDiscount() {
    return this.priceWithQuantity - this.totalDiscount;
  }

  get total() {
    return this.priceWithDiscount + this.taxAmount;
  }

  set priceWithQuantity(value) {
    // This setter is present so that mappers don't fail
  }

  set taxAmount(value) {
    // This setter is present so that mappers don't fail
  }

  set totalDiscount(value) {
    // This setter is present so that mappers don't fail
  }

  set priceWithDiscount(value) {
    // This setter is present so that mappers don't fail
  }

  set total(value) {
    // This setter is present so that mappers don't fail
  }
}

export default BillItemModel;
