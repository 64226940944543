import handlebars from 'handlebars';
import _ from 'lodash';
import { Converter } from 'showdown';

import { getSafeTranslation } from '../utils/language';
import { safeGlobalGetState } from '../utils/redux/storeUtils';

const showdownConverter = new Converter();

// HELPERS
handlebars.registerHelper('equals', (arg1, arg2) => _.isEqual(arg1, arg2));
handlebars.registerHelper('notEquals', (arg1, arg2) => !_.isEqual(arg1, arg2));
handlebars.registerHelper('contains', (list, item) => _.includes(list, item));
handlebars.registerHelper('length', (list) => _.size(list));

handlebars.registerHelper('uppercase', (value) => _.toUpper(value));
handlebars.registerHelper('translate', (value) => getSafeTranslation(value));
handlebars.registerHelper('md2html', (value) =>
  showdownConverter.makeHtml(value)
);

handlebars.registerHelper('findEnum', (value, enumValueList, enumNameList) => {
  const foundIndex = _.findIndex(
    enumValueList,
    (enumValue) => enumValue === value
  );

  if (foundIndex < 0) {
    return '/';
  }

  return getSafeTranslation(enumNameList[foundIndex]);
});

handlebars.registerHelper('findIdInRedux', (id, path) => {
  const store = safeGlobalGetState();
  const list = _.getNonEmpty(store, path, []);

  return _.findDefault(list, { id }, {});
});

// PARTIALS
handlebars.registerPartial(
  'widgetExportStandard',
  '<p><strong>{{uppercase (translate schema.properties.id001.title) }}: </strong>{{{md2html data.id001 }}}</p>'
);
handlebars.registerPartial(
  'widgetExportCustomLabel',
  '<p><strong>{{uppercase (translate label) }}: </strong>{{{md2html data.id001 }}}</p>'
);
