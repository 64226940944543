import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsTextAreaFocused } from '../../redux/core/formik/formikSelectors';

import { registerEvents } from '../event';

/**
 * useDocumentEvent - A hook which helps you register events on document
 * @param {String|String[]} events - List of events or a single event
 * @param {Function} callback - Callback which will be called once the event fires
 * @param initialShouldPause
 */
export const useDocumentEvent = (
  events,
  eventKey,
  callback,
  initialShouldPause = false
) => {
  const shouldPauseState = useState(initialShouldPause);
  const [shouldPause] = shouldPauseState;

  const isTextAreaFocused = useSelector(selectIsTextAreaFocused);

  const modifiedCallback = useCallback(
    (e) => {
      /**
       * If the text area (FormControlTextAreaField) is focused then prevent any callbacks
       * There is a problem if we are focused in an textarea and press enter,
       * the form is submitted instead of breaking a line.
       */
      if (!shouldPause && !isTextAreaFocused && e.key === eventKey) {
        callback(e);
      }
    },
    [shouldPause, isTextAreaFocused, eventKey, callback]
  );

  useEffect(() => {
    registerEvents(document, events, modifiedCallback);

    return () => {
      // When component unmounts we need to unregister the events
      registerEvents(document, events, modifiedCallback, true);
    };
  }, [events, modifiedCallback]);

  return shouldPauseState;
};
