import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../utils/constants/tableConstants';
import { THERAPY_LIST_FILTER_SEARCH } from '../../utils/constants/therapyConstants';
import { isValidDate } from '../../utils/date';
import {
  createPatientTherapyMapper,
  mapTherapyIsActive,
  therapyMapper
} from '../../utils/mappers/cleaned/therapy-mappers';
import { selectProps } from '../../utils/redux/selectorHelpers';

import {
  selectCachedAndDeletedDrugList,
  selectCachedAndDeletedUserList
} from '../core/cache/cacheSelectors';

export const selectTherapyStore = (state) => state.therapy;

export const selectTherapyList = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.get(therapyStore, 'list', [])
);

export const selectTherapyListWithDrug = createSelector(
  [selectTherapyList, selectCachedAndDeletedDrugList],
  (therapyList, drugList) =>
    _.map(therapyList, (therapy) => {
      const foundDrug = _.find(drugList, { id: therapy.drugID });

      if (_.isEmpty(foundDrug)) {
        therapy.drug = {
          name: _.get(therapy, 'therapy', '/')
        };
      } else {
        therapy.drug = foundDrug;
      }

      return therapy;
    })
);

export const selectIsTherapyListLoading = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.get(therapyStore, 'loadingList', true)
);

export const selectIsTherapyDetailLoading = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.get(therapyStore, 'loadingDetail', true)
);

export const selectTherapyDetail = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.get(therapyStore, 'detail')
);

export const selectTherapyDetailWithDrug = createSelector(
  [selectTherapyDetail, selectCachedAndDeletedDrugList],
  (therapy, drugList) => {
    const foundDrug = _.find(drugList, { id: _.get(therapy, 'drugID') });

    if (!_.isEmpty(foundDrug)) {
      therapy.drug = foundDrug;
    }

    return therapy;
  }
);

export const selectTherapyDetailWithMappedData = createSelector(
  [selectTherapyDetailWithDrug],
  (therapy) => therapyMapper(therapy)
);

export const selectPatientActiveTherapyList = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.getNonEmpty(therapyStore, 'patientActiveTherapyList', [])
);

export const selectPatientPastTherapyList = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.getNonEmpty(therapyStore, 'patientPastTherapyList', [])
);

export const selectPatientTherapyList = createSelector(
  [selectTherapyStore],
  (therapyStore) => {
    const patientTherapyList = _.getNonEmpty(
      therapyStore,
      'patientTherapyList',
      []
    );

    const therapyListWithIsActive = _.map(
      patientTherapyList,
      mapTherapyIsActive
    );

    return _.orderBy(therapyListWithIsActive, 'isActive', ORDER_DESCENDING);
  }
);

export const selectPatientTherapyDetail = createSelector(
  [selectTherapyStore],
  (therapyStore) => {
    const patientTherapyDetail = _.getNonEmpty(
      therapyStore,
      'patientTherapyDetail',
      {}
    );

    return therapyMapper(patientTherapyDetail);
  }
);

export const selectPatientActiveTherapyListWithMappedData = createSelector(
  [
    selectPatientActiveTherapyList,
    selectCachedAndDeletedDrugList,
    selectCachedAndDeletedUserList
  ],
  (patientActiveTherapyList, drugList, allUsersList) => {
    const mapPatientTherapy = createPatientTherapyMapper(
      drugList,
      allUsersList
    );

    const mappedPatientTherapyList = patientActiveTherapyList.map(
      mapPatientTherapy
    );

    return _.orderBy(mappedPatientTherapyList, 'startsAt', ORDER_DESCENDING);
  }
);

export const selectPatientDrugIntakeListWithMappedData = createSelector(
  [
    selectPatientActiveTherapyListWithMappedData,
    selectCachedAndDeletedDrugList
  ],
  (patientActiveTherapy) => _.get(patientActiveTherapy, 'events', [])
);

export const selectPatientPastTherapyListWithMappedData = createSelector(
  [
    selectPatientPastTherapyList,
    selectCachedAndDeletedDrugList,
    selectCachedAndDeletedUserList
  ],
  (patientPastTherapyList, drugList, allUsersList) => {
    if (_.isEmpty(patientPastTherapyList)) {
      return patientPastTherapyList;
    }

    const mapPatientTherapy = createPatientTherapyMapper(
      drugList,
      allUsersList
    );

    const mappedPatientTherapyList = patientPastTherapyList.map(
      mapPatientTherapy
    );

    return _.orderBy(mappedPatientTherapyList, 'startsAt', ORDER_DESCENDING);
  }
);

export const selectPastAndActiveTherapyListWithMappedData = createSelector(
  [
    selectPatientPastTherapyListWithMappedData,
    selectPatientActiveTherapyListWithMappedData
  ],
  (pastTherapies, activeTherapies) => [...pastTherapies, ...activeTherapies]
);

export const selectPastAndActiveTherapyListBeforeDate = createSelector(
  [selectPastAndActiveTherapyListWithMappedData, selectProps],
  (therapies, date) => {
    if (!date) return therapies;

    return _.filter(therapies, (therapy) =>
      moment(therapy.startDate).isBefore(date)
    );
  }
);

export const selectPastTherapyListBeforeDate = createSelector(
  [selectPastAndActiveTherapyListWithMappedData, selectProps],
  (therapies, date) => {
    if (!date) return therapies;

    return _.filter(therapies, (therapy) =>
      moment(therapy.endDate).isBefore(date)
    );
  }
);

export const selectActiveTherapyListBeforeDate = createSelector(
  [selectPastAndActiveTherapyListWithMappedData, selectProps],
  (therapies, date) => {
    if (!date) return therapies;

    return _.filter(therapies, (therapy) => {
      const isStartInPast = moment(therapy.startDate).isBefore(date);
      const isEndInFuture = !moment(therapy.endDate).isBefore(date);

      return isStartInPast && isEndInFuture;
    });
  }
);

export const selectTherapyListFilter = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.get(therapyStore, 'filter', {})
);

export const selectTherapyListSort = createSelector(
  [selectTherapyListFilter],
  (filter) => ({
    sortBy: _.get(filter, 'sortBy', 'drug.name'),
    order: _.get(filter, 'order', ORDER_ASCENDING)
  })
);

export const selectTherapyListFilterSearch = createSelector(
  [selectTherapyListFilter],
  (therapyFilter) => _.get(therapyFilter, THERAPY_LIST_FILTER_SEARCH, '')
);

const isDurationObject = (durationObject) =>
  _.isPlainObject(durationObject) &&
  _.has(durationObject, 'scale') &&
  _.has(durationObject, 'value');

export const selectSortedTherapyList = createSelector(
  [
    selectTherapyListWithDrug,
    selectTherapyListSort,
    selectTherapyListFilterSearch
  ],
  (list, sortConfig, searchQuery) => {
    const { sortBy, order } = sortConfig;

    const sortedList = _.orderBy(
      list,
      (item) => {
        const itemField = _.getNonEmpty(item, sortBy, '');

        if (isDurationObject(itemField)) {
          return moment
            .duration(itemField.value, itemField.scale)
            .asMilliseconds();
        } else if (isValidDate(itemField)) {
          return moment(itemField).valueOf();
        }

        return itemField;
      },
      order
    );
    const filteredList = _.filter(sortedList, (item) => {
      const filteredFieldData = _.get(item, 'drug.name');

      return _.includes(_.toLower(filteredFieldData), _.toLower(searchQuery));
    });

    return filteredList;
  }
);

export const selectTherapyListPagination = createSelector(
  [selectTherapyStore, selectSortedTherapyList],
  (therapyStore, list) => {
    const pagination = _.get(therapyStore, 'pagination', {});
    const resultCount = list.length;
    const pageCount = _.ceil(resultCount / pagination.limit);

    return { ...pagination, resultCount, pageCount };
  }
);

export const selectMappedTherapyList = createSelector(
  [selectSortedTherapyList, selectTherapyListPagination],
  (list, pagination) => {
    const { limit, page } = pagination;

    const paginatedDrugList = _.slice(list, limit * page - limit, limit * page);

    return paginatedDrugList;
  }
);

export const selectFormSubmissionDetail = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.get(therapyStore, 'formSubmission', {})
);

export const selectTherapyRouteOfAdmission = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.getNonEmpty(therapyStore, 'routeOfAdmission', {})
);

export const selectTherapyRouteOfAdmissionList = createSelector(
  [selectTherapyRouteOfAdmission],
  (routeOfAdmission) => _.getNonEmpty(routeOfAdmission, 'list', [])
);

export const selectIsRouteOfAdmissionVisible = createSelector(
  [selectTherapyRouteOfAdmissionList],
  (routeOfAdmissionList) => !_.isEmpty(routeOfAdmissionList)
);

export const selectTherapyCancelationReason = createSelector(
  [selectTherapyStore],
  (therapyStore) => _.getNonEmpty(therapyStore, 'reasonForCancelation', {})
);

export const selectTherapyReasonForCancelationList = createSelector(
  [selectTherapyCancelationReason],
  (reasonForCancelation) => _.getNonEmpty(reasonForCancelation, 'list', [])
);

export const selectIsTherapyCancelationReasonListEmpty = createSelector(
  [selectTherapyReasonForCancelationList],
  (reasonForCancelationList) => _.isEmptySafe(reasonForCancelationList)
);

export const selectTherapyCancelationReasonById = createSelector(
  [selectTherapyReasonForCancelationList, selectProps],
  (reasonForCancelationList, reasonForCancelationId) =>
    _.findDefault(
      reasonForCancelationList,
      { id: reasonForCancelationId },
      null
    )
);
