export const formatting = {
  shortDate: 'M/D/YYYY',
  longDate: 'MMMM D, YYYY',
  time: 'HH:mm',
  shortDateWithTime: 'M/D/YYYY, HH:mm',
  longDateWithTime: 'MMMM Do YYYY, HH:mm',
  longDateWithWeekDay: 'dddd, D. MMMM YYYY',
  longDateWithWeekDayAndTime: 'dddd, D. MMMM YYYY, HH:mm',
  shortDateWithWeekDay: 'dddd, M/D/YYYY',
  datePicker: 'M/d/y'
};

export default {
  date: 'Date',
  dateFrame: 'Date range',
  string: '{{ month }}/{{ day }}/{{ year }}',
  stringFormat: 'MM/DD/YYYY',
  stringFormatReadable: 'MM/DD/YYYY',
  shortDate: `{{ date, ${formatting.shortDate} }}`,
  longDate: `{{ date, ${formatting.longDate} }}`,
  longDateWithTime: `{{ date, ${formatting.longDateWithTime} }}`,
  time: `{{ date, ${formatting.time} }}`,
  today: 'Today',
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
  rangeLessThen: {
    week: 'Less than a week',
    month: 'Less than a month',
    halfYear: 'Less than half a year',
    year: 'Less than a year'
  },
  rangeMoreThan: {
    week: 'More than a week',
    month: 'More than a month',
    halfYear: 'More than half a year',
    year: 'More than a year'
  },
  selectDay: 'Select the day:',
  selectMonth: 'Select the month:',
  selectYear: 'Select the year:',
  from: 'From',
  to: 'To',
  datePickerFormat: formatting.datePicker,
  shortDateWithTime: `{{ date, ${formatting.shortDateWithTime} }}`,
  longDateWithWeekDay: `{{ date, ${formatting.longDateWithWeekDay} }}`,
  longDateWithWeekDayAndTime: `{{ date, ${formatting.longDateWithWeekDayAndTime} }}`,
  shortDateWithWeekDay: `{{ date, ${formatting.shortDateWithWeekDay} }}`,
  dateTimeAndLocation: `{{ date, ${formatting.shortDate} }} · {{ fromTime, ${formatting.time} }} - {{ toTime, ${formatting.time} }} · {{ location }}`,
  prefixes: {
    at: 'At'
  },
  periodShortDate: `{{ from, ${formatting.shortDate} }} - {{ to, ${formatting.shortDate} }}`,
  dateRange: {
    startDate: 'Start',
    endDate: 'End',
    dateSelectShortcut: {
      today: 'Today',
      yesterday: 'Yesterday',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
      last90Days: 'Last 90 days',
      lastMonth: 'Last month',
      lastYear: 'Last year',
      weekToDate: 'Week to date',
      monthToDate: 'Month to date',
      quarterToDate: 'Quarter to date',
      yearToDate: 'Year to date',
      allTime: 'All time'
    }
  },
  abbreviation: {
    minutes: 'min',
    minutesWithValue: '{{minutes}} min'
  },
  placeholder: {
    day: 'dd',
    month: 'mm',
    year: 'yyyy'
  }
};
