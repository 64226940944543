import _ from 'lodash';
import moment from 'moment';

import { DELETED_WORKING_HOUR_TIME } from '../../constants/workingHoursConstants';
import { safeGlobalGetState } from '../../redux/storeUtils';

import { selectBookingTemplateNameById } from '../../../redux/booking/bookingSelectors';

import {
  MOSHI_COLOR_BLUE_200,
  MOSHI_COLOR_BLUE_700,
  MOSHI_COLOR_WHITE_NAME
} from '../../color';
import { getDayName } from '../../date';
import { mapEventAssignees } from '../calendar-mappers';

export const mapBookingScheduleEventsForCalendar = (events) => {
  let combinedEvents = [];

  _.forIn(events, (value, keyDay) => {
    if (!_.isEmptySafe(value)) {
      _.forEach(value, (schedule) => {
        combinedEvents = [
          ...combinedEvents,
          createBookingScheduleEvent(schedule, keyDay)
        ];
      });
    }
  });

  return _.compact(combinedEvents);
};

export const createBookingScheduleEvent = (schedule, keyDay) => {
  const scheduleEvent = _.cloneDeep(schedule);
  const fromDay = _.toLower(
    moment(scheduleEvent.validFrom).locale('en').format('dddd')
  );
  const keyDayName = _.toLower(getDayName(keyDay));
  const isSameDayInDaysAsFrom =
    !_.isEmptySafe(scheduleEvent, `days.${fromDay}`) && keyDayName === fromDay;
  /*
   * When we delete a booking schedule we set from/to = '00:00' and add it
   * using POST method. Schedules are not not deleted (with DELETE method),
   * they are added as new schedules with from/to set to '00:00'.
   */
  const isDeletedDay =
    _.getNonEmpty(scheduleEvent, `days.${fromDay}.0.from`) ===
      DELETED_WORKING_HOUR_TIME &&
    _.getNonEmpty(scheduleEvent, `days.${fromDay}.0.to`) ===
      DELETED_WORKING_HOUR_TIME;

  const templateName = selectBookingTemplateNameById(
    safeGlobalGetState(),
    scheduleEvent.templateID
  );

  if (!isSameDayInDaysAsFrom || isDeletedDay) {
    return null;
  }

  _.map(scheduleEvent.assignees.combinations, (combination) => {
    combination.primary = mapEventAssignees(combination.primary);
    combination.secondary = mapEventAssignees(combination.secondary);

    return combination;
  });

  scheduleEvent.hours = _.getNonEmpty(scheduleEvent, `days.${fromDay}`, []);
  scheduleEvent.start = moment(keyDay).startOf('day').toISOString();
  scheduleEvent.end = moment(keyDay).endOf('day').toISOString();
  scheduleEvent.resourceIds = [scheduleEvent.templateID];
  scheduleEvent.textColor = MOSHI_COLOR_BLUE_700;
  scheduleEvent.borderColor = MOSHI_COLOR_WHITE_NAME;
  scheduleEvent.backgroundColor = MOSHI_COLOR_BLUE_200;
  scheduleEvent.templateName = templateName;

  return scheduleEvent;
};
