/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Badge } from 'reactstrap';

import PropTypes from 'prop-types';

const BadgeWrapper = ({ children, ...props }) => (
  <Badge {...props}>{children}</Badge>
);

BadgeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  pill: PropTypes.bool,
  className: PropTypes.string
};

BadgeWrapper.defaultProps = {
  color: undefined,
  pill: undefined,
  className: undefined
};

export default BadgeWrapper;
