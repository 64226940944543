export default {
  title: 'Delovni čas',
  setWorkingHours: 'Nastavite delovni čas',
  addShift: '+ Dodajte izmeno',
  shiftStart: 'Začetek izmene',
  shiftEnd: 'Konec izmene',
  repeats: 'Ponavljanje',
  noRepeat: 'Ne ponavljaj',
  weekly: 'Tedensko',
  ongoing: 'Brez konca',
  specificDate: 'Točen datum',
  endRepeats: 'Konec ponavljanja',
  endDate: 'Končni datum',
  editModal: {
    title: 'Uredite delovni čas za {{assigneeName}}'
  },
  alert: {
    added: 'Delovni čas dodan',
    updated: 'Delovni čas posodobljen',
    removed: 'Delovni čas izbrisan'
  },
  removerWorkingHourMessage: 'Ali ste prepričani da želite odstraniti izmeno?',
  removerWorkingHour: 'Odstranite izmeno',
  repeatingShift: 'Ponavljajoča izmena',
  repeatingShiftEditInfo:
    'Uredili ste izmeno, ki se ponavlja. Urejanje te izmene bo prepisalo vse nadaljnje urnike {{assigneeType}} za dan <0>{{dayName}}</0>.',
  updateUpcomingShifts: 'Uredite vse prihodnje izmene',
  updateThisShiftOnly: 'Uredite samo to izmeno',
  deleteThisShiftOnly: 'Odstranite samo to izmeno',
  deleteUpcomingShifts: 'Odstranite vse prihodnje izmene',
  repeatingShiftDeleteInfo:
    'Odstranjujete izmeno, ki se ponavlja. Odstranitev te izmene bo prepisala vse nadaljnje urnike {{assigneeType}} za dan <0>{{dayName}}</0>.',
  eventOutsideOfWorkingHours: 'Dogodek je izven delovnega časa.',
  employee: 'zaposlenega',
  resource: 'resursa'
};
