import * as Sentry from '@sentry/browser';
import axios, { CancelToken } from 'axios';
import cookie from 'js-cookie';
import _ from 'lodash';

import config from './moshiConfig';

import { guid } from './gen';

export let cancelTokenSource = CancelToken.source();

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Session-ID'] = config.sessionId;
axios.defaults.headers.common.Accept = '*/*';

const NON_CANCELABLE_REQUESTS = ['/versions'];

const getCancelToken = () => {
  const wasCancelUsed = !_.isEmptySafe(cancelTokenSource);

  if (wasCancelUsed) {
    cancelTokenSource = CancelToken.source();
  }

  return cancelTokenSource.token;
};

axios.interceptors.request.use((axiosConfig) => {
  const token = localStorage.getItem('token');
  const url = _.getNonEmpty(axiosConfig, 'url');
  const transactionId = guid();

  const tokenCookie = cookie.get('token');

  if (!_.has(axiosConfig, 'withCredentials')) {
    axiosConfig.withCredentials =
      !_.isEmpty(tokenCookie) && _.isString(tokenCookie);
  }

  if (token) {
    axiosConfig.headers.Authorization = `JWT ${token}`;
  } else {
    delete axiosConfig.headers.Authorization;
  }

  if (
    !_.includesAny(url, NON_CANCELABLE_REQUESTS) &&
    _.isEmptySafe(axiosConfig, 'cancelToken')
  ) {
    axiosConfig.cancelToken = getCancelToken();
  }

  _.set(axiosConfig, 'headers.X-Transaction-ID', transactionId);
  Sentry.configureScope((scope) => {
    scope.setTag('transaction_id', transactionId);
  });

  return axiosConfig;
});
