import React from 'react';

import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 * The common ErrorBoundary component
 *
 * @example ./__examples__/ErrorBoundary.md
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { children } = this.props;
    const childComponent = _.get(
      children,
      'type.WrappedComponent.name',
      _.get(children, 'type.displayName', 'UKNOWN_COMPONENT')
    );

    Sentry.configureScope((scope) => {
      scope.setTag('component', childComponent);
      scope.setTag('source', 'ErrorBoundary');

      Sentry.captureException(error, { extra: errorInfo });
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <div className={'component-error'} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  /** Children component, displayed when there's no error  */
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
