import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { doSchemaPropertiesContainMeasurement } from '../../../../../utils/widgets';

import DefaultWidgetObjectTemplate from './DefaultWidgetObjectTemplate';
import WidgetFieldTemplateBase from './WidgetFieldTemplateBase';
import MeasurementWidgetObjectTemplate from './measurement/MeasurementWidgetObjectTemplate';

const getWidgetTemplateComponent = ({ schema }) => {
  const doPropertiesContainMeasurement = doSchemaPropertiesContainMeasurement(
    schema
  );

  if (!_.isEmptySafe(schema, 'measurement') || doPropertiesContainMeasurement) {
    return MeasurementWidgetObjectTemplate;
  } else {
    return DefaultWidgetObjectTemplate;
  }
};

const WidgetObjectTemplate = (props) => {
  const { schema, title } = props;

  const WidgetObjectTemplateComponent = getWidgetTemplateComponent(props);

  return (
    <WidgetFieldTemplateBase schema={schema} title={title}>
      <WidgetObjectTemplateComponent {...props} />
    </WidgetFieldTemplateBase>
  );
};

WidgetObjectTemplate.propTypes = {
  schema: PropTypes.shape().isRequired,
  title: PropTypes.string
};

WidgetObjectTemplate.defaultProps = {
  title: null,
  disabled: false
};

export default WidgetObjectTemplate;
