export const NOTIFICATION_DELIVERY_TYPE_SMS = 'sms';
export const NOTIFICATION_DELIVERY_TYPE_EMAIL = 'email';

export const NOTIFICATION_TYPE_APPOINTMENT_ADDED = 'addedNotifications';
export const NOTIFICATION_TYPE_FEEDBACK = 'feedback';
export const NOTIFICATION_TYPE_APPOINTMENT_UPDATED = 'updateNotification';
export const NOTIFICATION_TYPE_REMINDER = 'reminder';
export const NOTIFICATION_TYPE_CUSTOM = 'customMessage';
export const NOTIFICATION_TYPE_NO_SHOW = 'noShow';
export const NOTIFICATION_TYPE_RSVP = 'rsvp';
export const NOTIFICATION_TYPE_CANCEL = 'canceled';

export const NOTIFICATION_STATUS_PENDING = 'pending';
export const NOTIFICATION_STATUS_FAILED = 'failed';
export const NOTIFICATION_STATUS_COMPLETED = 'completed';
export const NOTIFICATION_STATUS_DELETED = 'deleted';

export const getPatientReminderConsent = (
  deliveryType,
  hasEmailConsent,
  hasSmsConsent
) => {
  if (deliveryType === NOTIFICATION_DELIVERY_TYPE_EMAIL) {
    return hasEmailConsent;
  } else if (deliveryType === NOTIFICATION_DELIVERY_TYPE_SMS) {
    return hasSmsConsent;
  }

  return true;
};
