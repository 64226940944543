import {
  NOTIFICATION_TYPE_INBOX_SMS,
  NOTIFICATION_TYPE_CALENDAR,
  NOTIFICATION_TYPE_PATIENTS_EXPORT,
  NOTIFICATION_TYPE_SMS
} from '../../utils/constants/core/notificationConstants';

import { formatting } from './date';

export default {
  [NOTIFICATION_TYPE_CALENDAR]: {
    title: {
      add:
        'Dodan termin za {{ data.patient.firstName }} {{ data.patient.lastName }}',
      confirmed:
        'Potrjen termin za {{ data.patient.firstName }} {{ data.patient.lastName }}',
      update:
        'Spremenjen termin za {{ data.patient.firstName }} {{ data.patient.lastName }}',
      cancel:
        'Preklican termin za {{ data.patient.firstName }} {{ data.patient.lastName }}',
      delete:
        'Odstranjen termin za {{ data.patient.firstName }} {{ data.patient.lastName }}'
    },
    subtitle: `{{ data.from, ${formatting.shortDateWithTime} }}`
  },
  [NOTIFICATION_TYPE_SMS]: {
    received: 'SMS prejet',
    subtitle: `{{ data.from, ${formatting.shortDateWithTime} }}`
  },
  [NOTIFICATION_TYPE_PATIENTS_EXPORT]: {
    title: {
      add: 'Izvoz pacientov pripravljen',
      failed: 'Izvoz pacientov ni uspel'
    },
    subtitle: 'Kliknite za prenos'
  },
  [NOTIFICATION_TYPE_INBOX_SMS]: {
    title: {
      received: 'Prejet SMS'
    }
  }
};
