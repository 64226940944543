import _ from 'lodash';
import * as Yup from 'yup';

import i18n from '../i18n';
import { DOCUMENT_FORM_FIELD_TYPES } from './constants/formConstants';
import { ROLE_DOCTOR } from './constants/userConstants';
import { safeGlobalGetState } from './redux/storeUtils';

import { selectCachedUserList } from '../redux/core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../redux/location/locationSelectors';
import { selectCurrentUser } from '../redux/user/userSelectors';

import { showAlertError } from './alert';
import logger from './logger';
import { filterUserByLocationAndRole } from './mappers/user-mappers';

const SIGNATURE_FIELD_ID = 'id001';

export const NEW_ID = 'new';
// TODO: Replace with EMPTY_GUID after beta
export const NO_ID = 'NO_ID';

export const getInputValue = (e) => _.getNonEmpty(e, 'target.value', '');

export const getCheckboxValue = (e) =>
  _.getNonEmpty(e, 'target.checked', false);

export const getTargetName = (e) => _.getNonEmpty(e, 'target.name');

export const getInputChange = (e) => {
  const value = getInputValue(e);
  const name = getTargetName(e);

  return {
    [name]: value
  };
};

export const getCheckboxChange = (e) => {
  const value = getCheckboxValue(e);
  const name = getTargetName(e);

  return {
    [name]: value
  };
};

export const isFieldPresentInSchema = (validationSchema, name) => {
  let exists = true;

  try {
    Yup.reach(validationSchema, name);
  } catch (e) {
    exists = false;
  }

  return exists;
};

export const isRequiredField = (formik, name) => {
  const validationSchema = formik.validationSchema;
  let isRequired = false;

  if (!isFieldPresentInSchema(validationSchema, name)) {
    return false;
  }

  try {
    // this is a Yup helper which does not need "fields" suffixes, but works similar to _.get()
    validationSchema.validateSyncAt(name, formik.values, { abortEarly: false });
  } catch (error) {
    if (_.includes(error.errors, 'validation:required')) {
      isRequired = true;
    }
  }

  return isRequired;
};

export const isFormControlInvalid = (
  errors,
  touched,
  submitCount,
  name,
  debug = false
) => {
  const errorField = _.getNonEmpty(errors, name, null);
  const wasTouched = _.getNonEmpty(touched, name, false);
  const wasSubmitted = submitCount > 0;

  const hasErrors = !_.isEmpty(errorField);

  const wasSubmittedOrTouched = wasTouched || wasSubmitted;

  if (debug) {
    logger.log('===============================');
    logger.log(`name: ${name}`);
    logger.log(`errors: ${JSON.stringify(errors, null, 2)}`);
    logger.log(`touched: ${JSON.stringify(touched, null, 2)}`);
    logger.log(`hasErrors: ${hasErrors}`);
    logger.log(`wasSubmitted: ${wasSubmitted}`);
    logger.log(`final: ${hasErrors && wasSubmittedOrTouched}`);
    logger.log('===============================');
  }

  return hasErrors && wasSubmittedOrTouched;
};

export const localizeDropdownItem = (dropdownItem) => ({
  id: dropdownItem.id,
  name: i18n.t(dropdownItem.name)
});

export const formDataKey = (name) => `formik.data.${name}`;
export const formValidKey = (name) => `formik.valid.${name}`;

export const clearFormKeys = (name) => {
  sessionStorage.removeItem(formDataKey(name));
  sessionStorage.removeItem(formValidKey(name));
};

const getConditionalDependency = (field) => {
  const conditionalOption = _.find(
    field.options,
    (option) => !_.isEmpty(option.conditionals)
  );
  let schemaDependencyValue = {};

  if (!_.isEmpty(conditionalOption)) {
    schemaDependencyValue = {
      [field.id]: {
        oneOf: field.options.map((option) => {
          const fieldSchema = {
            properties: {
              [field.id]: {
                enum: [option.id]
              }
            }
          };

          if (!_.isEmpty(option.conditionals)) {
            _.forEach(option.conditionals, (conditional) => {
              fieldSchema.properties[conditional.id] = {
                type: 'string',
                title: conditional.value
              };
            });
          }

          return fieldSchema;
        })
      }
    };
  }

  return schemaDependencyValue;
};

export const getFreeTextFieldSchema = (field) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    type: 'object',
    title: '',
    description: field.value
  },
  widgetPath: field.id,
  widgetValue: 'text'
});

export const getFreeTextQuestionFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    type: 'string',
    title: `${fieldCount}. ${field.value}`
  },
  widgetPath: field.id,
  widgetValue: 'text',
  requiredPath: field.required ? 'required' : ''
});

export const getLongFreeTextQuestionFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    type: 'string',
    title: `${fieldCount}. ${field.value}`
  },
  widgetPath: field.id,
  widgetValue: 'customTextareaAutosizeWidget',
  requiredPath: field.required ? 'required' : ''
});

export const getRadioQuestionFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.groupId}`,
  schemaValue: {
    title: '',
    type: 'object',
    properties: {
      [field.id]: {
        type: 'string',
        title: `${fieldCount}. ${field.value}`,
        anyOf: field.options.map((option) => ({
          type: 'string',
          title: option.value,
          enum: [option.id]
        }))
      }
    },
    dependencies: getConditionalDependency(field)
  },
  widgetPath: `${field.groupId}.${field.id}`,
  widgetValue: 'radio',
  requiredPath: field.required ? `properties.${field.groupId}.required` : ''
});

export const getCheckboxQuestionFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    title: `${fieldCount}. ${field.value}`,
    type: 'array',
    minItems: field.required ? 1 : undefined,
    uniqueItems: true,
    items: {
      type: 'string',
      enum: field.options.map((option) => option.id),
      enumNames: field.options.map((option) => option.value)
    }
  },
  widgetPath: field.id,
  widgetValue: 'checkboxes',
  requiredPath: field.required ? 'required' : ''
});

export const getScaleQuestionFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    type: 'number',
    title: `${fieldCount}. ${field.value}`,
    values: field.options.map((option, i) => ({
      label: option.value,
      value: i
    }))
  },
  widgetPath: field.id,
  widgetValue: 'customScaleWidget',
  requiredPath: field.required ? `required` : ''
});

export const getSignatureFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    type: 'string',
    title: `${fieldCount}. ${field.value}`,
    readonly: true
  },
  widgetPath: field.id,
  widgetValue: 'SignatureWidget',
  requiredPath: field.required ? 'required' : ''
});

export const getDateFieldSchema = (field, fieldCount) => ({
  schemaPath: `properties.${field.id}`,
  schemaValue: {
    type: 'string',
    title: `${fieldCount}. ${field.value}`,
    readonly: true
  },
  widgetPath: field.id,
  widgetValue: 'dateWidget',
  requiredPath: field.required ? 'required' : ''
});

export const getSectionFieldSchema = (field, fieldCount) => {
  const sectionObject = {
    ...field,
    title: field.value
  };
  const { schema, uiSchema } = getJsonSchemaFormData(sectionObject, fieldCount);

  return {
    schemaPath: `properties.${field.id}`,
    schemaValue: schema,
    uiSchemaPath: field.id,
    uiSchemaValue: { ...uiSchema, classNames: 'field-section' }
  };
};

export const getJsonSchemaFormData = (builtForm, fieldCount = 0) => {
  const schema = {
    type: 'object',
    title: builtForm.title,
    required: [],
    properties: {}
  };

  const uiSchema = {};
  let calculatedFieldCount = fieldCount;

  _.forEach(builtForm.fields, (field) => {
    let fieldSchema = {};

    if (
      !_.includes(
        [
          DOCUMENT_FORM_FIELD_TYPES.FREE_TEXT,
          DOCUMENT_FORM_FIELD_TYPES.SECTION
        ],
        field.type
      )
    ) {
      calculatedFieldCount++;
    }

    switch (field.type) {
      case DOCUMENT_FORM_FIELD_TYPES.FREE_TEXT:
        fieldSchema = getFreeTextFieldSchema(field);
        break;
      case DOCUMENT_FORM_FIELD_TYPES.QUESTION_FREE_TEXT:
        fieldSchema = getFreeTextQuestionFieldSchema(
          field,
          calculatedFieldCount
        );
        break;
      case DOCUMENT_FORM_FIELD_TYPES.QUESTION_FREE_TEXT_LONG:
        fieldSchema = getLongFreeTextQuestionFieldSchema(
          field,
          calculatedFieldCount
        );
        break;
      case DOCUMENT_FORM_FIELD_TYPES.QUESTION_SINGLE_OPTION:
        fieldSchema = getRadioQuestionFieldSchema(field, calculatedFieldCount);
        break;
      case DOCUMENT_FORM_FIELD_TYPES.QUESTION_MULTI_OPTION:
        fieldSchema = getCheckboxQuestionFieldSchema(
          field,
          calculatedFieldCount
        );
        break;
      case DOCUMENT_FORM_FIELD_TYPES.QUESTION_SCALE:
        fieldSchema = getScaleQuestionFieldSchema(field, calculatedFieldCount);
        break;
      case DOCUMENT_FORM_FIELD_TYPES.SIGNATURE:
        fieldSchema = getSignatureFieldSchema(field, calculatedFieldCount);
        break;
      case DOCUMENT_FORM_FIELD_TYPES.DATE:
        fieldSchema = getDateFieldSchema(field, calculatedFieldCount);
        break;
      case DOCUMENT_FORM_FIELD_TYPES.SECTION:
        fieldSchema = getSectionFieldSchema(field, calculatedFieldCount);
        break;
      default:
        throw new Error('Field not supported.');
    }

    _.set(schema, fieldSchema.schemaPath, fieldSchema.schemaValue);

    if (!_.isEmpty(fieldSchema.widgetPath)) {
      _.set(uiSchema, fieldSchema.widgetPath, {
        'ui:widget': fieldSchema.widgetValue
      });
    }

    if (!_.isEmpty(fieldSchema.uiSchemaPath)) {
      _.set(uiSchema, fieldSchema.uiSchemaPath, fieldSchema.uiSchemaValue);
    }

    if (!_.isEmpty(fieldSchema.requiredPath)) {
      const currentRequiredField = _.get(schema, fieldSchema.requiredPath, []);

      _.set(schema, fieldSchema.requiredPath, [
        ...currentRequiredField,
        field.id
      ]);
    }
  });

  return {
    schema,
    uiSchema
  };
};

export const jsonSchemaSelectValue = (value, selected, all) => {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));

  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
};

export const jsonSchemaDeselectValue = (value, selected) =>
  selected.filter((v) => v !== value);

export const handleBackendValidationErrors = (actions, values = null) => (
  error
) => {
  const errorData = _.get(error, 'response.data', {});
  const errorValue = _.get(errorData, errorData.type, []);

  if (_.isEmpty(actions)) {
    showAlertError('error.serverError');

    return;
  }

  if (_.isArray(errorValue)) {
    _.forEach(errorValue, (validationError) => {
      const key = validationError.key;
      const code = validationError.code;
      const splitKeys = key.split('.');
      const fieldName = splitKeys.pop();
      const basePath = splitKeys.join('.');

      if (_.has(values, basePath)) {
        actions.setFieldError(transformKeyName(key), `error:${key}.${code}`);
      } else {
        actions.setFieldError(
          transformKeyName(fieldName),
          `error:${key}.${code}`
        );
      }
    });
  }
};

const transformKeyName = (keyName) => {
  switch (keyName) {
    case 'slug':
      return 'organization';
    default:
      return keyName;
  }
};

export const createJsonBlob = (data) => {
  const jsonData = JSON.stringify(data);
  const jsonBlob = new Blob([jsonData], { type: 'text/json' });

  return jsonBlob;
};

export const createJsonBlobFormData = (data) => {
  const jsonBlob = createJsonBlob(data);

  const formData = new FormData();

  formData.append('file', jsonBlob);

  return formData;
};

export const addSignatureToDocument = (document, documentData) => {
  // skip when document or documentData are empty
  if (_.isEmpty(documentData) || _.isEmpty(document)) {
    return document;
  }

  const data = JSON.parse(documentData.data);

  // skip if signature is not present
  if (!_.has(data, SIGNATURE_FIELD_ID)) {
    return document;
  }

  document.formBuilderSchema.fields.push({
    id: SIGNATURE_FIELD_ID,
    type: DOCUMENT_FORM_FIELD_TYPES.SIGNATURE,
    value: i18n.t('document:inbound.fields.signature')
  });

  return document;
};

export const getDefaultDoctorId = (fallbackDoctorId) => {
  const currentUser = selectCurrentUser(safeGlobalGetState());
  const selectedLocationId = selectCurrentLocationId(safeGlobalGetState());
  const users = selectCachedUserList(safeGlobalGetState());
  const doctors = _.filter(
    users,
    filterUserByLocationAndRole(selectedLocationId, ROLE_DOCTOR)
  );

  const doctorRoleFilter = filterUserByLocationAndRole(
    selectedLocationId,
    ROLE_DOCTOR
  );
  const isDoctorOnCurrentLocation = doctorRoleFilter(currentUser);
  let doctorId = fallbackDoctorId;

  /*
   * If the logged in user is a doctor set him as the encounter doctor
   */
  if (isDoctorOnCurrentLocation) {
    doctorId = currentUser.id;
  } else if (doctors.length === 1) {
    // if there is only one doctor preselect him
    doctorId = _.first(doctors).id;
  }

  return doctorId;
};

export const focusInput = (shouldFocus, id) => {
  const input = document.getElementById(id);

  if (!_.isEmpty(input) && shouldFocus) {
    input.focus();
  }
};

export const getFieldValidationOptions = (schema, fieldName) => {
  const tests = Yup.reach(schema, fieldName).tests;

  return tests.map((test) => test.OPTIONS);
};

export const getValidationOptionValue = (options, optionName) => {
  const selectedOption = _.find(
    options,
    (option) => option.name === optionName
  );

  return _.get(selectedOption, `params.${optionName}`, undefined);
};

export default {
  getInputChange,
  isFormControlInvalid,
  localizeDropdownItem,
  formDataKey,
  formValidKey,
  getJsonSchemaFormData
};
