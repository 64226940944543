import _ from 'lodash';
import { extension } from 'mime-types';

import { translateKey } from '../i18n';

export const getFileName = (fileTranslationPath, fileType) => {
  let fileTranslationName = fileTranslationPath;
  let fileTranslationOptions;

  if (_.isPlainObject(fileTranslationPath)) {
    fileTranslationName = _.getNonEmpty(fileTranslationPath, 'name');
    fileTranslationOptions = _.getNonEmpty(fileTranslationPath, 'options');
  }

  const fileName = translateKey(fileTranslationName, fileTranslationOptions);

  const parsedFileName = _.isString(fileName)
    ? fileName.split(' ').join('-')
    : 'file-name';

  const fileExtension = extension(fileType);

  return `${parsedFileName}.${fileExtension}`;
};
