export const DOCUMENT_FORM_FIELD_TYPES = {
  FREE_TEXT: 1,
  QUESTION_FREE_TEXT: 2,
  QUESTION_FREE_TEXT_LONG: 3,
  QUESTION_SINGLE_OPTION: 4,
  QUESTION_MULTI_OPTION: 5,
  SIGNATURE: 6,
  GROUP: 7,
  DROPDOWN: 8,
  QUESTION_SCALE: 9,
  DATE: 10,
  SECTION: 11
};

export const DOCUMENT_FORM_FIELDS = [
  {
    id: DOCUMENT_FORM_FIELD_TYPES.FREE_TEXT,
    labelKeyCode: 'document:inbound.fields.freeText',
    icon: 'align-left'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.QUESTION_FREE_TEXT,
    labelKeyCode: 'document:inbound.fields.freeTextQuestion',
    icon: 'edit'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.QUESTION_FREE_TEXT_LONG,
    labelKeyCode: 'document:inbound.fields.longFreeTextQuestion',
    icon: 'new'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.QUESTION_MULTI_OPTION,
    labelKeyCode: 'document:inbound.fields.checkboxQuestion',
    icon: 'ok-circle'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.QUESTION_SINGLE_OPTION,
    labelKeyCode: 'document:inbound.fields.radioQuestion',
    icon: 'disc'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.QUESTION_SCALE,
    labelKeyCode: 'document:inbound.fields.scaleQuestion',
    icon: 'ellypsis'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.DATE,
    labelKeyCode: 'document:inbound.fields.dateQuestion',
    icon: 'calendar'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.SECTION,
    labelKeyCode: 'document:inbound.fields.section',
    icon: 'return'
  },
  {
    id: DOCUMENT_FORM_FIELD_TYPES.SIGNATURE,
    labelKeyCode: 'document:inbound.fields.signature',
    icon: 'edit'
  }
];

export const FORM_SUBMISSION_REQUEST_TYPES = {
  NEXT_CHECK_IN: 'nextCheckIn',
  SENT_TO_DEVICE: 'sentToDevice'
};

export const FORM_STATUSES = {
  SUBMITTED: 'submitted',
  REQUEST: 'request',
  TEMPLATE: 'template'
};

export const FORM_LIST_FILTER_DATE_FROM = 'dateFrom';
export const FORM_LIST_FILTER_DATE_TO = 'dateTo';
export const FORM_LIST_FILTER_USER = 'userId';
