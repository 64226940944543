import PropTypes from 'prop-types';

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

ConditionalWrap.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  wrap: PropTypes.func.isRequired
};

export default ConditionalWrap;
