export default {
  title: 'Online booking',
  eventType: {
    bookingCreated: 'Created',
    bookingCanceledByPatient: 'Canceled'
  },
  onlineBooking: {
    description:
      'Here are displayed all appointments created or canceled by patients via online booking.'
  }
};
