import { formatting } from './date';

export default {
  title: 'Obiskovalci',
  queue: 'V čakalnici',
  upNext: 'Naslednji',
  consultation: 'V obravnavi',
  completed: 'Zaključeno',
  addComment: 'Dodajte komentar',
  commentTitle: `{{ user.firstName }} {{ user.lastName }} ∙ {{ date, ${formatting.longDateWithTime} }}`,
  commentTitleToday: `{{ user.firstName }} {{ user.lastName }} ∙ {{ date, ${formatting.time} }}`,
  bucket: {
    default: 'Seznam čakajočih pacientov',
    normal: 'Seznam pacientov, ki bodo naslednji v obravnavi',
    consultation: 'Seznam pacientov v obravnavi',
    completed: 'Seznam pacientov z zaključeno obravnavo'
  },
  dropEncounterPlaceholder: 'Spustite tukaj',
  bookNewAppointment: 'Naročite',
  bookNewAppointmentMessage: 'Naročite na naslednji pregled',
  addVisitor: 'Dodajte obiskovalca',
  archiveAllEncounters: 'Arhivirajte vse',
  archiveEncounter: 'Arhivirajte',
  removeEncounterTitle: 'Odstranite obiskovalca iz seznama',
  removeEncounterMessage:
    'Ali resnično želite odstraniti obiskovalca iz seznama?',
  confirmStartEncounterTitle: 'Pričnite obravnavo',
  confirmStartEncounterMessage:
    'Ali resnično želite pričeti z obravnavo izbranega obiskovalca?',
  confirmArchiveClosedEncountersTitle: 'Arhivirajte obravnave',
  confirmArchiveClosedEncountersMessage:
    'Ali resnično želite arhivirati zaključene obravnave?',
  confirmArchiveEncounterTitle: 'Arhivirajte obravnavo',
  confirmArchiveEncounterMessage:
    'Ali resnično želite arhivirati obravnavo izbranega obiskovalca?',
  filterDoctors: 'Zdravniki',
  upcomingEvents: 'Prihajajoči termini',
  pastEvents: 'Pretekli termini',
  appointment: 'Termin: ',
  assistanceRequired: 'Pomoč pri prijavi',
  toWaitingRoom: 'V čakalnico',
  didNotShow: 'Ni prišel',
  backToUpcoming: 'Nazaj v "$t(dashboard:upcomingEvents)"',
  checkinAt: `Prijava:  {{ time, ${formatting.time} }}`,
  checkinAtDate: `Prijava: {{ time, ${formatting.shortDateWithTime} }}`,
  startedAt: `Začetek: {{ time, ${formatting.time} }}`,
  appointmentAt: `Termin: {{ timeFrom, ${formatting.time} }} - {{ timeTo, ${formatting.time} }}`,
  appointmentAtDate: `Termin: {{ timeFrom, ${formatting.shortDateWithTime} }} - {{ timeTo, ${formatting.time} }}`,
  completedAt: `Končano: {{ time, ${formatting.time} }}`,
  completedAtDate: `Končano: {{ time, ${formatting.shortDateWithTime} }}`,
  resolve: 'Končano',
  newAppointment: 'Nov termin',
  appointmentAlreadyBooked: 'Naslednji termin rezerviran'
};
