import {
  EXTERNAL,
  INBOUND_REQUEST,
  INBOUND_SUBMISSION,
  INBOUND_TEMPLATE,
  OUTBOUND_DOCUMENT,
  OUTBOUND_TEMPLATE
} from '../../utils/constants/documentConstants';

import { formatting } from './date';

export default {
  title: 'Documents',
  document: 'Document',
  version: `Version {{ version }} ∙ {{ date, ${formatting.longDateWithTime}}}`,
  new: 'New document',
  questionnaire: 'Questionnaire',
  flows: 'Flows',
  noFlows: 'Not used in any flows',
  templates: 'Templates',
  template: 'Template',
  fromTemplate: 'From template',
  newTemplate: 'New',
  createNew: 'Create new',
  createDocument: 'Create document',
  saveAsTemplate: 'Save as template',
  removeDocument: 'Remove document',
  removeTemplate: 'Remove template',
  removeFromQueue: 'Remove from queue',
  copyOf: 'Copy of "{{entity.name}}"',
  removeFromQueueMessage:
    'Are you sure you want to remove document <0>{{entity.name}}</0> from queue?',
  attachment: {
    title: 'Attachment',
    all: 'All attachments'
  },
  headerFooterPreviewLabel: 'Preview header and footer',
  headerPreviewTitle: 'Header and footer preview',
  inbound: {
    title: 'Inbound',
    description: 'Forms and questionnaires patients need to fill out and sign',
    submitted: 'Submitted',
    waiting: 'Waiting',
    templates: 'Templates',
    inboundEntity: 'Inbound document',
    inboundEntities: 'Inbound documents',
    submissionEntity: 'Document submission',
    requestEntity: 'Document submission request',
    templateEntity: 'Document template',
    fromTemplate: 'From template',
    newTemplate: 'New',
    createNew: 'Create new',
    createFromTemplate: 'Create document from template',
    createNewTemplate: 'Create new inbound document',
    searchTemplates: 'Search inbound templates',
    new: 'New document - $t(document:inbound.title)',
    addField: 'Add field',
    addFormField: 'Add form element',
    addSectionField: 'Add field within this section',
    addOption: 'Add option',
    addLabel: 'Add label',
    formPreview: 'Form preview',
    saveTemplate: 'Save template',
    submitRequest: 'Send',
    editDocument: 'Edit document',
    disabledPreview:
      'Questions are not constructed properly. Correct the errors before continuing.',
    disabledSubmitRequest:
      'Patient is not selected. Select a patient to send the document to.',
    isClinical: 'Can be part of an encounter',
    documentSettings: 'Document settings',
    removeTemplateTitle: 'Remove document template',
    confirmSubmissionRemovalMessage:
      "Are you sure you want to remove {{ entity.patient.firstName }} {{ entity.patient.lastName }}'s submission of the document <0>{{ itemName }}</0>?",
    confirmRequestRemovalMessage:
      // eslint-disable-next-line max-len
      'Are you sure you want to remove submission request of the document <0>{{ itemName }}</0> waiting for entry by {{ entity.patient.firstName }} {{ entity.patient.lastName }}?',
    confirmTemplateRemovalMessage:
      'Are you sure you want to remove the document template <0>{{ itemName }}</0>?',
    signatureField: 'Signature field',
    signHere: 'Sign here',
    version: 'Version',
    fields: {
      documentTitle: 'Document title',
      freeText: 'Text',
      freeTextPlaceholder: 'Enter free text',
      freeTextQuestion: 'Short answer',
      freeTextQuestionPlaceholder: 'Enter your question',
      longFreeTextQuestion: 'Long answer',
      checkboxQuestion: 'Checkboxes',
      checkboxOptionPlaceholder: 'Enter checkbox option',
      radioQuestion: 'Multiple choice',
      radioOptionPlaceholder: 'Enter radio option',
      addCheckbox: 'Add checkbox',
      addRadio: 'Add radio',
      addConditional: 'Add conditional question',
      conditionalQuestionPlaceholder: 'Enter your conditional question',
      scaleQuestion: 'Scale question',
      dateQuestion: 'Date question',
      section: 'Section',
      signature: 'Signature'
    },
    formStatuses: {
      submitted: 'Submitted',
      request: 'Waiting'
    },
    formStatusData: `{{ personName }} • {{ date, ${formatting.shortDateWithTime} }}`
  },
  outbound: {
    title: 'Outbound',
    outboundEntity: 'Outbound document',
    outboundEntities: 'Outbound documents',
    description: 'Documents for patients',
    issued: 'Issued',
    new: 'New outbound document',
    disabledPreview: 'Document required fields are missing',
    disabledHeaderPreview: 'You need to save the changes to view the preview',
    searchTemplates: 'Search outbound templates',
    createNewTemplate: 'Create new outbound document',
    includeHeaderAndFooter: 'Include header and footer template',
    pdfIsGenerating: 'PDF is generating...'
  },
  external: {
    title: 'External',
    externalEntity: 'External document',
    externalEntities: 'External documents',
    description: 'Uploaded files, images and various documents'
  },
  alert: {
    templateCreated: 'Document template was successfully created.',
    templateUpdated: 'Document template was successfully updated.',
    templateRemoved: 'Document template was successfully removed.',
    requestCreated: 'Document submission request was successfully created.',
    requestUpdated: 'Document submission request was successfully updated',
    requestRemoved: 'Document submission request was successfully removed',
    submissionCreated: 'Document submission was successfully created.',
    submissionUpdated: 'Document submission was successfully updated',
    submissionRemoved: 'Document submission was successfully removed',
    fileCreated: 'File was successfully added.',
    fileUpdated: 'File was successfully updated.',
    fileRemoved: 'File was successfully removed.',
    documentCreated: 'Document was successfully created.',
    documentUpdated: 'Document was successfully updated.',
    documentRemoved: 'Document was successfully removed.',
    documentPdfGenerationError:
      'Something went wrong while generating the PDF file.'
  },
  file: {
    title: 'File',
    entity: 'File',
    drop: 'Click or drop file here to upload it',
    upload: 'Upload file',
    uploading: 'Uploading file...'
  },
  preview: 'Preview',
  previewNotAvailable: 'Preview not available',
  columns: {
    documentName: 'Document name',
    documentType: 'Document type',
    patientName: 'Patient',
    createdBy: 'Created by',
    createdAt: 'Date created',
    updatedAt: 'Date modified',
    fileSize: 'Size'
  },
  modal: {
    title: 'Upload',
    descriptionText:
      'Maximum file size is 100 MB. You can upload PDF, Word, Excel, images or DICOM files.',
    descriptionTextDicomMode:
      'Maximum file size is 100 MB. You can upload DICOM files.',
    documentName: 'Document name',
    selectPatient: 'Select a patient',
    renameDocument: 'Rename document',
    duplicateTemplate: 'Duplicate template'
  },
  types: {
    [INBOUND_SUBMISSION]: 'Inbound document submitted',
    [INBOUND_REQUEST]: 'Inbound document in queue',
    [OUTBOUND_DOCUMENT]: 'Outbound document',
    [EXTERNAL]: 'External document',
    [INBOUND_TEMPLATE]: 'Inbound document template',
    [OUTBOUND_TEMPLATE]: 'Outbound document template',
    document: 'Document',
    questionnaire: 'Questionnaire',
    jpg: 'JPG',
    jpeg: 'JPEG',
    png: 'PNG',
    pdf: 'PDF',
    excel: 'Excel',
    word: 'Word'
  },
  pdfView: {
    page: 'Page',
    of: 'of'
  },
  documents: {
    title: 'Document',
    plural: 'Documents',
    add: 'Add document',
    edit: 'Edit document',
    new: 'New document',
    select: 'Select existing document.',
    choose: 'Choose a document',
    description: 'You can choose between existing documents.',
    remove: 'Remove document',
    removeDescription:
      'Are you sure you want to remove document: <0>{{ entity.name }}</0>?'
  },
  questionnaires: {
    title: 'Questionnaire',
    plural: 'Questionnaires',
    add: 'Add questionnaire',
    edit: 'Edit questionnaire',
    new: 'New questionnaire',
    select: 'Select existing questionnaire.',
    choose: 'Choose a questionnaire',
    description: 'You can choose between existing questionnaires.',
    remove: 'Remove questionnaire',
    removeDescription:
      'Are you sure you want to remove questionnaire: <0>{{ entity.name }}</0>?'
  },
  bottomBar: {
    saveTemplate: 'Save template',
    submitRequest: 'Send',
    editDocument: 'Edit document',
    saveAsNewTemplate: 'Save as new template'
  },
  smartcodes: {
    currentDate: 'Current date',
    documentTitle: 'Document title',
    patientFullName: "Patient's full name",
    patientFirstName: "Patient's first name",
    patientLastName: "Patient's last name",
    patientGender: "Patient's gender",
    patientDob: "Patient's date of birth",
    patientFullAddress: "Patient's full address",
    patientStreet: "Patient's street",
    patientZip: "Patient's zip code",
    patientCity: "Patient's city",
    patientCountry: "Patient's country",
    patientEmail: "Patient's email",
    patientPhoneNumber: "Patient's phone number",
    patientInsuranceNumber: "Patient's insurance number",
    patientNextAppointment: "Patient's next appointment date",
    patientLastAppointment: "Patient's last appointment date",
    organizationName: "Organization's name",
    organizationAddress: "Organization's address",
    organizationAddress2: "Organization's second address",
    organizationZip: "Organization's zip code",
    organizationCity: "Organization's city",
    organizationCountry: "Organization's country"
  }
};
