import React from 'react';

import PropTypes from 'prop-types';

// eslint-disable-next-line react/jsx-no-useless-fragment
export const EmptyComponent = () => <React.Fragment />;

/* eslint-disable react/require-default-props */
const ChildrenOnlyPropType = {
  children: PropTypes.node
};

const ChildrenDefaultPropType = {
  children: undefined
};

export const Main = ({ children, ...props }) => (
  <main {...props}>{children}</main>
);

Main.propTypes = ChildrenOnlyPropType;
Main.defaultProps = ChildrenDefaultPropType;

export const Span = ({ children, ...props }) => (
  <span {...props}>{children}</span>
);

Span.propTypes = ChildrenOnlyPropType;
Span.defaultProps = ChildrenDefaultPropType;

export const Div = ({ children, ...props }) => <div {...props}>{children}</div>;

Div.propTypes = ChildrenOnlyPropType;
Div.defaultProps = ChildrenDefaultPropType;

export const Bold = ({ children, ...props }) => <b {...props}>{children}</b>;

Bold.propTypes = ChildrenOnlyPropType;
Bold.defaultProps = ChildrenDefaultPropType;

export const Img = ({ alt, ...props }) => <img alt={alt} {...props} />;

Img.propTypes = {
  alt: PropTypes.string
};

Img.defaultProps = {
  alt: undefined
};

export const ClickableDiv = ({ children, ...props }) => (
  <div role={'button'} tabIndex={0} {...props}>
    {children}
  </div>
);

ClickableDiv.propTypes = ChildrenOnlyPropType;
