import _ from 'lodash';

import { MOSHI_FILE_TYPE_DICOM } from '../../constants/fileConstants';

export const listTypeDentalChart = 'dentalChart';
export const listTypeDicomFile = 'dicomFile';

export const mapDentalChartResponse = (list) =>
  list.map(({ widgetType, ...entry }) => {
    if (entry?.contentType === MOSHI_FILE_TYPE_DICOM) {
      const doctorID = _.getNonEmpty(entry, 'createdBy');
      const timestamp = _.getNonEmpty(entry, 'createdAt');

      return { ...entry, type: listTypeDicomFile, doctorID, timestamp };
    }

    const jsonEntryData = _.getNonEmpty(entry, 'data', '');
    const parsedEntryData = JSON.parse(jsonEntryData);

    const fileId = _.getNonEmpty(entry, 'id');

    return {
      ...entry,
      ...parsedEntryData,
      type: listTypeDentalChart,
      fileId
    };
  });

/**
 * This mapper returns an object where the key is the tooth number and value
 * is the positions array. i.e {18: [2,3,4], 20: [0,3,6]}. It takes
 * teeth[14,15] and positions[,7,8] and  creates {14 : [7,8], 15: [7,8]}
 * @param dentalChartListFiles
 * @returns {Object}
 */
export const teethAndPositionsMapper = (dentalChartListFiles) => {
  // Create [{tooth: number, positions: [number] }] from the dentalChartFiles;
  const teethAndPostionsArray = _.flatten(
    _.map(dentalChartListFiles, (chartFile) => {
      const teeth = _.keyBy(chartFile.teeth);

      const mappedTeeth = _.map(teeth, (tooth) => {
        /**
         * If the entry was for the whole tooth and no position was selected
         * add a 0 to positions so that the advanced grid will know to select the number
         * of the tooth.
         */

        if (_.isEmpty(chartFile.positions)) {
          return { tooth, positions: [0] };
        }

        return {
          tooth,
          positions: chartFile.positions
        };
      });

      return mappedTeeth;
    })
  );

  const filteredAndMergedTeethArray = [];

  // This loop looks for the items with the same key and merges its unique values
  _.forEach(teethAndPostionsArray, (itemInTeethAndPostionsArray) => {
    const existing = _.filter(
      filteredAndMergedTeethArray,
      (filteredItem) => filteredItem.tooth === itemInTeethAndPostionsArray.tooth
    );

    if (_.isEmpty(existing)) {
      filteredAndMergedTeethArray.push(itemInTeethAndPostionsArray);
    } else {
      const existingIndex = _.indexOf(
        filteredAndMergedTeethArray,
        _.first(existing)
      );

      filteredAndMergedTeethArray[existingIndex].positions = _.uniq(
        _.concat(
          filteredAndMergedTeethArray[existingIndex].positions,
          itemInTeethAndPostionsArray.positions
        )
      );
    }
  });

  // Group the array by tooth so that [tooth: 12, positions: [1,2]] becomes [{12:positions: [1,2]}]
  const groupedArrayByTooth = _.groupBy(filteredAndMergedTeethArray, 'tooth');

  // finally map and flatten the array so it becomes an object that looks like {teethNumber: [positions]]}
  const finalObject = _.forIn(groupedArrayByTooth, (toothGroup) => {
    const positions = _.map(toothGroup, (item) => item.positions);

    _.assign(toothGroup, _.flatten(positions));
  });

  return finalObject;
};

export const mapDentalChartTeethMetaData = (teethList) => {
  let teethMeta = {};

  if (!_.isArray(teethList)) {
    return teethMeta;
  }

  teethList.forEach((teeth) => {
    teethMeta = { ...teethMeta, [`tooth${teeth}`]: 'true' };
  });

  return teethMeta;
};

/*
 * Output example {"25":{"2":1,"6":1},"36":{"1":1,"2":1},"37":{"1":1,"2":2,"6":1}}
 * 25 represents teeth, "2" is side and 1 is count
 */
export const mapDentalChartSummaryData = (
  existingSummary,
  newEntry,
  prevEntry,
  mapAction
) => {
  let mappedSummary = existingSummary;

  switch (mapAction) {
    case 'add':
      mappedSummary = addToSummary(mappedSummary, newEntry);
      break;
    case 'update':
      mappedSummary = removeFromSummary(mappedSummary, prevEntry);
      mappedSummary = addToSummary(mappedSummary, newEntry);
      break;
    case 'remove':
      mappedSummary = removeFromSummary(mappedSummary, newEntry);
      break;
    default:
  }

  return mappedSummary;
};

const addToSummary = (summary, entry) => {
  let mappedSummary = { ...summary };

  const newEntryPositions = _.getNonEmpty(entry, 'positions', []);
  const newEntryTeeth = _.getNonEmpty(entry, 'teeth', []);

  newEntryTeeth.forEach((teeth) => {
    newEntryPositions.forEach((position) => {
      const existingTeethPositionEntry = _.getNonEmpty(
        mappedSummary,
        `${teeth}.${position}`,
        0
      );

      mappedSummary = _.setWith(
        mappedSummary,
        [teeth, position],
        existingTeethPositionEntry + 1,
        Object
      );
    });
  });

  return mappedSummary;
};

const removeFromSummary = (summary, entry) => {
  const mappedSummary = _.cloneDeep(summary);

  const newEntryPositions = _.getNonEmpty(entry, 'positions', []);
  const newEntryTeeth = _.getNonEmpty(entry, 'teeth', []);

  newEntryTeeth.forEach((teeth) => {
    newEntryPositions.forEach((position) => {
      const existingTeethPositionEntry = _.getNonEmpty(
        mappedSummary,
        [teeth, position],
        0
      );

      if (existingTeethPositionEntry - 1 <= 0) {
        _.unset(mappedSummary, [teeth, position]);
      } else {
        _.setWith(
          mappedSummary,
          [teeth, position],
          existingTeethPositionEntry + 1,
          Object
        );
      }
    });
  });

  return mappedSummary;
};
