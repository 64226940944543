import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_ID,
  hideAlertWithId,
  showAlertError,
  showAlertSuccess,
  showAlertWarning,
  showBottomPanelInfoAlert,
  showMissingLocationIdError,
  showMissingOrganizationIdError
} from '../../utils/alert';
import {
  getCalendarAssigneesUrl,
  getCalendarEventByIdUrl,
  getCalendarEventByWithNotifyIdUrl,
  getCalendarEventCancelByIdUrl,
  getCalendarEventRescheduleByUrlUrl,
  getCalendarEventUrl,
  getLocationUrl
} from '../../utils/api/apiUrlUtils';
import {
  CALENDAR_AUDIT_LOG,
  CALENDAR_SEARCH_RESULTS_LIMIT,
  EVENT_RESCHEDULE_DOCTOR_REQUESTED,
  EVENT_RESCHEDULE_OTHER,
  EVENT_RESCHEDULE_PATIENT_REQUESTED,
  EVENT_STATUS_CANCELED,
  EVENT_STATUS_CONFIRMED,
  EVENT_STATUS_NO_SHOW,
  EVENT_STATUS_RESERVATION,
  EVENT_STATUS_SCHEDULED,
  EVENT_TYPE_ENCOUNTER,
  EVENT_TYPE_OTHER,
  EVENT_TYPE_QUEUE,
  EVENT_TYPE_REMINDER,
  PREFILL_EVENT_TYPE_DUPLICATE,
  PREFILL_EVENT_TYPE_RESCHEDULE
} from '../../utils/constants/calendarConstants';
import { ORDER_DESCENDING } from '../../utils/constants/tableConstants';
import { mergeDateAndDateTime } from '../../utils/date';
import {
  mapPrefilledEventData,
  getEventShortcutActionTranslationParams,
  getEventType
} from '../../utils/mappers/cleaned/calendar-mappers';

import { navigateToCalendarDate } from '../core/router/routerActions';
import { updateSelectedLocation } from '../location/locationActions';
import { getPatientEventList } from '../patient/patientActions';

import {
  ADD_EVENT,
  CLEAR_CALENDAR_ASSIGNEES_LIST,
  CLEAR_EVENT_MESSAGES,
  CLEAR_EVENT_PATIENT,
  CLEAR_HIGHLIGHT_EVENT_ID,
  CLEAR_PREFILLED_EVENT_DATA,
  CLEAR_SEARCH_PAGINATION_AND_RESULTS,
  CLEAR_SEARCH_QUERY_PAGINATION_AND_RESULTS,
  CLEAR_SELECTED_CALENDAR_ASSIGNEES,
  GET_CALENDAR_ASSIGNEES,
  GET_CALENDAR_EVENT_WITH_QUERY,
  GET_CALENDAR_WAITING_LIST,
  GET_EVENT_MESSAGES,
  GET_EVENTS_WITH_QUERY,
  REMOVE_EVENT,
  REMOVE_EVENT_MESSAGE,
  REMOVE_SELECTED_CALENDAR_ASSIGNEE,
  SEND_CUSTOM_REMINDER,
  SET_CALENDAR_EVENT_COLOR_SOURCE,
  SET_CALENDAR_FILTER_SELECTED_DATE_RANGE,
  SET_CALENDAR_SCROLL_TIME,
  SET_CALENDAR_SELECTED_DATE_RANGE,
  SET_CALENDAR_TITLE,
  SET_CURRENT_CALENDAR_VIEW,
  SET_EVENT_PATIENT,
  SET_EVENT_QUERY_STRING,
  SET_HIGHLIGHT_EVENT_ID,
  SET_PREFILLED_EVENT_DATA,
  SET_SEARCH_RESULTS_LIST_PAGINATION,
  SET_SELECTED_CALENDAR_ASSIGNEES,
  SET_SELECTED_CALENDAR_CATEGORIES,
  SET_SELECTED_CALENDAR_SEARCH_ASSIGNEES,
  SET_SELECTED_CALENDAR_SEARCH_CATEGORIES,
  SET_SELECTED_CALENDAR_SEARCH_EVENT_STATUSES,
  SET_SELECTED_CALENDAR_STATUSES,
  SET_SLOT_DURATION,
  TOGGLE_CALENDAR_ASSIGNEES_LIST,
  TOGGLE_CALENDAR_CATEGORY_LIST,
  TOGGLE_CALENDAR_STATUS_LIST,
  UPDATE_AND_RESCHEDULE_EVENT,
  UPDATE_EVENT
} from './calendarTypes';

import {
  selectCurrentLocationId,
  selectLocationsWithUserAccess
} from '../location/locationSelectors';
import {
  selectAreCanceledEventsVisible,
  selectCalendarLocationAssigneeList,
  selectCalendarSelectedCategoryIds
} from './calendarSelectors';

export const prepareEventPayload = (eventData) => (dispatch, getState) => {
  /**
   * eventData.from/to has the hours and minutes of the event. eventData.date has
   * the date of event. The reason for this is when we are adding an event we
   * have a date picker and a time picker. Thats why eventData.from and eventData.date
   * do not necessarily have the same date. We extract the hours and minutes and
   * set them to the selected date in date picker before sending to API.
   */
  const date = _.getNonEmpty(eventData, 'date', eventData.from);
  const from = _.getNonEmpty(eventData, 'from');
  const to = _.getNonEmpty(eventData, 'to');

  const eventPayload = {
    from: mergeDateAndDateTime(date, from, false),
    to: mergeDateAndDateTime(date, to, false),
    patientID: _.get(eventData, 'patient.id'),
    categoryID: eventData.categoryID,
    assignees: _.intersectionBy(
      selectCalendarLocationAssigneeList(getState()),
      _.map(eventData.assigneeIDs, (id) => ({ id })),
      'id'
    ),
    note: eventData.note,
    type: eventData.type,
    title: eventData.title ? eventData.title : null,
    skipNotifications: eventData.skipNotifications,
    status: _.getNonEmpty(eventData, 'status'),
    notify: _.getNonEmpty(eventData, 'notify', false)
  };

  switch (eventData.type) {
    case EVENT_TYPE_QUEUE:
      delete eventPayload.from;
      delete eventPayload.to;
      eventPayload.duration = parseInt(eventData.eventDuration);
      eventPayload.priority = parseInt(eventData.priority);
      break;
    case EVENT_TYPE_REMINDER:
      eventPayload.to = eventPayload.from;
      eventPayload.confirmed = true;
      break;
    case EVENT_TYPE_OTHER:
      eventPayload.confirmed = true;
      break;
    default:
  }

  /**
   * If an event was created from the completed column on visitors dashboard, we add the encounter id
   */
  if (!_.isEmptySafe(eventData, 'sourceEncounterID')) {
    eventPayload.sourceEncounterID = eventData.sourceEncounterID;
  }

  return eventPayload;
};

const prepareScheduleTimePeriods = (eventData) => {
  const customReminders = _.getNonEmpty(eventData, 'customReminders', []);
  const schedule = [];

  _.forEach(customReminders, (reminderData) => {
    const fromTimeHour = moment(reminderData.sendTime).hours();
    const fromTimeMinutes = moment(reminderData.sendTime).minutes();
    let scheduledAt = moment(eventData.date).subtract(
      reminderData.numBefore,
      reminderData.timeUnit
    );
    const rsvpRequired = _.getNonEmpty(reminderData, 'rsvpRequired', false);

    if (reminderData.timeUnit !== 'hours') {
      scheduledAt = moment(scheduledAt).set({
        hour: fromTimeHour,
        minutes: fromTimeMinutes
      });
    }

    schedule.push({
      sendAt: scheduledAt.toISOString(),
      rsvpRequired
    });
  });

  return schedule;
};

const prepareReminderPayload = (eventData) => {
  const customReminders = _.getNonEmpty(eventData, 'customReminders', []);
  const payload = {};
  const patientHasEmailEnabled = _.getNonEmpty(
    customReminders,
    '[0]notifications.email',
    false
  );
  const patientHasSmsEnabled = _.getNonEmpty(
    customReminders,
    '[0]notifications.sms',
    false
  );

  payload.deliveryTypes = _.compact([
    patientHasSmsEnabled ? 'sms' : null,
    patientHasEmailEnabled ? 'email' : null
  ]);

  payload.schedule = prepareScheduleTimePeriods(eventData);

  return payload;
};

export const getEventsWithQuery = (
  queryObject = {},
  actionType = GET_EVENTS_WITH_QUERY,
  onSuccess = null
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const locationId = _.get(getState(), 'location.selectedLocation.id', null);

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/locations/${locationId}/calendar`,
      {
        params: queryObject
      }
    ),
    onSuccess: _.isFunction(onSuccess) ? onSuccess : _.noop
  });
};

export const getCalendarWaitingList = () => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const locationId = _.get(getState(), 'location.selectedLocation.id', null);

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  const queryObject = {
    types: EVENT_TYPE_QUEUE
  };

  return dispatch(getEventsWithQuery(queryObject, GET_CALENDAR_WAITING_LIST));
};

export const getCalendarAssigneesList = () => (dispatch, getState) => {
  const assigneesUrl = getCalendarAssigneesUrl(getState);

  return dispatch({
    type: GET_CALENDAR_ASSIGNEES,
    apiCall: axios.get(assigneesUrl)
  });
};

export const addEvent = (eventData) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const locationId = _.get(getState(), 'location.selectedLocation.id', null);

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  const eventPayload = dispatch(prepareEventPayload(eventData));

  return dispatch({
    type: ADD_EVENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/locations/${locationId}/calendar`,
      eventPayload
    ),
    onSuccess: (unused, response) => {
      if (eventData.type === EVENT_TYPE_QUEUE) {
        dispatch(getCalendarWaitingList());
      }
      /**
       * When an event was added, clear the prefilled data and hide the
       * alert.
       */
      dispatch(clearPrefilledEventData());

      /**
       * If user added custom reminders when creating an event (add reminder
       * button at the bottom of the reminders table)
       */
      if (!_.isEmptySafe(eventData, 'customReminders')) {
        const payload = prepareReminderPayload(eventData);
        const eventId = _.getNonEmpty(response, 'data.id', null);

        dispatch(sendReminderAt(payload, eventId));
      }
    }
  });
};

export const updateEventBase = (
  eventId,
  eventPayload,
  actionType = UPDATE_EVENT,
  onSuccess = undefined
) => (dispatch, getState) => {
  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const locationId = _.getNonEmpty(
    eventPayload,
    'locationID',
    fallbackLocationId
  );

  const notify = _.getNonEmpty(eventPayload, 'notify', false);
  const updateUrl = getCalendarEventByIdUrl(getState, locationId, eventId);
  const updateWithNotifyUrl = getCalendarEventByWithNotifyIdUrl(
    getState,
    locationId,
    eventId
  );

  const apiUrl = notify ? updateWithNotifyUrl : updateUrl;

  return dispatch({
    type: actionType,
    apiCall: axios.put(apiUrl, eventPayload),
    onSuccess
  });
};

export const updateOrRescheduleEvent = (eventData, modalData, updateState) => (
  dispatch
) => {
  const { isEncounter } = getEventType(eventData);
  const eventStatus = _.getNonEmpty(eventData, 'status');
  const isDateChanged = _.getNonEmpty(updateState, 'dateChanged', false);
  const isOtherDataChanged = _.getNonEmpty(
    updateState,
    'otherDataChanged',
    false
  );

  /*
   * If the event type is an encounter and has status scheduled or confirmed
   * we need to update or reschedule it based on data changed
   */
  if (
    isEncounter &&
    _.includes([EVENT_STATUS_SCHEDULED, EVENT_STATUS_CONFIRMED], eventStatus)
  ) {
    if (!isOtherDataChanged && isDateChanged) {
      return dispatch(rescheduleCalendarEvent(eventData, modalData));
    } else if (isOtherDataChanged && !isDateChanged) {
      return dispatch(updateEvent(eventData));
    } else if (isOtherDataChanged && isDateChanged) {
      return new Promise((resolve) => {
        dispatch(
          rescheduleCalendarEvent(
            eventData,
            modalData,
            UPDATE_AND_RESCHEDULE_EVENT
          )
        ).then(() => {
          dispatch(updateEvent(eventData)).then(() => {
            resolve();
          });
        });
      });
    }
  }

  return dispatch(updateEvent(eventData));
};

export const rescheduleCalendarEvent = (
  eventData,
  modalData,
  actionType = UPDATE_EVENT
) => (dispatch, getState) => {
  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const locationId = _.getNonEmpty(eventData, 'locationID', fallbackLocationId);
  const initiatorType = _.getNonEmpty(modalData, 'initiatorType');
  const rescheduleEventUrl = getCalendarEventRescheduleByUrlUrl(
    getState,
    locationId,
    eventData.id
  );

  let initiatorID;

  switch (initiatorType) {
    case EVENT_RESCHEDULE_PATIENT_REQUESTED:
      initiatorID = _.getNonEmpty(eventData, 'patientID');
      break;
    case EVENT_RESCHEDULE_DOCTOR_REQUESTED:
      const assignees = _.getNonEmpty(eventData, 'assignees', []);

      initiatorID = _.find(assignees, { type: 'user' })?.id;
      break;
    default:
  }

  const date = _.getNonEmpty(eventData, 'date');
  const from = _.getNonEmpty(eventData, 'from');
  const newFrom = mergeDateAndDateTime(date, from, false);

  return dispatch({
    type: actionType,
    apiCall: axios.put(rescheduleEventUrl, {
      cancelationData: {
        deleteEvent: _.includes(
          [EVENT_RESCHEDULE_DOCTOR_REQUESTED, EVENT_RESCHEDULE_OTHER],
          initiatorType
        ),
        initiatorType,
        initiatorID,
        note: _.getNonEmpty(modalData, 'note'),
        notify: _.getNonEmpty(modalData, 'notify', true)
      },
      newFrom
    }),
    onSuccess: () => {
      dispatch(clearPrefilledEventData());
    }
  });
};

export const updateEvent = (eventData, actionType = UPDATE_EVENT) => (
  dispatch
) => {
  const eventId = eventData.id;
  const eventPayload = dispatch(prepareEventPayload(eventData));

  return dispatch(
    updateEventBase(eventId, eventPayload, actionType, () => {
      dispatch(clearPrefilledEventData());

      /**
       * If user added custom reminders (add reminder button at the bottom of
       * the reminders table)
       */
      if (!_.isEmptySafe(eventData, 'customReminders')) {
        const payload = prepareReminderPayload(eventData);

        dispatch(sendReminderAt(payload, eventId));
      }
    })
  );
};

export const deleteEvent = (
  eventData,
  cancellationData = {},
  showSuccessAlert = true,
  refetchPatientEvents = false
) => (dispatch, getState) => {
  const notify = _.getNonEmpty(cancellationData, 'notify', false);

  const addToWaitingList = _.getNonEmpty(
    cancellationData,
    'addToWaitingList',
    false
  );

  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const locationId = _.getNonEmpty(eventData, 'locationID', fallbackLocationId);
  const eventId = _.getNonEmpty(eventData, 'id');

  const deleteUrl = getCalendarEventByIdUrl(getState, locationId, eventId);

  return dispatch({
    type: REMOVE_EVENT,
    apiCall: axios.delete(`${deleteUrl}?notify=${notify}`),
    onSuccess: () => {
      if (showSuccessAlert) {
        showAlertSuccess('calendar:appointmentRemoved');
      }
      if (eventData.type === EVENT_TYPE_QUEUE && !addToWaitingList) {
        dispatch(getCalendarWaitingList());
      }
      if (addToWaitingList) {
        dispatch(addEventToWaitingList(eventData, false, refetchPatientEvents));
      }
    }
  });
};

const prepareCancelOrDeleteEventPayload = (
  eventData,
  cancellationEventData
) => {
  const status = _.getNonEmpty(eventData, 'status');
  const notify = _.getNonEmpty(cancellationEventData, 'notify', true);
  const initiatorType = _.getNonEmpty(cancellationEventData, 'initiatorType');
  let initiatorID;

  switch (initiatorType) {
    case EVENT_RESCHEDULE_PATIENT_REQUESTED:
      initiatorID = _.getNonEmpty(eventData, 'patientID');
      break;
    case EVENT_RESCHEDULE_DOCTOR_REQUESTED:
      const assignees = _.getNonEmpty(eventData, 'assignees', []);

      initiatorID = _.find(assignees, { type: 'user' })?.id;
      break;
    default:
  }

  return {
    initiatorType,
    initiatorID,
    deleteEvent: _.includes(
      [EVENT_STATUS_RESERVATION, EVENT_STATUS_CANCELED],
      status
    ),
    note: _.getNonEmpty(cancellationEventData, 'note'),
    notify
  };
};

export const cancelOrDeleteCalendarEvent = (
  eventData,
  cancellationEventData,
  showSuccessAlert = true,
  refetchPatientEvents = false
) => (dispatch, getState) => {
  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const locationId = _.getNonEmpty(eventData, 'locationID', fallbackLocationId);
  const eventType = _.getNonEmpty(eventData, 'type');
  const reasonForDeletion = _.getNonEmpty(
    cancellationEventData,
    'initiatorType'
  );
  const addToWaitingList = _.getNonEmpty(
    cancellationEventData,
    'addToWaitingList',
    false
  );

  if (
    eventType !== EVENT_TYPE_ENCOUNTER ||
    reasonForDeletion === EVENT_RESCHEDULE_DOCTOR_REQUESTED
  ) {
    return dispatch(
      deleteEvent(eventData, cancellationEventData, true, refetchPatientEvents)
    );
  }

  const cancelCalendarEventUrl = getCalendarEventCancelByIdUrl(
    getState,
    locationId,
    eventData.id
  );
  const payload = prepareCancelOrDeleteEventPayload(
    eventData,
    cancellationEventData
  );

  return dispatch({
    type: REMOVE_EVENT,
    apiCall: axios.put(cancelCalendarEventUrl, payload),
    onSuccess: () => {
      if (showSuccessAlert) {
        showAlertSuccess('calendar:appointmentRemoved');
      }
      if (eventData.type === EVENT_TYPE_QUEUE && !addToWaitingList) {
        dispatch(getCalendarWaitingList());
      }
      if (addToWaitingList) {
        dispatch(addEventToWaitingList(eventData, false, refetchPatientEvents));
      }
    }
  });
};

export const markEventAsNoShow = (id, notify = false) => (dispatch) =>
  dispatch(updateEventBase(id, { notify, status: EVENT_STATUS_NO_SHOW }));

export const getEventMessages = (eventId, eventLocationId) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const locationId = eventLocationId || fallbackLocationId;

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: GET_EVENT_MESSAGES,
    apiCall: axios.get(
      `/organizations/${organizationId}/locations/${locationId}/calendar/${eventId}/messages`
    )
  });
};

export const removeEventMessage = (messageId, eventId, eventLocationId) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const locationId = eventLocationId || fallbackLocationId;

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: REMOVE_EVENT_MESSAGE,
    apiCall: axios.delete(
      `/organizations/${organizationId}/locations/${locationId}/calendar/${eventId}/messages`,
      {
        params: { messageID: messageId }
      }
    ),
    onSuccess: () => {
      showAlertSuccess('calendar:notifications.reminderRemoved');
      dispatch(getEventMessages(eventId, eventLocationId));
    }
  });
};

export const setCurrentCalendarView = (view) => (dispatch) =>
  dispatch({
    type: SET_CURRENT_CALENDAR_VIEW,
    view
  });

export const setSelectedAssignees = (assigneeIds) => (dispatch, getState) => {
  let safeAssigneeIdList = assigneeIds;
  const selectedlocation = selectCurrentLocationId(getState());

  if (!_.isArray(assigneeIds)) {
    safeAssigneeIdList = [assigneeIds];
  }

  return dispatch({
    type: SET_SELECTED_CALENDAR_ASSIGNEES,
    assigneeIds: _.compact(safeAssigneeIdList),
    locationId: selectedlocation
  });
};

export const removeSelectedAssignee = (assigneeId) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_CALENDAR_ASSIGNEE,
    assigneeId
  });
};

export const setSelectedCalendarSearchAssignees = (assigneeIds) => (
  dispatch
) => {
  let safeAssigneeIdList = assigneeIds;

  if (!_.isArray(assigneeIds)) {
    safeAssigneeIdList = [assigneeIds];
  }

  return dispatch({
    type: SET_SELECTED_CALENDAR_SEARCH_ASSIGNEES,
    assigneeIds: safeAssigneeIdList
  });
};

export const setSelectedCalendarSearchCategories = (categoryIds) => (
  dispatch
) => {
  let safeCategoryIdList = categoryIds;

  if (!_.isArray(categoryIds)) {
    safeCategoryIdList = [categoryIds];
  }

  return dispatch({
    type: SET_SELECTED_CALENDAR_SEARCH_CATEGORIES,
    categoryIds: safeCategoryIdList
  });
};

export const setSelectedCalendarSearchEventStatuses = (eventStatuses) => (
  dispatch
) => {
  let safeStatusList = eventStatuses;

  if (!_.isArray(eventStatuses)) {
    safeStatusList = [eventStatuses];
  }

  return dispatch({
    type: SET_SELECTED_CALENDAR_SEARCH_EVENT_STATUSES,
    eventStatuses: safeStatusList
  });
};

export const setSelectedDateRange = (fromDate, toDate) => (dispatch) =>
  dispatch({
    type: SET_CALENDAR_SELECTED_DATE_RANGE,
    fromDate,
    toDate
  });

export const setEventPatient = (data) => (dispatch) => {
  dispatch({
    type: SET_EVENT_PATIENT,
    data
  });
};

export const highlightEvent = (id) => (dispatch) =>
  dispatch({
    type: SET_HIGHLIGHT_EVENT_ID,
    id
  });

export const clearHighlightEvent = () => (dispatch) =>
  dispatch({
    type: CLEAR_HIGHLIGHT_EVENT_ID
  });

export const clearEventPatient = () => (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_PATIENT
  });
};

export const clearEventMessages = () => (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_MESSAGES
  });
};

export const setSlotDuration = (data) => (dispatch) => {
  dispatch({
    type: SET_SLOT_DURATION,
    data
  });
};

export const setCalendarScrollTime = (scrollTime) => (dispatch) => {
  dispatch({
    type: SET_CALENDAR_SCROLL_TIME,
    scrollTime
  });
};

export const setCalendarTitle = (title) => (dispatch) => {
  dispatch({
    type: SET_CALENDAR_TITLE,
    title
  });
};

export const setPrefilledEventData = (eventData, prefillType = null) => (
  dispatch
) => {
  dispatch({
    type: SET_PREFILLED_EVENT_DATA,
    data: { eventData, prefillType }
  });

  showBottomPanelInfoAlert(
    'calendar:alert.eventShortcutAction',
    () => {
      dispatch(clearPrefilledEventData());
    },
    getEventShortcutActionTranslationParams(eventData)
  );
};

export const setRescheduleEvent = (eventData) => (dispatch) => {
  const rescheduledEventData = mapPrefilledEventData(
    eventData,
    PREFILL_EVENT_TYPE_RESCHEDULE
  );

  dispatch(
    setPrefilledEventData(rescheduledEventData, PREFILL_EVENT_TYPE_RESCHEDULE)
  );
};

export const duplicateEvent = (eventData) => (dispatch) => {
  const rescheduledEventData = mapPrefilledEventData(
    { ...eventData, status: EVENT_STATUS_SCHEDULED },
    PREFILL_EVENT_TYPE_DUPLICATE
  );

  dispatch(
    setPrefilledEventData(rescheduledEventData, PREFILL_EVENT_TYPE_DUPLICATE)
  );
};

export const addEventToWaitingList = (
  event,
  deleteEventAfter = true,
  refetchPatientEvents = false
) => (dispatch) => {
  const eventData = _.cloneDeep(event);
  const patientId = _.getNonEmpty(event, 'patientID', null);

  _.set(eventData, 'type', EVENT_TYPE_QUEUE);
  _.set(eventData, 'status', EVENT_STATUS_RESERVATION);

  dispatch(addEvent(eventData)).then(() => {
    if (deleteEventAfter) {
      dispatch(deleteEvent(event, {}, false)).then(() => {
        showAlertSuccess('calendar:eventMovedToWaitingList');
        if (!_.isEmptySafe(patientId) && refetchPatientEvents) {
          dispatch(getPatientEventList(patientId, true));
        }
      });
    }
    if (!deleteEventAfter) {
      showAlertSuccess('calendar:eventMovedToWaitingList');
    }

    if (
      !_.isEmptySafe(patientId) &&
      refetchPatientEvents &&
      !deleteEventAfter
    ) {
      dispatch(getPatientEventList(patientId, true));
    }
  });
};

export const clearPrefilledEventData = () => (dispatch) => {
  hideAlertWithId(FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_ID);

  return dispatch({
    type: CLEAR_PREFILLED_EVENT_DATA
  });
};

export const setCalendarFilterDateRange = ({ fromDate, toDate }) => (
  dispatch
) =>
  dispatch({
    type: SET_CALENDAR_FILTER_SELECTED_DATE_RANGE,
    fromDate,
    toDate
  });

export const setCalendarEventColorSource = (color) => (dispatch) => {
  dispatch({
    type: SET_CALENDAR_EVENT_COLOR_SOURCE,
    color
  });
};

export const sendCustomReminder = (data, eventId, refetchEventMessages) => (
  dispatch,
  getState
) => {
  const locationUrl = getLocationUrl(getState);

  const payload = {
    deliveryTypes: data.deliveryTypes,
    message: _.isEmptySafe(data, 'message') ? null : data.message
  };

  return dispatch({
    type: SEND_CUSTOM_REMINDER,
    apiCall: axios.post(`${locationUrl}/calendar/${eventId}/messages`, payload),
    onSuccess: () => {
      if (refetchEventMessages) {
        dispatch(getEventMessages(eventId));
      }
      showAlertSuccess('calendar:alert.smsReminderSent');
    }
  });
};

export const sendReminderAt = (data, eventId) => (dispatch, getState) => {
  const locationUrl = getLocationUrl(getState);
  const schedule = _.getNonEmpty(data, 'schedule', null);
  const isPastReminder = _.some(schedule, (date) =>
    moment(date.sentAt).isBefore()
  );

  if (isPastReminder) {
    showAlertError('calendar:alert.reminderIsInPast');

    return;
  }

  const payload = {
    deliveryTypes: data.deliveryTypes,
    schedule
  };

  return dispatch({
    type: SEND_CUSTOM_REMINDER,
    apiCall: axios.post(`${locationUrl}/calendar/${eventId}/messages`, payload)
  });
};

export const setSearchResultsPagination = (paginationName, paginationValue) => (
  dispatch
) =>
  dispatch({
    type: SET_SEARCH_RESULTS_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

export const setEventQueryString = (queryString) => (dispatch) => {
  dispatch({
    type: SET_EVENT_QUERY_STRING,
    queryString
  });
};

export const clearSearchQueryAndPagination = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_QUERY_PAGINATION_AND_RESULTS
  });
};

export const clearSearchPaginationAndResults = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_PAGINATION_AND_RESULTS
  });
};

export const clearSelectedCalendarAssignees = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_CALENDAR_ASSIGNEES
  });
};

export const clearAssigneesList = () => (dispatch) =>
  dispatch({
    type: CLEAR_CALENDAR_ASSIGNEES_LIST
  });

export const toggleCalendarAssigneeList = (isToggled) => (dispatch) =>
  dispatch({
    type: TOGGLE_CALENDAR_ASSIGNEES_LIST,
    isToggled
  });

export const toggleCalendarCategoryList = (isToggled) => (dispatch) =>
  dispatch({
    type: TOGGLE_CALENDAR_CATEGORY_LIST,
    isToggled
  });

export const toggleCalendarStatusList = (isToggled) => (dispatch) =>
  dispatch({
    type: TOGGLE_CALENDAR_STATUS_LIST,
    isToggled
  });

export const calendarSearch = (queryString = '', page = 1, filter = {}) => (
  dispatch,
  getState
) => {
  if (!_.isEmpty(queryString) && queryString.length >= 3) {
    dispatch(setEventQueryString(queryString));
    dispatch(setSearchResultsPagination('page', page));
    const includeCanceled = selectAreCanceledEventsVisible(getState());

    return dispatch(
      getEventsWithQuery({
        includeCanceled,
        q: queryString,
        limit: CALENDAR_SEARCH_RESULTS_LIMIT,
        page,
        withPatientEvents: true,
        order: ORDER_DESCENDING,
        types: [
          EVENT_TYPE_REMINDER,
          EVENT_TYPE_OTHER,
          EVENT_TYPE_ENCOUNTER
        ].join(','),
        ...filter
      })
    );
  }
};

export const calendarSearchByPatient = (
  patientId,
  patientName = '',
  page = 1
) => (dispatch) => {
  if (!_.isEmpty(patientId)) {
    dispatch(setEventQueryString(patientName));
    dispatch(setSearchResultsPagination('page', page));

    return dispatch(
      getEventsWithQuery({
        patientID: patientId,
        limit: CALENDAR_SEARCH_RESULTS_LIMIT,
        page,
        order: ORDER_DESCENDING,
        types: [
          EVENT_TYPE_REMINDER,
          EVENT_TYPE_OTHER,
          EVENT_TYPE_ENCOUNTER
        ].join(',')
      })
    );
  }
};

export const setSelectedCalendarCategories = (categoryIds) => (dispatch) => {
  let safeCategoryIdList = categoryIds;

  if (!_.isArray(categoryIds)) {
    safeCategoryIdList = [categoryIds];
  }

  return dispatch({
    type: SET_SELECTED_CALENDAR_CATEGORIES,
    categoryIds: safeCategoryIdList
  });
};

export const setSelectedCalendarStatuses = (statusIds) => (dispatch) => {
  let safeStatusIdList = statusIds;

  if (!_.isArray(statusIds)) {
    safeStatusIdList = [statusIds];
  }

  return dispatch({
    type: SET_SELECTED_CALENDAR_STATUSES,
    statusIds: safeStatusIdList
  });
};

export const viewEventOnCalendar = (eventData) => (dispatch, getState) => {
  const userAccessLocations = selectLocationsWithUserAccess(getState());
  const selectedCalendarCategoryIds = selectCalendarSelectedCategoryIds(
    getState()
  );
  const currentLocationId = selectCurrentLocationId(getState());
  const eventLocationId = _.getNonEmpty(
    eventData,
    'locationID',
    currentLocationId
  );

  /**
   * If the user has access to events location he is redirected to the calendar
   * and the location is changed. Else he gets a warning.
   */
  if (currentLocationId !== eventLocationId) {
    const userAccessLocation = _.find(userAccessLocations, {
      id: eventLocationId
    });

    if (_.isEmptySafe(userAccessLocation)) {
      showAlertWarning('location:noAccessToEventsLocation');

      return;
    } else {
      dispatch(updateSelectedLocation(userAccessLocation));
    }
  }

  const date = moment(eventData.from).format('MM-DD-YYYY');

  dispatch(setCalendarScrollTime(eventData.from));

  if (!_.isEmptySafe(eventData, 'assigneeIDs')) {
    dispatch(setSelectedAssignees(eventData.assigneeIDs));
  } else if (!_.isEmptySafe(eventData, 'assignees')) {
    const assigneeIds = _.map(eventData.assignees, 'id');

    dispatch(setSelectedAssignees(assigneeIds));
  }
  if (!_.isEmptySafe(eventData, 'categoryID')) {
    dispatch(
      setSelectedCalendarCategories(
        _.pushIfUnique(selectedCalendarCategoryIds, eventData.categoryID)
      )
    );
  }

  dispatch(highlightEvent(eventData.id));
  dispatch(navigateToCalendarDate(date));
};

export const getCalendarEventWithAuditLog = (eventId) => (dispatch) => {
  if (!_.isEmpty(eventId)) {
    const queryObject = {
      include: CALENDAR_AUDIT_LOG
    };

    return dispatch(getCalendarEventWithQuery(eventId, queryObject));
  }
};

export const getCalendarEventWithQuery = (
  eventId,
  queryObject = {},
  actionType = GET_CALENDAR_EVENT_WITH_QUERY,
  onSuccess = _.noop
) => (dispatch, getState) => {
  const calendarEventUrl = getCalendarEventUrl(getState, eventId);

  return dispatch({
    type: actionType,
    apiCall: axios.get(calendarEventUrl, {
      params: queryObject
    }),
    onSuccess
  });
};
