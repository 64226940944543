export default {
  title: 'Uporabniki',
  employee: 'Uporabnik',
  selectEmployee: 'Izberite uporabnika',
  profileTitle: 'Uporabniški profil',
  detailTitle: 'Uredite uporabnika',
  profile: 'Račun',
  subscription: 'Naročnina',
  addUser: 'Dodajte uporabnika',
  creatingUser: 'Dodajanje uporabnika',
  avatar: 'Slika uporabnika',
  security: 'Varnost',
  changePassword: 'Spremenite geslo',
  removeUserTitle: 'Odstranite uporabnika',
  removeUserMessage: 'Odstranite uporabnika <0>{{ userFullName }}</0>?',
  removeUserWarning:
    '<0>Opozorilo:</0> če ima uporabnik v prihodnosti naročene termine na koledarju jih najprej prestavite na drugega uporabnika.',
  resendVerificationEmail: 'Ponovno pošljite potrditveni email',
  resendConfirmation:
    'Nov potrditveni email je bil poslan na naslov: {{ email }}.',
  list: {
    title: 'Seznam uporabnikov',
    addUser: '$t(user:addUser)',
    filters: 'Filtri:',
    sortBy: 'Razvrstite:',
    roleFilter: 'Vloga: {{ roleName }}',
    locationFilter: 'Lokacija: {{ locationName }}',
    role: 'Vloga'
  },
  roles: {
    title: 'Uporabniške pravice',
    add: '+ Dodajte vlogo',
    greetingText:
      'Glede na dodeljeno vlogo lahko uporabniki dostopajo do različnih sklopov aplikacije {{organizationName}}.'
  },
  createPreview: {
    message:
      'Elektronsko sporočilo z navodili za aktivacijo uporabniškega računa bo poslano na {{ email }}',
    summary: 'Povzetek',
    summaryMessage: 'Ustvarjen bo uporabnik z naslednjimi podatki:'
  },
  validation: {
    userRightsLength: 'Uporabniku mora biti dodeljena vsaj 1 vloga.',
    duplicatedUserRight: 'Na se seznamu imate podvojeno uporabniško pravico.',
    userNotActivated: 'Nedokončana registracija',
    pendingEmailConfirmation: 'Nepotrjen elektronski naslov'
  },
  changePasswordModal: {
    changeSuccess: 'Sprememba gesla je bila uspešna.',
    changeSuccessText:
      'Uspešno ste spremenili svoje geslo. Uporabite ga lahko ob naslednji prijavi!',
    currentPassword: 'Trenutno geslo',
    newPassword: 'Novo geslo',
    retypeNewPassword: 'Ponovno vnesite novo geslo.',
    strengthLabel: 'Moč gesla: <0>{{ strength }}</0>',
    strengths: {
      veryLow: 'Zelo nizka',
      low: 'Nizka',
      medium: 'Srednja',
      high: 'Visoka',
      veryHigh: 'Zelo visoka'
    }
  },
  preferences: {
    title: 'Nastavitve',
    language: 'Jezik',
    regionalFormatting: 'Regijske nastavitve'
  },
  fiscalizationSection: {
    personalTaxNumber: 'Osebna davčna številka',
    displayName: 'Ime (prikazano na računih)'
  },
  role: {
    title: 'Vloga',
    isOrganizationAdmin: 'Administrator organizacije',
    hasCalendar: 'Ima svoj koledar',
    locationPatientAccess: 'Omejitev dostopa do pacientov na podlagi lokacije',
    locationPatientAccessTooltip:
      'Omejitev dostopa le do tistih pacientov, ki spadajo pod lokacije, do katerih ima uporabnik dostop. ' +
      'Ko je omejitev izklopljena, ima vsak uporabnik dostop do vseh pacientov, ne glede na njihovo dodeljeno lokacijo.'
  },
  alerts: {
    userCreated: 'Uporabnik je bil uspešno ustvarjen.',
    userUpdated: 'Uporabnik je bil uspešno posodobljen.',
    userRemoved: 'Uporabnik je bil uspešno izbrisan.',
    resendEmail: 'Potrditveni email je bil uspešno poslan.'
  },
  reassignCalendarEvents: 'Prestavite termine na koledarju',
  reassignEvents: 'Prestavite termine',
  reassignCalendarEventsModal: {
    title: 'Prestavite termine na koledarju',
    note:
      'Vsi dogodki na koledarju (termini, dogodki, opomniki) znotraj izbranega časovnega okvirja, bodo prenešeni na izbranega uporabnika.',
    submitButton: 'Prestavite',
    allEvents: 'Vse termine (vključno s preteklimi)',
    onSuccess: 'Termini na koledarju so bili uspešno prestavljeni.'
  }
};
