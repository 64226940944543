import produce from 'immer';
import _ from 'lodash';

import { ORDER_ASCENDING } from '../../../utils/constants/tableConstants';
import { mapAndOrderCategories } from '../../../utils/mappers/calendar-mappers';

import {
  GET_BILLING_CATEGORY_LIST_FAILURE,
  GET_BILLING_CATEGORY_LIST_IN_PROGRESS,
  GET_BILLING_CATEGORY_LIST_SUCCESS,
  SET_BILLING_CATEGORY_LIST_ORDER
} from './billingCategoryTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  list: [],
  loadingList: false,
  filter: { sortBy: 'name', order: ORDER_ASCENDING }
};

// eslint-disable-next-line default-param-last
const billingCategoryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_BILLING_CATEGORY_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_BILLING_CATEGORY_LIST_SUCCESS:
        const billingCategoryList = _.get(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = mapAndOrderCategories(billingCategoryList);
        break;
      case GET_BILLING_CATEGORY_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case SET_BILLING_CATEGORY_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.get(payload, 'order', ORDER_ASCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default billingCategoryReducer;
