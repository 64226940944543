import {
  PATIENT_SEVERITY_MILD,
  PATIENT_SEVERITY_MODERATE,
  PATIENT_SEVERITY_SEVERE,
  PATIENT_SEVERITY_UNKNOWN
} from '../../utils/constants/patientConstants';

import { formatting } from './date';

export default {
  entity: 'Pacienta',
  title: 'Pacient',
  deletePatient: 'Izbrišite pacienta',
  listTitle: 'Pacienti',
  firstNameLastName: 'Ime in priimek',
  lastNameFirstName: 'Priimek in ime',
  phoneNumber: 'Telefon',
  addModal: {
    addPatient: 'Dodajte pacienta',
    createPatient: 'Ustvari novega pacienta'
  },
  removeModal: {
    doctorNotAssigned: 'Pacient nima izbranega zdravnika.',
    title: 'Izbrišite pacienta',
    reason: 'Razlog',
    reasons: {
      first: 'Prvi razlog',
      second: 'Drugi razlog',
      third: 'Tretji razlog'
    },
    reasonDropdownPlaceholder: 'Izberite razlog'
  },
  createPreview: {
    summary: 'Povzetek',
    summaryMessage: 'Ustvarjen bo pacient z naslednjimi podatki:'
  },
  detail: {
    documents: 'Dokumenti',
    personalInfo: {
      title: 'Osebni podatki',
      identification: {
        dateOfBirth: 'Datum rojstva',
        doctorInformation: 'Informacije o zdravniku',
        doctor: '$t(patient:doctor.title)',
        doctorPlaceholder: 'Izberite zdravnika',
        insuranceId: 'Številka zavarovanja',
        shortId: 'Zaporedna številka',
        otherInfo: 'Ostali podatki'
      }
    },
    miniCardInfo: `{{ dateOfBirth, ${formatting.longDate} }} · {{ age }} {{ unit }} · {{ gender }}`
  },
  list: {
    lastVisitFilter: 'Zadnji obisk',
    timeFromLastVisitFilter: 'Čas od zadnjega obiska',
    doctorFilter: '$t(patient:doctor.title)',
    dateOfBirthAndAge: 'Datum rojstva · Starost',
    dateAndDifferenceValue: `{{ date, ${formatting.longDate}}} · {{count}}{{unit}}`,
    lastVisitAndPeriod: 'Zadnji obisk · Obdobje',
    sortBy: 'Razvrstite glede na:',
    lastVisitOrder: 'Zadnji obisk',
    columnNames: {
      therapyStatus: '$t(therapy:activeTherapyTile.therapyStatus)',
      drug: '$t(drug:singleTitle)',
      lastIntake: 'Zadnji vnos',
      nextIntake: 'Naslednji vnos',
      daysInTherapy: 'Dni v terapiji'
    },
    searchFieldTooltip:
      'Iščite po imenu, priimku, telefonski številki, elektronskem naslovu, številki zavarovanja ali zaporedni številki.',
    emptyStateText: `
        <0>Tukaj ni ničesar.</0>
        <1></1>
        <2>Ali pa izboljšate iskalni niz in iščite po imenu, priimku, telefonski številki, elektronskem naslovu, številki zavarovanja ali zaporedni številki.</2>
        `
  },
  state: {
    severity: {
      title: 'Resnost',
      [PATIENT_SEVERITY_MILD]: 'Blaga',
      [PATIENT_SEVERITY_MODERATE]: 'Zmerna',
      [PATIENT_SEVERITY_SEVERE]: 'Težka',
      [PATIENT_SEVERITY_UNKNOWN]: 'Neznana'
    },
    dateOfOnset: 'Datum nastopa'
  },
  anamnesis: {
    title: 'Splošna anamneza',
    noPinnedItems:
      'Ni pripetih podatkov, pripnete jih lahko v Splošni anamnezi pod Zdravstveno kartoteko.',
    medicalCondition: {
      title: 'Kronične bolezni',
      removeTitle: 'Kronično bolezen',
      field: 'Kronična bolezen',
      add: '+ dodajte kronično bolezen'
    },
    concomitantDrug: {
      title: 'Konkomitantna zdravila',
      removeTitle: '$t(patient:anamnesis.concomitantDrug.field)',
      field: 'Konkomitantno zdravilo',
      add: '+ dodajte konkomitantno zdravilo'
    },
    allergy: {
      title: 'Alergije',
      removeTitle: 'Alergijo',
      field: 'Alergija',
      add: '+ dodajte alergijo'
    },
    vaccination: {
      title: 'Cepiva',
      removeTitle: '$t(patient:anamnesis.vaccination.field)',
      field: 'Cepivo',
      add: '+ dodajte cepivo'
    },
    implant: {
      title: 'Medicinski pripomočki in implantati',
      removeTitle: '$t(patient:anamnesis.implant.field)',
      field: 'Medicinski pripomoček ali implantat',
      add: '+ dodajte medicinski pripomoček ali implantat'
    },
    operation: {
      title: 'Operacije',
      removeTitle: 'operacijo',
      field: 'Operacija',
      add: '+ dodajte operacijo'
    },
    other: {
      title: '$t(common:other)',
      removeTitle: '$t(common:other)',
      removeMessage: 'Želite odstraniti to anamnezo?',
      add: '+ dodajte $t(common:other)'
    },
    alert: {
      created: 'Anamneza je bila uspešno dodana.',
      updated: 'Anamneza je bila uspešno posodobljena.',
      removed: 'Anamneza je bila uspešno odstranjena.'
    }
  },
  cave: {
    cave: 'Cave',
    fieldLabel: {
      allergies: 'Alergije',
      medications: 'Zdravila',
      chronicConditions: 'Kronična stanja',
      other: 'Drugo',
      note: 'Opombe'
    },
    missingField: {
      allergies: 'Nima alergij',
      medications: 'Nima zdravil',
      chronicConditions: 'Nima kroničnih stanj',
      other: 'Nič',
      note: 'Nič'
    },
    noFileYet:
      'Tukaj lahko dodate pomembne medicinske podatke o pacientu. Kliknite na kartico, da pričnete urejati!'
  },
  note: {
    title: 'Opomba',
    placeholder: 'Dodajte beležko',
    removeNote: 'Odstranite beležko',
    confirmNoteRemoval: 'Ali ste prepričani, da želite odstraniti beležko?',
    type: {
      note: 'Beležka'
    },
    noFileYet:
      'Tukaj lahko dodate neklinične beležke o pacientu. Kot npr. “pacient ima raje termine v popoldanskem času',
    alerts: {
      noteCreated: 'Opomnik je bil uspešno ustvarjen.',
      noteUpdated: 'Opomnik je bil uspešno posodobljen.',
      noteRemoved: 'Opomnik je bil uspešno odstranjen.',
      notePinned: 'Opomnik je bil uspešno pripet.',
      noteUnpinned: 'Opomnik je bil uspešno odpet.'
    }
  },
  person: {
    firstName: 'Ime',
    lastName: 'Priimek',
    gender: 'Spol',
    genderTypes: {
      male: 'Moški',
      female: 'Ženski'
    },
    phoneNumber: 'Telefonska številka',
    notifications: {
      title: 'Nastavitve obveščanja',
      sms: 'SMS',
      email: 'Elektronski naslov',
      push: 'Potisna obvestila',
      marketing: {
        title: 'Marketing'
      },
      reminders: {
        title: 'Opomniki'
      }
    },
    contact: 'Kontakt',
    address: 'Naslov',
    identification: 'Identifikacija',
    dateOfBirth: 'Datum rojstva',
    insuranceId: 'Številka zavarovanja',
    shortId: 'Zaporedna številka'
  },
  address: {
    address: 'Naslov',
    street: 'Ulica',
    town: 'Kraj',
    zip: 'Poštna številka',
    zipShort: 'Poštna št.',
    country: 'Država'
  },
  doctor: {
    title: 'Zdravnik',
    doctor: '$t(patient:doctor.title)',
    choose: 'Izberite zdravnika',
    change: 'Spremenite zdravnika',
    chooseFor: 'Izberite za:',
    chooseForEncounter: 'trenutno obravnavo',
    chooseForPatient: 'trenutno in vse prihodnje obravnave',
    doctorInformation: 'Informacije o zdravniku',
    locationIDs: 'Lokacija',
    prefix: 'dr.'
  },
  therapy: {
    title: 'Terapija',
    intake: 'Odmerek',
    intakeNumber: 'Odmerek #{{number}}',
    intakeStatus: 'Status'
  },
  bills: {
    tab: {
      invoices: '$t(billing:invoices.title)',
      items: 'Predmeti'
    }
  },
  unbilledItems: {
    title: 'Neobračunano',
    moreButton: 'Uredite podrobnosti',
    addItem: 'Dodajte predmet',
    selectedTotal: 'Vsota izbranih',
    creatorLabel: {
      addedNow: 'Ravnokar (neshranjeno)'
    },
    alerts: {
      unbilledItemAdded: 'Predmet uspešno dodan k pacientu.',
      unbilledItemsUpdated: 'Neobračunani predmeti uspešno posodobljeni.'
    }
  },
  viewFullProfile: 'Ogled celotnega profila',
  merging: {
    merge: 'Združite',
    mergePatients: 'Združite paciente',
    mergeDuplicatedPatients: 'Združite podvojene paciente',
    duplicatedPatients: 'Podvojeni pacienti',
    duplicatedPatientsDescription:
      'Zaznali smo podvojene paciente, bi jih želeli združiti?',
    confirmMerge: 'Da, prikaži',
    findDuplicatedPatients: 'Poiščite paciente s podvojenenim imenom',
    pickPrimaryPatient: 'Izberite primarnega pacienta:',
    thesePatientsWillBeMerged: 'Združili boste sledeče paciente:',
    mergePatientFormTitle: 'Izberite podatke ki jih želite obdržati:',
    confirmMsg:
      'Ste prepričani da želite združiti paciente? Združitve se kasneje ne da razveljaviti.',
    identification: {
      firstName: '$t(patient:person.firstName)',
      dateOfBirth: '$t(patient:person.dateOfBirth)',
      lastName: '$t(patient:person.lastName)',
      gender: '$t(patient:person.gender)',
      genderTypes: {
        male: '$t(patient:person.genderTypes.male)',
        female: '$t(patient:person.genderTypes.female)'
      },
      insuranceID: '$t(patient:person.insuranceId)',
      shortID: '$t(patient:person.shortId)'
    },
    notifications: {
      reminders: {
        sms: 'SMS obveščanje',
        email: 'Email obveščanje',
        push: 'Potisna obvestila'
      },
      marketing: {
        sms: 'SMS marketing',
        email: 'Email marketing',
        push: 'Potisna marketing sporočila'
      }
    },
    contact: {
      address: {
        address: '$t(patient:address.address)',
        street: '$t(patient:address.street)',
        street1: '$t(patient:address.street)',
        town: '$t(patient:address.town)',
        zip: '$t(patient:address.zip)',
        zipShort: '$t(patient:address.zipShort)',
        country: '$t(patient:address.country)'
      },
      phoneNumber: '$t(patient:person.phoneNumber)',
      email: '$t(patient:person.notifications.email)'
    },
    doctor: {
      doctorID: '$t(patient:doctor.doctor)',
      locationIDs: '$t(patient:doctor.locationIDs)'
    },
    mergeSuccess: 'Pacient je bil uspešno združen.'
  },
  widgetHistoryModal: {
    emptyState: 'Zgodovinski podatki niso na voljo.'
  },
  medicalRecord: {
    title: 'Zdravstvena kartoteka'
  },
  overlay: {
    editPatient: 'Uredite pacientov profil',
    deletePatient: 'Odstranite izbranega pacienta',
    confirmRemovalMessage:
      'Ali ste prepričani, da želite odstraniti pripetega pacienta?',
    stats: {
      totalBookings: 'Število terminov',
      totalSales: 'Vsota računov',
      unusedAdvances: 'Neporabljeni avansi'
    }
  },
  alerts: {
    patientUpdated: 'Pacient je bil uspešno posodobljen.',
    patientDeleted: 'Pacient je bil uspešno izbrisan.'
  },
  infoDisplay: {
    miniCardInfoShortBirthDate: `{{ dateOfBirth, ${formatting.shortDate} }}`,
    miniCardInfo: `{{ dateOfBirth, ${formatting.longDate} }} · {{ age }} {{ unit }} · {{ gender }}`,
    miniCardInfoWithAddress: `$t(patient:infoDisplay.miniCardInfoShortBirthDate) · {{ address.street1 }}, {{ address.zip }} {{ address.town }}`,
    openProfile: 'odprite profil'
  },
  export: {
    title: 'Izvozite seznam pacientov',
    patientListWithDate: `Izvoz pacientov {{ date, ${formatting.shortDate} }}`,
    inProgress: 'Izvoz lahko traja nekaj trenutkov.',
    alert: {
      requestSuccess:
        'Pripravljamo izvoz pacientov. Obvestili vas bomo, ko bo na voljo za prenos.'
    }
  },
  appointmentList: {
    queueEventsOnDifferentLocations: 'Pacient je v čakalni vrsti na lokaciji: ',
    differentLocationWarning:
      'Termin je na lokaciji {{location}} in ga ni mogoče prestaviti. Najprej spremenite lokacijo in poskusite znova.'
  },
  validation: {
    duplicatedPatientOnList: 'Pacient že obstaja na seznamu.'
  }
};
