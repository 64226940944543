import axios from 'axios';
import _ from 'lodash';
import { takeEvery, select, put } from 'redux-saga/effects';

import { getPatientDetailUrl } from '../../../../utils/api';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../../../utils/constants/networkConstants';
import { apiCall } from '../../../../utils/redux/sagaUtils';
import { widgetMapper } from '../../../../utils/widgets';

import {
  GET_WIDGET_HISTORY_TRIGGER,
  GET_WIDGET_HISTORY_SANITIZED,
  GET_WIDGET_HISTORY_SUCCESS,
  GET_WIDGET_HISTORY_UPDATED,
  GET_WIDGET_HISTORY,
  WIDGET_HISTORY_UPDATE
} from '../widgetTypes';

export function* getWidgetHistoryApiRequest(action) {
  const { patientId, widgetType } = action;
  const state = yield select();
  const patientUrl = getPatientDetailUrl(() => state, patientId);

  yield apiCall(
    GET_WIDGET_HISTORY,
    axios.get,
    [
      `${patientUrl}/widgets`,
      {
        params: {
          includeContent: true,
          widgetIDs: widgetType,
          limit: REQUEST_PAGINATION_MAX_LIMIT
        }
      }
    ],
    { widgetType }
  );
}

export function* getWidgetHistorySanitization(action) {
  const rawHistory = _.getNonEmpty(action, 'data', []);
  const widgetType = _.getNonEmpty(action, 'additionalData.widgetType', null);

  if (!widgetType) return;

  const widgetHistory = rawHistory.map(widgetMapper);

  yield put({
    type: GET_WIDGET_HISTORY_SANITIZED,
    widgetHistory,
    widgetType
  });
}

export function* getWidgetHistoryStoreUpdate(action) {
  const { widgetHistory, widgetType } = action;

  yield put({ type: WIDGET_HISTORY_UPDATE, widgetHistory, widgetType });
  yield put({ type: GET_WIDGET_HISTORY_UPDATED, widgetType });
}

export const widgetHistorySagas = [
  takeEvery(GET_WIDGET_HISTORY_TRIGGER, getWidgetHistoryApiRequest),
  takeEvery(GET_WIDGET_HISTORY_SUCCESS, getWidgetHistorySanitization),
  takeEvery(GET_WIDGET_HISTORY_SANITIZED, getWidgetHistoryStoreUpdate)
];
