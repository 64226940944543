import produce from 'immer';
import _ from 'lodash';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../utils/browserStorage';
import { userPreferredDataInputStorageKey } from '../../utils/constants/browserStorageConstants';
import { ORDER_ASCENDING } from '../../utils/constants/tableConstants';
import {
  ROLE_ADMIN,
  ROLE_HAS_CALENDAR,
  ROLE_LOCAL_PATIENT_ONLY_ACCESS
} from '../../utils/constants/userConstants';
import { appendColorMapper } from '../../utils/mappers/color-mappers';

import { createActionTypeArray } from '../common/actionTypes';

import { LOGOUT } from '../auth/authReducer';

export const UPDATE_USER = 'user/UPDATE_USER';
export const DELETE_USER = 'user/DELETE_USER';
export const CREATE_USER = 'user/CREATE_USER';
export const GET_USER = 'user/GET_USER';
export const GET_USERS = 'user/GET_USERS';
export const SET_USER_LIST_ORDER = 'user/SET_USER_LIST_ORDER';
export const GET_CURRENT_USER = 'user/GET_CURRENT_USER';
export const UPDATE_USER_ROLE = 'user/UPDATE_USER_ROLE';
export const REASSIGN_CALENDAR_ITEMS = 'user/REASSIGN_CALENDAR_ITEMS';

export const RESEND_VERIFICATION_EMAIL = 'user/RESEND_VERIFICATION_EMAIL';

export const SET_PREFERRED_BILLING_INPUT = 'user/SET_PREFERRED_BILLING_INPUT';

export const [
  GET_USERS_IN_PROGRESS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE
] = createActionTypeArray(GET_USERS);

export const [
  GET_USER_IN_PROGRESS,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
] = createActionTypeArray(GET_USER);

export const [
  GET_CURRENT_USER_IN_PROGRESS,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE
] = createActionTypeArray(GET_CURRENT_USER);

export const [
  UPDATE_USER_IN_PROGRESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
] = createActionTypeArray(UPDATE_USER);

export const [
  CREATE_USER_IN_PROGRESS,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE
] = createActionTypeArray(CREATE_USER);

export const [
  DELETE_USER_IN_PROGRESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
] = createActionTypeArray(DELETE_USER);

export const [
  UPDATE_USER_ROLE_IN_PROGRESS,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE
] = createActionTypeArray(UPDATE_USER_ROLE);

export const USER_LIST_FULL_NAME_SORT_BY = [
  (user) => _.get(user, 'firstName', '').toLowerCase(),
  (user) => _.get(user, 'lastName', '').toLowerCase()
];
export const USER_LIST_FULL_NAME_FIELD = 'fullName';

const defaultPreferredDataInput = {
  billingUnitID: null,
  businessPremiseID: null,
  electronicDeviceID: null
};

const initialState = {
  currentUser: undefined,
  verificationEmailSent: false,
  activeUserEncounters: [],
  filter: {
    sortBy: USER_LIST_FULL_NAME_FIELD,
    order: ORDER_ASCENDING
  },
  preferredBillingData: getLocalStorageValue(
    userPreferredDataInputStorageKey,
    defaultPreferredDataInput
  )
};

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;
    let modifiedSortBy;

    switch (type) {
      case GET_USERS_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_USERS_FAILURE:
        draft.loadingList = false;
        draft.list = [];
        break;
      case GET_USERS_SUCCESS:
        const users = payload.response.data;

        modifiedSortBy = draft.filter.sortBy;

        if (draft.filter.sortBy === USER_LIST_FULL_NAME_FIELD) {
          modifiedSortBy = USER_LIST_FULL_NAME_SORT_BY;
        }

        draft.loadingList = false;
        draft.list = _.orderBy(users, modifiedSortBy, draft.filter.order);
        break;
      case GET_USER_IN_PROGRESS:
        draft.loadingItem = true;
        break;
      case GET_USER_FAILURE:
        draft.loadingItem = false;
        draft.detail = undefined;
        break;
      case GET_USER_SUCCESS:
        /**
         * If the user doesn't have a color set we generate it from its id as a
         * seed.
         */
        const userData = appendColorMapper(_.get(payload, 'response.data'));
        const userOrganizationRoles = _.get(userData, 'organizationRoles', []);

        if (_.isPlainObject(userData)) {
          userData.isOrganizationAdmin = _.includes(
            userOrganizationRoles,
            ROLE_ADMIN
          );
          userData.localPatientsOnly = _.includes(
            userOrganizationRoles,
            ROLE_LOCAL_PATIENT_ONLY_ACCESS
          );
          userData.hasCalendar = _.includes(
            userOrganizationRoles,
            ROLE_HAS_CALENDAR
          );
        }

        draft.loadingItem = false;
        draft.detail = userData;
        break;
      case GET_CURRENT_USER_IN_PROGRESS:
        draft.loadingItem = true;
        break;
      case GET_CURRENT_USER_SUCCESS:
        const currentUser = _.get(payload, 'response.data');
        const currentUserOrganizationRoles = _.get(
          currentUser,
          'organizationRoles',
          []
        );

        if (_.isPlainObject(currentUser)) {
          currentUser.isOrganizationAdmin = _.includes(
            currentUserOrganizationRoles,
            ROLE_ADMIN
          );
          currentUser.localPatientsOnly = _.includes(
            currentUserOrganizationRoles,
            ROLE_LOCAL_PATIENT_ONLY_ACCESS
          );
        }

        draft.loadingItem = false;
        draft.currentUser = currentUser;
        break;
      case GET_CURRENT_USER_FAILURE:
        draft.loadingItem = false;
        draft.currentUser = undefined;
        break;
      case UPDATE_USER_SUCCESS:
        draft.detail = _.get(payload, 'response.data');
        break;
      case CREATE_USER_SUCCESS:
        const newUser = _.get(payload, 'response.data');

        draft.newlyCreatedUser = newUser;
        break;
      case SET_USER_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.get(payload, 'order', ORDER_ASCENDING);

        modifiedSortBy = sortBy;

        if (sortBy === USER_LIST_FULL_NAME_FIELD) {
          modifiedSortBy = USER_LIST_FULL_NAME_SORT_BY;
        }

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        draft.list = _.orderBy(draft.list, modifiedSortBy, draft.filter.order);
        break;
      case RESEND_VERIFICATION_EMAIL:
        draft.verificationEmailSent = true;
        break;
      case SET_PREFERRED_BILLING_INPUT:
        const fieldName = _.getNonEmpty(payload, 'fieldName');
        const fieldValue = _.get(payload, 'fieldValue', null);

        if (
          _.isEmpty(fieldName) ||
          !_.has(defaultPreferredDataInput, fieldName)
        ) {
          return;
        }

        draft.preferredBillingData[fieldName] = fieldValue;

        setLocalStorageValue(
          userPreferredDataInputStorageKey,
          draft.preferredBillingData
        );
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default userReducer;
