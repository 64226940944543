import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { InputGroup, InputGroupAddon } from '../../../../../../reactstrap';
import ConditionalWrap from '../../../../../layout/ConditionalWrap';

const EditModeCustomGroupSingleField = ({
  children,
  measurement,
  className,
  styles
}) => {
  const groupWrapper = (child) => (
    <InputGroup size={'md'}>
      {child}
      <InputGroupAddon addonType={'append'}>{measurement}</InputGroupAddon>
    </InputGroup>
  );

  return (
    <div className={className} style={styles}>
      <ConditionalWrap condition={!_.isEmpty(measurement)} wrap={groupWrapper}>
        {children}
      </ConditionalWrap>
    </div>
  );
};

EditModeCustomGroupSingleField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  measurement: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.shape()
};

EditModeCustomGroupSingleField.defaultProps = {
  className: undefined,
  styles: undefined,
  measurement: ''
};

export default EditModeCustomGroupSingleField;
