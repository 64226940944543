import _ from 'lodash';

import { isWireTransferPayment } from '../../../billing/billingUtils';

import {
  BUILTIN_PAYMENT_TYPES,
  PAYMENT_TYPE_LIST_INACTIVE,
  PAYMENT_TYPE_LIST_MORE,
  PAYMENT_TYPE_LIST_VISIBLE,
  PAYMENT_TYPE_SOURCE_NAME_BUILTIN,
  PAYMENT_TYPE_SOURCE_NAME_CUSTOM
} from '../../../data/billing';

// Mapping: Organization settings + Custom payment types registry --> Payment type lists

const mapBuiltinPaymentTypeItem = (paymentTypeItem) => ({
  id: _.getNonEmpty(paymentTypeItem, 'name'),
  baseType: _.getNonEmpty(paymentTypeItem, 'name', ''),
  notTaggable: _.getNonEmpty(paymentTypeItem, 'notTaggable', false),
  isBuiltin: true
});

const mapCustomPaymentTypeItem = (paymentTypeItem, customPaymentTypes) => {
  const customPaymentType = _.find(customPaymentTypes, {
    id: paymentTypeItem.id
  });

  return {
    id: _.getNonEmpty(paymentTypeItem, 'id'),
    baseType: _.getNonEmpty(customPaymentType, 'type', ''),
    customName: _.getNonEmpty(customPaymentType, 'name', ''),
    isBuiltin: false
  };
};

const mapActivePaymentTypeItems = (paymentTypeItems, customPaymentTypes) =>
  _.map(paymentTypeItems, (item) => {
    const isBuiltin =
      _.getNonEmpty(item, 'type', null) === PAYMENT_TYPE_SOURCE_NAME_BUILTIN;

    return isBuiltin
      ? mapBuiltinPaymentTypeItem(item)
      : mapCustomPaymentTypeItem(item, customPaymentTypes);
  });

const getInactivePaymentTypeListItems = (
  paymentTypeSettings,
  customPaymentTypes
) => {
  const activePaymentTypes = _.concat(
    _.getNonEmpty(paymentTypeSettings, PAYMENT_TYPE_LIST_VISIBLE, []),
    _.getNonEmpty(paymentTypeSettings, PAYMENT_TYPE_LIST_MORE, [])
  );

  const inactivePaymentTypeItems = [];

  _.forEach(BUILTIN_PAYMENT_TYPES, (builtinType) => {
    const foundPaymentType = _.find(activePaymentTypes, { name: builtinType });

    if (_.isEmpty(foundPaymentType)) {
      inactivePaymentTypeItems.push({
        id: builtinType,
        baseType: builtinType,
        isBuiltin: true
      });
    }
  });

  _.forEach(customPaymentTypes, (customType) => {
    const foundPaymentType = _.find(activePaymentTypes, { id: customType.id });

    if (_.isEmpty(foundPaymentType)) {
      inactivePaymentTypeItems.push({
        id: customType.id,
        baseType: customType.type,
        customName: customType.name,
        isBuiltin: false
      });
    }
  });

  return inactivePaymentTypeItems;
};

export const getPaymentTypeListsFromSettings = (
  paymentTypeSettings,
  customPaymentTypes
) => ({
  [PAYMENT_TYPE_LIST_VISIBLE]: {
    items: mapActivePaymentTypeItems(
      _.getNonEmpty(paymentTypeSettings, PAYMENT_TYPE_LIST_VISIBLE, []),
      customPaymentTypes
    ),
    minItems: 1,
    maxItems: 5,
    swapToListId: PAYMENT_TYPE_LIST_MORE
  },
  [PAYMENT_TYPE_LIST_MORE]: {
    title: 'common:more',
    items: mapActivePaymentTypeItems(
      _.getNonEmpty(paymentTypeSettings, PAYMENT_TYPE_LIST_MORE, []),
      customPaymentTypes
    )
  },
  [PAYMENT_TYPE_LIST_INACTIVE]: {
    title: 'common:inactive.list',
    items: getInactivePaymentTypeListItems(
      paymentTypeSettings,
      customPaymentTypes
    )
  }
});

export const getAllPaymentTypeListFromSettings = (
  paymentTypeSettings,
  customPaymentTypes
) => {
  const visiblePaymentList = mapActivePaymentTypeItems(
    _.getNonEmpty(paymentTypeSettings, PAYMENT_TYPE_LIST_VISIBLE, []),
    customPaymentTypes
  );

  const morePaymentList = mapActivePaymentTypeItems(
    _.getNonEmpty(paymentTypeSettings, PAYMENT_TYPE_LIST_MORE, []),
    customPaymentTypes
  );

  const inactivePaymentList = getInactivePaymentTypeListItems(
    paymentTypeSettings,
    customPaymentTypes
  );

  return [...visiblePaymentList, ...morePaymentList, ...inactivePaymentList];
};

// Mapping: Payment type lists --> Organization settings

const mapPaymentTypeSettingsItems = (listItems) =>
  _.map(listItems, (listItem) => {
    if (listItem.isBuiltin) {
      return {
        name: listItem.baseType,
        type: PAYMENT_TYPE_SOURCE_NAME_BUILTIN,
        notTaggable: _.getNonEmpty(listItem, 'notTaggable', false)
      };
    }

    return { id: listItem.id, type: PAYMENT_TYPE_SOURCE_NAME_CUSTOM };
  });

export const getPaymentTypeSettingsFromLists = (paymentTypeLists) => ({
  [PAYMENT_TYPE_LIST_VISIBLE]: mapPaymentTypeSettingsItems(
    _.getNonEmpty(paymentTypeLists, `${PAYMENT_TYPE_LIST_VISIBLE}.items`, [])
  ),
  [PAYMENT_TYPE_LIST_MORE]: mapPaymentTypeSettingsItems(
    _.getNonEmpty(paymentTypeLists, `${PAYMENT_TYPE_LIST_MORE}.items`, [])
  )
});

// Component format -> Add payment buttons

export const getPaymentTypeDisplayName = (paymentTypeItem, translate) =>
  paymentTypeItem.isBuiltin || paymentTypeItem.type === BUILTIN_PAYMENT_TYPES
    ? translate(`billing:invoices.paymentTypes.${paymentTypeItem.baseType}`)
    : _.getNonEmpty(paymentTypeItem, 'customName', '');

export const getPaymentTypeMoreDropdownItems = (
  paymentTypeLists,
  disableNonWireTransferPayments,
  patchValues,
  submitForm,
  translate
) => {
  const morePaymentTypes = _.getNonEmpty(paymentTypeLists, 'more.items', []);

  const mappedMorePaymentTypes = _.map(morePaymentTypes, (paymentTypeItem) => ({
    id: paymentTypeItem.id,
    baseType: paymentTypeItem.baseType,
    onClick: () => {
      const formValues = mapPaymentTypeItemToFormValues(paymentTypeItem);

      patchValues({ tag: '', type: '', customPaymentID: '' });
      patchValues(formValues);
      submitForm();
    },
    text: getPaymentTypeDisplayName(paymentTypeItem, translate)
  }));

  if (disableNonWireTransferPayments) {
    return _.filter(mappedMorePaymentTypes, (type) =>
      isWireTransferPayment(type.baseType)
    );
  }

  return mappedMorePaymentTypes;
};

export const mapPaymentTypeItemToFormValues = (paymentTypeItem) => {
  const formValues = {
    type: paymentTypeItem.baseType
  };

  if (
    !paymentTypeItem.isBuiltin &&
    !_.isEmptySafe(paymentTypeItem, 'customName', false)
  ) {
    formValues.tag = paymentTypeItem.customName;
    formValues.customPaymentID = paymentTypeItem.id;
  }

  return formValues;
};

export const getBillPaymentWithDisplayname = (payments, translate) =>
  _.map(payments, (payment) => {
    const newPayment = _.cloneDeep(payment);

    newPayment.displayName = getBillPaymentDisplayName(newPayment, translate);

    return newPayment;
  });

export const getBillPaymentDisplayName = (billPayment, translate) =>
  _.isEmptySafe(billPayment, 'tag', false)
    ? translate(
        `billing:invoices.paymentTypes.${_.getNonEmpty(billPayment, 'type')}`
      )
    : _.getNonEmpty(billPayment, 'tag', '');

export const getEnabledBillTypes = (billTypeList) => {
  const visiblePaymentTypeList = _.getNonEmpty(
    billTypeList,
    `${PAYMENT_TYPE_LIST_VISIBLE}.items`,
    []
  );
  const morePaymentTypeList = _.getNonEmpty(
    billTypeList,
    `${PAYMENT_TYPE_LIST_MORE}.items`,
    []
  );

  return [...visiblePaymentTypeList, ...morePaymentTypeList];
};
