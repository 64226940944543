import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import { asyncPromiseWrapper } from '../../../../utils/gen';
import {
  WIDGET_FILE_ADDED,
  WIDGET_FILE_DELETED,
  filterWidgetFileDeleted
} from '../../../../utils/mappers/files-mapper';

import { addPatientFile } from '../../../../redux/patient/patientActions';

import Visible from '../../layout/Visible';
import FileListItem from '../file/FileListItem';
import MoshiFiles from '../file/MoshiFiles';

// TODO: Think of maybe extracting this somewhere else (action creators)?
const uploadFile = async (file, formContext, dispatch) => {
  const patientId = formContext.patientId;
  const encounterID = formContext.patientId;
  const sourceWidgetType = _.getNonEmpty(formContext, 'widget.widgetType');

  const meta = {
    encounterID,
    sourceWidgetType
  };

  const addPatientFileDispatch = (newPatientId, newFormData) =>
    dispatch(addPatientFile(newPatientId, newFormData, meta));

  const [result] = await asyncPromiseWrapper(
    addPatientFileDispatch(patientId, file)
  );

  return result;
};

const filesRemoveOne = (file, formData, onChange) => {
  const data = _.cloneDeep(formData);

  const removedFileIndex = _.findIndex(data, { id: file.id });

  _.set(data, `[${removedFileIndex}].localStatus`, WIDGET_FILE_DELETED);

  onChange(data);
};

const onFilesAdded = async ({
  newFile,
  formData,
  onChange,
  formContext,
  filesRef,
  dispatch,
  setIsFileUploading
}) => {
  let data = [];

  setIsFileUploading(true);

  if (_.isArray(formData)) {
    data = _.cloneDeep(formData);
  }

  const newFileData = await uploadFile(newFile, formContext, dispatch);

  _.set(newFileData, 'localStatus', WIDGET_FILE_ADDED);

  filesRef.current.removeFiles();

  if (!_.isEmpty(newFileData) && _.isPlainObject(newFileData)) {
    data.push({
      ...newFileData,
      name: newFile.name,
      sizeReadable: newFile.sizeReadable
    });

    onChange(data);
  }

  setIsFileUploading(false);
};
// END TODO

export const CustomFilesWidgetComponent = ({
  onChange,
  formData,
  disabled,
  formContext
}) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const filesRef = useRef(null);
  const dispatch = useDispatch();
  const filteredFiles = _.filter(formData, filterWidgetFileDeleted);

  useEffect(() => {
    if (disabled) {
      setIsFileUploading(false);
    }
  }, [disabled, setIsFileUploading]);

  return (
    <React.Fragment>
      <Visible visible={!_.isEmpty(formData)}>
        {() => (
          <div className={'files-list'}>
            {filteredFiles.map((file) => (
              <FileListItem
                key={file.id}
                file={file}
                disabled={disabled}
                onRemove={() => filesRemoveOne(file, formData, onChange)}
              />
            ))}
          </div>
        )}
      </Visible>
      <Visible visible={!disabled}>
        <MoshiFiles
          filesRef={filesRef}
          isFileUploading={isFileUploading}
          onFilesAdded={([newFile]) =>
            onFilesAdded({
              newFile,
              formData,
              onChange,
              formContext,
              filesRef,
              dispatch,
              setIsFileUploading
            })
          }
          onUploadClick={() => {
            filesRef.current.openFileChooser();
          }}
          clickable
          multiple
        />
      </Visible>
    </React.Fragment>
  );
};

CustomFilesWidgetComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formData: PropTypes.arrayOf(PropTypes.shape()),
  formContext: PropTypes.shape()
};

CustomFilesWidgetComponent.defaultProps = {
  disabled: false,
  formData: [],
  formContext: {}
};

export default localize(CustomFilesWidgetComponent);
