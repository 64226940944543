import produce from 'immer';
import _ from 'lodash';

import {
  CLEAR_FORM_REQUEST_DETAIL,
  GET_FORM_REQUEST_DETAIL_FAILURE,
  GET_FORM_REQUEST_DETAIL_IN_PROGRESS,
  GET_FORM_REQUEST_DETAIL_SUCCESS
} from './formRequestTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  detail: {},
  loadingDetail: false
};

// eslint-disable-next-line default-param-last
const formRequestReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_FORM_REQUEST_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_FORM_REQUEST_DETAIL_SUCCESS:
        const formRequest = _.get(payload, 'response.data', {});

        draft.detail = formRequest;
        draft.loadingDetail = false;
        break;
      case GET_FORM_REQUEST_DETAIL_FAILURE:
        draft.loadingDetail = false;
        break;
      case CLEAR_FORM_REQUEST_DETAIL:
        draft.detail = initialState.detail;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default formRequestReducer;
