import axios from 'axios';

import {
  showAlertSuccess,
  showMissingPatientIdError
} from '../../../utils/alert';
import {
  getPatientFileUrl,
  getPatientFileListUrl
} from '../../../utils/api/apiUrlUtils';
import { MOSHI_FILE_TYPE_EXTERNAL } from '../../../utils/constants/fileConstants';

import { appendItemsToFormData } from '../../core/file/fileActions';

import {
  GET_FORM_EXTERNAL_DETAIL,
  UPDATE_FORM_EXTERNAL_DETAIL,
  REMOVE_FORM_EXTERNAL_DETAIL,
  CLEAR_FORM_EXTERNAL_DETAIL
} from './formExternalTypes';

export const removeExternalDocument = (patientId, documentId) => (
  dispatch,
  getState
) => {
  const patientFileUrl = getPatientFileListUrl(getState, patientId);

  if (!patientFileUrl) {
    return showMissingPatientIdError();
  }

  return dispatch({
    type: REMOVE_FORM_EXTERNAL_DETAIL,
    apiCall: axios.delete(`${patientFileUrl}/${documentId}`),
    onSuccess: () => {
      showAlertSuccess('document:alert.fileRemoved');
    }
  });
};

export const getExternalDocumentDetail = (patientId, documentId) => (
  dispatch,
  getState
) => {
  const patientFileUrl = getPatientFileUrl(getState, patientId, documentId);

  if (!patientFileUrl) {
    return showMissingPatientIdError();
  }

  return dispatch({
    type: GET_FORM_EXTERNAL_DETAIL,
    apiCall: axios.get(patientFileUrl)
  });
};

export const updateExternalDocumentDetail = (
  patientId,
  fileId,
  documentPayload
) => (dispatch, getState) => {
  const patientFileUrl = getPatientFileUrl(getState, patientId, fileId);

  if (!patientFileUrl) {
    return showMissingPatientIdError();
  }

  const payloadMeta = {
    meta: {
      ...documentPayload.meta,
      type: MOSHI_FILE_TYPE_EXTERNAL
    }
  };

  const formData = appendItemsToFormData(payloadMeta);

  return dispatch({
    type: UPDATE_FORM_EXTERNAL_DETAIL,
    apiCall: axios.put(patientFileUrl, formData),
    onSuccess: () => showAlertSuccess('document:alert.fileUpdated')
  });
};

export const clearExternalDocumentDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_FORM_EXTERNAL_DETAIL
  });
