/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { ModalFooter } from 'reactstrap';

import PropTypes from 'prop-types';

const ModalFooterWrapper = ({ children, className, id }) => (
  <ModalFooter className={className} id={id}>
    {children}
  </ModalFooter>
);

ModalFooterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string
};

ModalFooterWrapper.defaultProps = {
  className: undefined,
  id: undefined
};

export default ModalFooterWrapper;
