import CKEditor from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';

import { ClassicEditor } from '@iryo/ckeditor5-build-markdown';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const MOSHI_CK_EDITOR_DEFAULT_TOOLBAR_PRESET =
  'MOSHI_CK_EDITOR_DEFAULT_TOOLBAR_PRESET';

export const MOSHI_CK_EDITOR_EMPTY_TOOLBAR_PRESET =
  'MOSHI_CK_EDITOR_EMPTY_TOOLBAR_PRESET';

const moshiCKEditorDefaultToolbarPreset = [
  'heading',
  '|',
  'bold',
  'italic',
  'link',
  'bulletedList',
  '|',
  'undo',
  'redo'
];

const moshiCKEditorToolbarPresets = {
  [MOSHI_CK_EDITOR_EMPTY_TOOLBAR_PRESET]: [],
  [MOSHI_CK_EDITOR_DEFAULT_TOOLBAR_PRESET]: moshiCKEditorDefaultToolbarPreset
};

// This component has issues displaying value when formik is reinitializing
const MoshiCKEditor = ({
  onChange,
  value,
  disabled,
  autoFocus,
  toolbarPresetName,
  config,
  ...props
}) => {
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(editor) && autoFocus) {
      editor.editing.view.focus();
    }
  }, [editor, autoFocus]);

  const debouncedOnChange = _.debounce((event, editorInstance) => {
    const data = editorInstance.getData();

    onChange(data);
  }, 200);

  const toolbarItems = _.get(
    moshiCKEditorToolbarPresets,
    toolbarPresetName,
    moshiCKEditorDefaultToolbarPreset
  );

  return (
    <CKEditor
      onInit={(editorInstance) => {
        setEditor(editorInstance);
      }}
      editor={ClassicEditor}
      data={value}
      onChange={debouncedOnChange}
      disabled={disabled}
      config={{
        ...config,
        toolbar: {
          items: toolbarItems
        }
      }}
      {...props}
    />
  );
};

MoshiCKEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  config: PropTypes.shape(),
  toolbarPresetName: PropTypes.oneOf([
    MOSHI_CK_EDITOR_EMPTY_TOOLBAR_PRESET,
    MOSHI_CK_EDITOR_DEFAULT_TOOLBAR_PRESET
  ])
};

MoshiCKEditor.defaultProps = {
  disabled: false,
  autoFocus: false,
  config: {},
  toolbarPresetName: MOSHI_CK_EDITOR_DEFAULT_TOOLBAR_PRESET
};

export default MoshiCKEditor;
