import {
  NOTIFICATION_TYPE_APPOINTMENT_ADDED,
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_TYPE_APPOINTMENT_UPDATED,
  NOTIFICATION_TYPE_REMINDER,
  NOTIFICATION_TYPE_CUSTOM,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_FAILED,
  NOTIFICATION_STATUS_COMPLETED,
  NOTIFICATION_STATUS_DELETED,
  NOTIFICATION_TYPE_NO_SHOW,
  NOTIFICATION_TYPE_CANCEL,
  NOTIFICATION_TYPE_FEEDBACK
} from '../../utils/data/notifications';

import { formatting } from '../sl-SI/date';

export default {
  appointment: 'Appointment',
  calendar: 'Calendar',
  create: 'Create new',
  cancelAppointment: 'Cancel appointment',
  newAppointment: 'New appointment',
  newEvent: 'New event',
  newWaitingListEvent: 'Waiting list event',
  category: 'Category',
  // TODO: @Gorazd use common: title pls
  title: 'Title',
  note: 'Note',
  patientNote: 'Patient note',
  categories: 'Categories',
  event: 'Event',
  eventType: 'Event type',
  patientAppointment: 'Patient appointment',
  appointmentHistory: 'Appointment history',
  appointmentCreatedByUser: ` {{ user }}, {{ date, ${formatting.shortDateWithTime} }}`,
  appointmentCreatedBy: `Created by $t(calendar:appointmentCreatedByUser)`,
  appointmentEditedBy: `$t(common:editedBy), {{ date, ${formatting.shortDateWithTime} }}`,
  appointmentCanceledByUser: ` {{ user }}, {{ date, ${formatting.shortDateWithTime} }}`,
  appointmentCanceledByPatient: ` by patient request, {{ date, ${formatting.shortDateWithTime} }} `,
  confirmRemoveEvent: 'Are you sure you want to delete this appointment?',
  confirmCancelEvent: 'Are you sure you want to cancel this appointment?',
  removeEvent: 'Remove event',
  findTime: 'Find time',
  other: 'Other',
  today: 'Today',
  queue: 'Waiting list',
  jumpTo: 'Jump to',
  jumpFor: 'Jump for',
  jumpToShortcuts: {
    week1: '1 week',
    week2: '2 weeks',
    week3: '3 weeks',
    week4: '4 weeks',
    pluralWeek: '{{numberOfWeeks}} weeks',
    month1: '1 month',
    month2: '2 months',
    month3: '3 months',
    month4: '4 months',
    pluralMonths: '{{numberOfMonths}} months',
    year1: '1 year'
  },
  editEvent: 'Edit event',
  viewEvent: 'View event',
  status: {
    status: 'Status',
    setStatus: 'Set status',
    statusUpdated: 'Status updated',
    eventUpdated: 'Event updated',
    reservation: 'Reserved',
    confirmed: 'Confirmed',
    reserved: 'Reserved',
    arrived: 'Arrived',
    noShow: 'No show',
    canceled: 'Canceled',
    scheduled: 'Scheduled',
    deleted: 'Deleted',
    statusTooltip: `
    <0>
      Appointment status affects how the notifications are sent out and is 
      important to keep track of a patient's appointment statistics.
    </0>
    <1>
      <0>
         Reserved - The appointment slot is reserved, but is not yet confirmed. 
         Appointment notifications will NOT be sent out.
      </0>
      <1>
        Scheduled - The appointment is scheduled and awaiting patient’s response. 
        The appointment notifications will be sent out with or without confirmation request, 
        according to the system settings. Once the confirmation request is sent, 
        we await confirmation or cancellation of the appointment by the patient.
      </1>
      <2>
        Confirmed - The appointment is confirmed and booked. The appointment 
        notifications will be sent out (if they are enabled in the settings and allowed by the patient).
      </2>
      <3>Arrived - The patient has arrived for his appointment.</3>
      <4>
        No show - The patient did not arrive for the scheduled appointment. 
        This status can not be set for upcoming appointments.
      </4>
      <5>
        Canceled - The appointment is cancelled by patient and will be shown on calendar according to the system settings. 
        The cancellation notification will also be sent out according to the system settings.
      </5>
    </1>      
  `
  },
  noPatientSelected:
    'No patient is selected. Please select patient or change type of event.',
  settings: {
    addNewCategory: 'Add new category',
    editCategory: 'Edit category',
    categoryName: 'Category name',
    viewSettings: {
      title: 'View settings',
      displayDays: 'Display days',
      displayHours: 'Display hours',
      displayHoursDescription:
        'Select hours that will be displayed in calendar. Hours that are not selected will not be visible in calendar.',
      displayDaysDescription:
        'Select days that will be displayed in calendar. Only selected days will be visible in calendar.',
      startOfWeek: 'Start of the week',
      startOfWeekDescription:
        'Day you select will be the first day of the week shown in calendar.',
      defaultZoom: 'Default zoom',
      defaultZoomDescription:
        'This is density that will be set when open your calendar. You can always adjust this directly above the calendar.',
      defaultViewType: 'Default view type',
      defaultViewTypeDescription:
        'This is the view that will be set when open your calendar. You can always adjust this directly above the calendar.',
      timelineView: 'Timeline view',
      weekView: 'Week view',
      onHover: 'On hover (mouse over)',
      onClick: 'On click',
      viewEvents: 'View events',
      viewPastEventsDescription: 'Reduce the brightness of past events',
      viewCanceledEvents: 'Display canceled events',
      viewCanceledEventsDescription:
        'Canceled events will be displayed on calendar',
      eventTypeColoring: {
        title: 'Style of the event box',
        description:
          'Set the style of patient appointment, event and reminder.',
        outlined: 'Outlined',
        filled: 'Filled'
      }
    }
  },
  eventSaved: 'Event time updated',
  notifications: {
    description: `These are automated messages that will only be sent to patients 
    that have opted in to receiving such communication from you. Here, you can turn 
    organization-wide on or off for both types of messages. You can also set the content 
    of messages but only for SMS, the content of the e-mail is preset and cannot be edited.`,
    from: 'from',
    message: 'message',
    newAppointment: {
      header: 'New patient appointment',
      description:
        'This text will be sent when a new patient appointment is scheduled to patients.'
    },
    cancelAppointment: {
      header: 'Canceled appointment',
      description:
        'This text will be sent to the patient when you cancel a patient’s existing appointment.'
    },
    noShowAppointment: {
      header: 'No show',
      description:
        'This text will be sent to the patient, when you mark a patient’s appointment as “no show”.'
    },
    reminderAppointment: {
      header: 'Reminder for an upcoming appointment',
      description:
        'This text will be sent to the patient before the scheduled appointment.',
      remindPatients: 'Remind patients',
      remindPatient: 'Remind patient'
    },
    reminders: 'Reminders',
    at: 'at',
    deliveryTypes: {
      [NOTIFICATION_DELIVERY_TYPE_SMS]: 'SMS',
      [NOTIFICATION_DELIVERY_TYPE_EMAIL]: 'Email'
    },
    types: {
      [NOTIFICATION_TYPE_APPOINTMENT_ADDED]:
        '$t(calendar:notifications.newAppointment.header)',
      [NOTIFICATION_TYPE_APPOINTMENT_UPDATED]: 'Appointment updated',
      [NOTIFICATION_TYPE_REMINDER]:
        '$t(calendar:notifications.reminderAppointment.header)',
      [NOTIFICATION_TYPE_CUSTOM]: 'Custom message',
      [NOTIFICATION_TYPE_NO_SHOW]: 'No show',
      [NOTIFICATION_TYPE_CANCEL]: 'Appointment canceled',
      [NOTIFICATION_TYPE_FEEDBACK]: 'Message after service',
      rsvpResponse: 'Confirmation of an appointment received'
    },
    status: {
      [NOTIFICATION_STATUS_PENDING]: 'Scheduled',
      [NOTIFICATION_STATUS_COMPLETED]: 'Sent',
      [NOTIFICATION_STATUS_FAILED]: 'Failed',
      [NOTIFICATION_STATUS_DELETED]: 'Deleted'
    },
    noReminderConsent: 'Reminders are disabled for this patient.',
    choosePatient: 'First, choose a patient.',
    legend: `
    <0>Legend</0>
    <1>
    The following smart codes can be entered into texts and will be
    automatically swapped with their respective data:
    </1>
    <1>[patient full name] - name of the patient</1>
    <1>[encounter date] - date for the appointment</1>
    <1>[encounter time] - time for the appointment</1>
    <1>[location name] - location of the appointment</1>
    <1>[location address] - location's address</1>
    <1>[location zip] - location's zip</1>
    <1>[location city] - location's city</1>
    <1>[location country] - location's country</1>
    <1>[location phone number] - location's phone number</1>
    <1>[location email] - location's email</1>
    <1>[doctor full name] - doctor's name</1>
    <1>[doctor last name] - doctor's last name</1>
    <1>[doctor first name] - doctor's first name</1>
    <1>[doctor email] - doctor's email</1>
    <1>[doctor phone number] - doctor's phone number</1>
    <1>[confirmation code] - patient appointment confirmation code</1>
    <1>[organization phone number] - organization's phone number</1>
    <1>[organization email] - organization's email</1>
    <1>[new booking url] - url to online booking</1>
    <1>[edit booking url] - url to existing online booking</1>
    <1>[if online booked] - display content if appointment was created over online booking</1>
    <1>[if user created] - display content if an employee created an appointment</1>
    <1>[end] - end of if block</1>
    `,
    disabledOrgNotifications:
      '<0>Your organization has disabled notifications. You can enable them</0> <1>here</1>',
    hasDisabledNotifications:
      '<0>Patient has disabled notifications. You can enable them on the patient personal info page</0> <1>here</1>',
    reminderRemoved: 'Reminder was successfully removed',
    feedback: {
      title: 'After service',
      description:
        'This text will be sent to the patient, when you mark a patient’s appointment as “Arrived”.',
      placeholder: 'Message',
      reminder: 'Reminder: ',
      periods: {
        afterOneHour: 'After 1 hour',
        afterTwoHours: 'After 2 hours',
        afterThreeHours: 'After 2 hours',
        afterFourHours: 'After 2 hours',
        afterFiveHours: 'After 2 hours',
        afterSixHours: 'After 2 hours',
        afterTwelveHours: 'After 12 hours',
        afterOneDay: 'After 1 day'
      }
    }
  },
  slotDuration: 'Slot duration',
  week: 'Week',
  threeDay: '3 days',
  day: 'Day',
  month: 'Month',
  hour: 'Hour',
  zoom: {
    title: 'Zoom',
    large: 'Large',
    medium: 'Medium',
    small: 'Small',
    extraSmall: 'Extra small'
  },
  referenceId: 'Reference id',
  view: 'View',
  appointments: 'Appointments',
  appointmentRemoved: 'Event removed.',
  selectATimeToBook: 'Select time to book.',
  reschedule: 'Re-schedule',
  setRescheduleEvent: 'Re-schedule event',
  duplicate: 'Duplicate',
  duplicateEvent: 'Duplicate event',
  moveToWaitingList: 'Move to "Waiting list"',
  doctors: 'Doctors',
  eventMovedToWaitingList: 'Event moved to "Waiting list"',
  confirmRemoveReminder: 'Are you sure you want to remove the reminder?',
  removeReminder: 'Remove reminder',
  addReminder: 'Add reminder',
  reminder: 'Reminder',
  reminderFor: 'Reminder for ',
  viewInCalendar: 'View in calendar',
  priority: {
    title: 'Priority',
    3: 'Low',
    2: 'Medium',
    1: 'High'
  },
  addToCalendar: 'Add to calendar',
  addStaff: 'Add staff',
  sendReminder: 'Send SMS reminder',
  sendReminderTo: 'Send reminder to: ',
  alert: {
    smsReminderSent: 'Sms reminder sent',
    reminderIsInPast:
      "The reminder you added can't be sent because its time is in the past.",
    calendarSettingsSaved: 'Calendar view settings successfully saved.',
    eventShortcutAction:
      'Select time to book: {{title}} • time: {{ readableDuration }} • with: {{ assigneeName }} • category: {{ categoryName }} • note: {{ note }}'
  },
  before: 'before ',
  at: 'at',
  upcoming: 'Upcoming',
  past: 'Past',
  advancedFilters: 'Advanced filters',
  hideFilters: 'Hide filters',
  showFilters: 'Show filters',
  resources: {
    title: 'Resources',
    resource: 'Resource',
    addNewResource: 'Add resource',
    editResource: 'Edit resource',
    resourceName: 'Resource name',
    resourceDescription: 'Resource description',
    alerts: {
      uniqueNameError: 'A resource with the same name already exists.',
      resourceAdded: 'Resource added.',
      resourceRemoved: 'Resource removed.',
      resourceEdited: 'Resource edited.'
    },
    resourceInfo:
      'For smart planning add calendars for resources such as rooms and equipment. This will allow you to see occupancy of doctors and other resources.',
    chooseResource: 'Choose Resource'
  },
  multipleResourcesDropdownGroups: {
    resource: 'Resources',
    user: 'Staff',
    chooseResource: 'Choose staff or resource'
  },
  staffAndResources: 'Staff and resources',
  eventPopover: {
    headerTitleType: {
      encounter: 'Appointment',
      other: 'Event',
      reminder: 'Reminder'
    }
  },
  removeEventModal: {
    title: 'Reason for cancellation',
    noReasonOption: 'No reason',
    canceledOption: 'Canceled',
    notifyPatient: 'Notify patient about cancellation',
    note: 'Note',
    notePlaceholder: 'Enter the reason for cancellation',
    reasonTooltip: `<0>
      <1>Cancelled appointments by patients will be shown on calendar according to calendar settings. 
      While the setting will be enabled, the status of the canceled appointment will be displayed as “Cancelled”.
      </1> 
      <1>Appointments cancelled by user (other) will be permanently removed.</1>
      </0>`
  },
  rescheduleEventModal: {
    selectReason: 'Select a reason for re-scheduling',
    patientRequested: 'Patient requested',
    userRequested: 'User requested',
    other: 'Other',
    notifyPatient: 'Do you want to notify the patient about the change?'
  },
  auditLog: {
    eventUpdatedByPatient: 'Event updated by patient, ',
    eventUpdatedBy: 'Event updated by {{userName}}',
    rescheduled: 'Rescheduled',
    reschedulingNote: 'Rescheduling note: ',
    appointmentRescheduledByPatient: 'Patient rescheduled ',
    appointmentRescheduledByUser: 'User rescheduled ',
    appointmentUpdatedByUser: 'User updated ',
    changeDate: `on {{changeDate, ${formatting.shortDateWithTime}}}, `,
    changedFromTime: ` changed start time from 
    {{originalDate,  ${formatting.time}}} to {{newDate,  ${formatting.time}}}`,
    changedFromTimeAndDate: ` changed start time from 
    {{originalDate,  ${formatting.shortDateWithTime}}} to {{newDate,  ${formatting.shortDateWithTime}}}`,
    changedToTime: ` changed end time from 
    {{originalDate,  ${formatting.time}}} to {{newDate,  ${formatting.time}}}`,
    changedToTimeAndDate: ` changed end time from 
    {{originalDate,  ${formatting.shortDateWithTime}}} to {{newDate,  ${formatting.shortDateWithTime}}}`,
    addedDoctors: ' added {{doctors}} to the event',
    removedDoctors: ' removed {{doctors}} from the event',
    updatedCategory:
      ' changed category from {{removedCategory}} to {{addedCategory}}',
    changedStatus: ' changed status from {{removedStatus}} to {{addedStatus}}',
    changedEventType:
      ' changed event type from {{removedType}} to {{addedType}}',
    changedNote: ' changed the description',
    changedPatient: ' changed the patient',
    eventConfirmedViaRsvp: `<0>Confirmed</0> <1>by patient via RSVP SMS, {{date,  ${formatting.shortDateWithTime}}}</1>`
  },
  noShowModal: {
    title: 'Did not show',
    notifyAboutNoShow: 'Notify patient about no-show',
    notifyDescription:
      'Send a message informing the patient they did not show for their appointment.',
    setAsNoShow: 'Set as no-show'
  },
  eventTypes: {
    queue: 'waiting queue',
    encounter: 'patient appointment',
    reminder: 'reminder',
    other: 'event'
  }
};
