import _ from 'lodash';
import moment from 'moment';

import CashRegisterLogModel from '../../../metadata/model/billing/CashRegisterLogModel';

import { BILL_TYPE_ADVANCE, groupItemsByTaxRate } from '../../billing';
import {
  BUILTIN_PAYMENT_TYPES,
  CASH_REGISTER_LOG_ID_COUNTER_NAME_PREFIX,
  CASH_REGISTER_LOG_NUMBER_COUNTER_NAME_PREFIX,
  CASH_REGISTER_MANDATORY_PAYMENT_TYPES
} from '../../data/billing';

// MAP LATEST DATA TO NEW CASH REGISTER LOGS

export const latestCashRegisterLogsMapper = (latestData, otherStoreData) => {
  const openLogs = mapLatestDataToCashRegisterLogs(latestData, otherStoreData);

  return cleanEmptyLogs(openLogs);
};

export const createLatestCashRegisterLogsMapper = (
  billingUnits,
  electronicDevices,
  currencyId,
  employee
) => (latestDeviceData) =>
  latestCashRegisterLogsMapper(latestDeviceData, {
    billingUnits,
    electronicDevices,
    currencyId,
    employee
  });

const mapLatestDataToCashRegisterLogs = (latestData, otherStoreData) =>
  _.mapValues(latestData, (dataPerBillingUnit, billingUnitId) =>
    _.mapValues(dataPerBillingUnit, (latestDeviceData, electronicDeviceId) =>
      mapLatestDataToCashRegisterLog(
        latestDeviceData,
        billingUnitId,
        electronicDeviceId,
        otherStoreData
      )
    )
  );

// eslint-disable-next-line max-statements
export const mapLatestDataToCashRegisterLog = (
  latestData,
  billingUnitId,
  electronicDeviceId,
  otherStoreData
) => {
  const latestLog = _.getNonEmpty(latestData, 'latestLog');
  const nextCounters = _.getNonEmpty(latestData, 'nextCounters');
  const latestLogFileId = _.getNonEmpty(latestData, 'latestLogFileId');
  const bills = _.getNonEmpty(latestData, 'bills');
  const logDate = _.getNonEmpty(latestLog, 'date');

  const hasDeviceIssuedBills = !_.isEmpty(bills);
  const hasDeviceIssuedLogs = !_.isEmpty(latestLog);

  if (!hasDeviceIssuedBills) {
    return null;
  }

  const newLog = makeBasicLog(
    billingUnitId,
    electronicDeviceId,
    nextCounters,
    otherStoreData
  );

  if (!hasDeviceIssuedLogs) {
    return setLogBillsForOldestDate(bills, newLog);
  }

  if (isDataMissingFromLog(latestLog, bills)) {
    const allLogBills = getBillsOnDate(bills, logDate);
    const amendedLog = _.nestedAssign(newLog, {
      fileId: latestLogFileId,
      date: logDate,
      logId: latestLog.logId,
      logNumber: latestLog.logNumber,
      currencyId: latestLog.currencyId
    });

    return setLogBills(allLogBills, amendedLog);
  }

  const billsInOpenLog = getBillsForOpenLog(logDate, bills);

  if (_.isEmpty(billsInOpenLog)) {
    return null;
  }

  newLog.date = _.getNonEmpty(_.first(billsInOpenLog), 'date');

  return setLogBills(billsInOpenLog, newLog);
};

const makeBasicLog = (
  billingUnitId,
  electronicDeviceId,
  nextCounters,
  { electronicDevices = [], billingUnits = {}, employee = {} }
) => {
  const deviceData = getLogDeviceData(
    electronicDeviceId,
    electronicDevices[billingUnitId]
  );
  const { businessPremiseLabel, electronicDeviceLabel } = deviceData;
  const companyData = getLogCompanyData(billingUnitId, billingUnits);
  const { logIdCounter = 1, logNumberCounter = 1 } = cashRegisterCounterMapper(
    nextCounters
  );

  const newLog = _.nestedAssign(new CashRegisterLogModel(), {
    ...companyData,
    ...deviceData,
    billingUnitID: billingUnitId,
    electronicDeviceID: electronicDeviceId,
    employee: getLogEmployee(employee)
  });

  newLog.logId = makeLogId(
    businessPremiseLabel,
    electronicDeviceLabel,
    logIdCounter
  );
  newLog.logNumber = logNumberCounter;
  newLog.logIdCounter = logIdCounter;

  return newLog;
};

const makeLogId = (businessPremiseLabel, electronicDeviceLabel, counterValue) =>
  `LOG-${businessPremiseLabel}-${electronicDeviceLabel}-${counterValue}`;

const isDataMissingFromLog = (log, bills) => {
  const logDate = _.getNonEmpty(log, 'date');
  const logBills = _.getNonEmpty(log, 'bills', []);

  const billsOnSameDay = getBillsOnDate(bills, logDate);
  const billsMissingFromLog = _.filter(
    billsOnSameDay,
    (bill) => !isBillInList(bill, logBills)
  );

  const billsWithChangedPayments = _.filter(
    logBills,
    (logBill) => !areAllPaymentsLogged(logBill, billsOnSameDay)
  );

  return (
    !_.isEmpty(billsMissingFromLog) || !_.isEmpty(billsWithChangedPayments)
  );
};

const isBillInList = (bill, billList) => {
  const billId = _.get(bill, 'id');

  if (_.isEmpty(billId)) {
    return false;
  }

  const billInList = _.find(billList, { id: billId });

  return !_.isEmpty(billInList);
};

const createValidPaymentFilter = (billIssuedAt) => (payment) => {
  const paymentDate = moment(payment.createdAt);

  return billIssuedAt.isSame(paymentDate, 'day');
};

const areAllPaymentsLogged = (loggedBill, bills) => {
  const bill = _.find(bills, { id: _.getNonEmpty(loggedBill, 'id') });

  if (_.isEmpty(bill)) {
    return false;
  }

  const validPaymentFilter = createValidPaymentFilter(moment(bill.issuedAt));
  const billPayments = _.getNonEmpty(bill, 'payments', []);
  const loggedPayments = _.getNonEmpty(loggedBill, 'payments', []);

  const validBillPayments = _.filter(billPayments, validPaymentFilter);
  const validLoggedPayments = _.filter(loggedPayments, validPaymentFilter);

  return _.isEqualSafe(validBillPayments, validLoggedPayments);
};

const getOldestBill = (bills) => _.first(_.sortBy(bills, ['date']));

const getBillsOnDate = (bills, date) =>
  _.filter(bills, (bill) => bill.date === date);

const getBillsForOpenLog = (lastClosedDate, bills) => {
  const billsAfterLastClosedDate = _.isEmpty(lastClosedDate)
    ? bills
    : _.filter(bills, (bill) => bill.date > lastClosedDate);

  if (_.isEmpty(billsAfterLastClosedDate)) {
    return [];
  }

  const firstNonClosedBill = getOldestBill(billsAfterLastClosedDate);
  const firstNonClosedDate = _.getNonEmpty(firstNonClosedBill, 'date');

  return getBillsOnDate(billsAfterLastClosedDate, firstNonClosedDate);
};

const setLogBillsForOldestDate = (bills, log) => {
  const billsInFirstLog = getBillsForOpenLog(null, bills);

  const oldestBill = getOldestBill(billsInFirstLog);
  const oldestBillDate = _.getNonEmpty(oldestBill, 'date');

  log.date = oldestBillDate;

  return setLogBills(billsInFirstLog, log);
};

const setLogBills = (bills, log) => {
  log.bills = bills;
  log.setSummaries();

  const firstBillCurrency = _.getNonEmpty(
    _.first(bills),
    'currency',
    undefined
  );

  log.currencyId = firstBillCurrency;

  return log;
};

const cleanEmptyLogs = (cashRegisterLogs) => {
  _.forEach(cashRegisterLogs, (logsPerBillingUnit, billingUnitId) => {
    _.forEach(logsPerBillingUnit, (logsPerDevice, electronicDeviceId) => {
      if (_.isEmpty(logsPerDevice)) {
        delete cashRegisterLogs[billingUnitId][electronicDeviceId];

        return false;
      }
    });

    if (_.isEmpty(cashRegisterLogs[billingUnitId])) {
      delete cashRegisterLogs[billingUnitId];
    }
  });

  return cashRegisterLogs;
};

export const listsFromCashRegisterLogsMapper = (
  logsPerBillingUnit,
  billingUnits
) => {
  const lists = {};

  _.forEach(logsPerBillingUnit, (logsPerDevice, billingUnitId) => {
    const hasNoLogs = _.every(_.map(logsPerDevice), _.isEmpty);

    if (hasNoLogs) {
      return;
    }

    const listItems = _.map(logsPerDevice);
    const isOnlyBillingUnit = _.size(logsPerBillingUnit) === 1;

    lists[billingUnitId] = {
      items: listItems.filter(Boolean)
    };

    if (!isOnlyBillingUnit) {
      const foundBillingUnit = _.find(billingUnits, { id: billingUnitId });

      lists[billingUnitId].title = _.get(
        foundBillingUnit,
        'companyDetails.name',
        '/'
      );
    }
  });

  return lists;
};

export const createListsFromCashRegisterLogsMapper = (billingUnits) => (
  logsPerBillingUnit
) => listsFromCashRegisterLogsMapper(logsPerBillingUnit, billingUnits);

export const cashRegisterCounterMapper = (counters) => ({
  logIdCounter: _.find(counters, (value, counterName) =>
    _.includes(counterName, CASH_REGISTER_LOG_ID_COUNTER_NAME_PREFIX)
  ),
  logNumberCounter: _.find(counters, (value, counterName) =>
    _.includes(counterName, CASH_REGISTER_LOG_NUMBER_COUNTER_NAME_PREFIX)
  )
});

export const getCashRegisterCounterNames = (
  billingUnitId,
  electronicDeviceId
) => {
  const logNumberCounterName = `${CASH_REGISTER_LOG_NUMBER_COUNTER_NAME_PREFIX}-unitID:${billingUnitId}`;
  const logIdCounterName = `${CASH_REGISTER_LOG_ID_COUNTER_NAME_PREFIX}-deviceId:${electronicDeviceId}`;

  return {
    logNumberCounterName,
    logIdCounterName
  };
};

export const getLogCompanyData = (billingUnitId, allBillingUnits) => {
  const billingUnit = _.find(allBillingUnits, { id: billingUnitId });

  return {
    companyName: _.get(billingUnit, 'companyDetails.name', ''),
    companyAddress: _.get(billingUnit, 'companyDetails.address', ''),
    taxNumber: _.get(billingUnit, 'companyDetails.taxNumber', '')
  };
};

export const getLogDeviceData = (electronicDeviceID, electronicDevices) => {
  const selectedDevice = _.find(
    electronicDevices,
    (device) => _.get(device, 'id', null) === electronicDeviceID
  );

  return {
    businessPremiseID: _.get(selectedDevice, 'premiseId', ''),
    electronicDeviceLabel: _.get(selectedDevice, 'label', ''),
    businessPremiseLabel: _.get(selectedDevice, 'premiseLabel', '')
  };
};

const getLogEmployee = (user) => ({
  id: _.getNonEmpty(user, 'id'),
  firstName: _.getNonEmpty(user, 'firstName'),
  lastName: _.getNonEmpty(user, 'lastName')
});

// CASH REGISTER SUMMARY MAPPERS

export const mapPaymentSummaryForDisplay = (log) => {
  const [, ...restOfPaymentTypes] = BUILTIN_PAYMENT_TYPES;

  const mappedPaymentSummary = _.map(restOfPaymentTypes, (paymentType) => {
    const paymentTypeSum = getPaymentTypeSum(log, paymentType);
    const customPayments = _.getNonEmpty(
      log,
      `paymentSummary.${paymentType}.customPayments`,
      []
    );

    return { paymentType, paymentTypeSum, customPayments };
  });

  return _.filter(
    mappedPaymentSummary,
    ({ paymentType, paymentTypeSum }) =>
      _.includes(CASH_REGISTER_MANDATORY_PAYMENT_TYPES, paymentType) ||
      paymentTypeSum > 0
  );
};

const getPaymentTypeSum = (log, paymentType) => {
  const paymentSummary = _.getNonEmpty(log, 'paymentSummary', {});
  const logVersion = _.getNonEmpty(log, 'logVersion', 1);

  let paymentTypeSum = 0;

  switch (logVersion) {
    case '1':
      paymentTypeSum = _.getNonEmpty(paymentSummary, paymentType);

      if (_.isFinite(paymentTypeSum)) {
        break;
      }

      // There are missversioned cash register logs on production, some v1 logs use v2 logic
      paymentTypeSum = _.getNonEmpty(paymentSummary, `${paymentType}.sum`, 0);
      break;
    case '2':
    default:
      paymentTypeSum = _.getNonEmpty(paymentSummary, `${paymentType}.sum`, 0);
      break;
  }

  return paymentTypeSum;
};

const getCustomPaymentSummary = (payments) => {
  const customPaymentSummary = [];

  const customTypePayments = _.filter(
    payments,
    (payment) => !_.isEmptySafe(payment, 'tag', false)
  );
  const paymentsByTag = _.groupBy(customTypePayments, 'tag');

  _.forEach(paymentsByTag, (tagPayments, tag) => {
    const sumOfTag = _.sumBy(tagPayments, 'amount');

    customPaymentSummary.push({ tag, sum: sumOfTag });
  });

  return customPaymentSummary;
};

export const getCashRegisterPaymentSummary = (allPayments = []) => {
  const paymentSummary = {};

  _.forEach(BUILTIN_PAYMENT_TYPES, (paymentType) => {
    const paymentTypePayments = _.filter(
      allPayments,
      (payment) => payment.type === paymentType
    );
    const paymentTypeSum = _.sumBy(paymentTypePayments, 'amount', 0);
    const customPaymentSummary = getCustomPaymentSummary(paymentTypePayments);

    paymentSummary[paymentType] = {
      customPayments: customPaymentSummary,
      sum: paymentTypeSum
    };
  });

  return paymentSummary;
};

export const getCashRegisterTaxSummary = (allItems = []) => {
  const sumByAndRound = (items, fieldName) => {
    const summedItemValues = _.sumBy(items, (item) =>
      _.getNonEmpty(item, fieldName, 0)
    );

    return _.round(summedItemValues, 2);
  };

  const itemsByTaxRate = groupItemsByTaxRate(allItems);

  const taxSummary = {};

  _.forIn(itemsByTaxRate, (items, taxRate) => {
    const totalTax = sumByAndRound(items, 'totalTax');
    const total = sumByAndRound(items, 'total');
    const base = total - totalTax;

    taxSummary[taxRate] = { base, totalTax, total };
  });

  return taxSummary;
};

export const getCashRegisterRevenueSummary = (allItems = []) => {
  const billWithoutAdvances = _.filter(
    allItems,
    (bill) => bill.type !== BILL_TYPE_ADVANCE
  );

  return billWithoutAdvances.reduce(
    (acc, bill) => {
      const total = _.getNonEmpty(bill, 'total', 0);
      const totalTax = _.getNonEmpty(bill, 'totalTax', 0);
      const base = total - totalTax;

      return {
        totalTax: acc.totalTax + totalTax,
        total: acc.total + total,
        base: acc.base + base
      };
    },
    {
      totalTax: 0,
      total: 0,
      base: 0
    }
  );
};

export const getCashRegisterDocumentSummary = (allBills = []) => ({
  first: _.get(allBills, '0.number', '/'),
  last: _.get(allBills, `${allBills.length - 1}.number`, '/'),
  count: allBills.length
});
