import React from 'react';
import InputMask from 'react-input-mask';

import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import {
  MOSHI_COLOR_LIGHT_NAME,
  MOSHI_COLOR_SECONDARY_NAME
} from '../../../../utils/color';
import {
  MOSHI_SIZE_MD,
  MOSHI_SIZE_SM,
  MOSHI_SIZE_XS
} from '../../../../utils/constants/sizeConstants';
import {
  allDropdownMonths,
  allDropdownYears,
  getDaysInMonth,
  isLeapYear,
  parseIsoDate
} from '../../../../utils/date';
import { generateArrayOfNumbers, guid } from '../../../../utils/gen';

import { Col, Row } from '../../../reactstrap';
import Icon from '../../Icon';
import MoshiButton from '../../button/MoshiButton';
import MoshiPopover from '../../popover/MoshiPopover';

export class MoshiBirthDatePickerComponent extends React.Component {
  constructor(props) {
    super(props);

    const { t: translate, value, initialOpen } = this.props;

    this.state = {
      isPopoverOpen: initialOpen,
      value: parseIsoDate(translate, value),
      dateSelectorIndex: 0,
      dayValue: null,
      monthValue: null,
      yearValue: null,
      dateFieldFocused: false,
      fieldIdentifier: guid()
    };

    this.togglePopover = this.togglePopover.bind(this);
    this.handleNewValue = this.handleNewValue.bind(this);
    this.updateDay = this.updateDay.bind(this);
    this.updateMonth = this.updateMonth.bind(this);
    this.updateYear = this.updateYear.bind(this);
    this.updateFocus = this.updateFocus.bind(this);
    this.constructDate = this.constructDate.bind(this);
    this.clearPicker = this.clearPicker.bind(this);
    this.dateSelectorBack = this.dateSelectorBack.bind(this);
    this.dateSelectorForward = this.dateSelectorForward.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { t: translate, value: propsValue, disabled } = props;
    const { value: stateValue } = state;

    if (disabled && propsValue !== stateValue) {
      return {
        value: parseIsoDate(translate, propsValue)
      };
    }

    return null;
  }

  get fieldId() {
    const { fieldIdentifier } = this.state;

    return `dateField-${fieldIdentifier}`;
  }

  togglePopover() {
    this.setState((prevState) => ({
      isPopoverOpen: !prevState.isPopoverOpen,
      dateSelectorIndex: prevState.isPopoverOpen
        ? 0
        : prevState.dateSelectorIndex
    }));
  }

  updateDay(day) {
    this.setState(
      () => ({
        dayValue: day,
        dateSelectorIndex: 1
      }),
      this.constructDate
    );
  }

  updateMonth(month) {
    this.setState(
      () => ({
        monthValue: month,
        dateSelectorIndex: 2
      }),
      this.constructDate
    );
  }

  updateYear(year) {
    this.setState(
      () => ({
        yearValue: year,
        dateSelectorIndex: 0
      }),
      this.constructDate
    );
  }

  clearPicker() {
    this.setState(() => ({
      dayValue: null,
      monthValue: null,
      yearValue: null
    }));
  }

  updateFocus(isFocused) {
    this.setState(() => ({
      dateFieldFocused: isFocused
    }));
  }

  constructDate() {
    const { t: translate } = this.props;
    const { dayValue, monthValue, yearValue } = this.state;

    const pad10 = (value) => value.toString().padStart(2, '0');

    if (
      _.isFinite(dayValue) &&
      _.isFinite(monthValue) &&
      _.isFinite(yearValue)
    ) {
      const localizedDateString = translate('date:string', {
        day: pad10(dayValue),
        month: pad10(monthValue + 1),
        year: pad10(yearValue)
      });

      this.handleNewValue(localizedDateString);
      this.clearPicker();
      this.togglePopover();
    }
  }

  handleNewValue(newValue) {
    const { t: translate, onChange } = this.props;

    this.setState(() => ({
      value: newValue
    }));

    const EMPTY_STRING = '__/__/____';

    if (newValue === EMPTY_STRING) {
      return onChange('');
    }
    if (!_.isEmpty(newValue)) {
      const dateFormat = translate('date:stringFormat');
      const momentDate = moment.utc(newValue, dateFormat, true);

      if (momentDate.isValid()) {
        const isoDate = momentDate.toISOString();

        onChange(isoDate);
      } else {
        onChange(newValue);
      }
    }
  }

  dateSelectorBack() {
    this.setState((prevState) => ({
      dateSelectorIndex:
        prevState.dateSelectorIndex > 0
          ? prevState.dateSelectorIndex - 1
          : prevState.dateSelectorIndex
    }));
  }

  dateSelectorForward() {
    this.setState((prevState) => ({
      dateSelectorIndex:
        prevState.dateSelectorIndex < 2
          ? prevState.dateSelectorIndex + 1
          : prevState.dateSelectorIndex
    }));
  }

  renderActions() {
    const { t: translate, backButtonHidden, forwardButtonHidden } = this.props;
    const { dateSelectorIndex } = this.state;

    return (
      <div className={'date-picker-actions'}>
        <MoshiButton
          size={MOSHI_SIZE_XS}
          color={MOSHI_COLOR_LIGHT_NAME}
          className={classNames('btn-icon', {
            'hidden-opacity': backButtonHidden
          })}
          disabled={dateSelectorIndex === 0}
          onClick={this.dateSelectorBack}
        >
          <Icon
            name={'chevron-left'}
            size={'md'}
            className={'vertical-align-middle'}
          />
        </MoshiButton>
        <MoshiButton
          size={MOSHI_SIZE_MD}
          color={MOSHI_COLOR_SECONDARY_NAME}
          onClick={() => {
            this.handleNewValue(
              moment().format(translate('date:stringFormat'))
            );
            this.togglePopover();
          }}
        >
          {translate('date:today')}
        </MoshiButton>
        <MoshiButton
          size={MOSHI_SIZE_XS}
          color={MOSHI_COLOR_LIGHT_NAME}
          className={classNames('btn-icon', {
            'hidden-opacity': forwardButtonHidden
          })}
          disabled={dateSelectorIndex === 2}
          onClick={this.dateSelectorForward}
        >
          <Icon
            name={'chevron-right'}
            size={'md'}
            className={'vertical-align-middle'}
          />
        </MoshiButton>
      </div>
    );
  }

  renderDaySelection() {
    const days = generateArrayOfNumbers(31).map((index) => index + 1);
    const weeksOfDays = _.chunk(days, 6).map((weekDays, index) => ({
      id: `week-${index}`,
      days: weekDays
    }));

    return weeksOfDays.map((week) => (
      <Row key={week.id}>
        {week.days.map((day) => (
          <Col key={day} xs={{ size: 2 }}>
            <MoshiButton
              size={MOSHI_SIZE_SM}
              color={MOSHI_COLOR_LIGHT_NAME}
              onClick={() => {
                this.updateDay(day);
              }}
            >
              {day}
            </MoshiButton>
          </Col>
        ))}
      </Row>
    ));
  }

  renderMonthSelection() {
    const { dayValue } = this.state;
    const quartersOfMonths = _.chunk(allDropdownMonths(), 3).map(
      (quarterMonths, index) => ({
        id: `month-${index}`,
        months: quarterMonths
      })
    );

    return quartersOfMonths.map((quarterOfMonths) => (
      <Row key={quarterOfMonths.id}>
        {quarterOfMonths.months.map((month, monthIndex) => (
          <Col
            key={month.id}
            xs={{ size: 3, offset: monthIndex === 0 ? 1 : 0 }}
          >
            <MoshiButton
              size={MOSHI_SIZE_SM}
              data-test={month.id}
              color={MOSHI_COLOR_LIGHT_NAME}
              disabled={getDaysInMonth(month.id) < dayValue}
              onClick={() => {
                this.updateMonth(month.id);
              }}
            >
              {month.name}
            </MoshiButton>
          </Col>
        ))}
      </Row>
    ));
  }

  renderYearSelection() {
    const { dayValue, monthValue } = this.state;
    const fifthsOfYears = _.chunk(allDropdownYears, 5).map(
      (fifthOfYears, index) => ({
        id: `year-${index}`,
        years: fifthOfYears
      })
    );

    return fifthsOfYears.map((fifthOfYears) => (
      <Row key={fifthOfYears.id}>
        {fifthOfYears.years.map((year, yearIndex) => (
          <Col key={year.id} xs={{ size: 2, offset: yearIndex === 0 ? 1 : 0 }}>
            <MoshiButton
              size={MOSHI_SIZE_SM}
              color={MOSHI_COLOR_LIGHT_NAME}
              disabled={
                dayValue === 29 && monthValue === 1 && !isLeapYear(year.id)
              }
              onClick={() => {
                this.updateYear(year.id);
              }}
            >
              {year.name}
            </MoshiButton>
          </Col>
        ))}
      </Row>
    ));
  }

  renderInput() {
    const {
      t: translate,
      disabled,
      onFocus,
      onBlur,
      name,
      className
    } = this.props;
    const { value, dateFieldFocused } = this.state;

    return (
      <InputMask
        type={'text'}
        alwaysShowMask
        name={name}
        id={this.fieldId}
        className={classNames('form-control', 'custom-datepicker', className)}
        disabled={disabled}
        mask={dateFieldFocused ? '99/99/9999' : '**/**/****'}
        value={
          _.isEmpty(value) ? translate('date:stringFormatReadable') : value
        }
        onClick={this.togglePopover}
        onChange={(e) => {
          const newValue = e.target.value;

          this.handleNewValue(newValue);
        }}
        onFocus={(e) => {
          this.updateFocus(true);
          onFocus(e);
        }}
        onBlur={(e) => {
          this.updateFocus(false);
          onBlur(e);
        }}
        maskChar={'_'}
      />
    );
  }

  renderDatePickerValues() {
    const { dateSelectorIndex } = this.state;

    switch (dateSelectorIndex) {
      default:
      case 0:
        return this.renderDaySelection();
      case 1:
        return this.renderMonthSelection();
      case 2:
        return this.renderYearSelection();
    }
  }

  render() {
    const { disabled } = this.props;
    const { isPopoverOpen } = this.state;

    return (
      <React.Fragment>
        {this.renderInput()}
        <MoshiPopover
          className={'date-picker-popover'}
          placement={'bottom'}
          targetId={this.fieldId}
          isOpen={!disabled && isPopoverOpen}
          toggleOpen={() => this.togglePopover()}
        >
          {this.renderActions()}
          <br />
          <div className={'date-picker-values'}>
            {this.renderDatePickerValues()}
          </div>
        </MoshiPopover>
      </React.Fragment>
    );
  }
}

MoshiBirthDatePickerComponent.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  backButtonHidden: PropTypes.bool,
  forwardButtonHidden: PropTypes.bool,
  initialOpen: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string
};

MoshiBirthDatePickerComponent.defaultProps = {
  value: null,
  disabled: false,
  onFocus: _.noop,
  onBlur: _.noop,
  backButtonHidden: false,
  forwardButtonHidden: true,
  initialOpen: false,
  name: 'dateField',
  className: ''
};

export default localize(MoshiBirthDatePickerComponent);
