import {
  START_ADVANCE_POLLING,
  START_BILL_POLLING,
  START_CALENDAR_EVENT_POLLING,
  START_CREDIT_NOTE_POLLING,
  START_DEVICE_POLLING,
  START_ESTIMATE_POLLING,
  START_INVOICE_POLLING,
  START_POLLING,
  START_POLLING_ANONYMOUS,
  STOP_ADVANCE_POLLING,
  STOP_BILL_POLLING,
  STOP_CALENDAR_EVENT_POLLING,
  STOP_CREDIT_NOTE_POLLING,
  STOP_DEVICE_POLLING,
  STOP_ESTIMATE_POLLING,
  STOP_INVOICE_POLLING,
  STOP_POLLING,
  STOP_POLLING_ANONYMOUS
} from './cacheTypes';

export const startPollingAnonymous = () => ({
  type: START_POLLING_ANONYMOUS
});

export const stopPollingAnonymous = () => ({
  type: STOP_POLLING_ANONYMOUS
});

export const startPolling = () => ({
  type: START_POLLING
});

export const stopPolling = () => ({
  type: STOP_POLLING
});

export const startPollingDevices = () => ({
  type: START_DEVICE_POLLING
});

export const stopPollingDevices = () => ({
  type: STOP_DEVICE_POLLING
});

export const startPollingInvoice = () => ({
  type: START_INVOICE_POLLING
});

export const stopPollingInvoice = () => ({
  type: STOP_INVOICE_POLLING
});

export const startPollingEstimate = () => ({
  type: START_ESTIMATE_POLLING
});

export const stopPollingEstimate = () => ({
  type: STOP_ESTIMATE_POLLING
});

export const startPollingAdvance = () => ({
  type: START_ADVANCE_POLLING
});

export const stopPollingAdvance = () => ({
  type: STOP_ADVANCE_POLLING
});

export const startPollingCreditNote = () => ({
  type: START_CREDIT_NOTE_POLLING
});

export const stopPollingCreditNote = () => ({
  type: STOP_CREDIT_NOTE_POLLING
});

export const startPollingAllBills = () => ({
  type: START_BILL_POLLING
});

export const stopPollingAllBills = () => ({
  type: STOP_BILL_POLLING
});

export const startPollingCalendarEvents = (data) => ({
  type: START_CALENDAR_EVENT_POLLING,
  data
});

export const stopPollingCalendarEvents = () => ({
  type: STOP_CALENDAR_EVENT_POLLING
});
