import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getDateFormat } from '../../../../utils/date';

import MoshiBirthDatePicker from '../date-picker/MoshiBirthDatePicker';

const CustomDatepickerWidget = ({ value, disabled, onChange }) => {
  const handleOnChange = (date) => {
    if (_.isEmpty(date)) {
      return onChange(date);
    }

    const formattedDate = getDateFormat(date, 'YYYY-MM-DD', undefined);

    if (!formattedDate) {
      return onChange(date);
    }
    onChange(formattedDate);
  };

  return (
    <MoshiBirthDatePicker
      value={value}
      disabled={disabled}
      onChange={handleOnChange}
    />
  );
};

CustomDatepickerWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

CustomDatepickerWidget.defaultProps = {
  value: null,
  disabled: false
};

export default CustomDatepickerWidget;
