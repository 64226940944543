import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { Img } from '../../HtmlComponents';

import Visible from '../../layout/Visible';

const CustomSignatureWidget = ({ id, value }) => {
  const { t: translate } = useTranslation();

  const isSignatureDefined = !_.isEmpty(value);

  return (
    <div className={'signature-widget'} id={id}>
      <Visible visible={isSignatureDefined}>
        <Img src={value} alt={'signature'} />
      </Visible>
      <Visible visible={!isSignatureDefined}>
        <div className={'signature-placeholder'}>
          <div className={'signature-placeholder-text'}>
            {translate('document:inbound.signHere')}
          </div>
        </div>
      </Visible>
    </div>
  );
};

CustomSignatureWidget.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string
};

CustomSignatureWidget.defaultProps = {
  value: null
};

export default CustomSignatureWidget;
