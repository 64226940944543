import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { moshiClassNames } from '../../../../../utils/gen';

import { Label } from '../../../../reactstrap';
import Visible from '../../../layout/Visible';

const DefaultWidgetObjectTemplate = ({ properties, schema, uiSchema }) =>
  properties.map((property) => {
    const name = _.getNonEmpty(property, 'name');
    const className = _.getNonEmpty(uiSchema, `${name}.classNames`);
    const title = _.getNonEmpty(schema, `properties.${name}.label`, undefined);

    return (
      <React.Fragment key={property.content.key}>
        <Visible
          visible={!_.isEmpty(title)}
          component={Label}
          className={moshiClassNames(className, 'field-label', 'grey-label')}
        >
          {title}
        </Visible>
        <div className={moshiClassNames({ [className]: _.isEmpty(title) })}>
          {property.content}
        </div>
      </React.Fragment>
    );
  });

DefaultWidgetObjectTemplate.propTypes = {
  uiSchema: PropTypes.shape().isRequired,
  schema: PropTypes.shape().isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default DefaultWidgetObjectTemplate;
