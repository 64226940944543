import { push } from 'connected-react-router';

import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import {
  showAlertError,
  showAlertSuccess,
  showMissingOrganizationIdError,
  showMissingUserIdError
} from '../../utils/alert';
import { getUserActivationUrl } from '../../utils/api/apiUrlUtils';
import { cancelTokenSource } from '../../utils/axiosConfig';
import { setLocalStorageValue } from '../../utils/browserStorage';
import { tempBillingItemInfoStorageKey } from '../../utils/constants/browserStorageConstants';
import { asyncDelay } from '../../utils/gen';
import { isTokenValid } from '../../utils/jwt';
import logger from '../../utils/logger';
import { CANCEL_REQUEST } from '../../utils/middleware/apiMiddleware';
import { getCurrentPageSlugAndDomain } from '../../utils/router';

import { clearBillItemDescription } from '../billing/bill/billActions';
import {
  stopPolling,
  startPolling,
  startPollingAnonymous
} from '../core/cache/cacheActions';
import {
  startIntercomChat,
  shutdownIntercomChat
} from '../core/intercom/intercomActions';
import { navigateToDefaultRoute } from '../core/router/routerActions';
import {
  webSocketConnect,
  closeWebSocketConnection
} from '../core/web-socket/webSocketAction';
import {
  getLocations,
  getLocationsWithUserAccess
} from '../location/locationActions';
import { getCurrentOrganization } from '../organization/organizationActions';
import { getCurrentUser } from '../user/userActions';

import {
  LOGIN,
  LOGOUT,
  RENEW_RETRY,
  RENEW_TOKEN,
  RESET_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  LOGIN_SUCCESS,
  REGISTER,
  VERIFY_EMAIL,
  VERIFY_CHANGED_EMAIL,
  CHANGE_PASSWORD,
  VALIDATE_RESOURCES
} from './authReducer';

import { selectRouterHistoryPreviousLocation } from '../core/routerHistory/routerHistorySelectors';
import { selectDefaultRoute } from '../organization/organizationSelectors';
import { selectCurrentUserId } from '../user/userSelectors';
import {
  selectOrganizationIdFromToken,
  selectTokenExpiration
} from './authSelectors';

import { baseRoutes } from '../../routes';

export const VALIDATE_READ_BINARY = 1;
export const VALIDATE_WRITE_BINARY = 2;
export const VALIDATE_DELETE_BINARY = 4;
export const VALIDATE_UPDATE_BINARY = 8;

const renewInterval = moment.duration(45, 'minutes').asMilliseconds();

/**
 * This method sums up bit flags passed in as an array
 * @param validateActions list of bit flags to check
 * @description This has more to do with how our API work than any specific logic.
 * Examples:
 * - So if we want to check if we have READ(1) and DELETE(4) access we have to send 5 to API which the some of both.
 * - If we want to check for all access we send 15 (= 1 + 2 + 4 + 8)
 * @returns {Number} sum of all bit flags
 */
const getResourceActionValue = (validateActions) => {
  const summaryReducer = (total, currentValue) => {
    if (!_.isFinite(currentValue)) {
      return total;
    }

    return total + currentValue;
  };
  const bitFlagSummary = validateActions.reduce(summaryReducer);

  return bitFlagSummary;
};

const renewRetry = () => (dispatch, getState) => {
  const retries = _.getNonEmpty(getState(), 'authentication.retries', 0);

  if (retries > 4) {
    localStorage.removeItem('token');
    showAlertError('error:authTokenExpired');

    return dispatch(push(baseRoutes.logout));
  } else {
    return setTimeout(() => {
      dispatch(renewToken());
    }, moment.duration(retries, 'seconds').asMilliseconds());
  }
};

export const renewToken = () => (dispatch, getState) => {
  const expirationEpoch = selectTokenExpiration(getState());

  if (!isTokenValid(expirationEpoch)) {
    return;
  }

  dispatch({ type: RENEW_RETRY });

  return dispatch({
    type: RENEW_TOKEN,
    apiCall: axios.get('/renew'),
    onSuccess: async (skip, response) => {
      if (response.status === 200) {
        dispatch({ type: RENEW_RETRY });

        await asyncDelay(renewInterval);
      }

      return dispatch(renewToken());
    },
    onError: () => dispatch(renewRetry())
  });
};

const cancelLingeringAPIRequests = () => {
  cancelTokenSource.cancel(CANCEL_REQUEST);
};

export const logout = (pathname = '/login', searchParams = null) => (
  dispatch,
  getState
) => {
  dispatch(applicationTerminate());
  dispatch({ type: LOGOUT });

  const {
    pathname: currentPathName,
    search: currentSearch
  } = selectRouterHistoryPreviousLocation(getState());
  const defaultRoute = selectDefaultRoute(getState());
  let safePathname = _.includesAny(currentPathName, ['logout', 'login'])
    ? defaultRoute
    : currentPathName;

  let search = searchParams;

  if (!_.isEmptySafe(currentSearch)) {
    safePathname = `${safePathname}${currentSearch}`;
  }

  if (safePathname !== '/' && _.isEmptySafe(search)) {
    search = queryString.stringify({ redirectTo: safePathname });
  }

  dispatch(push({ pathname, search }));
};

const onLoginSuccess = () => (dispatch) => dispatch(applicationInit());

export const applicationInit = () => (dispatch, getState) => {
  // TODO: Remove once we move away from Formik lib
  setLocalStorageValue(tempBillingItemInfoStorageKey, null);
  const tokenExp = selectTokenExpiration(getState());
  const tokenValid = isTokenValid(tokenExp);

  dispatch(startPollingAnonymous());

  if (tokenValid) {
    dispatch(getCurrentUser());
    dispatch(getCurrentOrganization());
    dispatch(getLocations());
    dispatch(getLocationsWithUserAccess());
    dispatch(webSocketConnect());
    dispatch(startPolling());
    dispatch(startIntercomChat());

    if (tokenExp - 30 > _.epoch()) {
      // Calculate token expiration in milliseconds
      const tokenExpiresIn = _.floor((tokenExp - _.epoch()) * 1000);
      let renewIn = renewInterval;

      // If token expires before the renewal triggers we need to change the interval to run sooner
      if (tokenExpiresIn < renewIn) {
        renewIn =
          tokenExpiresIn - moment.duration(30, 'seconds').asMilliseconds();
      }

      setTimeout(() => {
        dispatch(renewToken());
      }, renewIn);
    }
  }
};

export const applicationTerminate = () => (dispatch) => {
  dispatch(closeWebSocketConnection());
  dispatch(shutdownIntercomChat());
  dispatch(stopPolling());
  // TODO: Remove once we move away from Formik lib
  dispatch(clearBillItemDescription());
  cancelLingeringAPIRequests();
};

export const login = (credentials) => (dispatch) => {
  const [slug, domain] = getCurrentPageSlugAndDomain();

  return dispatch({
    type: LOGIN,
    apiCall: axios.post('/login', {
      ...credentials,
      organizationSlug: slug,
      externalDomain: domain
    }),
    showToastOnError: false,
    onSuccess: () => dispatch(onLoginSuccess())
  });
};

export const register = (userInfo) => (dispatch) =>
  dispatch({
    type: REGISTER,
    apiCall: axios.post(`/register`, userInfo),
    showToastOnError: false
  });

export const resetPassword = (email) => (dispatch) => {
  const [slug] = getCurrentPageSlugAndDomain();

  return dispatch({
    type: RESET_PASSWORD,
    apiCall: axios.post('/resetPassword', { email, organizationSlug: slug }),
    showToastOnError: false
  });
};

export const confirmNewPassword = (email, password, uid) => (dispatch) => {
  const [slug] = getCurrentPageSlugAndDomain();

  return dispatch({
    type: CONFIRM_NEW_PASSWORD,
    apiCall: axios.post(`/resetPassword/${uid}`, {
      email,
      password,
      organizationSlug: slug
    }),
    onSuccess: (notUsed, response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        response
      });
      dispatch(onLoginSuccess());
      dispatch(navigateToDefaultRoute());
      showAlertSuccess('auth:resetPassword.resetPasswordSuccessTitle');
    }
  });
};

export const verifyEmail = (uid, email) => (dispatch, getState) => {
  const url = getUserActivationUrl(getState, uid);

  return dispatch({
    type: VERIFY_EMAIL,
    apiCall: axios.post(url, { email }),
    onSuccess: (notUsed, response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        response
      });
      dispatch(onLoginSuccess());
      dispatch(navigateToDefaultRoute());
    }
  });
};

export const verifyWithPassword = (uid, password) => (dispatch, getState) => {
  const url = getUserActivationUrl(getState, uid);

  if (_.isEmpty(url)) {
    return Promise.reject(new Error('Malformed url'));
  }

  return dispatch({
    type: VERIFY_EMAIL,
    apiCall: axios.post(url, { password }),
    showToastOnError: false,
    onSuccess: (unused, response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        response
      });
      dispatch(onLoginSuccess());
      dispatch(push(baseRoutes.root));
    }
  });
};

export const verifyChangedEmail = (uid) => (dispatch) =>
  dispatch({
    type: VERIFY_CHANGED_EMAIL,
    apiCall: axios.post(`/verifyEmail/${uid}`),
    showToastOnError: false
  });

export const changePassword = (oldPassword, newPassword) => (
  dispatch,
  getState
) => {
  const organizationId = selectOrganizationIdFromToken(getState());
  const userId = selectCurrentUserId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(userId)) {
    return showMissingUserIdError();
  }

  return dispatch({
    type: CHANGE_PASSWORD,
    apiCall: axios.put(
      `/organizations/${organizationId}/users/${userId}/password`,
      { oldPassword, newPassword }
    ),
    noErrorDispatch: true
  });
};

export const validateResources = (
  resources,
  actionType = VALIDATE_RESOURCES
) => (dispatch) => {
  if (!_.isArray(resources)) {
    logger.error(`'resources' param is not of type array: ${typeof resources}`);

    return;
  }

  resources.map((resource) => {
    const actionsToValidate = _.getNonEmpty(resource, 'actions', [
      VALIDATE_READ_BINARY
    ]);

    resource.actions = getResourceActionValue(actionsToValidate);

    return resource;
  });

  return dispatch({
    type: actionType,
    apiCall: axios.post('/validate', resources)
  });
};
