import produce from 'immer';
import _ from 'lodash';

import { createActionTypeArray } from '../common/actionTypes';

import { LOGOUT } from '../auth/authReducer';

export const GET_FLOWS = 'flow/GET_FLOWS';
export const GET_FLOW = 'flow/GET_FLOW';
export const UPDATE_FLOW = 'flow/UPDATE_FLOW';
export const ADD_FLOW = 'flow/ADD_FLOW';
export const DELETE_FLOW = 'flow/DELETE_FLOW';

export const [
  GET_FLOWS_IN_PROGRESS,
  GET_FLOWS_SUCCESS,
  GET_FLOWS_FAILURE
] = createActionTypeArray(GET_FLOWS);

export const [
  GET_FLOW_IN_PROGRESS,
  GET_FLOW_SUCCESS,
  GET_FLOW_FAILURE
] = createActionTypeArray(GET_FLOW);

export const [
  UPDATE_FLOW_IN_PROGRESS,
  UPDATE_FLOW_SUCCESS,
  UPDATE_FLOW_FAILURE
] = createActionTypeArray(UPDATE_FLOW);

export const [
  ADD_FLOW_IN_PROGRESS,
  ADD_FLOW_SUCCESS,
  ADD_FLOW_FAILURE
] = createActionTypeArray(ADD_FLOW);

export const [
  DELETE_FLOW_IN_PROGRESS,
  DELETE_FLOW_SUCCESS,
  DELETE_FLOW_FAILURE
] = createActionTypeArray(DELETE_FLOW);

const initialState = {
  list: [],
  loadingList: false,
  detail: null,
  loadingDetail: false
};

// eslint-disable-next-line default-param-last
const flowReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_FLOWS_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_FLOWS_SUCCESS:
        const flows = _.get(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = _.sortBy(flows, 'name');
        break;
      case GET_FLOWS_FAILURE:
        draft.loadingList = false;
        break;
      case GET_FLOW_IN_PROGRESS:
      case UPDATE_FLOW_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_FLOW_SUCCESS:
      case UPDATE_FLOW_SUCCESS:
        const flow = _.get(payload, 'response.data', null);

        draft.loadingDetail = false;
        draft.detail = flow;
        break;
      case GET_FLOW_FAILURE:
      case UPDATE_FLOW_FAILURE:
        draft.loadingDetail = false;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default flowReducer;
