/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { InputGroupAddon } from 'reactstrap';

import PropTypes from 'prop-types';

const InputGroupAddonWrapper = ({ children, addonType, className, id }) => (
  <InputGroupAddon addonType={addonType} className={className} id={id}>
    {children}
  </InputGroupAddon>
);

InputGroupAddonWrapper.propTypes = {
  addonType: PropTypes.oneOf(['prepend', 'append']).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
};

InputGroupAddonWrapper.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined
};

export default InputGroupAddonWrapper;
