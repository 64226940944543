import _ from 'lodash';

export const handleLocalStorageChange = (e) => {
  const { key, oldValue, newValue } = e;

  if (key === 'token') {
    const didLogin = _.isEmptySafe(oldValue) && !_.isEmptySafe(newValue);
    const didLogout = !_.isEmptySafe(oldValue) && _.isEmptySafe(newValue);

    if (didLogin || didLogout) {
      window.location.reload(true);
    }
  }
};
