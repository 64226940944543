export default {
  settings: {
    title: {
      settings: 'Settings',
      account: 'Account settings',
      calendar: 'Calendar settings',
      billing: 'Invoice settings',
      documents: 'Document settings',
      automatedCheckIn: 'Automated check-in',
      therapy: 'Drug therapy settings',
      booking: 'Online booking'
    }
  },
  help: 'Help',
  magicbell: {
    header: {
      title: 'Notifications',
      markAllRead: 'Mark all read'
    },
    notification: {
      markAsRead: 'Mark as read',
      markAsUnread: 'Mark as unread',
      delete: 'Delete'
    },
    messages: {
      emptyInbox: `All clear! We'll let you know when there's more.`,
      noInternetConnection: `Hmm, we’re unable to connect to the internet. Please check your connection.`,
      serverError: `We can’t seem to retrieve your notifications. Please check back soon.`,
      inboxImgAlt: 'Empty inbox',
      newNotificationToast: 'New notification just arrived!'
    }
  }
};
