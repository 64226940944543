import produce from 'immer';
import _ from 'lodash';

import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../../utils/constants/tableConstants';
import moshiConfig from '../../../utils/moshiConfig';
import {
  setFilterToStoreDraft,
  setFiltersToStoreDraft
} from '../../../utils/redux/listUtils';

import {
  CLEAR_CREDIT_NOTE_DETAIL,
  CLEAR_CREDIT_NOTE_PAYMENTS_LIST,
  CLEAR_CREDIT_NOTE_LIST_FILTER,
  GET_CREDIT_NOTE_DETAIL_FAILURE,
  GET_CREDIT_NOTE_DETAIL_IN_PROGRESS,
  GET_CREDIT_NOTE_DETAIL_SUCCESS,
  GET_CREDIT_NOTE_LIST_FAILURE,
  GET_CREDIT_NOTE_LIST_IN_PROGRESS,
  GET_CREDIT_NOTE_LIST_SUCCESS,
  GET_CREDIT_NOTE_PAYMENTS_LIST_FAILURE,
  GET_CREDIT_NOTE_PAYMENTS_LIST_IN_PROGRESS,
  GET_CREDIT_NOTE_PAYMENTS_LIST_SUCCESS,
  SET_CREDIT_NOTE_LIST_FILTER,
  SET_CREDIT_NOTE_LIST_FILTERS,
  SET_CREDIT_NOTE_LIST_ORDER,
  SET_CREDIT_NOTE_LIST_PAGINATION,
  PATCH_CREDIT_NOTE_SUCCESS,
  UPDATE_CREDIT_NOTE_SUCCESS,
  REMOVE_CREDIT_NOTE_SUCCESS,
  CLEAR_CREDIT_NOTE_LIST_FILTER_NO_POLL,
  SET_CREDIT_NOTE_LIST_INCLUDE_PARAM
} from './creditNoteTypes';

import { LOGOUT } from '../../auth/authReducer';

import CreditNoteModel from '../../../metadata/model/billing/bill/CreditNoteModel';

export const initialState = {
  list: [],
  loadingList: false,
  detail: null,
  loadingDetail: false,
  include: '',
  filter: {
    sortBy: 'issuedAt',
    order: ORDER_DESCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: _.get(moshiConfig, 'list.pagination.limit', 20),
    page: 1
  },
  payments: {
    loadingPaymentsList: false,
    list: []
  }
};

// eslint-disable-next-line default-param-last
const creditNoteReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_CREDIT_NOTE_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_CREDIT_NOTE_LIST_SUCCESS:
        const responsePayload = _.get(payload, 'response', payload);
        const billingCreditNotes = _.get(responsePayload, 'data', []);

        const maxResultsRaw = _.get(
          responsePayload,
          'headers.x-total-count',
          draft.pagination.limit
        );
        const maxResults = _.parseInt(maxResultsRaw);

        draft.pagination.resultCount = maxResults;
        draft.pagination.pageCount = _.ceil(
          maxResults / draft.pagination.limit
        );

        draft.loadingList = false;
        draft.list = _.sortBy(billingCreditNotes, 'name');
        break;
      case GET_CREDIT_NOTE_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case GET_CREDIT_NOTE_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_CREDIT_NOTE_DETAIL_SUCCESS:
      case UPDATE_CREDIT_NOTE_SUCCESS:
      case PATCH_CREDIT_NOTE_SUCCESS:
      case REMOVE_CREDIT_NOTE_SUCCESS:
        const creditNote = CreditNoteModel.fromDto(
          _.get(payload, 'response.data', {})
        );

        draft.loadingDetail = false;
        draft.detail = creditNote;
        break;
      case GET_CREDIT_NOTE_DETAIL_FAILURE:
        draft.loadingDetail = false;
        break;
      case CLEAR_CREDIT_NOTE_DETAIL:
        draft.detail = null;
        break;
      case SET_CREDIT_NOTE_LIST_FILTER:
        setFilterToStoreDraft(draft, payload, initialState.pagination.page);

        break;
      case SET_CREDIT_NOTE_LIST_FILTERS:
        const filters = _.getNonEmpty(payload, 'filters', []);

        setFiltersToStoreDraft(draft, filters, initialState.pagination.page);

        break;
      case SET_CREDIT_NOTE_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.get(payload, 'order', ORDER_ASCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_CREDIT_NOTE_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case GET_CREDIT_NOTE_PAYMENTS_LIST_IN_PROGRESS:
        draft.payments.loadingPaymentsList = true;
        break;
      case GET_CREDIT_NOTE_PAYMENTS_LIST_SUCCESS:
        const creditNotePayments = _.get(payload, 'response.data', []);

        draft.payments.list = creditNotePayments;
        break;
      case GET_CREDIT_NOTE_PAYMENTS_LIST_FAILURE:
        draft.payments.loadingPaymentsList = false;
        break;
      case CLEAR_CREDIT_NOTE_PAYMENTS_LIST:
        draft.payments = {
          loadingPaymentsList: false,
          list: []
        };
        break;
      case CLEAR_CREDIT_NOTE_LIST_FILTER:
      case CLEAR_CREDIT_NOTE_LIST_FILTER_NO_POLL:
        draft.filter = initialState.filter;
        break;
      case SET_CREDIT_NOTE_LIST_INCLUDE_PARAM:
        draft.include = _.get(payload, 'includeValue');
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default creditNoteReducer;
