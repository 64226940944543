import { formatting } from './date';

export default {
  title: 'Online booking',
  calendarDescription:
    'By clicking on the calendar, you can add a time frame to determine when online booking is possible.',
  tableDescription: 'Create and manage templates for online booking.',
  newTemplate: 'New template',
  newTemplateTitle: 'New template for online booking',
  editTemplate: 'Edit template',
  form: {
    mainTitle: 'Name your template',
    templateName: 'Template name',
    descriptionHeader: 'Description',
    descriptionText:
      'This text will be visible to patients when making a booking.',
    descriptionPlaceholder:
      'Add summary and any details that patients need to be aware of when making a booking.',
    category: 'Category',
    categoryPlaceholder: 'Select a category',
    secondaryTitle: 'Length of booked appointment',
    price: 'Price',
    dateRange: 'Date range',
    futureEvent:
      '<0>Patients can book an appointment up to</0> <1></1> <2></2> <0>into the future</0>',
    futureEventDescription: `If a patients makes a booking today {{ currentDate, ${formatting.shortDate} }},
     they will be able to make an appointment between today and {{ futureDate, ${formatting.shortDate} }}.`,
    availabilityIncrement: 'Availability increment',
    availabilityIncrementDescription:
      'Show available times in the following increments',
    availabilityIncrementExample: `e.g: {{ time0, ${formatting.time} }}, {{ time1, ${formatting.time} }}, {{ time2, ${formatting.time} }}, 
     {{ time3, ${formatting.time} }}, {{ time4, ${formatting.time} }}, {{ time5, ${formatting.time} }}`,
    preventEventsHeader: 'Limits on “last minute” bookings',
    preventEvents:
      '<0>Prevent bookings that are less than</0> <1></1> <2></2> <0>away</0>',
    preventCancelingHeader: 'Prevent booking cancellation',
    cancelEvents:
      '<0>Prevent canceling of bookings that are less than </0> <1></1> <2></2> <0>away</0>',
    preventReschedulingHeader: 'Prevent rescheduling of bookings',
    reschedulingEvents:
      '<0>Prevent rescheduling of bookings that are less than</0> <1></1> <2></2> <0>away</0>'
  },
  alerts: {
    templateCreated: 'Template was successfully created',
    templateUpdated: 'Template was successfully updated',
    templateRemoved: 'Template was successfully removed',
    generalSettingsSaved: 'Booking settings saved'
  },
  templates: {
    active: 'Active templates',
    inactive: 'Inactive templates',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any templates yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `
  },
  calendarForm: {
    slot: 'Slot',
    addSlot: '+ add another slot',
    addSecondaryAssignee: '+ add secondary staff',
    primaryAssignee: 'Primary assignee',
    secondaryAssignee: 'Secondary staff',
    addStaff: 'Add staff',
    addScheduleForTemplate: 'Add schedule for {{templateName}}',
    editScheduleForTemplate: 'Edit schedule for {{templateName}}',
    repeatingSchedule: 'Repeating booking schedule',
    deleteThisScheduleOnly: 'Delete this schedule only',
    editThisScheduleOnly: 'Edit this schedule only',
    deleteUpcomingSchedules: 'Delete upcoming schedules',
    editUpcomingSchedules: 'Edit upcoming schedules',
    repeatingScheduleDeleteInfo: `You are deleting a booking schedule that repeats weekly. 
      Deleting this upcoming schedule will overwrite <0>{{templateName}}</0> ongoing <1>{{dayName}}</1> schedule.`,
    repeatingScheduleEditInfo: `You are editing a booking schedule that repeats weekly. Edit this 
      upcoming schedule will overwrite <0>{{templateName}}</0> ongoing <1>{{dayName}}</1> schedule.`
  },
  settingTabs: {
    general: 'General',
    templateSchedules: 'Template schedules',
    templates: 'Templates'
  },
  generalSettings: {
    description:
      'Customize look and feel of website for online booking. Define terms of use and set restrictions on cancellations and rescheduling of appointments.',
    chooseColorLabel: 'Organization color',
    chooseColor: 'Choose a color',
    logoUrl: 'Organization logo',
    logoDescription:
      'Choose an image that will be displayed on your website. We recommend an image of size 1000 x 1000 px.',
    welcomeMessage: 'Welcome message',
    welcomeMessageDetails: 'Welcome message details',
    timeUnit: {
      hours: 'hours',
      days: 'days',
      weeks: 'weeks',
      months: 'months'
    }
  },
  schedule: {
    warning:
      'Please note that the visible time slots will be a result of the overlap between working hours (if set) ' +
      'of a chosen assignee or resource and the booking schedule you are trying to set below. <0/> <1>Check working hours </1>'
  }
};
