import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import { showMissingOrganizationIdError } from '../../utils/alert';
import { removeSpacesYupTransform } from '../../utils/yupExtensions';

import {
  ADD_DEVICE,
  GET_DEVICES,
  GET_NEW_DEVICE_INFO,
  REMOVE_DEVICE
} from '../core/cache/cacheTypes';

export const removeDevice = (id) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return {
    type: REMOVE_DEVICE,
    apiCall: axios.delete(`/organizations/${organizationId}/devices/${id}`),
    onSuccess: ({ dispatch }) => {
      dispatch(getDevices());
    }
  };
};

export const getDevices = () => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return {
    type: GET_DEVICES,
    apiCall: axios.get(`/organizations/${organizationId}/devices`)
  };
};

export const addDevice = (deviceData) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const data = {
    otp: removeSpacesYupTransform(deviceData.deviceOtp),
    locationID: deviceData.deviceLocation
  };

  return dispatch({
    type: ADD_DEVICE,
    apiCall: axios.post(`/organizations/${organizationId}/devices/`, data),
    onSuccess: () => {
      dispatch(getDevices());
    }
  });
};
export const getDevice = (deviceId) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  dispatch({
    type: GET_NEW_DEVICE_INFO,
    apiCall: axios.get(`organizations/${organizationId}/devices/${deviceId}`)
  });
};
