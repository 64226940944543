import _ from 'lodash';

class LocationGeoModel {
  constructor(geo) {
    this.longitude = _.get(geo, 'longitude', 0);
    this.latitude = _.get(geo, 'latitude', 0);
  }
}

export default LocationGeoModel;
