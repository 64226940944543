import axios from 'axios';
import handlebars from 'handlebars';
import _ from 'lodash';
import moment from 'moment';

import { getEncounterUrl, getPatientDetailUrl } from '../../../utils/api';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../../utils/constants/networkConstants';
import { ORDER_ASCENDING } from '../../../utils/constants/tableConstants';
import { getMillisecondsEpoch } from '../../../utils/date';
import { getJsonSchemaFormData } from '../../../utils/form';
import { asyncPromiseWrapper } from '../../../utils/gen';
import logger from '../../../utils/logger';
import moshiConfig from '../../../utils/moshiConfig';
import { safeGlobalDispatch } from '../../../utils/redux/storeUtils';
import { getWidgetName, orderWidgetsByGroups } from '../../../utils/widgets';
import {
  WIDGET_TYPE_DENTAL_CHART_SUMMARY,
  WIDGET_TYPE_DENTAL_CHART_V1,
  WIDGET_TYPE_DENTAL_CHART_V2,
  WIDGET_TYPE_DENTAL_CHART_V3
} from '../../../widgets/widgetConstants';

import { getQuestionnaire } from '../../questionnaire/questionnaireActions';

import {
  CLEAR_WIDGET_FORM_DATA,
  GET_WIDGET_LIST,
  SET_WIDGET_EDIT_MODE,
  UPDATE_WIDGET_TRIGGER,
  REMOVE_WIDGET_TRIGGER,
  SET_CURRENT_ENCOUNTER_ID,
  ADD_LOCAL_WIDGET,
  REMOVE_LOCAL_WIDGET,
  GET_WIDGET_HISTORY_TRIGGER,
  GET_PATIENT_WIDGET_LIST,
  GET_ALL_PATIENT_WIDGET_LIST,
  CLEAR_ALL_PATIENT_WIDGET_LIST,
  SET_ALL_PATIENT_WIDGET_FILTER,
  SET_FOCUSED_WIDGET_ID,
  CLEAR_FOCUSED_WIDGET_ID,
  SET_WIDGET_IDS_TO_SUBMIT,
  CLEAR_WIDGET_IDS_TO_SUBMIT,
  SET_WIDGET_FORM_DATA_TRIGGER,
  ADD_WIDGET_TRIGGER
} from './widgetTypes';

import {
  selectOrganizationDefaultWidgetIdList,
  selectOrganizationWidgetExportSchema,
  selectOrganizationWidgetSchema
} from '../../organization/organizationSelectors';
import {
  selectWidgetListByEncounter,
  selectWidgetsInEditMode
} from './widgetSelectors';

import { QUESTIONNAIRE_TYPE } from '../../../containers/patient/documents/AddDocumentsToFlow';

export const addLocalWidget = (widgetId) => (dispatch) => {
  dispatch({
    type: ADD_LOCAL_WIDGET,
    widgetId
  });
};

export const removeLocalWidget = (widgetId) => (dispatch) => {
  dispatch({
    type: REMOVE_LOCAL_WIDGET,
    widgetId
  });
};

export const setLocalWidgetData = (widget, jsonSchema) => (dispatch) =>
  dispatch({
    type: SET_WIDGET_FORM_DATA_TRIGGER,
    widget,
    formData: _.getNonEmpty(jsonSchema, 'formData', undefined),
    schema: jsonSchema.schema
  });

export const clearLocalWidgetData = (widgetId) => (dispatch) => {
  dispatch({
    type: CLEAR_WIDGET_FORM_DATA,
    widgetId
  });
};

export const setWidgetEditMode = (widgetId, editMode) => (dispatch) => {
  dispatch({
    type: SET_WIDGET_EDIT_MODE,
    widgetId,
    editMode
  });
};

export const getEncounterWidgets = (
  encounterId,
  params = undefined,
  actionType = GET_WIDGET_LIST
) => (dispatch, getState) => {
  const encounterUrl = getEncounterUrl(getState, encounterId);

  dispatch({ type: SET_CURRENT_ENCOUNTER_ID, encounterId });

  return dispatch({
    type: actionType,
    apiCall: axios.get(`${encounterUrl}/widgets`, { params }),
    onSuccess: (none, response) => {
      const widgets = _.get(response, 'data', []);

      dispatch(processOutdatedLocalWidgetIds(widgets));
    }
  });
};

export const addEncounterWidget = (
  encounterId,
  widget,
  skipAddingOnErrors = false
) => (dispatch) =>
  dispatch({
    type: ADD_WIDGET_TRIGGER,
    encounterId,
    widget,
    skipAddingOnErrors
  });

export const updateEncounterWidget = (encounterId, widget) => (dispatch) =>
  dispatch({ type: UPDATE_WIDGET_TRIGGER, encounterId, widget });

export const removeEncounterWidget = (encounterId, widget) => (dispatch) =>
  dispatch({
    type: REMOVE_WIDGET_TRIGGER,
    encounterId,
    widget
  });

export const addOrUpdateEncounterWidget = (encounterId, widget) => (
  dispatch
) => {
  if (widget.id === widget.widgetType) {
    return dispatch(addEncounterWidget(encounterId, widget));
  } else {
    return dispatch(updateEncounterWidget(encounterId, widget));
  }
};

export const processOutdatedLocalWidgetIds = (widgets) => (
  dispatch,
  getState
) => {
  const localWidgetDataStaleDuration =
    moshiConfig.widgetConfig.localWidgetDataStaleDuration;
  const localWidgets = _.get(getState(), 'widget.localWidgetData', []);

  if (_.isEmpty(localWidgets)) {
    return;
  }

  _.forEach(widgets, (widget) => {
    const localWidget = _.find(localWidgets, { id: widget.id });

    if (_.isEmpty(localWidget)) {
      return;
    }

    const localWidgetDataStaleOnMilliseconds =
      getMillisecondsEpoch(localWidget.localLastModifiedOn) +
      localWidgetDataStaleDuration;
    const currentTimeMilliseconds = moment().valueOf();

    if (
      widget.versionID !== localWidget.versionID ||
      currentTimeMilliseconds > localWidgetDataStaleOnMilliseconds
    ) {
      dispatch(clearLocalWidgetData(localWidget.id));
      dispatch(removeLocalWidget(localWidget.id));
      dispatch(setWidgetEditMode(localWidget.id, false));
    }
  });
};

export const getPatientWidgetList = (
  patientId,
  actionType = GET_PATIENT_WIDGET_LIST,
  widgetParams = undefined
) => (dispatch, getState) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  return dispatch({
    type: actionType,
    apiCall: axios.get(`${patientUrl}/widgets`, {
      params: widgetParams
    })
  });
};

export const getWidgetHistory = (patientId, widgetType) => (dispatch) =>
  dispatch({ type: GET_WIDGET_HISTORY_TRIGGER, patientId, widgetType });

export const getAllPatientWidgetList = (patientId) => (dispatch) => {
  const excludeWidgetTypes = [
    WIDGET_TYPE_DENTAL_CHART_V1,
    WIDGET_TYPE_DENTAL_CHART_V2,
    WIDGET_TYPE_DENTAL_CHART_V3,
    WIDGET_TYPE_DENTAL_CHART_SUMMARY
  ].join(',');

  return dispatch(
    getPatientWidgetList(patientId, GET_ALL_PATIENT_WIDGET_LIST, {
      excludeWidgetTypes,
      latestOnly: true,
      includeContent: true,
      limit: REQUEST_PAGINATION_MAX_LIMIT
    })
  );
};

export const clearAllPatientWidgetList = () => (dispatch) =>
  dispatch({
    type: CLEAR_ALL_PATIENT_WIDGET_LIST
  });

export const setAllPatientWidgetListFilter = (filterName, filterValue) => (
  dispatch
) => {
  dispatch({
    type: SET_ALL_PATIENT_WIDGET_FILTER,
    filterName,
    filterValue
  });
};

export const setFocusedWidgetId = (widgetId) => (dispatch) =>
  dispatch({ type: SET_FOCUSED_WIDGET_ID, widgetId });

export const markFirstEditableWidgetAsFocused = () => (dispatch, getState) => {
  const state = getState();
  const editModeWidgets = selectWidgetsInEditMode(state);

  if (_.isEmptySafe(editModeWidgets)) {
    return;
  }

  const ordered = orderWidgetsByGroups(editModeWidgets, state);
  const firstWidgetId = _.getNonEmpty(_.first(ordered), 'id');

  return dispatch(setFocusedWidgetId(firstWidgetId));
};

export const clearFocusedWidgetId = () => (dispatch) =>
  dispatch({ type: CLEAR_FOCUSED_WIDGET_ID });

export const setWidgetIdsToSubmit = (widgetIds) => (dispatch) =>
  dispatch({ type: SET_WIDGET_IDS_TO_SUBMIT, widgetIds });

export const clearWidgetIdsToSubmit = () => (dispatch) =>
  dispatch({ type: CLEAR_WIDGET_IDS_TO_SUBMIT });

const isInvalidWidget = (
  widgetType,
  defaultWidgetIds,
  foundWidget,
  encounter,
  isQuestionnaire
) => {
  if (isQuestionnaire) {
    return false;
  }

  /**
   * When encounter is empty it means that the function is being called for Patient past data,
   * which means we cant render FLOW and DOCTOR, since we do not have that data.
   * Because of that we skip those widgets.
   *
   * In the second case we check if foundWidget is empty and if current widget is one of the default widgets,
   * which get its data from encounter object. In that case we do not care about the data of found widget.
   */
  if (_.includes(defaultWidgetIds, widgetType)) {
    return _.isEmptySafe(encounter);
  } else {
    return _.isEmptySafe(foundWidget);
  }
};

const getQuestionnaireSchema = async (widgetType) => {
  const questionnaireId = getWidgetName(widgetType);

  // eslint-disable-next-line no-await-in-loop
  const [questionnaire, err] = await asyncPromiseWrapper(
    safeGlobalDispatch(getQuestionnaire(questionnaireId))
  );

  if (!_.isEmptySafe(err)) {
    return null;
  }

  return getJsonSchemaFormData(
    _.getNonEmpty(questionnaire, 'formBuilderSchema', {})
  );
};

// eslint-disable-next-line max-statements
const transformEncounterDataToHtml = async (
  widgetList,
  store,
  encounter = null
) => {
  const widgetSchema = selectOrganizationWidgetSchema(store);
  const defaultWidgetIds = selectOrganizationDefaultWidgetIdList(store);
  const exportSchema = selectOrganizationWidgetExportSchema(store);

  const exportedData = [];

  const orderedExportSchema = _.orderBy(exportSchema, 'order', ORDER_ASCENDING);

  for (let index = 0; index < orderedExportSchema.length; index++) {
    const widgetExportSchema = orderedExportSchema[index];

    const widgetType = _.getNonEmpty(widgetExportSchema, 'id', null);
    const isQuestionnaire = _.includes(widgetType, QUESTIONNAIRE_TYPE);
    const foundWidget = _.find(widgetList, (widget) =>
      _.includes(widget.widgetType, widgetType)
    );

    if (
      isInvalidWidget(
        widgetType,
        defaultWidgetIds,
        foundWidget,
        encounter,
        isQuestionnaire
      )
    ) {
      continue;
    }
    const foundWidgetSchema = _.getAndClone(widgetSchema, widgetType, {});

    if (isQuestionnaire) {
      // eslint-disable-next-line no-await-in-loop
      const questionnaireSchema = await getQuestionnaireSchema(widgetType);

      if (_.isEmptySafe(questionnaireSchema)) {
        continue;
      }

      foundWidgetSchema.formSchema = questionnaireSchema.schema;
      foundWidgetSchema.uiSchema = questionnaireSchema.uiSchema;
    }

    const schema = _.getNonEmpty(foundWidgetSchema, 'formSchema', {});
    const uiSchema = _.getNonEmpty(foundWidgetSchema, 'uiSchema', {});
    const data = _.getNonEmpty(foundWidget, 'data', {});
    const extraData = _.getNonEmpty(widgetExportSchema, 'extraData', {});

    const templateContext = {
      schema,
      uiSchema,
      data,
      encounter,
      extraData
    };

    const template = handlebars.compile(widgetExportSchema.template);
    const html = template(templateContext);

    exportedData.push(html);
  }

  return exportedData.join('');
};

export const exportEncounterData = (encounter) => async (
  dispatch,
  getState
) => {
  const store = getState();
  const widgetList = selectWidgetListByEncounter(store, encounter.id);

  const exportedData = await transformEncounterDataToHtml(
    widgetList,
    store,
    encounter
  );

  logger.log(exportedData);

  return exportedData;
};

export const exportAllEncounterData = (widgetGroupList) => async (
  dispatch,
  getState
) => {
  const store = getState();
  const widgetList = _.flatten(_.map(widgetGroupList, 'widgets'));

  const exportedData = await transformEncounterDataToHtml(
    widgetList,
    store,
    store
  );

  logger.log(exportedData);

  return exportedData;
};
