import classNames from 'classnames';
import _ from 'lodash';

// prettier-ignore
export const asyncPromiseWrapper = (promise) => promise
  .then((result) => [result])
  .catch((err) => [null, err]);

export const asyncDelay = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const slugify = (text) => _.kebabCase(text);

export const generateArrayOfNumbers = (
  numberOfNumbers,
  start = 0,
  reverseArray = false
) =>
  reverseArray
    ? _.range(start, start + numberOfNumbers).reverse()
    : _.range(start, start + numberOfNumbers);

export const generateEhrId = (count = 1) => {
  const countString = count.toString();

  return `id${countString.padStart(3, '0')}`;
};

export default {
  asyncPromiseWrapper,
  asyncDelay,
  guid,
  generateArrayOfNumbers
};

export const moshiClassNames = (...params) => {
  const classes = classNames(...params);

  return _.isEmpty(classes) ? undefined : classes;
};

export const convertBytesToSize = (bytes) => {
  if (_.isNumber(bytes)) {
    const sizes = ['B', 'kB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }
};
