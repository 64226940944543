export default {
  title: 'Obravnava',
  new: 'Nova obravnava',
  titleWithFlow: 'Obravnava: {{ flow }}',
  comments: 'Komentarji',
  noComments: 'Tu še ni nobenega komentarja.',
  addComment: 'Dodajte komentar',
  author: 'Avtor',
  markAsComplete: 'Označite kot zaključeno',
  sidebarActiveEncounters: 'Aktivne obravnave',
  closeModal: {
    title: 'Zaključite obravnavo',
    doctorsNotes: `Zapiski zdravnika`,
    addNote: 'Dodajte zapisek',
    newAppointmentNeeded: 'Ali je potreben nov obisk?',
    memoForNextAppointment: 'Opomba za naslednji obisk.',
    addMemo: 'Dodajte zapisek'
  },
  closeSuccess: {
    title: 'Obravnava je uspešno zaključena.',
    summary: 'Povzetek',
    checkInType: 'Vrsta vpisa',
    doctorNotes: `Zdravnikovi zapiski ob obravnavi`,
    followUp: 'Naslednji obisk'
  },
  doctorLocationTime: '{{ doctor }} ∙ $t(common:locationTime)',
  questionnaires: 'Vprašalniki',
  documents: 'Pravni dokumenti',
  addCommentPlaceholder: 'Dodajte komentar...',
  widget: {
    add: '$t(common:addData)',
    notFound: 'Prišlo je do napake pri nalaganju gradnika',
    notFoundWithTitle:
      'Prišlo je do napake pri nalaganju gradnika: {{ widgetTitle }}',
    administrativeData: {
      title: 'Administrativni podatki',
      date: 'Datum',
      attendingPhysician: 'Dodeljeni zdravnik',
      typeOfEncounter: {
        title: 'Tip obravnave',
        officeVisit: 'Obisk pri zdravniku',
        telemedicalConsultation: 'Telemedicinski posvet',
        HomeVisit: 'Obisk na domu'
      },
      reasonForEncounter: {
        title: 'Razlog obravnave',
        acuteProblem: 'Akutna težava',
        acuteProblemFollowUp: 'Akutna težava - kontrolni pregled',
        chronicProblemRoutine: 'Kronična težava - redni pregled',
        chronicProblemFollowUp: 'Kronična težava - kontrolni pregled',
        prenatalCare: 'Predporodna nega',
        postnatalCare: 'Poporodna nega',
        postoperativeCare: 'Postoperativna nega',
        wellAdultExam: 'Preventivni pregled - odrasli',
        wellChildExam: 'Preventivni pregled - otroci',
        familyPlanning: 'Načrtovanje družine',
        counselingAdvice: 'Posvetovalnica',
        Immunization: 'Imunizacija',
        referredBy: 'Napoten od',
        administrativePurpose: 'Administrativni razlog',
        other: 'Drugo'
      }
    },
    vitalSigns: {
      title: 'Vitalni znaki',
      bodyHeight: 'Višina',
      bodyMass: 'Teža',
      bodyTemperature: 'Telesna temperatura',
      heartRate: 'Srčni utrip',
      oxygenSaturation: 'Saturacija kisika',
      respirationRate: 'Frekvenca dihanja',
      bloodGlucose: 'Sladkor v krvi',
      bloodPressure: 'Krvni tlak',
      bloodPressureSystolic: 'Sistolični krvni tlak',
      bloodPressureDiastolic: 'Diastolični krvni tlak'
    },
    problemDescription: {
      title: 'Opis težave',
      chiefComplaint: 'Razlog obiska',
      historyOfChiefComplaint: 'Razvoj poglavitnega simptoma',
      symptomsByPatient: 'Simptomi po opisu pacienta (subjektivno)',
      symptomsByDoctor: 'Simptomi po opredelitvi zdravnika (objektivno)'
    },
    healthHistory: {
      title: 'Splošna anamneza',
      pastIllness: 'Pomembne pretekle bolezni',
      currentIllness: 'Trenutne bolezni in spremljajoča stanja',
      chronicConditions: 'Kronične bolezni',
      surgicalHistory: 'Zgodovina operativnih posegov',
      familyHistory: 'Družinska anamneza',
      childhoodDiseases: 'Otroške bolezni',
      regularAndAcuteMedications: 'Trenutna in redna zdravila',
      allergies: 'Alergije',
      sexualHistory: 'Spolno zdravje',
      socioEconomicData: 'Socialno-ekonomska anamneza',
      environmentalData: 'Okoljski dejavniki'
    },
    procedures: {
      title: 'Posegi',
      undertaken: 'Opravljeni posegi',
      testsUndertaken: 'Opravljene preiskave'
    },
    diagnosis: {
      title: 'Diagnoza',
      description: 'Diagnoza'
    },
    conclusion: {
      title: 'Zaključek',
      treatments: 'Zdravljenja & predpisani ukrepi',
      medications: 'Predpisana zdravila'
    },
    notesAndSummaries: {
      title: 'Opombe in povzetki',
      forPatient: 'Zabeležke namenjene pacientu',
      discharge: 'Odpustno pismo',
      personal: 'Osebne zabeležke & komentarji'
    },
    attachedDocuments: {
      title: 'Priloženi dokumenti',
      documents: 'Priloženi dokumenti'
    },
    dropdown: {
      placeholderText: 'Izberite vrednost'
    }
  },
  waitingList: {
    alert: {
      encounterCreated: 'Dodan je bil nov obiskovalec ({{ patientName }}).',
      encounterUpdated:
        'Kartica obiskovalca je bila spremenjena ({{ patientName }}).',
      encounterMoved:
        'Kartica obiskovalca je bila premaknjena ({{ patientName }}).',
      encounterArchived:
        'Kartica obiskovalca je bila arhivirana ({{ patientName }}).',
      encounterDeleted: 'Obiskovalec je bil odstranjen. ({{ patientName }}).',
      allEncountersArchived:
        'Stolpec "$t(dashboard:completed)" je bil arhiviran',
      comment: {
        updated: 'Komentar je bil posodobljen',
        deleted: 'Komentar je bil odstranjen'
      }
    }
  },
  pastEncounters: {
    title: 'Pretekle obravnave',
    noPastEncounters: 'Ta oseba zaenkrat še ni imela nobene obravnave.'
  },
  pastData: {
    title: 'Pretekli podatki'
  }
};
