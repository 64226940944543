import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  showAlertSuccess,
  showMissingOrganizationIdError
} from '../../utils/alert';
import { getOrganizationUrl } from '../../utils/api';
import {
  getOrganizationFileUrl,
  getPublicOrganizationFileUrl,
  getOrganizationExportUrl
} from '../../utils/api/apiUrlUtils';
import { DELETED_WORKING_HOUR_TIME } from '../../utils/constants/workingHoursConstants';
import {
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_TYPE_SMS
} from '../../utils/data/notifications';

import { EMPTY_ACTION_TYPE } from '../common/actionTypes';
import { uploadFile } from '../core/file/fileActions';

import { GET_BOOKING_TERMS_AND_CONDITIONS } from '../booking/bookingTypes';
import {
  GET_CURRENT_ORGANIZATION,
  GET_CURRENT_ORGANIZATION_WIDGETS,
  UPDATE_ORGANIZATION,
  EXPORT_ORGANIZATION_DATA
} from './organizationTypes';

import { selectCurrentBookingTermsAndConditionsUrl } from '../booking/bookingSelectors';
import { selectCachedOrganizationId } from '../core/cache/cacheSelectors';
import {
  selectCurrentOrganizationNotificationSettings,
  selectCurrentOrganizationOtherSettings,
  selectCurrentOrganizationWebUISettings
} from './organizationSelectors';

import OrganizationModel from '../../metadata/model/OrganizationModel';
import BillingSettingsModel from '../../metadata/model/billing/BillingSettingsModel';

// eslint-disable-next-line max-statements
const prepareNotificationsPayload = (getState, formData) => {
  const notificationSettings = selectCurrentOrganizationNotificationSettings(
    getState()
  );
  const newNotificationSettings = _.getNonEmpty(
    formData,
    'notificationsSettings',
    {}
  );

  delete notificationSettings?.appointmentReminders?.periods;
  const newPeriods = _.getNonEmpty(
    newNotificationSettings,
    'appointmentReminders.periods'
  );

  const safePeriods = _.filter(
    newPeriods,
    (period) => !_.isEmptySafe(period, 'period')
  );

  const oldAppointmentReminders = _.getNonEmpty(
    notificationSettings,
    'appointmentReminders',
    {}
  );
  const newAppointmentReminders = _.getNonEmpty(
    newNotificationSettings,
    'appointmentReminders',
    {}
  );

  const newAppointmentsRemindersChannels = [];

  const isAppointmentReminderSmsEnabled = _.getNonEmpty(
    newAppointmentReminders,
    'smsEnabled',
    false
  );
  const isAppointmentReminderEmailEnabled = _.getNonEmpty(
    newAppointmentReminders,
    'emailEnabled',
    false
  );

  if (isAppointmentReminderSmsEnabled) {
    newAppointmentsRemindersChannels.push(NOTIFICATION_DELIVERY_TYPE_SMS);
  }
  if (isAppointmentReminderEmailEnabled) {
    newAppointmentsRemindersChannels.push(NOTIFICATION_DELIVERY_TYPE_EMAIL);
  }

  if (_.isEmptySafe(newAppointmentsRemindersChannels)) {
    _.set(newAppointmentReminders, 'enabled', false);
  } else {
    _.set(newAppointmentReminders, 'enabled', true);
  }

  const oldAppointmentSms = _.getNonEmpty(oldAppointmentReminders, 'sms', {});
  const newAppointmentSms = _.getNonEmpty(newAppointmentReminders, 'sms', {});

  delete newAppointmentReminders?.smsEnabled;
  delete newAppointmentReminders?.emailEnabled;

  const payload = {
    ...notificationSettings,
    ...newNotificationSettings,
    appointmentReminders: {
      ...oldAppointmentReminders,
      ...newAppointmentReminders,
      periods: safePeriods,
      channels: newAppointmentsRemindersChannels,
      sms: {
        ...oldAppointmentSms,
        ...newAppointmentSms
      }
    }
  };

  return payload;
};

export const getWidgetsConfigFileByUrl = (fileUrl) => ({
  type: GET_CURRENT_ORGANIZATION_WIDGETS,
  apiCall: axios.get(fileUrl, {
    responseType: 'json'
  })
});

export const getCurrentOrganization = () => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_CURRENT_ORGANIZATION,
    apiCall: axios.get(`/organizations/${organizationId}`),
    onSuccess: () => {
      const fileUrl = _.get(
        getState(),
        'organization.currentOrganization.widgetsConfig',
        null
      );

      if (!_.isEmpty(fileUrl)) {
        dispatch(getWidgetsConfigFileByUrl(fileUrl));
      }
    }
  });
};

export const updateOrganization = (
  organizationData,
  includeBillingSettings = false
) => {
  const payload = OrganizationModel.getPayload(
    organizationData,
    includeBillingSettings
  );

  return {
    type: UPDATE_ORGANIZATION,
    apiCall: axios.put(`/organizations/${organizationData.id}`, payload),
    onSuccess: ({ dispatch, getState }) => {
      const currentOrganizationId = _.get(
        getState(),
        'organization.currentOrganization.id'
      );

      // Update current user in store if it was just updated
      if (currentOrganizationId === organizationData.id) {
        dispatch(getCurrentOrganization());
      }
    }
  };
};

export const updateOrganizationFile = (fileId, file, meta, options) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch(
    uploadFile(
      `/organizations/${organizationId}/files/${fileId}`,
      file,
      { meta },
      { ...options, method: 'put' }
    )
  );
};

export const updateOrganizationBillingSettings = (
  billingSettings,
  onSuccess = undefined
) => (dispatch, getState) => {
  const payload = {
    billingSettings: BillingSettingsModel.getPayload(billingSettings)
  };

  return dispatch({
    type: UPDATE_ORGANIZATION,
    apiCall: axios.put(getOrganizationUrl(getState), payload),
    onSuccess
  });
};

export const updateOrganizationCalendarSettings = (data) => (
  dispatch,
  getState
) => {
  const startTime = _.getNonEmpty(data, 'displayHours.startTime');
  const endTime = _.getNonEmpty(data, 'displayHours.endTime');

  _.set(data, 'displayHours.startTime', moment(startTime).format('HH:mm'));
  _.set(data, 'displayHours.endTime', moment(endTime).format('HH:mm'));

  /**
   * Our time picker does not support 24:00. For the validation to pass
   * we need to set the 24:00 manually.
   */
  if (
    _.getNonEmpty(data, 'displayHours.endTime') === DELETED_WORKING_HOUR_TIME
  ) {
    _.set(data, 'displayHours.endTime', '24:00');
  }

  const webUiSettings = selectCurrentOrganizationWebUISettings(getState());

  return dispatch({
    type: UPDATE_ORGANIZATION,
    apiCall: axios.put(getOrganizationUrl(getState), {
      webUISettings: { ...webUiSettings, calendar: data }
    }),
    onSuccess: () => {
      showAlertSuccess('calendar:alert.calendarSettingsSaved');
    }
  });
};

export const updateOrganizationPdfGenerationSettings = (
  { headerId, footerId },
  onSuccess = _.noop
) => (dispatch, getState) => {
  const otherSettings = selectCurrentOrganizationOtherSettings(getState());

  return dispatch({
    type: UPDATE_ORGANIZATION,
    apiCall: axios.put(getOrganizationUrl(getState), {
      otherSettings: {
        ...otherSettings,
        pdfGeneration: {
          ...otherSettings.pdfGeneration,
          headerFileID: headerId,
          footerFileID: footerId
        }
      }
    }),
    onSuccess
  });
};

export const updateOrganizationNotificationSettings = (formData) => (
  dispatch,
  getState
) => {
  const payload = prepareNotificationsPayload(getState, formData);

  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: UPDATE_ORGANIZATION,
    apiCall: axios.put(organizationUrl, { notificationsSettings: payload })
  });
};

export const addOrganizationFile = (file, meta, options) => (
  dispatch,
  getState
) => {
  const orgFileUrl = getOrganizationFileUrl(getState);

  return dispatch(uploadFile(orgFileUrl, file, { meta }, options));
};

export const addPublicOrganizationFile = (file, meta, options) => (
  dispatch,
  getState
) => {
  const publicFileUrl = getPublicOrganizationFileUrl(getState);

  return dispatch(uploadFile(publicFileUrl, file, { meta }, options));
};

export const getPublicOrganizationFile = (
  url,
  actionType = EMPTY_ACTION_TYPE
) => (dispatch) =>
  dispatch({
    type: actionType,
    apiCall: axios.get(url, {
      withCredentials: false
    })
  });

export const updateOrganizationBookingSettings = (formData, uploadTerms) => (
  dispatch,
  getState
) => {
  const currentTermsAndConditionsUrl = selectCurrentBookingTermsAndConditionsUrl(
    getState()
  );

  const payload = _.cloneDeep(formData);
  const organizationUrl = getOrganizationUrl(getState);
  const termsAndConditions = _.getNonEmpty(
    payload,
    'automatedBooking.termsAndConditions',
    ''
  );

  /**
   *   If terms were not changed (not dirty) we do not upload a new public file,
   *   we just sent the current url
   */
  if (!uploadTerms) {
    _.set(
      payload,
      'automatedBooking.termsAndConditions',
      currentTermsAndConditionsUrl
    );

    return dispatch({
      type: UPDATE_ORGANIZATION,
      apiCall: axios.put(organizationUrl, payload),
      onSuccess: () => {
        showAlertSuccess('booking:alerts.generalSettingsSaved');
      }
    });
  }

  return dispatch(
    addPublicOrganizationFile(
      termsAndConditions,
      {
        customType: 'bookingTermsAndConditions'
      },
      {
        onSuccess: (skip, response) => {
          const fileUrl = _.getNonEmpty(response, 'data.url', '');

          _.set(payload, 'automatedBooking.termsAndConditions', fileUrl);

          return dispatch({
            type: UPDATE_ORGANIZATION,
            apiCall: axios.put(organizationUrl, payload),
            onSuccess: () => {
              showAlertSuccess('booking:alerts.generalSettingsSaved');
              dispatch(
                getPublicOrganizationFile(
                  fileUrl,
                  GET_BOOKING_TERMS_AND_CONDITIONS
                )
              );
            }
          });
        }
      }
    )
  );
};

export const scheduleOrganizationExport = () => (dispatch, getState) => {
  const url = getOrganizationExportUrl(getState);

  return dispatch({
    type: EXPORT_ORGANIZATION_DATA,
    apiCall: axios.post(url),
    onSuccess: () => {
      dispatch(getCurrentOrganization());
    }
  });
};
