import randomColor from 'randomcolor';

import PersonModel from './PersonModel';
import UserRightModel from './UserRightModel';

class UserModel extends PersonModel {
  constructor(prepareFormData = false, withAdminRights = false, id = '') {
    super(id);
    this.pendingEmail = '';
    this.userRights = [];
    this.getFullName = () => '';
    this.color = randomColor();
    this.hasCalendar = false;

    if (prepareFormData) {
      this.mobileNotifications = false;
      this.emailNotifications = false;
      this.smsNotifications = false;
      this.userRights.push(new UserRightModel());
    }

    if (withAdminRights) {
      this.isOrganizationAdmin = false;
      this.localPatientsOnly = false;
    }
  }
}

export default UserModel;
