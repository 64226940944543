import _ from 'lodash';

import { NO_TAX } from '../../tax-mappers';

export const getElectronicDeviceDisplayName = (
  businessPremiseLabel,
  electronicDeviceLabel
) => `${businessPremiseLabel}, ${electronicDeviceLabel}`;

export const electronicDeviceMapper = (businessPremises) => {
  const electronicDevices = [];

  _.forEach(businessPremises, (premise) => {
    const devices = _.get(premise, 'devices', []);

    _.forEach(devices, (device) =>
      electronicDevices.push({
        premiseId: premise.id,
        premiseLabel: premise.label,
        name: `${premise.label} - ${device.label}`,
        ...device
      })
    );
  });

  return electronicDevices;
};

export const mapAllPremisesToDevices = (businessPremises) => {
  const devices = {};

  _.forEach(businessPremises, (unitPremises, billingUnitId) => {
    const unitDevices = electronicDeviceMapper(unitPremises);

    devices[billingUnitId] = unitDevices;
  });

  return devices;
};

export const mapUnitPremisesToDevices = (
  businessPremises,
  selectedBillingUnitId
) => {
  if (_.isEmpty(selectedBillingUnitId)) {
    return [];
  }

  const selectedPremises = _.get(businessPremises, selectedBillingUnitId, []);

  return electronicDeviceMapper(selectedPremises);
};

export const advancePaymentTypesFilter = (payment) =>
  !_.isEmptySafe(payment, 'advancePaymentData.advanceID');

export const createMapTaxRatesMapper = (taxList) => (taxId) => {
  const foundTaxItem = _.find(taxList, { id: taxId });

  return _.isEmptySafe(foundTaxItem)
    ? NO_TAX
    : _.getNonEmpty(foundTaxItem, 'taxRates.0.rate', NO_TAX);
};

export const createPaymentTypeDropdownMapper = (translate) => (
  paymentType
) => ({
  id: paymentType.id,
  name: translate(`billing:invoices.paymentTypes.${paymentType.id}`)
});
