import PropTypes from 'prop-types';

export const CoreFormikFieldShape = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export const FormikBooleanFieldShape = {
  ...CoreFormikFieldShape,
  value: PropTypes.bool
};

export const FormikStringFieldShape = {
  ...CoreFormikFieldShape,
  value: PropTypes.string
};

export const FormikDropdownFieldShape = {
  ...CoreFormikFieldShape,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

export const FormikDateFieldShape = {
  ...CoreFormikFieldShape,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

export const FormikNullableObjectFieldShape = {
  ...CoreFormikFieldShape,
  value: PropTypes.shape()
};

export const FormikNullableStringFieldShape = {
  ...CoreFormikFieldShape,
  value: PropTypes.string
};

export const FormikNullableBlobFieldShape = {
  ...CoreFormikFieldShape,
  value: PropTypes.shape()
};

export const FormikErrorsShape = { errors: PropTypes.shape().isRequired };
export const FormikTouchedShape = { errors: PropTypes.shape().isRequired };

export const FormikFormShape = {
  ...FormikErrorsShape,
  ...FormikTouchedShape
};

export const FormikConnectShape = {
  values: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired
};

export default FormikFormShape;
