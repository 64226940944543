export default {
  missingOrganizationId:
    'Zaradi neznanega razloga nimamo podatkov o vaši organiziciji. Funkcionalnost aplikacije bo morda okrnjena.',
  missingUserId:
    'Zaradi neznanega razloga nimamo podatkov o prijavljenem uporabniku. Funkcionalnost aplikacije bo morda okrnjena.',
  missingPatientId:
    'Zaradi neznanega razloga nimamo podatkov o pacientu. Funkcionalnost aplikacije bo morda okrnjena.',
  missingLocationId:
    'Zaradi neznanega razloga nimamo podatkov o vaši izbrani lokaciji. Funkcionalnost aplikacije bo morda okrnjena.',
  missingEncounterId:
    'Zaradi neznanega razloga nimamo podatkov o vaši izbrani obravnavi. Funkcionalnost aplikacije bo morda okrnjena.',
  missingCommentId:
    'Zaradi neznanega razloga nimamo podatkov o komentarju. Funkcionalnost aplikacije bo morda okrnjena.',
  missingAssistRequestId:
    'Zaradi neznanega razloga nimamo podatkov o zahtevku za pomoč. Funkcionalnost aplikacije bo morda okrnjena.',
  missingBillingUnitId:
    'Zaradi neznanega razloga nimamo podatkov o vaši izbrani poslovni enoti. Funkcionalnost aplikacije bo morda okrnjena.',
  missingRegistryId:
    'Zaradi neznanega razloga nimamo podatkov o vašem izbranem šifrantu. Funkcionalnost aplikacije bo morda okrnjena.',
  authTokenExpired: 'Seja je potekla, prosim prijavite se še enkrat.',
  general:
    'Prišlo je do napake na strežniku. Prosimo, da pokusite še enkrat čez nekaj minut.',
  export: {
    alreadyScheduled:
      '$t(organizationSettings:export.validation.alreadyStarted)'
  },
  serverError: '$t(error:general)',
  internalServerError: '$t(error:general)',
  connection: 'Napaka pri povezavi s strežnikom.',
  invalidValue: 'Vnešena vrednost je napačna.',
  patternError: 'Vnesli ste vrednost v neustrezni obliki.',
  badRequest:
    'Prišlo je do napake na strežniku. Prosimo, da čez nekaj trenutkov poskusite znova.',
  planUpgradeRequired:
    'V trenutno izbranem paketu izbrana možnost ni na voljo.',
  forbidden: 'Nimate ustreznih pravic za izvedbo zahteve.',
  notFound: 'Zahtevane entitete ni bilo mogoče najti.',
  tooManyRequests: 'Omejitev količine zahtevkov.',
  internalError: 'Prišlo je do napake na strežniku.',
  unauthorized: 'Niste pooblaščeni za izvedbo akcije.',
  invalidUrl:
    'URL naslov, ki ste ga vnesli, ni pravilen. Prosimo preverite vnešeni naslov in poskusite znova.',
  user: {
    alreadyMember:
      'Izbran e-mail naslov je že uporabljen pri obstoječem uporabniku',
    notFound: 'Vnešeno uporabniško ime in/ali geslo ni pravilno',
    email: {
      conflict:
        'Uporabnik s tem elektronskim naslovom že obstaja. Prosimo, če uporabite drug elektronski naslov.'
    }
  },
  operator: {
    fursMissing:
      'Izdaja računa ni mogoča, saj nimate dodane osebne davčne številke'
  },
  furs: {
    alreadyEnabled: 'Davčno potrjevanje je bilo že aktivirano',
    certificateOrPassphraseInvalid: 'Naložen certifikat ali geslo sta napačna.'
  },
  invalidDate: 'Vnešen datum ni veljaven.',
  organization: {
    slug: {
      conflict:
        'Izbrano ime organizacije je žal že zasedeno. Prosimo, uporabite drugo ime.'
    },
    ipNotAllowed: 'Dostop iz vašega IP naslova ni dovoljen.'
  },
  document: {
    notFiscalised: 'Davčno potrjevanje dokumenta ni uspelo.',
    invoicingIDNotSet: 'Nimate vklopljenega davčnega potrjevanja.'
  },
  item: {
    quantityZero: 'Količina ne sme biti nič.'
  },
  billingUnit: {
    fiscalisationNotEnabled: 'Podjetje nima vklopljenega davčnega potrjevanja.',
    notActive: 'Podjetje ni aktivno.'
  },
  token: {
    expired: 'Zaradi neaktivnosti ste bili odjavljeni.',
    unauthorized: '$t(error:forbidden)'
  },
  doctorID: {
    invalid: 'Izbran zdravnik na tem pacientu ne obstaja.'
  },
  statusChange: {
    encounterExists: 'Pacient je že označen kot prišel v čakalnici.'
  },
  code: {
    entityNotFound: '$t(auth:acceptInvite.descriptionFailure)'
  },
  password: {
    incorrect: 'Vnešeno geslo ni pravilno'
  },
  patient: {
    notAllowed: 'Nimate ustreznih pravic za ogled pacienta',
    entityNotFound: 'Pacient ne obstaja ali je izbrisan'
  },
  therapy: {
    entityNotFound: 'Terapija ne obstaja ali je izbrisana'
  },
  email: {
    invalid: 'Elektronski naslov ni veljaven'
  },
  pauseAt: {
    beforeLastPause: 'Terapije ni mogoče zaustaviti dvakrat v istem dnevu'
  },
  resumeFrom: {
    beforePausedFrom:
      'Terapija se ne more nadaljevati z datumom pred začasno ustavitvijo.'
  }
};
