export const INDEXES_CALCULATION_ERROR_WIDGET = 'indexes/CALCULATION_ERROR/v1';

export const WIDGET_CALCULATION_ERRORS = {
  CALCULATION_ERROR: 'calculation-error',
  NO_WIDGET_VALUE: 'no-widget-value',
  NO_FORM_VALUE: 'no-form-value',
  NO_HISTORY_WIDGET_VALUE: 'no-history-widget-value',
  NO_MAIN_DIAGNOSIS: 'no-main-diagnosis',
  NO_CLINICAL_OUTLOOK: 'no-clinical-outlook'
};

export const WIDGET_COMPONENT_HOMUNCULUS = 'humanoidWidget';
export const WIDGET_COMPONENT_QUESTIONNAIRE = 'questionnaireWidget';
export const WIDGET_TYPE_QUESTIONNAIRE_SIGNATURE = 'SignatureWidget';
