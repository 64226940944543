import _ from 'lodash';
import moment from 'moment';

import {
  handleBillDocumentBillingUnitChange,
  INVOICE_STATUS_ISSUED
} from '../../../../utils/billing';
import { getLocalStorageValue } from '../../../../utils/browserStorage';
import { tempBillingItemInfoStorageKey } from '../../../../utils/constants/browserStorageConstants';
import { getDateFormat } from '../../../../utils/date';
import { EMPTY_GUID } from '../../../../utils/gen';
import { removeEmptyTaxFromBill } from '../../../../utils/mappers/tax-mappers';

import EntityBaseModel from '../../EntityBaseModel';
import BillClientModel from './BillClientModel';
import BillItemModel from './BillItemModel';
import BillTextsModel from './BillTextsModel';

class BillBaseModel extends EntityBaseModel {
  constructor(id) {
    super(id);

    const currentDate = moment().format('YYYY/MM/DD');

    this.billingUnitID = '';
    this.client = new BillClientModel();
    this.currency = 'EUR';
    this.date = currentDate;
    this.doctorID = '';
    this.locationID = '';
    this.patientID = '';
    this.encounterID = '';
    this.invoicingID = '';
    this.items = [];
    this.locale = 'sl';
    this.number = '';
    this.referencedDocuments = [];
    this.texts = new BillTextsModel();
  }

  sumItemsBy(fieldName, roundBy = 4) {
    if (_.isEmpty(this.items)) {
      return 0;
    }

    const rawSum = _.sumBy(this.items, fieldName);

    return _.round(rawSum, roundBy);
  }

  setPreferredDataInput(billingUnitList, preferredBillingData, defaultLocale) {
    let billingUnitID = _.get(preferredBillingData, 'billingUnitID', null);

    if (_.hasOneItem(billingUnitList)) {
      billingUnitID = _.first(billingUnitList).id;
    }

    const businessPremiseID = _.get(preferredBillingData, 'businessPremiseID');
    const electronicDeviceID = _.get(
      preferredBillingData,
      'electronicDeviceID'
    );

    const modelSetter = (fieldName, value) => {
      _.set(this, fieldName, value);
    };

    const billingUnit = handleBillDocumentBillingUnitChange(
      billingUnitList,
      billingUnitID,
      modelSetter,
      defaultLocale
    );

    if (_.isEmptySafe(billingUnit, 'id')) {
      billingUnitID = this.billingUnitID;
    }

    this.billingUnitID = billingUnitID;

    if (_.isEmpty(businessPremiseID) && _.isEmpty(electronicDeviceID)) {
      return billingUnit;
    }

    if (_.isEmpty(this.furs)) {
      this.furs = {};
    }

    this.furs.businessPremiseID = businessPremiseID;
    this.furs.electronicDeviceID = electronicDeviceID;

    return billingUnit;
  }

  static fromDto(dto) {
    const assignedBill = _.nestedAssign(new BillBaseModel([]), dto);

    if (!_.isEmptySafe(assignedBill, 'items')) {
      assignedBill.items = _.nestedListAssign(
        assignedBill.items,
        BillItemModel
      );
    }

    assignedBill.date = moment(assignedBill.date).toISOString();

    return assignedBill;
  }

  static createFromUnbilledItems(unbilledItems) {
    const itemsList = {
      items: _.map(unbilledItems, (item) =>
        BillItemModel.fromUnbilledItem(item)
      )
    };

    return _.nestedAssign(BillBaseModel, itemsList);
  }

  static getPayload(model, defaultLocale) {
    const nullableFields = [
      'patientID',
      'doctorID',
      'encounterID',
      'invoicingID',
      'id'
    ];
    let payload = { ...model };

    payload = EntityBaseModel.removeEntityMetadata(payload);

    payload.client = BillClientModel.getPayload(model.client);
    payload.texts = BillTextsModel.getPayload(model.texts);

    payload.date = getDateFormat(model.date, 'YYYY-MM-DD');

    payload.total = model.total;
    payload.totalTax = model.totalTax;

    const billId = _.getNonEmpty(model, 'id', EMPTY_GUID);

    payload.items = model.items.map((item, index) => {
      const billingItemInfo = getLocalStorageValue(
        tempBillingItemInfoStorageKey,
        {}
      );

      const storedItem = _.getNonEmpty(
        billingItemInfo,
        `${billId}.items.${index}`,
        null
      );

      const storedItemName = _.getNonEmpty(storedItem, 'name', null);

      // Needs to be get to differentiate between nullable values and empty string
      const storedItemDescription = _.get(storedItem, 'description');

      if (!_.isEmptySafe(storedItemName)) {
        item.name = storedItemName;
      }

      if (_.isString(storedItemDescription)) {
        item.description = storedItemDescription;
      }

      return BillItemModel.getPayload(item, defaultLocale);
    });

    _.forIn(payload, (value, key) => {
      if (_.includes(nullableFields, key) && _.isEmpty(value)) {
        delete payload[key];
      }
    });

    if (_.has(payload, 'isNumberUnique')) {
      delete payload.isNumberUnique;
    }
    if (_.has(payload, 'referencedDocuments')) {
      delete payload.referencedDocuments;
    }

    payload = removeEmptyTaxFromBill(payload);

    return payload;
  }

  get currentStatus() {
    if (_.isEmpty(this.status) || !_.isArray(this.status)) {
      return INVOICE_STATUS_ISSUED;
    } else {
      return _.first(this.status);
    }
  }

  get isNew() {
    const emptyId = this.id === EMPTY_GUID;
    const missingId = _.isEmpty(this.id);

    return emptyId || missingId;
  }

  get isDraft() {
    return this.isNew;
  }

  get priceWithDiscount() {
    return this.sumItemsBy('priceWithDiscount');
  }

  get total() {
    return this.sumItemsBy('total', 2);
  }

  get discount() {
    return this.sumItemsBy('totalDiscount');
  }

  get totalTax() {
    return this.sumItemsBy('taxAmount', 2);
  }

  get tax() {
    return this.sumItemsBy('tax');
  }

  set currentStatus(val) {
    // This setter is present so that mappers don't fail
  }

  set total(val) {
    // This setter is present so that mappers don't fail
  }

  set totalTax(val) {
    // This setter is present so that mappers don't fail
  }

  set discount(val) {
    // This setter is present so that mappers don't fail
  }
}

export default BillBaseModel;
