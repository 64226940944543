import produce from 'immer';
import _ from 'lodash';

import { createActionTypeArray } from '../../common/actionTypes';

import { LOGOUT } from '../../auth/authReducer';

export const GET_PATIENT_CAVE = 'patients/GET_PATIENT_CAVE';
export const GET_PATIENT_CAVE_FILES = 'patients/GET_PATIENT_CAVE_FILES';
export const CLEAR_PATIENT_CAVE = 'patients/CLEAR_PATIENT_CAVE';

export const [
  GET_PATIENT_CAVE_IN_PROGRESS,
  GET_PATIENT_CAVE_SUCCESS,
  GET_PATIENT_CAVE_FAILURE
] = createActionTypeArray(GET_PATIENT_CAVE);

const initialState = {
  data: {},
  fileId: null,
  loading: false
};

// eslint-disable-next-line default-param-last
const caveReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_PATIENT_CAVE_IN_PROGRESS:
        draft.loading = true;
        break;
      case GET_PATIENT_CAVE_SUCCESS:
        const caveData = _.getNonEmpty(payload, 'response.data', {});
        const fileId = _.get(payload, 'fileId', null);

        draft.data = caveData;
        draft.fileId = fileId;
        draft.loading = false;
        break;
      case GET_PATIENT_CAVE_FAILURE:
        draft.loading = false;
        break;
      case CLEAR_PATIENT_CAVE:
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default caveReducer;
