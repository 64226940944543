import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { from, timer, of } from 'rxjs';
import {
  switchMap,
  takeUntil,
  map,
  withLatestFrom,
  catchError,
  filter,
  merge
} from 'rxjs/operators';

import { storeDispatch } from '../configureStore';
import {
  getOrganizationUrl,
  getLocationUrl,
  getFormTemplateUrl
} from '../utils/api/apiUrlUtils';
import {
  EVENT_TYPE_ENCOUNTER,
  EVENT_TYPE_QUEUE
} from '../utils/constants/calendarConstants';
import { FILTER_ALL_ID } from '../utils/constants/dropdownConstants';
import {
  MOSHI_FILE_TYPE_DOCUMENT,
  MOSHI_FILE_TYPE_QUESTIONNAIRE
} from '../utils/constants/fileConstants';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../utils/constants/networkConstants';
import { dispatchError } from '../utils/middleware/apiMiddleware';

import { EMPTY_ACTION_TYPE } from '../redux/common/actionTypes';

import {
  ADD_BILLING_UNIT_SUCCESS,
  UPDATE_BILLING_UNIT_SUCCESS,
  REMOVE_BILLING_UNIT_SUCCESS,
  ADD_TAX_SUCCESS,
  UPDATE_TAX_SUCCESS,
  REMOVE_TAX_SUCCESS,
  ADD_BUSINESS_PREMISE_SUCCESS,
  ADD_ELECTRONIC_DEVICE_SUCCESS,
  ENABLE_FURS_SUCCESS
} from '../redux/billing/billingTypes';
import {
  ADD_CASH_REGISTER_LOG_SUCCESS,
  REMOVE_CASH_REGISTER_LOG_SUCCESS,
  UPDATE_CASH_REGISTER_LOG_SUCCESS
} from '../redux/billing/cash-register/cashRegisterTypes';
import {
  ADD_BILLING_CATEGORY_SUCCESS,
  REMOVE_BILLING_CATEGORY_SUCCESS,
  UPDATE_BILLING_CATEGORY_SUCCESS
} from '../redux/billing/category/billingCategoryTypes';
import { ADD_ESTIMATE_SUCCESS } from '../redux/billing/estimate/estimateTypes';
import { ADD_INVOICE_SUCCESS } from '../redux/billing/invoice/invoiceTypes';
import {
  ADD_EVENT_SUCCESS,
  GET_CALENDAR_WAITING_LIST_SUCCESS,
  GET_EVENTS_SUCCESS,
  REMOVE_EVENT_SUCCESS,
  SET_CALENDAR_SELECTED_DATE_RANGE,
  SET_SELECTED_CALENDAR_ASSIGNEES,
  UPDATE_EVENT_SUCCESS
} from '../redux/calendar/calendarTypes';
import {
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS
} from '../redux/calendar/category/categoryTypes';
import {
  START_POLLING,
  STOP_POLLING,
  POLL_ORGANIZATION,
  POLL_LOCATIONS,
  POLL_DOCUMENTS,
  POLL_QUESTIONNAIRES,
  POLL_FLOWS,
  POLL_WAITING_LIST_CONFIG,
  POLL_WAITING_LIST_ENCOUNTERS,
  POLL_DEVICES,
  POLL_BILLING_UNITS,
  POLL_TAXES,
  REFRESH_WAITING_LIST_ENCOUNTERS,
  ADD_DEVICE_SUCCESS,
  REMOVE_DEVICE_SUCCESS,
  POLL_CATEGORIES,
  POLL_BILLING_UNIT_PREMISES,
  POLL_WAITING_LIST_TODAYS_EVENTS,
  POLL_PATIENT_SCHEMA,
  POLL_BILLING_CATEGORY_LIST,
  START_DEVICE_POLLING,
  STOP_DEVICE_POLLING,
  START_CALENDAR_EVENT_POLLING,
  STOP_CALENDAR_EVENT_POLLING,
  MANUAL_CREATE_WAITING_LIST_ENCOUNTER,
  MANUAL_UPDATE_WAITING_LIST_ENCOUNTER,
  MANUAL_REMOVE_WAITING_LIST_ENCOUNTER,
  MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER
} from '../redux/core/cache/cacheTypes';
import {
  ADD_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS
} from '../redux/document/documentTypes';
import {
  ADD_FORM_TEMPLATE_SUCCESS,
  REMOVE_FORM_TEMPLATE_SUCCESS,
  UPDATE_FORM_TEMPLATE_SUCCESS
} from '../redux/form/template/formTemplateTypes';
import { UPDATE_ORGANIZATION_SUCCESS } from '../redux/organization/organizationTypes';
import {
  ARCHIVE_CLOSED_ENCOUNTERS_SUCCESS,
  ARCHIVE_ENCOUNTER_SUCCESS,
  CREATE_ENCOUNTER_COMMENT_SUCCESS,
  DELETE_ENCOUNTER_COMMENT_SUCCESS,
  UPDATE_ENCOUNTER_COMMENT_SUCCESS,
  CREATE_ENCOUNTER_SUCCESS,
  MOVE_WAITING_LIST_ITEM_SUCCESS,
  REMOVE_ENCOUNTER_SUCCESS,
  UPDATE_ENCOUNTER_SUCCESS,
  UPDATE_WAITING_LIST_FILTER,
  REMOVE_WAITING_LIST_ENCOUNTER_SUCCESS,
  CLEAR_WAITING_LIST_FILTER
} from '../redux/patient/encounter/encounterTypes';
import {
  UPDATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTIONNAIRE_SUCCESS,
  DELETE_QUESTIONNAIRE_SUCCESS
} from '../redux/questionnaire/questionnaireTypes';

import {
  UPDATE_FLOW_SUCCESS,
  ADD_FLOW_SUCCESS,
  DELETE_FLOW_SUCCESS,
  UPDATE_FLOW_FAILURE
} from '../redux/flow/flowReducer';
import {
  ADD_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  REMOVE_LOCATION_SUCCESS,
  UPDATE_SELECTED_LOCATION,
  GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS
} from '../redux/location/locationReducer';

import {
  selectAreCanceledEventsVisible,
  selectCalendarFromDate,
  selectCalendarSelectedAssigneesIds,
  selectCalendarToDate
} from '../redux/calendar/calendarSelectors';
import {
  selectWaitingListCategoryFilterValue,
  selectWaitingListDoctorFilterValue
} from '../redux/patient/encounter/encounterSelectors';

const timerBase = moment.duration(10, 'minutes').asMilliseconds();
const staggerMultiplier = moment.duration(1, 'seconds').asMilliseconds();

let staggerIncrementer = 0;
const getTimerDelay = (delay = timerBase, shouldStagger = false) => {
  const stagger = staggerIncrementer++ * staggerMultiplier;

  if (shouldStagger) {
    return delay + stagger;
  }

  return delay;
};

const getCacheEpic = (fetchAction, dispatchResponse, config = {}) => (
  action$,
  state$
) => {
  const defaultError = (error) =>
    of({ type: EMPTY_ACTION_TYPE, data: _.get(error, 'response.data', {}) });
  const triggerAction = _.getNonEmpty(config, 'triggerAction', START_POLLING);
  const stopAction = _.getNonEmpty(config, 'stopAction', STOP_POLLING);
  const reFetchActionTypes = _.getNonEmpty(config, 'reFetchActionTypes', []);
  const dispatchErrorHandler = _.getNonEmpty(
    config,
    'dispatchError',
    defaultError
  );

  const delay = getTimerDelay(config.delay);
  // Observable that emits actions that should trigger a reFetch of cached data
  const reFetchAction$ = action$.pipe(
    filter((action) => _.includes(reFetchActionTypes, action.type))
  );

  return action$.pipe(
    ofType(triggerAction),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      timer(0, delay).pipe(
        merge(reFetchAction$),
        takeUntil(action$.ofType(stopAction)),
        map(() => state),
        switchMap(() => fetchAction(state$.value)),
        map(dispatchResponse),
        catchError((error) => {
          dispatchError(storeDispatch, error);

          return dispatchErrorHandler(error);
        })
      )
    )
  );
};

export const organizationCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}`));
  },
  (response) => ({
    type: POLL_ORGANIZATION,
    data: response.data
  }),
  {
    reFetchActionTypes: [UPDATE_ORGANIZATION_SUCCESS]
  }
);

export const locationsCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/locations`));
  },
  (response) => ({
    type: POLL_LOCATIONS,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_LOCATION_SUCCESS,
      UPDATE_LOCATION_SUCCESS,
      REMOVE_LOCATION_SUCCESS
    ]
  }
);

// TODO DOCUMENTS REWORK: Remove documents cache epic, using formSagas now
export const documentsCacheEpic = getCacheEpic(
  (state) => {
    const url = getFormTemplateUrl(() => state);

    if (_.isEmpty(url)) {
      return;
    }

    return from(
      axios.get(url, {
        params: {
          type: MOSHI_FILE_TYPE_DOCUMENT,
          limit: REQUEST_PAGINATION_MAX_LIMIT
        }
      })
    );
  },
  (response) => ({
    type: POLL_DOCUMENTS,
    data: response.data
  }),
  { reFetchActionTypes: [ADD_DOCUMENT_SUCCESS, DELETE_DOCUMENT_SUCCESS] }
);

// TODO DOCUMENTS REWORK: Remove questionnaire cache epic, using formSagas now
export const questionnairesCacheEpic = getCacheEpic(
  (state) => {
    const url = getFormTemplateUrl(() => state);

    if (_.isEmpty(url)) {
      return;
    }

    return from(
      axios.get(url, {
        params: {
          type: MOSHI_FILE_TYPE_QUESTIONNAIRE,
          limit: REQUEST_PAGINATION_MAX_LIMIT
        }
      })
    );
  },
  (response) => ({
    type: POLL_QUESTIONNAIRES,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_FORM_TEMPLATE_SUCCESS,
      UPDATE_FORM_TEMPLATE_SUCCESS,
      REMOVE_FORM_TEMPLATE_SUCCESS,
      UPDATE_QUESTIONNAIRE_SUCCESS,
      CREATE_QUESTIONNAIRE_SUCCESS,
      DELETE_QUESTIONNAIRE_SUCCESS
    ]
  }
);

export const flowsCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/flows`));
  },
  (response) => ({
    type: POLL_FLOWS,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_FLOW_SUCCESS,
      UPDATE_FLOW_SUCCESS,
      DELETE_FLOW_SUCCESS,
      UPDATE_FLOW_FAILURE,
      REMOVE_FORM_TEMPLATE_SUCCESS
    ]
  }
);

export const waitingListConfigCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getLocationUrl(() => state);

    if (_.isEmptySafe(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/waitlistConfig`));
  },
  (response) => ({
    type: POLL_WAITING_LIST_CONFIG,
    data: response.data
  }),
  {
    triggerAction: GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS
  }
);

export const waitingListEncountersCacheEpic = getCacheEpic(
  (state) => {
    const query = _.getNonEmpty(state, 'encounter.filter', {});
    const urlPrefix = getLocationUrl(() => state);

    if (_.isEmptySafe(urlPrefix)) {
      return;
    }

    return from(
      axios.get(`${urlPrefix}/waitlist`, {
        params: query
      })
    );
  },
  (response) => ({
    type: POLL_WAITING_LIST_ENCOUNTERS,
    data: response.data
  }),
  {
    triggerAction: GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS,
    delay: moment.duration(1, 'minutes').asMilliseconds(),
    reFetchActionTypes: [
      REFRESH_WAITING_LIST_ENCOUNTERS,
      MOVE_WAITING_LIST_ITEM_SUCCESS,
      REMOVE_WAITING_LIST_ENCOUNTER_SUCCESS,
      REMOVE_ENCOUNTER_SUCCESS,
      CREATE_ENCOUNTER_SUCCESS,
      UPDATE_ENCOUNTER_SUCCESS,
      CREATE_ENCOUNTER_COMMENT_SUCCESS,
      UPDATE_ENCOUNTER_COMMENT_SUCCESS,
      DELETE_ENCOUNTER_COMMENT_SUCCESS,
      UPDATE_SELECTED_LOCATION,
      UPDATE_WAITING_LIST_FILTER,
      CLEAR_WAITING_LIST_FILTER,
      ARCHIVE_ENCOUNTER_SUCCESS,
      ARCHIVE_CLOSED_ENCOUNTERS_SUCCESS
    ]
  }
);

export const waitingListTodaysEventsCacheEpic = getCacheEpic(
  (state) => {
    const filteredDoctorID = selectWaitingListDoctorFilterValue(state);
    const filteredCategoryID = selectWaitingListCategoryFilterValue(state);

    const queryParams = {
      fromDate: moment().startOf('day').toISOString(),
      toDate: moment().endOf('day').toISOString(),
      types: EVENT_TYPE_ENCOUNTER,
      excludeCheckedIn: true,
      excludeNoShow: true,
      confirmedOnly: true,
      doctorID: filteredDoctorID === FILTER_ALL_ID ? null : filteredDoctorID,
      categoryID:
        filteredCategoryID === FILTER_ALL_ID ? null : filteredCategoryID
    };
    const urlPrefix = getLocationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(
      axios.get(`${urlPrefix}/calendar`, {
        params: queryParams
      })
    );
  },
  (response) => ({
    type: POLL_WAITING_LIST_TODAYS_EVENTS,
    data: response.data
  }),
  {
    triggerAction: GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS,
    delay: moment.duration(1, 'minutes').asMilliseconds(),
    reFetchActionTypes: [
      CLEAR_WAITING_LIST_FILTER,
      UPDATE_WAITING_LIST_FILTER,
      REMOVE_WAITING_LIST_ENCOUNTER_SUCCESS,
      REMOVE_ENCOUNTER_SUCCESS,
      CREATE_ENCOUNTER_SUCCESS,
      UPDATE_ENCOUNTER_SUCCESS,
      UPDATE_SELECTED_LOCATION,
      ARCHIVE_ENCOUNTER_SUCCESS,
      ARCHIVE_CLOSED_ENCOUNTERS_SUCCESS,
      MANUAL_CREATE_WAITING_LIST_ENCOUNTER,
      MANUAL_UPDATE_WAITING_LIST_ENCOUNTER,
      MANUAL_REMOVE_WAITING_LIST_ENCOUNTER,
      MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER,
      UPDATE_EVENT_SUCCESS,
      ADD_EVENT_SUCCESS,
      REMOVE_EVENT_SUCCESS
    ]
  }
);

export const devicesListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/devices`));
  },
  (response) => ({
    type: POLL_DEVICES,
    data: response.data
  }),
  {
    triggerAction: START_DEVICE_POLLING,
    stopAction: STOP_DEVICE_POLLING,
    reFetchActionTypes: [ADD_DEVICE_SUCCESS, REMOVE_DEVICE_SUCCESS]
  }
);

export const categoriesListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/categories`));
  },
  (response) => ({
    type: POLL_CATEGORIES,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_CATEGORY_SUCCESS,
      UPDATE_CATEGORY_SUCCESS,
      DELETE_CATEGORY_SUCCESS
    ]
  }
);

export const billingUnitListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    const params = {
      params: {
        deactivated: 'include'
      }
    };

    return from(axios.get(`${urlPrefix}/billing/units`, params));
  },
  (response) => ({
    type: POLL_BILLING_UNITS,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_BILLING_UNIT_SUCCESS,
      UPDATE_BILLING_UNIT_SUCCESS,
      REMOVE_BILLING_UNIT_SUCCESS,
      ADD_ELECTRONIC_DEVICE_SUCCESS,
      ADD_BUSINESS_PREMISE_SUCCESS,
      ENABLE_FURS_SUCCESS
    ]
  }
);

export const getPremiseList = getCacheEpic(
  (state) => {
    const billingUnitList = _.get(state, 'cache.billingUnit.list');
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    const promiseList = [];
    const billingUnitIds = billingUnitList.map((billingUnit) => billingUnit.id);

    _.forEach(billingUnitIds, (billingUnitId) => {
      promiseList.push(
        axios.get(`${urlPrefix}/billing/units/${billingUnitId}/furs/premises`, {
          params: {
            includeDevices: true,
            includeCashRegisterData: true,
            billingUnitId
          }
        })
      );
    });

    return Promise.all(promiseList);
  },
  (response) => ({
    type: POLL_BILLING_UNIT_PREMISES,
    data: response
  }),
  {
    delay: moment.duration(1, 'minutes').asMilliseconds(),
    triggerAction: POLL_BILLING_UNITS,
    reFetchActionTypes: [
      POLL_BILLING_UNITS,
      ADD_INVOICE_SUCCESS,
      ADD_ESTIMATE_SUCCESS,
      ADD_CASH_REGISTER_LOG_SUCCESS,
      UPDATE_CASH_REGISTER_LOG_SUCCESS,
      REMOVE_CASH_REGISTER_LOG_SUCCESS
    ]
  }
);

export const taxesListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/billing/taxes`));
  },
  (response) => ({
    type: POLL_TAXES,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_TAX_SUCCESS,
      UPDATE_TAX_SUCCESS,
      REMOVE_TAX_SUCCESS
    ]
  }
);

export const billingCategoryListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/billing/categories`));
  },
  (response) => ({
    type: POLL_BILLING_CATEGORY_LIST,
    data: response.data
  }),
  {
    reFetchActionTypes: [
      ADD_BILLING_CATEGORY_SUCCESS,
      UPDATE_BILLING_CATEGORY_SUCCESS,
      REMOVE_BILLING_CATEGORY_SUCCESS
    ]
  }
);

export const patientSchemaCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getOrganizationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(axios.get(`${urlPrefix}/patients/schema`));
  },
  (response) => ({
    type: POLL_PATIENT_SCHEMA,
    data: response.data
  })
);

export const calendarEventListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getLocationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    const fromDate = selectCalendarFromDate(state);
    const toDate = selectCalendarToDate(state);
    const assigneeIDs = selectCalendarSelectedAssigneesIds(state).join(',');
    const includeCanceled = selectAreCanceledEventsVisible(state);
    const fromIsoDate = moment(fromDate).startOf('isoDay').toISOString();
    const toIsoDate = moment(toDate).endOf('isoDay').toISOString();

    const params = {
      fromDate: fromIsoDate,
      toDate: toIsoDate,
      assigneeIDs,
      none: 'null',
      includeCanceled
    };

    /**
     * Backend returns all events if assigneeIDs = [],
     * but we need an [] if no assignees are selected.
     */
    if (_.isEmpty(assigneeIDs)) {
      return Promise.resolve([]);
    }

    if (_.isEmptySafe(params.resourceIDs)) {
      delete params.resourceIDs;
    }

    return from(
      axios.get(`${urlPrefix}/calendar`, {
        params
      })
    );
  },
  (response) => ({
    type: GET_EVENTS_SUCCESS,
    response
  }),
  {
    delay: moment.duration(1, 'minutes').asMilliseconds(),
    triggerAction: START_CALENDAR_EVENT_POLLING,
    stopAction: STOP_CALENDAR_EVENT_POLLING,
    reFetchActionTypes: [
      ADD_EVENT_SUCCESS,
      UPDATE_EVENT_SUCCESS,
      REMOVE_EVENT_SUCCESS,
      SET_SELECTED_CALENDAR_ASSIGNEES,
      SET_CALENDAR_SELECTED_DATE_RANGE,
      UPDATE_SELECTED_LOCATION
    ]
  }
);

export const calendarWaitingListCacheEpic = getCacheEpic(
  (state) => {
    const urlPrefix = getLocationUrl(() => state);

    if (_.isEmpty(urlPrefix)) {
      return;
    }

    return from(
      axios.get(`${urlPrefix}/calendar`, {
        params: {
          types: EVENT_TYPE_QUEUE
        }
      })
    );
  },
  (response) => ({
    type: GET_CALENDAR_WAITING_LIST_SUCCESS,
    response
  }),
  {
    delay: moment.duration(1, 'minutes').asMilliseconds(),
    triggerAction: START_CALENDAR_EVENT_POLLING,
    stopAction: STOP_CALENDAR_EVENT_POLLING,
    reFetchActionTypes: [UPDATE_EVENT_SUCCESS, UPDATE_SELECTED_LOCATION]
  }
);
