/* eslint-disable max-len,max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from '../../../../layout/Visible';

export const HUMANOID_LEFT_HAND_ID = 'left-hand';
const GROUP = `zoomed-${HUMANOID_LEFT_HAND_ID}-interactive`;

const WRIST = `${HUMANOID_LEFT_HAND_ID}-wrist`;

const INDEX_TIP = `${HUMANOID_LEFT_HAND_ID}-index-tip`;
const MIDDLE_TIP = `${HUMANOID_LEFT_HAND_ID}-middle-tip`;
const RING_TIP = `${HUMANOID_LEFT_HAND_ID}-ring-tip`;
const PINKIE_TIP = `${HUMANOID_LEFT_HAND_ID}-pinkie-tip`;

const THUMB_MID = `${HUMANOID_LEFT_HAND_ID}-thumb-mid`;
const INDEX_MID = `${HUMANOID_LEFT_HAND_ID}-index-mid`;
const MIDDLE_MID = `${HUMANOID_LEFT_HAND_ID}-middle-mid`;
const RING_MID = `${HUMANOID_LEFT_HAND_ID}-ring-mid`;
const PINKIE_MID = `${HUMANOID_LEFT_HAND_ID}-pinkie-mid`;

const THUMB_LOWER = `${HUMANOID_LEFT_HAND_ID}-thumb-low`;
const INDEX_LOWER = `${HUMANOID_LEFT_HAND_ID}-index-low`;
const MIDDLE_LOWER = `${HUMANOID_LEFT_HAND_ID}-middle-low`;
const RING_LOWER = `${HUMANOID_LEFT_HAND_ID}-ring-low`;
const PINKIE_LOWER = `${HUMANOID_LEFT_HAND_ID}-pinkie-low`;

const LeftHandInteractive = ({
  getColor,
  onChange,
  initialSelectedParts,
  tipsDisabled,
  lowDisabled,
  wristDisabled
}) => {
  const [checkedList, setCheckedList] = useState(initialSelectedParts);

  const isChecked = useCallback((partId) => _.includes(checkedList, partId), [
    checkedList
  ]);

  useEffect(() => {
    onChange(HUMANOID_LEFT_HAND_ID, checkedList);
  }, [checkedList, onChange]);

  const handleClick = useCallback(
    (partId) => {
      const result = _.pushOrRemoveIntoArray(checkedList, partId);

      setCheckedList(result);
    },
    [checkedList]
  );

  return (
    <g id={GROUP}>
      <Visible visible={!wristDisabled}>
        <g
          id={WRIST}
          onClick={() => handleClick(WRIST)}
          className={'clickable-text'}
        >
          <path
            d={
              'M334 51C334 45.4772 338.477 41 344 41V41C349.523 41 354 45.4772 354 51V51C354 56.5228 349.523 61 344 61V61C338.477 61 334 56.5228 334 51V51Z'
            }
            fill={'white'}
          />
          {isChecked(WRIST) && (
            <circle cx={'344'} cy={'51'} r={'5'} fill={getColor(WRIST)} />
          )}
          <path
            d={
              'M344 59C339.582 59 336 55.4183 336 51H332C332 57.6274 337.373 63 344 63V59ZM352 51C352 55.4183 348.418 59 344 59V63C350.627 63 356 57.6274 356 51H352ZM344 43C348.418 43 352 46.5817 352 51H356C356 44.3726 350.627 39 344 39V43ZM344 39C337.373 39 332 44.3726 332 51H336C336 46.5817 339.582 43 344 43V39Z'
            }
            fill={getColor(WRIST)}
          />
        </g>
      </Visible>

      <Visible visible={!tipsDisabled}>
        <g
          id={INDEX_TIP}
          onClick={() => handleClick(INDEX_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M300 214C300 208.477 304.477 204 310 204V204C315.523 204 320 208.477 320 214V214C320 219.523 315.523 224 310 224V224C304.477 224 300 219.523 300 214V214Z'
            }
            fill={'white'}
          />
          {isChecked(INDEX_TIP) && (
            <circle cx={'310'} cy={'214'} r={'5'} fill={getColor(INDEX_TIP)} />
          )}
          <path
            d={
              'M310 222C305.582 222 302 218.418 302 214H298C298 220.627 303.373 226 310 226V222ZM318 214C318 218.418 314.418 222 310 222V226C316.627 226 322 220.627 322 214H318ZM310 206C314.418 206 318 209.582 318 214H322C322 207.373 316.627 202 310 202V206ZM310 202C303.373 202 298 207.373 298 214H302C302 209.582 305.582 206 310 206V202Z'
            }
            fill={getColor(INDEX_TIP)}
          />
        </g>
        <g
          id={MIDDLE_TIP}
          onClick={() => handleClick(MIDDLE_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M329 228C329 222.477 333.477 218 339 218V218C344.523 218 349 222.477 349 228V228C349 233.523 344.523 238 339 238V238C333.477 238 329 233.523 329 228V228Z'
            }
            fill={'white'}
          />
          {isChecked(MIDDLE_TIP) && (
            <circle cx={'339'} cy={'228'} r={'5'} fill={getColor(MIDDLE_TIP)} />
          )}
          <path
            d={
              'M339 236C334.582 236 331 232.418 331 228H327C327 234.627 332.373 240 339 240V236ZM347 228C347 232.418 343.418 236 339 236V240C345.627 240 351 234.627 351 228H347ZM339 220C343.418 220 347 223.582 347 228H351C351 221.373 345.627 216 339 216V220ZM339 216C332.373 216 327 221.373 327 228H331C331 223.582 334.582 220 339 220V216Z'
            }
            fill={getColor(MIDDLE_TIP)}
          />
        </g>
        <g
          id={RING_TIP}
          onClick={() => handleClick(RING_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M360 222C360 216.477 364.477 212 370 212V212C375.523 212 380 216.477 380 222V222C380 227.523 375.523 232 370 232V232C364.477 232 360 227.523 360 222V222Z'
            }
            fill={'white'}
          />
          {isChecked(RING_TIP) && (
            <circle cx={'370'} cy={'222'} r={'5'} fill={getColor(RING_TIP)} />
          )}
          <path
            d={
              'M370 230C365.582 230 362 226.418 362 222H358C358 228.627 363.373 234 370 234V230ZM378 222C378 226.418 374.418 230 370 230V234C376.627 234 382 228.627 382 222H378ZM370 214C374.418 214 378 217.582 378 222H382C382 215.373 376.627 210 370 210V214ZM370 210C363.373 210 358 215.373 358 222H362C362 217.582 365.582 214 370 214V210Z'
            }
            fill={getColor(RING_TIP)}
          />
        </g>
        <g
          id={PINKIE_TIP}
          onClick={() => handleClick(PINKIE_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M387 198C387 192.477 391.477 188 397 188V188C402.523 188 407 192.477 407 198V198C407 203.523 402.523 208 397 208V208C391.477 208 387 203.523 387 198V198Z'
            }
            fill={'white'}
          />
          {isChecked(PINKIE_TIP) && (
            <circle cx={'397'} cy={'198'} r={'5'} fill={getColor(PINKIE_TIP)} />
          )}
          <path
            d={
              'M397 206C392.582 206 389 202.418 389 198H385C385 204.627 390.373 210 397 210V206ZM405 198C405 202.418 401.418 206 397 206V210C403.627 210 409 204.627 409 198H405ZM397 190C401.418 190 405 193.582 405 198H409C409 191.373 403.627 186 397 186V190ZM397 186C390.373 186 385 191.373 385 198H389C389 193.582 392.582 190 397 190V186Z'
            }
            fill={getColor(PINKIE_TIP)}
          />
        </g>
      </Visible>

      <g
        id={THUMB_MID}
        onClick={() => handleClick(THUMB_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M265 129C265 123.477 269.477 119 275 119C280.523 119 285 123.477 285 129C285 134.523 280.523 139 275 139C269.477 139 265 134.523 265 129Z'
          }
          fill={'white'}
        />
        {isChecked(THUMB_MID) && (
          <circle cx={'275'} cy={'129'} r={'5'} fill={getColor(THUMB_MID)} />
        )}
        <path
          d={
            'M275 137C270.582 137 267 133.418 267 129H263C263 135.627 268.373 141 275 141V137ZM283 129C283 133.418 279.418 137 275 137V141C281.627 141 287 135.627 287 129H283ZM275 121C279.418 121 283 124.582 283 129H287C287 122.373 281.627 117 275 117V121ZM275 117C268.373 117 263 122.373 263 129H267C267 124.582 270.582 121 275 121V117Z'
          }
          fill={getColor(THUMB_MID)}
        />
      </g>
      <g
        id={INDEX_MID}
        onClick={() => handleClick(INDEX_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M303 191C303 185.477 307.477 181 313 181C318.523 181 323 185.477 323 191C323 196.523 318.523 201 313 201C307.477 201 303 196.523 303 191Z'
          }
          fill={'white'}
        />
        {isChecked(INDEX_MID) && (
          <circle cx={'313'} cy={'191'} r={'5'} fill={getColor(INDEX_MID)} />
        )}
        <path
          d={
            'M313 199C308.582 199 305 195.418 305 191H301C301 197.627 306.373 203 313 203V199ZM321 191C321 195.418 317.418 199 313 199V203C319.627 203 325 197.627 325 191H321ZM313 183C317.418 183 321 186.582 321 191H325C325 184.373 319.627 179 313 179V183ZM313 179C306.373 179 301 184.373 301 191H305C305 186.582 308.582 183 313 183V179Z'
          }
          fill={getColor(INDEX_MID)}
        />
      </g>
      <g
        id={MIDDLE_MID}
        onClick={() => handleClick(MIDDLE_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M331 204C331 198.477 335.477 194 341 194C346.523 194 351 198.477 351 204C351 209.523 346.523 214 341 214C335.477 214 331 209.523 331 204Z'
          }
          fill={'white'}
        />
        {isChecked(MIDDLE_MID) && (
          <circle cx={'341'} cy={'204'} r={'5'} fill={getColor(MIDDLE_MID)} />
        )}
        <path
          d={
            'M341 212C336.582 212 333 208.418 333 204H329C329 210.627 334.373 216 341 216V212ZM349 204C349 208.418 345.418 212 341 212V216C347.627 216 353 210.627 353 204H349ZM341 196C345.418 196 349 199.582 349 204H353C353 197.373 347.627 192 341 192V196ZM341 192C334.373 192 329 197.373 329 204H333C333 199.582 336.582 196 341 196V192Z'
          }
          fill={getColor(MIDDLE_MID)}
        />
      </g>
      <g
        id={RING_MID}
        onClick={() => handleClick(RING_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M359 199C359 193.477 363.477 189 369 189C374.523 189 379 193.477 379 199C379 204.523 374.523 209 369 209C363.477 209 359 204.523 359 199Z'
          }
          fill={'white'}
        />
        {isChecked(RING_MID) && (
          <circle cx={'369'} cy={'199'} r={'5'} fill={getColor(RING_MID)} />
        )}
        <path
          d={
            'M369 207C364.582 207 361 203.418 361 199H357C357 205.627 362.373 211 369 211V207ZM377 199C377 203.418 373.418 207 369 207V211C375.627 211 381 205.627 381 199H377ZM369 191C373.418 191 377 194.582 377 199H381C381 192.373 375.627 187 369 187V191ZM369 187C362.373 187 357 192.373 357 199H361C361 194.582 364.582 191 369 191V187Z'
          }
          fill={getColor(RING_MID)}
        />
      </g>
      <g
        id={PINKIE_MID}
        onClick={() => handleClick(PINKIE_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M382 175C382 169.477 386.477 165 392 165C397.523 165 402 169.477 402 175C402 180.523 397.523 185 392 185C386.477 185 382 180.523 382 175Z'
          }
          fill={'white'}
        />
        {isChecked(PINKIE_MID) && (
          <circle cx={'392'} cy={'175'} r={'5'} fill={getColor(PINKIE_MID)} />
        )}
        <path
          d={
            'M392 183C387.582 183 384 179.418 384 175H380C380 181.627 385.373 187 392 187V183ZM400 175C400 179.418 396.418 183 392 183V187C398.627 187 404 181.627 404 175H400ZM392 167C396.418 167 400 170.582 400 175H404C404 168.373 398.627 163 392 163V167ZM392 163C385.373 163 380 168.373 380 175H384C384 170.582 387.582 167 392 167V163Z'
          }
          fill={getColor(PINKIE_MID)}
        />
      </g>

      <Visible visible={!lowDisabled}>
        <g
          id={THUMB_LOWER}
          onClick={() => handleClick(THUMB_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M274 100C274 94.4772 278.477 90 284 90C289.523 90 294 94.4772 294 100C294 105.523 289.523 110 284 110C278.477 110 274 105.523 274 100Z'
            }
            fill={'white'}
          />
          {isChecked(THUMB_LOWER) && (
            <circle
              cx={'284'}
              cy={'100'}
              r={'5'}
              fill={getColor(THUMB_LOWER)}
            />
          )}
          <path
            d={
              'M284 108C279.582 108 276 104.418 276 100H272C272 106.627 277.373 112 284 112V108ZM292 100C292 104.418 288.418 108 284 108V112C290.627 112 296 106.627 296 100H292ZM284 92C288.418 92 292 95.5817 292 100H296C296 93.3726 290.627 88 284 88V92ZM284 88C277.373 88 272 93.3726 272 100H276C276 95.5817 279.582 92 284 92V88Z'
            }
            fill={getColor(THUMB_LOWER)}
          />
        </g>
        <g
          id={INDEX_LOWER}
          onClick={() => handleClick(INDEX_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M310 155C310 149.477 314.477 145 320 145C325.523 145 330 149.477 330 155C330 160.523 325.523 165 320 165C314.477 165 310 160.523 310 155Z'
            }
            fill={'white'}
          />
          {isChecked(INDEX_LOWER) && (
            <circle
              cx={'320'}
              cy={'155'}
              r={'5'}
              fill={getColor(INDEX_LOWER)}
            />
          )}
          <path
            d={
              'M320 163C315.582 163 312 159.418 312 155H308C308 161.627 313.373 167 320 167V163ZM328 155C328 159.418 324.418 163 320 163V167C326.627 167 332 161.627 332 155H328ZM320 147C324.418 147 328 150.582 328 155H332C332 148.373 326.627 143 320 143V147ZM320 143C313.373 143 308 148.373 308 155H312C312 150.582 315.582 147 320 147V143Z'
            }
            fill={getColor(INDEX_LOWER)}
          />
        </g>
        <g
          id={MIDDLE_LOWER}
          onClick={() => handleClick(MIDDLE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M336 156C336 150.477 340.477 146 346 146C351.523 146 356 150.477 356 156C356 161.523 351.523 166 346 166C340.477 166 336 161.523 336 156Z'
            }
            fill={'white'}
          />
          {isChecked(MIDDLE_LOWER) && (
            <circle
              cx={'346'}
              cy={'156'}
              r={'5'}
              fill={getColor(MIDDLE_LOWER)}
            />
          )}
          <path
            d={
              'M346 164C341.582 164 338 160.418 338 156H334C334 162.627 339.373 168 346 168V164ZM354 156C354 160.418 350.418 164 346 164V168C352.627 168 358 162.627 358 156H354ZM346 148C350.418 148 354 151.582 354 156H358C358 149.373 352.627 144 346 144V148ZM346 144C339.373 144 334 149.373 334 156H338C338 151.582 341.582 148 346 148V144Z'
            }
            fill={getColor(MIDDLE_LOWER)}
          />
        </g>
        <g
          id={RING_LOWER}
          onClick={() => handleClick(RING_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M358 151C358 145.477 362.477 141 368 141C373.523 141 378 145.477 378 151C378 156.523 373.523 161 368 161C362.477 161 358 156.523 358 151Z'
            }
            fill={'white'}
          />
          {isChecked(RING_LOWER) && (
            <circle cx={'368'} cy={'151'} r={'5'} fill={getColor(RING_LOWER)} />
          )}
          <path
            d={
              'M368 159C363.582 159 360 155.418 360 151H356C356 157.627 361.373 163 368 163V159ZM376 151C376 155.418 372.418 159 368 159V163C374.627 163 380 157.627 380 151H376ZM368 143C372.418 143 376 146.582 376 151H380C380 144.373 374.627 139 368 139V143ZM368 139C361.373 139 356 144.373 356 151H360C360 146.582 363.582 143 368 143V139Z'
            }
            fill={getColor(RING_LOWER)}
          />
        </g>
        <g
          id={PINKIE_LOWER}
          onClick={() => handleClick(PINKIE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M377 141C377 135.477 381.477 131 387 131C392.523 131 397 135.477 397 141C397 146.523 392.523 151 387 151C381.477 151 377 146.523 377 141Z'
            }
            fill={'white'}
          />
          {isChecked(PINKIE_LOWER) && (
            <circle
              cx={'387'}
              cy={'141'}
              r={'5'}
              fill={getColor(PINKIE_LOWER)}
            />
          )}
          <path
            d={
              'M387 149C382.582 149 379 145.418 379 141H375C375 147.627 380.373 153 387 153V149ZM395 141C395 145.418 391.418 149 387 149V153C393.627 153 399 147.627 399 141H395ZM387 133C391.418 133 395 136.582 395 141H399C399 134.373 393.627 129 387 129V133ZM387 129C380.373 129 375 134.373 375 141H379C379 136.582 382.582 133 387 133V129Z'
            }
            fill={getColor(PINKIE_LOWER)}
          />
        </g>
      </Visible>
    </g>
  );
};

LeftHandInteractive.propTypes = {
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  tipsDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool,
  wristDisabled: PropTypes.bool
};

LeftHandInteractive.defaultProps = {
  onChange: _.noop,
  initialSelectedParts: [],
  tipsDisabled: false,
  lowDisabled: false,
  wristDisabled: false
};

export default LeftHandInteractive;
