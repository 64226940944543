import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import styles from './HomunculusWidget.module.scss';

import { Col, Row } from '../../../../reactstrap';
import Visible from '../../../layout/Visible';
import HomunculusTotal from './HomunculusTotal';

const TextPropShape = {
  text: PropTypes.string.isRequired
};

const NumericValuePropShape = {
  value: PropTypes.number.isRequired
};

const Cell = ({ value }) => <div className={styles.cell}>{value}</div>;

const NormalLabel = ({ text }) => (
  <div className={styles.normalLabel}>
    <span>{text}</span>
  </div>
);

const SpecialLabel = ({ text }) => (
  <div className={styles.specialLabel}>{text}</div>
);

Cell.propTypes = NumericValuePropShape;

NormalLabel.propTypes = TextPropShape;
SpecialLabel.propTypes = TextPropShape;

const HomunculusTable = ({
  fields,
  selectedParts,
  groupDefinitionList,
  totalText,
  totalVisible
}) => (
  <React.Fragment>
    {_.hasMoreThanOneItem(fields) && (
      <Row>
        {fields.map((field, index) => (
          <Col
            key={field.id}
            xs={{
              size: field.colSize,
              offset: index === 0 ? 5 : undefined
            }}
          >
            <SpecialLabel text={field.shortTitle} />
          </Col>
        ))}
        <Col xs={1} />
      </Row>
    )}
    {groupDefinitionList.map((groupDefinition, index) => (
      <Row
        key={groupDefinition.label}
        className={classNames({
          'margin-top-20': index === 0,
          'margin-top-12': index > 0
        })}
      >
        <Col xs={{ size: 5 }}>
          <NormalLabel text={groupDefinition.label} />
        </Col>
        {fields.map((field) => {
          const fieldSelectedParts = _.getNonEmpty(selectedParts, field.id, []);
          const options = _.getNonEmpty(groupDefinition, 'options', {});
          const fieldOptions = _.getNonEmpty(options, field.id, {});
          const className = _.getNonEmpty(fieldOptions, 'className');

          return (
            <Col key={field.id} className={className}>
              <Cell
                value={
                  _.intersection(fieldSelectedParts, groupDefinition.groupList)
                    .length
                }
              />
            </Col>
          );
        })}
        <Col xs={1} />
      </Row>
    ))}
    <hr />

    <Visible
      visible={totalVisible}
      component={Row}
      className={classNames('margin-top-20')}
    >
      <HomunculusTotal
        fields={fields}
        selectedParts={selectedParts}
        totalText={totalText}
      />
    </Visible>
  </React.Fragment>
);

HomunculusTable.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      homunculusColSize: PropTypes.number.isRequired,
      homunculusProps: PropTypes.shape().isRequired,
      title: PropTypes.string
    })
  ).isRequired,
  selectedParts: PropTypes.shape().isRequired,
  groupDefinitionList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      groupList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      options: PropTypes.shape({})
    })
  ).isRequired,
  totalText: PropTypes.string,
  totalVisible: PropTypes.bool
};

HomunculusTable.defaultProps = {
  totalText: '/',
  totalVisible: false
};

export default HomunculusTable;
