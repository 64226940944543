import React from 'react';

import PropTypes from 'prop-types';

import LeftHandInteractive from './LeftHandInteractive';
import LeftHandOutline from './LeftHandOutline';

const LeftHand = ({
  outlineColor,
  disabledColor,
  getColor,
  onChange,
  initialSelectedParts,
  disabled,
  tipsDisabled,
  lowDisabled,
  wristDisabled
}) => (
  <g id={'zoomed-left-hand'}>
    <LeftHandOutline
      outlineColor={outlineColor}
      disabledColor={disabledColor}
      disabled={disabled}
    />
    {!disabled && (
      <LeftHandInteractive
        getColor={getColor}
        onChange={onChange}
        initialSelectedParts={initialSelectedParts}
        tipsDisabled={tipsDisabled}
        lowDisabled={lowDisabled}
        wristDisabled={wristDisabled}
      />
    )}
  </g>
);

LeftHand.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  tipsDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool,
  wristDisabled: PropTypes.bool
};

LeftHand.defaultProps = {
  onChange: undefined,
  initialSelectedParts: undefined,
  disabled: false,
  tipsDisabled: undefined,
  lowDisabled: undefined,
  wristDisabled: undefined
};

export default LeftHand;
