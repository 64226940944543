/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { CardBody } from 'reactstrap';

import PropTypes from 'prop-types';

const CardBodyWrapper = ({ children, innerRef, className, id }) => (
  <CardBody innerRef={innerRef} className={className} id={id}>
    {children}
  </CardBody>
);

CardBodyWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func
  ]),
  className: PropTypes.string,
  id: PropTypes.string
};

CardBodyWrapper.defaultProps = {
  innerRef: undefined,
  className: undefined,
  id: undefined
};

export default CardBodyWrapper;
