import { createActionTypeArray } from '../../common/actionTypes';

export const GET_ALL_DOCUMENT_TYPES_LIST =
  'documents/GET_ALL_DOCUMENT_TYPES_LIST';
export const SET_ALL_DOCUMENT_TYPES_LIST_PAGINATION =
  'documents/SET_ALL_DOCUMENT_TYPES_LIST_PAGINATION';
export const SET_ALL_DOCUMENT_TYPES_LIST_ORDER =
  'documents/SET_ALL_DOCUMENT_TYPES_LIST_ORDER';
export const SET_ALL_DOCUMENT_TYPES_LIST_FILTER =
  'documents/SET_ALL_DOCUMENT_TYPES_LIST_FILTER';
export const CLEAR_ALL_DOCUMENT_TYPES_LIST_FILTERS =
  'documents/CLEAR_ALL_DOCUMENT_TYPES_LIST_FILTERS';
export const CLEAR_ALL_DOCUMENT_TYPES_LIST =
  'documents/CLEAR_ALL_DOCUMENT_TYPES_LIST';

export const [
  GET_ALL_DOCUMENT_TYPES_LIST_IN_PROGRESS,
  GET_ALL_DOCUMENT_TYPES_LIST_SUCCESS,
  GET_ALL_DOCUMENT_TYPES_LIST_FAILURE
] = createActionTypeArray(GET_ALL_DOCUMENT_TYPES_LIST);
