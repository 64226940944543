import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import PropTypes from 'prop-types';

const CustomTextareaAutosizeWidget = ({
  value,
  onChange,
  disabled,
  required
}) => (
  <TextareaAutosize
    className={'form-control'}
    value={value}
    required={required}
    disabled={disabled}
    onChange={(e) => onChange(e.target.value)}
    style={{ minHeight: disabled ? undefined : '80px' }}
  />
);

CustomTextareaAutosizeWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string
};

CustomTextareaAutosizeWidget.defaultProps = {
  disabled: false,
  required: false,
  value: ''
};

export default CustomTextareaAutosizeWidget;
