import _ from 'lodash';
import moment from 'moment';

import {
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_ISSUED
} from '../../../../utils/billing';
import { getDateFormat } from '../../../../utils/date';
import { EMPTY_GUID } from '../../../../utils/gen';

import LocalizedStringModel from '../../LocalizedStringModel';
import PaymentModel from '../PaymentModel';
import BillBaseModel from './BillBaseModel';

const DRAFT_NUMBER_PREFIX = 'draft-';

class InvoiceModel extends BillBaseModel {
  constructor(id, status = [INVOICE_STATUS_ISSUED]) {
    super(id);

    this.status = status;
    this.dueDate = this.date;
    this.dateService = this.date;
    this.advance = 0;
    this.draft = false;
  }

  setInvoicePreferredDataInput(
    billingUnitList,
    preferredBillingData,
    defaultLocale
  ) {
    const billingUnit = super.setPreferredDataInput(
      billingUnitList,
      preferredBillingData,
      defaultLocale
    );

    const note = _.get(
      billingUnit,
      LocalizedStringModel.getFieldPath('texts.invoiceNote', defaultLocale)
    );

    const defaultDueDate = _.get(billingUnit, 'paymentDetails.invoiceDueDays');

    this.texts.note = note;

    this.dueDate = moment().add(defaultDueDate, 'days').format('YYYY/MM/DD');
  }

  static fromDto(dto) {
    const assignedBillBase = BillBaseModel.fromDto(dto);
    const assignedInvoice = _.nestedAssign(
      new InvoiceModel(null, []),
      assignedBillBase
    );

    assignedInvoice.dueDate = moment(assignedInvoice.dueDate).toISOString();
    assignedInvoice.dateService = moment(
      assignedInvoice.dateService
    ).toISOString();

    return assignedInvoice;
  }

  static getPayload(model, defaultLocale) {
    const payload = BillBaseModel.getPayload(model, defaultLocale);

    payload.dueDate = getDateFormat(model.dueDate, 'YYYY-MM-DD');
    payload.dateService = getDateFormat(model.dateService, 'YYYY-MM-DD');

    if (
      _.has(payload, 'dateService') &&
      _.isEmptySafe(payload, 'dateService')
    ) {
      delete payload.dateService;
    }

    if (_.has(payload, 'payments')) {
      payload.payments = _.map(payload.payments, (payment) =>
        PaymentModel.getPayload(payment)
      );
    }

    const invoiceNumber = _.get(payload, 'number', '');
    const isNewDraftWithInvoiceNumber =
      model.draft && !_.includes(invoiceNumber, DRAFT_NUMBER_PREFIX);
    const isNewInvoiceWithDraftNumber =
      !model.draft && _.includes(invoiceNumber, DRAFT_NUMBER_PREFIX);

    if (isNewDraftWithInvoiceNumber || isNewInvoiceWithDraftNumber) {
      payload.number = '';
    }

    return payload;
  }

  get isDraft() {
    const emptyId = this.id === EMPTY_GUID;
    const isDraft = _.includes(this.status, INVOICE_STATUS_DRAFT);

    return emptyId || this.isNew || isDraft;
  }

  set isDraft(val) {
    // This setter is present so that mappers don't fail
  }
}

export default InvoiceModel;
