import React from 'react';
import { connect } from 'react-redux';

import ErrorBoundary from '../components/common/error/ErrorBoundary';

export const withError = (Component, enableForwardRef = false) => {
  let WithError;

  if (enableForwardRef) {
    WithError = React.forwardRef((props, ref) => (
      <ErrorBoundary>
        <Component ref={ref} {...props} />
      </ErrorBoundary>
    ));
  } else {
    WithError = (props) => (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  }

  WithError.displayName = `withError(${
    Component.displayName || Component.name
  })`;

  return WithError;
};

export const moshiConnect = (
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  options
) => (Component) => {
  const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    options
  );

  const MoshiConnect = reduxConnector(withError(Component));

  MoshiConnect.displayName = `moshiConnect(${
    Component.displayName || Component.name
  })`;

  return MoshiConnect;
};

export const moshiConnectWithForwardRef = (
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  options
) => (Component) => {
  const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    { forwardRef: true, ...options }
  );

  const MoshiConnect = reduxConnector(withError(Component, true));

  MoshiConnect.displayName = `moshiConnectWithForwardRef(${
    Component.displayName || Component.name
  })`;

  return MoshiConnect;
};
