class NotificationsModel {
  constructor() {
    this.defaultDrugTherapySMSTemplate = '';
    this.newAppointmentAddedSMSTemplate = '';
    this.appointmentReminderSMSTemplate = '';
    this.appointmentReminderSMSEnabled = false;
    this.newAppointmentAddedSMSEnabled = false;
    this.reminderPeriods = null;
    this.senderName = null;
  }
}
export default NotificationsModel;
