import React from 'react';

import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import {
  MOSHI_COLOR_PRIMARY_NAME,
  MOSHI_COLOR_SECONDARY_NAME
} from '../../../../utils/color';
import { MOSHI_SIZE_LG } from '../../../../utils/constants/sizeConstants';

import MoshiButton from '../../button/MoshiButton';
import MoshiLink from '../../button/MoshiLink';
import Visible from '../../layout/Visible';
import SubmitButton from './SubmitButton';

export const FormActionsSwitch = ({
  t: translate,
  onEdit,
  onCancel,
  editMode,
  editButtonKeyCode,
  cancelButtonKeyCode,
  saveButtonKeyCode,
  editButtonColor,
  cancelButtonColor,
  saveButtonColor,
  useLinkAsEditButton,
  editButtonSize,
  cancelButtonSize,
  saveButtonSize,
  formikAvailable,
  noFormikIsValid,
  editButtonInvisible,
  tabIndex
}) => {
  const EditButtonComponent = useLinkAsEditButton ? MoshiLink : MoshiButton;

  return (
    <div className={'edit-button-toggle'}>
      <Visible visible={!editMode && !editButtonInvisible}>
        <EditButtonComponent
          id={'edit'}
          onClick={onEdit}
          color={
            useLinkAsEditButton &&
            editButtonColor === MOSHI_COLOR_SECONDARY_NAME
              ? MOSHI_COLOR_PRIMARY_NAME
              : editButtonColor
          }
          size={editButtonSize}
        >
          {translate(editButtonKeyCode)}
        </EditButtonComponent>
      </Visible>
      <Visible visible={editMode}>
        <MoshiButton
          id={'cancel'}
          onClick={onCancel}
          color={cancelButtonColor}
          size={cancelButtonSize}
          tabIndex={tabIndex}
        >
          {translate(cancelButtonKeyCode)}
        </MoshiButton>
        <SubmitButton
          id={'save'}
          color={saveButtonColor}
          text={translate(saveButtonKeyCode)}
          size={saveButtonSize}
          formikAvailable={formikAvailable}
          noFormikIsValid={noFormikIsValid}
          tabIndex={tabIndex}
        />
      </Visible>
    </div>
  );
};

FormActionsSwitch.propTypes = {
  t: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  editButtonKeyCode: PropTypes.string,
  cancelButtonKeyCode: PropTypes.string,
  saveButtonKeyCode: PropTypes.string,
  editButtonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  saveButtonColor: PropTypes.string,
  useLinkAsEditButton: PropTypes.bool,
  editButtonSize: PropTypes.string,
  cancelButtonSize: PropTypes.string,
  saveButtonSize: PropTypes.string,
  formikAvailable: PropTypes.bool,
  noFormikIsValid: PropTypes.bool,
  editButtonInvisible: PropTypes.bool,
  tabIndex: PropTypes.number
};

FormActionsSwitch.defaultProps = {
  editButtonKeyCode: 'common:edit',
  cancelButtonKeyCode: 'common:cancel',
  saveButtonKeyCode: 'common:save',
  editButtonColor: MOSHI_COLOR_SECONDARY_NAME,
  cancelButtonColor: MOSHI_COLOR_SECONDARY_NAME,
  saveButtonColor: MOSHI_COLOR_PRIMARY_NAME,
  useLinkAsEditButton: false,
  editButtonSize: undefined,
  cancelButtonSize: MOSHI_SIZE_LG,
  saveButtonSize: MOSHI_SIZE_LG,
  cancelButtonOutline: false,
  formikAvailable: false,
  noFormikIsValid: true,
  editButtonInvisible: false,
  tabIndex: undefined
};

export default localize(FormActionsSwitch);
