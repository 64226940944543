import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { MOSHI_SIZE_XS } from '../../../utils/constants/sizeConstants';
import { getSafeTranslation } from '../../../utils/language';

import MoshiButton from '../button/MoshiButton';

const ToastWithButtons = ({ message, buttons }) => (
  <div className={'flex-space-between'}>
    <span>{message}</span>
    <div className={'margin-right-15'}>
      {_.map(buttons, (button, index) => (
        <MoshiButton key={index} size={MOSHI_SIZE_XS} onClick={button.onClick}>
          {getSafeTranslation(button.text)}
        </MoshiButton>
      ))}
    </div>
  </div>
);

ToastWithButtons.propTypes = {
  message: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired
};

export default ToastWithButtons;
