export default {
  to: 'Na',
  useOnce: 'Uporabite samo enkrat',
  useFromNowOn: 'Uporabite od zdaj naprej',
  message: 'Sporočilo',
  addMessagePlaceholder: 'Napišite sporočilo po meri ...',
  sms: {
    title: 'SMS',
    send: 'Pošljite SMS',
    updatePhoneNumber: 'Posodobite pacientovo telefonsko številko'
  },
  email: {
    title: 'E-pošta'
  },
  alert: {
    smsReminderSent: 'SMS je bil uspešno poslan.',
    smsReminderDisabled: 'Pacient nima vklopljenega SMS obveščanja.'
  }
};
