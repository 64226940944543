import _ from 'lodash';
import moment from 'moment';

import { ORDER_DESCENDING } from '../../constants/tableConstants';
import { ASSIGNEE_TYPE_LOCATION } from '../../constants/workingHoursConstants';
import { safeGlobalGetState } from '../../redux/storeUtils';

import { selectLocationNameById } from '../../../redux/location/locationSelectors';

/**
 * Groups the api response by closed date name, from and to. We want to display only one row
 * in the list so we need to group all locations with the same closed date. We also add location
 * names and map all closed dates ids (for deletion purposes). For examples please check the unit test.
 *
 * @param dates
 * @returns {unknown[ ]}
 */

export const mapClosedDatesForList = (dates) => {
  const output = [];

  _.forEach(dates, (item) => {
    const existing = _.filter(
      output,
      (existingItem) =>
        existingItem.name === item.name &&
        existingItem.from === item.from &&
        existingItem.to === item.to
    );

    if (_.isEmptySafe(existing)) {
      if (!_.isEmpty(item.assignee)) {
        const from = moment(item.from, 'YYYY-MM-DD');
        const to = moment(item.to, 'YYYY-MM-DD');

        item.assignee = [item.assignee];
        item.ids = [item.id];
        item.numOfDays = moment.duration(to.diff(from)).asDays() + 1;
        item.dateRange = `${moment(from).format('ddd, D. MMM YYYY')} - ${moment(
          to
        ).format('ddd, D. MMM YYYY')}`;
      }
      output.push(item);
    } else {
      const existingIndex = _.indexOf(output, existing[0]);

      output[existingIndex].assignee = output[existingIndex].assignee.concat(
        item.assignee
      );
      output[existingIndex].ids = output[existingIndex].ids.concat(item.id);
    }
  });

  const outputWithMappedLocationNames = _.map(output, (item) => {
    const locationNames = [];

    _.forEach(item.assignee, (assignee) => {
      if (assignee.type === ASSIGNEE_TYPE_LOCATION) {
        const locationName = selectLocationNameById(
          safeGlobalGetState(),
          assignee.id
        );

        locationNames.push(locationName);
      }
    });

    item.locations = locationNames.join(', ');

    return item;
  });

  return _.orderBy(outputWithMappedLocationNames, 'from', ORDER_DESCENDING);
};
