import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';

import {
  setFiltersToStoreDraft,
  setFilterToStoreDraft
} from '../../utils/redux/listUtils';

import {
  SET_ANALYTICS_FILTER,
  SET_ANALYTICS_FILTERS,
  GET_ANALYTICS_SALES_TOTAL_DATA_IN_PROGRESS,
  GET_ANALYTICS_SALES_TOTAL_DATA_SUCCESS,
  GET_ANALYTICS_SALES_TOTAL_DATA_FAILURE,
  GET_ANALYTICS_SALES_BY_DOCTOR_IN_PROGRESS,
  GET_ANALYTICS_SALES_BY_DOCTOR_SUCCESS,
  GET_ANALYTICS_SALES_BY_DOCTOR_FAILURE,
  GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_IN_PROGRESS,
  GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_SUCCESS,
  GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_FAILURE,
  GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_FAILURE,
  GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_FAILURE,
  GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_FAILURE,
  GET_ANALYTICS_PATIENTS_OVERVIEW_IN_PROGRESS,
  GET_ANALYTICS_PATIENTS_OVERVIEW_SUCCESS,
  GET_ANALYTICS_PATIENTS_OVERVIEW_FAILURE,
  GET_ANALYTICS_PATIENTS_ACTIVE_IN_PROGRESS,
  GET_ANALYTICS_PATIENTS_ACTIVE_SUCCESS,
  GET_ANALYTICS_PATIENTS_ACTIVE_FAILURE,
  GET_ANALYTICS_SALES_PER_PATIENT_IN_PROGRESS,
  GET_ANALYTICS_SALES_PER_PATIENT_SUCCESS,
  GET_ANALYTICS_SALES_PER_PATIENT_FAILURE,
  GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_FAILURE
} from './analyticsTypes';

const initialState = {
  sales: {
    allSales: [],
    salesPerDoctor: [],
    salesPerPaymentType: []
  },
  appointments: {
    allAppointments: [],
    byDoctor: [],
    byCategory: []
  },
  patients: {
    overview: {},
    active: {},
    salesPerPatient: {},
    appointmentsPerPatient: {}
  },
  loadingData: false,
  filter: {
    dashboardToDate: moment().toISOString(),
    analyticsFromDate: moment().subtract(1, 'month').toISOString(),
    analyticsToDate: moment().toISOString()
  }
};

// eslint-disable-next-line default-param-last
const analyticsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case SET_ANALYTICS_FILTER:
        setFilterToStoreDraft(draft, payload);

        break;
      case SET_ANALYTICS_FILTERS:
        const filters = _.getNonEmpty(payload, 'filters', []);

        setFiltersToStoreDraft(draft, filters);

        break;

      /*
       *  SALES CASES
       */

      // Total sales
      case GET_ANALYTICS_SALES_TOTAL_DATA_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_SALES_TOTAL_DATA_SUCCESS:
        const salesData = _.getNonEmpty(payload, 'response.data.data', []);

        draft.sales.allSales = salesData;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_SALES_TOTAL_DATA_FAILURE:
        draft.loadingData = false;
        break;

      // Sales by doctor
      case GET_ANALYTICS_SALES_BY_DOCTOR_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_SALES_BY_DOCTOR_SUCCESS:
        const salesPerDoctorData = _.getNonEmpty(
          payload,
          'response.data.data',
          []
        );

        draft.sales.salesPerDoctor = _.filter(
          salesPerDoctorData,
          (ent) => ent.doctorID !== undefined
        );

        draft.loadingData = false;
        break;

      case GET_ANALYTICS_SALES_BY_DOCTOR_FAILURE:
        draft.loadingData = false;
        break;

      // Sales by payment type
      case GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_SUCCESS:
        const responsePerPaymentType = _.getNonEmpty(
          payload,
          'response.data',
          []
        );

        draft.sales.salesPerPaymentType = responsePerPaymentType.data;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_FAILURE:
        draft.loadingData = false;
        break;

      /*
       *  APPOINTMENTS CASES
       */

      case GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_SUCCESS:
        const appointmentsData = _.getNonEmpty(
          payload,
          'response.data.data',
          []
        );

        draft.appointments.allAppointments = appointmentsData;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_FAILURE:
        draft.loadingData = false;
        break;

      // Appointments by Doctor
      case GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_SUCCESS:
        const appointmentsPerDoctorData = _.getNonEmpty(
          payload,
          'response.data.data',
          []
        );

        draft.appointments.byDoctor = appointmentsPerDoctorData;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_FAILURE:
        draft.loadingData = false;
        break;

      // Appointments by Category
      case GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_SUCCESS:
        const appointmentsPerCategoryData = _.getNonEmpty(
          payload,
          'response.data.data',
          []
        );

        draft.appointments.byCategory = appointmentsPerCategoryData;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_FAILURE:
        draft.loadingData = false;
        break;

      /*
       *  PATIENTS CASES
       */

      // Patients overview
      case GET_ANALYTICS_PATIENTS_OVERVIEW_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_PATIENTS_OVERVIEW_SUCCESS:
        const patientsOverviewData = _.getNonEmpty(
          payload,
          'response.data',
          []
        );

        draft.patients.overview = patientsOverviewData;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_PATIENTS_OVERVIEW_FAILURE:
        draft.loadingData = false;
        break;

      // Patients Active
      case GET_ANALYTICS_PATIENTS_ACTIVE_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_PATIENTS_ACTIVE_SUCCESS:
        const patientsActive = _.getNonEmpty(payload, 'response.data', []);

        draft.patients.active = patientsActive;
        draft.loadingData = false;

        break;

      case GET_ANALYTICS_PATIENTS_ACTIVE_FAILURE:
        draft.loadingData = false;
        break;

      // Patients Sales Per Patient
      case GET_ANALYTICS_SALES_PER_PATIENT_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_SALES_PER_PATIENT_SUCCESS:
        const salesPerPatient = _.getNonEmpty(payload, 'response.data', []);

        draft.patients.salesPerPatient = salesPerPatient;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_SALES_PER_PATIENT_FAILURE:
        draft.loadingData = false;
        break;

      // Patients Appointments Per Patient
      case GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_IN_PROGRESS:
        draft.loadingData = true;
        break;

      case GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_SUCCESS:
        const appointmentsPerPatient = _.getNonEmpty(
          payload,
          'response.data',
          []
        );

        draft.patients.appointmentsPerPatient = appointmentsPerPatient;
        draft.loadingData = false;
        break;

      case GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_FAILURE:
        draft.loadingData = false;
        break;

      default:
    }
  });

export default analyticsReducer;
