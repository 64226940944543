import { useEffect } from 'react';

/**
 * Trigger function on click outside of the element. Example: close popup on click outside.
 *
 * @param {ref} ref reference to an element considered as "inside"
 * @param {function} callback what to do on outside click
 *
 * Example:
 *
 * const ref = useRef();
 *
 * useOnClickOutside(ref, () => closeTooltip());
 *
 * return (
 *  <div ref={ref} />
 * );
 */
export const useOnClickOutside = (ref, callback) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      callback(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback]);
};
