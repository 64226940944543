export default {
  noAccessToEventsLocation: 'Nimate dostopa do lokacije termina.',
  location: 'Lokacija',
  alert: {
    created: 'Lokacija je bila uspešno dodana.',
    updated: 'Lokacija je bila uspešno posodobljena.',
    removed: 'Lokacija je bila uspešno odstranjena.',
    selectedLocationUpdated: 'Lokacija spremenjena'
  }
};
