import * as Yup from 'yup';

import {
  equalToYupValidator,
  isNotEmptyYupValidator,
  maxDecimalPlacesYupValidator,
  uniqueArrayYupValidator,
  passwordYupValidator,
  fromToTimeYupValidator,
  calculatedTherapyEndDateLowerThanToday,
  signatureFieldYupValidator,
  atLeastOneCheckboxSelectedValidator,
  dividableByYupValidator
} from './yupExtensions';

Yup.addMethod(Yup.string, 'equalTo', equalToYupValidator);
Yup.addMethod(Yup.array, 'uniqueArray', uniqueArrayYupValidator);
Yup.addMethod(Yup.number, 'maxDecimalPlaces', maxDecimalPlacesYupValidator);
Yup.addMethod(Yup.number, 'dividableBy', dividableByYupValidator);
Yup.addMethod(Yup.mixed, 'isNotEmpty', isNotEmptyYupValidator);
Yup.addMethod(Yup.mixed, 'validatePassword', passwordYupValidator);
Yup.addMethod(Yup.mixed, 'validateFromToTime', fromToTimeYupValidator);
Yup.addMethod(
  Yup.mixed,
  'calculatedTherapyEndDateLowerThanToday',
  calculatedTherapyEndDateLowerThanToday
);
Yup.addMethod(Yup.mixed, 'validateSignatureField', signatureFieldYupValidator);
Yup.addMethod(
  Yup.mixed,
  'validateMultipleCheckboxes',
  atLeastOneCheckboxSelectedValidator
);

Yup.setLocale({
  string: {
    email: 'validation:notAnEmail',
    min: ({ min, field }) => ({
      key: 'validation:tooShortMin',
      options: { min, field }
    }),
    max: ({ max, field }) => ({
      key: 'validation:tooLongMax',
      options: { max, field }
    })
  },
  number: {
    maxDecimalPlaces: ({ maxDecimalPlaces }) => ({
      key: 'validation:maxDecimalPlaces',
      options: { maxDecimalPlaces }
    }),
    min: ({ min, field }) => ({
      key: 'validation:tooLow',
      options: { min, field }
    }),
    max: ({ max, field }) => ({
      key: 'validation:tooHigh',
      options: { max, field }
    })
  },
  mixed: {
    required: 'validation:required'
  }
});
