import React from 'react';
import { Trans } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize, translateKey } from '../../../i18n';
import { MOSHI_COLOR_DANGER_NAME } from '../../../utils/color';

import { ModalBody } from '../../reactstrap';
import MoshiModal from './MoshiModal';

export const ConfirmEntityRemovalModalComponent = ({
  t: translate,
  toggleRef,
  removeEntityDispatch,
  entityNameText,
  title,
  message,
  itemNamePath,
  submitButtonText,
  cancelButtonText
}) => {
  const entityName = entityNameText.toLowerCase();
  const handleRemove = (toggle, none, actions, { entity }) => {
    const functionType = removeEntityDispatch(entity);

    // TODO: Add missing error handling
    if (functionType instanceof Promise) {
      functionType
        .then(() => {
          toggle();
        })
        .catch(() => {
          actions.setSubmitting(false);
        });
    } else {
      toggle();
    }
  };

  return (
    <MoshiModal
      name={'remove-entity'}
      header={translate(title, {
        entityName
      })}
      className={'small-width-modal'}
      toggleRef={toggleRef}
      onSubmit={handleRemove}
      submitButtonText={submitButtonText}
      submitButtonColor={MOSHI_COLOR_DANGER_NAME}
      submitButtonId={'remove'}
      cancelButtonText={cancelButtonText}
    >
      {(contentProps) => (
        <ModalBody>
          <Trans
            i18nKey={message}
            values={{
              entityName,
              itemName: _.getNonEmpty(contentProps, itemNamePath, '/'),
              entity: _.getNonEmpty(contentProps, 'entity', {})
            }}
          >
            <span className={'font-weight-bold'}>dummyEntityName</span>
          </Trans>
        </ModalBody>
      )}
    </MoshiModal>
  );
};

ConfirmEntityRemovalModalComponent.propTypes = {
  t: PropTypes.func.isRequired,
  removeEntityDispatch: PropTypes.func.isRequired,
  toggleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  entityNameText: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  itemNamePath: PropTypes.string,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
};

ConfirmEntityRemovalModalComponent.defaultProps = {
  title: 'modal:confirmEntityRemoval.title',
  message: 'modal:confirmEntityRemoval.message',
  itemNamePath: 'entity.name',
  submitButtonText: translateKey('common:remove'),
  cancelButtonText: undefined
};

export default localize(ConfirmEntityRemovalModalComponent);
