// AUTH
export const tokenStorageKey = 'token';
export const tokenCookieKey = 'token';

// APP CONFIG
export const collapsedSidebarStorageKey = 'isSidebarCollapsed';

// TABLE
export const tablesStorageKey = 'tables';

// LOCATION
export const selectedLocationStorageKey = 'selectedLocation';

// CALENDAR
export const slotDurationStorageKey = 'slotDuration';
export const calendarResourcesStorageKey = 'selectedCalendarResources';
export const calendarViewStorageKey = 'selectedCalendarView';
export const calendarLocationAssigneesStorageKey =
  'selectedCalendarLocationAssignees';
export const calendarCategoriesStorageKey = 'selectedCalendarCategories';
export const calendarStatusesStorageKey = 'selectedCalendarStatuses';
export const calendarColorSourceStorageKey = 'calendarColorSource';
export const toggleCalendarAssigneesListStorageKey =
  'toggleCalendarAssigneesList';
export const toggleCalendarCategoryListStorageKey =
  'toggleCalendarCategoryList';
export const toggleCalendarStatusListStorageKey = 'toggleCalendarStatusList';

export const calendarStorageKeys = 'calendarStorageKeys';

// BILLING
export const userPreferredDataInputStorageKey = 'preferredBillingData';
export const billingExportColumnsStorageKey = 'billingExportColumns';
export const groupBillingItemsStorageKey = 'groupBillingItems';
// EVENT LOG
export const lastEventLogFetchStorageKey = 'lastEventLogFetch';
// TODO: Remove once we move away from Formik lib
export const tempBillingItemInfoStorageKey = 'tempBillingItemInfo';
