import React from 'react';

import { connect } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { translateKey } from '../../../../i18n';
import { MOSHI_COLOR_PRIMARY_NAME } from '../../../../utils/color';

import { FormikConnectShape } from '../../../../metadata/shapes/FormikShape';

import MoshiButton from '../../button/MoshiButton';

export const RawButton = ({
  formik,
  id,
  text,
  size,
  color,
  outline,
  buttonType,
  onSubmit,
  className,
  noPadding,
  tabIndex
}) => (
  <MoshiButton
    id={id}
    disabled={formik.isSubmitting}
    size={size}
    color={color}
    outline={outline}
    type={buttonType}
    className={className}
    onClick={onSubmit}
    noPadding={noPadding}
    tabIndex={tabIndex}
  >
    {_.isFunction(text) ? text() : text}
  </MoshiButton>
);

RawButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  formik: PropTypes.shape(FormikConnectShape).isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  buttonType: PropTypes.oneOf(['button', 'reset', 'submit']),
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  tabIndex: PropTypes.number
};

RawButton.defaultProps = {
  id: '',
  size: undefined,
  color: MOSHI_COLOR_PRIMARY_NAME,
  outline: undefined,
  buttonType: 'submit',
  onSubmit: _.noop,
  className: undefined,
  noPadding: undefined,
  tabIndex: undefined
};

const FormikSubmitButton = connect(RawButton);

const SubmitButton = ({
  id,
  text,
  size,
  color,
  outline,
  buttonType,
  onSubmit,
  formikAvailable,
  noFormikIsValid,
  className,
  noPadding,
  tabIndex
}) =>
  formikAvailable ? (
    <FormikSubmitButton
      id={id}
      color={color}
      outline={outline}
      text={text}
      size={size}
      buttonType={buttonType}
      onSubmit={onSubmit}
      className={className}
      noPadding={noPadding}
      tabIndex={tabIndex}
    />
  ) : (
    <RawButton
      id={id}
      color={color}
      outline={outline}
      text={text}
      size={size}
      buttonType={buttonType}
      onSubmit={onSubmit}
      formik={{
        isSubmitting: false,
        isValid: noFormikIsValid,
        dirty: true,
        values: {}
      }}
      className={className}
      noPadding={noPadding}
      tabIndex={tabIndex}
    />
  );

SubmitButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  id: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  buttonType: PropTypes.oneOf(['button', 'reset', 'submit']),
  formikAvailable: PropTypes.bool,
  noFormikIsValid: PropTypes.bool,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  tabIndex: PropTypes.number
};

SubmitButton.defaultProps = {
  text: translateKey('common:save'),
  id: 'save',
  size: undefined,
  color: undefined,
  outline: undefined,
  buttonType: 'submit',
  formikAvailable: true,
  noFormikIsValid: false,
  onSubmit: _.noop,
  className: undefined,
  noPadding: undefined,
  tabIndex: undefined
};

export default SubmitButton;
