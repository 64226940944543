import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  BILL_LIST_FILTER_DOCTOR,
  BILL_LIST_FILTER_LOCATION,
  BILL_LIST_FILTER_TYPE,
  BILL_LIST_FILTER_SEARCH,
  BILL_LIST_FILTER_STATUS,
  BILL_LIST_FILTER_ELECTRONIC_DEVICE,
  BILL_LIST_FILTER_DATE_FROM,
  BILL_LIST_FILTER_DATE_TO,
  BILL_LIST_FILTER_PAYMENT_TYPE
} from '../../../utils/billing';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';

import { initialState } from './billReducer';

const selectBillStore = (store) => store.bill;

// LIST SELECTORS
export const selectBillList = createSelector([selectBillStore], (billStore) =>
  _.getNonEmpty(billStore, 'list', [])
);

export const selectIsBillListLoading = createSelector(
  [selectBillStore],
  (billStore) => _.getNonEmpty(billStore, 'loadingList', false)
);

export const selectBillIncludeParam = createSelector(
  [selectBillStore],
  (billStore) => _.getNonEmpty(billStore, 'include', '')
);

export const selectBillPagination = createSelector(
  [selectBillStore],
  (billStore) => _.getNonEmpty(billStore, 'pagination', {})
);

// FILTERS
export const selectBillFilter = createSelector([selectBillStore], (bilLStore) =>
  _.getNonEmpty(bilLStore, 'filter', {})
);

export const selectBillFilterLocationId = createSelector(
  [selectBillFilter],
  (billFilter) =>
    _.getNonEmpty(billFilter, BILL_LIST_FILTER_LOCATION, FILTER_ALL_ID)
);

export const selectBillFilterDoctorId = createSelector(
  [selectBillFilter],
  (billFilter) =>
    _.getNonEmpty(billFilter, BILL_LIST_FILTER_DOCTOR, FILTER_ALL_ID)
);

export const selectBillFilterBillTypeId = createSelector(
  [selectBillFilter],
  (billFilter) =>
    _.getNonEmpty(billFilter, BILL_LIST_FILTER_TYPE, FILTER_ALL_ID)
);

export const selectBillFilterSearchValue = createSelector(
  [selectBillFilter],
  (billFilter) => _.getNonEmpty(billFilter, BILL_LIST_FILTER_SEARCH, '')
);

export const selectBillFilterStatusValue = createSelector(
  [selectBillFilter],
  (billFilter) =>
    _.getNonEmpty(billFilter, BILL_LIST_FILTER_STATUS, FILTER_ALL_ID)
);

export const selectBillPaymentTypeFilterValue = createSelector(
  [selectBillFilter],
  (billFilter) =>
    _.getNonEmpty(billFilter, BILL_LIST_FILTER_PAYMENT_TYPE, FILTER_ALL_ID)
);

export const selectBillFilterElectronicDeviceValue = createSelector(
  [selectBillFilter],
  (billFilter) =>
    _.getNonEmpty(billFilter, BILL_LIST_FILTER_ELECTRONIC_DEVICE, FILTER_ALL_ID)
);

export const selectBillFilterFromDate = createSelector(
  [selectBillFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_FROM, undefined)
);

export const selectBillFilterToDate = createSelector(
  [selectBillFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_TO, undefined)
);

export const selectIsBillFilterInitial = createSelector(
  [selectBillFilter],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);
