import React from 'react';

import PropTypes from 'prop-types';

import MoshiCKEditor from '../editor/MoshiCKEditor';
import MoshiReactMarkdown from '../editor/MoshiReactMarkdown';

const CustomCKEditorWidget = ({ value, onChange, disabled }) =>
  disabled ? (
    <MoshiReactMarkdown source={value} />
  ) : (
    <MoshiCKEditor
      onChange={onChange}
      value={value}
      disabled={disabled}
      autoFocus
    />
  );

CustomCKEditorWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

CustomCKEditorWidget.defaultProps = {
  value: '',
  disabled: undefined
};

export default CustomCKEditorWidget;
