import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  BILL_LIST_FILTER_BILLING_UNIT,
  BILL_LIST_FILTER_DATE_FROM,
  BILL_LIST_FILTER_DATE_TO,
  BILL_LIST_FILTER_DOCTOR,
  BILL_LIST_FILTER_ELECTRONIC_DEVICE,
  BILL_LIST_FILTER_LOCATION,
  BILL_LIST_FILTER_SEARCH,
  BILL_LIST_FILTER_STATUS,
  BILL_LIST_FILTER_PAYMENT_TYPE
} from '../../../utils/billing';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';

import { initialState } from './invoiceReducer';

export const selectInvoiceStore = (store) => store.invoice;

// LIST SELECTORS
export const selectInvoiceList = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'list', [])
);

export const selectInvoiceLoadingList = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'loadingList', false)
);

export const selectInvoiceIncludeParam = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'include', '')
);

// DETAIL SELECTORS
export const selectInvoiceDetail = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'detail', {})
);

export const selectInvoiceLoadingDetail = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'loadingDetail', false)
);

// FILTER SELECTORS
export const selectInvoiceFilter = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'filter', {})
);

export const selectInvoiceFilterSearchValue = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) => _.getNonEmpty(invoiceFilter, BILL_LIST_FILTER_SEARCH, '')
);

export const selectInvoiceFilterLocationId = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) =>
    _.getNonEmpty(invoiceFilter, BILL_LIST_FILTER_LOCATION, FILTER_ALL_ID)
);

export const selectInvoiceFilterDoctorId = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) =>
    _.getNonEmpty(invoiceFilter, BILL_LIST_FILTER_DOCTOR, FILTER_ALL_ID)
);

export const selectInvoiceFilterBillingUnitId = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) =>
    _.getNonEmpty(invoiceFilter, BILL_LIST_FILTER_BILLING_UNIT, FILTER_ALL_ID)
);

export const selectInvoiceFilterStatusValue = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) =>
    _.getNonEmpty(invoiceFilter, BILL_LIST_FILTER_STATUS, FILTER_ALL_ID)
);

export const selectInvoicePaymentTypeFilterValue = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) =>
    _.getNonEmpty(invoiceFilter, BILL_LIST_FILTER_PAYMENT_TYPE, FILTER_ALL_ID)
);

export const selectIsInvoiceFilterInitial = createSelector(
  [selectInvoiceFilter],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectInvoiceFilterElectronicDeviceId = createSelector(
  [selectInvoiceFilter],
  (invoiceFilter) =>
    _.getNonEmpty(
      invoiceFilter,
      BILL_LIST_FILTER_ELECTRONIC_DEVICE,
      FILTER_ALL_ID
    )
);

export const selectInvoiceFilterFromDate = createSelector(
  [selectInvoiceFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_FROM, undefined)
);

export const selectInvoiceFilterToDate = createSelector(
  [selectInvoiceFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_TO, undefined)
);

// PAGINATION SELECTORS
export const selectInvoicePagination = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'pagination', {})
);

// PAYMENT SELECTORS
export const selectInvoicePaymentList = createSelector(
  [selectInvoiceStore],
  (invoiceStore) => _.getNonEmpty(invoiceStore, 'payments.list', [])
);
