import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  DOCUMENT_FILTER_CREATED_BY,
  DOCUMENT_FILTER_SEARCH,
  DOCUMENT_FILTER_TYPE
} from '../../../utils/constants/documentConstants';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';
import { ORDER_DESCENDING } from '../../../utils/constants/tableConstants';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../../utils/table';

import { initialState } from './allDocumentTemplatesReducer';

import { selectFormStore } from '../formSelectors';

export const selectAllDocumentTemplatesStore = createSelector(
  [selectFormStore],
  (formStore) => _.getNonEmpty(formStore, 'allDocumentTemplates', {})
);

export const selectAllDocumentTemplatesList = createSelector(
  [selectAllDocumentTemplatesStore],
  (allDocumentTemplatesStore) =>
    _.getNonEmpty(allDocumentTemplatesStore, 'list', [])
);

export const selectAllDocumentTemplatesPagination = createSelector(
  [selectAllDocumentTemplatesStore],
  (allDocumentTemplatesStore) =>
    _.getNonEmpty(allDocumentTemplatesStore, 'pagination', {})
);

export const selectAllDocumentTemplatesFilters = createSelector(
  [selectAllDocumentTemplatesStore],
  (allDocumentTemplatesStore) =>
    _.getNonEmpty(allDocumentTemplatesStore, 'filter', {})
);

export const selectIsFilterInitial = createSelector(
  [selectAllDocumentTemplatesFilters],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectAllDocumentTemplatesSortConfig = createSelector(
  [selectAllDocumentTemplatesFilters],
  (filters) => {
    const sortBy = _.getNonEmpty(
      filters,
      'sortBy',
      ENTITY_FIELD_CREATED_AT_NAME
    );
    const order = _.getNonEmpty(filters, 'order', ORDER_DESCENDING);

    return { sortBy, order };
  }
);

export const selectAllDocumentTemplatesFilterSearchValue = createSelector(
  [selectAllDocumentTemplatesFilters],
  (filters) => _.getNonEmpty(filters, DOCUMENT_FILTER_SEARCH, '')
);

export const selectAllDocumentTemplatesFilterTypeValue = createSelector(
  [selectAllDocumentTemplatesFilters],
  (filters) => _.getNonEmpty(filters, DOCUMENT_FILTER_TYPE, FILTER_ALL_ID)
);

export const selectAllDocumentTemplatesFilterUserIdValue = createSelector(
  [selectAllDocumentTemplatesFilters],
  (filters) => _.getNonEmpty(filters, DOCUMENT_FILTER_CREATED_BY, FILTER_ALL_ID)
);
