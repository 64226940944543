import _ from 'lodash';

const ADDRESS_COMPONENT_STREET_NUMBER_TYPE = 'street_number';
const ADDRESS_COMPONENT_STREET_TYPE = 'route';
const ADDRESS_COMPONENT_TOWN_TYPE = 'postal_town';
const ADDRESS_COMPONENT_ZIP_TYPE = 'postal_code';
const ADDRESS_COMPONENT_COUNTRY_TYPE = 'country';

export const ADDRESS_FIELD_BASE = 'address';
export const ADDRESS_FIELD_STREET1 = `${ADDRESS_FIELD_BASE}.street1`;
export const ADDRESS_FIELD_STREET2 = `${ADDRESS_FIELD_BASE}.street2`;
export const ADDRESS_FIELD_TOWN = `${ADDRESS_FIELD_BASE}.town`;
export const ADDRESS_FIELD_ZIP = `${ADDRESS_FIELD_BASE}.zip`;
export const ADDRESS_FIELD_COUNTRY = `${ADDRESS_FIELD_BASE}.country`;
export const ADDRESS_FIELD_GEO = `${ADDRESS_FIELD_BASE}.geo`;

export const getLocationAccessResourceById = (orgId, locationId) =>
  `/api/v2/organizations/${orgId}/locations/${locationId}/waitlist`;

const getAddressComponent = (selectedSuggestion, type) => {
  const addressComponents = _.get(
    selectedSuggestion,
    'gmaps.address_components',
    _.noop
  );

  const foundAddressComponent = _.find(addressComponents, (addressComponent) =>
    _.includes(addressComponent.types, type)
  );

  return _.get(foundAddressComponent, 'short_name', '');
};

const getAddressCoordinates = (selectedSuggestion) =>
  _.get(selectedSuggestion, 'location', {});
const getFormattedAddress = (selectedSuggestion) =>
  _.get(selectedSuggestion, 'gmaps.formatted_address', '/');

export const parseGeoSuggestion = (selectedSuggestion) => {
  const streetRoute = getAddressComponent(
    selectedSuggestion,
    ADDRESS_COMPONENT_STREET_TYPE
  );
  const streetNumber = getAddressComponent(
    selectedSuggestion,
    ADDRESS_COMPONENT_STREET_NUMBER_TYPE
  );
  const town = getAddressComponent(
    selectedSuggestion,
    ADDRESS_COMPONENT_TOWN_TYPE
  );
  const zip = getAddressComponent(
    selectedSuggestion,
    ADDRESS_COMPONENT_ZIP_TYPE
  );
  const country = getAddressComponent(
    selectedSuggestion,
    ADDRESS_COMPONENT_COUNTRY_TYPE
  );
  const coordinates = getAddressCoordinates(selectedSuggestion);
  const formattedAddress = getFormattedAddress(selectedSuggestion);

  const parsedGeoSuggestion = {
    address: `${streetRoute} ${streetNumber}`,
    town,
    zip,
    country,
    formattedAddress
  };

  if (_.isFinite(coordinates.lat) && _.isFinite(coordinates.lng)) {
    _.set(parsedGeoSuggestion, 'coordinates', {
      latitude: coordinates.lat,
      longitude: coordinates.lng
    });
  }

  return parsedGeoSuggestion;
};

export const convertAddressToString = (address) => {
  if (!address) {
    return '';
  }

  const addressParts = [address.street1];

  if (_.has(address, 'street2') && !_.isEmpty(address.street2)) {
    addressParts.push(address.street2);
  }
  if (_.has(address, 'town') && !_.isEmpty(address.town)) {
    addressParts.push(address.town);
  }
  if (_.has(address, 'region') && !_.isEmpty(address.region)) {
    addressParts.push(address.region);
  }
  if (_.has(address, 'country') && !_.isEmpty(address.country)) {
    addressParts.push(address.country);
  }

  return addressParts.join(', ');
};

export default {
  parseGeoSuggestion
};

export const getReadableAddress = (addressObj) => {
  if (_.isEmptySafe(addressObj)) {
    return '';
  } else {
    const street = _.getNonEmpty(addressObj, 'street1', '');
    const zip = _.getNonEmpty(addressObj, 'zip', '');
    const town = _.getNonEmpty(addressObj, 'town', '');
    const country = _.getNonEmpty(addressObj, 'country', '');

    const zipWithTown = _.trim(`${zip} ${town}`);

    return [street, zipWithTown, country].filter(Boolean).join(', ');
  }
};
