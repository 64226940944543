import _ from 'lodash';

import logger from './logger';

export const EVENT_MOUSE_ENTER = 'mouseenter';
export const EVENT_MOUSE_LEAVE = 'mouseleave';
export const EVENT_MOUSE_DOWN = 'mousedown';
export const EVENT_MOUSE_OVER = 'mouseover';
export const EVENT_CLICK = 'click';
export const EVENT_DOUBLE_CLICK = 'dblclick';
export const EVENT_KEY_UP = 'keyup';
export const EVENT_RESIZE = 'resize';
export const EVENT_ENTER_KEY = 'Enter';
export const EVENT_ESCAPE_KEY = 'Escape';
export const EVENT_HOVER = 'hover';

const unsupportedEventType = (events) =>
  logger.error(`useDomEvent - unsupported event format: ${events}`);

export const preventDefaultEvent = (e) => {
  e.preventDefault();
};

/**
 * registerEvents - Helper method to un/register single or multiple DOM events
 *
 * @param {Element} domElement - An actual element reference in the DOM
 * @param {String[]|String} events - List of events or a single event
 * @param {Function} callback - Callback which will be called once the event fires
 * @param {Boolean} unregister - Param which inverts the behavior to unregister the events
 */
export const registerEvents = (
  domElement,
  events,
  callback,
  unregister = false
) => {
  let method = 'addEventListener';

  if (unregister) {
    method = 'removeEventListener';
  }

  const processEvent = (event) => {
    /*
     * Depending on what the method is
     * we either register or unregister the event
     */
    domElement[method](event, callback);
  };

  /*
   * Since events can be either an array or a string
   * we do a check to see how to process it
   */
  if (_.isArray(events)) {
    _.forEach(events, (event) => {
      /*
       * Since event needs to be a string we do a check
       * and return if its not
       */
      if (!_.isString(event)) {
        unsupportedEventType(event);

        return;
      }

      processEvent(event);
    });
  } else if (_.isString(events)) {
    processEvent(events);
  } else {
    unsupportedEventType(events);
  }
};
