import _ from 'lodash';
import moment from 'moment';

import LocalizedStringModel from '../metadata/model/LocalizedStringModel';

import {
  MOSHI_COLOR_DANGER_NAME,
  MOSHI_COLOR_PRIMARY_NAME,
  MOSHI_COLOR_PURPLE_NAME,
  MOSHI_COLOR_SUCCESS_NAME,
  MOSHI_COLOR_WARNING_NAME
} from './color';
import { BUILTIN_PAYMENT_TYPES } from './data/billing';
import { getSupportedSpaceInvoiceCurrencies } from './data/currency';
import logger from './logger';
import { NO_TAX } from './mappers/tax-mappers';

export const ITEM_NAME_COLUMN = 'name';
export const ITEM_QUANTITY_COLUMN = 'quantity';
export const ITEM_PRICE_COLUMN = 'price';
export const ITEM_DISCOUNT_COLUMN = 'discount';
export const ITEM_TAX_COLUMN = 'tax';
export const ITEM_TOTAL_COLUMN = 'total';
export const ITEM_UNIT_COLUMN = 'unit';
export const ITEM_DELETE_COLUMN = 'delete';

export const INVOICE_STATUS_ISSUED = 'issued';
export const INVOICE_STATUS_CANCELED = 'canceled';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_PARTIALLY_PAID = 'partiallyPaid';
export const INVOICE_STATUS_DRAFT = 'draft';
export const INVOICE_STATUS_OVERDUE = 'overdue';
export const INVOICE_STATUS_UNPAID = 'unpaid';
export const INVOICE_STATUS_DELETED = 'deleted';
export const INVOICE_STATUS_USED = 'used';
export const INVOICE_STATUS_PARTIALLY_USED = 'partiallyUsed';

export const BILL_TYPE_INVOICE = 'invoice';
export const BILL_TYPE_ESTIMATE = 'estimate';
export const BILL_TYPE_ADVANCE = 'advance';
export const BILL_TYPE_CREDIT_NOTE = 'credit-note';
export const BILL_TYPE_DELIVERY_NOTE = 'delivery-note';

export const BILL_LIST_FILTER_SEARCH = 'q';
export const BILL_LIST_FILTER_DOCTOR = 'doctorID';
export const BILL_LIST_FILTER_LOCATION = 'locationID';
export const BILL_LIST_FILTER_BILLING_UNIT = 'billingUnitID';
export const BILL_LIST_FILTER_ELECTRONIC_DEVICE = 'deviceID';
export const BILL_LIST_FILTER_STATUS = 'status';
export const BILL_LIST_FILTER_PAYMENT_TYPE = 'paymentType';
export const BILL_LIST_FILTER_TYPE = 'documentTypes';
export const BILL_LIST_FILTER_DATE_FROM = 'dateFrom';
export const BILL_LIST_FILTER_DATE_TO = 'dateTo';

export const BILL_ICONS = {
  [BILL_TYPE_INVOICE]: 'inbox-alt',
  [BILL_TYPE_ESTIMATE]: 'help',
  [BILL_TYPE_ADVANCE]: 'rotate',
  [BILL_TYPE_CREDIT_NOTE]: 'credit-card'
};

export const BILL_STATUS_LIST = [
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_OVERDUE,
  INVOICE_STATUS_UNPAID,
  INVOICE_STATUS_USED,
  INVOICE_STATUS_PARTIALLY_USED
];

export const ADVANCE_STATUS_LIST = [
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_USED,
  INVOICE_STATUS_PARTIALLY_USED
];

export const CREDIT_NOTE_STATUS_LIST = [
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_PAID
];

export const INVOICE_STATUS_LIST = [
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_OVERDUE,
  INVOICE_STATUS_UNPAID
];

export const BILLING_FILE_TYPE_CASH_REGISTER_LOG = 'cashRegisterLog';

export const BILLING_ITEM_PRICE_TYPE_NET = 'net';
export const BILLING_ITEM_PRICE_TYPE_GROSS = 'gross';

export const BILL_DAY_PICKER_OPTIONS = [0, 8, 15, 30, 45, 60, 90];

export const supportedCurrenciesFilter = (currency) => {
  const supportedCurrencies = getSupportedSpaceInvoiceCurrencies();

  return _.includes(supportedCurrencies, currency.id);
};

export const getStatusColor = (status) => {
  switch (status) {
    case INVOICE_STATUS_CANCELED:
    case INVOICE_STATUS_DELETED:
      return MOSHI_COLOR_DANGER_NAME;
    case INVOICE_STATUS_PARTIALLY_PAID:
    case INVOICE_STATUS_PARTIALLY_USED:
      return MOSHI_COLOR_WARNING_NAME;
    case INVOICE_STATUS_OVERDUE:
      return MOSHI_COLOR_PURPLE_NAME;
    case INVOICE_STATUS_PAID:
    case INVOICE_STATUS_USED:
      return MOSHI_COLOR_SUCCESS_NAME;
    case INVOICE_STATUS_DRAFT:
    case INVOICE_STATUS_ISSUED:
      return MOSHI_COLOR_PRIMARY_NAME;
    default:
      logger.error(`Unsupported invoice status: ${status}`);

      return null;
  }
};

export const handleBillDocumentBillingUnitChange = (
  billingUnitList,
  billingUnitID,
  updateField,
  defaultLocale,
  prevBillingUnitID = null
) => {
  const billingUnit = _.find(billingUnitList, {
    id: billingUnitID
  });

  if (_.isEmpty(billingUnit) || prevBillingUnitID === billingUnitID) {
    return;
  }

  const footer = _.get(
    billingUnit,
    LocalizedStringModel.getFieldPath('texts.footer', defaultLocale)
  );
  const signature = _.get(
    billingUnit,
    LocalizedStringModel.getFieldPath('texts.signature', defaultLocale)
  );

  updateField('texts.footer', footer);
  updateField('texts.signature', signature);

  if (prevBillingUnitID !== null) {
    if (billingUnit.isFursActive) {
      updateField('furs.businessPremiseID', '');
      updateField('furs.electronicDeviceID', '');
    } else if (!billingUnit.isFursActive) {
      updateField('furs', undefined);
    }
  }

  return billingUnit;
};

export const handleBillItemsChange = (prevFormik, formik) => {
  const prevItems = _.get(prevFormik, 'values.items', []);
  const nextItems = _.get(formik, 'values.items', []);
  const payments = _.get(formik, 'values.payments', []);
  const referencedDocuments = _.get(formik, 'values.referencedDocuments', []);
  const setFieldValue = _.get(prevFormik, 'setFieldValue', _.noop);

  if (!_.isEqual(prevItems, nextItems)) {
    if (!_.isEmptySafe(payments)) {
      setFieldValue('payments', []);
    }
    if (!_.isEmptySafe(referencedDocuments)) {
      setFieldValue('referencedDocuments', []);
    }
  }
};

const checkIfBillNumberUnique = (
  prevFormik,
  formik,
  billType,
  getBillNumberIsUniqueDispatch
) => {
  const bill = _.get(formik, 'values', {});
  const billingUnitId = _.get(bill, 'billingUnitID', null);
  const nextBillNumber = _.get(bill, 'number', null);
  const prevBillNumber = _.get(prevFormik, 'values.number', null);
  const setFieldValue = _.get(prevFormik, 'setFieldValue', _.noop);
  const setFieldTouched = _.get(prevFormik, 'setFieldTouched', _.noop);

  if (
    !_.isEmpty(billingUnitId) &&
    !_.isEmpty(nextBillNumber) &&
    prevBillNumber !== nextBillNumber &&
    !isBillFiscalized(bill)
  ) {
    getBillNumberIsUniqueDispatch(nextBillNumber, billingUnitId, billType).then(
      (response) => {
        const isNumberUnique = _.get(response, 'unique', true);

        setFieldValue('isNumberUnique', isNumberUnique);
        setFieldTouched('number');
      }
    );
  }
};

export const handleBillNumberChange = (
  billType,
  getBillNumberIsUniqueDispatch
) =>
  _.debounce(
    // Debounce: call the number uniqueness check endpoint only when done typing
    (prevFormik, formik) =>
      checkIfBillNumberUnique(
        prevFormik,
        formik,
        billType,
        getBillNumberIsUniqueDispatch
      ),
    300
  );

export const isLogLatestOnDevice = (log, electronicDevicesPerBillingUnit) => {
  const devices = _.flatten(_.values(electronicDevicesPerBillingUnit));
  const electronicDeviceId = _.getNonEmpty(log, 'electronicDeviceID');
  const device = _.find(devices, { id: electronicDeviceId });
  const latestDeviceLogDate = _.getNonEmpty(
    device,
    'lastCashRegisterLog.date',
    null
  );

  return !_.isEmpty(latestDeviceLogDate) && log.date === latestDeviceLogDate;
};

export const getBillDocumentItemTemplateColumnValues = (
  hiddenColumnList,
  isPreview = false
) => {
  const templateColumns = [
    { name: ITEM_NAME_COLUMN, width: '1fr' },
    { name: ITEM_QUANTITY_COLUMN, width: '75px' },
    { name: ITEM_UNIT_COLUMN, width: '85px' },
    { name: ITEM_PRICE_COLUMN, width: '120px' },
    { name: ITEM_DISCOUNT_COLUMN, width: '100px' },
    { name: ITEM_TAX_COLUMN, width: isPreview ? '60px' : '100px' },
    { name: ITEM_TOTAL_COLUMN, width: '120px' },
    { name: ITEM_DELETE_COLUMN, width: '30px' }
  ];

  const filteredTemplateColumns = _.filter(
    templateColumns,
    (templateColumn) => !_.includes(hiddenColumnList, templateColumn.name)
  );

  return filteredTemplateColumns.map(
    (filteredTemplateColumn) => filteredTemplateColumn.width
  );
};

export const groupItemsByTaxRate = (itemList) => {
  const itemsByTaxRate = {};

  _.forEach(itemList, (item) => {
    let taxRate = _.getNonEmpty(item, 'taxes.0.rate');

    if (!_.isFinite(taxRate)) {
      taxRate = NO_TAX;
    }

    if (!_.isArray(_.get(itemsByTaxRate, taxRate))) {
      itemsByTaxRate[taxRate] = [];
    }
    itemsByTaxRate[taxRate].push(item);
  });

  return itemsByTaxRate;
};

export const isBillFiscalized = (bill) =>
  !_.isEmptySafe(bill, 'furs.electronicDeviceID');

export const showAddBillPaymentForm = (billType, billStatus, remainingDue) =>
  !_.includes(billStatus, INVOICE_STATUS_CANCELED) &&
  ((remainingDue > 0 && billType !== BILL_TYPE_CREDIT_NOTE) ||
    (remainingDue < 0 && billType === BILL_TYPE_CREDIT_NOTE));

export const hideEditBillDocument = (bill) => {
  const isCanceled = _.includes(bill.status, INVOICE_STATUS_CANCELED);
  const isFiscalizedAndIssued =
    !_.includes(bill.status, INVOICE_STATUS_DRAFT) && isBillFiscalized(bill);

  return isCanceled || isFiscalizedAndIssued;
};

export const hideBillPdfAction = (bill) =>
  _.includes(bill.status, INVOICE_STATUS_DRAFT) ||
  _.isEmptySafe(bill, 'invoicingID');

export const hideIfBillDraft = (bill) =>
  _.includes(bill.status, INVOICE_STATUS_DRAFT);

export const hideConvertDraftToBillDocument = (bill, billType) => {
  const safeBillType = getBillType(bill, billType);

  return (
    !_.includes(bill.status, INVOICE_STATUS_DRAFT) ||
    safeBillType !== BILL_TYPE_INVOICE
  );
};

export const hideDeletionOfBillDocument = (bill, billType) => {
  const safeBillType = getBillType(bill, billType);

  return (
    !_.includes(bill.status, INVOICE_STATUS_DRAFT) &&
    safeBillType !== BILL_TYPE_ESTIMATE
  );
};

export const hideCancellationOfNonFiscalizedBillDocument = (bill, billType) => {
  const safeBillType = getBillType(bill, billType);
  const isDraftOrCanceled = _.includesAny(bill.status, [
    INVOICE_STATUS_CANCELED,
    INVOICE_STATUS_DRAFT
  ]);

  return (
    isBillFiscalized(bill) ||
    isDraftOrCanceled ||
    safeBillType === BILL_TYPE_ESTIMATE
  );
};

export const hideCancellationOfFiscalizedBillDocument = (bill, billType) => {
  const safeBillType = getBillType(bill, billType);
  const isDraftOrCanceled = _.includesAny(bill.status, [
    INVOICE_STATUS_CANCELED,
    INVOICE_STATUS_DRAFT
  ]);
  const isCreditNote = safeBillType === BILL_TYPE_CREDIT_NOTE;

  return !isBillFiscalized(bill) || isDraftOrCanceled || isCreditNote;
};

export const checkIfBillPaid = (payments, billTotal, billType) => {
  if (billType === BILL_TYPE_CREDIT_NOTE) {
    return payments <= billTotal;
  }

  return payments >= billTotal;
};

export const hideFiscalizationRetryBillDocument = (bill, billType) => {
  const safeBillType = getBillType(bill, billType);
  const fursObject = _.getNonEmpty(bill, 'furs', {});
  const billStatus = _.getNonEmpty(bill, 'status', []);

  if (
    _.isEmpty(fursObject) ||
    _.includes(billStatus, INVOICE_STATUS_DRAFT) ||
    safeBillType === BILL_TYPE_ESTIMATE
  ) {
    return true;
  }
  const fursKeysExist = _.includesEach(_.keys(fursObject), [
    'EOR',
    'ZOI',
    'QR'
  ]);

  return fursKeysExist;
};

export const getBillType = (bill, billType) => {
  if (!_.isEmpty(billType)) {
    return billType;
  }

  return _.get(bill, 'type');
};

export const getBillTotalUnused = (bill) =>
  _.round(_.get(bill, 'total', 0) - _.get(bill, 'totalUsed', 0), 2);

export const getBillTotalsByTaxRate = (bill) => {
  const items = _.get(bill, 'items', []);

  const taxRateTotals = {};

  _.forEach(items, (item) => {
    const taxRate = item.taxRate;

    if (_.isEmpty(taxRateTotals[taxRate])) {
      taxRateTotals[taxRate] = { priceWithDiscount: 0, totalTax: 0 };
    }

    taxRateTotals[taxRate].priceWithDiscount += item.priceWithDiscount;
    taxRateTotals[taxRate].totalTax += item.taxAmount;
  });

  return taxRateTotals;
};

export const getDateFilterParametersPayload = (filters) => {
  const { dateFrom, dateTo, ...otherFilters } = filters;

  const newFilters = otherFilters;

  if (!_.isEmptySafe(dateFrom)) {
    newFilters.from = moment(dateFrom).format('YYYY-MM-DD');
  }

  if (!_.isEmptySafe(dateTo)) {
    newFilters.to = moment(dateTo).format('YYYY-MM-DD');
  }

  return newFilters;
};

export const getPaymentTypeFilterParametersPaylad = (filters) => {
  const { paymentType, ...cleanFilters } = filters;

  if (_.isEmpty(paymentType)) {
    return cleanFilters;
  }

  if (_.includes(BUILTIN_PAYMENT_TYPES, paymentType)) {
    cleanFilters.paymentTypes = paymentType;
  } else {
    cleanFilters.customPaymentIDs = paymentType;
  }

  return cleanFilters;
};

export const filterDuplicatedBill = (bill, duplicationType) => {
  const filterItems = (items) => {
    const itemsWithoutReferencedDocuments = _.filter(items, (item) =>
      _.isEmpty(item.referencedDocuments)
    );

    const itemsWithoutUnbilledItemID = _.cloneDeep(
      itemsWithoutReferencedDocuments
    );

    _.forEach(itemsWithoutUnbilledItemID, (item) => delete item.unbilledItemID);

    if (duplicationType === BILL_TYPE_CREDIT_NOTE) {
      _.forEach(itemsWithoutUnbilledItemID, (item) => {
        if (_.getNonEmpty(item, 'quantity', -1) > 0) {
          item.quantity = -item.quantity;
        }

        return item.quantity;
      });
    } else {
      _.forEach(itemsWithoutUnbilledItemID, (item) => {
        if (_.getNonEmpty(item, 'quantity', 1) < 0) {
          item.quantity = -item.quantity;
        }

        return item.quantity;
      });
    }

    return itemsWithoutUnbilledItemID;
  };

  if (_.has(bill, 'furs') && duplicationType === BILL_TYPE_ESTIMATE) {
    delete bill.furs;
  }

  if (_.has(bill, 'texts.note')) {
    delete bill.texts.note;
  }

  return {
    billingUnitID: bill.billingUnitID,
    billingUnits: bill.billingUnits,
    client: bill.client,
    currency: bill.currency,
    doctorID: bill.doctorID,
    locationID: bill.locationID,
    patientID: bill.patientID,
    patient: bill.patient,
    items: filterItems(bill.items),
    texts: bill.texts,
    furs: bill.furs
  };
};

export const getBillBulkEditUpdateConfig = (
  { tax, price, categoryID, unit },
  selectedItems,
  defaultLocale
) => {
  const itemsIDs = _.map(selectedItems, 'id');
  const updateConfig = { itemsIDs };

  if (!_.isEmptySafe(tax)) {
    tax === NO_TAX ? (updateConfig.taxIDs = []) : (updateConfig.taxIDs = [tax]);
  }
  if (!_.isEmptySafe(price)) {
    updateConfig.price = price;
  }
  if (!_.isEmptySafe(categoryID)) {
    updateConfig.categoryID = categoryID;
  }
  if (!_.isEmptySafe(unit)) {
    updateConfig.unit = { [defaultLocale]: unit };
  }

  return updateConfig;
};

export const getInvoiceDates = (
  billingUnitID,
  billingUnits,
  defaultDate = moment()
) => {
  const date = moment(defaultDate).format('YYYY-MM-DD');

  const defaultBillingUnit = _.findDefault(
    billingUnits,
    { id: billingUnitID },
    null
  );

  const dueDays = _.getNonEmpty(
    defaultBillingUnit,
    'paymentDetails.invoiceDueDays',
    7
  );

  const dueDate = moment().add(dueDays, 'days').format('YYYY-MM-DD');

  return {
    date,
    dateService: date,
    dueDate
  };
};

export const EXPORT_COLUMN_NUMBER = 'number';
export const EXPORT_REFERENCE_NUMBER = 'referenceNumber';
export const EXPORT_COLUMN_TYPE = 'type';
export const EXPORT_COLUMN_PATIENT_NUMBER = 'patientNumber';
export const EXPORT_COLUMN_CLIENT_ADDRESS = 'clientAddress';
export const EXPORT_COLUMN_CLIENT_NAME = 'clientName';
export const EXPORT_COLUMN_CLIENT_TAX_NUMBER = 'clientTaxNumber';
export const EXPORT_COLUMN_CLIENT_REGISTRATION_NUMBER =
  'clientRegistratioNumber';
export const EXPORT_COLUMN_DOCTOR_ID = 'doctorID';
export const EXPORT_COLUMN_DOCTOR_NAME = 'doctorName';
export const EXPORT_COLUMN_CANCELED = 'cancelled';
export const EXPORT_COLUMN_TOTAL = 'total';
export const EXPORT_COLUMN_TOTAL_WITHOUT_TAX = 'totalWithoutTax';
export const EXPORT_COLUMN_TAX = 'tax';
export const EXPORT_COLUMN_PAID = 'paid';
export const EXPORT_COLUMN_TOTAL_PAID = 'totalPaid';
export const EXPORT_COLUMN_PAYMENT_TYPE = 'paymentType';
export const EXPORT_COLUMN_DUE_DATE = 'dueDate';
export const EXPORT_COLUMN_CREATED_AT = 'createdAt';
export const EXPORT_COLUMN_DOCUMENT_DATE = 'documentDate';
export const EXPORT_COLUMN_ISSUED_AT = 'issuedAt';
export const EXPORT_COLUMN_ITEM_NAME = 'itemName';
export const EXPORT_COLUMN_ITEM_CATEGORY_NAME = 'itemCategoryName';
export const EXPORT_COLUMN_ITEM_TOTAL = 'itemTotal';
export const EXPORT_COLUMN_ITEM_PRICE = 'itemPrice';
export const EXPORT_COLUMN_ITEM_TAX = 'itemTax';
export const EXPORT_COLUMN_ITEM_QUANTITY = 'itemQuantity';

export const BILLING_EXPORT_COLUMNS = [
  EXPORT_COLUMN_NUMBER,
  EXPORT_REFERENCE_NUMBER,
  EXPORT_COLUMN_TYPE,
  EXPORT_COLUMN_PATIENT_NUMBER,
  EXPORT_COLUMN_CLIENT_ADDRESS,
  EXPORT_COLUMN_CLIENT_NAME,
  EXPORT_COLUMN_CLIENT_TAX_NUMBER,
  EXPORT_COLUMN_CLIENT_REGISTRATION_NUMBER,
  EXPORT_COLUMN_DOCTOR_ID,
  EXPORT_COLUMN_DOCTOR_NAME,
  EXPORT_COLUMN_CANCELED,
  EXPORT_COLUMN_TOTAL,
  EXPORT_COLUMN_TOTAL_WITHOUT_TAX,
  EXPORT_COLUMN_TAX,
  EXPORT_COLUMN_PAID,
  EXPORT_COLUMN_TOTAL_PAID,
  EXPORT_COLUMN_PAYMENT_TYPE,
  EXPORT_COLUMN_DUE_DATE,
  EXPORT_COLUMN_CREATED_AT,
  EXPORT_COLUMN_DOCUMENT_DATE,
  EXPORT_COLUMN_ISSUED_AT,
  EXPORT_COLUMN_ITEM_NAME,
  EXPORT_COLUMN_ITEM_CATEGORY_NAME,
  EXPORT_COLUMN_ITEM_TOTAL,
  EXPORT_COLUMN_ITEM_PRICE,
  EXPORT_COLUMN_ITEM_TAX,
  EXPORT_COLUMN_ITEM_QUANTITY
];
