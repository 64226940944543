import _ from 'lodash';
import { createSelector } from 'reselect';

import { translateKey } from '../../../i18n';

import { selectCurrentLocation } from '../../location/locationSelectors';
import { selectCurrentOrganization } from '../../organization/organizationSelectors';
import { selectCurrentUser } from '../../user/userSelectors';

export const allSignInFields = [
  'name',
  'dateOfBirth',
  'email',
  'phoneNumber',
  'address',
  'gender'
];

export const selectAppConfigStore = (store) => store.appConfig;

export const selectAppConfigDetail = createSelector(
  [selectAppConfigStore],
  (appConfigStore) => _.getNonEmpty(appConfigStore, 'detail', {})
);

export const selectAppConfigFlowIds = createSelector(
  [selectAppConfigDetail],
  (detail) => _.getNonEmpty(detail, 'flows', [])
);

// SIGN IN FIELDS
export const selectAppConfigSignInDetail = createSelector(
  [selectAppConfigDetail],
  (appConfigDetail) => _.getNonEmpty(appConfigDetail, 'checkIn', {})
);

export const selectAppConfigSignInRequiredFields = createSelector(
  [selectAppConfigSignInDetail],
  (signInDetail) => _.getNonEmpty(signInDetail, 'required', [])
);

export const selectAppConfigSignInEnabledFields = createSelector(
  [selectAppConfigSignInDetail],
  (signInDetail) => {
    const enabledFields = _.getNonEmpty(signInDetail, 'enabled', []);

    enabledFields.sort(
      (a, b) => allSignInFields.indexOf(a) - allSignInFields.indexOf(b)
    );

    return enabledFields;
  }
);

export const selectSignInDisabledFieldsMapper = createSelector(
  [selectAppConfigSignInEnabledFields],
  (signInEnabledFields) =>
    _.difference(allSignInFields, signInEnabledFields).map((field) => ({
      id: field,
      name: translateKey(
        `settings:automatedCheckIn.signInFields.newPatient.fields.${field}`
      )
    }))
);

export const selectSignInFields = createSelector(
  [selectAppConfigSignInEnabledFields, selectAppConfigSignInRequiredFields],
  (enabledFields, requiredFields) => {
    // Fields that cannot be made optional
    const mandatoryFields = ['dateOfBirth', 'phoneNumber'];
    // Fields that cannot be removed
    const unremovableFields = ['dateOfBirth', 'phoneNumber'];

    return enabledFields.map((field) => ({
      id: field,
      mandatory: _.includes(requiredFields, field),
      disabled: _.includes(mandatoryFields, field),
      disableRemove: _.includes(unremovableFields, field)
    }));
  }
);

export const selectShowLoader = createSelector(
  [selectCurrentUser, selectCurrentOrganization, selectCurrentLocation],
  (currentUser, currentOrganization, selectedLocation) =>
    _.isEmptySafe(currentUser) ||
    _.isEmptySafe(currentOrganization) ||
    _.isEmptySafe(selectedLocation)
);

export const selectIsSidebarCollapsed = createSelector(
  [selectAppConfigStore],
  (appConfig) => _.getNonEmpty(appConfig, 'isSidebarCollapsed', false)
);

export const selectAppConfigVisual = createSelector(
  [selectAppConfigDetail],
  (detail) => _.getNonEmpty(detail, 'visual', {})
);
