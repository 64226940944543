import _ from 'lodash';
import { createSelector } from 'reselect';

import { translateKey } from '../../i18n';
import {
  BUCKET_TYPE_COMPLETED,
  BUCKET_TYPE_CONSULTATION,
  BUCKET_TYPE_DEFAULT,
  BUCKET_TYPE_NORMAL
} from '../../utils/constants/encounterConstants';

import {
  selectCachedAndDeletedFlowList,
  selectCachedAndDeletedLocationList,
  selectCachedAndDeletedDoctorListByCurrentLocation,
  selectCachedAndDeletedUserList
} from '../core/cache/cacheSelectors';
import { selectCurrentUser } from '../user/userSelectors';

export const selectWaitingListItemMapper = createSelector(
  [
    selectCachedAndDeletedDoctorListByCurrentLocation,
    selectCachedAndDeletedFlowList,
    selectCachedAndDeletedLocationList
  ],
  (userList, flowList, locationList) => {
    const mapper = (encounter) => {
      if (_.isEmpty(encounter)) {
        return encounter;
      }

      if (!_.isEmpty(encounter.doctorID)) {
        const foundDoctor = _.find(userList, {
          id: encounter.doctorID
        });

        if (!_.isEmpty(foundDoctor)) {
          encounter.doctor = foundDoctor;
          encounter.doctorColor = _.get(encounter, 'doctor.color');
        }
      }

      if (!_.isEmpty(encounter.flowID)) {
        encounter.flow = _.find(flowList, {
          id: encounter.flowID
        });
      }

      if (!_.isEmpty(encounter.locationID)) {
        encounter.location = _.find(locationList, {
          id: encounter.locationID
        });
      }

      return encounter;
    };

    return mapper;
  }
);

export const selectBucketConfigToWaitingListDataMapper = createSelector(
  [selectWaitingListItemMapper],
  (waitingListItemMapper) => (waitingList) => (waitingListColumn) => {
    const foundWaitingListColumn = _.findDefault(
      waitingList,
      { bucketID: waitingListColumn.id },
      {}
    );

    switch (waitingListColumn.type) {
      case BUCKET_TYPE_DEFAULT:
        waitingListColumn.description = translateKey(
          'dashboard:bucket.default'
        );
        break;
      case BUCKET_TYPE_NORMAL:
        waitingListColumn.description = translateKey('dashboard:bucket.normal');
        break;
      case BUCKET_TYPE_CONSULTATION:
        waitingListColumn.description = translateKey(
          'dashboard:bucket.consultation'
        );
        break;
      case BUCKET_TYPE_COMPLETED:
        waitingListColumn.description = translateKey(
          'dashboard:bucket.completed'
        );
        break;
      default:
        break;
    }

    const bucketEncounters = _.get(foundWaitingListColumn, 'encounters', []);

    waitingListColumn.encounters = bucketEncounters.map(waitingListItemMapper);
    waitingListColumn.assistRequests = _.get(
      foundWaitingListColumn,
      'assistRequests',
      []
    );

    return waitingListColumn;
  }
);

export const selectEncounterCommentMapper = createSelector(
  [selectCachedAndDeletedUserList, selectCurrentUser],
  (usersList, currentUser) => {
    const mapper = (comment) => {
      comment.author = _.findDefault(usersList, { id: comment.authorID }, {});

      comment.isCurrentUser = comment.authorID === currentUser.id;

      return comment;
    };

    return mapper;
  }
);
