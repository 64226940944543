import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import axios from 'axios';
import _ from 'lodash';

import { getAllDocumentTypesListUrl } from '../../../utils/api/apiUrlUtils';
import {
  EXTERNAL,
  INBOUND,
  INBOUND_REQUEST,
  INBOUND_SUBMISSION,
  INBOUND_TEMPLATE,
  OUTBOUND_DOCUMENT,
  OUTBOUND_TEMPLATE
} from '../../../utils/constants/documentConstants';
import {
  mapDuplicatedInboundDocument,
  mapDuplicatedOutboundDocument,
  replaceDocumentName
} from '../../../utils/documentUtils';

import {
  clearOutboundDocumentDetails,
  getOutboundDocument,
  removeOutboundDocument,
  updateOutboundDocument
} from '../../document/outbound/outboundDocumentActions';
import { getAllDocumentTemplatesList } from '../allDocumentTemplates/allDocumentTemplatesActions';
import { removeExternalDocument } from '../external/formExternalActions';
import {
  clearFormRequestDetail,
  removeFormRequest
} from '../request/formRequestActions';
import {
  clearFormSubmissionDetail,
  removeFormSubmission
} from '../submission/formSubmissionActions';
import {
  addFormTemplate,
  addOrUpdateFormTemplate,
  getFormTemplateDetail,
  removeFormTemplate
} from '../template/formTemplateActions';

import {
  selectAllDocumentTypesFilters,
  selectAllDocumentTypesPagination
} from './allDocumentTypesSelectors';
import {
  SET_ALL_DOCUMENT_TYPES_LIST_PAGINATION,
  SET_ALL_DOCUMENT_TYPES_LIST_FILTER,
  CLEAR_ALL_DOCUMENT_TYPES_LIST,
  CLEAR_ALL_DOCUMENT_TYPES_LIST_FILTERS,
  SET_ALL_DOCUMENT_TYPES_LIST_ORDER,
  GET_ALL_DOCUMENT_TYPES_LIST
} from './allDocumentTypesTypes';

export const getAllDocumentTypesList = (patientId) => (dispatch, getState) => {
  const { limit, page } = selectAllDocumentTypesPagination(getState());
  let filters = selectAllDocumentTypesFilters(getState());
  const includeTypesFilter = _.getNonEmpty(filters, 'includeTypes', '');
  const allDocumentTypesUrl = getAllDocumentTypesListUrl(getState);

  if (!_.isEmptySafe(patientId)) {
    filters = { ...filters, patientIDs: patientId };
  }

  const inboundTypes = [INBOUND_SUBMISSION, INBOUND_REQUEST];

  if (includeTypesFilter === INBOUND) {
    filters = {
      ...filters,
      includeTypes: inboundTypes.join(',')
    };
  }

  return dispatch({
    type: GET_ALL_DOCUMENT_TYPES_LIST,
    apiCall: axios.get(allDocumentTypesUrl, {
      params: {
        ...filters,
        limit,
        page
      }
    })
  });
};

export const setAllDocumentTypesOrder = (sortBy, order, patientId) => (
  dispatch
) => {
  dispatch({ type: SET_ALL_DOCUMENT_TYPES_LIST_ORDER, sortBy, order });

  return dispatch(getAllDocumentTypesList(patientId));
};

export const setAllDocumentTypesPagination = (
  paginationName,
  paginationValue,
  patientId
) => (dispatch) => {
  dispatch({
    type: SET_ALL_DOCUMENT_TYPES_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

  return dispatch(getAllDocumentTypesList(patientId));
};

export const setAllDocumentTypesFilter = (filters, patientId) => (dispatch) => {
  dispatch({
    type: SET_ALL_DOCUMENT_TYPES_LIST_FILTER,
    filters
  });

  return dispatch(getAllDocumentTypesList(patientId));
};

export const clearAllDocumentTypesFilters = (patientId) => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_DOCUMENT_TYPES_LIST_FILTERS
  });

  return dispatch(getAllDocumentTypesList(patientId));
};

export const clearAllDocumentTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_ALL_DOCUMENT_TYPES_LIST
  });

export const removeAnyDocumentType = (document) => (dispatch) => {
  const type = _.getNonEmpty(document, 'type');
  const patientId = _.getNonEmpty(document, 'patient.id', null);
  const documentId = _.getNonEmpty(document, 'id');

  switch (type) {
    case INBOUND_SUBMISSION:
      return dispatch(removeFormSubmission(patientId, documentId));
    case INBOUND_REQUEST:
      return dispatch(removeFormRequest(patientId, documentId));
    case OUTBOUND_DOCUMENT:
      return dispatch(removeOutboundDocument(documentId));
    case EXTERNAL:
      return dispatch(removeExternalDocument(patientId, documentId));
    case INBOUND_TEMPLATE:
      return dispatch(removeFormTemplate(documentId));
    case OUTBOUND_TEMPLATE:
      return dispatch(removeOutboundDocument(documentId, true));
    default:
  }
};

export const removeAnyDocumentTypeWithRefetch = (document) => (dispatch) => {
  dispatch(removeAnyDocumentType(document)).then(() => {
    const documentType = _.getNonEmpty(document, 'type');

    if (_.includes([INBOUND_TEMPLATE, OUTBOUND_TEMPLATE], documentType)) {
      return dispatch(getAllDocumentTemplatesList());
    }

    const patientId = _.getNonEmpty(document, 'patientID');

    dispatch(getAllDocumentTypesList(patientId));
  });
};

export const getAnyDocumentTypeFormSchema = (document, onSuccess = _.noop) => (
  dispatch
) => {
  const type = _.getNonEmpty(document, 'type');

  switch (type) {
    case INBOUND_SUBMISSION:
    case INBOUND_TEMPLATE:
    case INBOUND_REQUEST:
      const requestFormId = _.getNonEmpty(document, 'formID');
      const requestFormVersion = _.getNonEmpty(document, 'formVersion');

      return dispatch(
        getFormTemplateDetail(requestFormId, requestFormVersion, onSuccess)
      );
    case OUTBOUND_DOCUMENT:
    case OUTBOUND_TEMPLATE:
      const documentId = _.getNonEmpty(document, 'id');

      return dispatch(getOutboundDocument(documentId, onSuccess));
    default:
  }
};

export const duplicateAnyDocumentType = (patientId, routes, document) => (
  dispatch
) => {
  const onSuccess = (response) => {
    const data = _.getNonEmpty(response, 'data');
    const type = _.getNonEmpty(document, 'type');

    const isInboundDocument = _.includes(
      [INBOUND_SUBMISSION, INBOUND_REQUEST, INBOUND_TEMPLATE],
      type
    );

    const newDocumentPath = isInboundDocument
      ? routes.INBOUND_TEMPLATE_NEW
      : routes.OUTBOUND_NEW;

    const documentData = isInboundDocument
      ? mapDuplicatedInboundDocument(data)
      : mapDuplicatedOutboundDocument(data);

    if (isInboundDocument) {
      dispatch(clearFormSubmissionDetail());
      dispatch(clearFormRequestDetail());
    } else {
      dispatch(clearOutboundDocumentDetails());
    }

    const generatedBackRoute = generatePath(routes.ALL, { patientId });

    dispatch(
      push(generatePath(newDocumentPath, { patientId }), {
        documentData,
        backRoute: generatedBackRoute
      })
    );
  };

  return dispatch(getAnyDocumentTypeFormSchema(document, onSuccess));
};

export const saveInboundDocumentAsTemplate = (document, onSuccess = _.noop) => (
  dispatch
) => {
  const onGetFormSchemaSuccess = (response) => {
    const data = _.getNonEmpty(response, 'data', {});
    const inboundDocumentData = mapDuplicatedInboundDocument(data);

    return dispatch(addFormTemplate(inboundDocumentData, false, onSuccess));
  };

  return dispatch(
    getAnyDocumentTypeFormSchema(document, onGetFormSchemaSuccess)
  );
};

export const saveInboundDocumentAsTemplateWithRedirect = (
  patientId,
  routes,
  document
) => (dispatch) => {
  const onSuccess = (response) => {
    const data = _.getNonEmpty(response, 'data');

    const generatedPath = generatePath(routes.INBOUND_TEMPLATE_DETAIL, {
      formId: _.getNonEmpty(data, 'id'),
      patientId
    });

    dispatch(push(generatedPath, { isPreviewMode: false }));
  };

  return dispatch(saveInboundDocumentAsTemplate(document, onSuccess));
};

export const renameAnyDocumentType = (document, onSuccess) => (dispatch) => {
  const onGetFormSchemaSuccess = (response) => {
    const data = _.getNonEmpty(response, 'data');
    const documentId = _.getNonEmpty(data, 'id');
    const documentType = _.getNonEmpty(document, 'type');
    const documentName = _.getNonEmpty(document, 'name');

    const updatedDocumentDetail = replaceDocumentName(
      data,
      documentName,
      documentType
    );

    switch (documentType) {
      case OUTBOUND_DOCUMENT:
      case OUTBOUND_TEMPLATE:
        dispatch(
          updateOutboundDocument(documentId, updatedDocumentDetail, onSuccess)
        );
        break;
      case INBOUND_TEMPLATE:
        dispatch(
          addOrUpdateFormTemplate(
            updatedDocumentDetail,
            false,
            null,
            false,
            onSuccess
          )
        );
        break;
      default:
    }
  };

  return dispatch(
    getAnyDocumentTypeFormSchema(document, onGetFormSchemaSuccess)
  );
};
