import moment from 'moment';
import { call, race, select, take } from 'redux-saga/effects';

import { getOrganizationWorkingHoursUrl } from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import {
  ASSIGNEE_TYPE_LOCATION,
  WORKING_HOURS_TYPE_CLOSED
} from '../../utils/constants/workingHoursConstants';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import {
  ADD_CLOSED_DATES_SUCCESS,
  DELETE_MULTIPLE_CLOSED_DATES,
  GET_CLOSED_DATES,
  START_CLOSED_DATES_POLLING,
  STOP_CLOSED_DATES_POLLING
} from './closedDatesTypes';

import { selectClosedDatesPagination } from './closedDatesSelectors';

export function* pollClosedDatesSaga() {
  const state = yield select();

  const { page } = selectClosedDatesPagination(state);
  const configGetter = () => ({
    params: {
      from: moment(new Date(0)).format('YYYY-MM-DD'),
      to: moment().add(100, 'years').format('YYYY-MM-DD'),
      types: WORKING_HOURS_TYPE_CLOSED,
      limit: REQUEST_PAGINATION_MAX_LIMIT,
      page,
      includeAssigneeTypes: ASSIGNEE_TYPE_LOCATION
    }
  });

  yield pollGenerator(GET_CLOSED_DATES, getOrganizationWorkingHoursUrl, {
    retriggerActions: [ADD_CLOSED_DATES_SUCCESS, DELETE_MULTIPLE_CLOSED_DATES],
    configGetter
  });
}

export function* closedDatesListSagaWatcher() {
  while (true) {
    yield take(START_CLOSED_DATES_POLLING);
    yield race([call(pollClosedDatesSaga), take(STOP_CLOSED_DATES_POLLING)]);
  }
}
