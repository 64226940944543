import produce from 'immer';
import _ from 'lodash';

import { generateEhrId } from '../../../utils/gen';

import {
  CLEAR_QUESTIONNAIRE_SUBMISSION_DATA,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_SUCCESS,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_FAILURE,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_IN_PROGRESS,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_SUCCESS,
  GET_PATIENT_QUESTIONNAIRE_DATA_FAILURE,
  GET_PATIENT_QUESTIONNAIRE_DATA_IN_PROGRESS,
  GET_PATIENT_QUESTIONNAIRE_DATA_SUCCESS,
  GET_QUESTIONNAIRE_SUBMISSION_DATA_FAILURE,
  GET_QUESTIONNAIRE_SUBMISSION_DATA_IN_PROGRESS,
  GET_QUESTIONNAIRE_SUBMISSION_DATA_SUCCESS,
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_FAILURE,
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_IN_PROGRESS,
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_SUCCESS
} from './questionnaireSubmissionTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  list: [],
  loadingList: false,
  detail: null,
  loadingDetail: false,
  submissionList: []
};

// eslint-disable-next-line default-param-last
const questionnaireSubmissionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_IN_PROGRESS:
        draft.loadingList = true;
        draft.list = [];
        break;
      case GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_SUCCESS:
        const encounterSubmissions = _.get(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = encounterSubmissions;
        break;
      case GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_FAILURE:
        draft.loadingList = false;
        draft.list = [];
        break;
      case GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_SUCCESS:
        const encounterSubmissionDataListRaw = _.get(payload, 'data', []);
        let encounterSubmissionDataList = [];

        encounterSubmissionDataList = encounterSubmissionDataListRaw.map(
          (encounterSubmissionData) => {
            encounterSubmissionData.data = {
              [generateEhrId()]: _.getNonEmpty(
                encounterSubmissionData,
                'data',
                {}
              )
            };
            encounterSubmissionData.id =
              encounterSubmissionData.questionnaireID;

            return encounterSubmissionData;
          }
        );

        draft.submissionList = encounterSubmissionDataList;
        break;
      case GET_QUESTIONNAIRE_SUBMISSION_DATA_IN_PROGRESS:
        draft.loadingDetail = true;
        draft.detail = null;
        break;
      case GET_QUESTIONNAIRE_SUBMISSION_DATA_SUCCESS:
        const submission = _.get(payload, 'response.data', null);

        draft.loadingDetail = false;
        draft.detail = submission;
        break;
      case GET_QUESTIONNAIRE_SUBMISSION_DATA_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_IN_PROGRESS:
        draft.loadingDetail = true;
        draft.detail = null;
        break;
      case UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_SUCCESS:
        const updatedSubmission = _.get(payload, 'response.data', null);

        draft.loadingDetail = false;
        draft.detail = updatedSubmission;
        break;
      case UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case GET_PATIENT_QUESTIONNAIRE_DATA_SUCCESS:
        draft.loadingDetail = false;
        draft.detail = _.get(payload, 'response.data', null);
        break;
      case GET_PATIENT_QUESTIONNAIRE_DATA_IN_PROGRESS:
        draft.loadingDetail = true;
        draft.detail = null;
        break;
      case GET_PATIENT_QUESTIONNAIRE_DATA_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case CLEAR_QUESTIONNAIRE_SUBMISSION_DATA:
        draft.detail = null;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default questionnaireSubmissionReducer;
