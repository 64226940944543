import _ from 'lodash';
import { createSelector } from 'reselect';

import { jsonParse } from '../../../utils/jwt';

export const selectDocumentSubmissionStore = (store) =>
  store.documentSubmission;

export const selectDocumentSubmissionDetail = createSelector(
  [selectDocumentSubmissionStore],
  (documentSubmission) => _.get(documentSubmission, 'detail', {})
);

export const selectDocumentSubmissionJsonData = createSelector(
  [selectDocumentSubmissionDetail],
  (documentSubmissionDetail) =>
    jsonParse(_.get(documentSubmissionDetail, 'data', null))
);
