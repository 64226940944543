import { createActionTypeArray } from '../../common/actionTypes';

export const GET_ALL_ENCOUNTER_PATIENTS =
  'encounter/GET_ALL_ENCOUNTER_PATIENTS';
export const GET_ALL_WAIT_LIST_DATA = 'encounter/GET_ALL_WAIT_LIST_DATA';

export const GET_ENCOUNTERS = 'encounter/GET_ENCOUNTERS';
export const GET_PAST_ENCOUNTERS = 'encounter/GET_PAST_ENCOUNTERS';
export const GET_ENCOUNTER = 'encounter/GET_ENCOUNTER';
export const GET_WAITING_LIST_ENCOUNTER =
  'encounter/GET_WAITING_LIST_ENCOUNTER';
export const CREATE_ENCOUNTER = 'encounter/CREATE_ENCOUNTER';
export const UPDATE_ENCOUNTER = 'encounter/UPDATE_ENCOUNTER';
export const REMOVE_ENCOUNTER = 'encounter/REMOVE_ENCOUNTER';
export const ARCHIVE_CLOSED_ENCOUNTERS = 'encounter/ARCHIVE_CLOSED_ENCOUNTERS';
export const ARCHIVE_ENCOUNTER = 'encounter/ARCHIVE_ENCOUNTER';

export const GET_ENCOUNTER_COMMENTS = 'encounter/GET_ENCOUNTER_COMMENTS';
export const CREATE_ENCOUNTER_COMMENT = 'encounter/CREATE_ENCOUNTER_COMMENT';
export const UPDATE_ENCOUNTER_COMMENT = 'encounter/UPDATE_ENCOUNTER_COMMENT';
export const DELETE_ENCOUNTER_COMMENT = 'encounter/DELETE_ENCOUNTER_COMMENT';

export const GET_ASSIST_REQUEST = 'encounter/GET_ASSIST_REQUEST';

export const MOVE_WAITING_LIST_ITEM = 'encounter/MOVE_WAITING_LIST_ITEM';
export const REMOVE_WAITING_LIST_ENCOUNTER =
  'encounter/REMOVE_WAITING_LIST_ENCOUNTER';

export const DISMISS_ASSIST_REQUEST = 'encounter/DISMISS_ASSIST_REQUEST';

export const UPDATE_WAITING_LIST_FILTER =
  'encounter/UPDATE_WAITING_LIST_FILTER';
export const CLEAR_WAITING_LIST_FILTER = 'encounter/CLEAR_WAITING_LIST_FILTER';

export const [
  GET_ALL_WAIT_LIST_DATA_IN_PROGRESS,
  GET_ALL_WAIT_LIST_DATA_SUCCESS,
  GET_ALL_WAIT_LIST_DATA_FAILURE
] = createActionTypeArray(GET_ALL_WAIT_LIST_DATA);

export const [
  GET_ENCOUNTERS_IN_PROGRESS,
  GET_ENCOUNTERS_SUCCESS,
  GET_ENCOUNTERS_FAILURE
] = createActionTypeArray(GET_ENCOUNTERS);

export const [
  GET_PAST_ENCOUNTERS_IN_PROGRESS,
  GET_PAST_ENCOUNTERS_SUCCESS,
  GET_PAST_ENCOUNTERS_FAILURE
] = createActionTypeArray(GET_PAST_ENCOUNTERS);

export const [
  GET_ENCOUNTER_IN_PROGRESS,
  GET_ENCOUNTER_SUCCESS,
  GET_ENCOUNTER_FAILURE
] = createActionTypeArray(GET_ENCOUNTER);

export const [
  CREATE_ENCOUNTER_IN_PROGRESS,
  CREATE_ENCOUNTER_SUCCESS,
  CREATE_ENCOUNTER_FAILURE
] = createActionTypeArray(CREATE_ENCOUNTER);

export const [
  UPDATE_ENCOUNTER_IN_PROGRESS,
  UPDATE_ENCOUNTER_SUCCESS,
  UPDATE_ENCOUNTER_FAILURE
] = createActionTypeArray(UPDATE_ENCOUNTER);

export const [
  REMOVE_ENCOUNTER_IN_PROGRESS,
  REMOVE_ENCOUNTER_SUCCESS,
  REMOVE_ENCOUNTER_FAILURE
] = createActionTypeArray(REMOVE_ENCOUNTER);

export const [
  MOVE_WAITING_LIST_ITEM_IN_PROGRESS,
  MOVE_WAITING_LIST_ITEM_SUCCESS,
  MOVE_WAITING_LIST_ITEM_FAILURE
] = createActionTypeArray(MOVE_WAITING_LIST_ITEM);

export const [
  REMOVE_WAITING_LIST_ENCOUNTER_IN_PROGRESS,
  REMOVE_WAITING_LIST_ENCOUNTER_SUCCESS,
  REMOVE_WAITING_LIST_ENCOUNTER_FAILURE
] = createActionTypeArray(REMOVE_WAITING_LIST_ENCOUNTER);

export const [
  DISMISS_ASSIST_REQUEST_IN_PROGRESS,
  DISMISS_ASSIST_REQUEST_SUCCESS,
  DISMISS_ASSIST_REQUEST_FAILURE
] = createActionTypeArray(DISMISS_ASSIST_REQUEST);

export const [
  GET_ENCOUNTER_COMMENTS_IN_PROGRESS,
  GET_ENCOUNTER_COMMENTS_SUCCESS,
  GET_ENCOUNTER_COMMENTS_FAILURE
] = createActionTypeArray(GET_ENCOUNTER_COMMENTS);

export const [
  CREATE_ENCOUNTER_COMMENT_IN_PROGRESS,
  CREATE_ENCOUNTER_COMMENT_SUCCESS,
  CREATE_ENCOUNTER_COMMENT_FAILURE
] = createActionTypeArray(CREATE_ENCOUNTER_COMMENT);

export const [
  UPDATE_ENCOUNTER_COMMENT_IN_PROGRESS,
  UPDATE_ENCOUNTER_COMMENT_SUCCESS,
  UPDATE_ENCOUNTER_COMMENT_FAILURE
] = createActionTypeArray(UPDATE_ENCOUNTER_COMMENT);

export const [
  DELETE_ENCOUNTER_COMMENT_IN_PROGRESS,
  DELETE_ENCOUNTER_COMMENT_SUCCESS,
  DELETE_ENCOUNTER_COMMENT_FAILURE
] = createActionTypeArray(DELETE_ENCOUNTER_COMMENT);

export const [
  ARCHIVE_CLOSED_ENCOUNTERS_IN_PROGRESS,
  ARCHIVE_CLOSED_ENCOUNTERS_SUCCESS,
  ARCHIVE_CLOSED_ENCOUNTERS_FAILURE
] = createActionTypeArray(ARCHIVE_CLOSED_ENCOUNTERS);

export const [
  ARCHIVE_ENCOUNTER_IN_PROGRESS,
  ARCHIVE_ENCOUNTER_SUCCESS,
  ARCHIVE_ENCOUNTER_FAILURE
] = createActionTypeArray(ARCHIVE_ENCOUNTER);

export const [
  GET_ASSIST_REQUEST_IN_PROGRESS,
  GET_ASSIST_REQUEST_SUCCESS,
  GET_ASSIST_REQUEST_FAILURE
] = createActionTypeArray(GET_ASSIST_REQUEST);
