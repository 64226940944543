import { combineReducers } from 'redux';

import allDocumentTypesReducer from './allDocumentTypes/allDocumentTypesReducer';

import outboundDocumentReducer from '../document/outbound/outboundDocumentReducer';
import outboundDocumentTemplateReducer from '../document/outbound/template/outboundDocumentTemplateReducer';
import allDocumentTemplatesReducer from './allDocumentTemplates/allDocumentTemplatesReducer';
import formExternalReducer from './external/formExternalReducer';
import formRequestReducer from './request/formRequestReducer';
import formSubmissionReducer from './submission/formSubmissionReducer';
import formTemplateReducer from './template/formTemplateReducer';

export default combineReducers({
  allDocumentTypes: allDocumentTypesReducer,
  allDocumentTemplates: allDocumentTemplatesReducer,
  external: formExternalReducer,
  submission: formSubmissionReducer,
  request: formRequestReducer,
  template: formTemplateReducer,
  outbound: outboundDocumentReducer,
  outboundTemplates: outboundDocumentTemplateReducer
});
