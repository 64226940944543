import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import { translateKey } from '../../i18n';

import { isUserDoctor } from '../filters/user-filters';
import { guid } from '../gen';
import { getFullName } from './common-mappers';

export const mapUser = (user) => {
  const userRights = [];

  const locations = _.get(storeGetState(), 'cache.location.list', []);

  if (_.isEmpty(user)) {
    return user;
  }

  if (_.isEmpty(user.email)) {
    user.email = user.pendingEmail;
  }

  if (_.isEmpty(user.phoneNumber)) {
    user.phoneNumber = '';
  }

  user.getFullName = (flipped) => getFullName(user, flipped);

  // TODO: Remove once uploading of files is implemented
  user.image = '';

  _.forEach(user.locationsRoles, (locationRoles) => {
    const location = _.find(locations, { id: locationRoles.locationID });

    if (!_.isPlainObject(location)) {
      return;
    }

    _.forEach(locationRoles.roles, (roleKey) => {
      const userRight = {
        id: guid(),
        role: {
          id: roleKey,
          nameKeyCode: `common:roles.${roleKey}`
        },
        location: {
          id: location.id,
          name: location.name
        }
      };

      userRights.push(userRight);
    });
  });

  user.userRights = _.sortBy(userRights, 'role.id');

  return user;
};

export const filterUserByLocationAndRole = (locationId, role) => (user) => {
  const doctorLocationRoles = _.find(user.locationsRoles, {
    locationID: locationId
  });

  if (_.isEmpty(doctorLocationRoles)) {
    return false;
  }

  return _.includes(doctorLocationRoles.roles, role);
};

export const locationToLocationRolesMapper = (location) => ({
  locationID: location.id
});

export const userRightToLocationRolesMapper = (user) => {
  user.locationsRoles = [];

  _.forEach(user.userRights, (userRight) => {
    const locationId = _.get(userRight, 'location.id', '?');
    const roleId = _.get(userRight, 'role.id', '?');

    const foundLocationRole = _.find(user.locationsRoles, {
      locationID: locationId
    });

    if (_.isEmpty(foundLocationRole)) {
      user.locationsRoles.push({
        locationID: locationId,
        roles: [roleId]
      });
    } else {
      foundLocationRole.roles.push(roleId);
    }
  });

  return user;
};

export const getUpdatedRights = (user, locationsRoles) => {
  const newLocationsRoles = userRightToLocationRolesMapper(user).locationsRoles;

  const updatedRoles = [];

  _.forEach(locationsRoles, (locationRoles) => {
    const newLocationRole = _.find(newLocationsRoles, {
      locationID: locationRoles.locationID
    });

    const oldRoles = _.orderBy(_.get(locationRoles, 'roles', []));
    const newRoles = _.orderBy(_.get(newLocationRole, 'roles', []));

    if (_.isEqual(newRoles, oldRoles)) {
      return;
    }

    updatedRoles.push({
      locationID: locationRoles.locationID,
      roles: newRoles
    });
  });

  return updatedRoles;
};

export const getUserRolesByLocation = (user, locationId) => {
  const allUserRoles = _.get(user, 'locationsRoles', []);
  const locationUserRights = _.findDefault(
    allUserRoles,
    { locationID: locationId },
    {}
  );

  return _.get(locationUserRights, 'roles', []);
};

export const getGenders = (extraItem) => {
  const genders = [];

  if (!_.isEmpty(extraItem)) {
    genders.push(extraItem);
  }

  genders.push({
    id: 'male',
    name: translateKey('common:person.genderTypes.male')
  });
  genders.push({
    id: 'female',
    name: translateKey('common:person.genderTypes.female')
  });

  return genders;
};

export const userSuggestionRemapper = (user) => ({
  id: user.id,
  name: getFullName(user)
});

export const getUserById = (userId) => {
  const allUsers = _.get(storeGetState(), 'cache.user.list', []);

  return _.find(allUsers, { id: userId });
};

export const isDoctorValid = (doctorId) => {
  const user = getUserById(doctorId);

  return isUserDoctor(user);
};
