import { createActionTypeArray } from '../../common/actionTypes';

export const GET_FORM_REQUEST_DETAIL = 'documents/GET_FORM_REQUEST_DETAIL';
export const ADD_FORM_REQUEST = 'documents/ADD_FORM_REQUEST';
export const UPDATE_FORM_REQUEST = 'documents/UPDATE_FORM_REQUEST';
export const REMOVE_FORM_REQUEST = 'documents/REMOVE_FORM_REQUEST';
export const CLEAR_FORM_REQUEST_DETAIL = 'documents/CLEAR_FORM_REQUEST_DETAIL';

export const [
  GET_FORM_REQUEST_DETAIL_IN_PROGRESS,
  GET_FORM_REQUEST_DETAIL_SUCCESS,
  GET_FORM_REQUEST_DETAIL_FAILURE
] = createActionTypeArray(GET_FORM_REQUEST_DETAIL);

export const [
  ADD_FORM_REQUEST_IN_PROGRESS,
  ADD_FORM_REQUEST_SUCCESS,
  ADD_FORM_REQUEST_FAILURE
] = createActionTypeArray(ADD_FORM_REQUEST);

export const [
  UPDATE_FORM_REQUEST_IN_PROGRESS,
  UPDATE_FORM_REQUEST_SUCCESS,
  UPDATE_FORM_REQUEST_FAILURE
] = createActionTypeArray(UPDATE_FORM_REQUEST);

export const [
  REMOVE_FORM_REQUEST_IN_PROGRESS,
  REMOVE_FORM_REQUEST_SUCCESS,
  REMOVE_FORM_REQUEST_FAILURE
] = createActionTypeArray(REMOVE_FORM_REQUEST);
