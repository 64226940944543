/* eslint-disable max-len,max-lines-per-function */
import React from 'react';

import PropTypes from 'prop-types';

const LeftHandOutline = ({ outlineColor, disabledColor, disabled }) => {
  const color = disabled ? disabledColor : outlineColor;

  return (
    <g id={'zoomed-left-hand-outline'}>
      <path
        d={
          'M305.988 1C310.046 34.7332 315.85 36.8897 301.237 53.5253C266.746 92.8294 273.166 76.2708 269.443 105.537C268.595 112.186 263.459 120.247 262.688 130.259C261.918 140.272 256.037 153.595 254.444 158.268C252.518 163.89 256.858 170.308 267.414 164.018C277.969 157.729 283.696 149.231 284.569 141.144C285.263 134.701 286.007 124.509 292.068 115.37'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M292.043 115.369C299.259 124.868 300.235 126.46 304.19 136.369C307.94 145.74 309.198 152.132 306.322 165.045C303.471 177.958 302.418 190.178 302.084 195.723C301.751 201.269 299.978 211.46 299.978 216.158C299.978 220.856 298.874 240.419 307.195 241.42C316.235 242.524 317.982 233.667 319.6 223.398C321.217 213.129 320.755 205.145 321.551 200.524C322.347 195.903 324.787 189.896 325.198 185.891C325.609 181.886 328.023 161.785 334.392 163.402C337.423 164.172 336.087 170.95 335.368 174.749C334.649 178.549 332.209 189.793 331.208 197.058C330.232 204.324 331.208 209.869 330.694 214.849C330.18 219.83 329.384 223.68 329.282 229.636C329.179 235.592 326.945 256.464 338.142 256.464C349.622 256.464 347.516 240.906 348.081 237.62C348.954 232.512 348.826 223.475 349.468 218.597C350.187 213.078 353.294 198.907 353.423 193.67C353.551 188.432 350.983 165.405 355.991 165.43C359.92 165.456 357.429 180.628 358.071 189.357C358.302 192.412 359.586 201.602 359.612 207.404C359.638 213.386 359.946 219.984 360.177 225.683C360.408 231.382 358.687 246.503 368.267 246.708C378.411 246.939 378.54 233.718 378.822 225.58C379.079 217.442 377.872 207.276 378.129 201.474C378.411 195.672 379.105 189.511 378.488 184.428C377.872 179.345 373.891 155.7 377.127 154.982C379.747 154.391 382.109 172.259 383.265 178.523C383.959 182.297 386.938 193.516 387.734 202.912C388.479 211.794 392.973 212.77 396.235 212.077C399.496 211.409 402.861 207.738 402.604 195.595C402.347 183.555 400.755 176.341 399.522 171.129C398.315 165.918 397.185 158.884 396.697 154.006C395.901 146.099 395.233 134.264 395.156 128.334C395.079 122.404 394.95 122.429 395.233 115.498C395.515 108.566 396.722 94.2156 392.485 81.5336C388.247 68.8515 377.101 45.5412 376.177 42.4605C375.227 39.3798 378.257 16.9167 376.228 1.74445'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M375.895 160.809C375.535 155.675 376.948 148.795 378.283 148.487'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M353.243 170.59C353.5 165.995 355.169 158.909 357.121 158.935'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M337.089 159.346C337.603 162.504 336.55 167.895 336.062 170.59'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M328.023 170.924C329.025 166.688 330.54 161.092 332.595 158.396'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M317.185 184.992L312.486 184.505'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M313.667 210.074L309.455 209.458'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M303.343 134.29C303.343 134.29 300.004 123.687 294.56 112.032'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M267.645 150.669C267.645 150.669 265.205 148.384 262.868 148.333'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M313.872 52.9604C313.872 52.9604 306.065 64.3589 295.381 72.0862'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M273.654 127.744C273.654 127.744 270.213 125.382 268.441 125.613'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M279.947 87.4639C279.947 87.4639 277.789 90.4419 277.532 91.6228'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M313.641 229.868C313.641 229.868 309.635 227.788 306.99 229.303'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M342.688 242.652C342.688 242.652 338.938 241.086 335.831 243.884'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M347.388 199.523L342.097 199.934'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M343.638 229.842L340.813 229.867'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M374.379 232.666C372.941 232.306 369.911 231.87 367.188 234.078'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M374.251 216.877L370.45 217.879'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M373.275 191.924C373.275 191.924 370.527 193.31 367.984 193.721'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M395.798 173.62C395.798 173.62 395.182 174.621 393.769 175.288'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M398.52 190.794C398.52 190.794 397.853 191.719 396.594 192.181'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M398.674 201.346C398.674 201.346 395.002 201.243 393.692 205.068'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M354.912 144.354C354.912 144.354 354.989 148.872 352.421 151.85'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M328.819 142.454L327.022 149.077'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M370.758 137.448C370.758 137.448 371.965 141.658 371.323 144.559'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M390.713 134.059C390.713 134.059 390.148 129.438 389.044 127.641'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
    </g>
  );
};

LeftHandOutline.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

LeftHandOutline.defaultProps = {
  disabled: false
};

export default LeftHandOutline;
