import _ from 'lodash';

import { translateKey } from '../../i18n';

import TaxModel from '../../metadata/model/TaxModel';

import { dropdownItemMapper } from './dropdown-mappers';

export const NO_TAX = 'NO_TAX';

export const getTaxesWithEmptyOption = (taxList) => {
  const emptyTax = new TaxModel(
    NO_TAX,
    translateKey('billing:settings.general.noTax'),
    '',
    null
  );

  return [emptyTax, ...taxList];
};

export const taxDropdownItemsWithNameAndValue = (taxes) =>
  taxes.map(
    dropdownItemMapper('id', (tax) => {
      if (_.isEqual(tax.id, NO_TAX)) {
        return tax.name;
      }
      const displayRate = _.get(tax, 'taxRates[0].rate', 0);

      return `${displayRate}% (${tax.name})`;
    })
  );

export const removeEmptyTaxFromBillingItem = (billItem) => {
  billItem.taxIDs = _.filter(billItem.taxIDs, (id) => id !== NO_TAX);

  return billItem;
};

export const removeEmptyTaxFromBill = (bill) => {
  const billItems = _.get(bill, 'items', []);

  bill.items = billItems.map((billItem) =>
    removeEmptyTaxFromBillingItem(billItem)
  );

  return bill;
};

export const taxDropdownItemsWithValue = (rawTaxes) => {
  const taxes = _.nestedListAssign(rawTaxes, TaxModel);
  const taxesDisplayRateMapper = dropdownItemMapper('id', (tax) => {
    if (_.isEqual(tax.id, NO_TAX)) {
      return translateKey('billing:settings.general.noTax');
    }

    return `${tax.displayRate}`;
  });

  return taxes.map((tax) => {
    const taxDropdownItem = taxesDisplayRateMapper(tax);

    if (_.isEqual(taxDropdownItem.id, NO_TAX)) {
      taxDropdownItem.name = `${taxDropdownItem.name}`;
    } else {
      taxDropdownItem.name = `${taxDropdownItem.name}%`;
    }

    return taxDropdownItem;
  });
};
