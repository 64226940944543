import { useEffect, useRef, useState } from 'react';

/**
 * @template T
 * @description
 * A useState hook with a callback that is executed after the state is set.
 * @example
 * const [state, setState] = useStateCallback(0);
 * const handleClick = () => {
 *   setState(
 *     (prev) => prev + 1,
 *     // 2nd argument is callback , `s` is *updated* state
 *     (s) => console.log("I am called after setState, state:", s)
 *   );
 * };
 * @param {T} initialState
 * @returns [{T} state, {Function} setStateCallback]
 */
export const useStateWithCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = (updatedState, cb) => {
    cbRef.current = cb;
    setState(updatedState);
  };

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};
