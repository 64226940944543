import _ from 'lodash';

import BillingCompanyModel from './BillingCompanyModel';
import BillingFursModel from './BillingFursModel';
import BillingTextsModel from './BillingTextsModel';
import BillingVisualModel from './BillingVisualModel';
import PaymentDetailsModel from './PaymentDetailsModel';

class BillingUnitModel {
  constructor() {
    this.id = '';
    this.invoicingID = '';
    this.companyDetails = new BillingCompanyModel();
    this.paymentDetails = new PaymentDetailsModel();
    this.texts = new BillingTextsModel();
    this.visual = new BillingVisualModel();
    this.furs = new BillingFursModel();
  }

  static fromDto(dto) {
    const assignedModel = _.nestedAssign(new BillingUnitModel(), dto);

    return assignedModel;
  }

  get isCertificateValid() {
    return _.getNonEmpty(this, 'furs.certificate', false);
  }

  get isBusinessPremisesValid() {
    return _.getNonEmpty(this, 'furs.businessPremises', false);
  }

  get areElectronicDevicesValid() {
    return _.getNonEmpty(this, 'furs.electronicDevices', false);
  }

  get isNumberingStrategyValid() {
    return !_.isEmptySafe(this, 'furs.strategy');
  }

  get isFursActive() {
    return _.getNonEmpty(this, 'furs.enabled', false);
  }

  get isFursSettingEnabled() {
    return (
      this.isCertificateValid ||
      this.isBusinessPremisesValid ||
      this.areElectronicDevicesValid ||
      this.isNumberingStrategyValid ||
      this.isFursActive
    );
  }
}

export default BillingUnitModel;
