import produce from 'immer';
import _ from 'lodash';

import { mapFormDetail } from '../../../utils/mappers/document-mappers';

import {
  GET_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS
} from '../documentTypes';
import {
  CLEAR_OUTBOUND_DETAILS,
  CLEAR_OUTBOUND_PREVIEW_PDF_URL,
  GET_OUTBOUND_DOCUMENT_FAILURE,
  GET_OUTBOUND_DOCUMENT_FOOTER_FAILURE,
  GET_OUTBOUND_DOCUMENT_FOOTER_SUCCESS,
  GET_OUTBOUND_DOCUMENT_HEADER_FAILURE,
  GET_OUTBOUND_DOCUMENT_HEADER_SUCCESS,
  GET_OUTBOUND_DOCUMENT_IN_PROGRESS,
  GET_OUTBOUND_DOCUMENT_SUCCESS,
  GET_OUTBOUND_PREVIEW_FAILURE,
  GET_OUTBOUND_PREVIEW_IN_PROGRESS,
  GET_OUTBOUND_PREVIEW_SUCCESS,
  UPDATE_OUTBOUND_DOCUMENT_FAILURE,
  UPDATE_OUTBOUND_DOCUMENT_IN_PROGRESS,
  SET_OUTBOUND_PREVIEW_PDF_URL,
  UPDATE_OUTBOUND_DOCUMENT_SUCCESS,
  SET_OUTBOUND_PDF_GENERATION_STATUS
} from './outboundDocumentTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  settings: {
    header: '',
    footer: ''
  },
  detail: null,
  loadingDetail: false,
  isPdfGenerating: false,
  preview: {
    detail: null,
    loadingPreview: false,
    pdfBlob: null
  }
};

// eslint-disable-next-line default-param-last
const outboundDocumentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_OUTBOUND_DOCUMENT_IN_PROGRESS:
      case UPDATE_OUTBOUND_DOCUMENT_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_OUTBOUND_DOCUMENT_FAILURE:
      case UPDATE_OUTBOUND_DOCUMENT_FAILURE:
        draft.loadingDetail = false;
        break;
      case GET_OUTBOUND_DOCUMENT_SUCCESS:
      case UPDATE_OUTBOUND_DOCUMENT_SUCCESS:
        const documentDetail = _.getNonEmpty(payload, 'response.data', {});

        draft.loadingDetail = false;
        draft.detail = documentDetail;
        break;
      case CLEAR_OUTBOUND_DETAILS:
        draft.detail = {};
        break;
      case SET_OUTBOUND_PREVIEW_PDF_URL:
        draft.preview.pdfBlob = _.getNonEmpty(payload, 'pdfBlob', null);
        break;
      case CLEAR_OUTBOUND_PREVIEW_PDF_URL:
        draft.preview.pdfBlob = null;
        break;
      case GET_OUTBOUND_PREVIEW_SUCCESS:
        draft.loadingDetail = false;
        break;
      case GET_OUTBOUND_PREVIEW_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case GET_OUTBOUND_PREVIEW_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case UPDATE_DOCUMENT_SUCCESS:
      case GET_DOCUMENT_SUCCESS:
        draft.loadingDetail = false;
        draft.detail = mapFormDetail(
          _.getNonEmpty(payload, 'response.data', {})
        );
        break;
      case GET_OUTBOUND_DOCUMENT_HEADER_SUCCESS:
        const header = _.getNonEmpty(payload, 'response.data', '');

        draft.settings.header = header;
        break;
      case GET_OUTBOUND_DOCUMENT_HEADER_FAILURE:
        draft.settings.header = '';
        break;
      case GET_OUTBOUND_DOCUMENT_FOOTER_SUCCESS:
        const footer = _.getNonEmpty(payload, 'response.data', '');

        draft.settings.footer = footer;
        break;
      case GET_OUTBOUND_DOCUMENT_FOOTER_FAILURE:
        draft.settings.footer = '';
        break;
      case SET_OUTBOUND_PDF_GENERATION_STATUS:
        draft.isPdfGenerating = _.getNonEmpty(payload, 'isGenerating', false);
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default outboundDocumentReducer;
