import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import { translateKey } from '../../i18n';
import {
  showAlertError,
  showAlertSuccess,
  showMissingBillingUnitIdError
} from '../../utils/alert';
import { getOrganizationUrl } from '../../utils/api';
import {
  getBillingExportUrl,
  getBillingItemsExportUrl,
  getBillingItemsUrl,
  getBillingTaxByIdUrl,
  getFursNumberingStrategyUrl
} from '../../utils/api/apiUrlUtils';
import {
  MOSHI_FILE_TYPE_PDF,
  MOSHI_FILE_TYPE_XLSX
} from '../../utils/constants/fileConstants';
import { getCountryList } from '../../utils/data/country';
import { getFileName } from '../../utils/file';
import { EMPTY_GUID } from '../../utils/gen';
import { billItemLocalizedExists } from '../../utils/mappers/billing-mappers';

import { EMPTY_ACTION_TYPE } from '../common/actionTypes';
import {
  downloadFileByUrl,
  openFileByUrl,
  printFileByUrl,
  uploadFile
} from '../core/file/fileActions';
import { setUserPreferredBillingInput } from '../user/userActions';
import { getAdvanceList } from './advance/advanceActions';
import { getBillList } from './bill/billActions';
import { getCreditNoteList } from './credit-note/creditNoteActions';
import { getEstimateList } from './estimate/estimateActions';
import { getInvoiceList } from './invoice/invoiceActions';

import {
  WEB_SOCKET_ENTITY_TYPE_ADVANCE,
  WEB_SOCKET_ENTITY_TYPE_CREDIT_NOTE,
  WEB_SOCKET_ENTITY_TYPE_ESTIMATE,
  WEB_SOCKET_ENTITY_TYPE_INVOICE
} from '../core/web-socket/webSocketTypes';
import {
  ADD_BILLING_ITEM,
  ADD_BILLING_UNIT,
  ADD_BUSINESS_PREMISE,
  ADD_ELECTRONIC_DEVICE,
  ADD_TAX,
  CLEAR_BILLING_UNIT,
  CLEAR_BILLING_UNIT_INVOICE_NUMBERS,
  ENABLE_FURS,
  GET_BILLING_ITEM_DETAIL,
  GET_BILLING_ITEM_LIST,
  GET_BILLING_UNIT,
  GET_BILLING_UNIT_INVOICE_NUMBERS,
  GET_BILLING_UNITS,
  GET_BUSINESS_PREMISE_LIST,
  GET_TAX_DETAIL,
  GET_TAX_LIST,
  REMOVE_BILLING_ITEM,
  REMOVE_BILLING_UNIT,
  REMOVE_TAX,
  SET_BILLING_ITEM_LIST_FILTER,
  SET_BILLING_ITEM_LIST_ORDER,
  SET_BILLING_ITEM_LIST_PAGINATION,
  SET_INVOICE_KEYWORD_DATA,
  UPDATE_BILLING_ITEM,
  UPDATE_BILLING_ITEMS_BATCH,
  UPDATE_BILLING_UNIT,
  UPDATE_TAX,
  SET_BILLING_EXPORT_COLUMNS,
  ADD_FURS_NUMBERING_STRATEGY,
  SET_GROUP_BILLING_ITEMS
} from './billingTypes';

import { selectDefaultBillingLocale } from '../organization/organizationSelectors';
import {
  selectBillingItemList,
  selectBillingItemsParams
} from './billingSelectors';

import TaxModel from '../../metadata/model/TaxModel';
import BillingItemLocalizedModel from '../../metadata/model/billing/BillingItemLocalizedModel';
import BillBaseModel from '../../metadata/model/billing/bill/BillBaseModel';

export const getBillKeywordData = (type) => (dispatch, getState) => {
  const reducerName = _.camelCase(type);
  const billingUnits = _.get(getState(), 'cache.billingUnit.list', []);
  const bill = _.get(
    getState(),
    `${reducerName}.detail`,
    new BillBaseModel(null, [])
  );
  const billingUnit = _.findDefault(
    billingUnits,
    {
      id: bill.billingUnitID
    },
    {}
  );
  const countryId = _.get(
    billingUnit,
    'companyDetails.address.country',
    EMPTY_GUID
  );
  const country = _.findDefault(getCountryList(), { id: countryId });
  const countryName = _.getNonEmpty(country, 'name', '/');

  const keyWordData = {
    '[organization name]': _.getNonEmpty(
      billingUnit,
      'companyDetails.name',
      '/'
    ),
    '[document number]': _.getNonEmpty(bill, 'number', '/'),
    '[IBAN]': _.getNonEmpty(billingUnit, 'companyDetails.iban', '/'),
    '[SWIFT]': _.getNonEmpty(billingUnit, 'companyDetails.swift', '/'),
    '[bank]': _.getNonEmpty(billingUnit, 'companyDetails.bank', '/'),
    '[address]': _.getNonEmpty(
      billingUnit,
      'companyDetails.address.street1',
      '/'
    ),
    '[address2]': _.getNonEmpty(
      billingUnit,
      'companyDetails.address.street2',
      '/'
    ),
    '[zip]': _.getNonEmpty(billingUnit, 'companyDetails.address.zip', '/'),
    '[city]': _.getNonEmpty(billingUnit, 'companyDetails.address.town', '/'),
    '[country]': countryName,
    '[tax number]': _.getNonEmpty(billingUnit, 'companyDetails.taxNumber', '/'),
    '[company number]': _.getNonEmpty(
      billingUnit,
      'companyDetails.companyID',
      '/'
    ),
    '[document type]': translateKey(`billing:types.${type}`)
  };

  dispatch({
    type: SET_INVOICE_KEYWORD_DATA,
    keyWordData
  });
};

export const getBillingUnitList = () => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  const params = {
    params: {
      deactivated: 'include'
    }
  };

  return dispatch({
    type: GET_BILLING_UNITS,
    apiCall: axios.get(`${organizationUrl}/billing/units`, params)
  });
};

export const getBillingUnit = (billingUnitId) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: GET_BILLING_UNIT,
    apiCall: axios.get(`${organizationUrl}/billing/units/${billingUnitId}`)
  });
};

export const clearBillingUnitDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_BILLING_UNIT
  });

export const updateBillingUnit = (billingUnit) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: UPDATE_BILLING_UNIT,
    apiCall: axios.put(
      `${organizationUrl}/billing/units/${billingUnit.id}`,
      billingUnit
    ),
    onSuccess: () => {
      dispatch(getBillingUnit(billingUnit.id));
    }
  });
};

export const addBillingUnit = (billingUnit) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: ADD_BILLING_UNIT,
    apiCall: axios.post(`${organizationUrl}/billing/units`, billingUnit)
  });
};

export const addOrUpdateBillingUnit = (billingUnit) => (dispatch) => {
  let promiseObject;

  if (_.isEmpty(billingUnit.id)) {
    promiseObject = dispatch(addBillingUnit(billingUnit));
  } else {
    promiseObject = dispatch(updateBillingUnit(billingUnit));
  }

  return promiseObject;
};

export const removeBillingUnit = (billingUnitId) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: REMOVE_BILLING_UNIT,
    apiCall: axios.delete(`${organizationUrl}/billing/units/${billingUnitId}`),
    onSuccess: () => {
      dispatch(getBillingUnitList());
    }
  });
};

export const getTaxList = () => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: GET_TAX_LIST,
    apiCall: axios.get(`${organizationUrl}/billing/taxes`)
  });
};

export const getTax = (taxId, actionType = GET_TAX_DETAIL) => (
  dispatch,
  getState
) => {
  const url = getBillingTaxByIdUrl(getState, taxId);

  return dispatch({
    type: actionType,
    apiCall: axios.get(url)
  });
};

export const addTax = (tax) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  const payload = TaxModel.getPayload(tax);

  return dispatch({
    type: ADD_TAX,
    apiCall: axios.post(`${organizationUrl}/billing/taxes`, payload),
    onSuccess: () => {
      dispatch(getTaxList());
    }
  });
};

export const updateTax = (tax) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: UPDATE_TAX,
    apiCall: axios.put(`${organizationUrl}/billing/taxes/${tax.id}`, tax),
    onSuccess: () => {
      dispatch(getTaxList());
    }
  });
};

export const removeTax = (taxId) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: REMOVE_TAX,
    apiCall: axios.delete(`${organizationUrl}/billing/taxes/${taxId}`),
    onSuccess: () => {
      dispatch(getTaxList());
    }
  });
};

export const addOrUpdateTax = (tax) => (dispatch) => {
  let promiseObject;

  if (_.isEmpty(tax.id)) {
    promiseObject = dispatch(addTax(tax));
  } else {
    promiseObject = dispatch(updateTax(tax));
  }

  return promiseObject;
};

export const getBillingItemList = (
  paramsSelector = selectBillingItemsParams,
  actionType = GET_BILLING_ITEM_LIST
) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: actionType,
    apiCall: axios.get(`${organizationUrl}/billing/items`, {
      params: paramsSelector(getState())
    })
  });
};

export const setBillingItemListFilter = (filterName, filterValue) => (
  dispatch
) => {
  dispatch({
    type: SET_BILLING_ITEM_LIST_FILTER,
    filterName,
    filterValue
  });

  dispatch(getBillingItemList());
};

export const setBillingItemListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_BILLING_ITEM_LIST_ORDER,
    sortBy,
    order
  });

  dispatch(getBillingItemList());
};

export const getBillingItem = (itemId) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: GET_BILLING_ITEM_DETAIL,
    apiCall: axios.get(`${organizationUrl}/billing/items/${itemId}`)
  });
};

export const addBillingItem = (billingItem) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  const payload = BillingItemLocalizedModel.getPayload(billingItem);

  return dispatch({
    type: ADD_BILLING_ITEM,
    apiCall: axios.post(`${organizationUrl}/billing/items`, payload),
    onSuccess: () => {
      showAlertSuccess('billing:items.alerts.billingItemCreated');
      dispatch(getBillingItemList());
    }
  });
};

export const updateBillingItem = (billingItem) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: UPDATE_BILLING_ITEM,
    apiCall: axios.put(
      `${organizationUrl}/billing/items/${billingItem.id}`,
      billingItem
    ),
    onSuccess: () => {
      showAlertSuccess('billing:items.alerts.billingItemUpdated');
      dispatch(getBillingItemList());
    }
  });
};

export const updateBillingItemsBatch = (billingItems) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: UPDATE_BILLING_ITEMS_BATCH,
    apiCall: axios.put(`${organizationUrl}/billing/items/`, billingItems),
    onSuccess: () => {
      showAlertSuccess('billing:items.alerts.billingItemsBatchUpdated');
      dispatch(getBillingItemList());
    }
  });
};

export const addOrUpdateBillingItem = (billingItem) => (dispatch, getState) => {
  let promiseObject;
  const store = getState();
  const billingItems = selectBillingItemList(store);
  const defaultLocale = selectDefaultBillingLocale(store);

  if (billItemLocalizedExists(billingItem, billingItems, defaultLocale)) {
    showAlertError('billing:items.alerts.billingItemExists');

    return Promise.resolve();
  }

  if (_.isEmpty(billingItem.id)) {
    promiseObject = dispatch(addBillingItem(billingItem));
  } else {
    promiseObject = dispatch(updateBillingItem(billingItem));
  }

  return promiseObject;
};

export const removeBillingItem = (billingItemId) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return dispatch({
    type: REMOVE_BILLING_ITEM,
    apiCall: axios.delete(`${organizationUrl}/billing/items/${billingItemId}`),
    onSuccess: () => {
      showAlertSuccess('billing:items.alerts.billingItemRemoved');
      dispatch(getBillingItemList());
    }
  });
};

export const removeMultipleBillingItems = (billingItemIds) => (
  dispatch,
  getState
) => {
  const billingItemsUrl = getBillingItemsUrl(getState);

  return dispatch({
    type: REMOVE_BILLING_ITEM,
    apiCall: axios.delete(billingItemsUrl, {
      data: {
        itemsIDs: billingItemIds
      }
    }),
    onSuccess: () => {
      showAlertSuccess('billing:items.alerts.billingItemsRemoved');
      dispatch(getBillingItemList());
    }
  });
};

export const setBillingItemListPagination = (
  paginationName,
  paginationValue
) => (dispatch) => {
  dispatch({
    type: SET_BILLING_ITEM_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

  dispatch(getBillingItemList());
};

export const uploadBillingUnitCertFile = (billingUnitId, file, password) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return dispatch(showMissingBillingUnitIdError());
  }

  return dispatch(
    uploadFile(
      `${organizationUrl}/billing/units/${billingUnitId}/furs/certificate`,
      file,
      { passphrase: password },
      {
        fieldName: 'cert',
        onSuccess: () => {
          dispatch(getBillingUnit(billingUnitId));
        }
      }
    )
  );
};

export const getBusinessPremiseList = (
  billingUnitId,
  query = { includeDevices: true }
) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return dispatch(showMissingBillingUnitIdError());
  }

  return dispatch({
    type: GET_BUSINESS_PREMISE_LIST,
    apiCall: axios.get(
      `${organizationUrl}/billing/units/${billingUnitId}/furs/premises`,
      { params: query }
    ),
    onSuccess: () => {
      dispatch(getBillingUnit(billingUnitId));
    }
  });
};

export const addBusinessPremise = (billingUnitId, businessPremise) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return showMissingBillingUnitIdError();
  }

  return dispatch({
    type: ADD_BUSINESS_PREMISE,
    apiCall: axios.post(
      `${organizationUrl}/billing/units/${billingUnitId}/furs/premises`,
      businessPremise
    ),
    onSuccess: () => {
      dispatch(getBusinessPremiseList(billingUnitId));
    }
  });
};

export const addBusinessElectronicDevice = (billingUnitId, formData) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return showMissingBillingUnitIdError();
  }

  const { premiseId, label } = formData;

  return dispatch({
    type: ADD_ELECTRONIC_DEVICE,
    apiCall: axios.post(
      `${organizationUrl}/billing/units/${billingUnitId}/furs/premises/${premiseId}/devices`,
      { label }
    ),
    onSuccess: () => {
      dispatch(getBusinessPremiseList(billingUnitId));
    }
  });
};

export const addFursNumberingStrategy = (billingUnitId, strategy) => (
  dispatch,
  getState
) => {
  const strategyUrl = getFursNumberingStrategyUrl(getState, billingUnitId);

  return dispatch({
    type: ADD_FURS_NUMBERING_STRATEGY,
    apiCall: axios.post(strategyUrl, { strategy }),
    onSuccess: () => {
      dispatch(getBillingUnit(billingUnitId));
    }
  });
};

export const enableFurs = (billingUnitId) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return showMissingBillingUnitIdError();
  }

  return dispatch({
    type: ENABLE_FURS,
    apiCall: axios.post(
      `${organizationUrl}/billing/units/${billingUnitId}/furs/enable`
    ),
    onSuccess: () => {
      dispatch(getBillingUnit(billingUnitId));
    }
  });
};

export const clearBillNumbers = () => (dispatch) =>
  dispatch({
    type: CLEAR_BILLING_UNIT_INVOICE_NUMBERS
  });

export const getBillNumbers = (
  billingUnitId,
  type,
  businessPremiseID = undefined,
  electronicDeviceID = undefined
) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return dispatch(showMissingBillingUnitIdError());
  }

  let params = {};

  if (!_.isEmptySafe(businessPremiseID) && !_.isEmptySafe(electronicDeviceID)) {
    params = {
      businessPremiseID,
      electronicDeviceID
    };
  }

  return dispatch({
    type: GET_BILLING_UNIT_INVOICE_NUMBERS,
    apiCall: axios.get(
      `${organizationUrl}/billing/units/${billingUnitId}/numbers/${type}`,
      { params }
    )
  });
};

export const getBillNumberIsUnique = (number, billingUnitId, type) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitId) {
    return dispatch(showMissingBillingUnitIdError());
  }

  return dispatch({
    type: EMPTY_ACTION_TYPE,
    apiCall: axios.get(
      `${organizationUrl}/billing/units/${billingUnitId}/numbers/${type}/isUnique`,
      {
        params: {
          number
        }
      }
    )
  });
};

export const downloadBillPdf = (
  billType,
  billId,
  fileName,
  locale = undefined
) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  const queryParams = _.isEmpty(locale) ? undefined : { locale };

  dispatch(
    downloadFileByUrl(
      EMPTY_ACTION_TYPE,
      `${organizationUrl}/billing/${_.camelCase(billType)}s/${billId}/pdf`,
      fileName,
      queryParams
    )
  );
};

export const openBillPdf = (billType, billId, locale = undefined) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  const queryParams = _.isEmpty(locale) ? undefined : { locale };

  dispatch(
    openFileByUrl(
      EMPTY_ACTION_TYPE,
      `${organizationUrl}/billing/${_.camelCase(billType)}s/${billId}/pdf`,
      MOSHI_FILE_TYPE_PDF,
      queryParams
    )
  );
};

export const printBillPdf = (billType, billId, locale = undefined) => (
  dispatch,
  getState
) => {
  const organizationUrl = getOrganizationUrl(getState);

  const queryParams = _.isEmpty(locale) ? undefined : { locale };

  dispatch(
    printFileByUrl(
      EMPTY_ACTION_TYPE,
      `${organizationUrl}/billing/${_.camelCase(billType)}s/${billId}/pdf`,
      MOSHI_FILE_TYPE_PDF,
      queryParams
    )
  );
};

export const updateUserPreferredBillDataInput = (billDocument) => (
  dispatch
) => {
  const billingUnitID = _.get(billDocument, 'billingUnitID');
  const businessPremiseID = _.get(billDocument, 'furs.businessPremiseID');
  const electronicDeviceID = _.get(billDocument, 'furs.electronicDeviceID');

  dispatch(
    setUserPreferredBillingData(
      billingUnitID,
      businessPremiseID,
      electronicDeviceID
    )
  );
};

export const setUserPreferredBillingData = (
  billingUnitID,
  businessPremiseID,
  electronicDeviceID
) => (dispatch) => {
  dispatch(setUserPreferredBillingInput('billingUnitID', billingUnitID));
  dispatch(
    setUserPreferredBillingInput('businessPremiseID', businessPremiseID)
  );
  dispatch(
    setUserPreferredBillingInput('electronicDeviceID', electronicDeviceID)
  );
};

export const getBillDocumentList = (
  billingUnitID,
  actionType,
  billFilters,
  callApiProps = {}
) => (dispatch, getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (!billingUnitID) {
    return showMissingBillingUnitIdError();
  }

  return dispatch({
    ...callApiProps,
    type: actionType,
    apiCall: axios.get(
      `${organizationUrl}/billing/units/${billingUnitID}/documents`,
      {
        params: billFilters
      }
    )
  });
};

export const exportBillDocuments = ({
  includeBillingItems = false,
  ...formData
}) => (dispatch, getState) => {
  const apiUrl = getBillingExportUrl(getState);
  const from = _.getNonEmpty(formData, 'from');
  const to = _.getNonEmpty(formData, 'to');
  const documentTypes = _.getNonEmpty(formData, 'documentTypes', []);
  const billingDevices = _.getNonEmpty(formData, 'deviceIDs', []);
  const billingUnitIDs = _.getNonEmpty(formData, 'billingUnitID', '');
  const includeColumns = _.getNonEmpty(formData, 'includeColumns', []);

  const payload = {
    documentTypes: documentTypes.join(','),
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD'),
    fursElectronicDeviceIDs: _.isEmptySafe(billingDevices)
      ? undefined
      : billingDevices.join(','),
    billingUnitIDs,
    exportType: includeBillingItems ? 'items' : 'documents',
    includeColumns: _.isEmptySafe(includeColumns)
      ? undefined
      : includeColumns.join(',')
  };

  const url = queryString.stringifyUrl({
    url: apiUrl,
    query: payload
  });

  const fileName = getFileName(
    {
      name: 'billing:exportBillDocuments.billingExportWithDate',
      options: {
        from,
        to
      }
    },
    MOSHI_FILE_TYPE_XLSX
  );

  if (!_.isEmptySafe(includeColumns)) {
    dispatch(setBillingExportColumns(includeColumns));
  }

  return dispatch(downloadFileByUrl(EMPTY_ACTION_TYPE, url, fileName));
};

export const exportBillingItemList = () => (dispatch, getState) => {
  const billingItemsUrl = getBillingItemsExportUrl(getState);

  const fileName = getFileName(
    'billing:export.exportBillingItems',
    MOSHI_FILE_TYPE_XLSX
  );

  dispatch(downloadFileByUrl(EMPTY_ACTION_TYPE, billingItemsUrl, fileName));
};

export const setBillingExportColumns = (columns) => (dispatch) =>
  dispatch({
    type: SET_BILLING_EXPORT_COLUMNS,
    columns
  });

export const setGroupBillingItems = (value) => (dispatch) =>
  dispatch({
    type: SET_GROUP_BILLING_ITEMS,
    value
  });

export const handleBillingDocumentWebSocketMessage = (
  entity,
  action,
  payload
) => (dispatch) => {
  const billDocumentId = _.getNonEmpty(payload, 'entityID', null);

  if (_.isEmptySafe(billDocumentId)) {
    return;
  }

  switch (entity) {
    case WEB_SOCKET_ENTITY_TYPE_INVOICE:
      dispatch(getInvoiceList());
      break;
    case WEB_SOCKET_ENTITY_TYPE_ESTIMATE:
      dispatch(getEstimateList());
      break;
    case WEB_SOCKET_ENTITY_TYPE_ADVANCE:
      dispatch(getAdvanceList());
      break;
    case WEB_SOCKET_ENTITY_TYPE_CREDIT_NOTE:
      dispatch(getCreditNoteList());
      break;
    default:
      break;
  }

  dispatch(getBillList());
};
