import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  FILTER_ALL_ID,
  FILTER_ALL_ITEM
} from '../../../utils/constants/dropdownConstants';
import {
  PATIENT_WIDGET_GROUP_FILTER,
  PATIENT_WIDGET_SEARCH_FILTER
} from '../../../utils/constants/patientConstants';
import { ORDER_DESCENDING } from '../../../utils/constants/tableConstants';
import { getSafeTranslation } from '../../../utils/language';
import { removeWidgetSubItemsMapper } from '../../../utils/mappers/cleaned/widgetMappers';
import { selectProps } from '../../../utils/redux/selectorHelpers';
import { getSubEntityDropdownMapper } from '../../../utils/storeExtensions';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../../utils/table';
import {
  getAllGroupsByCurrentWidgetList,
  getWidgetFormSchema,
  prepareWidgetListForRendering,
  getWidgetTypeWithoutVersion,
  isQuestionnaireWidget,
  isReadOnlyWidget
} from '../../../utils/widgets';
import { WIDGET_TYPE_QUESTIONNAIRE } from '../../../widgets/widgetConstants';

import {
  WIDGET_FIELD_DATA_TYPE_DOCTORS,
  WIDGET_FIELD_DATA_TYPE_FLOWS,
  WIDGET_FIELD_DATA_TYPE_LOCATIONS,
  WIDGET_FIELD_DATA_TYPE_PATIENT_THERAPIES
} from './widgetTypes';

import {
  selectCachedDoctorList,
  selectCacheStore
} from '../../core/cache/cacheSelectors';
import { selectOrganizationWidgetConfig } from '../../organization/organizationSelectors';
import { selectQuestionnaireWidgetList } from '../../questionnaire/questionnaireSelectors';
import { selectPatientTherapyList } from '../../therapy/therapySelectors';

export const selectWidgetStore = (state) => state.widget;

export const selectWidgetDataByType = createSelector(
  [
    selectCacheStore,
    selectProps,
    selectCachedDoctorList,
    selectPatientTherapyList
  ],
  (
    cacheStore,
    { widgetDataType, entityType },
    doctorList,
    patientTherapyList
  ) => {
    let cacheSelector;
    let items = [];

    switch (widgetDataType) {
      case WIDGET_FIELD_DATA_TYPE_DOCTORS:
        items = doctorList;
        break;
      case WIDGET_FIELD_DATA_TYPE_PATIENT_THERAPIES:
        items = patientTherapyList;
        break;
      case WIDGET_FIELD_DATA_TYPE_FLOWS:
        cacheSelector = 'flow';
        break;
      case WIDGET_FIELD_DATA_TYPE_LOCATIONS:
        cacheSelector = 'location';
        break;
      default:
        cacheSelector = 'none';
    }

    items = _.getNonEmpty(cacheStore, `${cacheSelector}.list`, items);

    const dropdownMapper = getSubEntityDropdownMapper(entityType);

    items = items.map(dropdownMapper);

    return items;
  }
);

export const selectWidgetHistoryByType = createSelector(
  [selectWidgetStore, selectProps],
  (widgetStore, { widgetType, ignoreVersion }) => {
    if (!ignoreVersion) {
      return _.getNonEmpty(widgetStore, `widgetHistory.${widgetType}`, []);
    }

    const widgetsInHistory = _.getNonEmpty(widgetStore, 'widgetHistory', {});
    const widgetTypeWithoutVersion = getWidgetTypeWithoutVersion(widgetType);

    return _.find(
      widgetsInHistory,
      (value, key) =>
        getWidgetTypeWithoutVersion(key) === widgetTypeWithoutVersion
    );
  }
);

export const selectWidgetByTypeAt = createSelector(
  [selectWidgetHistoryByType, selectProps],
  (widgetHistory, { date }) => {
    const dateBefore = moment(date).add(2, 'days');

    const widgetsBeforeDate = _.filter(widgetHistory, (widget) =>
      moment(widget.createdAt).isSameOrBefore(dateBefore, 'day')
    );

    const orderedWidgets = _.orderBy(
      widgetsBeforeDate,
      ENTITY_FIELD_CREATED_AT_NAME,
      ORDER_DESCENDING
    );

    return _.first(orderedWidgets);
  }
);

export const selectCurrentEncounter = createSelector(
  [selectWidgetStore],
  (store) => _.getNonEmpty(store, 'currentEncounterId')
);

export const selectLoadingWidgetList = createSelector(
  [selectWidgetStore],
  (widgetStore) => _.getNonEmpty(widgetStore, 'loadingWidgetList', true)
);

export const selectWidgetList = createSelector(
  [selectWidgetStore],
  (widgetStore) => _.getNonEmpty(widgetStore, `widgetList`, {})
);

export const selectWidgetListByEncounter = createSelector(
  [selectWidgetList, selectProps],
  (widgetList, encounterId) => _.getNonEmpty(widgetList, encounterId, [])
);

export const selectWidgetListByCurrentEncounter = createSelector(
  [selectWidgetList, selectCurrentEncounter],
  (widgetList, currentEncounterId) =>
    _.getNonEmpty(widgetList, currentEncounterId, [])
);

export const selectWidgetByCurrentEncounter = createSelector(
  [selectWidgetListByCurrentEncounter, selectProps],
  (widgetList, widgetType) => _.find(widgetList, { widgetType }, {})
);

export const selectCurrentEncounterWidgetDataValue = createSelector(
  [selectWidgetListByCurrentEncounter, selectProps],
  (widgetList, { widgetType, dataPath, defaultValue, ignoreVersion }) => {
    let widget;

    if (ignoreVersion) {
      const widgetTypeWithoutVersion = getWidgetTypeWithoutVersion(widgetType);

      widget = _.find(
        widgetList,
        (w) =>
          getWidgetTypeWithoutVersion(w.widgetType) ===
          widgetTypeWithoutVersion,
        {}
      );
    } else {
      widget = _.find(widgetList, { widgetType }, {});
    }

    return _.get(widget, `data.${dataPath}`, defaultValue);
  }
);

export const selectHasPendingLocalChanges = createSelector(
  [selectWidgetStore],
  (widgetStore) => _.getNonEmpty(widgetStore, 'hasPendingLocalChanges', false)
);

export const selectLocalWidgetIdList = createSelector(
  [selectWidgetStore],
  (widgetStore) => _.getNonEmpty(widgetStore, 'localWidgetIdList', [])
);

export const selectLocalWidgetList = createSelector(
  [selectLocalWidgetIdList, selectLoadingWidgetList],
  (localWidgetIdList, loadingWidgets) => {
    const localWidgetList = [];

    if (!loadingWidgets) {
      _.forEach(localWidgetIdList, (localWidgetId) => {
        localWidgetList.push({ id: localWidgetId, widgetType: localWidgetId });
      });
    }

    return localWidgetList;
  }
);

export const selectLocalWidgetData = createSelector(
  [selectWidgetStore],
  (store) => _.getNonEmpty(store, 'localWidgetData', {})
);

export const selectAllPatientWidgetList = createSelector(
  [selectWidgetStore],
  (widgetStore) => _.getNonEmpty(widgetStore, 'allPatientWidgetList', [])
);

export const selectWidgetsInEditMode = createSelector(
  [
    selectWidgetStore,
    selectWidgetListByCurrentEncounter,
    selectLocalWidgetData,
    selectOrganizationWidgetConfig
  ],
  (store, currentEncounterWidgets, localWidgets, organizationWidgetList) => {
    const widgetIds = _.getNonEmpty(store, 'localWidgetsInEditMode', []);
    const defaultWidgets = _.getNonEmpty(
      organizationWidgetList,
      'defaultWidgetsIds'
    );

    return _.reduce(
      widgetIds,
      (list, id) => {
        const encounterWidget = _.find(currentEncounterWidgets, { id });
        const localWidget = _.getNonEmpty(localWidgets, id);
        const isDefaultWidget = _.includes(defaultWidgets, id);

        if (encounterWidget) {
          list.push(encounterWidget);
        } else if (localWidget) {
          list.push(localWidget);
        } else if (isDefaultWidget) {
          list.push({
            id,
            widgetType: id
          });
        }

        return list;
      },
      []
    );
  }
);

export const selectAllPatientWidgetGroupDropdownList = createSelector(
  [selectAllPatientWidgetList],
  (allPatientWidgetList) => {
    const widgetGroupList = getAllGroupsByCurrentWidgetList(
      allPatientWidgetList
    );

    const widgetGroupListWithoutSubItems = _.map(
      widgetGroupList,
      removeWidgetSubItemsMapper
    );

    return [FILTER_ALL_ITEM, ...widgetGroupListWithoutSubItems];
  }
);

export const selectWidgetIdsToSubmit = createSelector(
  [selectWidgetStore],
  (store) => _.getNonEmpty(store, 'widgetIdsToSubmit', [])
);

export const selectIsMoreThanOneWidgetInEditMode = createSelector(
  [selectWidgetsInEditMode],
  (localWidgetsInEditMode) => {
    const widgetsInEditMode = _.filter(
      localWidgetsInEditMode,
      ({ widgetType }) =>
        !isQuestionnaireWidget(widgetType) && !isReadOnlyWidget(widgetType)
    );

    return widgetsInEditMode.length > 1;
  }
);

export const selectFocusedWidgetId = createSelector(
  [selectWidgetStore],
  (store) => _.getNonEmpty(store, 'focusedWidgetId', null)
);

// FILTER SELECTORS
export const selectAllPatientWidgetListFilter = createSelector(
  [selectWidgetStore],
  (widgetStore) => _.getNonEmpty(widgetStore, 'allPatientWidgetListFilter', {})
);

export const selectAllPatientWidgetListSearchQuery = createSelector(
  [selectAllPatientWidgetListFilter],
  (allPatientWidgetListFilter) =>
    _.get(allPatientWidgetListFilter, PATIENT_WIDGET_SEARCH_FILTER, '')
);

export const selectAllPatientWidgetListGroupFilter = createSelector(
  [selectAllPatientWidgetListFilter],
  (allPatientWidgetListFilter) =>
    _.get(
      allPatientWidgetListFilter,
      PATIENT_WIDGET_GROUP_FILTER,
      FILTER_ALL_ID
    )
);

// COMBINED SELECTORS
export const selectFilteredAllPatientWidgetList = createSelector(
  [
    selectAllPatientWidgetList,
    selectAllPatientWidgetListSearchQuery,
    selectAllPatientWidgetListGroupFilter
  ],
  (allPatientWidgets, filterSearchValue, filterGroupId) => {
    const widgetGroups = prepareWidgetListForRendering(allPatientWidgets);

    const safeFilterSearchValue = _.toLower(filterSearchValue);
    let filteredWidgetGroups = widgetGroups;

    if (!_.isEmpty(filterGroupId) && filterGroupId !== FILTER_ALL_ID) {
      filteredWidgetGroups = _.filter(widgetGroups, { id: filterGroupId });
    }

    const filteredWidgetGroupList = _.filter(
      filteredWidgetGroups,
      (widgetGroup) => {
        widgetGroup.widgets = _.filter(widgetGroup.widgets, (widget) => {
          const schema = getWidgetFormSchema(widget.widgetType);
          const widgetTitle = _.getNonEmpty(schema, 'title');
          const safeTranslation = _.toLower(getSafeTranslation(widgetTitle));
          const widgetNameIncludesSearchValue = _.includes(
            safeTranslation,
            safeFilterSearchValue
          );
          const dataIncludesSearchValue = _.includes(
            _.toLower(widget.rawData),
            safeFilterSearchValue
          );

          return widgetNameIncludesSearchValue || dataIncludesSearchValue;
        });

        return !_.isEmpty(widgetGroup.widgets);
      }
    );

    return filteredWidgetGroupList;
  }
);

export const selectWidgetSchema = createSelector(
  [selectOrganizationWidgetConfig, selectQuestionnaireWidgetList],
  (orgWidgets, questionnaireWidgetList) => {
    const questionnaireWidgetObject = {};

    _.forEach(questionnaireWidgetList, (questionnaireWidget) => {
      _.set(
        questionnaireWidgetObject,
        questionnaireWidget.id,
        questionnaireWidget
      );
    });

    // merge questionnaire widgets with default widgets
    orgWidgets.widgets = _.cloneDeep({
      ...orgWidgets.widgets,
      ...questionnaireWidgetObject
    });

    const questionnaireWidgetGroup = {
      id: WIDGET_TYPE_QUESTIONNAIRE,
      name: 'widget:questionnaire.title',
      widgets: questionnaireWidgetList.map((questionnaireWidget, index) => ({
        id: questionnaireWidget.id,
        order: index
      }))
    };

    if (!_.isArray(orgWidgets.groups)) {
      orgWidgets.groups = [];
    }

    orgWidgets.groups.push(questionnaireWidgetGroup);

    return orgWidgets;
  }
);
