import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CardMoreActions from './CardMoreActions';

import TableColumnShape, {
  TableDataShape,
  CardMoreActionsShape
} from '../../../metadata/shapes/TableShape';

import { Card, Col, Row } from '../../reactstrap';
import ConditionalWrap from '../layout/ConditionalWrap';
import Visible from '../layout/Visible';
import TableCellWrapper from './TableCellWrapper';

export const TableItemComponent = ({
  item,
  columns,
  onMainCellClick,
  onRowClick,
  rowActions,
  tableItemClass,
  disableClickableCell
}) => {
  _.forEach(columns, (column) => {
    if (!_.isFunction(column.cellComponent)) {
      column.cellComponent = (cellItem) =>
        _.get(cellItem, column.name, '/').toString();
    }
  });

  return (
    <Card
      key={item.id}
      className={classNames('full-width', 'table-row', tableItemClass)}
    >
      <Row onClick={onRowClick} className={'full-height'}>
        {columns.map((column, index) => {
          let onClick;

          if (_.isFunction(onMainCellClick) && index === 0) {
            onClick = () => {
              onMainCellClick(item);
            };
          }

          return (
            <Col
              onClick={onClick}
              key={column.displayKeyCode || index}
              xs={column.colWidth}
              className={classNames('column-cell', {
                'clickable-text':
                  index === 0 &&
                  _.isFunction(onMainCellClick) &&
                  !disableClickableCell
              })}
            >
              <ConditionalWrap
                condition={
                  !_.isBoolean(column.noCellWrapper) || !column.noCellWrapper
                }
                wrap={(children) => (
                  <TableCellWrapper>{children}</TableCellWrapper>
                )}
              >
                {column.cellComponent(item, column.name)}
              </ConditionalWrap>
            </Col>
          );
        })}
        <Visible visible={_.isArray(rowActions) && rowActions.length > 0}>
          <CardMoreActions actions={rowActions} item={item} />
        </Visible>
      </Row>
    </Card>
  );
};

TableItemComponent.propTypes = {
  item: PropTypes.shape(TableDataShape).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape(TableColumnShape)).isRequired,
  rowActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape))
    .isRequired,
  onMainCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  tableItemClass: PropTypes.string,
  disableClickableCell: PropTypes.bool
};

TableItemComponent.defaultProps = {
  onMainCellClick: undefined,
  onRowClick: _.noop,
  tableItemClass: undefined,
  disableClickableCell: false
};

export default TableItemComponent;
