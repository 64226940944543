/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button } from 'reactstrap';

import PropTypes from 'prop-types';

const ButtonWrapper = ({
  children,
  disabled,
  active,
  color,
  onClick,
  size,
  id,
  className,
  style,
  type,
  tabIndex
}) => (
  <Button
    disabled={disabled}
    active={active}
    color={color}
    onClick={onClick}
    size={size}
    id={id}
    className={className}
    style={style}
    type={type}
    tabIndex={tabIndex}
  >
    {children}
  </Button>
);

ButtonWrapper.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
  tabIndex: PropTypes.number
};

ButtonWrapper.defaultProps = {
  children: undefined,
  disabled: undefined,
  active: undefined,
  color: undefined,
  onClick: undefined,
  size: undefined,
  id: undefined,
  className: undefined,
  style: undefined,
  type: undefined,
  tabIndex: undefined
};

export default ButtonWrapper;
