import { take, race, call, select } from 'redux-saga/effects';

import { getInvoiceListUrl } from '../../../utils/api/apiUrlUtils';
import { getPaymentTypeFilterParametersPaylad } from '../../../utils/billing';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import {
  START_INVOICE_POLLING,
  STOP_INVOICE_POLLING,
  STOP_POLLING
} from '../../core/cache/cacheTypes';
import {
  CLEAR_INVOICE_LIST_FILTER,
  GET_INVOICE_LIST,
  SET_INVOICE_LIST_FILTER,
  SET_INVOICE_LIST_FILTERS,
  SET_INVOICE_LIST_INCLUDE_PARAM,
  SET_INVOICE_LIST_ORDER,
  SET_INVOICE_LIST_PAGINATION
} from './invoiceTypes';

import {
  selectInvoiceFilter,
  selectInvoicePagination,
  selectInvoiceIncludeParam
} from './invoiceSelectors';

function* pollInvoicesSaga() {
  function* configGetter() {
    const state = yield select();
    const { limit, page } = selectInvoicePagination(state);
    const include = selectInvoiceIncludeParam(state);
    let customFilter = selectInvoiceFilter(state);

    customFilter = getPaymentTypeFilterParametersPaylad(customFilter);

    return {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    };
  }

  yield pollGenerator(GET_INVOICE_LIST, getInvoiceListUrl, {
    retriggerActions: [
      SET_INVOICE_LIST_FILTER,
      SET_INVOICE_LIST_FILTERS,
      SET_INVOICE_LIST_INCLUDE_PARAM,
      SET_INVOICE_LIST_ORDER,
      SET_INVOICE_LIST_PAGINATION,
      CLEAR_INVOICE_LIST_FILTER
    ],
    configGetter
  });
}

export function* invoiceSagaWatcher() {
  while (true) {
    yield take(START_INVOICE_POLLING);
    yield race([
      call(pollInvoicesSaga),
      take(STOP_POLLING),
      take(STOP_INVOICE_POLLING)
    ]);
  }
}
