/* eslint-disable max-len,max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from '../../../../layout/Visible';

export const HUMANOID_BODY_ID = 'body';
const GROUP = `${HUMANOID_BODY_ID}-interactive`;

const EAR_RIGHT = `${HUMANOID_BODY_ID}-ear-right`;
const EAR_LEFT = `${HUMANOID_BODY_ID}-ear-left`;

const CHEST_RIGHT_TOP = `${HUMANOID_BODY_ID}-chest-right-top`;
const CHEST_RIGHT_LOW = `${HUMANOID_BODY_ID}-chest-right-low`;
const CHEST_LEFT_TOP = `${HUMANOID_BODY_ID}-chest-left-top`;
const CHEST_LEFT_LOW = `${HUMANOID_BODY_ID}-chest-left-low`;

const SHOULDER_RIGHT = `${HUMANOID_BODY_ID}-shoulder-right`;
const SHOULDER_LEFT = `${HUMANOID_BODY_ID}-shoulder-left`;

const ELBOW_RIGHT = `${HUMANOID_BODY_ID}-elbow-right`;
const ELBOW_LEFT = `${HUMANOID_BODY_ID}-elbow-left`;

const KNEE_RIGHT = `${HUMANOID_BODY_ID}-knee-right`;
const KNEE_LEFT = `${HUMANOID_BODY_ID}-knee-left`;

const HIP_RIGHT = `${HUMANOID_BODY_ID}-hip-right`;
const HIP_LEFT = `${HUMANOID_BODY_ID}-hip-left`;

const HEEL_RIGHT = `${HUMANOID_BODY_ID}-heel-right`;
const HEEL_LEFT = `${HUMANOID_BODY_ID}-heel-left`;

const BodyInteractive = ({
  getColor,
  onChange,
  initialSelectedParts,
  chestDisabled,
  shouldersDisabled,
  earsDisabled,
  hipsDisabled,
  heelsDisabled
}) => {
  const [checkedList, setCheckedList] = useState(initialSelectedParts);

  const isChecked = useCallback((partId) => _.includes(checkedList, partId), [
    checkedList
  ]);

  useEffect(() => {
    onChange(HUMANOID_BODY_ID, checkedList);
  }, [checkedList, onChange]);

  const handleClick = useCallback(
    (partId) => {
      const result = _.pushOrRemoveIntoArray(checkedList, partId);

      setCheckedList(result);
    },
    [checkedList]
  );

  return (
    <g id={GROUP}>
      <Visible visible={!shouldersDisabled}>
        <g
          id={SHOULDER_RIGHT}
          onClick={() => handleClick(SHOULDER_RIGHT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M136 289C136 283.477 140.477 279 146 279C151.523 279 156 283.477 156 289C156 294.523 151.523 299 146 299C140.477 299 136 294.523 136 289Z'
            }
            fill={'white'}
          />
          {isChecked(SHOULDER_RIGHT) && (
            <circle
              cx={'146'}
              cy={'289'}
              r={'5'}
              fill={getColor(SHOULDER_RIGHT)}
            />
          )}
          <path
            d={
              'M146 297C141.582 297 138 293.418 138 289H134C134 295.627 139.373 301 146 301V297ZM154 289C154 293.418 150.418 297 146 297V301C152.627 301 158 295.627 158 289H154ZM146 281C150.418 281 154 284.582 154 289H158C158 282.373 152.627 277 146 277V281ZM146 277C139.373 277 134 282.373 134 289H138C138 284.582 141.582 281 146 281V277Z'
            }
            fill={getColor(SHOULDER_RIGHT)}
          />
        </g>
        <g
          id={SHOULDER_LEFT}
          onClick={() => handleClick(SHOULDER_LEFT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M260 286C260 280.477 264.477 276 270 276C275.523 276 280 280.477 280 286C280 291.523 275.523 296 270 296C264.477 296 260 291.523 260 286Z'
            }
            fill={'white'}
          />
          {isChecked(SHOULDER_LEFT) && (
            <circle
              cx={'270'}
              cy={'286'}
              r={'5'}
              fill={getColor(SHOULDER_LEFT)}
            />
          )}
          <path
            d={
              'M270 294C265.582 294 262 290.418 262 286H258C258 292.627 263.373 298 270 298V294ZM278 286C278 290.418 274.418 294 270 294V298C276.627 298 282 292.627 282 286H278ZM270 278C274.418 278 278 281.582 278 286H282C282 279.373 276.627 274 270 274V278ZM270 274C263.373 274 258 279.373 258 286H262C262 281.582 265.582 278 270 278V274Z'
            }
            fill={getColor(SHOULDER_LEFT)}
          />
        </g>
      </Visible>

      <g
        id={ELBOW_LEFT}
        onClick={() => handleClick(ELBOW_LEFT)}
        className={'clickable-text'}
      >
        <path
          d={
            'M269 364C269 358.477 273.477 354 279 354V354C284.523 354 289 358.477 289 364V364C289 369.523 284.523 374 279 374V374C273.477 374 269 369.523 269 364V364Z'
          }
          fill={'white'}
        />
        {isChecked(ELBOW_LEFT) && (
          <circle cx={'279'} cy={'364'} r={'5'} fill={getColor(ELBOW_LEFT)} />
        )}
        <path
          d={
            'M279 372C274.582 372 271 368.418 271 364H267C267 370.627 272.373 376 279 376V372ZM287 364C287 368.418 283.418 372 279 372V376C285.627 376 291 370.627 291 364H287ZM279 356C283.418 356 287 359.582 287 364H291C291 357.373 285.627 352 279 352V356ZM279 352C272.373 352 267 357.373 267 364H271C271 359.582 274.582 356 279 356V352Z'
          }
          fill={getColor(ELBOW_LEFT)}
        />
      </g>
      <g
        id={ELBOW_RIGHT}
        onClick={() => handleClick(ELBOW_RIGHT)}
        className={'clickable-text'}
      >
        <path
          d={
            'M131 364C131 358.477 135.477 354 141 354C146.523 354 151 358.477 151 364C151 369.523 146.523 374 141 374C135.477 374 131 369.523 131 364Z'
          }
          fill={'white'}
        />
        {isChecked(ELBOW_RIGHT) && (
          <circle cx={'141'} cy={'364'} r={'5'} fill={getColor(ELBOW_RIGHT)} />
        )}
        <path
          d={
            'M141 372C136.582 372 133 368.418 133 364H129C129 370.627 134.373 376 141 376V372ZM149 364C149 368.418 145.418 372 141 372V376C147.627 376 153 370.627 153 364H149ZM141 356C145.418 356 149 359.582 149 364H153C153 357.373 147.627 352 141 352V356ZM141 352C134.373 352 129 357.373 129 364H133C133 359.582 136.582 356 141 356V352Z'
          }
          fill={getColor(ELBOW_RIGHT)}
        />
      </g>
      <g
        id={KNEE_RIGHT}
        onClick={() => handleClick(KNEE_RIGHT)}
        className={'clickable-text'}
      >
        <path
          d={
            'M163 568C163 562.477 167.477 558 173 558C178.523 558 183 562.477 183 568C183 573.523 178.523 578 173 578C167.477 578 163 573.523 163 568Z'
          }
          fill={'white'}
        />
        {isChecked(KNEE_RIGHT) && (
          <circle cx={'173'} cy={'568'} r={'5'} fill={getColor(KNEE_RIGHT)} />
        )}
        <path
          d={
            'M173 576C168.582 576 165 572.418 165 568H161C161 574.627 166.373 580 173 580V576ZM181 568C181 572.418 177.418 576 173 576V580C179.627 580 185 574.627 185 568H181ZM173 560C177.418 560 181 563.582 181 568H185C185 561.373 179.627 556 173 556V560ZM173 556C166.373 556 161 561.373 161 568H165C165 563.582 168.582 560 173 560V556Z'
          }
          fill={getColor(KNEE_RIGHT)}
        />
      </g>
      <g
        id={KNEE_LEFT}
        onClick={() => handleClick(KNEE_LEFT)}
        className={'clickable-text'}
      >
        <path
          d={
            'M235 568C235 562.477 239.477 558 245 558C250.523 558 255 562.477 255 568C255 573.523 250.523 578 245 578C239.477 578 235 573.523 235 568Z'
          }
          fill={'white'}
        />
        {isChecked(KNEE_LEFT) && (
          <circle cx={'245'} cy={'568'} r={'5'} fill={getColor(KNEE_LEFT)} />
        )}
        <path
          d={
            'M245 576C240.582 576 237 572.418 237 568H233C233 574.627 238.373 580 245 580V576ZM253 568C253 572.418 249.418 576 245 576V580C251.627 580 257 574.627 257 568H253ZM245 560C249.418 560 253 563.582 253 568H257C257 561.373 251.627 556 245 556V560ZM245 556C238.373 556 233 561.373 233 568H237C237 563.582 240.582 560 245 560V556Z'
          }
          fill={getColor(KNEE_LEFT)}
        />
      </g>

      <Visible visible={!heelsDisabled}>
        <g
          id={HEEL_RIGHT}
          onClick={() => handleClick(HEEL_RIGHT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M168 682C168 676.477 172.477 672 178 672V672C183.523 672 188 676.477 188 682V682C188 687.523 183.523 692 178 692V692C172.477 692 168 687.523 168 682V682Z'
            }
            fill={'white'}
          />
          {isChecked(HEEL_RIGHT) && (
            <circle cx={'178'} cy={'682'} r={'5'} fill={getColor(HEEL_RIGHT)} />
          )}
          <path
            d={
              'M178 690C173.582 690 170 686.418 170 682H166C166 688.627 171.373 694 178 694V690ZM186 682C186 686.418 182.418 690 178 690V694C184.627 694 190 688.627 190 682H186ZM178 674C182.418 674 186 677.582 186 682H190C190 675.373 184.627 670 178 670V674ZM178 670C171.373 670 166 675.373 166 682H170C170 677.582 173.582 674 178 674V670Z'
            }
            fill={getColor(HEEL_RIGHT)}
          />
        </g>
        <g
          id={HEEL_LEFT}
          onClick={() => handleClick(HEEL_LEFT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M230 682C230 676.477 234.477 672 240 672V672C245.523 672 250 676.477 250 682V682C250 687.523 245.523 692 240 692V692C234.477 692 230 687.523 230 682V682Z'
            }
            fill={'white'}
          />
          {isChecked(HEEL_LEFT) && (
            <circle cx={'240'} cy={'682'} r={'5'} fill={getColor(HEEL_LEFT)} />
          )}
          <path
            d={
              'M240 690C235.582 690 232 686.418 232 682H228C228 688.627 233.373 694 240 694V690ZM248 682C248 686.418 244.418 690 240 690V694C246.627 694 252 688.627 252 682H248ZM240 674C244.418 674 248 677.582 248 682H252C252 675.373 246.627 670 240 670V674ZM240 670C233.373 670 228 675.373 228 682H232C232 677.582 235.582 674 240 674V670Z'
            }
            fill={getColor(HEEL_LEFT)}
          />
        </g>
      </Visible>

      <Visible visible={!hipsDisabled}>
        <g
          id={HIP_LEFT}
          onClick={() => handleClick(HIP_LEFT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M238 419C238 413.477 242.477 409 248 409V409C253.523 409 258 413.477 258 419V419C258 424.523 253.523 429 248 429V429C242.477 429 238 424.523 238 419V419Z'
            }
            fill={'white'}
          />
          {isChecked(HIP_LEFT) && (
            <circle cx={'248'} cy={'419'} r={'5'} fill={getColor(HIP_LEFT)} />
          )}
          <path
            d={
              'M248 427C243.582 427 240 423.418 240 419H236C236 425.627 241.373 431 248 431V427ZM256 419C256 423.418 252.418 427 248 427V431C254.627 431 260 425.627 260 419H256ZM248 411C252.418 411 256 414.582 256 419H260C260 412.373 254.627 407 248 407V411ZM248 407C241.373 407 236 412.373 236 419H240C240 414.582 243.582 411 248 411V407Z'
            }
            fill={getColor(HIP_LEFT)}
          />
        </g>
        <g
          id={HIP_RIGHT}
          onClick={() => handleClick(HIP_RIGHT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M161 419C161 413.477 165.477 409 171 409V409C176.523 409 181 413.477 181 419V419C181 424.523 176.523 429 171 429V429C165.477 429 161 424.523 161 419V419Z'
            }
            fill={'white'}
          />
          {isChecked(HIP_RIGHT) && (
            <circle cx={'171'} cy={'419'} r={'5'} fill={getColor(HIP_RIGHT)} />
          )}
          <path
            d={
              'M171 427C166.582 427 163 423.418 163 419H159C159 425.627 164.373 431 171 431V427ZM179 419C179 423.418 175.418 427 171 427V431C177.627 431 183 425.627 183 419H179ZM171 411C175.418 411 179 414.582 179 419H183C183 412.373 177.627 407 171 407V411ZM171 407C164.373 407 159 412.373 159 419H163C163 414.582 166.582 411 171 411V407Z'
            }
            fill={getColor(HIP_RIGHT)}
          />
        </g>
      </Visible>

      <Visible visible={!chestDisabled}>
        <g
          id={CHEST_RIGHT_TOP}
          onClick={() => handleClick(CHEST_RIGHT_TOP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M158 258C158 252.477 162.477 248 168 248V248C173.523 248 178 252.477 178 258V258C178 263.523 173.523 268 168 268V268C162.477 268 158 263.523 158 258V258Z'
            }
            fill={'white'}
          />
          {isChecked(CHEST_RIGHT_TOP) && (
            <circle
              cx={'168'}
              cy={'258'}
              r={'5'}
              fill={getColor(CHEST_RIGHT_TOP)}
            />
          )}
          <path
            d={
              'M168 266C163.582 266 160 262.418 160 258H156C156 264.627 161.373 270 168 270V266ZM176 258C176 262.418 172.418 266 168 266V270C174.627 270 180 264.627 180 258H176ZM168 250C172.418 250 176 253.582 176 258H180C180 251.373 174.627 246 168 246V250ZM168 246C161.373 246 156 251.373 156 258H160C160 253.582 163.582 250 168 250V246Z'
            }
            fill={getColor(CHEST_RIGHT_TOP)}
          />
        </g>
        <g
          id={CHEST_RIGHT_LOW}
          onClick={() => handleClick(CHEST_RIGHT_LOW)}
          className={'clickable-text'}
        >
          <path
            d={
              'M183 266C183 260.477 187.477 256 193 256V256C198.523 256 203 260.477 203 266V266C203 271.523 198.523 276 193 276V276C187.477 276 183 271.523 183 266V266Z'
            }
            fill={'white'}
          />
          {isChecked(CHEST_RIGHT_LOW) && (
            <circle
              cx={'193'}
              cy={'266'}
              r={'5'}
              fill={getColor(CHEST_RIGHT_LOW)}
            />
          )}
          <path
            d={
              'M193 274C188.582 274 185 270.418 185 266H181C181 272.627 186.373 278 193 278V274ZM201 266C201 270.418 197.418 274 193 274V278C199.627 278 205 272.627 205 266H201ZM193 258C197.418 258 201 261.582 201 266H205C205 259.373 199.627 254 193 254V258ZM193 254C186.373 254 181 259.373 181 266H185C185 261.582 188.582 258 193 258V254Z'
            }
            fill={getColor(CHEST_RIGHT_LOW)}
          />
        </g>
        <g
          id={CHEST_LEFT_LOW}
          onClick={() => handleClick(CHEST_LEFT_LOW)}
          className={'clickable-text'}
        >
          <path
            d={
              'M214 266C214 260.477 218.477 256 224 256V256C229.523 256 234 260.477 234 266V266C234 271.523 229.523 276 224 276V276C218.477 276 214 271.523 214 266V266Z'
            }
            fill={'white'}
          />
          {isChecked(CHEST_LEFT_LOW) && (
            <circle
              cx={'224'}
              cy={'266'}
              r={'5'}
              fill={getColor(CHEST_LEFT_LOW)}
            />
          )}
          <path
            d={
              'M224 274C219.582 274 216 270.418 216 266H212C212 272.627 217.373 278 224 278V274ZM232 266C232 270.418 228.418 274 224 274V278C230.627 278 236 272.627 236 266H232ZM224 258C228.418 258 232 261.582 232 266H236C236 259.373 230.627 254 224 254V258ZM224 254C217.373 254 212 259.373 212 266H216C216 261.582 219.582 258 224 258V254Z'
            }
            fill={getColor(CHEST_LEFT_LOW)}
          />
        </g>
        <g
          id={CHEST_LEFT_TOP}
          onClick={() => handleClick(CHEST_LEFT_TOP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M236 258C236 252.477 240.477 248 246 248V248C251.523 248 256 252.477 256 258V258C256 263.523 251.523 268 246 268V268C240.477 268 236 263.523 236 258V258Z'
            }
            fill={'white'}
          />
          {isChecked(CHEST_LEFT_TOP) && (
            <circle
              cx={'246'}
              cy={'258'}
              r={'5'}
              fill={getColor(CHEST_LEFT_TOP)}
            />
          )}
          <path
            d={
              'M246 266C241.582 266 238 262.418 238 258H234C234 264.627 239.373 270 246 270V266ZM254 258C254 262.418 250.418 266 246 266V270C252.627 270 258 264.627 258 258H254ZM246 250C250.418 250 254 253.582 254 258H258C258 251.373 252.627 246 246 246V250ZM246 246C239.373 246 234 251.373 234 258H238C238 253.582 241.582 250 246 250V246Z'
            }
            fill={getColor(CHEST_LEFT_TOP)}
          />
        </g>
      </Visible>

      <Visible visible={!earsDisabled}>
        <g
          id={EAR_RIGHT}
          onClick={() => handleClick(EAR_RIGHT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M167 208C167 202.477 171.477 198 177 198V198C182.523 198 187 202.477 187 208V208C187 213.523 182.523 218 177 218V218C171.477 218 167 213.523 167 208V208Z'
            }
            fill={'white'}
          />
          {isChecked(EAR_RIGHT) && (
            <circle cx={'177'} cy={'208'} r={'5'} fill={getColor(EAR_RIGHT)} />
          )}
          <path
            d={
              'M177 216C172.582 216 169 212.418 169 208H165C165 214.627 170.373 220 177 220V216ZM185 208C185 212.418 181.418 216 177 216V220C183.627 220 189 214.627 189 208H185ZM177 200C181.418 200 185 203.582 185 208H189C189 201.373 183.627 196 177 196V200ZM177 196C170.373 196 165 201.373 165 208H169C169 203.582 172.582 200 177 200V196Z'
            }
            fill={getColor(EAR_RIGHT)}
          />
        </g>
        <g
          id={EAR_LEFT}
          onClick={() => handleClick(EAR_LEFT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M231 209C231 203.477 235.477 199 241 199V199C246.523 199 251 203.477 251 209V209C251 214.523 246.523 219 241 219V219C235.477 219 231 214.523 231 209V209Z'
            }
            fill={'white'}
          />
          {isChecked(EAR_LEFT) && (
            <circle cx={'241'} cy={'209'} r={'5'} fill={getColor(EAR_LEFT)} />
          )}
          <path
            d={
              'M241 217C236.582 217 233 213.418 233 209H229C229 215.627 234.373 221 241 221V217ZM249 209C249 213.418 245.418 217 241 217V221C247.627 221 253 215.627 253 209H249ZM241 201C245.418 201 249 204.582 249 209H253C253 202.373 247.627 197 241 197V201ZM241 197C234.373 197 229 202.373 229 209H233C233 204.582 236.582 201 241 201V197Z'
            }
            fill={getColor(EAR_LEFT)}
          />
        </g>
      </Visible>
    </g>
  );
};

BodyInteractive.propTypes = {
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  chestDisabled: PropTypes.bool,
  shouldersDisabled: PropTypes.bool,
  earsDisabled: PropTypes.bool,
  hipsDisabled: PropTypes.bool,
  heelsDisabled: PropTypes.bool
};

BodyInteractive.defaultProps = {
  onChange: _.noop,
  initialSelectedParts: [],
  chestDisabled: false,
  shouldersDisabled: false,
  earsDisabled: false,
  hipsDisabled: false,
  heelsDisabled: false
};

export default BodyInteractive;
