import produce from 'immer';
import _ from 'lodash';

import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../../utils/constants/tableConstants';
import { jsonParse } from '../../../utils/jwt';
import { mapDentalChartResponse } from '../../../utils/mappers/cleaned/dental-chart-mappers';
import moshiConfig from '../../../utils/moshiConfig';
import { getListPaginationFromResponse } from '../../../utils/redux/listUtils';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../../utils/table';

import {
  CLEAR_DENTAL_CHART_DETAIL,
  CLEAR_DENTAL_CHART_LIST,
  CLEAR_DENTAL_CHART_TEETH_FILTER,
  FILTER_DENTAL_CHART_LIST,
  GET_DENTAL_CHART_DETAIL_FAILURE,
  GET_DENTAL_CHART_DETAIL_IN_PROGRESS,
  GET_DENTAL_CHART_DETAIL_SUCCESS,
  GET_DENTAL_CHART_LIST_FAILURE,
  GET_DENTAL_CHART_LIST_IN_PROGRESS,
  GET_DENTAL_CHART_LIST_SUCCESS,
  GET_DENTAL_CHART_SUMMARY_SUCCESS,
  RESET_DENTAL_CHART_PAGINATION,
  SET_DENTAL_CHART_LIST_PAGINATION,
  SET_DENTAL_CHART_ORDER
} from './dentalChartTypes';

import { LOGOUT } from '../../auth/authReducer';

export const initialState = {
  list: [],
  filter: {
    sortBy: ENTITY_FIELD_CREATED_AT_NAME,
    order: ORDER_DESCENDING,
    teethList: []
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  },
  loadingList: false,
  detail: {},
  summary: {
    id: null,
    list: {}
  }
};

// eslint-disable-next-line default-param-last
const dentalChartReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_DENTAL_CHART_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_DENTAL_CHART_LIST_SUCCESS:
        const responsePayload = _.getNonEmpty(payload, 'response', payload);
        const rawList = _.getNonEmpty(responsePayload, 'data', []);

        const { resultCount, pageCount } = getListPaginationFromResponse(
          responsePayload,
          draft.pagination.limit
        );

        draft.pagination.resultCount = resultCount;
        draft.pagination.pageCount = pageCount;

        draft.list = mapDentalChartResponse(rawList);
        draft.loadingList = false;
        break;
      case GET_DENTAL_CHART_LIST_FAILURE:
        draft.loadingList = false;
        draft.list = [];
        break;

      case SET_DENTAL_CHART_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case GET_DENTAL_CHART_DETAIL_IN_PROGRESS:
        draft.detailLoading = true;
        break;
      case GET_DENTAL_CHART_DETAIL_SUCCESS:
        if (draft.detailLoading) {
          const detail = _.getNonEmpty(payload, 'response.data', {});

          draft.detailLoading = false;
          draft.detail = detail;
        }

        break;
      case GET_DENTAL_CHART_DETAIL_FAILURE:
        draft.detailLoading = false;
        break;
      case CLEAR_DENTAL_CHART_DETAIL:
        draft.detailLoading = false;
        draft.detail = initialState.detail;
        break;
      case GET_DENTAL_CHART_SUMMARY_SUCCESS:
        const response = _.getNonEmpty(payload, 'response.data', []);
        const foundSummary = _.isArray(response) ? _.first(response) : response;
        const summaryData = _.getNonEmpty(foundSummary, 'data', '');

        draft.summary.id = _.getNonEmpty(foundSummary, 'id');
        draft.summary.list = jsonParse(summaryData, {});
        break;
      case FILTER_DENTAL_CHART_LIST:
        const newTeeth = _.getNonEmpty(payload, 'filterTeethNumber');
        const currentTeethFilter = _.getNonEmpty(state, 'filter.teethList', []);

        draft.filter.teethList = _.pushOrRemoveIntoArray(
          currentTeethFilter,
          newTeeth
        );
        break;

      case SET_DENTAL_CHART_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.getNonEmpty(payload, 'order', ORDER_ASCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case CLEAR_DENTAL_CHART_TEETH_FILTER:
        draft.filter.teethList = initialState.filter.teethList;
        break;
      case RESET_DENTAL_CHART_PAGINATION:
        draft.pagination = initialState.pagination;
        break;
      case CLEAR_DENTAL_CHART_LIST:
        draft.list = initialState.list;
        draft.pagination = initialState.pagination;
        draft.summary = initialState.summary;
        draft.detail = initialState.detail;

        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default dentalChartReducer;
