import React, { useMemo } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import MoshiDropdown, { DROPDOWN_FORM_LOOK } from '../dropdown/MoshiDropdown';

export const CustomDropdownWidget = ({
  value,
  disabled,
  onChange,
  options: { enumOptions, ...otherOptions },
  enableDeselection,
  id,
  schema,
  fullWidthFilterMenu
}) => {
  let items = [];

  const params = useMemo(
    () => _.getNonEmpty(otherOptions, 'componentParams', {}),
    [otherOptions]
  );

  if (!_.isEmpty(enumOptions)) {
    items = enumOptions.map((enumOption) => ({
      id: _.getNonEmpty(enumOption, 'value', enumOption.id),
      name: _.getNonEmpty(enumOption, 'label', enumOption.name)
    }));
  } else if (
    _.isObject(schema) &&
    !_.isEmpty(schema.enum) &&
    !_.isEmpty(schema.enumNames)
  ) {
    items = _.get(schema, 'enum', []).map((enumItem, i) => ({
      id: enumItem,
      name: _.get(schema, `enumNames.${i}`, '')
    }));
  } else if (_.isObject(schema) && !_.isEmpty(schema.enum)) {
    items = _.get(schema, 'enum', []).map((enumItem) => ({
      id: enumItem,
      name: enumItem
    }));
  }

  return (
    <MoshiDropdown
      {...params}
      look={DROPDOWN_FORM_LOOK}
      toggleClassName={'no-break'}
      value={value}
      disabled={disabled}
      onSelect={(item) => onChange(_.get(item, 'id', null))}
      items={items}
      enableDeselection={enableDeselection}
      id={id}
      filterEnabled={items.length > 6}
      fullWidthFilterMenu={fullWidthFilterMenu}
    />
  );
};

CustomDropdownWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape().isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  enableDeselection: PropTypes.bool,
  schema: PropTypes.shape({
    enum: PropTypes.array,
    enumNames: PropTypes.array
  }),
  fullWidthFilterMenu: PropTypes.bool
};

CustomDropdownWidget.defaultProps = {
  value: null,
  disabled: false,
  enableDeselection: undefined,
  id: undefined,
  schema: {},
  fullWidthFilterMenu: false
};

export default CustomDropdownWidget;
