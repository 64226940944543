import {
  CLEAR_FORMIK_STATE,
  SET_FORMIK_DATA,
  SET_FORMIK_VALIDITY,
  TOGGLE_TEXTAREA_FOCUSED
} from './formikReducer';

export const setFormikData = (formData) => (dispatch) => {
  dispatch({ type: SET_FORMIK_DATA, formData });
};

export const setFormikValidity = (isFormValid) => (dispatch) => {
  dispatch({ type: SET_FORMIK_VALIDITY, isFormValid });
};

export const clearFormikState = () => (dispatch) => {
  dispatch({ type: CLEAR_FORMIK_STATE });
};

export const toggleTextAreaFocused = (focused) => (dispatch) => {
  dispatch({ type: TOGGLE_TEXTAREA_FOCUSED, focused });
};
