import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import apiMiddleware from './utils/middleware/apiMiddleware';
import archiveMiddleware from './utils/middleware/archiveMiddleware';
import moshiConfig, {
  ENV_DEVELOPMENT,
  ENV_PRODUCTION,
  ENV_STABILITY,
  ENV_TEST
} from './utils/moshiConfig';
import { initializeStoreUtils } from './utils/redux/storeUtils';

import rootSaga from './rootSagas';

import rootReducer from './rootReducer';

import { userConfirmation } from './components/common/navigation/NavigationPrompt';

import rootEpic from './rootEpic';

export const history = createBrowserHistory({
  getUserConfirmation: (config, callback) => userConfirmation(config, callback)
});

const epicMiddleware = createEpicMiddleware();
const sagaMiddleware = createSagaMiddleware();

const enhancers = [];
const middleware = [
  apiMiddleware,
  archiveMiddleware,
  thunk,
  routerMiddleware(history),
  epicMiddleware,
  sagaMiddleware
];

if (!_.includes([ENV_STABILITY, ENV_PRODUCTION], moshiConfig.appEnv)) {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (_.isFunction(devToolsExtension)) {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const configureStore = () => {
  const store = createStore(rootReducer(history), {}, composedEnhancers);

  sagaMiddleware.run(rootSaga);

  if (_.includes([ENV_DEVELOPMENT, ENV_TEST], moshiConfig.appEnv)) {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer(history));
      });
    }
  }

  return store;
};

const store = configureStore();

epicMiddleware.run(rootEpic);

initializeStoreUtils(store.dispatch, store.getState);

// TODO: Remove in future as it causes bunch of recursive errors
export const storeDispatch = (action) => {
  store.dispatch(action);
};

export const storeGetState = () => store.getState();
// TODO END

export default store;
