import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

const CalculationSimpleErrorMessage = ({ error }) => {
  const { t: translate } = useTranslation();

  return (
    <div className={'text-color-red text-normal-wrap'}>
      {translate(`widget:calculationError.${_.camelCase(error)}`)}
    </div>
  );
};

CalculationSimpleErrorMessage.propTypes = {
  error: PropTypes.string.isRequired
};

export default CalculationSimpleErrorMessage;
