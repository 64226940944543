import React from 'react';
import { Trans } from 'react-i18next';

import _ from 'lodash';

import { getNumbroDisplayValue } from '../components/common/form/I18nNumericInput';

import InitialsBadge from '../components/common/InitialsBadge';
import MoshiNavLink from '../components/common/navigation/MoshiNavLink';
import MoshiTableDateCell from '../components/common/table/new/cells/MoshiTableDateCell';
import DisplayFullName from '../components/common/view/DisplayFullName';
import DisplayWithFallback from '../components/common/view/DisplayWithFallback';
import { Badge, Col, Row } from '../components/reactstrap';

import { ENTITY_STATUS_ACTIVE, ENTITY_STATUS_INACTIVE } from './alert';
import { MOSHI_COLOR_WARNING_NAME } from './color';
import { isValidDate } from './date';

export const MOSHI_COLUMN_ALIGN_LEFT = 'left';
export const MOSHI_COLUMN_ALIGN_RIGHT = 'right';
export const MOSHI_COLUMN_ALIGN_CENTER = 'center';

export const getDateCellRenderer = (translate, className = undefined) => (
  item,
  fieldName,
  cellRef
) => (
  <MoshiTableDateCell
    item={item}
    fieldName={fieldName}
    config={{ className }}
    cellRef={cellRef}
  />
);

export const getLinkCellRenderer = (getPathCallback = _.noop) => (
  item,
  fieldName
) => (
  <MoshiNavLink
    className={'clickable-text moshi-table-link'}
    to={getPathCallback(item)}
  >
    {_.get(item, fieldName, '/')}
  </MoshiNavLink>
);

export const getTimeCellRenderer = (translate, className = undefined) => (
  item,
  fieldName,
  cellRef
) => {
  const isoDateString = _.get(item, fieldName);

  if (!isValidDate(isoDateString)) {
    return '/';
  }

  return (
    <span ref={cellRef} className={className}>
      {translate('date:time', {
        date: new Date(isoDateString)
      })}
    </span>
  );
};

export const getNumericCellRenderer = (
  suffix,
  thousandSeparated = false,
  emptyText = '/',
  trimMantissa = true
) => (item, fieldName) => {
  const paid = _.get(item, fieldName, 0);
  const paidDisplayValue = getNumbroDisplayValue(
    paid,
    thousandSeparated,
    2,
    trimMantissa
  );

  return (
    <DisplayWithFallback
      displayValue={paidDisplayValue}
      suffix={suffix}
      emptyText={emptyText}
    />
  );
};

export const getFirstLastNameCellRenderer = (
  emptyText = '/',
  lastNameFirst = true
) => (item, fieldName) => (
  <DisplayFullName
    person={_.get(item, fieldName)}
    emptyText={emptyText}
    lastNameFirst={lastNameFirst}
  />
);

export const getFirstLastNameCellRendererWithBadge = (emptyText = '/') => (
  item
) => {
  const hasPendingEmail =
    !_.isEmptySafe(item, 'pendingEmail') &&
    item.status === ENTITY_STATUS_ACTIVE;

  return (
    <Row className={'first-last-name-cell'}>
      <Col xs={'auto'}>
        <InitialsBadge person={item} />
      </Col>
      <Col xs={'auto'} className={'flex-align-middle-column'}>
        <span
          className={
            'font-weight-extra-bold flex-align-middle-row clickable-text'
          }
        >
          <DisplayFullName person={item} emptyText={emptyText} />
        </span>
        {item.status === ENTITY_STATUS_INACTIVE && (
          <Row>
            <Col xs={'auto'}>
              <Badge color={MOSHI_COLOR_WARNING_NAME}>
                <Trans i18nKey={'user:validation.userNotActivated'} />
              </Badge>
            </Col>
          </Row>
        )}
        {hasPendingEmail && (
          <Row>
            <Col xs={'auto'}>
              <Badge color={MOSHI_COLOR_WARNING_NAME}>
                <Trans i18nKey={'user:validation.pendingEmailConfirmation'} />
              </Badge>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export const getEmailCellRenderer = (emptyString = '/') => (
  item,
  fieldName
) => {
  const email = _.get(item, fieldName, emptyString);

  return <a href={`mailto:${email}`}>{email}</a>;
};

// COMMON TABLE COLUMN NAMES
export const ENTITY_FIELD_CREATED_AT_NAME = 'createdAt';
export const ENTITY_FIELD_UPDATED_AT_NAME = 'updatedAt';
export const ENTITY_FIELD_CREATED_BY_NAME = 'createdBy';
export const ENTITY_FIELD_SCHEDULED_AT_NAME = 'scheduledAt';
export const ENTITY_FIELD_SCHEDULED_AT_ORDER_NAME = 'scheduledOrReceivedAt';

// COMMON TABLE COLUMNS
export const TABLE_COLUMN_CREATED_BY = {
  name: ENTITY_FIELD_CREATED_BY_NAME,
  displayKeyCode: 'common:createdBy',
  width: 2,
  cellComponentName: 'user'
};

export const TABLE_COLUMN_CREATED_AT = {
  name: ENTITY_FIELD_CREATED_AT_NAME,
  displayKeyCode: 'common:createdAt',
  cellComponentName: 'date',
  width: 1.5
};

export const TABLE_COLUMN_UPDATED_AT = {
  name: ENTITY_FIELD_UPDATED_AT_NAME,
  displayKeyCode: 'common:updatedAt',
  cellComponentName: 'date',
  width: 1.5
};

export const TABLE_COLUMN_DOCTOR = {
  name: 'doctor',
  displayKeyCode: 'common:roles.doctor',
  width: 0.5,
  cellComponentName: 'doctor'
};

export const TABLE_COLUMN_NAME = {
  name: 'name',
  displayKeyCode: 'common:name',
  cellComponentName: 'link',
  width: 3
};

export const TABLE_COLUMN_DELIVERY_STATUS = {
  name: 'status',
  displayKeyCode: 'common:status',
  cellComponentName: 'deliveryStatus',
  width: 3
};

export const TABLE_COLUMN_CALENDAR_CATEGORY = {
  name: 'categoryID',
  displayKeyCode: 'common:category',
  cellComponentName: 'calendarCategory',
  width: 3
};

export const TABLE_COLUMN_BOOKING_TEMPLATE = {
  name: 'templateID',
  displayKeyCode: 'common:template',
  cellComponentName: 'bookingTemplate',
  width: 3
};
