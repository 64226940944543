import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal } from 'reactstrap';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ModalBody, ModalHeader } from '../../reactstrap';
import Icon from '../Icon';
import Visible from '../layout/Visible';

export const BasicMoshiModalComponent = ({
  isOpen,
  toggleOpened,
  className,
  title,
  children,
  onOpened,
  hideHeader,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    toggle={() => toggleOpened(!isOpen)}
    className={classNames('moshi-basic-modal', className)}
    onOpened={onOpened}
    {...props}
  >
    <Visible visible={!hideHeader}>
      <ModalHeader>
        <span>{title}</span>
        <Icon
          className={'close-icon'}
          onClick={() => toggleOpened(false)}
          name={'close'}
          id={'close-modal-icon'}
        />
      </ModalHeader>
    </Visible>
    <ModalBody>{children}</ModalBody>
  </Modal>
);

BasicMoshiModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpened: PropTypes.func.isRequired,
  onOpened: PropTypes.func,
  hideHeader: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

BasicMoshiModalComponent.defaultProps = {
  hideHeader: false,
  className: undefined,
  title: undefined,
  children: null,
  onOpened: undefined
};

export default BasicMoshiModalComponent;
