import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import {
  MOSHI_COLOR_DANGER_NAME,
  MOSHI_COLOR_GRAY_NAME
} from '../../../utils/color';
import { MOSHI_SIZE_LG } from '../../../utils/constants/sizeConstants';

import MoshiButton from '../button/MoshiButton';
import BasicMoshiModal from './BasicMoshiModal';

export const MoshiControlledDiscardChangesPromptComponent = ({
  isOpen,
  onClose,
  onCancel
}) => {
  const { t: translate } = useTranslation();

  return (
    <BasicMoshiModal
      title={translate('modal:confirmDiscardUnsaved.title')}
      isOpen={isOpen}
      toggleOpened={onCancel}
      className={'padded-body'}
    >
      <div className={'margin-bottom-15'}>
        {translate('modal:confirmDiscardUnsaved.message')}
      </div>
      <MoshiButton
        id={'discard-unsaved-button'}
        onClick={onClose}
        size={MOSHI_SIZE_LG}
        color={MOSHI_COLOR_DANGER_NAME}
        className={'float-right'}
      >
        {translate('modal:confirmDiscardUnsaved.discardButton')}
      </MoshiButton>
      <MoshiButton
        id={'discard-unsaved-button'}
        onClick={onCancel}
        size={MOSHI_SIZE_LG}
        color={MOSHI_COLOR_GRAY_NAME}
        className={'float-right margin-right-10'}
      >
        {translate('common:back')}
      </MoshiButton>
    </BasicMoshiModal>
  );
};

MoshiControlledDiscardChangesPromptComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default MoshiControlledDiscardChangesPromptComponent;
