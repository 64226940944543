import allergies from './allergies';
import chief from './cheif';
import childDiseases from './childDiseases';
import chronicConditions from './chronicConditions';
import currentIllness from './currentIllness';
import environmentalData from './environmentalData';
import familyHistory from './familyHistory';
import pastIllness from './pastIllness';
import sexualHistory from './sexualHistory';
import socioEconomicData from './socioEconomicData';
import surgicalHistory from './surgicalHistory';

export default {
  groupId: 'healthHistory',
  groupName: 'encounter:widget.healthHistory.title',
  widgets: {
    [pastIllness.id]: pastIllness,
    [currentIllness.id]: currentIllness,
    [chronicConditions.id]: chronicConditions,
    [surgicalHistory.id]: surgicalHistory,
    [familyHistory.id]: familyHistory,
    [childDiseases.id]: childDiseases,
    [chief.id]: chief,
    [allergies.id]: allergies,
    [sexualHistory.id]: sexualHistory,
    [socioEconomicData.id]: socioEconomicData,
    [environmentalData.id]: environmentalData
  }
};
