import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getSafeTranslation } from '../../../../utils/language';

import DropdownItemShape, {
  DropdownItemWithColorShape
} from '../../../../metadata/shapes/DropdownItemShape';

import Icon from '../../Icon';
import Visible from '../../layout/Visible';

const MoshiDropdownMenuItemContent = ({ item, isSelected, className, id }) => (
  <li className={classNames({ selected: isSelected }, className)} id={id}>
    <Visible visible={!_.isEmpty(item.color)}>
      <span
        style={{ backgroundColor: item.color }}
        className={'color-indicator'}
      />
    </Visible>
    <Visible visible={_.isFunction(item.icon)}>{() => <item.icon />}</Visible>
    <span>{getSafeTranslation(item.name)}</span>
    <Visible visible={isSelected}>
      <Icon name={'ok'} size={'md'} className={'checkmark'} />
    </Visible>
  </li>
);

MoshiDropdownMenuItemContent.propTypes = {
  /** The item that populates one row in the dropdown, needs to have `id` and `name` properties */
  item: PropTypes.oneOfType([
    PropTypes.shape(DropdownItemShape),
    PropTypes.shape(DropdownItemWithColorShape)
  ]).isRequired,
  /** Indicates whether the current item is also the selected item in the dropown */
  isSelected: PropTypes.bool,
  /** Custom classname option */
  className: PropTypes.string,
  /** DOM id attribute for the current list item */
  id: PropTypes.string
};

MoshiDropdownMenuItemContent.defaultProps = {
  isSelected: false,
  className: '',
  id: ''
};

export default MoshiDropdownMenuItemContent;
