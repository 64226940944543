import produce from 'immer';
import _ from 'lodash';

import { EMPTY_GUID } from '../../../utils/gen';

import {
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_SUBSCRIBE,
  WEBSOCKET_UNSUBSCRIBE,
  WEBSOCKET_CONNECT_IN_PROGRESS,
  WEBSOCKET_CONNECT_SUCCESS,
  WEBSOCKET_CONNECT_FAILURE
} from './webSocketTypes';

const initialState = {
  pending: false,
  connected: false,
  activeSubscriptions: {}
};

// eslint-disable-next-line default-param-last
const webSocketReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case WEBSOCKET_CONNECT_IN_PROGRESS:
        draft.pending = true;
        draft.connected = false;
        break;
      case WEBSOCKET_CONNECT_SUCCESS:
        draft.pending = false;
        draft.connected = true;
        break;
      case WEBSOCKET_CONNECT_FAILURE:
        draft.pending = false;
        draft.connected = false;
        break;
      case WEBSOCKET_SUBSCRIBE:
        const componentId = _.get(payload, 'componentId', EMPTY_GUID);
        const channel = _.get(payload, 'channel', '');
        const existingComponentSubscriptions = _.get(
          draft,
          `activeSubscriptions[${componentId}]`,
          []
        );

        draft.activeSubscriptions = {
          ...draft.activeSubscriptions,
          [componentId]: _.concat(existingComponentSubscriptions, channel)
        };
        break;
      case WEBSOCKET_UNSUBSCRIBE:
        const componentIdValue = _.get(payload, 'componentId');
        const subscriptionExists = !_.isEmptySafe(
          draft,
          `activeSubscriptions.${componentIdValue}`
        );

        if (subscriptionExists) {
          delete draft.activeSubscriptions[componentIdValue];
        }
        break;
      case WEBSOCKET_DISCONNECT:
        draft.pending = false;
        draft.connected = false;
        draft.activeSubscriptions = {};
        break;
      default:
    }
  });

export default webSocketReducer;
