export const PATIENT_SORT_BY_FIRST_LAST_NAME = 'firstNameLastName';
export const PATIENT_SORT_BY_LAST_CHECK_IN_AT = 'lastCheckInAt';
export const PATIENT_SORT_BY_LAST_FIRST_NAME = 'lastNameFirstName';

export const PATIENT_WIDGET_SEARCH_FILTER = 'query';
export const PATIENT_WIDGET_GROUP_FILTER = 'groupId';

export const PATIENT_ANAMNESIS_SEARCH_FILTER = 'search';
export const PATIENT_ANAMNESIS_USER_FILTER = 'userId';

export const PATIENT_ANAMNESIS_SEVERITY_FIELD = 'severity';
export const PATIENT_ANAMNESIS_SEARCHABLE_FIELDS = [
  'medicalConditionName',
  'concomitantDrugName',
  'allergyName',
  'vaccinationName',
  'implantName',
  'description',
  PATIENT_ANAMNESIS_SEVERITY_FIELD
];

export const PATIENT_SEVERITY_MILD = 'mild';
export const PATIENT_SEVERITY_MODERATE = 'moderate';
export const PATIENT_SEVERITY_SEVERE = 'severe';
export const PATIENT_SEVERITY_UNKNOWN = 'unknown';

export const PATIENT_ANAMNESIS_PREFIX = 'anamnesis';
export const PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE = 'medicalCondition';
export const PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE = 'concomitantDrug';
export const PATIENT_ANAMNESIS_VACCINATION_TYPE = 'vaccination';
export const PATIENT_ANAMNESIS_ALLERGY_TYPE = 'allergy';
export const PATIENT_ANAMNESIS_IMPLANT_TYPE = 'implant';
export const PATIENT_ANAMNESIS_OPERATION_TYPE = 'operation';
export const PATIENT_ANAMNESIS_OTHER_TYPE = 'other';

export const HOME_PAGE_PATIENT_SCHEMA = 'home';
export const PERSONAL_INFO_PAGE_PATIENT_SCHEMA = 'personal-info';
export const MEDICAL_RECORD_PAGE_PATIENT_SCHEMA = 'medical-record';
export const DOCUMENTS_PAGE_PATIENT_SCHEMA = 'documents';
export const BILLS_PAGE_PATIENT_SCHEMA = 'bills';
export const APPOINTMENTS_PAGE_PATIENT_SCHEMA = 'appointments';
export const ACTIVE_ENCOUNTER_PAGE_PATIENT_SCHEMA = 'active-encounter';
export const DENTAL_CHART_PAGE_PATIENT_SCHEMA = 'dental-chart';

export const PATIENT_EVENT_LIST_TYPE_QUEUE = 'queue';
export const PATIENT_EVENT_LIST_TYPE_PAST = 'past';
export const PATIENT_EVENT_LIST_TYPE_UPCOMING = 'upcoming';
