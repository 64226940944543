import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { MOSHI_COLOR_DANGER_NAME } from '../../../utils/color';

import MoshiRoutedPrompt from './MoshiRoutedPrompt';

const promptConfig = (translate) => ({
  header: translate('modal:confirmDiscardUnsaved.title'),
  message: translate('modal:confirmDiscardUnsaved.message'),
  submitButtonText: translate('modal:confirmDiscardUnsaved.discardButton'),
  submitButtonColor: MOSHI_COLOR_DANGER_NAME,
  cancelButtonText: translate('common:back'),
  confirmActionType: ''
});

export const MoshiRoutedDiscardChangesPromptComponent = ({ when }) => {
  const { t: translate } = useTranslation();

  return <MoshiRoutedPrompt {...promptConfig(translate)} when={when} />;
};

MoshiRoutedDiscardChangesPromptComponent.propTypes = {
  when: PropTypes.bool
};

MoshiRoutedDiscardChangesPromptComponent.defaultProps = {
  when: true
};

export default MoshiRoutedDiscardChangesPromptComponent;
