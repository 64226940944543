export default {
  title: 'Analitika',
  emptyListText: 'Žal ni podatkov za prikaz.',
  location: 'Lokacija',
  invalidDateRange: '*Napačen časovni okvir',
  dashboard: {
    title: 'Pregled',
    totalSales: 'Skupna prodaja',
    appointments: 'Termini',
    today: 'Danes',
    thisWeek: 'Ta teden',
    thisMonth: 'Ta mesec',
    lastSevenDays: 'Zadnjih 7 dni',
    revenuePerApp: 'Prihodek/Termin'
  },
  sales: {
    title: 'Prodaja',
    totalSalesPerDoctor: 'Prodaja glede na zdravnika',
    totalSalesPerPaymentType: 'Prodaja glede na plačilno sredstvo',
    paymentType: 'Vrsta plačila'
  },
  appointments: {
    title: 'Termini',
    total: 'Skupaj',
    completed: 'Zaključeni',
    noShow: 'Ni prišel',
    reserved: 'Rezervani',
    futureConfirmed: 'Prihajajoči',
    canceled: 'Preklicani',
    busiestDay: 'Dan z največ obiska',
    busiestWeek: 'Teden z največ obiska',
    appointmentsPerDoctor: 'Termini glede na zdravnika',
    appointmentsPerCategory: 'Termini glede na kategorijo'
  },
  patients: {
    title: 'Pacienti',
    newPatients: 'Novi pacienti',
    newPatientsInfo: 'Novi pacienti v izbranem časovnem obdobju',
    activePatients: 'Aktivni pacienti',
    activePatientsInfo: 'Vsaj en obisk v izbranem časovnem obdobju',
    byGender: 'Po spolu',
    allTime: 'Ves čas',
    allPatients: 'Vsi pacienti',
    noGender: 'Nedefiniran spol',
    totalCount: 'Število',
    averageAge: 'Povprečna starost',
    byDoctors: 'Pacienti po zdravniku',
    patientsNumber: 'Število pacientov',
    salesPerPatient: 'Pacienti z največ plačili',
    appointmentsPerPatient: 'Pacienti z največ obiski'
  }
};
