import { take, race, call, select } from 'redux-saga/effects';

import { getEstimateListUrl } from '../../../utils/api/apiUrlUtils';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import {
  START_ESTIMATE_POLLING,
  STOP_ESTIMATE_POLLING,
  STOP_POLLING
} from '../../core/cache/cacheTypes';
import {
  CLEAR_ESTIMATE_LIST_FILTER,
  GET_ESTIMATE_LIST,
  SET_ESTIMATE_LIST_FILTER,
  SET_ESTIMATE_LIST_FILTERS,
  SET_ESTIMATE_LIST_ORDER,
  SET_ESTIMATE_LIST_PAGINATION
} from './estimateTypes';

import {
  selectEstimateFilter,
  selectEstimatePagination
} from './estimateSelectors';

function* pollEstimatesSaga() {
  function* configGetter() {
    const state = yield select();
    const { limit, page } = selectEstimatePagination(state);
    const customFilter = selectEstimateFilter(state);

    return {
      params: {
        ...customFilter,
        limit,
        page
      }
    };
  }

  yield pollGenerator(GET_ESTIMATE_LIST, getEstimateListUrl, {
    retriggerActions: [
      SET_ESTIMATE_LIST_FILTER,
      SET_ESTIMATE_LIST_FILTERS,
      SET_ESTIMATE_LIST_ORDER,
      SET_ESTIMATE_LIST_PAGINATION,
      CLEAR_ESTIMATE_LIST_FILTER
    ],
    configGetter
  });
}

export function* estimateSagaWatcher() {
  while (true) {
    yield take(START_ESTIMATE_POLLING);
    yield race([
      call(pollEstimatesSaga),
      take(STOP_POLLING),
      take(STOP_ESTIMATE_POLLING)
    ]);
  }
}
