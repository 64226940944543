import {
  createActionTypeArray,
  createSanitizableActionTypeArray
} from '../../common/actionTypes';

export const WIDGET_FIELD_DATA_TYPE_DOCTORS = 'widgetDataType_DOCTORS';
export const WIDGET_FIELD_DATA_TYPE_PATIENTS = 'widgetDataType_PATIENTS';
export const WIDGET_FIELD_DATA_TYPE_FLOWS = 'widgetDataType_FLOWS';
export const WIDGET_FIELD_DATA_TYPE_LOCATIONS = 'widgetDataType_LOCATIONS';
export const WIDGET_FIELD_DATA_TYPE_PATIENT_THERAPIES =
  'widgetDataType_PATIENT_THERAPIES';

export const SET_CURRENT_ENCOUNTER_ID = 'widget/SET_CURRENT_ENCOUNTER_ID';
export const GET_WIDGET_LIST = 'widget/GET_WIDGET_LIST';
export const ADD_WIDGET = 'widget/ADD_WIDGET';
export const UPDATE_WIDGET = 'widget/UPDATE_WIDGET';
export const REMOVE_WIDGET = 'widget/REMOVE_WIDGET';

export const UPDATE_WIDGET_LIST_ADD = 'widget/UPDATE_WIDGET_LIST_ADD';
export const UPDATE_WIDGET_LIST_UPDATE = 'widget/UPDATE_WIDGET_LIST_UPDATE';
export const UPDATE_WIDGET_LIST_REMOVE = 'widget/UPDATE_WIDGET_LIST_REMOVE';

export const ADD_LOCAL_WIDGET = 'widget/ADD_LOCAL_WIDGET';
export const REMOVE_LOCAL_WIDGET = 'widget/REMOVE_LOCAL_WIDGET';
export const SET_WIDGET_FORM_DATA = 'widget/SET_WIDGET_FORM_DATA';
export const CLEAR_WIDGET_FORM_DATA = 'widget/CLEAR_WIDGET_FORM_DATA';
export const SET_WIDGET_EDIT_MODE = 'widget/SET_WIDGET_EDIT_MODE';
export const CLEAR_LOCAL_ENCOUNTER_WIDGET_DATA =
  'widget/CLEAR_LOCAL_ENCOUNTER_WIDGET_DATA';
export const GET_PATIENT_WIDGET_LIST = 'widget/GET_PATIENT_WIDGET_LIST';
export const GET_WIDGET_HISTORY = 'widget/GET_WIDGET_HISTORY';
export const WIDGET_HISTORY_UPDATE = 'widget/WIDGET_HISTORY_UPDATE';
export const GET_ALL_PATIENT_WIDGET_LIST = 'widget/GET_ALL_PATIENT_WIDGET_LIST';
export const CLEAR_ALL_PATIENT_WIDGET_LIST =
  'widget/CLEAR_ALL_PATIENT_WIDGET_LIST';
export const SET_ALL_PATIENT_WIDGET_FILTER =
  'widget/SET_ALL_PATIENT_WIDGET_FILTER';

export const [
  GET_WIDGET_LIST_IN_PROGRESS,
  GET_WIDGET_LIST_SUCCESS,
  GET_WIDGET_LIST_FAILURE
] = createActionTypeArray(GET_WIDGET_LIST);

export const [
  GET_WIDGET_HISTORY_TRIGGER,
  GET_WIDGET_HISTORY_IN_PROGRESS,
  GET_WIDGET_HISTORY_SUCCESS,
  GET_WIDGET_HISTORY_FAILURE,
  GET_WIDGET_HISTORY_SANITIZED,
  GET_WIDGET_HISTORY_UPDATED
] = createSanitizableActionTypeArray(GET_WIDGET_HISTORY);

export const [
  GET_ALL_PATIENT_WIDGET_LIST_IN_PROGRESS,
  GET_ALL_PATIENT_WIDGET_LIST_SUCCESS,
  GET_ALL_PATIENT_WIDGET_LIST_FAILURE
] = createActionTypeArray(GET_ALL_PATIENT_WIDGET_LIST);

export const [
  ADD_WIDGET_TRIGGER,
  ADD_WIDGET_IN_PROGRESS,
  ADD_WIDGET_SUCCESS,
  ADD_WIDGET_FAILURE,
  ADD_WIDGET_SANITIZED,
  ADD_WIDGET_UPDATED
] = createSanitizableActionTypeArray(ADD_WIDGET);

export const [
  UPDATE_WIDGET_TRIGGER,
  UPDATE_WIDGET_IN_PROGRESS,
  UPDATE_WIDGET_SUCCESS,
  UPDATE_WIDGET_FAILURE,
  UPDATE_WIDGET_SANITIZED,
  UPDATE_WIDGET_UPDATED
] = createSanitizableActionTypeArray(UPDATE_WIDGET);

export const [
  REMOVE_WIDGET_TRIGGER,
  REMOVE_WIDGET_IN_PROGRESS,
  REMOVE_WIDGET_SUCCESS,
  REMOVE_WIDGET_FAILURE,
  ,
  REMOVE_WIDGET_UPDATED
] = createSanitizableActionTypeArray(REMOVE_WIDGET);

export const [SET_WIDGET_FORM_DATA_TRIGGER] = createSanitizableActionTypeArray(
  SET_WIDGET_FORM_DATA
);

export const SET_FOCUSED_WIDGET_ID = 'widget/SET_FOCUSED_WIDGET_ID';
export const CLEAR_FOCUSED_WIDGET_ID = 'widget/CLEAR_FOCUSED_WIDGET_ID';

export const SET_WIDGET_IDS_TO_SUBMIT = 'widget/SET_WIDGET_IDS_TO_SUBMIT';
export const CLEAR_WIDGET_IDS_TO_SUBMIT = 'widget/CLEAR_WIDGET_IDS_TO_SUBMIT';
