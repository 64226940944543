import { all } from 'redux-saga/effects';

import { getFormTemplateUrl } from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import { pollFormTemplatesListSagaWatcher } from './template/formTemplateSagas';

import { POLL_FORMS } from '../core/cache/cacheTypes';
import {
  ADD_FORM_TEMPLATE_SUCCESS,
  REMOVE_FORM_TEMPLATE_SUCCESS,
  UPDATE_FORM_TEMPLATE_SUCCESS
} from './template/formTemplateTypes';

export function* pollFormsSaga() {
  const configGetter = () => ({
    params: { limit: REQUEST_PAGINATION_MAX_LIMIT, page: 1 }
  });

  yield pollGenerator(POLL_FORMS, getFormTemplateUrl, {
    retriggerActions: [
      ADD_FORM_TEMPLATE_SUCCESS,
      UPDATE_FORM_TEMPLATE_SUCCESS,
      REMOVE_FORM_TEMPLATE_SUCCESS
    ],
    configGetter
  });
}

export function* formSagaWatcher() {
  yield all([pollFormTemplatesListSagaWatcher()]);
}
