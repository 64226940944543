/* eslint-disable max-len,max-lines-per-function */
import React from 'react';

import PropTypes from 'prop-types';

const LeftFootOutline = ({ outlineColor, disabledColor, disabled }) => {
  const color = disabled ? disabledColor : outlineColor;

  return (
    <g id={'zoomed-left-foot-outline'}>
      <path
        d={
          'M342.899 759.08C342.874 758.772 343.747 772.841 343.747 776.178C343.772 779.387 344.646 785.189 343.182 795.15C341.718 805.111 343.053 809.218 349.037 809.911C356.485 810.758 359.67 805.47 360.8 799.078C361.468 795.227 360.774 790.092 360.774 787.32C360.774 784.547 361.853 782.827 361.416 778.129C360.98 773.431 357.975 767.86 363.24 759.106'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M361.108 790.503C361.75 793.892 363.317 796.253 368.299 794.97C375.182 793.198 376.414 785.831 377.262 781.723C379.548 770.915 376.414 756.051 378.906 749.864'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M378.084 774.663C379.445 776.486 381.654 777.538 385.018 777.051C393.159 775.87 397.14 765.498 397.114 757.591C397.088 752.868 393.262 743.472 393.031 739.621C392.825 735.77 395.393 732.997 395.393 732.997'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M396.241 752.123C396.96 755.229 399.169 758.053 403.432 755.05C407.721 752.046 410.34 744.729 410.34 739.159C410.34 733.588 408.234 729.326 408.671 724.602C409.108 719.904 411.881 707.71 410.597 702.37C409.313 697.031 403.329 683.758 401.608 676.493C399.888 669.228 379.573 599.091 379.573 587.539'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M343.336 766.885C343.336 766.885 339.586 762.494 336.53 765.447'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M378.289 759.055C379.676 758.028 380.832 756.179 381.089 754.254'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M394.443 745.705C395.265 744.344 397.217 742.855 397.14 739.492'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M335.041 792.326C333.628 791.376 331.574 790.554 328.569 790.426C323.022 790.195 320.068 793.738 318.784 795.945'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M354.225 799.668C351.888 799.514 350.142 800.156 348.96 800.823'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M371.484 787.961C371.073 787.114 370.148 786.318 368.325 786.062'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M389.409 770.941C388.588 769.58 386.662 769.041 384.145 769.247'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M406.745 750.839C406.745 750.839 406.385 748.195 402.636 750.249'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M316.293 699.084C317.911 692.564 320.068 682.064 320.274 671.975'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M372.408 621.965C371.56 612.723 371.843 595.215 382.629 579.221C398.193 556.116 398.193 556.116 398.193 556.116'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M324.075 553.087C321.198 559.351 307.715 568.593 307.715 580.838C307.715 593.546 319.272 609.822 319.272 617.935'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M320.222 545C309.23 561.019 299.676 560.968 308.768 617.935C310.9 631.259 313.057 647.432 312.184 670.101C311.336 692.769 302.142 717.594 302.142 735.334C302.142 746.347 308.126 752.021 308.357 762.29C308.562 772.558 304.941 778.54 304.941 786.036C304.941 793.533 311.156 813.198 328.466 812.35C345.802 811.503 343.644 790.426 341.949 782.725C340.229 775.023 335.965 769.991 336.171 759.748'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M329.93 779.849C329.93 779.849 324.793 780.979 319.94 780.85'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M356.331 780.363C356.331 780.363 351.837 780.593 350.347 780.363'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M392.902 759.055C392.902 759.055 391.464 759.414 389.409 759.594'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M406.745 739.492H404.305'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M326.745 744.832C323.689 744.704 320.556 744.267 317.397 743.523'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M357.05 744.165C354.79 744.601 352.53 744.935 350.219 745.166'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M374.36 736.463C372.793 736.746 371.227 737.028 369.634 737.233'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M388.434 726.887C386.893 727.247 385.3 727.581 383.657 727.914'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M406.206 710.072C405.255 710.509 403.92 711.073 402.199 711.715'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M373.384 772.609C372.357 772.712 370.687 772.789 369.069 772.609'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M388.613 665.479C387.792 662.527 386.918 658.882 386.071 654.415'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
    </g>
  );
};

LeftFootOutline.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

LeftFootOutline.defaultProps = {
  disabled: false
};

export default LeftFootOutline;
