import {
  ACTIVE_ENCOUNTER_PAGE_PATIENT_SCHEMA,
  APPOINTMENTS_PAGE_PATIENT_SCHEMA,
  BILLS_PAGE_PATIENT_SCHEMA,
  DENTAL_CHART_PAGE_PATIENT_SCHEMA,
  DOCUMENTS_PAGE_PATIENT_SCHEMA,
  HOME_PAGE_PATIENT_SCHEMA,
  MEDICAL_RECORD_PAGE_PATIENT_SCHEMA,
  PERSONAL_INFO_PAGE_PATIENT_SCHEMA
} from '../../../utils/constants/patientConstants';

import { TAGS_FEATURE_MODULE } from '../../../featureModules';

export default {
  tileLayoutSchema: {
    pages: [
      {
        id: HOME_PAGE_PATIENT_SCHEMA,
        layout: '3-column',
        name: 'common:home',
        icon: 'home-alt2',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'noteTile'
            }
          ],
          right: [
            {
              type: 'unbilledItems'
            }
          ]
        }
      },
      {
        id: PERSONAL_INFO_PAGE_PATIENT_SCHEMA,
        layout: '3-column',
        name: 'patient:detail.personalInfo.title',
        icon: 'person',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            },
            {
              type: 'possibleDuplicates'
            }
          ],
          center: [
            {
              type: 'personalInfo'
            }
          ],
          right: [
            {
              type: 'unbilledItems'
            }
          ]
        }
      },
      {
        id: MEDICAL_RECORD_PAGE_PATIENT_SCHEMA,
        layout: '2-column',
        name: 'patient:medicalRecord.title',
        icon: 'copy',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'medicalRecord'
            }
          ]
        }
      },
      {
        id: DOCUMENTS_PAGE_PATIENT_SCHEMA,
        layout: '2-full-width-column',
        name: 'patient:detail.documents',
        icon: 'file',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'documents'
            }
          ]
        }
      },
      {
        id: BILLS_PAGE_PATIENT_SCHEMA,
        layout: '3-full-width-column',
        name: 'billing:invoices.title',
        icon: 'inbox-alt',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'bills'
            }
          ],
          right: [
            {
              type: 'unbilledItems'
            }
          ]
        }
      },
      {
        id: APPOINTMENTS_PAGE_PATIENT_SCHEMA,
        icon: 'calendar',
        name: 'calendar:appointments',
        layout: '2-column',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'appointments'
            }
          ]
        }
      },
      {
        id: ACTIVE_ENCOUNTER_PAGE_PATIENT_SCHEMA,
        layout: '2-column',
        name: 'encounter:title',
        icon: 'play',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'activeEncounter'
            }
          ]
        }
      },
      {
        id: DENTAL_CHART_PAGE_PATIENT_SCHEMA,
        icon: 'chart',
        name: 'dentalChart:dentalChart',
        layout: '2-full-width-column',
        sections: {
          left: [
            {
              type: 'basicInfo',
              params: {
                linksTo: 'personal-info'
              }
            },
            {
              type: 'reminder'
            },
            {
              type: 'note'
            }
          ],
          center: [
            {
              type: 'dentalChart'
            }
          ]
        }
      }
    ],
    subHeader: [{ type: 'anamnesis' }],
    default: ['active-encounter', 'home', 'personal-info']
  },
  uiSchema: {
    additionalProperties: false,
    'ui:order': ['identification', 'contact', 'doctor', 'notifications', '*'],
    identification: {
      'ui:order': [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'insuranceID',
        'shortID',
        '*'
      ],
      'ui:options': {
        label: false
      },
      gender: {
        'ui:widget': 'customDropdownWidget',
        'ui:column': 6
      },
      firstName: {
        'ui:column': 6
      },
      lastName: {
        'ui:column': 6
      },
      dateOfBirth: {
        'ui:widget': 'customDatepickerWidget',
        'ui:column': 6
      },
      insuranceID: {
        'ui:column': 6,
        'ui:emptyValue': ''
      },
      shortID: {
        'ui:column': 6,
        'ui:emptyValue': '',
        'ui:readonly': true
      },
      possibleDuplicates: {
        classNames: 'd-none'
      }
    },
    contact: {
      'ui:order': ['email', 'phoneNumber', 'address', '*'],
      email: {
        'ui:column': 12,
        'ui:emptyValue': null
      },
      phoneNumber: {
        'ui:widget': 'customPhoneNumberWidget',
        'ui:column': 12,
        'ui:emptyValue': null
      },
      address: {
        'ui:order': ['street1', 'zip', 'town', 'country'],
        'ui:options': {
          subGroup: true
        },
        street1: {
          'ui:column': 12,
          'ui:emptyValue': ''
        },
        zip: {
          'ui:column': 6,
          'ui:emptyValue': ''
        },
        town: {
          'ui:column': 6,
          'ui:emptyValue': ''
        },
        country: {
          'ui:widget': 'customCountriesDropdownWidget',
          'ui:emptyValue': ''
        }
      }
    },
    doctor: {
      doctorID: {
        'ui:widget': 'customDataDropdownWidget',
        'ui:options': {
          widgetDataType: 'widgetDataType_DOCTORS',
          entityType: 'SUB_ENTITY_TYPE_USER'
        }
      },
      locationIDs: {
        items: {
          'ui:widget': 'customDataDropdownWidget',
          'ui:options': {
            orderable: false,
            widgetDataType: 'widgetDataType_LOCATIONS',
            entityType: 'SUB_ENTITY_TYPE_LOCATION',
            xAddItemButtonTranslationKey: 'settings:account.addLocation'
          }
        }
      }
    },
    notifications: {
      'ui:order': ['reminders', 'marketing', '*'],
      marketing: {
        'ui:options': {
          nestedSubGroup: true
        },
        sms: {
          'ui:options': {
            label: false
          },
          'ui:widget': 'customToggleWidget'
        },
        email: {
          'ui:options': {
            label: false
          },
          'ui:widget': 'customToggleWidget'
        },
        push: {
          'ui:options': {
            label: false
          },
          'ui:widget': 'customToggleWidget'
        }
      },
      reminders: {
        'ui:options': {
          // TODO change to nestedSubGroup when marketing will be added to jsonSchema
          subGroup: true
        },
        sms: {
          'ui:options': {
            label: false
          },
          'ui:widget': 'customToggleWidget'
        },
        email: {
          'ui:options': {
            label: false
          },
          'ui:widget': 'customToggleWidget'
        },
        push: {
          'ui:options': {
            label: false
          },
          'ui:widget': 'customToggleWidget'
        }
      }
    },
    lastCheckInAt: {
      'ui:widget': 'hiddenWidget',
      'ui:options': {
        label: false
      }
    },
    therapies: {
      classNames: 'd-none'
    }
  },
  tableSchema: [
    {
      name: 'lastNameFirstName',
      cellComponentName: 'link',
      cellClassName: 'tw-flex tw-items-center',
      config: {
        path: '/patients/{{ id }}',
        className: 'font-weight-bold',
        cell: {
          cellComponentName: 'firstLastName',
          config: {
            displayBadge: true,
            className: 'clickable-text font-weight-bold'
          }
        }
      },
      displayKeyCode: 'common:name',
      width: 4,
      sortable: true
    },
    {
      name: 'dateOfBirth',
      cellComponentName: 'dateAndDifference',
      displayKeyCode: 'patient:list.dateOfBirthAndAge',
      cellClassName: 'tw-flex tw-items-center',
      width: 4
    },
    {
      name: 'lastCheckInAt',
      cellComponentName: 'dateAndDifference',
      displayKeyCode: 'patient:list.lastVisitAndPeriod',
      cellClassName: 'tw-flex tw-items-center',
      width: 4,
      sortable: true
    },
    {
      name: 'doctorID',
      cellComponentName: 'doctor',
      displayKeyCode: 'common:roles.doctor',
      cellClassName: 'tw-flex tw-items-center',
      width: 4
    },
    {
      name: 'lastUpdateAt',
      cellComponentName: 'dateAndDifference',
      displayKeyCode: 'common:lastUpdateAt',
      cellClassName: 'tw-flex tw-items-center',
      width: 4,
      sortable: true
    },
    {
      name: 'tags',
      cellComponentName: 'tags',
      displayKeyCode: 'tags:title',
      cellClassName: 'tw-flex tw-items-center',
      featureModule: TAGS_FEATURE_MODULE,
      width: 4,
      config: {
        maxNumberOfTags: 2
      }
    }
  ]
};
