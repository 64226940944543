import _ from 'lodash';

import {
  PATIENT_ANAMNESIS_ALLERGY_TYPE,
  PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE,
  PATIENT_ANAMNESIS_IMPLANT_TYPE,
  PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE,
  PATIENT_ANAMNESIS_OPERATION_TYPE,
  PATIENT_ANAMNESIS_OTHER_TYPE,
  PATIENT_ANAMNESIS_PREFIX,
  PATIENT_ANAMNESIS_VACCINATION_TYPE
} from '../constants/patientConstants';

export const getAnamnesisType = (type) => {
  if (
    !_.includes(
      [
        PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE,
        PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE,
        PATIENT_ANAMNESIS_VACCINATION_TYPE,
        PATIENT_ANAMNESIS_OPERATION_TYPE,
        PATIENT_ANAMNESIS_ALLERGY_TYPE,
        PATIENT_ANAMNESIS_IMPLANT_TYPE,
        PATIENT_ANAMNESIS_OTHER_TYPE
      ],
      type
    )
  ) {
    return;
  }

  return `${PATIENT_ANAMNESIS_PREFIX}/${type}/v1`;
};

export const PATIENT_ANAMNESIS_ALL_TYPES = [
  getAnamnesisType(PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE),
  getAnamnesisType(PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE),
  getAnamnesisType(PATIENT_ANAMNESIS_VACCINATION_TYPE),
  getAnamnesisType(PATIENT_ANAMNESIS_OPERATION_TYPE),
  getAnamnesisType(PATIENT_ANAMNESIS_ALLERGY_TYPE),
  getAnamnesisType(PATIENT_ANAMNESIS_IMPLANT_TYPE),
  getAnamnesisType(PATIENT_ANAMNESIS_OTHER_TYPE)
];
