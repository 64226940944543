/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { DropdownItem } from 'reactstrap';

import PropTypes from 'prop-types';

const DropdownItemWrapper = ({
  children,
  onClick,
  className,
  id,
  ...props
}) => (
  <DropdownItem onClick={onClick} className={className} id={id} {...props}>
    {children}
  </DropdownItem>
);

DropdownItemWrapper.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string
};

DropdownItemWrapper.defaultProps = {
  children: undefined,
  onClick: undefined,
  className: undefined,
  id: undefined
};

export default DropdownItemWrapper;
