import axios, { CancelToken } from 'axios';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { extension } from 'mime-types';

import { showMissingOrganizationIdError } from '../../../utils/alert';
import {
  CUSTOM_FILE_EXTENSIONS,
  MOSHI_FILE_TYPE_PDF
} from '../../../utils/constants/fileConstants';

import { EMPTY_ACTION_TYPE } from '../../common/actionTypes';
import { getPatientFiles } from '../../patient/patientActions';

import { GET_PATIENT_FILE_BY_URL } from '../../patient/patientTypes';
import {
  ADD_FILE,
  GET_ALL_PATIENTS_FILES,
  UPLOAD_FILE_STATUS
} from './fileTypes';

import { defaultProgressEvent } from './fileReducer';

import { selectCachedOrganizationId } from '../cache/cacheSelectors';

export const appendItemsToFormData = (
  extraFormData,
  formData = new FormData()
) => {
  _.forIn(extraFormData, (value, key) => {
    let safeValue = value;

    if (_.isObject(safeValue)) {
      safeValue = JSON.stringify(safeValue);
    }

    formData.append(key, safeValue);
  });

  return formData;
};

export const axiosUploadProgressConfig = (dispatch) => {
  if (!_.isFunction(dispatch)) {
    throw new Error('Expected dispatch to be provided.');
  }

  return (progressEvent) => {
    dispatch({
      type: UPLOAD_FILE_STATUS,
      progressEvent
    });
  };
};

export const uploadProgressFinished = (dispatch) => {
  if (!_.isFunction(dispatch)) {
    throw new Error('Expected dispatch to be provided.');
  }

  dispatch({
    type: UPLOAD_FILE_STATUS,
    progressEvent: defaultProgressEvent
  });
};

const getFileFormData = (file, name) => {
  const formData = new FormData();

  formData.append(
    name,
    new Blob([file], { type: file.type }),
    file.name || 'file'
  );

  return formData;
};

export const getFileBlobByUrl = (
  fileUrl,
  actionType = GET_PATIENT_FILE_BY_URL,
  onSuccess = undefined,
  queryParams = undefined
) => (dispatch) =>
  dispatch({
    type: actionType,
    apiCall: axios.get(fileUrl, {
      responseType: 'blob',
      params: queryParams
    }),
    onSuccess
  });

export const downloadFileByUrl = (
  action,
  fileUrl,
  fileName,
  queryParams = undefined
) => (dispatch) =>
  dispatch(
    getFileBlobByUrl(
      fileUrl,
      action,
      (notUsed, response) => fileDownload(response.data, fileName),
      queryParams
    )
  );

export const getFileExtensionByType = (type) => {
  if (_.has(CUSTOM_FILE_EXTENSIONS, type)) {
    return _.get(CUSTOM_FILE_EXTENSIONS, type);
  }

  return extension(type);
};

export const openFileByUrl = (
  action,
  fileUrl,
  mimeType = MOSHI_FILE_TYPE_PDF,
  queryParams = undefined
) => (dispatch) =>
  dispatch(
    getFileBlobByUrl(
      fileUrl,
      action,
      (notUsed, response) => {
        const file = new Blob([response.data], { type: mimeType });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      },
      queryParams
    )
  );

export const printFileByUrl = (
  action,
  fileUrl,
  mimeType = MOSHI_FILE_TYPE_PDF,
  queryParams = undefined
) => (dispatch) =>
  dispatch(
    getFileBlobByUrl(
      fileUrl,
      action,
      (notUsed, response) => {
        const file = new Blob([response.data], { type: mimeType });
        let fileURL = URL.createObjectURL(file);
        const printFrame = document.querySelector('#print-frame');

        if (_.isEmpty(printFrame)) {
          return;
        }
        printFrame.src = '';
        printFrame.src = fileURL;
        fileURL = URL.revokeObjectURL(file);

        window.setTimeout(() => {
          if (!_.isEmptySafe(printFrame, 'contentWindow.print', false)) {
            printFrame.contentWindow.print();
          }
        }, 1000);
      },
      queryParams
    )
  );

export const uploadFile = (url, file, extraFormData, options) => (dispatch) => {
  const fieldName = _.getNonEmpty(options, 'fieldName', 'file');
  const onSuccess = _.getNonEmpty(options, 'onSuccess', _.noop);
  const onError = _.getNonEmpty(options, 'onError', _.noop);
  const actionType = _.getNonEmpty(options, 'actionType', ADD_FILE);
  const method = _.getNonEmpty(options, 'method', 'post');
  const setCancelToken = _.getNonEmpty(options, 'setCancelToken', _.noop);

  const onUploadProgress = _.getNonEmpty(
    options,
    'onUploadProgress',
    axiosUploadProgressConfig(dispatch)
  );

  const formData =
    file instanceof FormData ? file : getFileFormData(file, fieldName);

  if (!(formData instanceof FormData)) {
    throw new Error('Expected type to be a FormData.');
  }

  const updatedFormData = appendItemsToFormData(extraFormData, formData);

  return dispatch({
    type: actionType,
    apiCall: axios({
      method,
      url,
      data: updatedFormData,
      onUploadProgress,
      cancelToken: new CancelToken(setCancelToken)
    }),
    onSuccess: (state, response) => {
      uploadProgressFinished(dispatch);
      onSuccess(state, response);
    },
    onError: () => {
      uploadProgressFinished(dispatch);
      onError();
    }
  });
};

export const getPatientFileDocuments = (patientId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const { limit, page } = _.get(getState(), 'file.pagination', {});

  return dispatch(
    getPatientFiles(
      patientId,
      { type: 'formSubmission|externalDocument' },
      { params: { limit, page } },
      GET_ALL_PATIENTS_FILES
    )
  );
};

export const getFileArrayBuffer = (
  fileUrl,
  actionType = GET_PATIENT_FILE_BY_URL
) => (dispatch) =>
  dispatch(getFileBlobByUrl(fileUrl), actionType).then(async (data) => {
    const arrayBuffedData = await data.arrayBuffer();

    return arrayBuffedData.byteLength > 0 ? arrayBuffedData : new ArrayBuffer();
  });

/*
 * @description Downloads file with file extensions written inside content-type.
 */
export const genericFileDownload = (fileUrl, fileName) => (dispatch) =>
  dispatch(
    getFileBlobByUrl(fileUrl, EMPTY_ACTION_TYPE, (notUsed, response) => {
      const contentType = _.getNonEmpty(response, 'headers.content-type');

      const fileExtension = extension(contentType);

      return fileDownload(response?.data, `${fileName}.${fileExtension}`);
    })
  );
