/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Input } from 'reactstrap';

import PropTypes from 'prop-types';

const InputWrapper = ({
  children,
  type,
  size,
  bsSize,
  valid,
  invalid,
  innerRef,
  plaintext,
  addon,
  className,
  id,
  ...props
}) => (
  <Input
    type={type}
    size={size}
    bsSize={bsSize}
    valid={valid}
    invalid={invalid}
    innerRef={innerRef}
    plaintext={plaintext}
    addon={addon}
    className={className}
    id={id}
    {...props}
  >
    {children}
  </Input>
);

InputWrapper.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  bsSize: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ]),
  plaintext: PropTypes.bool,
  addon: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

InputWrapper.defaultProps = {
  children: undefined,
  type: undefined,
  size: undefined,
  bsSize: undefined,
  valid: undefined,
  invalid: undefined,
  innerRef: undefined,
  plaintext: undefined,
  addon: undefined,
  className: undefined,
  id: undefined
};

export default InputWrapper;
