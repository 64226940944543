import produce from 'immer';
import _ from 'lodash';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../../utils/browserStorage';
import { tempBillingItemInfoStorageKey } from '../../../utils/constants/browserStorageConstants';
import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../../utils/constants/tableConstants';
import moshiConfig from '../../../utils/moshiConfig';
import {
  setFilterToStoreDraft,
  setFiltersToStoreDraft
} from '../../../utils/redux/listUtils';

import {
  GET_BILL_LIST_SUCCESS,
  GET_BILL_LIST_IN_PROGRESS,
  GET_BILL_LIST_FAILURE,
  SET_BILL_LIST_ORDER,
  SET_BILL_LIST_PAGINATION,
  SET_BILL_LIST_FILTER,
  SET_BILL_LIST_FILTERS,
  CLEAR_BILL_LIST_FILTERS,
  CLEAR_BILL_LIST_FILTERS_NO_POLL,
  SET_BILL_LIST_INCLUDE_PARAM,
  CLEAR_BILLING_ITEM_DESCRIPTION
} from './billTypes';

import { LOGOUT } from '../../auth/authReducer';

export const initialState = {
  list: [],
  loadingList: false,
  filter: {
    sortBy: 'issuedAt',
    order: ORDER_DESCENDING
  },
  include: '',
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: _.get(moshiConfig, 'list.pagination.limit', 20),
    page: 1
  }
};

// eslint-disable-next-line default-param-last
const invoiceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_BILL_LIST_SUCCESS:
        const responsePayload = _.getNonEmpty(payload, 'response', payload);
        const bills = _.getNonEmpty(responsePayload, 'data', []);

        const maxResultsRaw = _.get(
          responsePayload,
          'headers.x-total-count',
          draft.pagination.limit
        );
        const maxResults = _.parseInt(maxResultsRaw);

        draft.pagination.resultCount = maxResults;
        draft.pagination.pageCount = _.ceil(
          maxResults / draft.pagination.limit
        );

        draft.loadingList = false;
        draft.list = _.sortBy(bills, 'name');
        break;
      case GET_BILL_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_BILL_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case SET_BILL_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.getNonEmpty(payload, 'order', ORDER_ASCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_BILL_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case SET_BILL_LIST_FILTER:
        setFilterToStoreDraft(draft, payload, initialState.pagination.page);

        break;
      case SET_BILL_LIST_FILTERS:
        const filters = _.getNonEmpty(payload, 'filters', []);

        setFiltersToStoreDraft(draft, filters, initialState.pagination.page);

        break;
      case CLEAR_BILL_LIST_FILTERS:
      case CLEAR_BILL_LIST_FILTERS_NO_POLL:
        draft.filter = initialState.filter;
        break;
      case SET_BILL_LIST_INCLUDE_PARAM:
        draft.include = _.get(payload, 'includeValue');
        break;
      // TODO: Remove once we move away from Formik lib
      case CLEAR_BILLING_ITEM_DESCRIPTION:
        const billId = _.getNonEmpty(payload, 'billId', null);

        if (_.isEmptySafe(billId)) {
          localStorage.removeItem(tempBillingItemInfoStorageKey);
        } else {
          const billingItemInfo = getLocalStorageValue(
            tempBillingItemInfoStorageKey,
            {}
          );

          delete billingItemInfo[billId];

          setLocalStorageValue(tempBillingItemInfoStorageKey, billingItemInfo);
        }
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default invoiceReducer;
