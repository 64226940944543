import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import MoshiLink from '../../button/MoshiLink';
import Visible from '../../layout/Visible';
import { AUTO_SUGGEST_NO_RESULTS } from './MoshiAutoSuggest';

export const MoshiAutoSuggestionsContainer = ({
  containerProps,
  displayShowAllButton,
  onShowAllClick,
  suggestions,
  children
}) => {
  const { t: translate } = useTranslation();

  const noResults =
    suggestions.length === 1 &&
    _.some(suggestions, { id: AUTO_SUGGEST_NO_RESULTS });

  return (
    <div {...containerProps}>
      <div className={'autosuggest-search-results-container'}>{children}</div>
      <Visible visible={displayShowAllButton && !noResults}>
        <div className={'autosuggest-show-all-container'}>
          <MoshiLink onClick={onShowAllClick}>
            {translate('common:showAll')}
          </MoshiLink>
        </div>
      </Visible>
    </div>
  );
};

MoshiAutoSuggestionsContainer.propTypes = {
  containerProps: PropTypes.shape(),
  children: PropTypes.node,
  displayShowAllButton: PropTypes.bool,
  onShowAllClick: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape)
};

MoshiAutoSuggestionsContainer.defaultProps = {
  containerProps: {},
  displayShowAllButton: false,
  children: undefined,
  onShowAllClick: _.noop,
  suggestions: []
};

export default MoshiAutoSuggestionsContainer;
