class PersonModel {
  constructor(id) {
    this.id = id;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phoneNumber = '';
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export default PersonModel;
