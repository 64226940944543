export default {
  title: 'Employees',
  employee: 'Employee',
  selectEmployee: 'Select employee',
  profileTitle: 'User profile',
  detailTitle: 'Edit user',
  profile: 'Account',
  subscription: 'Subscription',
  addUser: 'Create user',
  creatingUser: 'Creating user',
  avatar: 'Avatar image',
  security: 'Security',
  changePassword: 'Change password',
  removeUserTitle: 'Remove employee',
  removeUserMessage: 'Remove employee <0>{{ userFullName }}</0>?',
  removeUserWarning:
    '<0>Warning:</0> if employee has future calendar events, reassign them first to another employee.',
  resendVerificationEmail: 'Resend verification email',
  resendConfirmation: 'New verification email was sent to: {{ email }}.',
  list: {
    title: 'List of employees',
    addUser: 'Add employee',
    filters: 'Filters:',
    sortBy: 'Order by:',
    roleFilter: 'Role: {{ roleName }}',
    locationFilter: 'Location: {{ locationName }}',
    role: 'Role'
  },
  roles: {
    title: 'User rights',
    add: '+ Add role',
    greetingText:
      'Depending on the assigned role, employees can access different portions of the {{ organizationName }} web dashboard.'
  },
  createPreview: {
    message: 'An email with further instructions will be sent to: {{ email }}',
    summary: 'Summary',
    summaryMessage: 'User with the following data will be created:'
  },
  validation: {
    userRightsLength: 'User should have at least 1 user right',
    duplicatedUserRight: 'There is a duplicated user right in the list',
    userNotActivated: 'Registration not complete',
    pendingEmailConfirmation: 'Email confirmation pending'
  },
  changePasswordModal: {
    changeSuccess: 'Password change successful',
    changeSuccessText:
      'You have successfully changed your password, use it next time you login!',
    currentPassword: 'Current password',
    newPassword: 'New password',
    retypeNewPassword: 'Retype new password',
    strengthLabel: 'Strength: <0>{{ strength }}</0>',
    strengths: {
      veryLow: 'Very low',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      veryHigh: 'Very high'
    }
  },
  preferences: {
    title: 'Preferences',
    language: 'Language',
    regionalFormatting: 'Regional formatting'
  },
  fiscalizationSection: {
    personalTaxNumber: 'Personal tax number',
    displayName: 'Name (as displayed on invoices)'
  },
  role: {
    title: 'Role',
    isOrganizationAdmin: 'Organization admin',
    hasCalendar: 'Has own calendar',
    locationPatientAccess: 'Restrict patient access based on location',
    locationPatientAccessTooltip:
      'When enabled, the user will only have access to patients that are assigned to the locations to which the user has been granted access. ' +
      'When disabled, every user has access to all patients, regardless of their assigned location.'
  },
  alerts: {
    userCreated: 'User was successfully created.',
    userUpdated: 'User was successfully updated.',
    userRemoved: 'User was successfully removed.',
    resendEmail: 'Verification email was successfully sent.'
  },
  reassignCalendarEvents: 'Reassign calendar events',
  reassignEvents: 'Reassign events',
  reassignCalendarEventsModal: {
    title: 'Reassign calendar events',
    note:
      'All calendar events (appointments, events and reminders) within defined time period, will be reassigned to the selected employee.',
    submitButton: 'Reassign',
    allEvents: 'All events (including past)',
    onSuccess: 'Calendar items successfully reassigned'
  }
};
