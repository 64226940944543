import LocationAddressModel from './LocationAddressModel';

class LocationModel {
  constructor(name = '', street1 = '', geo = undefined) {
    this.id = '';
    this.name = name;
    this.address = new LocationAddressModel(street1, geo);
  }
}

export default LocationModel;
