import produce from 'immer';
import _ from 'lodash';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../utils/browserStorage';
import {
  billingExportColumnsStorageKey,
  groupBillingItemsStorageKey
} from '../../utils/constants/browserStorageConstants';
import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../utils/constants/tableConstants';
import { mapBillingUnit } from '../../utils/mappers/billing-mappers';
import moshiConfig from '../../utils/moshiConfig';

import {
  ADD_BILLING_UNIT_SUCCESS,
  CLEAR_BILLING_UNIT,
  CLEAR_BILLING_UNIT_INVOICE_NUMBERS,
  GET_BILLING_ITEM_DETAIL_FAILURE,
  GET_BILLING_ITEM_DETAIL_IN_PROGRESS,
  GET_BILLING_ITEM_DETAIL_SUCCESS,
  GET_BILLING_ITEM_LIST_FAILURE,
  GET_BILLING_ITEM_LIST_IN_PROGRESS,
  GET_BILLING_ITEM_LIST_SUCCESS,
  GET_BILLING_UNIT_FAILURE,
  GET_BILLING_UNIT_IN_PROGRESS,
  GET_BILLING_UNIT_INVOICE_NUMBERS_SUCCESS,
  GET_BILLING_UNIT_SUCCESS,
  GET_BILLING_UNITS_FAILURE,
  GET_BILLING_UNITS_IN_PROGRESS,
  GET_BILLING_UNITS_SUCCESS,
  GET_BUSINESS_PREMISE_LIST_FAILURE,
  GET_BUSINESS_PREMISE_LIST_IN_PROGRESS,
  GET_BUSINESS_PREMISE_LIST_SUCCESS,
  GET_TAX_DETAIL_FAILURE,
  GET_TAX_DETAIL_IN_PROGRESS,
  GET_TAX_DETAIL_SUCCESS,
  GET_TAX_LIST_FAILURE,
  GET_TAX_LIST_IN_PROGRESS,
  GET_TAX_LIST_SUCCESS,
  SET_BILLING_ITEM_LIST_FILTER,
  SET_BILLING_ITEM_LIST_ORDER,
  SET_BILLING_ITEM_LIST_PAGINATION,
  SET_INVOICE_KEYWORD_DATA,
  UPDATE_BILLING_UNIT_SUCCESS,
  SET_BILLING_EXPORT_COLUMNS,
  SET_GROUP_BILLING_ITEMS
} from './billingTypes';

import { LOGOUT } from '../auth/authReducer';

import TaxModel from '../../metadata/model/TaxModel';
import BillingItemLocalizedModel from '../../metadata/model/billing/BillingItemLocalizedModel';
import BillingUnitModel from '../../metadata/model/billing/BillingUnitModel';

const initialState = {
  keyWordData: {},
  numbers: {},
  unit: {
    list: [],
    loadingList: false,
    detail: null,
    loadingDetail: false,
    premiseList: [],
    loadingPremiseList: false
  },
  taxes: {
    list: [],
    loadingList: false,
    detail: null,
    loadingDetail: false
  },
  items: {
    list: [],
    loadingList: false,
    detail: null,
    loadingDetail: false,
    pagination: {
      resultCount: 0,
      pageCount: 0,
      limit: _.get(moshiConfig, 'list.pagination.limit', 20),
      page: 1
    },
    filter: { sortBy: 'name', order: ORDER_ASCENDING }
  },
  exportColumns: getLocalStorageValue(billingExportColumnsStorageKey, []),
  groupBillingItems: getLocalStorageValue(groupBillingItemsStorageKey, false)
};

// eslint-disable-next-line default-param-last
const billingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_BILLING_UNITS_IN_PROGRESS:
        draft.unit.loadingList = true;
        break;
      case GET_BILLING_UNITS_SUCCESS:
        const billingUnits = _.get(payload, 'response.data', []);

        draft.unit.loadingList = false;
        draft.unit.list = _.sortBy(billingUnits.map(mapBillingUnit), 'name');
        break;
      case GET_BILLING_UNITS_FAILURE:
        draft.unit.loadingList = false;
        break;
      case GET_BILLING_UNIT_IN_PROGRESS:
        draft.unit.loadingDetail = true;
        break;
      case GET_BILLING_UNIT_SUCCESS:
      case ADD_BILLING_UNIT_SUCCESS:
      case UPDATE_BILLING_UNIT_SUCCESS:
        const billingUnit = BillingUnitModel.fromDto(
          _.get(payload, 'response.data', {})
        );

        draft.unit.loadingDetail = false;
        draft.unit.detail = mapBillingUnit(billingUnit);
        break;
      case GET_BILLING_UNIT_FAILURE:
        draft.unit.loadingDetail = false;
        draft.unit.detail = null;
        break;
      case CLEAR_BILLING_UNIT:
        draft.unit.detail = null;
        break;
      case GET_BUSINESS_PREMISE_LIST_IN_PROGRESS:
        draft.unit.loadingPremiseList = true;
        break;
      case GET_BUSINESS_PREMISE_LIST_SUCCESS:
        const businessPremiseList = _.get(payload, 'response.data', []);

        draft.unit.loadingPremiseList = false;
        draft.unit.premiseList = businessPremiseList;
        break;
      case GET_BUSINESS_PREMISE_LIST_FAILURE:
        draft.unit.loadingPremiseList = false;
        break;
      case GET_TAX_LIST_IN_PROGRESS:
        draft.taxes.loadingList = true;
        break;
      case GET_TAX_LIST_SUCCESS:
        // TODO: Implement logic that will invalid tax rates
        const taxes = _.nestedListAssign(
          _.get(payload, 'response.data', []),
          TaxModel
        );

        draft.taxes.loadingList = false;
        draft.taxes.list = _.orderBy(
          taxes,
          'createdAtTimestamp',
          ORDER_DESCENDING
        );
        break;
      case GET_TAX_LIST_FAILURE:
        draft.taxes.loadingList = false;
        break;
      case GET_TAX_DETAIL_IN_PROGRESS:
        draft.taxes.loadingDetail = true;
        break;
      case GET_TAX_DETAIL_SUCCESS:
        const tax = _.nestedAssign(
          TaxModel,
          _.get(payload, 'response.data', {})
        );

        draft.taxes.loadingDetail = false;
        draft.taxes.detail = tax;
        break;
      case GET_TAX_DETAIL_FAILURE:
        draft.taxes.loadingDetail = false;
        break;
      case GET_BILLING_ITEM_LIST_IN_PROGRESS:
        draft.items.loadingList = true;
        break;
      case GET_BILLING_ITEM_LIST_SUCCESS:
        const billingItems = _.nestedListAssign(
          _.get(payload, 'response.data', []),
          BillingItemLocalizedModel
        );

        const maxItemResultsRaw = _.get(
          payload,
          'response.headers.x-total-count',
          draft.items.pagination.limit
        );
        const itemMaxResults = _.parseInt(maxItemResultsRaw);

        draft.items.loadingList = false;
        draft.items.list = _.orderBy(
          billingItems,
          'createdAtTimestamp',
          ORDER_DESCENDING
        );
        draft.items.pagination.resultCount = itemMaxResults;
        draft.items.pagination.pageCount = _.ceil(
          itemMaxResults / draft.items.pagination.limit
        );
        draft.items.list = _.sortBy(billingItems, 'name');
        break;
      case GET_BILLING_ITEM_LIST_FAILURE:
        draft.items.loadingList = false;
        break;
      case SET_BILLING_ITEM_LIST_FILTER:
        const itemFilterName = _.getNonEmpty(payload, 'filterName', null);
        const itemFilterValue = _.get(payload, 'filterValue', '');

        if (!_.isEmpty(itemFilterName) || !_.isEmpty(itemFilterValue)) {
          draft.items.filter[itemFilterName] = itemFilterValue;
          draft.items.pagination.page = initialState.items.pagination.page;
        }
        break;
      case SET_BILLING_ITEM_LIST_ORDER:
        draft.items.filter.sortBy = _.get(
          payload,
          'sortBy',
          initialState.items.filter.sortBy
        );
        draft.items.filter.order = _.get(
          payload,
          'order',
          initialState.items.filter.order
        );
        break;
      case GET_BILLING_ITEM_DETAIL_IN_PROGRESS:
        draft.items.loadingDetail = true;
        break;
      case GET_BILLING_ITEM_DETAIL_SUCCESS:
        const item = _.get(payload, 'response.data', {});

        // BE returns enabled prop only if its set to true
        const itemWithStatus = _.set(
          item,
          'enabled',
          _.get(item, 'enabled', false)
        );
        const billingItem = _.nestedAssign(
          BillingItemLocalizedModel,
          itemWithStatus
        );

        draft.items.loadingDetail = false;
        draft.items.detail = billingItem;
        break;
      case GET_BILLING_ITEM_DETAIL_FAILURE:
        draft.items.loadingDetail = false;
        break;
      case SET_BILLING_ITEM_LIST_PAGINATION:
        const itemPaginationName = _.getNonEmpty(
          payload,
          'paginationName',
          null
        );
        const itemPaginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(itemPaginationName) || !_.isEmpty(itemPaginationValue)) {
          draft.items.pagination[itemPaginationName] = itemPaginationValue;
        }
        break;
      case GET_BILLING_UNIT_INVOICE_NUMBERS_SUCCESS:
        const invoiceNumbers = _.get(payload, 'response.data', {});

        draft.numbers = invoiceNumbers;
        break;
      case CLEAR_BILLING_UNIT_INVOICE_NUMBERS:
        draft.numbers = {};
        break;
      case SET_INVOICE_KEYWORD_DATA:
        const keyWordData = _.get(payload, 'keyWordData', {});

        draft.keyWordData = keyWordData;
        break;
      case SET_BILLING_EXPORT_COLUMNS:
        const columns = _.getNonEmpty(payload, 'columns', []);

        draft.exportColumns = columns;

        setLocalStorageValue(
          billingExportColumnsStorageKey,
          draft.exportColumns
        );
        break;
      case SET_GROUP_BILLING_ITEMS:
        const value = _.getNonEmpty(payload, 'value', false);

        draft.groupBillingItems = value;

        setLocalStorageValue(
          groupBillingItemsStorageKey,
          draft.groupBillingItems
        );
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default billingReducer;
