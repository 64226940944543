export default {
  id: 'diagnosis/DESCRIPTION/v1',
  formSchema: {
    title: 'encounter:widget.diagnosis.title',
    type: 'object',
    required: ['id001'],
    properties: {
      id001: {
        type: 'string',
        title: 'encounter:widget.diagnosis.title',
        minLength: 1
      }
    }
  },
  uiSchema: {
    id001: { 'ui:widget': 'customCKEditorWidget' },
    'x-customClass': 'problem-description-widget'
  }
};
