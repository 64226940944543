import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DELETED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_ISSUED,
  INVOICE_STATUS_OVERDUE,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_PARTIALLY_USED,
  INVOICE_STATUS_UNPAID,
  INVOICE_STATUS_USED
} from '../billing';

export const BILL_STATUS_ENUM_CONFIG = {
  default: {
    id: INVOICE_STATUS_ISSUED,
    name: `billing:invoices.status.${INVOICE_STATUS_ISSUED}`,
    icon: {
      className: 'icon-size-md icon-color-info',
      name: 'disc'
    }
  },
  options: [
    {
      id: INVOICE_STATUS_PAID,
      name: `billing:invoices.status.${INVOICE_STATUS_PAID}`,
      icon: {
        className: 'icon-size-md icon-color-success',
        name: 'ok-circle'
      }
    },
    {
      id: INVOICE_STATUS_USED,
      name: `billing:invoices.status.${INVOICE_STATUS_USED}`,
      icon: {
        className: 'icon-size-md icon-color-success',
        name: 'ok-circle'
      }
    },
    {
      id: INVOICE_STATUS_PARTIALLY_PAID,
      name: `billing:invoices.status.${INVOICE_STATUS_PARTIALLY_PAID}`,
      icon: {
        className: 'icon-size-md icon-color-warning',
        name: 'disc'
      }
    },
    {
      id: INVOICE_STATUS_PARTIALLY_USED,
      name: `billing:invoices.status.${INVOICE_STATUS_PARTIALLY_USED}`,
      icon: {
        className: 'icon-size-md icon-color-warning',
        name: 'disc'
      }
    },
    {
      id: INVOICE_STATUS_OVERDUE,
      name: `billing:invoices.status.${INVOICE_STATUS_OVERDUE}`,
      icon: {
        className: 'icon-size-md icon-color-danger',
        name: 'error'
      }
    },
    {
      id: INVOICE_STATUS_UNPAID,
      name: `billing:invoices.status.${INVOICE_STATUS_UNPAID}`,
      icon: {
        className: 'icon-size-md icon-color-danger',
        name: 'remove'
      }
    },
    {
      id: INVOICE_STATUS_DELETED,
      name: `billing:invoices.status.${INVOICE_STATUS_DELETED}`,
      icon: {
        className: 'icon-size-md icon-color-danger',
        name: 'cancel'
      }
    },
    {
      id: INVOICE_STATUS_CANCELED,
      name: `billing:invoices.status.${INVOICE_STATUS_CANCELED}`,
      icon: {
        className: 'icon-size-md icon-color-gray',
        name: 'not-allowed'
      }
    },
    {
      id: INVOICE_STATUS_DRAFT,
      name: `billing:invoices.status.${INVOICE_STATUS_DRAFT}`,
      icon: {
        className: 'icon-size-md icon-color-gray',
        name: 'remove'
      }
    }
  ]
};

export const BILLING_ITEM_LIST_FILTER_CATEGORY = 'categoryID';
export const BILLING_ITEM_LIST_FILTER_SEARCH = 'q';

export const FURS_SETTINGS_SECTION_CARD_CERT = 'certificate';
export const FURS_SETTINGS_SECTION_CARD_PREMISES = 'businessPremises';
export const FURS_SETTINGS_SECTION_CARD_DEVICES = 'electronicDevices';
export const FURS_SETTINGS_SECTION_CARD_ACT = 'act';
export const FURS_SETTINGS_SECTION_CARD_ACTIVATE = 'activate';
export const FURS_SETTINGS_SECTION_NUMBERING = 'numbering';

export const FURS_SETTINGS_NUMBERING_BY_DEVICE = 'device';
export const FURS_SETTINGS_NUMBERING_BY_PREMISE = 'premise';
