import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../../configureStore';
import { showMissingOrganizationIdError } from '../../../utils/alert';

import { EMPTY_ACTION_TYPE } from '../../common/actionTypes';

import {
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS,
  GET_QUESTIONNAIRE_SUBMISSION_DATA,
  GET_PATIENT_QUESTIONNAIRE_DATA,
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA,
  CLEAR_QUESTIONNAIRE_SUBMISSION_DATA,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_SUCCESS
} from './questionnaireSubmissionTypes';

export const getEncounterQuestionnaireSubmissions = (
  encounterId,
  actionType = GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS,
  onSuccess = undefined
) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/encounters/${encounterId}/questionnaires`
    ),
    onSuccess
  });
};

export const getEncounterQuestionnaireSubmissionDataList = (encounterId) => (
  dispatch
) => {
  const onListFetched = (notUsed, response) => {
    const questionnaireSubmissionList = _.getNonEmpty(response, 'data', []);

    const promiseList = questionnaireSubmissionList.map(
      (questionnaireSubmission) =>
        dispatch(
          getQuestionnaireSubmissionData(
            encounterId,
            questionnaireSubmission.submissionID
          )
        )
    );

    if (_.isEmpty(promiseList)) {
      return dispatch({
        type: GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_SUCCESS,
        data: []
      });
    }

    return Promise.all(promiseList).then((data) => {
      dispatch({
        type: GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_SUCCESS,
        data
      });
    });
  };

  return dispatch(
    getEncounterQuestionnaireSubmissions(
      encounterId,
      EMPTY_ACTION_TYPE,
      onListFetched
    )
  );
};

export const getQuestionnaireSubmissionData = (encounterId, submissionId) => (
  dispatch
) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_QUESTIONNAIRE_SUBMISSION_DATA,
    apiCall: axios.get(
      `/organizations/${organizationId}/encounters/${encounterId}/questionnaires/${submissionId}`
    )
  });
};

export const updateQuestionnaireSubmissionData = (
  encounterId,
  submissionId,
  data
) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: UPDATE_QUESTIONNAIRE_SUBMISSION_DATA,
    apiCall: axios.put(
      `/organizations/${organizationId}/encounters/${encounterId}/questionnaires/${submissionId}`,
      {
        data: JSON.stringify(data)
      }
    )
  });
};

export const getPatientQuestionnaireSubmissionData = (
  patientId,
  submissionId
) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_PATIENT_QUESTIONNAIRE_DATA,
    apiCall: axios.get(
      `/organizations/${organizationId}/patients/${patientId}/questionnaires/${submissionId}`
    )
  });
};

export const clearQuestionnaireSubmissionData = () => (dispatch) => {
  dispatch({
    type: CLEAR_QUESTIONNAIRE_SUBMISSION_DATA
  });
};
