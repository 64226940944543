import _ from 'lodash';

import logger from '../logger';

const storeNotInitializedYet = () => {
  logger.devError('Redux store not initialized yet');

  return null;
};

export const initializeStoreUtils = (dispatch, getState) => {
  if (_.isFunction(dispatch)) {
    safeGlobalDispatch = dispatch;
  }

  if (_.isFunction(getState)) {
    safeGlobalGetState = getState;
  }
};

export let safeGlobalDispatch = storeNotInitializedYet;
export let safeGlobalGetState = storeNotInitializedYet;
