import axios from 'axios';
import _ from 'lodash';

import { storeDispatch, storeGetState } from '../../../configureStore';
import { showAlertSuccess } from '../../../utils/alert';
import {
  getFormTemplateDetailUrl,
  getFormTemplateDetailWithVersionUrl,
  getFormTemplateUrl
} from '../../../utils/api/apiUrlUtils';
import { generateEhrId } from '../../../utils/gen';
import { getFormPayload } from '../../../utils/mappers/cleaned/document-mappers';

import {
  START_FORM_TEMPLATE_LIST_POLLING,
  STOP_FORM_TEMPLATE_LIST_POLLING,
  REMOVE_FORM_TEMPLATE,
  ADD_FORM_TEMPLATE,
  UPDATE_FORM_TEMPLATE,
  GET_FORM_TEMPLATE_DETAIL,
  INCREMENT_FORM_FIELD_COUNT,
  RESET_FORM_FIELD_COUNT,
  CLEAR_FORM_TEMPLATE_DETAIL
} from './formTemplateTypes';

import { selectFormDetailFieldCounter } from './formTemplateSelectors';

export const startPollingFormTemplatesList = () => (dispatch) =>
  dispatch({
    type: START_FORM_TEMPLATE_LIST_POLLING
  });

export const stopPollingFormTemplatesList = () => (dispatch) =>
  dispatch({
    type: STOP_FORM_TEMPLATE_LIST_POLLING
  });

export const getFormTemplateDetail = (
  formId,
  version = undefined,
  onSuccess = _.noop
) => (dispatch, getState) =>
  dispatch({
    type: GET_FORM_TEMPLATE_DETAIL,
    apiCall: axios.get(
      getFormTemplateDetailWithVersionUrl(getState, formId, version)
    ),
    onSuccess: (unused, response) => {
      onSuccess(response);
    }
  });

export const getNewFieldId = () => {
  storeDispatch({
    type: INCREMENT_FORM_FIELD_COUNT
  });

  const newFieldCount = selectFormDetailFieldCounter(storeGetState());

  return generateEhrId(newFieldCount);
};

export const resetFieldCount = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_FIELD_COUNT
  });
};

export const addOrUpdateFormTemplate = (
  formData,
  isPatientTemplate = false,
  patientId = null,
  disableAlert = false,
  onSuccess = _.noop
) => (dispatch) => {
  if (_.isEmptySafe(formData, 'id')) {
    return dispatch(
      addFormTemplate(
        formData,
        disableAlert,
        onSuccess,
        isPatientTemplate,
        patientId
      )
    );
  } else {
    return dispatch(
      updateFormTemplate(
        formData.id,
        formData,
        onSuccess,
        isPatientTemplate,
        patientId
      )
    );
  }
};

export const addFormTemplate = (
  formData,
  disableAlert,
  onSuccess = _.noop,
  isPatientTemplate = false,
  patientId = null
) => (dispatch, getState) => {
  const formTemplateUrl = getFormTemplateUrl(getState);
  const payload = getFormPayload(formData);

  if (isPatientTemplate && !_.isEmptySafe(patientId)) {
    payload.patientID = patientId;
  }

  return dispatch({
    type: ADD_FORM_TEMPLATE,
    apiCall: axios.post(formTemplateUrl, payload),
    onSuccess: (unused, response) => {
      onSuccess(response);
      !disableAlert && showAlertSuccess('document:alert.templateCreated');
    }
  });
};

export const updateFormTemplate = (
  formId,
  formData,
  onSuccess = _.noop,
  isPatientTemplate = false,
  patientId = null
) => (dispatch, getState) => {
  const formTemplateDetailUrl = getFormTemplateDetailUrl(getState, formId);
  const payload = getFormPayload(formData);

  if (isPatientTemplate && !_.isEmptySafe(patientId)) {
    payload.patientID = patientId;
  }

  return dispatch({
    type: UPDATE_FORM_TEMPLATE,
    apiCall: axios.put(formTemplateDetailUrl, payload),
    onSuccess: () => {
      onSuccess();
      showAlertSuccess('document:alert.templateUpdated');
    }
  });
};

export const removeFormTemplate = (formId) => (dispatch, getState) => {
  const formTemplateDetailUrl = getFormTemplateDetailUrl(getState, formId);

  return dispatch({
    type: REMOVE_FORM_TEMPLATE,
    apiCall: axios.delete(formTemplateDetailUrl),
    onSuccess: () => {
      showAlertSuccess('document:alert.templateRemoved');
    }
  });
};

export const clearFormTemplateDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_FORM_TEMPLATE_DETAIL
  });
