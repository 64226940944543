/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { ModalBody } from 'reactstrap';

import PropTypes from 'prop-types';

const ModalBodyWrapper = ({ children, className, id }) => (
  <ModalBody className={className} id={id}>
    {children}
  </ModalBody>
);

ModalBodyWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
};

ModalBodyWrapper.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined
};

export default ModalBodyWrapper;
