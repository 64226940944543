export default {
  export: {
    title: 'Izvoz podatkov',
    startExport: 'Začnite izvoz',
    ready: {
      info: 'Tukaj lahko izvozite vse svoje podatke v arhiv.',
      description:
        'Ko izberete "$t(organizationSettings:export.startExport)", vam bomo začeli pripravljati podatke. ' +
        'Naslednji dan boste prejeli povezavo do datotek na administratorjev elektronski naslov.'
    },
    pending: {
      info: 'Hmm, vaš izvoz že pripravljamo.',
      description:
        'Jutri ne pozabite preveriti e-pošto, kjer boste našli povezavo do datotek.'
    },
    validation: {
      alreadyStarted: 'Vrnite se jutri, če želite ustvariti nov izvoz.'
    }
  }
};
