import { createActionTypeArray } from '../common/actionTypes';

export const GET_BOOKING_TEMPLATES = 'booking/GET_BOOKING_TEMPLATES';
export const GET_BOOKING_SCHEDULES = 'booking/GET_BOOKING_SCHEDULES';
export const GET_BOOKING_TERMS_AND_CONDITIONS =
  'booking/GET_BOOKING_TERMS_AND_CONDITIONS';
export const ADD_BOOKING_TEMPLATE = 'booking/ADD_BOOKING_TEMPLATE';
export const ADD_BOOKING_SCHEDULE = 'booking/ADD_BOOKING_SCHEDULE';
export const UPDATE_BOOKING_TEMPLATE = 'booking/UPDATE_BOOKING_TEMPLATE';
export const REMOVE_BOOKING_TEMPLATE = 'booking/REMOVE_BOOKING_TEMPLATE';
export const GET_BOOKING_TEMPLATE_DETAIL =
  'booking/GET_BOOKING_TEMPLATE_DETAIL';
export const TOGGLE_BOOKING_TEMPLATE_STATUS =
  'booking/TOGGLE_BOOKING_TEMPLATE_STATUS';
export const CLEAR_BOOKING_TEMPLATE_DETAIL =
  'booking/CLEAR_BOOKING_TEMPLATE_DETAIL';
export const UPDATE_BOOKING_TEMPLATE_DRAFT_DETAIL =
  'booking/UPDATE_BOOKING_TEMPLATE_DRAFT_DETAIL';

export const START_CALENDAR_BOOKING_SCHEDULES_POLLING =
  'working-hours/START_CALENDAR_BOOKING_SCHEDULES_POLLING';
export const STOP_CALENDAR_BOOKING_SCHEDULES_POLLING =
  'working-hours/STOP_CALENDAR_BOOKING_SCHEDULES_POLLING';

export const [
  GET_BOOKING_TEMPLATES_IN_PROGRESS,
  GET_BOOKING_TEMPLATES_SUCCESS,
  GET_BOOKING_TEMPLATES_FAILURE
] = createActionTypeArray(GET_BOOKING_TEMPLATES);

export const [
  GET_BOOKING_SCHEDULES_IN_PROGRESS,
  GET_BOOKING_SCHEDULES_SUCCESS,
  GET_BOOKING_SCHEDULES_FAILURE
] = createActionTypeArray(GET_BOOKING_SCHEDULES);

export const [
  GET_BOOKING_TERMS_AND_CONDITIONS_IN_PROGRESS,
  GET_BOOKING_TERMS_AND_CONDITIONS_SUCCESS,
  GET_BOOKING_TERMS_AND_CONDITIONS_FAILURE
] = createActionTypeArray(GET_BOOKING_TERMS_AND_CONDITIONS);

export const [
  ADD_BOOKING_TEMPLATE_IN_PROGRESS,
  ADD_BOOKING_TEMPLATE_SUCCESS,
  ADD_BOOKING_TEMPLATE_FAILURE
] = createActionTypeArray(ADD_BOOKING_TEMPLATE);

export const [
  ADD_BOOKING_SCHEDULE_IN_PROGRESS,
  ADD_BOOKING_SCHEDULE_SUCCESS,
  ADD_BOOKING_SCHEDULE_FAILURE
] = createActionTypeArray(ADD_BOOKING_SCHEDULE);

export const [
  UPDATE_BOOKING_TEMPLATE_IN_PROGRESS,
  UPDATE_BOOKING_TEMPLATE_SUCCESS,
  UPDATE_BOOKING_TEMPLATE_FAILURE
] = createActionTypeArray(UPDATE_BOOKING_TEMPLATE);

export const [
  REMOVE_BOOKING_TEMPLATE_IN_PROGRESS,
  REMOVE_BOOKING_TEMPLATE_SUCCESS,
  REMOVE_BOOKING_TEMPLATE_FAILURE
] = createActionTypeArray(REMOVE_BOOKING_TEMPLATE);

export const [
  GET_BOOKING_TEMPLATE_DETAIL_IN_PROGRESS,
  GET_BOOKING_TEMPLATE_DETAIL_SUCCESS,
  GET_BOOKING_TEMPLATE_DETAIL_FAILURE
] = createActionTypeArray(GET_BOOKING_TEMPLATE_DETAIL);

export const [
  TOGGLE_BOOKING_TEMPLATE_STATUS_IN_PROGRESS,
  TOGGLE_BOOKING_TEMPLATE_STATUS_SUCCESS,
  TOGGLE_BOOKING_TEMPLATE_STATUS_FAILURE
] = createActionTypeArray(TOGGLE_BOOKING_TEMPLATE_STATUS);
