export const PAYMENT_TYPE_CASH = 'cash';
export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_BANK = 'bank';
export const PAYMENT_TYPE_ONLINE = 'online';
export const PAYMENT_TYPE_PAYPAL = 'paypal';
export const PAYMENT_TYPE_COUPON = 'coupon';
export const PAYMENT_TYPE_OTHER = 'other';
export const PAYMENT_TYPE_ADVANCE = 'advance';

export const PAYMENT_TYPE_SOURCE_NAME_BUILTIN = 'builtin';
export const PAYMENT_TYPE_SOURCE_NAME_CUSTOM = 'custom';

export const PAYMENT_TYPE_LIST_VISIBLE = 'visible';
export const PAYMENT_TYPE_LIST_MORE = 'more';
export const PAYMENT_TYPE_LIST_INACTIVE = 'inactive';

export const BILLING_LOCALES_LIST_ACTIVE = 'active';
export const BILLING_LOCALES_LIST_INACTIVE = 'inactive';

export const CASH_REGISTER_LOG_ID_COUNTER_NAME_PREFIX = 'CASH_REGISTER_LOG_ID';
export const CASH_REGISTER_LOG_NUMBER_COUNTER_NAME_PREFIX =
  'CASH_REGISTER_LOG_NUMBER';

export const CASH_REGISTER_MANDATORY_PAYMENT_TYPES = {
  PAYMENT_TYPE_CASH
};

export const BUILTIN_PAYMENT_TYPES = [
  PAYMENT_TYPE_ADVANCE,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_BANK,
  PAYMENT_TYPE_ONLINE,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_COUPON,
  PAYMENT_TYPE_OTHER
];
