export default {
  required: 'Required',
  missingOrIncorrect: 'The value for {{field}} is missing or incorrect',
  tooShort: '{{field}} is too short',
  tooLong: '{{field}} is too long',
  tooShortMin: 'Min characters: {{min}}',
  tooLongMax: 'Max characters: {{max}}',
  tooLow: '{{field}} is too low',
  tooHigh: '{{field}} is too high',
  maxValue: 'Maximum number reached',
  NaN: '{{filed}} is not of numeric type',
  notAnInteger: '{{filed}} does not allow decimals',
  notPositive: '{{filed}} allows only a positive number',
  notNegative: '{{filed}} allows only a negative number',
  maxDecimalPlaces: 'Max decimal places: {{maxDecimalPlaces}}',
  notAnEmail: 'Please enter a valid email',
  notANumber: 'Please enter a valid number',
  notAPhoneNumber:
    '{{field}} is not a valid phone number. Use e.g. +386********',
  passwordsMustMatch: 'Passwords must match',
  devicePairingCodeInvalid: 'The {{field}} is not a valid code',
  devicePairingCodeNotCorrectLength: 'The {{field}} does not have 6 digits',
  noSpace: 'Field {{filed}} does not allow spaces',
  duplicateValue: 'Duplicate value',
  numberOnly: 'Only numeric characters are allowed',
  numberDashOnly: 'Only numeric characters and dashes are allowed',
  letterNumberDashOnly:
    'Allowed characters are letters (without special characters), numbers and dashes',
  letterNumberOnly: 'Allowed characters are letters and numbers',
  billNumberExists: 'Bill number already exists',
  higherThanPrepayment: 'Value higher than the remaining prepayment',
  endGreaterOrSameThanStart: 'End time is the same or before the start time',
  phoneNotValid: 'Phone number not valid',
  file: {
    maxSizeLimit: 'Max individual file size is  {{ maxFileSize }} Mb',
    fileTooLarge: 'File is too large'
  },
  passwordAlphaNumeric:
    'Password should be either: <br />- 16 characters long  <br />- 8 characters long containing at least one upper case, one lowercase and one digit',
  urlNotValid: 'Url is not valid',
  document: {
    signatureOnly: 'Document must not contain only a signature',
    fieldLength: 'Document should have at least 1 question',
    optionLength: 'Field should have at least 2 options'
  },
  atLeastOneLocationSelected: 'At least one location must be selected',
  onlineBooking: {
    durationDividableBy5:
      'The number should be a multiple of 5. Example: 5, 10, 15, 20, 25, etc.',
    welcomeMessageTooLong: 'Max length is 100 characters',
    welcomeDetailsTooLong: 'Max length is 2000 characters'
  }
};
