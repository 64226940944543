import _ from 'lodash';

import { useStateWithCallback } from '../useStateWithCallback';

/**
 * Prepares props for dropdown
 * @param {object} params contains list of overridable params
 * | @param {string} id id of dropdown
 * | @param {function} onClose on close handler function
 * | @param {string} dropdownName name of dropdown
 * | @param {string} className overridable className
 *
 * @returns {object}
 * | @returns {object} dropdownProps: props for dropdown
 */
export const useDropdownProps = ({
  id,
  onClose,
  dropdownName,
  disabled,
  className
} = {}) => {
  const [isOpen, setIsOpen] = useStateWithCallback(false);

  const toggle = () => {
    setIsOpen(
      (prevState) => !prevState,
      (isOpenNew) => {
        if (isOpenNew || !_.isFunction(onClose)) return;

        onClose();
      }
    );
  };

  let fallbackId;

  if (_.isString(dropdownName) && !_.isEmptySafe(dropdownName)) {
    fallbackId = _.kebabCase(dropdownName);
  }

  const safeId = id || fallbackId;
  const safeClassName = className || 'moshi-rs-dropdown';

  return {
    dropdownProps: {
      id: safeId,
      className: safeClassName,
      isOpen,
      toggle,
      name: dropdownName,
      disabled
    }
  };
};
