import moment from 'moment';

import {
  selectActiveTherapyListBeforeDate,
  selectPastTherapyListBeforeDate,
  selectPastAndActiveTherapyListBeforeDate
} from '../../redux/therapy/therapySelectors';

export const getQuantityScale = (translate) => [
  { id: 'ml', name: translate('therapy:quantitySuffix.ml') },
  { id: 'mg', name: translate('therapy:quantitySuffix.mg') },
  { id: 'ie', name: translate('therapy:quantitySuffix.ie') },
  {
    id: 'pills',
    name: translate('therapy:quantitySuffix.pills')
  },
  { id: 'unknown', name: translate('therapy:quantitySuffix.unknown') }
];

export const getFrequencyScale = (translate) => [
  { id: 'hours', name: 'therapy:timeUnit.hours' },
  { id: 'days', name: 'therapy:timeUnit.days' },
  {
    id: 'weeks',
    name: translate('therapy:timeUnit.weeks')
  },
  {
    id: 'months',
    name: translate('therapy:timeUnit.months')
  },
  {
    id: 'years',
    name: translate('therapy:timeUnit.years')
  }
];

export const getDurationScale = (translate) => [
  { id: 'hours', name: translate('therapy:timeUnit.hours') },
  { id: 'days', name: translate('therapy:timeUnit.days') },
  {
    id: 'weeks',
    name: translate('therapy:timeUnit.weeks')
  },
  {
    id: 'months',
    name: translate('therapy:timeUnit.months')
  },
  {
    id: 'years',
    name: translate('therapy:timeUnit.years')
  }
];

export const getTherapyListFilteredBy = (
  store,
  { beforeDate, therapiesToInclude }
) => {
  if (therapiesToInclude === 'past') {
    return selectPastTherapyListBeforeDate(store, beforeDate);
  } else if (therapiesToInclude === 'active') {
    return selectActiveTherapyListBeforeDate(store, beforeDate);
  }

  return selectPastAndActiveTherapyListBeforeDate(store, beforeDate);
};

/**
 * Gets end date of therapy, if therapy is indefinite it looks for stoppedAt, if it's definite it looks for endDate.
 * Falls back to maxDate.
 *
 * @param {Therapy} therapy mapped therapy
 * @param {*} maxDate date considered as "now"
 * @returns end date of therapy
 */
export const getTherapyEndDate = (therapy, maxDate) => {
  const date = moment(maxDate);
  const isStoppedIndefinite =
    therapy.indefiniteTherapy && moment(therapy.stoppedAt).isBefore(date);
  const isStoppedDefinite =
    !therapy.indefiniteTherapy && moment(therapy.endDate).isBefore(date);

  if (isStoppedIndefinite) return therapy.stoppedAt;
  if (isStoppedDefinite) return therapy.endDate;

  return date;
};
