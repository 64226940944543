import { push } from 'connected-react-router';
import { generatePath } from 'react-router';

import _ from 'lodash';

import { showAlertInfo } from '../../../../utils/alert';
import {
  BUCKET_NAME_COMPLETED,
  BUCKET_NAME_CONSULTATION
} from '../../../../utils/constants/encounterConstants';
import { asyncPromiseWrapper } from '../../../../utils/gen';
import { getFullName } from '../../../../utils/mappers/common-mappers';

import { EMPTY_ACTION_TYPE } from '../../../common/actionTypes';
import {
  createVisitor,
  getActiveEncounters,
  getAssistRequest,
  getWaitingListEncounter,
  moveEncounterTo
} from '../../../patient/encounter/encounterActions';

import {
  REFRESH_WAITING_LIST_ENCOUNTERS,
  MOVE_WAITING_LIST_ITEM_TO,
  MANUAL_CREATE_WAITING_LIST_ENCOUNTER,
  MANUAL_REMOVE_WAITING_LIST_ENCOUNTER,
  MANUAL_UPDATE_WAITING_LIST_ENCOUNTER,
  MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER,
  MARK_WAITING_LIST_ENCOUNTER_COMMENT_UNREAD,
  MARK_WAITING_LIST_ENCOUNTER_COMMENT_READ,
  MARK_WAITING_LIST_ENCOUNTER_UPDATED,
  MANUAL_REMOVE_ASSIST_REQUEST
} from '../cacheTypes';

import {
  selectWaitingListEncounterById,
  selectWaitingListPatientNameByEncounterId
} from '../../../patient/encounter/encounterSelectors';
import {
  selectCachedWaitingListBucketIdByName,
  selectCachedWaitingListBucketsByName,
  selectCachedWaitingListEncounters
} from '../cacheSelectors';

import { patientEncounterRoute } from '../../../../containers/patient/patientRoutes';

export const refreshWaitingListEncounters = () => ({
  type: REFRESH_WAITING_LIST_ENCOUNTERS
});

export const manualCreateWaitingListEncounter = (encounter) => ({
  type: MANUAL_CREATE_WAITING_LIST_ENCOUNTER,
  encounter
});

export const manualUpdateWaitingListEncounter = (encounter) => ({
  type: MANUAL_UPDATE_WAITING_LIST_ENCOUNTER,
  encounter
});

export const manualRemoveWaitingListEncounter = (encounterId) => ({
  type: MANUAL_REMOVE_WAITING_LIST_ENCOUNTER,
  encounterId
});

export const manualArchiveAllWaitingListEncounter = () => ({
  type: MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER
});

export const markWaitingListEncounterUpdated = (encounterId) => ({
  type: MARK_WAITING_LIST_ENCOUNTER_UPDATED,
  encounterId
});

export const markWaitingListEncounterCommentUnread = (encounterId) => ({
  type: MARK_WAITING_LIST_ENCOUNTER_COMMENT_UNREAD,
  encounterId
});

export const markWaitingListEncounterCommentRead = (encounterId) => ({
  type: MARK_WAITING_LIST_ENCOUNTER_COMMENT_READ,
  encounterId
});

export const manualMoveWaitingListEncounter = (payload) => (
  dispatch,
  getState
) => {
  const { id, fromBucketID, toBucketID, beforeID, afterID } = payload;

  const waitingList = selectCachedWaitingListEncounters(getState());

  const fromBucket = _.findDefault(waitingList, { bucketID: fromBucketID }, {});
  const toBucket = _.findDefault(waitingList, { bucketID: toBucketID }, {});

  const fromBucketEncounters = _.get(fromBucket, 'encounters', []);
  const toBucketEncounters = _.get(toBucket, 'encounters', []);

  const fromIndex = _.findIndex(fromBucketEncounters, { id });

  let toIndex = 0;

  if (!_.isEmpty(beforeID)) {
    const beforeIndex = _.findIndex(toBucketEncounters, { id: beforeID });

    toIndex = beforeIndex;

    if (fromBucketID === toBucketID) {
      if (fromIndex < beforeIndex) {
        toIndex = beforeIndex - 1;
      }
    }
    // eslint-disable-next-line no-negated-condition
  } else if (!_.isEmpty(afterID)) {
    const afterIndex = _.findIndex(toBucketEncounters, { id: afterID });

    toIndex = afterIndex + 1;

    if (fromBucketID === toBucketID) {
      if (fromIndex < afterIndex) {
        toIndex = afterIndex;
      }
    }
  }

  return dispatch({
    type: MOVE_WAITING_LIST_ITEM_TO,
    fromBucketId: fromBucketID,
    toBucketId: toBucketID,
    fromIndex,
    toIndex
  });
};

const fixBrokenBackendPayload = (updatedPayload) => (dispatch, getState) => {
  let fixedPayload = updatedPayload;
  const entityId = fixedPayload.id;
  const payloadAction = _.getNonEmpty(fixedPayload, 'action', null);

  if (payloadAction === 'reopened' && !_.has(payloadAction, 'toBucketID')) {
    const waitingListEncounter = selectWaitingListEncounterById(
      getState(),
      entityId
    );
    const toBucketID = fixedPayload.fromBucketID;

    fixedPayload = {
      ...fixedPayload,
      fromBucketID: waitingListEncounter.bucketID,
      toBucketID
    };
  } else if (
    payloadAction === 'closed' &&
    !_.has(payloadAction, 'fromBucketID')
  ) {
    const waitingListEncounter = selectWaitingListEncounterById(
      getState(),
      entityId
    );

    const bucket = selectCachedWaitingListBucketsByName(
      getState(),
      BUCKET_NAME_COMPLETED
    );

    fixedPayload = {
      ...fixedPayload,
      fromBucketID: waitingListEncounter.bucketID,
      toBucketID: bucket.id
    };
  }

  return fixedPayload;
};

export const handleEncounterWebSocketPayload = (payload) => (
  dispatch,
  getState
) => {
  const entityId = payload.id;
  const payloadAction = _.getNonEmpty(payload, 'action', null);
  let patientName;
  let updatedPayload = payload;

  switch (payloadAction) {
    case 'allArchived':
      dispatch(manualArchiveAllWaitingListEncounter());
      showAlertInfo('encounter:waitingList.alert.allEncountersArchived');
      break;
    case 'created':
      dispatch(getWaitingListEncounter(entityId, EMPTY_ACTION_TYPE)).then(
        (encounter) => {
          patientName = getFullName(_.getNonEmpty(encounter, 'patient', {}));

          dispatch(manualCreateWaitingListEncounter(encounter));
          dispatch(markWaitingListEncounterUpdated(entityId));
          if (!_.isEmpty(patientName)) {
            showAlertInfo('encounter:waitingList.alert.encounterCreated', {
              translationParams: { patientName }
            });
          }
        }
      );
      break;
    case 'updated':
      dispatch(getWaitingListEncounter(entityId, EMPTY_ACTION_TYPE)).then(
        (encounter) => {
          dispatch(manualUpdateWaitingListEncounter(encounter));
          dispatch(markWaitingListEncounterUpdated(entityId));

          patientName = selectWaitingListPatientNameByEncounterId(
            getState(),
            entityId
          );
          if (!_.isEmpty(patientName)) {
            showAlertInfo('encounter:waitingList.alert.encounterUpdated', {
              translationParams: { patientName }
            });
          }
        }
      );
      break;
    case 'moved':
    case 'closed':
    case 'reopened':
      // TODO: WORKAROUND until https://github.com/iryonetwork/moshi-backend/issues/461 is fixed
      updatedPayload = dispatch(fixBrokenBackendPayload(updatedPayload));

      dispatch(manualMoveWaitingListEncounter(updatedPayload));
      dispatch(markWaitingListEncounterUpdated(entityId));

      if (_.includes(['closed', 'reopened'], payloadAction)) {
        dispatch(getWaitingListEncounter(entityId, EMPTY_ACTION_TYPE)).then(
          (encounter) => {
            dispatch(manualUpdateWaitingListEncounter(encounter));
          }
        );
      }

      patientName = selectWaitingListPatientNameByEncounterId(
        getState(),
        entityId
      );
      if (!_.isEmpty(patientName)) {
        showAlertInfo('encounter:waitingList.alert.encounterMoved', {
          translationParams: { patientName }
        });
      }
      break;
    case 'deleted':
      patientName = selectWaitingListPatientNameByEncounterId(
        getState(),
        entityId
      );
      if (!_.isEmpty(patientName)) {
        showAlertInfo('encounter:waitingList.alert.encounterDeleted', {
          translationParams: { patientName }
        });
      }
      dispatch(manualRemoveWaitingListEncounter(entityId));
      break;
    case 'archived':
      patientName = selectWaitingListPatientNameByEncounterId(
        getState(),
        entityId
      );

      if (!_.isEmpty(patientName)) {
        showAlertInfo('encounter:waitingList.alert.encounterArchived', {
          translationParams: { patientName }
        });
      }
      dispatch(manualRemoveWaitingListEncounter(entityId));
      break;
    default:
      break;
  }
};

export const handleEncounterCommentWebSocketPayload = (payload) => (
  dispatch
) => {
  const payloadAction = _.getNonEmpty(payload, 'action', null);

  switch (payloadAction) {
    case 'created':
    case 'updated':
      dispatch(markWaitingListEncounterCommentUnread(payload.encounterID));
      break;
    default:
      break;
  }
};

export const manualRemoveAssistRequest = (requestId) => ({
  type: MANUAL_REMOVE_ASSIST_REQUEST,
  requestId
});

export const handleAssistRequestWebsocketPayload = (payload) => (dispatch) => {
  const payloadAction = _.getNonEmpty(payload, 'action', null);

  switch (payloadAction) {
    case 'created':
      dispatch(getAssistRequest(payload.requestID));
      break;
    case 'dismissed':
      dispatch(manualRemoveAssistRequest(payload.requestID));
      break;
    default:
      break;
  }
};

// TODO: Remove all logic once encounter without visitor dashboard can be added
export const addNewVisitorAndMoveItToEncounter = (patientId, payload) => async (
  dispatch,
  getState
) => {
  const toBucketId = selectCachedWaitingListBucketIdByName(
    getState(),
    BUCKET_NAME_CONSULTATION
  );

  const [newEncounter, errCreatedEncounter] = await asyncPromiseWrapper(
    dispatch(createVisitor(payload))
  );

  if (errCreatedEncounter) {
    return;
  }

  const fromBucketId = _.getNonEmpty(newEncounter, 'bucketID');
  const encounterId = _.getNonEmpty(newEncounter, 'id');

  const moveEncounterPayload = [
    encounterId,
    fromBucketId,
    0,
    toBucketId,
    0,
    false,
    { fromBucketId, toBucketId }
  ];

  const [, errMove] = await asyncPromiseWrapper(
    dispatch(moveEncounterTo(...moveEncounterPayload))
  );

  if (errMove) {
    return;
  }

  const [, errDispatch] = await asyncPromiseWrapper(
    dispatch(getActiveEncounters({ patientID: patientId }))
  );

  if (errDispatch) {
    return;
  }

  const activeEncounterRoute = generatePath(patientEncounterRoute, {
    patientId
  });

  setTimeout(() => {
    dispatch(
      push(activeEncounterRoute, { encounterId, encounter: newEncounter })
    );
  }, 1000);
};
