import React, { useRef } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { SimpleDocumentShape } from '../../../../metadata/shapes/DocumentShape';

import ConfirmEntityRemovalModal from '../../../../components/common/modal/ConfirmEntityRemovalModal';
import Table from '../../../../components/common/table/Table';

import { DOCUMENT_TYPE, QUESTIONNAIRE_TYPE } from '../AddDocumentsToFlow';
import {
  getDocumentNameColumn,
  documentVersionColumn,
  getRemoveColumn
} from './detailColumns';

const DocumentsInFlowListComponent = ({
  enabledDocumentIds,
  enabledQuestionnaireIds,
  onRemove,
  items,
  className,
  addedItem
}) => {
  const toggleRemovalVisibility = useRef(_.noop);
  const enabledDocuments = _.filter(items, (document) =>
    _.includes(enabledDocumentIds, document.id)
  );
  const enabledQuestionnaires = _.filter(items, (questionnaire) =>
    _.includes(enabledQuestionnaireIds, questionnaire.id)
  );

  const enabledItems = [...enabledDocuments, ...enabledQuestionnaires];

  const toggleRemoved = (document) => {
    let updatedEnabledIds;
    const entityType = document.type;
    const entityId = document.id;

    if (entityType === DOCUMENT_TYPE) {
      updatedEnabledIds = enabledDocumentIds;
    } else {
      updatedEnabledIds = enabledQuestionnaireIds;
    }

    if (_.includes(updatedEnabledIds, entityId)) {
      _.pull(updatedEnabledIds, entityId);
    }

    onRemove({
      [entityType === QUESTIONNAIRE_TYPE
        ? 'questionnaires'
        : 'documents']: updatedEnabledIds
    });
  };
  const getColumns = () => [
    getDocumentNameColumn(),
    documentVersionColumn,
    getRemoveColumn((entity) => {
      toggleRemovalVisibility.current({ entity });
    })
  ];

  const getRowClassNames = (tableItem) =>
    classNames('small-table-card', {
      highlight: tableItem.id === addedItem
    });

  return (
    <React.Fragment>
      <Table
        emptyState={() => ''}
        displayTableHeader={false}
        className={classNames(className)}
        columns={getColumns(true)}
        data={enabledItems}
        getRowClassNames={getRowClassNames}
      />
      <ConfirmEntityRemovalModal
        message={'modal:confirmItemRemoval.message'}
        title={'modal:confirmEntityRemoval.title'}
        entityNameText={''}
        itemNamePath={'entity.name'}
        removeEntityDispatch={toggleRemoved}
        toggleRef={(toggleMethod) => {
          toggleRemovalVisibility.current = toggleMethod;
        }}
      />
    </React.Fragment>
  );
};

DocumentsInFlowListComponent.propTypes = {
  onRemove: PropTypes.func.isRequired,
  enabledDocumentIds: PropTypes.arrayOf(PropTypes.string),
  enabledQuestionnaireIds: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.shape(SimpleDocumentShape)),
  className: PropTypes.string,
  addedItem: PropTypes.string
};

DocumentsInFlowListComponent.defaultProps = {
  flow: null,
  enabledDocumentIds: [],
  enabledQuestionnaireIds: [],
  items: [],
  className: '',
  addedItem: ''
};

export default DocumentsInFlowListComponent;
