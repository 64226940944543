import { createActionTypeArray } from '../common/actionTypes';

export const EXPORT_ORGANIZATION_DATA = 'organization/EXPORT_ORGANIZATION_DATA';
export const UPDATE_ORGANIZATION = 'organization/UPDATE_ORGANIZATION';
export const GET_CURRENT_ORGANIZATION = 'organization/GET_CURRENT_ORGANIZATION';
export const GET_CURRENT_ORGANIZATION_WIDGETS =
  'organization/GET_CURRENT_ORGANIZATION_WIDGETS';

export const [
  UPDATE_ORGANIZATION_IN_PROGRESS,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE
] = createActionTypeArray(UPDATE_ORGANIZATION);

export const [
  GET_CURRENT_ORGANIZATION_WIDGETS_IN_PROGRESS,
  GET_CURRENT_ORGANIZATION_WIDGETS_SUCCESS,
  GET_CURRENT_ORGANIZATION_WIDGETS_FAILURE
] = createActionTypeArray(GET_CURRENT_ORGANIZATION_WIDGETS);

export const [
  GET_CURRENT_ORGANIZATION_IN_PROGRESS,
  GET_CURRENT_ORGANIZATION_SUCCESS,
  GET_CURRENT_ORGANIZATION_FAILURE
] = createActionTypeArray(GET_CURRENT_ORGANIZATION);
