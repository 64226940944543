import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  DEFAULT_CATEGORY_COLOR,
  getTextColorByBackgroundColor,
  MOSHI_COLOR_PRIMARY_BLUE_100
} from '../../utils/color';
import {
  ACTIVE_BOOKING_TEMPLATE,
  INACTIVE_BOOKING_TEMPLATE
} from '../../utils/constants/bookingConstants';
import { ORDER_ASCENDING } from '../../utils/constants/tableConstants';
import { mapBookingScheduleEventsForCalendar } from '../../utils/mappers/cleaned/booking-mappers';
import { appendCategoryColorMapper } from '../../utils/mappers/color-mappers';
import { selectProps } from '../../utils/redux/selectorHelpers';
import { selectAppConfigVisual } from '../core/app-config/appConfigSelectors';

import {
  selectIsFeatureEnabled,
  selectOrganizationBookingSettings
} from '../organization/organizationSelectors';

import { BOOKING_MODULE } from '../../featureModules';

export const selectBookingStore = (store) => store.booking;

export const selectBookingTemplateList = createSelector(
  [selectBookingStore],
  (bookingStore) => {
    const templateList = _.getNonEmpty(bookingStore, 'templateList', []);

    // We append the category color
    return _.map(templateList, appendCategoryColorMapper);
  }
);

export const selectBookingScheduleList = createSelector(
  [selectBookingStore],
  (bookingStore) => {
    const schedules = _.getNonEmpty(bookingStore, 'scheduleList', []);

    return mapBookingScheduleEventsForCalendar(schedules);
  }
);

export const selectBookingTemplateDetail = createSelector(
  [selectBookingStore],
  (bookingStore) => _.getNonEmpty(bookingStore, 'detail', {})
);

export const selectBookingTemplateNameById = createSelector(
  [selectBookingTemplateList, selectProps],
  (templates, templateId) => {
    const template = _.find(templates, { id: templateId });
    const templateName = _.getNonEmpty(template, 'name', '/');

    return templateName;
  }
);

export const selectIsBookingTemplateDetailLoading = createSelector(
  [selectBookingStore],
  (bookingStore) => _.getNonEmpty(bookingStore, 'loadingDetail', false)
);

export const selectBookingTemplateDraft = createSelector(
  [selectBookingStore],
  (bookingStore) => _.getNonEmpty(bookingStore, 'draftDetail', {})
);

export const getBookingTemplateDraftWithFallbackValues = createSelector(
  selectBookingTemplateDraft,
  (templateDraftDetail) => ({
    name: _.getNonEmpty(templateDraftDetail, 'name', ''),
    instructions: _.getNonEmpty(templateDraftDetail, 'instructions', ''),
    categoryID: _.getNonEmpty(templateDraftDetail, 'categoryID', ''),
    increment: {
      scale: _.getNonEmpty(templateDraftDetail, 'increment.scale', 'minutes'),
      value: _.getNonEmpty(templateDraftDetail, 'increment.value', 0)
    },
    duration: {
      scale: _.getNonEmpty(templateDraftDetail, 'duration.scale', 'minutes'),
      value: _.getNonEmpty(templateDraftDetail, 'duration.value', 30)
    },
    availability: {
      bookingWindowEnd: {
        scale: _.getNonEmpty(
          templateDraftDetail,
          'availability.bookingWindowEnd.scale',
          'days'
        ),
        value: _.getNonEmpty(
          templateDraftDetail,
          'availability.bookingWindowEnd.value',
          60
        )
      },
      allowCancelingUntil: {
        scale: _.getNonEmpty(
          templateDraftDetail,
          'availability.allowCancelingUntil.scale',
          'hours'
        ),
        value: _.getNonEmpty(
          templateDraftDetail,
          'availability.allowCancelingUntil.value',
          0
        )
      },
      allowReschedulingUntil: {
        scale: _.getNonEmpty(
          templateDraftDetail,
          'availability.allowReschedulingUntil.scale',
          'hours'
        ),
        value: _.getNonEmpty(
          templateDraftDetail,
          'availability.allowReschedulingUntil.value',
          0
        )
      },
      bookingWindowStart: {
        scale: _.getNonEmpty(
          templateDraftDetail,
          'availability.bookingWindowStart.scale',
          'hours'
        ),
        value: _.getNonEmpty(
          templateDraftDetail,
          'availability.bookingWindowStart.value',
          2
        )
      }
    },
    price: _.getNonEmpty(templateDraftDetail, 'price', null)
  })
);
export const selectCalendarBookingTemplatesList = createSelector(
  [selectBookingTemplateList],
  (templatesList) => {
    const mappedList = [];

    _.forEach(templatesList, (template) => {
      const type = template.active
        ? ACTIVE_BOOKING_TEMPLATE
        : INACTIVE_BOOKING_TEMPLATE;
      const templateItem = _.cloneDeep(template);

      const templateColor = _.getNonEmpty(
        templateItem,
        'color',
        DEFAULT_CATEGORY_COLOR
      );

      mappedList.push({
        type,
        item: templateItem,
        id: template.id,
        name: _.getNonEmpty(templateItem, 'name', '/'),
        color: templateColor,
        textColor: getTextColorByBackgroundColor(templateColor)
      });
    });

    const nameSorter = (template) => template.name.toLowerCase();

    return _.orderBy(_.sortBy(mappedList, [nameSorter]), 'type', [
      ORDER_ASCENDING
    ]);
  }
);

export const selectIsBookingModuleEnabled = createSelector(
  [(store) => selectIsFeatureEnabled(store, BOOKING_MODULE)],
  (isBookingModuleEnabled) => isBookingModuleEnabled
);

export const selectCurrentBookingTermsAndConditionsUrl = createSelector(
  [selectOrganizationBookingSettings],
  (currentBookingSettings) =>
    _.getNonEmpty(currentBookingSettings, 'termsAndConditions', '')
);

export const selectCurrentBookingTermsAndConditionsFileContent = createSelector(
  [selectBookingStore],
  (bookingStore) =>
    _.getNonEmpty(bookingStore, 'generalSettings.termsAndConditions', '')
);

export const getOrganizationBookingSettingsWithFallbackValues = createSelector(
  [
    selectOrganizationBookingSettings,
    selectAppConfigVisual,
    selectCurrentBookingTermsAndConditionsFileContent
  ],
  (currentBookingSettings, appConfigVisual, termsAndConditionsFileContent) => {
    const fallbackBackgroundColor = _.getNonEmpty(
      appConfigVisual,
      'buttonColor',
      MOSHI_COLOR_PRIMARY_BLUE_100
    );

    return {
      allowCancelingUntil: {
        scale: _.getNonEmpty(
          currentBookingSettings,
          'allowCancelingUntil.scale',
          'hours'
        ),
        value: _.getNonEmpty(
          currentBookingSettings,
          'allowCancelingUntil.value',
          24
        )
      },
      allowReschedulingUntil: {
        scale: _.getNonEmpty(
          currentBookingSettings,
          'allowReschedulingUntil.scale',
          'hours'
        ),
        value: _.getNonEmpty(
          currentBookingSettings,
          'allowCancelingUntil.value',
          24
        )
      },
      logoUrl: _.getNonEmpty(currentBookingSettings, 'logoUrl', null),
      backgroundColor: _.getNonEmpty(
        currentBookingSettings,
        'backgroundColor',
        fallbackBackgroundColor
      ),
      welcomeMessage: _.getNonEmpty(
        currentBookingSettings,
        'welcomeMessage',
        ''
      ),
      welcomeDetails: _.getNonEmpty(
        currentBookingSettings,
        'welcomeDetails',
        ''
      ),
      termsAndConditions: termsAndConditionsFileContent
    };
  }
);
