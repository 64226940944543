import { createActionTypeArray } from '../common/actionTypes';

export const GET_EVENT_LOG_LIST = 'eventLog/GET_EVENT_LOG_LIST';
export const SET_EVENT_LOG_LIST_PAGINATION =
  'eventLog/SET_EVENT_LOG_LIST_PAGINATION';
export const CLEAR_NEW_EVENT_LOG_ID_LIST =
  'eventLog/CLEAR_NEW_EVENT_LOG_ID_LIST';
export const CLEAR_ALL_NEW_EVENT_LOG_ID_LIST =
  'eventLog/CLEAR_ALL_NEW_EVENT_LOG_ID_LIST';

export const [
  GET_EVENT_LOG_LIST_IN_PROGRESS,
  GET_EVENT_LOG_LIST_SUCCESS,
  GET_EVENT_LOG_LIST_FAILURE
] = createActionTypeArray(GET_EVENT_LOG_LIST);
