import _ from 'lodash';

class LocalizedStringModel {
  constructor() {
    this.en = '';
    this.sl = '';
    this.de = '';
    this.fr = '';
    this.it = '';
    this.cs = '';
  }

  static getFieldPath(prefix, defaultLocale) {
    let path = defaultLocale;

    if (!_.isEmpty(prefix)) {
      path = `${prefix}.${path}`;
    }

    return path;
  }
}

export default LocalizedStringModel;
