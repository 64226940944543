import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import { getSafeTranslation } from '../../../../utils/language';

export const ErrorListTemplateComponent = ({
  t: translate,
  errors,
  schema
}) => {
  const emptyError = {
    errorTranslationCode: 'NO_TRANSLATE_CODE'
  };
  let firstError = _.first(errors);

  if (_.isEmpty(firstError)) {
    firstError = emptyError;
  }

  const fieldName = _.getNonEmpty(schema, `title`, 'FIELD_NOT_FOUND');

  return (
    <div className={'panel panel-danger errors'}>
      <span className={'text-color-red'}>
        {translate(firstError.errorTranslationCode, {
          field: getSafeTranslation(fieldName)
        })}
      </span>
    </div>
  );
};

ErrorListTemplateComponent.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  schema: PropTypes.shape().isRequired
};

export default localize(ErrorListTemplateComponent);
