import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { MOSHI_COLOR_SECONDARY_NAME } from '../../../../../utils/color';
import { MOSHI_SIZE_LG } from '../../../../../utils/constants/sizeConstants';

import { ModalFooter } from '../../../../reactstrap';
import MoshiButton from '../../../button/MoshiButton';
import SubmitButton from '../../button/SubmitButton';

const QuestionnaireWidgetFooter = ({ toggleOpened }) => {
  const { t: translate } = useTranslation();

  return (
    <ModalFooter className={'flex-justify-end-row'}>
      <MoshiButton
        id={'cancel'}
        size={MOSHI_SIZE_LG}
        color={MOSHI_COLOR_SECONDARY_NAME}
        onClick={() => toggleOpened(false)}
      >
        {translate('common:cancel')}
      </MoshiButton>
      <SubmitButton formikAvailable={false} noFormikIsValid />
    </ModalFooter>
  );
};

QuestionnaireWidgetFooter.propTypes = {
  toggleOpened: PropTypes.func.isRequired
};

export default QuestionnaireWidgetFooter;
