import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  showMissingLocationIdError,
  showMissingOrganizationIdError,
  showAlertSuccess
} from '../../../utils/alert';
import { getOrganizationUrl } from '../../../utils/api';
import { getAdvanceRecheckFursData } from '../../../utils/api/apiUrlUtils';
import {
  BILL_TYPE_ADVANCE,
  getDateFilterParametersPayload,
  isBillFiscalized
} from '../../../utils/billing';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../../utils/constants/networkConstants';
import { EMPTY_GUID } from '../../../utils/gen';

import { patchPatient } from '../../patient/patientActions';
import { clearBillItemDescription } from '../bill/billActions';
import {
  getBillKeywordData,
  updateUserPreferredBillDataInput
} from '../billingActions';

import {
  GET_ADVANCE_LIST,
  SET_ADVANCE_LIST_FILTER,
  SET_ADVANCE_LIST_FILTERS,
  SET_ADVANCE_LIST_ORDER,
  GET_ADVANCE_DETAIL,
  UPDATE_ADVANCE,
  ADD_ADVANCE,
  REMOVE_ADVANCE,
  SET_ADVANCE_LIST_PAGINATION,
  CLEAR_ADVANCE_DETAIL,
  ADD_ADVANCE_PAYMENT,
  GET_ADVANCE_PAYMENTS_LIST,
  REMOVE_ADVANCE_PAYMENT,
  CLEAR_ADVANCE_PAYMENTS_LIST,
  PATCH_ADVANCE,
  RECHECK_ADVANCE_FURS_STATUS,
  SEND_ADVANCE_VIA_EMAIL,
  GET_PATIENT_ADVANCE_LIST,
  CLEAR_PATIENT_ADVANCE_LIST,
  CLEAR_ADVANCE_LIST_FILTER,
  CLEAR_ADVANCE_LIST_FILTER_NO_POLL,
  SET_ADVANCE_LIST_INCLUDE_PARAM
} from './advanceTypes';

import { selectCachedOrganizationId } from '../../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';
import { selectDefaultBillingLocale } from '../../organization/organizationSelectors';
import {
  selectAdvanceDetail,
  selectAdvanceFilter,
  selectAdvanceIncludeParam,
  selectAdvancePagination
} from './advanceSelectors';

import AdvanceModel from '../../../metadata/model/billing/bill/AdvanceModel';

export const clearAdvanceDetail = () => (dispatch, getState) => {
  const bill = selectAdvanceDetail(getState());
  const billId = _.getNonEmpty(bill, 'id', EMPTY_GUID);

  dispatch(clearBillItemDescription(billId));

  return dispatch({
    type: CLEAR_ADVANCE_DETAIL
  });
};

export const clearAdvancePaymentList = () => (dispatch) =>
  dispatch({
    type: CLEAR_ADVANCE_PAYMENTS_LIST
  });

export const resetAdvanceStoreData = () => (dispatch) => {
  dispatch(clearAdvanceDetail());
  dispatch(clearAdvancePaymentList());
};

export const getAdvanceList = (
  customQueryParams = null,
  actionName = GET_ADVANCE_LIST
) => (dispatch, getState) => {
  const store = getState();
  const organizationUrl = getOrganizationUrl(() => store);
  const include = selectAdvanceIncludeParam(store);
  const { limit, page } = selectAdvancePagination(store);

  let customFilter = selectAdvanceFilter(store);

  customFilter = getDateFilterParametersPayload(customFilter);

  let queryParams = {
    ...customFilter,
    include,
    limit,
    page
  };

  if (!_.isEmpty(customQueryParams)) {
    queryParams = customQueryParams;
  }

  return dispatch({
    type: actionName,
    apiCall: axios.get(`${organizationUrl}/billing/advances`, {
      params: queryParams
    })
  });
};

export const getPatientAdvances = (patientID, billingUnitID) => (dispatch) =>
  dispatch(
    getAdvanceList(
      {
        patientID,
        billingUnitID,
        limit: REQUEST_PAGINATION_MAX_LIMIT,
        include: ['items'].join(',')
      },
      GET_PATIENT_ADVANCE_LIST
    )
  );

export const clearPatientAdvances = () => (dispatch) =>
  dispatch({
    type: CLEAR_PATIENT_ADVANCE_LIST
  });

export const getAdvance = (advanceId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: GET_ADVANCE_DETAIL,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/advances/${advanceId}`
    ),
    onSuccess: () => {
      dispatch(getBillKeywordData(BILL_TYPE_ADVANCE));
    }
  });
};

export const setAdvanceListFilter = (filterName, filterValue) => (dispatch) => {
  dispatch({
    type: SET_ADVANCE_LIST_FILTER,
    filterName,
    filterValue
  });
};

export const setAdvanceListFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_ADVANCE_LIST_FILTERS,
    filters
  });
};

export const setAdvanceListIncludeParam = (includeValue) => (dispatch) =>
  dispatch({
    type: SET_ADVANCE_LIST_INCLUDE_PARAM,
    includeValue
  });

export const setAdvanceListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_ADVANCE_LIST_ORDER,
    sortBy,
    order
  });
};

export const setAdvanceListPagination = (paginationName, paginationValue) => (
  dispatch
) => {
  dispatch({
    type: SET_ADVANCE_LIST_PAGINATION,
    paginationName,
    paginationValue
  });
};

export const addAdvance = (advance, customListAction, formId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const locationId = selectCurrentLocationId(state);

  if (_.isEmpty(locationId)) {
    return dispatch(showMissingLocationIdError());
  }

  const payload = AdvanceModel.getPayload(advance, defaultLocale);

  payload.locationID = locationId;
  // A unique form id that is generated when we open the add advance form.
  payload.formID = formId;

  return dispatch({
    type: ADD_ADVANCE,
    apiCall: axios.post(`${organizationUrl}/billing/advances`, payload),
    onSuccess: () => {
      showAlertSuccess('billing:advances.alerts.advanceCreated');
      dispatch(updateUserPreferredBillDataInput(advance));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const updateAdvance = (advance, customListAction) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const payload = AdvanceModel.getPayload(advance, defaultLocale);

  return dispatch({
    type: UPDATE_ADVANCE,
    apiCall: axios.put(
      `${organizationUrl}/billing/advances/${advance.id}`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:advances.alerts.advanceUpdated');
      dispatch(updateUserPreferredBillDataInput(advance));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const addOrUpdateAdvance = (advance, customListAction, formId) => (
  dispatch
) => {
  let promiseObject;

  if (_.isEmpty(advance.id)) {
    promiseObject = dispatch(addAdvance(advance, customListAction, formId));
  } else {
    promiseObject = dispatch(updateAdvance(advance, customListAction));
  }

  return promiseObject;
};

export const patchAdvance = (
  advanceId,
  patchedFields,
  customListAction = undefined
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: PATCH_ADVANCE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/advances/${advanceId}/reassign`,
      patchedFields
    ),
    onSuccess: () => {
      showAlertSuccess(`billing:advances.alerts.advanceUpdated`);

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const voidAdvance = (advance, basePath, customListAction = null) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_ADVANCE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/advances/${advance.id}/cancel`
    ),
    onSuccess: () => {
      showAlertSuccess(
        `billing:advances.alerts.${
          isBillFiscalized(advance) ? 'advanceVoided' : 'advanceRemoved'
        }`
      );

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const getAdvancePayments = (advanceId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: GET_ADVANCE_PAYMENTS_LIST,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/advances/${advanceId}/payments`
    )
  });
};

export const addAdvancePayment = (advanceId, payload, customListAction) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  payload.date = moment(payload.date).format('YYYY-MM-DD');

  return dispatch({
    type: ADD_ADVANCE_PAYMENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/advances/${advanceId}/payments`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.paymentAdded');
      dispatch(getAdvance(advanceId));
      dispatch(getAdvancePayments(advanceId));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const removeAdvancePayment = (
  advanceId,
  paymentId,
  customListAction
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_ADVANCE_PAYMENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/billing/advances/${advanceId}/payments/${paymentId}`
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.paymentRemoved');
      dispatch(getAdvance(advanceId));
      dispatch(getAdvancePayments(advanceId));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const recheckAdvanceFursData = (advanceId, customListAction) => (
  dispatch,
  getState
) => {
  const advanceRecheckFursDataUrl = getAdvanceRecheckFursData(
    getState,
    advanceId
  );

  return dispatch({
    type: RECHECK_ADVANCE_FURS_STATUS,
    apiCall: axios.put(advanceRecheckFursDataUrl),
    onSuccess: () => {
      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getAdvanceList());
    }
  });
};

export const sendAdvanceViaEmail = (
  advanceId,
  email,
  note = undefined,
  patientIdToUpdate = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = { email, note };

  return dispatch({
    type: SEND_ADVANCE_VIA_EMAIL,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/advances/${advanceId}/sendPdf`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.pdfSentViaEmail');

      if (!_.isEmpty(patientIdToUpdate) && patientIdToUpdate !== EMPTY_GUID) {
        dispatch(patchPatient(patientIdToUpdate, { contact: { email } }));
      }
    }
  });
};

export const clearAdvanceListFilters = (refetchAdvanceList = true) => (
  dispatch
) => {
  if (refetchAdvanceList) {
    dispatch({ type: CLEAR_ADVANCE_LIST_FILTER });
  } else {
    dispatch({ type: CLEAR_ADVANCE_LIST_FILTER_NO_POLL });
  }
};
