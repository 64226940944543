import _ from 'lodash';

import { SUB_ENTITY_TYPE_USER } from '../constants/entityConstants';

import UserModel from '../../metadata/model/UserModel';

import { findEntityInStore } from '../storeExtensions';

export const getSafeFullName = (person, fallback = '') =>
  _.isEmptySafe(person, 'firstName') || _.isEmptySafe(person, 'lastName')
    ? fallback
    : getFullName(person);

export const getPatientFullName = (person, flip = false) => {
  const firstName = _.get(person, 'identification.firstName', '');
  let lastName = _.get(person, 'identification.lastName', '');

  // Add a comma only if flip and last name exists, otherwise leave empty string
  if (flip && lastName.length) {
    lastName = `${lastName},`;
  }

  if (flip) {
    return `${lastName} ${firstName}`;
  } else {
    return `${firstName} ${lastName}`;
  }
};

export const getFullName = (person, flip = false) => {
  const firstName = _.getNonEmpty(person, 'firstName', '');
  let lastName = _.getNonEmpty(person, 'lastName', '');

  // Add a comma only if flip and last name exists, otherwise leave empty string
  if (flip && lastName.length) {
    lastName = `${lastName},`;
  }

  let fullName;

  if (flip) {
    fullName = `${lastName} ${firstName}`;
  } else {
    fullName = `${firstName} ${lastName}`;
  }

  return _.trim(fullName);
};

export const isNotEmptyFilter = (obj) => !_.isEmpty(obj);

export const mapSubEntityDoctor = (doctorID) => {
  let doctor = null;

  if (!_.isEmpty(doctorID)) {
    const foundDoctor = findEntityInStore(SUB_ENTITY_TYPE_USER, doctorID);

    if (!_.isEmpty(foundDoctor)) {
      doctor = _.nestedAssign(UserModel, foundDoctor);
    }
  }

  return doctor;
};

/**
 * Function finds paths in values and merge them into array in targetPath.
 *
 * @param {object} values
 * @param {array} paths
 * @param {string} targetPath
 * @return {object}
 *
 */

export const mergePropertiesToArray = (values, paths, targetPath) => {
  const arrayOfValues = [];

  paths.forEach((path) => {
    const value = _.get(values, path, null);

    !_.isEmpty(value) && arrayOfValues.push(value);
    _.unset(values, path);
  });
  _.set(values, targetPath, arrayOfValues);

  return values;
};

export const getFirstTwoLetters = (words) =>
  _.split(words, ' ', 2)
    .map((word) => word[0])
    .join('');

/**
 * If you have an array of numbers and want to insert a number to the right
 * index this function returns the index where the number should be inserted,
 * so that the order is preserved.
 *
 * example: getIndexToInsertAt([1,2,4,5], 3) // 2
 */
export const getIndexToInsertAt = (arrayOfNumbers, num) =>
  arrayOfNumbers
    .concat(num)
    .sort((a, b) => a - b)
    .indexOf(num);

// TODO: Find a better place for this function
export const escapeId = (id) => {
  const idWithoutWhiteSpaces = id.replace(/ /gu, '');

  const idWithEscapedLeadingCharacters = idWithoutWhiteSpaces.replace(
    /^[0-9.]+/gu,
    (leadingChars) =>
      leadingChars
        .split('')
        .map((char) => {
          // Remove leading '.'
          if (char === '.') return '';

          // Convert leading numbers to ASCII characters (to keep IDs unique)
          return String.fromCharCode(
            char.charCodeAt(0) + 'A'.charCodeAt(0) - '0'.charCodeAt(0)
          );
        })
        .join('')
  );

  // Convert remaining '.' characters to '-'
  return idWithEscapedLeadingCharacters.replace(/\./gu, '-');
};
