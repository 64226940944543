import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import { showMissingOrganizationIdError } from '../../utils/alert';
import {
  getPatientTherapyIntakeListUrl,
  getPatientTherapyListUrl,
  getPatientTherapyUrl,
  getPausePatientTherapyUrl,
  getResumePatientTherapyUrl
} from '../../utils/api/apiUrlUtils';
import { REGISTRY_THERAPY_TEMPLATES } from '../../utils/constants/redux/registryConstants';
import {
  THERAPY_DEFAULT_DURATION_VALUE,
  THERAPY_TYPE_MANUAL
} from '../../utils/constants/therapyConstants';
import { isValidDate } from '../../utils/date';

import { EMPTY_ACTION_TYPE } from '../common/actionTypes';
import {
  addRegistryItem,
  getRegistryItem,
  getRegistryItemList,
  removeRegistryItem,
  updateRegistryItem
} from '../core/registry/registryActions';

import {
  ADD_FORM_SUBMISSION_DETAIL,
  ADD_PATIENT_THERAPY,
  ADD_THERAPY_DETAIL,
  CLEAR_THERAPY_DETAIL,
  GET_FORM_SUBMISSION_DETAIL,
  DELETE_THERAPY_DETAIL,
  GET_PATIENT_THERAPY_LIST_TRIGGER,
  GET_THERAPY_DETAIL,
  GET_THERAPY_LIST,
  REMOVE_PATIENT_THERAPY,
  SET_THERAPY_LIST_FILTER,
  SET_THERAPY_LIST_ORDER,
  SET_THERAPY_LIST_PAGINATION,
  UPDATE_THERAPY_DETAIL,
  UPDATE_PATIENT_THERAPY,
  GET_PATIENT_THERAPY_TRIGGER,
  GET_PATIENT_THERAPY_DETAIL_TRIGGER,
  FETCH_PATIENT_THERAPY_LIST
} from './therapyTypes';

import {
  selectCachedDrugList,
  selectCachedOrganizationId
} from '../core/cache/cacheSelectors';
import { selectFormSubmissionDetail } from './therapySelectors';

import EntityBaseModel from '../../metadata/model/EntityBaseModel';

const prepareBaseTherapyPayload = (therapy) => {
  const therapyPayload = _.cloneDeep(therapy);
  const payload = EntityBaseModel.removeEntityMetadata(therapyPayload);

  if (isValidDate(payload.eventTime)) {
    payload.eventTime = moment(payload.eventTime).format('HH:mm');
  }

  if (_.getNonEmpty(payload, 'indefiniteTherapy', false)) {
    if (_.isEmptySafe(payload, 'duration')) {
      payload.duration = THERAPY_DEFAULT_DURATION_VALUE;
    }
    payload.duration.value = -1;
  }

  if (_.has(payload, 'indefiniteTherapy')) {
    delete payload.indefiniteTherapy;
  }

  return payload;
};

const prepareTherapyPayload = (therapy, drugList) => {
  const payload = prepareBaseTherapyPayload(therapy);

  const foundDrug = _.find(drugList, { id: _.get(therapy, 'drugID') });

  payload.name = _.get(foundDrug, 'name', '');
  delete payload.drug;

  return payload;
};

const preparePatientActiveTherapyPayload = (
  therapy,
  drugList,
  patch = false
) => {
  const payload = prepareBaseTherapyPayload(therapy);
  const foundDrug = _.find(drugList, { id: therapy.drugID });

  if (!patch) {
    payload.startDate = moment(payload.startDate).format('YYYY-MM-DD');
    payload.drugName = _.getNonEmpty(foundDrug, 'name', '/');
  }
  delete payload.drug;

  if (_.getNonEmpty(payload, 'type') === THERAPY_TYPE_MANUAL) {
    payload.frequency = null;
  }

  return payload;
};

const prepareFormSubmissionPayload = ({ formId, formVersion }, formData) => {
  const payload = {
    forms: [
      {
        formID: formId,
        formVersion,
        data: formData
      }
    ]
  };

  return payload;
};

export const getTherapyList = () => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const customFilter = _.get(getState(), 'therapy.filter', {});
  const { limit, page } = _.get(getState(), 'therapy.pagination', {});

  return dispatch(
    getRegistryItemList(REGISTRY_THERAPY_TEMPLATES, GET_THERAPY_LIST, {
      params: {
        ...customFilter,
        limit,
        page
      }
    })
  );
};

export const setTherapyListFilter = (filterName, filterValue) => (dispatch) =>
  dispatch({
    type: SET_THERAPY_LIST_FILTER,
    filterName,
    filterValue
  });

export const setTherapyListOrder = (sortBy, order) => (dispatch) =>
  dispatch({
    type: SET_THERAPY_LIST_ORDER,
    sortBy,
    order
  });

export const setTherapyListPagination = (paginationName, paginationValue) => (
  dispatch
) =>
  dispatch({
    type: SET_THERAPY_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

export const getTherapy = (therapyId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return dispatch(
    getRegistryItem(REGISTRY_THERAPY_TEMPLATES, therapyId, GET_THERAPY_DETAIL)
  );
};

export const addTherapy = (therapy) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const drugList = selectCachedDrugList(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const payload = prepareTherapyPayload(therapy, drugList);

  return dispatch(
    addRegistryItem(REGISTRY_THERAPY_TEMPLATES, payload, ADD_THERAPY_DETAIL)
  );
};

export const updateTherapy = (therapy) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const drugList = selectCachedDrugList(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const therapyId = therapy.id;
  const payload = prepareTherapyPayload(therapy, drugList);

  return dispatch(
    updateRegistryItem(
      REGISTRY_THERAPY_TEMPLATES,
      therapyId,
      payload,
      UPDATE_THERAPY_DETAIL
    )
  );
};

export const addOrUpdateTherapy = (therapy) => (dispatch) => {
  let promiseObject;

  if (_.isEmpty(therapy.id)) {
    promiseObject = dispatch(addTherapy(therapy));
  } else {
    promiseObject = dispatch(updateTherapy(therapy));
  }

  return promiseObject;
};

export const removeTherapy = (therapyId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return dispatch(
    removeRegistryItem(
      REGISTRY_THERAPY_TEMPLATES,
      therapyId,
      DELETE_THERAPY_DETAIL
    )
  );
};

export const clearTherapyDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_THERAPY_DETAIL
  });

export const fetchPatientTherapyList = (patientId) => (dispatch, getState) => {
  const url = getPatientTherapyListUrl(getState, patientId);

  return dispatch({
    type: FETCH_PATIENT_THERAPY_LIST,
    apiCall: axios.get(url)
  });
};

export const getPatientTherapyList = (patientId) => (dispatch) =>
  dispatch({ type: GET_PATIENT_THERAPY_LIST_TRIGGER, patientId });

export const getPatientTherapy = (patientId, therapyId) => (dispatch) =>
  dispatch({ type: GET_PATIENT_THERAPY_TRIGGER, patientId, therapyId });

export const getPatientTherapyDetail = (patientId, therapyId) => (dispatch) =>
  dispatch({ type: GET_PATIENT_THERAPY_DETAIL_TRIGGER, patientId, therapyId });

export const addPatientTherapy = (patientId, therapy) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());
  const drugList = selectCachedDrugList(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const payload = preparePatientActiveTherapyPayload(therapy, drugList);

  return dispatch({
    type: ADD_PATIENT_THERAPY,
    apiCall: axios.post(
      `/organizations/${organizationId}/patients/${patientId}/drugTherapies`,
      payload
    ),
    onSuccess: () => {
      dispatch(getPatientTherapyList(patientId));
    }
  });
};

export const updatePatientTherapy = (patientId, therapy) => (
  dispatch,
  getState
) => {
  const therapyId = therapy.id;
  const url = getPatientTherapyUrl(getState, patientId, therapyId);

  if (_.isEmpty(url)) {
    return;
  }

  const payload = preparePatientActiveTherapyPayload(therapy, [], true);

  return dispatch({
    type: UPDATE_PATIENT_THERAPY,
    apiCall: axios.put(url, payload),
    onSuccess: () => {
      dispatch(getPatientTherapyList(patientId));
    }
  });
};

export const addOrUpdatePatientTherapy = (patientId, therapy) => (dispatch) => {
  let promiseObject;

  if (_.isEmptySafe(therapy, 'id')) {
    promiseObject = dispatch(addPatientTherapy(patientId, therapy));
  } else {
    promiseObject = dispatch(updatePatientTherapy(patientId, therapy));
  }

  return promiseObject;
};

export const stopPatientTherapy = (patientId, therapyId, extraParams = {}) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return dispatch(
    updatePatientTherapy(patientId, {
      ...extraParams,
      id: therapyId,
      stopped: true
    })
  );
};

export const pausePatientTherapy = (patientId, therapyId) => (
  dispatch,
  getState
) => {
  const url = getPausePatientTherapyUrl(getState, patientId, therapyId);

  if (_.isEmpty(url)) {
    return;
  }

  return dispatch({
    type: UPDATE_PATIENT_THERAPY,
    apiCall: axios.put(url, {
      pauseAt: null
    }),
    onSuccess: () => {
      dispatch(getPatientTherapyList(patientId));
    }
  });
};

export const resumePatientTherapy = (
  patientId,
  therapyId,
  resumeAt,
  eventTime
) => (dispatch, getState) => {
  const url = getResumePatientTherapyUrl(getState, patientId, therapyId);

  if (_.isEmpty(url)) {
    return;
  }

  return dispatch({
    type: UPDATE_PATIENT_THERAPY,
    apiCall: axios.put(url, {
      resumeAt,
      eventTime
    }),
    onSuccess: () => {
      dispatch(getPatientTherapyList(patientId));
    }
  });
};

export const removePatientTherapy = (patientId, therapyId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: REMOVE_PATIENT_THERAPY,
    apiCall: axios.delete(
      `/organizations/${organizationId}/patients/${patientId}/drugTherapies/${therapyId}`
    ),
    onSuccess: () => {
      dispatch(getPatientTherapyList(patientId));
    }
  });
};

export const addTherapyManualIntake = (patientId, therapyId, payload) => (
  dispatch,
  getState
) => {
  const url = getPatientTherapyIntakeListUrl(getState, patientId, therapyId);

  if (_.isEmpty(url)) {
    return Promise.reject(new Error('Malformed url'));
  }

  return dispatch({
    type: EMPTY_ACTION_TYPE,
    apiCall: axios.post(url, payload),
    onSuccess: () => {
      dispatch(getPatientTherapy(patientId, therapyId));
    }
  });
};

export const getFormSubmissionDetails = (requestId) => (dispatch) =>
  dispatch({
    type: GET_FORM_SUBMISSION_DETAIL,
    apiCall: axios.get(`/formSubmissionRequest/${requestId}`)
  });

export const addFormSubmission = (submissionId, formData) => (
  dispatch,
  getState
) => {
  const formDetails = selectFormSubmissionDetail(getState());

  const payload = prepareFormSubmissionPayload(formDetails, formData);

  return dispatch({
    type: ADD_FORM_SUBMISSION_DETAIL,
    apiCall: axios.post(`/formSubmissionRequest/${submissionId}`, payload)
  });
};
