import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import {
  showAlertSuccess,
  showMissingOrganizationIdError
} from '../../utils/alert';
import {
  getReassignCalendarItemsUrl,
  getUserListUrl,
  getUserUrl
} from '../../utils/api/apiUrlUtils';
import { NO_ID } from '../../utils/form';

import {
  clearSelectedCalendarAssignees,
  getCalendarAssigneesList,
  removeSelectedAssignee
} from '../calendar/calendarActions';
import { EMPTY_ACTION_TYPE } from '../common/actionTypes';
import { updateIntercomChat } from '../core/intercom/intercomActions';

import {
  CREATE_USER,
  DELETE_USER,
  GET_CURRENT_USER,
  GET_USER,
  GET_USERS,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  GET_CURRENT_USER_SUCCESS,
  RESEND_VERIFICATION_EMAIL,
  SET_USER_LIST_ORDER,
  SET_PREFERRED_BILLING_INPUT,
  REASSIGN_CALENDAR_ITEMS
} from './userReducer';

import { selectCurrentUserIdWithFallback } from './userSelectors';

const prepareCapitalizedUserPayload = (userData) => {
  const payload = _.cloneDeep(userData);

  payload.firstName = _.sentenceCase(payload.firstName);
  payload.lastName = _.sentenceCase(payload.lastName);

  return payload;
};

export const getUsers = (actionType = GET_USERS) => {
  const { organizationID: organizationId } = _.get(
    storeGetState(),
    'authentication.token'
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return {
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/users`)
  };
};

export const getCurrentUser = () => (dispatch, getState) => {
  const userId = selectCurrentUserIdWithFallback(getState());

  return dispatch(getUser(userId, GET_CURRENT_USER)).then(() => {
    dispatch(updateIntercomChat());
  });
};

export const getUser = (userId, actionType = GET_USER) => (
  dispatch,
  getState
) => {
  const url = getUserUrl(getState, userId);

  if (_.isEmptySafe(url)) {
    return;
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(url)
  });
};

export const updateUser = (userData) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmptySafe(userData, 'phoneNumber')) {
    delete userData.phoneNumber;
  }

  if (userData.pendingEmail && userData.pendingEmail !== userData.email) {
    userData.email = userData.pendingEmail;
  }

  return {
    type: UPDATE_USER,
    apiCall: axios.put(
      `/organizations/${organizationId}/users/${userData.id}`,
      userData
    ),
    onSuccess: ({ dispatch, getState }, response) => {
      const currentUserId = _.get(getState(), 'user.currentUser.id');

      showAlertSuccess('user:alerts.userUpdated');
      // Update current user in store if it was just updated
      if (currentUserId === userData.id) {
        dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          response
        });
      }
    }
  };
};

export const createUser = (userData) => (dispatch, getState) => {
  const addUserUrl = getUserListUrl(getState);

  const payload = prepareCapitalizedUserPayload(userData);

  return dispatch({
    type: CREATE_USER,
    apiCall: axios.post(addUserUrl, payload)
  });
};

export const removeUser = (userId) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return {
    type: DELETE_USER,
    apiCall: axios.delete(`/organizations/${organizationId}/users/${userId}`),
    onSuccess: ({ dispatch }) => {
      showAlertSuccess('user:alerts.userRemoved');
      // remove user from selected calendar assignees
      dispatch(removeSelectedAssignee(userId));
      dispatch(getUsers());
    }
  };
};

export const setUserListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_USER_LIST_ORDER,
    sortBy,
    order
  });
};

export const updateOrganizationRoles = (userId, roles) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return {
    type: UPDATE_USER_ROLE,
    apiCall: axios.put(
      `/organizations/${organizationId}/users/${userId}/roles`,
      roles
    ),
    onSuccess: ({ dispatch }) => {
      dispatch(getUser(userId));
      dispatch(clearSelectedCalendarAssignees());
    }
  };
};

export const updateRole = (userId, locationId, roles) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return {
    type: UPDATE_USER_ROLE,
    apiCall: axios.put(
      `/organizations/${organizationId}/users/${userId}/roles/locations/${locationId}`,
      roles
    ),
    onSuccess: ({ dispatch }) => {
      dispatch(getUser(userId));
    }
  };
};

export const updateRoles = (userId, locationsRoles) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationsRoles)) {
    return {
      type: EMPTY_ACTION_TYPE
    };
  }

  return {
    type: UPDATE_USER_ROLE,
    apiCall: axios.put(
      `/organizations/${organizationId}/users/${userId}/roles/locations`,
      locationsRoles
    ),
    onSuccess: ({ dispatch }) => {
      dispatch(getCalendarAssigneesList());
    }
  };
};

export const updateUserAndRoles = (userData, locationsRoles) => (dispatch) =>
  dispatch(updateUser(userData))
    .then(() => dispatch(updateRoles(userData.id, locationsRoles)))
    .then(() => {
      dispatch(getUser(userData.id));
    });

export const createUserAndUpdateRoles = (userData, locationsRoles) => (
  dispatch,
  getState
) =>
  dispatch(createUser(userData))
    .then(() => {
      const newUserId = _.get(getState(), 'user.newlyCreatedUser.id', null);

      return dispatch(updateRoles(newUserId, locationsRoles));
    })
    .then(() => {
      const newUserId = _.get(getState(), 'user.newlyCreatedUser.id', null);

      dispatch(getUsers());

      return dispatch(getUser(newUserId, CREATE_USER));
    });

export const resendVerificationEmail = (user) => {
  const userId = _.get(user, 'id', NO_ID);

  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return {
    type: RESEND_VERIFICATION_EMAIL,
    apiCall: axios.post(
      `/organizations/${organizationId}/users/${userId}/activate`
    ),
    onSuccess: ({ dispatch }) => {
      dispatch(getUser(userId));
      showAlertSuccess('user:alerts.resendEmail');
    }
  };
};

export const setUserPreferredBillingInput = (fieldName, fieldValue) => (
  dispatch
) => {
  dispatch({
    type: SET_PREFERRED_BILLING_INPUT,
    fieldName,
    fieldValue
  });
};

export const reassignCalendarItems = (data, locationId) => (
  dispatch,
  getState
) => {
  const reassignAllEvents = _.getNonEmpty(data, 'allEvents', true);
  const reassignUrl = getReassignCalendarItemsUrl(getState, locationId);

  const sourceUserID = _.getNonEmpty(data, 'sourceUserID', null);
  const targetUserID = _.getNonEmpty(data, 'targetUserID', null);
  const from = reassignAllEvents
    ? null
    : _.getNonEmpty(data, 'dateRange.fromDate', null);
  const to = reassignAllEvents
    ? null
    : _.getNonEmpty(data, 'dateRange.toDate', null);

  return {
    type: REASSIGN_CALENDAR_ITEMS,
    apiCall: axios.put(
      reassignUrl,
      {},
      {
        params: { sourceUserID, targetUserID, from, to }
      }
    )
  };
};
