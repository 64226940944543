import { formatting } from './date';

export default {
  title: 'Spletno naročanje',
  calendarDescription:
    'S klikom na koledar lahko dodate časovni termin ter s tem določite, kdaj je spletno naročanje možno.',
  tableDescription: 'Ustvarite in urejajte predloge za spletno naročanje.',
  newTemplate: 'Nova predloga',
  newTemplateTitle: 'Nova predloga za spletno naročanje',
  editTemplate: 'Uredite predlogo',
  form: {
    mainTitle: 'Poimenujte predlogo',
    templateName: 'Ime predloge',
    descriptionHeader: 'Opis',
    descriptionText: 'To besedilo bo vidno pacientom ob spletnem naročanju.',
    descriptionPlaceholder:
      'Napišite pomembne podatke, ki jih morajo pacienti vedeti ob naročanju.',
    category: 'Kategorija',
    categoryPlaceholder: 'Izberite kategorijo',
    secondaryTitle: 'Dolžina rezerviranega termina',
    price: 'Cena',
    dateRange: 'Obdobje sprejemanja rezervacij',
    futureEvent:
      '<0>Pacienti lahko rezervirajo termin do</0> <1></1> <2></2> <0>v prihodnost</0>',
    futureEventDescription: `Če pacient danes opravlja spletno naročanje {{ currentDate, ${formatting.shortDate} }},
     bo lahko rezerviral termin od danes do {{ futureDate, ${formatting.shortDate} }}.`,
    availabilityIncrement: 'Frekvenca razpoložljivosti terminov',
    availabilityIncrementDescription: 'Pokaži proste termine v razmakih',
    availabilityIncrementExample: `npr: {{ time0, ${formatting.time} }}, {{ time1, ${formatting.time} }}, {{ time2, ${formatting.time} }}, 
     {{ time3, ${formatting.time} }}, {{ time4, ${formatting.time} }}, {{ time5, ${formatting.time} }}`,
    preventEventsHeader: 'Omejitev “last minute” rezervacij',
    preventEvents:
      '<0>Ne dovoli rezervacij, ki so manj kot</0> <1></1> <2></2> <0>v prihodnosti</0>',
    preventCancelingHeader: 'Omejitev preklica rezervacij',
    cancelEvents:
      '<0>Ne dovoli preklica rezervacij, ki so manj kot</0> <1></1> <2></2> <0>v prihodnosti</0>',
    preventReschedulingHeader: 'Omejitev prestavljanja rezervacij',
    reschedulingEvents:
      '<0>Ne dovoli prestavljanja rezervacij, ki so manj kot</0> <1></1> <2></2> <0>v prihodnosti</0>'
  },
  alerts: {
    templateCreated: 'Predloga je bila ustvarjena',
    templateUpdated: 'Predloga je bila posodobljena',
    templateRemoved: 'Predloga je bila odstanjena',
    generalSettingsSaved: 'Splošne nastavitve shranjene'
  },
  templates: {
    active: 'Aktivne predloge',
    inactive: 'Neaktivne predloge',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih predlog.</0> <1></1>
      <0>Dodajte novo predlogo.</0>
    `
  },
  calendarForm: {
    slot: 'Termin',
    addSlot: '+ dodajte termin',
    addSecondaryAssignee: '+ dodajte dodatno osebje',
    primaryAssignee: 'Izvajalec',
    secondaryAssignee: 'Dodatno osebje',
    addStaff: 'Dodajte osebje',
    addScheduleForTemplate: 'Dodajte urnik za {{templateName}}',
    editScheduleForTemplate: 'Uredite urnik za {{templateName}}',
    repeatingSchedule: 'Ponavljajoč urnik spletnega naročanja',
    deleteThisScheduleOnly: 'Odstranite samo ta urnik',
    editThisScheduleOnly: 'Uredite samo ta urnik',
    deleteUpcomingSchedules: 'Odstranite vse prihodnje urnike',
    editUpcomingSchedules: 'Uredite vse prihodnje urnike',
    repeatingScheduleDeleteInfo:
      'Odstranjujete urnik, ki se ponavlja. Odstranitev tega urnika bo prepisala vse nadaljnje urnike za predlogo <0>{{templateName}}</0> za dan <1>{{dayName}}</1>.',
    repeatingScheduleEditInfo:
      'Urejate urnik, ki se ponavlja. Ureditev tega urnika bo prepisala vse nadaljnje urnike za predlogo <0>{{templateName}}</0> za dan <1>{{dayName}}</1>.'
  },
  settingTabs: {
    general: 'Splošno',
    templateSchedules: 'Urnik predlog',
    templates: 'Predloge'
  },
  generalSettings: {
    description:
      'Prilagodite celostno podobo spletne strani za spletno naročanje. Dodajte pogoje uporabe ter določite omejitve preklicevanja in prestavljanja terminov.',
    chooseColorLabel: 'Barva organizacije',
    chooseColor: 'Izberite barvo',
    logoUrl: 'Logotip organizacije',
    logoDescription:
      'Izberite logotip, ki se bo pojavil na spletni strani. Priporočamo sliko v velikosti 1000 x 1000 px.',
    welcomeMessage: 'Pozdravno sporočilo',
    welcomeMessageDetails: 'Podrobnosti pozdravnega sporočila',
    timeUnit: {
      hours: 'ur',
      days: 'dni',
      weeks: 'tednov',
      months: 'mesecev'
    }
  },
  schedule: {
    warning:
      'Prosimo upoštevajte, da bodo od spodaj nastavljenih terminov končno vidni smo tisti, ki so tudi znotraj delovnega časa (če je le-ta nastavljen)' +
      ' posameznega zdravnika ali resursa. <0/> <1>Preveri delovni čas</1>'
  }
};
