import { formatting } from './date';

export default {
  itemFinder: {
    title: '$t(common:addData)',
    searchLabel: 'Filter items',
    groups: {
      templates: 'Templates'
    }
  },
  administrativeData: {
    flow: {
      title: 'Reason for visit'
    }
  },
  questionnaire: {
    title: 'Questionnaire'
  },
  vitalSigns: {
    bodyMassIndex: {
      title: 'Body mass index',
      labels: {
        underweight: 'Underweight',
        normalWeight: 'Normal weight',
        overweight: 'Overweight',
        obesity: 'Obesity'
      }
    }
  },
  saveAll: {
    invalidData: 'Incomplete or invalid data detected.'
  },
  calculationError: {
    calculationError: 'Error during calculation.',
    noWidgetValueMessage:
      'Error: Property {{ property }} from {{ widget }} is missing.',
    noFormValueMessage: 'Error: Property {{ property }} is missing.',
    noHistoryWidgetValueMessage: `Error: Property {{ property }} from {{ widget }} at {{ date, ${formatting.longDate} }} is missing.`,
    noTherapyStartDateMessage:
      'Error: Missing therapy start date (drug id: {{ drugCategoryIDs }}).',
    noMainDiagnosis: 'Error: No main diagnosis',
    noClinicalOutlook: 'Error: No clinical outlook'
  }
};
