import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import numbro from 'numbro';
import PropTypes from 'prop-types';

import { Span } from '../HtmlComponents';

import { Input } from '../../reactstrap';
import ConditionalWrap from '../layout/ConditionalWrap';
import Visible from '../layout/Visible';

export const getNumbroDisplayValue = (
  value,
  thousandSeparated = false,
  mantissa = 2,
  trimMantissa = true
) => {
  let displayValue = '';

  if (_.isFinite(value)) {
    displayValue = numbro(value).format({
      thousandSeparated,
      mantissa,
      trimMantissa
    });
  }

  return displayValue;
};

export const getNumbroRawValue = (stringValue, fallbackValue = null) => {
  if (!_.isString(stringValue) || stringValue === '') {
    return fallbackValue;
  }

  const parsedValue = numbro.unformat(stringValue);

  return _.isFinite(parsedValue) ? parsedValue : null;
};

export const InputAffixWrapper = (prefix, suffix) => (children) => (
  <div className={classNames('input-affix-wrapper')}>
    <Visible
      visible={!_.isEmpty(prefix)}
      component={Span}
      className={'prefix-container'}
    >
      {prefix}
    </Visible>
    <Visible
      visible={!_.isEmpty(suffix)}
      component={Span}
      className={'suffix-container'}
    >
      {suffix}
    </Visible>
    {children}
  </div>
);

/**
 * The common Numeric component
 *
 * @example ./__examples__/I18nNumericInput.md
 */
const I18nNumericInput = ({
  fallbackValue,
  value,
  disabled,
  onChange,
  className,
  prefix,
  suffix,
  mantissa,
  thousandsSeparated,
  trimMantissa,
  ...props
}) => {
  const [prevValue, setPrevValue] = useState(value);
  const [internalValue, setInternalValue] = useState(null);
  let displayValue = internalValue;

  const getDisplayValue = useCallback(
    (newValue) =>
      getNumbroDisplayValue(
        newValue,
        thousandsSeparated,
        mantissa,
        trimMantissa
      ),
    [mantissa, thousandsSeparated, trimMantissa]
  );

  useEffect(() => {
    if (prevValue !== value) {
      setInternalValue(getDisplayValue(value));
      setPrevValue(value);
    }
  }, [getDisplayValue, prevValue, value]);

  if (_.isNull(internalValue)) {
    displayValue = getDisplayValue(value);
  }

  return disabled ? (
    displayValue
  ) : (
    <ConditionalWrap
      condition={!_.isEmpty(prefix) || !_.isEmpty(suffix)}
      wrap={InputAffixWrapper(prefix, suffix)}
    >
      <Input
        {...props}
        className={classNames(className, {
          'input-prefix': !_.isEmpty(prefix),
          'input-suffix': !_.isEmpty(suffix)
        })}
        value={displayValue}
        onChange={(event) => {
          const newValue = event.target.value;
          const parsedValue = getNumbroRawValue(
            newValue.toString(),
            fallbackValue
          );

          setInternalValue(newValue);

          setPrevValue(parsedValue);
          onChange(parsedValue);
        }}
      />
    </ConditionalWrap>
  );
};

I18nNumericInput.propTypes = {
  /** Function that fires when the value changes and passes standard Event */
  onChange: PropTypes.func.isRequired,
  /** The actual value in the input */
  value: PropTypes.number,
  /** Can add extra class/es to the input */
  className: PropTypes.string,
  /** A prefix inside the input */
  prefix: PropTypes.string,
  /** A suffix inside the input */
  suffix: PropTypes.string,
  /** Disables the input */
  disabled: PropTypes.bool,
  /** Max length of mantissa */
  mantissa: PropTypes.number,
  /** Force separator on thousands */
  thousandsSeparated: PropTypes.bool,
  /** Default fallback value if value is '' (empty string) */
  fallbackValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Trim trailing zeros of mantissa */
  trimMantissa: PropTypes.bool
};

I18nNumericInput.defaultProps = {
  value: null,
  className: undefined,
  prefix: undefined,
  suffix: undefined,
  disabled: false,
  mantissa: undefined,
  thousandsSeparated: false,
  fallbackValue: undefined,
  trimMantissa: true
};

export default I18nNumericInput;
