import PropTypes from 'prop-types';

import {
  MOSHI_COLOR_DANGER_NAME,
  MOSHI_COLOR_DEFAULT_NAME,
  MOSHI_COLOR_SUCCESS_NAME,
  MOSHI_COLOR_WARNING_NAME,
  MOSHI_COLOR_YELLOW_NAME
} from '../../../utils/color';

export const JsonSchemaWidgetOptionsLabelsShape = {
  label: PropTypes.string,
  color: PropTypes.oneOf([
    MOSHI_COLOR_SUCCESS_NAME,
    MOSHI_COLOR_DEFAULT_NAME,
    MOSHI_COLOR_WARNING_NAME,
    MOSHI_COLOR_DANGER_NAME,
    MOSHI_COLOR_YELLOW_NAME
  ])
};

export const JsonSchemaWidgetOptionsShape = {
  enumOptions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any.isRequired,
        icon: PropTypes.string
      })
    )
  ]),
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  'x-labels': PropTypes.objectOf(
    PropTypes.shape(JsonSchemaWidgetOptionsLabelsShape)
  )
};

export const JsonSchemaWidgetShape = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape(JsonSchemaWidgetOptionsShape),
  readonly: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  autofocus: PropTypes.bool
};

export const JsonSchemaError = {
  errorTranslationCode: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  params: PropTypes.shape(),
  property: PropTypes.string,
  schemaPath: PropTypes.string,
  stack: PropTypes.string,
  empty: PropTypes.bool
};

export const JsonSchemaWidgetErrorShape = {
  widgetId: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape(JsonSchemaError)).isRequired
};
