import axios from 'axios';
import _ from 'lodash';
import { select, takeEvery, all, put } from 'redux-saga/effects';

import { getPatientTherapyListUrl } from '../../../utils/api/apiUrlUtils';
import {
  THERAPY_STATUS_ACTIVE,
  THERAPY_STATUS_PAUSED
} from '../../../utils/constants/therapyConstants';
import { apiCall } from '../../../utils/redux/sagaUtils';

import {
  GET_PATIENT_THERAPY_LIST,
  GET_PATIENT_THERAPY_LIST_TRIGGER,
  GET_PATIENT_THERAPY_LIST_SUCCESS,
  GET_PATIENT_THERAPY_LIST_SANITIZED,
  GET_PATIENT_THERAPY_LIST_UPDATED,
  SET_ACTIVE_PATIENT_THERAPY_LIST,
  SET_PAST_PATIENT_THERAPY_LIST,
  GET_PATIENT_THERAPY_TRIGGER
} from '../therapyTypes';

export function* getPatientTherapyListApiRequest(action) {
  const { patientId } = action;
  const state = yield select();

  const patientTherapyListUrl = getPatientTherapyListUrl(
    () => state,
    patientId
  );

  yield apiCall(GET_PATIENT_THERAPY_LIST, axios.get, [patientTherapyListUrl], {
    patientId
  });
}

export function* getPatientTherapyListSanitization(action) {
  const patientTherapyList = _.getNonEmpty(action, 'data', []);

  const activeTherapyList = _.filter(patientTherapyList, (item) =>
    _.includesAny(item.status, [THERAPY_STATUS_ACTIVE, THERAPY_STATUS_PAUSED])
  );

  const pastTherapyList = _.filter(
    patientTherapyList,
    (item) =>
      !_.includesAny(item.status, [
        THERAPY_STATUS_ACTIVE,
        THERAPY_STATUS_PAUSED
      ])
  );

  yield put({
    type: GET_PATIENT_THERAPY_LIST_SANITIZED,
    activeTherapyList,
    pastTherapyList
  });
}

export function* getPatientTherapyListStoreUpdate(action) {
  const { activeTherapyList, pastTherapyList } = action;

  yield all([
    put({
      type: SET_ACTIVE_PATIENT_THERAPY_LIST,
      therapyList: activeTherapyList
    }),
    put({
      type: SET_PAST_PATIENT_THERAPY_LIST,
      therapyList: pastTherapyList
    })
  ]);
  yield put({ type: GET_PATIENT_THERAPY_LIST_UPDATED });
}

export function* getPatientTherapyDetailsForList(action) {
  const patientTherapyList = _.getNonEmpty(action, 'data', []);
  const patientId = _.getNonEmpty(action, 'additionalData.patientId', null);

  const actions = _.map(patientTherapyList, (therapy) =>
    put({
      type: GET_PATIENT_THERAPY_TRIGGER,
      patientId,
      therapyId: therapy.id
    })
  );

  yield all(actions);
}

export const patientTherapyListSagas = [
  takeEvery(GET_PATIENT_THERAPY_LIST_TRIGGER, getPatientTherapyListApiRequest),
  takeEvery(
    GET_PATIENT_THERAPY_LIST_SUCCESS,
    getPatientTherapyListSanitization
  ),
  takeEvery(
    GET_PATIENT_THERAPY_LIST_SANITIZED,
    getPatientTherapyListStoreUpdate
  ),

  takeEvery(GET_PATIENT_THERAPY_LIST_SUCCESS, getPatientTherapyDetailsForList)
];
