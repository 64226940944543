import _ from 'lodash';
import { createSelector } from 'reselect';

import { isAuthenticated } from '../../utils/jwt';

export const selectAuthStore = (store) => store.authentication;

export const selectTokenString = createSelector([selectAuthStore], (auth) =>
  _.getNonEmpty(auth, 'tokenString', '')
);

export const selectToken = createSelector([selectAuthStore], (auth) =>
  _.getNonEmpty(auth, 'token', {})
);

export const selectTokenExpiration = createSelector([selectToken], (token) =>
  _.getNonEmpty(token, 'exp', 0)
);

export const selectLoginError = createSelector([selectAuthStore], (auth) =>
  _.getNonEmpty(auth, 'loginError', null)
);

export const selectResetPasswordError = createSelector(
  [selectAuthStore],
  (auth) => _.getNonEmpty(auth, 'resetPasswordError', null)
);

export const selectOrganizationIdFromToken = createSelector(
  [selectToken],
  (token) => _.getNonEmpty(token, 'organizationID', null)
);

export const selectUserIdFromToken = createSelector([selectToken], (token) =>
  _.getNonEmpty(token, 'sub', null)
);

export const selectIsAuthenticated = createSelector(
  [selectTokenString],
  (tokenString) => isAuthenticated(tokenString)
);

export const selectIsUserSuperAdmin = createSelector(
  [selectAuthStore],
  (authStore) => _.getNonEmpty(authStore, 'isSuperAdmin', false)
);
