import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from './layout/Visible';

const RadioItemComponent = ({
  groupName,
  onChange,
  onBlur,
  value,
  label,
  selectedValue,
  displayAsButton,
  prefix,
  disabled,
  className,
  labelClassName,
  ...props
}) => {
  const isChecked = _.toString(selectedValue) === _.toString(value);

  return (
    <label
      key={label}
      className={classNames(className, 'radio form-control', {
        'radio-as-button': displayAsButton,
        checked: isChecked,
        disabled: disabled === true
      })}
    >
      <input
        {...props}
        type={'radio'}
        name={groupName}
        value={value}
        checked={isChecked}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span className={'radio-icon'} />
      <Visible visible={!_.isEmpty(prefix)}>{prefix}</Visible>
      <span className={classNames(labelClassName, 'radio-label')}>{label}</span>
    </label>
  );
};

RadioItemComponent.propTypes = {
  label: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  onBlur: PropTypes.func,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  displayAsButton: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  prefix: PropTypes.any
};

RadioItemComponent.defaultProps = {
  onChange: undefined,
  value: undefined,
  groupName: 'radio-group',
  selectedValue: null,
  onBlur: undefined,
  className: undefined,
  labelClassName: undefined,
  disabled: undefined,
  displayAsButton: false,
  prefix: undefined
};

export default RadioItemComponent;
