/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { InputGroup } from 'reactstrap';

import PropTypes from 'prop-types';

const InputGroupWrapper = ({ children, size, className, id }) => (
  <InputGroup size={size} className={className} id={id}>
    {children}
  </InputGroup>
);

InputGroupWrapper.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};

InputGroupWrapper.defaultProps = {
  children: undefined,
  size: undefined,
  className: undefined,
  id: undefined
};

export default InputGroupWrapper;
