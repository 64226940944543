import _ from 'lodash';

import { intervalGroupsPattern } from './regexPatterns';

/**
 * Tries to parse interval of a type intervalGroupsPattern and converts it to object with computable bounds and values
 *
 * @param {string} interval string that matches intervalGroupsPattern
 * @param {any} data any data associated with interval
 *
 * @returns {Interval} parsed interval object:
 * {
 *     interval: string matching intervalGroupsPattern
 *     data: data inputted into functions
 *     isLowerIncluded: boolean, is lower bound included in interval
 *     isUpperIncluded: boolean, is upper bound included in interval
 *     lowerValue: number, lower bound
 *     upperValue: number, upper bound
 * }
 */
export const parseInterval = (interval, data) => {
  const patternMatch = interval.match(intervalGroupsPattern);

  if (!patternMatch) {
    return null;
  }

  const [, lowerBound, lowerValue, upperValue, upperBound] = patternMatch;

  return {
    interval,
    data,
    isLowerIncluded: lowerBound === '[',
    isUpperIncluded: upperBound === ']',
    lowerValue: lowerValue === '-INF' ? -Infinity : Number(lowerValue),
    upperValue: upperValue === 'INF' ? Infinity : Number(upperValue)
  };
};

/**
 * Perform parseInterval on key-value map
 * @param {Object} keyValues key-value map { 'interval': value, 'interval2': value2 }
 *
 * @returns {Interval[]} array of parsed intervals
 */
export const parseIntervals = (keyValues) =>
  Object.entries(keyValues)
    .map(([key, value]) => parseInterval(key, value))
    .filter((interval) => Boolean(interval));

/**
 * Checks whether value is included in interval
 * @param {Interval} interval interval to be checked against
 * @param {number} value numeric value
 */
export const isValueInInterval = (interval, value) => {
  if (!_.isNumber(value)) return false;
  if (interval.isLowerIncluded && interval.lowerValue === value) return true;
  if (interval.isUpperIncluded && interval.upperValue === value) return true;

  const largerThanLowerBound = value > interval.lowerValue;
  const smallerThanUpperBound = value < interval.upperValue;

  return largerThanLowerBound && smallerThanUpperBound;
};

/**
 * Gets `data` property from matching interval that includes a number
 * @param {Interval[]} intervals list of parsed intervals
 * @param {number} value numeric value
 */
export const getMatchingIntervalData = (intervals, value) => {
  const matchingInterval = _.find(intervals, (interval) =>
    isValueInInterval(interval, value)
  );

  if (!matchingInterval) {
    return null;
  }

  return matchingInterval.data;
};
