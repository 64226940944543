import React from 'react';
import { toast, Slide } from 'react-toastify';

import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { doesTranslateKeyExist, translateKey as translate } from '../i18n';

import { EMPTY_ACTION } from '../redux/common/actionTypes';

import Icon from '../components/common/Icon';
import ToastWithButtons from '../components/common/alert/ToastWithButtons';

import * as alert from './alert';
import { moshiClassNames } from './gen';
import logger from './logger';

export const ALERT_TYPE_DEFAULT = 'default';
export const ALERT_TYPE_PRIMARY = 'info';
export const ALERT_TYPE_ERROR = 'danger';
export const ALERT_TYPE_WARNING = 'warning';
export const ALERT_TYPE_SUCCESS = 'success';

export const ENTITY_STATUS_ACTIVE = 'active';
export const ENTITY_STATUS_INACTIVE = 'inactive';
export const ENTITY_STATUS_DELETED = 'deleted';

export const FULL_WIDTH_TOAST_ID = 'full-width-toast';
export const FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_ID =
  'full-width-with-default-z-index-toast';
export const FULL_WIDTH_TOAST_CONTAINER_ID = 'full-width-toast-container';
export const FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_CONTAINER_ID =
  'full-width-toast-with-default-z-index-container';
export const DEFAULT_TOAST_CONTAINER_ID = 'default-width-toast';
export const MOSHI_FULL_WIDTH_TOAST_CLASS = 'moshi-full-width-toast';

export const toastyConfig = {
  position: 'bottom-left',
  limit: 3,
  newestOnTop: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Slide,
  containerId: DEFAULT_TOAST_CONTAINER_ID
};

export const fullWidthToastConfig = {
  position: 'bottom-center',
  autoClose: false,
  closeOnClick: false,
  draggable: false,
  containerId: FULL_WIDTH_TOAST_CONTAINER_ID,
  transition: Slide,
  className: 'moshi-full-width-toast-container',
  toastClassName: MOSHI_FULL_WIDTH_TOAST_CLASS
};

export const fullWidthToastWithDefaultZIndexConfig = {
  position: 'bottom-center',
  autoClose: false,
  closeOnClick: false,
  draggable: false,
  containerId: FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_CONTAINER_ID,
  transition: Slide,
  className: 'moshi-full-width-toast-container default-z-index',
  toastClassName: MOSHI_FULL_WIDTH_TOAST_CLASS
};

export const getErrorMessageCode = (error) => {
  const responseData = _.get(error, 'response.data', { type: null });
  const responseCode = _.get(responseData, responseData.code, 'badRequest');
  const responseValue = _.get(responseData, responseData.type, 'general');

  // TODO: Ignored errors to show alert. Remove once they are converted to validationErrors
  const ignoredResponses = ['invalidPassword'];

  let errorMessageCode = '';

  switch (responseData.type) {
    case 'message':
      if (!_.includes(ignoredResponses, responseValue)) {
        if (_.includes(responseValue, ' ')) {
          errorMessageCode = `error:${responseCode}`;
        } else {
          errorMessageCode = `error:${responseValue}`;
        }
      }
      break;
    case 'validationErrors': {
      const validationErrorKey = _.getNonEmpty(
        responseData,
        'validationErrors[0].key',
        ''
      );

      switch (validationErrorKey) {
        case 'user.email':
          errorMessageCode = 'error:user.email.conflict';
          break;
        case 'organization.slug':
          errorMessageCode = 'error:organization.slug.conflict';
          break;
        case 'patientID':
          const validationErrorMessage = _.getNonEmpty(
            responseData,
            'validationErrors[0].message',
            ''
          );
          const translateKey = `error:${validationErrorMessage}`;

          errorMessageCode = doesTranslateKeyExist(translateKey)
            ? translateKey
            : `error:${responseCode}`;
          break;
        default:
          errorMessageCode = `error:${responseCode}`;
          break;
      }
      break;
    }
    default:
      errorMessageCode = `error:${responseCode}`;
      break;
  }

  return errorMessageCode;
};

const getToastifyType = (alertType) => {
  let toastifyType;

  switch (alertType) {
    case ALERT_TYPE_PRIMARY:
      toastifyType = toast.info;

      break;
    case ALERT_TYPE_WARNING:
      toastifyType = toast.warning;

      break;
    case ALERT_TYPE_SUCCESS:
      toastifyType = toast.success;

      break;
    case ALERT_TYPE_ERROR:
      toastifyType = toast.error;
      break;
    case ALERT_TYPE_DEFAULT:
      toastifyType = toast;

      break;
    default:
      throw new Error(`${alertType} type not supported`);
  }

  return toastifyType;
};

/**
 * showAlert
 * @description The core helper for showing the toast
 * @param {String} message Translation key
 * @param {Object} [config]
 * @param {String} [config.alertType=ALERT_TYPE_DEFAULT] Type of an alert
 * @param {Number} [config.closeIn=5] Timer in seconds for how long the toast persists, 0 - means it does not go away
 * @param {Object} [config.translationParams=undefined] Params passed to translate() function
 * @param {String} [config.toastId=Translated message] The toast identifier to prevent duplications
 * @param {String} [config.containerId=DEFAULT_TOAST_CONTAINER_ID] Container to which the notification will be sent to
 * @param {Object[]} [config.buttons=undefined] An array object button definitions
 * @param {Boolean|Node} [config.closeButton=CloseIcon] You can either overwrite the icon, or by passing "false" hide it altogether
 * @param {Object} [config.otherParams={}] Any other params passed directly to toastify
 * @example
 *  showAlert('some.translate.message', { alertType: 'danger', closeIn: 2, translationParams: { some: 'param'} };
 */
// eslint-disable-next-line id-blacklist
export const showAlert = (message, config = {}) => {
  const { translatedMessage, alertType } = commonAlertProps(message, config);
  const closeIn = _.getNonEmpty(config, 'closeIn', 5);
  const otherParams = _.getNonEmpty(config, 'otherParams', {});
  const buttons = _.getNonEmpty(config, 'buttons', []);
  const toastId = _.getNonEmpty(config, 'toastId', translatedMessage);
  const containerId = _.getNonEmpty(
    config,
    'containerId',
    DEFAULT_TOAST_CONTAINER_ID
  );
  const className = _.getNonEmpty(otherParams, 'className');

  if (_.isEmpty(translatedMessage)) {
    return;
  }

  const getToastify = getToastifyType(alertType);
  let toastContent = translatedMessage;

  if (!_.isEmptySafe(buttons)) {
    toastContent = (
      <ToastWithButtons message={translatedMessage} buttons={buttons} />
    );
  }

  return getToastify(toastContent, {
    autoClose:
      closeIn > 0
        ? moment.duration(closeIn, 'seconds').asMilliseconds()
        : false,
    toastId,
    containerId,
    closeButton: (
      <Icon
        name={'close'}
        size={'md'}
        className={classNames({
          'text-color-black': alertType === ALERT_TYPE_DEFAULT
        })}
      />
    ),
    ...otherParams,
    className: moshiClassNames(className, {
      'toast-unclickable':
        _.getNonEmpty(otherParams, 'closeButton', true) === false
    })
  });
};

/**
 * showFullWidthAlert
 * @description The core helper for showing the full width toast
 * @param message
 * @param {Object} [config]
 * @param {String} [config.alertType=ALERT_TYPE_DEFAULT] Type of an alert
 * @param {String} [config.closeCallback] Callback when closing the alert
 * @param {Object} [config.translationParams=undefined] Params passed to translate() function
 * @example
 *  showFullWidthAlert('some.translate.message', { alertType: 'danger', translationParams: { some: 'param'}, closeCallback:() => {console.log('woot')} };
 */
export const showBottomPanelAlert = (message, config = {}) => {
  const closeCallback = _.getNonEmpty(config, 'closeCallback', undefined);

  const alertParams = commonAlertProps(message, config);

  if (_.isEmpty(alertParams.translatedMessage)) {
    return;
  }

  const showToast = getToastifyType(alertParams.alertType);

  showToast(alertParams.translatedMessage, {
    containerId: FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_CONTAINER_ID,
    toastId: FULL_WIDTH_TOAST_WITH_DEFAULT_Z_INDEX_ID,
    // eslint-disable-next-line react/prop-types
    closeButton: ({ closeToast }) => (
      <Icon
        name={'close'}
        size={'lg'}
        className={classNames({
          'text-color-black': alertParams.alertType === ALERT_TYPE_DEFAULT
        })}
        onClick={() => {
          if (_.isFunction(closeCallback)) {
            closeCallback();
          }
          closeToast();
        }}
      />
    )
  });
};

const commonAlertProps = (message, config) => {
  const translationParams = _.getNonEmpty(
    config,
    'translationParams',
    undefined
  );

  const alertType = _.getNonEmpty(config, 'alertType', ALERT_TYPE_DEFAULT);
  let translatedMessage = null;

  if (doesTranslateKeyExist(message)) {
    translatedMessage = translate(message, translationParams);
  } else {
    logger.error(`Translation does not exist: "${message}"`);
  }

  return {
    translationParams,
    alertType,
    translatedMessage
  };
};

/**
 * showAlertSuccess
 * @description Shows success toast
 * @param {String} message Translation key
 * @param {Object} [config={closeIn: 3}]
 * @param {Object} [config.closeIn] Timer in seconds for how long the toast persists
 * @param {Object} [config.translationParams=undefined] Params passed to translate() function
 * @example
 *  showAlert('some.translate.message', { closeIn: 2, translationParams: { some: 'param'} };
 */
export const showAlertSuccess = (message, config = { closeIn: 3 }) =>
  alert.showAlert(message, { ...config, alertType: ALERT_TYPE_SUCCESS });

/**
 * showAlertError
 * @description Shows error toast
 * @param {String} message Translation key
 * @param {Object} [config]
 * @param {Object} [config.closeIn] Timer in seconds for how long the toast persists
 * @param {Object} [config.translationParams=undefined] Params passed to translate() function
 * @example
 *  showAlert('some.translate.message', { closeIn: 2, translationParams: { some: 'param'} };
 */
export const showAlertError = (message, config) =>
  alert.showAlert(message, { ...config, alertType: ALERT_TYPE_ERROR });

/**
 * showAlertWarning
 * @description Shows warning toast
 * @param {String} message Translation key
 * @param {Object} [config]
 * @param {Object} [config.closeIn] Timer in seconds for how long the toast persists
 * @param {Object} [config.translationParams=undefined] Params passed to translate() function
 * @example
 *  showAlert('some.translate.message', { closeIn: 2, translationParams: { some: 'param'} };
 */
export const showAlertWarning = (message, config) =>
  alert.showAlert(message, { ...config, alertType: ALERT_TYPE_WARNING });

/**
 * showAlertInfo
 * @description Shows info toast
 * @param {String} message Translation key
 * @param {Object} [config]
 * @param {Object} [config.closeIn] Timer in seconds for how long the toast persists
 * @param {Object} [config.translationParams=undefined] Params passed to translate() function
 * @example
 *  showAlert('some.translate.message', { closeIn: 2, translationParams: { some: 'param'} };
 */
export const showAlertInfo = (message, config) =>
  alert.showAlert(message, { ...config, alertType: ALERT_TYPE_PRIMARY });

/**
 * This bottom alert does not have a timer to dismiss it, thats why we have to
 * dismiss it manually with `hideAlertWithId`.
 * @param message
 * @param closeCallback
 */
export const showBottomPanelInfoAlert = (
  message,
  closeCallback,
  translationParams = {}
) => {
  alert.showBottomPanelAlert(message, {
    closeCallback,
    translationParams,
    alertType: ALERT_TYPE_PRIMARY
  });
};

export const showMissingOrganizationIdError = () => {
  alert.showAlert('error:missingOrganizationId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingUserIdError = () => {
  alert.showAlert('error:missingUserId', { alertType: ALERT_TYPE_ERROR });

  return EMPTY_ACTION;
};

export const showMissingLocationIdError = () => {
  alert.showAlert('error:missingLocationId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingEncounterIdError = () => {
  alert.showAlert('error:missingEncounterId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingCommentIdError = () => {
  alert.showAlert('error:missingCommentId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingAssistRequestIdError = () => {
  alert.showAlert('error:missingAssistRequestId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingPatientIdError = () => {
  alert.showAlert('error:missingPatientId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingBillingUnitIdError = () => {
  alert.showAlert('error:missingBillingUnitId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showMissingRegistryIdError = () => {
  alert.showAlert('error:missingRegistryId', {
    alertType: ALERT_TYPE_ERROR
  });

  return EMPTY_ACTION;
};

export const showInvalidDateError = () => {
  alert.showAlert('error:invalidDate', { alertType: ALERT_TYPE_ERROR });

  return EMPTY_ACTION;
};

export const hideAlertWithId = (id) => {
  toast.dismiss(id);
};
