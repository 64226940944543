import {
  BILL_TYPE_ADVANCE,
  BILL_TYPE_CREDIT_NOTE,
  BILL_TYPE_DELIVERY_NOTE,
  BILL_TYPE_ESTIMATE,
  BILL_TYPE_INVOICE,
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_ISSUED,
  INVOICE_STATUS_OVERDUE,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_UNPAID,
  INVOICE_STATUS_DELETED,
  INVOICE_STATUS_USED,
  INVOICE_STATUS_PARTIALLY_USED,
  EXPORT_COLUMN_ITEM_QUANTITY,
  EXPORT_COLUMN_ITEM_TAX,
  EXPORT_COLUMN_ITEM_PRICE,
  EXPORT_COLUMN_ITEM_TOTAL,
  EXPORT_COLUMN_ITEM_CATEGORY_NAME,
  EXPORT_COLUMN_ITEM_NAME,
  EXPORT_COLUMN_ISSUED_AT,
  EXPORT_COLUMN_DOCUMENT_DATE,
  EXPORT_COLUMN_CREATED_AT,
  EXPORT_COLUMN_DUE_DATE,
  EXPORT_COLUMN_PAYMENT_TYPE,
  EXPORT_COLUMN_TOTAL_PAID,
  EXPORT_COLUMN_PAID,
  EXPORT_COLUMN_TAX,
  EXPORT_COLUMN_TOTAL_WITHOUT_TAX,
  EXPORT_COLUMN_TOTAL,
  EXPORT_COLUMN_CANCELED,
  EXPORT_COLUMN_DOCTOR_NAME,
  EXPORT_COLUMN_DOCTOR_ID,
  EXPORT_COLUMN_CLIENT_REGISTRATION_NUMBER,
  EXPORT_COLUMN_CLIENT_TAX_NUMBER,
  EXPORT_COLUMN_CLIENT_NAME,
  EXPORT_COLUMN_CLIENT_ADDRESS,
  EXPORT_COLUMN_PATIENT_NUMBER,
  EXPORT_COLUMN_TYPE,
  EXPORT_REFERENCE_NUMBER,
  EXPORT_COLUMN_NUMBER
} from '../../utils/billing';
import {
  PAYMENT_TYPE_ADVANCE,
  PAYMENT_TYPE_BANK,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_COUPON,
  PAYMENT_TYPE_ONLINE,
  PAYMENT_TYPE_OTHER,
  PAYMENT_TYPE_PAYPAL
} from '../../utils/data/billing';

import { formatting } from './date';

export default {
  types: {
    [BILL_TYPE_INVOICE]: '$t(billing:invoices.invoice)',
    [BILL_TYPE_ESTIMATE]: '$t(billing:estimates.estimate)',
    [BILL_TYPE_ADVANCE]: '$t(billing:advances.advance)',
    [BILL_TYPE_CREDIT_NOTE]: '$t(billing:creditNotes.creditNote)',
    [BILL_TYPE_DELIVERY_NOTE]: '$t(billing:deliveryNotes.deliveryNote)'
  },
  export: {
    typeLabel: 'What do you wish to export?',
    columnsLabel: 'Which columns would you like in the export?',
    validation: {
      documentTypesLength: 'You need to select at least 1 document type'
    },
    exportBillingItems: 'Export billing items',
    itemsWithCategories: 'Items with categories',
    includeColumnsDescription: `<0><1>List of columns to be included in the file. All columns will be included if nothing is selected.</1> <2> Columns are
      sorted in the same order as in this selection.</2> <2>"Item" columns will only be included if "Items with categories" is selected.</2></0> `
  },
  invoices: {
    title: 'Invoices',
    invoice: 'Invoice',
    new: 'New invoice',
    view: 'Invoice: {{ number }}',
    viewDraft: 'Invoice draft: {{ number }}',
    create: 'Create invoice',
    createFromDraft: 'Create invoice from draft',
    issue: 'Issue invoice',
    issueWithoutPayment: 'Issue without payment',
    issuePartiallyPaid: 'Issue partially paid',
    save: 'Save invoice',
    saveAsDraft: 'Save draft',
    savingBill: 'Saving invoice...',
    savingDraft: 'Saving draft...',
    issuingBill: 'Issuing invoice...',
    referencedDocuments: 'Referenced documents:',
    exportTo: 'Export to invoice',
    draft: 'Draft',
    void: 'Void',
    details: 'Details',
    number: 'Number',
    previousNumber: 'Previous invoice number: {{ number }}',
    dateIssued: 'Date issued',
    totalAmount: 'Total amount',
    dueDate: 'Due date',
    dueDateDaysPlaceholder: 'Due date days',
    dueDays: '{{number}} days',
    dateService: 'Date of service',
    paid: 'Paid',
    total: 'Total',
    totalWithTax: 'Total with tax',
    totalPerTaxRate: 'Tax {{ taxRate }}% of {{ price }} {{ currency }}',
    billedTo: 'Billed to:',
    payment: 'Payment',
    payments: 'Payments',
    addPayment: 'Add payment',
    editPayment: 'Save',
    paymentType: 'Payment type',
    disabledPayments:
      'For {{billType}} issued before today, you can only add non-cash payments (bank transfer and PayPal).',
    disabledPaymentsTooltip:
      'If you wish to add a payment type which is disabled, you need to cancel the billing document and issue a new one',
    amount: 'Amount',
    remainingDue: 'Remaining due:',
    noPaymentsYet: 'No payments added to the invoice yet.',
    confirmVoiding: {
      title: 'Void {{ entityName }}',
      message:
        'Are you sure you want to void this {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    confirmRemoving: {
      title: 'Cancel {{ entityName }}',
      message:
        'Are you sure you want to cancel this {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    confirmDeleting: {
      title: 'Delete {{ entityName }}',
      message:
        'Are you sure you want to delete this {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    paymentTypes: {
      [PAYMENT_TYPE_CASH]: 'Cash',
      [PAYMENT_TYPE_BANK]: 'Bank',
      [PAYMENT_TYPE_CARD]: 'Card',
      [PAYMENT_TYPE_PAYPAL]: 'Paypal',
      [PAYMENT_TYPE_COUPON]: 'Coupon',
      [PAYMENT_TYPE_ONLINE]: 'Online',
      [PAYMENT_TYPE_OTHER]: 'Other',
      [PAYMENT_TYPE_ADVANCE]: 'Advance'
    },
    status: {
      title: 'Status',
      [INVOICE_STATUS_ISSUED]: 'Issued',
      [INVOICE_STATUS_CANCELED]: 'Canceled',
      [INVOICE_STATUS_PAID]: 'Paid',
      [INVOICE_STATUS_PARTIALLY_PAID]: 'Partially paid',
      [INVOICE_STATUS_DRAFT]: 'Draft',
      [INVOICE_STATUS_OVERDUE]: 'Overdue',
      [INVOICE_STATUS_UNPAID]: 'Unpaid',
      [INVOICE_STATUS_DELETED]: 'Deleted',
      [INVOICE_STATUS_USED]: 'Used',
      [INVOICE_STATUS_PARTIALLY_USED]: 'Partially used'
    },
    issuer: 'Issued by',
    zoi: 'ZOI',
    eor: 'EOR',
    validation: {
      itemsLength: 'At least one item or service must be selected.',
      total: 'Invoice must not have a negative total sum',
      amountHigherThanAdvance:
        'Amount is higher than available credit from advance'
    },
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any invioces yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `,
    noPatientSelected: 'No patient is added to this invoice.',
    alerts: {
      itemAdded: 'Item or service succesfully added.',
      invoiceCreated: 'Invoice was successfully created.',
      draftCreated: 'Invoice draft was successfully created.',
      invoiceUpdated: 'Invoice was successfully updated.',
      draftUpdated: 'Invoice draft was successfully updated.',
      invoiceVoided: 'Invoice was successfully voided.',
      draftRemoved: 'Invoice draft was successfully deleted.',
      invoiceRemoved: 'Invoice was successfully canceled.',
      paymentAdded: 'Payment was successfully added.',
      paymentRemoved: 'Payment was successfully removed.',
      pdfSentViaEmail: 'PDF was successfully sent to the chosen e-mail address.'
    }
  },
  settings: {
    title: 'Invoice settings',
    billingUnit: 'Business',
    newBillingUnit: 'New business',
    addBillingUnit: 'Add business',
    billingUnitDeactivated: '{{name}} (deactivated)',
    billingUnitInfo: `
      <0>$t(settings:account.taxNumber)</0> {{ companyDetails.taxNumber }} <0>∙</0>
      <0>$t(settings:account.companyId)</0> {{ companyDetails.companyId }} <0>∙</0>
      <0>$t(billing:settings.general.iban)</0> {{ companyDetails.iban }}`,
    removeBillingUnitTitle: 'Remove business',
    removeBillingUnitMessage:
      'Are you sure you want to remove this business: <0>{{ locationName }}</0>?',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any businesses yet.</0> <1></1>
      <0>Let’s start by creating a new business.</0>
    `,
    billType: 'Bill type',
    customization: {
      title: 'Customization',
      textSettings: 'Default text settings',
      logo: 'Logo',
      signature: 'Signature',
      paymentDetails: 'Payment details',
      missingObjects: {
        noLogo: '$t(billing:settings.customization.logo) not set',
        noSignature: '$t(billing:settings.customization.signature) not set'
      },
      texts: {
        description:
          'Changes of default text will only be visible on new documents. To change texts on previously saved documents you must edit each document separately.',
        localeNotSet:
          'You need to set default locale to be able to modify texts',
        bodyText: 'Body Text',
        invoiceNote: 'Text on invoice',
        estimateNote: 'Text on estimate',
        advanceNote: 'Text on advance invoice',
        creditNoteNote: 'Text on credit note',
        signature: 'Signature',
        header: 'Header',
        footer: 'Footer',
        legend: `
        <0>Legend</0>
        <1>
          The following smart codes can be entered into texts and will be
          automatically swapped with their respective data:
        </1>
        <1>[organization name] - name of the organization.</1>
        <1>[document number] - number of current document.</1>
        <1>[IBAN] - IBAN number.</1>
        <1>[SWIFT] - SWIFT reference.</1>
        <1>[bank] - name of your bank.</1>
        <1>[address] - address.</1>
        <1>[address2] - second address.</1>
        <1>[zip] - zip / post code.</1>
        <1>[city] - city.</1>
        <1>[country] - country of company.</1>
        <1>[tax number] - tax number of company.</1>
        <1>[company number] - company registration number.</1>
        <1>
          [document type] - name of document type (ie. invoice, estimate,
          advance, etc.)
        </1>
        <2>Why use smart codes?</2>
        <1>
          With smart codes your document will always have the latest
          information.
        </1>
        <1>
          For example if you use the code [IBAN] and sometime in the future
          change your company's bank account number in the settings, you
          wont have to also update the default document texts.
        </1>
        `
      }
    },
    locale: 'Localization',
    general: {
      title: 'General settings',
      iban: 'IBAN',
      ibanInput: '$t(billing:settings.general.iban): {{ iban }}',
      ibanPlaceholder: 'GB19123456789',
      swift: 'SWIFT',
      swiftPlaceholder: 'LOYD',
      bank: 'Bank name',
      bankPlaceholder: 'Lloyds Banking Group',
      defaultLocale: 'Default language',
      currency: 'Currency',
      noTax: 'None',
      taxNumber: 'Tax number',
      taxId: 'VAT ID',
      taxNumberPlaceholder: '123456789',
      taxSubject: 'VAT subject',
      companyId: 'Registration number',
      companyIdPlaceholder: '1234567890',
      invoiceDueDays: 'Invoice due days',
      invoiceDueDaysPlaceholder: '30',
      legalRepresentative: 'Legal representative',
      estimateValidTillDays: 'Estimate valid till days',
      estimateValidTillDaysPlaceholder: '30',
      confirmIssuingBill: 'Yes, issue the {{ billType, lowercase }}',
      cancelIssuingBill: 'No, take me back'
    },
    fiscalization: {
      title: 'Fiscalization',
      location: 'Location',
      device: 'Device',
      selectDevices: 'Select devices',
      toggleEnableFiscalization: 'Enable fiscalization',
      disabledFiscalizationWarning:
        'You need to fill out the [basic settings] first, before continuing to set up fiscalization',
      fiscalize: 'Fiscalize',
      section: {
        certificate: '1. Certificate',
        businessPremises: '2. Business premises',
        electronicDevices: '3. Electronic devices',
        numbering: '4. Numbering strategy',
        act: '5. Act',
        activate: '6. Activate'
      },
      numbering: {
        description:
          'You can choose to number your invoices either by your business premise or by your electronic devices.',
        dropdownLabel: 'Numbering type',
        byPremise: 'By business premise',
        byDevice: 'By electronic device'
      },
      certificate: {
        upload: 'Certificate',
        password: '$t(common:password)',
        description: `
        <0>
          One of the steps required to enable fiscal verification is to
          obtain a certificate issued by FURS. You can obtain a
          certificate on eDavki platform.
        </0>
        <0>
          If you are not a user of eDavki and you dont plan on signing up
          there, it is also possible to authorize another person (for
          example, your accountant) to do it for you.
        </p>
      `
      },
      status: {
        fiscalised: 'Fiscalised',
        notFiscalised: 'Not fiscalised'
      },
      businessPremises: {
        add: 'Add new premise',
        label: 'Name',
        labelLong: 'Premise ID',
        zipAndTown: '$t(common:address.zipShort) & $t(common:address.town)',
        street: 'Street',
        municipality: 'Municipality',
        houseNumber: 'Number',
        houseNumberAdditional: 'Additional',
        cadastralNumber: 'Cadastral',
        cadastralNumberLong: 'Cadastral number',
        buildingNumber: 'Building',
        buildingNumberLong: 'Building number',
        buildingSection: 'Section',
        buildingSectionLong: 'Building section',
        description: `
        <0>
          Business premise is any moveable or non-movable space where you continuously,
          occasionally or temporarily issue receipts for the supply of goods or services and receive payment in cash.
          The business premise should have unique id and should be defined in detail at least to the address level.
        </0>
        <1>
          Cadastral information about premises can be found on the following link:
        </1>
        <2>https://eprostor.gov.si/javni/</2>
        `
      },
      electronicDevices: {
        add: 'Add new device',
        label: 'Device name',
        description:
          'Electronic device is individual computer, cash register or other device which issues invoices in your business premise.'
      },
      act: {
        description: `
        <0>
          Below we have pre populated the act that an organization is required to possess to start issuing
          fiscally verified invoices. Please copy the generated text, make any required changes and print it.
        </0>
        <1>
          It is also required to display a notice about requirement of taking and saving an invoice,
          the notice can be found on the following link:
        </1>
        <2>http://www.fu.gov.si/nadzor/podrocja/davcne_blagajne_in_vezane_knjige_racunov_vkr</2>
        `
      },
      activate: {
        save: 'Save settings and activate',
        description: `
        <0>
          The following button will activate fiscal verification, once active all your invoices, advances
          and credit notes will automatically be cryptographically signed and sent to FURS once saved.
        </0>
        `
      },
      invoiceNumberingType: 'Numbering type',
      invoiceNumberingByPremise: 'By business premise',
      invoiceNumberingByDevice: 'By electronic device',
      invoiceNumberingByDescription: `
      <0>$t(billing:settings.fiscalization.invoiceNumberingByDevice)</0>
      <1>
        Your invoices will be numbered consecutively for each business
        premise.
      </1>
      <0>$t(billing:settings.fiscalization.invoiceNumberingByDevice)</0>
      <1>
        Your invoices will be numbered consecutively for each electronic
        device.
      </1>
      `
    }
  },
  bulkItems: {
    title: 'Items and services',
    message: 'Are you sure you want to delete items and services?'
  },
  items: {
    title: 'Items and services',
    add: 'Add item / service',
    newItem: 'New item or service',
    item: 'Item',
    category: '$t(settings:billing.category.title)',
    description: 'Description',
    unit: 'Unit',
    price: 'Net price',
    discount: 'Discount',
    total: 'Total',
    sku: 'SKU',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any items and services yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `,
    alerts: {
      billingItemCreated: 'Item or service was successfully created.',
      billingItemUpdated: 'Item or service was successfully updated.',
      billingItemExists: 'Item or service with the same name already exists.',
      billingItemRemoved: 'Item or service was successfully removed.',
      billingItemsRemoved: 'Items or services were successfully removed',
      billingItemsBatchUpdated: 'Items or services were successfully updated.'
    },
    editingItems: 'You are editing these items/services:',
    editingValues: 'Please edit values that you want to change:',
    searchItems: 'Search items and services',
    tooltip: {
      itemEnabled:
        'Disabling the item or service will hide it from the menus, but will still keep the item on the list. You can enable it again at any time.'
    },
    groupBillingItems: 'Add up same items and services on the list'
  },
  taxes: {
    title: 'Taxes',
    tax: 'Tax',
    add: 'Add tax',
    newTax: 'New tax',
    abbreviation: 'Abbreviation',
    rate: 'Rate',
    taxValue: '{{ value }}%',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any taxes yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `
  },
  estimates: {
    title: 'Estimates',
    estimate: 'Estimate',
    new: 'New estimate',
    view: 'Estimate: {{ number }}',
    create: 'Create estimate',
    issue: 'Issue estimate',
    save: 'Save estimate',
    savingBill: 'Saving estimate...',
    issuingBill: 'Issuing estimate...',
    exportTo: 'Export to estimate',
    validUntil: 'Valid until',
    validUntilPlaceholder: 'Valid until days',
    previousNumber: 'Previous estimate number: {{ number }}',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any items and estimates yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `,
    noPatientSelected: 'No patient is added to this estimate.',
    alerts: {
      estimateCreated: 'Estimate was successfully created.',
      estimateUpdated: 'Estimate was successfully updated.',
      estimateRemoved: 'Estimate was successfully removed.'
    },
    validation: {
      total: 'Estimate must not have a negative total sum'
    }
  },
  advances: {
    title: 'Advances',
    advance: 'Advance',
    new: 'New advance',
    view: 'Advance: {{ number }}',
    create: 'Create advance',
    issue: 'Issue advance',
    save: 'Save advance',
    savingBill: 'Saving advance...',
    issuingBill: 'Issuing advance...',
    placeholder: 'Choose an advance',
    basedOn: 'Payment based on advance {{ number }}',
    exportTo: 'Export to advance',
    datePaid: 'Date of payment',
    previousNumber: 'Previous advance number: {{ number }}',
    advancePayment: 'Advance payment',
    addReferencingDocument: 'Add advance',
    noPaymentsYet: 'No payments added to the advance yet.',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any advances yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `,
    noPatientSelected: 'No patient is added to this advance.',
    unused: 'Unused:',
    alerts: {
      advanceCreated: 'Advance was successfully created.',
      advanceUpdated: 'Advance was successfully updated.',
      advanceVoided: 'Advance was successfully voided.',
      advanceRemoved: 'Advance was successfully canceled.'
    },
    validation: {
      total: 'Advance must not have a negative total sum'
    }
  },
  creditNotes: {
    title: 'Credit notes',
    creditNote: 'Credit note',
    new: 'New credit note',
    issue: 'Issue credit note',
    view: 'Credit note: {{ number }}',
    create: 'Create credit note',
    save: 'Save credit note',
    savingBill: 'Saving credit note...',
    issuingBill: 'Issuing credit note...',
    previousNumber: 'Previous credit note number: {{ number }}',
    noPaymentsYet: 'No payments added to the credit note yet.',
    emptyListText: `
      <0>Hmmm. Seems like you don’t have any credit notes yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
      `,
    noPatientSelected: 'No patient is added to this credit note.',
    alerts: {
      creditNoteCreated: 'Credit note was successfully created.',
      creditNoteUpdated: 'Credit note was successfully updated.',
      creditNoteVoided: 'Credit note was successfully voided.',
      creditNoteRemoved: 'Credit note was successfully canceled.'
    },
    validation: {
      total: 'Credit note must not have a positive total sum'
    }
  },
  deliveryNotes: { title: 'Delivery notes' },
  cashRegister: {
    title: 'Cash Register Logs',
    listTitle: 'Completed cash register logs',
    cashRegisterLog: 'Cash register log',
    confirmComplete: {
      title: 'Complete log',
      message:
        'Do you want to complete the cash register log <0>{{ logId }}</0>?'
    },
    confirmReopen: {
      title: 'Reopen {{ entityName }}',
      message:
        'Are you sure you want to reopen this {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    submittingMessage: 'Saving cash register log...',
    add: 'Add log',
    new: 'New cash register log',
    logNumber: 'Log number',
    logId: 'Log ID',
    createdBy: 'Created by',
    paymentType: 'Payment type',
    documentType: 'Doc. type',
    totalPayment: 'Total {{type, lowercase}}',
    totalPayments: 'Sum of payments',
    totalAll: 'All payments',
    taxRate: 'Tax rate',
    taxBase: 'VAT base',
    taxAmount: 'VAT amount',
    grossAmount: 'Total incl. VAT',
    includedDocuments: 'Included documents',
    includedDocumentsLabel: '{{ count }}  (from {{ first }} to {{ last }})',
    cashPaymentsInline: '$t(billing:invoices.paymentTypes.cash): ',
    numOfDocuments: 'Num. of documents',
    numOfDocumentsInline:
      '$t(billing:cashRegister.numOfDocuments): {{ count }}',
    vat: 'VAT',
    emptyListText: `Hmmm. Seems like there weren't any cash register logs issued for this company yet.`,
    emptyInvoiceListText: `Hmmm. Seems like there weren't any documents issued on this day and device.`,
    noTaxableItems: 'There are no taxable items and services.',
    noRevenue: 'There is no revenue for this period.',
    revenue: 'Revenue',
    totalRevenue: 'Total revenue',
    totalRevenueInline: '$t(billing:cashRegister.totalRevenue): ',
    noLogModal: {
      title: "Can't create a new document",
      message: `Selected device <0>{{ deviceLabel }}</0> has an open cash register log.<1></1>
        Before you can issue new documents, you need to complete all old cash register logs for this device.`,
      createLog: 'Go to cash register logs'
    }
  },
  exportBillDocuments: {
    title: '$t(common:export)',
    billingExportWithDate: `Billing export {{ from, ${formatting.shortDate} }} {{ to, ${formatting.shortDate} }}`,
    billingUnitExport: `{{ billingUnitName }} {{ from, ${formatting.shortDate} }} {{ to, ${formatting.shortDate} }} export`
  },
  payments: {
    backToPayments: 'Back to payments'
  },
  sendEmail: {
    sendViaEmail: 'Send PDF via e-mail',
    sendTo: 'To',
    updatePatient: `Update patient's e-mail address`,
    useOnce: 'No, use address just once',
    useEmail: 'Yes, use this address from now on',
    additionalMessage: 'Additional message'
  },
  translateBill: {
    chooseLanguage: 'Choose language',
    createTranslation: 'Create translation'
  },
  duplicate: {
    selectType: 'What would you like to do?',
    copyBill: 'Copy {{ billType, lowercase }}'
  },
  exportColumns: {
    [EXPORT_COLUMN_NUMBER]: 'Number',
    [EXPORT_REFERENCE_NUMBER]: 'Reference number',
    [EXPORT_COLUMN_PATIENT_NUMBER]: 'Patient number',
    [EXPORT_COLUMN_TYPE]: 'Type',
    [EXPORT_COLUMN_CLIENT_ADDRESS]: 'Client address',
    [EXPORT_COLUMN_CLIENT_NAME]: 'Client name',
    [EXPORT_COLUMN_CLIENT_TAX_NUMBER]: 'Client tax number',
    [EXPORT_COLUMN_CLIENT_REGISTRATION_NUMBER]: 'Client registration number',
    [EXPORT_COLUMN_DOCTOR_ID]: 'Doctor id',
    [EXPORT_COLUMN_DOCTOR_NAME]: 'Doctor name',
    [EXPORT_COLUMN_CANCELED]: 'Canceled',
    [EXPORT_COLUMN_TOTAL]: 'Total',
    [EXPORT_COLUMN_TOTAL_WITHOUT_TAX]: 'Total without tax',
    [EXPORT_COLUMN_TAX]: 'Tax',
    [EXPORT_COLUMN_PAID]: 'Paid',
    [EXPORT_COLUMN_TOTAL_PAID]: 'Total paid',
    [EXPORT_COLUMN_PAYMENT_TYPE]: 'Payment type',
    [EXPORT_COLUMN_DUE_DATE]: 'Due date',
    [EXPORT_COLUMN_CREATED_AT]: 'Created at',
    [EXPORT_COLUMN_DOCUMENT_DATE]: 'Document date',
    [EXPORT_COLUMN_ISSUED_AT]: 'Issued at',
    [EXPORT_COLUMN_ITEM_NAME]: 'Item name',
    [EXPORT_COLUMN_ITEM_CATEGORY_NAME]: 'Item category name',
    [EXPORT_COLUMN_ITEM_TOTAL]: 'Item total',
    [EXPORT_COLUMN_ITEM_PRICE]: 'Item price',
    [EXPORT_COLUMN_ITEM_TAX]: 'Item tax',
    [EXPORT_COLUMN_ITEM_QUANTITY]: 'Item quantity'
  },
  pluralBillTypes: {
    [BILL_TYPE_INVOICE]: 'invoices',
    [BILL_TYPE_CREDIT_NOTE]: 'credit notes',
    [BILL_TYPE_ADVANCE]: 'advances',
    [BILL_TYPE_ESTIMATE]: 'estimates'
  }
};
