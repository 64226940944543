import moment from 'moment';
import { call, race, select, take } from 'redux-saga/effects';

import {
  getAllValidBookingSchedulesUrl,
  getBookingTemplateUrl
} from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import { isValidDate } from '../../utils/date';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import { SET_CALENDAR_SELECTED_DATE_RANGE } from '../calendar/calendarTypes';
import { POLL_BOOKING_TEMPLATES } from '../core/cache/cacheTypes';
import {
  START_CALENDAR_BOOKING_SCHEDULES_POLLING,
  STOP_CALENDAR_BOOKING_SCHEDULES_POLLING,
  ADD_BOOKING_SCHEDULE_SUCCESS,
  GET_BOOKING_SCHEDULES,
  ADD_BOOKING_TEMPLATE_SUCCESS,
  UPDATE_BOOKING_TEMPLATE_SUCCESS,
  REMOVE_BOOKING_TEMPLATE_SUCCESS
} from './bookingTypes';

import { UPDATE_SELECTED_LOCATION } from '../location/locationReducer';

import {
  selectCalendarFromDate,
  selectCalendarToDate
} from '../calendar/calendarSelectors';
import { selectIsOnlineBookingModuleEnabled } from '../organization/organizationSelectors';

export function* pollCalendarBookingSchedulesSaga() {
  function* configGetter() {
    const state = yield select();
    const fromDate = selectCalendarFromDate(state);
    const toDate = selectCalendarToDate(state);

    if (isValidDate(fromDate) && isValidDate(toDate)) {
      const fromFormattedDate = moment(fromDate).format('YYYY-MM-DD');
      const toFormattedDate = moment(toDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

      const params = {
        from: fromFormattedDate,
        to: toFormattedDate
      };

      return {
        params
      };
    }
  }

  yield pollGenerator(GET_BOOKING_SCHEDULES, getAllValidBookingSchedulesUrl, {
    retriggerActions: [
      ADD_BOOKING_SCHEDULE_SUCCESS,
      SET_CALENDAR_SELECTED_DATE_RANGE,
      UPDATE_SELECTED_LOCATION
    ],
    configGetter
  });
}

export function* bookingScheduleListSagaWatcher() {
  while (true) {
    yield take(START_CALENDAR_BOOKING_SCHEDULES_POLLING);
    yield take(SET_CALENDAR_SELECTED_DATE_RANGE);
    yield race([
      call(pollCalendarBookingSchedulesSaga),
      take(STOP_CALENDAR_BOOKING_SCHEDULES_POLLING)
    ]);
  }
}

export function* pollBookingTemplatesSaga() {
  const state = yield select();
  const isOnlineBookingModuleEnabled = selectIsOnlineBookingModuleEnabled(
    state
  );

  const configGetter = () => ({
    params: { limit: REQUEST_PAGINATION_MAX_LIMIT, page: 1 }
  });

  yield pollGenerator(POLL_BOOKING_TEMPLATES, getBookingTemplateUrl, {
    retriggerActions: [
      ADD_BOOKING_TEMPLATE_SUCCESS,
      UPDATE_BOOKING_TEMPLATE_SUCCESS,
      REMOVE_BOOKING_TEMPLATE_SUCCESS
    ],
    configGetter,
    disabled: !isOnlineBookingModuleEnabled
  });
}
