import { formatting } from './date';

/* eslint sort-keys: "error" */
export default {
  activate: 'Activate',
  active: 'Active',
  add: 'Add',
  addData: 'Add data',
  addedByPrefix: 'Added by',
  address: {
    country: 'Country',
    inlineAddress:
      '{{ address.street1 }}, {{ address.zip }} {{ address.town }}, {{ address.country }}',
    street: 'Address',
    town: 'City',
    zip: 'Postal code',
    zipShort: 'ZIP'
  },
  all: 'All',
  allTime: 'All time',
  and: 'and',
  apply: 'Apply',
  archive: 'Archive',
  avatar: 'avatar',
  back: 'Back',
  backToApp: 'Back to {{ appName }}',
  cancel: 'Cancel',
  cancelAll: 'Cancel all',
  category: 'Category',
  change: 'Change',
  clear: 'Clear',
  clearAll: 'Clear all',
  close: 'Close',
  colonSeparated: `{{ left }}: {{ right }}`,
  color: 'Color',
  complete: 'Complete',
  confirm: 'Confirm',
  content: 'Content',
  copy: 'Copy',
  copyTo: 'Copy to',
  created: 'Created',
  createdAt: 'Created at',
  createdBy: 'Created by',
  createdByPatient: 'Created by patient',
  creator: 'Creator',
  custom: 'Custom',
  deactivate: 'Deactivate',
  default: 'Default',
  delete: 'Delete',
  deleted: 'Deleted',
  description: 'Description',
  deselectAll: 'Deselect all',
  disable: 'Disable',
  disabled: 'Disabled',
  displayName: `{{ user.firstName }} {{ user.lastName }}`,
  displayNameReverse: `{{ user.lastName }} {{ user.firstName }}`,
  done: 'Done',
  download: 'Download',
  downloadPdf: 'Download PDF',
  dropdownPlaceholder: 'Select...',
  dropdownUnselect: '[None]',
  duplicate: 'Duplicate',
  duration: 'Duration',
  edit: 'Edit',
  editAll: 'Edit all',
  edited: 'Edited',
  editedBy: 'Edited by {{ user }}',
  ellipsis: '...',
  email: 'Email',
  empty: 'Empty',
  enable: 'Enable',
  enabled: 'Enabled',
  end: 'End',
  export: 'Export',
  finish: 'Finish',
  frequency: 'Frequency',
  generated: 'Generated',
  group: 'Group',
  here: 'here',
  hide: 'Hide',
  hideHistory: 'Hide history',
  home: 'Home',
  inactive: {
    list: 'Inactive',
    single: 'Inactive'
  },
  include: 'Include',
  languages: {
    cs: 'Czech',
    de: 'German',
    en: 'English',
    'en-US': 'English (United States)',
    fr: 'French',
    it: 'Italian',
    sl: 'Slovenian',
    'sl-SI': 'Slovenian (Slovenia)'
  },
  lastChange: 'Last change',
  lastChanged: 'Last changed',
  lastUpdateAt: 'Last update',
  loading: 'Loading...',
  location: 'Location',
  locationTime: `{{ location }} ∙ {{ time, ${formatting.longDateWithTime} }}`,
  locations: 'Locations',
  login: 'Login',
  logout: 'Logout',
  mandatory: 'Mandatory',
  mandatoryFields: 'mandatory fields',
  merge: 'Merge',
  message: 'Message',
  missingObject: {
    base: ' not found',
    noAuthor: '$t(encounter:author) $t(common:missingObject.base)',
    noDoctor: '$t(common:roles.doctor) $t(common:missingObject.base)',
    noFlow: '$t(flow:title) $t(common:missingObject.base)',
    noImage: 'Image was not set',
    noLocation: '$t(settings:account.location) $t(common:missingObject.base)',
    noPatient: '$t(patient:title) $t(common:missingObject.base)',
    noResource: `$t(common:resource)  $t(common:missingObject.base)`
  },
  more: 'More',
  moreOptions: 'More options',
  name: 'Name',
  never: 'Never',
  newestFirst: 'Newest first',
  next: 'Next',
  no: 'No',
  noItemsFound: 'No items found...',
  note: 'Note',
  nothingHere: 'There is nothing here',
  number: {
    separator: '\\.'
  },
  numericValueWithSuffix: '{{value, number}} {{suffix}}',
  oldestFirst: 'Oldest first',
  open: 'Open',
  openPdf: 'Open PDF',
  origin: 'Origin',
  other: 'Other',
  password: 'Password',
  period: 'Period',
  person: {
    address: 'Address',
    contact: 'Contact',
    dateOfBirth: 'Date of birth',
    email: 'Email address',
    firstName: 'First name',
    gender: 'Gender',
    genderPlaceholder: 'Choose a gender',
    genderTypes: {
      female: 'Female',
      male: 'Male'
    },
    identification: 'Identification',
    lastName: 'Last name',
    notifications: {
      email: 'Email',
      mobile: 'Iryo Moshi mobile application',
      sms: 'SMS',
      title: 'Notification Preferences'
    },
    phoneNumber: 'Phone number'
  },
  photo: {
    add: 'Add photo'
  },
  pinToTheTop: 'Pin to the top',
  post: 'Post',
  preview: 'Preview',
  price: 'Price',
  print: 'Print',
  quantity: 'Quantity',
  refresh: 'Refresh',
  register: 'Register',
  remove: 'Remove',
  rename: 'Rename',
  reopen: 'Reopen',
  reset: 'Reset',
  resource: 'Resource',
  resources: 'Resources',
  resultsFor: ' results for {{query}}',
  roles: {
    admin: 'Administrator',
    assistant: 'Assistant',
    doctor: '$t(patient:doctor.title)'
  },
  save: 'Save',
  saveAll: 'Save all',
  search: 'Search',
  seeAll: 'See all',
  selectAll: 'Select all',
  selectLocation: 'Select location',
  send: 'Send',
  sendViaEmail: 'Send via email',
  showAll: 'Show all',
  showEncounter: 'Show encounter',
  showHistory: 'Show history',
  showLess: 'Show less',
  showMore: 'Show more',
  sms: 'SMS',
  sort: {
    aToZ: 'A-Z',
    zToA: 'Z-A'
  },
  staff: 'Staff',
  start: 'Start',
  status: 'Status',
  template: 'Template',
  termsAndConditions: 'Terms and conditions',
  time: 'Time',
  title: 'Title',
  type: 'Type',
  underConstruction: 'Under construction...',
  unknown: 'Unknown',
  unpinFromTheTop: 'Unpin from the top',
  upNext: 'Next',
  updatedAt: 'Updated at',
  upload: 'Upload',
  url: 'URL',
  useNonExistingEntry: 'Use non existing entry "{{ name }}"',
  valueWithSuffix: '{{value}} {{suffix}}',
  version: 'v{{ version }}',
  versionLabel: 'Version',
  view: 'View',
  warning: {
    confirmMissingClientDocumentCreationMessage:
      'You are issuing the {{ billType, lowercase }} without any customer information. Do you want to proceed?',
    confirmTodaysLogClosedMessage:
      "Today's cash register log was already closed for the selected device. It will have to be closed again in case of a new bill being issued. Do you want to proceed?",
    confirmZeroTotalBillCreationMessage:
      'You are issuing the {{ billType, lowercase }} with a total amount of 0 {{ currency }}. Do you want to proceed?',
    warningTitle: 'Warning'
  },
  with: 'with',
  yes: 'Yes'
};
