/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { ModalHeader } from 'reactstrap';

import PropTypes from 'prop-types';

const ModalHeaderWrapper = ({
  children,
  toggle,
  closeAriaLabel,
  charCode,
  close,
  className,
  id
}) => (
  <ModalHeader
    toggle={toggle}
    closeAriaLabel={closeAriaLabel}
    charCode={charCode}
    close={close}
    className={className}
    id={id}
  >
    {children}
  </ModalHeader>
);

ModalHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  toggle: PropTypes.func,
  closeAriaLabel: PropTypes.string,
  charCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  close: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string
};

ModalHeaderWrapper.defaultProps = {
  toggle: undefined,
  closeAriaLabel: undefined,
  charCode: undefined,
  close: undefined,
  className: undefined,
  id: undefined
};

export default ModalHeaderWrapper;
