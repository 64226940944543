import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  MOSHI_COLOR_DANGER_NAME,
  MOSHI_COLOR_GRAY_NAME,
  MOSHI_COLOR_LIGHT_NAME,
  MOSHI_COLOR_PRIMARY_NAME,
  MOSHI_COLOR_SECONDARY_NAME,
  MOSHI_COLOR_TRANSPARENT_NAME
} from '../../../utils/color';
import {
  MOSHI_SIZE_LG,
  MOSHI_SIZE_MD,
  MOSHI_SIZE_SM,
  MOSHI_SIZE_XS
} from '../../../utils/constants/sizeConstants';

import { Button } from '../../reactstrap';

/**
 * The common button component
 *
 *
 * @example ./__examples__/MoshiButton.md
 */
const MoshiButton = ({
  children,
  disabled,
  active,
  color,
  onClick,
  size,
  id,
  className,
  style,
  type,
  tabIndex,
  noPadding,
  hasBoxShadow
}) => (
  <Button
    disabled={disabled}
    active={active}
    color={color}
    onClick={onClick}
    size={size}
    id={id}
    className={classNames(className, 'moshi-button', {
      'no-padding': noPadding,
      'btn-box-shadow': hasBoxShadow
    })}
    style={style}
    type={type}
    tabIndex={tabIndex}
  >
    {children}
  </Button>
);

MoshiButton.propTypes = {
  /** Children to render inside the button */
  children: PropTypes.node,
  /** Disable the button */
  disabled: PropTypes.bool,
  /** Make button active */
  active: PropTypes.bool,
  /** Set button's color (default is MOSHI_COLOR_LIGHT_NAME) */
  color: PropTypes.oneOf([
    MOSHI_COLOR_PRIMARY_NAME,
    MOSHI_COLOR_SECONDARY_NAME,
    MOSHI_COLOR_LIGHT_NAME,
    MOSHI_COLOR_DANGER_NAME,
    MOSHI_COLOR_GRAY_NAME,
    MOSHI_COLOR_TRANSPARENT_NAME
  ]),
  /** Function that's called when button is clicked */
  onClick: PropTypes.func,
  /** Size of the button */
  size: PropTypes.oneOf([
    MOSHI_SIZE_XS,
    MOSHI_SIZE_SM,
    MOSHI_SIZE_MD,
    MOSHI_SIZE_LG
  ]),
  /** id */
  id: PropTypes.string,
  /** className */
  className: PropTypes.string,
  /** style */
  style: PropTypes.shape(),
  /** button type */
  type: PropTypes.oneOf(['button', 'submit', 'color']),
  /** tab index */
  tabIndex: PropTypes.number,
  /** Removes button's left and right padding making it a square */
  noPadding: PropTypes.bool,
  /** Adds a box shadow to the button */
  hasBoxShadow: PropTypes.bool
};

MoshiButton.defaultProps = {
  children: undefined,
  disabled: undefined,
  active: undefined,
  color: MOSHI_COLOR_LIGHT_NAME,
  onClick: undefined,
  size: MOSHI_SIZE_LG,
  id: undefined,
  className: undefined,
  style: undefined,
  type: 'button',
  tabIndex: undefined,
  noPadding: false,
  hasBoxShadow: false
};

export default MoshiButton;
