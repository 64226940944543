/* eslint-disable max-len,max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from '../../../../layout/Visible';

export const HUMANOID_RIGHT_HAND_ID = 'right-hand';
const GROUP = `zoomed-${HUMANOID_RIGHT_HAND_ID}-interactive`;

const WRIST = `${HUMANOID_RIGHT_HAND_ID}-wrist`;

const INDEX_TIP = `${HUMANOID_RIGHT_HAND_ID}-index-tip`;
const MIDDLE_TIP = `${HUMANOID_RIGHT_HAND_ID}-middle-tip`;
const RING_TIP = `${HUMANOID_RIGHT_HAND_ID}-ring-tip`;
const PINKIE_TIP = `${HUMANOID_RIGHT_HAND_ID}-pinkie-tip`;

const THUMB_MID = `${HUMANOID_RIGHT_HAND_ID}-thumb-mid`;
const INDEX_MID = `${HUMANOID_RIGHT_HAND_ID}-index-mid`;
const MIDDLE_MID = `${HUMANOID_RIGHT_HAND_ID}-middle-mid`;
const RING_MID = `${HUMANOID_RIGHT_HAND_ID}-ring-mid`;
const PINKIE_MID = `${HUMANOID_RIGHT_HAND_ID}-pinkie-mid`;

const THUMB_LOWER = `${HUMANOID_RIGHT_HAND_ID}-thumb-low`;
const INDEX_LOWER = `${HUMANOID_RIGHT_HAND_ID}-index-low`;
const MIDDLE_LOWER = `${HUMANOID_RIGHT_HAND_ID}-middle-low`;
const RING_LOWER = `${HUMANOID_RIGHT_HAND_ID}-ring-low`;
const PINKIE_LOWER = `${HUMANOID_RIGHT_HAND_ID}-pinkie-low`;

const RightHandInteractive = ({
  getColor,
  onChange,
  initialSelectedParts,
  tipsDisabled,
  lowDisabled,
  wristDisabled
}) => {
  const [checkedList, setCheckedList] = useState(initialSelectedParts);

  const isChecked = useCallback((partId) => _.includes(checkedList, partId), [
    checkedList
  ]);

  useEffect(() => {
    onChange(HUMANOID_RIGHT_HAND_ID, checkedList);
  }, [checkedList, onChange]);

  const handleClick = useCallback(
    (partId) => {
      const result = _.pushOrRemoveIntoArray(checkedList, partId);

      setCheckedList(result);
    },
    [checkedList]
  );

  return (
    <g id={GROUP}>
      <Visible visible={!wristDisabled}>
        <g
          id={WRIST}
          onClick={() => handleClick(WRIST)}
          className={'clickable-text'}
        >
          <path
            d={
              'M56 51C56 45.4772 60.4772 41 66 41V41C71.5228 41 76 45.4772 76 51V51C76 56.5228 71.5228 61 66 61V61C60.4772 61 56 56.5228 56 51V51Z'
            }
            fill={'white'}
          />
          {isChecked(WRIST) && (
            <circle cx={'66'} cy={'51'} r={'5'} fill={getColor(WRIST)} />
          )}
          <path
            d={
              'M66 59C61.5817 59 58 55.4183 58 51H54C54 57.6274 59.3726 63 66 63V59ZM74 51C74 55.4183 70.4183 59 66 59V63C72.6274 63 78 57.6274 78 51H74ZM66 43C70.4183 43 74 46.5817 74 51H78C78 44.3726 72.6274 39 66 39V43ZM66 39C59.3726 39 54 44.3726 54 51H58C58 46.5817 61.5817 43 66 43V39Z'
            }
            fill={getColor(WRIST)}
          />
        </g>
      </Visible>

      <Visible visible={!tipsDisabled}>
        <g
          id={PINKIE_TIP}
          onClick={() => handleClick(PINKIE_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M5 195C5 189.477 9.47715 185 15 185V185C20.5228 185 25 189.477 25 195V195C25 200.523 20.5228 205 15 205V205C9.47715 205 5 200.523 5 195V195Z'
            }
            fill={'white'}
          />
          {isChecked(PINKIE_TIP) && (
            <circle cx={'15'} cy={'195'} r={'5'} fill={getColor(PINKIE_TIP)} />
          )}
          <path
            d={
              'M15 203C10.5817 203 7 199.418 7 195H3C3 201.627 8.37258 207 15 207V203ZM23 195C23 199.418 19.4183 203 15 203V207C21.6274 207 27 201.627 27 195H23ZM15 187C19.4183 187 23 190.582 23 195H27C27 188.373 21.6274 183 15 183V187ZM15 183C8.37258 183 3 188.373 3 195H7C7 190.582 10.5817 187 15 187V183Z'
            }
            fill={getColor(PINKIE_TIP)}
          />
        </g>
        <g
          id={RING_TIP}
          onClick={() => handleClick(RING_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M31 221C31 215.477 35.4772 211 41 211V211C46.5228 211 51 215.477 51 221V221C51 226.523 46.5228 231 41 231V231C35.4772 231 31 226.523 31 221V221Z'
            }
            fill={'white'}
          />
          {isChecked(RING_TIP) && (
            <circle cx={'41'} cy={'221'} r={'5'} fill={getColor(RING_TIP)} />
          )}
          <path
            d={
              'M41 229C36.5817 229 33 225.418 33 221H29C29 227.627 34.3726 233 41 233V229ZM49 221C49 225.418 45.4183 229 41 229V233C47.6274 233 53 227.627 53 221H49ZM41 213C45.4183 213 49 216.582 49 221H53C53 214.373 47.6274 209 41 209V213ZM41 209C34.3726 209 29 214.373 29 221H33C33 216.582 36.5817 213 41 213V209Z'
            }
            fill={getColor(RING_TIP)}
          />
        </g>
        <g
          id={MIDDLE_TIP}
          onClick={() => handleClick(MIDDLE_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M62 228C62 222.477 66.4772 218 72 218V218C77.5228 218 82 222.477 82 228V228C82 233.523 77.5228 238 72 238V238C66.4772 238 62 233.523 62 228V228Z'
            }
            fill={'white'}
          />
          {isChecked(MIDDLE_TIP) && (
            <circle cx={'72'} cy={'228'} r={'5'} fill={getColor(MIDDLE_TIP)} />
          )}
          <path
            d={
              'M72 236C67.5817 236 64 232.418 64 228H60C60 234.627 65.3726 240 72 240V236ZM80 228C80 232.418 76.4183 236 72 236V240C78.6274 240 84 234.627 84 228H80ZM72 220C76.4183 220 80 223.582 80 228H84C84 221.373 78.6274 216 72 216V220ZM72 216C65.3726 216 60 221.373 60 228H64C64 223.582 67.5817 220 72 220V216Z'
            }
            fill={getColor(MIDDLE_TIP)}
          />
        </g>
        <g
          id={INDEX_TIP}
          onClick={() => handleClick(INDEX_TIP)}
          className={'clickable-text'}
        >
          <path
            d={
              'M90 211C90 205.477 94.4772 201 100 201V201C105.523 201 110 205.477 110 211V211C110 216.523 105.523 221 100 221V221C94.4772 221 90 216.523 90 211V211Z'
            }
            fill={'white'}
          />
          {isChecked(INDEX_TIP) && (
            <circle cx={'100'} cy={'211'} r={'5'} fill={getColor(INDEX_TIP)} />
          )}
          <path
            d={
              'M100 219C95.5817 219 92 215.418 92 211H88C88 217.627 93.3726 223 100 223V219ZM108 211C108 215.418 104.418 219 100 219V223C106.627 223 112 217.627 112 211H108ZM100 203C104.418 203 108 206.582 108 211H112C112 204.373 106.627 199 100 199V203ZM100 199C93.3726 199 88 204.373 88 211H92C92 206.582 95.5817 203 100 203V199Z'
            }
            fill={getColor(INDEX_TIP)}
          />
        </g>
      </Visible>

      <Visible visible={!lowDisabled}>
        <g
          id={PINKIE_LOWER}
          onClick={() => handleClick(PINKIE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M14 144C14 138.477 18.4772 134 24 134C29.5228 134 34 138.477 34 144C34 149.523 29.5228 154 24 154C18.4772 154 14 149.523 14 144Z'
            }
            fill={'white'}
          />
          {isChecked(PINKIE_LOWER) && (
            <circle
              cx={'24'}
              cy={'144'}
              r={'5'}
              fill={getColor(PINKIE_LOWER)}
            />
          )}
          <path
            d={
              'M24 152C19.5817 152 16 148.418 16 144H12C12 150.627 17.3726 156 24 156V152ZM32 144C32 148.418 28.4183 152 24 152V156C30.6274 156 36 150.627 36 144H32ZM24 136C28.4183 136 32 139.582 32 144H36C36 137.373 30.6274 132 24 132V136ZM24 132C17.3726 132 12 137.373 12 144H16C16 139.582 19.5817 136 24 136V132Z'
            }
            fill={getColor(PINKIE_LOWER)}
          />
        </g>
        <g
          id={RING_LOWER}
          onClick={() => handleClick(RING_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M35 153C35 147.477 39.4772 143 45 143C50.5228 143 55 147.477 55 153C55 158.523 50.5228 163 45 163C39.4772 163 35 158.523 35 153Z'
            }
            fill={'white'}
          />
          {isChecked(RING_LOWER) && (
            <circle cx={'45'} cy={'153'} r={'5'} fill={getColor(RING_LOWER)} />
          )}
          <path
            d={
              'M45 161C40.5817 161 37 157.418 37 153H33C33 159.627 38.3726 165 45 165V161ZM53 153C53 157.418 49.4183 161 45 161V165C51.6274 165 57 159.627 57 153H53ZM45 145C49.4183 145 53 148.582 53 153H57C57 146.373 51.6274 141 45 141V145ZM45 141C38.3726 141 33 146.373 33 153H37C37 148.582 40.5817 145 45 145V141Z'
            }
            fill={getColor(RING_LOWER)}
          />
        </g>
        <g
          id={MIDDLE_LOWER}
          onClick={() => handleClick(MIDDLE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M57 154C57 148.477 61.4772 144 67 144C72.5228 144 77 148.477 77 154C77 159.523 72.5228 164 67 164C61.4772 164 57 159.523 57 154Z'
            }
            fill={'white'}
          />
          {isChecked(MIDDLE_LOWER) && (
            <circle
              cx={'67'}
              cy={'154'}
              r={'5'}
              fill={getColor(MIDDLE_LOWER)}
            />
          )}
          <path
            d={
              'M67 162C62.5817 162 59 158.418 59 154H55C55 160.627 60.3726 166 67 166V162ZM75 154C75 158.418 71.4183 162 67 162V166C73.6274 166 79 160.627 79 154H75ZM67 146C71.4183 146 75 149.582 75 154H79C79 147.373 73.6274 142 67 142V146ZM67 142C60.3726 142 55 147.373 55 154H59C59 149.582 62.5817 146 67 146V142Z'
            }
            fill={getColor(MIDDLE_LOWER)}
          />
        </g>
        <g
          id={INDEX_LOWER}
          onClick={() => handleClick(INDEX_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M80 153C80 147.477 84.4772 143 90 143V143C95.5228 143 100 147.477 100 153V153C100 158.523 95.5228 163 90 163V163C84.4772 163 80 158.523 80 153V153Z'
            }
            fill={'white'}
          />
          {isChecked(INDEX_LOWER) && (
            <circle cx={'90'} cy={'153'} r={'5'} fill={getColor(INDEX_LOWER)} />
          )}
          <path
            d={
              'M90 161C85.5817 161 82 157.418 82 153H78C78 159.627 83.3726 165 90 165V161ZM98 153C98 157.418 94.4183 161 90 161V165C96.6274 165 102 159.627 102 153H98ZM90 145C94.4183 145 98 148.582 98 153H102C102 146.373 96.6274 141 90 141V145ZM90 141C83.3726 141 78 146.373 78 153H82C82 148.582 85.5817 145 90 145V141Z'
            }
            fill={getColor(INDEX_LOWER)}
          />
        </g>
        <g
          id={THUMB_LOWER}
          onClick={() => handleClick(THUMB_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M117 100C117 94.4772 121.477 90 127 90C132.523 90 137 94.4772 137 100C137 105.523 132.523 110 127 110C121.477 110 117 105.523 117 100Z'
            }
            fill={'white'}
          />
          {isChecked(THUMB_LOWER) && (
            <circle
              cx={'127'}
              cy={'100'}
              r={'5'}
              fill={getColor(THUMB_LOWER)}
            />
          )}
          <path
            d={
              'M127 108C122.582 108 119 104.418 119 100H115C115 106.627 120.373 112 127 112V108ZM135 100C135 104.418 131.418 108 127 108V112C133.627 112 139 106.627 139 100H135ZM127 92C131.418 92 135 95.5817 135 100H139C139 93.3726 133.627 88 127 88V92ZM127 88C120.373 88 115 93.3726 115 100H119C119 95.5817 122.582 92 127 92V88Z'
            }
            fill={getColor(THUMB_LOWER)}
          />
        </g>
      </Visible>

      <g
        id={PINKIE_MID}
        onClick={() => handleClick(PINKIE_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M9 174C9 168.477 13.4772 164 19 164C24.5228 164 29 168.477 29 174C29 179.523 24.5228 184 19 184C13.4772 184 9 179.523 9 174Z'
          }
          fill={'white'}
        />
        {isChecked(PINKIE_MID) && (
          <circle cx={'19'} cy={'174'} r={'5'} fill={getColor(PINKIE_MID)} />
        )}
        <path
          d={
            'M19 182C14.5817 182 11 178.418 11 174H7C7 180.627 12.3726 186 19 186V182ZM27 174C27 178.418 23.4183 182 19 182V186C25.6274 186 31 180.627 31 174H27ZM19 166C23.4183 166 27 169.582 27 174H31C31 167.373 25.6274 162 19 162V166ZM19 162C12.3726 162 7 167.373 7 174H11C11 169.582 14.5817 166 19 166V162Z'
          }
          fill={getColor(PINKIE_MID)}
        />
      </g>
      <g
        id={RING_MID}
        onClick={() => handleClick(RING_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M33 199C33 193.477 37.4772 189 43 189C48.5228 189 53 193.477 53 199C53 204.523 48.5228 209 43 209C37.4772 209 33 204.523 33 199Z'
          }
          fill={'white'}
        />
        {isChecked(RING_MID) && (
          <circle cx={'43'} cy={'199'} r={'5'} fill={getColor(RING_MID)} />
        )}
        <path
          d={
            'M43 207C38.5817 207 35 203.418 35 199H31C31 205.627 36.3726 211 43 211V207ZM51 199C51 203.418 47.4183 207 43 207V211C49.6274 211 55 205.627 55 199H51ZM43 191C47.4183 191 51 194.582 51 199H55C55 192.373 49.6274 187 43 187V191ZM43 187C36.3726 187 31 192.373 31 199H35C35 194.582 38.5817 191 43 191V187Z'
          }
          fill={getColor(RING_MID)}
        />
      </g>
      <g
        id={MIDDLE_MID}
        onClick={() => handleClick(MIDDLE_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M61 204C61 198.477 65.4772 194 71 194C76.5228 194 81 198.477 81 204C81 209.523 76.5228 214 71 214C65.4772 214 61 209.523 61 204Z'
          }
          fill={'white'}
        />
        {isChecked(MIDDLE_MID) && (
          <circle cx={'71'} cy={'204'} r={'5'} fill={getColor(MIDDLE_MID)} />
        )}
        <path
          d={
            'M71 212C66.5817 212 63 208.418 63 204H59C59 210.627 64.3726 216 71 216V212ZM79 204C79 208.418 75.4183 212 71 212V216C77.6274 216 83 210.627 83 204H79ZM71 196C75.4183 196 79 199.582 79 204H83C83 197.373 77.6274 192 71 192V196ZM71 192C64.3726 192 59 197.373 59 204H63C63 199.582 66.5817 196 71 196V192Z'
          }
          fill={getColor(MIDDLE_MID)}
        />
      </g>
      <g
        id={INDEX_MID}
        onClick={() => handleClick(INDEX_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M88 188C88 182.477 92.4772 178 98 178C103.523 178 108 182.477 108 188C108 193.523 103.523 198 98 198C92.4772 198 88 193.523 88 188Z'
          }
          fill={'white'}
        />
        {isChecked(INDEX_MID) && (
          <circle cx={'98'} cy={'188'} r={'5'} fill={getColor(INDEX_MID)} />
        )}
        <path
          d={
            'M98 196C93.5817 196 90 192.418 90 188H86C86 194.627 91.3726 200 98 200V196ZM106 188C106 192.418 102.418 196 98 196V200C104.627 200 110 194.627 110 188H106ZM98 180C102.418 180 106 183.582 106 188H110C110 181.373 104.627 176 98 176V180ZM98 176C91.3726 176 86 181.373 86 188H90C90 183.582 93.5817 180 98 180V176Z'
          }
          fill={getColor(INDEX_MID)}
        />
      </g>
      <g
        id={THUMB_MID}
        onClick={() => handleClick(THUMB_MID)}
        className={'clickable-text'}
      >
        <path
          d={
            'M126 129C126 123.477 130.477 119 136 119C141.523 119 146 123.477 146 129C146 134.523 141.523 139 136 139C130.477 139 126 134.523 126 129Z'
          }
          fill={'white'}
        />
        {isChecked(THUMB_MID) && (
          <circle cx={'136'} cy={'129'} r={'5'} fill={getColor(THUMB_MID)} />
        )}
        <path
          d={
            'M136 137C131.582 137 128 133.418 128 129H124C124 135.627 129.373 141 136 141V137ZM144 129C144 133.418 140.418 137 136 137V141C142.627 141 148 135.627 148 129H144ZM136 121C140.418 121 144 124.582 144 129H148C148 122.373 142.627 117 136 117V121ZM136 117C129.373 117 124 122.373 124 129H128C128 124.582 131.582 121 136 121V117Z'
          }
          fill={getColor(THUMB_MID)}
        />
      </g>
    </g>
  );
};

RightHandInteractive.propTypes = {
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  tipsDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool,
  wristDisabled: PropTypes.bool
};

RightHandInteractive.defaultProps = {
  onChange: _.noop,
  initialSelectedParts: [],
  tipsDisabled: false,
  lowDisabled: false,
  wristDisabled: false
};

export default RightHandInteractive;
