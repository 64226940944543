import produce from 'immer';
import _ from 'lodash';

import { setFilterToStoreDraft } from '../../../utils/redux/listUtils';

import {
  GET_ENCOUNTER_COMMENTS_FAILURE,
  GET_ENCOUNTER_COMMENTS_IN_PROGRESS,
  GET_ENCOUNTER_COMMENTS_SUCCESS,
  GET_ENCOUNTER_SUCCESS,
  GET_ENCOUNTERS_FAILURE,
  GET_ENCOUNTERS_IN_PROGRESS,
  GET_ENCOUNTERS_SUCCESS,
  GET_PAST_ENCOUNTERS_FAILURE,
  GET_PAST_ENCOUNTERS_IN_PROGRESS,
  GET_PAST_ENCOUNTERS_SUCCESS,
  UPDATE_WAITING_LIST_FILTER,
  CLEAR_WAITING_LIST_FILTER
} from './encounterTypes';

import { LOGOUT } from '../../auth/authReducer';
import { UPDATE_SELECTED_LOCATION } from '../../location/locationReducer';

export const initialState = {
  detail: null,
  list: [],
  pastEncountersList: [],
  loadingList: false,
  pastLoadingList: false,
  groupedEncounterComments: {},
  filter: {}
};

const encounterReducer = produce((draft, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case UPDATE_SELECTED_LOCATION:
      draft.filter = {};
      break;
    case UPDATE_WAITING_LIST_FILTER:
      setFilterToStoreDraft(draft, payload);
      break;
    case GET_ENCOUNTERS_IN_PROGRESS:
      draft.loadingList = true;
      break;
    case GET_ENCOUNTERS_SUCCESS:
      const encountersList = _.get(payload, 'response.data', []);

      draft.loadingList = false;
      draft.list = _.filter(
        encountersList,
        (encounter) => !_.isEmpty(encounter.startedAt)
      );
      break;
    case GET_ENCOUNTERS_FAILURE:
      draft.loadingList = false;
      draft.list = [];
      break;
    case GET_ENCOUNTER_COMMENTS_IN_PROGRESS:
      draft.loadingComments = true;
      break;
    case GET_PAST_ENCOUNTERS_IN_PROGRESS:
      draft.pastLoadingList = true;
      break;
    case GET_PAST_ENCOUNTERS_SUCCESS:
      const pastEncountersList = _.get(payload, 'response.data', []);

      draft.pastLoadingList = false;
      draft.pastEncountersList = pastEncountersList;
      break;
    case GET_PAST_ENCOUNTERS_FAILURE:
      draft.pastLoadingList = false;
      draft.pastEncountersList = [];
      break;
    case GET_ENCOUNTER_COMMENTS_SUCCESS:
      const encounterComments = _.get(payload, 'response.data', []);

      const encounterId = _.get(payload, 'encounterId', null);

      if (!_.isEmpty(encounterId)) {
        draft.groupedEncounterComments[encounterId] = encounterComments;
      }
      draft.loadingComments = false;
      break;
    case GET_ENCOUNTER_COMMENTS_FAILURE:
      draft.loadingComments = false;
      break;
    case GET_ENCOUNTER_SUCCESS:
      const encounter = _.get(payload, 'response.data', {});

      draft.detail = encounter;
      break;
    case CLEAR_WAITING_LIST_FILTER:
      draft.filter = initialState.filter;
      break;
    case LOGOUT:
      return initialState;
    default:
  }
}, initialState);

export default encounterReducer;
