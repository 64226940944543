import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const MoshiDropdownCaretComponent = ({ noCaret, isOpen }) =>
  noCaret ? null : (
    <div className={'dropdown-caret'}>
      <i className={classNames({ open: isOpen })} />
    </div>
  );

MoshiDropdownCaretComponent.propTypes = {
  /* Hides the dropdown caret icon */
  noCaret: PropTypes.bool,
  /* Indicates whether the dropdown is opened, used for rotating the caret */
  isOpen: PropTypes.bool
};

MoshiDropdownCaretComponent.defaultProps = {
  noCaret: false,
  isOpen: false
};

const MoshiDropdownToggleComponent = ({
  value,
  prefix,
  isOpen,
  noCaret,
  toggleComponent
}) => {
  if (toggleComponent) {
    return toggleComponent;
  }

  return (
    <React.Fragment>
      <div
        className={classNames('dropdown-toggle-label', {
          'has-prefix': !_.isEmpty(prefix)
        })}
      >
        {prefix && <span className={'dropdown-prefix'}>{prefix}</span>}
        <span className={'dropdown-value'}>{value}</span>
      </div>
      <MoshiDropdownCaretComponent noCaret={noCaret} isOpen={isOpen} />
    </React.Fragment>
  );
};

MoshiDropdownToggleComponent.propTypes = {
  /** Value to display in the dropdown button */
  value: PropTypes.string.isRequired,
  /** Prefix to display in front of 'value' in the dropdown button */
  prefix: PropTypes.string,
  /* Indicates whether the dropdown is opened, used for rotating the caret */
  isOpen: PropTypes.bool,
  /* Hides the dropdown caret icon */
  noCaret: PropTypes.bool,
  /** Custom toggle button component */
  toggleComponent: PropTypes.node
};

MoshiDropdownToggleComponent.defaultProps = {
  prefix: undefined,
  isOpen: false,
  noCaret: false,
  toggleComponent: undefined
};

export default MoshiDropdownToggleComponent;
