import { getTagsUrl } from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import { POLL_TAGS } from '../core/cache/cacheTypes';
import {
  ADD_TAG_SUCCESS,
  DELETE_TAG_SUCCESS,
  UPDATE_TAG_SUCCESS
} from './tagsTypes';

export function* pollTagsSaga() {
  const configGetter = () => ({
    params: { limit: REQUEST_PAGINATION_MAX_LIMIT, page: 1 }
  });

  yield pollGenerator(POLL_TAGS, getTagsUrl, {
    retriggerActions: [ADD_TAG_SUCCESS, UPDATE_TAG_SUCCESS, DELETE_TAG_SUCCESS],
    configGetter
  });
}
