import { LOCATION_CHANGE } from 'connected-react-router';

import _ from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';

import { SET_PREVIOUS_LOCATION } from './routerHistoryTypes';

let cachedLocation = {};

export function* savePreviousLocationOnRouterChange(location) {
  const previousLocation = cachedLocation;

  cachedLocation = _.getNonEmpty(location, 'payload.location', {});

  yield put({ type: SET_PREVIOUS_LOCATION, data: previousLocation });
}

export function* routerHistorySagaWatcher() {
  yield takeEvery(LOCATION_CHANGE, savePreviousLocationOnRouterChange);
}
