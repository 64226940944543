import Color from 'color';

// Color names
export const MOSHI_COLOR_PRIMARY_NAME = 'primary';
export const MOSHI_COLOR_SECONDARY_NAME = 'secondary';
export const MOSHI_COLOR_INFO_NAME = 'info';
export const MOSHI_COLOR_LIGHT_NAME = 'light';
export const MOSHI_COLOR_SUCCESS_NAME = 'success';
export const MOSHI_COLOR_WARNING_NAME = 'warning';
export const MOSHI_COLOR_DANGER_NAME = 'danger';
export const MOSHI_COLOR_YELLOW_NAME = 'yellow';
export const MOSHI_COLOR_PURPLE_NAME = 'purple';
export const MOSHI_COLOR_BLACK_NAME = 'black';
export const MOSHI_COLOR_WHITE_NAME = 'white';
export const MOSHI_COLOR_GRAY_NAME = 'gray';
export const MOSHI_COLOR_LINK_NAME = 'link';
export const MOSHI_COLOR_TRANSPARENT_NAME = 'transparent';
export const MOSHI_COLOR_DEFAULT_NAME = 'default';

export const DEFAULT_CATEGORY_COLOR = '#317DC8';

// Color hex values
export const MOSHI_COLOR_BLUE_100 = '#f0fafe';
export const MOSHI_COLOR_BLUE_200 = '#caebfc';
export const MOSHI_COLOR_BLUE_300 = '#99dafa';
export const MOSHI_COLOR_BLUE_400 = '#69c8f7';
export const MOSHI_COLOR_BLUE_500 = '#38b6f5';
export const MOSHI_COLOR_BLUE_600 = '#0ca3ed';
export const MOSHI_COLOR_BLUE_700 = '#0a83bf';
export const MOSHI_COLOR_BLUE_800 = '#07608d';
export const MOSHI_COLOR_BLUE_900 = '#053f5c';
export const MOSHI_COLOR_BLUE_910 = '#122F4C';
export const MOSHI_COLOR_BLUE_1000 = '#021e2c';

export const MOSHI_COLOR_PRIMARY_BLUE_100 = '#2763a0';
export const MOSHI_COLOR_PASTEL_BLUE_100 = '#ebf1f5';

export const MOSHI_COLOR_RED_100 = '#fdf0ed';
export const MOSHI_COLOR_RED_200 = '#f7c9c0';
export const MOSHI_COLOR_RED_300 = '#f1a393';
export const MOSHI_COLOR_RED_400 = '#eb7c66';
export const MOSHI_COLOR_RED_500 = '#e55639';
export const MOSHI_COLOR_RED_600 = '#cf3a1b';
export const MOSHI_COLOR_RED_700 = '#a22d15';
export const MOSHI_COLOR_RED_800 = '#75210f';
export const MOSHI_COLOR_RED_900 = '#481409';

export const MOSHI_COLOR_YELLOW_500 = '#fdd230';
export const MOSHI_COLOR_YELLOW_600 = '#f7c403';

export const MOSHI_COLOR_VIOLET_500 = '#6F6BC2';

export const MOSHI_COLOR_ORANGE_100 = '#fff1eb';
export const MOSHI_COLOR_ORANGE_200 = '#fed7c8';
export const MOSHI_COLOR_ORANGE_300 = '#fdb396';
export const MOSHI_COLOR_ORANGE_400 = '#fc8f64';
export const MOSHI_COLOR_ORANGE_500 = '#fb6b32';
export const MOSHI_COLOR_ORANGE_600 = '#f54905';
export const MOSHI_COLOR_ORANGE_700 = '#b43503';
export const MOSHI_COLOR_ORANGE_800 = '#501801';

export const MOSHI_COLOR_GREEN_100 = '#f1f9f6';
export const MOSHI_COLOR_GREEN_200 = '#d8eee6';
export const MOSHI_COLOR_GREEN_300 = '#b5dece';
export const MOSHI_COLOR_GREEN_400 = '#92ceb7';
export const MOSHI_COLOR_GREEN_500 = '#6fbea0';
export const MOSHI_COLOR_GREEN_600 = '#4eac88';
export const MOSHI_COLOR_GREEN_700 = '#3e896d';
export const MOSHI_COLOR_GREEN_800 = '#2e6651';
export const MOSHI_COLOR_GREEN_900 = '#19382d';

export const MOSHI_COLOR_GREY_900 = '#406080';
export const MOSHI_COLOR_GREY_800 = '#d7d7d7';

export const MOSHI_COLOR_BLACK_800 = '#13304d';
export const MOSHI_COLOR_BLACK_900 = '#171f26';
export const MOSHI_COLOR_BLACK_1000 = '#000';

export const MOSHI_COLOR_WHITE_100 = '#fff';

export const categoryColors = [
  '#FEE895',
  '#FED7C8',
  '#F1A393',
  '#FEC8C8',
  '#B5B3E0',
  '#A9C9EA',
  '#99DAFA',
  '#B5DECE',
  '#FDDD63',
  '#FDB396',
  '#EB7C66',
  '#FD9696',
  '#928FD1',
  '#80B0E0',
  '#69C8F7',
  '#92CEB7',
  '#F7C403',
  '#FB6B32',
  '#CF3A1B',
  '#FB3232',
  '#4E49B0',
  '#317DC8',
  '#0CA3ED',
  '#4EAC88',
  '#927402',
  '#B43503',
  '#75210F',
  '#B40404',
  '#2E2B68',
  '#1D4A77',
  '#0A82BD'
];

export const getTextColorByBackgroundColor = (rawBackgroundColor) => {
  const backgroundColor = Color(rawBackgroundColor);

  const textColor = backgroundColor.isDark() ? Color('white') : Color('black');

  return textColor.hex();
};

export const isDarkColor = (color) => Color(color).isDark();

export const moshiColorsArray = [
  '#0ca3ed',
  '#4eac88',
  '#4e49b0',
  '#69c8f7',
  '#92ceb7',
  '#928fd1',
  '#caebfc',
  '#d8eee6',
  '#d8d7ef'
];

export const convertFromSemanticColor = (semanticColor) => {
  switch (semanticColor) {
    case MOSHI_COLOR_PRIMARY_NAME:
      return MOSHI_COLOR_BLUE_700;
    case MOSHI_COLOR_SUCCESS_NAME:
      return MOSHI_COLOR_GREEN_500;
    case MOSHI_COLOR_WARNING_NAME:
      return MOSHI_COLOR_ORANGE_500;
    case MOSHI_COLOR_DANGER_NAME:
      return MOSHI_COLOR_RED_600;
    case MOSHI_COLOR_YELLOW_NAME:
      return MOSHI_COLOR_YELLOW_600;
    default:
      return MOSHI_COLOR_BLACK_900;
  }
};
