import React, { useRef, useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

const useFocusOnEdit = (disabled) => {
  const containerRef = useRef();
  const [prevDisabled, setPrevDisabled] = useState(true);

  useEffect(() => {
    const containerNode = _.get(containerRef, 'current.parentElement', null);

    if (!_.isEmpty(containerNode)) {
      let editField = containerNode.querySelector('input:not([type="file"])');

      if (_.isEmpty(editField)) {
        editField = containerNode.querySelector('textarea');
      }

      if (!_.isEmpty(editField) && prevDisabled !== disabled) {
        if (disabled) {
          editField.blur();
        } else {
          editField.focus();
        }
      }

      setPrevDisabled(disabled);
    }
  }, [containerRef, disabled, prevDisabled]);

  return containerRef;
};

const CustomWidgetFieldTemplate = ({ children, classNames, disabled }) => {
  const containerRef = useFocusOnEdit(disabled);

  return (
    <React.Fragment>
      {_.includes(classNames, 'field-object') && (
        <div
          ref={(ref) => {
            if (_.includes(classNames, 'field-object')) {
              containerRef.current = ref;
            }
          }}
        />
      )}
      {children}
    </React.Fragment>
  );
};

CustomWidgetFieldTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default CustomWidgetFieldTemplate;
