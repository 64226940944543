import { useRef, useCallback } from 'react';

import _ from 'lodash';

/**
 * Check if required widget properties are empty and returns error
 *
 * @param {object} ref reference to widget element
 * @param {array} validationErrors already discovered validation errors for widget
 *
 * @returns {array | null} error array if widget is empty, null if not
 */
const handleEmptyWidgetContent = (ref, validationErrors) => {
  const requiredProps = _.getNonEmpty(ref, 'state.schema.required', undefined);
  const hasAnyEmptyProps = _.some(requiredProps, (prop) =>
    _.isEmptySafe(ref, `state.formData.${prop}`)
  );

  if (hasAnyEmptyProps && !validationErrors) {
    return [{ empty: true }];
  }

  return null;
};

/**
 * Triggers on form ref change, it sets formRef to form element and reports `state.errors` value to listener
 */

/**
 * Checks widget for errors and reports them to onErrorChange.
 * - Handles ref assignment
 * - Handles multiple calls, only triggers changes if changes occurred.
 *
 * @param {object} formRef useRef result to be populated by JsonSchemaForm ref property
 * @param {function} onErrorChange error change handler, function that reports errors to component
 * @param {boolean} editMode is widget in edit mode
 *
 * @returns {function} callback function to be used in ref property of JsonSchemaForm element
 */
export const useWidgetErrorChange = (formRef, onErrorChange, editMode) => {
  const prevErrorsRef = useRef();
  const debounceTimer = useRef();

  return useCallback(
    (ref) => {
      formRef.current = ref;

      let newErrors;

      if (editMode) {
        newErrors = _.getNonEmpty(ref, 'state.errors', undefined);
        const emptyErrors = handleEmptyWidgetContent(ref, newErrors);

        if (emptyErrors) {
          newErrors = emptyErrors;
        }
      }

      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        if (_.isEqual(prevErrorsRef.current, newErrors)) {
          return;
        }

        onErrorChange(newErrors);
        prevErrorsRef.current = newErrors;
      }, 100);
    },
    [formRef, onErrorChange, editMode]
  );
};
