import React from 'react';

import PropTypes from 'prop-types';

import { Span } from '../../../../HtmlComponents';

import { InputGroup, InputGroupAddon } from '../../../../../reactstrap';
import Visible from '../../../../layout/Visible';

const CustomWidgetGroupAllFields = ({ properties, disabled, measurement }) =>
  disabled ? (
    <React.Fragment>
      <div className={'widget-value'}>
        {properties.map((property, index) => (
          <React.Fragment key={property.content.key}>
            <Visible
              visible={disabled && index > 0}
              component={Span}
              className={'widget-value-splitter'}
            >
              /
            </Visible>
            {property.content}
          </React.Fragment>
        ))}
      </div>
      <div className={'measurement'}>{measurement}</div>
    </React.Fragment>
  ) : (
    <InputGroup size={'md'}>
      {properties.map((property, index) => (
        <React.Fragment key={property.content.key}>
          <Visible
            visible={disabled && index > 0}
            component={Span}
            className={'widget-value-splitter'}
          >
            /
          </Visible>
          {property.content}
        </React.Fragment>
      ))}
      <InputGroupAddon addonType={'append'}>{measurement}</InputGroupAddon>
    </InputGroup>
  );

CustomWidgetGroupAllFields.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool.isRequired,
  measurement: PropTypes.string
};

CustomWidgetGroupAllFields.defaultProps = {
  measurement: 'PropTypes.string'
};

export default CustomWidgetGroupAllFields;
