import React from 'react';
// eslint-disable-next-line id-blacklist
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useIsFeatureModuleEnabled } from '../../../utils/hooks/useIsFeatureModuleEnabled';

import { EmptyComponent } from '../HtmlComponents';

import { FEATURE_MODULE_LIST } from '../../../featureModules';

const MoshiNavLink = ({ to, featureModule, ...props }) => {
  const featureEnabled = useIsFeatureModuleEnabled(featureModule);

  if (!featureEnabled) {
    return <EmptyComponent />;
  }

  return <NavLink to={to} {...props} />;
};

MoshiNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  featureModule: PropTypes.oneOf(FEATURE_MODULE_LIST)
};

MoshiNavLink.defaultProps = {
  featureModule: undefined
};

export default MoshiNavLink;
