export default {
  export: {
    title: 'Export data',
    startExport: 'Start export',
    ready: {
      info: 'You can download all your data in one archive.',
      description:
        'After you click "$t(organizationSettings:export.startExport)," we will prepare your files. ' +
        'Tomorrow you will receive a download link to your admin e-mail address.'
    },
    pending: {
      info: 'Hmm, it seems like we are already preparing your export.',
      description:
        'Don’t forget to check your email tomorrow where you will find a download link.'
    },
    validation: {
      alreadyStarted:
        'Come back tomorrow if you would like to create another export.'
    }
  }
};
