import LocationAddressModel from '../LocationAddressModel';

class BusinessModel {
  constructor(name = '', street1 = '', geo = null) {
    this.name = name;
    this.address = new LocationAddressModel(street1, geo);
    this.companyID = '';
    this.taxNumber = '';
    this.website = '';
  }
}

export default BusinessModel;
