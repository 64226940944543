import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  ASSIGNEE_TYPE_RESOURCE,
  ASSIGNEE_TYPE_USER,
  CALENDAR_WEEK_VIEW,
  calendarEventStatusesList,
  defaultEventTypeColoring,
  EVENT_COLOR_SOURCE_CATEGORY,
  EVENT_COLOR_TYPE_FILL,
  EVENT_COLOR_TYPE_OUTLINE
} from '../../utils/constants/calendarConstants';
import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../utils/constants/tableConstants';
import { mapEventsForCalendar } from '../../utils/mappers/calendar-mappers';
import { selectProps } from '../../utils/redux/selectorHelpers';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../utils/table';

import {
  selectCacheCategoriesList,
  selectCachedAndDeletedCategoriesList,
  selectCachedUserIdList
} from '../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../location/locationSelectors';
import { selectCurrentOrganizationWebUISettings } from '../organization/organizationSelectors';
import { selectCurrentUserId } from '../user/userSelectors';

export const selectCalendarStore = (state) => state.calendar;

export const selectEventPatient = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'eventPatient', {})
);

export const selectEventPatientData = createSelector(
  [selectEventPatient],
  (eventPatient) => _.getNonEmpty(eventPatient, 'data', {})
);

export const selectIsCalendarEventDataPrefilled = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'prefilledEventData.isDataPrefilled', false)
);

export const selectCalendarPrefilledEventData = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'prefilledEventData.eventData', {})
);

export const selectPrefilledEventType = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'prefilledEventData.prefillType', null)
);

export const selectCalendarEventsList = createSelector(
  [selectCalendarStore, selectCachedAndDeletedCategoriesList],
  (calendarStore, categories) => {
    const events = _.getNonEmpty(calendarStore, 'list', []);
    const eventMapper = mapEventsForCalendar(categories);

    return events.map(eventMapper);
  }
);

export const selectCalendarEventDetail = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'event', {})
);

export const selectCalendarEventAuditLog = createSelector(
  [selectCalendarEventDetail],
  (eventDetail) => _.getNonEmpty(eventDetail, 'auditLog', [])
);

export const selectNonEmptyCalendarEventAuditLog = createSelector(
  [selectCalendarEventAuditLog],
  (eventFullAuditLog) => {
    // We remove updates that have empty diff[]
    const onlyUpdatesWithDiff = _.filter(
      eventFullAuditLog,
      (log) => !_.isEmptySafe(log, 'payload.diff')
    );

    return _.orderBy(
      onlyUpdatesWithDiff,
      ENTITY_FIELD_CREATED_AT_NAME,
      ORDER_ASCENDING
    );
  }
);

export const selectCalendarEventReschedulingData = createSelector(
  [selectCalendarEventDetail],
  (eventDetail) => _.getNonEmpty(eventDetail, 'reschedulingData', {})
);

// ORGANIZATION VALUES
export const selectCalendarOrganizationSlotDuration = createSelector(
  [selectCurrentOrganizationWebUISettings],
  (webUISettings) => _.getNonEmpty(webUISettings, 'calendar.defaultZoom', 30)
);

export const selectCalendarSlotDuration = createSelector(
  [selectCalendarStore, selectCalendarOrganizationSlotDuration],
  (calendarStore, organizationDuration) =>
    // If there is nothing saved in localStorage, use the organizations defaultZoom setting
    _.getNonEmpty(calendarStore, 'slotDuration', organizationDuration)
);

export const selectAreCanceledEventsVisible = createSelector(
  [selectCurrentOrganizationWebUISettings],
  (webUISettings) =>
    _.getNonEmpty(webUISettings, 'calendar.showCanceled', false)
);

export const selectWaitingList = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'calendarWaitingList.list', [])
);

export const selectCalendarSelectedDoctorIds = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'selectedDoctorIds', [])
);

export const selectCalendarAssigneeList = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'assignees.list', [])
);

export const selectCalendarLocationAssigneeList = createSelector(
  [selectCalendarAssigneeList, selectCurrentLocationId, selectCachedUserIdList],
  (assigneesList, currentLocationId, userIdList) => {
    const currentLocationAndAssignees = _.findDefault(
      assigneesList,
      { locationID: currentLocationId },
      {}
    );
    const assignees = _.getNonEmpty(
      currentLocationAndAssignees,
      'assignees',
      []
    );
    const locationUserAssignees = _.filter(assignees, {
      type: ASSIGNEE_TYPE_USER
    });
    const locationResourceAssignees = _.filter(assignees, {
      type: ASSIGNEE_TYPE_RESOURCE
    });
    const assigneesWithoutDeletedUsers = _.intersectionBy(
      locationUserAssignees,
      _.map(userIdList, (id) => ({ id })),
      'id'
    );

    return _.concat(assigneesWithoutDeletedUsers, locationResourceAssignees);
  }
);

export const selectCalendarLocationAssigneeListIds = createSelector(
  [selectCalendarLocationAssigneeList],
  (assigneesLocationList) => _.sortBy(_.map(assigneesLocationList, 'id'))
);

export const selectCalendarSelectedAssigneesIds = createSelector(
  [selectCalendarStore, selectCurrentLocationId],
  (calendarStore, currentLocation) => {
    const locationAssigneesObj = _.getNonEmpty(
      calendarStore,
      'selectedAssigneesByLocation',
      {}
    );

    const locationAssignees = _.getNonEmpty(
      locationAssigneesObj,
      currentLocation,
      []
    );

    return _.sortBy(locationAssignees);
  }
);

export const selectCalendarSelectedAssignees = createSelector(
  [selectCalendarSelectedAssigneesIds, selectCalendarLocationAssigneeList],
  (assigneeIds, assigneeList) =>
    _.intersectionBy(
      assigneeList,
      _.map(assigneeIds, (id) => ({ id })),
      'id'
    )
);

export const selectCalendarSelectedResourceIds = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.get(calendarStore, 'selectedResourceIds', [])
);

export const selectCalendarSelectedCategoryIds = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'selectedCategoryIds', [])
);

export const selectCalendarSelectedStatusIds = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'selectedStatusIds', [])
);

// ORGANIZATION VALUES
export const selectCalendarOrganizationDefaultViewType = createSelector(
  [selectCurrentOrganizationWebUISettings],
  (webUISettings) =>
    _.getNonEmpty(webUISettings, 'calendar.defaultViewType', CALENDAR_WEEK_VIEW)
);

export const selectCalendarEventTypeColoring = createSelector(
  [selectCurrentOrganizationWebUISettings],
  (webUISettings) =>
    _.getNonEmpty(
      webUISettings,
      'calendar.eventTypeColoring',
      defaultEventTypeColoring
    )
);

export const selectCalendarEventColorType = createSelector(
  [selectCalendarEventTypeColoring, selectProps],
  (eventTypeColoring, eventType) =>
    _.getNonEmpty(eventTypeColoring, eventType, EVENT_COLOR_TYPE_FILL)
);

export const selectCalendarEncounterColorType = createSelector(
  [selectCalendarEventTypeColoring],
  (eventTypeColoring) =>
    _.getNonEmpty(eventTypeColoring, 'encounter', EVENT_COLOR_TYPE_FILL)
);

export const selectCalendarReminderColorType = createSelector(
  [selectCalendarEventTypeColoring],
  (eventTypeColoring) =>
    _.getNonEmpty(eventTypeColoring, 'reminder', EVENT_COLOR_TYPE_OUTLINE)
);

export const selectCalendarOtherColorType = createSelector(
  [selectCalendarEventTypeColoring],
  (eventTypeColoring) =>
    _.getNonEmpty(eventTypeColoring, 'other', EVENT_COLOR_TYPE_OUTLINE)
);

export const selectCurrentCalendarView = createSelector(
  [selectCalendarStore, selectCalendarOrganizationDefaultViewType],
  (calendarStore, organizationViewType) =>
    _.getNonEmpty(calendarStore, 'currentCalendarView', organizationViewType)
);

export const selectCurrentCalendarEventColorSource = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(
      calendarStore,
      'calendarEventColorSource',
      EVENT_COLOR_SOURCE_CATEGORY
    )
);

export const selectCalendarFromDate = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'fromDate')
);

export const selectCalendarToDate = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'toDate')
);

export const selectCalendarWaitingList = createSelector(
  [selectWaitingList, selectCacheCategoriesList],
  (calendarWaitingList, categoriesList) => {
    const eventMapper = mapEventsForCalendar(categoriesList);
    const waitingList = calendarWaitingList.map(eventMapper);

    return _.sortBy(waitingList, 'priority');
  }
);

export const selectPatientSearchResultEventsList = createSelector(
  [selectCalendarStore, selectCacheCategoriesList],
  (calendarStore, categoriesList) => {
    const eventMapper = mapEventsForCalendar(categoriesList);
    const searchResultEvents = _.getNonEmpty(
      calendarStore,
      'patientEventsList',
      []
    ).map(eventMapper);

    return _.sortBy(searchResultEvents, (event) => new Date(event.from));
  }
);

export const selectCalendarWaitingListEventById = createSelector(
  [selectCalendarWaitingList, selectProps],
  (waitingListEvents, eventId) => _.find(waitingListEvents, { id: eventId })
);

export const selectCalendarWaitingListPriorityGroup = createSelector(
  [selectCalendarWaitingList, selectProps],
  (calendarWaitingList, priority) =>
    calendarWaitingList.filter((event) => event.priority === priority)
);

export const selectCalendarScrollTime = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'calendarScrollTime')
);

export const selectEventMessages = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'messageList', [])
);

export const selectPatientEmailConsent = createSelector(
  [selectEventPatientData],
  (eventPatient) =>
    _.getNonEmpty(eventPatient, 'notifications.reminders.email', undefined)
);

export const selectPatientSmsConsent = createSelector(
  [selectEventPatientData],
  (eventPatient) =>
    _.getNonEmpty(eventPatient, 'notifications.reminders.sms', undefined)
);

export const selectCalendarTitle = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'calendarTitle', '')
);

export const selectCalendarHighlightEvent = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'highlightEventId', null)
);

export const selectDefaultEventDoctorId = createSelector(
  [selectCurrentUserId, selectCalendarSelectedDoctorIds],
  (currentUserId, selectedDoctorIds) => {
    const firstSelectedDoctor = _.first(selectedDoctorIds);

    /**
     * If the current user is a doctor and is selected we set the current user as doctor,
     * else the first on the list is selected
     */
    return _.includes(selectedDoctorIds, currentUserId)
      ? currentUserId
      : firstSelectedDoctor;
  }
);

export const selectDefaultEventAssigneeId = createSelector(
  [selectCurrentUserId, selectCalendarLocationAssigneeListIds],
  (currentUserId, assigneeIds) => {
    const firstSelectedAssignee = _.first(assigneeIds);

    /**
     * If the current user is a doctor and is selected we set the current user as assignee,
     * else the first on the list is selected
     */
    return _.includes(assigneeIds, currentUserId)
      ? currentUserId
      : firstSelectedAssignee;
  }
);

export const selectCalendarQueriedEvents = createSelector(
  [selectCalendarStore, selectCacheCategoriesList],
  (calendarStore, categoriesList) => {
    const eventMapper = mapEventsForCalendar(categoriesList);
    const searchResultEvents = _.getNonEmpty(
      calendarStore,
      'queriedEvents.list',
      []
    ).map(eventMapper);

    return _.sortBy(searchResultEvents, (event) => new Date(event.from));
  }
);

export const selectCalendarQueriedEventsGroupedByDate = createSelector(
  [selectCalendarQueriedEvents],
  (queriedEvents) => {
    const date = (item) => moment(item.start, 'YYYY-MM-DD').toISOString();
    const resultsObj = _.groupBy(queriedEvents, date);
    const datesArray = [];

    _.forEach(resultsObj, (value, key) => {
      datesArray.push({
        date: key,
        events: value,
        isPast: moment(key).isBefore()
      });
    });

    return _.orderBy(datesArray, 'date', ORDER_DESCENDING);
  }
);

export const selectQueriedEventsLength = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'queriedEvents.pagination.resultCount', 0)
);

export const selectQueriedEventsQueryString = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'queriedEvents.queryString', '')
);

// PAGINATION SELECTORS
export const selectSearchResultsPagination = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'queriedEvents.pagination', {})
);

export const selectSearchResultsPaginationPage = createSelector(
  [selectSearchResultsPagination],
  (paginationConfig) => _.getNonEmpty(paginationConfig, 'page', 1)
);

// CALENDAR FILTERS

export const selectCalendarSearchFilter = createSelector(
  [selectCalendarStore],
  (calendarStore) => _.getNonEmpty(calendarStore, 'queriedEvents.filter', {})
);

export const selectCalendarSelectedSearchFilterAssigneeIds = createSelector(
  [selectCalendarSearchFilter],
  (filter) => _.sortBy(_.getNonEmpty(filter, 'assigneeIds', []))
);

export const selectCalendarSelectedSearchFilterCategoryIds = createSelector(
  [selectCalendarSearchFilter],
  (filter) => _.sortBy(_.getNonEmpty(filter, 'categoryIds', []))
);

export const selectCalendarSelectedSearchFilterEventStatuses = createSelector(
  [selectCalendarSearchFilter],
  (filter) =>
    _.getNonEmpty(
      filter,
      'eventStatuses',
      _.map(calendarEventStatusesList, 'id')
    )
);

export const selectCalendarSearchFilterDateFromRange = createSelector(
  [selectCalendarSearchFilter],
  (filter) => _.getNonEmpty(filter, 'fromDate', undefined)
);

export const selectCalendarSearchFilterDateToRange = createSelector(
  [selectCalendarSearchFilter],
  (filter) => _.getNonEmpty(filter, 'toDate', undefined)
);

export const selectIsCalendarAssigneesListToggled = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'isCalendarAssigneesListToggled', true)
);

export const selectIsCalendarcategoryListToggled = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'isCalendarCategoryListToggled', true)
);

export const selectIsCalendarStatusListToggled = createSelector(
  [selectCalendarStore],
  (calendarStore) =>
    _.getNonEmpty(calendarStore, 'isCalendarStatusListToggled', true)
);
