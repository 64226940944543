import axios from 'axios';

import { showMissingOrganizationIdError } from '../../../utils/alert';

import {
  GET_ENCOUNTER_DOCUMENT_SUBMISSIONS,
  GET_DOCUMENT_SUBMISSION_DATA,
  GET_PATIENT_DOCUMENT_DETAIL,
  CLEAR_DOCUMENT_SUBMISSION_DATA,
  REMOVE_ENCOUNTER_DOCUMENT_SUBMISSIONS,
  UPDATE_ENCOUNTER_DOCUMENT_SUBMISSIONS,
  ADD_ENCOUNTER_DOCUMENT_SUBMISSIONS
} from './documentSubmissionReducer';

import { selectCachedOrganizationId } from '../../core/cache/cacheSelectors';

export const getEncounterDocumentSubmissions = (encounterId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_ENCOUNTER_DOCUMENT_SUBMISSIONS,
    apiCall: axios.get(
      `/organizations/${organizationId}/encounters/${encounterId}/documents`
    )
  });
};

export const addEncounterQuestionnaireSubmission = (
  encounterId,
  questionnaireId,
  questionnaireVersion,
  formData
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = {
    data: JSON.stringify(formData),
    existingID: undefined,
    questionnaireID: questionnaireId,
    questionnaireVersion
  };

  return dispatch({
    type: ADD_ENCOUNTER_DOCUMENT_SUBMISSIONS,
    apiCall: axios.post(
      `/organizations/${organizationId}/encounters/${encounterId}/questionnaires`,
      payload
    )
  });
};

export const updateEncounterQuestionnaireSubmission = (
  submissionId,
  encounterId,
  formData
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = {
    data: JSON.stringify(formData)
  };

  return dispatch({
    type: UPDATE_ENCOUNTER_DOCUMENT_SUBMISSIONS,
    apiCall: axios.put(
      `/organizations/${organizationId}/encounters/${encounterId}/questionnaires/${submissionId}`,
      payload
    )
  });
};

export const removeEncounterQuestionnaireSubmission = (
  submissionId,
  encounterId
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: REMOVE_ENCOUNTER_DOCUMENT_SUBMISSIONS,
    apiCall: axios.delete(
      `/organizations/${organizationId}/encounters/${encounterId}/questionnaires/${submissionId}`
    )
  });
};

export const getDocumentSubmissionData = (encounterId, submissionId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_DOCUMENT_SUBMISSION_DATA,
    apiCall: axios.get(
      `/organizations/${organizationId}/encounters/${encounterId}/documents/${submissionId}`
    )
  });
};

export const getPatientDocumentDetails = (patientId, submissionId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_PATIENT_DOCUMENT_DETAIL,
    apiCall: axios.get(
      `/organizations/${organizationId}/patients/${patientId}/documents/${submissionId}`
    )
  });
};

export const clearDocumentSubmittionData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_SUBMISSION_DATA
  });
};
