import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { take, race, call, put, delay, select } from 'redux-saga/effects';

import { safeGlobalDispatch } from './storeUtils';

import { createActionTypeArray } from '../../redux/common/actionTypes';

import { dispatchError } from '../middleware/apiMiddleware';

const TIMER_BASE = moment.duration(10, 'minutes').asMilliseconds();

export function* pollGenerator(
  actionName,
  getUrl,
  {
    timer = TIMER_BASE,
    retriggerActions = [],
    configGetter,
    disabled = false
  } = {}
) {
  const [
    ACTION_IN_PROGRESS,
    ACTION_SUCCESS,
    ACTION_FAILURE
  ] = createActionTypeArray(actionName);

  while (true) {
    const state = yield select();
    const url = getUrl(() => state);

    if (!_.isEmpty(url) || disabled) {
      try {
        yield put({ type: ACTION_IN_PROGRESS });

        let config;

        if (_.isFunction(configGetter)) {
          config = yield configGetter(state);
        }

        const response = yield call(axios.get, url, config);

        const action = {
          type: ACTION_SUCCESS,
          data: response.data,
          headers: response.headers
        };

        yield put(action);
      } catch (error) {
        dispatchError(safeGlobalDispatch, error);
        yield put({ type: ACTION_FAILURE, error });
      }
    }

    yield race([delay(timer), take([...retriggerActions])]);
  }
}

/**
 * API call utility function to be used inside redux-saga for making api calls
 * @param {string} actionType original action type, will generate _IN_PROGRESS, _SUCCESS, _FAIL types while executing
 * @param {Function} apiCallFunction axios api client function ex: axios.get, axios.put
 * @param {array} apiCallParams axios api client function param in array ex: ['test/13', { data: '' }]
 * @param {any} additionalData additional data to be set to result actions ex: { id: 13 }
 */
export function* apiCall(
  actionType,
  apiCallFunction,
  apiCallParams,
  additionalData = undefined
) {
  const [
    ACTION_IN_PROGRESS,
    ACTION_SUCCESS,
    ACTION_FAILURE
  ] = createActionTypeArray(actionType);

  yield put({ type: ACTION_IN_PROGRESS });

  try {
    const response = yield call(apiCallFunction, ...apiCallParams);

    yield put({
      type: ACTION_SUCCESS,
      data: response.data,
      headers: response.headers,
      additionalData
    });
  } catch (error) {
    dispatchError(safeGlobalDispatch, error);
    yield put({ type: ACTION_FAILURE, error, additionalData });
  }
}
