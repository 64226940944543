import PropTypes from 'prop-types';

export const QuestionConditionalShape = {
  id: PropTypes.string,
  value: PropTypes.string
};

export const QuestionOptionShape = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  conditionals: PropTypes.arrayOf(PropTypes.shape(QuestionConditionalShape))
    .isRequired
};

export const QuestionFieldShape = {
  type: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disableActions: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(QuestionOptionShape)).isRequired
};

export const FormBuilderSchemaShape = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape(QuestionFieldShape))
};

export const SimpleDocumentShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  createdAt: PropTypes.string,
  archivedAt: PropTypes.string,
  usedInFlows: PropTypes.arrayOf(PropTypes.string),
  version: PropTypes.number
};

export const FileDocumentShape = {
  url: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string
};

const DocumentShape = {
  ...SimpleDocumentShape,
  formBuilderSchema: PropTypes.shape(FormBuilderSchemaShape),
  jsonSchema: PropTypes.shape()
};

export default DocumentShape;
