import _ from 'lodash';

import { translateKey } from '../../i18n';
import {
  SUB_ENTITY_TYPE_FLOW,
  SUB_ENTITY_TYPE_LOCATION
} from '../constants/entityConstants';

import { findEntityInStore } from '../storeExtensions';

export const filterEncounterByActive = (encounter) =>
  !_.has(encounter, 'completedAt');

export const filterEncounterByCompleted = (encounter) =>
  _.has(encounter, 'completedAt') && !_.isEmpty(encounter.completedAt);

export const addFlowNameToEncounter = (encounter) => {
  if (_.isEmpty(encounter.flowID)) {
    return encounter;
  }
  const flow = findEntityInStore(SUB_ENTITY_TYPE_FLOW, encounter.flowID);
  const flowName = _.get(
    flow,
    'name',
    translateKey('common:missingObject.noFlow')
  );

  _.set(encounter, 'flowName', flowName);

  return encounter;
};

export const addLocationNameToEncounter = (encounter) => {
  if (_.isEmpty(encounter.locationID)) {
    return encounter;
  }
  const location = findEntityInStore(
    SUB_ENTITY_TYPE_LOCATION,
    encounter.locationID
  );
  const locationName = _.get(
    location,
    'name',
    translateKey('common:missingObject.noLocation')
  );

  _.set(encounter, 'locationName', locationName);

  return encounter;
};
