import produce from 'immer';
import _ from 'lodash';

import { mapAndOrderCategories } from '../../../utils/mappers/calendar-mappers';

import { GET_CATEGORIES_SUCCESS } from './categoryTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  list: []
};

// eslint-disable-next-line default-param-last
const categoryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_CATEGORIES_SUCCESS:
        const categories = _.get(payload, 'response.data', []);

        draft.list = mapAndOrderCategories(categories);
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default categoryReducer;
