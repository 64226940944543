import React from 'react';

import PropTypes from 'prop-types';

import MoshiReactMarkdown from '../editor/MoshiReactMarkdown';

const CustomDescriptionField = ({ className, description }) => {
  if (!description) {
    return null;
  }

  return <MoshiReactMarkdown className={className} source={description} />;
};

CustomDescriptionField.propTypes = {
  description: PropTypes.string,
  className: PropTypes.string
};

CustomDescriptionField.defaultProps = {
  description: '',
  className: ''
};

export default CustomDescriptionField;
