export default {
  id: 'vitalSign/BODY_MASS/v1',
  formSchema: {
    title: 'encounter:widget.vitalSigns.bodyMass',
    type: 'object',
    required: ['id001'],
    properties: {
      id001: {
        type: 'number',
        minimum: 1,
        maximum: 999,
        title: 'encounter:widget.vitalSigns.bodyMass'
      }
    },
    iconClass: 'icon-download',
    measurement: 'kg'
  },
  uiSchema: {
    id001: { 'ui:widget': 'customI18nNumericWidget' },
    'x-customClass': 'vital-signs-widget',
    'x-cancelBtnSize': 'sm',
    'x-saveBtnSize': 'sm'
  }
};
