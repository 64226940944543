import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Displays label above the radio button.
 *
 * @example ./__examples__/VerticalRadioButton.md
 */
const VerticalRadioButton = ({
  name,
  disabled,
  label,
  value,
  checked,
  onChange
}) => (
  <label
    key={value}
    className={classNames(
      'radio form-control d-flex flex-column px-1 mb-5 text-center moshi-vertical-radio',
      { checked }
    )}
  >
    <span className={'radio-label mb-3'}>{label}</span>

    <span className={'position-relative radio-wrapper'}>
      <input
        type={'radio'}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={'radio-icon'} />
    </span>
  </label>
);

VerticalRadioButton.propTypes = {
  /** Name of radio input, used for groups */
  name: PropTypes.string.isRequired,
  /** Function fired when user clicks on radio button */
  onChange: PropTypes.func.isRequired,
  /** Flag whether radio button is selected or not */
  checked: PropTypes.bool.isRequired,
  /** Label of radio button */
  label: PropTypes.string.isRequired,
  /** Value, passed along when user clicks radio button */
  value: PropTypes.number,
  /** Flag whether radio button is disabled or not */
  disabled: PropTypes.bool
};

VerticalRadioButton.defaultProps = {
  value: undefined,
  disabled: false
};

export default VerticalRadioButton;
