import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import Dropdown, {
  MenuItem,
  DropdownToggle,
  DropdownMenu,
  DropdownMenuWrapper
} from '@trendmicro/react-dropdown';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import { getSafeTranslation } from '../../../../utils/language';
import { isNotEmptyFilter } from '../../../../utils/mappers/common-mappers';
import {
  findEntityInStore,
  getSubEntityDropdownMapper
} from '../../../../utils/storeExtensions';

import DropdownItemShape, {
  DropdownItemWithColorShape
} from '../../../../metadata/shapes/DropdownItemShape';

import Visible from '../../layout/Visible';
import MoshiDisabledFormDropdown from './MoshiDisabledFormDropdown';
import MoshiDropdownFilter from './MoshiDropdownFilter';
import MoshiDropdownMenuItemContent from './MoshiDropdownMenuItemContent';
import MoshiDropdownToggle from './MoshiDropdownToggle';

export const DROPDOWN_CENTER_TEXT_LOOK = 'center-text';
export const DROPDOWN_FORM_LOOK = 'form';
export const DROPDOWN_FILTER_LOOK = 'filter';
export const DROPDOWN_BUTTON_LOOK = 'button';
export const DROPDOWN_TINY_LOOK = 'tiny';
export const DROPDOWN_NO_STYLE_LOOK = 'none';

const getToggleClassNames = (look) => {
  switch (look) {
    case DROPDOWN_NO_STYLE_LOOK:
      return classNames('no-style-look');
    case DROPDOWN_FORM_LOOK:
      return classNames('btn', 'btn-lg', 'no-break', 'form-look');
    case DROPDOWN_BUTTON_LOOK:
      return classNames('btn', 'button-look');
    case DROPDOWN_TINY_LOOK:
      return classNames('tiny-look');
    case DROPDOWN_CENTER_TEXT_LOOK:
      return classNames('center-text-look');
    case DROPDOWN_FILTER_LOOK:
    default:
      return classNames('filter-look');
  }
};

/**
 * The common Dropdown component
 *
 * @example ./__examples__/MoshiDropdown.md
 */
/* eslint-disable max-lines-per-function, max-statements */
export const MoshiDropdownComponent = ({
  t: translate,
  placeholder,
  items,
  onSelect,
  onToggle,
  onClose,
  value,
  toggleClassName,
  noCaret,
  valuePrefix,
  disabled,
  readOnly,
  id,
  dropdownName,
  className,
  entityType,
  enableDeselection,
  openMenuToLeft,
  filterEnabled,
  look,
  toggleComponent,
  componentClass,
  toggleComponentClass,
  tabIndex,
  fullWidthFilterMenu
}) => {
  const [filterWidth, setFilterWidth] = useState(0);

  const setFilterWidthToMenu = (menuRef) => {
    if (filterEnabled) {
      // eslint-disable-next-line react/no-find-dom-node
      const menu = ReactDOM.findDOMNode(menuRef);

      if (menu instanceof HTMLElement && filterWidth !== menu.offsetWidth) {
        const width = menu.offsetWidth;

        setFilterWidth(width === 0 ? 200 : width);
      }
    }
  };

  const emptyText = _.isEmptySafe(placeholder)
    ? translate('common:empty')
    : placeholder;
  const fallbackText = disabled ? '/' : emptyText;
  const allFoundItems = _.cloneDeep(_.filter(items), isNotEmptyFilter);
  const [enteredFilter, setEnteredFilter] = useState('');

  if (!_.isEmptySafe(entityType)) {
    const foundEntity = findEntityInStore(entityType, value, true);
    const dropdownMapper = getSubEntityDropdownMapper(entityType);

    if (!_.isEmptySafe(foundEntity)) {
      allFoundItems.push(dropdownMapper(foundEntity));
    }
  }

  const foundItem = _.findDefault(
    allFoundItems,
    { id: value },
    { name: fallbackText }
  );

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = (dropdownOpen) => {
    setIsOpen(dropdownOpen);
    onToggle(dropdownOpen);
  };

  const getValuePrefix = (prefix) => {
    if (_.isEmptySafe(prefix)) {
      return '';
    }

    return `${prefix} `;
  };

  const filteredItems = _.filter(allFoundItems, (item) =>
    _.includes(_.toLower(item.name), enteredFilter)
  );
  const fallbackId =
    _.isString(dropdownName) && dropdownName.length > 0
      ? _.kebabCase(dropdownName)
      : undefined;

  if (look === DROPDOWN_FORM_LOOK && disabled) {
    return (
      <MoshiDisabledFormDropdown
        name={dropdownName}
        id={id || fallbackId}
        prefix={getValuePrefix(valuePrefix)}
        value={getSafeTranslation(foundItem.name)}
      />
    );
  }

  const unselectItem = {
    id: null,
    name: translate('common:dropdownUnselect'),
    className: 'text-color-grey'
  };
  const itemsToDisplay = enableDeselection
    ? [unselectItem, ...filteredItems]
    : filteredItems;

  return (
    <Dropdown
      name={dropdownName}
      id={id || fallbackId}
      disabled={disabled || readOnly}
      onClose={() => {
        setEnteredFilter('');
        onClose();
      }}
      onToggle={handleToggle}
      className={classNames('dropdown', className, {
        empty: _.isEmptySafe(value)
      })}
      componentClass={componentClass}
    >
      <DropdownToggle
        noCaret
        className={classNames(
          'dropdown-toggle',
          toggleClassName,
          getToggleClassNames(look),
          { 'no-caret': noCaret },
          { open: isOpen }
        )}
        componentClass={toggleComponentClass}
        tabIndex={tabIndex}
      >
        <MoshiDropdownToggle
          value={getSafeTranslation(foundItem.name)}
          prefix={getValuePrefix(valuePrefix)}
          noCaret={noCaret}
          isOpen={isOpen}
          toggleComponent={toggleComponent}
          look={look}
        />
      </DropdownToggle>
      <DropdownMenuWrapper
        className={classNames('dropdown-menu', {
          'flip-lr': openMenuToLeft
        })}
      >
        <MoshiDropdownFilter
          isOpen={isOpen}
          filterEnabled={filterEnabled}
          enteredFilter={enteredFilter}
          setEnteredFilter={setEnteredFilter}
          menuItemLength={_.isArray(filteredItems) ? filteredItems.length : 0}
          width={filterWidth}
          fullWidthInputField={fullWidthFilterMenu}
        />
        <DropdownMenu
          className={classNames('dropdown-menu-content', {
            'w-100': fullWidthFilterMenu
          })}
          ref={(menuRef) => setFilterWidthToMenu(menuRef)}
        >
          {itemsToDisplay.map((item, index) => (
            <MenuItem
              disabled={item.disabled}
              onSelect={() => {
                if (!_.isArray(item.subItems)) {
                  onSelect(item);
                }
              }}
              key={_.get(item, 'key', item.id)}
            >
              <MoshiDropdownMenuItemContent
                item={item}
                isSelected={foundItem.id === item.id}
                className={classNames(item.className, {
                  'flipped-submenu-content': openMenuToLeft
                })}
                id={`dropdown-item-${index}`}
              />
              {_.isArray(item.subItems) &&
                item.subItems.map((subItem, subItemIndex) => (
                  <MenuItem
                    disabled={subItem.disabled}
                    onSelect={(unused, event) => {
                      event.stopPropagation();
                      onSelect(subItem);
                    }}
                    key={subItem.id}
                    className={classNames(subItem.className, {
                      'flipped-submenu-container': openMenuToLeft
                    })}
                  >
                    <MoshiDropdownMenuItemContent
                      item={subItem}
                      id={`submenu-item-${index}${subItemIndex}`}
                      className={classNames({
                        'flipped-submenu-content': openMenuToLeft
                      })}
                    />
                  </MenuItem>
                ))}
            </MenuItem>
          ))}
          <Visible visible={_.isEmptySafe(items)}>
            <MenuItem
              className={classNames({
                'flipped-submenu-content': openMenuToLeft
              })}
            >
              {translate('common:noItemsFound')}
            </MenuItem>
          </Visible>
        </DropdownMenu>
      </DropdownMenuWrapper>
    </Dropdown>
  );
};

MoshiDropdownComponent.propTypes = {
  /** i18next translation function */
  t: PropTypes.func.isRequired,
  /** Function that fires when an item is selected and passes back the whole object */
  onSelect: PropTypes.func.isRequired,
  /** Function that fires when the dropdown is toggled */
  onToggle: PropTypes.func,
  /** Function that fires when the dropdown menu is closed */
  onClose: PropTypes.func,
  /** This is an array of objects that populates the dropdown, each item needs to have `id` and `name` properties */
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ ...DropdownItemShape })),
    PropTypes.arrayOf(PropTypes.shape({ ...DropdownItemWithColorShape }))
  ]).isRequired,
  /** This is the actual selected value `id` of the item */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  /** This is the default text before anything is selected it can also be used as constant text */
  placeholder: PropTypes.string,
  /** Can add extra class/es to the toggle button */
  toggleClassName: PropTypes.string,
  /** Disables right icon caret */
  noCaret: PropTypes.bool,
  /** Adds a prefix when the value is set and the name of the item is displayed */
  valuePrefix: PropTypes.string,
  /** Disables the dropdown and visually turns it into simple non-clickable text */
  disabled: PropTypes.bool,
  /** Disables the dropdown */
  readOnly: PropTypes.bool,
  /** HTML Id attribute on the top dropdown wrapper */
  id: PropTypes.string,
  /** HTML 'name' attribute, also 'id' attribute when id prop not given */
  dropdownName: PropTypes.string,
  /** Can add extra class/es to the top dropdown wrapper */
  className: PropTypes.string,
  /** If this is set it will try to get the data for the selected value from the deleted cache in redux store */
  entityType: PropTypes.string,
  /** Will enable to deselect an item to return to empty state */
  enableDeselection: PropTypes.bool,
  /** This only applies when using multi levels, it will flip the submenu to the left */
  openMenuToLeft: PropTypes.bool,
  /** Enables item filtering input */
  filterEnabled: PropTypes.bool,
  /** Sets the look of the dropdown, can be: 'none', 'filter', 'form', 'button', 'tiny' */
  look: PropTypes.oneOf([
    DROPDOWN_FORM_LOOK,
    DROPDOWN_FILTER_LOOK,
    DROPDOWN_BUTTON_LOOK,
    DROPDOWN_TINY_LOOK,
    DROPDOWN_NO_STYLE_LOOK,
    DROPDOWN_CENTER_TEXT_LOOK
  ]),
  /** Custom toggle button component used only if no 'look' fits the purpose */
  toggleComponent: PropTypes.node,
  /** Type of outter-most wrapper, default ButtonGroup (<div>) */
  // eslint-disable-next-line react/no-typos
  componentClass: PropTypes.elementType,
  /** Type of dropdown toggle, default Button (<button>) */
  // eslint-disable-next-line react/no-typos
  toggleComponentClass: PropTypes.elementType,
  /** Sets toggle as focusable if value is larger than 1 */
  tabIndex: PropTypes.number,
  /** Sets the width of the dropdown menu to the width of the toggle */
  fullWidthFilterMenu: PropTypes.bool
};

MoshiDropdownComponent.defaultProps = {
  onToggle: _.noop,
  onClose: _.noop,
  toggleClassName: '',
  value: '',
  placeholder: '',
  noCaret: false,
  valuePrefix: '',
  disabled: false,
  readOnly: false,
  id: undefined,
  dropdownName: undefined,
  className: undefined,
  entityType: undefined,
  enableDeselection: false,
  openMenuToLeft: false,
  filterEnabled: false,
  look: DROPDOWN_FILTER_LOOK,
  toggleComponent: undefined,
  componentClass: undefined,
  toggleComponentClass: undefined,
  tabIndex: undefined,
  fullWidthFilterMenu: false
};

export default localize(MoshiDropdownComponent);
