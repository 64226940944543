import _ from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { getCalculationRefreshDataList } from '../../../../utils/widgets';

import { fetchPatientTherapyList } from '../../../therapy/therapyActions';

import {
  handleRefreshDataForTherapy,
  handleRefreshDataForWidgetHistory
} from './indexCalculationWidgetSagas';

import { FETCH_PATIENT_THERAPY_LIST } from '../../../therapy/therapyTypes';

import { selectPatientDetailId } from '../../patientSelectors';

export function* handleRefreshData(widgetType) {
  const refreshDataList = getCalculationRefreshDataList(widgetType);

  if (_.isEmptySafe(refreshDataList)) return;

  yield all([
    handleRefreshDataForWidgetHistory(refreshDataList),
    handleRefreshDataForTherapy(refreshDataList)
  ]);
}

export function* fetchPatientTherapyListSaga() {
  const patientId = yield select(selectPatientDetailId);

  yield put(fetchPatientTherapyList(patientId));
}

export function* fetchPatientTherapyListener() {
  yield takeLatest(FETCH_PATIENT_THERAPY_LIST, fetchPatientTherapyListSaga);
}
