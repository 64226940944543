/* eslint-disable max-len,max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from '../../../../layout/Visible';

export const HUMANOID_RIGHT_FOOT_ID = 'right-foot';
const GROUP = `zoomed-${HUMANOID_RIGHT_FOOT_ID}-interactive`;

const ANKLE_RIGHT = `${HUMANOID_RIGHT_FOOT_ID}-ankle`;
const INSTEP_RIGHT = `${HUMANOID_RIGHT_FOOT_ID}-instep`;

const THUMB_TIP = `${HUMANOID_RIGHT_FOOT_ID}-thumb-tip`;
const INDEX_TIP = `${HUMANOID_RIGHT_FOOT_ID}-index-tip`;
const MIDDLE_TIP = `${HUMANOID_RIGHT_FOOT_ID}-middle-tip`;
const RING_TIP = `${HUMANOID_RIGHT_FOOT_ID}-ring-tip`;
const PINKIE_TIP = `${HUMANOID_RIGHT_FOOT_ID}-pinkie-tip`;

const THUMB_LOWER = `${HUMANOID_RIGHT_FOOT_ID}-thumb-low`;
const INDEX_LOWER = `${HUMANOID_RIGHT_FOOT_ID}-index-low`;
const MIDDLE_LOWER = `${HUMANOID_RIGHT_FOOT_ID}-middle-low`;
const RING_LOWER = `${HUMANOID_RIGHT_FOOT_ID}-ring-low`;
const PINKIE_LOWER = `${HUMANOID_RIGHT_FOOT_ID}-pinkie-low`;

const RightFootInteractive = ({
  getColor,
  onChange,
  initialSelectedParts,
  anklesDisabled,
  instepDisabled,
  lowDisabled
}) => {
  const [checkedList, setCheckedList] = useState(initialSelectedParts);

  const isChecked = useCallback((partId) => _.includes(checkedList, partId), [
    checkedList
  ]);

  useEffect(() => {
    onChange(HUMANOID_RIGHT_FOOT_ID, checkedList);
  }, [checkedList, onChange]);

  const handleClick = useCallback(
    (partId) => {
      const result = _.pushOrRemoveIntoArray(checkedList, partId);

      setCheckedList(result);
    },
    [checkedList]
  );

  return (
    <g id={GROUP}>
      <Visible visible={!anklesDisabled}>
        <g
          id={ANKLE_RIGHT}
          onClick={() => handleClick(ANKLE_RIGHT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M55 584C55 578.477 59.4772 574 65 574C70.5228 574 75 578.477 75 584C75 589.523 70.5228 594 65 594C59.4772 594 55 589.523 55 584Z'
            }
            fill={'white'}
          />
          {isChecked(ANKLE_RIGHT) && (
            <circle cx={'65'} cy={'584'} r={'5'} fill={getColor(ANKLE_RIGHT)} />
          )}
          <path
            d={
              'M65 592C60.5817 592 57 588.418 57 584H53C53 590.627 58.3726 596 65 596V592ZM73 584C73 588.418 69.4183 592 65 592V596C71.6274 596 77 590.627 77 584H73ZM65 576C69.4183 576 73 579.582 73 584H77C77 577.373 71.6274 572 65 572V576ZM65 572C58.3726 572 53 577.373 53 584H57C57 579.582 60.5817 576 65 576V572Z'
            }
            fill={getColor(ANKLE_RIGHT)}
          />
        </g>
      </Visible>

      <Visible visible={!instepDisabled}>
        <g
          id={INSTEP_RIGHT}
          onClick={() => handleClick(INSTEP_RIGHT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M50 658C50 652.477 54.4772 648 60 648V648C65.5228 648 70 652.477 70 658V658C70 663.523 65.5228 668 60 668V668C54.4772 668 50 663.523 50 658V658Z'
            }
            fill={'white'}
          />
          {isChecked(INSTEP_RIGHT) && (
            <circle
              cx={'60'}
              cy={'658'}
              r={'5'}
              fill={getColor(INSTEP_RIGHT)}
            />
          )}
          <path
            d={
              'M60 666C55.5817 666 52 662.418 52 658H48C48 664.627 53.3726 670 60 670V666ZM68 658C68 662.418 64.4183 666 60 666V670C66.6274 670 72 664.627 72 658H68ZM60 650C64.4183 650 68 653.582 68 658H72C72 651.373 66.6274 646 60 646V650ZM60 646C53.3726 646 48 651.373 48 658H52C52 653.582 55.5817 650 60 650V646Z'
            }
            fill={getColor(INSTEP_RIGHT)}
          />
        </g>
      </Visible>

      <g
        id={RING_TIP}
        onClick={() => handleClick(RING_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M17 755C17 749.477 21.4772 745 27 745V745C32.5228 745 37 749.477 37 755V755C37 760.523 32.5228 765 27 765V765C21.4772 765 17 760.523 17 755V755Z'
          }
          fill={'white'}
        />
        {isChecked(RING_TIP) && (
          <circle cx={'27'} cy={'755'} r={'5'} fill={getColor(RING_TIP)} />
        )}
        <path
          d={
            'M27 763C22.5817 763 19 759.418 19 755H15C15 761.627 20.3726 767 27 767V763ZM35 755C35 759.418 31.4183 763 27 763V767C33.6274 767 39 761.627 39 755H35ZM27 747C31.4183 747 35 750.582 35 755H39C39 748.373 33.6274 743 27 743V747ZM27 743C20.3726 743 15 748.373 15 755H19C19 750.582 22.5817 747 27 747V743Z'
          }
          fill={getColor(RING_TIP)}
        />
      </g>
      <g
        id={MIDDLE_TIP}
        onClick={() => handleClick(MIDDLE_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M34 769C34 763.477 38.4772 759 44 759V759C49.5228 759 54 763.477 54 769V769C54 774.523 49.5228 779 44 779V779C38.4772 779 34 774.523 34 769V769Z'
          }
          fill={'white'}
        />
        {isChecked(MIDDLE_TIP) && (
          <circle cx={'44'} cy={'769'} r={'5'} fill={getColor(MIDDLE_TIP)} />
        )}
        <path
          d={
            'M44 777C39.5817 777 36 773.418 36 769H32C32 775.627 37.3726 781 44 781V777ZM52 769C52 773.418 48.4183 777 44 777V781C50.6274 781 56 775.627 56 769H52ZM44 761C48.4183 761 52 764.582 52 769H56C56 762.373 50.6274 757 44 757V761ZM44 757C37.3726 757 32 762.373 32 769H36C36 764.582 39.5817 761 44 761V757Z'
          }
          fill={getColor(MIDDLE_TIP)}
        />
      </g>
      <g
        id={INDEX_TIP}
        onClick={() => handleClick(INDEX_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M52 782C52 776.477 56.4772 772 62 772V772C67.5228 772 72 776.477 72 782V782C72 787.523 67.5228 792 62 792V792C56.4772 792 52 787.523 52 782V782Z'
          }
          fill={'white'}
        />
        {isChecked(INDEX_TIP) && (
          <circle cx={'62'} cy={'782'} r={'5'} fill={getColor(INDEX_TIP)} />
        )}
        <path
          d={
            'M62 790C57.5817 790 54 786.418 54 782H50C50 788.627 55.3726 794 62 794V790ZM70 782C70 786.418 66.4183 790 62 790V794C68.6274 794 74 788.627 74 782H70ZM62 774C66.4183 774 70 777.582 70 782H74C74 775.373 68.6274 770 62 770V774ZM62 770C55.3726 770 50 775.373 50 782H54C54 777.582 57.5817 774 62 774V770Z'
          }
          fill={getColor(INDEX_TIP)}
        />
      </g>
      <g
        id={THUMB_TIP}
        onClick={() => handleClick(THUMB_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M80 778C80 772.477 84.4772 768 90 768V768C95.5228 768 100 772.477 100 778V778C100 783.523 95.5228 788 90 788V788C84.4772 788 80 783.523 80 778V778Z'
          }
          fill={'white'}
        />
        {isChecked(THUMB_TIP) && (
          <circle cx={'90'} cy={'778'} r={'5'} fill={getColor(THUMB_TIP)} />
        )}
        <path
          d={
            'M90 786C85.5817 786 82 782.418 82 778H78C78 784.627 83.3726 790 90 790V786ZM98 778C98 782.418 94.4183 786 90 786V790C96.6274 790 102 784.627 102 778H98ZM90 770C94.4183 770 98 773.582 98 778H102C102 771.373 96.6274 766 90 766V770ZM90 766C83.3726 766 78 771.373 78 778H82C82 773.582 85.5817 770 90 770V766Z'
          }
          fill={getColor(THUMB_TIP)}
        />
      </g>
      <g
        id={PINKIE_TIP}
        onClick={() => handleClick(PINKIE_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M0 740C0 734.477 4.47715 730 10 730V730C15.5228 730 20 734.477 20 740V740C20 745.523 15.5228 750 10 750V750C4.47715 750 0 745.523 0 740V740Z'
          }
          fill={'white'}
        />
        {isChecked(PINKIE_TIP) && (
          <circle cx={'10'} cy={'740'} r={'5'} fill={getColor(PINKIE_TIP)} />
        )}
        <path
          d={
            'M10 748C5.58172 748 2 744.418 2 740H-2C-2 746.627 3.37258 752 10 752V748ZM18 740C18 744.418 14.4183 748 10 748V752C16.6274 752 22 746.627 22 740H18ZM10 732C14.4183 732 18 735.582 18 740H22C22 733.373 16.6274 728 10 728V732ZM10 728C3.37258 728 -2 733.373 -2 740H2C2 735.582 5.58172 732 10 732V728Z'
          }
          fill={getColor(PINKIE_TIP)}
        />
      </g>
      <Visible visible={!lowDisabled}>
        <g
          id={PINKIE_LOWER}
          onClick={() => handleClick(PINKIE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M3 710C3 704.477 7.47715 700 13 700V700C18.5228 700 23 704.477 23 710V710C23 715.523 18.5228 720 13 720V720C7.47715 720 3 715.523 3 710V710Z'
            }
            fill={'white'}
          />
          {isChecked(PINKIE_LOWER) && (
            <circle
              cx={'13'}
              cy={'710'}
              r={'5'}
              fill={getColor(PINKIE_LOWER)}
            />
          )}
          <path
            d={
              'M13 718C8.58172 718 5 714.418 5 710H1C1 716.627 6.37258 722 13 722V718ZM21 710C21 714.418 17.4183 718 13 718V722C19.6274 722 25 716.627 25 710H21ZM13 702C17.4183 702 21 705.582 21 710H25C25 703.373 19.6274 698 13 698V702ZM13 698C6.37258 698 1 703.373 1 710H5C5 705.582 8.58172 702 13 702V698Z'
            }
            fill={getColor(PINKIE_LOWER)}
          />
        </g>
        <g
          id={RING_LOWER}
          onClick={() => handleClick(RING_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M19 729C19 723.477 23.4772 719 29 719V719C34.5228 719 39 723.477 39 729V729C39 734.523 34.5228 739 29 739V739C23.4772 739 19 734.523 19 729V729Z'
            }
            fill={'white'}
          />
          {isChecked(RING_LOWER) && (
            <circle cx={'29'} cy={'729'} r={'5'} fill={getColor(RING_LOWER)} />
          )}
          <path
            d={
              'M29 737C24.5817 737 21 733.418 21 729H17C17 735.627 22.3726 741 29 741V737ZM37 729C37 733.418 33.4183 737 29 737V741C35.6274 741 41 735.627 41 729H37ZM29 721C33.4183 721 37 724.582 37 729H41C41 722.373 35.6274 717 29 717V721ZM29 717C22.3726 717 17 722.373 17 729H21C21 724.582 24.5817 721 29 721V717Z'
            }
            fill={getColor(RING_LOWER)}
          />
        </g>
        <g
          id={MIDDLE_LOWER}
          onClick={() => handleClick(MIDDLE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M34 743C34 737.477 38.4772 733 44 733V733C49.5228 733 54 737.477 54 743V743C54 748.523 49.5228 753 44 753V753C38.4772 753 34 748.523 34 743V743Z'
            }
            fill={'white'}
          />
          {isChecked(MIDDLE_LOWER) && (
            <circle
              cx={'44'}
              cy={'743'}
              r={'5'}
              fill={getColor(MIDDLE_LOWER)}
            />
          )}
          <path
            d={
              'M44 751C39.5817 751 36 747.418 36 743H32C32 749.627 37.3726 755 44 755V751ZM52 743C52 747.418 48.4183 751 44 751V755C50.6274 755 56 749.627 56 743H52ZM44 735C48.4183 735 52 738.582 52 743H56C56 736.373 50.6274 731 44 731V735ZM44 731C37.3726 731 32 736.373 32 743H36C36 738.582 39.5817 735 44 735V731Z'
            }
            fill={getColor(MIDDLE_LOWER)}
          />
        </g>
        <g
          id={INDEX_LOWER}
          onClick={() => handleClick(INDEX_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M54 750C54 744.477 58.4772 740 64 740V740C69.5228 740 74 744.477 74 750V750C74 755.523 69.5228 760 64 760V760C58.4772 760 54 755.523 54 750V750Z'
            }
            fill={'white'}
          />
          {isChecked(INDEX_LOWER) && (
            <circle cx={'64'} cy={'750'} r={'5'} fill={getColor(INDEX_LOWER)} />
          )}
          <path
            d={
              'M64 758C59.5817 758 56 754.418 56 750H52C52 756.627 57.3726 762 64 762V758ZM72 750C72 754.418 68.4183 758 64 758V762C70.6274 762 76 756.627 76 750H72ZM64 742C68.4183 742 72 745.582 72 750H76C76 743.373 70.6274 738 64 738V742ZM64 738C57.3726 738 52 743.373 52 750H56C56 745.582 59.5817 742 64 742V738Z'
            }
            fill={getColor(INDEX_LOWER)}
          />
        </g>
        <g
          id={THUMB_LOWER}
          onClick={() => handleClick(THUMB_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M81 751C81 745.477 85.4772 741 91 741V741C96.5228 741 101 745.477 101 751V751C101 756.523 96.5228 761 91 761V761C85.4772 761 81 756.523 81 751V751Z'
            }
            fill={'white'}
          />
          {isChecked(THUMB_LOWER) && (
            <circle cx={'91'} cy={'751'} r={'5'} fill={getColor(THUMB_LOWER)} />
          )}
          <path
            d={
              'M91 759C86.5817 759 83 755.418 83 751H79C79 757.627 84.3726 763 91 763V759ZM99 751C99 755.418 95.4183 759 91 759V763C97.6274 763 103 757.627 103 751H99ZM91 743C95.4183 743 99 746.582 99 751H103C103 744.373 97.6274 739 91 739V743ZM91 739C84.3726 739 79 744.373 79 751H83C83 746.582 86.5817 743 91 743V739Z'
            }
            fill={getColor(THUMB_LOWER)}
          />
        </g>
      </Visible>
    </g>
  );
};

RightFootInteractive.propTypes = {
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  anklesDisabled: PropTypes.bool,
  instepDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool
};

RightFootInteractive.defaultProps = {
  onChange: _.noop,
  initialSelectedParts: [],
  anklesDisabled: false,
  instepDisabled: false,
  lowDisabled: false
};

export default RightFootInteractive;
