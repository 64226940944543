import _ from 'lodash';
import moment from 'moment';
import { select, all, takeEvery, put } from 'redux-saga/effects';

import { getEventLogListUrl } from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import { getBookingEventLogList } from './eventLogActions';

import {
  POLL_EVENT_LOGS,
  POLL_EVENT_LOGS_SUCCESS
} from '../core/cache/cacheTypes';

import { UPDATE_SELECTED_LOCATION } from '../location/locationReducer';

import { selectCurrentLocationId } from '../location/locationSelectors';
import { selectIsOnlineBookingModuleEnabled } from '../organization/organizationSelectors';
import { selectEventLogLastListFetch } from './eventLogSelectors';

export function* fetchEventLogList(action) {
  const state = yield select();
  const locationId = selectCurrentLocationId(state);

  if (_.isEmptySafe(action, 'data')) {
    return;
  }

  yield put(getBookingEventLogList(locationId));
}

export function* eventLogSagaWatcher() {
  yield all([takeEvery(POLL_EVENT_LOGS_SUCCESS, fetchEventLogList)]);
}

export function* pollEventLogSaga() {
  const state = yield select();
  const isOnlineBookingModuleEnabled = selectIsOnlineBookingModuleEnabled(
    state
  );
  const configGetter = (store) => {
    const lastListFetch = selectEventLogLastListFetch(store);
    const locationId = selectCurrentLocationId(store);

    return {
      params: {
        from: lastListFetch,
        locationIds: locationId,
        limit: REQUEST_PAGINATION_MAX_LIMIT,
        page: 1,
        eventTypes: ['bookingCreated', 'bookingCanceledByPatient'].join(',')
      }
    };
  };

  yield pollGenerator(POLL_EVENT_LOGS, getEventLogListUrl, {
    configGetter,
    timer: moment.duration(1, 'minutes').asMilliseconds(),
    retriggerActions: [UPDATE_SELECTED_LOCATION],
    disabled: !isOnlineBookingModuleEnabled
  });
}
