import axios from 'axios';
import _ from 'lodash';

import { showAlertSuccess } from '../../../utils/alert';
import {
  getFormRequestDetailUrl,
  getFormSubmissionRequestDetailUrl,
  getFormSubmissionRequestsUrl
} from '../../../utils/api/apiUrlUtils';
import { getFormRequestPayload } from '../../../utils/mappers/cleaned/document-mappers';

import {
  ADD_FORM_REQUEST,
  UPDATE_FORM_REQUEST,
  REMOVE_FORM_REQUEST,
  GET_FORM_REQUEST_DETAIL,
  CLEAR_FORM_REQUEST_DETAIL
} from './formRequestTypes';

export const getFormRequestDetail = (patientId, requestId) => (
  dispatch,
  getState
) =>
  dispatch({
    type: GET_FORM_REQUEST_DETAIL,
    apiCall: axios.get(getFormRequestDetailUrl(getState, patientId, requestId))
  });

export const addOrUpdateFormRequest = (
  patientId,
  { formId, version },
  requestId = undefined
) => (dispatch) => {
  if (_.isEmptySafe(requestId)) {
    return dispatch(addFormRequest(patientId, { formId, version }));
  } else {
    return dispatch(
      updateFormRequest(patientId, { formId, version }, requestId)
    );
  }
};

export const addFormRequest = (patientId, { formId, version }) => (
  dispatch,
  getState
) => {
  const formSubmissionRequestsUrl = getFormSubmissionRequestsUrl(
    getState,
    patientId
  );
  const payload = getFormRequestPayload(formId, version);

  return dispatch({
    type: ADD_FORM_REQUEST,
    apiCall: axios.post(formSubmissionRequestsUrl, payload),
    onSuccess: () => showAlertSuccess('document:alert.requestCreated')
  });
};

export const updateFormRequest = (
  patientId,
  { formId, version },
  requestId
) => (dispatch, getState) => {
  const formSubmissionRequestDetailUrl = getFormSubmissionRequestDetailUrl(
    getState,
    patientId,
    requestId
  );
  const payload = getFormRequestPayload(formId, version);

  return dispatch({
    type: UPDATE_FORM_REQUEST,
    apiCall: axios.put(formSubmissionRequestDetailUrl, payload),
    onSuccess: () => showAlertSuccess('document:alert.requestUpdated')
  });
};

export const removeFormRequest = (patientId, requestId) => (
  dispatch,
  getState
) => {
  const formSubmissionRequestDetailUrl = getFormSubmissionRequestDetailUrl(
    getState,
    patientId,
    requestId
  );

  return dispatch({
    type: REMOVE_FORM_REQUEST,
    apiCall: axios.delete(formSubmissionRequestDetailUrl),
    onSuccess: () => {
      showAlertSuccess('document:alert.templateRemoved');
    }
  });
};

export const clearFormRequestDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_FORM_REQUEST_DETAIL
  });
