import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getSafeTranslation } from '../../../../../../utils/language';
import { getWidgetFormSchema } from '../../../../../../utils/widgets';

const NoFormValueError = ({ dataPath, widgetType }) => {
  const { t: translate } = useTranslation();
  const formSchema = getWidgetFormSchema(widgetType);

  const property = _.get(formSchema, `properties.${dataPath}.title`);

  return (
    <div className={'text-color-red text-normal-wrap'}>
      {translate('widget:calculationError.noFormValueMessage', {
        property: getSafeTranslation(property)
      })}
    </div>
  );
};

NoFormValueError.propTypes = {
  dataPath: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired
};

export default NoFormValueError;
