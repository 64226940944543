import { createActionTypeArray } from '../../common/actionTypes';

export const ADD_PAYMENT_TYPE = 'paymentType/ADD_PAYMENT_TYPE';
export const UPDATE_PAYMENT_TYPE = 'paymentType/UPDATE_PAYMENT_TYPE';
export const REMOVE_PAYMENT_TYPE = 'paymentType/REMOVE_PAYMENT_TYPE';

export const [
  ADD_PAYMENT_TYPE_IN_PROGRESS,
  ADD_PAYMENT_TYPE_SUCCESS,
  ADD_PAYMENT_TYPE_FAILURE
] = createActionTypeArray(ADD_PAYMENT_TYPE);

export const [
  UPDATE_PAYMENT_TYPE_IN_PROGRESS,
  UPDATE_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_FAILURE
] = createActionTypeArray(UPDATE_PAYMENT_TYPE);

export const [
  REMOVE_PAYMENT_TYPE_IN_PROGRESS,
  REMOVE_PAYMENT_TYPE_SUCCESS,
  REMOVE_PAYMENT_TYPE_FAILURE
] = createActionTypeArray(REMOVE_PAYMENT_TYPE);
