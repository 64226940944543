import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import CustomWidgetGroup from '../inputGroup/CustomWidgetGroup';

const MeasurementWidgetObjectTemplate = (props) => (
  <CustomWidgetGroup
    {...props}
    measurement={_.getNonEmpty(props, 'schema.measurement', '')}
  />
);

MeasurementWidgetObjectTemplate.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  schema: PropTypes.shape().isRequired,
  disabled: PropTypes.bool
};

MeasurementWidgetObjectTemplate.defaultProps = {
  disabled: false
};

export default MeasurementWidgetObjectTemplate;
