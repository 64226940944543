import produce from 'immer';
import _ from 'lodash';

import { mapAndOrderCategories } from '../../../utils/mappers/calendar-mappers';
import { appendColorMapper } from '../../../utils/mappers/color-mappers';

import { GET_CATEGORIES_SUCCESS } from '../../calendar/category/categoryTypes';
import {
  GET_NEW_DEVICE_INFO_SUCCESS,
  POLL_BILLING_CATEGORY_LIST,
  POLL_BILLING_UNIT_PREMISES,
  POLL_BILLING_UNITS,
  POLL_CATEGORIES,
  POLL_DEVICES,
  POLL_DOCUMENTS,
  POLL_FLOWS,
  POLL_LOCATIONS,
  POLL_ORGANIZATION,
  POLL_PATIENT_SCHEMA,
  POLL_QUESTIONNAIRES,
  POLL_TAXES,
  POLL_WAITING_LIST_TODAYS_EVENTS,
  START_POLLING,
  STOP_POLLING,
  POLL_REGISTRY_PAYMENT_TYPES_SUCCESS,
  POLL_USERS_SUCCESS,
  POLL_VERSIONS_SUCCESS,
  POLL_REGISTRY_THERAPY_LIST_SUCCESS,
  POLL_REGISTRY_DRUG_LIST_SUCCESS,
  POLL_RESOURCES_SUCCESS,
  POLL_FORMS_SUCCESS,
  POLL_TAGS_SUCCESS,
  POLL_BILLING_ITEM_LIST_SUCCESS,
  POLL_BOOKING_TEMPLATES_SUCCESS
} from './cacheTypes';

import { LOGOUT } from '../../auth/authReducer';
import waitingListEncounterReducer from './encounterWaitingList/waitingListEncounterReducer';

import BillingUnitModel from '../../../metadata/model/billing/BillingUnitModel';

import patientSchemaDefaults from '../../../containers/patient/detail/patientSchemaDefaults';

const initialState = {
  isPolling: false,
  didPoll: false,
  versions: {
    didPoll: false,
    data: {}
  },
  organization: {
    didPoll: false,
    data: null
  },
  location: {
    didPoll: false,
    list: []
  },
  user: {
    didPoll: false,
    list: []
  },
  // TODO DOCUMENTS REWORK: remove unused document and questionnaire from initial state
  document: {
    didPoll: false,
    list: []
  },
  questionnaire: {
    didPoll: false,
    list: []
  },
  flow: {
    didPoll: false,
    list: []
  },
  todaysEvents: {
    didPoll: false,
    list: []
  },
  devices: {
    didPoll: false,
    list: []
  },
  category: {
    didPoll: false,
    list: []
  },
  resource: {
    didPoll: false,
    list: []
  },
  billingUnit: {
    didPoll: false,
    list: [],
    didPollPremises: false,
    premisesList: {}
  },
  billingItem: {
    didPoll: false,
    list: []
  },
  tax: {
    didPoll: false,
    list: []
  },
  billingCategory: {
    didPoll: false,
    list: []
  },
  drug: {
    didPoll: false,
    list: []
  },
  therapy: {
    didPoll: false,
    list: []
  },
  patientSchema: {
    didPoll: false,
    data: {}
  },
  paymentType: {
    didPoll: false,
    list: []
  },
  form: {
    didPoll: false,
    list: []
  },
  tag: {
    didPoll: false,
    list: []
  },
  bookingTemplate: {
    didPoll: false,
    list: []
  }
};

const cacheReducer = (state, action) =>
  produce(state, (draft) => {
    const { type, data, ...payload } = action;

    switch (type) {
      case START_POLLING:
        draft.isPolling = true;
        break;
      case POLL_VERSIONS_SUCCESS:
        draft.versions.didPoll = true;
        draft.versions.data = data;
        break;
      case POLL_ORGANIZATION:
        draft.organization.didPoll = true;
        draft.organization.data = data;
        break;
      case POLL_USERS_SUCCESS:
        draft.user.didPoll = true;
        _.map(data, appendColorMapper);
        draft.user.list = data;
        break;
      case POLL_LOCATIONS:
        draft.location.didPoll = true;
        draft.location.list = data;
        break;
      case POLL_DOCUMENTS:
        draft.document.didPoll = true;
        draft.document.list = data;
        break;
      case POLL_QUESTIONNAIRES:
        draft.questionnaire.didPoll = true;
        draft.questionnaire.list = data;
        break;
      case POLL_FLOWS:
        draft.flow.didPoll = true;
        draft.flow.list = data;
        break;
      case POLL_WAITING_LIST_TODAYS_EVENTS:
        draft.todaysEvents.didPoll = true;

        /**
         * If the event has no patient then there is no point of showing it on
         * the upcoming list
         * @type {string[]}
         */
        draft.todaysEvents.list = _.filter(
          data,
          (event) => !_.isEmpty(event.patient)
        );
        break;
      case POLL_BILLING_UNIT_PREMISES:
        const premiseList = {};

        _.forEach(data, (premise) => {
          const billingUnitId = _.get(premise, 'config.params.billingUnitId');
          const billingUnitPremises = _.get(premise, 'data', []);

          _.set(premiseList, billingUnitId, billingUnitPremises);
        });

        draft.billingUnit.premisesList = premiseList;
        draft.billingUnit.didPollPremises = true;
        break;
      case POLL_DEVICES:
        draft.devices.didPoll = true;
        draft.devices.list = data;
        break;
      case POLL_BILLING_UNITS:
        const billingUnits = _.nestedListAssign(data, BillingUnitModel);

        draft.billingUnit.didPoll = true;
        draft.billingUnit.list = billingUnits;
        break;
      case POLL_BILLING_ITEM_LIST_SUCCESS:
        draft.billingItem.didPoll = true;

        const filterDisabledItems = _.filter(data, {
          enabled: true
        });

        draft.billingItem.list = filterDisabledItems;
        break;
      case POLL_TAXES:
        draft.tax.didPoll = true;
        draft.tax.list = data;
        break;
      case POLL_BILLING_CATEGORY_LIST:
        draft.billingCategory.didPoll = true;
        draft.billingCategory.list = mapAndOrderCategories(data);
        break;
      case POLL_PATIENT_SCHEMA:
        const filteredData = _.pickBy(data, _.identity);
        const existingPatientSchema = _.getNonEmpty(
          state,
          'patientSchema.data',
          {}
        );
        const patientSchema = { ...patientSchemaDefaults, ...filteredData };

        if (!_.isEqual(patientSchema, existingPatientSchema)) {
          draft.patientSchema.data = patientSchema;
        }

        draft.patientSchema.didPoll = true;
        break;
      case POLL_REGISTRY_DRUG_LIST_SUCCESS:
        draft.drug.didPoll = true;
        draft.drug.list = data;
        break;
      case POLL_REGISTRY_THERAPY_LIST_SUCCESS:
        draft.therapy.didPoll = true;
        draft.therapy.list = data;
        break;
      case GET_NEW_DEVICE_INFO_SUCCESS:
        const deviceData = _.get(payload, 'response.data', '');
        const deviceId = _.get(deviceData, 'id', '');
        const newDeviceList = _.cloneDeep(draft.devices.list);
        const deviceIndex = _.findIndex(newDeviceList, { id: deviceId });

        if (deviceIndex > -1) {
          draft.devices.list[deviceIndex] = deviceData;
        } else {
          draft.devices.list = [...draft.devices.list, deviceData];
        }
        break;
      case POLL_CATEGORIES:
        const categories = mapAndOrderCategories(data);

        draft.category.didPoll = true;
        draft.category.list = categories;
        break;
      case POLL_RESOURCES_SUCCESS:
        const resources = data;

        draft.resource.didPoll = true;
        draft.resource.list = resources;
        break;
      case GET_CATEGORIES_SUCCESS:
        const newCategories = _.get(payload, 'response.data', []);

        draft.category.didPoll = true;
        draft.category.list = mapAndOrderCategories(newCategories);
        break;
      case POLL_REGISTRY_PAYMENT_TYPES_SUCCESS:
        draft.paymentType.didPoll = true;
        draft.paymentType.list = data;
        break;
      case POLL_FORMS_SUCCESS:
        draft.form.didPoll = true;
        draft.form.list = data;
        break;
      case POLL_TAGS_SUCCESS:
        draft.tag.didPoll = true;
        draft.tag.list = data;
        break;
      case POLL_BOOKING_TEMPLATES_SUCCESS:
        draft.bookingTemplate.didPoll = true;
        draft.bookingTemplate.list = data;
        break;
      case STOP_POLLING:
      case LOGOUT:
        // TODO: Figure out how to handle anonymous data polling better during state reset
        const versions = _.getNonEmpty(draft, 'versions');

        return { ...initialState, versions };
      default:
        return state;
    }

    const appInitRequiredItems = [
      'organization',
      'location',
      'user',
      'document',
      'questionnaire',
      'flow',
      'versions'
    ];

    let didPoll = true;

    _.forIn(draft, (value, key) => {
      if (
        _.isPlainObject(value) &&
        didPoll &&
        _.includes(appInitRequiredItems, key)
      ) {
        didPoll = _.get(value, 'didPoll', false);
      }
    });
    draft.didPoll = didPoll;
  });

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => ({
  ...cacheReducer(state, action),
  waitingList: waitingListEncounterReducer(state.waitingList, action)
});
