/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Container } from 'reactstrap';

import PropTypes from 'prop-types';

const ContainerWrapper = ({ children, fluid, className, id }) => (
  <Container fluid={fluid} className={className} id={id}>
    {children}
  </Container>
);

ContainerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

ContainerWrapper.defaultProps = {
  fluid: undefined,
  className: undefined,
  id: undefined
};

export default ContainerWrapper;
