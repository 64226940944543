import produce from 'immer';
import _ from 'lodash';

import { ORDER_DESCENDING } from '../../../utils/constants/tableConstants';
import {
  mapFileDetail,
  isDocument,
  isQuestionnaire
} from '../../../utils/mappers/files-mapper';
import moshiConfig from '../../../utils/moshiConfig';

import {
  GET_PATIENT_FILES_IN_PROGRESS,
  GET_PATIENT_FILES_SUCCESS,
  GET_PATIENT_FILES_FAILURE,
  UPLOAD_FILE_STATUS,
  SET_PATIENT_FILES_ORDER,
  SET_PATIENT_FILES_PAGINATION,
  DOWNLOAD_FILE_STATUS,
  GET_ALL_PATIENTS_FILES_SUCCESS,
  GET_ALL_PATIENTS_FILES_IN_PROGRESS,
  GET_ALL_PATIENTS_FILES_FAILURE,
  GET_FILE_DETAIL_SUCCESS,
  GET_FILE_DETAIL_FAILURE,
  CLEAR_FILE_DETAIL,
  CLEAR_FILE_LIST,
  CLEAR_PAGINATION
} from './fileTypes';

import { LOGOUT } from '../../auth/authReducer';

export const FILES_SORT_BY_CREATED_AT = 'createdAt';

export const initialState = {
  filter: {
    sortBy: FILES_SORT_BY_CREATED_AT,
    order: ORDER_DESCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: _.get(moshiConfig, 'list.pagination.limit', 20),
    page: 1
  },
  fileList: [],
  fileDetail: {},
  documentList: [],
  questionnaireList: [],
  loadingList: false,
  loadingFileList: false,
  patientDicomFiles: [],
  uploadPercentage: 0,
  uploadPercentageAvailable: true
};

export const defaultProgressEvent = {
  lengthComputable: true,
  loaded: 0,
  total: 1
};

// eslint-disable-next-line default-param-last
const fileReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_PATIENT_FILES_IN_PROGRESS:
        draft.documentList = [];
        draft.questionnaireList = [];
        draft.loadingList = true;
        break;
      case GET_PATIENT_FILES_SUCCESS:
        const patientFiles = _.get(payload, 'response.data', []);

        draft.fileList = patientFiles;
        draft.documentList = _.filter(patientFiles, isDocument);
        draft.questionnaireList = _.filter(patientFiles, isQuestionnaire);
        draft.loadingList = false;
        break;
      case GET_PATIENT_FILES_FAILURE:
        draft.fileList = [];
        draft.documentList = [];
        draft.questionnaireList = [];
        draft.loadingList = false;
        break;
      case UPLOAD_FILE_STATUS:
        const { lengthComputable, loaded, total } = _.get(
          payload,
          'progressEvent',
          defaultProgressEvent
        );

        const decimalPercent = loaded / total;

        draft.uploadPercentageAvailable = lengthComputable;
        draft.uploadPercentage = _.floor(100 * decimalPercent);
        break;
      case SET_PATIENT_FILES_ORDER:
        draft.filter.sortBy = _.get(
          payload,
          'filter.sortBy',
          initialState.filter.sortBy
        );
        draft.filter.order = _.get(
          payload,
          'filter.order',
          initialState.filter.order
        );
        break;
      case SET_PATIENT_FILES_PAGINATION:
        draft.pagination.page = _.get(
          payload,
          'paginationValue',
          initialState.pagination.page
        );
        break;
      case DOWNLOAD_FILE_STATUS:
        break;
      case GET_ALL_PATIENTS_FILES_SUCCESS:
        draft.fileList = _.get(payload, 'response.data', []);
        draft.loadingFileList = false;
        const maxItemResultsRaw = _.get(
          payload,
          'response.headers.x-total-count',
          draft.pagination.limit
        );
        const itemMaxResults = _.parseInt(maxItemResultsRaw);

        draft.pagination.resultCount = itemMaxResults;
        draft.pagination.pageCount = _.ceil(
          itemMaxResults / draft.pagination.limit
        );
        break;
      case GET_ALL_PATIENTS_FILES_IN_PROGRESS:
        draft.loadingFileList = true;
        break;
      case GET_ALL_PATIENTS_FILES_FAILURE:
        draft.fileList = [];
        draft.loadingFileList = false;
        break;
      case GET_FILE_DETAIL_SUCCESS:
        const fileHeader = _.get(payload, 'response.headers');

        draft.fileDetail = mapFileDetail(fileHeader);
        break;
      case CLEAR_FILE_DETAIL:
      case GET_FILE_DETAIL_FAILURE:
        draft.fileDetail = {};
        break;
      case CLEAR_FILE_LIST:
        draft.fileList = [];
        draft.pagination = initialState.pagination;
        break;

      case CLEAR_PAGINATION:
        draft.pagination.page = 1;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default fileReducer;
