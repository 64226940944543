import produce from 'immer';
import _ from 'lodash';

import {
  GET_PATIENT_FOR_CACHE_LIST_SUCCESS,
  HIDE_PATIENT_MERGE_TILE
} from './patientTypes';

import { LOGOUT } from '../auth/authReducer';
import { initialState } from './patientReducer';

const cachedPatientReducer = (state, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_PATIENT_FOR_CACHE_LIST_SUCCESS:
        const patient = _.getNonEmpty(payload, 'response.data', null);

        if (!_.isEmptySafe(patient)) {
          draft.patientList[patient.id] = patient;
        }
        break;
      case HIDE_PATIENT_MERGE_TILE:
        const mergeTilePatientId = _.getNonEmpty(payload, 'patientId');

        if (!_.isEmptySafe(mergeTilePatientId)) {
          draft.hiddenMergeTiles.push(mergeTilePatientId);
        }
        break;
      case LOGOUT:
        return initialState.cachedPatient;
      default:
    }
  });

export default cachedPatientReducer;
