import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import {
  showMissingOrganizationIdError,
  showAlertSuccess
} from '../../utils/alert';
import {
  getFormTemplateUrl,
  getOrganizationFileIdUrl
} from '../../utils/api/apiUrlUtils';
import { MOSHI_FILE_TYPE_DOCUMENT } from '../../utils/constants/fileConstants';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import { getJsonSchemaFormData } from '../../utils/form';

import { getPatientFileDocuments } from '../core/file/fileActions';
import {
  addOrganizationFile,
  updateOrganizationFile,
  updateOrganizationPdfGenerationSettings
} from '../organization/organizationActions';
import { clearDocumentSubmittionData } from './submission/documentSubmissionActions';

import {
  GET_DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  GET_DOCUMENT,
  UPDATE_DOCUMENT,
  CLEAR_DOCUMENT_DETAIL,
  UPDATE_PATIENT_DOCUMENT,
  DELETE_PATIENT_DOCUMENT
} from './documentTypes';
import {
  GET_OUTBOUND_DOCUMENT_FOOTER,
  GET_OUTBOUND_DOCUMENT_HEADER
} from './outbound/outboundDocumentTypes';

import {
  selectDocumentSettingsFooterId,
  selectDocumentSettingsHeaderId
} from './documentSelectors';

// TODO DOCUMENTS REWORK: Remove the /redux/document folder after document rework, now using /redux/form
export const getDocumentPayload = (documentData) => {
  const documentPayload = _.cloneDeep(documentData) || {};
  const formBuilderSchema = _.get(documentData, 'formBuilderSchema', {});
  const { schema: jsonSchema } = getJsonSchemaFormData(formBuilderSchema);

  documentPayload.name = _.get(formBuilderSchema, 'title', '/');
  documentPayload.formBuilderSchema = JSON.stringify({
    ...formBuilderSchema,

    fieldCounter: _.get(formBuilderSchema, 'fieldCounter', 2)
  });
  documentPayload.jsonSchema = JSON.stringify(jsonSchema);

  return documentPayload;
};

export const getDocuments = () => (dispatch, getState) => {
  const url = getFormTemplateUrl(getState);

  return dispatch({
    type: GET_DOCUMENTS,
    apiCall: axios.get(url, {
      params: {
        type: MOSHI_FILE_TYPE_DOCUMENT,
        limit: REQUEST_PAGINATION_MAX_LIMIT
      }
    })
  });
};

export const createDocument = (documentData) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const documentPayload = getDocumentPayload(documentData);

  return dispatch({
    type: ADD_DOCUMENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/documents`,
      documentPayload
    ),
    onSuccess: () => showAlertSuccess('document:alert.documentCreated')
  });
};

export const updateDocument = (documentId, documentData) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const documentPayload = getDocumentPayload(documentData);

  return dispatch({
    type: UPDATE_DOCUMENT,
    apiCall: axios.put(
      `/organizations/${organizationId}/documents/${documentId}`,
      documentPayload
    ),
    onSuccess: () => dispatch(getDocuments())
  });
};

export const deleteDocument = (documentId) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: DELETE_DOCUMENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/documents/${documentId}`
    ),
    onSuccess: () => {
      showAlertSuccess('document:alert.documentRemoved');
      dispatch(getDocuments());
    }
  });
};

export const getDocument = (documentId, version) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const versionEndpoint = version ? `/${version}` : '';

  return dispatch({
    type: GET_DOCUMENT,
    apiCall: axios.get(
      `/organizations/${organizationId}/documents/${documentId}${versionEndpoint}`
    )
  });
};

export const clearDocumentDetail = () => (dispatch) => {
  dispatch(clearDocumentSubmittionData());
  dispatch({
    type: CLEAR_DOCUMENT_DETAIL
  });
};

export const updatePatientDocument = (
  patientId,
  documentData,
  submissionId
) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const documentPayload = {
    data: JSON.stringify(documentData.formData)
  };

  dispatch({
    type: UPDATE_PATIENT_DOCUMENT,
    apiCall: axios.put(
      `/organizations/${organizationId}/patients/${patientId}/questionnaires/${submissionId}`,
      documentPayload
    ),
    onSuccess: () => {
      dispatch(getPatientFileDocuments(patientId));
    }
  });
};

export const deletePatientDocument = (
  patientId,
  submissionId,
  refetchPatientDocuments = true
) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: DELETE_PATIENT_DOCUMENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/patients/${patientId}/documents/${submissionId}`
    ),
    onSuccess: () => {
      showAlertSuccess('document:alert.documentRemoved');
      if (refetchPatientDocuments) {
        return dispatch(getPatientFileDocuments(patientId));
      }
    }
  });
};

export const getDocumentSettingsHeaderData = () => (dispatch, getState) => {
  const documentHeaderId = selectDocumentSettingsHeaderId(getState());

  if (!_.isEmptySafe(documentHeaderId)) {
    return dispatch({
      type: GET_OUTBOUND_DOCUMENT_HEADER,
      apiCall: axios.get(getOrganizationFileIdUrl(getState, documentHeaderId))
    });
  }
};

export const getDocumentSettingsFooterData = () => (dispatch, getState) => {
  const documentFooterId = selectDocumentSettingsFooterId(getState());

  if (!_.isEmptySafe(documentFooterId)) {
    return dispatch({
      type: GET_OUTBOUND_DOCUMENT_FOOTER,
      apiCall: axios.get(getOrganizationFileIdUrl(getState, documentFooterId))
    });
  }
};

export const getDocumentSettingsData = () => (dispatch) =>
  Promise.all([
    dispatch(getDocumentSettingsHeaderData()),
    dispatch(getDocumentSettingsFooterData())
  ]);

export const saveDocumentSettingsAction = ({
  header: headerFile = '',
  footer: footerFile = ''
}) => (dispatch, getState) => {
  const headerId = selectDocumentSettingsHeaderId(getState());
  const footerId = selectDocumentSettingsFooterId(getState());

  const handleSuccess = () => {
    showAlertSuccess('settings:documents.alert.saved');
    dispatch(getDocumentSettingsData());
  };

  return Promise.all([
    dispatch(uploadDocumentHeader(headerFile)),
    dispatch(uploadDocumentFooter(footerFile))
  ]).then((response) => {
    const newHeaderId = _.getNonEmpty(response, '[0].id', '');
    const newFooterId = _.getNonEmpty(response, '[1].id', '');

    if (headerId === newHeaderId && footerId === newFooterId) {
      handleSuccess();

      return;
    }

    return dispatch(
      updateOrganizationPdfGenerationSettings(
        {
          headerId: newHeaderId,
          footerId: newFooterId
        },
        handleSuccess
      )
    );
  });
};

export const uploadDocumentHeader = (headerFile) => (dispatch, getState) => {
  const headerId = selectDocumentSettingsHeaderId(getState());

  if (_.isEmptySafe(headerId)) {
    return dispatch(
      addOrganizationFile(headerFile, {
        type: 'outboundDocumentHeader'
      })
    );
  } else {
    return dispatch(
      updateOrganizationFile(headerId, headerFile, {
        type: 'outboundDocumentHeader'
      })
    );
  }
};

export const uploadDocumentFooter = (footerFile) => (dispatch, getState) => {
  const footerId = selectDocumentSettingsFooterId(getState());

  if (_.isEmptySafe(footerId)) {
    return dispatch(
      addOrganizationFile(footerFile, {
        type: 'outboundDocumentFooter'
      })
    );
  } else {
    return dispatch(
      updateOrganizationFile(footerId, footerFile, {
        type: 'outboundDocumentFooter'
      })
    );
  }
};
