import {
  NOTIFICATION_TYPE_INBOX_SMS,
  NOTIFICATION_TYPE_CALENDAR,
  NOTIFICATION_TYPE_PATIENTS_EXPORT,
  NOTIFICATION_TYPE_SMS
} from '../../utils/constants/core/notificationConstants';

import { formatting } from './date';

export default {
  [NOTIFICATION_TYPE_CALENDAR]: {
    title: {
      add:
        'Added event for {{ data.patient.firstName }} {{ data.patient.lastName }}',
      confirmed:
        'Confirmed event for {{ data.patient.firstName }} {{ data.patient.lastName }}',
      update:
        'Updated event for {{ data.patient.firstName }} {{ data.patient.lastName }}',
      cancel:
        'Canceled event for {{ data.patient.firstName }} {{ data.patient.lastName }}',
      delete:
        'Removed event for {{ data.patient.firstName }} {{ data.patient.lastName }}'
    },
    subtitle: `{{ data.from, ${formatting.shortDateWithTime} }}`
  },
  [NOTIFICATION_TYPE_SMS]: {
    title: {
      received: 'SMS received'
    },
    subtitle: `{{ data.from, ${formatting.shortDateWithTime} }}`
  },
  [NOTIFICATION_TYPE_PATIENTS_EXPORT]: {
    title: {
      add: 'Patients export ready',
      failed: 'Patients export failed'
    },
    subtitle: 'Click to download'
  },
  [NOTIFICATION_TYPE_INBOX_SMS]: {
    title: {
      received: 'SMS received'
    }
  }
};
