import { createActionTypeArray } from '../../common/actionTypes';

export const POLL_FORM_TEMPLATE_LIST = 'documents/POLL_FORM_TEMPLATE_LIST';

export const START_FORM_TEMPLATE_LIST_POLLING =
  'cache/START_FORM_TEMPLATE_LIST_POLLING';
export const STOP_FORM_TEMPLATE_LIST_POLLING =
  'cache/STOP_FORM_TEMPLATE_LIST_POLLING';

export const GET_FORM_TEMPLATE_DETAIL = 'documents/GET_FORM_TEMPLATE_DETAIL';
export const ADD_FORM_TEMPLATE = 'documents/ADD_FORM_TEMPLATE';
export const UPDATE_FORM_TEMPLATE = 'documents/UPDATE_FORM_TEMPLATE';
export const REMOVE_FORM_TEMPLATE = 'documents/REMOVE_FORM_TEMPLATE';
export const CLEAR_FORM_TEMPLATE_DETAIL =
  'documents/CLEAR_FORM_TEMPLATE_DETAIL';

export const INCREMENT_FORM_FIELD_COUNT =
  'documents/INCREMENT_FORM_FIELD_COUNT';
export const RESET_FORM_FIELD_COUNT = 'documents/RESET_FORM_FIELD_COUNT';

export const [
  POLL_FORM_TEMPLATE_LIST_IN_PROGRESS,
  POLL_FORM_TEMPLATE_LIST_SUCCESS,
  POLL_FORM_TEMPLATE_LIST_FAILURE
] = createActionTypeArray(POLL_FORM_TEMPLATE_LIST);

export const [
  GET_FORM_TEMPLATE_DETAIL_IN_PROGRESS,
  GET_FORM_TEMPLATE_DETAIL_SUCCESS,
  GET_FORM_TEMPLATE_DETAIL_FAILURE
] = createActionTypeArray(GET_FORM_TEMPLATE_DETAIL);

export const [
  ADD_FORM_TEMPLATE_IN_PROGRESS,
  ADD_FORM_TEMPLATE_SUCCESS,
  ADD_FORM_TEMPLATE_FAILURE
] = createActionTypeArray(ADD_FORM_TEMPLATE);

export const [
  UPDATE_FORM_TEMPLATE_IN_PROGRESS,
  UPDATE_FORM_TEMPLATE_SUCCESS,
  UPDATE_FORM_TEMPLATE_FAILURE
] = createActionTypeArray(UPDATE_FORM_TEMPLATE);

export const [
  REMOVE_FORM_TEMPLATE_IN_PROGRESS,
  REMOVE_FORM_TEMPLATE_SUCCESS,
  REMOVE_FORM_TEMPLATE_FAILURE
] = createActionTypeArray(REMOVE_FORM_TEMPLATE);
