/* eslint-disable no-restricted-imports */
import FullCalendarLocal from '@fullcalendar/react';
import dayGridPluginLocal from '@fullcalendar/daygrid';
import timeGridPluginLocal from '@fullcalendar/timegrid';
import scrollgridLocal from '@fullcalendar/scrollgrid';
import interactionPluginLocal, { Draggable } from '@fullcalendar/interaction';
import slLocaleLocal from '@fullcalendar/core/locales/sl';
import resourceTimelinePluginLocal from '@fullcalendar/resource-timeline';
import resourceTimeGridPluginLocal from '@fullcalendar/resource-timegrid';

export const FullCalendar = FullCalendarLocal;
export const dayGridPlugin = dayGridPluginLocal;
export const timeGridPlugin = timeGridPluginLocal;
export const interactionPlugin = interactionPluginLocal;
export const FullCalendarDraggable = Draggable;
export const slLocale = slLocaleLocal;
export const resourceTimeGridPlugin = resourceTimeGridPluginLocal;
export const scrollgrid = scrollgridLocal;
export const resourceTimelinePlugin = resourceTimelinePluginLocal;
