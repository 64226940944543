/* eslint-disable max-len,max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from '../../../../layout/Visible';

export const HUMANOID_LEFT_FOOT_ID = 'left-foot';
const GROUP = `zoomed-${HUMANOID_LEFT_FOOT_ID}-interactive`;

const ANKLE_LEFT = `${HUMANOID_LEFT_FOOT_ID}-ankle`;
const INSTEP_LEFT = `${HUMANOID_LEFT_FOOT_ID}-instep`;

const THUMB_TIP = `${HUMANOID_LEFT_FOOT_ID}-thumb-tip`;
const INDEX_TIP = `${HUMANOID_LEFT_FOOT_ID}-index-tip`;
const MIDDLE_TIP = `${HUMANOID_LEFT_FOOT_ID}-middle-tip`;
const RING_TIP = `${HUMANOID_LEFT_FOOT_ID}-ring-tip`;
const PINKIE_TIP = `${HUMANOID_LEFT_FOOT_ID}-pinkie-tip`;

const THUMB_LOWER = `${HUMANOID_LEFT_FOOT_ID}-thumb-low`;
const INDEX_LOWER = `${HUMANOID_LEFT_FOOT_ID}-index-low`;
const MIDDLE_LOWER = `${HUMANOID_LEFT_FOOT_ID}-middle-low`;
const RING_LOWER = `${HUMANOID_LEFT_FOOT_ID}-ring-low`;
const PINKIE_LOWER = `${HUMANOID_LEFT_FOOT_ID}-pinkie-low`;

const LeftFootInteractive = ({
  getColor,
  onChange,
  initialSelectedParts,
  anklesDisabled,
  instepDisabled,
  lowDisabled
}) => {
  const [checkedList, setCheckedList] = useState(initialSelectedParts);

  const isChecked = useCallback((partId) => _.includes(checkedList, partId), [
    checkedList
  ]);

  useEffect(() => {
    onChange(HUMANOID_LEFT_FOOT_ID, checkedList);
  }, [checkedList, onChange]);

  const handleClick = useCallback(
    (partId) => {
      const result = _.pushOrRemoveIntoArray(checkedList, partId);

      setCheckedList(result);
    },
    [checkedList]
  );

  return (
    <g id={GROUP}>
      <Visible visible={!anklesDisabled}>
        <g
          id={ANKLE_LEFT}
          onClick={() => handleClick(ANKLE_LEFT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M339 584C339 578.477 343.477 574 349 574C354.523 574 359 578.477 359 584C359 589.523 354.523 594 349 594C343.477 594 339 589.523 339 584Z'
            }
            fill={'white'}
          />
          {isChecked(ANKLE_LEFT) && (
            <circle cx={'349'} cy={'584'} r={'5'} fill={getColor(ANKLE_LEFT)} />
          )}
          <path
            d={
              'M349 592C344.582 592 341 588.418 341 584H337C337 590.627 342.373 596 349 596V592ZM357 584C357 588.418 353.418 592 349 592V596C355.627 596 361 590.627 361 584H357ZM349 576C353.418 576 357 579.582 357 584H361C361 577.373 355.627 572 349 572V576ZM349 572C342.373 572 337 577.373 337 584H341C341 579.582 344.582 576 349 576V572Z'
            }
            fill={getColor(ANKLE_LEFT)}
          />
        </g>
      </Visible>

      <Visible visible={!instepDisabled}>
        <g
          id={INSTEP_LEFT}
          onClick={() => handleClick(INSTEP_LEFT)}
          className={'clickable-text'}
        >
          <path
            d={
              'M346 658C346 652.477 350.477 648 356 648V648C361.523 648 366 652.477 366 658V658C366 663.523 361.523 668 356 668V668C350.477 668 346 663.523 346 658V658Z'
            }
            fill={'white'}
          />
          {isChecked(INSTEP_LEFT) && (
            <circle
              cx={'356'}
              cy={'658'}
              r={'5'}
              fill={getColor(INSTEP_LEFT)}
            />
          )}
          <path
            d={
              'M356 666C351.582 666 348 662.418 348 658H344C344 664.627 349.373 670 356 670V666ZM364 658C364 662.418 360.418 666 356 666V670C362.627 670 368 664.627 368 658H364ZM356 650C360.418 650 364 653.582 364 658H368C368 651.373 362.627 646 356 646V650ZM356 646C349.373 646 344 651.373 344 658H348C348 653.582 351.582 650 356 650V646Z'
            }
            fill={getColor(INSTEP_LEFT)}
          />
        </g>
      </Visible>

      <g
        id={THUMB_TIP}
        onClick={() => handleClick(THUMB_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M312 779C312 773.477 316.477 769 322 769V769C327.523 769 332 773.477 332 779V779C332 784.523 327.523 789 322 789V789C316.477 789 312 784.523 312 779V779Z'
          }
          fill={'white'}
        />
        {isChecked(THUMB_TIP) && (
          <circle cx={'322'} cy={'779'} r={'5'} fill={getColor(THUMB_TIP)} />
        )}
        <path
          d={
            'M322 787C317.582 787 314 783.418 314 779H310C310 785.627 315.373 791 322 791V787ZM330 779C330 783.418 326.418 787 322 787V791C328.627 791 334 785.627 334 779H330ZM322 771C326.418 771 330 774.582 330 779H334C334 772.373 328.627 767 322 767V771ZM322 767C315.373 767 310 772.373 310 779H314C314 774.582 317.582 771 322 771V767Z'
          }
          fill={getColor(THUMB_TIP)}
        />
      </g>
      <g
        id={INDEX_TIP}
        onClick={() => handleClick(INDEX_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M342 780C342 774.477 346.477 770 352 770V770C357.523 770 362 774.477 362 780V780C362 785.523 357.523 790 352 790V790C346.477 790 342 785.523 342 780V780Z'
          }
          fill={'white'}
        />
        {isChecked(INDEX_TIP) && (
          <circle cx={'352'} cy={'780'} r={'5'} fill={getColor(INDEX_TIP)} />
        )}
        <path
          d={
            'M352 788C347.582 788 344 784.418 344 780H340C340 786.627 345.373 792 352 792V788ZM360 780C360 784.418 356.418 788 352 788V792C358.627 792 364 786.627 364 780H360ZM352 772C356.418 772 360 775.582 360 780H364C364 773.373 358.627 768 352 768V772ZM352 768C345.373 768 340 773.373 340 780H344C344 775.582 347.582 772 352 772V768Z'
          }
          fill={getColor(INDEX_TIP)}
        />
      </g>
      <g
        id={MIDDLE_TIP}
        onClick={() => handleClick(MIDDLE_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M360 770C360 764.477 364.477 760 370 760V760C375.523 760 380 764.477 380 770V770C380 775.523 375.523 780 370 780V780C364.477 780 360 775.523 360 770V770Z'
          }
          fill={'white'}
        />
        {isChecked(MIDDLE_TIP) && (
          <circle cx={'370'} cy={'770'} r={'5'} fill={getColor(MIDDLE_TIP)} />
        )}
        <path
          d={
            'M370 778C365.582 778 362 774.418 362 770H358C358 776.627 363.373 782 370 782V778ZM378 770C378 774.418 374.418 778 370 778V782C376.627 782 382 776.627 382 770H378ZM370 762C374.418 762 378 765.582 378 770H382C382 763.373 376.627 758 370 758V762ZM370 758C363.373 758 358 763.373 358 770H362C362 765.582 365.582 762 370 762V758Z'
          }
          fill={getColor(MIDDLE_TIP)}
        />
      </g>
      <g
        id={RING_TIP}
        onClick={() => handleClick(RING_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M378 758C378 752.477 382.477 748 388 748V748C393.523 748 398 752.477 398 758V758C398 763.523 393.523 768 388 768V768C382.477 768 378 763.523 378 758V758Z'
          }
          fill={'white'}
        />
        {isChecked(RING_TIP) && (
          <circle cx={'388'} cy={'758'} r={'5'} fill={getColor(RING_TIP)} />
        )}
        <path
          d={
            'M388 766C383.582 766 380 762.418 380 758H376C376 764.627 381.373 770 388 770V766ZM396 758C396 762.418 392.418 766 388 766V770C394.627 770 400 764.627 400 758H396ZM388 750C392.418 750 396 753.582 396 758H400C400 751.373 394.627 746 388 746V750ZM388 746C381.373 746 376 751.373 376 758H380C380 753.582 383.582 750 388 750V746Z'
          }
          fill={getColor(RING_TIP)}
        />
      </g>
      <g
        id={PINKIE_TIP}
        onClick={() => handleClick(PINKIE_TIP)}
        className={'clickable-text'}
      >
        <path
          d={
            'M397 739C397 733.477 401.477 729 407 729V729C412.523 729 417 733.477 417 739V739C417 744.523 412.523 749 407 749V749C401.477 749 397 744.523 397 739V739Z'
          }
          fill={'white'}
        />
        {isChecked(PINKIE_TIP) && (
          <circle cx={'407'} cy={'739'} r={'5'} fill={getColor(PINKIE_TIP)} />
        )}
        <path
          d={
            'M407 747C402.582 747 399 743.418 399 739H395C395 745.627 400.373 751 407 751V747ZM415 739C415 743.418 411.418 747 407 747V751C413.627 751 419 745.627 419 739H415ZM407 731C411.418 731 415 734.582 415 739H419C419 732.373 413.627 727 407 727V731ZM407 727C400.373 727 395 732.373 395 739H399C399 734.582 402.582 731 407 731V727Z'
          }
          fill={getColor(PINKIE_TIP)}
        />
      </g>

      <Visible visible={!lowDisabled}>
        <g
          id={THUMB_LOWER}
          onClick={() => handleClick(THUMB_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M313 752C313 746.477 317.477 742 323 742V742C328.523 742 333 746.477 333 752V752C333 757.523 328.523 762 323 762V762C317.477 762 313 757.523 313 752V752Z'
            }
            fill={'white'}
          />
          {isChecked(THUMB_LOWER) && (
            <circle
              cx={'323'}
              cy={'752'}
              r={'5'}
              fill={getColor(THUMB_LOWER)}
            />
          )}
          <path
            d={
              'M323 760C318.582 760 315 756.418 315 752H311C311 758.627 316.373 764 323 764V760ZM331 752C331 756.418 327.418 760 323 760V764C329.627 764 335 758.627 335 752H331ZM323 744C327.418 744 331 747.582 331 752H335C335 745.373 329.627 740 323 740V744ZM323 740C316.373 740 311 745.373 311 752H315C315 747.582 318.582 744 323 744V740Z'
            }
            fill={getColor(THUMB_LOWER)}
          />
        </g>
        <g
          id={INDEX_LOWER}
          onClick={() => handleClick(INDEX_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M341 750C341 744.477 345.477 740 351 740V740C356.523 740 361 744.477 361 750V750C361 755.523 356.523 760 351 760V760C345.477 760 341 755.523 341 750V750Z'
            }
            fill={'white'}
          />
          {isChecked(INDEX_LOWER) && (
            <circle
              cx={'351'}
              cy={'750'}
              r={'5'}
              fill={getColor(INDEX_LOWER)}
            />
          )}
          <path
            d={
              'M351 758C346.582 758 343 754.418 343 750H339C339 756.627 344.373 762 351 762V758ZM359 750C359 754.418 355.418 758 351 758V762C357.627 762 363 756.627 363 750H359ZM351 742C355.418 742 359 745.582 359 750H363C363 743.373 357.627 738 351 738V742ZM351 738C344.373 738 339 743.373 339 750H343C343 745.582 346.582 742 351 742V738Z'
            }
            fill={getColor(INDEX_LOWER)}
          />
        </g>
        <g
          id={MIDDLE_LOWER}
          onClick={() => handleClick(MIDDLE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M360 742C360 736.477 364.477 732 370 732V732C375.523 732 380 736.477 380 742V742C380 747.523 375.523 752 370 752V752C364.477 752 360 747.523 360 742V742Z'
            }
            fill={'white'}
          />
          {isChecked(MIDDLE_LOWER) && (
            <circle
              cx={'370'}
              cy={'742'}
              r={'5'}
              fill={getColor(MIDDLE_LOWER)}
            />
          )}
          <path
            d={
              'M370 750C365.582 750 362 746.418 362 742H358C358 748.627 363.373 754 370 754V750ZM378 742C378 746.418 374.418 750 370 750V754C376.627 754 382 748.627 382 742H378ZM370 734C374.418 734 378 737.582 378 742H382C382 735.373 376.627 730 370 730V734ZM370 730C363.373 730 358 735.373 358 742H362C362 737.582 365.582 734 370 734V730Z'
            }
            fill={getColor(MIDDLE_LOWER)}
          />
        </g>
        <g
          id={RING_LOWER}
          onClick={() => handleClick(RING_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M378 732C378 726.477 382.477 722 388 722V722C393.523 722 398 726.477 398 732V732C398 737.523 393.523 742 388 742V742C382.477 742 378 737.523 378 732V732Z'
            }
            fill={'white'}
          />
          {isChecked(RING_LOWER) && (
            <circle cx={'388'} cy={'732'} r={'5'} fill={getColor(RING_LOWER)} />
          )}
          <path
            d={
              'M388 740C383.582 740 380 736.418 380 732H376C376 738.627 381.373 744 388 744V740ZM396 732C396 736.418 392.418 740 388 740V744C394.627 744 400 738.627 400 732H396ZM388 724C392.418 724 396 727.582 396 732H400C400 725.373 394.627 720 388 720V724ZM388 720C381.373 720 376 725.373 376 732H380C380 727.582 383.582 724 388 724V720Z'
            }
            fill={getColor(RING_LOWER)}
          />
        </g>
        <g
          id={PINKIE_LOWER}
          onClick={() => handleClick(PINKIE_LOWER)}
          className={'clickable-text'}
        >
          <path
            d={
              'M392 714C392 708.477 396.477 704 402 704V704C407.523 704 412 708.477 412 714V714C412 719.523 407.523 724 402 724V724C396.477 724 392 719.523 392 714V714Z'
            }
            fill={'white'}
          />
          {isChecked(PINKIE_LOWER) && (
            <circle
              cx={'402'}
              cy={'714'}
              r={'5'}
              fill={getColor(PINKIE_LOWER)}
            />
          )}

          <path
            d={
              'M402 722C397.582 722 394 718.418 394 714H390C390 720.627 395.373 726 402 726V722ZM410 714C410 718.418 406.418 722 402 722V726C408.627 726 414 720.627 414 714H410ZM402 706C406.418 706 410 709.582 410 714H414C414 707.373 408.627 702 402 702V706ZM402 702C395.373 702 390 707.373 390 714H394C394 709.582 397.582 706 402 706V702Z'
            }
            fill={getColor(PINKIE_LOWER)}
          />
        </g>
      </Visible>
    </g>
  );
};

LeftFootInteractive.propTypes = {
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  anklesDisabled: PropTypes.bool,
  instepDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool
};

LeftFootInteractive.defaultProps = {
  onChange: _.noop,
  initialSelectedParts: [],
  anklesDisabled: false,
  instepDisabled: false,
  lowDisabled: false
};

export default LeftFootInteractive;
