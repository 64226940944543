import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectRouterHistoryStore = (store) =>
  _.getNonEmpty(store, 'routerHistory', {});

export const selectRouterHistoryPreviousLocation = createSelector(
  [selectRouterHistoryStore],
  (store) => _.getNonEmpty(store, 'previousLocation', {})
);
