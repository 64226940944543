/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Label } from 'reactstrap';

import PropTypes from 'prop-types';

import { ReactstrapColShape } from '../../metadata/shapes/ReactstrapShape';

const LabelWrapper = ({
  children,
  hidden,
  check,
  size,
  htmlFor,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  id
}) => (
  <Label
    hidden={hidden}
    check={check}
    size={size}
    htmlFor={htmlFor}
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    className={className}
    id={id}
  >
    {children}
  </Label>
);

LabelWrapper.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
  check: PropTypes.bool,
  size: PropTypes.string,
  htmlFor: PropTypes.string,
  xs: ReactstrapColShape,
  sm: ReactstrapColShape,
  md: ReactstrapColShape,
  lg: ReactstrapColShape,
  xl: ReactstrapColShape,
  className: PropTypes.string,
  id: PropTypes.string
};

LabelWrapper.defaultProps = {
  children: undefined,
  hidden: undefined,
  check: undefined,
  size: undefined,
  htmlFor: undefined,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  className: undefined,
  id: undefined
};

export default LabelWrapper;
