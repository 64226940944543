import _ from 'lodash';
import numbro from 'numbro';
import languages from 'numbro/dist/languages.min';

import {
  LOCALE_SL_SI,
  LOCALE_EN_US
} from '../utils/constants/languageConstants';

import { getRegionalFormatting } from './language';

const whitelistedLanguages = [LOCALE_EN_US, LOCALE_SL_SI];

const languagesWithSlSi = {
  ...languages,
  [LOCALE_SL_SI]: { ...languages.sl, languageTag: LOCALE_SL_SI }
};

Object.values(languagesWithSlSi).forEach((language) =>
  numbro.registerLanguage(language)
);

const currentLocale = getRegionalFormatting();

numbro.setLanguage(currentLocale);

const allLanguageList = _.values(languagesWithSlSi);
let languageList = _.uniqBy(allLanguageList, 'languageTag');

languageList.push({ languageTag: LOCALE_EN_US });

languageList = _.sortBy(languageList, 'languageTag');

const filteredList = _.filter(languageList, (language) =>
  _.includes(whitelistedLanguages, language.languageTag)
);

export const formattingLanguages = _.cloneDeep(filteredList);
