import React, { useMemo } from 'react';
import JsonSchemaForm from 'react-jsonschema-form';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { WIDGET_TYPE_QUESTIONNAIRE_SIGNATURE } from '../../../../../utils/constants/widgetConstants';
import { useActions, useUnmount } from '../../../../../utils/hooks';
import {
  getCustomWidgets,
  transformQuestionnaireErrors
} from '../../../../../utils/widgets';

import { clearFormTemplateDetail } from '../../../../../redux/form/template/formTemplateActions';

import Visible from '../../../layout/Visible';
import CustomDescriptionField from '../CustomDescriptionField';
import QuestionnaireWidgetFooter from './QuestionnaireWidgetFooter';

export const fields = {
  DescriptionField: CustomDescriptionField
};

const QuestionnaireWidgetForm = ({
  jsonSchemaFormProps,
  disabled,
  handleCancel,
  withoutTitle
}) => {
  const [clearFormTemplateDetailDispatch] = useActions([
    clearFormTemplateDetail
  ]);

  const finalJsonSchemaFormProps = useMemo(() => {
    const jsonSchema = _.cloneDeep(jsonSchemaFormProps);
    const schema = _.getNonEmpty(jsonSchema, 'schema');
    let requiredFields = _.getAndClone(schema, 'required', []);
    const uiSchema = _.getNonEmpty(jsonSchema, 'uiSchema');
    const signatureFieldId = _.findKey(uiSchema, {
      'ui:widget': WIDGET_TYPE_QUESTIONNAIRE_SIGNATURE
    });

    /**
     * If the questionnaire includes a signature widget it must not be required
     */
    if (!_.isEmptySafe(signatureFieldId)) {
      requiredFields = _.remove(
        requiredFields,
        (fieldId) => fieldId !== signatureFieldId
      );

      _.set(jsonSchema, 'schema.required', requiredFields);
    }

    if (withoutTitle) {
      jsonSchema.schema = _.omit(jsonSchema.schema, 'title');
    }

    return jsonSchema;
  }, [jsonSchemaFormProps, withoutTitle]);

  useUnmount(() => {
    clearFormTemplateDetailDispatch();
  });

  return (
    <JsonSchemaForm
      {...finalJsonSchemaFormProps}
      ObjectFieldTemplate={undefined}
      FieldTemplate={undefined}
      liveValidate={false}
      noHtml5Validate
      showErrorList={false}
      transformErrors={transformQuestionnaireErrors}
      widgets={getCustomWidgets()}
      fields={fields}
      className={undefined}
      disabled={disabled}
    >
      <Visible visible={!disabled}>
        <QuestionnaireWidgetFooter toggleOpened={handleCancel} />
      </Visible>
    </JsonSchemaForm>
  );
};

QuestionnaireWidgetForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  jsonSchemaFormProps: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  withoutTitle: PropTypes.bool
};

QuestionnaireWidgetForm.defaultProps = {
  disabled: false,
  withoutTitle: false
};

export default QuestionnaireWidgetForm;
