import React from 'react';
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { selectOrganizationCountry } from '../../../../redux/organization/organizationSelectors';

const CustomPhoneNumberWidget = ({
  value,
  onChange,
  disabled,
  countryOptionsOrder
}) => {
  const country = useSelector(selectOrganizationCountry);

  const handleOnChange = (input) => {
    let phoneNumber = input;

    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      const { number } = parsePhoneNumber(phoneNumber);

      phoneNumber = number;
    }

    onChange(phoneNumber);
  };

  return (
    <PhoneInput
      className={classNames(`form-control moshi-phone-input`, {
        disabled
      })}
      defaultCountry={country}
      placeholder={'040 123 456'}
      countryOptionsOrder={countryOptionsOrder}
      displayInitialValueAsLocalNumber
      addInternationalOption={false}
      value={value}
      onChange={handleOnChange}
      disabled={disabled}
    />
  );
};

CustomPhoneNumberWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  countryOptionsOrder: PropTypes.arrayOf(PropTypes.string)
};

CustomPhoneNumberWidget.defaultProps = {
  value: undefined,
  disabled: false,
  placeholder: 'phoneNumber:example',
  countryOptionsOrder: ['SI', 'IT', 'AT', 'HR', 'HU', 'DE', '|', '...'],
  addInternationalOption: false
};

export default CustomPhoneNumberWidget;
