import { createActionTypeArray } from '../../common/actionTypes';

export const GET_ADVANCE_LIST = 'advance/GET_ADVANCE_LIST';
export const ADD_ADVANCE = 'advance/ADD_ADVANCE';
export const UPDATE_ADVANCE = 'advance/UPDATE_ADVANCE';
export const PATCH_ADVANCE = 'billing/PATCH_ADVANCE';
export const REMOVE_ADVANCE = 'advance/REMOVE_ADVANCE';
export const GET_ADVANCE_DETAIL = 'advance/GET_ADVANCE_DETAIL';
export const CLEAR_ADVANCE_DETAIL = 'advance/CLEAR_ADVANCE_DETAIL';

export const SET_ADVANCE_LIST_FILTER = 'advance/SET_ADVANCE_LIST_FILTER';
export const SET_ADVANCE_LIST_FILTERS = 'advance/SET_ADVANCE_LIST_FILTERS';
export const CLEAR_ADVANCE_LIST_FILTER = 'advance/CLEAR_ADVANCE_LIST_FILTER';
export const CLEAR_ADVANCE_LIST_FILTER_NO_POLL =
  'billing/CLEAR_ADVANCE_LIST_FILTER_NO_POLL';
export const SET_ADVANCE_LIST_INCLUDE_PARAM =
  'billing/SET_ADVANCE_LIST_INCLUDE_PARAM';
export const SET_ADVANCE_LIST_ORDER = 'advance/SET_ADVANCE_LIST_ORDER';
export const SET_ADVANCE_LIST_PAGINATION =
  'advance/SET_ADVANCE_LIST_PAGINATION';

export const ADD_ADVANCE_PAYMENT = 'advance/ADD_ADVANCE_PAYMENT';
export const GET_ADVANCE_PAYMENTS_LIST = 'advance/GET_ADVANCE_PAYMENTS_LIST';
export const REMOVE_ADVANCE_PAYMENT = 'advance/REMOVE_ADVANCE_PAYMENT';
export const CLEAR_ADVANCE_PAYMENTS_LIST =
  'advance/CLEAR_ADVANCE_PAYMENTS_LIST';

export const GET_PATIENT_ADVANCE_LIST = 'advance/GET_PATIENT_ADVANCE_LIST';
export const CLEAR_PATIENT_ADVANCE_LIST = 'advance/CLEAR_PATIENT_ADVANCE_LIST';

export const RECHECK_ADVANCE_FURS_STATUS =
  'advance/RECHECK_ADVANCE_FURS_STATUS';
export const SEND_ADVANCE_VIA_EMAIL = 'billing/SEND_ADVANCE_VIA_EMAIL';

export const [
  GET_ADVANCE_LIST_IN_PROGRESS,
  GET_ADVANCE_LIST_SUCCESS,
  GET_ADVANCE_LIST_FAILURE
] = createActionTypeArray(GET_ADVANCE_LIST);

export const [
  GET_PATIENT_ADVANCE_LIST_IN_PROGRESS,
  GET_PATIENT_ADVANCE_LIST_SUCCESS,
  GET_PATIENT_ADVANCE_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_ADVANCE_LIST);

export const [
  GET_ADVANCE_DETAIL_IN_PROGRESS,
  GET_ADVANCE_DETAIL_SUCCESS,
  GET_ADVANCE_DETAIL_FAILURE
] = createActionTypeArray(GET_ADVANCE_DETAIL);

export const [
  ADD_ADVANCE_IN_PROGRESS,
  ADD_ADVANCE_SUCCESS,
  ADD_ADVANCE_FAILURE
] = createActionTypeArray(ADD_ADVANCE);

export const [
  UPDATE_ADVANCE_IN_PROGRESS,
  UPDATE_ADVANCE_SUCCESS,
  UPDATE_ADVANCE_FAILURE
] = createActionTypeArray(UPDATE_ADVANCE);

export const [
  PATCH_ADVANCE_IN_PROGRESS,
  PATCH_ADVANCE_SUCCESS,
  PATCH_ADVANCE_FAILURE
] = createActionTypeArray(PATCH_ADVANCE);

export const [
  REMOVE_ADVANCE_IN_PROGRESS,
  REMOVE_ADVANCE_SUCCESS,
  REMOVE_ADVANCE_FAILURE
] = createActionTypeArray(REMOVE_ADVANCE);

export const [
  GET_ADVANCE_PAYMENTS_LIST_IN_PROGRESS,
  GET_ADVANCE_PAYMENTS_LIST_SUCCESS,
  GET_ADVANCE_PAYMENTS_LIST_FAILURE
] = createActionTypeArray(GET_ADVANCE_PAYMENTS_LIST);

export const [
  RECHECK_ADVANCE_FURS_STATUS_IN_PROGRESS,
  RECHECK_ADVANCE_FURS_STATUS_SUCCESS,
  RECHECK_ADVANCE_FURS_STATUS_FAILURE
] = createActionTypeArray(RECHECK_ADVANCE_FURS_STATUS);
