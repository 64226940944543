import React, { useEffect } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  PORTAL_ELEMENT_MOUNTED,
  PORTAL_ELEMENT_UNMOUNTED
} from '../../../utils/constants/pubSubEventConstants';
import usePubSub from '../../../utils/hooks/usePubSub';

const PortalPlaceholder = ({ id, children, className }) => {
  const { emit } = usePubSub();

  useEffect(() => {
    if (!_.isEmptySafe(id)) {
      emit({ type: PORTAL_ELEMENT_MOUNTED, id });
    }

    return () => {
      if (!_.isEmptySafe(id)) {
        emit({ type: PORTAL_ELEMENT_UNMOUNTED, id });
      }
    };
  }, [emit, id]);

  return (
    <div id={id} className={className}>
      {children}
    </div>
  );
};

PortalPlaceholder.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

PortalPlaceholder.defaultProps = {
  id: undefined,
  children: undefined,
  className: undefined
};

export default PortalPlaceholder;
