import { createActionTypeArray } from '../common/actionTypes';

export const GET_BILLING_UNITS = 'billing/GET_BILLING_UNITS';
export const GET_BILLING_UNIT = 'billing/GET_BILLING_UNIT';
export const CLEAR_BILLING_UNIT = 'billing/CLEAR_BILLING_UNIT';
export const UPDATE_BILLING_UNIT = 'billing/UPDATE_BILLING_UNIT';
export const ADD_BILLING_UNIT = 'billing/ADD_BILLING_UNIT';
export const REMOVE_BILLING_UNIT = 'billing/REMOVE_BILLING_UNIT';
export const GET_TAX_LIST = 'billing/GET_TAX_LIST';
export const GET_TAX_DETAIL = 'billing/GET_TAX_DETAIL';
export const ADD_TAX = 'billing/ADD_TAX';
export const UPDATE_TAX = 'billing/UPDATE_TAX';
export const REMOVE_TAX = 'billing/REMOVE_TAX';
export const GET_BILLING_ITEM_LIST = 'billing/GET_BILLING_ITEM_LIST';
export const SET_BILLING_ITEM_LIST_FILTER =
  'billing/SET_BILLING_ITEM_LIST_FILTER';
export const GET_BILLING_ITEM_DETAIL = 'billing/GET_BILLING_ITEM_DETAIL';
export const ADD_BILLING_ITEM = 'billing/ADD_BILLING_ITEM';
export const UPDATE_BILLING_ITEM = 'billing/UPDATE_BILLING_ITEM';
export const UPDATE_BILLING_ITEMS_BATCH = 'billing/UPDATE_BILLING_ITEMS_BATCH';
export const REMOVE_BILLING_ITEM = 'billing/REMOVE_BILLING_ITEM';
export const SET_BILLING_ITEM_LIST_PAGINATION =
  'SET_BILLING_ITEM_LIST_PAGINATION';
export const SET_BILLING_ITEM_LIST_ORDER =
  'billing/SET_BILLING_ITEM_LIST_ORDER';
export const GET_BUSINESS_PREMISE_LIST = 'billing/GET_BUSINESS_PREMISE_LIST';
export const ADD_BUSINESS_PREMISE = 'billing/ADD_BUSINESS_PREMISE';
export const ADD_FURS_NUMBERING_STRATEGY =
  'billing/ADD_FURS_NUMBERING_STRATEGY';
export const ADD_ELECTRONIC_DEVICE = 'billing/ADD_ELECTRONIC_DEVICE';
export const ENABLE_FURS = 'billing/BILLING_ENABLE_FURS';
export const SET_INVOICE_KEYWORD_DATA = 'billing/SET_INVOICE_KEYWORD_DATA';
export const GET_BILLING_UNIT_INVOICE_NUMBERS = 'billing/GET_INVOICE_NUMBERS';
export const CLEAR_BILLING_UNIT_INVOICE_NUMBERS =
  'billing/CLEAR_BILLING_UNIT_INVOICE_NUMBERS';
export const SET_BILLING_EXPORT_COLUMNS = 'bill/SET_BILLING_EXPORT_COLUMNS';
export const SET_GROUP_BILLING_ITEMS = 'bill/SET_GROUP_BILLING_ITEMS';

export const [
  GET_BILLING_UNITS_IN_PROGRESS,
  GET_BILLING_UNITS_SUCCESS,
  GET_BILLING_UNITS_FAILURE
] = createActionTypeArray(GET_BILLING_UNITS);

export const [
  GET_BILLING_UNIT_IN_PROGRESS,
  GET_BILLING_UNIT_SUCCESS,
  GET_BILLING_UNIT_FAILURE
] = createActionTypeArray(GET_BILLING_UNIT);

export const [
  UPDATE_BILLING_UNIT_IN_PROGRESS,
  UPDATE_BILLING_UNIT_SUCCESS,
  UPDATE_BILLING_UNIT_FAILURE
] = createActionTypeArray(UPDATE_BILLING_UNIT);

export const [
  ADD_BILLING_UNIT_IN_PROGRESS,
  ADD_BILLING_UNIT_SUCCESS,
  ADD_BILLING_UNIT_FAILURE
] = createActionTypeArray(ADD_BILLING_UNIT);

export const [
  REMOVE_BILLING_UNIT_IN_PROGRESS,
  REMOVE_BILLING_UNIT_SUCCESS,
  REMOVE_BILLING_UNIT_FAILURE
] = createActionTypeArray(REMOVE_BILLING_UNIT);

export const [
  GET_BUSINESS_PREMISE_LIST_IN_PROGRESS,
  GET_BUSINESS_PREMISE_LIST_SUCCESS,
  GET_BUSINESS_PREMISE_LIST_FAILURE
] = createActionTypeArray(GET_BUSINESS_PREMISE_LIST);

export const [
  GET_TAX_LIST_IN_PROGRESS,
  GET_TAX_LIST_SUCCESS,
  GET_TAX_LIST_FAILURE
] = createActionTypeArray(GET_TAX_LIST);

export const [
  GET_TAX_DETAIL_IN_PROGRESS,
  GET_TAX_DETAIL_SUCCESS,
  GET_TAX_DETAIL_FAILURE
] = createActionTypeArray(GET_TAX_DETAIL);

export const [
  ADD_TAX_IN_PROGRESS,
  ADD_TAX_SUCCESS,
  ADD_TAX_FAILURE
] = createActionTypeArray(ADD_TAX);

export const [
  UPDATE_TAX_IN_PROGRESS,
  UPDATE_TAX_SUCCESS,
  UPDATE_TAX_FAILURE
] = createActionTypeArray(UPDATE_TAX);

export const [
  REMOVE_TAX_IN_PROGRESS,
  REMOVE_TAX_SUCCESS,
  REMOVE_TAX_FAILURE
] = createActionTypeArray(REMOVE_TAX);

export const [
  GET_BILLING_ITEM_LIST_IN_PROGRESS,
  GET_BILLING_ITEM_LIST_SUCCESS,
  GET_BILLING_ITEM_LIST_FAILURE
] = createActionTypeArray(GET_BILLING_ITEM_LIST);

export const [
  GET_BILLING_ITEM_DETAIL_IN_PROGRESS,
  GET_BILLING_ITEM_DETAIL_SUCCESS,
  GET_BILLING_ITEM_DETAIL_FAILURE
] = createActionTypeArray(GET_BILLING_ITEM_DETAIL);

export const [
  ADD_BILLING_ITEM_IN_PROGRESS,
  ADD_BILLING_ITEM_SUCCESS,
  ADD_BILLING_ITEM_FAILURE
] = createActionTypeArray(ADD_BILLING_ITEM);

export const [
  UPDATE_BILLING_ITEM_IN_PROGRESS,
  UPDATE_BILLING_ITEM_SUCCESS,
  UPDATE_BILLING_ITEM_FAILURE
] = createActionTypeArray(UPDATE_BILLING_ITEM);

export const [
  REMOVE_BILLING_ITEM_IN_PROGRESS,
  REMOVE_BILLING_ITEM_SUCCESS,
  REMOVE_BILLING_ITEM_FAILURE
] = createActionTypeArray(REMOVE_BILLING_ITEM);

export const [
  GET_BILLING_UNIT_INVOICE_NUMBERS_IN_PROGRESS,
  GET_BILLING_UNIT_INVOICE_NUMBERS_SUCCESS,
  GET_BILLING_UNIT_INVOICE_NUMBERS_FAILURE
] = createActionTypeArray(GET_BILLING_UNIT_INVOICE_NUMBERS);

export const [
  ADD_ELECTRONIC_DEVICE_IN_PROGRESS,
  ADD_ELECTRONIC_DEVICE_SUCCESS,
  ADD_ELECTRONIC_DEVICE_FAILURE
] = createActionTypeArray(ADD_ELECTRONIC_DEVICE);

export const [
  ADD_BUSINESS_PREMISE_IN_PROGRESS,
  ADD_BUSINESS_PREMISE_SUCCESS,
  ADD_BUSINESS_PREMISE_FAILURE
] = createActionTypeArray(ADD_BUSINESS_PREMISE);

export const [
  ADD_FURS_NUMBERING_STRATEGY_IN_PROGRESS,
  ADD_FURS_NUMBERING_STRATEGY_SUCCESS,
  ADD_FURS_NUMBERING_STRATEGY_FAILURE
] = createActionTypeArray(ADD_FURS_NUMBERING_STRATEGY);

export const [
  ENABLE_FURS_IN_PROGRESS,
  ENABLE_FURS_SUCCESS,
  ENABLE_FURS_FAILURE
] = createActionTypeArray(ENABLE_FURS);
