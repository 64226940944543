import React, { useCallback } from 'react';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { isValidDate } from '../../../utils/date';
import { getLanguage } from '../../../utils/language';

import Icon from '../Icon';

/**
 * The common date picker component
 *
 * @example ./__examples__/MoshiDatePicker.md
 */
const MoshiDatePicker = ({
  onChange,
  onBlur,
  value,
  name,
  minDate,
  maxDate,
  format,
  disabled,
  className,
  calendarIcon,
  clearIcon,
  wrapperStyle,
  allowEmpty,
  returnValueAtPosition,
  ...props
}) => {
  const { t: translate } = useTranslation();
  let safeValue = value;

  if (!isValidDate(safeValue) && !allowEmpty) {
    safeValue = moment().toISOString();
  }

  const getDateLimit = useCallback(
    (date) => {
      if (disabled || !isValidDate(date)) {
        return undefined;
      }

      return moment(date).toDate();
    },
    [disabled]
  );

  return (
    <div className={'custom-daypicker'} style={wrapperStyle}>
      <DatePicker
        {...props}
        dayPlaceholder={translate('date:placeholder.day')}
        monthPlaceholder={translate('date:placeholder.month')}
        yearPlaceholder={translate('date:placeholder.year')}
        locale={getLanguage()}
        returnValue={returnValueAtPosition}
        disabled={disabled}
        className={classNames('form-control', className)}
        calendarClassName={'moshi-custom-date-picker'}
        onBlur={onBlur}
        onChange={onChange}
        onCalendarOpen={() => {
          /*
           * This is needed because there is a common issue with closing
           * the datepicker on safari and firefox on macOS
           * https://github.com/wojtekmaj/react-date-picker/issues/15
           */
          const htmlDomResult = document.getElementsByClassName(
            'react-date-picker__calendar'
          );

          if (htmlDomResult instanceof HTMLCollection) {
            htmlDomResult[0].setAttribute('tabindex', '-1');
          }
        }}
        name={name}
        autocomplete={false}
        clearIcon={isValidDate(safeValue) ? clearIcon : null}
        calendarIcon={calendarIcon}
        value={isValidDate(safeValue) ? moment(safeValue).toDate() : undefined}
        tileClassName={'moshi-daypicker-day'}
        format={format}
        minDate={getDateLimit(minDate)}
        maxDate={getDateLimit(maxDate)}
      />
    </div>
  );
};

MoshiDatePicker.propTypes = {
  /** Sets the input value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Sets the class */
  className: PropTypes.string,
  /** Sets the style of the wrapper div */
  wrapperStyle: PropTypes.shape(),
  /** Disables the control */
  disabled: PropTypes.bool,
  /** Will not prefill default value as current date */
  allowEmpty: PropTypes.bool,
  /** Defines the date format */
  format: PropTypes.string,
  /** Defines the min allowed date */
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Defines the max allowed date */
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  /** Sets the input name */
  name: PropTypes.string,
  /** Sets blur event callback */
  onBlur: PropTypes.func,
  /** Sets change event callback */
  onChange: PropTypes.func,
  /** Overrides the default calendar icon */
  calendarIcon: PropTypes.element,
  /** Sets the clear icon or text */
  clearIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** returns the start or end time of day of the selected date */
  returnValueAtPosition: PropTypes.string
};

MoshiDatePicker.defaultProps = {
  value: undefined,
  className: undefined,
  wrapperStyle: undefined,
  disabled: undefined,
  allowEmpty: false,
  format: undefined,
  minDate: undefined,
  maxDate: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  calendarIcon: <Icon name={'calendar-event'} />,
  clearIcon: null,
  returnValueAtPosition: 'start'
};

export default MoshiDatePicker;
