import { lazy } from 'react';

export const LazyCornerstoneViewport = lazy(() =>
  import('react-cornerstone-viewport')
);

export const LazyFeedbackForm = lazy(() =>
  import('./components/common/form/FeedbackForm')
);
export const LazyConfirmPassword = lazy(() =>
  import('./containers/auth/ConfirmPassword')
);
export const LazyLogin = lazy(() => import('./containers/auth/Login'));
export const LazyLogout = lazy(() => import('./containers/auth/Logout'));
export const LazyRegister = lazy(() => import('./containers/auth/Register'));
export const LazyResetPassword = lazy(() =>
  import('./containers/auth/ResetPassword')
);
export const LazyVerifyChangedEmail = lazy(() =>
  import('./containers/auth/VerifyChangedEmail')
);
export const LazyVerifyEmail = lazy(() =>
  import('./containers/auth/VerifyEmail')
);
export const LazyVerifyEmailAndPassword = lazy(() =>
  import('./containers/auth/VerifyWithPassword')
);
export const LazyDownloadPage = lazy(() =>
  import('./containers/download/DownloadPage')
);
export const LazyAutoLogoutWithRedirect = lazy(() =>
  import('./containers/auth/AutoLogoutWithRedirect')
);

export const LazyProtectedApp = lazy(() => import('./ProtectedApp'));

export const LazyDashboard = lazy(() => import('./containers/Dashboard'));
export const LazyAnalytics = lazy(() =>
  import('./containers/analytics/Analytics')
);
export const LazyBilling = lazy(() => import('./containers/billing/Billing'));
export const LazyCalendarLayout = lazy(() =>
  import('./containers/calendar/CalendarLayout')
);
export const LazyEventLog = lazy(() =>
  import('./containers/event-log/EventLog')
);
export const LazyDocuments = lazy(() =>
  import('./containers/documents/Documents')
);
export const LazyPatients = lazy(() => import('./containers/patient/Patients'));
export const LazySettings = lazy(() =>
  import('./containers/settings/Settings')
);
export const LazyTherapy = lazy(() => import('./containers/therapy/Therapy'));
export const LazyCurrentUser = lazy(() =>
  import('./containers/user/CurrentUser')
);
export const LazyUserList = lazy(() => import('./containers/user/UserRouter'));
export const LazyCommunication = lazy(() =>
  import('./containers/communication/Communication')
);

export const LazyAccountSettings = lazy(() =>
  import('./containers/settings/account/AccountSettings')
);
export const LazyAutomatedCheckIn = lazy(() =>
  import('./containers/settings/automated-check-in/AutomatedCheckIn')
);
export const LazyBillingSettingsRouter = lazy(() =>
  import('./containers/settings/billing/BillingSettingsRouter')
);
export const LazyCalendarSettingsRouter = lazy(() =>
  import('./containers/settings/calendar/CalendarSettingsRouter')
);
export const LazyDocumentSettingsRouter = lazy(() =>
  import('./containers/settings/documents/DocumentSettingsRouter')
);
export const LazyBookingSettingsRouter = lazy(() =>
  import('./containers/settings/booking/BookingRouter')
);
export const LazyTherapySettingsRouter = lazy(() =>
  import('./containers/therapy/settings/TherapySettingsRouter')
);

export const LazyTagDetailRouter = lazy(() =>
  import('./containers/settings/account/tags/detail/TagDetailRouter')
);

export const LazyActiveEncounterDetail = lazy(() =>
  import('./containers/patient/detail/ActiveEncounterDetail')
);
export const LazyPatientAnamnesisList = lazy(() =>
  import('./containers/patient/detail/anamnesis/PatientAnamnesisList')
);
export const LazyPatientAppointmentsList = lazy(() =>
  import('./containers/patient/detail/appointments/PatientAppointments')
);
export const LazyPatientBasicInfo = lazy(() =>
  import('./containers/patient/detail/basic-info/PatientBasicInfo')
);
export const LazyPatientBills = lazy(() =>
  import('./containers/patient/detail/bills/PatientBills')
);
export const LazyRheumatologyDiagnosisCard = lazy(() =>
  import(
    './containers/patient/detail/custom-tiles/rheumatology/RheumatologyDiagnosisCard'
  )
);
export const LazyRheumatologyTreatmentOverview = lazy(() =>
  import(
    './containers/patient/detail/custom-tiles/rheumatology/RheumatologyTreatmentOverview'
  )
);
export const LazyDentalChartList = lazy(() =>
  import('./containers/patient/detail/dental-chart/DentalChartList')
);
export const LazyPatientDocument = lazy(() =>
  import('./containers/patient/detail/documents/PatientDocument')
);
export const LazyAllPatientWidgets = lazy(() =>
  import('./containers/patient/detail/medical-record/AllPatientWidgets')
);
export const LazyMedicalRecord = lazy(() =>
  import('./containers/patient/detail/medical-record/MedicalRecord')
);
export const LazyPatientNote = lazy(() =>
  import('./containers/patient/detail/note/PatientNote')
);
export const LazyPatientNoteTile = lazy(() =>
  import('./containers/patient/detail/notes/PatientNoteTile')
);
export const LazyPastEncounters = lazy(() =>
  import('./containers/patient/detail/past-encounters/PastEncounters')
);
export const LazyPatientPersonalInfo = lazy(() =>
  import('./containers/patient/detail/personal-info/PatientPersonalInfo')
);
export const LazyReminderTile = lazy(() =>
  import('./containers/patient/detail/reminders/ReminderTile')
);
export const LazyUnbilledItemsTile = lazy(() =>
  import('./containers/patient/detail/unbilled-items/UnbilledItemsTile')
);
export const LazyPatientActiveTherapy = lazy(() =>
  import('./containers/patient/therapy/PatientActiveTherapy')
);
export const LazyPatientPastTherapyList = lazy(() =>
  import('./containers/patient/therapy/PatientPastTherapyList')
);
export const LazyPatientAnamnesisTile = lazy(() =>
  import('./containers/patient/detail/anamnesis/PatientAnamnesisTile')
);

export const LazyViewDicom = lazy(() =>
  import('./containers/documents/view/ViewDicom')
);
export const LazyViewDocx = lazy(() =>
  import('./containers/documents/view/ViewDocx')
);
export const LazyViewImage = lazy(() =>
  import('./containers/documents/view/ViewImage')
);
export const LazyViewNotAvailable = lazy(() =>
  import('./containers/documents/view/ViewNotAvailable')
);
export const LazyViewPdf = lazy(() =>
  import('./containers/documents/view/ViewPdf')
);
export const LazyViewXlsx = lazy(() =>
  import('./containers/documents/view/ViewXlsx')
);
export const LazyDicomViewer = lazy(() =>
  import('./components/dicom/DicomViewer')
);
export const LazyPossibleDuplicatesTile = lazy(() =>
  import('./components/patient/duplicates/PossibleDuplicatesTile')
);
