import { createActionTypeArray } from '../../../common/actionTypes';

export const GET_OUTBOUND_DOCUMENT_HEADER =
  'documents/GET_OUTBOUND_DOCUMENT_HEADER';
export const GET_OUTBOUND_DOCUMENT_FOOTER =
  'documents/GET_OUTBOUND_DOCUMENT_FOOTER';

// TEMPLATES

export const POLL_OUTBOUND_TEMPLATES_LIST =
  'documents/POLL_OUTBOUND_TEMPLATES_LIST';

export const GET_OUTBOUND_TEMPLATE_DETAIL =
  'documents/GET_OUTBOUND_TEMPLATE_DETAIL';

export const CLEAR_OUTBOUND_TEMPLATE_LIST =
  'documents/CLEAR_OUTBOUND_TEMPLATE_LIST';

export const CLEAR_OUTBOUND_TEMPLATE_DETAIL =
  'documents/CLEAR_OUTBOUND_TEMPLATE_DETAIL';

export const SET_OUTBOUND_TEMPLATE_PAGINATION =
  'documents/SET_OUTBOUND_TEMPLATE_PAGINATION';
export const SET_OUTBOUND_TEMPLATE_LIST_FILTERS =
  'documents/SET_OUTBOUND_TEMPLATE_LIST_FILTERS';
export const START_OUTBOUND_TEMPLATE_LIST_POLLING =
  'cache/START_OUTBOUND_TEMPLATE_LIST_POLLING';
export const STOP_OUTBOUND_TEMPLATE_LIST_POLLING =
  'cache/STOP_OUTBOUND_TEMPLATE_LIST_POLLING';

export const REMOVE_OUTBOUND_TEMPLATE = 'documents/REMOVE_OUTBOUND_TEMPLATE';
export const ADD_OUTBOUND_TEMPLATE = 'documents/ADD_OUTBOUND_TEMPLATE';
export const UPDATE_OUTBOUND_TEMPLATE = 'documents/UPDATE_OUTBOUND_TEMPLATE';

export const [
  GET_OUTBOUND_TEMPLATE_DETAIL_IN_PROGRESS,
  GET_OUTBOUND_TEMPLATE_DETAIL_SUCCESS,
  GET_OUTBOUND_TEMPLATE_DETAIL_FAILURE
] = createActionTypeArray(GET_OUTBOUND_TEMPLATE_DETAIL);

export const [
  UPDATE_OUTBOUND_TEMPLATE_IN_PROGRESS,
  UPDATE_OUTBOUND_TEMPLATE_SUCCESS,
  UPDATE_OUTBOUND_TEMPLATE_FAILURE
] = createActionTypeArray(UPDATE_OUTBOUND_TEMPLATE);

export const [
  ADD_OUTBOUND_TEMPLATE_IN_PROGRESS,
  ADD_OUTBOUND_TEMPLATE_SUCCESS,
  ADD_OUTBOUND_TEMPLATE_FAILURE
] = createActionTypeArray(ADD_OUTBOUND_TEMPLATE);

export const [
  REMOVE_OUTBOUND_TEMPLATE_IN_PROGRESS,
  REMOVE_OUTBOUND_TEMPLATE_SUCCESS,
  REMOVE_OUTBOUND_TEMPLATE_FAILURE
] = createActionTypeArray(REMOVE_OUTBOUND_TEMPLATE);

export const [
  POLL_OUTBOUND_TEMPLATES_LIST_IN_PROGRESS,
  POLL_OUTBOUND_TEMPLATES_LIST_SUCCESS,
  POLL_OUTBOUND_TEMPLATES_LIST_FAILURE
] = createActionTypeArray(POLL_OUTBOUND_TEMPLATES_LIST);

export const [
  GET_OUTBOUND_DOCUMENT_HEADER_IN_PROGRESS,
  GET_OUTBOUND_DOCUMENT_HEADER_SUCCESS,
  GET_OUTBOUND_DOCUMENT_HEADER_FAILURE
] = createActionTypeArray(GET_OUTBOUND_DOCUMENT_HEADER);

export const [
  GET_OUTBOUND_DOCUMENT_FOOTER_IN_PROGRESS,
  GET_OUTBOUND_DOCUMENT_FOOTER_SUCCESS,
  GET_OUTBOUND_DOCUMENT_FOOTER_FAILURE
] = createActionTypeArray(GET_OUTBOUND_DOCUMENT_FOOTER);
