import produce from 'immer';
import _ from 'lodash';

import { ORDER_ASCENDING } from '../../utils/constants/tableConstants';
import moshiConfig from '../../utils/moshiConfig';

import {
  GET_DRUG_LIST_SUCCESS,
  GET_DRUG_ITEM_SUCCESS,
  SET_DRUG_LIST_FILTER,
  SET_DRUG_LIST_ORDER,
  SET_DRUG_LIST_PAGINATION,
  CLEAR_DRUG_ITEM
} from './drugTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  list: [],
  detail: {},
  loadingList: true,
  filter: {
    sortBy: 'name',
    order: ORDER_ASCENDING,
    q: ''
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: _.get(moshiConfig, 'list.pagination.limit', 20),
    page: 1
  }
};

// eslint-disable-next-line default-param-last
const drugReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_DRUG_LIST_SUCCESS:
        draft.loadingList = false;
        draft.list = _.get(payload, 'response.data', []);
        break;
      case GET_DRUG_ITEM_SUCCESS:
        draft.loadingList = false;
        draft.detail = _.get(payload, 'response.data', {});
        break;
      case SET_DRUG_LIST_FILTER:
        draft.filter.q = _.get(payload, 'query', '');
        draft.pagination.page = 1;
        break;
      case SET_DRUG_LIST_ORDER:
        draft.filter.sortBy = _.get(
          payload,
          'sortBy',
          initialState.filter.sortBy
        );
        draft.filter.order = _.get(payload, 'order', initialState.filter.order);
        break;
      case SET_DRUG_LIST_PAGINATION:
        draft.pagination.page = _.get(
          payload,
          'paginationValue',
          initialState.pagination.page
        );
        break;
      case CLEAR_DRUG_ITEM:
        draft.detail = {};
        draft.loadingList = false;
        break;
      case LOGOUT:
        return initialState;

      default:
    }
  });

export default drugReducer;
