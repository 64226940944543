import React from 'react';

import PropTypes from 'prop-types';

const CustomHiddenWidget = ({ id, value }) => (
  <input id={id} type={'hidden'} value={value === null ? '' : value} />
);

CustomHiddenWidget.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

CustomHiddenWidget.defaultProps = {
  value: null
};

export default CustomHiddenWidget;
