import _ from 'lodash';

export const getCurrencySign = (currencyId, fallback = undefined) => {
  if (_.isEmpty(currencyId) || !_.isString(currencyId)) {
    return fallback;
  }

  const currency = _.find(getCurrencyList(), { id: currencyId });

  return _.get(currency, 'sign', fallback);
};

// eslint-disable-next-line max-lines-per-function
export const getSupportedSpaceInvoiceCurrencies = () => [
  'EUR',
  'USD',
  'CAD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BND',
  'BOB',
  'BRL',
  'BWP',
  'BYR',
  'BZD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'GBP',
  'GEL',
  'GHS',
  'GNF',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KHR',
  'KMF',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MOP',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SDG',
  'SEK',
  'SGD',
  'SOS',
  'SYP',
  'THB',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'XAF',
  'XOF',
  'YER',
  'ZAR',
  'ZMK',
  'BTC',
  'ETH',
  'LTC'
];

// eslint-disable-next-line max-lines-per-function
export const getCurrencyList = () => [
  {
    id: 'AED',
    name: 'AED',
    sign: 'د.إ'
  },
  {
    id: 'AFN',
    name: 'AFN',
    sign: 'Af'
  },
  {
    id: 'ALL',
    name: 'ALL',
    sign: 'L'
  },
  {
    id: 'AMD',
    name: 'AMD',
    sign: 'Դ'
  },
  {
    id: 'ANG',
    name: 'ANG',
    sign: ''
  },
  {
    id: 'AOA',
    name: 'AOA',
    sign: 'Kz'
  },
  {
    id: 'ARS',
    name: 'ARS',
    sign: '$'
  },
  {
    id: 'AUD',
    name: 'AUD',
    sign: '$'
  },
  {
    id: 'AWG',
    name: 'AWG',
    sign: 'ƒ'
  },
  {
    id: 'AZN',
    name: 'AZN',
    sign: 'ман'
  },
  {
    id: 'BAM',
    name: 'BAM',
    sign: 'КМ'
  },
  {
    id: 'BBD',
    name: 'BBD',
    sign: '$'
  },
  {
    id: 'BDT',
    name: 'BDT',
    sign: '৳'
  },
  {
    id: 'BGN',
    name: 'BGN',
    sign: 'лв'
  },
  {
    id: 'BHD',
    name: 'BHD',
    sign: 'ب.د'
  },
  {
    id: 'BIF',
    name: 'BIF',
    sign: '₣'
  },
  {
    id: 'BMD',
    name: 'BMD',
    sign: '$'
  },
  {
    id: 'BND',
    name: 'BND',
    sign: '$'
  },
  {
    id: 'BOB',
    name: 'BOB',
    sign: 'Bs.'
  },
  {
    id: 'BRL',
    name: 'BRL',
    sign: 'R$'
  },
  {
    id: 'BSD',
    name: 'BSD',
    sign: '$'
  },
  {
    id: 'BTC',
    name: 'BTC',
    sign: ''
  },
  {
    id: 'BTN',
    name: 'BTN',
    sign: ''
  },
  {
    id: 'BWP',
    name: 'BWP',
    sign: 'P'
  },
  {
    id: 'BYN',
    name: 'BYN',
    sign: 'Br'
  },
  {
    id: 'BZD',
    name: 'BZD',
    sign: '$'
  },
  {
    id: 'CAD',
    name: 'CAD',
    sign: '$'
  },
  {
    id: 'CDF',
    name: 'CDF',
    sign: '₣'
  },
  {
    id: 'CHF',
    name: 'CHF',
    sign: '₣'
  },
  {
    id: 'CLF',
    name: 'CLF',
    sign: ''
  },
  {
    id: 'CLP',
    name: 'CLP',
    sign: '$'
  },
  {
    id: 'CNH',
    name: 'CNH',
    sign: ''
  },
  {
    id: 'CNY',
    name: 'CNY',
    sign: '¥'
  },
  {
    id: 'COP',
    name: 'COP',
    sign: '$'
  },
  {
    id: 'CRC',
    name: 'CRC',
    sign: '₡'
  },
  {
    id: 'CUC',
    name: 'CUC',
    sign: ''
  },
  {
    id: 'CUP',
    name: 'CUP',
    sign: '$'
  },
  {
    id: 'CVE',
    name: 'CVE',
    sign: '$'
  },
  {
    id: 'CZK',
    name: 'CZK',
    sign: 'Kč'
  },
  {
    id: 'DJF',
    name: 'DJF',
    sign: '₣'
  },
  {
    id: 'DKK',
    name: 'DKK',
    sign: 'kr'
  },
  {
    id: 'DOP',
    name: 'DOP',
    sign: '$'
  },
  {
    id: 'DZD',
    name: 'DZD',
    sign: 'د.ج'
  },
  {
    id: 'EGP',
    name: 'EGP',
    sign: '£'
  },
  {
    id: 'ERN',
    name: 'ERN',
    sign: 'Nfk'
  },
  {
    id: 'ETB',
    name: 'ETB',
    sign: ''
  },
  {
    id: 'EUR',
    name: 'EUR',
    sign: '€'
  },
  {
    id: 'FJD',
    name: 'FJD',
    sign: '$'
  },
  {
    id: 'FKP',
    name: 'FKP',
    sign: '£'
  },
  {
    id: 'GBP',
    name: 'GBP',
    sign: '£'
  },
  {
    id: 'GEL',
    name: 'GEL',
    sign: ''
  },
  {
    id: 'GGP',
    name: 'GGP',
    sign: ''
  },
  {
    id: 'GHS',
    name: 'GHS',
    sign: '₵'
  },
  {
    id: 'GIP',
    name: 'GIP',
    sign: '£'
  },
  {
    id: 'GMD',
    name: 'GMD',
    sign: 'D'
  },
  {
    id: 'GNF',
    name: 'GNF',
    sign: '₣'
  },
  {
    id: 'GTQ',
    name: 'GTQ',
    sign: 'Q'
  },
  {
    id: 'GYD',
    name: 'GYD',
    sign: '$'
  },
  {
    id: 'HKD',
    name: 'HKD',
    sign: '$'
  },
  {
    id: 'HNL',
    name: 'HNL',
    sign: 'L'
  },
  {
    id: 'HRK',
    name: 'HRK',
    sign: 'Kn'
  },
  {
    id: 'HTG',
    name: 'HTG',
    sign: 'G'
  },
  {
    id: 'HUF',
    name: 'HUF',
    sign: 'Ft'
  },
  {
    id: 'IDR',
    name: 'IDR',
    sign: 'Rp'
  },
  {
    id: 'ILS',
    name: 'ILS',
    sign: '₪'
  },
  {
    id: 'IMP',
    name: 'IMP',
    sign: ''
  },
  {
    id: 'INR',
    name: 'INR',
    sign: '₹'
  },
  {
    id: 'IQD',
    name: 'IQD',
    sign: 'ع.د'
  },
  {
    id: 'IRR',
    name: 'IRR',
    sign: '﷼'
  },
  {
    id: 'ISK',
    name: 'ISK',
    sign: 'Kr'
  },
  {
    id: 'JEP',
    name: 'JEP',
    sign: ''
  },
  {
    id: 'JMD',
    name: 'JMD',
    sign: '$'
  },
  {
    id: 'JOD',
    name: 'JOD',
    sign: 'د.ا'
  },
  {
    id: 'JPY',
    name: 'JPY',
    sign: '¥'
  },
  {
    id: 'KES',
    name: 'KES',
    sign: 'Sh'
  },
  {
    id: 'KGS',
    name: 'KGS',
    sign: ''
  },
  {
    id: 'KHR',
    name: 'KHR',
    sign: '៛'
  },
  {
    id: 'KMF',
    name: 'KMF',
    sign: ''
  },
  {
    id: 'KPW',
    name: 'KPW',
    sign: '₩'
  },
  {
    id: 'KRW',
    name: 'KRW',
    sign: '₩'
  },
  {
    id: 'KWD',
    name: 'KWD',
    sign: 'د.ك'
  },
  {
    id: 'KYD',
    name: 'KYD',
    sign: '$'
  },
  {
    id: 'KZT',
    name: 'KZT',
    sign: '〒'
  },
  {
    id: 'LAK',
    name: 'LAK',
    sign: '₭'
  },
  {
    id: 'LBP',
    name: 'LBP',
    sign: 'ل.ل'
  },
  {
    id: 'LKR',
    name: 'LKR',
    sign: 'Rs'
  },
  {
    id: 'LRD',
    name: 'LRD',
    sign: '$'
  },
  {
    id: 'LSL',
    name: 'LSL',
    sign: 'L'
  },
  {
    id: 'LYD',
    name: 'LYD',
    sign: 'ل.د'
  },
  {
    id: 'MAD',
    name: 'MAD',
    sign: 'د.م.'
  },
  {
    id: 'MDL',
    name: 'MDL',
    sign: 'L'
  },
  {
    id: 'MGA',
    name: 'MGA',
    sign: ''
  },
  {
    id: 'MKD',
    name: 'MKD',
    sign: 'ден'
  },
  {
    id: 'MMK',
    name: 'MMK',
    sign: 'K'
  },
  {
    id: 'MNT',
    name: 'MNT',
    sign: '₮'
  },
  {
    id: 'MOP',
    name: 'MOP',
    sign: 'P'
  },
  {
    id: 'MRO',
    name: 'MRO',
    sign: ''
  },
  {
    id: 'MRU',
    name: 'MRU',
    sign: 'UM'
  },
  {
    id: 'MUR',
    name: 'MUR',
    sign: '₨'
  },
  {
    id: 'MVR',
    name: 'MVR',
    sign: 'ރ.'
  },
  {
    id: 'MWK',
    name: 'MWK',
    sign: 'MK'
  },
  {
    id: 'MXN',
    name: 'MXN',
    sign: '$'
  },
  {
    id: 'MYR',
    name: 'MYR',
    sign: 'RM'
  },
  {
    id: 'MZN',
    name: 'MZN',
    sign: 'MTn'
  },
  {
    id: 'NAD',
    name: 'NAD',
    sign: '$'
  },
  {
    id: 'NGN',
    name: 'NGN',
    sign: '₦'
  },
  {
    id: 'NIO',
    name: 'NIO',
    sign: 'C$'
  },
  {
    id: 'NOK',
    name: 'NOK',
    sign: 'kr'
  },
  {
    id: 'NPR',
    name: 'NPR',
    sign: '₨'
  },
  {
    id: 'NZD',
    name: 'NZD',
    sign: '$'
  },
  {
    id: 'OMR',
    name: 'OMR',
    sign: 'ر.ع.'
  },
  {
    id: 'PAB',
    name: 'PAB',
    sign: 'B/.'
  },
  {
    id: 'PEN',
    name: 'PEN',
    sign: 'S/.'
  },
  {
    id: 'PGK',
    name: 'PGK',
    sign: 'K'
  },
  {
    id: 'PHP',
    name: 'PHP',
    sign: '₱'
  },
  {
    id: 'PKR',
    name: 'PKR',
    sign: '₨'
  },
  {
    id: 'PLN',
    name: 'PLN',
    sign: 'zł'
  },
  {
    id: 'PYG',
    name: 'PYG',
    sign: '₲'
  },
  {
    id: 'QAR',
    name: 'QAR',
    sign: 'ر.ق'
  },
  {
    id: 'RON',
    name: 'RON',
    sign: 'L'
  },
  {
    id: 'RSD',
    name: 'RSD',
    sign: 'din'
  },
  {
    id: 'RUB',
    name: 'RUB',
    sign: 'р.'
  },
  {
    id: 'RWF',
    name: 'RWF',
    sign: '₣'
  },
  {
    id: 'SAR',
    name: 'SAR',
    sign: 'ر.س'
  },
  {
    id: 'SBD',
    name: 'SBD',
    sign: '$'
  },
  {
    id: 'SCR',
    name: 'SCR',
    sign: '₨'
  },
  {
    id: 'SDG',
    name: 'SDG',
    sign: '£'
  },
  {
    id: 'SEK',
    name: 'SEK',
    sign: 'kr'
  },
  {
    id: 'SGD',
    name: 'SGD',
    sign: '$'
  },
  {
    id: 'SHP',
    name: 'SHP',
    sign: '£'
  },
  {
    id: 'SLL',
    name: 'SLL',
    sign: 'Le'
  },
  {
    id: 'SOS',
    name: 'SOS',
    sign: 'Sh'
  },
  {
    id: 'SRD',
    name: 'SRD',
    sign: '$'
  },
  {
    id: 'SSP',
    name: 'SSP',
    sign: ''
  },
  {
    id: 'STD',
    name: 'STD',
    sign: ''
  },
  {
    id: 'STN',
    name: 'STN',
    sign: 'Db'
  },
  {
    id: 'SVC',
    name: 'SVC',
    sign: ''
  },
  {
    id: 'SYP',
    name: 'SYP',
    sign: 'ل.س'
  },
  {
    id: 'SZL',
    name: 'SZL',
    sign: 'L'
  },
  {
    id: 'THB',
    name: 'THB',
    sign: '฿'
  },
  {
    id: 'TJS',
    name: 'TJS',
    sign: 'ЅМ'
  },
  {
    id: 'TMT',
    name: 'TMT',
    sign: 'm'
  },
  {
    id: 'TND',
    name: 'TND',
    sign: 'د.ت'
  },
  {
    id: 'TOP',
    name: 'TOP',
    sign: 'T$'
  },
  {
    id: 'TRY',
    name: 'TRY',
    sign: '₤'
  },
  {
    id: 'TTD',
    name: 'TTD',
    sign: '$'
  },
  {
    id: 'TWD',
    name: 'TWD',
    sign: '$'
  },
  {
    id: 'TZS',
    name: 'TZS',
    sign: 'Sh'
  },
  {
    id: 'UAH',
    name: 'UAH',
    sign: '₴'
  },
  {
    id: 'UGX',
    name: 'UGX',
    sign: 'Sh'
  },
  {
    id: 'USD',
    name: 'USD',
    sign: '$'
  },
  {
    id: 'UYU',
    name: 'UYU',
    sign: '$'
  },
  {
    id: 'UZS',
    name: 'UZS',
    sign: ''
  },
  {
    id: 'VEF',
    name: 'VEF',
    sign: 'Bs F'
  },
  {
    id: 'VES',
    name: 'VES',
    sign: ''
  },
  {
    id: 'VND',
    name: 'VND',
    sign: '₫'
  },
  {
    id: 'VUV',
    name: 'VUV',
    sign: 'Vt'
  },
  {
    id: 'WST',
    name: 'WST',
    sign: 'T'
  },
  {
    id: 'XAF',
    name: 'XAF',
    sign: '₣'
  },
  {
    id: 'XAG',
    name: 'XAG',
    sign: ''
  },
  {
    id: 'XAU',
    name: 'XAU',
    sign: ''
  },
  {
    id: 'XCD',
    name: 'XCD',
    sign: '$'
  },
  {
    id: 'XDR',
    name: 'XDR',
    sign: ''
  },
  {
    id: 'XOF',
    name: 'XOF',
    sign: ''
  },
  {
    id: 'XPD',
    name: 'XPD',
    sign: ''
  },
  {
    id: 'XPF',
    name: 'XPF',
    sign: '₣'
  },
  {
    id: 'XPT',
    name: 'XPT',
    sign: ''
  },
  {
    id: 'YER',
    name: 'YER',
    sign: '﷼'
  },
  {
    id: 'ZAR',
    name: 'ZAR',
    sign: 'R'
  },
  {
    id: 'ZMW',
    name: 'ZMW',
    sign: 'ZK'
  },
  {
    id: 'ZWL',
    name: 'ZWL',
    sign: '$'
  }
];
