import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Visible from '../layout/Visible';

/**
 * Overlay on bottom of screen, used to show global/contextual action buttons.
 *
 * @example ./__examples__/BottomBar.md
 */

const BottomBarComponent = ({ isOpen, children, leftSide, zIndex }) => (
  <Visible visible={isOpen}>
    <div
      className={classNames('tw-px-4 md:tw-px-24 save-bar')}
      style={{ zIndex }}
    >
      <div className={'left-action-buttons'}>{leftSide}</div>
      <div className={'action-buttons'}>{children}</div>
    </div>
  </Visible>
);

BottomBarComponent.propTypes = {
  /** Should bar be shown/open */
  isOpen: PropTypes.bool,
  /** Content, usually buttons */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** z-index of the save-bar div */
  zIndex: PropTypes.number,
  /** Buttons shown on the left side of the bar */
  leftSide: PropTypes.node
};

BottomBarComponent.defaultProps = {
  isOpen: false,
  children: undefined,
  zIndex: undefined,
  leftSide: undefined
};

export default BottomBarComponent;
