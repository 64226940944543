import React, { useMemo, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useDropdown } from '../../../../../utils/hooks';
import { useGetFeatureModuleEnabledChecker } from '../../../../../utils/hooks/useIsFeatureModuleEnabled';

import { CardMoreActionsShape } from '../../../../../metadata/shapes/TableShape';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from '../../../../reactstrap';
import Icon from '../../../Icon';
import Visible from '../../../layout/Visible';

const MoshiRsMoreActionsDropdown = ({
  toggleSize,
  items,
  onToggle,
  tabIndex,
  className,
  iconClassName,
  onSelect: onClick
}) => {
  const isFeatureModuleEnabled = useGetFeatureModuleEnabledChecker();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const safeItems = useMemo(() => {
    const mappedItems = items.map((action) => {
      const featureModule = _.getNonEmpty(action, 'featureModule', undefined);

      const moduleNotEnabled = !isFeatureModuleEnabled(featureModule);
      const isEmptyItem = _.isEmptySafe(action);

      if (moduleNotEnabled || isEmptyItem) {
        return false;
      }

      return action;
    });

    return mappedItems.filter(Boolean);
  }, [items, isFeatureModuleEnabled]);

  const {
    dropdownProps,
    dropdownMenuProps,
    getItemProps,
    itemsToDisplay
  } = useDropdown({
    items: safeItems,
    onClick
  });

  const handleToggle = (e) => {
    e.stopPropagation();

    if (_.isFunction(onToggle)) {
      onToggle();
    }
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown
      {...dropdownProps}
      className={classNames(
        dropdownProps.className,
        className,
        'rs-more-actions-container more-toggle'
      )}
      isOpen={dropdownOpen}
      toggle={handleToggle}
    >
      <DropdownToggle
        tag={'span'}
        data-toggle={'dropdown'}
        aria-expanded={dropdownOpen}
        tabIndex={tabIndex}
        className={'rs-more-actions-toggle'}
      >
        <Icon
          name={'ellypsis'}
          className={classNames('card-more-actions-icon', iconClassName)}
          size={toggleSize}
        />
      </DropdownToggle>
      <DropdownMenu
        positionFixed
        {...dropdownMenuProps}
        className={classNames(
          dropdownMenuProps.className,
          'rs-more-actions-menu'
        )}
      >
        {itemsToDisplay.map((item, index) => {
          const dropdownItemProps = getItemProps(item, index);
          const itemClassNames = _.getNonEmpty(item, 'className');
          const itemId = _.getNonEmpty(item, 'key', item.id);

          return (
            <DropdownItem
              key={itemId}
              onClick={() => onClick(item)}
              className={classNames(
                dropdownItemProps.className,
                itemClassNames,
                'rs-more-actions-item'
              )}
            >
              <Visible visible={_.isFunction(item.icon)}>{item.icon}</Visible>
              <span>{item.text}</span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

MoshiRsMoreActionsDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape)).isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  onToggle: PropTypes.func,
  toggleSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  onSelect: PropTypes.func
};

MoshiRsMoreActionsDropdown.defaultProps = {
  index: undefined,
  className: undefined,
  iconClassName: undefined,
  toggleSize: 'md',
  tabIndex: undefined,
  onToggle: _.noop,
  onSelect: _.noop
};

export default MoshiRsMoreActionsDropdown;
