export const MOSHI_SIZE_XS = 'xs';
export const MOSHI_SIZE_SM = 'sm';
export const MOSHI_SIZE_MD = 'md';
export const MOSHI_SIZE_LG = 'lg';
export const MOSHI_SIZE_XL = 'xl';
export const MOSHI_SIZE_XXL = 'xxl';

export const TAILWIND_VALID_SIZES = [
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  14,
  16
];
