import { createActionTypeArray } from '../common/actionTypes';

export const ADD_WORKING_HOURS = 'working-hours/ADD_WORKING_HOURS';
export const UPDATE_WORKING_HOURS = 'working-hours/UPDATE_WORKING_HOURS';
export const DELETE_WORKING_HOURS = 'working-hours/DELETE_WORKING_HOURS';
export const GET_CALENDAR_WORKING_HOURS =
  'working-hours/GET_CALENDAR_WORKING_HOURS';
export const GET_ASSIGNEES_CALENDAR_WORKING_HOURS =
  'working-hours/GET_ASSIGNEES_CALENDAR_WORKING_HOURS';

export const START_CALENDAR_WORKING_HOURS_POLLING =
  'working-hours/START_CALENDAR_WORKING_HOURS_POLLING';
export const STOP_CALENDAR_WORKING_HOURS_POLLING =
  'working-hours/STOP_CALENDAR_WORKING_HOURS_POLLING';

export const [
  ADD_WORKING_HOURS_IN_PROGRESS,
  ADD_WORKING_HOURS_SUCCESS,
  ADD_WORKING_HOURS_FAILURE
] = createActionTypeArray(ADD_WORKING_HOURS);

export const [
  UPDATE_WORKING_HOURS_IN_PROGRESS,
  UPDATE_WORKING_HOURS_SUCCESS,
  UPDATE_WORKING_HOURS_FAILURE
] = createActionTypeArray(UPDATE_WORKING_HOURS);
export const [
  DELETE_WORKING_HOURS_IN_PROGRESS,
  DELETE_WORKING_HOURS_SUCCESS,
  DELETE_WORKING_HOURS_FAILURE
] = createActionTypeArray(DELETE_WORKING_HOURS);

export const [
  GET_CALENDAR_WORKING_HOURS_IN_PROGRESS,
  GET_CALENDAR_WORKING_HOURS_SUCCESS,
  GET_CALENDAR_WORKING_HOURS_FAILURE
] = createActionTypeArray(GET_CALENDAR_WORKING_HOURS);

export const [
  GET_ASSIGNEES_CALENDAR_WORKING_HOURS_IN_PROGRESS,
  GET_ASSIGNEES_CALENDAR_WORKING_HOURS_SUCCESS,
  GET_ASSIGNEES_CALENDAR_WORKING_HOURS_FAILURE
] = createActionTypeArray(GET_ASSIGNEES_CALENDAR_WORKING_HOURS);
