import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getCountryList } from '../../../../utils/data/country';
import { useMount } from '../../../../utils/hooks';

import { selectOrganizationCountry } from '../../../../redux/organization/organizationSelectors';

import CustomDropdownWidget from './CustomDropdownWidget';

const CustomCountriesDropdownWidget = ({ value, onChange, ...props }) => {
  const items = getCountryList();
  const orgCountry = useSelector(selectOrganizationCountry);
  const [country, setCountry] = useState(value);
  const shouldUpdateCountry = _.get(props, 'formContext.updateCountry', false);

  useMount(() => {
    if (_.isEmpty(country) && shouldUpdateCountry) {
      handleOnChange(orgCountry);
    }
  });

  const handleOnChange = (input) => {
    setCountry(input);
    onChange(input);
  };

  return (
    <CustomDropdownWidget
      {...props}
      onChange={handleOnChange}
      options={{ enumOptions: items }}
      value={country}
    />
  );
};

CustomCountriesDropdownWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

CustomCountriesDropdownWidget.defaultProps = {
  value: null,
  disabled: false
};

export default CustomCountriesDropdownWidget;
