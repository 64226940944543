export default {
  title: 'Zdravila',
  singleTitle: 'Zdravilo',
  create: 'Novo zdravilo',
  edit: 'Uredite zdravilo',
  drugName: 'Ime zdravila',
  emptyListText: `
  <0>Hmmm, izgleda kot da še ni nobenih zdravil.</0> <1></1>
  <0>Dodajte novo zdravilo.</0>
`
};
