import _ from 'lodash';

import { FILTER_ALL_ID } from '../constants/dropdownConstants';

/**
 * Sets a filter to a state
 * @param {immer state} draft state of store returned by immer
 * @param {{filterName, filterValue}} filter object that contains `filterName` and `filterValue`
 * @param {number | null} resetPageTo page to reset to after setting filter
 */
export const setFilterToStoreDraft = (draft, filter, resetPageTo = null) => {
  const filterName = _.getNonEmpty(filter, 'filterName', null);
  const filterValue = _.getNonEmpty(filter, 'filterValue', '');

  if (_.isEmptySafe(filterName) && _.isEmptySafe(filterValue)) {
    return;
  }

  draft.filter[filterName] =
    filterValue === FILTER_ALL_ID ? undefined : filterValue;

  if (_.isEmptySafe(resetPageTo)) {
    return;
  }

  draft.pagination.page = resetPageTo;
};

/**
 * Sets an array of filters to a state
 * @param {immer state} draft state of store returned by immer
 * @param {[{filterName, filterValue}]} filters array of objects that contains `filterName` and `filterValue`
 * @param {number | null} resetPageTo page to reset to after setting filters
 */
export const setFiltersToStoreDraft = (draft, filters, resetPageTo = null) => {
  if (_.isEmptySafe(filters)) {
    return;
  }

  _.forEach(filters, (filter) => setFilterToStoreDraft(draft, filter));

  if (_.isEmptySafe(resetPageTo)) {
    return;
  }

  draft.pagination.page = resetPageTo;
};

/**
 * Calculates resultCount and pageCount from the API list response
 * @param response API response including 'headers' field
 * @param limit Number of items per page
 */
export const getListPaginationFromResponse = (response, limit) => {
  const resultCountRaw = _.get(response, 'headers.x-total-count');
  let resultCount = _.parseInt(resultCountRaw);

  if (!_.isFinite(resultCount)) {
    resultCount = _.getNonEmpty(response, 'data.length', 0);
  }

  let pageCount = 0;

  if (_.isFinite(limit)) {
    pageCount = _.ceil(resultCount / limit);
  }

  return { resultCount, pageCount };
};
