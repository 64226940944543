import { createActionTypeArray } from '../common/actionTypes';

export const GET_TAG = 'tags/GET_TAG';
export const ADD_TAG = 'tags/ADD_TAG';
export const UPDATE_TAG = 'tags/UPDATE_TAG';
export const CLEAR_TAG = 'tags/CLEAR_TAG';
export const DELETE_TAG = 'tags/DELETE_TAG';

export const GET_TAG_LIST = 'tags/GET_TAG_LIST';
export const SET_TAG_LIST_ORDER = 'tags/SET_TAG_LIST_ORDER';
export const SET_TAG_LIST_PAGINATION = 'tags/SET_TAG_LIST_PAGINATION';
export const LINK_TAG_WITH_SUBJECT = 'tags/LINK_TAG_WITH_SUBJECT';
export const UNLINK_TAG_WITH_SUBJECT = 'tags/UNLINK_TAG_WITH_SUBJECT';

export const [
  GET_TAG_IN_PROGRESS,
  GET_TAG_SUCCESS,
  GET_TAG_FAILURE
] = createActionTypeArray(GET_TAG);

export const [
  ADD_TAG_IN_PROGRESS,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILURE
] = createActionTypeArray(ADD_TAG);

export const [
  UPDATE_TAG_IN_PROGRESS,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE
] = createActionTypeArray(UPDATE_TAG);

export const [
  DELETE_TAG_IN_PROGRESS,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE
] = createActionTypeArray(DELETE_TAG);

export const [
  GET_TAG_LIST_IN_PROGRESS,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_LIST_FAILURE
] = createActionTypeArray(GET_TAG_LIST);

export const [
  LINK_TAG_WITH_SUBJECT_IN_PROGRESS,
  LINK_TAG_WITH_SUBJECT_SUCCESS,
  LINK_TAG_WITH_SUBJECT_FAILURE
] = createActionTypeArray(LINK_TAG_WITH_SUBJECT);

export const [
  UNLINK_TAG_WITH_SUBJECT_IN_PROGRESS,
  UNLINK_TAG_WITH_SUBJECT_SUCCESS,
  UNLINK_TAG_WITH_SUBJECT_FAILURE
] = createActionTypeArray(UNLINK_TAG_WITH_SUBJECT);
