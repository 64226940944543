import produce from 'immer';
import _ from 'lodash';

import { ORDER_DESCENDING } from '../../utils/constants/tableConstants';
import moshiConfig from '../../utils/moshiConfig';
import {
  getListPaginationFromResponse,
  setFiltersToStoreDraft
} from '../../utils/redux/listUtils';
import {
  ENTITY_FIELD_SCHEDULED_AT_ORDER_NAME,
  ENTITY_FIELD_SCHEDULED_AT_NAME
} from '../../utils/table';

import {
  CLEAR_COMMUNICATION_LIST_FILTERS,
  CLEAR_SMS_COMMUNICATION_LIST,
  CLEAR_SMS_COMMUNICATION_MESSAGE_DETAILS,
  GET_SMS_COMMUNICATION_LIST_FAILURE,
  GET_SMS_COMMUNICATION_LIST_IN_PROGRESS,
  GET_SMS_COMMUNICATION_LIST_SUCCESS,
  SET_COMMUNICATION_LIST_FILTERS,
  SET_COMMUNICATION_LIST_PAGINATION,
  SET_SMS_COMMUNICATION_MESSAGE_DETAILS,
  SET_COMMUNICATION_LIST_ORDER
} from './communicationTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  messageDetails: {},
  list: [],
  loadingList: false,
  filter: {
    sortBy: ENTITY_FIELD_SCHEDULED_AT_ORDER_NAME,
    order: ORDER_DESCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  }
};

// eslint-disable-next-line default-param-last
const communicationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_SMS_COMMUNICATION_LIST_SUCCESS:
        const communicationList = _.getNonEmpty(payload, 'response.data', []);
        const response = _.getNonEmpty(payload, 'response', {});
        const { resultCount, pageCount } = getListPaginationFromResponse(
          response,
          draft.pagination.limit
        );

        draft.pagination.resultCount = resultCount;
        draft.pagination.pageCount = pageCount;

        const communicationListWithPatientObj = _.map(
          communicationList,
          (item) => {
            item.patient = _.getNonEmpty(item, 'patients.0', {});
            item.patientID = _.getNonEmpty(item, 'patientIDs.0', null);

            return item;
          }
        );

        draft.list = communicationListWithPatientObj;
        draft.loadingList = false;
        break;
      case GET_SMS_COMMUNICATION_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case CLEAR_SMS_COMMUNICATION_LIST:
        draft.list = [];
        draft.pagination = initialState.pagination;
        draft.filter = initialState.filter;
        break;
      case CLEAR_COMMUNICATION_LIST_FILTERS:
        draft.filter = initialState.filter;
        break;
      case GET_SMS_COMMUNICATION_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case SET_SMS_COMMUNICATION_MESSAGE_DETAILS:
        draft.messageDetails = payload.details;
        break;
      case CLEAR_SMS_COMMUNICATION_MESSAGE_DETAILS:
        draft.messageDetails = {};
        break;
      case SET_COMMUNICATION_LIST_FILTERS:
        const filters = _.getNonEmpty(payload, 'filters', []);

        setFiltersToStoreDraft(draft, filters, initialState.pagination.page);
        break;
      case SET_COMMUNICATION_LIST_ORDER:
        const sortBy = _.getNonEmpty(
          payload,
          'sortBy',
          ENTITY_FIELD_SCHEDULED_AT_NAME
        );
        const order = _.getNonEmpty(payload, 'order', ORDER_DESCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_COMMUNICATION_LIST_PAGINATION:
        const name = _.getNonEmpty(payload, 'paginationName', 'page');
        const value = _.getNonEmpty(
          payload,
          'paginationValue',
          draft.pagination.page
        );

        draft.pagination[name] = value;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default communicationReducer;
