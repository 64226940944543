import produce from 'immer';
import _ from 'lodash';

import {
  DISMISS_ASSIST_REQUEST_SUCCESS,
  GET_ASSIST_REQUEST_SUCCESS
} from '../../../patient/encounter/encounterTypes';
import {
  MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER,
  MANUAL_CREATE_WAITING_LIST_ENCOUNTER,
  MANUAL_REMOVE_WAITING_LIST_ENCOUNTER,
  MANUAL_UPDATE_WAITING_LIST_ENCOUNTER,
  MARK_WAITING_LIST_ENCOUNTER_COMMENT_READ,
  MARK_WAITING_LIST_ENCOUNTER_COMMENT_UNREAD,
  MARK_WAITING_LIST_ENCOUNTER_UPDATED,
  MOVE_WAITING_LIST_ITEM_TO,
  POLL_WAITING_LIST_CONFIG,
  POLL_WAITING_LIST_ENCOUNTERS,
  STOP_POLLING,
  MANUAL_REMOVE_ASSIST_REQUEST
} from '../cacheTypes';

import { LOGOUT } from '../../../auth/authReducer';

const initialState = {
  didPoll: false,
  list: [],
  moveAction: {},
  didPollConfig: false,
  config: {},
  unreadCommentList: [],
  updatedEncountersList: []
};

// eslint-disable-next-line default-param-last
const waitingListEncounterReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;
    let newList;
    let newUnreadCommentList;
    let encounter;
    let encounterId;

    switch (type) {
      case POLL_WAITING_LIST_CONFIG:
        const waitingListConfig = _.get(payload, 'data', []);

        draft.didPollConfig = true;
        draft.config = waitingListConfig;
        break;
      case POLL_WAITING_LIST_ENCOUNTERS:
        const waitingListEncounters = _.get(payload, 'data', []);

        _.map(waitingListEncounters, (bucket) => {
          if (!_.isArray(bucket.encounters)) {
            bucket.encounters = [];
          }

          return bucket;
        });

        draft.didPoll = true;
        draft.list = waitingListEncounters;
        draft.updatedEncountersList = [];
        break;
      case MOVE_WAITING_LIST_ITEM_TO:
        const { fromBucketId, fromIndex, toBucketId, toIndex } = payload;

        newList = _.cloneDeep(draft.list);

        const fromBucketIndex = _.findIndex(newList, {
          bucketID: fromBucketId
        });
        const toBucketIndex = _.findIndex(newList, {
          bucketID: toBucketId
        });

        const fromBucket = _.cloneDeep(newList[fromBucketIndex]);
        let toBucket;

        if (fromBucketId === toBucketId) {
          toBucket = fromBucket;
        } else {
          toBucket = _.cloneDeep(newList[toBucketIndex]);
        }

        const itemToBeMoved = _.getAndClone(
          fromBucket,
          `encounters.${fromIndex}`
        );
        const itemToBeMovedId = _.get(itemToBeMoved, 'id');

        if (_.isEmpty(itemToBeMoved)) {
          return draft;
        }

        itemToBeMoved.bucketID = toBucketId;
        fromBucket.encounters.splice(fromIndex, 1);
        toBucket.encounters.splice(toIndex, 0, itemToBeMoved);

        newList[fromBucketIndex] = fromBucket;
        if (fromBucketId !== toBucketId) {
          newList[toBucketIndex] = toBucket;
        }

        const prevEncounterId = _.get(
          toBucket,
          `encounters.[${toIndex - 1}].id`,
          undefined
        );
        const nextEncounterId = _.get(
          toBucket,
          `encounters.[${toIndex + 1}].id`,
          undefined
        );

        draft.list = newList;
        draft.updatedEncountersList = [];
        draft.moveAction = {
          fromBucketID: fromBucketId,
          toBucketID: toBucketId,
          afterID:
            prevEncounterId === itemToBeMovedId ? undefined : prevEncounterId,
          beforeID:
            nextEncounterId === itemToBeMovedId ? undefined : nextEncounterId
        };
        break;
      case DISMISS_ASSIST_REQUEST_SUCCESS:
        const requestId = _.get(action, 'response.data.id');

        if (requestId) {
          draft.list = removeAssistRequestById(draft.list, requestId);
        }
        break;
      case MANUAL_REMOVE_ASSIST_REQUEST:
        const requestIdToRemove = _.get(action, 'requestId');

        if (requestIdToRemove) {
          draft.list = removeAssistRequestById(draft.list, requestIdToRemove);
        }
        break;
      case GET_ASSIST_REQUEST_SUCCESS:
        const request = _.get(action, 'response.data', {});
        const newRequestId = _.get(action, 'response.data.id', '');

        // remove existing request
        if (!_.isEmpty(newRequestId)) {
          draft.list = removeAssistRequestById(draft.list, newRequestId);
        }

        // add new request
        draft.list[0] = _.cloneDeep(draft.list[0]);
        if (!_.isArray(draft.list[0].assistRequests)) {
          draft.list[0].assistRequests = [];
        }
        draft.list[0].assistRequests.push(request);
        break;
      case MANUAL_CREATE_WAITING_LIST_ENCOUNTER:
        encounter = _.get(payload, 'encounter', {});

        newList = _.cloneDeep(draft.list);

        const bucket = _.findDefault(
          newList,
          { bucketID: encounter.bucketID },
          {}
        );

        if (!_.some(bucket.encounters, { id: encounter.id })) {
          bucket.encounters = [...bucket.encounters, encounter];

          draft.list = newList;
        }

        break;
      case MANUAL_UPDATE_WAITING_LIST_ENCOUNTER:
        encounter = _.get(payload, 'encounter', {});

        newList = _.cloneDeep(draft.list);

        _.forEach(newList, (updatedBucket) => {
          const foundEncounterIndex = _.findIndex(updatedBucket.encounters, {
            id: encounter.id
          });

          if (foundEncounterIndex > -1) {
            _.patchPath(
              updatedBucket,
              `encounters.${foundEncounterIndex}`,
              encounter
            );
          }
        });

        draft.list = newList;
        break;
      case MANUAL_REMOVE_WAITING_LIST_ENCOUNTER:
        encounterId = _.getNonEmpty(payload, 'encounterId', null);

        newList = _.cloneDeep(draft.list);

        _.forEach(newList, (updatedBucket) => {
          updatedBucket.encounters = _.filter(
            updatedBucket.encounters,
            (filteredEncounter) => encounterId !== filteredEncounter.id
          );
        });

        draft.list = newList;
        break;
      case MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER:
        newList = _.cloneDeep(draft.list);

        _.last(newList).encounters = [];

        draft.list = newList;
        break;
      case MARK_WAITING_LIST_ENCOUNTER_UPDATED:
        encounterId = _.getNonEmpty(payload, 'encounterId', null);

        let newUpdatedEncountersList = _.cloneDeep(draft.updatedEncountersList);

        newUpdatedEncountersList = _.pushIfUnique(
          newUpdatedEncountersList,
          encounterId
        );

        draft.updatedEncountersList = newUpdatedEncountersList;
        break;
      case MARK_WAITING_LIST_ENCOUNTER_COMMENT_UNREAD:
        encounterId = _.getNonEmpty(payload, 'encounterId', null);

        newUnreadCommentList = _.cloneDeep(draft.unreadCommentList);

        newUnreadCommentList = _.pushIfUnique(
          newUnreadCommentList,
          encounterId
        );

        draft.unreadCommentList = newUnreadCommentList;
        break;
      case MARK_WAITING_LIST_ENCOUNTER_COMMENT_READ:
        encounterId = _.getNonEmpty(payload, 'encounterId', null);

        newUnreadCommentList = _.cloneDeep(draft.unreadCommentList);

        newUnreadCommentList = _.filter(
          newUnreadCommentList,
          (existingEncounterId) => existingEncounterId !== encounterId
        );

        draft.unreadCommentList = newUnreadCommentList;
        break;
      case STOP_POLLING:
      case LOGOUT:
        return initialState;
      default:
        return state;
    }
  });

// eslint-disable-next-line default-param-last
export default waitingListEncounterReducer;

const removeAssistRequestById = (currentList, requestId) =>
  _.cloneDeep(currentList).map((col) => {
    col.assistRequests = _.filter(
      col.assistRequests,
      (el) => el.id !== requestId
    );

    return col;
  });
