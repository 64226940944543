/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Row } from 'reactstrap';

import PropTypes from 'prop-types';

const RowWrapper = ({ children, onClick, noGutters, form, className, id }) => (
  <Row
    noGutters={noGutters}
    onClick={onClick}
    form={form}
    className={className}
    id={id}
  >
    {children}
  </Row>
);

RowWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onClick: PropTypes.func,
  noGutters: PropTypes.bool,
  form: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

RowWrapper.defaultProps = {
  children: undefined,
  onClick: undefined,
  noGutters: undefined,
  className: undefined,
  form: undefined,
  id: undefined
};

export default RowWrapper;
