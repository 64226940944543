import { put, select } from 'redux-saga/effects';

import {
  getBillingItemsUrl,
  getPaymentTypesUrl
} from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import { guid } from '../../utils/gen';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import {
  webSocketBillingUrl,
  webSocketSubscribe
} from '../core/web-socket/webSocketAction';

import {
  POLL_BILLING_ITEM_LIST,
  POLL_REGISTRY_PAYMENT_TYPES
} from '../core/cache/cacheTypes';
import {
  ADD_BILLING_ITEM_SUCCESS,
  REMOVE_BILLING_ITEM_SUCCESS,
  REMOVE_TAX_SUCCESS,
  UPDATE_BILLING_ITEM_SUCCESS
} from './billingTypes';
import {
  ADD_PAYMENT_TYPE_SUCCESS,
  REMOVE_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_SUCCESS
} from './paymentType/paymentTypeTypes';

import {
  selectCurrentOrganizationId,
  selectIsBillingModuleEnabled
} from '../organization/organizationSelectors';

export function* pollPaymentTypeListSaga() {
  yield pollGenerator(POLL_REGISTRY_PAYMENT_TYPES, getPaymentTypesUrl, {
    retriggerActions: [
      ADD_PAYMENT_TYPE_SUCCESS,
      UPDATE_PAYMENT_TYPE_SUCCESS,
      REMOVE_PAYMENT_TYPE_SUCCESS
    ]
  });
}

export function* subscribeToBillingWebsockets() {
  const state = yield select();
  const isBillingModuleEnabled = selectIsBillingModuleEnabled(state);

  if (isBillingModuleEnabled) {
    const componentId = guid();
    const organizationId = selectCurrentOrganizationId(state);

    yield put(
      webSocketSubscribe(componentId, webSocketBillingUrl(organizationId))
    );
  }
}

export function* billingItemListPollSaga() {
  /**
   * TODO Limit is 20 by default, so here we hardcode it to the max integer. We do this
   * because the dropdown for 'add item' HAS to have ALL of the items in the
   * dropdown. We should replace the filter dropdown with an autosuggest when
   * we are adding an item to the invoice.
   */
  function configGetter() {
    return {
      params: {
        limit: REQUEST_PAGINATION_MAX_LIMIT
      }
    };
  }

  yield pollGenerator(POLL_BILLING_ITEM_LIST, getBillingItemsUrl, {
    retriggerActions: [
      ADD_BILLING_ITEM_SUCCESS,
      UPDATE_BILLING_ITEM_SUCCESS,
      REMOVE_BILLING_ITEM_SUCCESS,
      REMOVE_TAX_SUCCESS
    ],
    configGetter
  });
}
