import { createActionTypeArray } from '../../common/actionTypes';

export const ADD_RESOURCE = 'resource/ADD_RESOURCE';
export const DELETE_RESOURCE = 'resource/DELETE_RESOURCE';
export const UPDATE_RESOURCE = 'resource/UPDATE_RESOURCE';
export const GET_RESOURCES = 'resource/GET_RESOURCES';
export const GET_RESOURCE = 'resource/GET_RESOURCE';

export const [
  GET_RESOURCES_IN_PROGRESS,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE
] = createActionTypeArray(GET_RESOURCES);

export const [
  ADD_RESOURCE_IN_PROGRESS,
  ADD_RESOURCE_SUCCESS,
  ADD_RESOURCE_FAILURE
] = createActionTypeArray(ADD_RESOURCE);

export const [
  UPDATE_RESOURCE_IN_PROGRESS,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE
] = createActionTypeArray(UPDATE_RESOURCE);

export const [
  DELETE_RESOURCE_IN_PROGRESS,
  DELETE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_FAILURE
] = createActionTypeArray(DELETE_RESOURCE);

export const [
  GET_RESOURCE_IN_PROGRESS,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_FAILURE
] = createActionTypeArray(GET_RESOURCE);
