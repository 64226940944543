import produce from 'immer';
import _ from 'lodash';

import { ORDER_DESCENDING } from '../../utils/constants/tableConstants';
import moshiConfig from '../../utils/moshiConfig';
import { getListPaginationFromResponse } from '../../utils/redux/listUtils';
import { ENTITY_FIELD_UPDATED_AT_NAME } from '../../utils/table';

import {
  CLEAR_TAG,
  GET_TAG_FAILURE,
  GET_TAG_IN_PROGRESS,
  GET_TAG_LIST_FAILURE,
  GET_TAG_LIST_IN_PROGRESS,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_SUCCESS,
  SET_TAG_LIST_ORDER,
  SET_TAG_LIST_PAGINATION
} from './tagsTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  detail: null,
  loadingDetail: false,
  list: [],
  loadingList: false,
  filter: {
    sortBy: ENTITY_FIELD_UPDATED_AT_NAME,
    order: ORDER_DESCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  }
};

// eslint-disable-next-line default-param-last
const tagsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_TAG_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_TAG_LIST_SUCCESS:
        const response = _.getNonEmpty(payload, 'response', {});
        const { resultCount, pageCount } = getListPaginationFromResponse(
          response,
          draft.pagination.limit
        );

        draft.pagination.resultCount = resultCount;
        draft.pagination.pageCount = pageCount;
        draft.loadingList = false;
        draft.list = _.getNonEmpty(response, 'data', []);
        break;
      case GET_TAG_LIST_FAILURE:
        draft.loadingList = false;
        draft.list = [];
        break;
      case SET_TAG_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.getNonEmpty(payload, 'order', ORDER_DESCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_TAG_LIST_PAGINATION:
        const name = _.getNonEmpty(payload, 'paginationName', 'page');
        const value = _.getNonEmpty(
          payload,
          'paginationValue',
          draft.pagination.page
        );

        draft.pagination[name] = value;
        break;
      case GET_TAG_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_TAG_SUCCESS:
        if (draft.loadingDetail) {
          const tags = _.getNonEmpty(payload, 'response.data', []);

          draft.loadingDetail = false;
          draft.detail = _.first(tags);
        }

        break;
      case GET_TAG_FAILURE:
        draft.loadingDetail = false;
        break;
      case CLEAR_TAG:
        draft.loadingDetail = false;
        draft.detail = {};
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default tagsReducer;
