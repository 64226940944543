import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  showMissingLocationIdError,
  showMissingOrganizationIdError,
  showAlertSuccess
} from '../../../utils/alert';
import { getOrganizationUrl } from '../../../utils/api';
import {
  BILL_TYPE_CREDIT_NOTE,
  getDateFilterParametersPayload,
  isBillFiscalized
} from '../../../utils/billing';
import { EMPTY_GUID } from '../../../utils/gen';

import { patchPatient } from '../../patient/patientActions';
import { clearBillItemDescription } from '../bill/billActions';
import {
  getBillKeywordData,
  updateUserPreferredBillDataInput
} from '../billingActions';

import {
  GET_CREDIT_NOTE_LIST,
  SET_CREDIT_NOTE_LIST_FILTER,
  SET_CREDIT_NOTE_LIST_FILTERS,
  SET_CREDIT_NOTE_LIST_ORDER,
  GET_CREDIT_NOTE_DETAIL,
  UPDATE_CREDIT_NOTE,
  ADD_CREDIT_NOTE,
  REMOVE_CREDIT_NOTE,
  SET_CREDIT_NOTE_LIST_PAGINATION,
  CLEAR_CREDIT_NOTE_DETAIL,
  ADD_CREDIT_NOTE_PAYMENT,
  GET_CREDIT_NOTE_PAYMENTS_LIST,
  REMOVE_CREDIT_NOTE_PAYMENT,
  CLEAR_CREDIT_NOTE_PAYMENTS_LIST,
  PATCH_CREDIT_NOTE,
  RECHECK_CREDIT_NOTE_FURS_STATUS,
  SEND_CREDIT_NOTE_VIA_EMAIL,
  CLEAR_CREDIT_NOTE_LIST_FILTER,
  CLEAR_CREDIT_NOTE_LIST_FILTER_NO_POLL,
  SET_CREDIT_NOTE_LIST_INCLUDE_PARAM
} from './creditNoteTypes';

import { selectCachedOrganizationId } from '../../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';
import { selectDefaultBillingLocale } from '../../organization/organizationSelectors';
import {
  selectCreditNoteDetail,
  selectCreditNoteFilter,
  selectCreditNoteIncludeParam,
  selectCreditNotePagination
} from './creditNoteSelectors';

import CreditNoteModel from '../../../metadata/model/billing/bill/CreditNoteModel';

export const clearCreditNoteDetail = () => (dispatch, getState) => {
  const bill = selectCreditNoteDetail(getState());
  const billId = _.getNonEmpty(bill, 'id', EMPTY_GUID);

  dispatch(clearBillItemDescription(billId));

  return dispatch({
    type: CLEAR_CREDIT_NOTE_DETAIL
  });
};

export const clearCreditNotePaymentList = () => (dispatch) =>
  dispatch({
    type: CLEAR_CREDIT_NOTE_PAYMENTS_LIST
  });

export const resetCreditNoteStoreData = () => (dispatch) => {
  dispatch(clearCreditNoteDetail());
  dispatch(clearCreditNotePaymentList());
};

export const getCreditNoteList = () => (dispatch, getState) => {
  const store = getState();
  const organizationUrl = getOrganizationUrl(() => store);
  const include = selectCreditNoteIncludeParam(store);
  const { limit, page } = selectCreditNotePagination(store);

  let customFilter = selectCreditNoteFilter(store);

  customFilter = getDateFilterParametersPayload(customFilter);

  return dispatch({
    type: GET_CREDIT_NOTE_LIST,
    apiCall: axios.get(`${organizationUrl}/billing/creditNotes`, {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    })
  });
};

export const getCreditNote = (creditNoteId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: GET_CREDIT_NOTE_DETAIL,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}`
    ),
    onSuccess: () => {
      dispatch(getBillKeywordData(BILL_TYPE_CREDIT_NOTE));
    }
  });
};

export const setCreditNoteListFilter = (filterName, filterValue) => (
  dispatch
) => {
  dispatch({
    type: SET_CREDIT_NOTE_LIST_FILTER,
    filterName,
    filterValue
  });
};

export const setCreditNoteListFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_CREDIT_NOTE_LIST_FILTERS,
    filters
  });
};

export const setCreditNoteListIncludeParam = (includeValue) => (dispatch) =>
  dispatch({
    type: SET_CREDIT_NOTE_LIST_INCLUDE_PARAM,
    includeValue
  });

export const setCreditNoteListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_CREDIT_NOTE_LIST_ORDER,
    sortBy,
    order
  });
};

export const setCreditNoteListPagination = (
  paginationName,
  paginationValue
) => (dispatch) => {
  dispatch({
    type: SET_CREDIT_NOTE_LIST_PAGINATION,
    paginationName,
    paginationValue
  });
};

export const addCreditNote = (creditNote, customListAction, formId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const locationId = selectCurrentLocationId(state);

  if (_.isEmpty(locationId)) {
    return dispatch(showMissingLocationIdError());
  }

  const payload = CreditNoteModel.getPayload(creditNote, defaultLocale);

  payload.locationID = locationId;
  // A unique form id that is generated when we open the add credit note form.
  payload.formID = formId;

  return dispatch({
    type: ADD_CREDIT_NOTE,
    apiCall: axios.post(`${organizationUrl}/billing/creditNotes`, payload),
    onSuccess: () => {
      showAlertSuccess('billing:creditNotes.alerts.creditNoteCreated');
      dispatch(updateUserPreferredBillDataInput(creditNote));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getCreditNoteList());
    }
  });
};

export const updateCreditNote = (creditNote, customListAction) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const payload = CreditNoteModel.getPayload(creditNote, defaultLocale);

  return dispatch({
    type: UPDATE_CREDIT_NOTE,
    apiCall: axios.put(
      `${organizationUrl}/billing/creditNotes/${creditNote.id}`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:creditNotes.alerts.creditNoteUpdated');
      dispatch(updateUserPreferredBillDataInput(creditNote));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getCreditNoteList());
    }
  });
};

export const addOrUpdateCreditNote = (creditNote, customListAction, formId) => (
  dispatch
) => {
  let promiseObject;

  if (_.isEmpty(creditNote.id)) {
    promiseObject = dispatch(
      addCreditNote(creditNote, customListAction, formId)
    );
  } else {
    promiseObject = dispatch(updateCreditNote(creditNote, customListAction));
  }

  return promiseObject;
};

export const patchCreditNote = (creditNoteId, patchedFields) => (
  dispatch,
  getState,
  customListAction = undefined
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: PATCH_CREDIT_NOTE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}/reassign`,
      patchedFields
    ),
    onSuccess: () => {
      showAlertSuccess(`billing:creditNotes.alerts.creditNoteUpdated`);

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getCreditNoteList());
    }
  });
};

export const voidCreditNote = (creditNote, basePath, customListAction) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_CREDIT_NOTE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/creditNotes/${creditNote.id}/cancel`
    ),
    onSuccess: () => {
      showAlertSuccess(
        `billing:creditNotes.alerts.${
          isBillFiscalized(creditNote)
            ? 'creditNoteVoided'
            : 'creditNoteRemoved'
        }`
      );

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getCreditNoteList());
    }
  });
};

export const getCreditNotePayments = (creditNoteId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: GET_CREDIT_NOTE_PAYMENTS_LIST,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}/payments`
    )
  });
};

export const addCreditNotePayment = (
  creditNoteId,
  payload,
  customListAction
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  payload.date = moment(payload.date).format('YYYY-MM-DD');

  return dispatch({
    type: ADD_CREDIT_NOTE_PAYMENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}/payments`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.paymentAdded');
      dispatch(getCreditNotePayments(creditNoteId));
      dispatch(getCreditNote(creditNoteId));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getCreditNoteList());
    }
  });
};

export const removeCreditNotePayment = (
  creditNoteId,
  paymentId,
  customListAction
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_CREDIT_NOTE_PAYMENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}/payments/${paymentId}`
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.paymentRemoved');
      dispatch(getCreditNotePayments(creditNoteId));
      dispatch(getCreditNote(creditNoteId));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getCreditNoteList());
    }
  });
};

export const recheckCreditNoteFursData = (creditNoteId, customListAction) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: RECHECK_CREDIT_NOTE_FURS_STATUS,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}/recheckFursData`
    ),
    onSuccess: () => {
      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      dispatch(getCreditNoteList());
    }
  });
};

export const sendCreditNoteViaEmail = (
  creditNoteId,
  email,
  note = undefined,
  patientIdToUpdate = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = { email, note };

  return dispatch({
    type: SEND_CREDIT_NOTE_VIA_EMAIL,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/creditNotes/${creditNoteId}/sendPdf`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.pdfSentViaEmail');

      if (!_.isEmpty(patientIdToUpdate) && patientIdToUpdate !== EMPTY_GUID) {
        dispatch(patchPatient(patientIdToUpdate, { contact: { email } }));
      }
    }
  });
};

export const clearCreditNoteListFilters = (refetchCreditNoteList = true) => (
  dispatch
) => {
  if (refetchCreditNoteList) {
    dispatch({ type: CLEAR_CREDIT_NOTE_LIST_FILTER });
  } else {
    dispatch({ type: CLEAR_CREDIT_NOTE_LIST_FILTER_NO_POLL });
  }
};
