import _ from 'lodash';
import { createSelector } from 'reselect';

import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';
import { ORDER_DESCENDING } from '../../../utils/constants/tableConstants';
import { getFullName } from '../../../utils/mappers/common-mappers';
import {
  addFlowNameToEncounter,
  addLocationNameToEncounter,
  filterEncounterByActive
} from '../../../utils/mappers/encounter-mappers';
import { selectProps } from '../../../utils/redux/selectorHelpers';

import { ENCOUNTER_STATUS_ACTIVE } from './encounterActions';

import { initialState } from './encounterReducer';

import {
  selectCachedWaitingListConfigBuckets,
  selectCachedWaitingListEncounters,
  selectCachedWaitingListUnreadComments,
  selectCachedWaitingListUpdatedEncounters
} from '../../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';
import { selectBucketConfigToWaitingListDataMapper } from '../../mappers/encounterMapperSelectors';

export const selectEncounterStore = (store) => store.encounter;

export const selectMappedWaitingListData = createSelector(
  [
    selectCachedWaitingListConfigBuckets,
    selectCachedWaitingListEncounters,
    selectBucketConfigToWaitingListDataMapper
  ],
  (buckets, waitingList, bucketConfigToWaitListData) => {
    let waitingListData = [];

    if (!_.isEmpty(buckets) && !_.isEmpty(waitingList)) {
      const waitingListDataMapper = bucketConfigToWaitListData(waitingList);
      const clonedBuckets = _.cloneDeep(buckets);

      waitingListData = clonedBuckets.map(waitingListDataMapper);
    }

    return waitingListData;
  }
);

export const selectGroupedEncounterComments = createSelector(
  [selectEncounterStore],
  (encounterStore) => _.get(encounterStore, 'groupedEncounterComments', {})
);

export const selectHasEncounterNewComments = createSelector(
  [selectCachedWaitingListUnreadComments, selectProps],
  (unreadEncounterComments, encounterId) =>
    _.includes(unreadEncounterComments, encounterId)
);

export const selectWasEncounterUpdated = createSelector(
  [selectCachedWaitingListUpdatedEncounters, selectProps],
  (updatedEncountersList, encounterId) =>
    _.includes(updatedEncountersList, encounterId)
);

export const selectWaitingListEncounterById = createSelector(
  [selectCachedWaitingListEncounters, selectProps],
  (waitingList, encounterId) => {
    let foundEncounter = null;

    _.forEach(waitingList, (updatedBucket) => {
      if (!_.isEmpty(foundEncounter)) {
        return false;
      }
      foundEncounter = _.find(updatedBucket.encounters, {
        id: encounterId
      });
    });

    return foundEncounter;
  }
);

export const selectWaitingListPatientNameByEncounterId = createSelector(
  [(...params) => selectWaitingListEncounterById(...params), selectProps],
  (waitingListEncounter) =>
    getFullName(_.getNonEmpty(waitingListEncounter, 'patient', {}))
);

export const selectWaitingListFilter = createSelector(
  [selectEncounterStore],
  (encounterStore) => _.get(encounterStore, 'filter', {})
);

export const selectWaitingListDoctorFilterValue = createSelector(
  [selectWaitingListFilter],
  (filters) => _.getNonEmpty(filters, 'doctorID', FILTER_ALL_ID)
);

export const selectWaitingListFlowFilterValue = createSelector(
  [selectWaitingListFilter],
  (filters) => _.getNonEmpty(filters, 'flowID', FILTER_ALL_ID)
);

export const selectWaitingListCategoryFilterValue = createSelector(
  [selectWaitingListFilter],
  (filters) => _.getNonEmpty(filters, 'categoryID', FILTER_ALL_ID)
);

export const selectEncounterList = createSelector(
  [selectEncounterStore],
  (encounterStore) => _.get(encounterStore, 'list')
);

export const selectIsActiveEncounter = createSelector(
  [selectEncounterList, selectProps],
  (encounterList, encounterId) => {
    const encounter = _.findDefault(encounterList, { id: encounterId }, {});

    return encounter.status === ENCOUNTER_STATUS_ACTIVE;
  }
);

export const selectActiveEncounter = createSelector(
  [selectEncounterList, selectCurrentLocationId],
  (encounters, selectedLocationId) => {
    const allActiveEncounters = _.filter(encounters, filterEncounterByActive);
    const encounterOnLocation = _.findDefault(
      allActiveEncounters,
      { locationID: selectedLocationId },
      { id: null }
    );

    return encounterOnLocation;
  }
);

export const selectIsEncounterFilterInitial = createSelector(
  [selectWaitingListFilter],
  (currentFilter) => {
    const initialFilter = _.getNonEmpty(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectPastEncounterList = createSelector(
  [selectEncounterStore],
  (encounter) => {
    const pastEncounters = _.getNonEmpty(encounter, 'pastEncountersList', [])
      .map(addFlowNameToEncounter)
      .map(addLocationNameToEncounter);

    return _.orderBy(pastEncounters, ['startedAt'], [ORDER_DESCENDING]);
  }
);
