import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import VerticalRadioButton from '../VerticalRadioButton';

const CustomScaleWidget = ({
  id,
  value,
  onChange,
  disabled,
  schema: { values }
}) => {
  const handleOnChange = useCallback(
    (event) => {
      const intValue = Number(event.target.value);

      onChange(intValue);
    },
    [onChange]
  );

  return (
    <div className={'moshi-vertical-radio-group'}>
      {values.map((option) => (
        <VerticalRadioButton
          key={option.value}
          disabled={disabled}
          name={id}
          label={option.label}
          value={option.value}
          checked={option.value === value}
          onChange={handleOnChange}
        />
      ))}
    </div>
  );
};

CustomScaleWidget.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.shape().isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

CustomScaleWidget.defaultProps = {
  value: undefined,
  disabled: false
};

export default CustomScaleWidget;
