import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DELETED,
  BILL_LIST_FILTER_BILLING_UNIT,
  BILL_LIST_FILTER_DOCTOR,
  BILL_LIST_FILTER_LOCATION,
  BILL_LIST_FILTER_SEARCH,
  BILL_LIST_FILTER_STATUS,
  BILL_LIST_FILTER_ELECTRONIC_DEVICE,
  BILL_LIST_FILTER_DATE_FROM,
  BILL_LIST_FILTER_DATE_TO,
  BILL_LIST_FILTER_PAYMENT_TYPE
} from '../../../utils/billing';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';

import { initialState } from './advanceReducer';

export const selectAdvanceStore = (store) => store.advance;

// LIST SELECTORS
export const selectAdvanceList = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'list', [])
);

export const selectAdvanceLoadingList = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'loadingList', false)
);

export const selectAdvanceIncludeParam = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'include', '')
);

// FILTER SELECTORS
export const selectAdvanceFilter = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'filter', {})
);

export const selectAdvanceFilterSearchValue = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) => _.getNonEmpty(advanceFilter, BILL_LIST_FILTER_SEARCH, '')
);

export const selectAdvanceFilterLocationId = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) =>
    _.getNonEmpty(advanceFilter, BILL_LIST_FILTER_LOCATION, FILTER_ALL_ID)
);

export const selectAdvanceFilterDoctorId = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) =>
    _.getNonEmpty(advanceFilter, BILL_LIST_FILTER_DOCTOR, FILTER_ALL_ID)
);

export const selectAdvanceFilterBillingUnitId = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) =>
    _.getNonEmpty(advanceFilter, BILL_LIST_FILTER_BILLING_UNIT, FILTER_ALL_ID)
);

export const selectAdvanceFilterStatusValue = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) =>
    _.getNonEmpty(advanceFilter, BILL_LIST_FILTER_STATUS, FILTER_ALL_ID)
);

export const selectAdvancePaymentTypeFilterValue = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) =>
    _.getNonEmpty(advanceFilter, BILL_LIST_FILTER_PAYMENT_TYPE, FILTER_ALL_ID)
);

export const selectAdvanceFilterElectronicDeviceId = createSelector(
  [selectAdvanceFilter],
  (advanceFilter) =>
    _.getNonEmpty(
      advanceFilter,
      BILL_LIST_FILTER_ELECTRONIC_DEVICE,
      FILTER_ALL_ID
    )
);

export const selectIsAdvanceFilterInitial = createSelector(
  [selectAdvanceFilter],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectAdvanceFilterFromDate = createSelector(
  [selectAdvanceFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_FROM, undefined)
);

export const selectAdvanceFilterToDate = createSelector(
  [selectAdvanceFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_TO, undefined)
);

// PAGINATION SELECTORS
export const selectAdvancePagination = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'pagination', {})
);

// DETAIL SELECTORS
export const selectAdvanceDetail = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'detail', {})
);

export const selectAdvanceLoadingDetail = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'loadingDetail', false)
);

// PAYMENTS SELECTORS
export const selectAdvancePaymentList = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'payments.list', [])
);

// PATIENT ADVANCES SELECTORS
export const selectPatientAdvanceList = createSelector(
  [selectAdvanceStore],
  (advanceStore) => _.getNonEmpty(advanceStore, 'patientAdvances.list', [])
);

export const selectPatientUnusedAdvanceList = createSelector(
  [selectPatientAdvanceList],
  (patientAdvanceList) =>
    _.filter(patientAdvanceList, (advance) => {
      const billStatus = _.getNonEmpty(advance, 'status', []);
      const isAdvanceUnused =
        _.getNonEmpty(advance, 'total', 0) >
        _.getNonEmpty(advance, 'totalUsed', 0);
      const isNotCanceledOrDeleted = !_.includesAny(billStatus, [
        INVOICE_STATUS_CANCELED,
        INVOICE_STATUS_DELETED
      ]);

      return isAdvanceUnused && isNotCanceledOrDeleted;
    })
);
