import { createActionTypeArray } from '../common/actionTypes';

export const GET_DRUG_LIST = 'drug/GET_DRUG_LIST';

export const GET_DRUG_ITEM = 'drug/GET_DRUG_ITEM';
export const ADD_DRUG_ITEM = 'drug/ADD_DRUG_ITEM';
export const UPDATE_DRUG_ITEM = 'drug/UPDATE_DRUG_ITEM';
export const REMOVE_DRUG_ITEM = 'drug/REMOVE_DRUG_ITEM';
export const CLEAR_DRUG_ITEM = 'drug/CLEAR_DRUG_ITEM';
export const SET_DRUG_LIST_FILTER = 'drug/SET_DRUG_LIST_FILTER';
export const SET_DRUG_LIST_ORDER = 'drug/SET_DRUG_LIST_ORDER';
export const SET_DRUG_LIST_PAGINATION = 'drug/SET_DRUG_LIST_PAGINATION';

export const [
  GET_DRUG_LIST_IN_PROGRESS,
  GET_DRUG_LIST_SUCCESS,
  GET_DRUG_LIST_FAILURE
] = createActionTypeArray(GET_DRUG_LIST);

export const [
  GET_DRUG_ITEM_IN_PROGRESS,
  GET_DRUG_ITEM_SUCCESS,
  GET_DRUG_ITEM_FAILURE
] = createActionTypeArray(GET_DRUG_ITEM);

export const [
  ADD_DRUG_ITEM_IN_PROGRESS,
  ADD_DRUG_ITEM_SUCCESS,
  ADD_DRUG_ITEM_FAILURE
] = createActionTypeArray(ADD_DRUG_ITEM);

export const [
  UPDATE_DRUG_ITEM_IN_PROGRESS,
  UPDATE_DRUG_ITEM_SUCCESS,
  UPDATE_DRUG_ITEM_FAILURE
] = createActionTypeArray(UPDATE_DRUG_ITEM);

export const [
  REMOVE_DRUG_ITEM_IN_PROGRESS,
  REMOVE_DRUG_ITEM_SUCCESS,
  REMOVE_DRUG_ITEM_FAILURE
] = createActionTypeArray(REMOVE_DRUG_ITEM);
