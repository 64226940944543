/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { DropdownMenu } from 'reactstrap';

import PropTypes from 'prop-types';

const DropdownMenuWrapper = ({ className, children, ...props }) => (
  <DropdownMenu className={className} {...props}>
    {children}
  </DropdownMenu>
);

DropdownMenuWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

DropdownMenuWrapper.defaultProps = {
  className: undefined,
  children: undefined
};

export default DropdownMenuWrapper;
