import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { MOSHI_COLOR_SECONDARY_NAME } from '../../../utils/color';
import { MOSHI_SIZE_LG } from '../../../utils/constants/sizeConstants';

import MoshiButton from '../button/MoshiButton';
import MoshiRoutedDiscardChangesPrompt from '../modal/MoshiRoutedDiscardChangesPrompt';
import BottomBar from './BottomBar';
import SubmitButton from './button/SubmitButton';

const PureSaveBar = ({
  onCancel,
  onSubmit,
  visible,
  showWarningPrompt,
  formikAvailable,
  noFormikIsValid,
  zIndex,
  submitButtonClassName
}) => {
  const { t: translate } = useTranslation();

  return (
    <BottomBar isOpen={visible} zIndex={zIndex}>
      <MoshiButton
        size={MOSHI_SIZE_LG}
        id={'cancel'}
        onClick={onCancel}
        color={MOSHI_COLOR_SECONDARY_NAME}
      >
        {translate('common:cancel')}
      </MoshiButton>
      <SubmitButton
        id={'save'}
        onSubmit={onSubmit}
        text={translate('common:save')}
        formikAvailable={formikAvailable}
        noFormikIsValid={noFormikIsValid}
        className={submitButtonClassName}
      />
      <MoshiRoutedDiscardChangesPrompt when={showWarningPrompt} />
    </BottomBar>
  );
};

PureSaveBar.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  showWarningPrompt: PropTypes.bool,
  formikAvailable: PropTypes.bool,
  noFormikIsValid: PropTypes.bool,
  zIndex: PropTypes.number,
  submitButtonClassName: PropTypes.string
};

PureSaveBar.defaultProps = {
  visible: true,
  onSubmit: _.noop,
  hideIfNotDirty: false,
  onCancel: _.noop,
  showWarningPrompt: true,
  formikAvailable: false,
  noFormikIsValid: false,
  zIndex: undefined,
  submitButtonClassName: undefined
};

export default PureSaveBar;
