/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Form } from 'reactstrap';

import PropTypes from 'prop-types';

const FormWrapper = ({ children, inline, innerRef, className, id }) => (
  <Form inline={inline} innerRef={innerRef} className={className} id={id}>
    {children}
  </Form>
);

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ]),
  className: PropTypes.string,
  id: PropTypes.string
};

FormWrapper.defaultProps = {
  inline: undefined,
  innerRef: undefined,
  className: undefined,
  id: undefined
};

export default FormWrapper;
