import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  MOSHI_COLOR_PRIMARY_NAME,
  MOSHI_COLOR_DANGER_NAME,
  MOSHI_COLOR_BLACK_NAME,
  MOSHI_COLOR_WHITE_NAME
} from '../../../utils/color';
import {
  MOSHI_SIZE_LG,
  MOSHI_SIZE_SM,
  MOSHI_SIZE_XL,
  MOSHI_SIZE_XXL
} from '../../../utils/constants/sizeConstants';
import { useIsFeatureModuleEnabled } from '../../../utils/hooks/useIsFeatureModuleEnabled';

import { EmptyComponent } from '../HtmlComponents';

import { FEATURE_MODULE_LIST } from '../../../featureModules';
import { Button } from '../../reactstrap';

/**
 * The common link component
 *
 *
 * @example ./__examples__/MoshiLink.md
 */
const MoshiLink = ({
  children,
  disabled,
  active,
  color,
  onClick,
  size,
  id,
  className,
  tabIndex,
  featureModule
}) => {
  const featureEnabled = useIsFeatureModuleEnabled(featureModule);

  if (!featureEnabled) {
    return <EmptyComponent />;
  }

  return (
    <Button
      disabled={disabled}
      active={active}
      color={`link-${color}`}
      onClick={onClick}
      type={'button'}
      size={`link-${size}`}
      id={id}
      className={classNames(className, 'moshi-link')}
      tabIndex={tabIndex}
    >
      {children}
    </Button>
  );
};

MoshiLink.propTypes = {
  /** Children to render inside the link */
  children: PropTypes.node,
  /** Disable the link */
  disabled: PropTypes.bool,
  /** Make link active */
  active: PropTypes.bool,
  /** Set link's color (default is MOSHI_COLOR_PRIMARY_NAME) */
  color: PropTypes.oneOf([
    MOSHI_COLOR_PRIMARY_NAME,
    MOSHI_COLOR_DANGER_NAME,
    MOSHI_COLOR_BLACK_NAME,
    MOSHI_COLOR_WHITE_NAME
  ]),
  /** Function that's called when link is clicked */
  onClick: PropTypes.func,
  /** Size of the link */
  size: PropTypes.oneOf([
    MOSHI_SIZE_SM,
    MOSHI_SIZE_LG,
    MOSHI_SIZE_XL,
    MOSHI_SIZE_XXL
  ]),
  /** id */
  id: PropTypes.string,
  /** className */
  className: PropTypes.string,
  /** tab index */
  tabIndex: PropTypes.number,
  /** defined feature module */
  featureModule: PropTypes.oneOf(FEATURE_MODULE_LIST)
};

MoshiLink.defaultProps = {
  children: undefined,
  disabled: undefined,
  active: undefined,
  color: MOSHI_COLOR_PRIMARY_NAME,
  onClick: undefined,
  size: MOSHI_SIZE_LG,
  id: undefined,
  className: undefined,
  tabIndex: undefined,
  featureModule: undefined
};

export default MoshiLink;
