import produce from 'immer';
import _ from 'lodash';

import {
  GET_CALENDAR_WORKING_HOURS_IN_PROGRESS,
  GET_CALENDAR_WORKING_HOURS_SUCCESS,
  GET_CALENDAR_WORKING_HOURS_FAILURE,
  GET_ASSIGNEES_CALENDAR_WORKING_HOURS_SUCCESS,
  GET_ASSIGNEES_CALENDAR_WORKING_HOURS_IN_PROGRESS,
  GET_ASSIGNEES_CALENDAR_WORKING_HOURS_FAILURE
} from './workingHoursTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  list: [],
  loadingList: false,
  assigneesWorkingHoursList: [],
  loadingAssigneesList: false
};

// eslint-disable-next-line default-param-last
const workingHoursReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_CALENDAR_WORKING_HOURS_SUCCESS:
        const workingHours = _.getNonEmpty(payload, 'data', []);

        draft.assigneesWorkingHoursList = workingHours;
        draft.list = workingHours;
        draft.loadingList = false;
        break;
      case GET_CALENDAR_WORKING_HOURS_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_CALENDAR_WORKING_HOURS_FAILURE:
        draft.loadingList = false;
        break;
      case GET_ASSIGNEES_CALENDAR_WORKING_HOURS_SUCCESS:
        const assigneesWorkingHours = _.getNonEmpty(
          payload,
          'response.data',
          []
        );

        draft.assigneesWorkingHoursList = assigneesWorkingHours;
        draft.loadingAssigneesList = false;
        break;
      case GET_ASSIGNEES_CALENDAR_WORKING_HOURS_IN_PROGRESS:
        draft.loadingAssigneesList = true;
        break;
      case GET_ASSIGNEES_CALENDAR_WORKING_HOURS_FAILURE:
        draft.loadingAssigneesList = false;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default workingHoursReducer;
