import axios from 'axios';
import _ from 'lodash';
import { takeEvery, select, put, all } from 'redux-saga/effects';

import { getApiWidgetTypeConfig, getEncounterUrl } from '../../../../utils/api';
import { apiCall } from '../../../../utils/redux/sagaUtils';
import { hasCalculationErrors } from '../../../../utils/widgets';

import { processWidgetData } from './indexCalculationWidgetSagas';
import { handleRefreshData } from './widgetDependencyDataSagas';

import {
  ADD_WIDGET,
  ADD_WIDGET_TRIGGER,
  ADD_WIDGET_SUCCESS,
  ADD_WIDGET_SANITIZED,
  ADD_WIDGET_UPDATED,
  UPDATE_WIDGET_LIST_ADD,
  CLEAR_WIDGET_FORM_DATA,
  REMOVE_LOCAL_WIDGET
} from '../widgetTypes';

export function* addWidgetApiRequest(action) {
  const { encounterId, widget, skipAddingOnErrors } = action;
  const state = yield select();

  const encounterUrl = getEncounterUrl(() => state, encounterId);
  const axiosConfig = getApiWidgetTypeConfig(widget.widgetType);

  yield handleRefreshData(widget.widgetType);
  const processedData = yield processWidgetData(widget.widgetType, widget.data);

  if (_.isEmptySafe(processedData)) return;
  if (skipAddingOnErrors && hasCalculationErrors(processedData)) return;

  yield apiCall(
    ADD_WIDGET,
    axios.post,
    [`${encounterUrl}/widgets`, processedData, axiosConfig],
    { processedData }
  );
}

export function* addWidgetSanitization(action) {
  const {
    data: savedWidget,
    additionalData: { processedData }
  } = action;

  const sanitizedWidget = {
    ...savedWidget,
    data: processedData
  };

  yield put({
    type: ADD_WIDGET_SANITIZED,
    widget: sanitizedWidget
  });
}

export function* addWidgetStoreUpdate(action) {
  const { widget } = action;

  yield all([
    put({
      type: UPDATE_WIDGET_LIST_ADD,
      widget
    }),
    put({
      type: CLEAR_WIDGET_FORM_DATA,
      widgetId: widget.widgetType
    }),
    put({
      type: REMOVE_LOCAL_WIDGET,
      widgetId: widget.widgetType
    })
  ]);
  yield put({ type: ADD_WIDGET_UPDATED, widget });
}

export const addWidgetSagas = [
  takeEvery(ADD_WIDGET_TRIGGER, addWidgetApiRequest),
  takeEvery(ADD_WIDGET_SUCCESS, addWidgetSanitization),
  takeEvery(ADD_WIDGET_SANITIZED, addWidgetStoreUpdate)
];
