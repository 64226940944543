import produce from 'immer';
import _ from 'lodash';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../utils/browserStorage';
import { tablesStorageKey } from '../../utils/constants/browserStorageConstants';

import { SET_TABLE_COLUMN_VISIBILITY } from './tableTypes';

const defaultInitialState = {
  tableList: {}
};

const getInitialState = () =>
  getLocalStorageValue(tablesStorageKey, defaultInitialState);

const setTablesState = (tablesState) =>
  setLocalStorageValue(tablesStorageKey, tablesState);

const tableReducer = produce((draft, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case SET_TABLE_COLUMN_VISIBILITY:
      const tableName = _.get(payload, 'tableName');
      const columnName = _.get(payload, 'columnName');
      const isVisible = _.get(payload, 'isVisible');

      if (
        _.isEmpty(tableName) ||
        _.isEmpty(columnName) ||
        _.isEmptySafe(isVisible)
      ) {
        break;
      }
      _.set(
        draft,
        `tableList.${tableName}.columns.${columnName}.isVisible`,
        isVisible
      );

      setTablesState(draft);
      break;
    default:
  }
}, getInitialState());

export default tableReducer;
