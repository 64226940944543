import React from 'react';

import PropTypes from 'prop-types';

const ModalHeaderText = ({ text }) => (
  <span className={'modal-text-title'}>{text}</span>
);

ModalHeaderText.propTypes = {
  text: PropTypes.string.isRequired
};

export default ModalHeaderText;
