import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { WIDGET_TYPE_QUESTIONNAIRE } from '../../widgets/widgetConstants';
import {
  getSubEntityKeysConfig,
  SUB_ENTITY_TYPE_CATEGORY,
  SUB_ENTITY_TYPE_DRUG,
  SUB_ENTITY_TYPE_FLOW,
  SUB_ENTITY_TYPE_LOCATION,
  SUB_ENTITY_TYPE_WIDGET_QUESTIONNAIRE,
  SUB_ENTITY_TYPE_USER,
  SUB_ENTITY_TYPE_TAX
} from '../constants/entityConstants';
import { REGISTRY_THERAPY_DRUGS } from '../constants/redux/registryConstants';

import { getTax } from '../../redux/billing/billingActions';
import { getCategory } from '../../redux/calendar/category/categoryActions';
import { getRegistryItem } from '../../redux/core/registry/registryActions';
import { getFlow } from '../../redux/flow/flowActions';
import { getLocation } from '../../redux/location/locationActions';
import { getQuestionnaire } from '../../redux/questionnaire/questionnaireActions';
import { getUser } from '../../redux/user/userActions';

import {
  GET_DELETED_CATEGORY,
  GET_DELETED_DRUG,
  GET_DELETED_FLOW,
  GET_DELETED_LOCATION,
  GET_DELETED_QUESTIONNAIRE,
  GET_DELETED_TAX,
  GET_DELETED_USER
} from '../../redux/core/archive/archiveTypes';

import { NO_ID } from '../form';
import { EMPTY_GUID } from '../gen';
import { getCachedList } from '../storeExtensions';

const getBoundActions = (dispatch) =>
  bindActionCreators(
    {
      getUserDispatch: getUser,
      getFlowDispatch: getFlow,
      getLocationDispatch: getLocation,
      getDrugDispatch: (id, actionType) =>
        getRegistryItem(REGISTRY_THERAPY_DRUGS, id, actionType),
      getCategoryDispatch: getCategory,
      getQuestionnaireDispatch: getQuestionnaire,
      getTaxDispatch: getTax
    },
    dispatch
  );

// TODO: actionBinder is here just for testing purposes, we should think of an alternative
export const getSubEntityKeysWithDispatch = (
  dispatch,
  actionBinder = getBoundActions
) => {
  const {
    getUserDispatch,
    getFlowDispatch,
    getLocationDispatch,
    getDrugDispatch,
    getCategoryDispatch,
    getQuestionnaireDispatch,
    getTaxDispatch
  } = actionBinder(dispatch);

  const subEntityKeysWithDispatch = _.cloneDeep(getSubEntityKeysConfig());

  _.forEach(subEntityKeysWithDispatch, (subEntityKeyWithDispatch) => {
    switch (subEntityKeyWithDispatch.type) {
      case SUB_ENTITY_TYPE_USER:
        subEntityKeyWithDispatch.fetchDispatchFunction = (id) =>
          getUserDispatch(id, GET_DELETED_USER);
        break;
      case SUB_ENTITY_TYPE_LOCATION:
        subEntityKeyWithDispatch.fetchDispatchFunction = (id) =>
          getLocationDispatch(id, GET_DELETED_LOCATION);
        break;
      case SUB_ENTITY_TYPE_FLOW:
        subEntityKeyWithDispatch.fetchDispatchFunction = (id) =>
          getFlowDispatch(id, GET_DELETED_FLOW);
        break;
      case SUB_ENTITY_TYPE_DRUG:
        subEntityKeyWithDispatch.fetchDispatchFunction = (id) =>
          getDrugDispatch(id, GET_DELETED_DRUG);
        break;
      case SUB_ENTITY_TYPE_CATEGORY:
        subEntityKeyWithDispatch.fetchDispatchFunction = (id) =>
          getCategoryDispatch(id, GET_DELETED_CATEGORY);
        break;
      case SUB_ENTITY_TYPE_WIDGET_QUESTIONNAIRE:
        subEntityKeyWithDispatch.fetchDispatchFunction = (widgetType) => {
          if (!_.includes(widgetType, WIDGET_TYPE_QUESTIONNAIRE)) {
            return;
          }

          const [, questionnaireId, questionnaireVersion] = widgetType.split(
            '/'
          );

          return getQuestionnaireDispatch(
            questionnaireId,
            questionnaireVersion,
            GET_DELETED_QUESTIONNAIRE
          );
        };
        break;
      case SUB_ENTITY_TYPE_TAX:
        subEntityKeyWithDispatch.fetchDispatchFunction = (id) =>
          getTaxDispatch(id, GET_DELETED_TAX);
        break;
      default:
        break;
    }
  });

  return subEntityKeysWithDispatch;
};

// TODO: Figure out how to test this in a way that private function can be mocked
export const fetchDeletedSubEntities = (data, { dispatch }, actionType) => {
  let entities;
  const propsToCheck = getSubEntityKeysWithDispatch(dispatch);

  if (_.isPlainObject(data)) {
    entities = [data];
  } else if (_.isArray(data)) {
    const firstItem = _.first(data);

    if (_.has(firstItem, 'encounters')) {
      entities = _.flatten(
        _.map(data, (bucket) => _.getNonEmpty(bucket, 'encounters', []))
      );
    } else {
      entities = data;
    }
  } else {
    return;
  }

  _.forEach(propsToCheck, (propToCheck) => {
    _.forEach(entities, (entity) => {
      const propId = _.getNonEmpty(entity, propToCheck.key, NO_ID);
      let allowedActionType = true;
      const allowedActionTypeNamespaceList = _.getNonEmpty(
        propToCheck,
        'allowedActionTypeNamespaceList',
        []
      );

      if (!_.isEmpty(allowedActionTypeNamespaceList)) {
        const actionTypeNamespace = _.first(_.split(actionType, '/'));

        allowedActionType = _.includes(
          allowedActionTypeNamespaceList,
          actionTypeNamespace
        );
      }

      if (propId === NO_ID) {
        return;
      }

      const cacheList = getCachedList(propToCheck.storePath);
      const archiveList = getCachedList(propToCheck.storePath, true);

      const cacheListIds = [...cacheList, ...archiveList].map(
        (item) => item.id
      );

      if (_.isArray(propId)) {
        _.forEach(propId, (singlePropId) => {
          const includesId = _.includes(cacheListIds, singlePropId);

          if (!includesId && singlePropId !== EMPTY_GUID && allowedActionType) {
            propToCheck.fetchDispatchFunction(singlePropId);
          }
        });
      } else {
        const includesId = _.includes(cacheListIds, propId);

        if (!includesId && propId !== EMPTY_GUID && allowedActionType) {
          propToCheck.fetchDispatchFunction(propId);
        }
      }
    });
  });
};
