import {
  getPatientAllergyListUrl,
  getPatientConcomitantDrugListUrl,
  getPatientImplantListUrl,
  getPatientMedicalConditionListUrl,
  getPatientOperationListUrl,
  getPatientVaccinationListUrl
} from '../../../utils/api/apiUrlUtils';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import {
  POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST,
  POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST,
  POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST,
  POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST,
  POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST,
  POLL_REGISTRY_ANAMNESIS_OPERATION_LIST
} from '../../core/cache/cacheTypes';

export function* anamnesisMedicalConditionPollSaga() {
  yield pollGenerator(
    POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST,
    getPatientMedicalConditionListUrl
  );
}

export function* anamnesisConcomitantDrugPollSaga() {
  yield pollGenerator(
    POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST,
    getPatientConcomitantDrugListUrl
  );
}

export function* anamnesisAllergyPollSaga() {
  yield pollGenerator(
    POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST,
    getPatientAllergyListUrl
  );
}

export function* anamnesisVaccinationPollSaga() {
  yield pollGenerator(
    POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST,
    getPatientVaccinationListUrl
  );
}

export function* anamnesisOperationPollSaga() {
  yield pollGenerator(
    POLL_REGISTRY_ANAMNESIS_OPERATION_LIST,
    getPatientOperationListUrl
  );
}

export function* anamnesisImplantPollSaga() {
  yield pollGenerator(
    POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST,
    getPatientImplantListUrl
  );
}
