import { all } from 'redux-saga/effects';

import { getOutboundDocumentUrl } from '../../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../../utils/constants/networkConstants';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import { pollOutboundTemplatesListSagaWatcher } from './template/outboundDocumentTemplateSagas';

import {
  ADD_OUTBOUND_TEMPLATE_SUCCESS,
  POLL_OUTBOUND_TEMPLATES_LIST,
  REMOVE_OUTBOUND_TEMPLATE_SUCCESS,
  UPDATE_OUTBOUND_TEMPLATE_SUCCESS
} from './template/outboundDocumentTemplateTypes';

export function* pollOutboundTemplatesSaga() {
  const configGetter = () => ({
    params: { limit: REQUEST_PAGINATION_MAX_LIMIT, page: 1, type: 'templates' }
  });

  yield pollGenerator(POLL_OUTBOUND_TEMPLATES_LIST, getOutboundDocumentUrl, {
    retriggerActions: [
      ADD_OUTBOUND_TEMPLATE_SUCCESS,
      UPDATE_OUTBOUND_TEMPLATE_SUCCESS,
      REMOVE_OUTBOUND_TEMPLATE_SUCCESS
    ],
    configGetter
  });
}

export function* outboundSagaWatcher() {
  yield all([pollOutboundTemplatesListSagaWatcher()]);
}
