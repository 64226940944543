import { createActionTypeArray } from '../../common/actionTypes';

export const GET_CASH_REGISTER_LOG_LIST =
  'cashRegister/GET_CASH_REGISTER_LOG_LIST';
export const SET_CASH_REGISTER_LIST_FILTER =
  'cashRegister/SET_CASH_REGISTER_LIST_FILTER';
export const SET_CASH_REGISTER_LIST_PAGINATION =
  'cashRegister/SET_CASH_REGISTER_LIST_PAGINATION';
export const GET_CASH_REGISTER_LOG_DETAIL =
  'cashRegister/GET_CASH_REGISTER_LOG_DETAIL';
export const GET_CASH_REGISTER_BILL_LIST =
  'cashRegister/GET_CASH_REGISTER_BILL_LIST';
export const CLEAR_CASH_REGISTER_DETAIL =
  'cashRegister/CLEAR_CASH_REGISTER_DETAIL';
export const GET_CASH_REGISTER_NEXT_COUNTERS =
  'cashRegister/GET_CASH_REGISTER_NEXT_COUNTERS';
export const INCREMENT_CASH_REGISTER_COUNTERS =
  'cashRegister/INCREMENT_CASH_REGISTER_COUNTERS';
export const ADD_CASH_REGISTER_LOG = 'cashRegister/ADD_CASH_REGISTER_LOG';
export const UPDATE_CASH_REGISTER_LOG = 'cashRegister/UPDATE_CASH_REGISTER_LOG';
export const DECREMENT_CASH_REGISTER_COUNTERS =
  'cashRegister/DECREMENT_CASH_REGISTER_COUNTERS';
export const REMOVE_CASH_REGISTER_LOG = 'cashRegister/REMOVE_CASH_REGISTER_LOG';
export const SET_CASH_REGISTER_LATEST_LOG_DATA =
  'cashRegister/SET_CASH_REGISTER_LATEST_LOG_DATA';
export const CLEAR_CASH_REGISTER_LATEST_LOG_DATA =
  'cashRegister/CLEAR_CASH_REGISTER_LATEST_LOG_DATA';

export const [
  GET_CASH_REGISTER_LOG_LIST_IN_PROGRESS,
  GET_CASH_REGISTER_LOG_LIST_SUCCESS,
  GET_CASH_REGISTER_LOG_LIST_FAILURE
] = createActionTypeArray(GET_CASH_REGISTER_LOG_LIST);

export const [
  GET_CASH_REGISTER_LOG_DETAIL_IN_PROGRESS,
  GET_CASH_REGISTER_LOG_DETAIL_SUCCESS,
  GET_CASH_REGISTER_LOG_DETAIL_FAILURE
] = createActionTypeArray(GET_CASH_REGISTER_LOG_DETAIL);

export const [
  ADD_CASH_REGISTER_LOG_IN_PROGRESS,
  ADD_CASH_REGISTER_LOG_SUCCESS,
  ADD_CASH_REGISTER_LOG_FAILURE
] = createActionTypeArray(ADD_CASH_REGISTER_LOG);

export const [
  UPDATE_CASH_REGISTER_LOG_IN_PROGRESS,
  UPDATE_CASH_REGISTER_LOG_SUCCESS,
  UPDATE_CASH_REGISTER_LOG_FAILURE
] = createActionTypeArray(UPDATE_CASH_REGISTER_LOG);

export const [
  REMOVE_CASH_REGISTER_LOG_IN_PROGRESS,
  REMOVE_CASH_REGISTER_LOG_SUCCESS,
  REMOVE_CASH_REGISTER_LOG_FAILURE
] = createActionTypeArray(REMOVE_CASH_REGISTER_LOG);
