import _ from 'lodash';
import { createSelector } from 'reselect';
import semver from 'semver';

import { ROLE_DOCTOR } from '../../../utils/constants/userConstants';
import { isUserDoctor } from '../../../utils/filters/user-filters';
import { mapAllPremisesToDevices } from '../../../utils/mappers/cleaned/billing/billingMappers';
import {
  mapTherapyNamePreview,
  therapyMapper
} from '../../../utils/mappers/cleaned/therapy-mappers';
import { appendColorMapper } from '../../../utils/mappers/color-mappers';
import {
  dropdownGroupedItemMapper,
  filterAll
} from '../../../utils/mappers/dropdown-mappers';
import moshiConfig from '../../../utils/moshiConfig';
import { selectProps } from '../../../utils/redux/selectorHelpers';

import { selectOrganizationIdFromToken } from '../../auth/authSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';
import {
  selectDeletedDrugList,
  selectDeletedFlowList,
  selectDeletedUserList,
  selectDeletedCategoryList,
  selectDeletedLocationList,
  selectDeletedPaymentTypeList
} from '../archive/archiveSelectors';

export const selectCacheStore = (state) => state.cache;

// MAIN SELECTORS
export const selectIsPolling = createSelector([selectCacheStore], (cache) =>
  _.getNonEmpty(cache, 'isPolling', false)
);

export const selectDidPoll = createSelector([selectCacheStore], (cache) =>
  _.getNonEmpty(cache, 'didPoll', false)
);

// VERSION SELECTORS
export const selectCachedVersions = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'versions.data', {})
);

export const selectCachedLatestClientVersion = createSelector(
  [selectCachedVersions],
  (versions) => _.getNonEmpty(versions, 'dashboard', null)
);

export const selectIsNewVersionAvailable = createSelector(
  [selectCachedLatestClientVersion],
  (latestClientVersion) => {
    const currentVersion = _.getNonEmpty(moshiConfig, 'version');

    if (
      _.isEmptySafe(semver.valid(latestClientVersion)) ||
      _.isEmptySafe(semver.valid(currentVersion))
    ) {
      return false;
    }

    return semver.gt(latestClientVersion, moshiConfig.version);
  }
);

export const selectCachedLatestApiVersion = createSelector(
  [selectCachedVersions],
  (versions) => _.getNonEmpty(versions, 'api', null)
);

// ORG SELECTORS
export const selectCachedOrganization = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'organization.data', {})
);

export const selectCachedOrganizationId = createSelector(
  [selectCachedOrganization, selectOrganizationIdFromToken],
  (organization, organizationIdFromToken) =>
    _.getNonEmpty(organization, 'id', organizationIdFromToken)
);

// LOCATION SELECTORS
export const selectCachedLocationList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'location.list', [])
);

export const selectCachedLocationListWithFilterAll = createSelector(
  [selectCachedLocationList],
  (locationList) => [filterAll(), ...locationList]
);

export const selectCachedAndDeletedLocationList = createSelector(
  [selectCachedLocationList, selectDeletedLocationList],
  (cachedList, deletedList) => [...cachedList, ...deletedList]
);

export const selectCachedOrDeletedLocationById = createSelector(
  [selectCachedAndDeletedLocationList, selectProps],
  (locations, locationId) => _.find(locations, { id: locationId })
);

// DEVICE SELECTORS
export const selectCachedDeviceList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'devices.list', [])
);

export const selectCachedDeviceListWithLocationName = createSelector(
  [selectCachedDeviceList, selectCachedLocationList],
  (deviceList, locationList) =>
    _.map(deviceList, (deviceItem) => {
      const foundLocation = _.find(locationList, {
        id: deviceItem.locationID
      });

      deviceItem.locationName = foundLocation
        ? foundLocation.name
        : 'common:unknown';

      return deviceItem;
    })
);

// DRUG SELECTORS
export const selectCachedDrugList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'drug.list', [])
);

export const selectDidPollCachedDrugList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'drug.didPoll', false)
);

export const selectCachedAndDeletedDrugList = createSelector(
  [selectCachedDrugList, selectDeletedDrugList],
  (cachedDrugList, deletedDrugList) => [...cachedDrugList, ...deletedDrugList]
);

// THERAPY SELECTORS
export const selectCachedTherapyList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'therapy.list', [])
);

export const selectDidPollCachedTherapyList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'therapy.didPoll', false)
);

export const selectCachedTherapyListWithDrug = createSelector(
  [selectCachedTherapyList, selectCachedDrugList],
  (therapyList, drugList) =>
    _.map(therapyList, (therapy) => {
      const foundDrug = _.find(drugList, { id: therapy.drugID });

      if (!_.isEmpty(foundDrug)) {
        therapy.drug = foundDrug;
      }

      return therapy;
    })
);

export const selectCachedTherapyListWithNamePreview = createSelector(
  [selectCachedTherapyListWithDrug],
  (therapyList) => _.map(therapyList, mapTherapyNamePreview)
);

export const selectCachedTherapyListWithMappedData = createSelector(
  [selectCachedTherapyListWithNamePreview],
  (therapyList) => _.map(therapyList, therapyMapper)
);

// FLOW SELECTORS
export const selectCachedFlowList = createSelector(
  [selectCacheStore],
  (cache) => _.get(cache, 'flow.list', [])
);

export const selectCachedAndDeletedFlowList = createSelector(
  [selectCachedFlowList, selectDeletedFlowList],
  (cachedFlows, deletedFlows) => [...cachedFlows, ...deletedFlows]
);

export const selectCachedFlowById = createSelector(
  [selectCachedAndDeletedFlowList, selectProps],
  (cachedAndDeletedFlowList, flowId) =>
    _.findDefault(cachedAndDeletedFlowList, { id: flowId }, {})
);

// USER SELECTORS
export const selectCachedUserList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'user.list', [])
);

export const selectCachedUserIdList = createSelector(
  [selectCachedUserList],
  (userList) => _.map(userList, 'id')
);

export const selectCachedAndDeletedUserList = createSelector(
  [selectCachedUserList, selectDeletedUserList],
  (cachedUsers, deletedUsers) => [...deletedUsers, ...cachedUsers]
);

export const selectCachedUserById = createSelector(
  [selectCachedUserList, selectProps],
  (users, userId) => _.find(users, { id: userId })
);

export const selectCachedOrDeletedUserById = createSelector(
  [selectCachedAndDeletedUserList, selectProps],
  (users, userId) => _.find(users, { id: userId })
);

export const selectCachedOrDeletedUserNameById = createSelector(
  [selectCachedAndDeletedUserList, selectProps],
  (users, userId) => {
    const user = _.find(users, { id: userId });
    const firstName = _.getNonEmpty(user, 'firstName', '');
    const lastName = _.getNonEmpty(user, 'lastName', '');

    const fullName = `${firstName} ${lastName}`;

    return _.trim(fullName);
  }
);

export const selectCachedOrDeletedUserNamesByIds = createSelector(
  [selectCachedAndDeletedUserList, selectProps],
  (users, userIds) => {
    const userNames = [];

    userIds.forEach((userId) => {
      const user = _.find(users, { id: userId });
      const firstName = _.getNonEmpty(user, 'firstName', '');
      const lastName = _.getNonEmpty(user, 'lastName', '');

      const fullName = `${firstName} ${lastName}`;

      userNames.push(_.trim(fullName));
    });

    return userNames.join(', ');
  }
);

export const selectCachedDoctorList = createSelector(
  [selectCachedUserList],
  (userList) => _.filter(userList, isUserDoctor)
);

export const selectCachedAndDeletedDoctorList = createSelector(
  [selectCachedAndDeletedUserList],
  (userList) => _.filter(userList, isUserDoctor)
);

export const selectCachedAndDeletedDoctorIdList = createSelector(
  [selectCachedAndDeletedDoctorList],
  (doctorsList) => _.map(doctorsList, 'id')
);

const filterUserByLocationAndRole = (locationId, role) => (user) => {
  const doctorLocationRoles = _.find(user.locationsRoles, {
    locationID: locationId
  });

  if (_.isEmpty(doctorLocationRoles)) {
    return false;
  }

  return _.includes(doctorLocationRoles.roles, role);
};

export const selectCachedDoctorListByCurrentLocation = createSelector(
  [selectCachedDoctorList, selectCurrentLocationId, selectProps],
  (userList, currentLocationId) =>
    _.filter(
      userList,
      filterUserByLocationAndRole(currentLocationId, ROLE_DOCTOR)
    )
);

export const selectCachedDoctorIdListByCurrentLocation = createSelector(
  [selectCachedDoctorListByCurrentLocation],
  (doctorsList) => _.map(doctorsList, 'id')
);

export const selectCachedAndDeletedDoctorListByCurrentLocation = createSelector(
  [selectCachedAndDeletedUserList, selectCurrentLocationId, selectProps],
  (userList, currentLocationId) =>
    _.filter(
      userList,
      filterUserByLocationAndRole(currentLocationId, ROLE_DOCTOR)
    )
);

// TAX SELECTORS
export const selectTaxList = createSelector([selectCacheStore], (cache) =>
  _.getNonEmpty(cache, 'tax.list', [])
);

// BILLING PAYMENT TYPES SELECTORS
export const selectCachedCustomPaymentTypesList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'paymentType.list', [])
);

export const selectCachedOrDeletedCustomPaymentTypesList = createSelector(
  [selectCachedCustomPaymentTypesList, selectDeletedPaymentTypeList],
  (cachedTypes, deletedTypes) =>
    _.uniqBy(_.concat(cachedTypes, deletedTypes), 'id')
);

export const selectCachedCustomPaymentTypesDidPoll = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'paymentType.didPoll', false)
);

export const selectCachedCustomPaymentTypeById = createSelector(
  [selectCachedCustomPaymentTypesList, selectProps],
  (paymentTypes, paymentTypeId) => {
    if (_.isEmpty(paymentTypeId)) {
      return null;
    }

    return _.find(paymentTypes, { id: paymentTypeId });
  }
);

// BILLING CATEGORY SELECTORS
export const selectCachedBillingCategoryList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'billingCategory.list', [])
);

// BILLING ITEM SELECTORS
export const selectCachedBillingItemList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'billingItem.list', [])
);
export const selectCachedBillingItemListForItemPicker = createSelector(
  [selectCachedBillingItemList, selectCachedBillingCategoryList],
  (itemList, categoryList) =>
    dropdownGroupedItemMapper(itemList, categoryList, 'categoryID')
);

// BILLING UNIT SELECTORS
export const selectCachedBillingUnitStore = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'billingUnit', {})
);

export const selectCachedBillingUnitList = createSelector(
  [selectCachedBillingUnitStore],
  (billingUnitStore) => _.getNonEmpty(billingUnitStore, 'list', [])
);

export const selectCachedFiscalizedBillingUnitList = createSelector(
  [selectCachedBillingUnitList],
  (billingUnitList) =>
    _.filter(billingUnitList, (unit) =>
      _.get(unit, 'furs.electronicDevices', false)
    )
);

export const selectCachedBillingUnitById = createSelector(
  [selectCachedBillingUnitList, selectProps],
  (billingUnitList, billingUnitId) =>
    _.findDefault(billingUnitList, { id: billingUnitId }, {})
);

export const selectCachedBillingUnitDidPoll = createSelector(
  [selectCachedBillingUnitStore],
  (billingUnitStore) => _.getNonEmpty(billingUnitStore, 'didPoll', false)
);

export const selectCachedBillingUnitPremisesList = createSelector(
  [selectCachedBillingUnitStore],
  (billingUnitStore) => _.getNonEmpty(billingUnitStore, 'premisesList', {})
);

export const selectCachedElectronicDevices = createSelector(
  [selectCachedBillingUnitPremisesList],
  (premisesList) => mapAllPremisesToDevices(premisesList)
);

export const selectCachedElectronicDeviceById = createSelector(
  [selectCachedElectronicDevices, selectProps],
  (electronicDevices, deviceId) => {
    const devices = _.flatMap(electronicDevices);

    return _.findDefault(devices, { id: deviceId }, {});
  }
);

export const selectCachedBillingUnitPremisesDidPoll = createSelector(
  [selectCachedBillingUnitStore],
  (billingUnitStore) =>
    _.getNonEmpty(billingUnitStore, 'didPollPremises', false)
);

export const selectCachedBillingUnitDidPollAll = createSelector(
  [selectCachedBillingUnitDidPoll, selectCachedBillingUnitPremisesDidPoll],
  (didPoll, didPollPremises) => didPoll && didPollPremises
);

// PATIENT SCHEMA SELECTORS
export const selectCachedPatientSchema = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'patientSchema.data', {})
);

export const selectPatientTableSchema = createSelector(
  [selectCachedPatientSchema],
  (schema) => _.getNonEmpty(schema, 'tableSchema', [])
);

export const selectPatientFormSchema = createSelector(
  [selectCachedPatientSchema],
  (patientSchema) => _.getNonEmpty(patientSchema, 'jsonSchema', {})
);

export const selectPatientFormUiSchema = createSelector(
  [selectCachedPatientSchema],
  (patientSchema) => _.getNonEmpty(patientSchema, 'uiSchema', {})
);

// CATEGORY SELECTORS
export const selectCacheCategoriesList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'category.list', [])
);

export const selectCacheCategoriesIdList = createSelector(
  [selectCacheCategoriesList],
  (categories) => _.sortBy(_.map(categories, 'id'))
);

export const selectCachedAndDeletedCategoriesList = createSelector(
  [selectCacheCategoriesList, selectDeletedCategoryList],
  (cachedCategories, deletedCategories) => [
    ...cachedCategories,
    ...deletedCategories
  ]
);

export const selectCachedCategoriesById = createSelector(
  [selectCachedAndDeletedCategoriesList, selectProps],
  (categories, categoryId) => _.find(categories, { id: categoryId })
);

// RESOURCE SELECTORS
export const selectCacheResourcesList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'resource.list', [])
);

export const selectCachedResourceById = createSelector(
  [selectCacheResourcesList, selectProps],
  (resources, resourceId) => _.find(resources, { id: resourceId })
);

// WAITING LIST SELECTORS
export const selectCachedWaitingListStore = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'waitingList', {})
);

export const selectCachedWaitingListConfig = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) => _.getNonEmpty(waitingListStore, 'config', {})
);

export const selectDidPollCachedWaitingListConfig = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) => _.getNonEmpty(waitingListStore, 'didPollConfig', {})
);

export const selectCachedWaitingListConfigBuckets = createSelector(
  [selectCachedWaitingListConfig],
  (waitingListConfig) => _.getNonEmpty(waitingListConfig, 'buckets', {})
);

export const selectCachedWaitingListBucketsByName = createSelector(
  [selectCachedWaitingListConfigBuckets, selectProps],
  (waitingListBuckets, bucketName) =>
    _.findDefault(waitingListBuckets, { name: bucketName }, {})
);

export const selectCachedWaitingListBucketIdByName = createSelector(
  [selectCachedWaitingListBucketsByName],
  (waitingListBucket) => _.getNonEmpty(waitingListBucket, 'id', [])
);

export const selectCachedWaitingListEncounters = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) => _.getNonEmpty(waitingListStore, 'list', [])
);

export const selectDidPollCachedWaitingListEncounters = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) => _.getNonEmpty(waitingListStore, 'didPoll', false)
);

export const selectCachedWaitingListMoveAction = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) => _.getNonEmpty(waitingListStore, 'moveAction', {})
);

export const selectCachedWaitingListUnreadComments = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) => _.getNonEmpty(waitingListStore, 'unreadCommentList', [])
);

export const selectCachedWaitingListUpdatedEncounters = createSelector(
  [selectCachedWaitingListStore],
  (waitingListStore) =>
    _.getNonEmpty(waitingListStore, 'updatedEncountersList', [])
);

export const selectDidPollAllCachedWaitingListData = createSelector(
  [
    selectDidPollCachedWaitingListConfig,
    selectDidPollCachedWaitingListEncounters
  ],
  (didPollConfig, didPollList) => didPollConfig && didPollList
);

// TODAY'S EVENT LIST SELECTORS
export const selectCachedUpcomingEventsList = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'todaysEvents.list', [])
);

export const selectCachedUpcomingEventsListWithMappedData = createSelector(
  [
    selectCachedUpcomingEventsList,
    selectCachedAndDeletedUserList,
    selectCachedCategoriesById
  ],
  (upcomingEvents, users, categories) =>
    _.map(upcomingEvents, (event) => {
      const mappedEvent = _.cloneDeep(event);
      const foundCategory = _.find(categories, { id: event.categoryID });
      // TODO remove doctorID when deprecated
      let foundDoctor = _.find(users, { id: event.doctorID });

      if (!_.isEmpty(_.get(foundDoctor, 'id'))) {
        foundDoctor = appendColorMapper(foundDoctor);
      }

      mappedEvent.doctor = foundDoctor;
      mappedEvent.categoryName = _.get(foundCategory, 'name', '');
      mappedEvent.doctors = _.map(event.doctorIDs, (doctorId) => {
        let doctor = _.find(users, { id: doctorId });

        if (!_.isEmpty(_.get(doctor, 'id'))) {
          doctor = appendColorMapper(doctor);
        }

        return doctor;
      });

      return mappedEvent;
    })
);

// FORMS
export const selectCachedForm = createSelector([selectCacheStore], (cache) =>
  _.getNonEmpty(cache, 'form', {})
);

export const selectCachedFormList = createSelector([selectCachedForm], (form) =>
  _.getNonEmpty(form, 'list', [])
);

export const selectCachedFormById = createSelector(
  [selectCachedFormList, selectProps],
  (formList, formId) => _.findDefault(formList, { id: formId }, {})
);

// TODO DOCUMENTS REWORK: remove cached document and questionnaire selectors, using cachedForms now
export const selectCachedDocument = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'document', {})
);

export const selectCachedDocumentList = createSelector(
  [selectCachedDocument],
  (document) => _.getNonEmpty(document, 'list', [])
);

export const selectCachedQuestionnaire = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'questionnaire', {})
);

export const selectCachedQuestionnaireList = createSelector(
  [selectCachedQuestionnaire],
  (questionnaire) => _.getNonEmpty(questionnaire, 'list', [])
);

export const selectCachedTags = createSelector([selectCacheStore], (cache) =>
  _.getNonEmpty(cache, 'tag', {})
);

export const selectCachedTagList = createSelector([selectCachedTags], (tags) =>
  _.getNonEmpty(tags, 'list', [])
);

export const selectCachedBookingTemplate = createSelector(
  [selectCacheStore],
  (cache) => _.getNonEmpty(cache, 'bookingTemplate', {})
);

export const selectCachedBookingTemplateList = createSelector(
  [selectCachedBookingTemplate],
  (bookingTemplate) => _.getNonEmpty(bookingTemplate, 'list', [])
);
