import { take, race, call, select } from 'redux-saga/effects';

import { getFormTemplateUrl } from '../../../utils/api/apiUrlUtils';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import { STOP_POLLING } from '../../core/cache/cacheTypes';
import {
  POLL_FORM_TEMPLATE_LIST,
  START_FORM_TEMPLATE_LIST_POLLING,
  STOP_FORM_TEMPLATE_LIST_POLLING,
  REMOVE_FORM_TEMPLATE_SUCCESS,
  UPDATE_FORM_TEMPLATE_SUCCESS,
  ADD_FORM_TEMPLATE_SUCCESS
} from './formTemplateTypes';

import { selectFormTemplatePagination } from './formTemplateSelectors';

function* pollFormTemplatesListSaga() {
  function* configGetter() {
    const state = yield select();
    const { limit, page } = selectFormTemplatePagination(state);

    return {
      params: {
        limit,
        page
      }
    };
  }

  yield pollGenerator(POLL_FORM_TEMPLATE_LIST, getFormTemplateUrl, {
    retriggerActions: [
      ADD_FORM_TEMPLATE_SUCCESS,
      UPDATE_FORM_TEMPLATE_SUCCESS,
      REMOVE_FORM_TEMPLATE_SUCCESS
    ],
    configGetter
  });
}

export function* pollFormTemplatesListSagaWatcher() {
  while (true) {
    yield take(START_FORM_TEMPLATE_LIST_POLLING);
    yield race([
      call(pollFormTemplatesListSaga),
      take(STOP_POLLING),
      take(STOP_FORM_TEMPLATE_LIST_POLLING)
    ]);
  }
}
