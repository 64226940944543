import { useFormikContext } from 'formik';

import { EVENT_ENTER_KEY, EVENT_KEY_UP } from '../event';
import { useDocumentEvent } from './useDocumentEvent';

/**
 * A hook which wraps the useDocumentEvent and listens for enter key and
 * fires the callback only if the formik is valid/dirty and not submitting
 * (to prevent multiple enter key press submitting);
 * @param onEnterKeyPressed
 * @returns {[(value: (((prevState: boolean) => boolean) | boolean)) => void]}
 */
export const useEnterKeyInFormik = (onEnterKeyPressed) => {
  const { dirty, isSubmitting, isValid } = useFormikContext();

  const [shouldPauseState, setShouldPause] = useDocumentEvent(
    EVENT_KEY_UP,
    EVENT_ENTER_KEY,
    () => {
      if (dirty && !isSubmitting && isValid && !shouldPauseState) {
        onEnterKeyPressed();
      }
    }
  );

  return setShouldPause;
};
