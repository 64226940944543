import attendingPhysician from './attendingPhysician';
import date from './date';
import flow from './flow';
import reasonForEncounter from './reasonForEncounter';
import typeOfEncounter from './typeOfEncounter';

export default {
  groupId: 'administrativeData',
  groupName: 'encounter:widget.administrativeData.title',
  widgets: {
    [date.id]: date,
    [attendingPhysician.id]: attendingPhysician,
    [typeOfEncounter.id]: typeOfEncounter,
    [reasonForEncounter.id]: reasonForEncounter,
    [flow.id]: flow
  }
};
