import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { isValidDate } from '../../../../utils/date';
import { useMount } from '../../../../utils/hooks';

import MoshiDatePicker from '../../date/MoshiDatePicker';

export const CustomMoshiDatepickerWidgetComponent = ({
  value,
  disabled,
  onChange,
  schema
}) => {
  const { t: translate } = useTranslation();
  const allowedTypes = useMemo(() => _.getNonEmpty(schema, 'type', ['null']), [
    schema
  ]);
  const hasDefault = useMemo(
    () => _.getNonEmpty(schema, 'x-default-today', false),
    [schema]
  );

  useMount(() => {
    if (hasDefault) {
      const newValue = moment(
        _.isString(value) ? value : undefined
      ).toISOString();

      // Somehow this doesnt work if it not in another event loop
      setTimeout(() => {
        onChange(newValue);
      }, 0);
    }
  });

  const handleOnChange = useCallback(
    (date) => {
      const safeNewValue = isValidDate(date) ? date : undefined;
      const isoDate = moment(safeNewValue).toISOString();

      onChange(isoDate);
    },
    [onChange]
  );

  return (
    <MoshiDatePicker
      value={value}
      disabled={disabled}
      onChange={handleOnChange}
      allowEmpty
      clearIcon={
        _.includes(allowedTypes, 'null') ? translate('common:clear') : undefined
      }
    />
  );
};

CustomMoshiDatepickerWidgetComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool,
  schema: PropTypes.shape({})
};

CustomMoshiDatepickerWidgetComponent.defaultProps = {
  value: null,
  disabled: false,
  schema: {}
};

export default CustomMoshiDatepickerWidgetComponent;
