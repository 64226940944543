import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  EVENT_TYPE_ENCOUNTER,
  EVENT_TYPE_QUEUE
} from '../../../../utils/constants/calendarConstants';

import Icon from '../../Icon';
import Visible from '../../layout/Visible';

export const MoshiAutoSuggestEventResultCard = ({ data }) => {
  const { t: translate } = useTranslation();
  const hasTitle = !_.isEmpty(data.title);
  const isEncounterType = data.eventType === EVENT_TYPE_ENCOUNTER;
  const isQueueType = data.eventType === EVENT_TYPE_QUEUE;
  const from = _.getNonEmpty(data, 'from', null);
  const to = _.getNonEmpty(data, 'to', null);
  const location = _.getNonEmpty(data, 'location', {});

  return (
    <div className={'event-auto-suggest-result-card'}>
      <div className={'icon-container'}>
        <Icon name={'calendar'} />
      </div>
      <div className={'details-container'}>
        <span className={'date-time-location'}>
          {translate('date:dateTimeAndLocation', {
            date: from,
            fromTime: from,
            toTime: to,
            location: location.name
          })}
        </span>

        <Visible visible={hasTitle && !isEncounterType && !isQueueType}>
          <span className={'name-title'}>{data.title}</span>
        </Visible>

        <span className={'name-title'}>{data.name}</span>
      </div>
    </div>
  );
};

MoshiAutoSuggestEventResultCard.propTypes = {
  data: PropTypes.shape().isRequired
};

export default MoshiAutoSuggestEventResultCard;
