export default {
  id: 'administrativeData/TYPE_OF_ENCOUNTER/v1',
  formSchema: {
    title: 'encounter:widget.administrativeData.typeOfEncounter.title',
    type: 'object',
    required: ['id001'],
    properties: {
      id001: {
        enum: ['id002', 'id003', 'id004'],
        enumNames: [
          'encounter:widget.administrativeData.typeOfEncounter.officeVisit',
          'encounter:widget.administrativeData.typeOfEncounter.telemedicalConsultation',
          'encounter:widget.administrativeData.typeOfEncounter.HomeVisit'
        ],
        type: 'string',
        title: 'encounter:widget.administrativeData.typeOfEncounter.title'
      }
    }
  },
  uiSchema: {
    id001: { 'ui:widget': 'customDropdownWidget' },
    'x-customClass': 'admin-data-widget',
    'x-cancelBtnSize': 'sm',
    'x-saveBtnSize': 'sm'
  }
};
