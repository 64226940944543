import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getSafeTranslation } from '../../../../../utils/language';

import { Label } from '../../../../reactstrap';
import Visible from '../../../layout/Visible';

const WidgetFieldTemplateBase = ({ title, schema, children }) => (
  <div>
    <Visible visible={!_.isEmpty(title)}>
      <Label className={'widget-title-wrapper'}>
        <Visible
          visible={_.has(schema, 'iconClass') && !_.isEmpty(schema.iconClass)}
        >
          {() => (
            <div className={'widget-icon-wrapper'}>
              <i className={`${schema.iconClass} icon-size-md widget-icon`} />
            </div>
          )}
        </Visible>
        <span className={'widget-title'}>{getSafeTranslation(title)}</span>
      </Label>
    </Visible>
    <div className={'fields-wrapper'}>{children}</div>
  </div>
);

WidgetFieldTemplateBase.propTypes = {
  schema: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string
};

WidgetFieldTemplateBase.defaultProps = {
  title: null,
  disabled: false
};

export default WidgetFieldTemplateBase;
