import axios from 'axios';
import _ from 'lodash';
import { takeEvery, select, put, all } from 'redux-saga/effects';

import { getApiWidgetTypeConfig, getEncounterUrl } from '../../../../utils/api';
import { apiCall } from '../../../../utils/redux/sagaUtils';

import { processWidgetData } from './indexCalculationWidgetSagas';
import { handleRefreshData } from './widgetDependencyDataSagas';

import {
  UPDATE_WIDGET_TRIGGER,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCESS,
  UPDATE_WIDGET_SANITIZED,
  UPDATE_WIDGET_UPDATED,
  UPDATE_WIDGET_LIST_UPDATE,
  CLEAR_WIDGET_FORM_DATA,
  REMOVE_LOCAL_WIDGET
} from '../widgetTypes';

export function* updateWidgetApiRequest(action) {
  const { encounterId, widget } = action;
  const state = yield select();
  const encounterUrl = getEncounterUrl(() => state, encounterId);
  const axiosConfig = getApiWidgetTypeConfig(widget.widgetType);

  yield handleRefreshData(widget.widgetType);
  const processedData = yield processWidgetData(widget.widgetType, widget.data);

  if (_.isEmptySafe(processedData)) return;

  yield apiCall(
    UPDATE_WIDGET,
    axios.put,
    [`${encounterUrl}/widgets/${widget.id}`, processedData, axiosConfig],
    { processedData }
  );
}

export function* updateWidgetSanitization(action) {
  const {
    data: savedWidget,
    additionalData: { processedData }
  } = action;

  const newWidget = {
    ...savedWidget,
    data: processedData
  };

  yield put({
    type: UPDATE_WIDGET_SANITIZED,
    widget: newWidget
  });
}

export function* updateWidgetStoreUpdate(action) {
  const { widget } = action;

  yield all([
    put({
      type: UPDATE_WIDGET_LIST_UPDATE,
      widget
    }),
    put({
      type: CLEAR_WIDGET_FORM_DATA,
      widgetId: widget.id
    }),
    put({
      type: REMOVE_LOCAL_WIDGET,
      widgetId: widget.id
    })
  ]);
  yield put({ type: UPDATE_WIDGET_UPDATED, widget });
}

export const updateWidgetSagas = [
  takeEvery(UPDATE_WIDGET_TRIGGER, updateWidgetApiRequest),
  takeEvery(UPDATE_WIDGET_SUCCESS, updateWidgetSanitization),
  takeEvery(UPDATE_WIDGET_SANITIZED, updateWidgetStoreUpdate)
];
