import axios from 'axios';
import _ from 'lodash';
import { select, takeEvery, all, put } from 'redux-saga/effects';

import { getPatientTherapyUrl } from '../../../utils/api/apiUrlUtils';
import { apiCall } from '../../../utils/redux/sagaUtils';

import {
  GET_PATIENT_THERAPY,
  GET_PATIENT_THERAPY_TRIGGER,
  GET_PATIENT_THERAPY_SUCCESS,
  GET_PATIENT_THERAPY_SANITIZED,
  GET_PATIENT_THERAPY_UPDATED,
  UPDATE_ACTIVE_PATIENT_THERAPY_LIST_ITEM,
  UPDATE_PAST_PATIENT_THERAPY_LIST_ITEM,
  SET_PATIENT_THERAPY_DETAIL,
  GET_PATIENT_THERAPY_DETAIL_TRIGGER
} from '../therapyTypes';

import {
  selectPatientActiveTherapyList,
  selectPatientPastTherapyList
} from '../therapySelectors';

export function* getPatientTherapyApiRequest(action) {
  const { patientId, therapyId, type } = action;
  const state = yield select();

  const patientTherapyUrl = getPatientTherapyUrl(
    () => state,
    patientId,
    therapyId
  );

  const shouldUpdateItemsOnList = type === GET_PATIENT_THERAPY_TRIGGER;

  yield apiCall(
    GET_PATIENT_THERAPY,
    axios.get,
    [
      patientTherapyUrl,
      {
        params: {
          include: ['auditLog', 'events'].join(',')
        }
      }
    ],
    {
      shouldUpdateItemsOnList
    }
  );
}

export function* getPatientTherapySanitization(action) {
  const patientTherapy = _.getNonEmpty(action, 'data', {});
  const shouldUpdateItemsOnList = _.getNonEmpty(
    action,
    'additionalData.shouldUpdateItemsOnList',
    false
  );
  const patientTherapyDetail = _.cloneDeep(patientTherapy);

  yield put({
    type: GET_PATIENT_THERAPY_SANITIZED,
    patientTherapyDetail,
    shouldUpdateItemsOnList
  });
}

export function* getPatientTherapyStoreUpdate(action) {
  const { patientTherapyDetail, shouldUpdateItemsOnList } = action;

  const actions = [
    put({
      type: SET_PATIENT_THERAPY_DETAIL,
      therapyDetail: patientTherapyDetail
    })
  ];

  if (shouldUpdateItemsOnList) {
    actions.push(updatePatientActiveTherapyListIfExists(patientTherapyDetail));
    actions.push(updatePatientPastTherapyListIfExists(patientTherapyDetail));
  }

  yield all(actions);
  yield put({
    type: GET_PATIENT_THERAPY_UPDATED,
    therapyId: patientTherapyDetail.id
  });
}

export function* updatePatientActiveTherapyListIfExists(patientTherapyDetail) {
  const patientActiveTherapyList = yield select(selectPatientActiveTherapyList);

  if (!_.find(patientActiveTherapyList, { id: patientTherapyDetail.id })) {
    return;
  }

  yield put({
    type: UPDATE_ACTIVE_PATIENT_THERAPY_LIST_ITEM,
    item: patientTherapyDetail
  });
}

export function* updatePatientPastTherapyListIfExists(patientTherapyDetail) {
  const patientPastTherapyList = yield select(selectPatientPastTherapyList);

  if (!_.find(patientPastTherapyList, { id: patientTherapyDetail.id })) {
    return;
  }

  yield put({
    type: UPDATE_PAST_PATIENT_THERAPY_LIST_ITEM,
    item: patientTherapyDetail
  });
}

export const patientTherapySagas = [
  takeEvery(
    [GET_PATIENT_THERAPY_TRIGGER, GET_PATIENT_THERAPY_DETAIL_TRIGGER],
    getPatientTherapyApiRequest
  ),
  takeEvery(GET_PATIENT_THERAPY_SUCCESS, getPatientTherapySanitization),
  takeEvery(GET_PATIENT_THERAPY_SANITIZED, getPatientTherapyStoreUpdate)
];
