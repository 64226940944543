/* eslint-disable max-len,max-lines-per-function */
import React from 'react';

import PropTypes from 'prop-types';

const BodyOutline = ({ outlineColor, disabledColor, disabled }) => {
  const color = disabled ? disabledColor : outlineColor;

  return (
    <g className={'body-outline'}>
      <path
        d={
          'M236.823 203.501C236.181 205.529 235.744 207.506 235.718 209.688C235.616 224.501 220.694 238.03 208.881 238.03'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M208.855 150C229.298 150 239.545 164.556 240.752 179.343C241.42 187.481 240.264 192.924 238.903 197.288'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M230.3 225.374C229.709 229.686 229.17 234.77 229.015 236.798C228.296 245.423 231.969 247.246 235.616 248.992C240.29 251.225 272.572 262.983 280.662 278.951C287.981 293.379 283.23 307.884 284.18 317.793C284.771 323.878 287.827 345.134 289.265 350.166C290.704 355.172 294.35 360.538 297.586 370.062C300.899 379.817 297.484 402.435 300.643 410.958C303.801 419.481 302.594 420.687 308.347 422.947C321.959 428.286 318.26 425.103 322.857 431.649C323.91 433.138 332.206 441.559 333.182 442.483C334.363 443.613 333.644 445.846 330.203 445.564C326.761 445.281 324.244 443.946 323.037 442.098C321.83 440.25 319.853 437.066 315.692 435.911'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M317.952 436.81C317.31 439.916 318.979 451.16 320.238 454.472C325.837 469.054 326.915 473.598 324.681 474.342C322.267 475.164 320.88 472.237 318.209 464.561C316 458.195 314.049 454.883 312.405 455.679C311.609 456.064 312.405 457.732 312.841 458.682C313.278 459.632 315.23 464.202 315.769 466.076C318.26 474.881 319.339 479.579 316.206 480.042C313.021 480.504 312.816 474.958 311.403 470.568C309.965 466.127 307.962 457.168 306.601 457.476C305.522 457.707 307.859 467.077 308.167 468.592C309.092 473.007 310.813 478.681 308.193 479.297C305.419 479.939 304.572 476.114 302.697 468.13C302.338 466.615 301.053 456.012 300.103 456.012C299.358 456.012 299.05 461.532 299.281 463.226C299.975 468.592 299.949 472.468 297.715 472.725C296.79 472.828 295.378 472.366 294.325 462.456C293.631 456.115 292.912 452.521 292.296 451.032C291.679 449.543 287.339 441.174 286.826 437.657C286.312 434.14 286.389 427.234 286.106 426.412C282.973 417.607 278.941 410.239 276.193 403.667C273.445 397.095 265.278 381.255 264.713 379.355C264.148 377.456 262.71 373.194 262.274 368.522C261.837 363.849 262.428 357.868 261.94 355.763C261.426 353.657 257.137 332.195 256.726 325.392C256.29 318.589 255.108 309.27 255.648 307.139C256.187 305.009 261.144 299.926 261.888 296.383'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M255.262 307.422C252.36 310.682 247.661 317.845 238.389 320.694'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M257.317 330.629C257.317 334.275 257.42 337.124 256.778 340.385C256.136 343.645 255.339 350.243 255.391 354.607C255.725 388.931 266.023 437.015 267.282 463.919C269.593 513.082 262.428 544.299 262.556 549.254C262.659 554.234 262.197 559.625 263.07 563.168C263.943 566.711 266.562 576.98 267.949 583.475C269.336 589.97 270.543 598.339 270.209 605.245C268.951 631.097 260.373 657.179 260.912 661.852C261.452 666.524 263.198 670.555 263.737 673.712C264.559 678.513 261.632 680.901 264.482 685.496C265.407 686.985 267.282 692.915 268.668 694.532C270.055 696.15 272.803 698.152 274.19 699.564C275.577 700.976 277.786 708.935 273.753 709.653C272.701 709.833 272.058 709.833 271.596 709.782'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M270.363 702.645C270.826 705.289 271.545 707.035 271.339 709.243C271.211 710.629 269.259 711.296 267.924 710.757'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M266.126 702.337C265.689 702.902 265.407 703.287 265.741 703.8C269.336 709.243 268.437 711.322 266.563 711.682C264.534 712.092 263.66 711.656 263.66 711.656'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M261.606 702.645C261.272 703.466 261.015 703.62 261.606 704.57C264.919 709.884 264.79 712.144 261.323 712.298C257.548 712.452 257.137 711.527 256.701 711.04'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M242.807 682.441C242.37 679.566 241.471 676.742 241.291 676.1C239.596 669.528 245.734 664.445 244.733 654.715C243.423 641.956 234.897 626.912 233.202 606.169C232.534 597.877 234.563 586.068 232.996 581.601C231.43 577.134 223.211 566.942 222.235 557.392C218.203 517.882 214.171 506.484 210.961 481.787C210.062 474.933 210.319 464.227 210.319 464.227'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M218.229 457.45C217.767 460.377 214.788 463.919 208.855 463.919'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M240.059 193.668C240.059 193.668 242.832 187.687 245.426 188.739C250.075 190.613 243.038 209.97 235.95 208.199'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M250.588 705.597C250.588 705.597 253.388 705.109 255.391 705.52'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M250.332 701.464C251.821 701.849 254.235 703.056 256.264 706.573C256.829 707.548 258.652 712.708 253.131 712.708C246.582 712.708 236.746 706.136 246.993 695.38'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M256.418 568.328C256.136 570.767 254.852 572.256 253.696 574.156'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M234.306 573.617C234.306 573.617 236.9 575.927 241.06 578.623'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M317.028 436.861C317.028 436.861 310.504 439.608 303.904 436.758'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M259.885 292.172C259.885 292.172 262.685 297.872 263.763 299.284'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M274.961 357.868L276.476 364.363'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M208.855 316.612C211.218 316.612 213.401 318.23 213.401 318.23'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M208.778 209.56C209.703 209.56 212.296 209.251 213.144 208.738'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M221.08 200.446C223.094 200.446 224.727 198.814 224.727 196.8C224.727 194.787 223.094 193.155 221.08 193.155C219.066 193.155 217.433 194.787 217.433 196.8C217.433 198.814 219.066 200.446 221.08 200.446Z'
        }
        fill={color}
      />
      <path
        d={'M298.049 455.396L301.773 456.32'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M310.915 456.115L313.92 454.857'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M305.162 457.142L308.065 457.296'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M292.733 420.431L294.119 423.152'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M313.92 445.898C313.92 445.898 306.806 447.027 303.365 446.822'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M295.84 447.746C295.84 447.746 299.923 449.055 302.723 449.671'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M216.508 188.072L229.015 186.968C229.426 186.942 229.709 186.583 229.683 186.172L229.555 184.657C229.529 184.272 229.195 183.99 228.81 183.99L216.226 184.426C215.815 184.452 215.481 184.812 215.532 185.222L215.712 187.404C215.763 187.815 216.123 188.098 216.508 188.072Z'
        }
        fill={color}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M209.112 218.083C211.166 218.083 217.022 217.415 218.922 216.234'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M260.553 707.42C261.837 707.445 262.941 707.779 262.941 707.779'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M268.925 706.804C270.209 706.829 271.314 707.163 271.314 707.163'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M274.318 705.161C274.935 705.34 275.5 705.571 275.885 705.777'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M265.484 707.214C266.768 707.24 267.872 707.574 267.872 707.574'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M233.818 429.75C233.818 429.75 237.311 423.049 244.861 416.349'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M208.855 264.96C211.218 264.96 213.401 263.343 213.401 263.343'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M241.728 678.41C241.163 692.325 237.619 699.025 242.807 702.054'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        opacity={'0.2'}
        d={
          'M208.855 150C188.412 150 178.216 164.556 177.035 179.343C176.496 185.89 176.419 189.612 178.114 194.567C179.244 197.879 186.435 206.915 182.685 184.683C177.292 152.721 198.171 168.51 208.855 168.51C219.539 168.51 240.444 152.721 235.025 184.683C231.275 206.915 238.466 197.879 239.596 194.567C241.291 189.612 241.214 185.89 240.675 179.343C239.494 164.556 229.324 150 208.855 150Z'
        }
        fill={color}
      />
      <path
        d={
          'M180.913 203.501C181.555 205.529 181.992 207.506 182.017 209.688C182.12 224.501 197.041 238.03 208.855 238.03'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M208.855 150C188.412 150 178.165 164.556 176.958 179.343C176.29 187.481 177.446 192.924 178.807 197.288'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M187.411 225.374C188.001 229.686 188.541 234.77 188.695 236.798C189.414 245.423 185.741 247.246 182.094 248.992C177.42 251.225 145.138 262.983 137.048 278.951C129.729 293.379 134.48 307.884 133.53 317.793C132.939 323.878 129.883 345.134 128.445 350.166C127.006 355.172 123.36 360.538 120.124 370.062C116.811 379.817 120.226 402.435 117.067 410.958C113.909 419.481 115.116 420.687 109.363 422.947C95.7513 428.286 99.4496 425.103 94.8525 431.649C93.7995 433.138 85.5042 441.559 84.5283 442.483C83.3469 443.613 84.066 445.846 87.5074 445.564C90.9488 445.281 93.4656 443.946 94.6727 442.098C95.8798 440.25 97.8573 437.066 102.018 435.911'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M99.7834 436.81C100.425 439.916 98.7561 451.16 97.4977 454.472C91.899 469.054 90.8204 473.598 93.0547 474.342C95.4688 475.164 96.8557 472.237 99.5266 464.561C101.735 458.195 103.687 454.883 105.331 455.679C106.127 456.064 105.331 457.732 104.894 458.682C104.458 459.632 102.506 464.202 101.966 466.076C99.4752 474.881 98.3966 479.579 101.53 480.042C104.714 480.504 104.92 474.958 106.332 470.568C107.771 466.127 109.774 457.168 111.135 457.476C112.214 457.707 109.876 467.077 109.568 468.592C108.644 473.007 106.923 478.681 109.543 479.297C112.316 479.939 113.164 476.114 115.039 468.13C115.398 466.615 116.682 456.012 117.632 456.012C118.377 456.012 118.685 461.532 118.454 463.226C117.761 468.592 117.787 472.468 120.021 472.725C120.945 472.828 122.358 472.366 123.411 462.456C124.104 456.115 124.823 452.521 125.44 451.032C126.056 449.569 130.396 441.174 130.91 437.657C131.424 434.14 131.347 427.234 131.629 426.412C134.762 417.607 138.794 410.239 141.542 403.667C144.265 397.095 152.457 381.255 153.022 379.355C153.587 377.456 155.025 373.194 155.462 368.522C155.899 363.849 155.308 357.868 155.796 355.763C156.31 353.657 160.598 332.195 161.009 325.392C161.446 318.589 162.627 309.27 162.088 307.139C161.549 305.009 156.592 299.926 155.847 296.383'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M162.473 307.422C165.375 310.682 170.075 317.845 179.346 320.694'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M160.419 330.629C160.419 334.275 160.316 337.124 160.958 340.385C161.6 343.645 162.396 350.243 162.345 354.607C162.011 388.931 151.712 437.015 150.454 463.919C148.143 513.082 155.308 544.299 155.18 549.254C155.077 554.234 155.539 559.625 154.666 563.168C153.793 566.711 151.173 576.98 149.786 583.475C148.399 589.97 147.192 598.339 147.526 605.245C148.785 631.097 157.363 657.179 156.823 661.852C156.284 666.524 154.537 670.555 153.998 673.712C153.176 678.513 156.104 680.901 153.253 685.496C152.329 686.985 150.454 692.915 149.067 694.532C147.68 696.15 144.932 698.152 143.546 699.564C142.159 700.976 139.95 708.935 143.982 709.653C145.035 709.833 145.677 709.833 146.139 709.782'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M147.347 702.645C146.884 705.289 146.165 707.035 146.371 709.243C146.499 710.629 148.451 711.296 149.786 710.757'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M151.584 702.337C152.021 702.902 152.303 703.287 151.969 703.8C148.374 709.243 149.273 711.322 151.148 711.682C153.176 712.092 154.05 711.656 154.05 711.656'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M156.13 702.645C156.464 703.466 156.72 703.62 156.13 704.57C152.817 709.884 152.945 712.144 156.412 712.298C160.188 712.452 160.598 711.527 161.035 711.04'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M174.929 682.441C175.366 679.566 176.264 676.742 176.444 676.1C178.139 669.528 172.001 664.445 173.003 654.715C174.313 641.956 182.839 626.912 184.534 606.169C185.202 597.877 183.173 586.068 184.74 581.601C186.306 577.134 194.524 566.942 195.5 557.392C199.532 517.882 203.565 506.484 206.775 481.787C207.674 474.933 207.417 464.227 207.417 464.227'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M199.481 457.45C199.943 460.377 202.922 463.919 208.855 463.919'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M177.677 193.668C177.677 193.668 174.903 187.687 172.309 188.739C167.661 190.613 174.698 209.97 181.786 208.199'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M167.122 705.597C167.122 705.597 164.322 705.109 162.319 705.52'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M167.404 701.464C165.915 701.849 163.5 703.056 161.472 706.573C160.907 707.548 159.083 712.708 164.605 712.708C171.154 712.708 180.99 706.136 170.743 695.38'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M161.318 568.328C161.6 570.767 162.884 572.256 164.04 574.156'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M183.43 573.617C183.43 573.617 180.836 575.927 176.675 578.623'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M100.708 436.861C100.708 436.861 107.231 439.608 113.831 436.758'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M157.851 292.172C157.851 292.172 155.051 297.872 153.973 299.284'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M142.775 357.868L141.26 364.363'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M208.855 316.612C206.492 316.612 204.309 318.23 204.309 318.23'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M208.932 209.56C208.007 209.56 205.414 209.251 204.566 208.738'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M196.656 200.446C198.67 200.446 200.303 198.814 200.303 196.8C200.303 194.787 198.67 193.155 196.656 193.155C194.642 193.155 193.009 194.787 193.009 196.8C193.009 198.814 194.642 200.446 196.656 200.446Z'
        }
        fill={color}
      />
      <path
        d={'M119.687 455.396L115.963 456.32'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M106.82 456.115L103.79 454.857'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M112.573 457.142L109.671 457.296'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M125.003 420.431L123.616 423.152'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M103.79 445.898C103.79 445.898 110.904 447.027 114.345 446.822'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M121.896 447.746C121.896 447.746 117.812 449.055 115.013 449.671'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M201.227 188.072L188.72 186.968C188.309 186.942 188.027 186.583 188.053 186.172L188.181 184.657C188.207 184.272 188.541 183.99 188.926 183.99L201.51 184.426C201.921 184.452 202.255 184.812 202.203 185.222L202.024 187.404C201.972 187.815 201.613 188.098 201.227 188.072Z'
        }
        fill={color}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M208.624 218.083C206.569 218.083 200.714 217.415 198.813 216.234'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M157.183 707.42C155.899 707.445 154.794 707.779 154.794 707.779'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M148.81 706.804C147.526 706.829 146.422 707.163 146.422 707.163'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M143.417 705.161C142.801 705.34 142.236 705.571 141.851 705.777'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M152.252 707.214C150.968 707.24 149.863 707.574 149.863 707.574'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M183.918 429.75C183.918 429.75 180.425 423.049 172.875 416.349'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M208.855 264.96C206.492 264.96 204.309 263.343 204.309 263.343'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M176.008 678.41C176.573 692.325 180.117 699.025 174.929 702.054'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
    </g>
  );
};

BodyOutline.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

BodyOutline.defaultProps = {
  disabled: false
};

export default BodyOutline;
