import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import styles from './HomunculusWidget.module.scss';

import { Col } from '../../../../reactstrap';

const TotalCell = ({ value }) => (
  <div className={styles.totalCell}>{value}</div>
);

const TotalLabel = ({ text }) => (
  <span className={styles.totalLabel}>{text}</span>
);

TotalCell.propTypes = {
  value: PropTypes.number.isRequired
};
TotalLabel.propTypes = {
  text: PropTypes.string.isRequired
};

const HomunculusTotal = ({ fields, selectedParts, totalText }) => (
  <React.Fragment>
    <Col xs={{ size: 5 }} className={'d-flex'}>
      <TotalLabel text={totalText} />
    </Col>
    {fields.map((field) => {
      const fieldSelectedParts = _.getNonEmpty(selectedParts, field.id, []);

      return (
        <Col key={field.id}>
          <TotalCell value={fieldSelectedParts.length} />
        </Col>
      );
    })}
    <Col xs={1} />
  </React.Fragment>
);

HomunculusTotal.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedParts: PropTypes.shape().isRequired,
  totalText: PropTypes.string.isRequired
};

export default HomunculusTotal;
