export default {
  inProgress: 'Prenos je v teku ',
  description:
    'Vaš prenos bi se moral samodejno začeti v nekaj trenutkih. <0/> Če se ne, kliknite <1>tukaj</1>, da ga zaženete ročno.',
  error: {
    title: 'Nekaj je šlo narobe',
    description:
      'Prosimo, preverite ali je povezava veljavna ali pa poskusite znova kasneje.'
  }
};
