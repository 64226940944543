import axios from 'axios';
import _ from 'lodash';

import {
  showMissingOrganizationIdError,
  showMissingPatientIdError,
  showAlertError
} from '../../../utils/alert';
import { createJsonBlobFormData } from '../../../utils/form';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../../utils/table';

import { uploadFile } from '../../core/file/fileActions';
import { getPatientFileByUrl } from '../patientActions';

import { UPDATE_FILE, ADD_FILE } from '../../core/file/fileTypes';

import {
  GET_PATIENT_CAVE,
  GET_PATIENT_CAVE_FILES,
  CLEAR_PATIENT_CAVE
} from './caveReducer';

export const getPatientCave = (patientId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  if (!patientId) {
    return showMissingPatientIdError();
  }

  const extraHeaders = {
    headers: {
      'X-Meta-Filter': JSON.stringify({ widgetType: 'cave/v1' })
    }
  };

  return dispatch({
    type: GET_PATIENT_CAVE_FILES,
    apiCall: axios.get(
      `/organizations/${organizationId}/patients/${patientId}/files`,
      extraHeaders
    ),
    onSuccess: (state, response) => {
      const caveFileList = _.get(response, 'data', []);

      if (!_.isEmpty(caveFileList)) {
        const caveFile = _.maxBy(caveFileList, ENTITY_FIELD_CREATED_AT_NAME);

        return dispatch(
          getPatientFileByUrl(caveFile.url, GET_PATIENT_CAVE, {
            fileId: caveFile.id
          })
        );
      }

      return Promise.resolve();
    }
  });
};

export const addOrUpdatePatientCave = (patientId, caveData, fileId) => (
  dispatch,
  getState
) => {
  const isFileNew = _.isEmpty(fileId);

  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  const file = createJsonBlobFormData(caveData);

  const onSuccess = () => {
    dispatch(getPatientCave(patientId));
  };

  const onError = () => {
    showAlertError('error:general');
  };

  const uploadUrlBase = `/organizations/${organizationId}/patients/${patientId}/files`;
  const uploadUrl = isFileNew ? uploadUrlBase : `${uploadUrlBase}/${fileId}`;
  const method = isFileNew ? 'post' : 'put';
  const actionType = isFileNew ? ADD_FILE : UPDATE_FILE;

  return dispatch(
    uploadFile(
      uploadUrl,
      file,
      {
        meta: {
          widgetType: 'cave/v1'
        }
      },
      {
        onSuccess,
        onError,
        actionType,
        method
      }
    )
  );
};

export const clearPatientCave = () => (dispatch) =>
  dispatch({ type: CLEAR_PATIENT_CAVE });
