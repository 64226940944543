import _ from 'lodash';
import moment from 'moment';

import {
  MOSHI_FILE_TYPE_JPEG,
  MOSHI_FILE_TYPE_JPG,
  MOSHI_FILE_TYPE_PNG
} from './constants/fileConstants';

import { guid } from './gen';
import logger from './logger';

export const ENV_DEVELOPMENT = 'development';
export const ENV_TEST = 'test';
export const ENV_E2E = 'e2e';
export const ENV_STAGING = 'staging';
export const ENV_STABILITY = 'stability';
export const ENV_PRODUCTION = 'production';

export const defaultDebounceDelay = moment
  .duration(500, 'milliseconds')
  .asMilliseconds();

export const getEnvValue = (env, fallbackValue, skipError = false) => {
  let envValue = _.get(process.env, env);

  if (_.isEmpty(envValue) && !skipError) {
    logger.error(`Missing value for ${env}`);
  }

  if (_.isEmpty(envValue) && !_.isUndefined(fallbackValue)) {
    envValue = fallbackValue;
  }

  return envValue;
};

export const getHostname = () => _.get(window, 'location.hostname');

const fallbackEnv = getEnvValue('NODE_ENV', ENV_DEVELOPMENT, true);

const replaceHostname = (url) => {
  const safeUrl = _.isEmpty(url) ? 'localhost' : url;

  return safeUrl.replace('{HOSTNAME}', _.get(window || {}, 'location.host'));
};

const moshiConfig = {
  appName: getEnvValue('REACT_APP_NAME', 'IRYO MOSHI', true),
  version: getEnvValue('REACT_APP_VERSION', ENV_DEVELOPMENT, true),
  apiUrl: replaceHostname(getEnvValue('REACT_APP_API_URL')),
  hostSuffix: getEnvValue('REACT_APP_DEFAULT_HOSTNAME_SUFFIX', '', true),
  orgSlug: getEnvValue('REACT_APP_MOSHI_ORG_SLUG', null, true),
  webSocketUrl: replaceHostname(getEnvValue('REACT_APP_WEBSOCKET_URL')),
  mapsApiKey: getEnvValue('REACT_APP_MAPS_API_KEY'),
  googleAnalyticsKey: getEnvValue('REACT_APP_GOOGLE_ANALYTICS_KEY', null, true),
  defaultLocale: getEnvValue('REACT_APP_DEFAULT_LOCALE'),
  appEnv: getEnvValue('REACT_APP_ENV', fallbackEnv),
  intercom: getEnvValue('REACT_APP_INTERCOM_TOKEN', null, true),
  magicBell: getEnvValue('REACT_APP_MAGICBELL_TOKEN', null, true),
  disabledUnifiedSession: getEnvValue(
    'REACT_APP_DISABLE_UNIFIED_SESSION',
    false,
    true
  ),
  fullCalendarLicenseKey: getEnvValue(
    'REACT_APP_FULLCALENDAR_LICENSE_KEY',
    null,
    true
  ),
  appState: {
    googleAnalyticsInitialized: false
  },
  widgetConfig: {
    localWidgetDataStaleDuration: moment.duration(3, 'days').asMilliseconds()
  },
  imageUpload: {
    acceptedFileTypes: [
      MOSHI_FILE_TYPE_JPG,
      MOSHI_FILE_TYPE_JPEG,
      MOSHI_FILE_TYPE_PNG
    ],
    maxFileSize: 100,
    preview: {
      height: 210,
      width: 390,
      overlayHeight: 59
    }
  },
  list: {
    pagination: {
      limit: 20
    }
  },
  billing: {
    cadastralInfoLink: getEnvValue(
      'REACT_APP_CADASTRAL_INFO_URL',
      'https://eprostor.gov.si/javni/',
      true
    ),
    noticeInfoLink: getEnvValue(
      'REACT_APP_NOTICE_INFO_URL',
      'http://www.fu.gov.si/nadzor/podrocja/davcne_blagajne_in_vezane_knjige_racunov_vkr/',
      true
    )
  },
  sessionId: guid(),
  logos: {
    'pulmologi.moshi.stg.iryo.io': 'https://fs.iryo.io/m/astma-asist.png',
    'pulmologi.stab.stg.iryo.io': 'https://fs.iryo.io/m/astma-asist.png',
    'astma-asist.si': 'https://fs.iryo.io/m/astma-asist.png',
    'pulmologija.iryomoshi.io': 'https://fs.iryo.io/m/astma-asist.png',
    'demo-pulmologija.iryomoshi.io': 'https://fs.iryo.io/m/astma-asist.png'
  },
  smallLogos: {
    'pulmologi.moshi.stg.iryo.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'pulmologi.stab.stg.iryo.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'astma-asist.si': 'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'pulmologija.iryomoshi.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'demo-pulmologija.iryomoshi.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png'
  },
  brandNames: {
    'pulmologi.moshi.stg.iryo.io': 'Astma Asist',
    'pulmologi.stab.stg.iryo.io': 'Astma Asist',
    'astma-asist.si': 'Astma Asist',
    'pulmologija.iryomoshi.io': 'Astma Asist',
    'demo-pulmologija.iryomoshi.io': 'Astma Asist'
  },
  favicons: {
    'pulmologi.moshi.stg.iryo.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'pulmologi.stab.stg.iryo.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'astma-asist.si': 'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'pulmologija.iryomoshi.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png',
    'demo-pulmologija.iryomoshi.io':
      'https://fs.iryo.io/m/astma-asist-collapsed.png'
  },
  dev: {
    buildNumber: getEnvValue('REACT_APP_DRONE_BUILD_NUMBER', null, true)
  }
};

export default moshiConfig;

export const getLogoURL = (hostname) => _.get(moshiConfig, [`logos`, hostname]);
export const getSmallLogoURL = (hostname) =>
  _.get(moshiConfig, [`smallLogos`, hostname]);
export const getBrandName = (hostname) =>
  _.get(moshiConfig, ['brandNames', hostname], moshiConfig.appName);
export const getFaviconURL = (hostname) =>
  _.get(moshiConfig, ['favicons', hostname]);
