import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  BILL_LIST_FILTER_BILLING_UNIT,
  BILL_LIST_FILTER_DATE_FROM,
  BILL_LIST_FILTER_DATE_TO,
  BILL_LIST_FILTER_DOCTOR,
  BILL_LIST_FILTER_ELECTRONIC_DEVICE,
  BILL_LIST_FILTER_LOCATION,
  BILL_LIST_FILTER_SEARCH,
  BILL_LIST_FILTER_STATUS,
  BILL_LIST_FILTER_PAYMENT_TYPE
} from '../../../utils/billing';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';

import { initialState } from './creditNoteReducer';

export const selectCreditNoteStore = (store) => store.creditNote;

// LIST SELECTORS
export const selectCreditNoteList = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'list', [])
);

export const selectCreditNoteLoadingList = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'loadingList', false)
);

export const selectCreditNoteIncludeParam = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'include', '')
);

// DETAIL SELECTORS
export const selectCreditNoteDetail = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'detail', {})
);

export const selectCreditNoteLoadingDetail = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'loadingDetail', false)
);

// FILTER SELECTORS
export const selectCreditNoteFilter = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'filter', {})
);

export const selectCreditNoteFilterSearchValue = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(creditNoteFilter, BILL_LIST_FILTER_SEARCH, '')
);

export const selectCreditNoteFilterLocationId = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(creditNoteFilter, BILL_LIST_FILTER_LOCATION, FILTER_ALL_ID)
);

export const selectCreditNoteFilterDoctorId = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(creditNoteFilter, BILL_LIST_FILTER_DOCTOR, FILTER_ALL_ID)
);

export const selectCreditNoteFilterBillingUnitId = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(
      creditNoteFilter,
      BILL_LIST_FILTER_BILLING_UNIT,
      FILTER_ALL_ID
    )
);

export const selectCreditNoteFilterStatusValue = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(creditNoteFilter, BILL_LIST_FILTER_STATUS, FILTER_ALL_ID)
);

export const selectCreditNotePaymentTypeFilterValue = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(
      creditNoteFilter,
      BILL_LIST_FILTER_PAYMENT_TYPE,
      FILTER_ALL_ID
    )
);

export const selectCreditNoteFilterElectronicDeviceId = createSelector(
  [selectCreditNoteFilter],
  (creditNoteFilter) =>
    _.getNonEmpty(
      creditNoteFilter,
      BILL_LIST_FILTER_ELECTRONIC_DEVICE,
      FILTER_ALL_ID
    )
);

export const selectIsCreditNoteFilterInitial = createSelector(
  [selectCreditNoteFilter],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});

    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectCreditNoteFilterFromDate = createSelector(
  [selectCreditNoteFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_FROM, undefined)
);

export const selectCreditNoteFilterToDate = createSelector(
  [selectCreditNoteFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_TO, undefined)
);

// PAGINATION SELECTORS
export const selectCreditNotePagination = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'pagination', {})
);

// PAYMENTS SELECTORS
export const selectCreditNotePaymentList = createSelector(
  [selectCreditNoteStore],
  (creditNoteStore) => _.getNonEmpty(creditNoteStore, 'payments.list', [])
);
