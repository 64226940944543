import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getSafeTranslation } from '../../../../../../utils/language';
import { getLatestVersionWidgetFormSchema } from '../../../../../../utils/widgets';

const NoWidgetValueError = ({ dataPath, widgetType }) => {
  const { t: translate } = useTranslation();
  const formSchema = getLatestVersionWidgetFormSchema(widgetType);

  const title = _.get(formSchema, 'title');
  const property = _.get(formSchema, `properties.${dataPath}.title`);

  return (
    <div className={'text-color-red text-normal-wrap'}>
      {translate('widget:calculationError.noWidgetValueMessage', {
        property: getSafeTranslation(property),
        widget: getSafeTranslation(title)
      })}
    </div>
  );
};

NoWidgetValueError.propTypes = {
  dataPath: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired
};

export default NoWidgetValueError;
