import _ from 'lodash';

import BillClientAddressModel from './BillClientAddressModel';

class BillClientModel {
  constructor(name = '') {
    this.name = name;
    this.address = new BillClientAddressModel();
    this.taxSubject = false;
    this.taxNumber = '';
    this.companyID = '';
  }

  static getPayload(model) {
    const payload = { ...model };

    if (_.isEmpty(payload.name)) {
      return null;
    }

    payload.address = BillClientAddressModel.getPayload(model.address);

    return payload;
  }
}

export default BillClientModel;
