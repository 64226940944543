import { useDropdownItemProps } from './useDropdownItemProps';
import { useDropdownMenuProps } from './useDropdownMenuProps';
import { useDropdownProps } from './useDropdownProps';
import { useDropdownToggleProps } from './useDropdownToggleProps';

/**
 * Prepares props for whole dropdown with toggle, menu and menu item
 * @param {object} params contains list of overridable params
 * | @param {string} id id of dropdown
 * | @param {function} onClose handler on dropdown close
 * | @param {string} dropdownName name of dropdown
 * | @param {string} dropdownClassName overrides dropdown className
 * | @param {string} placeholder placeholder in dropdown toggle
 * | @param {boolean} disabled whether dropdown is disabled
 * | @param {array} items list of items
 * | @param {string} value selected value
 * | @param {string} dropdownToggleClassName overrides dropdown toggle className
 * | @param {boolean} enableDeselection adds deselection item into array of items
 * | @param {string} dropdownMenuClassName overrides dropdown menu className
 * | @param {(item) => void} onClick triggers on dropdown item click
 * | @param {string} dropdownItemClassName overrides dropdown menu item className
 *
 * @returns {object}
 * | @returns {object} dropdownProps
 * | @returns {object} dropdownToggleProps
 * | @returns {object} dropdownMenuProps
 * | @returns {(item, index) => props} getItemProps: getter function to get props for item
 * | @returns {string} selectedItem: currently selected item
 * | @returns {array} itemsToDisplay: list of items to be used in menu
 */
export const useDropdown = ({
  id,
  onClose,
  dropdownName,
  dropdownClassName,
  placeholder,
  disabled,
  items,
  value,
  dropdownToggleClassName,
  enableDeselection,
  dropdownMenuClassName,
  onClick,
  dropdownItemClassName
} = {}) => {
  const { dropdownProps } = useDropdownProps({
    id,
    onClose,
    dropdownName,
    disabled,
    className: dropdownClassName
  });
  const { dropdownToggleProps, selectedItem } = useDropdownToggleProps({
    placeholder,
    disabled,
    items,
    value,
    className: dropdownToggleClassName
  });
  const { dropdownMenuProps, itemsToDisplay } = useDropdownMenuProps({
    items,
    enableDeselection,
    className: dropdownMenuClassName
  });
  const { getItemProps } = useDropdownItemProps({
    onClick,
    className: dropdownItemClassName
  });

  return {
    dropdownProps,
    dropdownToggleProps,
    dropdownMenuProps,
    getItemProps,
    selectedItem,
    itemsToDisplay
  };
};
