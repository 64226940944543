import produce from 'immer';
import _ from 'lodash';

import { GET_RESOURCES_SUCCESS } from './resourceTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  list: []
};

// eslint-disable-next-line default-param-last
const resourceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_RESOURCES_SUCCESS:
        const resources = _.get(payload, 'response.data', []);

        draft.list = resources;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default resourceReducer;
