import { createActionTypeArray } from '../../common/actionTypes';

export const GET_PATIENT_NOTE_LIST = 'patient/GET_PATIENT_NOTE_LIST';

export const ADD_PATIENT_NOTE = 'patient/ADD_PATIENT_NOTE';
export const PIN_PATIENT_NOTE = 'patient/PIN_PATIENT_NOTE';
export const UPDATE_PATIENT_NOTE = 'patient/UPDATE_PATIENT_NOTE';
export const DELETE_PATIENT_NOTE = 'patient/DELETE_PATIENT_NOTE';

export const [
  GET_PATIENT_NOTE_LIST_IN_PROGRESS,
  GET_PATIENT_NOTE_LIST_SUCCESS,
  GET_PATIENT_NOTE_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_NOTE_LIST);

export const [
  ADD_PATIENT_NOTE_IN_PROGRESS,
  ADD_PATIENT_NOTE_SUCCESS,
  ADD_PATIENT_NOTE_FAILURE
] = createActionTypeArray(ADD_PATIENT_NOTE);

export const [
  UPDATE_PATIENT_NOTE_IN_PROGRESS,
  UPDATE_PATIENT_NOTE_SUCCESS,
  UPDATE_PATIENT_NOTE_FAILURE
] = createActionTypeArray(UPDATE_PATIENT_NOTE);

export const [
  DELETE_PATIENT_NOTE_IN_PROGRESS,
  DELETE_PATIENT_NOTE_SUCCESS,
  DELETE_PATIENT_NOTE_FAILURE
] = createActionTypeArray(DELETE_PATIENT_NOTE);

export const [
  PIN_PATIENT_NOTE_IN_PROGRESS,
  PIN_PATIENT_NOTE_SUCCESS,
  PIN_PATIENT_NOTE_FAILURE
] = createActionTypeArray(PIN_PATIENT_NOTE);
