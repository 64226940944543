import PropTypes from 'prop-types';

export const ButtonShape = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  outline: PropTypes.bool,
  size: PropTypes.string,
  invisible: PropTypes.bool
};
