import _ from 'lodash';
import moment from 'moment';
import retry from 'retry';

import { jsonParse } from '../../../utils/jwt';
import logger, { safeStringify } from '../../../utils/logger';
import moshiConfig from '../../../utils/moshiConfig';

import { handleBillingDocumentWebSocketMessage } from '../../billing/billingActions';
import { getDevice } from '../../device/devicesActions';
import { handleOutboundDocumentPdfUrl } from '../../document/outbound/outboundDocumentActions';
import {
  handleAssistRequestWebsocketPayload,
  handleEncounterCommentWebSocketPayload,
  handleEncounterWebSocketPayload
} from '../cache/encounterWaitingList/waitingListEncounterActions';

import {
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_RESUBSCRIBE,
  WEBSOCKET_SUBSCRIBE,
  WEBSOCKET_UNSUBSCRIBE,
  WEBSOCKET_CONNECT_SUCCESS,
  WEBSOCKET_CONNECT_FAILURE,
  WEBSOCKET_CONNECT_IN_PROGRESS,
  WEBSOCKET_CONNECT_TIMEOUT,
  WEBSOCKET_CLOSE,
  WEB_SOCKET_ENTITY_TYPE_ENCOUNTER,
  WEB_SOCKET_ENTITY_TYPE_ENCOUNTER_COMMENT,
  WEB_SOCKET_ENTITY_TYPE_ASSIST_REQUEST,
  WEB_SOCKET_ENTITY_TYPE_DEVICE,
  WEB_SOCKET_ENTITY_TYPE_OUTBOUND_PDF_URL,
  WEB_SOCKET_ENTITY_TYPE_INVOICE,
  WEB_SOCKET_ENTITY_TYPE_ESTIMATE,
  WEB_SOCKET_ENTITY_TYPE_ADVANCE,
  WEB_SOCKET_ENTITY_TYPE_CREDIT_NOTE
} from './webSocketTypes';

import { selectTokenString } from '../../auth/authSelectors';

const WS_PING_INTERVAL = moment.duration(10, 'seconds').asMilliseconds();
const WS_CONNECTION_TIMEOUT = moment.duration(10, 'minutes').asMilliseconds();
const WS_RETRY_MAX_TIMEOUT = moment.duration(30, 'minutes').asMilliseconds();
const WS_RETRY_MIN_TIMEOUT = moment.duration(2, 'seconds').asMilliseconds();
const READY_STATE_CONNECTION = 0;
const READY_STATE_OPEN = 1;

const NORMAL_WEBSOCKET_CLOSE = 1000;
const CLIENT_LEAVING_WEBSOCKET_CLOSE = 1001;
const SERVICE_RESTART_WEBSOCKET_CLOSE = 1012;
const ACTION_SUBSCRIBE = 'subscribe';
const ACTION_UNSUBSCRIBE = 'unsubscribe';
const ACTION_LOGIN = 'login';
const ACTION_AUTHENTICATION = 'authentication';
const ACTION_EVENT = 'event';
let ws;
let pingIntervalInstanceId;
let wsConnectionTimoutId;

const connectOperation = retry.operation({
  retries: 4,
  minTimeout: WS_RETRY_MIN_TIMEOUT,
  maxTimeout: WS_RETRY_MAX_TIMEOUT
});

const throttledErrorLog = _.throttle(logger.error, WS_RETRY_MAX_TIMEOUT);

export const webSocketConnect = () => (dispatch, getState) => {
  dispatch({ type: WEBSOCKET_CONNECT_IN_PROGRESS });
  let token;

  connectOperation.attempt(() => {
    ws = new WebSocket(moshiConfig.webSocketUrl);
    token = selectTokenString(getState());

    const close = (closeError) => {
      clearInterval(pingIntervalInstanceId);
      clearTimeout(wsConnectionTimoutId);
      ws.removeEventListener('open', onOpen);
      ws.removeEventListener('message', onMessage);
      ws.removeEventListener('error', onError);
      ws.removeEventListener('close', onClose);

      if (_.isError(closeError)) {
        connectOperation.reset();
        connectOperation.retry(closeError);
        throttledErrorLog(closeError);
      }
    };

    // Clear just in case before new one is created
    clearTimeout(wsConnectionTimoutId);
    wsConnectionTimoutId = setTimeout(() => {
      if (isWebSocket() || ws.readyState !== READY_STATE_OPEN) {
        dispatch({ type: WEBSOCKET_CONNECT_TIMEOUT });
        close(new Error('Connection to the web socket server timed out'));
      }
    }, WS_CONNECTION_TIMEOUT);

    const onOpen = () => {
      if (isWebSocket()) {
        throttledErrorLog.cancel();
        clearTimeout(wsConnectionTimoutId);
        const sessionId = moshiConfig.sessionId;

        dispatch({ type: WEBSOCKET_CONNECT_SUCCESS });
        sendWsMessage({ action: ACTION_LOGIN, token, sessionID: sessionId });
        connectOperation.stop(ws);
        dispatch(resubscribeWebSocket());

        // Clear just in case before new one is created
        clearInterval(pingIntervalInstanceId);
        pingIntervalInstanceId = setInterval(() => {
          sendWsMessage('ping');
        }, WS_PING_INTERVAL);
      }
    };

    const onMessage = (response) => {
      dispatch(handleOnMessage(response.data));
    };

    const onError = () => {
      dispatch({ type: WEBSOCKET_CONNECT_FAILURE });
    };

    const onClose = (closeEvent) => {
      const closeEventCode = _.getNonEmpty(closeEvent, 'code');
      const closeEventReason = _.getNonEmpty(
        closeEvent,
        'reason',
        'unknown reason'
      );
      let closeError;

      if (
        !_.includes(
          [
            NORMAL_WEBSOCKET_CLOSE,
            CLIENT_LEAVING_WEBSOCKET_CLOSE,
            SERVICE_RESTART_WEBSOCKET_CLOSE
          ],
          closeEventCode
        )
      ) {
        closeError = new Error(
          `${closeEventCode} Connection closed: ${closeEventReason}`
        );
      }

      dispatch({ type: WEBSOCKET_CLOSE });
      close(closeError);
    };

    ws.addEventListener('open', onOpen);
    ws.addEventListener('message', onMessage);
    ws.addEventListener('error', onError);
    ws.addEventListener('close', onClose);
  });
};

export const webSocketSubscribe = (componentId, channel) => (dispatch) => {
  const subscribeOperation = retry.operation({
    retries: 5,
    maxTimeout: WS_RETRY_MAX_TIMEOUT
  });

  subscribeOperation.attempt(() => {
    if (isWebSocket() && ws.readyState === READY_STATE_OPEN) {
      dispatch({
        type: WEBSOCKET_SUBSCRIBE,
        componentId,
        channel
      });
      sendWsMessage({ action: ACTION_SUBSCRIBE, channel });
      subscribeOperation.stop();
    } else if (isWebSocket() && ws.readyState === READY_STATE_CONNECTION) {
      subscribeOperation.retry('Connection not opened yet');
    }
  });
};

export const webSocketUnsubscribe = (componentId) => (dispatch, getState) => {
  const channels = _.get(
    getState(),
    `webSocket.activeSubscriptions.${componentId}`,
    []
  );

  if (
    isWebSocket() &&
    ws.readyState === READY_STATE_OPEN &&
    !_.isEmpty(channels)
  ) {
    _.forEach(channels, (channel) =>
      sendWsMessage({ action: ACTION_UNSUBSCRIBE, channel })
    );
    dispatch({ type: WEBSOCKET_UNSUBSCRIBE, componentId });
  }
};

const sendWsMessage = (message) => {
  let payload = message;

  if (_.isObject(message)) {
    payload = safeStringify(message);
  }

  ws.send(payload);
};

const handleOnMessage = (response) => (dispatch) => {
  const message = jsonParse(response);

  const payload = _.getNonEmpty(message, 'payload', {});
  const entity = _.getNonEmpty(payload, 'entity', null);
  const action = _.getNonEmpty(message, 'action', '');

  switch (action) {
    case ACTION_AUTHENTICATION:
      break;
    case ACTION_EVENT:
      switch (entity) {
        case WEB_SOCKET_ENTITY_TYPE_ENCOUNTER:
          dispatch(handleEncounterWebSocketPayload(payload));
          break;
        case WEB_SOCKET_ENTITY_TYPE_ENCOUNTER_COMMENT:
          dispatch(handleEncounterCommentWebSocketPayload(payload));
          break;
        case WEB_SOCKET_ENTITY_TYPE_ASSIST_REQUEST:
          dispatch(handleAssistRequestWebsocketPayload(payload));
          break;
        case WEB_SOCKET_ENTITY_TYPE_DEVICE:
          dispatch(getDevice(payload.id));
          break;
        case WEB_SOCKET_ENTITY_TYPE_OUTBOUND_PDF_URL:
          dispatch(handleOutboundDocumentPdfUrl(payload));
          break;
        case WEB_SOCKET_ENTITY_TYPE_INVOICE:
        case WEB_SOCKET_ENTITY_TYPE_ESTIMATE:
        case WEB_SOCKET_ENTITY_TYPE_ADVANCE:
        case WEB_SOCKET_ENTITY_TYPE_CREDIT_NOTE:
          dispatch(
            handleBillingDocumentWebSocketMessage(entity, action, payload)
          );
          break;
        default:
          break;
      }
      break;
    default:
  }
};

export const closeWebSocketConnection = () => (dispatch) => {
  if (isWebSocket()) {
    ws.close(NORMAL_WEBSOCKET_CLOSE);
  }
  dispatch({ type: WEBSOCKET_DISCONNECT });
};

export const resubscribeWebSocket = () => (dispatch, getState) => {
  const activeSubscription = _.get(
    getState(),
    'webSocket.activeSubscriptions',
    {}
  );

  if (!_.isEmpty(activeSubscription) && isWebSocket()) {
    _.forIn(activeSubscription, (channel) => {
      sendWsMessage({ action: ACTION_SUBSCRIBE, channel });
    });
    dispatch({ type: WEBSOCKET_RESUBSCRIBE });
  }
};

export const webSocketDeviceUrl = (organizationId) =>
  `/organizations/${organizationId}/devices`;

export const webSocketBillingUrl = (organizationId) =>
  `/organizations/${organizationId}/billing`;

export const webSocketVisitorWaitingListUrl = (organizationId, locationId) =>
  `/organizations/${organizationId}/locations/${locationId}/waitlist`;

export const assistRequestsUrl = (organizationId, locationId) =>
  `/organizations/${organizationId}/locations/${locationId}/waitlist/assistRequests`;

export const calendarEventsUrl = (organizationId, locationId) =>
  `/organizations/${organizationId}/locations/${locationId}/calendar`;

const isWebSocket = () =>
  _.isFunctionSafe(ws, 'close') &&
  _.isFunctionSafe(ws, 'send') &&
  ws instanceof WebSocket;
