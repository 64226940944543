import React from 'react';

import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import { translateKey } from '../../i18n';
import { EVENT_TYPE_QUEUE } from '../constants/calendarConstants';
import { SEARCH_RESULT_ENTITY_TYPE_PATIENT } from '../constants/entityConstants';

import { selectCalendarWaitingListEventById } from '../../redux/calendar/calendarSelectors';
import {
  selectCachedCategoriesById,
  selectCachedOrDeletedLocationById
} from '../../redux/core/cache/cacheSelectors';
import { selectLocationById } from '../../redux/location/locationSelectors';

import LocationModel from '../../metadata/model/LocationModel';
import BillClientModel from '../../metadata/model/billing/bill/BillClientModel';

import { AUTO_SUGGEST_NO_RESULTS } from '../../components/common/form/dropdown/MoshiAutoSuggest';
import MoshiAutoSuggestEventResultCard from '../../components/common/form/dropdown/MoshiAutoSuggestEventResultCard';
import Visible from '../../components/common/layout/Visible';

import PatientInfoMiniCard from '../../containers/patient/PatientInfoMiniCard';
import { getCountryList } from '../data/country';
import { getDateDifferenceDuration } from '../date';
import { EMPTY_GUID } from '../gen';
import { getFullName, getPatientFullName } from './common-mappers';
import { getUserById } from './user-mappers';

export const patientSuggestionMapper = (data) => ({
  id: data.id,
  name: getFullName(data),
  town: _.get(data, 'address.town', ''),
  street: _.get(data, 'address.street1', ''),
  address: _.get(data, 'address', {}),
  notifications: _.get(data, 'notifications', {}),
  doctorId: _.get(data, 'doctorID', undefined),
  dateOfBirth: _.get(data, 'dateOfBirth', {}),
  gender: _.get(data, 'gender', ''),
  contact: {
    address: _.get(data, 'address', {}),
    phoneNumber: _.get(data, 'phoneNumber', ''),
    email: _.get(data, 'email', '')
  },
  identification: {
    firstName: _.get(data, 'firstName', ''),
    lastName: _.get(data, 'lastName', ''),
    dateOfBirth: _.get(data, 'dateOfBirth', {}),
    gender: _.get(data, 'gender', '')
  },
  entityType: _.get(data, 'entityType')
});

export const patientAndEventSuggestionMapper = (data) => {
  const resultType = _.getNonEmpty(data, 'entityType', null);

  if (resultType === SEARCH_RESULT_ENTITY_TYPE_PATIENT) {
    return patientSuggestionMapper(data);
  }

  if (data.type === EVENT_TYPE_QUEUE) {
    const waitingListEvent = selectCalendarWaitingListEventById(
      storeGetState(),
      data.id
    );

    return {
      name: getFullName(data.patient),
      eventType: data.type,
      ...waitingListEvent
    };
  }

  const assigneeIDs = _.map(data.assignees, 'id');

  return {
    appointmentCode: data.appointmentCode,
    id: data.id,
    name: getFullName(data.patient),
    duration:
      data.type === EVENT_TYPE_QUEUE
        ? data.duration
        : getDateDifferenceDuration(data.from, data.to).asMinutes(),
    from: data.from,
    to: data.to,
    eventType: data.type,
    assigneeIDs,
    assignees: data.assignees,
    category: selectCachedCategoriesById(storeGetState(), data.categoryID),
    title: data.title,
    patient: data.patient,
    location: selectCachedOrDeletedLocationById(
      storeGetState(),
      data.locationID
    ),
    entityType: data.entityType
  };
};

export const billClientMapper = (patient, isSuggestionMapped = false) => {
  const name = isSuggestionMapped
    ? _.get(patient, 'name', '')
    : getPatientFullName(patient);
  const addressPath = isSuggestionMapped ? 'address' : 'contact.address';

  const safePatient = _.isEmpty(patient) ? {} : patient;
  const patientID = _.get(safePatient, 'id', EMPTY_GUID);
  const address = _.getNonEmpty(safePatient, addressPath, new LocationModel());

  return {
    patientID,
    client: _.nestedAssign(BillClientModel, {
      name,
      address,
      taxSubject: false,
      taxNumber: '',
      companyID: ''
    })
  };
};

export const patientRemoveNullEmailAndPhoneNumber = (patient) => {
  if (_.isEmpty(patient)) {
    return patient;
  }

  if (_.isEmptySafe(patient, 'contact.email')) {
    patient.contact.email = null;
  }

  if (_.isEmptySafe(patient, 'contact.phoneNumber')) {
    patient.contact.phoneNumber = null;
  }

  return patient;
};

export const patientSuggestRender = (data) => {
  const resultId = _.getNonEmpty(data, 'id');

  return resultId === AUTO_SUGGEST_NO_RESULTS ? (
    translateKey('common:noItemsFound')
  ) : (
    <div>
      <PatientInfoMiniCard
        horizontal
        fetchPatient={false}
        patientData={data}
        displayAddress
        displayLinkToProfile
        patientId={_.getNonEmpty(data, 'id', null)}
      />
    </div>
  );
};

export const patientOverlaySuggestRender = (patient, translate) => (
  <div className={'patient-suggestion'}>
    <div className={'patient-name'}>{patient.name}</div>
    <div className={'patient-info no-break'}>
      <Visible visible={!_.isEmpty(patient.dateOfBirth)}>
        <span>
          {translate('date:shortDate', {
            date: patient.dateOfBirth
          })}
        </span>
      </Visible>
      <Visible visible={!_.isEmpty(patient.town) && !_.isEmpty(patient.street)}>
        <span>{`${patient.street}, ${patient.town}`}</span>
      </Visible>
    </div>
  </div>
);

export const patientOrEventSuggestRender = (data) => {
  const resultType = _.getNonEmpty(data, 'entityType', null);
  const resultId = _.getNonEmpty(data, 'id');

  if (resultType === SEARCH_RESULT_ENTITY_TYPE_PATIENT) {
    return patientSuggestRender(data);
  } else if (resultId === AUTO_SUGGEST_NO_RESULTS) {
    return translateKey('common:noItemsFound');
  }

  return <MoshiAutoSuggestEventResultCard data={data} />;
};

const getConflictItemsCreator = (conflictValues) => (getName) =>
  _.map(conflictValues, (value) => ({
    id: value.value,
    name: getName(value),
    key: `${value.sourceID}-${value.value}`
  }));

export const mapItemsForMergePatientFormFields = (conflict) => {
  let items;

  if (_.isEmpty(conflict)) {
    return items;
  } else {
    const type = _.last(conflict.key.split('.'));

    const conflictItemsMapper = getConflictItemsCreator(conflict.values);

    switch (type) {
      case 'dateOfBirth':
        items = conflictItemsMapper((value) =>
          translateKey('date:shortDate', {
            date: value.value
          })
        );
        break;
      case 'gender':
        items = conflictItemsMapper((value) =>
          translateKey(`patient:person.genderTypes.${value.value}`)
        );
        break;
      case 'country':
        items = conflictItemsMapper(
          (value) => _.find(getCountryList(), { id: value.value }).name
        );
        break;
      case 'doctorID':
        items = conflictItemsMapper((value) => {
          const doctor = getUserById(value.value);

          return getFullName(doctor);
        });
        break;
      case 'sms':
        items = conflictItemsMapper((value) =>
          value.value ? translateKey('common:yes') : translateKey('common:no')
        );
        break;
      case 'push':
        items = conflictItemsMapper((value) =>
          value.value ? translateKey('common:yes') : translateKey('common:no')
        );
        break;
      case 'locationIDs':
        items = _.map(conflict.values, (value) => {
          const locationNames = _.map(value.value, (locationId) => {
            const location = selectLocationById(storeGetState(), locationId);

            return _.get(location, 'name', '');
          });
          /**
           * location ids are converted to a comma separated string and then
           * converted back at the payload for the api.
           */

          return {
            id: value.value.join(','),
            name: locationNames.join(', '),
            key: `${value.sourceID}-${value.value}`
          };
        });
        break;
      case 'email':
        if (
          _.includes(conflict.key, 'reminders') ||
          _.includes(conflict.key, 'marketing')
        ) {
          items = conflictItemsMapper((value) =>
            value.value ? translateKey('common:yes') : translateKey('common:no')
          );
        } else {
          items = conflictItemsMapper((value) => value.value);
        }
        break;
      default:
        items = conflictItemsMapper((value) => value.value);
    }

    return _.uniqBy(items, 'id');
  }
};

export const addOrderToMergeConflicts = (conflicts) => {
  if (_.isEmpty(conflicts)) {
    return null;
  } else {
    return _.map(_.cloneDeep(conflicts), (conflict) => {
      const type = _.last(conflict.key.split('.'));

      switch (type) {
        case 'firstName':
          conflict.order = 10;
          break;
        case 'lastName':
          conflict.order = 20;
          break;
        case 'dateOfBirth':
          conflict.order = 30;
          break;
        case 'gender':
          conflict.order = 40;
          break;
        case 'insuranceID':
          conflict.order = 50;
          break;
        case 'email':
          if (_.includes(conflict.key, 'reminders')) {
            conflict.order = 150;
          } else if (_.includes(conflict.key, 'marketing')) {
            conflict.order = 151;
          } else {
            conflict.order = 60;
          }
          break;
        case 'phoneNumber':
          conflict.order = 70;
          break;
        case 'street1':
          conflict.order = 80;
          break;
        case 'zip':
          conflict.order = 90;
          break;
        case 'town':
          conflict.order = 100;
          break;
        case 'country':
          conflict.order = 110;
          break;
        case 'doctorID':
          conflict.order = 120;
          break;
        case 'locationIDs':
          conflict.order = 130;
          break;
        case 'sms':
          if (_.includes(conflict.key, 'reminders')) {
            conflict.order = 140;
          } else if (_.includes(conflict.key, 'marketing')) {
            conflict.order = 141;
          }
          break;
        case 'push':
          if (_.includes(conflict.key, 'reminders')) {
            conflict.order = 160;
          } else if (_.includes(conflict.key, 'marketing')) {
            conflict.order = 161;
          }
          break;
        default:
          conflict.order = 1000;
      }

      return conflict;
    });
  }
};

/**
 * If there are no notifications set on the patient we set them to false by default.
 * @param details
 * @returns {*}
 */
export const mapRemindersForPatient = (details) => {
  const patientDetails = _.cloneDeep(details);

  const hasSomeEmptyNotificationReminders =
    _.isEmptySafe(patientDetails.notifications) ||
    _.isEmptySafe(patientDetails.notifications.reminders) ||
    !_.isBoolean(patientDetails.notifications.reminders.sms) ||
    !_.isBoolean(patientDetails.notifications.reminders.push) ||
    !_.isBoolean(patientDetails.notifications.reminders.email);

  const hasSomeEmptyMarketingReminders =
    _.isEmptySafe(patientDetails.notifications) ||
    _.isEmptySafe(patientDetails.notifications.marketing) ||
    !_.isBoolean(patientDetails.notifications.marketing.sms) ||
    !_.isBoolean(patientDetails.notifications.marketing.push) ||
    !_.isBoolean(patientDetails.notifications.marketing.email);

  if (hasSomeEmptyNotificationReminders) {
    _.set(patientDetails, 'notifications.reminders', {
      email: _.getNonEmpty(
        patientDetails,
        'notifications.reminders.email',
        false
      ),
      sms: _.getNonEmpty(patientDetails, 'notifications.reminders.sms', false),
      push: _.getNonEmpty(patientDetails, 'notifications.reminders.push', false)
    });
  }

  if (hasSomeEmptyMarketingReminders) {
    _.set(patientDetails, 'notifications.marketing', {
      email: _.getNonEmpty(
        patientDetails,
        'notifications.marketing.email',
        false
      ),
      sms: _.getNonEmpty(patientDetails, 'notifications.marketing.sms', false),
      push: _.getNonEmpty(patientDetails, 'notifications.marketing.push', false)
    });
  }

  return patientDetails;
};
