import _ from 'lodash';

export const WIDGET_FILE_NONE = 'widgetFile/none';
export const WIDGET_FILE_ADDED = 'widgetFile/added';
export const WIDGET_FILE_DELETED = 'widgetFile/deleted';

export const isDocument = (file) => _.has(file, 'meta.documentID');
export const isQuestionnaire = (file) => _.has(file, 'meta.questionnaireID');
export const isDentalChartEntry = (file) =>
  _.includes(_.get(file, 'meta.widgetType', ''), 'dental/dentalChart');

export const filterWidgetFileAdded = (file) =>
  _.get(file, 'localStatus', '') !== WIDGET_FILE_ADDED;
export const filterWidgetFileDeleted = (file) =>
  _.get(file, 'localStatus', '') !== WIDGET_FILE_DELETED;

export const convertMbToBytes = (mb) => {
  if (_.isNumber(mb)) {
    return mb * 1024 * 1024;
  }
};

export const mapFileDetail = (fileDetail) => {
  if (_.isEmpty(fileDetail)) {
    return {};
  }

  const fileMetaData = JSON.parse(
    decodeURIComponent(_.get(fileDetail, 'x-meta-encoded'))
  );

  return {
    type: _.get(fileDetail, 'content-type'),
    createdAt: _.get(fileDetail, 'x-created-at'),
    doctorId: _.get(fileDetail, 'x-created-by'),
    url: _.get(fileDetail, 'x-url'),
    name: _.get(fileMetaData, 'fileName', ''),
    description: _.get(fileMetaData, 'description', '')
  };
};
