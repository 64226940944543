import { useTranslation } from 'react-i18next';

import _ from 'lodash';

/**
 * Prepares props for dropdown toggles
 * @param {object} params contains list of overridable params
 * | @param {string} placeholder provides placeholder in case item is not selected
 * | @param {boolean} disabled provides different selected item in case item is disabled
 * | @param {array} items list of items
 * | @param {string} value selected item's value/id
 * | @param {string} className className to override default
 *
 * @returns {object}
 * | @returns {object} dropdownToggleProps: props for dropdown toggle component
 * | @returns {string} selectedItem: selected item to be shown in dropdown toggle
 */
export const useDropdownToggleProps = ({
  placeholder,
  disabled,
  items,
  value,
  className
} = {}) => {
  const { t: translate } = useTranslation();

  const emptyText = _.isEmptySafe(placeholder)
    ? translate('common:empty')
    : placeholder;

  const safeFallbackText = disabled ? '/' : emptyText;
  const selectedItem = _.findDefault(
    items,
    { id: value },
    { name: safeFallbackText }
  );

  const safeClassName = className || 'moshi-rs-dropdown-toggle';

  return {
    dropdownToggleProps: { className: safeClassName, disabled },
    selectedItem
  };
};
