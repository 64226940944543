import produce from 'immer';
import _ from 'lodash';

import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT
} from '../../utils/billing';
import { EVENT_TYPE_QUEUE } from '../../utils/constants/calendarConstants';
import { SEARCH_RESULT_ENTITY_TYPE_PATIENT } from '../../utils/constants/entityConstants';
import { PATIENT_SORT_BY_LAST_FIRST_NAME } from '../../utils/constants/patientConstants';
import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../utils/constants/tableConstants';
import {
  addOrderToMergeConflicts,
  mapRemindersForPatient
} from '../../utils/mappers/patient-mappers';
import moshiConfig from '../../utils/moshiConfig';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../utils/table';

import { EMPTY_ACTION } from '../common/actionTypes';

import { ADD_ADVANCE_SUCCESS } from '../billing/advance/advanceTypes';
import { ADD_INVOICE_SUCCESS } from '../billing/invoice/invoiceTypes';
import {
  GET_PATIENTS_IN_PROGRESS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAILURE,
  SET_PATIENTS_COUNT,
  GET_PATIENT_BILLS_IN_PROGRESS,
  GET_PATIENT_BILLS_SUCCESS,
  GET_PATIENT_BILLS_FAILURE,
  UPDATE_PATIENT_UNBILLED_ITEMS_IN_PROGRESS,
  GET_PATIENT_UNBILLED_ITEMS_IN_PROGRESS,
  UPDATE_PATIENT_UNBILLED_ITEMS_SUCCESS,
  GET_PATIENT_UNBILLED_ITEMS_SUCCESS,
  UPDATE_PATIENT_UNBILLED_ITEMS_FAILURE,
  GET_PATIENT_UNBILLED_ITEMS_FAILURE,
  SET_PATIENT_BILL_LIST_ORDER,
  SET_PATIENT_BILL_LIST_PAGINATION,
  GET_PATIENT_SUCCESS,
  ADD_PATIENT_SUCCESS,
  UPDATE_PATIENT_SUCCESS,
  GET_PATIENT_IN_PROGRESS,
  GET_PATIENT_FAILURE,
  SET_PATIENT_LIST_FILTER,
  SET_PATIENT_LIST_ORDER,
  SET_PATIENT_LIST_PAGINATION,
  UPDATE_PATIENT_FAILURE,
  TOGGLE_PATIENT_SIDE_PANEL_FORM,
  GET_PATIENT_EVENT_LIST_SUCCESS,
  GET_PATIENT_EVENT_LIST_FAILURE,
  GET_PATIENT_EVENT_LIST_IN_PROGRESS,
  CLEAR_PATIENT_EVENT_LIST,
  GET_PATIENT_STATS_SUCCESS,
  GET_PATIENT_STATS_IN_PROGRESS,
  GET_PATIENT_STATS_FAILURE,
  CLEAR_PATIENT_STATS,
  GET_ALL_PATIENT_BILLS_SUCCESS,
  SET_PATIENT_BILLED_ITEM_LIST_ORDER,
  SET_PATIENT_BILLED_ITEM_LIST_PAGINATION,
  GET_PATIENT_OVERLAY_DETAIL_IN_PROGRESS,
  GET_PATIENT_OVERLAY_DETAIL_FAILURE,
  GET_PATIENT_OVERLAY_DETAIL_SUCCESS,
  CLEAR_PATIENT_OVERLAY_DETAIL,
  CLEAR_PATIENT_LIST_FILTER,
  GET_MERGE_PATIENT_DRY_RUN_SUCCESS,
  GET_MERGE_PATIENT_DRY_RUN_FAILURE,
  GET_MERGE_PATIENT_DRY_RUN_IN_PROGRESS,
  MERGE_PATIENT_IN_PROGRESS,
  MERGE_PATIENT_SUCCESS,
  MERGE_PATIENT_FAILURE,
  SET_PATIENT_TAGS,
  EXPORT_PATIENT_LIST_SUCCESS,
  EXPORT_PATIENT_LIST_IN_PROGRESS,
  EXPORT_PATIENT_LIST_FAILURE,
  REMOVE_PATIENT_TAG,
  CLEAR_PATIENT_TAG_LIST
} from './patientTypes';

import { LOGOUT } from '../auth/authReducer';
import cachedPatientReducer from './cachedPatientReducer';

import patientSchemaDefaults from '../../containers/patient/detail/patientSchemaDefaults';

export const initialState = {
  detail: null,
  loadingPatient: false,
  list: [],
  loadingList: false,
  allPatientsCount: 0,
  bills: {
    list: [],
    loadingList: false,
    sorting: {
      sortBy: ENTITY_FIELD_CREATED_AT_NAME,
      order: ORDER_DESCENDING
    },
    pagination: {
      resultCount: 0,
      pageCount: 0,
      limit: _.get(moshiConfig, 'list.pagination.limit', 10),
      page: 1
    }
  },
  filter: {
    sortBy: PATIENT_SORT_BY_LAST_FIRST_NAME,
    order: ORDER_ASCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: _.get(moshiConfig, 'list.pagination.limit', 20),
    page: 1
  },
  cave: {
    data: {},
    loading: false,
    didPoll: false
  },
  unbilledItems: {
    list: [],
    unbilledCount: 0,
    loadingList: false
  },
  billedItems: {
    list: [],
    loadingList: false,
    filter: {
      sortBy: 'date',
      order: ORDER_DESCENDING
    },
    pagination: {
      resultCount: 0,
      pageCount: 0,
      limit: _.get(moshiConfig, 'list.pagination.limit', 10),
      page: 1
    }
  },
  isPatientSidepanelFormOpen: false,
  events: {
    list: [],
    queueList: [],
    loadingList: false
  },
  stats: {
    loadingStats: false,
    data: {}
  },
  overlayDetail: {
    loadingDetail: false,
    data: {}
  },
  merge: {
    loadingData: false,
    patientData: {
      conflicts: [],
      data: {}
    },
    mergingPatient: false
  },
  export: {
    loadingExport: false
  },
  cachedPatient: {
    patientList: {},
    hiddenMergeTiles: []
  }
};

const patientReducer = (state, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;
    let patients;
    let sortBy;
    let order;
    let paginationName;
    let paginationValue;
    let maxResultsRaw;
    let maxResults;

    switch (type) {
      case GET_PATIENTS_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_PATIENTS_SUCCESS:
        patients = payload.response.data;
        _.map(patients, (item) => {
          item.entityType = SEARCH_RESULT_ENTITY_TYPE_PATIENT;

          return item;
        });

        maxResultsRaw = _.get(
          payload,
          'response.headers.x-total-count',
          draft.pagination.limit
        );

        maxResults = _.parseInt(maxResultsRaw);

        draft.pagination.resultCount = maxResults;
        draft.pagination.pageCount = _.ceil(
          maxResults / draft.pagination.limit
        );
        draft.loadingList = false;
        draft.list = patients;
        break;
      case GET_PATIENTS_FAILURE:
        draft.loadingList = false;
        break;
      case SET_PATIENTS_COUNT:
        draft.allPatientsCount = payload.count;
        break;
      case GET_PATIENT_BILLS_IN_PROGRESS:
        draft.bills.loadingList = true;
        break;
      case GET_PATIENT_BILLS_SUCCESS:
        draft.bills.list = _.get(payload, 'response.data', []);

        const maxBillResultsRaw = _.get(
          payload,
          'response.headers.x-total-count',
          draft.pagination.limit
        );
        const maxBillResults = _.parseInt(maxBillResultsRaw);

        draft.bills.pagination.resultCount = maxBillResults;
        draft.bills.pagination.pageCount = _.ceil(
          maxBillResults / draft.bills.pagination.limit
        );
        draft.bills.loadingList = false;
        break;
      case GET_ALL_PATIENT_BILLS_SUCCESS:
        const allBills = _.get(payload, 'response.data', []);
        const filteredBills = _.filter(allBills, (bill) => {
          const status = _.getNonEmpty(bill, 'status', []);

          return !_.includesAny(status, [
            INVOICE_STATUS_DRAFT,
            INVOICE_STATUS_CANCELED
          ]);
        });

        const arrayOfBillItems = _.map(filteredBills, (bill) => {
          const items = _.getNonEmpty(bill, 'items', []);

          return _.map(items, (item) => {
            if (_.isEmptySafe(item, 'id')) {
              item.id = _.kebabCase(item.name);
            }

            return {
              ...bill,
              ...item,
              billId: bill.id,
              uniqueKey: `${bill.id}-${item.id}`
            };
          });
        });
        const allBillItems = _.flatten(arrayOfBillItems);

        maxResultsRaw = _.get(
          payload,
          'response.headers.x-total-count',
          draft.pagination.limit
        );

        maxResults = _.parseInt(maxResultsRaw);

        draft.billedItems.pagination.resultCount = maxResults;
        draft.billedItems.pagination.pageCount = _.ceil(
          maxResults / draft.pagination.limit
        );
        draft.billedItems.loadingList = false;

        draft.billedItems.list = allBillItems;
        break;
      case GET_PATIENT_BILLS_FAILURE:
        draft.bills.loadingList = false;
        break;
      case SET_PATIENT_BILLED_ITEM_LIST_ORDER:
        sortBy = _.getNonEmpty(payload, 'sortBy', null);
        order = _.get(payload, 'order', ORDER_ASCENDING);

        draft.billedItems.filter.sortBy = sortBy;
        draft.billedItems.filter.order = order;
        break;
      case SET_PATIENT_BILLED_ITEM_LIST_PAGINATION:
        paginationName = _.getNonEmpty(payload, 'paginationName', null);
        paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.billedItems.pagination[paginationName] = paginationValue;
        }
        break;
      case UPDATE_PATIENT_UNBILLED_ITEMS_IN_PROGRESS:
      case GET_PATIENT_UNBILLED_ITEMS_IN_PROGRESS:
        draft.unbilledItems.loadingList = true;
        break;
      case UPDATE_PATIENT_UNBILLED_ITEMS_SUCCESS:
      case GET_PATIENT_UNBILLED_ITEMS_SUCCESS:
        draft.unbilledItems.list = _.get(payload, 'response.data', []);

        const unbilledCount = _.get(
          payload,
          'response.headers.x-total-count',
          0
        );

        draft.unbilledItems.unbilledCount = unbilledCount;
        draft.unbilledItems.loadingList = false;
        break;
      case UPDATE_PATIENT_UNBILLED_ITEMS_FAILURE:
      case GET_PATIENT_UNBILLED_ITEMS_FAILURE:
        draft.unbilledItems.loadingList = false;
        break;
      case ADD_INVOICE_SUCCESS:
      case ADD_ADVANCE_SUCCESS:
        const issuedBillingItems = _.get(payload, 'response.data.items', []);
        const issuedBillingItemIds = _.map(
          issuedBillingItems,
          (item) => item.unbilledItemID
        );
        const filteredBillingItems = _.filter(
          draft.unbilledItems.list,
          (unbilledItem) => !_.includes(issuedBillingItemIds, unbilledItem.id)
        );

        draft.unbilledItems.list = filteredBillingItems;
        break;
      case SET_PATIENT_BILL_LIST_ORDER:
        sortBy = _.getNonEmpty(payload, 'sortBy', null);
        order = _.get(payload, 'order', ORDER_ASCENDING);

        draft.bills.sorting.sortBy = sortBy;
        draft.bills.sorting.order = order;
        break;
      case SET_PATIENT_BILL_LIST_PAGINATION:
        const billPaginationName = _.getNonEmpty(
          payload,
          'paginationName',
          null
        );
        const billPaginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(billPaginationName)) {
          draft.bills.pagination[billPaginationName] = billPaginationValue;
        }
        break;
      case GET_PATIENT_SUCCESS:
      case ADD_PATIENT_SUCCESS:
      case UPDATE_PATIENT_SUCCESS:
        const detail = _.get(payload, 'response.data', {});

        if (_.isEmpty(detail.doctor)) {
          _.set(detail, 'doctor', {});
        }

        draft.loadingPatient = false;
        draft.detail = mapRemindersForPatient(detail);
        draft.overlayDetail.data = detail;
        break;
      case GET_PATIENT_IN_PROGRESS:
        draft.loadingPatient = true;
        draft.detail = undefined;
        break;
      case GET_PATIENT_FAILURE:
        break;
      case SET_PATIENT_LIST_FILTER:
        const filterName = _.getNonEmpty(payload, 'filterName', null);
        const filterValue = _.get(payload, 'filterValue');

        if (!_.isEmpty(filterName)) {
          draft.filter[filterName] = _.isArray(filterValue)
            ? filterValue.join(',')
            : filterValue;
          draft.pagination.page = 1;
        }
        break;
      case SET_PATIENT_LIST_ORDER:
        sortBy = _.getNonEmpty(payload, 'sortBy', null);
        order = _.get(payload, 'order', initialState.filter.order);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_PATIENT_LIST_PAGINATION:
        paginationName = _.getNonEmpty(payload, 'paginationName', null);
        paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case UPDATE_PATIENT_FAILURE:
        draft.loadingPatient = false;
        break;
      case TOGGLE_PATIENT_SIDE_PANEL_FORM:
        const fallbackIsOpen = _.get(draft, 'isPatientSidepanelFormOpen');
        const isOpen = _.get(payload, 'isOpen', fallbackIsOpen);

        draft.isPatientSidepanelFormOpen = isOpen;
        break;
      case GET_PATIENT_EVENT_LIST_SUCCESS:
        const events = _.get(payload, 'response.data', []);

        draft.events.loadingList = false;
        draft.events.list = _.filter(
          events,
          (event) => event.type !== EVENT_TYPE_QUEUE
        );
        draft.events.queueList = _.filter(
          events,
          (event) => event.type === EVENT_TYPE_QUEUE
        );
        break;
      case GET_PATIENT_EVENT_LIST_IN_PROGRESS:
        draft.events.loadingList = true;
        break;
      case GET_PATIENT_EVENT_LIST_FAILURE:
        draft.events.loadingList = false;
        break;
      case CLEAR_PATIENT_EVENT_LIST:
        draft.events.list = [];
        draft.events.queueList = [];
        break;
      case GET_PATIENT_OVERLAY_DETAIL_IN_PROGRESS:
        draft.overlayDetail.loadingDetail = true;
        break;
      case GET_PATIENT_OVERLAY_DETAIL_FAILURE:
        draft.overlayDetail.loadingDetail = false;
        break;
      case GET_PATIENT_OVERLAY_DETAIL_SUCCESS:
        const responsePatient = _.getNonEmpty(payload, 'response.data', {});
        const patient = _.getNonEmpty(payload, 'patient', responsePatient);

        draft.overlayDetail.loadingDetail = false;
        draft.overlayDetail.data = patient;
        break;
      case CLEAR_PATIENT_OVERLAY_DETAIL:
        draft.overlayDetail.data = {};
        break;
      case GET_PATIENT_STATS_IN_PROGRESS:
        draft.stats.loadingStats = true;
        break;
      case GET_PATIENT_STATS_FAILURE:
        draft.stats.loadingStats = false;
        break;
      case GET_PATIENT_STATS_SUCCESS:
        const stats = _.get(payload, 'response.data', {});

        draft.stats.loadingStats = false;
        draft.stats.data = stats;
        break;
      case CLEAR_PATIENT_STATS:
        draft.stats.data = {};
        break;
      case GET_MERGE_PATIENT_DRY_RUN_SUCCESS:
        const conflicts = _.get(
          payload,
          'response.data.patientData.conflicts',
          []
        );

        const orderedConflicts = _.orderBy(
          addOrderToMergeConflicts(conflicts),
          (item) => item.order
        );

        const data = _.get(payload, 'response.data.patientData.data', {});

        /**
         * locationIDs are converted to a comma separated string to comply with the
         * form props and shapes.
         */
        if (!_.isEmptySafe(data, 'doctor.locationIDs')) {
          data.doctor.locationIDs = data.doctor.locationIDs.join(',');
        }

        /**
         * Custom patient fields should not be displayed. Custom fields should
         * be automatically merged (using the values from the targetID - target patient)
         */
        draft.merge.patientData.conflicts = _.filter(
          orderedConflicts,
          (conflict) => {
            const isDefaultSchema = _.get(
              patientSchemaDefaults,
              `uiSchema.${conflict.key}`,
              {}
            );

            return !_.isEmpty(isDefaultSchema);
          }
        );
        draft.merge.patientData.data = data;
        draft.merge.loadingData = false;
        break;
      case GET_MERGE_PATIENT_DRY_RUN_FAILURE:
        draft.merge.loadingData = false;
        break;
      case GET_MERGE_PATIENT_DRY_RUN_IN_PROGRESS:
        draft.merge.loadingData = true;
        break;
      case MERGE_PATIENT_IN_PROGRESS:
        draft.merge.mergingPatient = true;
        break;
      case MERGE_PATIENT_SUCCESS:
      case MERGE_PATIENT_FAILURE:
        draft.merge.mergingPatient = false;
        break;
      case EXPORT_PATIENT_LIST_IN_PROGRESS:
        draft.export.loadingExport = true;
        break;
      case EXPORT_PATIENT_LIST_FAILURE:
      case EXPORT_PATIENT_LIST_SUCCESS:
        draft.export.loadingExport = false;
        break;
      case SET_PATIENT_TAGS:
        draft.detail.tags = _.getNonEmpty(payload, 'payload', []);
        break;
      case REMOVE_PATIENT_TAG:
        const patientTags = _.getNonEmpty(state, 'detail.tags', []);
        const removedTagId = _.getNonEmpty(payload, 'payload');

        draft.detail.tags = patientTags.filter(
          (tag) => tag.id !== removedTagId
        );
        break;
      case CLEAR_PATIENT_TAG_LIST:
        if (!_.isEmptySafe(draft, 'details')) {
          draft.detail.tags = [];
        }
        break;
      case CLEAR_PATIENT_LIST_FILTER:
        draft.filter = initialState.filter;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default (state = initialState, action = EMPTY_ACTION) => ({
  ...patientReducer(state, action),
  cachedPatient: cachedPatientReducer(state.cachedPatient, action)
});
