import _ from 'lodash';

import LocationGeoModel from './LocationGeoModel';

class LocationAddressModel {
  constructor(street1, geo) {
    this.street1 = street1;
    this.street2 = '';
    this.town = '';
    this.zip = '';
    this.region = '';
    // TODO: Temp for demo
    this.country = 'SI';
    this.geo = _.isPlainObject(geo) ? new LocationGeoModel(geo) : null;
    this.phoneNumber = '';
  }

  static getPayload(model) {
    return { ...model };
  }
}

export default LocationAddressModel;
