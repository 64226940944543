export default {
  id: 'administrativeData/FLOW/v1',
  formSchema: {
    title: 'widget:administrativeData.flow.title',
    type: 'object',
    required: ['id001'],
    properties: {
      id001: {
        type: 'string',
        title: 'widget:administrativeData.flow.title'
      }
    }
  },
  uiSchema: {
    id001: {
      'ui:widget': 'customFlowWidget',
      'ui:options': {
        widgetDataType: 'widgetDataType_FLOWS',
        entityType: 'SUB_ENTITY_TYPE_FLOW'
      },
      'x-defaultFormDataKey': 'flowID'
    },
    'x-readOnly': true,
    'x-customClass': 'admin-data-widget',
    'x-cancelBtnSize': 'sm',
    'x-saveBtnSize': 'sm'
  }
};
