import axios from 'axios';
import { takeEvery, select, put } from 'redux-saga/effects';

import { getEncounterUrl } from '../../../../utils/api';
import { apiCall } from '../../../../utils/redux/sagaUtils';

import {
  REMOVE_WIDGET,
  REMOVE_WIDGET_SUCCESS,
  REMOVE_WIDGET_TRIGGER,
  REMOVE_WIDGET_UPDATED,
  UPDATE_WIDGET_LIST_REMOVE
} from '../widgetTypes';

export function* removeWidgetApiRequest(action) {
  const { encounterId, widget } = action;
  const state = yield select();
  const encounterUrl = getEncounterUrl(() => state, encounterId);

  yield apiCall(
    REMOVE_WIDGET,
    axios.delete,
    [`${encounterUrl}/widgets/${widget.id}`],
    { widget }
  );
}

export function* removeWidgetStoreUpdate(action) {
  const {
    additionalData: { widget }
  } = action;

  yield put({
    type: UPDATE_WIDGET_LIST_REMOVE,
    widgetId: widget.id
  });
  yield put({ type: REMOVE_WIDGET_UPDATED, widget });
}

export const removeWidgetSagas = [
  takeEvery(REMOVE_WIDGET_TRIGGER, removeWidgetApiRequest),
  takeEvery(REMOVE_WIDGET_SUCCESS, removeWidgetStoreUpdate)
];
