import {
  EXTERNAL,
  INBOUND_REQUEST,
  INBOUND_SUBMISSION,
  INBOUND_TEMPLATE,
  OUTBOUND_DOCUMENT,
  OUTBOUND_TEMPLATE
} from '../../utils/constants/documentConstants';

import { formatting } from './date';

export default {
  title: 'Dokumenti',
  document: 'Dokument',
  version: `Verzija {{ version }} ∙ {{ date, ${formatting.longDateWithTime}}}`,
  new: 'Nov dokument',
  questionnaire: 'Vprašalnik',
  flows: 'Tokovi',
  noFlows: 'Ni uporabljen v nobenem toku',
  templates: 'Predloge',
  template: 'Predloga',
  fromTemplate: 'Po predlogi',
  newTemplate: 'Nov',
  createNew: 'Ustvarite nov',
  createDocument: 'Ustvarite dokument',
  saveAsTemplate: 'Shranite kot predlogo',
  removeDocument: 'Odstanite dokument',
  removeTemplate: 'Odstanite predlogo',
  removeFromQueue: 'Odstranite iz čakalne vrste',
  copyOf: 'Kopija "{{entity.name}}"',
  removeFromQueueMessage:
    'Ali ste prepričani, da želite odstraniti dokument <0>{{entity.name}}</0> iz čakalne vrste?',
  attachment: {
    title: 'Priponke',
    all: 'Vse priponke'
  },
  headerFooterPreviewLabel: 'Predogled glave in noge',
  headerPreviewTitle: 'Predogled glave in noge',
  inbound: {
    title: 'Vhodni',
    description:
      'Obrazci in vprašalniki, ki jih morajo pacienti izpolniti in podpisati.',
    submitted: 'Izpolnjeni',
    waiting: 'V čakalnici',
    templates: 'Predloge',
    inboundEntity: 'Vhodni dokument',
    inboundEntities: 'Vhodni dokumenti',
    requestEntity: 'Čakajoč dokument',
    submissionEntity: 'Izpolnjen dokument',
    templateEntity: 'Predloga dokumenta',
    fromTemplate: 'Po predlogi',
    newTemplate: 'Nov',
    createNew: 'Ustvarite novega',
    createFromTemplate: 'Ustvarite dokument po predlogi',
    createNewTemplate: 'Ustvarite nov dokument',
    searchTemplates: 'Iščite po vhodnih predlogah',
    new: 'Nov dokument - $t(document:inbound.title)',
    addField: 'Dodajte polje',
    addFormField: 'Dodajte polje',
    addSectionField: 'Dodajte polje znotraj sekcije',
    addOption: 'Dodajte možnost',
    addLabel: 'Dodajte oznako',
    formPreview: 'Predogled dokumenta',
    saveTemplate: 'Shranite predlogo',
    submitRequest: 'Pošljite',
    editDocument: 'Uredite dokument',
    disabledPreview:
      'Vprašanja niso pravilno oblikovana. Pred nadaljevanjem morate popraviti napake.',
    disabledSubmitRequest:
      'Pacient ni izbran. Najprej izberite pacienta, kateremu želite poslati dokument.',
    isClinical: 'Lahko je del obravnave',
    documentSettings: 'Nastavitve dokumenta',
    removeTemplateTitle: 'Odstranite predlogo dokumenta',
    confirmSubmissionRemovalMessage:
      'Ali ste prepričani, da želite odstraniti izpolnjen dokument <0>{{ itemName }}</0>, pacienta {{ entity.patient.firstName }} {{ entity.patient.lastName }}?',
    confirmRequestRemovalMessage:
      'Ali ste prepričani, da želite odstraniti dokument <0>{{ itemName }}</0>, ki čaka na vnos pacienta {{ entity.patient.firstName }} {{ entity.patient.lastName }}?',
    confirmTemplateRemovalMessage:
      'Ali ste prepričani, da želite odstraniti predlogo dokumenta <0>{{ itemName }}</0>?',
    signatureField: 'Polje za podpis',
    signHere: 'Podpišite tukaj',
    fields: {
      documentTitle: 'Naslov dokumenta',
      freeText: 'Besedilo',
      freeTextPlaceholder: 'Vnesite prosti teskt',
      freeTextQuestion: 'Kratek odgovor',
      freeTextQuestionPlaceholder: 'Vpišite vaše vprašanje',
      longFreeTextQuestion: 'Dolg odgovor',
      checkboxQuestion: 'Potrditvena polja',
      checkboxOptionPlaceholder: 'Vnesite potrditveno polje',
      radioQuestion: 'Izbirna polja',
      radioOptionPlaceholder: 'Vnesite izbirno polje',
      addCheckbox: 'Dodajte potrditveno polje',
      addRadio: 'Dodajte izbirno polje',
      addConditional: 'Dodajte podvprašanje',
      conditionalQuestionPlaceholder: 'Vnesite vaše podvprašanje',
      scaleQuestion: 'Ocenjevalna lestvica',
      dateQuestion: 'Polje za vnos datuma',
      section: 'Sekcija',
      signature: 'Podpis'
    },
    formStatuses: {
      submitted: 'Izpolnjen',
      request: 'Čakajoč'
    },
    formStatusData: `{{ personName }} • {{ date, ${formatting.shortDateWithTime} }}`
  },
  outbound: {
    title: 'Izhodni',
    outboundEntity: 'Izhodni dokument',
    outboundEntities: 'Izhodni dokumenti',
    description: 'Dokumenti za paciente',
    issued: 'Izdani',
    new: 'Nov izhodni dokument',
    disabledPreview: 'Dokument nima izpolnjenih obveznih polj',
    disabledHeaderPreview: 'Za prikaz predogleda najprej shranite spremembe',
    searchTemplates: 'Iščite po izhodnih predlogah',
    createNewTemplate: 'Ustvarite nov izhodni dokument',
    includeHeaderAndFooter: 'Vključite glavo in nogo po predlogi',
    pdfIsGenerating: 'PDF se generira...'
  },
  external: {
    title: 'Zunanji',
    externalEntity: 'Zunanji dokument',
    externalEntities: 'Zunanji dokumenti',
    description: 'Naložene datoteke, slike in različni dokumenti'
  },
  alert: {
    templateCreated: 'Predloga dokumenta je bila uspešno dodana.',
    templateUpdated: 'Predloga dokumenta je bila uspešno posodobljena.',
    templateRemoved: 'Predloga dokumenta je bila uspešno odstranjena.',
    requestCreated:
      'Zahteva za izpolnitev dokumenta je bila uspešno ustvarjena.',
    requestUpdated:
      'Zahteva za izpolnitev dokumenta je bila uspešno posobljena.',
    requestRemoved:
      'Zahteva za izpolnitev dokumenta je bila uspešno odstranjena.',
    submissionCreated: 'Dokument je bil uspešno izpolnjen.',
    submissionUpdated: 'Izpolnjen dokument je bil uspešno posodobljen.',
    submissionRemoved: 'Izpolnjen dokument je bil uspešno odstranjen.',
    fileCreated: 'Datoteka je bila uspešno dodana.',
    fileUpdated: 'Datoteka je bila uspešno posodobljena.',
    fileRemoved: 'Datoteka je bila uspešno odstranjena.',
    documentCreated: 'Dokument je bil uspešno dodan.',
    documentUpdated: 'Dokument je bil uspešno posodobljen.',
    documentRemoved: 'Dokument je bil uspešno odstranjen.',
    documentPdfGenerationError:
      'Pri ustvarjanju PDF datoteke je prišlo do napake.'
  },
  file: {
    title: 'Datoteka',
    entity: 'Datoteko',
    drop: 'Tukaj kliknite ali odložite dokument.',
    upload: 'Naložite dokument',
    uploading: 'Nalaganje dokumenta...'
  },
  preview: 'Predogled',
  previewNotAvailable: 'Predogled ni na voljo',
  columns: {
    documentName: 'Ime dokumenta',
    documentType: 'Tip dokumenta',
    patientName: 'Pacient',
    createdBy: 'Ustvaril',
    createdAt: 'Ustvarjeno',
    updatedAt: 'Posodobljeno',
    fileSize: 'Velikost'
  },
  modal: {
    title: 'Naložite',
    descriptionText:
      'Največja velikost datoteke je 100 MB. Naložite lahko PDF, Word, Excel, slike ali DICOM datoteke.',
    descriptionTextDicomMode:
      'Največja velikost datoteke je 100 MB. Naložite lahko DICOM datoteke.',
    documentName: 'Ime dokumenta',
    selectPatient: 'Izberite pacienta',
    renameDocument: 'Preimenujte dokument',
    duplicateTemplate: 'Podvojite predlogo'
  },
  types: {
    [INBOUND_SUBMISSION]: 'Izpolnjen vhodni dokument',
    [INBOUND_REQUEST]: 'Vhodni dokument v čakalni vrsti',
    [OUTBOUND_DOCUMENT]: 'Izhodni dokument',
    [EXTERNAL]: 'Zunanji dokument',
    [INBOUND_TEMPLATE]: 'Predloga vhodnega dokumenta',
    [OUTBOUND_TEMPLATE]: 'Predloga izhodnega dokumenta',
    document: 'Dokument',
    questionnaire: 'Vprašalnik',
    jpg: 'JPG',
    jpeg: 'JPEG',
    png: 'PNG',
    pdf: 'PDF',
    excel: 'Excel',
    word: 'Word'
  },
  pdfView: {
    page: 'Stran',
    of: 'od'
  },
  documents: {
    title: 'Dokument',
    plural: 'Dokumenti',
    add: 'Dodajte dokument',
    edit: 'Uredite dokument',
    new: 'Nov dokument',
    select: 'Izberite obstoječi dokument.',
    choose: 'Izberite dokument',
    description: 'Izberete lahko med obstoječimi dokumenti.',
    remove: 'Odstranite dokument',
    removeDescription: 'Želite odstraniti dokument: <0>{{ entity.name }}</0>?'
  },
  questionnaires: {
    title: 'Vprašalnik',
    plural: 'Vprašalniki',
    add: 'Dodajte vprašalnik',
    edit: 'Uredite vprašalnik',
    new: 'Nov vprašalnik',
    select: 'Izberite obstoječi vprašalnik',
    choose: 'Izberite vprašalnik',
    description: 'Izberete lahko med obstoječimi vprašalniki.',
    remove: 'Odstranite vprašalnik',
    removeDescription: 'Želite odstraniti vprašalnik: <0>{{ entity.name }}</0>?'
  },
  bottomBar: {
    saveTemplate: 'Shranite predlogo',
    submitRequest: 'Pošljite',
    editDocument: 'Uredite dokument',
    saveAsNewTemplate: 'Shranite kot novo predlogo'
  },
  smartcodes: {
    currentDate: 'Današnji datum',
    documentTitle: 'Naslov dokumenta',
    patientFullName: 'Polno ime pacienta',
    patientFirstName: 'Ime pacienta',
    patientLastName: 'Priimek pacienta',
    patientGender: 'Spol pacienta',
    patientDob: 'Pacientov datum rojstva',
    patientFullAddress: 'Naslov pacienta',
    patientStreet: 'Pacientova ulica',
    patientZip: 'Poštna številka pacienta',
    patientCity: 'Kraj pacienta',
    patientCountry: 'Država pacienta',
    patientEmail: 'Email pacienta',
    patientPhoneNumber: 'Telefonska številka pacienta',
    patientInsuranceNumber: 'Pacientova številka zavarovanja',
    patientNextAppointment: 'Pacientov prihajajoči termin',
    patientLastAppointment: 'Pacientov zadnji termin',
    organizationName: 'Ime organizacije',
    organizationAddress: 'Naslov organizacije',
    organizationAddress2: 'Drugi naslov organizacije',
    organizationZip: 'Poštna številka organizacije',
    organizationCity: 'Kraj organizacije',
    organizationCountry: 'Država organizacije'
  }
};
