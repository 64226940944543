import { createActionTypeArray } from '../../common/actionTypes';

export const GET_FORM_SUBMISSION_DETAIL =
  'documents/GET_FORM_SUBMISSION_DETAIL';
export const REMOVE_FORM_SUBMISSION = 'documents/REMOVE_FORM_SUBMISSION';
export const CLEAR_FORM_SUBMISSION_DETAIL =
  'documents/CLEAR_FORM_SUBMISSION_DETAIL';

export const [
  GET_FORM_SUBMISSION_DETAIL_IN_PROGRESS,
  GET_FORM_SUBMISSION_DETAIL_SUCCESS,
  GET_FORM_SUBMISSION_DETAIL_FAILURE
] = createActionTypeArray(GET_FORM_SUBMISSION_DETAIL);

export const [
  REMOVE_FORM_SUBMISSION_IN_PROGRESS,
  REMOVE_FORM_SUBMISSION_SUCCESS,
  REMOVE_FORM_SUBMISSION_FAILURE
] = createActionTypeArray(REMOVE_FORM_SUBMISSION);
