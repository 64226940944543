import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { MOSHI_SIZE_LG } from '../../../../utils/constants/sizeConstants';
import { EMPTY_GUID } from '../../../../utils/gen';

import CardMoreActions from '../../table/CardMoreActions';
import FormActionsSwitch from '../button/FormActionsSwitch';

import { selectWidgetSchema } from '../../../../redux/patient/widget/widgetSelectors';

import { CardMoreActionsShape } from '../../../../metadata/shapes/TableShape';

import Visible from '../../layout/Visible';

const usePrepareButtons = (widgetType) => {
  const widgetSchema = useSelector(selectWidgetSchema);

  return useMemo(() => {
    const widgets = _.getNonEmpty(widgetSchema, 'widgets', null);

    let saveButtonSize;
    let cancelButtonSize;

    if (_.isString(widgetType) && _.isPlainObject(widgets)) {
      saveButtonSize = _.getNonEmpty(
        widgets,
        `${widgetType}.uiSchema.x-saveBtnSize`,
        MOSHI_SIZE_LG
      );

      cancelButtonSize = _.getNonEmpty(
        widgets,
        `${widgetType}.uiSchema.x-cancelBtnSize`,
        MOSHI_SIZE_LG
      );
    }

    return {
      saveButtonSize,
      cancelButtonSize
    };
  }, [widgetSchema, widgetType]);
};

const CustomWidgetActions = ({
  widget,
  widgetActions,
  editMode,
  onCancel,
  hideActionsButtons
}) => {
  const widgetType = useMemo(() => _.getNonEmpty(widget, 'widgetType', null), [
    widget
  ]);
  const { saveButtonSize, cancelButtonSize } = usePrepareButtons(widgetType);

  return (
    <React.Fragment>
      <CardMoreActions
        actions={widgetActions}
        item={{ ...widget, id: _.getNonEmpty(widget, 'id', EMPTY_GUID) }}
        tabIndex={-1}
      />
      <Visible visible={!hideActionsButtons}>
        {() => (
          <FormActionsSwitch
            editMode={editMode}
            onEdit={_.noop}
            onCancel={onCancel}
            formikAvailable={false}
            cancelButtonSize={cancelButtonSize}
            saveButtonSize={saveButtonSize}
            editButtonInvisible
            tabIndex={-1}
          />
        )}
      </Visible>
    </React.Fragment>
  );
};

CustomWidgetActions.propTypes = {
  widget: PropTypes.shape().isRequired,
  widgetActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape))
    .isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  hideActionsButtons: PropTypes.bool
};

CustomWidgetActions.defaultProps = {
  hideActionsButtons: false
};

export default CustomWidgetActions;
