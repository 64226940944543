import React from 'react';

import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';

import { Span } from '../../HtmlComponents';

import Visible from '../../layout/Visible';

export const CustomWidgetTimeStampLabelComponent = ({
  t: translate,
  editMode,
  dateTime
}) => (
  <Visible
    visible={!editMode}
    component={Span}
    className={'widget-created-at-label grey-label'}
  >
    {translate('date:longDateWithTime', {
      date: dateTime
    })}
  </Visible>
);

CustomWidgetTimeStampLabelComponent.propTypes = {
  t: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  dateTime: PropTypes.string
};

CustomWidgetTimeStampLabelComponent.defaultProps = {
  dateTime: null
};

export default localize(CustomWidgetTimeStampLabelComponent);
