import { createActionTypeArray } from '../common/actionTypes';

export const SET_ANALYTICS_FILTER = 'analytics/SET_ANALYTICS_FILTER';
export const SET_ANALYTICS_FILTERS = 'analytics/SET_ANALYTICS_FILTERS';

// SALES Types
export const GET_ANALYTICS_SALES_TOTAL_DATA =
  'analytics/GET_ANALYTICS_SALES_TOTAL_DATA';

export const GET_ANALYTICS_SALES_BY_DOCTOR =
  'analytics/GET_ANALYTICS_SALES_BY_DOCTOR';

export const GET_ANALYTICS_SALES_BY_PAYMENT_TYPE =
  'analytics/GET_ANALYTICS_SALES_BY_PAYMENT_TYPE';

export const [
  GET_ANALYTICS_SALES_TOTAL_DATA_IN_PROGRESS,
  GET_ANALYTICS_SALES_TOTAL_DATA_SUCCESS,
  GET_ANALYTICS_SALES_TOTAL_DATA_FAILURE
] = createActionTypeArray(GET_ANALYTICS_SALES_TOTAL_DATA);

export const [
  GET_ANALYTICS_SALES_BY_DOCTOR_IN_PROGRESS,
  GET_ANALYTICS_SALES_BY_DOCTOR_SUCCESS,
  GET_ANALYTICS_SALES_BY_DOCTOR_FAILURE
] = createActionTypeArray(GET_ANALYTICS_SALES_BY_DOCTOR);

export const [
  GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_IN_PROGRESS,
  GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_SUCCESS,
  GET_ANALYTICS_SALES_BY_PAYMENT_TYPE_FAILURE
] = createActionTypeArray(GET_ANALYTICS_SALES_BY_PAYMENT_TYPE);

// APPOINTMENTS Types
export const GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA =
  'analytics/GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA';

export const [
  GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA_FAILURE
] = createActionTypeArray(GET_ANALYTICS_APPOINTMENTS_TOTAL_DATA);

export const GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA =
  'analytics/GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA';

export const [
  GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA_FAILURE
] = createActionTypeArray(GET_ANALYTICS_APPOINTMENTS_BY_DOCTOR_DATA);

export const GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA =
  'analytics/GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA';

export const [
  GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA_FAILURE
] = createActionTypeArray(GET_ANALYTICS_APPOINTMENTS_BY_CATEGORY_DATA);

// PATIENTS Types
export const GET_ANALYTICS_PATIENTS_OVERVIEW =
  'analytics/GET_ANALYTICS_PATIENTS_OVERVIEW';

export const GET_ANALYTICS_PATIENTS_ACTIVE =
  'analytics/GET_ANALYTICS_PATIENTS_ACTIVE';

export const GET_ANALYTICS_SALES_PER_PATIENT =
  'analytics/GET_ANALYTICS_SALES_PER_PATIENT';

export const GET_ANALYTICS_APPOINTMENTS_PER_PATIENT =
  'analytics/GET_ANALYTICS_APPOINTMENTS_PER_PATIENT';

export const [
  GET_ANALYTICS_PATIENTS_OVERVIEW_IN_PROGRESS,
  GET_ANALYTICS_PATIENTS_OVERVIEW_SUCCESS,
  GET_ANALYTICS_PATIENTS_OVERVIEW_FAILURE
] = createActionTypeArray(GET_ANALYTICS_PATIENTS_OVERVIEW);

export const [
  GET_ANALYTICS_PATIENTS_ACTIVE_IN_PROGRESS,
  GET_ANALYTICS_PATIENTS_ACTIVE_SUCCESS,
  GET_ANALYTICS_PATIENTS_ACTIVE_FAILURE
] = createActionTypeArray(GET_ANALYTICS_PATIENTS_ACTIVE);

export const [
  GET_ANALYTICS_SALES_PER_PATIENT_IN_PROGRESS,
  GET_ANALYTICS_SALES_PER_PATIENT_SUCCESS,
  GET_ANALYTICS_SALES_PER_PATIENT_FAILURE
] = createActionTypeArray(GET_ANALYTICS_SALES_PER_PATIENT);

export const [
  GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_IN_PROGRESS,
  GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_SUCCESS,
  GET_ANALYTICS_APPOINTMENTS_PER_PATIENT_FAILURE
] = createActionTypeArray(GET_ANALYTICS_APPOINTMENTS_PER_PATIENT);
