import _ from 'lodash';
import nestedObjectAssign from 'nested-object-assign';

import { isValidDate } from './date';
import logger from './logger';
import { guidPattern } from './regexPatterns';

const deepMapKeys = (obj, fn) => {
  const newObject = {};

  _.forOwn(obj, (value, key) => {
    let newValue = value;

    if (_.isPlainObject(newValue)) {
      newValue = _.deepMapKeys(newValue, fn);
    }
    if (_.isArray(newValue)) {
      const tempValue = [];

      _.forEach(newValue, (item) => {
        tempValue.push(_.deepMapKeys(item, fn));
      });
      newValue = tempValue;
    }
    newObject[key] = fn(newValue, key);
  });

  return newObject;
};

const findDefault = (obj, predicate, defaultValue) => {
  const foundObject = _.find(obj, predicate);

  return _.isEmpty(foundObject) ? defaultValue : foundObject;
};

const findOr = (obj, predicates) => {
  for (const predicate of predicates) {
    const matchedObject = _.find(obj, predicate);

    if (!_.isEmptySafe(matchedObject)) {
      return matchedObject;
    }
  }

  return null;
};

const getAndClone = (obj, path, defaultValue) => {
  const foundValue = _.getNonEmpty(obj, path, defaultValue);

  return _.cloneDeep(foundValue);
};

const getNonEmpty = (obj, path, defaultValue) => {
  const foundValue = _.get(obj, path, defaultValue);

  return isEmptyAllTypes(foundValue) ? defaultValue : foundValue;
};

/**
 * getByList - A hook which helps you register events on a component
 *
 * @param {Object} obj - Object to get from
 * @param {String[]} propertyPathCollection - List of property paths
 * @param {any} [fallback=null] - Default for get (depending on the vlaue, might be ignore by _.isEmpty)
 *
 * @example
 *  const propertyValues = _.getByList({ id: 1, name: 'test'}, ['id', 'name']);
 *
 *  console.log(propertyValues) // => [1, 'test']
 *
 * @returns {any[]}
 */
const getByList = (obj, propertyPathCollection, fallback = null) => {
  const propertyValues = [];

  _.forEach(propertyPathCollection, (propPath) => {
    const propValue = _.get(obj, propPath, fallback);

    if (!_.isEmpty(propValue)) {
      propertyValues.push(propValue);
    }
  });

  return propertyValues;
};

/**
 * patchPath
 * @description Method that only patches an object at a specific path
 * @param {Object} obj - Object to get from
 * @param {(String[]|String)} path - List of property paths
 * @param {Object} newValue - Object to patch over existing values
 *
 * @example
 *  const someEntity = { path: { id: 1, name: 'test'} };
 *
 *  _.patchPath(someEntity, 'path', { name: 'test2', newProp: 'newValue' })
 *
 *  console.log(someEntity) // => { path: { id: 1, name: 'test2', newProp: 'newValue' } }
 *
 * @returns {void}
 */
const patchPath = (obj, path, newValue) => {
  const oldValue = _.getNonEmpty(obj, path, {});

  if (_.isPlainObject(obj) && _.isPlainObject(oldValue)) {
    _.set(obj, path, { ...oldValue, ...newValue });
  } else if (_.isArray(obj) && _.isArray(oldValue)) {
    _.set(obj, path, [...oldValue, ...newValue]);
  } else {
    logger.error('Patch only works with objects and arrays');
  }
};

const isEmptySafe = (obj, path = undefined) => {
  if (!_.isString(path) || isEmptyAllTypes(path)) {
    return isEmptyAllTypes(obj);
  }

  const value = _.get(obj, path, {});

  return isEmptyAllTypes(value);
};

const pascalCase = (value) => {
  const camelValue = _.camelCase(value);

  return _.upperFirst(camelValue);
};

export const sentenceCase = (string) => _.upperFirst(string);

const isGuid = (value) => value.match(guidPattern);

const hasOneItem = (array) =>
  _.isArray(array) && !_.isEmpty(array) && array.length === 1;

/**
 * hasMoreThanOneItem
 *  @description Method that checks if there is more than one item in a collection
 * @param array
 *
 * @example
 * _.hasMoreThanOneItem(['one item']) // => false
 * _.hasMoreThanOneItem(['one item', 'second item']) // => true
 *
 * @returns {boolean}
 */
const hasMoreThanOneItem = (array) =>
  _.isArray(array) && !_.isEmpty(array) && array.length > 1;

const pushUniqueBy = (arr, predicate, obj) => {
  if (!_.some(arr, predicate)) {
    arr.push(obj);
  }

  return arr;
};

const pushIfUnique = (arr, item) => {
  const array = _.clone(arr);

  if (!_.some(array, (existingItem) => existingItem === item)) {
    array.push(item);
  }

  return array;
};

/**
 * includesEach
 * @description Checks if destination array includes each item from the predicate
 * @param array Destination array
 * @param arrayPredicate The array from which each value is looped and checked if included
 * @example
 *  _.includesEach([1,2,3,4,5], [1,3,5]) // returns true
 *  _.includesEach([1,2,3,4,5], [1,3,6]) // returns false
 *
 * @return {Boolean}
 */
const includesEach = (array, arrayPredicate) => {
  let isIncluded = true;

  _.forEach(arrayPredicate, (item) => {
    if (!isIncluded) return;

    isIncluded = _.includes(array, item);
  });

  return isIncluded;
};

/**
 * includesEither
 * @description Checks if predicate value is partially or fully includes any item from the array
 * @param array Destination array
 * @param {string} predicate Value checked if included
 * @example
 *  _.includesEither(["1","2","3","4","5"], "1") // returns true
 *  _.includesEither(["1","2","3","4","5"], "6") // returns false
 *
 * @return {Boolean}
 */
const includesEither = (array, predicate) => {
  let isIncluded = false;

  _.forEach(array, (item) => {
    if (isIncluded) return;

    isIncluded = _.includes(item, predicate);
  });

  return isIncluded;
};

/**
 * includesAny
 * @description Checks if destination array includes any item from the predicate
 * @param array Destination array
 * @param arrayPredicate The array from which each value is looped and checked if included
 * @example
 *  _.includesAny([1,2,3,4,5], [1]) // returns true
 *  _.includesAny([1,2,3,4,5], [3]) // returns true
 *  _.includesAny([1,2,3,4,5], [4,5]) // returns true
 *  _.includesAny([1,2,3,4,5], [6]) // returns false
 *
 * @return {Boolean}
 */
const includesAny = (array, arrayPredicate) => {
  let isIncluded = false;

  _.forEach(arrayPredicate, (item) => {
    if (isIncluded) return;

    isIncluded = _.includes(array, item);
  });

  return isIncluded;
};

const firstBy = (array, predicate) => {
  const filteredArray = _.filter(array, predicate);

  return _.first(filteredArray);
};

const concatBy = (array, path) => {
  const remappedArrayOfArrays = array.map((object) =>
    _.getNonEmpty(object, path, [])
  );

  return _.flatten(remappedArrayOfArrays);
};

const isEmptyAllTypes = (value) => {
  if (value instanceof Date) {
    return !isValidDate(value);
  }
  if (_.isArrayBuffer(value)) {
    return false;
  }

  switch (typeof value) {
    case 'number':
      return !_.isFinite(value);
    case 'boolean':
      return !_.isBoolean(value);
    case 'function':
      return !_.isFunction(value);
    default:
      return _.isEmpty(value);
  }
};

const nestedAssign = (TargetClass, source, targetParams = []) => {
  let targetObject = _.cloneDeep(TargetClass);

  if (_.isFunction(TargetClass)) {
    targetObject = new TargetClass(...targetParams);
  }

  return nestedObjectAssign(targetObject, source);
};

const nestedListAssign = (list, TargetClass) => {
  const assignedList = [];

  _.forEach(list, (listItem) => {
    const assignedTarget = _.nestedAssign(TargetClass, listItem);

    assignedList.push(assignedTarget);
  });

  return assignedList;
};

const isFunctionSafe = (obj, path) => {
  const value = _.get(obj, path, null);

  return _.isFunction(value);
};

const pushOrRemoveIntoArray = (array, value) => {
  let tempArray = _.isArray(array) ? _.clone(array) : [];

  if (_.includes(tempArray, value)) {
    tempArray = _.remove(tempArray, (tempValue) => tempValue !== value);
  } else {
    tempArray.push(value);
  }

  return tempArray;
};

/**
 * pushOrRemoveByKey
 * @description Adds or removes an item from array based on key
 * @param array Collection
 * @param object Object pushed or removed from array
 * @param key Key to compare with
 * @return {array}
 */
const pushOrRemoveByKey = (array, value, key = 'id') => {
  let tempArray = _.isArray(array) ? _.clone(array) : [];
  const comparisonValue = _.getNonEmpty(value, key);

  if (_.find(tempArray, { [key]: comparisonValue })) {
    tempArray = _.remove(
      tempArray,
      (tempValue) => _.getNonEmpty(tempValue, key) !== comparisonValue
    );
  } else {
    tempArray.push(value);
  }

  return tempArray;
};

/**
 * safeOrderBy
 * @description Orders an array of items and ignores case
 * @param array Collection
 * @param string Sort by
 * @param string Order by
 * @return {array}
 */
const safeOrderBy = (collection, sortBy, order) =>
  _.orderBy(collection, (item) => item[sortBy].toLowerCase(), order);

/**
 * epoch
 * @description Returns EPOCH
 * @return {Number}
 */
const epoch = () => _.now() / 1000;

const mapMultiple = (collection, callbacks) =>
  _.map(collection, (item, index) =>
    _.reduce(callbacks, (res, cb) => cb(res, index), item)
  );

const filterOr = (collection, predicates) => {
  const results = _.map(predicates, (predicate) =>
    _.filter(collection, predicate)
  );

  return _.union(...results);
};

/**
 * isEqualSafe
 * @description Orders and compares two arrays
 * @param array collection1
 * @param array collection2
 * @return boolean
 */
const isEqualSafe = (collection1, collection2) => {
  const sortedCollection1 = _.sortBy(collection1);
  const sortedCollection2 = _.sortBy(collection2);

  return _.isEqual(sortedCollection1, sortedCollection2);
};

_.mixin({
  deepMapKeys,
  findDefault,
  findOr,
  getNonEmpty,
  getAndClone,
  getByList,
  patchPath,
  isEmptySafe,
  isFunctionSafe,
  isGuid,
  pascalCase,
  pushUniqueBy,
  hasOneItem,
  firstBy,
  nestedAssign,
  nestedListAssign,
  sentenceCase,
  pushOrRemoveIntoArray,
  pushOrRemoveByKey,
  includesEach,
  includesEither,
  includesAny,
  concatBy,
  pushIfUnique,
  safeOrderBy,
  hasMoreThanOneItem,
  epoch,
  mapMultiple,
  filterOr,
  isEqualSafe
});
