import { translateKey } from '../../i18n';

// NOTE: Some countries use multiple currencies. This list doesn't reflect that fact.
// eslint-disable-next-line max-lines-per-function
export const getCountryList = () => [
  {
    id: 'AF',
    name: translateKey('country:AF'),
    currencyName: 'AFN'
  },
  {
    id: 'AX',
    name: translateKey('country:AX'),
    currencyName: 'EUR'
  },
  {
    id: 'AL',
    name: translateKey('country:AL'),
    currencyName: 'ALL'
  },
  {
    id: 'DZ',
    name: translateKey('country:DZ'),
    currencyName: 'DZD'
  },
  {
    id: 'AS',
    name: translateKey('country:AS'),
    currencyName: 'USD'
  },
  {
    id: 'VI',
    name: translateKey('country:VI'),
    currencyName: 'USD'
  },
  {
    id: 'AD',
    name: translateKey('country:AD'),
    currencyName: 'EUR'
  },
  {
    id: 'AO',
    name: translateKey('country:AO'),
    currencyName: 'AOA'
  },
  {
    id: 'AI',
    name: translateKey('country:AI'),
    currencyName: 'XCD'
  },
  {
    id: 'AQ',
    name: translateKey('country:AQ'),
    currencyName: 'AAD'
  },
  {
    id: 'AG',
    name: translateKey('country:AG'),
    currencyName: 'XCD'
  },
  {
    id: 'AR',
    name: translateKey('country:AR'),
    currencyName: 'ARS'
  },
  {
    id: 'AM',
    name: translateKey('country:AM'),
    currencyName: 'AMD'
  },
  {
    id: 'AW',
    name: translateKey('country:AW'),
    currencyName: 'AWG'
  },
  {
    id: 'AU',
    name: translateKey('country:AU'),
    currencyName: 'AUD'
  },
  {
    id: 'AT',
    name: translateKey('country:AT'),
    currencyName: 'EUR'
  },
  {
    id: 'AZ',
    name: translateKey('country:AZ'),
    currencyName: 'AZN'
  },
  {
    id: 'BS',
    name: translateKey('country:BS'),
    currencyName: 'BSD'
  },
  {
    id: 'BH',
    name: translateKey('country:BH'),
    currencyName: 'BHD'
  },
  {
    id: 'BD',
    name: translateKey('country:BD'),
    currencyName: 'BDT'
  },
  {
    id: 'BB',
    name: translateKey('country:BB'),
    currencyName: 'BBD'
  },
  {
    id: 'BE',
    name: translateKey('country:BE'),
    currencyName: 'EUR'
  },
  {
    id: 'BZ',
    name: translateKey('country:BZ'),
    currencyName: 'BZD'
  },
  {
    id: 'BY',
    name: translateKey('country:BY'),
    currencyName: 'BYN'
  },
  {
    id: 'BJ',
    name: translateKey('country:BJ'),
    currencyName: 'XOF'
  },
  {
    id: 'BM',
    name: translateKey('country:BM'),
    currencyName: 'BMD'
  },
  {
    id: 'BW',
    name: translateKey('country:BW'),
    currencyName: 'BWP'
  },
  {
    id: 'BG',
    name: translateKey('country:BG'),
    currencyName: 'BGN'
  },
  {
    id: 'BO',
    name: translateKey('country:BO'),
    currencyName: 'BOB'
  },
  {
    id: 'BA',
    name: translateKey('country:BA'),
    currencyName: 'BAM'
  },
  {
    id: 'CX',
    name: translateKey('country:CX'),
    currencyName: 'AUD'
  },
  {
    id: 'BR',
    name: translateKey('country:BR'),
    currencyName: 'BRL'
  },
  {
    id: 'VG',
    name: translateKey('country:VG'),
    currencyName: 'USD'
  },
  {
    id: 'IO',
    name: translateKey('country:IO'),
    currencyName: 'USD'
  },
  {
    id: 'BN',
    name: translateKey('country:BN'),
    currencyName: 'BND'
  },
  {
    id: 'BF',
    name: translateKey('country:BF'),
    currencyName: 'XOF'
  },
  {
    id: 'BI',
    name: translateKey('country:BI'),
    currencyName: 'BIF'
  },
  {
    id: 'BT',
    name: translateKey('country:BT'),
    currencyName: 'BTN'
  },
  {
    id: 'CF',
    name: translateKey('country:CF'),
    currencyName: 'XAF'
  },
  {
    id: 'EA',
    name: translateKey('country:EA'),
    currencyName: 'EUR'
  },
  {
    id: 'CY',
    name: translateKey('country:CY'),
    currencyName: 'EUR'
  },
  {
    id: 'CK',
    name: translateKey('country:CK'),
    currencyName: 'NZD'
  },
  {
    id: 'CW',
    name: translateKey('country:CW'),
    currencyName: 'ANG'
  },
  {
    id: 'TD',
    name: translateKey('country:TD'),
    currencyName: 'XAF'
  },
  {
    id: 'CZ',
    name: translateKey('country:CZ'),
    currencyName: 'CZK'
  },
  {
    id: 'CL',
    name: translateKey('country:CL'),
    currencyName: 'XOF'
  },
  {
    id: 'ME',
    name: translateKey('country:ME'),
    currencyName: 'EUR'
  },
  {
    id: 'DK',
    name: translateKey('country:DK'),
    currencyName: 'DKK'
  },
  {
    id: 'CD',
    name: translateKey('country:CD'),
    currencyName: 'CFD'
  },
  {
    id: 'DG',
    name: translateKey('country:DG'),
    currencyName: 'USD'
  },
  {
    id: 'DM',
    name: translateKey('country:DM'),
    currencyName: 'XCD'
  },
  {
    id: 'DO',
    name: translateKey('country:DO'),
    currencyName: 'DOP'
  },
  {
    id: 'DJ',
    name: translateKey('country:DJ'),
    currencyName: 'DJF'
  },
  {
    id: 'EG',
    name: translateKey('country:EG'),
    currencyName: 'EGP'
  },
  {
    id: 'EC',
    name: translateKey('country:EC'),
    currencyName: 'USD'
  },
  {
    id: 'GQ',
    name: translateKey('country:GQ'),
    currencyName: 'XAF'
  },
  {
    id: 'ER',
    name: translateKey('country:ER'),
    currencyName: 'ERN'
  },
  {
    id: 'EE',
    name: translateKey('country:EE'),
    currencyName: 'EUR'
  },
  {
    id: 'ET',
    name: translateKey('country:ET'),
    currencyName: 'ETB'
  },
  {
    id: 'FK',
    name: translateKey('country:FK'),
    currencyName: 'FKP'
  },
  {
    id: 'FO',
    name: translateKey('country:FO'),
    currencyName: 'DKK'
  },
  {
    id: 'FJ',
    name: translateKey('country:FJ'),
    currencyName: 'FJD'
  },
  {
    id: 'PH',
    name: translateKey('country:PH'),
    currencyName: 'PHP'
  },
  {
    id: 'FI',
    name: translateKey('country:FI'),
    currencyName: 'EUR'
  },
  {
    id: 'FR',
    name: translateKey('country:FR'),
    currencyName: 'EUR'
  },
  {
    id: 'GF',
    name: translateKey('country:GF'),
    currencyName: 'EUR'
  },
  {
    id: 'PF',
    name: translateKey('country:PF'),
    currencyName: 'XPF'
  },
  {
    id: 'TF',
    name: translateKey('country:TF'),
    currencyName: 'EUR'
  },
  {
    id: 'GA',
    name: translateKey('country:GA'),
    currencyName: 'XAF'
  },
  {
    id: 'GM',
    name: translateKey('country:GM'),
    currencyName: 'GMD'
  },
  {
    id: 'GH',
    name: translateKey('country:GH'),
    currencyName: 'GHS'
  },
  {
    id: 'GI',
    name: translateKey('country:GI'),
    currencyName: 'GIP'
  },
  {
    id: 'GR',
    name: translateKey('country:GR'),
    currencyName: 'EUR'
  },
  {
    id: 'GD',
    name: translateKey('country:GD'),
    currencyName: 'XCD'
  },
  {
    id: 'GL',
    name: translateKey('country:GL'),
    currencyName: 'DKK'
  },
  {
    id: 'GE',
    name: translateKey('country:GE'),
    currencyName: 'GEL'
  },
  {
    id: 'GP',
    name: translateKey('country:GP'),
    currencyName: 'EUR'
  },
  {
    id: 'GU',
    name: translateKey('country:GU'),
    currencyName: 'USD'
  },
  {
    id: 'GG',
    name: translateKey('country:GG'),
    currencyName: 'GGP'
  },
  {
    id: 'GY',
    name: translateKey('country:GY'),
    currencyName: 'GYD'
  },
  {
    id: 'GT',
    name: translateKey('country:GT'),
    currencyName: 'GTQ'
  },
  {
    id: 'GN',
    name: translateKey('country:GN'),
    currencyName: 'GNF'
  },
  {
    id: 'GW',
    name: translateKey('country:GW'),
    currencyName: 'XOF'
  },
  {
    id: 'HT',
    name: translateKey('country:HT'),
    currencyName: 'HTG'
  },
  {
    id: 'HN',
    name: translateKey('country:HN'),
    currencyName: 'HNL'
  },
  {
    id: 'HR',
    name: translateKey('country:HR'),
    currencyName: 'HRK'
  },
  {
    id: 'IN',
    name: translateKey('country:IN'),
    currencyName: 'INR'
  },
  {
    id: 'ID',
    name: translateKey('country:ID'),
    currencyName: 'IDR'
  },
  {
    id: 'IQ',
    name: translateKey('country:IQ'),
    currencyName: 'IQD'
  },
  {
    id: 'IR',
    name: translateKey('country:IR'),
    currencyName: 'IRR'
  },
  {
    id: 'IE',
    name: translateKey('country:IE'),
    currencyName: 'EUR'
  },
  {
    id: 'IS',
    name: translateKey('country:IS'),
    currencyName: 'ISK'
  },
  {
    id: 'IT',
    name: translateKey('country:IT'),
    currencyName: 'EUR'
  },
  {
    id: 'IL',
    name: translateKey('country:IL'),
    currencyName: 'ILS'
  },
  {
    id: 'JM',
    name: translateKey('country:JM'),
    currencyName: 'JMD'
  },
  {
    id: 'JP',
    name: translateKey('country:JP'),
    currencyName: 'JPY'
  },
  {
    id: 'YE',
    name: translateKey('country:YE'),
    currencyName: 'YER'
  },
  {
    id: 'JE',
    name: translateKey('country:JE'),
    currencyName: 'JEP'
  },
  {
    id: 'JO',
    name: translateKey('country:JO'),
    currencyName: 'JOD'
  },
  {
    id: 'GS',
    name: translateKey('country:GS'),
    currencyName: 'GBP'
  },
  {
    id: 'KR',
    name: translateKey('country:KR'),
    currencyName: 'KRW'
  },
  {
    id: 'SS',
    name: translateKey('country:SS'),
    currencyName: 'SSP'
  },
  {
    id: 'ZA',
    name: translateKey('country:ZA'),
    currencyName: 'ZAR'
  },
  {
    id: 'KY',
    name: translateKey('country:KY'),
    currencyName: 'KYD'
  },
  {
    id: 'KH',
    name: translateKey('country:KH'),
    currencyName: 'KHR'
  },
  {
    id: 'CM',
    name: translateKey('country:CM'),
    currencyName: 'XAF'
  },
  {
    id: 'CA',
    name: translateKey('country:CA'),
    currencyName: 'CAD'
  },
  {
    id: 'IC',
    name: translateKey('country:IC'),
    currencyName: 'EUR'
  },
  {
    id: 'QA',
    name: translateKey('country:QA'),
    currencyName: 'QAR'
  },
  {
    id: 'KZ',
    name: translateKey('country:KZ'),
    currencyName: 'KZT'
  },
  {
    id: 'KE',
    name: translateKey('country:KE'),
    currencyName: 'KES'
  },
  {
    id: 'KG',
    name: translateKey('country:KG'),
    currencyName: 'KGS'
  },
  {
    id: 'KI',
    name: translateKey('country:KI'),
    currencyName: 'AUD'
  },
  {
    id: 'CN',
    name: translateKey('country:CN'),
    currencyName: 'CNY'
  },
  {
    id: 'CC',
    name: translateKey('country:CC'),
    currencyName: 'AUD'
  },
  {
    id: 'CO',
    name: translateKey('country:CO'),
    currencyName: 'COP'
  },
  {
    id: 'KM',
    name: translateKey('country:KM'),
    currencyName: 'KMF'
  },
  {
    id: 'CG',
    name: translateKey('country:CG'),
    currencyName: 'XAF'
  },
  {
    id: 'XK',
    name: translateKey('country:XK'),
    currencyName: 'EUR'
  },
  {
    id: 'CR',
    name: translateKey('country:CR'),
    currencyName: 'CRC'
  },
  {
    id: 'CU',
    name: translateKey('country:CU'),
    currencyName: 'CUP'
  },
  {
    id: 'KW',
    name: translateKey('country:KW'),
    currencyName: 'KWD'
  },
  {
    id: 'LA',
    name: translateKey('country:LA'),
    currencyName: 'LAK'
  },
  {
    id: 'LV',
    name: translateKey('country:LV'),
    currencyName: 'EUR'
  },
  {
    id: 'LS',
    name: translateKey('country:LS'),
    currencyName: 'LSL'
  },
  {
    id: 'LB',
    name: translateKey('country:LB'),
    currencyName: 'LBP'
  },
  {
    id: 'LR',
    name: translateKey('country:LR'),
    currencyName: 'LRD'
  },
  {
    id: 'LY',
    name: translateKey('country:LY'),
    currencyName: 'LYD'
  },
  {
    id: 'LI',
    name: translateKey('country:LI'),
    currencyName: 'CHF'
  },
  {
    id: 'LT',
    name: translateKey('country:LT'),
    currencyName: 'EUR'
  },
  {
    id: 'LU',
    name: translateKey('country:LU'),
    currencyName: 'EUR'
  },
  {
    id: 'MG',
    name: translateKey('country:MG'),
    currencyName: 'MGA'
  },
  {
    id: 'HU',
    name: translateKey('country:HU'),
    currencyName: 'HUF'
  },
  {
    id: 'MW',
    name: translateKey('country:MW'),
    currencyName: 'MWK'
  },
  {
    id: 'MV',
    name: translateKey('country:MV'),
    currencyName: 'MVR'
  },
  {
    id: 'MY',
    name: translateKey('country:MY'),
    currencyName: 'MYR'
  },
  {
    id: 'ML',
    name: translateKey('country:ML'),
    currencyName: 'XOF'
  },
  {
    id: 'MT',
    name: translateKey('country:MT'),
    currencyName: 'EUR'
  },
  {
    id: 'MA',
    name: translateKey('country:MA'),
    currencyName: 'MAD'
  },
  {
    id: 'MH',
    name: translateKey('country:MH'),
    currencyName: 'USD'
  },
  {
    id: 'MQ',
    name: translateKey('country:MQ'),
    currencyName: 'EUR'
  },
  {
    id: 'MU',
    name: translateKey('country:MU'),
    currencyName: 'MUR'
  },
  {
    id: 'MR',
    name: translateKey('country:MR'),
    currencyName: 'MRU'
  },
  {
    id: 'YT',
    name: translateKey('country:YT'),
    currencyName: 'EUR'
  },
  {
    id: 'MX',
    name: translateKey('country:MX'),
    currencyName: 'MXN'
  },
  {
    id: 'FM',
    name: translateKey('country:FM'),
    currencyName: 'USD'
  },
  {
    id: 'MM',
    name: translateKey('country:MM'),
    currencyName: 'MMK'
  },
  {
    id: 'MD',
    name: translateKey('country:MD'),
    currencyName: 'MDL'
  },
  {
    id: 'MC',
    name: translateKey('country:MC'),
    currencyName: 'EUR'
  },
  {
    id: 'MN',
    name: translateKey('country:MN'),
    currencyName: 'MNT'
  },
  {
    id: 'MS',
    name: translateKey('country:MS'),
    currencyName: 'XCD'
  },
  {
    id: 'MZ',
    name: translateKey('country:MZ'),
    currencyName: 'MZN'
  },
  {
    id: 'NA',
    name: translateKey('country:NA'),
    currencyName: 'NAD'
  },
  {
    id: 'NR',
    name: translateKey('country:NR'),
    currencyName: 'AUD'
  },
  {
    id: 'DE',
    name: translateKey('country:DE'),
    currencyName: 'EUR'
  },
  {
    id: 'NP',
    name: translateKey('country:NP'),
    currencyName: 'NPR'
  },
  {
    id: 'NE',
    name: translateKey('country:NE'),
    currencyName: 'XOF'
  },
  {
    id: 'NG',
    name: translateKey('country:NG'),
    currencyName: 'NGN'
  },
  {
    id: 'NI',
    name: translateKey('country:NI'),
    currencyName: 'NIO'
  },
  {
    id: 'NU',
    name: translateKey('country:NU'),
    currencyName: 'NZD'
  },
  {
    id: 'NL',
    name: translateKey('country:NL'),
    currencyName: 'EUR'
  },
  {
    id: 'BQ',
    name: translateKey('country:BQ'),
    currencyName: 'USD'
  },
  {
    id: 'NF',
    name: translateKey('country:NF'),
    currencyName: 'AUD'
  },
  {
    id: 'NO',
    name: translateKey('country:NO'),
    currencyName: 'NOK'
  },
  {
    id: 'NC',
    name: translateKey('country:NC'),
    currencyName: 'XPF'
  },
  {
    id: 'NZ',
    name: translateKey('country:NZ'),
    currencyName: 'NZD'
  },
  {
    id: 'OM',
    name: translateKey('country:OM'),
    currencyName: 'OMR'
  },
  {
    id: 'AC',
    name: translateKey('country:AC'),
    currencyName: 'SHP'
  },
  {
    id: 'IM',
    name: translateKey('country:IM'),
    currencyName: 'IMP'
  },
  {
    id: 'TC',
    name: translateKey('country:TC'),
    currencyName: 'USD'
  },
  {
    id: 'PK',
    name: translateKey('country:PK'),
    currencyName: 'PKR'
  },
  {
    id: 'PW',
    name: translateKey('country:PW'),
    currencyName: 'USD'
  },
  {
    id: 'PS',
    name: translateKey('country:PS'),
    currencyName: 'ILS'
  },
  {
    id: 'PA',
    name: translateKey('country:PA'),
    currencyName: 'USD'
  },
  {
    id: 'PG',
    name: translateKey('country:PG'),
    currencyName: 'PGK'
  },
  {
    id: 'PY',
    name: translateKey('country:PY'),
    currencyName: 'PYG'
  },
  {
    id: 'PE',
    name: translateKey('country:PE'),
    currencyName: 'PEN'
  },
  {
    id: 'PN',
    name: translateKey('country:PN'),
    currencyName: 'NZD'
  },
  {
    id: 'PL',
    name: translateKey('country:PL'),
    currencyName: 'PLN'
  },
  {
    id: 'PR',
    name: translateKey('country:PR'),
    currencyName: 'USD'
  },
  {
    id: 'PT',
    name: translateKey('country:PT'),
    currencyName: 'EUR'
  },
  {
    id: 'HK',
    name: translateKey('country:HK'),
    currencyName: 'HKD'
  },
  {
    id: 'MO',
    name: translateKey('country:MO'),
    currencyName: 'MOP'
  },
  /*
   * TODO: We will not use these 2
   * {
   *   id: 'XB',
   *   name: translateKey('country:XB'
   * },
   * {
   *   id: 'XA',
   *   name: translateKey('country:XA'
   * },
   */
  {
    id: 'RE',
    name: translateKey('country:RE'),
    currencyName: 'EUR'
  },
  {
    id: 'RO',
    name: translateKey('country:RO'),
    currencyName: 'RON'
  },
  {
    id: 'RW',
    name: translateKey('country:RW'),
    currencyName: 'RWF'
  },
  {
    id: 'RU',
    name: translateKey('country:RU'),
    currencyName: 'RUB'
  },
  {
    id: 'BL',
    name: translateKey('country:BL'),
    currencyName: 'EUR'
  },
  {
    id: 'KN',
    name: translateKey('country:KN'),
    currencyName: 'XCD'
  },
  {
    id: 'LC',
    name: translateKey('country:LC'),
    currencyName: 'XCD'
  },
  {
    id: 'MF',
    name: translateKey('country:MF'),
    currencyName: 'EUR'
  },
  {
    id: 'PM',
    name: translateKey('country:PM'),
    currencyName: 'EUR'
  },
  {
    id: 'VC',
    name: translateKey('country:VC'),
    currencyName: 'XCD'
  },
  {
    id: 'SB',
    name: translateKey('country:SB'),
    currencyName: 'SBD'
  },
  {
    id: 'SV',
    name: translateKey('country:SV'),
    currencyName: 'USD'
  },
  {
    id: 'WS',
    name: translateKey('country:WS'),
    currencyName: 'WST'
  },
  {
    id: 'SM',
    name: translateKey('country:SM'),
    currencyName: 'EUR'
  },
  {
    id: 'ST',
    name: translateKey('country:ST'),
    currencyName: 'STN'
  },
  {
    id: 'SA',
    name: translateKey('country:SA'),
    currencyName: 'SAR'
  },
  {
    id: 'SC',
    name: translateKey('country:SC'),
    currencyName: 'SCR'
  },
  {
    id: 'SN',
    name: translateKey('country:SN'),
    currencyName: 'XOF'
  },
  {
    id: 'KP',
    name: translateKey('country:KP'),
    currencyName: 'KPW'
  },
  {
    id: 'MK',
    name: translateKey('country:MK'),
    currencyName: 'MKD'
  },
  {
    id: 'MP',
    name: translateKey('country:MP'),
    currencyName: 'USD'
  },
  {
    id: 'SL',
    name: translateKey('country:SL'),
    currencyName: 'SLL'
  },
  {
    id: 'SG',
    name: translateKey('country:SG'),
    currencyName: 'SGD'
  },
  {
    id: 'SX',
    name: translateKey('country:SX'),
    currencyName: 'ANG'
  },
  {
    id: 'SY',
    name: translateKey('country:SY'),
    currencyName: 'SYP'
  },
  {
    id: 'CI',
    name: translateKey('country:CI'),
    currencyName: 'XOF'
  },
  {
    id: 'SK',
    name: translateKey('country:SK'),
    currencyName: 'EUR'
  },
  {
    id: 'SI',
    name: translateKey('country:SI'),
    currencyName: 'EUR'
  },
  {
    id: 'SO',
    name: translateKey('country:SO'),
    currencyName: 'SOS'
  },
  {
    id: 'RS',
    name: translateKey('country:RS'),
    currencyName: 'RSD'
  },
  {
    id: 'UM',
    name: translateKey('country:UM'),
    currencyName: 'USD'
  },
  {
    id: 'SD',
    name: translateKey('country:SD'),
    currencyName: 'SDG'
  },
  {
    id: 'SR',
    name: translateKey('country:SR'),
    currencyName: 'SRD'
  },
  {
    id: 'SJ',
    name: translateKey('country:SJ'),
    currencyName: 'NOK'
  },
  {
    id: 'SZ',
    name: translateKey('country:SZ'),
    currencyName: 'SZL'
  },
  {
    id: 'SH',
    name: translateKey('country:SH'),
    currencyName: 'SHP'
  },
  {
    id: 'ES',
    name: translateKey('country:ES'),
    currencyName: 'EUR'
  },
  {
    id: 'LK',
    name: translateKey('country:LK'),
    currencyName: 'LKR'
  },
  {
    id: 'SE',
    name: translateKey('country:SE'),
    currencyName: 'SEK'
  },
  {
    id: 'CH',
    name: translateKey('country:CH'),
    currencyName: 'CHF'
  },
  {
    id: 'TJ',
    name: translateKey('country:TJ'),
    currencyName: 'TJS'
  },
  {
    id: 'TH',
    name: translateKey('country:TH'),
    currencyName: 'THB'
  },
  {
    id: 'TW',
    name: translateKey('country:TW'),
    currencyName: 'TWD'
  },
  {
    id: 'TZ',
    name: translateKey('country:TZ'),
    currencyName: 'TZS'
  },
  {
    id: 'TL',
    name: translateKey('country:TL'),
    currencyName: 'USD'
  },
  {
    id: 'TG',
    name: translateKey('country:TG'),
    currencyName: 'XOF'
  },
  {
    id: 'TK',
    name: translateKey('country:TK'),
    currencyName: 'NZD'
  },
  {
    id: 'TO',
    name: translateKey('country:TO'),
    currencyName: 'TOP'
  },
  {
    id: 'TT',
    name: translateKey('country:TT'),
    currencyName: 'TDD'
  },
  {
    id: 'TA',
    name: translateKey('country:TA'),
    currencyName: 'GBP'
  },
  {
    id: 'TN',
    name: translateKey('country:TN'),
    currencyName: 'TND'
  },
  {
    id: 'TR',
    name: translateKey('country:TR'),
    currencyName: 'TRY'
  },
  {
    id: 'TM',
    name: translateKey('country:TM'),
    currencyName: 'TMT'
  },
  {
    id: 'TV',
    name: translateKey('country:TV'),
    currencyName: 'AUD'
  },
  {
    id: 'UG',
    name: translateKey('country:UG'),
    currencyName: 'UGX'
  },
  {
    id: 'UA',
    name: translateKey('country:UA'),
    currencyName: 'UAH'
  },
  {
    id: 'UY',
    name: translateKey('country:UY'),
    currencyName: 'UYU'
  },
  {
    id: 'UZ',
    name: translateKey('country:UZ'),
    currencyName: 'UZS'
  },
  {
    id: 'VU',
    name: translateKey('country:VU'),
    currencyName: 'VUV'
  },
  {
    id: 'VA',
    name: translateKey('country:VA'),
    currencyName: 'EUR'
  },
  {
    id: 'VE',
    name: translateKey('country:VE'),
    currencyName: 'VES'
  },
  {
    id: 'VN',
    name: translateKey('country:VN'),
    currencyName: 'VND'
  },
  {
    id: 'WF',
    name: translateKey('country:WF'),
    currencyName: 'XPF'
  },
  {
    id: 'EH',
    name: translateKey('country:EH'),
    currencyName: 'MAD'
  },
  {
    id: 'ZM',
    name: translateKey('country:ZM'),
    currencyName: 'ZMW'
  },
  {
    id: 'US',
    name: translateKey('country:US'),
    currencyName: 'USD'
  },
  {
    id: 'AE',
    name: translateKey('country:AE'),
    currencyName: 'AED'
  },
  {
    id: 'GB',
    name: translateKey('country:GB'),
    currencyName: 'GBP'
  },
  {
    id: 'CV',
    name: translateKey('country:CV'),
    currencyName: 'CVE'
  },
  {
    id: 'ZW',
    name: translateKey('country:ZW'),
    currencyName: 'USD'
  }
];
