/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { ButtonDropdown } from 'reactstrap';

import PropTypes from 'prop-types';

const ButtonDropdownWrapper = ({
  disabled,
  isOpen,
  className,
  id,
  children,
  ...props
}) => (
  <ButtonDropdown
    disabled={disabled}
    isOpen={isOpen}
    className={className}
    id={id}
    {...props}
  >
    {children}
  </ButtonDropdown>
);

ButtonDropdownWrapper.propTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
};

ButtonDropdownWrapper.defaultProps = {
  disabled: undefined,
  isOpen: undefined,
  children: undefined,
  className: undefined,
  id: undefined
};

export default ButtonDropdownWrapper;
