import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  BILL_LIST_FILTER_BILLING_UNIT,
  BILL_LIST_FILTER_DATE_FROM,
  BILL_LIST_FILTER_DATE_TO,
  BILL_LIST_FILTER_DOCTOR,
  BILL_LIST_FILTER_ELECTRONIC_DEVICE,
  BILL_LIST_FILTER_LOCATION,
  BILL_LIST_FILTER_SEARCH
} from '../../../utils/billing';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';

import { initialState } from './estimateReducer';

export const selectEstimateStore = (store) => store.estimate;

// LIST SELECTORS
export const selectEstimateList = createSelector(
  [selectEstimateStore],
  (estimateStore) => _.getNonEmpty(estimateStore, 'list', [])
);

export const selectEstimateLoadingList = createSelector(
  [selectEstimateStore],
  (estimateStore) => _.getNonEmpty(estimateStore, 'loadingList', false)
);

// DETAIL SELECTORS
export const selectEstimateDetail = createSelector(
  [selectEstimateStore],
  (estimateStore) => _.getNonEmpty(estimateStore, 'detail', {})
);

export const selectEstimateLoadingDetail = createSelector(
  [selectEstimateStore],
  (estimateStore) => _.getNonEmpty(estimateStore, 'loadingDetail', false)
);

// FILTER SELECTORS
export const selectEstimateFilter = createSelector(
  [selectEstimateStore],
  (estimateStore) => _.getNonEmpty(estimateStore, 'filter', {})
);

export const selectEstimateFilterSearchValue = createSelector(
  [selectEstimateFilter],
  (estimateFilter) => _.getNonEmpty(estimateFilter, BILL_LIST_FILTER_SEARCH, '')
);

export const selectEstimateFilterLocationId = createSelector(
  [selectEstimateFilter],
  (estimateFilter) =>
    _.getNonEmpty(estimateFilter, BILL_LIST_FILTER_LOCATION, FILTER_ALL_ID)
);

export const selectEstimateFilterDoctorId = createSelector(
  [selectEstimateFilter],
  (estimateFilter) =>
    _.getNonEmpty(estimateFilter, BILL_LIST_FILTER_DOCTOR, FILTER_ALL_ID)
);

export const selectEstimateFilterBillingUnitId = createSelector(
  [selectEstimateFilter],
  (estimateFilter) =>
    _.getNonEmpty(estimateFilter, BILL_LIST_FILTER_BILLING_UNIT, FILTER_ALL_ID)
);

export const selectEstimateFilterElectronicDeviceId = createSelector(
  [selectEstimateFilter],
  (estimateFilter) =>
    _.getNonEmpty(
      estimateFilter,
      BILL_LIST_FILTER_ELECTRONIC_DEVICE,
      FILTER_ALL_ID
    )
);

export const selectIsEstimateFilterInitial = createSelector(
  [selectEstimateFilter],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectEstimateFilterFromDate = createSelector(
  [selectEstimateFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_FROM, undefined)
);

export const selectEstimateFilterToDate = createSelector(
  [selectEstimateFilter],
  (filters) => _.getNonEmpty(filters, BILL_LIST_FILTER_DATE_TO, undefined)
);

// PAGINATION SELECTORS
export const selectEstimatePagination = createSelector(
  [selectEstimateStore],
  (estimateStore) => _.getNonEmpty(estimateStore, 'pagination', {})
);
