import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getDateFormat } from '../../../../utils/date';

import MoshiDatePicker from '../../date/MoshiDatePicker';

const CustomCalendarDatepickerWidget = ({
  value,
  disabled,
  onChange,
  schema
}) => {
  const { t: translate } = useTranslation();
  const allowedTypes = useMemo(() => _.getNonEmpty(schema, 'type', ['null']), [
    schema
  ]);

  const handleOnChange = (date) => {
    const formattedDate = getDateFormat(date, 'YYYY-MM-DD', null);

    onChange(formattedDate);
  };

  return (
    <MoshiDatePicker
      disabled={disabled}
      onChange={handleOnChange}
      name={'datepicker'}
      value={value}
      format={translate('date:datePickerFormat')}
      allowEmpty
      clearIcon={
        _.includes(allowedTypes, 'null') ? translate('common:clear') : undefined
      }
    />
  );
};

CustomCalendarDatepickerWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool,
  schema: PropTypes.shape({})
};

CustomCalendarDatepickerWidget.defaultProps = {
  value: null,
  disabled: false,
  schema: {}
};

export default CustomCalendarDatepickerWidget;
