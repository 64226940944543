import { guid } from '../../utils/gen';

class RoleModel {
  constructor(id = guid(), name = '') {
    this.id = id;
    this.name = name;
  }
}

export default RoleModel;
