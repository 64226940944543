import _ from 'lodash';

import { getBrandName, getFaviconURL } from './moshiConfig';

export const setOrganizationMeta = () => {
  setFavicon();
  setTitle();
};

export const setFavicon = () => {
  const faviconURL = getFaviconURL(_.get(document, 'location.hostname', ''));

  if (_.isEmpty(faviconURL)) {
    return;
  }

  const favicon = document.createElement('link');

  favicon.type = 'image/x-icon';
  favicon.rel = 'shortcut icon';
  favicon.href = faviconURL;

  if (_.isFunction(document.head.appendChild)) {
    document.head.appendChild(favicon);
  }
};

export const setTitle = () => {
  document.title = getBrandName(_.get(document, 'location.hostname', ''));
};
