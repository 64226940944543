import _ from 'lodash';
import { createSelector } from 'reselect';

import { selectProps } from '../../../utils/redux/selectorHelpers';

export const selectArchiveStore = (store) => store.archive;

// LOCATION SELECTORS
export const selectDeletedLocationList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'location.list', [])
);

// FLOW SELECTORS
export const selectDeletedFlowList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'flow.list', [])
);

// USER SELECTORS
export const selectDeletedUserList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'user.list', [])
);

// DRUG SELECTORS
export const selectDeletedDrugList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'drug.list', [])
);

// CATEGORY SELECTORS
export const selectDeletedCategoryList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'category.list', [])
);

// QUESTIONNAIRE SELECTORS
export const selectDeletedQuestionnaireList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'questionnaire.list', [])
);

// PAYMENT TYPE SELECTORS
export const selectDeletedPaymentTypeList = createSelector(
  [selectArchiveStore],
  (archiveStore) => _.get(archiveStore, 'paymentType.list', [])
);

export const selectDeletedCustomPaymentTypeById = createSelector(
  [selectDeletedPaymentTypeList, selectProps],
  (paymentTypes, paymentTypeId) => {
    if (_.isEmpty(paymentTypeId)) {
      return null;
    }

    return _.find(paymentTypes, { id: paymentTypeId });
  }
);
