import _ from 'lodash';

import moshiConfig, {
  ENV_DEVELOPMENT,
  ENV_E2E,
  ENV_TEST,
  getHostname
} from './moshiConfig';

export const routerActionTypes = {
  push: 'PUSH',
  pop: 'POP',
  replace: 'REPLACE'
};

export const getCurrentPageSlugAndDomain = () => {
  const hostname = getHostname();
  const hostnameDomainList = hostname.split('.');
  let orgSlug = null;
  let orgDomain = null;

  if (
    _.includes([ENV_DEVELOPMENT, ENV_TEST], moshiConfig.appEnv) &&
    !_.isEmptySafe(moshiConfig, 'orgSlug')
  ) {
    orgSlug = moshiConfig.orgSlug;
  } else if (moshiConfig.appEnv === ENV_E2E) {
    const droneBuildNumber = moshiConfig.dev.buildNumber;
    const organizationSuffix = `-${(droneBuildNumber % 5) + 1}`;

    orgSlug = `${moshiConfig.orgSlug}${organizationSuffix}`;
  } else if (_.endsWith(hostname, moshiConfig.hostSuffix)) {
    orgSlug = _.first(hostnameDomainList);
  } else {
    orgDomain = hostname;
  }

  return [orgSlug, orgDomain];
};

export const populateRoute = (route, params, paramMap = {}) => {
  let path = route;

  const extendedParams = _.cloneDeep(params);

  // paramMap: Used for extracting params from deep paths, or renaming params to suit route
  _.forEach(paramMap, (valuePath, newParamName) => {
    extendedParams[newParamName] = _.getNonEmpty(params, valuePath);
  });

  _.forEach(extendedParams, (paramValue, paramName) => {
    const paramAtEndOfRoute = new RegExp(`/:${paramName}$`, 'u');
    const paramInMiddleOfRoute = new RegExp(`/:${paramName}/`, 'u');

    path = path.replace(paramAtEndOfRoute, `/${paramValue}`);
    path = path.replace(paramInMiddleOfRoute, `/${paramValue}/`);
  });

  return path;
};

export const populateRouteObject = (routes, params, paramMap) =>
  _.mapValues(routes, (route) => populateRoute(route, params, paramMap));
