import _ from 'lodash';
import { createSelector } from 'reselect';

import { LOCALE_BILLING_EN } from '../../utils/constants/languageConstants';
import { getCurrencyList } from '../../utils/data/currency';
import {
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_TYPE_SMS
} from '../../utils/data/notifications';
import { getBillingLocaleLists } from '../../utils/mappers/cleaned/billing/billingLocaleMappers';
import {
  getAllPaymentTypeListFromSettings,
  getPaymentTypeListsFromSettings
} from '../../utils/mappers/cleaned/billing/paymentTypeMappers';
import { selectProps } from '../../utils/redux/selectorHelpers';

import {
  selectCachedBillingUnitList,
  selectCachedCustomPaymentTypesList
} from '../core/cache/cacheSelectors';
import { selectCurrentUserRoles } from '../user/userSelectors';

import OrganizationModel from '../../metadata/model/OrganizationModel';

import {
  BILLING_FEATURE_MODULE,
  BOOKING_MODULE,
  ENCOUNTER_FEATURE_MODULE,
  RSVP_MODULE,
  TAGS_FEATURE_MODULE,
  THERAPY_FEATURE_MODULE
} from '../../featureModules';

export const selectOrganizationStore = (state) => state.organization;

// COMMON ORGANIZATION SELECTORS

export const selectCurrentOrganization = createSelector(
  [selectOrganizationStore],
  (organization) => _.getNonEmpty(organization, 'currentOrganization', {})
);

export const selectCurrentOrganizationId = createSelector(
  [selectCurrentOrganization],
  (currentOrganization) => _.getNonEmpty(currentOrganization, 'id')
);

export const selectCurrentOrganizationModel = createSelector(
  [selectCurrentOrganization],
  (currentOrganization) =>
    _.nestedAssign(
      new OrganizationModel('', '', null, null),
      currentOrganization
    )
);

export const selectCurrentOrganizationWebUISettings = createSelector(
  [selectCurrentOrganization],
  (organization) => _.getNonEmpty(organization, 'webUISettings', {})
);

export const selectCalendarUiSettings = createSelector(
  [selectCurrentOrganizationWebUISettings],
  (webUISettings) => {
    const calendarSettings = _.getNonEmpty(webUISettings, 'calendar', {});

    /**
     *     If dimPastEvents was set to false it does not come back from the backend
     *     so we set it here, to avoid formik errors
     *
     */
    const dimPastEvents = _.getNonEmpty(
      calendarSettings,
      'dimPastEvents',
      false
    );

    _.set(calendarSettings, 'dimPastEvents', dimPastEvents);

    return calendarSettings;
  }
);

export const selectRegistryConfig = createSelector(
  [selectCurrentOrganization],
  (currentOrganization) => _.getNonEmpty(currentOrganization, 'registries', {})
);

export const selectEnabledFeatureModules = createSelector(
  [selectCurrentOrganization],
  (organization) => _.getNonEmpty(organization, 'enabledModules', [])
);

export const selectIsFeatureEnabled = createSelector(
  [selectEnabledFeatureModules, selectProps],
  (enabledModules, featureModule) => _.includes(enabledModules, featureModule)
);

// WEB CONFIG SELECTORS

export const selectWebConfig = createSelector(
  [selectCurrentOrganization],
  (organization) => {
    const webConfig = _.getNonEmpty(organization, 'webUISettings');

    return {
      defaultRoute: _.getNonEmpty(webConfig, 'defaultRoute'),
      logoUrl: _.getNonEmpty(webConfig, 'logoUrl', ''),
      logoCollapsedUrl: _.getNonEmpty(webConfig, 'logoCollapsedUrl', ''),
      supportEmail: _.getNonEmpty(webConfig, 'supportEmail', '')
    };
  }
);

export const selectSupportEmail = createSelector(
  [selectWebConfig],
  (webConfig) => _.getNonEmpty(webConfig, 'supportEmail', '')
);

export const selectDefaultRoute = createSelector(
  [selectWebConfig, selectEnabledFeatureModules],
  (webConfig, enabledModules) => {
    const fallbackDefaultRoute = _.includes(
      enabledModules,
      ENCOUNTER_FEATURE_MODULE
    )
      ? 'dashboard'
      : 'patients';

    return _.getNonEmpty(webConfig, 'defaultRoute', fallbackDefaultRoute);
  }
);

export const selectDefaultRedirectRoute = createSelector(
  [selectDefaultRoute],
  (defaultRoute) => `/${defaultRoute}`
);

/*
 * Selector appends routes to hidden routes list which
 * shouldn't be visible  to selected roles
 */
export const selectHiddenRoutesByRole = createSelector(
  [selectCurrentUserRoles],
  ({ isOrganizationAdmin, isLocationAdmin }) => {
    const whiteListedRoutes = [
      {
        role: 'admin',
        visible: isOrganizationAdmin,
        whitelisted: ['analytics']
      },
      {
        role: 'admin',
        visible: isOrganizationAdmin || isLocationAdmin,
        whitelisted: ['automated-check-in']
      }
    ];

    let hiddenRouteListByRole = [];

    whiteListedRoutes.forEach(({ visible, whitelisted }) => {
      if (!visible) {
        hiddenRouteListByRole = [...hiddenRouteListByRole, ...whitelisted];
      }
    });

    const uniqueRoutes = _.uniq(hiddenRouteListByRole);

    return _.isEmptySafe(uniqueRoutes) ? [] : uniqueRoutes;
  }
);

export const selectOrganizationIsFiscalizationEnabled = createSelector(
  [
    selectCachedBillingUnitList,
    (store) => selectIsFeatureEnabled(store, BILLING_FEATURE_MODULE)
  ],
  (billingUnitList, isBillingEnabled) => {
    const doBillingUnitsHaveFiscalizationEnabled = _.some(billingUnitList, {
      furs: { enabled: true }
    });

    return doBillingUnitsHaveFiscalizationEnabled && isBillingEnabled;
  }
);

// FEATURE MODULES

export const selectIsTherapyModuleEnabled = createSelector(
  [(store) => selectIsFeatureEnabled(store, THERAPY_FEATURE_MODULE)],
  (isTherapyModuleEnabled) => isTherapyModuleEnabled
);

export const selectIsBillingModuleEnabled = createSelector(
  [(store) => selectIsFeatureEnabled(store, BILLING_FEATURE_MODULE)],
  (isBillingModuleEnabled) => isBillingModuleEnabled
);

export const selectIsTagsModuleEnabled = createSelector(
  [(store) => selectIsFeatureEnabled(store, TAGS_FEATURE_MODULE)],
  (isTagModuleEnabled) => isTagModuleEnabled
);

export const selectIsRsvpModuleEnabled = createSelector(
  [(store) => selectIsFeatureEnabled(store, RSVP_MODULE)],
  (isRsvpModuleEnabled) => isRsvpModuleEnabled
);

export const selectIsOnlineBookingModuleEnabled = createSelector(
  [(store) => selectIsFeatureEnabled(store, BOOKING_MODULE)],
  (isRsvpModuleEnabled) => isRsvpModuleEnabled
);

// BILLING SETTINGS SELECTORS

export const selectOrganizationBillingSettings = createSelector(
  [selectCurrentOrganization],
  (organization) => _.getNonEmpty(organization, 'billingSettings', {})
);

export const selectOrganizationPaymentTypes = createSelector(
  [selectOrganizationBillingSettings],
  (billingSettings) => _.getNonEmpty(billingSettings, 'paymentTypes', {})
);

export const selectOrganizationPaymentTypeLists = createSelector(
  [selectOrganizationPaymentTypes, selectCachedCustomPaymentTypesList],
  (paymentTypeSettings, customPaymentTypes) =>
    getPaymentTypeListsFromSettings(paymentTypeSettings, customPaymentTypes)
);

export const selectOrganizationAllPaymentTypeList = createSelector(
  [selectOrganizationPaymentTypes, selectCachedCustomPaymentTypesList],
  (paymentTypeSettings, customPaymentTypes) =>
    getAllPaymentTypeListFromSettings(paymentTypeSettings, customPaymentTypes)
);

export const selectOrganizationBuiltinAndTaggablePaymentTypeList = createSelector(
  [selectOrganizationAllPaymentTypeList],
  (allPaymentTypes) => {
    const builtinPaymentTypes = _.filter(allPaymentTypes, { isBuiltin: true });

    return _.filter(
      builtinPaymentTypes,
      (paymentType) =>
        _.getNonEmpty(paymentType, 'notTaggable', false) === false
    );
  }
);

export const selectCurrency = createSelector(
  [selectOrganizationBillingSettings],
  (billingSettings) => _.getNonEmpty(billingSettings, 'currency')
);

export const selectCurrencySign = createSelector(
  [selectCurrency],
  (currency) => {
    const foundCurrency = _.find(getCurrencyList(), {
      id: currency
    });

    return _.getNonEmpty(foundCurrency, 'sign', '/');
  }
);

export const selectDefaultBillingLocale = createSelector(
  [selectOrganizationBillingSettings],
  (billingSettings) =>
    _.getNonEmpty(billingSettings, 'defaultLocale', LOCALE_BILLING_EN)
);

export const selectSupportedBillingLocales = createSelector(
  [selectOrganizationBillingSettings],
  (billingSettings) => _.getNonEmpty(billingSettings, 'supportedLocales', [])
);

export const selectOrganizationBillingLocaleLists = createSelector(
  [selectSupportedBillingLocales, selectDefaultBillingLocale],
  (enabledLocales, defaultLocale) =>
    getBillingLocaleLists(enabledLocales, defaultLocale)
);

// NOTIFICATION SETTINGS SELECTORS

export const selectCurrentOrganizationNotificationSettings = createSelector(
  [selectCurrentOrganization],
  (organization) => _.getNonEmpty(organization, 'notificationsSettings')
);

export const selectEventReminderSmsTemplate = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationSettings) =>
    _.getNonEmpty(notificationSettings, 'appointmentReminderSMSTemplate', '')
);

export const selectAppointmentCanceledEmailEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationSettings) =>
    _.getNonEmpty(
      notificationSettings,
      'appointmentCanceledEmailEnabled',
      false
    )
);

export const selectappointmentFeedbackEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationSettings) =>
    _.getNonEmpty(notificationSettings, 'appointmentFeedback.enabled', false)
);

export const selectAppointmentCanceledSMSEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationSettings) =>
    _.getNonEmpty(notificationSettings, 'appointmentCanceledSMSEnabled', false)
);

export const selectOrganizationCountry = createSelector(
  [selectCurrentOrganization],
  (currentOrganization) =>
    _.getNonEmpty(currentOrganization, 'address.country', 'SI')
);

export const selectNewAppointmentMessageEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) =>
    _.getNonEmpty(notificationsSettings, 'newAppointmentAddedSMSEnabled', false)
);

export const selectNoShowSmsEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) =>
    _.getNonEmpty(notificationsSettings, 'appointmentNoShowSMSEnabled', false)
);

export const selectNoShowEmailEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) =>
    _.getNonEmpty(notificationsSettings, 'appointmentNoShowEmailEnabled', false)
);

export const selectReminderMessageEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) => {
    const reminderChannels = _.getNonEmpty(
      notificationsSettings,
      'appointmentReminders.channels',
      []
    );

    return _.includes(reminderChannels, NOTIFICATION_DELIVERY_TYPE_SMS);
  }
);

export const selectNewAppointmentEmailEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) =>
    _.getNonEmpty(
      notificationsSettings,
      'newAppointmentAddedEmailEnabled',
      false
    )
);

export const selectReminderEmailEnabled = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) => {
    const reminderChannels = _.getNonEmpty(
      notificationsSettings,
      'appointmentReminders.channels',
      []
    );

    return _.includes(reminderChannels, NOTIFICATION_DELIVERY_TYPE_EMAIL);
  }
);

export const selectReminderPeriods = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) =>
    _.getNonEmpty(notificationsSettings, 'appointmentReminders.periods', [])
);

export const selectNotifyPatientWhenRescheduling = createSelector(
  [selectCurrentOrganizationNotificationSettings],
  (notificationsSettings) =>
    _.getNonEmpty(notificationsSettings, 'appointmentCanceledSMSEnabled', false)
);

// OTHER SETTINGS
export const selectCurrentOrganizationOtherSettings = createSelector(
  [selectCurrentOrganization],
  (organization) => _.getNonEmpty(organization, 'otherSettings', {})
);

export const selectCurrentOrganizationTherapySettings = createSelector(
  [selectCurrentOrganizationOtherSettings],
  (otherSettings) => _.getNonEmpty(otherSettings, 'drugTherapies', {})
);

export const selectAreMultipleActiveTherapiesAllowed = createSelector(
  [selectCurrentOrganizationTherapySettings],
  (therapySettings) =>
    _.getNonEmpty(therapySettings, 'allowMultipleActiveTherapies', false)
);

// WIDGET SELECTORS

export const selectOrganizationWidgetConfig = createSelector(
  selectOrganizationStore,
  (organization) => _.getNonEmpty(organization, 'organizationWidgets', {})
);

export const selectOrganizationWidgetSchema = createSelector(
  selectOrganizationWidgetConfig,
  (widgetConfig) => _.getNonEmpty(widgetConfig, 'widgets', {})
);

export const selectOrganizationWidgetGroups = createSelector(
  selectOrganizationWidgetConfig,
  (widgetConfig) => _.getNonEmpty(widgetConfig, 'groups', {})
);

export const selectOrganizationDefaultWidgetIdList = createSelector(
  selectOrganizationWidgetConfig,
  (widgetConfig) => _.getNonEmpty(widgetConfig, 'defaultWidgetsIds', [])
);

export const selectOrganizationWidgetExportSchema = createSelector(
  selectOrganizationWidgetConfig,
  (widgetConfig) => _.getNonEmpty(widgetConfig, 'exportSchema', {})
);

export const selectOrganizationBookingSettings = createSelector(
  selectCurrentOrganization,
  (orgSettings) => _.getNonEmpty(orgSettings, 'automatedBooking', {})
);

export const selectOrganizationLatestExportRequest = createSelector(
  selectCurrentOrganization,
  (orgSettings) => _.getNonEmpty(orgSettings, 'latestExportRequest', null)
);
