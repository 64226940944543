import axios from 'axios';
import _ from 'lodash';

import { showMissingLocationIdError } from '../../utils/alert';
import { getEventLogListUrl } from '../../utils/api/apiUrlUtils';

import {
  CLEAR_NEW_EVENT_LOG_ID_LIST,
  GET_EVENT_LOG_LIST,
  SET_EVENT_LOG_LIST_PAGINATION,
  CLEAR_ALL_NEW_EVENT_LOG_ID_LIST
} from './eventLogTypes';

import { selectCurrentLocationId } from '../location/locationSelectors';
import {
  selectEventLogListPagination,
  selectEventLogFilters
} from './eventLogSelectors';

export const getEventLogList = (locationId, eventTypes) => (
  dispatch,
  getState
) => {
  const url = getEventLogListUrl(getState);

  if (_.isEmptySafe(locationId)) {
    showMissingLocationIdError();

    return;
  }

  const store = getState();

  const pagination = selectEventLogListPagination(store);
  const filters = selectEventLogFilters(store);

  let safeEventTypes;

  if (_.isArray(eventTypes)) {
    safeEventTypes = eventTypes;
  } else {
    safeEventTypes = [eventTypes];
  }

  return dispatch({
    type: GET_EVENT_LOG_LIST,
    apiCall: axios.get(url, {
      params: {
        ...filters,
        ...pagination,
        locationIDs: locationId,
        eventTypes: safeEventTypes.join(',')
      }
    })
  });
};

export const getBookingEventLogList = (locationId) => (dispatch) =>
  dispatch(
    getEventLogList(locationId, ['bookingCreated', 'bookingCanceledByPatient'])
  );

export const setEventLogListPagination = (paginationName, paginationValue) => (
  dispatch,
  getState
) => {
  dispatch({
    type: SET_EVENT_LOG_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

  const locationId = selectCurrentLocationId(getState());

  dispatch(getBookingEventLogList(locationId));
};

export const clearNewEventLogIdList = (eventLogIds) => (dispatch) => {
  dispatch({
    type: CLEAR_NEW_EVENT_LOG_ID_LIST,
    eventLogIds
  });
};

export const clearAllNewEventLogIdList = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_NEW_EVENT_LOG_ID_LIST
  });
};
