import { createActionTypeArray } from '../../common/actionTypes';

export const GET_CREDIT_NOTE_LIST = 'creditNote/GET_CREDIT_NOTE_LIST';
export const ADD_CREDIT_NOTE = 'creditNote/ADD_CREDIT_NOTE';
export const UPDATE_CREDIT_NOTE = 'creditNote/UPDATE_CREDIT_NOTE';
export const PATCH_CREDIT_NOTE = 'billing/PATCH_CREDIT_NOTE';
export const REMOVE_CREDIT_NOTE = 'creditNote/REMOVE_CREDIT_NOTE';
export const GET_CREDIT_NOTE_DETAIL = 'creditNote/GET_CREDIT_NOTE_DETAIL';
export const CLEAR_CREDIT_NOTE_DETAIL = 'creditNote/CLEAR_CREDIT_NOTE_DETAIL';

export const SET_CREDIT_NOTE_LIST_FILTER =
  'creditNote/SET_CREDIT_NOTE_LIST_FILTER';
export const SET_CREDIT_NOTE_LIST_FILTERS =
  'creditNote/SET_CREDIT_NOTE_LIST_FILTERS';
export const CLEAR_CREDIT_NOTE_LIST_FILTER =
  'creditNote/CLEAR_CREDIT_NOTE_LIST_FILTER';
export const CLEAR_CREDIT_NOTE_LIST_FILTER_NO_POLL =
  'billing/CLEAR_CREDIT_NOTE_LIST_FILTER_NO_POLL';
export const SET_CREDIT_NOTE_LIST_INCLUDE_PARAM =
  'billing/SET_CREDIT_NOTE_LIST_INCLUDE_PARAM';
export const SET_CREDIT_NOTE_LIST_ORDER =
  'creditNote/SET_CREDIT_NOTE_LIST_ORDER';
export const SET_CREDIT_NOTE_LIST_PAGINATION =
  'creditNote/SET_CREDIT_NOTE_LIST_PAGINATION';

export const ADD_CREDIT_NOTE_PAYMENT = 'creditNote/ADD_CREDIT_NOTE_PAYMENT';
export const GET_CREDIT_NOTE_PAYMENTS_LIST =
  'creditNote/GET_CREDIT_NOTE_PAYMENTS_LIST';
export const REMOVE_CREDIT_NOTE_PAYMENT =
  'creditNote/REMOVE_CREDIT_NOTE_PAYMENT';
export const CLEAR_CREDIT_NOTE_PAYMENTS_LIST =
  'creditNote/CLEAR_CREDIT_NOTE_PAYMENTS_LIST';
export const RECHECK_CREDIT_NOTE_FURS_STATUS =
  'creditNote/RECHECK_CREDIT_NOTE_FURS_STATUS';
export const SEND_CREDIT_NOTE_VIA_EMAIL =
  'creditNote/SEND_CREDIT_NOTE_VIA_EMAIL';

export const [
  GET_CREDIT_NOTE_LIST_IN_PROGRESS,
  GET_CREDIT_NOTE_LIST_SUCCESS,
  GET_CREDIT_NOTE_LIST_FAILURE
] = createActionTypeArray(GET_CREDIT_NOTE_LIST);

export const [
  GET_CREDIT_NOTE_DETAIL_IN_PROGRESS,
  GET_CREDIT_NOTE_DETAIL_SUCCESS,
  GET_CREDIT_NOTE_DETAIL_FAILURE
] = createActionTypeArray(GET_CREDIT_NOTE_DETAIL);

export const [
  ADD_CREDIT_NOTE_IN_PROGRESS,
  ADD_CREDIT_NOTE_SUCCESS,
  ADD_CREDIT_NOTE_FAILURE
] = createActionTypeArray(ADD_CREDIT_NOTE);

export const [
  UPDATE_CREDIT_NOTE_IN_PROGRESS,
  UPDATE_CREDIT_NOTE_SUCCESS,
  UPDATE_CREDIT_NOTE_FAILURE
] = createActionTypeArray(UPDATE_CREDIT_NOTE);

export const [
  REMOVE_CREDIT_NOTE_IN_PROGRESS,
  REMOVE_CREDIT_NOTE_SUCCESS,
  REMOVE_CREDIT_NOTE_FAILURE
] = createActionTypeArray(REMOVE_CREDIT_NOTE);

export const [
  PATCH_CREDIT_NOTE_IN_PROGRESS,
  PATCH_CREDIT_NOTE_SUCCESS,
  PATCH_CREDIT_NOTE_FAILURE
] = createActionTypeArray(PATCH_CREDIT_NOTE);

export const [
  GET_CREDIT_NOTE_PAYMENTS_LIST_IN_PROGRESS,
  GET_CREDIT_NOTE_PAYMENTS_LIST_SUCCESS,
  GET_CREDIT_NOTE_PAYMENTS_LIST_FAILURE
] = createActionTypeArray(GET_CREDIT_NOTE_PAYMENTS_LIST);
