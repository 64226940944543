export default {
  tooth: 'Zob',
  teeth: 'Zobje',
  position: 'Pozicija',
  positions: 'Pozicije',
  positionsShortened: 'Poz.',
  addItemsAndServices: 'Dodajte predmet ali storitev',
  notes: 'Zabeležke',
  newDentalChartEntry: 'Nov vnos na zobozdravstveni karton',
  editDentalChartEntry: 'Uredite vnos v zobozdravstvenem kartonu',
  dentalChart: 'Zobozdravstveni karton',
  newEntry: 'Nov vnos',
  dentalChartEntry: 'Vnos v zobozdravstvenem kartonu',
  xRayImage: 'Rentgenska slika',
  uploadXRay: 'Naloži sliko',
  alert: {
    removed: 'Vnos v zobozdravstvenem kartonu je bil izbrisan',
    updated: 'Zobozdravstvena kartoteka je bila posodobljena',
    added: 'Zobozdravstvena kartoteka je bila dodana'
  }
};
