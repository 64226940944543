import _ from 'lodash';
import moment from 'moment';

import { translateKey } from '../../../i18n';
import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../constants/tableConstants';
import {
  THERAPY_STATUS_FINISHED,
  THERAPY_STATUS_PAUSED,
  THERAPY_STATUS_STOPPED,
  DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED,
  DRUG_INTAKE_STATUS_CONFIRMATION_SENT,
  DRUG_INTAKE_STATUS_LATE,
  DRUG_INTAKE_STATUS_NO_REPLY,
  DRUG_INTAKE_STATUS_NOT_TAKEN,
  DRUG_INTAKE_STATUS_PAUSED,
  DRUG_INTAKE_STATUS_PENDING,
  DRUG_INTAKE_STATUS_REMINDER_SENT,
  DRUG_INTAKE_STATUS_STOPPED,
  DRUG_INTAKE_STATUS_TAKEN_LATE,
  DRUG_INTAKE_STATUS_TAKEN_ON_TIME,
  THERAPY_DEFAULT_NOTIFICATION_TIME,
  THERAPY_DEFAULT_DURATION_VALUE,
  DRUG_INTAKE_STATUS_OK,
  THERAPY_STATUS_ACTIVE,
  DRUG_INTAKE_STATUS_REMINDER_NOT_SENT
} from '../../constants/therapyConstants';

import { ENTITY_STATUS_DELETED } from '../../alert';
import { MOSHI_COLOR_GREEN_500, MOSHI_COLOR_RED_300 } from '../../color';
import { getDateDifferenceDuration, getDateTimeFromTime } from '../../date';

const getActiveEventStatus = (status) => {
  if (_.includes(status, DRUG_INTAKE_STATUS_NOT_TAKEN)) {
    return DRUG_INTAKE_STATUS_NOT_TAKEN;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_TAKEN_LATE)) {
    return DRUG_INTAKE_STATUS_TAKEN_LATE;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_TAKEN_ON_TIME)) {
    return DRUG_INTAKE_STATUS_TAKEN_ON_TIME;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED)) {
    return DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_LATE)) {
    return DRUG_INTAKE_STATUS_LATE;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_NO_REPLY)) {
    return DRUG_INTAKE_STATUS_NO_REPLY;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_PENDING)) {
    return DRUG_INTAKE_STATUS_PENDING;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_CONFIRMATION_SENT)) {
    return DRUG_INTAKE_STATUS_CONFIRMATION_SENT;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_REMINDER_SENT)) {
    return DRUG_INTAKE_STATUS_REMINDER_SENT;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_REMINDER_NOT_SENT)) {
    return DRUG_INTAKE_STATUS_REMINDER_NOT_SENT;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_PAUSED)) {
    return DRUG_INTAKE_STATUS_PAUSED;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_STOPPED)) {
    return DRUG_INTAKE_STATUS_STOPPED;
  } else if (_.includes(status, DRUG_INTAKE_STATUS_OK)) {
    return DRUG_INTAKE_STATUS_OK;
  } else if (_.includes(status, ENTITY_STATUS_DELETED)) {
    return ENTITY_STATUS_DELETED;
  }
};

export const therapyMapper = (therapy) => {
  if (_.isEmpty(therapy)) {
    return therapy;
  }

  const clonedTherapy = _.cloneDeep(therapy);

  if (_.isEmptySafe(clonedTherapy, 'eventTime')) {
    clonedTherapy.eventTime = THERAPY_DEFAULT_NOTIFICATION_TIME;
  } else {
    clonedTherapy.eventTime = getDateTimeFromTime(
      clonedTherapy.eventTime
    ).toISOString();
  }
  clonedTherapy.indefiniteTherapy =
    _.getNonEmpty(clonedTherapy, 'duration.value', 0) < 1;

  clonedTherapy.sendReminder = _.getNonEmpty(
    clonedTherapy,
    'sendReminder',
    false
  );
  clonedTherapy.askForConfirmation = _.getNonEmpty(
    clonedTherapy,
    'askForConfirmation',
    false
  );

  if (_.isEmptySafe(clonedTherapy, 'duration')) {
    clonedTherapy.duration = THERAPY_DEFAULT_DURATION_VALUE;
  }

  return clonedTherapy;
};

export const patientTherapyDrugIntakeMapper = (
  patientActiveTherapy,
  drugList
) => {
  const patientDrugIntakeList = _.get(patientActiveTherapy, 'events', []);
  const patientDrugIntakeListClone = _.cloneDeep(patientDrugIntakeList);

  const sortedPatientDrugIntakeList = _.orderBy(
    patientDrugIntakeListClone,
    ['scheduledFor'],
    [ORDER_ASCENDING]
  );

  const groupedIntakeList = _.groupBy(
    sortedPatientDrugIntakeList,
    'therapyVersion'
  );

  const therapyVersionList = _.keys(groupedIntakeList);
  const latestVersion = _.last(therapyVersionList);
  const latestIntakeList = _.getNonEmpty(groupedIntakeList, latestVersion);

  let foundCount = 0;
  const remappedPatientDrugIntakeList = _.map(
    sortedPatientDrugIntakeList,
    (patientDrugIntake, index) => {
      if (_.isEmpty(patientDrugIntake)) {
        return patientDrugIntake;
      }

      const foundDrug = _.find(drugList, { id: patientActiveTherapy.drugID });
      const foundLatestIntake = _.find(latestIntakeList, {
        id: patientDrugIntake.id
      });

      if (!_.isEmpty(foundDrug)) {
        patientDrugIntake.drug = foundDrug;
      }
      if (_.isEmpty(foundDrug)) {
        patientDrugIntake.drug = {
          name: _.get(patientActiveTherapy, 'drugName', '/')
        };
      } else {
        patientDrugIntake.drug = foundDrug;
      }

      if (_.isEmpty(foundLatestIntake) && therapyVersionList.length > 2) {
        patientDrugIntake.outdated = true;
      }

      if (
        _.isEmpty(patientDrugIntake.status) ||
        _.includes(patientDrugIntake.status, DRUG_INTAKE_STATUS_OK)
      ) {
        if (
          foundCount >= 3 ||
          _.includes(
            [
              THERAPY_STATUS_PAUSED,
              THERAPY_STATUS_STOPPED,
              THERAPY_STATUS_FINISHED
            ],
            patientActiveTherapy.status
          )
        ) {
          patientDrugIntake.hidden = true;
        } else {
          foundCount++;
        }
      }

      patientDrugIntake.intakeNumber = index + 1;
      patientDrugIntake.activeStatus = getActiveEventStatus(
        patientDrugIntake.status
      );

      return patientDrugIntake;
    }
  );

  return _.orderBy(
    remappedPatientDrugIntakeList,
    ['intakeNumber'],
    [ORDER_DESCENDING]
  );
};

const getCurrentIntakeCount = (events) =>
  _.filter(events, (event) => {
    const eventStatus = _.get(event, 'status');

    return (
      !_.isEmpty(eventStatus) &&
      !_.includes(eventStatus, DRUG_INTAKE_STATUS_PENDING)
    );
  }).length;

export const getTherapyCalculatedDuration = (startDate, endDate) => {
  const hasTherapyReachedEndDate = moment() < moment(endDate);
  const targetDate = hasTherapyReachedEndDate ? moment() : endDate;

  return getDateDifferenceDuration(startDate, targetDate);
};

// eslint-disable-next-line max-statements
export const createPatientTherapyMapper = (drugList, allUsersList) => (
  originalPatientTherapy
) => {
  const patientTherapy = therapyMapper(originalPatientTherapy);

  if (_.isEmpty(patientTherapy)) {
    return patientTherapy;
  }

  const foundDrug = _.find(drugList, {
    id: _.get(patientTherapy, 'drugID')
  });
  const doctorId = _.getNonEmpty(
    patientTherapy,
    'doctorID',
    patientTherapy.createdBy
  );
  const foundUser = _.find(allUsersList, {
    id: doctorId
  });

  if (_.isEmpty(foundDrug)) {
    patientTherapy.drug = {
      name: _.get(patientTherapy, 'drugName', '/')
    };
  } else {
    patientTherapy.drug = foundDrug;
  }

  if (!_.isEmpty(foundUser)) {
    patientTherapy.doctor = foundUser;
  }

  patientTherapy.events = patientTherapyDrugIntakeMapper(
    patientTherapy,
    drugList
  );

  const durationValue = _.get(patientTherapy, 'duration.value', 0);
  const durationScale = _.get(patientTherapy, 'duration.scale', 'days');
  const therapyCalculatedDuration = getTherapyCalculatedDuration(
    patientTherapy.startDate,
    patientTherapy.endDate
  );

  patientTherapy.endDate = moment(patientTherapy.startDate)
    .add(durationValue, durationScale)
    .toISOString();
  patientTherapy.durationInDays = _.ceil(therapyCalculatedDuration.asDays());
  patientTherapy.targetedIntakeCount = _.get(
    patientTherapy,
    'events',
    []
  ).length;
  patientTherapy.currentIntakeCount = getCurrentIntakeCount(
    patientTherapy.events
  );

  patientTherapy.intakeProgressPercentage = _.round(
    (patientTherapy.currentIntakeCount / patientTherapy.targetedIntakeCount) *
      100
  );

  return patientTherapy;
};

export const mapTherapyIsActive = (therapy) => {
  therapy.isActive = _.includesAny(therapy.status, [
    THERAPY_STATUS_ACTIVE,
    THERAPY_STATUS_PAUSED
  ]);

  return therapy;
};

// eslint-disable-next-line max-statements
export const mapTherapyNamePreview = (therapy) => {
  if (_.isEmptySafe(therapy)) {
    return therapy;
  }

  const fallbackTherapyName = _.get(therapy, 'drugName', '/');
  const therapyName = _.get(therapy, 'drug.name', fallbackTherapyName);
  const quantityValue = _.getNonEmpty(therapy, 'quantity.value', 0);
  const quantityScale = _.get(therapy, 'quantity.scale');
  const frequencyValue = _.getNonEmpty(therapy, 'frequency.value', 0);
  const frequencyScale = _.get(therapy, 'frequency.scale');
  const durationValue = _.getNonEmpty(therapy, 'duration.value', 0);
  const durationScale = _.get(therapy, 'duration.scale');

  const quantityDisplay = translateKey('common:numericValueWithSuffix', {
    value: quantityValue,
    suffix: translateKey(`therapy:quantitySuffix.${quantityScale}`, {
      count: quantityValue
    })
  });

  let frequencyPrefix = '';
  let frequencyDisplayValue = '';

  if (!_.isEmptySafe(frequencyPrefix)) {
    frequencyPrefix = translateKey('therapy:frequencyPrefix', {
      count: frequencyValue
    });
  }

  if (frequencyValue > 0 && !_.isEmptySafe(frequencyScale)) {
    frequencyDisplayValue = translateKey('common:numericValueWithSuffix', {
      value: frequencyValue,
      suffix: translateKey(`therapy:timeSuffix.${frequencyScale}`, {
        count: frequencyValue
      }),
      count: frequencyValue
    });
  }

  let durationPrefix = translateKey('therapy:indefiniteDurationPrefix');
  let durationDisplayValue = _.toLower(
    translateKey('therapy:indefiniteDuration')
  );

  if (durationValue > 0 && !_.isEmptySafe(durationScale)) {
    durationPrefix = translateKey('therapy:durationPrefix');
    durationDisplayValue = translateKey('common:numericValueWithSuffix', {
      value: durationValue,
      suffix: translateKey(`therapy:timeSuffix.${durationScale}`, {
        count: durationValue
      })
    });
  }

  therapy.name = `${therapyName} - ${quantityDisplay} ${frequencyPrefix} ${frequencyDisplayValue} ${durationPrefix} ${durationDisplayValue}`;

  return therapy;
};

export const mapTherapyDropdownWithColorAndDate = (therapy) => {
  let mappedTherapy = _.cloneDeep(therapy);

  mappedTherapy = mapTherapyNamePreview(mappedTherapy);
  mappedTherapy = therapyMapper(mappedTherapy);

  const startDate = _.getNonEmpty(mappedTherapy, 'startsAt');

  const startDateDisplayFormat = translateKey('date:shortDate', {
    date: startDate
  });

  const durationValue = _.get(mappedTherapy, 'duration.value', 0);
  const durationScale = _.get(mappedTherapy, 'duration.scale', 'days');

  const endDate = moment(mappedTherapy.startDate)
    .add(durationValue, durationScale)
    .toISOString();

  const endDateDisplayFormat = mappedTherapy.indefiniteTherapy
    ? translateKey('therapy:indefiniteDuration')
    : translateKey('date:shortDate', { date: endDate });

  mappedTherapy.color = mappedTherapy.isActive
    ? MOSHI_COLOR_GREEN_500
    : MOSHI_COLOR_RED_300;

  mappedTherapy.name = `${mappedTherapy.name}, ${startDateDisplayFormat} - ${endDateDisplayFormat}`;

  return mappedTherapy;
};
