import ReactGA from 'react-ga';

import _ from 'lodash';

import moshiConfig, { ENV_DEVELOPMENT } from './moshiConfig';

if (
  !_.isEmpty(moshiConfig.googleAnalyticsKey) &&
  moshiConfig.appEnv !== ENV_DEVELOPMENT
) {
  ReactGA.initialize(moshiConfig.googleAnalyticsKey);
  ReactGA.pageview(window.location.pathname);
  moshiConfig.appState.googleAnalyticsInitialized = true;
}

export const googleAnalyticsRecordPageView = (newPathname) => {
  if (
    !_.getNonEmpty(moshiConfig, 'appState.googleAnalyticsInitialized', false)
  ) {
    return;
  }

  const routes = newPathname.split('/');

  const routesWithHiddenIds = routes.map((route) => {
    if (_.isGuid(route)) {
      return 'UUID';
    } else {
      return route;
    }
  });

  ReactGA.pageview(newPathname, [], routesWithHiddenIds.join('/'));
};
