export default {
  title: 'Analytics',
  emptyListText: 'Please provide some data.',
  location: 'Location',
  invalidDateRange: '*Invalid date range',
  dashboard: {
    title: 'Dashboard',
    totalSales: 'Total sales',
    appointments: 'Appointments',
    today: 'Today',
    thisWeek: 'This week',
    thisMonth: 'This month',
    lastSevenDays: 'Last 7 days',
    revenuePerApp: 'Rev./Appoint.'
  },
  sales: {
    title: 'Sales',
    totalSalesPerDoctor: 'Total sales per doctor',
    totalSalesPerPaymentType: 'Total sales per payment type',
    paymentType: 'Payment type'
  },
  appointments: {
    title: 'Appointments',
    total: 'Total',
    completed: 'Completed',
    noShow: 'No show',
    reserved: 'Reserved',
    futureConfirmed: 'Upcoming',
    canceled: 'Canceled',
    busiestDay: 'Busiest day',
    busiestWeek: 'Busiest week',
    appointmentsPerDoctor: 'Appointments per doctor',
    appointmentsPerCategory: 'Appointments per category'
  },
  patients: {
    title: 'Patients',
    newPatients: 'New patients',
    newPatientsInfo: 'New patients added in this time period',
    activePatients: 'Active Patients',
    activePatientsInfo: 'At least one visit in this time period',
    byGender: 'Gender',
    allTime: 'All time',
    allPatients: 'All patients',
    noGender: 'Undefined',
    totalCount: 'Number',
    averageAge: 'Average age',
    byDoctors: 'Patients per doctor',
    patientsNumber: 'Number of patients',
    salesPerPatient: 'Patients with most sales',
    appointmentsPerPatient: 'Patients with most appointments'
  }
};
