export const BUCKET_TYPE_DEFAULT = 'default';
export const BUCKET_TYPE_NORMAL = 'normal';
export const BUCKET_TYPE_CONSULTATION = 'consultation';
export const BUCKET_TYPE_COMPLETED = 'completed';

export const BUCKET_NAME_QUEUE = 'queue';
export const BUCKET_NAME_UP_NEXT = 'normal';
export const BUCKET_NAME_CONSULTATION = 'consultation';
export const BUCKET_NAME_COMPLETED = 'completed';

export const YES = 'yes';
export const NO = 'no';
