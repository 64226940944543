/*
 * Note: `x-errors` should be of a type array,
 * but due to limitation that array type cannot have custom widget,
 * it's set to string
 */
export default {
  id: 'indexes/CALCULATION_ERROR/v1',
  formSchema: {
    title: 'encounter:widget.indexes.calculationError',
    type: 'object',
    properties: {
      'x-errors': {
        type: 'string'
      }
    },
    iconClass: 'icon-error'
  },
  uiSchema: {
    'x-errors': { 'ui:widget': 'customCalculationErrorWidget' }
  }
};
