export default {
  title: 'Tags',
  entity: 'Tag',
  removeEntity: 'Tag',
  addTag: '+ Add tag',
  newTag: 'New tag',
  editTag: 'Edit tag',
  tagName: 'Tag name',
  tagColor: 'Tag color',
  assignTags: 'Assign tags',
  assignTagsOnPatients: 'Assign tags to patients',
  assignTagsOnPatient: 'Assign tags to patient',
  createNewTag: 'Create new tag "{{ name }}"',
  alerts: {
    tagCreated: 'Tag was successfully created',
    tagUpdated: 'Tag was successfully updated',
    tagDeleted: 'Tag was successfully deleted'
  }
};
