import * as Sentry from '@sentry/browser';
import _ from 'lodash';

// const formatMsg = (msg) => _.isObject(msg) ? JSON.stringify(msg) : msg;

const getCircularReplacer = () => {
  const seen = new WeakSet();

  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }

    return value;
  };
};

export const safeStringify = (msg, space = 2) =>
  JSON.stringify(msg, getCircularReplacer(), space);

const defineConsoleMethod = (method, logToSentry = false) => {
  // In production we do not want to display console messages
  const returnMethod = process.env.production ? _.noop : method;

  return (...messages) => {
    const formattedMessages = [];
    let capturedError = false;

    _.forEach(messages, (msg) => {
      const formattedMsg =
        _.isPlainObject(msg) || _.isArray(msg) ? safeStringify(msg) : msg;

      if (_.isError(msg)) {
        Sentry.captureException(msg);
        capturedError = true;
      }

      formattedMessages.push(formattedMsg);
    });

    if (logToSentry && !capturedError && _.isArray(formattedMessages)) {
      const newError = new Error(formattedMessages.join(' '));

      Sentry.captureException(newError);
    }

    return returnMethod(...formattedMessages);
  };
};

/* eslint-disable no-console */
const log = defineConsoleMethod(console.log);
const warning = defineConsoleMethod(console.warn);
const error = defineConsoleMethod(console.error, true);
const devError = defineConsoleMethod(console.error);
/* eslint-enable no-console */

const logger = {
  log,
  warning,
  error,
  devError
};

export default logger;
