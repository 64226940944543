import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal } from 'reactstrap';

import classNames from 'classnames';
import { Form } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../i18n';
import { MOSHI_COLOR_SECONDARY_NAME } from '../../../utils/color';
import { MOSHI_SIZE_LG } from '../../../utils/constants/sizeConstants';

import { ButtonShape } from '../../../metadata/shapes/ModalShape';

import { ModalFooter, ModalHeader } from '../../reactstrap';
import Icon from '../Icon';
import MoshiButton from '../button/MoshiButton';
import MoshiLink from '../button/MoshiLink';
import MoshiFormik from '../form/MoshiFormik';
import SubmitButton from '../form/button/SubmitButton';
import ConditionalWrap from '../layout/ConditionalWrap';
import Visible from '../layout/Visible';
import ModalHeaderText from './ModalHeaderText';

export class MoshiModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    const { contentProps, isInitialOpen } = this.props;

    this.state = {
      modalOpen: isInitialOpen,
      contentProps: contentProps || {}
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.isModalOpen = this.isModalOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { toggleRef, isModalOpenRef } = this.props;

    if (_.isPlainObject(toggleRef) && _.has(toggleRef, 'current')) {
      toggleRef.current = this.toggleModal;
    } else if (_.isFunction(toggleRef)) {
      toggleRef(this.toggleModal);
    } else {
      throw new Error('toggleRef is not a function or a react ref');
    }

    if (_.isPlainObject(isModalOpenRef) && _.has(isModalOpenRef, 'current')) {
      isModalOpenRef.current = this.isModalOpen;
    } else if (_.isFunction(isModalOpenRef)) {
      isModalOpenRef(this.isModalOpen);
    } else {
      throw new Error('isModalOpenRef is not a function or a react ref');
    }
  }

  get modalHeader() {
    const { header } = this.props;
    let FinalHeaderComponent;

    if (_.isString(header)) {
      FinalHeaderComponent = () => (
        <ModalHeader>
          <ModalHeaderText text={header} />
        </ModalHeader>
      );
    } else if (_.isFunction(header)) {
      FinalHeaderComponent = header;
    } else {
      FinalHeaderComponent = () => header;
    }

    return FinalHeaderComponent;
  }

  getModalFooter(handleSubmit) {
    const {
      t: translate,
      submitButtonText,
      submitButtonColor,
      submitButtonId,
      submitButtonInvisible,
      cancelButtonText,
      cancelButtonSize,
      cancelButtonInvisible,
      disableForm,
      extraFooterButtons,
      footerClassName
    } = this.props;

    return (
      <ModalFooter
        className={classNames('flex-justify-end-row', footerClassName)}
      >
        {extraFooterButtons.map(
          ({ invisible: invisibleButton, ...footerButton }) => (
            <Visible
              key={_.kebabCase(footerButton.text)}
              visible={!invisibleButton}
            >
              <MoshiButton {...footerButton}>{footerButton.text}</MoshiButton>
            </Visible>
          )
        )}
        <Visible visible={!cancelButtonInvisible}>
          <MoshiButton
            id={'cancel'}
            size={cancelButtonSize}
            color={MOSHI_COLOR_SECONDARY_NAME}
            onClick={this.handleCancel}
          >
            {cancelButtonText || translate('common:cancel')}
          </MoshiButton>
        </Visible>
        <Visible visible={!submitButtonInvisible}>
          {/* buttonType has to be 'button' since MoshiModal can be wrapped in a form and we don't want to submit the form */}
          <SubmitButton
            buttonType={'button'}
            color={submitButtonColor}
            id={submitButtonId}
            text={submitButtonText || translate('common:save')}
            onSubmit={disableForm ? this.handleSubmit : handleSubmit}
          />
        </Visible>
      </ModalFooter>
    );
  }

  handleSubmit(...args) {
    const { onSubmit } = this.props;
    const { contentProps } = this.state;

    args.push(contentProps);

    onSubmit(this.toggleModal, ...args);
  }

  handleCancel() {
    const { onCancel } = this.props;

    this.toggleModal({}, false);
    onCancel();
  }

  toggleModal(contentProps = {}, forceToggle = undefined) {
    const { onToggle } = this.props;

    const resetForm = _.getNonEmpty(this, 'formRef.current.resetForm', null);

    if (_.isFunction(resetForm)) {
      resetForm();
    }

    this.setState(
      (prevState) => {
        const newModalState = !prevState.modalOpen;
        let newState = {
          modalOpen: forceToggle || newModalState
        };

        if (!_.isEmpty(contentProps)) {
          newState = {
            ...newState,
            contentProps
          };
        }

        return newState;
      },
      () => {
        const { modalOpen } = this.state;

        onToggle(modalOpen, contentProps);
      }
    );
  }

  isModalOpen() {
    const { modalOpen } = this.state;

    return modalOpen;
  }

  renderCloseButton() {
    return (
      <MoshiLink className={'modal-close-button'} onClick={this.handleCancel}>
        <Icon name={'close'} size={MOSHI_SIZE_LG} />
      </MoshiLink>
    );
  }

  render() {
    const {
      className,
      children,
      name,
      initialFormValues,
      validationFormSchema,
      disableForm,
      formClassName
    } = this.props;
    const { modalOpen, contentProps } = this.state;
    const contentPropsFormValues = _.get(contentProps, 'initialFormValues', {});
    const FinalHeaderComponent = this.modalHeader;
    const newInitialValues = {
      ...initialFormValues,
      ...contentPropsFormValues
    };

    return (
      <MoshiFormik
        innerRef={this.formRef}
        initialValues={newInitialValues}
        enableReinitialize
        validationSchema={validationFormSchema}
        onSubmit={this.handleSubmit}
      >
        {(formik) => (
          <Modal
            modalClassName={'moshi-modal'}
            className={classNames(`${name}-modal`, className)}
            isOpen={modalOpen}
          >
            {this.renderCloseButton()}
            <FinalHeaderComponent />
            <ConditionalWrap
              condition={!disableForm}
              wrap={(wrapChildren) => (
                <Form className={formClassName}>{wrapChildren}</Form>
              )}
            >
              {_.isFunction(children)
                ? children(contentProps, formik)
                : children}
              {this.getModalFooter(formik.handleSubmit)}
            </ConditionalWrap>
          </Modal>
        )}
      </MoshiFormik>
    );
  }
}

MoshiModalComponent.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  toggleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isModalOpenRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  submitButtonText: PropTypes.string,
  submitButtonColor: PropTypes.string,
  submitButtonId: PropTypes.string,
  submitButtonInvisible: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonSize: PropTypes.string,
  cancelButtonInvisible: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  initialFormValues: PropTypes.shape(),
  validationFormSchema: PropTypes.shape(),
  contentProps: PropTypes.shape(),
  disableForm: PropTypes.bool,
  formClassName: PropTypes.string,
  onToggle: PropTypes.func,
  isInitialOpen: PropTypes.bool,
  extraFooterButtons: PropTypes.arrayOf(PropTypes.shape(ButtonShape)),
  footerClassName: PropTypes.string
};

MoshiModalComponent.defaultProps = {
  className: '',
  name: 'custom',
  contentProps: {},
  header: null,
  initialFormValues: {},
  validationFormSchema: undefined,
  submitButtonColor: undefined,
  submitButtonId: undefined,
  submitButtonText: undefined,
  submitButtonInvisible: false,
  cancelButtonText: undefined,
  cancelButtonSize: MOSHI_SIZE_LG,
  cancelButtonInvisible: false,
  onCancel: _.noop,
  isModalOpenRef: _.noop,
  disableForm: false,
  formClassName: undefined,
  onToggle: _.noop,
  isInitialOpen: false,
  extraFooterButtons: [],
  footerClassName: undefined
};

export default localize(MoshiModalComponent);
