import _ from 'lodash';
import moment from 'moment';

import { storeGetState } from '../../configureStore';
import { translateKey } from '../../i18n';
import {
  ASSIGNEE_TYPE_RESOURCE,
  ASSIGNEE_TYPE_USER,
  EVENT_COLOR_SOURCE_ASSIGNEE,
  EVENT_COLOR_SOURCE_CATEGORY,
  EVENT_COLOR_TYPE_OUTLINE,
  EVENT_STATUS_RESERVATION
} from '../constants/calendarConstants';
import { ORDER_ASCENDING, ORDER_DESCENDING } from '../constants/tableConstants';

import {
  selectCalendarEventColorType,
  selectCalendarSelectedAssignees,
  selectCurrentCalendarEventColorSource
} from '../../redux/calendar/calendarSelectors';
import { selectIsCategoryDeleted } from '../../redux/calendar/category/categorySelectors';
import {
  selectCachedOrDeletedLocationById,
  selectCachedOrDeletedUserById,
  selectCachedResourceById
} from '../../redux/core/cache/cacheSelectors';
import { selectCurrentOrganization } from '../../redux/organization/organizationSelectors';

import {
  DEFAULT_CATEGORY_COLOR,
  getTextColorByBackgroundColor,
  MOSHI_COLOR_WHITE_NAME
} from '../color';
import {
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_STATUS_COMPLETED,
  NOTIFICATION_STATUS_DELETED,
  NOTIFICATION_STATUS_FAILED,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_TYPE_APPOINTMENT_ADDED,
  NOTIFICATION_TYPE_REMINDER
} from '../data/notifications';
import { mergeDateAndDateTime } from '../date';
import {
  getEventDuration,
  getEventEndTime,
  getEventStartTime,
  getEventStatus,
  getEventTitle,
  getEventType
} from './cleaned/calendar-mappers';
import { getReminderPeriods } from './organization-mappers';

export const mapAndOrderCategories = (categories) => {
  _.map(categories, (category) => {
    if (_.isEmpty(category.color)) {
      category.color = DEFAULT_CATEGORY_COLOR;
      category.textColor = MOSHI_COLOR_WHITE_NAME;
    } else {
      category.textColor = getTextColorByBackgroundColor(category.color);
    }
  });

  return _.orderBy(categories, 'name', ORDER_ASCENDING);
};

const getNewReminderMessages = (deliveryType, eventData, reminderPeriods) => {
  const timeOfEvent = mergeDateAndDateTime(eventData.date, eventData.from);

  // filter only reminders set on organization and scheduled some time in the future
  const futureReminderPeriods = _.filter(
    getReminderPeriods(),
    (period) =>
      _.includes(reminderPeriods, period.id) &&
      moment(timeOfEvent)
        .subtract(period.hoursBefore, 'hours')
        .isSameOrAfter(moment().format())
  );

  const sortedReminderPeriods = _.orderBy(
    futureReminderPeriods,
    'hoursBefore',
    ORDER_DESCENDING
  );
  const reminderMessages = _.map(
    sortedReminderPeriods,
    ({ hoursBefore, id }) => {
      const timeOfReminder = moment(timeOfEvent).subtract(hoursBefore, 'hours');

      return getEventMessage(
        `${id}-${deliveryType}`,
        timeOfReminder,
        NOTIFICATION_TYPE_REMINDER,
        id,
        deliveryType
      );
    }
  );

  return reminderMessages;
};

const getNewAppointmentMessage = (deliveryType) => {
  const fiveMinsFromNow = moment().add(5, 'minutes').toDate();
  const newAppointmentMessage = getEventMessage(
    `new-${deliveryType}`,
    fiveMinsFromNow,
    NOTIFICATION_TYPE_APPOINTMENT_ADDED,
    null,
    deliveryType
  );

  return newAppointmentMessage;
};

const createNewAppointmentMessages = (
  newAppointmentSmsEnabled,
  hasEmailConsent,
  hasSmsConsent,
  newAppointmentEmailEnabled
) => {
  const messages = [];

  if (newAppointmentSmsEnabled && hasSmsConsent) {
    messages.push(getNewAppointmentMessage(NOTIFICATION_DELIVERY_TYPE_SMS));
  }

  if (newAppointmentEmailEnabled && hasEmailConsent) {
    messages.push(getNewAppointmentMessage(NOTIFICATION_DELIVERY_TYPE_EMAIL));
  }

  return messages;
};

const createReminderMessages = (
  reminderSmsEnabled,
  eventData,
  reminderPeriods,
  hasEmailConsent,
  hasSmsConsent,
  reminderEmailEnabled
) => {
  const messages = [];

  if (reminderSmsEnabled && hasSmsConsent) {
    messages.push(
      ...getNewReminderMessages(
        NOTIFICATION_DELIVERY_TYPE_SMS,
        eventData,
        reminderPeriods
      )
    );
  }

  if (reminderEmailEnabled && hasEmailConsent) {
    messages.push(
      ...getNewReminderMessages(
        NOTIFICATION_DELIVERY_TYPE_EMAIL,
        eventData,
        reminderPeriods
      )
    );
  }

  return messages;
};

export const mapMessagesForNewEvent = (
  eventData,
  reminderPeriods,
  reminderSmsEnabled,
  newAppointmentSmsEnabled,
  hasEmailConsent,
  hasSmsConsent,
  newAppointmentEmailEnabled,
  reminderEmailEnabled
) => {
  const messages = [];

  messages.push(
    ...createNewAppointmentMessages(
      newAppointmentSmsEnabled,
      hasEmailConsent,
      hasSmsConsent,
      newAppointmentEmailEnabled
    )
  );

  messages.push(
    ...createReminderMessages(
      reminderSmsEnabled,
      eventData,
      reminderPeriods,
      hasEmailConsent,
      hasSmsConsent,
      reminderEmailEnabled
    )
  );

  return messages;
};

export const getEventMessage = (
  id,
  date,
  type,
  period = null,
  deliveryType = NOTIFICATION_DELIVERY_TYPE_SMS,
  status = NOTIFICATION_STATUS_PENDING
) => {
  let icon;
  let iconClassName;

  switch (status) {
    case NOTIFICATION_STATUS_PENDING:
      icon = 'time';
      break;
    case NOTIFICATION_STATUS_COMPLETED:
      icon = 'ok-circle';
      iconClassName = 'icon-color-success';
      break;
    case NOTIFICATION_STATUS_DELETED:
    case NOTIFICATION_STATUS_FAILED:
    default:
      icon = 'cancel';
      iconClassName = 'icon-color-danger';
      break;
  }

  return {
    id,
    text: translateKey(`calendar:notifications.types.${type}`),
    type,
    typeText: translateKey(
      `calendar:notifications.deliveryTypes.${deliveryType}`
    ),
    status,
    icon,
    iconClassName,
    time: date,
    date,
    period,
    deliveryType
  };
};

export const getCalendarEventTargetId = (eventTitle, eventId) =>
  `event-${_.kebabCase(eventTitle)}-${eventId}`;

export const mapEventAssignees = (assignees) =>
  _.map(assignees, (assignee) => {
    const type = assignee.type;

    if (type === ASSIGNEE_TYPE_USER) {
      const user = selectCachedOrDeletedUserById(storeGetState(), assignee.id);

      assignee.assignee = user;
    }
    if (type === ASSIGNEE_TYPE_RESOURCE) {
      const resource = selectCachedResourceById(storeGetState(), assignee.id);

      assignee.assignee = resource;
    }

    if (_.isEmptySafe(type)) {
      const user = selectCachedOrDeletedUserById(storeGetState(), assignee.id);
      let resource;

      if (_.isEmptySafe(user)) {
        resource = selectCachedResourceById(storeGetState(), assignee.id);
        if (!_.isEmptySafe(resource)) {
          resource.type = ASSIGNEE_TYPE_RESOURCE;
        }
      } else {
        user.type = ASSIGNEE_TYPE_USER;
      }

      assignee.assignee = _.isEmptySafe(user) ? resource : user;
    }

    return assignee;
  });

// eslint-disable-next-line max-statements
export const getEventColors = (event, categories, eventAssignees) => {
  const eventType = _.getNonEmpty(event, 'type');
  const eventColorType = selectCalendarEventColorType(
    storeGetState(),
    eventType
  );
  const { isCanceled, isReservation } = getEventStatus(event);
  const isOutlinedEvent =
    eventColorType === EVENT_COLOR_TYPE_OUTLINE || isCanceled;

  const colorObj = {};
  const foundCategory = _.find(categories, { id: event.categoryID });
  const eventTextColor = _.get(
    foundCategory,
    'textColor',
    MOSHI_COLOR_WHITE_NAME
  );
  const eventColorSource = selectCurrentCalendarEventColorSource(
    storeGetState()
  );
  const isAssigneeColorSource =
    eventColorSource === EVENT_COLOR_SOURCE_ASSIGNEE;
  const isCategoryColorSource =
    eventColorSource === EVENT_COLOR_SOURCE_CATEGORY;
  const categoryColor = _.getNonEmpty(
    foundCategory,
    'color',
    DEFAULT_CATEGORY_COLOR
  );
  const selectedAssignees = selectCalendarSelectedAssignees(storeGetState());

  const selectedEventAssignees = _.intersectionBy(
    eventAssignees,
    selectedAssignees,
    'id'
  );

  const assigneeColor = _.getNonEmpty(
    selectedEventAssignees,
    '[0].assignee.color',
    categoryColor
  );

  colorObj.textColor = eventTextColor;
  colorObj.categoryColor = categoryColor;

  // Reservation text gets the same color as the event
  if (isReservation || isCanceled) {
    colorObj.backgroundColor = MOSHI_COLOR_WHITE_NAME;
    colorObj.textColor = categoryColor;
  }
  // The default event color is the category color
  colorObj.color = categoryColor;

  // If the color source is the doctor we set the background and text color accordingly
  if (!_.isEmpty(eventAssignees) && isAssigneeColorSource) {
    colorObj.color = assigneeColor;
    colorObj.textColor = isReservation
      ? assigneeColor
      : getTextColorByBackgroundColor(assigneeColor);

    if (!_.isEmptySafe(foundCategory, 'color')) {
      colorObj.borderColor = categoryColor;
    }

    if (isOutlinedEvent) {
      colorObj.backgroundColor = MOSHI_COLOR_WHITE_NAME;
      colorObj.textColor = assigneeColor;
    }
  } else if (!_.isEmpty(eventAssignees) && isCategoryColorSource) {
    colorObj.borderColor = assigneeColor;
    if (isOutlinedEvent) {
      colorObj.backgroundColor = MOSHI_COLOR_WHITE_NAME;
      colorObj.textColor = categoryColor;
    }
  }

  return colorObj;
};

export const getOrganizationPhoneNumber = () => {
  const organization = selectCurrentOrganization(storeGetState());

  return _.getNonEmpty(organization, 'notificationsSettings.phoneNumber', '/');
};

export const mapEventsForCalendar = (categories) => (event) => {
  if (_.isEmpty(event)) {
    return {};
  }
  const { isOther, isReminder, isQueue } = getEventType(event);
  const { isCanceled, isAutomatedBooking } = getEventStatus(event);
  const eventPatient = isOther ? {} : _.getNonEmpty(event, 'patient', {});
  const fallBackAssignees = _.map(event.doctorIDs, (id) => ({
    type: ASSIGNEE_TYPE_USER,
    id
  }));
  const eventAssignees = mapEventAssignees(
    _.isEmptySafe(event, 'assignees') ? fallBackAssignees : event.assignees
  );
  const location = selectCachedOrDeletedLocationById(
    storeGetState(),
    event.locationID
  );
  const isCategoryDeleted = selectIsCategoryDeleted(
    storeGetState(),
    event.categoryID
  );

  if (isCanceled) {
    event.startEditable = false;
    event.durationEditable = false;
    event.resourceEditable = false;
  }

  if (isAutomatedBooking) {
    event.startEditable = false;
  }

  if (isReminder) {
    event.durationEditable = false;
  }

  if (isQueue) {
    event.status = EVENT_STATUS_RESERVATION;
  }

  return {
    ...event,
    duration: getEventDuration(event),
    title: getEventTitle(event),
    start: getEventStartTime(event),
    end: getEventEndTime(event),
    eventDuration: getEventDuration(event, true),
    patient: eventPatient,
    priority: _.getNonEmpty(event, 'priority', '1').toString(),
    location,
    organizationPhoneNumber: getOrganizationPhoneNumber(),
    ...getEventColors(event, categories, eventAssignees),
    assignees: eventAssignees,
    assigneeIDs: _.map(eventAssignees, 'id'),
    resourceIds: _.map(eventAssignees, 'id'),
    isCategoryDeleted
  };
};
