import produce from 'immer';
import _ from 'lodash';

import { ORDER_ASCENDING } from '../../utils/constants/tableConstants';
import moshiConfig from '../../utils/moshiConfig';

import {
  POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST_SUCCESS,
  POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST_SUCCESS
} from '../core/cache/cacheTypes';
import {
  GET_THERAPY_LIST_FAILURE,
  GET_THERAPY_LIST_IN_PROGRESS,
  GET_THERAPY_LIST_SUCCESS,
  SET_THERAPY_LIST_FILTER,
  SET_THERAPY_LIST_ORDER,
  SET_THERAPY_LIST_PAGINATION,
  CLEAR_THERAPY_DETAIL,
  GET_THERAPY_DETAIL_SUCCESS,
  GET_FORM_SUBMISSION_DETAIL_SUCCESS,
  SET_ACTIVE_PATIENT_THERAPY_LIST,
  SET_PAST_PATIENT_THERAPY_LIST,
  SET_PATIENT_THERAPY_DETAIL,
  UPDATE_ACTIVE_PATIENT_THERAPY_LIST_ITEM,
  UPDATE_PAST_PATIENT_THERAPY_LIST_ITEM,
  FETCH_PATIENT_THERAPY_LIST_SUCCESS
} from './therapyTypes';

import { LOGOUT } from '../auth/authReducer';

export const FORM_SUBMISSION_ACTIVE_STATUS = 'active';
export const FORM_SUBMISSION_SUBMITTED_STATUS = 'submitted';

export const FORM_ALREADY_SUBMITTED_TEXT = 'Vaš odgovor smo že prejeli. Hvala.';
export const FORM_SUBMITTED_THANK_YOU_TEXT = 'Hvala za vaš odgovor.';
export const FUTURE_DATE_SELECTED_ERROR_MSG =
  'Izbrani dan ne sme biti v prihodnosti.';

const initialState = {
  list: [],
  loadingList: false,
  patientTherapyList: [],
  patientActiveTherapyList: [],
  patientPastTherapyList: [],
  filter: {
    sortBy: 'drug.name',
    order: ORDER_ASCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  },
  formSubmission: {
    formId: null,
    formVersion: null,
    status: null
  },
  reasonForCancelation: {
    didPoll: false,
    list: []
  },
  routeOfAdmission: {
    didPoll: false,
    list: []
  }
};

// eslint-disable-next-line default-param-last
const therapyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;
    let therapyList;

    switch (type) {
      case GET_THERAPY_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_THERAPY_LIST_SUCCESS:
        therapyList = _.getNonEmpty(payload, 'response.data', []);

        const maxResultsRaw = _.getNonEmpty(
          payload,
          'response.headers.x-total-count',
          draft.pagination.limit
        );

        const maxResults = _.parseInt(maxResultsRaw);

        draft.pagination.resultCount = maxResults;
        draft.pagination.pageCount = _.ceil(
          maxResults / draft.pagination.limit
        );
        draft.loadingList = false;
        draft.list = therapyList;
        break;
      case GET_THERAPY_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case SET_THERAPY_LIST_FILTER:
        const filterName = _.getNonEmpty(payload, 'filterName', null);
        const filterValue = _.getNonEmpty(payload, 'filterValue', '');

        if (!_.isEmpty(filterName) || !_.isEmpty(filterValue)) {
          draft.filter[filterName] = filterValue;
          draft.pagination.page = 1;
        }
        break;
      case SET_THERAPY_LIST_ORDER:
        const sortBy = _.getNonEmpty(payload, 'sortBy', null);
        const order = _.getNonEmpty(payload, 'order', ORDER_ASCENDING);

        draft.filter.sortBy = sortBy;
        draft.filter.order = order;
        break;
      case SET_THERAPY_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.getNonEmpty(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case GET_THERAPY_DETAIL_SUCCESS:
        const therapyDetail = _.getNonEmpty(payload, 'response.data', {});

        draft.detail = therapyDetail;
        break;
      case CLEAR_THERAPY_DETAIL:
        draft.detail = null;
        break;
      case FETCH_PATIENT_THERAPY_LIST_SUCCESS:
        const patientTherapyList = _.getNonEmpty(payload, 'response.data', []);

        draft.patientTherapyList = patientTherapyList;
        break;
      case SET_ACTIVE_PATIENT_THERAPY_LIST:
        const patientActiveTherapyList = _.getNonEmpty(
          payload,
          'therapyList',
          []
        );

        draft.patientActiveTherapyList = patientActiveTherapyList;
        break;
      case SET_PAST_PATIENT_THERAPY_LIST:
        const patientPastTherapyList = _.getNonEmpty(
          payload,
          'therapyList',
          []
        );

        draft.patientPastTherapyList = patientPastTherapyList;
        break;
      case SET_PATIENT_THERAPY_DETAIL:
        const patientTherapyDetail = _.getNonEmpty(
          payload,
          'therapyDetail',
          {}
        );

        draft.patientTherapyDetail = patientTherapyDetail;
        break;
      case UPDATE_ACTIVE_PATIENT_THERAPY_LIST_ITEM:
        const activeListItem = _.getNonEmpty(payload, 'item', {});
        const activeItemIndex = _.findIndex(draft.patientActiveTherapyList, {
          id: activeListItem.id
        });

        draft.patientActiveTherapyList[activeItemIndex] = activeListItem;

        break;
      case UPDATE_PAST_PATIENT_THERAPY_LIST_ITEM:
        const pastListItem = _.getNonEmpty(payload, 'item', {});
        const pastItemIndex = _.findIndex(draft.patientPastTherapyList, {
          id: pastListItem.id
        });

        draft.patientPastTherapyList[pastItemIndex] = pastListItem;

        break;
      case GET_FORM_SUBMISSION_DETAIL_SUCCESS:
        const form = _.getNonEmpty(payload, 'response.data.forms[0]', {});

        draft.formSubmission.formId = form.formID;
        draft.formSubmission.formVersion = form.formVersion;
        draft.formSubmission.status = _.getNonEmpty(
          payload,
          'response.data.status',
          'active'
        );

        break;
      case POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST_SUCCESS:
        const routeOfAdmissionList = _.getNonEmpty(payload, 'data', []);

        draft.routeOfAdmission.didPoll = true;
        draft.routeOfAdmission.list = routeOfAdmissionList;
        break;
      case POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST_SUCCESS:
        const reasonForCancelationList = _.getNonEmpty(payload, 'data', []);

        draft.reasonForCancelation.didPoll = true;
        draft.reasonForCancelation.list = reasonForCancelationList;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default therapyReducer;
