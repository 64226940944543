import { take, race, call, select } from 'redux-saga/effects';

import { getAdvanceListUrl } from '../../../utils/api/apiUrlUtils';
import { getPaymentTypeFilterParametersPaylad } from '../../../utils/billing';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import {
  START_ADVANCE_POLLING,
  STOP_ADVANCE_POLLING,
  STOP_POLLING
} from '../../core/cache/cacheTypes';
import {
  CLEAR_ADVANCE_LIST_FILTER,
  GET_ADVANCE_LIST,
  SET_ADVANCE_LIST_FILTER,
  SET_ADVANCE_LIST_FILTERS,
  SET_ADVANCE_LIST_INCLUDE_PARAM,
  SET_ADVANCE_LIST_ORDER,
  SET_ADVANCE_LIST_PAGINATION
} from './advanceTypes';

import {
  selectAdvanceFilter,
  selectAdvancePagination,
  selectAdvanceIncludeParam
} from './advanceSelectors';

function* pollAdvancesSaga() {
  function* configGetter() {
    const state = yield select();
    const { limit, page } = selectAdvancePagination(state);
    const include = selectAdvanceIncludeParam(state);
    let customFilter = selectAdvanceFilter(state);

    customFilter = getPaymentTypeFilterParametersPaylad(customFilter);

    return {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    };
  }

  yield pollGenerator(GET_ADVANCE_LIST, getAdvanceListUrl, {
    retriggerActions: [
      SET_ADVANCE_LIST_FILTER,
      SET_ADVANCE_LIST_FILTERS,
      SET_ADVANCE_LIST_INCLUDE_PARAM,
      SET_ADVANCE_LIST_ORDER,
      SET_ADVANCE_LIST_PAGINATION,
      CLEAR_ADVANCE_LIST_FILTER
    ],
    configGetter
  });
}

export function* advanceSagaWatcher() {
  while (true) {
    yield take(START_ADVANCE_POLLING);
    yield race([
      call(pollAdvancesSaga),
      take(STOP_POLLING),
      take(STOP_ADVANCE_POLLING)
    ]);
  }
}
