import { call, race, select, take } from 'redux-saga/effects';

import { subscribeToBillingWebsockets } from '../../billing/billingSagas';

import { GET_CURRENT_ORGANIZATION_SUCCESS } from '../../organization/organizationTypes';
import {
  WEBSOCKET_CLOSE,
  WEBSOCKET_CONNECT_FAILURE,
  WEBSOCKET_CONNECT_SUCCESS,
  WEBSOCKET_CONNECT_TIMEOUT,
  WEBSOCKET_DISCONNECT
} from './webSocketTypes';

import { selectDidPoll } from '../cache/cacheSelectors';

export function* webSocketPollingSagaWatcher() {
  while (true) {
    const state = yield select();
    const didPoll = selectDidPoll(state);

    if (didPoll) {
      yield take(WEBSOCKET_CONNECT_SUCCESS);
    } else {
      yield take(GET_CURRENT_ORGANIZATION_SUCCESS);
    }
    yield race([
      call(subscribeToBillingWebsockets),
      take(WEBSOCKET_DISCONNECT),
      take(WEBSOCKET_CLOSE),
      take(WEBSOCKET_CONNECT_FAILURE),
      take(WEBSOCKET_CONNECT_TIMEOUT)
    ]);
  }
}
