import React from 'react';
import AutoSuggest from 'react-autosuggest';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize, localizeWithRef } from '../../../../i18n';
import { EVENT_ENTER_KEY } from '../../../../utils/event';
import { asyncPromiseWrapper } from '../../../../utils/gen';
import { removeLocalizeHocProps } from '../../../../utils/language';
import { defaultDebounceDelay } from '../../../../utils/moshiConfig';

import DropdownItemShape from '../../../../metadata/shapes/DropdownItemShape';

import Icon from '../../Icon';
import Visible from '../../layout/Visible';
import ClearButton from '../button/ClearButton';
import MoshiAutoSuggestionsContainer from './MoshiAutoSuggestionsContainer';

export const AUTO_SUGGEST_NO_RESULTS = 'noResults';

/**
 * TODO The results in autosuggest should come from the redux flow.
 * Currently the direct response from the fetchPromise is used for displaying
 * results.
 */
export class MoshiAutoSuggestComponent extends React.Component {
  constructor(props) {
    super(props);

    const { initialList, suggestionRemap, initialText } = this.props;

    this.state = {
      suggestions: initialList.map(suggestionRemap),
      inputValue: initialText
    };

    this.onSuggestionsFetchRequested = _.debounce(
      this.onSuggestionsFetchRequested.bind(this),
      defaultDebounceDelay
    );
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
    this.getInputProps = this.getInputProps.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(
      this
    );
  }

  async onSuggestionsFetchRequested({ value: searchValue }) {
    const { t: translate, fetchPromise, suggestionRemap } = this.props;
    const noResultsItem = {
      id: AUTO_SUGGEST_NO_RESULTS,
      name: translate('common:noItemsFound')
    };
    const noResults = [noResultsItem];

    const [result, error] = await asyncPromiseWrapper(
      fetchPromise(searchValue)
    );

    if (!_.isEmpty(error)) {
      return;
    }

    const remappedResult = result.map(suggestionRemap);

    this.setState(() => ({
      suggestions: _.isEmpty(remappedResult) ? noResults : remappedResult
    }));
  }

  onSuggestionsClearRequested() {
    this.setState(() => ({
      suggestions: [],
      suggestionsOpened: false
    }));
  }

  getInputProps() {
    const {
      fetchPromise,
      onSuggestionSelected,
      initialList,
      className,
      suggestionRemap,
      renderSuggestion,
      prependIcon,
      clearButton,
      onClear,
      renderNoItemsFoundAction,
      ...props
    } = this.props;
    const { currentValue, suggestionsOpened, inputValue } = this.state;

    /*
     * when wrapping the component in 'localize' the props get some
     * additional properties which cause an error. That's why this util is needed
     */
    const newInputProps = removeLocalizeHocProps(props);

    delete newInputProps.initialText;
    delete newInputProps.displayShowAllButton;
    delete newInputProps.onShowAllClick;
    delete newInputProps.minSearchCharacterLimit;
    delete newInputProps.onSuggestionHighlighted;

    const inputProps = {
      ...newInputProps,
      value: inputValue,
      onChange: (event, { newValue }) => this.setInputValue(newValue),
      className: classNames(className, {
        'form-control-prepended-icon': !_.isEmpty(prependIcon),
        'form-control-clear-button': clearButton
      }),
      onKeyDown: (e) => {
        if (e.key === EVENT_ENTER_KEY && suggestionsOpened) {
          e.preventDefault();
        }
      },
      onKeyUp: () => {
        if (!_.isEmpty(currentValue) && currentValue.name !== inputValue) {
          onSuggestionSelected(null, { suggestion: null });
        }
      }
    };

    return inputProps;
  }

  setInputValue(value) {
    const { onClear } = this.props;

    this.setState(
      () => ({
        inputValue: value
      }),
      () => {
        if (_.isEmpty(value)) {
          onClear();
        }
      }
    );
  }

  shouldRenderSuggestions(inputValue) {
    const { minSearchCharacterLimit } = this.props;
    const { suggestionsOpened } = this.state;
    const shouldSuggestionsOpen =
      inputValue.trim().length >= minSearchCharacterLimit;

    if (suggestionsOpened !== shouldSuggestionsOpen) {
      this.setState(() => ({
        suggestionsOpened: shouldSuggestionsOpen
      }));
    }

    return shouldSuggestionsOpen;
  }

  handleSuggestionSelected(event, { suggestion }) {
    const { onSuggestionSelected } = this.props;
    const { currentValue } = this.state;
    const noResults = suggestion.id === AUTO_SUGGEST_NO_RESULTS;

    if (
      (_.isEmpty(suggestion) && !_.isEmpty(currentValue)) ||
      _.isEmpty(suggestion.id)
    ) {
      onSuggestionSelected(null, { suggestion: null });
    } else if (noResults) {
      return;
    } else if (!_.isEmpty(suggestion)) {
      onSuggestionSelected(suggestion);
    }

    this.setState(() => ({
      currentValue: suggestion
    }));
  }

  renderSuggestionsContainer({ containerProps, children }) {
    const { displayShowAllButton, onShowAllClick } = this.props;
    const { suggestions, inputValue } = this.state;

    return (
      <MoshiAutoSuggestionsContainer
        containerProps={containerProps}
        displayShowAllButton={displayShowAllButton}
        suggestions={suggestions}
        onShowAllClick={() => onShowAllClick(inputValue)}
      >
        {children}
      </MoshiAutoSuggestionsContainer>
    );
  }

  render() {
    const {
      renderSuggestion,
      prependIcon,
      clearButton,
      onClear,
      renderNoItemsFoundAction,
      onSuggestionHighlighted
    } = this.props;
    const { suggestions, inputValue } = this.state;

    return (
      <div
        className={classNames({
          'autosuggest-prepended-icon': !_.isEmpty(prependIcon)
        })}
      >
        {!_.isEmpty(prependIcon) && prependIcon}
        <Visible visible={clearButton && !_.isEmpty(inputValue)}>
          <ClearButton
            onClick={() => {
              onClear();
              this.setInputValue('');
            }}
          />
        </Visible>
        <AutoSuggest
          className={'form-control-no-style'}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={(suggestion) =>
            _.isEmpty(suggestion.id) ? '' : suggestion.name
          }
          onSuggestionSelected={this.handleSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={(suggestion) =>
            _.isFunction(renderNoItemsFoundAction) &&
            (_.isEmpty(suggestion.id) ||
              suggestion.id === AUTO_SUGGEST_NO_RESULTS)
              ? renderNoItemsFoundAction(suggestion)
              : renderSuggestion(suggestion)
          }
          inputProps={this.getInputProps()}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          onSuggestionHighlighted={onSuggestionHighlighted}
        />
      </div>
    );
  }
}

MoshiAutoSuggestComponent.propTypes = {
  t: PropTypes.func.isRequired,
  fetchPromise: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  suggestionRemap: PropTypes.func,
  renderSuggestion: PropTypes.func,
  initialList: PropTypes.arrayOf(PropTypes.shape(DropdownItemShape)),
  className: PropTypes.string,
  initialText: PropTypes.string,
  prependIcon: PropTypes.shape(),
  onClear: PropTypes.func,
  clearButton: PropTypes.bool,
  renderNoItemsFoundAction: PropTypes.func,
  displayShowAllButton: PropTypes.bool,
  onShowAllClick: PropTypes.func,
  minSearchCharacterLimit: PropTypes.number,
  onSuggestionHighlighted: PropTypes.func
};

MoshiAutoSuggestComponent.defaultProps = {
  suggestionRemap: (suggestion) => suggestion,
  renderSuggestion: (suggestion) => <span>{suggestion.name}</span>,
  initialList: [],
  className: '',
  initialText: '',
  prependIcon: <Icon name={'search'} size={'lg'} className={'margin-left-7'} />,
  clearButton: true,
  onClear: _.noop,
  onShowAllClick: _.noop,
  displayShowAllButton: false,
  renderNoItemsFoundAction: undefined,
  minSearchCharacterLimit: 2,
  onSuggestionHighlighted: _.noop
};

export const MoshiAutoSuggestWithRef = localizeWithRef(
  MoshiAutoSuggestComponent
);

export default localize(MoshiAutoSuggestComponent);
