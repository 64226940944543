export const LOCALE_BILLING_EN = 'en';
export const LOCALE_BILLING_SL = 'sl';
export const LOCALE_BILLING_DE = 'de';
export const LOCALE_BILLING_FR = 'fr';
export const LOCALE_BILLING_IT = 'it';
export const LOCALE_BILLING_CS = 'cs';

export const LOCALE_SL_SI = 'sl-SI';
export const LOCALE_EN_US = 'en-US';

export const BILLING_LOCALES = [
  LOCALE_BILLING_EN,
  LOCALE_BILLING_SL,
  LOCALE_BILLING_DE,
  LOCALE_BILLING_FR,
  LOCALE_BILLING_IT,
  LOCALE_BILLING_CS
];
