import _ from 'lodash';

export const parsePayloadJwt = (token) => {
  if (_.isEmpty(token) || !_.isString(token)) {
    return null;
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const decodedJwt = atob(base64);

  return jsonParse(decodedJwt);
};

export const isAuthenticated = (token) => {
  if (_.isEmptySafe(token)) {
    return false;
  }

  const parsedToken = parsePayloadJwt(token);

  const expirationEpoch = _.get(parsedToken, 'exp', 0);

  return isTokenValid(expirationEpoch);
};

export const isTokenValid = (expirationEpoch) => {
  if (_.isEmptySafe(expirationEpoch)) {
    return false;
  }

  return expirationEpoch > _.epoch();
};

export const jsonParse = (rawValue, fallbackValue = null) => {
  let value;

  try {
    value = JSON.parse(rawValue);
  } catch (e) {
    value = rawValue;
  }

  return _.isEmptySafe(value) ? fallbackValue : value;
};

export default {
  parsePayloadJwt,
  isAuthenticated
};
