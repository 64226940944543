import { createActionTypeArray } from '../../common/actionTypes';

export const ADD_CATEGORY = 'category/ADD_CATEGORY';
export const DELETE_CATEGORY = 'category/DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'category/UPDATE_CATEGORY';
export const GET_CATEGORIES = 'category/GET_CATEGORIES';
export const GET_CATEGORY = 'category/GET_CATEGORY';

export const [
  GET_CATEGORIES_IN_PROGRESS,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE
] = createActionTypeArray(GET_CATEGORIES);

export const [
  ADD_CATEGORY_IN_PROGRESS,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE
] = createActionTypeArray(ADD_CATEGORY);

export const [
  UPDATE_CATEGORY_IN_PROGRESS,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE
] = createActionTypeArray(UPDATE_CATEGORY);

export const [
  DELETE_CATEGORY_IN_PROGRESS,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE
] = createActionTypeArray(DELETE_CATEGORY);

export const [
  GET_CATEGORY_IN_PROGRESS,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE
] = createActionTypeArray(GET_CATEGORY);
