import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

const TableCellWrapper = ({ children }) => (
  <span className={'cell-wrapper no-break'}>
    {_.isEmpty(children) && '/'}
    {children}
  </span>
);

TableCellWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default TableCellWrapper;
