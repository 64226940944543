import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import { showMissingOrganizationIdError } from '../../utils/alert';
import { UPDATE_APP_CONFIG } from '../core/app-config/appConfigReducer';

import {
  GET_FLOWS,
  GET_FLOW,
  UPDATE_FLOW,
  ADD_FLOW,
  DELETE_FLOW
} from './flowReducer';

export const getFlows = (actionType = GET_FLOWS) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/flows`)
  });
};

export const getFlow = (flowId, actionType = GET_FLOW) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/flows/${flowId}`)
  });
};

export const deleteFlow = (flowId) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: DELETE_FLOW,
    apiCall: axios.delete(`/organizations/${organizationId}/flows/${flowId}`)
  });
};

export const updateFlow = ({ id, ...flowData }) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: UPDATE_FLOW,
    apiCall: axios.put(`/organizations/${organizationId}/flows/${id}`, flowData)
  });
};

export const addFlow = (flowData) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: ADD_FLOW,
    apiCall: axios.post(`/organizations/${organizationId}/flows/`, flowData)
  });
};

export const toggleEnabledFlow = (flowData) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const appConfigFlows = _.get(storeGetState(), 'appConfig.detail.flows', []);

  if (flowData.enabled && !_.includes(appConfigFlows, flowData.id)) {
    appConfigFlows.push(flowData.id);
  } else if (!flowData.enabled && _.includes(appConfigFlows, flowData.id)) {
    _.remove(appConfigFlows, (flowId) => flowId === flowData.id);
  } else {
    return;
  }

  return dispatch({
    type: UPDATE_APP_CONFIG,
    apiCall: axios.put(`/organizations/${organizationId}/appConfig`, {
      flows: _.uniq(appConfigFlows)
    })
  });
};
