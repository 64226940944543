import {
  NOTIFICATION_TYPE_APPOINTMENT_ADDED,
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_TYPE_APPOINTMENT_UPDATED,
  NOTIFICATION_TYPE_REMINDER,
  NOTIFICATION_TYPE_CUSTOM,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_FAILED,
  NOTIFICATION_STATUS_COMPLETED,
  NOTIFICATION_STATUS_DELETED,
  NOTIFICATION_TYPE_NO_SHOW,
  NOTIFICATION_TYPE_CANCEL,
  NOTIFICATION_TYPE_FEEDBACK
} from '../../utils/data/notifications';

import { formatting } from './date';

export default {
  appointment: 'Termin',
  calendar: 'Koledar',
  create: 'Dodajte',
  newAppointment: 'Nov termin',
  cancelAppointment: 'Prekličite termin',
  newEvent: 'Nov termin',
  newWaitingListEvent: 'Termin za čakalno vrsto',
  category: 'Kategorija',
  note: 'Opis',
  patientNote: 'Pacientov zaznamek',
  categories: 'Kategorije',
  // TODO: @Gorazd use common: title pls
  title: 'Naslov',
  event: 'Dogodek',
  eventType: 'Tip dogodka',
  patientAppointment: 'Termin pacienta',
  appointmentHistory: 'Zgodovina termina',
  appointmentCreatedByUser: ` {{ user }}, {{ date, ${formatting.longDateWithTime} }}`,
  appointmentCreatedBy: `Ustvaril/a $t(calendar:appointmentCreatedByUser)`,
  appointmentEditedBy: `$t(common:editedBy), {{ date, ${formatting.longDateWithTime} }}`,
  appointmentCanceledByUser: ` {{ user }}, {{ date, ${formatting.shortDateWithTime} }}`,
  appointmentCanceledByPatient: ` na zahtevo pacienta, {{ date, ${formatting.shortDateWithTime} }} `,
  confirmRemoveEvent: 'Ste prepričani da želite odstraniti termin?',
  confirmCancelEvent: 'Ste prepričani da želite preklicati termin?',
  removeEvent: 'Odstranite termin',
  findTime: 'Poiščite termin',
  other: 'Drugo',
  queue: 'Čakalna vrsta',
  today: 'Danes',
  jumpTo: 'Pojdite na',
  jumpFor: 'Skočite za',
  jumpToShortcuts: {
    week1: '1 teden',
    week2: '2 tedna',
    week3: '3 tedne',
    week4: '4 tedne',
    pluralWeek: '{{numberOfWeeks}} tednov',
    month1: '1 mesec',
    month2: '2 meseca',
    month3: '3 mesece',
    month4: '4 mesece',
    pluralMonths: '{{numberOfMonths}} mesecev',
    year1: '1 leto'
  },
  month1: '1 mesec kasneje',
  month3: '3 mesece kasneje',
  month6: '6 mesecev kasneje',
  year1: '1 leto kasneje',
  editEvent: 'Uredite termin',
  viewEvent: 'Podrobnosti termina',
  status: {
    status: 'Status',
    setStatus: 'Nastavite status',
    statusUpdated: 'Status je posodobljen',
    doctorUpdated: 'Zdravnik je posodobljen',
    reservation: 'Rezerviran',
    confirmed: 'Potrjen',
    reserved: 'Rezerviran',
    arrived: 'Prišel',
    noShow: 'Ni prišel',
    canceled: 'Preklican',
    scheduled: 'Naročen',
    deleted: 'Zbrisano',
    statusTooltip: `
    <0>
      Status termina vpliva na to, kako se pošiljajo opomniki za termine in
      so pomembni za vodenje statistike o pacientovih obiskih.
    </0>
    <1>
      <0>Rezerviran - Termin je rezerviran, vendar še ni potrjen. Opomnik za tak termin NE BO poslan.</0>
      <1>Naročen - Termin je načrtovan in čakamo na odziv pacienta. Opomnik za termin bo poslan, z 
          ali brez zahtevka za potrditev, v skladu s sistemskimi nastavitvami. Ko je zahtevek za potrditev poslan, 
          čakamo potrditev ali odpoved termina s strani pacienta.</1>
      <2>Potrjen - Termin je potrjen in opomnik bo poslan v skladu s sistemskimi nastavitvami.</2>
      <3>Prišel - Pacient je prispel na dogovorjen termin.</3>
      <4>Ni prišel - Pacient ni prišel na dogovorjen termin. Ta status se ne more izbrati za prihajajoče termine.</4>
      <5>Preklican - Pacient je preklical termin in bo prikazan na koledarju glede na sistemske nastavitve. Obvestilo o 
          preklicu bo prav tako poslano v skladu s sistemskimi nastavitvami.</5>
    </1>
  `
  },
  noPatientSelected:
    'Ni izbranega pacienta. Izberite pacienta ali spremenite tip dogodka.',
  settings: {
    addNewCategory: 'Dodajte kategorijo',
    editCategory: 'Uredite kategorijo',
    categoryName: 'Ime kategorije',
    viewSettings: {
      title: 'Nastavitve izgleda',
      displayDays: 'Prikazani dnevi',
      displayHours: 'Prikazane ure',
      displayHoursDescription:
        'Izberite od katere ure do katere ure želite imeti prikazane na koledarju.',
      displayDaysDescription:
        'Izberite katere dneve želite prikazati na koledarju. Samo izbrani dnevi bodo vidni na koledarju.',
      startOfWeek: 'Začetek tedna',
      startOfWeekDescription:
        'Izbran dan bo prikazan kot prvi dan v tednu na koledarju',
      defaultZoom: 'Privzeta povečava',
      defaultZoomDescription:
        'Povečava bo prikazana ob odprtju koledarja. Povečavo lahko vedno spremenite tik nad koledarjem.',
      defaultViewType: 'Privzeti pogled',
      defaultViewTypeDescription:
        'Pogled bo prikazan ob odprtju koledarja. Pogled lahko vedno spremenite tik nad koledarjem.',
      timelineView: 'Časovnica',
      weekView: 'Tedenski pogled',
      onHover: 'Ob prehodu z miško čez dogodek',
      onClick: 'Ob kliku na dogodek',
      viewEvents: 'Prikaz dogodkov',
      viewPastEventsDescription:
        'Prikaz preteklih dogodkov z zmanjšano svetlostjo',
      viewCanceledEvents: 'Prikaz preklicanih dogodkov',
      viewCanceledEventsDescription:
        'Preklicani dogodki bodo vidni na koledarju',
      eventTypeColoring: {
        title: 'Slog prikazanih dogodkov',
        description: 'Določite slog za termin pacienta, dogodek in opomnik.',
        outlined: 'Obroba',
        filled: 'Pobarvan'
      }
    }
  },
  eventSaved: 'Čas termina posodobljen',
  notifications: {
    description: `To so sporočila, ki bodo samodejno poslana pacientom, 
    ki so dovolili takšen način obveščanja. Na tem mestu lahko za oba tipa 
    sporočil vklapljate ali izklapljate na nivoju celotne organizacije. Prav 
    tako nastavljate vsebino sporočil vendar samo za SMS, vsebina elektronske
     pošte je prednastavljena in je ni možno urejati.`,
    from: 'od',
    message: 'sporočilo',
    newAppointment: {
      header: 'Nov termin pacienta',
      description: 'To besedilo bo poslano, ko ustvarite nov termin pacienta.'
    },
    cancelAppointment: {
      header: 'Preklican termin',
      description:
        'To besedilo bo poslano pacientu, ko prekličete obstoječ termin pacienta.'
    },
    noShowAppointment: {
      header: 'Ni prišel',
      description:
        'To besedilo bo poslano pacientu, ko boste obstoječ termin pacienta označili s statusom “ni prišel”.'
    },
    reminderAppointment: {
      header: 'Opomnik za prihajajoči termin',
      description: 'To besedilo bo poslano pacientu pred obstoječim terminom.',
      remindPatients: 'Opomni paciente',
      remindPatient: 'Opomni pacienta'
    },
    reminders: 'Opomniki',
    at: 'ob',
    deliveryTypes: {
      [NOTIFICATION_DELIVERY_TYPE_SMS]: 'SMS',
      [NOTIFICATION_DELIVERY_TYPE_EMAIL]: 'Email'
    },
    types: {
      [NOTIFICATION_TYPE_APPOINTMENT_ADDED]:
        '$t(calendar:notifications.newAppointment.header)',
      [NOTIFICATION_TYPE_APPOINTMENT_UPDATED]: 'Pregled posodobljen',
      [NOTIFICATION_TYPE_REMINDER]:
        '$t(calendar:notifications.reminderAppointment.header)',
      [NOTIFICATION_TYPE_CUSTOM]: 'Sporočilo po meri',
      [NOTIFICATION_TYPE_NO_SHOW]: 'Pacienta ni bilo',
      [NOTIFICATION_TYPE_CANCEL]: 'Termin preklican',
      rsvpResponse: 'Potrdilo o terminu',
      [NOTIFICATION_TYPE_FEEDBACK]: 'Sporočilo po storitvi'
    },
    status: {
      [NOTIFICATION_STATUS_PENDING]: 'Bo poslano',
      [NOTIFICATION_STATUS_COMPLETED]: 'Poslano',
      [NOTIFICATION_STATUS_FAILED]: 'Ni uspelo',
      [NOTIFICATION_STATUS_DELETED]: 'Odstranjeno'
    },
    noReminderConsent: 'Opomniki so za izbranega pacienta izključeni.',
    choosePatient: 'Najprej izberite pacienta.',
    legend: `
    <0>Legenda</0>
    <1>
    Pametne kode lahko vnesemo v besedilo in bodo
    samodejno zamenjane z ustreznimi podatki:
    </1>
    <1>[patient full name] - ime pacienta</1>
    <1>[encounter date] - datum pregleda</1>
    <1>[encounter time] - čas pregleda</1>
    <1>[location name] - lokacija pregleda</1>
    <1>[location address] - naslov lokacije</1>
    <1>[location zip] - poštna številka lokacije</1>
    <1>[location city] - kraj lokacije</1>
    <1>[location country] - država lokacije</1>
    <1>[location phone number] - telefonska številka lokacije</1>
    <1>[location email] - email lokacije</1>
    <1>[doctor full name] - ime in priimek zdravnika</1>
    <1>[doctor last name] - priimek zdravnika</1>
    <1>[doctor first name] - ime zdravnika</1>
    <1>[doctor email] - elektronski naslov zdravnika</1>
    <1>[doctor phone number] - telefonska številka zdravnika</1>
    <1>[confirmation code] - koda za potrjevanje termina pacienta</1>
    <1>[organization phone number] - telefonska številka organizacije</1>
    <1>[organization email] - elektronski naslov organizacije</1>
    <1>[new booking url] - povezava do spletnega naročanja</1>
    <1>[edit booking url] - povezava do obstojočega termina preko spletnega naročanja</1>
    <1>[if online booked] - vsebina, ki bo prikazana v primeru, da je bil termin ustvarjen preko spretnega naročanja</1>
    <1>[if user created] - vsebina, ki bo prikazana v primeru, da je osebje ustvarilo dogodek</1>
    <1>[end] - konec if bloka</1>
    `,
    disabledOrgNotifications:
      '<0>Vaša organizacija ima izključeno obveščanje. Obveščanje lahko vključite</0> <1>tukaj</1>',
    hasDisabledNotifications:
      '<0>Pacient ima izključena obvestila. Vključite jih lahko na strani pacientovih osebnih podatkov</0> <1>tukaj</1>',
    reminderRemoved: 'Opomnik je bil uspešno odstranjen',
    feedback: {
      title: 'Po storitvi',
      description:
        'To besedilo bo poslano pacientu, ko boste obstoječ termin pacienta označili s statusom “Prišel”.',
      placeholder: 'Sporočilo',
      reminder: 'Opomnik: ',
      periods: {
        afterOneHour: 'Po 1 uri',
        afterTwoHours: 'Po 2 urah',
        afterThreeHours: 'Po 3 urah',
        afterFourHours: 'Po 4 urah',
        afterFiveHours: 'Po 5 urah',
        afterSixHours: 'Po 6 urah',
        afterTwelveHours: 'Po 12 urah',
        afterOneDay: 'Po 1 dnevu'
      }
    }
  },
  slotDuration: 'Čas koledarja',
  week: 'Teden',
  threeDay: '3 dni',
  day: 'Dan',
  month: 'Mesec',
  hour: 'Ura',
  zoom: {
    title: 'Povečava',
    large: 'Velika',
    medium: 'Srednja',
    small: 'Majhna',
    extraSmall: 'Zelo majhna'
  },
  referenceId: 'Referenčna koda',
  view: 'Pogled',
  appointments: 'Termini',
  appointmentRemoved: 'Termin odstranjen.',
  selectATimeToBook: 'Izberite čas termina.',
  reschedule: 'Prestavite termin',
  setRescheduleEvent: 'Prestavite termin',
  duplicate: 'Podvojite',
  duplicateEvent: 'Podvojite termin',
  doctors: 'Zdravniki',
  moveToWaitingList: 'Prestavite v "čakalno vrsto"',
  eventMovedToWaitingList: 'Termin prestavljen v "Čakalno vrsto"',
  confirmRemoveReminder: 'Ste prepričani da želite odstraniti opomnik?',
  removeReminder: 'Odstranite opomnik',
  addReminder: 'Dodaj opomnik',
  reminder: 'Opomnik',
  reminderFor: 'Opomnik za ',
  viewInCalendar: 'Odprite v koledarju',
  priority: {
    title: 'Prioriteta',
    3: 'Nizka',
    2: 'Srednja',
    1: 'Visoka'
  },
  addToCalendar: 'Dodajte v koledar',
  addStaff: 'Dodajte zdravnika',
  sendReminder: 'Pošljite SMS opomnik',
  sendReminderTo: 'Pošljite opomnik za: ',
  alert: {
    smsReminderSent: 'Sms je bil uspešno poslan',
    reminderIsInPast:
      'Opomnik, ki ste ga dodali, ne more biti poslan, ker je njegov čas v preteklosti.',
    calendarSettingsSaved: 'Nastavitve koledarja so bile uspešno shranjene.',
    eventShortcutAction:
      'Izberite čas termina: {{title}} • čas: {{ readableDuration }} • pri: {{ assigneeName }} • kategorija: {{ categoryName }} • opis: {{ note }}'
  },
  before: 'prej ',
  at: 'ob',
  upcoming: 'Prihajajoči',
  past: 'Pretekli',
  advancedFilters: 'Podrobno iskanje',
  hideFilters: 'Skrijte filtre',
  showFilters: 'Prikažite filtre',
  resources: {
    title: 'Resursi',
    resource: 'Resurs',
    addNewResource: 'Nov resurs',
    editResource: 'Uredite resurs',
    resourceName: 'Ime resursa',
    resourceDescription: 'Opis resursa',
    alerts: {
      uniqueNameError: 'Resurs z istim imenom že obstaja.',
      resourceAdded: 'Resurs je bil uspešno dodan.',
      resourceRemoved: 'Resurs je bil odstranjen.',
      resourceEdited: 'Resurs je bil uspešno urejen.'
    },
    resourceInfo:
      'Za pametno načrtovanje časa, si lahko tukaj ustvarite dodatne koledarje za resurse,' +
      'kot na primer koledar za določeno sobo ali napravo. S tem boste vedno na ' +
      'tekočem z zasedenostjo.',
    chooseResource: 'Izberite resurs'
  },
  multipleResourcesDropdownGroups: {
    user: 'Osebje',
    resource: 'Ostalo',
    chooseResource: 'Izberite osebo ali resurs'
  },
  staffAndResources: 'Osebje in resursi',
  eventPopover: {
    headerTitleType: {
      encounter: 'Termin',
      other: 'Dogodek',
      reminder: 'Opomnik'
    }
  },
  removeEventModal: {
    title: 'Razlog za preklic termina',
    noReasonOption: 'Brez razloga',
    canceledOption: 'Preklicano',
    notifyPatient: 'Obvestite pacienta o preklicu',
    note: 'Opomba',
    notePlaceholder: 'Vnesite razlog za preklic',
    reasonTooltip: `<0>
    <1>Preklicani termini s strani pacientov bodo prikazani na koledarju v skladu z nastavitvami koledarja. 
    Ob izbrani nastavitvi bo stanje preklicanega termina prikazan kot "Preklicano".
    </1> 
    <1>Termini, ki bodo preklicani s strani uporabnika (drugo), bodo trajno odstranjeni.</1>
    </0>`
  },
  rescheduleEventModal: {
    selectReason: 'Izberite razlog za prestavitev termina',
    patientRequested: 'Zahteva pacienta',
    userRequested: 'Zahteva uporabnika',
    other: 'Drugo',
    notifyPatient: 'Ali želite obvestiti pacienta o spremembi?'
  },
  auditLog: {
    eventUpdatedByPatient: 'Pacient spremenil termin, ',
    eventUpdatedBy: 'Termin je spremenil {{userName}}',
    rescheduled: 'Prestavljen',
    reschedulingNote: 'Opomba pri prestavljanju: ',
    appointmentRescheduledByPatient: 'Pacient prestavil ',
    appointmentRescheduledByUser: 'Uporabnik prestavil ',
    appointmentUpdatedByUser: 'Uporabnik spremenil ',
    changeDate: `{{changeDate, ${formatting.shortDateWithTime}}}, `,
    changedFromTime: ` spremenil začetni čas iz
    {{originalDate,  ${formatting.time}}} na {{newDate,  ${formatting.time}}}`,
    changedFromTimeAndDate: ` spremenil začetek iz
    {{originalDate,  ${formatting.shortDateWithTime}}} na {{newDate,  ${formatting.shortDateWithTime}}}`,
    changedToTime: ` spremenil končni čas iz
    {{originalDate,  ${formatting.time}}} na {{newDate,  ${formatting.time}}}`,
    changedToTimeAndDate: ` spremenil konec iz
    {{originalDate,  ${formatting.shortDateWithTime}}} na {{newDate,  ${formatting.shortDateWithTime}}}`,
    addedDoctors: ' dodal {{doctors}} na termin',
    removedDoctors: ' odstranil {{doctors}} iz termina',
    updatedCategory:
      ' spremenil kategorijo iz {{removedCategory}} v {{addedCategory}}',
    changedStatus: ' spremenil status iz {{removedStatus}} v {{addedStatus}}',
    changedEventType:
      ' spremenil tip termina iz {{removedType}} v {{addedType}}',
    changedNote: ' spremenil opis',
    changedPatient: ' zamenjal pacienta',
    eventConfirmedViaRsvp: `<0>Pacient potrdil</0> <1>preko SMSa, {{date,  ${formatting.shortDateWithTime}}}</1>`
  },
  noShowModal: {
    title: 'Pacient ni prišel',
    notifyAboutNoShow: 'Obvestite pacienta, da ni prišel',
    notifyDescription:
      'Pošljite sporočilo pacientu, da ni prišel na dogovorjen termin.',
    setAsNoShow: 'Označite kot Ni prišel'
  },
  eventTypes: {
    queue: 'čakalna vrsta',
    encounter: 'termin pacienta',
    reminder: 'opomnik',
    other: 'dogodek'
  }
};
