import { formatting } from './date';

/* eslint sort-keys: "error" */
export default {
  activate: 'Aktivirajte',
  active: 'Aktiven',
  add: 'Dodajte',
  addData: 'Dodajte podatek',
  addedByPrefix: 'Dodal/-a',
  address: {
    country: 'Država',
    inlineAddress:
      '{{ address.street1 }}, {{ address.zip }} {{ address.town }}, {{ address.country }}',
    street: 'Naslov',
    town: 'Kraj',
    zip: 'Poštna številka',
    zipShort: 'Poštna št.'
  },
  all: 'Vsi',
  allTime: 'Celoten čas',
  and: 'in',
  apply: 'Uporabite',
  archive: 'Arhivirajte',
  avatar: 'avatar',
  back: 'Nazaj',
  backToApp: 'Nazaj v {{ appName }}',
  cancel: 'Prekličite',
  cancelAll: 'Prekličite vse',
  category: 'Kategorija',
  change: 'Zamenjajte',
  clear: 'Počistite',
  clearAll: 'Počistite vse',
  close: 'Zaprite',
  colonSeparated: `{{ left }}: {{ right }}`,
  color: 'Barva',
  complete: '$t(common:finish)',
  confirm: 'Potrdite',
  content: 'Vsebina',
  copy: 'Kopirajte',
  copyTo: 'Kopirajte v',
  created: 'Ustvarjen',
  createdAt: 'Ustvarjeno',
  createdBy: 'Ustvaril',
  createdByPatient: 'Ustvaril pacient',
  creator: 'Ustvaril',
  custom: 'Po meri',
  deactivate: 'Deaktivirajte',
  default: 'Privzeti',
  delete: 'Izbrišite',
  deleted: 'Izbrisano',
  description: 'Opis',
  deselectAll: 'Počistite vse',
  disable: 'Onemogočite',
  disabled: 'Onemogočeno',
  displayName: `{{ user.firstName }} {{ user.lastName }}`,
  displayNameReverse: `{{ user.lastName }} {{ user.firstName }}`,
  done: 'Opravljeno',
  download: 'Prenesite',
  downloadPdf: 'Prenesite PDF',
  dropdownPlaceholder: 'Izberite...',
  dropdownUnselect: '[Brez]',
  duplicate: 'Podvojite',
  duration: 'Trajanje',
  edit: 'Uredite',
  editAll: 'Uredite vse',
  edited: 'Urejeno',
  editedBy: 'Uredil/a {{ user }}',
  ellipsis: '...',
  email: 'Elektronski naslov',
  empty: 'Prazno',
  enable: 'Omogočite',
  enabled: 'Omogočeno',
  end: 'Konec',
  export: 'Izvozite',
  finish: 'Zaključite',
  frequency: 'Pogostost',
  generated: 'Generirano',
  group: 'Skupina',
  here: 'tukaj',
  hide: 'Skrijte',
  hideHistory: 'Skrijte zgodovino',
  home: 'Domov',
  inactive: {
    list: 'Neaktivni',
    single: 'Neaktiven'
  },
  include: 'Vključite',
  languages: {
    cs: 'Češki',
    de: 'Nemški',
    en: 'Angleški',
    'en-US': 'Angleški (Združene Države)',
    fr: 'Francoski',
    it: 'Italijanski',
    sl: 'Slovenski',
    'sl-SI': 'Slovenski (Slovenija)'
  },
  lastChange: 'Zadnja sprememba',
  lastChanged: 'Zadnjič spremenjeno',
  lastUpdateAt: 'Zadnja sprememba',
  loading: 'Nalaganje...',
  location: 'Lokacija',
  locationTime: `{{ location }} ∙ {{ time, ${formatting.longDateWithTime} }}`,
  locations: 'Lokacije',
  login: 'Vpišite se',
  logout: 'Odjava',
  mandatory: 'Obvezno',
  mandatoryFields: 'obvezna polja',
  merge: 'Združite',
  message: 'Sporočilo',
  missingObject: {
    base: '{{ objectName }} ni bilo mogoče najti',
    noAuthor: 'Avtorja $t(common:missingObject.base)',
    noDoctor: 'Zdravnika $t(common:missingObject.base)',
    noFlow: 'Toka $t(common:missingObject.base)',
    noImage: 'Slika ni nastavljena',
    noLocation: 'Lokacije $t(common:missingObject.base)',
    noPatient: 'Pacienta $t(common:missingObject.base)',
    noResource: `Resursa  $t(common:missingObject.base)`
  },
  more: 'Več',
  moreOptions: 'Več možnosti',
  name: 'Ime',
  never: 'Nikoli',
  newestFirst: 'Novejši naprej',
  next: 'Naprej',
  no: 'Ne',
  noItemsFound: 'Ni zadetkov...',
  note: 'Opomba',
  nothingHere: 'Tukaj ni ničesar',
  number: {
    separator: '\\,'
  },
  numericValueWithSuffix: '{{value, number}} {{suffix}}',
  oldestFirst: 'Starejši naprej',
  open: 'Odprite',
  openPdf: 'Odprite PDF',
  origin: 'Izvor',
  other: 'Drugo',
  password: 'Geslo',
  period: 'Obdobje',
  person: {
    address: 'Naslov',
    contact: 'Kontakt',
    dateOfBirth: 'Datum rojstva',
    email: 'Elektronski naslov',
    firstName: 'Ime',
    gender: 'Spol',
    genderPlaceholder: 'Izberite spol',
    genderTypes: {
      female: 'Ženski',
      male: 'Moški'
    },
    identification: 'Identifikacija',
    lastName: 'Priimek',
    notifications: {
      email: 'Elektronski naslov',
      mobile: 'Iryo Moshi mobilna aplikacija',
      sms: 'SMS',
      title: 'Nastavitve obveščanja'
    },
    phoneNumber: 'Telefonska številka'
  },
  photo: {
    add: 'Dodajte sliko'
  },
  pinToTheTop: 'Pripnite na vrh',
  post: 'Objavite',
  preview: 'Predogled',
  price: 'Cena',
  print: 'Natisnite',
  quantity: 'Količina',
  refresh: 'Osveži',
  register: 'Ustvarite račun',
  remove: 'Odstranite',
  rename: 'Preimenujte',
  reopen: 'Ponovno odprite',
  reset: 'Ponastavite',
  resource: 'Resurs',
  resources: 'Resursi',
  resultsFor: ' rezultatov za {{query}}',
  roles: {
    admin: 'Administrator',
    assistant: 'Asistent',
    doctor: '$t(patient:doctor.title)'
  },
  save: 'Shranite',
  saveAll: 'Shranite vse',
  search: 'Iščite',
  seeAll: 'Prikažite vse',
  selectAll: 'Izberite vse',
  selectLocation: 'Izberite lokacijo',
  send: 'Pošljite',
  sendViaEmail: 'Pošljite po emailu',
  showAll: 'Prikažite vse',
  showEncounter: 'Prikažite obisk',
  showHistory: 'Pokažite zgodovino',
  showLess: 'Prikažite manj',
  showMore: 'Prikažite več',
  sms: 'SMS',
  sort: {
    aToZ: 'A-Ž',
    zToA: 'Ž-A'
  },
  sortAtoZ: 'A-Ž',
  sortZtoA: 'Ž-A',
  staff: 'Osebje',
  start: 'Pričnite',
  status: 'Status',
  template: 'Predloga',
  termsAndConditions: 'Pogoji uporabe',
  time: 'Čas',
  title: 'Naslov',
  type: 'Vrsta',
  underConstruction: 'V izdelavi...',
  unknown: 'Neznano',
  unpinFromTheTop: 'Odpnite z vrha',
  upNext: 'Naslednji',
  updatedAt: 'Spremenjeno',
  upload: 'Naložite',
  url: 'URL',
  useNonExistingEntry: 'Uporabite neobstoječ vnos "{{ name }}"',
  valueWithSuffix: '{{value}} {{suffix}}',
  version: 'v{{ version }}',
  versionLabel: 'Verzija',
  view: 'Pogled',
  warning: {
    confirmMissingClientDocumentCreationMessage:
      'Izdajate {{ billType, lowercase }} brez dodanih podatkov o plačniku. Ali želite nadaljevati?',
    confirmTodaysLogClosedMessage:
      'Današnji blagajniški dnevnik je bil že zaključen na izbrani napravi. V kolikor izdate nov dokument, ga bo potrebno zapreti ponovno. Ali želite nadaljevati?',
    confirmZeroTotalBillCreationMessage:
      'Izdajate {{ billType, lowercase }} s skupno vsoto 0 {{ currency }}. Ali želite nadaljevati?',
    warningTitle: 'Opozorilo'
  },
  with: 'pri',
  yes: 'Da'
};
