export default {
  login: {
    greetingTitle: 'Vstopite v {{brandName}}.',
    greetingText: 'Vpišite vaš elektronski naslov in geslo.',
    forgotPassword: 'Pozabljeno geslo?',
    register: 'Ustvarite račun',
    backToLogin: 'Nazaj na vpis',
    dontHaveAccount: 'Še nimate računa?',
    problemLoggingIn: 'Ste pozabili geslo?',
    loggingOut: 'Odjavljanje',
    loggingOutDescription:
      'Trenutno že imate aktivno sejo z drugim uporabniškim imenom. Za nadaljevanje vas bomo najprej izpisali iz obstoječe seje.',
    loggingOutCountdown: 'Do odjave še:'
  },
  resetPassword: {
    greetingTitle: 'Ponastavite geslo',
    greetingText: 'Za ponastavitev gesla vpišite vaš elektronski naslov.',
    successGreetingTitle: 'Prošnja za ponastavitev gesla je bila poslana.',
    successGreetingText:
      'Če vpisan elektronski naslov obstaja v naši bazi, boste v kratkem nanj prejeli navodila za ponastavitev gesla.',
    resetPasswordSuccessTitle: 'Vaše geslo je bilo uspešno ponastavljeno',
    retypePassword: 'Ponovno vpišite geslo'
  },
  register: {
    resendEmail: 'Ponovno pošljite elektronsko sporočilo.',
    greetingTitle: 'Ustvarite uporabniški račun.',
    greetingText:
      'Vpišite vaše polno ime, ime podjetja, elektronski naslov in si ustvarite geslo.',
    successGreetingTitle: 'Vaš uporabniški račun je bil ustvarjen.',
    successGreetingText:
      'V kratkem boste na vaš elektronski naslov prejeli navodila za aktivacijo uporabniškega računa.',
    verifyingEmail: 'Preverjanje elektronskega naslova...',
    verifyEmailSuccess: 'Vaš elektronski naslov je uspešno aktiviran.',
    emailAlreadyVerified: 'Vaš elektronski naslov je že aktiviran.',
    emailVerificationFailed:
      'Med preverjanjem vašega elektronskega naslova je prišlo do napake.',
    alreadyHaveAccount: 'Že imate uporabniški račun?',
    alert: {
      registrationSuccess:
        'Vaš uporabniški račun je bil uspešno ustvarjen, preverite elektronski naslov',
      verifyEmailSuccess: 'Vaš elektronski naslov je uspešno aktiviran'
    }
  },
  changeEmail: {
    title: 'Spremenite email',
    inProgress: 'Spreminjamo elektronski naslov...',
    success: 'Vaš elektronsi naslov je bil uspešno spremenjen.',
    failure:
      'Vaša zahteva za spremembo elektronskega naslova je potekla ali pa je povezava že uporabljena.'
  },
  acceptInvite: {
    description: 'Vnesite geslo, ki ga boste uporabili za prijavo.',
    descriptionSuccess: 'Geslo je bilo uspešno nastavljeno.',
    descriptionFailure:
      'Vaša zahteva za pridružitev je potekla ali pa je povezava že uporabljena.'
  }
};
