import _ from 'lodash';

import { jsonParse } from '../jwt';

export const isValidDocumentObject = (obj) => !_.isEmpty(obj.id);

export const mapFormDetail = (rawDocument) => {
  const document = _.cloneDeep(rawDocument);

  if (_.isEmpty(document)) {
    return document;
  }

  const documentRawFormBuilderSchema = _.get(
    document,
    'formBuilderSchema',
    JSON.stringify({})
  );
  const documentRawJsonSchema = _.get(
    document,
    'jsonSchema',
    JSON.stringify({})
  );

  if (_.isString(documentRawFormBuilderSchema)) {
    document.formBuilderSchema = jsonParse(documentRawFormBuilderSchema);
  }
  if (_.isString(documentRawJsonSchema)) {
    document.jsonSchema = jsonParse(documentRawJsonSchema);
  }

  if (
    !_.has(document, 'formBuilderSchema.fieldCounter') &&
    _.has(document, 'formBuilderSchema.fields') &&
    _.isArray(document.formBuilderSchema.fields)
  ) {
    document.formBuilderSchema.fieldCounter =
      document.formBuilderSchema.fields.length;
  }

  return document;
};

export const getDocumentFlowMapper = (flows) => (document) => {
  if (_.isEmpty(document)) {
    return document;
  }

  const usedInFlows = _.get(document, 'usedInFlows', []);

  const flowNames = usedInFlows.map((flowId) => {
    const flow = _.find(flows, { id: flowId });

    if (_.isEmpty(flow)) {
      return '/';
    } else {
      return flow.name;
    }
  });

  document.usedInFlowsNames = flowNames;

  return document;
};

export const getPatientDocumentPayload = (documentData, isDocument = true) => {
  const { version, id, data } = documentData;
  const formData = JSON.stringify(data.formData);

  if (isDocument) {
    return { data: formData, documentID: id, documentVersion: version };
  }

  return {
    data: formData,
    questionnaireID: id,
    questionnaireVersion: version
  };
};
