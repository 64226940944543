export const REGISTRY_BILLING_ITEMS = 'billingItems';
export const REGISTRY_CALENDAR = 'calendar';
export const REGISTRY_PAYMENT_TYPES = 'paymentTypes';
export const REGISTRY_THERAPY_DRUGS = 'therapyDrugs';
export const REGISTRY_THERAPY_TEMPLATES = 'therapyTemplates';
export const REGISTRY_THERAPY_ROUTE_OF_ADMISSION = 'therapyRouteOfAdmission';
export const REGISTRY_THERAPY_CANCELATION_REASONS = 'therapyCancelationReasons';
export const REGISTRY_ANAMNESIS_MEDICAL_CONDITIONS = 'medicalConditions';
export const REGISTRY_ANAMNESIS_CONCOMITANT_DRUGS = 'concomitantDrugs';
export const REGISTRY_ANAMNESIS_ALLERGIES = 'allergies';
export const REGISTRY_ANAMNESIS_VACCINATIONS = 'vaccinations';
export const REGISTRY_ANAMNESIS_OPERATIONS = 'operations';
export const REGISTRY_ANAMNESIS_IMPLANTS = 'implants';
