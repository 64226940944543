import { useTranslation } from 'react-i18next';

/**
 * Prepares props for dropdown menu
 * @param {object} params contains list of overridable params
 * | @param {array} items list of items
 * | @param {boolean} enableDeselection whether deselection is enabled or not
 * | @param {string} className overridable classname
 *
 * @returns {object}
 * | @returns {object} dropdownMenuProps: props for dropdown menu
 * | @returns {array} itemsToDisplay: modified list of items to be used in menu
 */
export const useDropdownMenuProps = ({
  items,
  enableDeselection,
  className
} = {}) => {
  const { t: translate } = useTranslation();

  let itemsToDisplay = items;

  if (enableDeselection) {
    const unselectItem = {
      id: null,
      name: translate('common:dropdownUnselect'),
      className: 'text-color-grey'
    };

    itemsToDisplay = [unselectItem, ...items];
  }

  const safeClassName = className || 'moshi-rs-dropdown-menu';

  return { itemsToDisplay, dropdownMenuProps: { className: safeClassName } };
};
