import moment from 'moment';
import 'moment/locale/sl';

import { getLanguage } from './language';

moment.updateLocale('sl', {
  longDateFormat: {
    l: 'D. M. YYYY',
    L: 'D. M. YYYY'
  }
});

moment.locale(getLanguage());
