export const PORTAL_ID_ADD_PATIENT_SIDEPANEL = 'create-patient-sidepanel';
export const PORTAL_ID_OVERLAY_TITLE = 'overlay-title';
export const PORTAL_ID_ANALYTICS_FILTERS = 'analytics-filters';
export const PORTAL_ID_MEDICAL_RECORD_HEADER = 'medical-record-header';
export const PORTAL_ID_THERAPY_LIST_HEADER = 'therapy-action-portal';
export const PORTAL_ID_DOCUMENT_LIST_HEADER = 'documents-action-portal';
export const PORTAL_ID_COMMUNICATION_LIST_HEADER =
  'communication-action-portal';
export const PORTAL_ID_BILLING_SETTINGS_HEADER =
  'billing-settings-action-portal';
export const PORTAL_ID_CALENDAR_SETTINGS_HEADER =
  'calendar-settings-action-portal';
export const PORTAL_ID_ACCOUNT_SETTINGS_HEADER =
  'account-settings-action-portal';
export const PORTAL_ID_DOCUMENT_SETTINGS_HEADER =
  'document-settings-action-portal';
export const PORTAL_ID_MERGE_PATIENTS_BTNS_CONTAINER =
  'merge-patients-btns-container';
export const PORTAL_ID_CALENDAR_CONTROLS = 'calendar-controls';
export const PORTAL_ID_HEADER_PORTAL = 'header-portal';
export const PORTAL_ID_BOOKING_SETTINGS_HEADER =
  'booking-settings-action-portal';
export const PORTAL_ID_EVENT_LOG_HEADER = 'event-log-action-portal';

export const FOCUSABLE_ELEMENTS_SELECTOR = `
button:not([tabindex="-1"]), 
[href], 
input, 
select, 
textarea, 
[tabindex]:not([tabindex="-1"]), 
[contenteditable="true"]
`;
