import { WIDGET_TYPE_QUESTIONNAIRE } from '../widgetConstants';

export default {
  id: WIDGET_TYPE_QUESTIONNAIRE,
  formSchema: {
    title: '',
    type: 'object',
    properties: {}
  },
  uiSchema: {
    'x-customClass': 'questionnaire-widget problem-description-widget'
  }
};
