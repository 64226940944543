export const EMPTY_ACTION_TYPE = 'EMPTY_ACTION_TYPE';
export const EMPTY_ACTION = { type: EMPTY_ACTION_TYPE };

export const ACTION_TYPE_TRIGGER = '_TRIGGER';
export const ACTION_TYPE_IN_PROGRESS = '_IN_PROGRESS';
export const ACTION_TYPE_SUCCESS = '_SUCCESS';
export const ACTION_TYPE_FAILURE = '_FAILURE';
export const ACTION_TYPE_SANITIZED = '_SANITIZED';
export const ACTION_TYPE_UPDATED = '_UPDATED';

export const createActionTypeArray = (type) => {
  const requestSuffix = '_REQUEST';

  return [
    `${type}${requestSuffix}${ACTION_TYPE_IN_PROGRESS}`,
    `${type}${requestSuffix}${ACTION_TYPE_SUCCESS}`,
    `${type}${requestSuffix}${ACTION_TYPE_FAILURE}`
  ];
};

export const createSanitizableActionTypeArray = (type) => [
  `${type}${ACTION_TYPE_TRIGGER}`,
  ...createActionTypeArray(type),
  `${type}${ACTION_TYPE_SANITIZED}`,
  `${type}${ACTION_TYPE_UPDATED}`
];
