import { createActionTypeArray } from '../common/actionTypes';

export const GET_SMS_COMMUNICATION_LIST =
  'communication/GET_SMS_COMMUNICATION_LIST';

export const CLEAR_SMS_COMMUNICATION_LIST =
  'communication/CLEAR_SMS_COMMUNICATION_LIST';

export const CLEAR_SMS_COMMUNICATION_MESSAGE_DETAILS =
  'communication/CLEAR_SMS_COMMUNICATION_MESSAGE_DETAILS';

export const SET_SMS_COMMUNICATION_MESSAGE_DETAILS =
  'communication/SET_SMS_COMMUNICATION_MESSAGE_DETAILS';

export const SET_COMMUNICATION_LIST_PAGINATION =
  'communication/SET_COMMUNICATION_LIST_PAGINATION';

export const SET_COMMUNICATION_LIST_FILTERS =
  'communication/SET_COMMUNICATION_LIST_FILTERS';

export const SET_COMMUNICATION_LIST_ORDER =
  'communication/SET_COMMUNICATION_LIST_ORDER';

export const CLEAR_COMMUNICATION_LIST_FILTERS =
  'communication/CLEAR_COMMUNICATION_LIST_FILTERS';

export const [
  GET_SMS_COMMUNICATION_LIST_IN_PROGRESS,
  GET_SMS_COMMUNICATION_LIST_SUCCESS,
  GET_SMS_COMMUNICATION_LIST_FAILURE
] = createActionTypeArray(GET_SMS_COMMUNICATION_LIST);
