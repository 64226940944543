import React from 'react';
import { Helmet } from 'react-helmet';

import _ from 'lodash';
import PropTypes from 'prop-types';

import moshiConfig, { getBrandName } from '../../../utils/moshiConfig';

/**
 * Helper component for setting page title, includes app name
 *
 * @example ./__examples__/PageMeta.md
 */
const PageMeta = ({ titlePrefix, title, children }) => {
  const hasTitlePrefix = !_.isEmptySafe(titlePrefix);
  const hasTitle = !_.isEmptySafe(title);
  let appName = getBrandName(_.get(document, 'location.hostname', ''));

  if (_.isEmpty(appName)) {
    appName = moshiConfig.appName;
  }

  let pageTitle = appName;

  if (hasTitlePrefix && hasTitle) {
    pageTitle = `${titlePrefix} - ${title} | ${appName}`;
  }

  if (!hasTitlePrefix && hasTitle) {
    pageTitle = `${title} | ${appName}`;
  }

  return (
    <Helmet>
      <title>{pageTitle}</title>

      {children}
    </Helmet>
  );
};

PageMeta.propTypes = {
  /** Title to be shown before page title */
  titlePrefix: PropTypes.string,
  /** Page title to be displayed */
  title: PropTypes.string,
  /** Any meta tags */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PageMeta.defaultProps = {
  titlePrefix: undefined,
  title: undefined,
  children: undefined
};

export default PageMeta;
