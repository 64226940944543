import _ from 'lodash';
import moment from 'moment';

import { INVOICE_STATUS_ISSUED } from '../../../../utils/billing';
import { getDateFormat } from '../../../../utils/date';

import LocalizedStringModel from '../../LocalizedStringModel';
import PaymentModel from '../PaymentModel';
import BillBaseModel from './BillBaseModel';

class AdvanceModel extends BillBaseModel {
  constructor(id, status = [INVOICE_STATUS_ISSUED]) {
    super(id);

    this.totalUsed = 0;
    this.datePaid = this.date;
    this.status = status;
  }

  setAdvancePreferredDataInput(
    billingUnitList,
    preferredBillingData,
    defaultLocale
  ) {
    const billingUnit = super.setPreferredDataInput(
      billingUnitList,
      preferredBillingData,
      defaultLocale
    );

    const note = _.get(
      billingUnit,
      LocalizedStringModel.getFieldPath('texts.advanceNote', defaultLocale)
    );

    this.texts.note = note;
  }

  static fromDto(dto) {
    const assignedBillBase = BillBaseModel.fromDto(dto);
    const assignedInvoice = _.nestedAssign(
      new AdvanceModel(null, []),
      assignedBillBase
    );

    assignedInvoice.datePaid = moment(assignedInvoice.datePaid).toISOString();

    return assignedInvoice;
  }

  static getPayload(model, defaultLocale) {
    const payload = BillBaseModel.getPayload(model, defaultLocale);

    payload.datePaid = getDateFormat(model.datePaid, 'YYYY-MM-DD');

    if (_.has(payload, 'payments')) {
      payload.payments = _.map(payload.payments, (payment) =>
        PaymentModel.getPayload(payment)
      );
    }

    return payload;
  }
}

export default AdvanceModel;
