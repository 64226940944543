import LocalizedStringModel from '../LocalizedStringModel';

class BillingTextsModel {
  constructor() {
    this.invoiceNote = new LocalizedStringModel();
    this.estimateNote = new LocalizedStringModel();
    this.advanceNote = new LocalizedStringModel();
    this.signature = new LocalizedStringModel();
    this.footer = new LocalizedStringModel();
  }
}

export default BillingTextsModel;
