import {
  GENERATED_SMS_TYPE_APPOINTMENT_ADDED,
  GENERATED_SMS_TYPE_APPOINTMENT_CANCELED,
  GENERATED_SMS_TYPE_APPOINTMENT_NO_SHOW,
  GENERATED_SMS_TYPE_APPOINTMENT_REMINDER,
  SMS_TYPE_CUSTOM,
  GENERATED_SMS_TYPE_RSVP_RESPONSE,
  GENERATED_SMS_TYPE_THERAPY_REMINDER,
  GENERATED_SMS_TYPE_UNKNOWN,
  GENERATED_SMS_TYPE_APPOINTMENT_RSVP_RESPONSE,
  GENERATED_SMS_TYPE_RSVP_PATIENT_RESPONSE,
  SMS_TYPE_CUSTOM_INBOUND,
  GENERATED_SMS_APPOINTMENT_FEEDBACK
} from '../../utils/constants/communicationConstants';

export default {
  title: 'Sporočanje',
  smsType: {
    all: 'Vsi',
    sent: 'Poslani',
    scheduled: 'Bo poslano',
    failed: 'Ni uspelo',
    received: 'Prejeti'
  },
  deliveryStatus: {
    sent: 'Poslano',
    scheduled: 'Bo poslano',
    failed: 'Ni uspelo',
    dispatched: 'V pošiljanju',
    read: 'Prebrano',
    unread: 'Neprebrano',
    draft: 'Osnutek',
    received: 'Prejeto'
  },
  smsGeneratedType: {
    [GENERATED_SMS_TYPE_THERAPY_REMINDER]: 'Opomnik za terapijo',
    [GENERATED_SMS_TYPE_RSVP_RESPONSE]: 'Prejet odgovor',
    [GENERATED_SMS_TYPE_RSVP_PATIENT_RESPONSE]:
      'Odgovor pacienta za potrditev termina',
    [GENERATED_SMS_TYPE_APPOINTMENT_ADDED]: 'Nov termin dodan',
    [GENERATED_SMS_TYPE_APPOINTMENT_NO_SHOW]: 'Ni prišel na termin',
    [GENERATED_SMS_TYPE_APPOINTMENT_CANCELED]: 'Termin preklican',
    [GENERATED_SMS_TYPE_APPOINTMENT_REMINDER]: 'Opomnik za termin',
    [GENERATED_SMS_TYPE_APPOINTMENT_RSVP_RESPONSE]: 'Prejet odgovor',
    [GENERATED_SMS_TYPE_UNKNOWN]: 'Neznano',
    [SMS_TYPE_CUSTOM]: 'SMS po meri',
    [SMS_TYPE_CUSTOM_INBOUND]: 'Prejet SMS od pacienta',
    [GENERATED_SMS_APPOINTMENT_FEEDBACK]: 'Sporočilo po storitvi'
  }
};
