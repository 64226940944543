import axios from 'axios';
import _ from 'lodash';
import { select, takeEvery } from 'redux-saga/effects';

import { getUserListUrl, getUserUrlByUser } from '../../utils/api/apiUrlUtils';
import { getLocalStorageValue } from '../../utils/browserStorage';
import { LOCALE_SL_SI } from '../../utils/constants/languageConstants';
import { setRegionalFormatting, setLanguage } from '../../utils/language';
import { pollGenerator, apiCall } from '../../utils/redux/sagaUtils';

import { POLL_USERS } from '../core/cache/cacheTypes';

import { UPDATE_USER } from '../user/userReducer';
import {
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_SUCCESS,
  GET_CURRENT_USER_SUCCESS
} from './userReducer';

export function* pollUserListSaga() {
  yield pollGenerator(POLL_USERS, getUserListUrl, {
    retriggerActions: [
      UPDATE_USER_SUCCESS,
      DELETE_USER_SUCCESS,
      CREATE_USER_SUCCESS,
      UPDATE_USER_ROLE_SUCCESS
    ]
  });
}

export function* userSagaWatcher() {
  yield takeEvery(GET_CURRENT_USER_SUCCESS, updateUserLanguage);
}

export function* updateUserLanguage(response) {
  const languageSettings = _.getNonEmpty(response, 'response.data.language');
  const regionalSettings = _.getNonEmpty(
    response,
    'response.data.regionalSettings'
  );
  const state = yield select();

  if (!_.isEmptySafe(languageSettings)) {
    setLanguage(languageSettings);
  }

  if (!_.isEmptySafe(regionalSettings)) {
    setRegionalFormatting(regionalSettings);
  }

  /** ************************ MIGRATION IN VERSION 1.44 -  ********************+**** **/
  const localStorageLanguageSettings = getLocalStorageValue('selectedLanguage');
  const localStorageRegionalSettings = getLocalStorageValue(
    'selectedRegionalFormatting'
  );
  const user = _.getNonEmpty(response, 'response.data');
  const userUrl = getUserUrlByUser(() => state, user);

  if (localStorageLanguageSettings === 'sl') {
    setLanguage(LOCALE_SL_SI);

    yield apiCall(UPDATE_USER, axios.put, [
      userUrl,
      { ...user, language: LOCALE_SL_SI }
    ]);
  }

  if (localStorageRegionalSettings === 'sl') {
    setRegionalFormatting(LOCALE_SL_SI);

    yield apiCall(UPDATE_USER, axios.put, [
      userUrl,
      { ...user, regionalSettings: LOCALE_SL_SI }
    ]);
  }
  /** ************************ MIGRATION IN VERSION 1.44 -  ************************ **/
}
