import * as Sentry from '@sentry/browser';

import moshiConfig, { ENV_DEVELOPMENT } from './moshiConfig';

if (moshiConfig.appEnv !== ENV_DEVELOPMENT) {
  Sentry.init({
    dsn:
      'https://8845b5275b6d4676b547432532d78510@o137858.ingest.sentry.io/1502644',
    environment: moshiConfig.appEnv,
    release: moshiConfig.version
  });
}
