export const SUB_ENTITY_TYPE_USER = 'SUB_ENTITY_TYPE_USER';
export const SUB_ENTITY_TYPE_LOCATION = 'SUB_ENTITY_TYPE_LOCATION';
export const SUB_ENTITY_TYPE_FLOW = 'SUB_ENTITY_TYPE_FLOW';
export const SUB_ENTITY_TYPE_DRUG = 'SUB_ENTITY_TYPE_DRUG';
export const SUB_ENTITY_TYPE_CATEGORY = 'SUB_ENTITY_TYPE_CATEGORY';
export const SUB_ENTITY_TYPE_TAX = 'SUB_ENTITY_TYPE_TAX';
export const SUB_ENTITY_TYPE_WIDGET_QUESTIONNAIRE =
  'SUB_ENTITY_TYPE_WIDGET_QUESTIONNAIRE';
export const SUB_ENTITY_TYPE_PATIENT_THERAPY =
  'SUB_ENTITY_TYPE_PATIENT_THERAPY';

export const getSubEntityKeysConfig = () => [
  {
    key: 'doctorID',
    storePath: 'user',
    type: SUB_ENTITY_TYPE_USER
  },
  {
    key: 'locationID',
    storePath: 'location',
    type: SUB_ENTITY_TYPE_LOCATION
  },
  {
    key: 'flowID',
    storePath: 'flow',
    type: SUB_ENTITY_TYPE_FLOW
  },
  {
    key: 'drugID',
    storePath: 'drug',
    type: SUB_ENTITY_TYPE_DRUG
  },
  {
    key: 'categoryID',
    storePath: 'category',
    allowedActionTypeNamespaceList: ['calendar', 'patient_events'],
    type: SUB_ENTITY_TYPE_CATEGORY
  },
  {
    key: 'widgetType',
    storePath: 'questionnaire',
    type: SUB_ENTITY_TYPE_WIDGET_QUESTIONNAIRE
  },
  {
    key: 'taxIDs',
    storePath: 'tax',
    type: SUB_ENTITY_TYPE_TAX
  }
];

export const SEARCH_RESULT_ENTITY_TYPE_PATIENT = 'patient';
export const SEARCH_RESULT_ENTITY_TYPE_EVENT = 'event';
