import {
  GENERATED_SMS_TYPE_APPOINTMENT_ADDED,
  GENERATED_SMS_TYPE_APPOINTMENT_CANCELED,
  GENERATED_SMS_TYPE_APPOINTMENT_NO_SHOW,
  GENERATED_SMS_TYPE_APPOINTMENT_REMINDER,
  SMS_TYPE_CUSTOM,
  GENERATED_SMS_TYPE_RSVP_RESPONSE,
  GENERATED_SMS_TYPE_THERAPY_REMINDER,
  GENERATED_SMS_TYPE_UNKNOWN,
  GENERATED_SMS_TYPE_APPOINTMENT_RSVP_RESPONSE,
  GENERATED_SMS_TYPE_RSVP_PATIENT_RESPONSE,
  SMS_TYPE_CUSTOM_INBOUND,
  GENERATED_SMS_APPOINTMENT_FEEDBACK
} from '../../utils/constants/communicationConstants';

export default {
  title: 'Communication',
  smsType: {
    all: 'All',
    sent: 'Sent',
    scheduled: 'Scheduled',
    failed: 'Failed',
    received: 'Received'
  },
  deliveryStatus: {
    sent: 'Sent',
    scheduled: 'Scheduled',
    failed: 'Failed',
    dispatched: 'Dispatched',
    read: 'Read',
    unread: 'Unread',
    draft: 'Draft',
    received: 'Received'
  },
  smsGeneratedType: {
    [GENERATED_SMS_TYPE_THERAPY_REMINDER]: 'Drug therapy reminder',
    [GENERATED_SMS_TYPE_RSVP_RESPONSE]: 'Received reply',
    [GENERATED_SMS_TYPE_RSVP_PATIENT_RESPONSE]: 'Received patients RSVP',
    [GENERATED_SMS_TYPE_APPOINTMENT_ADDED]: 'Appointment added',
    [GENERATED_SMS_TYPE_APPOINTMENT_NO_SHOW]: 'Appointment no show',
    [GENERATED_SMS_TYPE_APPOINTMENT_CANCELED]: 'Appointment canceled',
    [GENERATED_SMS_TYPE_APPOINTMENT_REMINDER]: 'Appointment reminder',
    [GENERATED_SMS_TYPE_APPOINTMENT_RSVP_RESPONSE]: 'Received reply',
    [GENERATED_SMS_TYPE_UNKNOWN]: 'Unknown',
    [SMS_TYPE_CUSTOM]: 'Custom SMS',
    [SMS_TYPE_CUSTOM_INBOUND]: 'Custom received SMS',
    [GENERATED_SMS_APPOINTMENT_FEEDBACK]: 'Message after service'
  }
};
