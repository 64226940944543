import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PORTAL_ID_OVERLAY_TITLE } from '../../../../utils/constants/domConstants';
import { EVENT_ESCAPE_KEY, EVENT_KEY_UP } from '../../../../utils/event';
import { useDocumentEvent } from '../../../../utils/hooks';

import Icon from '../../Icon';
import PortalPlaceholder from '../../layout/PortalPlaceholder';

const OverlayComponent = ({
  children,
  onClose,
  containerClassName,
  contentClassName,
  title,
  headerClassName,
  headerComponent: OverlayHeaderComponent,
  headerComponentProps
}) => {
  useDocumentEvent(EVENT_KEY_UP, EVENT_ESCAPE_KEY, () => {
    onClose();
  });

  return (
    <div className={classNames('overlay-container', containerClassName)}>
      <header className={classNames('overlay-header', headerClassName)}>
        {_.isFunction(OverlayHeaderComponent) ? (
          <OverlayHeaderComponent {...headerComponentProps} />
        ) : (
          <PortalPlaceholder id={PORTAL_ID_OVERLAY_TITLE}>
            {title}
          </PortalPlaceholder>
        )}

        <div
          className={'close-button'}
          role={'button'}
          tabIndex={0}
          onClick={onClose}
          id={'close-overlay'}
        >
          <Icon size={'lg'} name={'close'} />
        </div>
      </header>
      <div className={'overlay-content-container'}>
        <div className={classNames('overlay-content', contentClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};

OverlayComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  onClose: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerComponent: PropTypes.func,
  headerComponentProps: PropTypes.shape()
};

OverlayComponent.defaultProps = {
  children: undefined,
  containerClassName: undefined,
  contentClassName: undefined,
  headerClassName: undefined,
  headerComponent: undefined,
  title: '',
  headerComponentProps: {}
};

export default OverlayComponent;
