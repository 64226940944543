export default {
  noAccessToEventsLocation: 'You do not have access to events location.',
  location: 'Location',
  alert: {
    created: 'Location was successfully created.',
    updated: 'Location was successfully updated.',
    removed: 'Location was successfully removed.',
    selectedLocationUpdated: 'Location changed'
  }
};
