import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../i18n';
import { moshiConnect } from '../../../utils/redux';

import DocumentShape, {
  SimpleDocumentShape
} from '../../../metadata/shapes/DocumentShape';
import { FlowShape } from '../../../metadata/shapes/FlowShape';

import MoshiDropdown, {
  DROPDOWN_TINY_LOOK
} from '../../../components/common/form/dropdown/MoshiDropdown';

import DocumentsInFlowList from './table/DocumentsInFlowList';

export const DOCUMENT_TYPE = 'document';
export const QUESTIONNAIRE_TYPE = 'questionnaire';

class AddDocumentsToFlowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newItem: null
    };

    this.setNewItem = this.setNewItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  getDropdownList(itemsList) {
    const { flow } = this.props;

    return _.filter(
      itemsList,
      (item) =>
        !(
          (item.type === DOCUMENT_TYPE &&
            _.includes(flow.documents, item.id)) ||
          (item.type === QUESTIONNAIRE_TYPE &&
            _.includes(flow.questionnaires, item.id))
        )
    );
  }

  setNewItem(newItemId) {
    this.setState(() => ({
      newItem: newItemId
    }));
  }

  handleRemove(updatedFlowObj) {
    const { flow, onUpdate } = this.props;

    const updatedFlow = { ...flow, ...updatedFlowObj };

    this.setNewItem(null);
    onUpdate(updatedFlow);
  }

  addItem(selectedItem) {
    const { flow, onUpdate } = this.props;
    const updatedFlow = _.cloneDeep(flow);

    if (selectedItem.type === 'questionnaire') {
      updatedFlow.questionnaires.push(selectedItem.id);
    } else {
      updatedFlow.documents.push(selectedItem.id);
    }

    this.setNewItem(selectedItem.id);
    onUpdate(updatedFlow);
  }

  renderDocumentList() {
    const { flow, documents, questionnaires } = this.props;
    const { newItem } = this.state;

    const enabledQuestionnaireIds = _.get(flow, 'questionnaires', []);
    const enabledDocumentsIds = _.get(flow, 'documents', []);

    return (
      <DocumentsInFlowList
        className={'documents-or-questionnaires-table'}
        enabledDocumentIds={enabledDocumentsIds}
        enabledQuestionnaireIds={enabledQuestionnaireIds}
        items={[...documents, ...questionnaires]}
        onRemove={this.handleRemove}
        addedItem={newItem}
      />
    );
  }

  render() {
    const { t: translate, flow, documents, questionnaires } = this.props;

    const items = [
      ...this.getDropdownList(documents),
      ...this.getDropdownList(questionnaires)
    ];

    return (
      <div className={'flow-dropdown-container'}>
        <hr />
        <h5 className={'dropdown-title'}>
          {translate(
            'settings:automatedCheckIn.documentsAndQuestionnaires.mandatory'
          )}
          <span>
            {translate(
              'settings:automatedCheckIn.documentsAndQuestionnaires.mandatoryIf',
              { flowName: flow.name }
            )}
          </span>
        </h5>
        {this.renderDocumentList()}
        <MoshiDropdown
          onSelect={this.addItem}
          items={items}
          look={DROPDOWN_TINY_LOOK}
          toggleClassName={'link'}
          className={'fit-content'}
          noCaret
          filterEnabled={items.length > 6}
          placeholder={`+ ${translate(
            'settings:automatedCheckIn.documentsAndQuestionnaires.addDocumentOrQuestionnaire'
          )}`}
        />
      </div>
    );
  }
}

AddDocumentsToFlowComponent.propTypes = {
  t: PropTypes.func.isRequired,
  flow: PropTypes.shape(FlowShape).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape(DocumentShape)).isRequired,
  questionnaires: PropTypes.arrayOf(PropTypes.shape(SimpleDocumentShape))
    .isRequired,
  onUpdate: PropTypes.func.isRequired
};

const mapStateToProps = ({ document, questionnaire }) => {
  const documents = _.get(document, 'list', []).map((documentItem) => {
    documentItem.type = DOCUMENT_TYPE;

    return documentItem;
  });
  const questionnaires = _.get(questionnaire, 'list', []).map(
    (questionnaireItem) => {
      questionnaireItem.type = QUESTIONNAIRE_TYPE;

      return questionnaireItem;
    }
  );

  return {
    documents,
    questionnaires
  };
};

export default moshiConnect(
  mapStateToProps,
  null
)(localize(AddDocumentsToFlowComponent));
