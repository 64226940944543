import _ from 'lodash';
import { createSelector } from 'reselect';

import { mapClosedDatesForList } from '../../utils/mappers/cleaned/closed-dates-mappers';

const selectClosedDatesStore = (store) => store.closedDates;

// LIST SELECTORS
export const selectClosedDatesList = createSelector(
  [selectClosedDatesStore],
  (closedDatesStore) => {
    const dates = _.getNonEmpty(closedDatesStore, 'list', []);

    return mapClosedDatesForList(_.cloneDeep(dates));
  }
);

export const selectIsClosedDatesListLoading = createSelector(
  [selectClosedDatesStore],
  (closedDatesStore) => _.getNonEmpty(closedDatesStore, 'loadingList', false)
);

export const selectClosedDatesPagination = createSelector(
  [selectClosedDatesStore],
  (closedDatesStore) => _.getNonEmpty(closedDatesStore, 'pagination', {})
);
