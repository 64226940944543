import { push } from 'connected-react-router';

import * as Sentry from '@sentry/browser';
import _ from 'lodash';

import { doesTranslateKeyExist } from '../../i18n';
import { networkMethods } from '../constants/networkConstants';

import { createActionTypeArray } from '../../redux/common/actionTypes';

import { baseRoutes } from '../../routes';
import { showAlertError, showAlertInfo, getErrorMessageCode } from '../alert';
import logger from '../logger';

export const CANCEL_REQUEST = 'request canceled by user';

export const INFO_ALERTS = [
  { name: 'error:token.expired', config: { autoClose: false } }
];

const apiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  const {
    apiCall,
    payload,
    onSuccess = _.noop,
    noSuccessDispatch = false,
    onError = _.noop,
    noErrorDispatch = false,
    showToastOnError = true,
    onFinish = _.noop
  } = action;

  if (!_.has(action, 'type') || !(apiCall instanceof Promise)) {
    return next(action);
  }

  if (!_.isString(action.type)) {
    throw new Error('Expected type to be a string.');
  }

  const [requestType, successType, failureType] = createActionTypeArray(
    action.type
  );

  dispatch({
    ...payload,
    type: requestType
  });

  // TODO: Should return response.data
  return apiCall
    .then((response) => {
      const data = _.get(response, 'data', null);

      if (!noSuccessDispatch) {
        dispatch({
          ...payload,
          type: successType,
          response
        });
      }

      onSuccess({ dispatch, getState }, response);

      return Promise.resolve(data);
    })
    .catch((error) => {
      /*
       * TODO: Switch usage of error with response error
       * const responseError = _.get(error, 'response.data', {});
       */
      if (!noErrorDispatch) {
        if (showToastOnError) {
          dispatchError(dispatch, error);
        }
        dispatch({
          ...payload,
          type: failureType,
          error
        });
      }

      onError({ dispatch, getState }, error);

      return Promise.reject(error);
    })
    .finally(() => {
      onFinish();
    });
};

const logError = (error) => {
  const responseStatus = _.get(error, 'response.status', 500);

  if (_.includes([400, 500], responseStatus)) {
    const transactionId = _.get(
      error,
      'response.header.X-Transaction-ID',
      null
    );

    if (!_.isEmpty(transactionId)) {
      Sentry.configureScope((scope) => {
        scope.setTag('transaction_id', transactionId);
      });
    }

    logger.error(error);
  }
};

export const dispatchError = (dispatch, error) => {
  if (_.getNonEmpty(error, 'message') === CANCEL_REQUEST) {
    return;
  }

  const responseData = _.get(error, 'response.data', { type: null });
  let errorMessageCode = getErrorMessageCode(error);

  if (responseData.code === 'unauthorized') {
    dispatch(push(baseRoutes.logout));
  }

  logError(error);

  if (!_.isEmpty(errorMessageCode)) {
    const requestMethod = _.get(error, 'config.method');
    const allowedMethodsForGenericError = [
      networkMethods.put,
      networkMethods.post,
      networkMethods.delete
    ];

    if (
      !doesTranslateKeyExist(errorMessageCode) &&
      _.includes(allowedMethodsForGenericError, _.upperCase(requestMethod))
    ) {
      errorMessageCode = 'error:general';
    }

    const infoAlertMessage = _.find(INFO_ALERTS, { name: errorMessageCode });

    if (!_.isEmpty(infoAlertMessage)) {
      const otherParams = _.get(infoAlertMessage, 'config', {});

      return showAlertInfo(errorMessageCode, { otherParams });
    }

    showAlertError(errorMessageCode, { closeIn: 10 });
  }
};

export default apiMiddleware;
