import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Visible from '../../layout/Visible';
import Overlay from './Overlay';

const ControlledOverlay = ({
  children,
  isOpen,
  containerClassName,
  onClose,
  title,
  headerClassName,
  headerComponent,
  headerComponentProps
}) => (
  <Visible visible={isOpen}>
    <Overlay
      containerClassName={containerClassName}
      onClose={onClose}
      title={title}
      headerComponent={headerComponent}
      headerComponentProps={headerComponentProps}
      headerClassName={headerClassName}
    >
      {children}
    </Overlay>
  </Visible>
);

ControlledOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  isOpen: PropTypes.bool,
  containerClassName: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  headerComponent: PropTypes.func,
  headerComponentProps: PropTypes.shape(),
  headerClassName: PropTypes.string
};

ControlledOverlay.defaultProps = {
  children: undefined,
  isOpen: false,
  containerClassName: undefined,
  onClose: _.noop,
  title: undefined,
  headerComponent: undefined,
  headerComponentProps: {},
  headerClassName: undefined
};

export default ControlledOverlay;
