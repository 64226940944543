export const MOSHI_FILE_TYPE_JPG = 'image/jpg';
export const MOSHI_FILE_TYPE_JPEG = 'image/jpeg';
export const MOSHI_FILE_TYPE_PNG = 'image/png';
export const MOSHI_FILE_TYPE_GIF = 'image/gif';
export const MOSHI_FILE_TYPE_P12 = 'application/x-pkcs12';
export const MOSHI_FILE_TYPE_PDF = 'application/pdf';
export const MOSHI_FILE_TYPE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MOSHI_FILE_TYPE_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MOSHI_FILE_TYPE_XLS = 'application/vnd.ms-excel';
export const MOSHI_FILE_TYPE_DOCUMENT = 'document';
export const MOSHI_FILE_TYPE_QUESTIONNAIRE = 'questionnaire';
export const MOSHI_FILE_TYPE_EXTERNAL = 'externalDocument';
export const MOSHI_FILE_TYPE_DICOM = 'application/dicom';

export const MOSHI_FILE_DICOM_EXTENSION = '.dicom';
export const MOSHI_FILE_DCM_EXTENSION = '.dcm';

export const CUSTOM_FILE_EXTENSIONS = {
  [MOSHI_FILE_TYPE_DICOM]: 'dcm'
};
export const IMAGE_FILE_TYPES = [
  MOSHI_FILE_TYPE_JPG,
  MOSHI_FILE_TYPE_JPEG,
  MOSHI_FILE_TYPE_PNG,
  MOSHI_FILE_TYPE_GIF
];
