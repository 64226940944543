export default {
  tooth: 'Tooth',
  teeth: 'Teeth',
  position: 'Position',
  positions: 'Positions',
  positionsShortened: 'Pos.',
  addItemsAndServices: 'Add items and services',
  notes: 'Notes',
  newDentalChartEntry: 'New dental chart entry',
  editDentalChartEntry: 'Edit dental chart entry',
  dentalChart: 'Dental chart',
  newEntry: 'New entry',
  dentalChartEntry: 'Dental chart entry',
  xRayImage: 'X-ray image',
  uploadXRay: 'Upload x-ray',
  alert: {
    removed: 'Dental chart entry removed',
    updated: 'Dental chart entry updated',
    added: 'Dental chart entry added'
  }
};
