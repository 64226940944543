import _ from 'lodash';
import { createSelector } from 'reselect';

import { BILLING_ITEM_LIST_FILTER_CATEGORY } from '../../utils/constants/billingConstants';
import { FILTER_ALL_ID } from '../../utils/constants/dropdownConstants';
import { EMPTY_GUID } from '../../utils/gen';
import { electronicDeviceMapper } from '../../utils/mappers/cleaned/billing/billingMappers';
import {
  getTaxesWithEmptyOption,
  taxDropdownItemsWithNameAndValue,
  taxDropdownItemsWithValue
} from '../../utils/mappers/tax-mappers';
import { selectProps } from '../../utils/redux/selectorHelpers';

import {
  selectTaxList,
  selectCachedBillingUnitPremisesList,
  selectCachedBillingUnitList
} from '../core/cache/cacheSelectors';
import { selectDefaultBillingLocale } from '../organization/organizationSelectors';
import { selectPreferredBillingUnitId } from '../user/userSelectors';

import BillingItemLocalizedModel from '../../metadata/model/billing/BillingItemLocalizedModel';
import BillingUnitModel from '../../metadata/model/billing/BillingUnitModel';

export const selectBillingStore = (state) => state.billing;

// BILLING UNIT SELECTORS
export const selectBillingUnitStore = createSelector(
  [selectBillingStore],
  (billingStore) => _.get(billingStore, 'unit')
);

export const selectBillingUnitDetail = createSelector(
  [selectBillingUnitStore],
  (billingUnitStore) => _.get(billingUnitStore, 'detail')
);

export const selectBillingUnitDetailWithDefault = createSelector(
  [selectBillingUnitDetail],
  (billingUnitDetail) =>
    _.isEmpty(billingUnitDetail) ? new BillingUnitModel() : billingUnitDetail
);

export const selectPreferredBillingUnitIdWithFallback = createSelector(
  [selectCachedBillingUnitList, selectPreferredBillingUnitId],
  (billingUnitList, preferredBillingUnitId) =>
    _.hasOneItem(billingUnitList)
      ? _.first(billingUnitList).id
      : preferredBillingUnitId
);

// BILLING ITEMS SELECTORS
export const selectBillingItemStore = createSelector(
  [selectBillingStore],
  (billingStore) => _.get(billingStore, 'items')
);

export const selectIsBillingItemDetailLoading = createSelector(
  [selectBillingItemStore],
  (billingItemStore) => _.get(billingItemStore, 'loadingDetail', true)
);

export const selectBillingItem = createSelector(
  [selectBillingItemStore, selectProps],
  (billingItemStore, itemId) => {
    let item;

    if (itemId === EMPTY_GUID) {
      item = new BillingItemLocalizedModel(null);
    } else {
      item = _.getNonEmpty(
        billingItemStore,
        'detail',
        new BillingItemLocalizedModel(null)
      );
    }

    return item;
  }
);

export const selectBillingItemWithDefaultLocale = createSelector(
  [selectBillingItemStore, selectDefaultBillingLocale, selectProps],
  (billingItemStore, defaultLocale, itemId) => {
    let item;

    if (itemId === EMPTY_GUID) {
      item = new BillingItemLocalizedModel(null);
    } else {
      item = _.getNonEmpty(
        billingItemStore,
        'detail',
        new BillingItemLocalizedModel(null)
      );
    }

    item.locale = defaultLocale;

    return item;
  }
);

export const selectBillingItemList = createSelector(
  [selectBillingStore],
  (billingStore) => _.get(billingStore, 'items.list', [])
);

export const selectBillingItemsFilter = createSelector(
  [selectBillingStore],
  (billingStore) => _.get(billingStore, 'items.filter', {})
);

export const selectBillingItemsPagination = createSelector(
  [selectBillingStore],
  (billingStore) => _.get(billingStore, 'items.pagination', {})
);

export const selectBillingItemsParams = createSelector(
  [selectBillingItemsFilter, selectBillingItemsPagination],
  (billingItemFilter, billingItemPagination) => ({
    ...billingItemFilter,
    ...billingItemPagination
  })
);

export const selectBillingExportColumns = createSelector(
  [selectBillingStore],
  (billingStore) => _.getNonEmpty(billingStore, 'exportColumns', [])
);

export const selectGroupBillingItems = createSelector(
  [selectBillingStore],
  (billingStore) => _.getNonEmpty(billingStore, 'groupBillingItems', false)
);

export const selectBillingItemFilterCategoryId = createSelector(
  [selectBillingItemsFilter],
  (billingItemsFilterStore) =>
    _.getNonEmpty(
      billingItemsFilterStore,
      BILLING_ITEM_LIST_FILTER_CATEGORY,
      FILTER_ALL_ID
    )
);

// TAX SELECTORS
export const selectTaxListWithEmptyTax = createSelector(
  [selectTaxList],
  (taxList) => getTaxesWithEmptyOption(taxList)
);

export const selectTaxListWithNameAndValueEmpty = createSelector(
  [selectTaxList],
  (taxList) => {
    const taxListWithEmpty = getTaxesWithEmptyOption(taxList);

    return taxDropdownItemsWithNameAndValue(taxListWithEmpty);
  }
);

export const selectTaxValueList = createSelector([selectTaxList], (taxList) => {
  const taxListWithEmpty = getTaxesWithEmptyOption(taxList);

  return taxDropdownItemsWithValue(taxListWithEmpty);
});

// KEYWORD DATA SELECTORS
export const selectBillingKeyWordData = createSelector(
  [selectBillingStore],
  (billingStore) => _.get(billingStore, 'keyWordData', {})
);

export const selectBillingElectronicDeviceList = createSelector(
  [selectCachedBillingUnitPremisesList],
  (premiseLIstByBillingUnit) => {
    let allPremiseList = [];

    _.forIn(premiseLIstByBillingUnit, (premises) => {
      allPremiseList = _.uniq([...allPremiseList, ...premises]);
    });

    return electronicDeviceMapper(allPremiseList);
  }
);

export const selectHasMoreThanOneDevice = createSelector(
  [selectBillingElectronicDeviceList],
  (electronicDeviceList) => _.hasMoreThanOneItem(electronicDeviceList)
);

// BILL NUMBER SELECTORS

export const selectBillNumbers = createSelector(
  [selectBillingStore],
  (billingStore) => _.getNonEmpty(billingStore, 'numbers', {})
);
