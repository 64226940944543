export default {
  title: 'Encounter',
  new: 'New encounter',
  titleWithFlow: 'Encounter: {{ flow }}',
  comments: 'Comments',
  noComments: 'There are no comments here yet',
  addComment: '+ Add comment',
  author: 'Author',
  markAsComplete: 'Mark as complete',
  sidebarActiveEncounters: 'Active Encounters',
  closeModal: {
    title: 'Close encounter',
    doctorsNotes: `Doctor's notes`,
    addNote: 'Add note',
    newAppointmentNeeded: 'New appointment needed?',
    memoForNextAppointment: 'Memo for next appointment',
    addMemo: 'Add memo'
  },
  closeSuccess: {
    title: 'Consultation successfully closed.',
    summary: 'Summary',
    checkInType: 'Check-in type',
    doctorNotes: `Doctor's notes in consultation`,
    followUp: 'Follow-up'
  },
  doctorLocationTime: '{{ doctor }} ∙ $t(common:locationTime)',
  questionnaires: 'Questionnaires',
  documents: 'Legal documents',
  addCommentPlaceholder: 'Add comment...',
  widget: {
    add: '$t(common:addData)',
    notFound: 'There was an error fetching a widget',
    notFoundWithTitle: 'There was an error fetching widget: {{ widgetTitle }}',
    administrativeData: {
      title: 'Administrative data',
      date: 'Date',
      attendingPhysician: 'Attending physician',
      typeOfEncounter: {
        title: 'Type of encounter',
        officeVisit: 'Office visit',
        telemedicalConsultation: 'Telemedical consultation',
        HomeVisit: 'Home visit'
      },
      reasonForEncounter: {
        title: 'Reason for encounter',
        acuteProblem: 'Acute Problem',
        acuteProblemFollowUp: 'Acute Problem - Follow up',
        chronicProblemRoutine: 'Chronic Problem - Routine',
        chronicProblemFollowUp: 'Chronic Problem - Follow up',
        prenatalCare: 'Prenatal Care',
        postnatalCare: 'Postnatal Care',
        postoperativeCare: 'Postoperative Care',
        wellAdultExam: 'Well Adult Exam',
        wellChildExam: 'Well Child Exam',
        familyPlanning: 'Family Planning',
        counselingAdvice: 'Counseling / Advice',
        Immunization: 'Immunization',
        referredBy: 'Referred By',
        administrativePurpose: 'Administrative Purpose',
        other: 'Other'
      }
    },
    vitalSigns: {
      title: 'Vital signs',
      bodyHeight: 'Body height',
      bodyMass: 'Body mass',
      bodyTemperature: 'Body temperature',
      heartRate: 'Heart rate',
      oxygenSaturation: 'Oxygen saturation',
      respirationRate: 'Respiration rate',
      bloodGlucose: 'Blood glucose',
      bloodPressure: 'Blood pressure',
      bloodPressureSystolic: 'Systolic blood pressure',
      bloodPressureDiastolic: 'Diastolic blood pressure'
    },
    problemDescription: {
      title: 'Problem description',
      chiefComplaint: 'Chief complaint',
      historyOfChiefComplaint: 'History of present chief complaint',
      symptomsByPatient: 'Symptoms described by patient (subjective)',
      symptomsByDoctor: 'Symptoms defined by doctor (objective)'
    },
    healthHistory: {
      title: 'Health history',
      pastIllness: 'Major past illnesses',
      currentIllness: 'Current illnesses & known medical conditions',
      chronicConditions: 'Chronic conditions',
      surgicalHistory: 'Surgical history',
      familyHistory: 'Family history',
      childhoodDiseases: 'Childhood diseases',
      regularAndAcuteMedications: 'Regular and acute medications',
      allergies: 'Allergies',
      sexualHistory: 'Sexual history',
      socioEconomicData: 'Socio-economic data',
      environmentalData: 'Environmental data'
    },
    procedures: {
      title: 'Procedures',
      undertaken: 'Procedures undertaken',
      testsUndertaken: 'Tests undertaken'
    },
    diagnosis: {
      title: 'Diagnosis',
      description: 'Diagnosis'
    },
    conclusion: {
      title: 'Conclusion',
      treatments: 'Treatments & prescribed action(s)',
      medications: 'Prescribed medication(s)'
    },
    notesAndSummaries: {
      title: 'Notes & summaries',
      forPatient: 'Notes for the patient',
      discharge: 'Discharge summary',
      personal: 'Personal notes & comments'
    },
    attachedDocuments: {
      title: 'Attached documents',
      documents: 'Attached documents'
    },
    dropdown: {
      placeholderText: 'Select a value'
    }
  },
  waitingList: {
    alert: {
      encounterCreated: 'A new visitor was added ({{ patientName }}).',
      encounterUpdated: 'A visitor has been updated ({{ patientName }}).',
      encounterMoved: 'A visitor card was moved ({{ patientName }}).',
      encounterArchived: 'Visitor card was archived ({{ patientName }}).',
      encounterDeleted: 'A visitor was removed ({{ patientName }}).',
      allEncountersArchived: 'Column "$t(dashboard:completed)" was archived.',
      comment: {
        updated: 'Comment has been updated',
        deleted: 'Comment has been deleted'
      }
    }
  },
  pastEncounters: {
    title: 'Past encounters',
    noPastEncounters: 'There were no encounters for this patient yet'
  },
  pastData: {
    title: 'Past data'
  }
};
