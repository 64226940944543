/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Col } from 'reactstrap';

import PropTypes from 'prop-types';

import { ReactstrapColShape } from '../../metadata/shapes/ReactstrapShape';

const ColWrapper = ({
  children,
  onClick,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  id,
  ...props
}) => (
  <Col
    {...props}
    onClick={onClick}
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    className={className}
    id={id}
  >
    {children}
  </Col>
);

ColWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onClick: PropTypes.func,
  xs: ReactstrapColShape,
  sm: ReactstrapColShape,
  md: ReactstrapColShape,
  lg: ReactstrapColShape,
  xl: ReactstrapColShape,
  className: PropTypes.string,
  id: PropTypes.string
};

ColWrapper.defaultProps = {
  children: undefined,
  onClick: undefined,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  className: undefined,
  id: undefined
};

export default ColWrapper;
