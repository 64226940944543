import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

const Visible = ({
  children,
  visible,
  component: Component,
  render,
  ...props
}) => {
  if (!visible) {
    return null;
  }

  const renderChildren = () => {
    if (_.isFunction(render)) {
      return render();
    } else if (_.isFunction(children)) {
      return children();
    } else {
      return children;
    }
  };

  if (_.isFunction(Component)) {
    return <Component {...props}>{renderChildren()}</Component>;
  }

  return renderChildren();
};

Visible.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  visible: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func
};

Visible.defaultProps = {
  component: null,
  render: null,
  visible: false,
  children: null
};

export default Visible;
