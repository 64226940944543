import { createActionTypeArray } from '../../common/actionTypes';

export const GET_ALL_PATIENTS_FILES = 'file/GET_ALL_PATIENTS_FILES';
export const GET_FILE_DETAIL = 'file/GET_FILE_DETAIL';
export const CLEAR_FILE_LIST = 'file/CLEAR_FILE_LIST';
export const CLEAR_FILE_DETAIL = 'file/CLEAR_FILE_DETAIL';

export const FILE_TYPE_BILLING_UNIT_LOGO = 'BILLING_UNIT_LOGO';
export const FILE_TYPE_BILLING_UNIT_SIGNATURE = 'BILLING_UNIT_SIGNATURE';

export const FILE_TYPE_GREETING_SCREEN_IMAGE = 'GREETING_SCREEN_IMAGE';
export const FILE_TYPE_BOOKING_LOGO_IMAGE = 'ONLINE_BOOKING_LOGO';
export const GET_PATIENT_FILES = 'file/GET_PATIENT_FILES';

export const SET_PATIENT_FILES_ORDER = 'files/SET_PATIENT_FILES_ORDER';
export const SET_PATIENT_FILES_PAGINATION =
  'files/SET_PATIENT_FILES_PAGINATION';
export const CLEAR_PAGINATION = 'files/CLEAR_PAGINATION';

export const ADD_FILE = 'file/ADD_FILE';
export const UPDATE_FILE = 'file/UPDATE_FILE';

export const REMOVE_PATIENT_FILE = 'file/REMOVE_PATIENT_FILE';
export const UPLOAD_FILE_STATUS = 'file/UPLOAD_FILE_STATUS';
export const DOWNLOAD_FILE_STATUS = 'file/DOWNLOAD_FILE_STATUS';

export const [
  GET_PATIENT_FILES_IN_PROGRESS,
  GET_PATIENT_FILES_SUCCESS,
  GET_PATIENT_FILES_FAILURE
] = createActionTypeArray(GET_PATIENT_FILES);

export const [
  GET_ALL_PATIENTS_FILES_IN_PROGRESS,
  GET_ALL_PATIENTS_FILES_SUCCESS,
  GET_ALL_PATIENTS_FILES_FAILURE
] = createActionTypeArray(GET_ALL_PATIENTS_FILES);

export const [
  GET_FILE_DETAIL_IN_PROGRESS,
  GET_FILE_DETAIL_SUCCESS,
  GET_FILE_DETAIL_FAILURE
] = createActionTypeArray(GET_FILE_DETAIL);
