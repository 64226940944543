export default {
  validationErrors: {
    format: {
      email: 'Please enter a valid email.',
      phoneNumber: 'Phone number not valid.',
      date: 'Please enter a valid date.'
    },
    required: 'Required',
    uniqueItems: 'Duplicated values are not allowed'
  }
};
