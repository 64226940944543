import { createActionTypeArray } from '../common/actionTypes';

export const GET_PATIENTS = 'patients/GET_PATIENTS';
export const SET_PATIENTS_COUNT = 'patients/SET_PATIENTS_COUNT';
export const GET_PATIENT = 'patients/GET_PATIENT';
export const GET_PATIENT_BILLS = 'patients/GET_PATIENT_BILLS';
export const GET_ALL_PATIENT_BILLS = 'patients/GET_ALL_PATIENT_BILLS';
export const ADD_PATIENT_UNBILLED_ITEM = 'patients/ADD_PATIENT_UNBILLED_ITEM';
export const UPDATE_PATIENT_UNBILLED_ITEM =
  'patients/UPDATE_PATIENT_UNBILLED_ITEM';
export const GET_PATIENT_UNBILLED_ITEMS = 'patients/GET_PATIENT_UNBILLED_ITEMS';
export const UPDATE_PATIENT_UNBILLED_ITEMS =
  'patients/UPDATE_PATIENT_UNBILLED_ITEMS';
export const SET_PATIENT_BILLED_ITEM_LIST_ORDER =
  'patients/SET_PATIENT_BILLED_ITEM_LIST_ORDER';
export const SET_PATIENT_BILLED_ITEM_LIST_PAGINATION =
  'patients/SET_PATIENT_BILLED_ITEM_LIST_PAGINATION';

export const ADD_PATIENT = 'patients/ADD_PATIENT';
export const UPDATE_PATIENT = 'patients/UPDATE_PATIENT';
export const DELETE_PATIENT = 'patients/DELETE_PATIENT';
export const SET_PATIENT_LIST_FILTER = 'patients/SET_PATIENT_LIST_FILTER';
export const SET_PATIENT_LIST_ORDER = 'patients/SET_PATIENT_LIST_ORDER';
export const SET_PATIENT_LIST_PAGINATION =
  'patients/SET_PATIENT_LIST_PAGINATION';
export const CLEAR_PATIENT_LIST_FILTER = 'patients/CLEAR_PATIENT_LIST_FILTER';

export const GET_AUTO_SUGGEST_PATIENT_LIST =
  'patients/GET_AUTO_SUGGEST_PATIENT_LIST';

export const GET_PATIENT_OVERLAY_DETAIL = 'patients/GET_OVERLAY_DETAIL';
export const CLEAR_PATIENT_OVERLAY_DETAIL =
  'patients/CLEAR_PATIENT_OVERLAY_DETAIL';
export const GET_PATIENT_STATS = 'patients/GET_PATIENT_STATS';
export const CLEAR_PATIENT_STATS = 'patients/CLEAR_PATIENT_STATS';

export const SET_PATIENT_BILL_LIST_FILTER =
  'patients/SET_PATIENT_BILL_LIST_FILTER';
export const SET_PATIENT_BILL_LIST_ORDER =
  'patients/SET_PATIENT_BILL_LIST_ORDER';
export const SET_PATIENT_BILL_LIST_PAGINATION =
  'patients/SET_PATIENT_BILL_LIST_PAGINATION';

export const TOGGLE_PATIENT_SIDE_PANEL_FORM =
  'patients/TOGGLE_PATIENT_SIDE_PANEL_FORM';

export const GET_PATIENT_EVENT_LIST = 'patient_events/GET_PATIENT_EVENT_LIST';
export const CLEAR_PATIENT_EVENT_LIST =
  'patient_events/CLEAR_PATIENT_EVENT_LIST';

export const GET_MERGE_PATIENT_DRY_RUN = 'patient/GET_MERGE_PATIENT_DRY_RUN';
export const MERGE_PATIENT = 'patient/MERGE_PATIENT';

export const GET_PATIENT_FILE_BY_URL = 'patient/GET_PATIENT_FILE_BY_URL';

export const EXPORT_PATIENT_LIST = 'patient/EXPORT_PATIENT_LIST';

export const SET_PATIENT_TAGS = 'patient/SET_PATIENT_TAGS';
export const REMOVE_PATIENT_TAG = 'patient/REMOVE_PATIENT_TAG';
export const CLEAR_PATIENT_TAG_LIST = 'patient/CLEAR_PATIENT_TAG_LIST';

export const GET_PATIENT_FOR_CACHE_LIST = 'patient/GET_PATIENT_FOR_CACHE_LIST';
export const HIDE_PATIENT_MERGE_TILE = 'patient/HIDE_PATIENT_MERGE_TILE';

export const [
  GET_PATIENTS_IN_PROGRESS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAILURE
] = createActionTypeArray(GET_PATIENTS);

export const [
  GET_PATIENT_IN_PROGRESS,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAILURE
] = createActionTypeArray(GET_PATIENT);

export const [
  GET_PATIENT_BILLS_IN_PROGRESS,
  GET_PATIENT_BILLS_SUCCESS,
  GET_PATIENT_BILLS_FAILURE
] = createActionTypeArray(GET_PATIENT_BILLS);

export const [
  GET_ALL_PATIENT_BILLS_IN_PROGRESS,
  GET_ALL_PATIENT_BILLS_SUCCESS,
  GET_PATIENT_BILLSGET_ALL_PATIENT_BILLS_FAILURE
] = createActionTypeArray(GET_ALL_PATIENT_BILLS);

export const [
  ADD_PATIENT_IN_PROGRESS,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_FAILURE
] = createActionTypeArray(ADD_PATIENT);

export const [
  MERGE_PATIENT_IN_PROGRESS,
  MERGE_PATIENT_SUCCESS,
  MERGE_PATIENT_FAILURE
] = createActionTypeArray(MERGE_PATIENT);

export const [
  UPDATE_PATIENT_IN_PROGRESS,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE
] = createActionTypeArray(UPDATE_PATIENT);

export const [
  DELETE_PATIENT_IN_PROGRESS,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_FAILURE
] = createActionTypeArray(DELETE_PATIENT);

export const [
  GET_PATIENT_UNBILLED_ITEMS_IN_PROGRESS,
  GET_PATIENT_UNBILLED_ITEMS_SUCCESS,
  GET_PATIENT_UNBILLED_ITEMS_FAILURE
] = createActionTypeArray(GET_PATIENT_UNBILLED_ITEMS);

export const [
  UPDATE_PATIENT_UNBILLED_ITEMS_IN_PROGRESS,
  UPDATE_PATIENT_UNBILLED_ITEMS_SUCCESS,
  UPDATE_PATIENT_UNBILLED_ITEMS_FAILURE
] = createActionTypeArray(UPDATE_PATIENT_UNBILLED_ITEMS);

export const [
  GET_PATIENT_EVENT_LIST_IN_PROGRESS,
  GET_PATIENT_EVENT_LIST_SUCCESS,
  GET_PATIENT_EVENT_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_EVENT_LIST);

export const [
  GET_PATIENT_STATS_IN_PROGRESS,
  GET_PATIENT_STATS_SUCCESS,
  GET_PATIENT_STATS_FAILURE
] = createActionTypeArray(GET_PATIENT_STATS);

export const [
  GET_PATIENT_OVERLAY_DETAIL_IN_PROGRESS,
  GET_PATIENT_OVERLAY_DETAIL_SUCCESS,
  GET_PATIENT_OVERLAY_DETAIL_FAILURE
] = createActionTypeArray(GET_PATIENT_OVERLAY_DETAIL);

export const [
  GET_MERGE_PATIENT_DRY_RUN_IN_PROGRESS,
  GET_MERGE_PATIENT_DRY_RUN_SUCCESS,
  GET_MERGE_PATIENT_DRY_RUN_FAILURE
] = createActionTypeArray(GET_MERGE_PATIENT_DRY_RUN);

export const [
  EXPORT_PATIENT_LIST_IN_PROGRESS,
  EXPORT_PATIENT_LIST_SUCCESS,
  EXPORT_PATIENT_LIST_FAILURE
] = createActionTypeArray(EXPORT_PATIENT_LIST);

export const [
  GET_PATIENT_FOR_CACHE_LIST_IN_PROGRESS,
  GET_PATIENT_FOR_CACHE_LIST_SUCCESS,
  GET_PATIENT_FOR_CACHE_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_FOR_CACHE_LIST);
