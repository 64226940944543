import { createActionTypeArray } from '../../common/actionTypes';

export const WEB_SOCKET_ENTITY_TYPE_DEVICE = 'device';
export const WEB_SOCKET_ENTITY_TYPE_ENCOUNTER = 'encounter';
export const WEB_SOCKET_ENTITY_TYPE_ENCOUNTER_COMMENT = 'encounterComment';
export const WEB_SOCKET_ENTITY_TYPE_ASSIST_REQUEST = 'assistRequest';
export const WEB_SOCKET_ENTITY_TYPE_OUTBOUND_PDF_URL = 'outboundDocumentPDF';
export const WEB_SOCKET_ENTITY_TYPE_INVOICE = 'invoice';
export const WEB_SOCKET_ENTITY_TYPE_ESTIMATE = 'estimate';
export const WEB_SOCKET_ENTITY_TYPE_ADVANCE = 'advance';
export const WEB_SOCKET_ENTITY_TYPE_CREDIT_NOTE = 'creditNote';

export const WEBSOCKET_SUBSCRIBE = 'webSocket/SUBSCRIBE';
export const WEBSOCKET_UNSUBSCRIBE = 'webSocket/UNSUBSCRIBE';
export const WEBSOCKET_RESUBSCRIBE = 'webSocket/RESUBSCRIBE';
export const WEBSOCKET_CONNECT = 'webSocket/CONNECT';
export const WEBSOCKET_CONNECT_TIMEOUT = 'webSocket/CONNECT_TIMEOUT';
export const WEBSOCKET_CLOSE = 'webSocket/WEBSOCKET_CLOSE';
export const WEBSOCKET_DISCONNECT = 'webSocket/DISCONNECT';

export const [
  WEBSOCKET_CONNECT_IN_PROGRESS,
  WEBSOCKET_CONNECT_SUCCESS,
  WEBSOCKET_CONNECT_FAILURE
] = createActionTypeArray(WEBSOCKET_CONNECT);
