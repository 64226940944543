/* eslint-disable max-len,max-lines-per-function */
import React from 'react';

import PropTypes from 'prop-types';

const RightFootOutline = ({ outlineColor, disabledColor, disabled }) => {
  const color = disabled ? disabledColor : outlineColor;

  return (
    <g id={'zoomed-right-foot-outline'}>
      <path
        d={
          'M71.0344 759.08C71.0601 758.772 70.1869 772.841 70.1869 776.178C70.1612 779.387 69.288 785.189 70.7519 795.15C72.2158 805.111 70.8803 809.218 64.8964 809.911C57.4486 810.758 54.264 805.47 53.134 799.078C52.4663 795.227 53.1597 790.092 53.1597 787.32C53.1597 784.547 52.081 782.827 52.5176 778.129C52.9542 773.431 55.959 767.86 50.6942 759.106'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M52.8002 790.503C52.1581 793.892 50.5915 796.253 45.6092 794.97C38.7265 793.198 37.4937 785.831 36.6462 781.723C34.3605 770.915 37.4937 756.051 35.0026 749.864'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M35.85 774.663C34.4888 776.486 32.2802 777.538 28.9158 777.051C20.7746 775.87 16.7939 765.498 16.8196 757.591C16.8453 752.868 20.6719 743.472 20.903 739.621C21.1085 735.77 18.5403 732.997 18.5403 732.997'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M17.6928 752.123C16.9737 755.229 14.765 758.053 10.5018 755.05C6.2386 752.046 3.59336 744.729 3.59336 739.159C3.59336 733.588 5.69928 729.326 5.26269 724.602C4.82609 719.904 2.05243 707.71 3.33654 702.37C4.62064 697.031 10.6045 683.758 12.3252 676.493C14.0459 669.228 34.3604 599.091 34.3604 587.539'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M70.5978 766.885C70.5978 766.885 74.3474 762.494 77.4035 765.447'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M35.6445 759.055C34.2577 758.028 33.102 756.179 32.8452 754.254'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M19.4905 745.705C18.6686 744.344 16.7168 742.855 16.7938 739.492'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M78.8931 792.326C80.3056 791.376 82.3601 790.554 85.3649 790.426C90.9123 790.195 93.8657 793.738 95.1498 795.945'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M59.7086 799.668C62.0456 799.514 63.792 800.156 64.9734 800.823'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M42.4503 787.961C42.8612 787.114 43.7858 786.318 45.6092 786.062'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M24.5242 770.941C25.346 769.58 27.2721 769.041 29.789 769.247'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M7.18884 750.839C7.18884 750.839 7.54839 748.195 11.298 750.249'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M97.6409 699.084C96.023 692.564 93.8657 682.064 93.6602 671.975'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M41.5257 621.965C42.3732 612.723 42.0907 595.215 31.3043 579.221C15.741 556.116 15.741 556.116 15.741 556.116'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M89.8593 553.087C92.7357 559.351 106.219 568.593 106.219 580.838C106.219 593.546 94.6618 609.822 94.6618 617.935'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M93.7116 545C104.703 561.019 114.257 560.968 105.166 617.935C103.034 631.259 100.877 647.432 101.75 670.101C102.598 692.769 111.792 717.594 111.792 735.334C111.792 746.347 105.808 752.021 105.577 762.29C105.346 772.558 108.992 778.54 108.992 786.036C108.992 793.533 102.777 813.198 85.4677 812.35C68.1323 811.503 70.2896 790.426 71.9846 782.725C73.7053 775.023 77.9685 769.991 77.763 759.748'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M83.9781 779.849C83.9781 779.849 89.1146 780.979 93.9685 780.85'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M57.6027 780.363C57.6027 780.363 62.097 780.593 63.5866 780.363'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M21.0314 759.055C21.0314 759.055 22.4696 759.414 24.5242 759.594'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M7.18884 739.492H9.62864'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M87.1884 744.832C90.2445 744.704 93.3777 744.267 96.5365 743.523'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M56.8835 744.165C59.1435 744.601 61.4035 744.935 63.7149 745.166'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M39.5739 736.463C41.1405 736.746 42.7071 737.028 44.2994 737.233'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M25.5002 726.887C27.0411 727.247 28.6334 727.581 30.277 727.914'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M7.70245 710.072C8.65269 710.509 9.98817 711.073 11.7089 711.715'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M40.5498 772.609C41.5771 772.712 43.2464 772.789 44.8644 772.609'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M25.3204 665.479C26.1422 662.527 27.0154 658.882 27.8629 654.415'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
    </g>
  );
};

RightFootOutline.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

RightFootOutline.defaultProps = {
  disabled: false
};

export default RightFootOutline;
