export default {
  settings: {
    title: {
      settings: 'Nastavitve',
      account: 'Nastavitve organizacije',
      calendar: 'Nastavitve koledarja',
      billing: 'Nastavitve računov',
      documents: 'Nastavitve dokumentov',
      automatedCheckIn: 'Registracija prihoda',
      therapy: 'Nastavitve terapij',
      booking: 'Spletno naročanje'
    }
  },
  help: 'Pomoč',
  magicbell: {
    header: {
      title: 'Obvestila',
      markAllRead: 'Označite vse kot prebrano'
    },
    notification: {
      markAsRead: 'Označite kot prebrano',
      markAsUnread: 'Označite kot neprebrano',
      delete: 'Izbrišite'
    },
    messages: {
      emptyInbox: 'Ni neprebranih sporočil',
      noInternetConnection:
        'Ne moremo se povezati z internetom. Prosimo previrete internetno povezavo',
      serverError:
        'Težava pri dostopu do strežnika. Prosimo poizkusite znova kasneje.',
      inboxImgAlt: 'Nabiralnik je prazen',
      newNotificationToast: 'Pravkar je prispelo novo sporočilo!'
    }
  }
};
