import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../i18n';

import Visible from '../layout/Visible';

export const DisplayWithFallbackComponent = ({
  t: translate,
  displayValue,
  emptyText,
  assertObjectProps,
  translationKey,
  prefix,
  prefixNoSpace,
  showPrefixIfEmpty,
  suffix,
  suffixNoSpace,
  showSuffixIfEmpty
}) => {
  let isEmpty = false;

  if (_.isPlainObject(displayValue)) {
    _.forEach(assertObjectProps, (objectProp) => {
      if (!isEmpty) {
        let safeValue = _.get(displayValue, objectProp, null);

        if (_.isNumber(safeValue)) {
          safeValue = safeValue.toString();
        }

        isEmpty = _.isEmptySafe(safeValue);
      }
    });
  } else {
    let safeValue = displayValue;

    if (_.isNumber(safeValue)) {
      safeValue = safeValue.toString();
    }
    isEmpty = _.isEmpty(safeValue);
  }

  const finalDisplayValue =
    _.isEmpty(translationKey) || !_.isPlainObject(displayValue)
      ? displayValue
      : translate(translationKey, displayValue);

  const showValue = isEmpty ? emptyText : finalDisplayValue;

  const prefixSpace = prefixNoSpace ? '' : ' ';
  const suffixSpace = suffixNoSpace ? '' : ' ';

  return (
    <React.Fragment>
      <Visible visible={!_.isEmpty(prefix) && (!isEmpty || showPrefixIfEmpty)}>
        {`${prefix}${prefixSpace}`}
      </Visible>
      {_.isPlainObject(showValue) ? JSON.stringify(showValue) : showValue}
      <Visible visible={!_.isEmpty(suffix) && (!isEmpty || showSuffixIfEmpty)}>
        {`${suffixSpace}${suffix}`}
      </Visible>
    </React.Fragment>
  );
};

DisplayWithFallbackComponent.propTypes = {
  t: PropTypes.func.isRequired,
  displayValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape()
  ]),
  emptyText: PropTypes.string,
  translationKey: PropTypes.string,
  assertObjectProps: PropTypes.arrayOf(PropTypes.string),
  prefix: PropTypes.string,
  prefixNoSpace: PropTypes.bool,
  showPrefixIfEmpty: PropTypes.bool,
  suffix: PropTypes.string,
  suffixNoSpace: PropTypes.bool,
  showSuffixIfEmpty: PropTypes.bool
};

DisplayWithFallbackComponent.defaultProps = {
  displayValue: null,
  emptyText: '/',
  assertObjectProps: [],
  translationKey: undefined,
  prefix: undefined,
  prefixNoSpace: false,
  showPrefixIfEmpty: false,
  suffix: undefined,
  suffixNoSpace: false,
  showSuffixIfEmpty: false
};

export default localize(DisplayWithFallbackComponent);
