import _ from 'lodash';

import { safeGlobalGetState } from './redux/storeUtils';

export const getCategoryNameById = (categoryId) => {
  const categories = _.getNonEmpty(
    safeGlobalGetState(),
    'cache.category.list',
    []
  );
  const foundCategory = _.find(categories, { id: categoryId });

  return _.getNonEmpty(foundCategory, 'name', '');
};

export const getCategoryById = (categoryId) => {
  const categories = _.getNonEmpty(
    safeGlobalGetState(),
    'cache.category.list',
    []
  );
  const foundCategory = _.find(categories, { id: categoryId });

  return _.isEmptySafe(foundCategory) ? {} : foundCategory;
};

export const getCategoryColorById = (categoryId) => {
  const categories = _.getNonEmpty(
    safeGlobalGetState(),
    'cache.category.list',
    []
  );
  const foundCategory = _.find(categories, { id: categoryId });

  return _.getNonEmpty(foundCategory, 'color', '');
};
