export default {
  title: 'Working hours',
  setWorkingHours: 'Set working hours',
  addShift: '+ Add shift',
  shiftStart: 'Shift start',
  shiftEnd: 'Shift end',
  repeats: 'Repeats',
  noRepeat: "Don't repeat",
  weekly: 'Weekly',
  ongoing: 'Ongoing',
  specificDate: 'Specific date',
  endRepeats: 'End repeats',
  endDate: 'End date',
  editModal: {
    title: 'Edit {{assigneeName}} working hours'
  },
  alert: {
    added: 'Working hours added',
    updated: 'Working hours updated',
    removed: 'Working hours deleted'
  },
  removerWorkingHourMessage: 'Are you sure you want to delete working hours?',
  removerWorkingHour: 'Delete working hours',
  repeatingShift: 'Repeating shift',
  repeatingShiftEditInfo:
    'You have edited a shift that repeats weekly. Updating upcoming shifts will overwrite {{assigneeType}} ongoing <0>{{dayName}}</0> schedule.',
  updateUpcomingShifts: 'Update upcoming shifts',
  updateThisShiftOnly: 'Update this shift only',
  deleteThisShiftOnly: 'Delete this shift only',
  deleteUpcomingShifts: 'Delete upcoming shifts',
  repeatingShiftDeleteInfo:
    'You are deleting a shift that repeats weekly. Deleting upcoming shifts will overwrite {{assigneeType}} ongoing <0>{{dayName}}</0> schedule.',
  eventOutsideOfWorkingHours: 'Event is outside of the working hours',
  employee: "employee's",
  resource: "resource's"
};
