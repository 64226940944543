import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../../configureStore';
import { showMissingOrganizationIdError } from '../../../utils/alert';

import { setSelectedCalendarCategories } from '../calendarActions';

import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY,
  UPDATE_CATEGORY
} from './categoryTypes';

import { selectCalendarSelectedCategoryIds } from '../calendarSelectors';

export const getCategoryList = () => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return {
    type: GET_CATEGORIES,
    apiCall: axios.get(`/organizations/${organizationId}/categories`)
  };
};

export const getCategory = (categoryID, actionType = GET_CATEGORY) => (
  dispatch
) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/categories/${categoryID}`
    )
  });
};

export const addCategory = (categoryPayload, refetchCategories = true) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: ADD_CATEGORY,
    apiCall: axios.post(
      `/organizations/${organizationId}/categories`,
      categoryPayload
    ),
    onSuccess: (none, response) => {
      if (refetchCategories) {
        dispatch(getCategoryList());
      }

      const newCategoryId = _.getNonEmpty(response, 'data.id', null);

      if (!_.isEmptySafe(newCategoryId)) {
        const selectedCategoryList = selectCalendarSelectedCategoryIds(
          getState()
        );

        dispatch(
          setSelectedCalendarCategories([
            ...selectedCategoryList,
            newCategoryId
          ])
        );
      }
    }
  });
};

export const updateCategory = (category, refetchCategories = true) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = {
    name: _.get(category, 'name'),
    color: _.get(category, 'color')
  };

  return dispatch({
    type: UPDATE_CATEGORY,
    apiCall: axios.put(
      `/organizations/${organizationId}/categories/${category.id}`,
      payload
    ),
    onSuccess: () => {
      if (refetchCategories) {
        dispatch(getCategoryList());
      }
    }
  });
};

export const addOrUpdateCategory = (category) => (dispatch) => {
  if (_.isEmptySafe(category, 'id')) {
    return dispatch(addCategory(category));
  } else {
    return dispatch(updateCategory(category));
  }
};

export const removeCategory = (categoryId, refetchCategories = true) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: DELETE_CATEGORY,
    apiCall: axios.delete(
      `/organizations/${organizationId}/categories/${categoryId}`
    ),
    onSuccess: () => {
      if (refetchCategories) {
        dispatch(getCategoryList());
      }
    }
  });
};
