import produce from 'immer';

import { HIDE_APP_OFFLINE_STATUS_BAR } from './appTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  hideOfflineStatusBar: false
};

const appReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    const { type } = action;

    switch (type) {
      case HIDE_APP_OFFLINE_STATUS_BAR:
        draft.hideOfflineStatusBar = true;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default appReducer;
