import produce from 'immer';
import cookie from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';

import { getErrorMessageCode } from '../../utils/alert';
import {
  tokenCookieKey,
  tokenStorageKey
} from '../../utils/constants/browserStorageConstants';
import { parsePayloadJwt } from '../../utils/jwt';

import { createActionTypeArray } from '../common/actionTypes';

export const LOGIN = 'authentication/LOGIN';
export const RENEW_TOKEN = 'authentication/RENEW_TOKEN';
export const RESET_PASSWORD = 'authentication/RESET_PASSWORD';
export const CONFIRM_NEW_PASSWORD = 'authentication/CONFIRM_NEW_PASSWORD';
export const CHANGE_PASSWORD = 'authentication/CHANGE_PASSWORD';
export const REGISTER = 'authentication/REGISTER';
export const VERIFY_EMAIL = 'authentication/VERIFY_EMAIL';
export const VERIFY_CHANGED_EMAIL = 'authentication/VERIFY_CHANGED_EMAIL';

export const LOGOUT = 'authentication/LOGOUT';
export const RENEW_RETRY = 'authentication/RENEW_RETRY';

export const VALIDATE_RESOURCES = 'authentication/VALIDATE_RESOURCES';

export const [
  LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
] = createActionTypeArray(LOGIN);

export const [
  RENEW_TOKEN_IN_PROGRESS,
  RENEW_TOKEN_SUCCESS,
  RENEW_TOKEN_FAILURE
] = createActionTypeArray(RENEW_TOKEN);

export const [
  RESET_PASSWORD_IN_PROGRESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
] = createActionTypeArray(RESET_PASSWORD);

export const [
  CHANGE_PASSWORD_IN_PROGRESS,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE
] = createActionTypeArray(CHANGE_PASSWORD);

export const [
  CONFIRM_NEW_PASSWORD_IN_PROGRESS,
  CONFIRM_NEW_PASSWORD_SUCCESS,
  CONFIRM_NEW_PASSWORD_FAILURE
] = createActionTypeArray(CONFIRM_NEW_PASSWORD);

export const [
  VERIFY_EMAIL_IN_PROGRESS,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE
] = createActionTypeArray(VERIFY_EMAIL);

export const [
  REGISTER_IN_PROGRESS,
  REGISTER_SUCCESS,
  REGISTER_FAILURE
] = createActionTypeArray(REGISTER);

export const [
  VERIFY_CHANGED_EMAIL_IN_PROGRESS,
  VERIFY_CHANGED_EMAIL_SUCCESS,
  VERIFY_CHANGED_EMAIL_FAILURE
] = createActionTypeArray(VERIFY_CHANGED_EMAIL);

const getInitialState = () => {
  const authInitialState = {
    retries: 0
  };

  try {
    const tokenString = _.getNonEmpty(localStorage, tokenStorageKey, null);
    const token = parsePayloadJwt(tokenString);
    const state = { ...authInitialState };

    state.tokenString = tokenString;
    state.token = token;

    if (!_.isEmptySafe(token)) {
      state.userId = _.getNonEmpty(token, 'sub', null);
      state.organizationId = _.getNonEmpty(token, 'organizationID', null);
      state.isSuperAdmin = _.getNonEmpty(token, 'superadmin', false);
    }

    return state;
  } catch (e) {
    return authInitialState;
  }
};

const setLocalAuthData = (token) => {
  localStorage.setItem(tokenStorageKey, token);
  const expiresIn = moment().add(24, 'hours').toDate();

  cookie.set(tokenCookieKey, token, {
    expires: expiresIn,
    secure: true
  });
};

const clearAuthInfo = () => {
  localStorage.removeItem(tokenStorageKey);
  cookie.remove(tokenCookieKey);
};

const authReducer = produce((draft, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case RENEW_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
      const token = _.getNonEmpty(payload, 'response.data.token', '');
      const parsedToken = parsePayloadJwt(token);

      draft.retries = 0;
      draft.tokenString = token;
      draft.token = parsedToken;
      draft.userId = _.getNonEmpty(parsedToken, 'sub', null);
      draft.organizationId = _.getNonEmpty(parsedToken, 'organizationID', null);
      draft.isSuperAdmin = _.getNonEmpty(parsedToken, 'superadmin', false);

      setLocalAuthData(token);
      break;
    case LOGIN_IN_PROGRESS:
      draft.loginError = null;
      break;
    case LOGIN_FAILURE:
      const loginError = _.getNonEmpty(payload, 'error');
      const loginErrorMessageCode = getErrorMessageCode(loginError);

      draft.loginError = loginErrorMessageCode;

      clearAuthInfo();
      break;
    case REGISTER_IN_PROGRESS:
      draft.registerError = null;
      break;
    case REGISTER_FAILURE:
      const registerError = _.getNonEmpty(payload, 'error');
      const registerErrorMessageCode = getErrorMessageCode(registerError);

      draft.registerError = registerErrorMessageCode;
      break;
    case RESET_PASSWORD_IN_PROGRESS:
      draft.resetPasswordError = null;
      break;
    case RESET_PASSWORD_FAILURE:
      const resetPasswordError = _.getNonEmpty(payload, 'error');
      const resetPasswordErrorMessageCode = getErrorMessageCode(
        resetPasswordError
      );

      draft.resetPasswordError = resetPasswordErrorMessageCode;
      break;
    case LOGOUT:
      clearAuthInfo();

      return getInitialState();
    case RENEW_RETRY:
      draft.retries += 1;
      break;
    default:
  }
}, getInitialState());

export default authReducer;
