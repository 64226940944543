/* eslint-disable camelcase */
import { ENTITY_STATUS_DELETED } from '../../utils/alert';
import {
  THERAPY_STATUS_ACTIVE,
  THERAPY_STATUS_FINISHED,
  THERAPY_STATUS_IN_PROGRESS,
  THERAPY_STATUS_PAUSED,
  THERAPY_STATUS_STOPPED,
  DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED,
  DRUG_INTAKE_STATUS_CONFIRMATION_SENT,
  DRUG_INTAKE_STATUS_LATE,
  DRUG_INTAKE_STATUS_NO_REPLY,
  DRUG_INTAKE_STATUS_NOT_TAKEN,
  DRUG_INTAKE_STATUS_PAUSED,
  DRUG_INTAKE_STATUS_PENDING,
  DRUG_INTAKE_STATUS_REMINDER_SENT,
  DRUG_INTAKE_STATUS_STOPPED,
  DRUG_INTAKE_STATUS_TAKEN_LATE,
  DRUG_INTAKE_STATUS_TAKEN_ON_TIME,
  DRUG_INTAKE_STATUS_UPCOMING,
  THERAPY_TYPE_SCHEDULED,
  THERAPY_TYPE_MANUAL,
  THERAPY_INTAKE_TYPE_MANUAL,
  THERAPY_INTAKE_TYPE_GENERATED,
  DRUG_INTAKE_STATUS_REMINDER_NOT_SENT
} from '../../utils/constants/therapyConstants';

import { formatting } from './date';

export default {
  title: 'Drug therapies',
  header: 'Drug therapy',
  removeTitle: 'Drug therapy',
  create: 'New drug therapy',
  edit: 'Edit drug therapy',
  drug: '$t(drug:singleTitle)',
  intake: 'Intake',
  intakes: 'Intakes',
  indefiniteTherapy: 'Therapy has indefinite duration',
  indefiniteTherapyDescription:
    'Therapy will run until it is manually concluded.',
  addIntake: 'Add intake',
  routeOfAdmission: 'Route of admission',
  notificationSettings: 'Notification Preferences',
  eventTimeLabel: 'Notification time',
  resumeEventTimeLabel: 'Start time',
  instructions: 'Instructions',
  sendReminder: 'Send a reminder to patients before intake.',
  sendReminderDescription:
    'Patient will receive an SMS reminder prior to the scheduled intake.',
  askForConfirmation: 'Check for medication adherence.',
  askForConfirmationDescription:
    'By selecting this option, the patient will also receive a link with the SMS reminder ' +
    'which will open a website and ask the patient to provide feedback on when they took the medication.',
  patientWillReceiveReminder: 'Patient will receive an SMS reminder.',
  patientWillNotReceiveReminder:
    'No SMS reminders will be sent, because intake date is today or earlier.',
  isScheduled: 'Generate intakes in advance',
  isScheduledDescription:
    'Therapy intakes can be generated in advance in according to the specified frequency. You can always add other intakes manually.',
  therapyType: {
    [THERAPY_TYPE_SCHEDULED]: 'Scheduled',
    [THERAPY_TYPE_MANUAL]: 'Manual'
  },
  quantitySuffix: {
    mg: 'mg',
    ml: 'ml',
    ie: 'iu',
    pills: 'pill/s',
    unknown: 'unknown'
  },
  timeSuffix: {
    hours: 'hour',
    hours_plural: 'hours',
    days: 'day',
    days_plural: 'days',
    weeks: 'week',
    weeks_plural: 'weeks',
    months: 'month',
    months_plural: 'months',
    years: 'year',
    years_plural: 'years'
  },
  timeUnit: {
    hours: '$t(therapy:timeSuffix.hours)(s)',
    days: '$t(therapy:timeSuffix.days)(s)',
    weeks: '$t(therapy:timeSuffix.weeks)(s)',
    months: '$t(therapy:timeSuffix.months)(s)',
    years: '$t(therapy:timeSuffix.years)(s)'
  },
  frequencyPrefix: 'every',
  durationPrefix: 'for',
  indefiniteDurationPrefix: '$t(therapy:durationPrefix)',
  indefiniteDuration: 'Indefinite time',
  noStartDate: 'No start date',
  frequencyDisplay:
    '$t(therapy:frequencyPrefix) $t(common:numericValueWithSuffix)',
  emptyListText: `
      <0>Hmmm. Seems like you don’t have any drug therapies yet.</0> <1></1>
      <0>Let’s start by creating a new one.</0>
    `,
  pastTherapyList: {
    title: 'Past therapies'
  },
  activeTherapyTile: {
    therapyStatus: 'Intake status',
    status: {
      active: 'Active therapy',
      past: 'Past therapy',
      paused: 'Paused therapy',
      stopped: 'Stopped therapy'
    },
    manuallyStoppedTherapy: 'Manually stopped therapy',
    reasonForCancelation: 'Conclusion reason',
    reasonForCancelationStatus: 'Conclusion: {{ reasonForCancelationName }}',
    new: 'New drug therapy',
    edit: 'Edit drug therapy',
    create: 'Add therapy',
    selectTherapyTemplate: 'Select a therapy template',
    startDate: 'Start date',
    dateWithDoctor: `{{ date, ${formatting.longDateWithTime} }} ∙ DR. {{ user.firstName }} {{ user.lastName }}`,
    therapyNotStartedYet: 'No yet started',
    infoStart: `START: {{ startAt, ${formatting.shortDate} }} ∙ `,
    infoNoStart: `START: $t(therapy:noStartDate) ∙ `,
    infoEnd: `END: {{ endsAt, ${formatting.shortDate} }} ∙ `,
    info:
      `$t(therapy:activeTherapyTile.infoStart, { "startAt": "{{ startAt }}" })` +
      `$t(therapy:activeTherapyTile.infoEnd, { "endsAt": "{{ endsAt }}" })` +
      'DAYS IN THERAPY: {{ durationPrefix }}{{ duration }} $t(therapy:timeSuffix.days, { "count": {{ duration }} })',
    infoWithoutDuration:
      `$t(therapy:activeTherapyTile.infoNoStart)` +
      `$t(therapy:activeTherapyTile.infoEnd, { "endsAt": "{{ endsAt }}" })` +
      'DAYS IN THERAPY: ?',
    infoPaused:
      `$t(therapy:activeTherapyTile.infoStart, { "startAt": "{{ startAt }}" })` +
      `PAUSED`,
    contactAndTime: `Reminder will be sent at <0>{{ time, ${formatting.shortDate} }}</0> to <0>{{ phoneNumber }}</0>`,
    intakeProgress: '<0>{{ current }}</0> / {{ target }}',
    pauseTherapy: 'Pause',
    resumeTherapy: 'Resume',
    resumeTherapyAt: 'Date of resumption',
    endTherapy: 'Stop',
    showAllEvents: 'See all intakes',
    hideEvents: 'Hide intakes',
    confirmStopTherapy: {
      title: 'Stop therapy',
      message:
        'Are you sure you want to stop this therapy: <0>{{ itemName }}</0>?'
    },
    confirmResumeTherapy: {
      title: 'Resume therapy'
    },
    confirmRemoveTherapy: {
      title: 'Remove therapy',
      message:
        'Are you sure you want to remove this therapy: <0>{{ itemName }}</0>?'
    },
    addManualIntake: 'Add intake'
  },
  drugIntake: {
    type: {
      [THERAPY_INTAKE_TYPE_MANUAL]: 'Manual',
      [THERAPY_INTAKE_TYPE_GENERATED]: 'Generated'
    },
    status: {
      [DRUG_INTAKE_STATUS_NOT_TAKEN]: 'Not taken',
      [DRUG_INTAKE_STATUS_TAKEN_LATE]: 'Taken late',
      [DRUG_INTAKE_STATUS_TAKEN_ON_TIME]: 'Taken on time',
      [DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED]: 'Sent',
      [DRUG_INTAKE_STATUS_LATE]: 'Late',
      [DRUG_INTAKE_STATUS_NO_REPLY]: 'No reply',
      [DRUG_INTAKE_STATUS_PENDING]: 'Pending',
      [DRUG_INTAKE_STATUS_CONFIRMATION_SENT]: `$t(therapy:drugIntake.status.${DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED})`,
      [DRUG_INTAKE_STATUS_REMINDER_SENT]: `$t(therapy:drugIntake.status.${DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED})`,
      [DRUG_INTAKE_STATUS_REMINDER_NOT_SENT]: 'Not sent',
      [DRUG_INTAKE_STATUS_PAUSED]: 'Paused',
      [DRUG_INTAKE_STATUS_STOPPED]: 'Stopped',
      [ENTITY_STATUS_DELETED]: 'Deleted',
      [DRUG_INTAKE_STATUS_UPCOMING]: 'Scheduled'
    },
    statusDescription: {
      [DRUG_INTAKE_STATUS_NOT_TAKEN]:
        'Patient has not taken the scheduled medication.',
      [DRUG_INTAKE_STATUS_TAKEN_LATE]:
        'Patient has taken the scheduled medication with a 24h or more delay.',
      [DRUG_INTAKE_STATUS_TAKEN_ON_TIME]:
        'Patient has taken the scheduled medication on time (within 24 hours of receiving the reminder).',
      [DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED]:
        'Patient has replied to the notification',
      [DRUG_INTAKE_STATUS_LATE]:
        'Patient has not yet confirmed whether she/he has taken the scheduled medication. It has been more than 24h since sending the reminder.',
      [DRUG_INTAKE_STATUS_NO_REPLY]:
        'Patient has not confirmed whether she/he has taken the scheduled medication. It has been more than 7 days since sending the reminder.',
      [DRUG_INTAKE_STATUS_PENDING]:
        'Waiting for the patient’s reply. It has been less than 24h since sending the reminder.',
      [DRUG_INTAKE_STATUS_CONFIRMATION_SENT]: 'Reminder has been sent.',
      [DRUG_INTAKE_STATUS_REMINDER_SENT]: 'Reminder has been sent.',
      [DRUG_INTAKE_STATUS_REMINDER_NOT_SENT]: 'Reminder has not been sent.',
      [DRUG_INTAKE_STATUS_PAUSED]: 'The therapy has temporarily been paused.',
      [DRUG_INTAKE_STATUS_STOPPED]: 'Therapy has been permanently stopped.',
      [ENTITY_STATUS_DELETED]: 'Reminder was not sent.',
      [DRUG_INTAKE_STATUS_UPCOMING]: 'Reminder has not yet been sent.'
    }
  },
  settings: {
    title: 'Drug therapy settings',
    description:
      'Reminders are text messages that remind your patients to take their drug intake.',
    text: {
      remindPatientHeader: 'Remind patient to take a drug',
      remindPatientDescription:
        'This text will be sent before a patient needs to take their drug and has enabled SMS and Email reminders.',
      from: 'From',
      message: 'Message'
    },
    legend: `
    <0>Legend</0>
    <1>
    The following smart codes can be entered into texts and will be automatically swapped with their respective data:
    </1>
    <1>[drug therapy instructions] - drug intake instructions.</1>
    <1>[drug therapy intake date] - date of intake.</1>
    <1>[drug therapy intake time] - time of intake.</1>
    <1>[location name] - location of the appointment.</1>
    <1>[location phone number] - location phone number.</1>
    <1>[organization phone number] - organization phone number.</1>
    <1>[doctor full name] - doctor's name.</1>
    <1>[doctor email] - doctor's email</1>
    <1>[doctor phone number] - doctor's phone number</1>
    <1>[drug therapy name] - drug.</1>
    <1>[drug therapy dosage] - dosage.</1>
    <1>[drug therapy ask for confirmation] - ask for confirmation.</1>
    <1>[drug therapy confirmation url] - confirmation url.</1>
    <1>[if drug therapy instructions] - display content if instructions enabled.</1>
    <1>[if drug therapy ask for confirmation] - display content if confirmation enabled.</1>
    <1>[end] - end of if.</1>
    `
  },
  patientList: {
    status: {
      [THERAPY_STATUS_ACTIVE]: 'In progress',
      [THERAPY_STATUS_IN_PROGRESS]: `$t(therapy:patientList.status.${THERAPY_STATUS_ACTIVE})`,
      [THERAPY_STATUS_PAUSED]: 'Paused',
      [THERAPY_STATUS_STOPPED]: 'Stopped',
      [THERAPY_STATUS_FINISHED]: 'Finished'
    }
  },
  history: {
    created: `<0>Created by</0> {{ userName }} on {{ date, ${formatting.shortDateWithTime} }}`,
    updated: `<0>Updated by</0> {{ userName }} on {{ date, ${formatting.shortDateWithTime} }}`,
    paused: `<0>Paused by</0> {{ userName }} on {{ date, ${formatting.shortDateWithTime} }}`,
    resumed: `<0>Resumed by</0> {{ userName }} on {{ date, ${formatting.shortDateWithTime} }}`,
    stopped: `<0>Stopped by</0> {{ userName }} on {{ date, ${formatting.shortDateWithTime} }}`
  },
  validation: {
    endDateShorterThanToday: 'The calculated duration ends before today'
  },
  warning: {
    endDateShorterThanToday: 'The therapy ends before selected date.'
  }
};
