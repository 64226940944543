import { createActionTypeArray } from '../../common/actionTypes';

export const GET_INVOICE_LIST = 'billing/GET_INVOICE_LIST';
export const ADD_INVOICE = 'billing/ADD_INVOICE';
export const UPDATE_INVOICE = 'billing/UPDATE_INVOICE';
export const PATCH_INVOICE = 'billing/PATCH_INVOICE';
export const REMOVE_INVOICE = 'billing/REMOVE_INVOICE';
export const VOID_INVOICE = 'billing/VOID_INVOICE';
export const GET_INVOICE_DETAIL = 'billing/GET_INVOICE_DETAIL';
export const CLEAR_INVOICE_DETAIL = 'billing/CLEAR_INVOICE_DETAIL';

export const SET_INVOICE_LIST_FILTER = 'billing/SET_INVOICE_LIST_FILTER';
export const SET_INVOICE_LIST_FILTERS = 'billing/SET_INVOICE_LIST_FILTERS';
export const CLEAR_INVOICE_LIST_FILTER = 'billing/CLEAR_INVOICE_LIST_FILTER';
export const CLEAR_INVOICE_LIST_FILTER_NO_POLL =
  'billing/CLEAR_INVOICE_LIST_FILTER_NO_POLL';
export const SET_INVOICE_LIST_INCLUDE_PARAM =
  'billing/SET_INVOICE_LIST_INCLUDE_PARAM';
export const SET_INVOICE_LIST_ORDER = 'billing/SET_INVOICE_LIST_ORDER';
export const SET_INVOICE_LIST_PAGINATION =
  'billing/SET_INVOICE_LIST_PAGINATION';

export const ADD_INVOICE_PAYMENT = 'billing/ADD_INVOICE_PAYMENT';
export const GET_INVOICE_PAYMENTS_LIST = 'billing/GET_INVOICE_PAYMENTS_LIST';
export const REMOVE_INVOICE_PAYMENT = 'billing/REMOVE_INVOICE_PAYMENT';
export const CLEAR_INVOICE_PAYMENTS_LIST =
  'billing/CLEAR_INVOICE_PAYMENTS_LIST';
export const RECHECK_INVOICE_FURS_STATUS =
  'billing/RECHECK_INVOICE_FURS_STATUS';
export const SEND_INVOICE_VIA_EMAIL = 'billing/SEND_INVOICE_VIA_EMAIL';

export const [
  GET_INVOICE_LIST_IN_PROGRESS,
  GET_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_FAILURE
] = createActionTypeArray(GET_INVOICE_LIST);

export const [
  GET_INVOICE_PAYMENTS_LIST_IN_PROGRESS,
  GET_INVOICE_PAYMENTS_LIST_SUCCESS,
  GET_INVOICE_PAYMENTS_LIST_FAILURE
] = createActionTypeArray(GET_INVOICE_PAYMENTS_LIST);

export const [
  GET_INVOICE_DETAIL_IN_PROGRESS,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAILURE
] = createActionTypeArray(GET_INVOICE_DETAIL);

export const [
  ADD_INVOICE_IN_PROGRESS,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FAILURE
] = createActionTypeArray(ADD_INVOICE);

export const [
  UPDATE_INVOICE_IN_PROGRESS,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE
] = createActionTypeArray(UPDATE_INVOICE);

export const [
  PATCH_INVOICE_IN_PROGRESS,
  PATCH_INVOICE_SUCCESS,
  PATCH_INVOICE_FAILURE
] = createActionTypeArray(PATCH_INVOICE);

export const [
  REMOVE_INVOICE_IN_PROGRESS,
  REMOVE_INVOICE_SUCCESS,
  REMOVE_INVOICE_FAILURE
] = createActionTypeArray(REMOVE_INVOICE);

export const [
  VOID_INVOICE_IN_PROGRESS,
  VOID_INVOICE_SUCCESS,
  VOID_INVOICE_FAILURE
] = createActionTypeArray(VOID_INVOICE);

export const [
  RECHECK_INVOICE_FURS_STATUS_IN_PROGRESS,
  RECHECK_INVOICE_FURS_STATUS_SUCCESS,
  RECHECK_INVOICE_FURS_STATUS_FAILURE
] = createActionTypeArray(RECHECK_INVOICE_FURS_STATUS);
