import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import JsonSchemaForm from 'react-jsonschema-form';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FOCUSABLE_ELEMENTS_SELECTOR } from '../../../../utils/constants/domConstants';
import { useActions } from '../../../../utils/hooks';

import {
  clearFocusedWidgetId,
  clearWidgetIdsToSubmit
} from '../../../../redux/patient/widget/widgetActions';
import CustomWidgetActions from './CustomWidgetActions';

import {
  selectFocusedWidgetId,
  selectWidgetIdsToSubmit
} from '../../../../redux/patient/widget/widgetSelectors';

import { CardMoreActionsShape } from '../../../../metadata/shapes/TableShape';
import { CustomWidgetShape } from '../../../../metadata/shapes/WidgetShape';

import { Span } from '../../HtmlComponents';

import Visible from '../../layout/Visible';
import DisplayWithFallback from '../../view/DisplayWithFallback';
import CustomWidgetTimeStampLabel from './CustomWidgetTimeStampLabel';

/**
 * Focuses and brings focusable element into view when focused widget id changes in the store.
 * Afterwards, clears focused widget id, making this effect run only once on focus.
 *
 * @param {*} widget current widget entity
 * @param {*} formRef reference to widget html form element
 */
const useFocusOnRequest = (widget, formRef) => {
  const focusedWidgetId = useSelector(selectFocusedWidgetId);
  const [clearFocusedWidgetIdDispatch] = useActions([clearFocusedWidgetId]);

  useLayoutEffect(() => {
    if (focusedWidgetId !== widget.id) return;

    const widgetHtmlElm = formRef.current.formElement;
    const focusableElm = widgetHtmlElm.querySelector(
      FOCUSABLE_ELEMENTS_SELECTOR
    );

    if (focusableElm) {
      // Focus setTimeout to override autofocus properties of widgets
      setTimeout(() => {
        focusableElm.focus();
      });
    }

    widgetHtmlElm.scrollIntoView({ behavior: 'smooth' });
    clearFocusedWidgetIdDispatch();
  }, [focusedWidgetId, formRef, widget.id, clearFocusedWidgetIdDispatch]);
};

/**
 * When widget store requests a submit, this hook submits the widget form.
 * Afterwards, clears submission list to ensure it's only ran once.
 * @param {*} widget current widget entity
 * @param {*} formRef reference to widget html from element
 */
const useSubmitOnRequest = (widget, formRef) => {
  const widgetIdsToSubmit = useSelector(selectWidgetIdsToSubmit);
  const [clearWidgetIdsToSubmitDispatch] = useActions([clearWidgetIdsToSubmit]);

  useEffect(() => {
    if (!widgetIdsToSubmit.includes(widget.id)) return;

    const widgetHtmlElm = formRef.current.formElement;
    const saveButton = widgetHtmlElm.querySelector('#save');

    if (saveButton) {
      // Wrapped inside set timeout to ensure errors are displayed correctly
      setTimeout(() => {
        saveButton.click();
      });
    }

    clearWidgetIdsToSubmitDispatch();
  }, [widgetIdsToSubmit, formRef, widget.id, clearWidgetIdsToSubmitDispatch]);
};

const DefaultWidget = ({
  widget,
  editMode,
  jsonSchemaFormProps,
  widgetActions,
  handleCancel,
  formRef
}) => {
  const widgetVersion = _.getNonEmpty(
    jsonSchemaFormProps,
    'formContext.widgetVersion',
    undefined
  );

  const noFormData = useMemo(
    () => _.isEmptySafe(jsonSchemaFormProps, 'formData'),
    [jsonSchemaFormProps]
  );

  useFocusOnRequest(widget, formRef);
  useSubmitOnRequest(widget, formRef);

  return (
    <JsonSchemaForm
      noHtml5Validate
      {...jsonSchemaFormProps}
      className={classNames(_.getNonEmpty(jsonSchemaFormProps, 'className'), {
        'draft-widget': noFormData && !editMode
      })}
    >
      <CustomWidgetActions
        widget={widget}
        widgetActions={widgetActions}
        editMode={editMode}
        onCancel={handleCancel}
      />
      <CustomWidgetTimeStampLabel
        editMode={editMode}
        dateTime={widget.createdAt}
      />
      <Visible
        visible={!editMode}
        component={Span}
        className={'widget-version-label grey-label'}
      >
        <DisplayWithFallback
          prefixNoSpace
          prefix={_.includes(widgetVersion, 'v') ? undefined : 'v'}
          displayValue={widgetVersion}
        />
      </Visible>
    </JsonSchemaForm>
  );
};

DefaultWidget.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  widget: PropTypes.shape(CustomWidgetShape).isRequired,
  jsonSchemaFormProps: PropTypes.shape().isRequired,
  widgetActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape))
    .isRequired,
  formRef: PropTypes.shape().isRequired
};

export default DefaultWidget;
