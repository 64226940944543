/* eslint-disable max-len,max-lines-per-function */
import React from 'react';

import PropTypes from 'prop-types';

const RightHandOutline = ({ outlineColor, disabledColor, disabled }) => {
  const color = disabled ? disabledColor : outlineColor;

  return (
    <g id={'zoomed-right-hand-outline'}>
      <path
        d={
          'M104.655 1C100.597 34.7332 94.7933 36.8897 109.406 53.5253C143.897 92.8294 137.477 76.2708 141.201 105.537C142.048 112.186 147.185 120.247 147.955 130.259C148.726 140.272 154.607 153.595 156.199 158.268C158.125 163.89 153.785 170.308 143.23 164.018C132.674 157.729 126.947 149.231 126.074 141.144C125.381 134.701 124.636 124.509 118.575 115.37'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={
          'M118.601 115.37C111.384 124.868 110.408 126.46 106.453 136.369C102.703 145.74 101.445 152.132 104.321 165.045C107.172 177.958 108.225 190.178 108.559 195.723C108.893 201.269 110.665 211.46 110.665 216.158C110.665 220.856 111.769 240.419 103.448 241.42C94.4082 242.524 92.6618 233.667 91.0438 223.398C89.4258 213.129 89.8881 205.145 89.092 200.524C88.2958 195.903 85.856 189.896 85.4451 185.891C85.0342 181.886 82.6201 161.785 76.251 163.402C73.2205 164.172 74.556 170.95 75.2751 174.749C75.9941 178.549 78.4339 189.793 79.4355 197.058C80.4115 204.324 79.4355 209.869 79.9492 214.849C80.4628 219.83 81.259 223.68 81.3617 229.636C81.4644 235.592 83.6731 256.438 72.4757 256.438C60.9958 256.438 63.1018 240.881 62.5368 237.595C61.6636 232.486 61.792 223.449 61.1499 218.572C60.4308 213.052 57.3233 198.881 57.1949 193.644C57.0665 188.407 59.6347 165.379 54.6267 165.405C50.6974 165.43 53.1885 180.602 52.5465 189.331C52.3153 192.386 51.0312 201.577 51.0055 207.379C50.9799 213.36 50.6717 219.958 50.4405 225.657C50.2094 231.356 51.9301 246.477 42.3507 246.683C32.2063 246.914 32.0779 233.693 31.7954 225.554C31.5386 217.416 32.7456 207.25 32.4888 201.448C32.2063 195.646 31.5129 189.485 32.1293 184.402C32.7456 179.319 36.7263 155.675 33.4904 154.956C30.8708 154.366 28.5081 172.233 27.3524 178.497C26.659 182.271 23.6799 193.49 22.8837 202.886C22.1389 211.769 17.6446 212.744 14.383 212.051C11.1214 211.383 7.75701 207.712 8.01383 195.569C8.27065 183.529 9.86294 176.315 11.0957 171.104C12.3284 165.892 13.4327 158.858 13.9207 153.98C14.7168 146.073 15.3846 134.239 15.4616 128.308C15.5387 122.378 15.6671 122.404 15.3846 115.472C15.1021 108.541 13.895 94.19 18.1325 81.5079C22.3701 68.8259 33.5161 45.5155 34.4406 42.4349C35.3909 39.3542 32.3604 16.8911 34.3893 1.71881'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M34.7488 160.809C35.1083 155.675 33.6958 148.795 32.3604 148.487'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M57.4004 170.59C57.1436 165.995 55.4742 158.909 53.5224 158.935'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M73.5544 159.346C73.0407 162.504 74.0937 167.895 74.5816 170.59'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M82.6201 170.924C81.6185 166.688 80.1033 161.092 78.0487 158.396'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M93.4579 184.992L98.132 184.505'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M96.9764 210.074L101.188 209.458'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M107.301 134.29C107.301 134.29 110.639 123.687 116.084 112.032'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M142.999 150.669C142.999 150.669 145.438 148.384 147.775 148.333'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M96.7709 52.9604C96.7709 52.9604 104.578 64.3589 115.262 72.0862'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M136.963 127.744C136.963 127.744 140.405 125.382 142.177 125.613'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M130.697 87.4639C130.697 87.4639 132.854 90.4419 133.111 91.6228'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M97.0021 229.868C97.0021 229.868 101.008 227.788 103.654 229.303'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M67.9557 242.652C67.9557 242.652 71.7053 241.086 74.8128 243.884'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M63.2559 199.523L68.5464 199.934'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M67.0055 229.842L69.8048 229.867'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M36.2641 232.666C37.7023 232.306 40.7328 231.87 43.4551 234.078'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M36.3925 216.877L40.1678 217.879'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M37.3683 191.924C37.3683 191.924 40.1163 193.31 42.6588 193.721'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M14.8452 173.62C14.8452 173.62 15.4616 174.621 16.8741 175.288'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M12.123 190.794C12.123 190.794 12.7907 191.719 14.0491 192.181'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M11.9689 201.346C11.9689 201.346 15.6414 201.243 16.9512 205.068'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M55.7311 144.354C55.7311 144.354 55.654 148.872 58.2222 151.85'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M81.824 142.454L83.6217 149.077'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M39.8853 137.448C39.8853 137.448 38.6782 141.658 39.3202 144.559'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
      <path
        d={'M19.9303 134.059C19.9303 134.059 20.4953 129.438 21.5996 127.641'}
        stroke={color}
        strokeWidth={'2'}
        strokeMiterlimit={'10'}
        strokeLinecap={'round'}
      />
    </g>
  );
};

RightHandOutline.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

RightHandOutline.defaultProps = {
  disabled: false
};

export default RightHandOutline;
