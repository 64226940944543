import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { Col, Row } from '../../reactstrap';

const CellWithIcon = (Icon, propPath, transformValue) => (entity) => {
  const propValue = _.get(entity, propPath, null);
  let displayText = propValue || '/';

  if (_.isFunction(transformValue) && !_.isNull(propValue)) {
    displayText = transformValue(propValue, entity);
  }

  return (
    <Row>
      <Col xs={1} className={'cell-with-icon'}>
        <div className={'table-row-icon'}>
          <Icon />
        </div>
      </Col>
      <Col xs={11} className={'no-break'}>
        <span className={'font-weight-bold'}>{displayText}</span>
      </Col>
    </Row>
  );
};

CellWithIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  propPath: PropTypes.string.isRequired,
  transformValue: PropTypes.func
};

CellWithIcon.defaultProps = {
  transformValue: null
};

export default CellWithIcon;
