import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { useMount } from '../../../../../utils/hooks';

import { selectWidgetDataByType } from '../../../../../redux/patient/widget/widgetSelectors';

import CustomTextareaAutosizeWidget from '../CustomTextareaAutosizeWidget';

const PatientTherapiesTextAreaWidget = (props) => {
  const dispatch = useDispatch();
  const {
    value: inputValue,
    options: { widgetDataType, entityType, fetchAction }
  } = props;
  const items = useSelector((store) =>
    selectWidgetDataByType(store, { widgetDataType, entityType })
  );

  useMount(() => {
    if (!_.isEmpty(fetchAction) && _.isEmptySafe(items)) {
      dispatch({
        type: fetchAction
      });
    }
  });

  const value = useMemo(() => {
    if (_.isNull(inputValue) || _.isUndefined(inputValue)) {
      const itemNames = _.map(items, 'name');

      itemNames.unshift(
        '!!! Naštete so vse terapije pacienta. Skrbno preverite vsebino, preden shranite podatke. !!!'
      );

      return itemNames.join('\n');
    } else {
      return inputValue;
    }
  }, [inputValue, items]);

  return <CustomTextareaAutosizeWidget {...props} value={value} />;
};

PatientTherapiesTextAreaWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    widgetDataType: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    fetchAction: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string
};

PatientTherapiesTextAreaWidget.defaultProps = {
  disabled: false,
  required: false,
  value: null
};

export default PatientTherapiesTextAreaWidget;
