import _ from 'lodash';
import moment from 'moment';
import numbro from 'numbro';

import i18n, { doesTranslateKeyExist, translateKey } from '../i18n';
import { BILLING_LOCALES } from './constants/languageConstants';
import moshiConfig from './moshiConfig';

export const selectedLanguageStorageKey = 'selectedLanguage';
export const selectedRegionalFormattingStorageKey =
  'selectedRegionalFormatting';

export const getLanguage = () => {
  const selectedLanguage = localStorage.getItem(selectedLanguageStorageKey);

  return selectedLanguage || moshiConfig.defaultLocale;
};

export const setLanguage = (language) => {
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
    moment.locale(language);
    localStorage.setItem(selectedLanguageStorageKey, language);
  }
};

export const getRegionalFormatting = () => {
  const selectedLanguage = localStorage.getItem(
    selectedRegionalFormattingStorageKey
  );

  return selectedLanguage || moshiConfig.defaultLocale;
};

export const setRegionalFormatting = (formattingLanguage) => {
  numbro.setLanguage(formattingLanguage);
  localStorage.setItem(
    selectedRegionalFormattingStorageKey,
    formattingLanguage
  );
};

export const removeLocalizeHocProps = (propsObj) => {
  const newPropsObj = { ...propsObj };

  delete newPropsObj.tReady;
  delete newPropsObj.i18n;
  delete newPropsObj.t;

  return newPropsObj;
};

export const getSafeTranslation = (name) =>
  _.isString(name) && doesTranslateKeyExist(name) ? translateKey(name) : name;

export const getDefaultLanguages = () => [
  { name: translateKey('common:languages.en'), id: 'en-US', shortCode: 'en' },
  { name: translateKey('common:languages.sl'), id: 'sl-SI', shortCode: 'sl' }
];

export const getBillingLocales = (
  translate,
  billingLocales = BILLING_LOCALES
) =>
  _.map(billingLocales, (languageCode) => ({
    id: languageCode,
    name: translate(`common:languages.${languageCode}`)
  }));
