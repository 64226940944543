import _ from 'lodash';
import moment from 'moment';

import { getDateFormat } from '../../../../utils/date';

import LocalizedStringModel from '../../LocalizedStringModel';
import BillBaseModel from './BillBaseModel';

class EstimateModel extends BillBaseModel {
  constructor(id) {
    super(id);

    this.validUntil = this.date;
  }

  setEstimatePreferredDataInput(
    billingUnitList,
    preferredBillingData,
    defaultLocale
  ) {
    const billingUnit = super.setPreferredDataInput(
      billingUnitList,
      preferredBillingData,
      defaultLocale
    );

    const note = _.get(
      billingUnit,
      LocalizedStringModel.getFieldPath('texts.estimateNote', defaultLocale)
    );

    const defaultDueDate = _.get(
      billingUnit,
      'paymentDetails.estimateValidTillDays'
    );

    this.texts.note = note;
    this.validUntil = moment().add(defaultDueDate, 'days').format('YYYY/MM/DD');
  }

  static fromDto(dto) {
    const assignedBillBase = BillBaseModel.fromDto(dto);
    const assignedInvoice = _.nestedAssign(
      new EstimateModel(null, []),
      assignedBillBase
    );

    assignedInvoice.validUntil = moment(
      assignedInvoice.validUntil
    ).toISOString();

    return assignedInvoice;
  }

  static getPayload(model, defaultLocale) {
    const payload = BillBaseModel.getPayload(model, defaultLocale);

    payload.validUntil = getDateFormat(model.validUntil, 'YYYY-MM-DD');

    return payload;
  }
}

export default EstimateModel;
