import { createActionTypeArray } from '../../common/actionTypes';

export const START_POLLING = 'cache/START_POLLING';
export const START_POLLING_ANONYMOUS = 'cache/START_POLLING_ANONYMOUS';
export const STOP_POLLING = 'cache/STOP_POLLING';
export const STOP_POLLING_ANONYMOUS = 'cache/STOP_POLLING_ANONYMOUS';

export const START_DEVICE_POLLING = 'cache/START_DEVICE_POLLING';
export const STOP_DEVICE_POLLING = 'cache/STOP_DEVICE_POLLING';
export const START_INVOICE_POLLING = 'cache/START_INVOICE_POLLING';
export const STOP_INVOICE_POLLING = 'cache/STOP_INVOICE_POLLING';
export const START_ESTIMATE_POLLING = 'cache/START_ESTIMATE_POLLING';
export const STOP_ESTIMATE_POLLING = 'cache/STOP_ESTIMATE_POLLING';
export const START_ADVANCE_POLLING = 'cache/START_ADVANCE_POLLING';
export const STOP_ADVANCE_POLLING = 'cache/STOP_ADVANCE_POLLING';
export const START_CREDIT_NOTE_POLLING = 'cache/START_CREDIT_NOTE_POLLING';
export const STOP_CREDIT_NOTE_POLLING = 'cache/STOP_CREDIT_NOTE_POLLING';
export const START_BILL_POLLING = 'cache/START_BILL_POLLING';
export const STOP_BILL_POLLING = 'cache/STOP_BILL_POLLING';
export const START_CALENDAR_EVENT_POLLING =
  'cache/START_CALENDAR_EVENT_POLLING';
export const STOP_CALENDAR_EVENT_POLLING = 'cache/STOP_CALENDAR_EVENT_POLLING';

export const REFRESH_WAITING_LIST_ENCOUNTERS =
  'cache/REFRESH_WAITING_LIST_ENCOUNTERS';
export const MANUAL_CREATE_WAITING_LIST_ENCOUNTER =
  'cache/MANUAL_CREATE_WAITING_LIST_ENCOUNTER';
export const MANUAL_UPDATE_WAITING_LIST_ENCOUNTER =
  'cache/MANUAL_UPDATE_WAITING_LIST_ENCOUNTER';
export const MANUAL_REMOVE_WAITING_LIST_ENCOUNTER =
  'cache/MANUAL_REMOVE_WAITING_LIST_ENCOUNTER';
export const MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER =
  'cache/MANUAL_ARCHIVE_ALL_WAITING_LIST_ENCOUNTER';
export const MARK_WAITING_LIST_ENCOUNTER_UPDATED =
  'cache/MARK_WAITING_LIST_ENCOUNTER_UPDATED';
export const MARK_WAITING_LIST_ENCOUNTER_COMMENT_UNREAD =
  'cache/MARK_WAITING_LIST_ENCOUNTER_COMMENT_UNREAD';
export const MARK_WAITING_LIST_ENCOUNTER_COMMENT_READ =
  'cache/MARK_WAITING_LIST_ENCOUNTER_COMMENT_READ';

export const MOVE_WAITING_LIST_ITEM_TO = 'cache/MOVE_WAITING_LIST_ITEM_TO';

export const MANUAL_REMOVE_ASSIST_REQUEST =
  'cache/MANUAL_REMOVE_ASSIST_REQUEST';

export const POLL_VERSIONS = 'cache/POLL_VERSIONS';
export const POLL_ORGANIZATION = 'cache/ORGANIZATION_POLL_SUCCESS';
export const POLL_USERS = 'cache/USERS_POLL_SUCCESS';
export const POLL_LOCATIONS = 'cache/LOCATIONS_POLL_SUCCESS';
export const POLL_DOCUMENTS = 'cache/DOCUMENTS_POLL_SUCCESS';
export const POLL_QUESTIONNAIRES = 'cache/QUESTIONNAIRES_POLL_SUCCESS';
export const POLL_FLOWS = 'cache/FLOWS_POLL_SUCCESS';
export const POLL_BILLING_UNITS = 'cache/BILLING_UNITS_POLL_SUCCESS';
export const POLL_BILLING_ITEM_LIST = 'cache/BILLING_ITEMS_POLL';
export const POLL_TAXES = 'cache/TAXES_POLL_SUCCESS';
export const POLL_BILLING_CATEGORY_LIST =
  'cache/BILLING_CATEGORY_LIST_POLL_SUCCESS';
export const POLL_WAITING_LIST_CONFIG =
  'cache/WAITING_LIST_CONFIG_POLL_SUCCESS';
export const POLL_WAITING_LIST_ENCOUNTERS =
  'cache/WAITING_LIST_ENCOUNTERS_POLL_SUCCESS';
export const POLL_BILLING_UNIT_PREMISES =
  'cache/BILLING_UNIT_PREMISES_POLL_SUCCESS';
export const POLL_WAITING_LIST_TODAYS_EVENTS =
  'cache/WAITING_LIST_TODAYS_EVENTS';
export const POLL_PATIENT_SCHEMA = 'cache/PATIENT_SCHEMA_POLL_SUCCESS';
export const POLL_REGISTRY_DRUG_LIST = 'cache/REGISTRY_DRUG_LIST_POLL_SUCCESS';
export const POLL_REGISTRY_THERAPY_LIST =
  'cache/REGISTRY_THERAPY_LIST_POLL_SUCCESS';
export const POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST =
  'cache/POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST';
export const POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST =
  'cache/POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST';
export const POLL_REGISTRY_PAYMENT_TYPES = 'cache/POLL_REGISTRY_PAYMENT_TYPES';
export const POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST =
  'cache/POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST';
export const POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST =
  'cache/POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST';
export const POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST =
  'cache/POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST';
export const POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST =
  'cache/POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST';
export const POLL_REGISTRY_ANAMNESIS_OPERATION_LIST =
  'cache/POLL_REGISTRY_ANAMNESIS_OPERATION_LIST';
export const POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST =
  'cache/POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST';
export const POLL_FORMS = 'cache/POLL_FORMS';

export const POLL_DEVICES = 'cache/DEVICES_POLL_SUCCESS';
export const ADD_DEVICE = 'devices/ADD_DEVICE';
export const REMOVE_DEVICE = 'devices/REMOVE_DEVICE';
export const GET_DEVICES = 'devices/GET_DEVICES';
export const GET_NEW_DEVICE_INFO = 'devices/GET_NEW_DEVICE_INFO';
export const POLL_CATEGORIES = 'cache/CATEGORIES_POLL_SUCCESS';
export const GET_CATEGORIES = 'category/GET_CATEGORIES';
export const POLL_RESOURCES = 'cache/RESOURCES_POLL_SUCCESS';
export const POLL_INVOICES = 'cache/INVOICES_POLL_SUCCESS';
export const POLL_TAGS = 'cache/POLL_TAGS';
export const POLL_BOOKING_TEMPLATES = 'cache/POLL_BOOKING_TEMPLATES';
export const POLL_EVENT_LOGS = 'cache/POLL_EVENT_LOGS';

export const [
  POLL_VERSIONS_IN_PROGRESS,
  POLL_VERSIONS_SUCCESS,
  POLL_VERSIONS_FAILURE
] = createActionTypeArray(POLL_VERSIONS);

export const [
  GET_CATEGORIES_IN_PROGRESS,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE
] = createActionTypeArray(GET_CATEGORIES);

export const [
  REMOVE_DEVICE_IN_PROGRESS,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILURE
] = createActionTypeArray(REMOVE_DEVICE);

export const [
  GET_DEVICES_IN_PROGRESS,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE
] = createActionTypeArray(GET_DEVICES);

export const [
  ADD_DEVICE_IN_PROGRESS,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAILURE
] = createActionTypeArray(ADD_DEVICE);

export const [
  GET_NEW_DEVICE_INFO_IN_PROGRESS,
  GET_NEW_DEVICE_INFO_SUCCESS,
  GET_NEW_DEVICE_INFO_FAILURE
] = createActionTypeArray(GET_NEW_DEVICE_INFO);

export const [
  POLL_REGISTRY_PAYMENT_TYPES_IN_PROGRESS,
  POLL_REGISTRY_PAYMENT_TYPES_SUCCESS,
  POLL_REGISTRY_PAYMENT_TYPES_FAILURE
] = createActionTypeArray(POLL_REGISTRY_PAYMENT_TYPES);

export const [
  POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST_IN_PROGRESS,
  POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST);

export const [
  POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST_IN_PROGRESS,
  POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST);

export const [
  POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST_IN_PROGRESS,
  POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST);

export const [
  POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST_IN_PROGRESS,
  POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST);

export const [
  POLL_REGISTRY_ANAMNESIS_OPERATION_LIST_IN_PROGRESS,
  POLL_REGISTRY_ANAMNESIS_OPERATION_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_OPERATION_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_ANAMNESIS_OPERATION_LIST);

export const [
  POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST_IN_PROGRESS,
  POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST);

export const [
  POLL_FORMS_IN_PROGRESS,
  POLL_FORMS_SUCCESS,
  POLL_FORMS_FAILURE
] = createActionTypeArray(POLL_FORMS);

export const [
  POLL_USERS_IN_PROGRESS,
  POLL_USERS_SUCCESS,
  POLL_USERS_FAILURE
] = createActionTypeArray(POLL_USERS);

export const [
  POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST_IN_PROGRESS,
  POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST_SUCCESS,
  POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST);

export const [
  POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST_IN_PROGRESS,
  POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST_SUCCESS,
  POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST);

export const [
  POLL_REGISTRY_THERAPY_LIST_IN_PROGRESS,
  POLL_REGISTRY_THERAPY_LIST_SUCCESS,
  POLL_REGISTRY_THERAPY_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_THERAPY_LIST);

export const [
  POLL_REGISTRY_DRUG_LIST_IN_PROGRESS,
  POLL_REGISTRY_DRUG_LIST_SUCCESS,
  POLL_REGISTRY_DRUG_LIST_FAILURE
] = createActionTypeArray(POLL_REGISTRY_DRUG_LIST);

export const [
  POLL_RESOURCES_IN_PROGRESS,
  POLL_RESOURCES_SUCCESS,
  POLL_RESOURCES_FAILURE
] = createActionTypeArray(POLL_RESOURCES);

export const [
  POLL_TAGS_IN_PROGRESS,
  POLL_TAGS_SUCCESS,
  POLL_TAGS_FAILURE
] = createActionTypeArray(POLL_TAGS);

export const [
  POLL_BOOKING_TEMPLATES_IN_PROGRESS,
  POLL_BOOKING_TEMPLATES_SUCCESS,
  POLL_BOOKING_TEMPLATES_FAILURE
] = createActionTypeArray(POLL_BOOKING_TEMPLATES);

export const [
  POLL_EVENT_LOGS_IN_PROGRESS,
  POLL_EVENT_LOGS_SUCCESS,
  POLL_EVENT_LOGS_FAILURE
] = createActionTypeArray(POLL_EVENT_LOGS);

export const [
  POLL_BILLING_ITEM_LIST_IN_PROGRESS,
  POLL_BILLING_ITEM_LIST_SUCCESS,
  POLL_BILLING_ITEM_LIST_FAILURE
] = createActionTypeArray(POLL_BILLING_ITEM_LIST);
