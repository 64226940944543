import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ConditionalWrap from '../../layout/ConditionalWrap';

/**
 * The common ToggleSwitch component
 *
 * @example ./__examples__/ToggleSwitch.md
 */
const ToggleSwitch = ({
  toggleLabel,
  round,
  className,
  wrap,
  wrapperClassName,
  value,
  onChange,
  disabled,
  labelId,
  labelClassName,
  ...props
}) => (
  <ConditionalWrap
    condition={wrap}
    wrap={(children) => (
      <div className={classNames(wrapperClassName, 'toggle-switch-container')}>
        {children}
      </div>
    )}
  >
    <label
      id={labelId}
      className={classNames('form-control', className, 'toggle-switch', {
        disabled
      })}
    >
      <input
        {...props}
        disabled={disabled}
        type={'checkbox'}
        onChange={onChange}
        checked={Boolean(value)}
      />
      <span className={classNames('slider', { round })} />
    </label>
    <span className={classNames('toggle-switch-label', labelClassName)}>
      {toggleLabel}
    </span>
  </ConditionalWrap>
);

ToggleSwitch.propTypes = {
  /** The state of the toggle switch (toggled if true, un-toggled if false) */
  value: PropTypes.bool,
  /** onChange callback function */
  onChange: PropTypes.func,
  /** Round or Square toggle, round by default */
  round: PropTypes.bool,
  /** Can add extra class/es to the label around input */
  className: PropTypes.string,
  /** Disables the toggle */
  disabled: PropTypes.bool,
  /** Text that will show on the right side of the toggle */
  toggleLabel: PropTypes.string,
  /** Optional ID of the label element */
  labelId: PropTypes.string,
  /** A condition to wrap switch toggle with a div.toggle-switch-container */
  wrap: PropTypes.bool,
  /** Can add extra class/es to the wrapper */
  wrapperClassName: PropTypes.string,
  /** Can add extra class/es to the label */
  labelClassName: PropTypes.string
};

ToggleSwitch.defaultProps = {
  value: false,
  onChange: _.noop,
  round: true,
  className: undefined,
  disabled: undefined,
  toggleLabel: undefined,
  labelId: undefined,
  wrap: false,
  wrapperClassName: undefined,
  labelClassName: undefined
};

export default ToggleSwitch;
