export const NOTIFICATION_TYPE_CALENDAR = 'calendar';
export const NOTIFICATION_TYPE_SMS = 'sms';
export const NOTIFICATION_TYPE_PATIENTS_EXPORT = 'patientsExport';
export const NOTIFICATION_TYPE_INBOX_SMS = 'inbox';

export const NOTIFICATION_ACTION_TYPE_ADD = 'add';
export const NOTIFICATION_ACTION_TYPE_CONFIRMED = 'confirmed';
export const NOTIFICATION_ACTION_TYPE_UPDATE = 'update';
export const NOTIFICATION_ACTION_TYPE_CANCEL = 'cancel';
export const NOTIFICATION_ACTION_TYPE_DELETE = 'delete';
export const NOTIFICATION_ACTION_TYPE_REMINDER = 'reminder';
