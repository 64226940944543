import {
  getTherapyCancelationReasonListUrl,
  getTherapyDrugListUrl,
  getTherapyRouteOfAdmissionListUrl,
  getTherapyTemplateListUrl
} from '../../utils/api/apiUrlUtils';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import {
  POLL_REGISTRY_DRUG_LIST,
  POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST,
  POLL_REGISTRY_THERAPY_LIST,
  POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST
} from '../core/cache/cacheTypes';
import {
  ADD_DRUG_ITEM_SUCCESS,
  REMOVE_DRUG_ITEM_SUCCESS,
  UPDATE_DRUG_ITEM_SUCCESS
} from '../drug/drugTypes';
import {
  ADD_THERAPY_DETAIL_SUCCESS,
  DELETE_THERAPY_DETAIL_SUCCESS,
  UPDATE_THERAPY_DETAIL_SUCCESS
} from './therapyTypes';

export function* therapyRouteOfAdmissionSaga() {
  yield pollGenerator(
    POLL_REGISTRY_THERAPY_ROUTE_OF_ADMISSION_LIST,
    getTherapyRouteOfAdmissionListUrl,
    {
      retriggerActions: []
    }
  );
}

export function* therapyCancelationReasonSaga() {
  yield pollGenerator(
    POLL_REGISTRY_THERAPY_CANCELATION_REASONS_LIST,
    getTherapyCancelationReasonListUrl,
    {
      retriggerActions: []
    }
  );
}

export function* therapyTemplateListSaga() {
  yield pollGenerator(POLL_REGISTRY_THERAPY_LIST, getTherapyTemplateListUrl, {
    retriggerActions: [
      ADD_THERAPY_DETAIL_SUCCESS,
      UPDATE_THERAPY_DETAIL_SUCCESS,
      DELETE_THERAPY_DETAIL_SUCCESS
    ]
  });
}

export function* therapyDrugListSaga() {
  yield pollGenerator(POLL_REGISTRY_DRUG_LIST, getTherapyDrugListUrl, {
    retriggerActions: [
      ADD_DRUG_ITEM_SUCCESS,
      UPDATE_DRUG_ITEM_SUCCESS,
      REMOVE_DRUG_ITEM_SUCCESS
    ]
  });
}
