import { createActionTypeArray } from '../common/actionTypes';

export const GET_EVENTS = 'calendar/GET_EVENTS';
export const ADD_EVENT = 'calendar/ADD_EVENT';
export const UPDATE_EVENT = 'calendar/UPDATE_EVENT';
export const REMOVE_EVENT = 'calendar/REMOVE_EVENT';
export const REMOVE_EVENT_MESSAGE = 'calendar/REMOVE_EVENT_MESSAGE';
export const GET_EVENT_PATIENT = 'calendar/GET_EVENT_PATIENT';
export const GET_CALENDAR_ASSIGNEES = 'calendar/GET_CALENDAR_ASSIGNEES';
export const SET_CURRENT_CALENDAR_VIEW = 'calendar/SET_CURRENT_CALENDAR_VIEW';
export const SET_CALENDAR_EVENT_COLOR_SOURCE =
  'calendar/SET_CALENDAR_EVENT_COLOR_SOURCE';
export const SET_SELECTED_CALENDAR_ASSIGNEES =
  'calendar/SET_SELECTED_CALENDAR_ASSIGNEES';
export const REMOVE_SELECTED_CALENDAR_ASSIGNEE =
  'calendar/REMOVE_SELECTED_CALENDAR_ASSIGNEE';
export const CLEAR_SELECTED_CALENDAR_ASSIGNEES =
  'calendar/CLEAR_SELECTED_CALENDAR_ASSIGNEES';
export const CLEAR_CALENDAR_ASSIGNEES_LIST =
  'calendar/CLEAR_CALENDAR_ASSIGNEES_LIST';
export const SET_SELECTED_CALENDAR_SEARCH_ASSIGNEES =
  'calendar/SET_SELECTED_CALENDAR_SEARCH_DOCTORS';
export const SET_SELECTED_CALENDAR_SEARCH_CATEGORIES =
  'calendar/SET_SELECTED_CALENDAR_SEARCH_CATEGORIES';
export const SET_SELECTED_CALENDAR_SEARCH_EVENT_STATUSES =
  'calendar/SET_SELECTED_CALENDAR_SEARCH_EVENT_STATUSES';
export const SET_CALENDAR_SELECTED_DATE_RANGE =
  'calendar/SET_CALENDAR_SELECTED_DATE_RANGE';
export const SET_SELECTED_CALENDAR_CATEGORIES =
  'category/SET_SELECTED_CALENDAR_CATEGORIES';
export const SET_SELECTED_CALENDAR_STATUSES =
  'category/SET_SELECTED_CALENDAR_STATUSES';
export const GET_EVENT_MESSAGES = 'calendar/GET_EVENT_MESSAGES';
export const SET_EVENT_PATIENT = 'calendar/SET_EVENT_PATIENT';
export const CLEAR_EVENT_PATIENT = 'calendar/CLEAR_EVENT_PATIENT';
export const CLEAR_EVENT_MESSAGES = 'calendar/CLEAR_EVENT_MESSAGES';
export const SET_SLOT_DURATION = 'calendar/SET_SLOT_DURATION';
export const GET_CALENDAR_WAITING_LIST = 'calendar/GET_CALENDAR_WAITING_LIST';
export const GET_EVENTS_WITH_QUERY = 'calendar/GET_EVENTS_WITH_QUERY';
export const GET_CALENDAR_EVENT_WITH_QUERY =
  'calendar/GET_CALENDAR_EVENT_WITH_QUERY';
export const SET_CALENDAR_SCROLL_TIME = 'calendar/SET_CALENDAR_SCROLL_TIME';
export const SET_PREFILLED_EVENT_DATA = 'calendar/SET_PREFILLED_EVENT_DATA';
export const CLEAR_PREFILLED_EVENT_DATA = 'calendar/CLEAR_PREFILLED_EVENT_DATA';
export const SET_CALENDAR_FILTER_SELECTED_DATE_RANGE =
  'calendar/SET_CALENDAR_FILTER_SELECTED_DATE_RANGE';
export const SET_CALENDAR_TITLE = 'calendar/SET_CALENDAR_TITLE';
export const SET_HIGHLIGHT_EVENT_ID = 'calendar/SET_HIGHLIGHT_EVENT_ID';
export const CLEAR_HIGHLIGHT_EVENT_ID = 'calendar/CLEAR_HIGHLIGHT_EVENT_ID';
export const SEND_CUSTOM_REMINDER = 'calendar/SEND_CUSTOM_REMINDER';
export const SET_SEARCH_RESULTS_LIST_PAGINATION =
  'calendar/SET_SEARCH_RESULTS_LIST_PAGINATION';
export const SET_EVENT_QUERY_STRING = 'calendar/SET_EVENT_QUERY_STRING';
export const CLEAR_SEARCH_QUERY_PAGINATION_AND_RESULTS =
  'calendar/CLEAR_SEARCH_QUERY_PAGINATION_AND_RESULTS';
export const CLEAR_SEARCH_PAGINATION_AND_RESULTS =
  'calendar/CLEAR_SEARCH_PAGINATION_AND_RESULTS';
export const TOGGLE_CALENDAR_ASSIGNEES_LIST =
  'calendar/TOGGLE_CALENDAR_ASSIGNEES_LIST';
export const TOGGLE_CALENDAR_CATEGORY_LIST =
  'calendar/TOGGLE_CALENDAR_CATEGORY_LIST';
export const TOGGLE_CALENDAR_STATUS_LIST =
  'calendar/TOGGLE_CALENDAR_STATUS_LIST';
export const UPDATE_AND_RESCHEDULE_EVENT =
  'calendar/UPDATE_AND_RESCHEDULE_EVENT';

export const [
  GET_EVENTS_IN_PROGRESS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE
] = createActionTypeArray(GET_EVENTS);

export const [
  GET_EVENTS_WITH_QUERY_IN_PROGRESS,
  GET_EVENTS_WITH_QUERY_SUCCESS,
  GET_EVENTS_WITH_QUERY_FAILURE
] = createActionTypeArray(GET_EVENTS_WITH_QUERY);

export const [
  GET_CALENDAR_EVENT_WITH_QUERY_IN_PROGRESS,
  GET_CALENDAR_EVENT_WITH_QUERY_SUCCESS,
  GET_CALENDAR_EVENT_WITH_QUERY_FAILURE
] = createActionTypeArray(GET_CALENDAR_EVENT_WITH_QUERY);

export const [
  GET_CALENDAR_WAITING_LIST_IN_PROGRESS,
  GET_CALENDAR_WAITING_LIST_SUCCESS,
  GET_CALENDAR_WAITING_LIST_FAILURE
] = createActionTypeArray(GET_CALENDAR_WAITING_LIST);

export const [
  GET_EVENT_PATIENT_IN_PROGRESS,
  GET_EVENT_PATIENT_SUCCESS,
  GET_EVENT_PATIENT_FAILURE
] = createActionTypeArray(GET_EVENT_PATIENT);

export const [
  GET_EVENT_MESSAGES_IN_PROGRESS,
  GET_EVENT_MESSAGES_SUCCESS,
  GET_EVENT_MESSAGES_FAILURE
] = createActionTypeArray(GET_EVENT_MESSAGES);

export const [
  ADD_EVENT_IN_PROGRESS,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE
] = createActionTypeArray(ADD_EVENT);

export const [
  UPDATE_EVENT_IN_PROGRESS,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE
] = createActionTypeArray(UPDATE_EVENT);

export const [
  REMOVE_EVENT_IN_PROGRESS,
  REMOVE_EVENT_SUCCESS,
  REMOVE_EVENT_FAILURE
] = createActionTypeArray(REMOVE_EVENT);

export const [
  REMOVE_EVENT_MESSAGE_IN_PROGRESS,
  REMOVE_EVENT_MESSAGE_SUCCESS,
  REMOVE_EVENT_MESSAGE_FAILURE
] = createActionTypeArray(REMOVE_EVENT_MESSAGE);

export const [
  SEND_CUSTOM_REMINDER_IN_PROGRESS,
  SEND_CUSTOM_REMINDER_SUCCESS,
  SEND_CUSTOM_REMINDER_FAILURE
] = createActionTypeArray(SEND_CUSTOM_REMINDER);

export const [
  GET_CALENDAR_ASSIGNEES_IN_PROGRESS,
  GET_CALENDAR_ASSIGNEES_SUCCESS,
  GET_CALENDAR_ASSIGNEES_FAILURE
] = createActionTypeArray(GET_CALENDAR_ASSIGNEES);
