import _ from 'lodash';
import { takeEvery, select, put, all, take } from 'redux-saga/effects';

import {
  getProcessedWidgetFormDataByType,
  getWidgetSchema,
  isWidgetObservingAnother,
  getLatestVersionWidget
} from '../../../../utils/widgets';

import {
  GET_PATIENT_THERAPY_LIST_SUCCESS,
  GET_PATIENT_THERAPY_LIST_TRIGGER,
  GET_PATIENT_THERAPY_UPDATED
} from '../../../therapy/therapyTypes';
import {
  ADD_WIDGET_TRIGGER,
  UPDATE_WIDGET_TRIGGER,
  ADD_WIDGET_UPDATED,
  UPDATE_WIDGET_UPDATED,
  REMOVE_WIDGET_UPDATED,
  GET_WIDGET_HISTORY_TRIGGER,
  GET_WIDGET_HISTORY_UPDATED
} from '../widgetTypes';

import { selectPatientDetailId } from '../../patientSelectors';
import {
  selectCurrentEncounter,
  selectWidgetByCurrentEncounter
} from '../widgetSelectors';

export function* recalculateObservers(action) {
  const widgetType = _.getNonEmpty(action, 'widget.widgetType');
  const widgetSchema = getWidgetSchema();
  const encounterId = yield select(selectCurrentEncounter);
  const widgetDefinitions = _.getNonEmpty(widgetSchema, `widgets`, []);

  const observingWidgetDefinitions = _.filter(
    widgetDefinitions,
    (widgetDefinition) => isWidgetObservingAnother(widgetDefinition, widgetType)
  );

  for (const widgetDefinition of observingWidgetDefinitions) {
    const widget = yield select((state) =>
      selectWidgetByCurrentEncounter(state, widgetDefinition.id)
    );

    if (!widget) {
      const newWidget = {
        widgetType: widgetDefinition.id
      };

      yield put({
        type: ADD_WIDGET_TRIGGER,
        encounterId,
        widget: newWidget,
        skipAddingOnErrors: true
      });

      continue;
    }

    yield put({ type: UPDATE_WIDGET_TRIGGER, encounterId, widget });
  }
}

export function* processWidgetData(widgetType, formData) {
  const state = yield select();

  return getProcessedWidgetFormDataByType(widgetType, formData, state);
}

export function* handleRefreshDataForWidgetHistory(refreshDataList) {
  const refreshHistoryRequests = _.filter(refreshDataList, {
    type: GET_WIDGET_HISTORY_TRIGGER
  });

  if (_.isEmptySafe(refreshHistoryRequests)) return;

  const patientId = yield select(selectPatientDetailId);

  const actionTriggers = [];

  for (const refreshRequest of refreshHistoryRequests) {
    const latestVersionWidget = getLatestVersionWidget(
      refreshRequest.widgetType
    );

    actionTriggers.push(
      put({
        type: GET_WIDGET_HISTORY_TRIGGER,
        patientId,
        widgetType: latestVersionWidget.id
      })
    );
  }

  yield all(actionTriggers);

  // Wait for all refreshes to finish before continuing
  for (let i = 0; i < refreshHistoryRequests.length; i++) {
    yield take(GET_WIDGET_HISTORY_UPDATED);
  }
}

export function* handleRefreshDataForTherapy(refreshDataList) {
  const therapyListRequest = _.find(refreshDataList, {
    type: GET_PATIENT_THERAPY_LIST_TRIGGER
  });

  if (_.isEmptySafe(therapyListRequest)) return;

  const patientId = yield select(selectPatientDetailId);

  yield put({
    type: GET_PATIENT_THERAPY_LIST_TRIGGER,
    patientId
  });

  // Wait for all therapy details to be refreshed
  const action = yield take(GET_PATIENT_THERAPY_LIST_SUCCESS);
  const patientTherapyList = _.getNonEmpty(action, 'data', []);

  for (let i = 0; i < patientTherapyList.length; i++) {
    yield take(GET_PATIENT_THERAPY_UPDATED);
  }
}

export const indexCalculationWidgetSagas = [
  takeEvery(
    [ADD_WIDGET_UPDATED, UPDATE_WIDGET_UPDATED, REMOVE_WIDGET_UPDATED],
    recalculateObservers
  )
];
