import PropTypes from 'prop-types';

import {
  MOSHI_COLOR_DANGER_NAME,
  MOSHI_COLOR_INFO_NAME,
  MOSHI_COLOR_PRIMARY_NAME,
  MOSHI_COLOR_PURPLE_NAME,
  MOSHI_COLOR_SECONDARY_NAME,
  MOSHI_COLOR_SUCCESS_NAME,
  MOSHI_COLOR_WARNING_NAME,
  MOSHI_COLOR_YELLOW_NAME
} from '../../utils/color';

export const DropdownButtonItemShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
  iconProps: PropTypes.shape(),
  className: PropTypes.string,
  isHidden: PropTypes.bool
};

const DropdownItemShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  key: PropTypes.string,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      nameKeyCode: PropTypes.string
    })
  )
};

export const DropdownItemWithKeyCodeShape = {
  id: [PropTypes.string, PropTypes.number],
  nameKeyCode: PropTypes.string
};

export const DropdownItemWithColorShape = {
  ...DropdownItemShape,
  color: PropTypes.oneOf([
    MOSHI_COLOR_PRIMARY_NAME,
    MOSHI_COLOR_SECONDARY_NAME,
    MOSHI_COLOR_INFO_NAME,
    MOSHI_COLOR_SUCCESS_NAME,
    MOSHI_COLOR_WARNING_NAME,
    MOSHI_COLOR_DANGER_NAME,
    MOSHI_COLOR_YELLOW_NAME,
    MOSHI_COLOR_PURPLE_NAME
  ])
};

export const DropdownItemsCommonShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    .isRequired,
  name: PropTypes.string.isRequired
};

export const MoshiItemFinderRowShape = {
  ...DropdownItemsCommonShape,
  disabled: PropTypes.bool
};

export const MoshiItemFinderGroupShape = {
  ...DropdownItemsCommonShape,
  subItems: PropTypes.arrayOf(PropTypes.shape(MoshiItemFinderRowShape))
    .isRequired
};

export default DropdownItemShape;
