import moment from 'moment';

import { translateKey } from '../../i18n';

export const getReminderPeriods = () => [
  {
    name: translateKey('settings:notifications.periods.oneHour'),
    id: '1h',
    hoursBefore: moment.duration(1, 'hours').asHours()
  },
  {
    name: translateKey('settings:notifications.periods.twelweHours'),
    id: '12h',
    hoursBefore: moment.duration(12, 'hours').asHours()
  },
  {
    name: translateKey('settings:notifications.periods.oneDay'),
    id: '24h',
    hoursBefore: moment.duration(1, 'days').asHours()
  },
  {
    name: translateKey('settings:notifications.periods.twoDays'),
    id: '48h',
    hoursBefore: moment.duration(2, 'days').asHours()
  },
  {
    name: translateKey('settings:notifications.periods.sevenDays'),
    id: '168h',
    hoursBefore: moment.duration(7, 'days').asHours()
  },
  {
    name: translateKey('settings:notifications.periods.fourteenDays'),
    id: '336h',
    hoursBefore: moment.duration(14, 'days').asHours()
  }
];
