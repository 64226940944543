import moment from 'moment';
import { call, race, select, take } from 'redux-saga/effects';

import { getCalendarWorkingHoursUrl } from '../../utils/api/apiUrlUtils';
import { isValidDate } from '../../utils/date';
import { pollGenerator } from '../../utils/redux/sagaUtils';

import { SET_CALENDAR_SELECTED_DATE_RANGE } from '../calendar/calendarTypes';
import {
  ADD_WORKING_HOURS_SUCCESS,
  DELETE_WORKING_HOURS_SUCCESS,
  GET_CALENDAR_WORKING_HOURS,
  START_CALENDAR_WORKING_HOURS_POLLING,
  STOP_CALENDAR_WORKING_HOURS_POLLING,
  UPDATE_WORKING_HOURS_SUCCESS
} from './workingHoursTypes';

import { UPDATE_SELECTED_LOCATION } from '../location/locationReducer';

import {
  selectCalendarFromDate,
  selectCalendarToDate
} from '../calendar/calendarSelectors';

export function* pollCalendarWorkingHoursSaga() {
  function* configGetter() {
    const state = yield select();
    const fromDate = selectCalendarFromDate(state);
    const toDate = selectCalendarToDate(state);

    if (isValidDate(fromDate) && isValidDate(toDate)) {
      const fromFormattedDate = moment(fromDate).format('YYYY-MM-DD');
      const toFormattedDate = moment(toDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

      const params = {
        from: fromFormattedDate,
        to: toFormattedDate
      };

      return {
        params
      };
    }
  }

  yield pollGenerator(GET_CALENDAR_WORKING_HOURS, getCalendarWorkingHoursUrl, {
    retriggerActions: [
      ADD_WORKING_HOURS_SUCCESS,
      SET_CALENDAR_SELECTED_DATE_RANGE,
      UPDATE_SELECTED_LOCATION,
      UPDATE_WORKING_HOURS_SUCCESS,
      DELETE_WORKING_HOURS_SUCCESS
    ],
    configGetter
  });
}

export function* workingHoursEventListSagaWatcher() {
  while (true) {
    yield take(START_CALENDAR_WORKING_HOURS_POLLING);
    yield take(SET_CALENDAR_SELECTED_DATE_RANGE);
    yield race([
      call(pollCalendarWorkingHoursSaga),
      take(STOP_CALENDAR_WORKING_HOURS_POLLING)
    ]);
  }
}
