import { createActionTypeArray } from '../../common/actionTypes';

export const GET_FORM_EXTERNAL_DETAIL = 'documents/GET_FORM_EXTERNAL_DETAIL';
export const UPDATE_FORM_EXTERNAL_DETAIL =
  'documents/export const UPDATE_FORM_EXTERNAL_DETAIL';
export const REMOVE_FORM_EXTERNAL_DETAIL =
  'documents/REMOVE_FORM_EXTERNAL_DETAIL';
export const CLEAR_FORM_EXTERNAL_DETAIL =
  'documents/CLEAR_FORM_EXTERNAL_DETAIL';

export const [
  GET_FORM_EXTERNAL_DETAIL_IN_PROGRESS,
  GET_FORM_EXTERNAL_DETAIL_SUCCESS,
  GET_FORM_EXTERNAL_DETAIL_FAILURE
] = createActionTypeArray(GET_FORM_EXTERNAL_DETAIL);

export const [
  REMOVE_FORM_EXTERNAL_DETAIL_IN_PROGRESS,
  REMOVE_FORM_EXTERNAL_DETAIL_SUCCESS,
  REMOVE_FORM_EXTERNAL_DETAIL_FAILURE
] = createActionTypeArray(REMOVE_FORM_EXTERNAL_DETAIL);

export const [
  UPDATE_FORM_EXTERNAL_DETAIL_IN_PROGRESS,
  UPDATE_FORM_EXTERNAL_DETAIL_SUCCESS,
  UPDATE_FORM_EXTERNAL_DETAIL_FAILURE
] = createActionTypeArray(UPDATE_FORM_EXTERNAL_DETAIL);
