/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { DropdownToggle } from 'reactstrap';

import PropTypes from 'prop-types';

const DropdownToggleWrapper = ({
  caret,
  disabled,
  color,
  size,
  className,
  children,
  ...props
}) => (
  <DropdownToggle
    caret={caret}
    disabled={disabled}
    color={color}
    size={size}
    className={className}
    {...props}
  >
    {children}
  </DropdownToggle>
);

DropdownToggleWrapper.propTypes = {
  caret: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

DropdownToggleWrapper.defaultProps = {
  caret: undefined,
  disabled: undefined,
  color: undefined,
  size: undefined,
  className: undefined,
  children: undefined
};

export default DropdownToggleWrapper;
