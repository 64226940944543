import { initReactI18next, withTranslation } from 'react-i18next';

import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment';

import { getNumbroDisplayValue } from './components/common/form/I18nNumericInput';
import {
  LOCALE_SL_SI,
  LOCALE_EN_US
} from './utils/constants/languageConstants';
import { isValidDate } from './utils/date';
import { getLanguage } from './utils/language';
import logger from './utils/logger';
import moshiConfig, { ENV_DEVELOPMENT } from './utils/moshiConfig';

import enUsTranslations from './locale/en-US';
import slSiTranslations from './locale/sl-SI';

const resources = {
  [LOCALE_EN_US]: enUsTranslations,
  [LOCALE_SL_SI]: slSiTranslations
};

const parseMissingKeyHandler = (key) => {
  if (_.isEmpty(moshiConfig.appEnv) || moshiConfig.appEnv === ENV_DEVELOPMENT) {
    const notFountText = `Translation ERROR: Key '${key}' not found.`;

    logger.error(notFountText);

    return notFountText;
  } else {
    return key;
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguage(),
  fallbackLng: LOCALE_EN_US,
  interpolation: {
    escapeValue: false,
    format(value, format) {
      if (format === 'uppercase') return _.toUpper(value);
      if (format === 'lowercase') return _.toLower(value);
      if (format === 'number') return getNumbroDisplayValue(value);
      if (isValidDate(value)) return moment(value).format(format);

      return value;
    }
  },
  parseMissingKeyHandler
});

export const localize = withTranslation();
export const localizeWithRef = withTranslation('translation', {
  withRef: true
});
export const translateKey = i18n.getFixedT();
export const doesTranslateKeyExist = (...params) => i18n.exists(...params);

export default i18n;
