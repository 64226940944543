import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './configureStore';

import App from './App';

const ReduxApp = () => (
  <Provider store={configureStore}>
    <App />
  </Provider>
);

export default ReduxApp;
