import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  showAlertSuccess,
  showMissingAssistRequestIdError,
  showMissingCommentIdError,
  showMissingEncounterIdError,
  showMissingLocationIdError,
  showMissingOrganizationIdError
} from '../../../utils/alert';
import { getEncounterUrl } from '../../../utils/api';
import {
  BUCKET_TYPE_COMPLETED,
  BUCKET_TYPE_NORMAL
} from '../../../utils/constants/encounterConstants';
import { isValidDate } from '../../../utils/date';

import { MOVE_WAITING_LIST_ITEM_TO } from '../../core/cache/cacheTypes';
import { CLEAR_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST } from '../../custom/rheumatology/rheumatologyTypes';
import {
  GET_ENCOUNTERS,
  MOVE_WAITING_LIST_ITEM,
  GET_ENCOUNTER_COMMENTS,
  REMOVE_ENCOUNTER,
  UPDATE_ENCOUNTER,
  CREATE_ENCOUNTER,
  CREATE_ENCOUNTER_COMMENT,
  GET_ENCOUNTER,
  GET_PAST_ENCOUNTERS,
  UPDATE_WAITING_LIST_FILTER,
  ARCHIVE_ENCOUNTER,
  ARCHIVE_CLOSED_ENCOUNTERS,
  DISMISS_ASSIST_REQUEST,
  GET_WAITING_LIST_ENCOUNTER,
  UPDATE_ENCOUNTER_COMMENT,
  DELETE_ENCOUNTER_COMMENT,
  GET_ASSIST_REQUEST,
  CLEAR_WAITING_LIST_FILTER
} from './encounterTypes';

import {
  selectCachedOrganizationId,
  selectCachedWaitingListConfigBuckets,
  selectCachedWaitingListMoveAction
} from '../../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';

export const ENCOUNTER_STATUS_ACTIVE = 'active';
export const ENCOUNTER_STATUS_ARCHIVED = 'archived';
export const ENCOUNTER_STATUS_DELETED = 'deleted';

export const updateWaitingListFilter = (filterName, filterValue) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_WAITING_LIST_FILTER,
    filterName,
    filterValue
  });
};

export const localMoveEncounterTo = (
  encounterId,
  fromBucketId,
  fromIndex,
  toBucketId,
  toIndex
) => (dispatch) => {
  // TODO: Figure out the usage of this or remove it
  if (!_.isFinite(fromIndex) || !_.isFinite(toIndex) || _.isEmpty(toBucketId)) {
    return { fromBucketId };
  }

  return dispatch({
    type: MOVE_WAITING_LIST_ITEM_TO,
    fromBucketId,
    fromIndex,
    toBucketId,
    toIndex
  });
};

const getMoveEncounterApiEndpoint = (fromBucketId, toBucketId) => (
  dispatch,
  getState
) => {
  const waitListBuckets = selectCachedWaitingListConfigBuckets(getState());

  const fromBucket = _.find(waitListBuckets, { id: fromBucketId });
  const fromBucketType = _.get(fromBucket, 'type', BUCKET_TYPE_NORMAL);

  if (fromBucket.id === toBucketId) {
    return 'move';
  } else if (fromBucketType === BUCKET_TYPE_COMPLETED) {
    return 'reopen';
  } else {
    return 'move';
  }
};

export const moveEncounterTo = (...params) => (dispatch, getState) => {
  const [
    encounterId,
    fromBucketId,
    fromIndex,
    toBucketId,
    toIndex,
    moveLocally = true,
    customPayload = {}
  ] = params;
  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());
  const apiEndpoint = dispatch(
    getMoveEncounterApiEndpoint(fromBucketId, toBucketId)
  );

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  if (moveLocally) {
    dispatch(
      localMoveEncounterTo(
        encounterId,
        fromBucketId,
        fromIndex,
        toBucketId,
        toIndex
      )
    );
  }

  const payload = _.isEmptySafe(customPayload)
    ? selectCachedWaitingListMoveAction(getState())
    : customPayload;

  return dispatch({
    type: MOVE_WAITING_LIST_ITEM,
    apiCall: axios.post(
      `/organizations/${organizationId}/locations/${locationId}/waitlist/${encounterId}/${apiEndpoint}`,
      payload
    )
  });
};

export const closeEncounter = (movePayload, closeData, wasMoved = true) => (
  dispatch,
  getState
) => {
  const [encounterId, fromBucketId] = movePayload;
  let payload = {};

  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  if (wasMoved) {
    payload = selectCachedWaitingListMoveAction(getState());
  }

  return dispatch({
    type: MOVE_WAITING_LIST_ITEM,
    apiCall: axios.post(
      `/organizations/${organizationId}/locations/${locationId}/waitlist/${encounterId}/close`,
      { fromBucketID: fromBucketId, ...payload, ...closeData }
    )
  });
};

export const archiveClosedEncounters = () => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: ARCHIVE_CLOSED_ENCOUNTERS,
    apiCall: axios.post(
      `/organizations/${organizationId}/locations/${locationId}/waitlist/archive`
    )
  });
};

export const archiveEncounter = (encounterId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  const locationId = selectCurrentLocationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  return dispatch({
    type: ARCHIVE_ENCOUNTER,
    apiCall: axios.post(
      `/organizations/${organizationId}/locations/${locationId}/waitlist/${encounterId}/archive`
    )
  });
};

export const getEncounters = (query = {}, actionType = GET_ENCOUNTERS) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/encounters`, {
      params: query
    })
  });
};

export const getPastEncounters = (
  query = {},
  actionType = GET_PAST_ENCOUNTERS
) => (dispatch) => {
  dispatch(getEncounters({ ...query, completed: true }, actionType));
};

export const getActiveEncounters = (query = {}) => (dispatch) =>
  dispatch(getEncounters({ ...query, completed: false }));

export const getEncounter = (encounterId, actionType = GET_ENCOUNTER) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/encounters/${encounterId}`
    )
  });
};

export const getWaitingListEncounter = (
  encounterId,
  actionType = GET_WAITING_LIST_ENCOUNTER
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/locations/${locationId}/waitlist/${encounterId}`
    )
  });
};

export const getAssistRequest = (requestId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: GET_ASSIST_REQUEST,
    apiCall: axios.get(
      `/organizations/${organizationId}/locations/${locationId}/assistRequests/${requestId}`
    )
  });
};

export const createVisitor = (encounter) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  return dispatch({
    type: CREATE_ENCOUNTER,
    apiCall: axios.post(
      `/organizations/${organizationId}/locations/${locationId}/waitlist`,
      encounter
    )
  });
};

export const updateEncounter = (encounterId, encounter) => (
  dispatch,
  getState
) => {
  const encounterUrl = getEncounterUrl(getState, encounterId);

  // Api expects the date format to be ISO string
  if (isValidDate(_.getNonEmpty(encounter, 'startedAt'))) {
    _.set(encounter, 'startedAt', moment(encounter.startedAt).toISOString());
  }

  return dispatch({
    type: UPDATE_ENCOUNTER,
    apiCall: axios.put(encounterUrl, encounter)
  });
};

export const removeEncounter = (encounterId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  return dispatch({
    type: REMOVE_ENCOUNTER,
    apiCall: axios.delete(
      `/organizations/${organizationId}/encounters/${encounterId}`
    )
  });
};

export const removeWaitingListEncounter = (encounterId, locationId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());
  const fallbackLocationId = selectCurrentLocationId(getState()) || null;
  const safeLocationId = _.isEmpty(locationId)
    ? fallbackLocationId
    : locationId;

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(safeLocationId)) {
    return showMissingLocationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  return dispatch({
    type: REMOVE_ENCOUNTER,
    apiCall: axios.delete(
      `/organizations/${organizationId}/locations/${safeLocationId}/waitlist/${encounterId}`
    )
  });
};

// TODO: Add test coverage
export const getEncounterComments = (encounterId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  return dispatch({
    type: GET_ENCOUNTER_COMMENTS,
    apiCall: axios.get(
      `/organizations/${organizationId}/encounters/${encounterId}/comments`
    ),
    payload: {
      encounterId
    }
  });
};

// TODO: Add test coverage
export const createEncounterComment = (
  locationId,
  encounterId,
  commentBody
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  const data = {
    locationID: locationId,
    comment: commentBody
  };

  return dispatch({
    type: CREATE_ENCOUNTER_COMMENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/encounters/${encounterId}/comments`,
      data
    )
  });
};

export const updateEncounterComment = (encounterId, commentId, commentBody) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  if (_.isEmpty(commentId)) {
    return showMissingCommentIdError();
  }

  const data = {
    comment: commentBody
  };

  return dispatch({
    type: UPDATE_ENCOUNTER_COMMENT,
    apiCall: axios.put(
      `/organizations/${organizationId}/encounters/${encounterId}/comments/${commentId}`,
      data
    ),
    onSuccess: () => {
      showAlertSuccess('encounter:waitingList.alert.comment.updated');
    }
  });
};

export const deleteEncounterComment = (encounterId, commentId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(encounterId)) {
    return showMissingEncounterIdError();
  }

  if (_.isEmpty(commentId)) {
    return showMissingCommentIdError();
  }

  return dispatch({
    type: DELETE_ENCOUNTER_COMMENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/encounters/${encounterId}/comments/${commentId}`
    ),
    onSuccess: () => {
      showAlertSuccess('encounter:waitingList.alert.comment.deleted');
    }
  });
};

export const dismissAssistRequest = (requestId) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());
  const locationId = selectCurrentLocationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  if (_.isEmpty(locationId)) {
    return showMissingLocationIdError();
  }

  if (_.isEmpty(requestId)) {
    return showMissingAssistRequestIdError();
  }

  return dispatch({
    type: DISMISS_ASSIST_REQUEST,
    apiCall: axios.put(
      `/organizations/${organizationId}/locations/${locationId}/assistRequests/${requestId}/dismiss`,
      {}
    )
  });
};

export const clearWaitListFilter = () => (dispatch) =>
  dispatch({ type: CLEAR_WAITING_LIST_FILTER });

export const clearPatientTreatmentPastEncounterList = () => (dispatch) =>
  dispatch({ type: CLEAR_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST });
