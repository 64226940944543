import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getSafeTranslation } from '../../../../utils/language';

import { Card, Col, Row } from '../../../reactstrap';
import Icon from '../../Icon';
import Visible from '../../layout/Visible';
import ToggleSwitch from '../checkbox/ToggleSwitch';

const CustomToggleWidget = ({ schema, value, onChange, disabled, options }) => {
  const handleOnChange = (e) => {
    const checked = e.target.checked;

    onChange(checked);
  };
  const safeIcon = _.getNonEmpty(options, 'iconName', 'envelope-alt');
  const hideIcon = _.getNonEmpty(options, 'hideIcon', false);
  const title = _.getNonEmpty(schema, 'title');
  const label = _.getNonEmpty(schema, 'label', title);

  return (
    <Card className={classNames('notification-card')}>
      <Row>
        <Col xs={10}>
          <Visible visible={!hideIcon}>
            <Icon name={safeIcon} size={'md'} className={'margin-aside-5'} />
          </Visible>
          <span className={'font-weight-bold'}>
            {getSafeTranslation(label)}
          </span>
        </Col>
        <Col xs={2}>
          <ToggleSwitch
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
          />
        </Col>
      </Row>
    </Card>
  );
};

CustomToggleWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.bool,
  schema: PropTypes.shape(),
  options: PropTypes.shape()
};

CustomToggleWidget.defaultProps = {
  value: null,
  schema: {},
  options: {}
};

export default CustomToggleWidget;
