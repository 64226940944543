import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectEventLogStore = (state) => state.eventLog;

export const selectEventLogList = createSelector(
  [selectEventLogStore],
  (eventLogStore) => _.getNonEmpty(eventLogStore, 'list', [])
);

export const selectEventLogListPagination = createSelector(
  [selectEventLogStore],
  (eventLogStore) => _.getNonEmpty(eventLogStore, 'pagination', {})
);

export const selectEventLogFilters = createSelector(
  [selectEventLogStore],
  (eventLogStore) => _.getNonEmpty(eventLogStore, 'filter', {})
);

export const selectNewEventLogIdList = createSelector(
  [selectEventLogStore],
  (eventLogStore) => _.getNonEmpty(eventLogStore, 'newEventLogIdList', [])
);

export const selectHasAnyNewEventLogs = createSelector(
  [selectNewEventLogIdList],
  (newEventLogIdList) => !_.isEmptySafe(newEventLogIdList)
);

export const selectEventLogLastListFetch = createSelector(
  [selectEventLogStore],
  (eventLogStore) =>
    _.getNonEmpty(eventLogStore, 'lastListFetch', new Date().toISOString())
);
