import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import I18nNumericInput from '../I18nNumericInput';

const CustomI18nNumericWidget = ({
  value,
  disabled,
  onChange,
  id,
  label,
  schema
}) => {
  const inputId = _.kebabCase(`${id}${label}`);
  const maxLength = _.get(schema, 'maximum');

  return (
    <I18nNumericInput
      id={inputId}
      value={value}
      disabled={disabled}
      onChange={(newValue) => {
        onChange(newValue);
        /*
         * if input has maxLength specified and there is more than one
         * input (like in bloodPressure), we want to automatically focus on
         * the next input when the max length is entered.
         */
        if (
          _.isNumber(maxLength) &&
          !_.isUndefined(newValue) &&
          newValue >= maxLength
        ) {
          const inputElement = document.getElementById(inputId);
          const siblingInput =
            _.isElement(inputElement) && inputElement.nextSibling;

          if (_.isElement(siblingInput) && siblingInput.tagName === 'INPUT') {
            siblingInput.focus();
          }
        }
      }}
    />
  );
};

CustomI18nNumericWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  schema: PropTypes.shape().isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

CustomI18nNumericWidget.defaultProps = {
  value: null,
  disabled: false
};

export default CustomI18nNumericWidget;
