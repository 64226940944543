import LocalizedStringModel from '../LocalizedStringModel';
import BillingItemModel from './BillingItemModel';

class BillingItemLocalizedModel extends BillingItemModel {
  constructor(id) {
    super(id);

    this.name = new LocalizedStringModel();
    this.description = new LocalizedStringModel();
    this.unit = new LocalizedStringModel();
  }
}

export default BillingItemLocalizedModel;
