import { push, replace } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import logger from '../../../utils/logger';

import {
  settingsRoutes,
  patientRoutes,
  baseRoutes,
  communicationRoutes,
  communicationBaseSmsRoute,
  bookingRoutes
} from '../../../routes';

export const NAVIGATION_TYPE_PUSH = 'push';
export const NAVIGATION_TYPE_REPLACE = 'replace';

export const navigateToDefaultRoute = () => (dispatch) => dispatch(push('/'));

// PATIENT
export const navigatePatientDetail = (patientId) => (dispatch) => {
  const path = generatePath(patientRoutes.detail.root, {
    id: patientId
  });

  dispatch(push(path));
};

export const navigatePatientDetailAppointments = (patientId) => (dispatch) => {
  const path = generatePath(patientRoutes.detail.appointments, {
    id: patientId
  });

  dispatch(push(path));
};

// BILLING UNIT

export const navigateBillingUnitDetail = (
  billingUnitId,
  type = NAVIGATION_TYPE_PUSH
) => (dispatch) => {
  const path = generatePath(settingsRoutes.billing.unitDetail, {
    id: billingUnitId
  });

  if (type === NAVIGATION_TYPE_PUSH) {
    dispatch(push(path));
  } else if (type === NAVIGATION_TYPE_REPLACE) {
    dispatch(replace(path));
  } else {
    logger.error(`Unknown navigation type "${type}"`);
  }
};

export const navigateBillingUnitNew = () => (dispatch) => {
  const path = generatePath(settingsRoutes.billing.unitNew);

  dispatch(push(path));
};

export const navigateBillingCategoryDetail = (billingCategoryId) => (
  dispatch
) => {
  const path = generatePath(settingsRoutes.billing.categoryDetail, {
    id: billingCategoryId
  });

  dispatch(push(path));
};

export const navigateBillingCategoryNew = () => (dispatch) => {
  const path = generatePath(settingsRoutes.billing.categoryNew);

  dispatch(push(path));
};

export const navigateBillingPaymentNew = () => (dispatch) => {
  const path = generatePath(settingsRoutes.billing.paymentNew);

  dispatch(push(path));
};

export const navigateBillingPaymentList = () => (dispatch) => {
  const path = generatePath(settingsRoutes.billing.payment);

  dispatch(push(path));
};

// SETTINGS

export const navigateToTagSettings = () => (dispatch) => {
  dispatch(push(settingsRoutes.account.tags));
};

// BOOKING
export const navigateToBookingTemplateDetail = (bookingId) => (dispatch) => {
  const populatedPath = generatePath(bookingRoutes.bookingDetail, {
    bookingId
  });

  dispatch(push(populatedPath));
};

export const navigateToNewBookingTemplate = () => (dispatch) => {
  dispatch(push(bookingRoutes.newBooking));
};

export const navigateToBookingTemplateList = () => (dispatch) => {
  dispatch(push(bookingRoutes.bookingTemplateList));
};

export const navigateToBookingSchedule = () => (dispatch) => {
  dispatch(push(bookingRoutes.bookingSchedule));
};

// CALENDAR

export const navigateToCalendarDate = (date) => (dispatch) => {
  const path = generatePath(baseRoutes.calendar, {
    date
  });

  dispatch(push(path));
};

// COMMUNICATION

export const navigateToCommunicationAllList = () => (dispatch) => {
  dispatch(push(communicationRoutes.sms.all));
};

export const navigateToCommunicationSentList = () => (dispatch) => {
  dispatch(push(communicationRoutes.sms.sent));
};

export const navigateToCommunicationReceivedList = () => (dispatch) => {
  dispatch(push(communicationRoutes.sms.received));
};

export const navigateToCommunicationSentScheduled = () => (dispatch) => {
  dispatch(push(communicationRoutes.sms.scheduled));
};

export const navigateToSmsTypeList = (type) => (dispatch) => {
  const path = generatePath(communicationBaseSmsRoute, {
    type
  });

  dispatch(push(path));
};

// DOWNLOAD

export const navigateFileDownload = (fileUrl) => (dispatch) => {
  const path = `${generatePath(baseRoutes.download)}?file_url=${fileUrl}`;

  dispatch(push(path));
};
