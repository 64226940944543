import produce from 'immer';
import _ from 'lodash';

import { safeLocationAddress } from '../../utils/mappers/location-mappers';

import {
  UPDATE_ORGANIZATION_SUCCESS,
  GET_CURRENT_ORGANIZATION_WIDGETS_SUCCESS,
  GET_CURRENT_ORGANIZATION_WIDGETS_FAILURE,
  GET_CURRENT_ORGANIZATION_IN_PROGRESS,
  GET_CURRENT_ORGANIZATION_SUCCESS,
  GET_CURRENT_ORGANIZATION_FAILURE
} from './organizationTypes';

import { LOGOUT } from '../auth/authReducer';

import systemWidgets from '../../widgets';

const initialState = {
  currentOrganization: undefined,
  organizationWidgets: systemWidgets
};

// eslint-disable-next-line default-param-last
const organizationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...response } = action;

    switch (type) {
      case GET_CURRENT_ORGANIZATION_IN_PROGRESS:
        draft.loadingItem = true;
        break;
      case GET_CURRENT_ORGANIZATION_SUCCESS:
      case UPDATE_ORGANIZATION_SUCCESS:
        const currentOrganization = _.get(response, 'response.data', undefined);

        draft.loadingItem = false;
        draft.currentOrganization = safeLocationAddress(currentOrganization);
        break;
      case GET_CURRENT_ORGANIZATION_FAILURE:
        draft.loadingItem = false;
        draft.currentOrganization = undefined;
        break;
      case GET_CURRENT_ORGANIZATION_WIDGETS_SUCCESS:
        const organizationWidgets = _.getNonEmpty(
          response,
          'response.data',
          systemWidgets
        );
        const fallbackGroup = _.getAndClone(systemWidgets, 'groups', []);
        const groups = _.getAndClone(
          organizationWidgets,
          'groups',
          fallbackGroup
        );
        const fallbackExportSchema = _.getAndClone(
          systemWidgets,
          'exportSchema',
          {}
        );
        const exportSchema = _.getAndClone(
          organizationWidgets,
          'exportSchema',
          fallbackExportSchema
        );
        const orgWidgetsWithFallback = _.defaultsDeep(
          organizationWidgets,
          systemWidgets
        );

        orgWidgetsWithFallback.groups = groups;
        orgWidgetsWithFallback.exportSchema = exportSchema;

        orgWidgetsWithFallback.widgets = _.cloneDeep({
          ...systemWidgets.widgets,
          ...orgWidgetsWithFallback.widgets
        });

        draft.organizationWidgets = orgWidgetsWithFallback;
        break;
      case GET_CURRENT_ORGANIZATION_WIDGETS_FAILURE:
        draft.organizationWidgets = systemWidgets;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default organizationReducer;
