import { generatePath } from 'react-router-dom';

import _ from 'lodash';

import {
  EXTERNAL,
  INBOUND_REQUEST,
  INBOUND_SUBMISSION,
  INBOUND_TEMPLATE,
  OUTBOUND_DOCUMENT,
  OUTBOUND_TEMPLATE
} from '../../utils/constants/documentConstants';

export const documentBaseRoute = '/documents';
export const patientDocumentBaseRoute = '/patients/:patientId/documents';

export const baseListRoutes = {
  ALL: '/all',
  INBOUND: '/inbound',
  INBOUND_SUBMISSION: '/inbound/submitted',
  INBOUND_TEMPLATE: '/inbound/templates',
  INBOUND_REQUEST: '/inbound/requests',
  EXTERNAL: '/external',
  OUTBOUND: '/outbound',
  TEMPLATE: '/templates'
};

const patientDetailRoutes = {
  ALL: {
    INBOUND_SUBMISSION_DETAIL:
      '/inbound/submitted/:formId/:version/:submissionId',
    INBOUND_REQUEST_DETAIL: '/inbound/templates/:formId/:version/:requestId',
    EXTERNAL_DETAIL: '/external/:id',
    EXTERNAL_DETAIL_EDIT: '/external/:id/edit'
  },
  TEMPLATE: {
    INBOUND_TEMPLATE_NEW: '/inbound/new',
    INBOUND_TEMPLATE_DETAIL: '/inbound/:formId'
  },
  OUTBOUND: {
    OUTBOUND_NEW: '/new',
    OUTBOUND_DETAIL: '/:id',
    OUTBOUND_DETAIL_EDIT: '/:id/edit'
  }
};

const documentDetailRoutes = {
  ALL: {
    INBOUND_SUBMISSION_DETAIL:
      '/inbound/submitted/:patientId/:formId/:version/:submissionId',
    INBOUND_REQUEST_DETAIL:
      '/inbound/request/:patientId/:formId/:version/:requestId',
    EXTERNAL_DETAIL: '/external/:patientId/:id',
    EXTERNAL_DETAIL_EDIT: '/external/:patientId/:id/edit'
  },
  TEMPLATE: {
    INBOUND_TEMPLATE_NEW: '/inbound/new',
    INBOUND_TEMPLATE_DETAIL: '/inbound/:formId'
  },
  OUTBOUND: {
    OUTBOUND_NEW: '/new',
    OUTBOUND_DETAIL: '/:id',
    OUTBOUND_DETAIL_EDIT: '/:id/edit'
  }
};

const getListRoutes = (basePath) =>
  _.mapValues(baseListRoutes, (listRoute) => `${basePath}${listRoute}`);

const getDetailRoutes = (baseDetailRoutes, basePath) => {
  const detailRoutes = {};

  _.forEach(baseDetailRoutes, (listDetailRoutes, listRouteName) => {
    _.forEach(listDetailRoutes, (detailRoute, detailRouteName) => {
      detailRoutes[
        detailRouteName
      ] = `${basePath}${baseListRoutes[listRouteName]}${detailRoute}`;
    });
  });

  return detailRoutes;
};

export const documentRoutes = {
  ...getListRoutes(documentBaseRoute),
  ...getDetailRoutes(documentDetailRoutes, documentBaseRoute)
};

export const patientDocumentRoutes = {
  ...getListRoutes(patientDocumentBaseRoute),
  ...getDetailRoutes(patientDetailRoutes, patientDocumentBaseRoute)
};

export const allDocumentRoutes = {
  [documentBaseRoute]: documentRoutes,
  [patientDocumentBaseRoute]: patientDocumentRoutes
};

export const outboundDocumentRoutes = {
  OUTBOUND_BASE: baseListRoutes.OUTBOUND,
  OUTBOUND_DETAIL: `${baseListRoutes.OUTBOUND}/:id`,
  OUTBOUND_NEW: `${baseListRoutes.OUTBOUND}/new`,
  OUTBOUND_EDIT: `${baseListRoutes.OUTBOUND}/:id/edit`,
  OUTBOUND_NEW_FROM_TEMPLATE: `${baseListRoutes.OUTBOUND}/new/:id`
};

export const outboundDocumentDetailRoutes = {
  OUTBOUND_BASE: `/documents${baseListRoutes.OUTBOUND}`,
  OUTBOUND_DETAIL: `/documents${outboundDocumentRoutes.OUTBOUND_DETAIL}`,
  OUTBOUND_NEW: `/documents${outboundDocumentRoutes.OUTBOUND_NEW}`,
  OUTBOUND_EDIT: `/documents${outboundDocumentRoutes.OUTBOUND_EDIT}`,
  OUTBOUND_NEW_FROM_TEMPLATE: `/documents${outboundDocumentRoutes.OUTBOUND_NEW_FROM_TEMPLATE}`
};

export const patientOutboundDocumentRoutes = {
  OUTBOUND_BASE: `${patientDocumentBaseRoute}${baseListRoutes.OUTBOUND}`,
  OUTBOUND_DETAIL: `${patientDocumentBaseRoute}${outboundDocumentRoutes.OUTBOUND_DETAIL}`,
  OUTBOUND_NEW: `${patientDocumentBaseRoute}${outboundDocumentRoutes.OUTBOUND_NEW}`,
  OUTBOUND_EDIT: `${patientDocumentBaseRoute}${outboundDocumentRoutes.OUTBOUND_EDIT}`,
  OUTBOUND_NEW_FROM_TEMPLATE: `${patientDocumentBaseRoute}${outboundDocumentRoutes.OUTBOUND_NEW_FROM_TEMPLATE}`
};

export const getRoutes = (isPatientPath) => {
  const baseRoute = isPatientPath
    ? patientDocumentBaseRoute
    : documentBaseRoute;

  return _.getNonEmpty(allDocumentRoutes, baseRoute);
};

const getInboundSubmissionPath = (routes, patientId, item) => {
  const submissionFormId = _.getNonEmpty(item, 'formID');
  const submissionVersion = _.getNonEmpty(item, 'formVersion');
  const submissionId = _.getNonEmpty(item, 'id');

  if (
    _.isEmptySafe(submissionFormId) ||
    _.isEmptySafe(submissionVersion) ||
    _.isEmptySafe(submissionId)
  ) {
    return '/';
  }

  const path = routes.INBOUND_SUBMISSION_DETAIL;

  return generatePath(path, {
    formId: submissionFormId,
    version: submissionVersion,
    submissionId,
    patientId
  });
};

const getInboundRequestPath = (routes, patientId, item) => {
  const requestFormId = _.getNonEmpty(item, 'formID');
  const requestFormVersion = _.getNonEmpty(item, 'formVersion');
  const requestId = _.getNonEmpty(item, 'id');

  if (
    _.isEmptySafe(requestFormId) ||
    _.isEmptySafe(requestFormVersion) ||
    _.isEmptySafe(requestId)
  ) {
    return '/';
  }

  const path = routes.INBOUND_REQUEST_DETAIL;

  return generatePath(path, {
    formId: requestFormId,
    version: requestFormVersion,
    requestId,
    patientId
  });
};

const getInboundTemplatePath = (routes, patientId, documentId) => {
  const path = routes.INBOUND_TEMPLATE_DETAIL;

  return generatePath(path, {
    formId: documentId,
    patientId
  });
};

const getOutboundDocumentPath = (routes, patientId, documentId) => {
  const path = routes.OUTBOUND_DETAIL;

  return generatePath(path, {
    id: documentId,
    patientId
  });
};

const getOutboundTemplatePath = (routes, patientId, item) => {
  const outboundDocumentTemplateId = _.getNonEmpty(item, 'id');

  const path = routes.OUTBOUND_DETAIL_EDIT;

  return generatePath(path, {
    id: outboundDocumentTemplateId,
    patientId
  });
};

const getExternalPath = (routes, patientId, item) => {
  const externalId = _.getNonEmpty(item, 'id');

  const path = routes.EXTERNAL_DETAIL;

  return generatePath(path, { id: externalId, patientId });
};

export const getDocumentRouteBasedOnType = (item, isPatientPath) => {
  const routes = getRoutes(isPatientPath);
  const patientId = _.getNonEmpty(item, 'patientID');
  const type = _.getNonEmpty(item, 'type');
  const documentId = _.getNonEmpty(item, 'id');

  switch (type) {
    case INBOUND_SUBMISSION:
      return getInboundSubmissionPath(routes, patientId, item);
    case INBOUND_REQUEST:
      return getInboundRequestPath(routes, patientId, item);
    case INBOUND_TEMPLATE:
      return getInboundTemplatePath(routes, patientId, documentId);
    case OUTBOUND_DOCUMENT:
      return getOutboundDocumentPath(routes, patientId, documentId);
    case OUTBOUND_TEMPLATE:
      return getOutboundTemplatePath(routes, patientId, item);
    case EXTERNAL:
      return getExternalPath(routes, patientId, item);
    default:
      return '';
  }
};
