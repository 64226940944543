import { createActionTypeArray } from '../../common/actionTypes';

export const GET_DENTAL_CHART_LIST = 'dentalChart/GET_DENTAL_CHART_LIST';
export const SET_DENTAL_CHART_LIST_PAGINATION =
  'dentalChart/SET_DENTAL_CHART_LIST_PAGINATION';
export const CLEAR_DENTAL_CHART_LIST = 'dentalChart/CLEAR_DENTAL_CHART_LIST';
export const CLEAR_DENTAL_CHART_DETAIL =
  'dentalChart/CLEAR_DENTAL_CHART_DETAIL';
export const GET_DENTAL_CHART_DETAIL = 'dentalChart/GET_DENTAL_CHART_DETAIL';
export const FILTER_DENTAL_CHART_LIST = 'dentalChart/FILTER_DENTAL_CHART_FILES';
export const SET_DENTAL_CHART_ORDER = 'dentalChart/SET_DENTAL_CHART_ORDER';
export const CLEAR_DENTAL_CHART_TEETH_FILTER =
  'dentalChart/CLEAR_DENTAL_CHART_TEETH_FILTER';
export const CLEAR_DENTAL_CHART_FILTER =
  'dentalChart/CLEAR_DENTAL_CHART_FILTER';
export const RESET_DENTAL_CHART_PAGINATION =
  'dentalChart/RESET_DENTAL_CHART_PAGINATION';
export const REMOVE_DENTAL_CHART = 'dentalChart/REMOVE_DENTAL_CHART';

export const GET_DENTAL_CHART_SUMMARY = 'dentalChart/GET_DENTAL_CHART_SUMMARY';
export const UPDATE_DENTAL_CHART_SUMMARY =
  'dentalChart/UPDATE_DENTAL_CHART_SUMMARY';

export const [
  GET_DENTAL_CHART_LIST_IN_PROGRESS,
  GET_DENTAL_CHART_LIST_SUCCESS,
  GET_DENTAL_CHART_LIST_FAILURE
] = createActionTypeArray(GET_DENTAL_CHART_LIST);

export const [
  GET_DENTAL_CHART_DETAIL_IN_PROGRESS,
  GET_DENTAL_CHART_DETAIL_SUCCESS,
  GET_DENTAL_CHART_DETAIL_FAILURE
] = createActionTypeArray(GET_DENTAL_CHART_DETAIL);

export const [
  REMOVE_DENTAL_CHART_IN_PROGRESS,
  REMOVE_DENTAL_CHART_SUCCESS,
  REMOVE_DENTAL_CHART_FAILURE
] = createActionTypeArray(REMOVE_DENTAL_CHART);

export const [
  GET_DENTAL_CHART_SUMMARY_IN_PROGRESS,
  GET_DENTAL_CHART_SUMMARY_SUCCESS,
  GET_DENTAL_CHART_SUMMARY_FAILURE
] = createActionTypeArray(GET_DENTAL_CHART_SUMMARY);

export const [
  UPDATE_DENTAL_CHART_SUMMARY_IN_PROGRESS,
  UPDATE_DENTAL_CHART_SUMMARY_SUCCESS,
  UPDATE_DENTAL_CHART_SUMMARY_FAILURE
] = createActionTypeArray(UPDATE_DENTAL_CHART_SUMMARY);
