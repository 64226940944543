import { formatting } from './date';

export default {
  title: 'Visitors',
  queue: 'Waiting Room',
  upNext: 'Up Next',
  consultation: 'Encounter',
  completed: 'Completed',
  addComment: 'Add comment',
  commentTitle: `{{ user.firstName }} {{ user.lastName }} ∙ {{ date, ${formatting.longDateWithTime} }}`,
  commentTitleToday: `{{ user.firstName }} {{ user.lastName }} ∙ {{ date, ${formatting.time} }}`,
  bucket: {
    default: 'List of patients in the Waiting Room',
    normal: 'List of patients to be next in the Encounter',
    consultation: 'List of patients in the Encounter',
    completed: 'List of patients with the completed Encounter'
  },
  dropEncounterPlaceholder: 'Drop here',
  bookNewAppointment: 'Book',
  bookNewAppointmentMessage: 'Book new appointment',
  addVisitor: 'Add visitor',
  archiveAllEncounters: 'Archive all',
  archiveEncounter: 'Archive',
  removeEncounterTitle: 'Remove from the visitors dashboard',
  removeEncounterMessage:
    'Do you really want to remove the encounter from the visitors dashboard?',
  confirmStartEncounterTitle: 'Start encounter',
  confirmStartEncounterMessage:
    'Do you really want to start the encounter for this visitor?',
  confirmArchiveClosedEncountersTitle: 'Archive encounters',
  confirmArchiveClosedEncountersMessage:
    'Do you really want to archive all the closed encounters?',
  confirmArchiveEncounterTitle: 'Archive encounter',
  confirmArchiveEncounterMessage:
    'Do you really want to archive the encounter for this visitor?',
  filterDoctors: 'Doctors',
  upcomingEvents: 'Upcoming events',
  pastEvents: 'Past events',
  appointment: 'Appointment: ',
  assistanceRequired: 'Check In assistance required',
  toWaitingRoom: 'To waiting room',
  didNotShow: 'Did not show',
  backToUpcoming: 'Back to "$t(dashboard:upcomingEvents)"',
  checkinAt: `Check-in: {{ time, ${formatting.time} }}`,
  checkinAtDate: `Check-in: {{ time, ${formatting.shortDateWithTime} }}`,
  startedAt: `Started: {{ time, ${formatting.time} }}`,
  appointmentAt: `Appointment: {{ timeFrom, ${formatting.time} }} - {{ timeTo, ${formatting.time} }}`,
  appointmentAtDate: `Appointment: {{ timeFrom, ${formatting.time} }} - {{ timeTo, ${formatting.time} }}`,
  completedAt: `Completed: {{ time, ${formatting.time} }}`,
  completedAtDate: `Completed: {{ time, ${formatting.shortDateWithTime} }}`,
  resolve: 'Resolve',
  newAppointment: 'New appointment',
  appointmentAlreadyBooked: 'Follow up appointment booked'
};
