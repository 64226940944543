import _ from 'lodash';

import { jsonParse } from './jwt';
// TODO: Investigate! Somehow tests fail if this is not here
import './lodashMixins';

export const getLocalStorage = () => localStorage;

export const setLocalStorageValue = (key, value) => {
  let valueToStore = value;

  if (_.isObject(value)) {
    valueToStore = JSON.stringify(value);
  }

  localStorage.setItem(key, valueToStore);
};

export const removeLocalStorageValue = (key) => {
  localStorage.removeItem(key);
};

export const getLocalStorageValue = (key, fallbackValue = null) => {
  const rawValue = localStorage.getItem(key);

  if (_.isNull(rawValue)) {
    return fallbackValue;
  }

  const value = jsonParse(rawValue);

  return _.isEmptySafe(value) ? fallbackValue : value;
};
