import _ from 'lodash';
import moment from 'moment';

import { BILLING_FILE_TYPE_CASH_REGISTER_LOG } from '../../../utils/billing';
import { EMPTY_GUID } from '../../../utils/gen';
import {
  getCashRegisterPaymentSummary,
  getCashRegisterTaxSummary,
  getCashRegisterDocumentSummary,
  getCashRegisterRevenueSummary
} from '../../../utils/mappers/cleaned/cashRegisterMappers';

class CashRegisterLogModel {
  constructor() {
    this.logVersion = '3';
    this.type = BILLING_FILE_TYPE_CASH_REGISTER_LOG;
    this.logNumber = 1;
    this.logId = '';
    this.logIdCounter = '';
    this.billingUnitID = '';
    this.companyName = '';
    this.companyAddress = {};
    this.taxNumber = '';
    this.businessPremiseID = '';
    this.businessPremiseLabel = '';
    this.electronicDeviceID = '';
    this.electronicDeviceLabel = '';
    this.date = moment().format('YYYY-MM-DD');
    this.bills = [];
    this.currencyId = '';
    this.taxSummary = {};
    this.documentSummary = {
      first: '',
      last: '',
      count: 0
    };
    this.paymentSummary = {};
    this.employee = {
      firstName: '',
      lastName: '',
      id: EMPTY_GUID
    };
  }

  setPreferredDataInput(billingUnitList, preferredBillingData) {
    let billingUnitID = _.get(preferredBillingData, 'billingUnitID', null);

    if (_.hasOneItem(billingUnitList)) {
      billingUnitID = _.first(billingUnitList).id;
    }

    const businessPremiseID = _.get(preferredBillingData, 'businessPremiseID');
    const electronicDeviceID = _.get(
      preferredBillingData,
      'electronicDeviceID'
    );

    const billingUnit = _.find(billingUnitList, {
      id: billingUnitID
    });

    if (_.isEmptySafe(billingUnit, 'id')) {
      billingUnitID = this.billingUnitID;
    }

    this.billingUnitID = billingUnitID;

    if (_.isEmpty(businessPremiseID) && _.isEmpty(electronicDeviceID)) {
      return billingUnit;
    }

    this.businessPremiseID = businessPremiseID;
    this.electronicDeviceID = electronicDeviceID;

    return billingUnit;
  }

  get logMeta() {
    const cashPaymentsSum = _.getNonEmpty(this.paymentSummary, 'cash.sum', 0);
    const allPaymentsSum = _.reduce(
      this.paymentSummary,
      (totalSum, paymentType) =>
        totalSum + _.getNonEmpty(paymentType, 'sum', 0),
      0
    );

    const totalRevenue = _.reduce(
      this.bills,
      (totalSum, bill) => totalSum + _.getNonEmpty(bill, 'total', 0),
      0
    );

    const lastDocumentAt = _.reduce(
      this.bills,
      (lastIssuedDate, bill) => {
        const billIssuedAt = _.getNonEmpty(bill, 'issuedAt', '');

        return lastIssuedDate > billIssuedAt ? lastIssuedDate : billIssuedAt;
      },
      ''
    );

    const lastPaymentAt = _.reduce(
      this.bills,
      (latestPaymentAt, bill) => {
        const billPayments = _.getNonEmpty(bill, 'payments', []);
        const latestBillPaymentAt = _.reduce(
          billPayments,
          (latestBillPaymentCreatedAt, payment) => {
            const paymentCreatedAt = _.getNonEmpty(payment, 'createdAt', '');

            return paymentCreatedAt > latestBillPaymentCreatedAt
              ? paymentCreatedAt
              : latestBillPaymentCreatedAt;
          },
          ''
        );

        return latestBillPaymentAt > latestPaymentAt
          ? latestBillPaymentAt
          : latestPaymentAt;
      },
      ''
    );

    return {
      billingUnitID: this.billingUnitID,
      businessPremiseID: this.businessPremiseID,
      businessPremiseLabel: this.businessPremiseLabel,
      currencyId: this.currencyId,
      date: this.date,
      documentCount: _.toString(this.documentSummary.count),
      electronicDeviceID: this.electronicDeviceID,
      electronicDeviceLabel: this.electronicDeviceLabel,
      employeeId: this.employee.id,
      employeeLabel: `${this.employee.firstName} ${this.employee.lastName}`,
      lastDocumentAt,
      lastPaymentAt,
      logId: this.logId,
      logNumber: _.toString(this.logNumber),
      logVersion: this.logVersion,
      totalAll: _.toString(allPaymentsSum),
      totalCash: _.toString(cashPaymentsSum),
      totalRevenue: _.toString(totalRevenue),
      logIdCounter: _.toString(this.logIdCounter),
      type: this.type
    };
  }

  setDate(date) {
    this.date = moment(date).format('YYYY-MM-DD');

    return this;
  }

  setSummaries() {
    const allBills = this.bills;
    const allPayments = _.reduce(
      allBills,
      (payments, invoice) => [...payments, ..._.get(invoice, 'payments', [])],
      []
    );
    const allItems = _.concatBy(allBills, 'items');

    this.paymentSummary = getCashRegisterPaymentSummary(allPayments);
    this.taxSummary = getCashRegisterTaxSummary(allItems);
    this.revenueSummary = getCashRegisterRevenueSummary(allBills);
    this.documentSummary = getCashRegisterDocumentSummary(allBills);

    return this;
  }

  resetSummaries() {
    this.taxSummary = {};
    this.documentSummary = {
      first: '',
      last: '',
      count: 0
    };
    this.paymentSummary = {
      cash: 0,
      card: 0,
      coupon: 0
    };
  }
}

export default CashRegisterLogModel;
