import { all } from 'redux-saga/effects';

import { advanceSagaWatcher } from './redux/billing/advance/advanceSagas';
import { billSagaWatcher } from './redux/billing/bill/billSagas';
import { creditNoteSagaWatcher } from './redux/billing/credit-note/creditNoteSagas';
import { estimateSagaWatcher } from './redux/billing/estimate/estimateSagas';
import { invoiceSagaWatcher } from './redux/billing/invoice/invoiceSagas';
import { bookingScheduleListSagaWatcher } from './redux/booking/bookingSagas';
import { closedDatesListSagaWatcher } from './redux/closed-dates/closedDatesSagas';
import {
  checkNewVersionSaga,
  checkServerStatus
} from './redux/core/app/appSagas';
import {
  anonymousPollingSagaWatcher,
  organizationSagaWatcher,
  pollingSagaWatcher
} from './redux/core/cache/cacheSagas';
import { routerHistorySagaWatcher } from './redux/core/routerHistory/routerHistorySagas';
import { webSocketPollingSagaWatcher } from './redux/core/web-socket/webSocketSagas';
import { watchPatientTreatmentPastEncountersSaga } from './redux/custom/rheumatology/rheumatologySagas';
import { eventLogSagaWatcher } from './redux/event-log/eventLogSagas';
import { formSagaWatcher } from './redux/form/formSagas';
import {
  ensureLocationIsSelectedSaga,
  userLocationSagaWatcher,
  watchForUpdatedLocationSaga
} from './redux/location/locationSagas';
import { widgetsSagaWatcher } from './redux/patient/widget/sagas';
import { fetchPatientTherapyListener } from './redux/patient/widget/sagas/widgetDependencyDataSagas';
import { therapySagaWatcher } from './redux/therapy/sagas';
import { userSagaWatcher } from './redux/user/userSagas';
import { workingHoursEventListSagaWatcher } from './redux/working-hours/workingHoursSagas';

export default function* rootSaga() {
  yield all([
    fetchPatientTherapyListener(),
    pollingSagaWatcher(),
    anonymousPollingSagaWatcher(),
    organizationSagaWatcher(),
    routerHistorySagaWatcher(),
    checkNewVersionSaga(),
    checkServerStatus(),
    invoiceSagaWatcher(),
    advanceSagaWatcher(),
    creditNoteSagaWatcher(),
    estimateSagaWatcher(),
    billSagaWatcher(),
    widgetsSagaWatcher(),
    userSagaWatcher(),
    therapySagaWatcher(),
    eventLogSagaWatcher(),
    watchPatientTreatmentPastEncountersSaga(),
    watchForUpdatedLocationSaga(),
    ensureLocationIsSelectedSaga(),
    userLocationSagaWatcher(),
    formSagaWatcher(),
    workingHoursEventListSagaWatcher(),
    closedDatesListSagaWatcher(),
    webSocketPollingSagaWatcher(),
    bookingScheduleListSagaWatcher()
  ]);
}
