import _ from 'lodash';

import { WIDGET_TYPE_QUESTIONNAIRE } from '../../../widgets/widgetConstants';

import questionnaire from '../../../widgets/questionnaire/questionnaire';

export const questionnaireWidgetMapper = (questionnaireItem) => {
  const questionnaireWidget = _.cloneDeep(questionnaireItem);
  const formSchema = _.cloneDeep(questionnaire.formSchema);
  const uiSchema = _.cloneDeep(questionnaire.uiSchema);

  questionnaireWidget.id = `${WIDGET_TYPE_QUESTIONNAIRE}/${questionnaireWidget.id}/${questionnaireWidget.version}`;
  questionnaireWidget.widgetType = questionnaireWidget.id;
  questionnaireWidget.data = {};
  questionnaireWidget.formSchema = {
    ...formSchema,
    title: questionnaireWidget.name
  };
  questionnaireWidget.uiSchema = uiSchema;

  return questionnaireWidget;
};

export const removeWidgetSubItemsMapper = (widgetGroup) => {
  const widgetGroupWithoutSubItems = _.cloneDeep(widgetGroup);

  delete widgetGroupWithoutSubItems.subItems;

  return widgetGroupWithoutSubItems;
};
