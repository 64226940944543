import _ from 'lodash';
import { createSelector } from 'reselect';

import { ROLE_ADMIN, ROLE_DOCTOR } from '../../utils/constants/userConstants';
import { selectProps } from '../../utils/redux/selectorHelpers';

import {
  selectIsUserSuperAdmin,
  selectUserIdFromToken
} from '../auth/authSelectors';
import { selectCachedDoctorIdListByCurrentLocation } from '../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../location/locationSelectors';

export const selectUserStore = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUserStore],
  (userStore) => _.getNonEmpty(userStore, 'currentUser', {})
);

export const selectUserList = createSelector([selectUserStore], (userStore) =>
  _.getNonEmpty(userStore, 'list', [])
);

export const selectUserListSortConfig = createSelector(
  [selectUserStore],
  (userStore) => _.getNonEmpty(userStore, 'filter', {})
);

// CURRENT USER SELECTROS
export const selectCurrentUserId = createSelector(
  [selectCurrentUser],
  (currentUser) => _.getNonEmpty(currentUser, 'id', null)
);

export const selectCurrentUserIdWithFallback = createSelector(
  [selectCurrentUserId, selectUserIdFromToken],
  (currentUserId, userIdFromToken) =>
    _.isEmptySafe(currentUserId) ? userIdFromToken : currentUserId
);

export const selectCurrentUserEmail = createSelector(
  [selectCurrentUser],
  (currentUser) => _.getNonEmpty(currentUser, 'email', null)
);

export const selectIsCurrentUser = createSelector(
  [selectCurrentUserId, selectProps],
  (currentUserId, userId) => _.isEqual(currentUserId, userId)
);

// PREFERRED DATA INPUT SELECTORS
export const selectPreferredBillingData = createSelector(
  [selectUserStore],
  (userStore) => _.getNonEmpty(userStore, 'preferredBillingData', {})
);

export const selectPreferredBillingUnitId = createSelector(
  [selectPreferredBillingData],
  (preferredInput) => _.getNonEmpty(preferredInput, 'billingUnitID')
);

export const selectPreferredElectronicDeviceId = createSelector(
  [selectPreferredBillingData],
  (preferredInput) => _.getNonEmpty(preferredInput, 'electronicDeviceID')
);

// USER ROLES
export const selectUserOrganizationRoles = createSelector(
  [selectCurrentUser],
  (currentUser) => _.getNonEmpty(currentUser, 'organizationRoles', [])
);

export const selectUserLocationRoles = createSelector(
  [selectCurrentUser],
  (currentUser) => _.getNonEmpty(currentUser, 'locationsRoles', [])
);

export const selectIsUserOrganizationAdmin = createSelector(
  [selectUserOrganizationRoles, selectIsUserSuperAdmin],
  (organizationRoles, isUserSuperAdmin) =>
    _.includes(organizationRoles, ROLE_ADMIN) || isUserSuperAdmin
);

export const selectIsUserLocationAdmin = createSelector(
  [selectUserLocationRoles, selectIsUserSuperAdmin],
  (locationRoles, isUserSuperAdmin) => {
    const roles = [];

    if (!_.isEmpty(locationRoles)) {
      locationRoles.forEach((location) =>
        location.roles.forEach((role) => roles.push(role))
      );
    }

    return _.includes(roles, ROLE_ADMIN) || isUserSuperAdmin;
  }
);

export const selectIsUserAdmin = createSelector(
  [
    selectIsUserLocationAdmin,
    selectIsUserOrganizationAdmin,
    selectIsUserSuperAdmin
  ],
  (isUserLocationAdmin, isUserOrganizationAdmin, isUserSuperAdmin) =>
    isUserLocationAdmin || isUserOrganizationAdmin || isUserSuperAdmin
);

export const selectIsUserDoctorOnLocation = createSelector(
  [selectUserLocationRoles, selectCurrentLocationId],
  (locationRoles, currentLocationId) => {
    const currentLocation = _.find(locationRoles, {
      locationID: currentLocationId
    });
    const roles = _.getNonEmpty(currentLocation, 'roles', []);

    return _.includes(roles, ROLE_DOCTOR);
  }
);

export const selectCurrentUserRoles = createSelector(
  [selectIsUserOrganizationAdmin, selectIsUserLocationAdmin],
  (isOrganizationAdmin, isLocationAdmin) => ({
    isOrganizationAdmin,
    isLocationAdmin
  })
);

export const selectIsUserADoctorOnCurrentLocation = createSelector(
  [selectCachedDoctorIdListByCurrentLocation, selectProps],
  (currentLocationDoctorList, doctorId) =>
    _.includes(currentLocationDoctorList, doctorId)
);
