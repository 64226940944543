import _ from 'lodash';

import { parsePayloadJwt } from '../../../utils/jwt';
import { getLanguage } from '../../../utils/language';
import { getFullName } from '../../../utils/mappers/common-mappers';
import moshiConfig from '../../../utils/moshiConfig';

import { selectCurrentOrganizationId } from '../../organization/organizationSelectors';

const updateIntercomState = (action, ...params) =>
  !_.isEmpty(moshiConfig.intercom) && window.Intercom(action, ...params);

export const startIntercomChat = () => () => {
  const intercomState = updateIntercomState('boot', {
    // eslint-disable-next-line camelcase
    app_id: moshiConfig.intercom,
    // eslint-disable-next-line camelcase
    custom_launcher_selector: '#sidebarHelp',
    // eslint-disable-next-line camelcase
    hide_default_launcher: true
  });

  return intercomState;
};

export const toggleIntercomChat = (hide) => {
  const intercomState = updateIntercomState('update', {
    // eslint-disable-next-line camelcase
    hide_default_launcher: hide
  });

  return intercomState;
};

export const updateIntercomChat = () => (dispatch, getState) => {
  const currentUser = _.get(getState(), 'user.currentUser', {});

  if (_.isEmpty(currentUser)) {
    return;
  }
  /* eslint-disable camelcase */
  const token = _.get(getState(), 'authentication.tokenString');
  const user_hash = _.get(parsePayloadJwt(token), 'intercom', '');
  const user_id = _.get(getState(), 'user.currentUser.id', '');
  const name = getFullName(currentUser);
  const organizationName = _.get(
    getState(),
    'organization.currentOrganization.name',
    ''
  );
  const organization_id = selectCurrentOrganizationId(getState());

  const language_override = getLanguage();
  const { email } = currentUser;

  const intercomState = updateIntercomState('update', {
    app_id: moshiConfig.intercom,
    user_hash,
    user_id,
    email,
    name,
    organization_id,
    moshiEnvironment: moshiConfig.appEnv,
    moshiVersion: moshiConfig.version,
    organizationName,
    language_override
  });

  return intercomState;
  /* eslint-enable camelcase */
};
export const shutdownIntercomChat = () => () => {
  updateIntercomState('shutdown');
};
