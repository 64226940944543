import axios from 'axios';
import _ from 'lodash';

import { showMissingOrganizationIdError } from '../../utils/alert';
import { getFormTemplateUrl } from '../../utils/api/apiUrlUtils';
import { MOSHI_FILE_TYPE_QUESTIONNAIRE } from '../../utils/constants/fileConstants';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';

import { clearFormikState } from '../core/formik/formikActions';
import { getDocumentPayload } from '../document/documentActions';
import { clearQuestionnaireSubmissionData } from './submission/questionnaireSubmissionActions';

import {
  CREATE_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRE,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_LIST,
  UPDATE_QUESTIONNAIRE,
  CLEAR_QUESTIONNAIRE_DETAIL
} from './questionnaireTypes';

import { selectCachedOrganizationId } from '../core/cache/cacheSelectors';

// TODO DOCUMENTS REWORK: the whole /redux/questionnaire should be removed, now using /redux/form
export const getQuestionnaires = () => (dispatch, getState) => {
  const url = getFormTemplateUrl(getState);

  return dispatch({
    type: GET_QUESTIONNAIRE_LIST,
    apiCall: axios.get(url, {
      params: {
        type: MOSHI_FILE_TYPE_QUESTIONNAIRE,
        limit: REQUEST_PAGINATION_MAX_LIMIT
      }
    })
  });
};

export const getQuestionnaire = (
  questionnaireId,
  version,
  actionType = GET_QUESTIONNAIRE
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const versionEndpoint = version ? `/${version}` : '';

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/questionnaires/${questionnaireId}${versionEndpoint}`
    )
  });
};

export const updateQuestionnaire = (questionnaireId, questionnaireData) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const questionnairePayload = getDocumentPayload(questionnaireData);

  return dispatch({
    type: UPDATE_QUESTIONNAIRE,
    apiCall: axios.put(
      `/organizations/${organizationId}/questionnaires/${questionnaireId}`,
      questionnairePayload
    ),
    onSuccess: () => {
      dispatch(clearFormikState());
      dispatch(getQuestionnaires());
    }
  });
};

export const createQuestionnaire = (questionnaireData) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  const questionnairePayload = getDocumentPayload(questionnaireData);

  return dispatch({
    type: CREATE_QUESTIONNAIRE,
    apiCall: axios.post(
      `/organizations/${organizationId}/questionnaires`,
      questionnairePayload
    ),
    onSuccess: () => {
      dispatch(clearFormikState());
      dispatch(getQuestionnaires());
    }
  });
};

export const createOrUpdateQuestionnaire = (questionnaireData) => (
  dispatch
) => {
  if (_.isEmptySafe(questionnaireData, 'id')) {
    return dispatch(createQuestionnaire(questionnaireData));
  } else {
    return dispatch(
      updateQuestionnaire(questionnaireData.id, questionnaireData)
    );
  }
};

export const removeQuestionnaire = (questionnaireId) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: DELETE_QUESTIONNAIRE,
    apiCall: axios.delete(
      `/organizations/${organizationId}/questionnaires/${questionnaireId}`
    ),
    onSuccess: () => {
      dispatch(getQuestionnaires());
    }
  });
};

export const clearQuestionnaireDetail = () => (dispatch) => {
  dispatch(clearQuestionnaireSubmissionData());
  dispatch({
    type: CLEAR_QUESTIONNAIRE_DETAIL
  });
};
