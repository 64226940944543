import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../i18n';

import DisplayWithFallback from './DisplayWithFallback';

export const DisplayFullNameComponent = ({
  person,
  prefix,
  suffix,
  emptyText,
  lastNameFirst,
  showPrefixIfEmpty,
  showSuffixIfEmpty,
  suffixNoSpace
}) => (
  <DisplayWithFallback
    displayValue={{ user: _.get(person, 'identification', person) }}
    assertObjectProps={['user.firstName', 'user.lastName']}
    translationKey={`common:${
      lastNameFirst ? 'displayNameReverse' : 'displayName'
    }`}
    emptyText={emptyText}
    prefix={prefix}
    suffix={suffix}
    suffixNoSpace={suffixNoSpace}
    showSuffixIfEmpty={showSuffixIfEmpty}
    showPrefixIfEmpty={showPrefixIfEmpty}
  />
);

DisplayFullNameComponent.propTypes = {
  person: PropTypes.shape(),
  emptyText: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  lastNameFirst: PropTypes.bool,
  suffixNoSpace: PropTypes.bool,
  showSuffixIfEmpty: PropTypes.bool,
  showPrefixIfEmpty: PropTypes.bool
};

DisplayFullNameComponent.defaultProps = {
  person: {},
  prefix: undefined,
  suffix: undefined,
  lastNameFirst: false,
  suffixNoSpace: true,
  showSuffixIfEmpty: false,
  showPrefixIfEmpty: false
};

export default localize(DisplayFullNameComponent);
