import produce from 'immer';
import _ from 'lodash';

import {
  GET_PATIENT_NOTE_LIST_SUCCESS,
  GET_PATIENT_NOTE_LIST_FAILURE,
  GET_PATIENT_NOTE_LIST_IN_PROGRESS
} from './patientNoteTypes';

import { LOGOUT } from '../../auth/authReducer';

export const initialState = {
  list: [],
  loadingList: false
};

// eslint-disable-next-line default-param-last
const patientNoteReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_PATIENT_NOTE_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;

      case GET_PATIENT_NOTE_LIST_SUCCESS:
        const data = _.getNonEmpty(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = data;
        break;

      case GET_PATIENT_NOTE_LIST_FAILURE:
        draft.loadingList = false;
        draft.list = [];
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default patientNoteReducer;
