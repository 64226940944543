import { getMillisecondsEpoch } from '../../utils/date';
import { guid } from '../../utils/gen';

class EntityBaseModel {
  constructor(id = guid()) {
    this.id = id;
    this.createdBy = null;
    this.createdAt = null;
    this.updatedAt = null;
  }

  get createdAtTimestamp() {
    return getMillisecondsEpoch(this.createdAt);
  }

  static removeEntityMetadata(model) {
    delete model.id;
    delete model.createdBy;
    delete model.createdAt;
    delete model.updatedAt;
    delete model.updatedBy;
    delete model.lastUpdateAt;
    delete model.status;

    return model;
  }
}

export default EntityBaseModel;
