import moment from 'moment';

export const THERAPY_STATUS_ACTIVE = 'active';
export const THERAPY_STATUS_IN_PROGRESS = 'inProgress';
export const THERAPY_STATUS_PAUSED = 'paused';
export const THERAPY_STATUS_STOPPED = 'stopped';
export const THERAPY_STATUS_FINISHED = 'done';

export const THERAPY_TYPE_MANUAL = 'manual';
export const THERAPY_TYPE_SCHEDULED = 'scheduled';

export const THERAPY_INTAKE_TYPE_MANUAL = 'manual';
export const THERAPY_INTAKE_TYPE_GENERATED = 'scheduled';

export const DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED = 'confirmationReceived';
export const DRUG_INTAKE_STATUS_CONFIRMATION_SENT = 'confirmationSent';
export const DRUG_INTAKE_STATUS_LATE = 'late';
export const DRUG_INTAKE_STATUS_NO_REPLY = 'noReply';
export const DRUG_INTAKE_STATUS_NOT_TAKEN = 'notTaken';
export const DRUG_INTAKE_STATUS_PAUSED = 'therapyPaused';
export const DRUG_INTAKE_STATUS_PENDING = 'pending';
export const DRUG_INTAKE_STATUS_REMINDER_SENT = 'reminderSent';
export const DRUG_INTAKE_STATUS_REMINDER_NOT_SENT = 'reminderNotSent';
export const DRUG_INTAKE_STATUS_STOPPED = 'therapyStopped';
export const DRUG_INTAKE_STATUS_TAKEN_LATE = 'takenLate';
export const DRUG_INTAKE_STATUS_TAKEN_ON_TIME = 'takenOnTime';
export const DRUG_INTAKE_STATUS_OK = 'ok';

// Frontend status
export const DRUG_INTAKE_STATUS_UPCOMING = 'upcoming';
export const THERAPY_STATUS_PAST = 'past';

// Filters
export const THERAPY_LIST_FILTER_SEARCH = 'q';

// Defaults
export const THERAPY_DEFAULT_NOTIFICATION_TIME = moment()
  .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
  .toISOString();

export const THERAPY_DEFAULT_DURATION_VALUE = {
  value: null,
  scale: 'months'
};

export const THERAPY_FORM_INITIAL_VALUES = {
  drug: null,
  quantity: {
    value: null,
    scale: 'pills'
  },
  frequency: {
    value: null,
    scale: 'weeks'
  },
  duration: THERAPY_DEFAULT_DURATION_VALUE,
  instructions: '',
  sendReminder: true,
  eventTime: THERAPY_DEFAULT_NOTIFICATION_TIME,
  askForConfirmation: true,
  indefiniteTherapy: false
};

export const PATIENT_THERAPY_FORM_INITIAL_VALUES = {
  ...THERAPY_FORM_INITIAL_VALUES,
  type: THERAPY_TYPE_SCHEDULED,
  startDate: moment().toISOString(),
  doctorID: null,
  locationID: null
};
