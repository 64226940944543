import {
  PATIENT_SEVERITY_MILD,
  PATIENT_SEVERITY_MODERATE,
  PATIENT_SEVERITY_SEVERE,
  PATIENT_SEVERITY_UNKNOWN
} from '../../utils/constants/patientConstants';

import { formatting } from './date';

export default {
  entity: 'Patient',
  title: 'Patient',
  deletePatient: 'Delete patient',
  listTitle: 'Patients',
  firstNameLastName: 'First and last name',
  lastNameFirstName: 'Last and first name',
  phoneNumber: 'Phone number',
  addModal: {
    addPatient: 'Add patient',
    createPatient: 'Create new patient'
  },
  removeModal: {
    doctorNotAssigned: 'Patient has no doctor assigned',
    title: 'Remove patient',
    reason: 'Reason',
    reasons: {
      first: 'First reason',
      second: 'Second reason',
      third: 'Third reason'
    },
    reasonDropdownPlaceholder: 'Select reason'
  },
  createPreview: {
    summary: 'Summary',
    summaryMessage: 'Patient with the following information will be created:'
  },
  detail: {
    documents: 'Documents',
    personalInfo: {
      title: 'Personal info',
      identification: {
        dateOfBirth: 'Date of birth',
        doctorInformation: 'Doctor information',
        doctor: '$t(patient:doctor.title)',
        doctorPlaceholder: 'Choose a doctor',
        insuranceId: 'Insurance number',
        shortId: 'Serial number',
        otherInfo: 'Other info'
      }
    },
    miniCardInfo: `{{ dateOfBirth, ${formatting.longDate} }} · {{ age }} {{ unit }} · {{ gender }}`
  },
  list: {
    lastVisitFilter: 'Last visit',
    timeFromLastVisitFilter: 'Time from the last visit',
    doctorFilter: '$t(patient:doctor.title)',
    dateOfBirthAndAge: 'Date of Birth · Age',
    dateAndDifferenceValue: `{{ date, ${formatting.longDate}}} · {{count}}{{unit}}`,
    lastVisitAndPeriod: 'Last visit · Period',
    sortBy: 'Sort by:',
    lastVisitOrder: 'Last visit',
    columnNames: {
      therapyStatus: '$t(therapy:activeTherapyTile.therapyStatus)',
      drug: '$t(drug:singleTitle)',
      lastIntake: 'Last intake',
      nextIntake: 'Next intake',
      daysInTherapy: 'Days in therapy'
    },
    searchFieldTooltip:
      'Search by name, phone number, email, insurance number or serial number',
    emptyStateText: `
        <0>There is nothing here.</0>
        <1></1>
        <2>Or refine your search by searching by first name, last name, phone number, email address, insurance number or serial number.</2>
        `
  },
  state: {
    severity: {
      title: 'Severity',
      [PATIENT_SEVERITY_MILD]: 'Mild',
      [PATIENT_SEVERITY_MODERATE]: 'Moderate',
      [PATIENT_SEVERITY_SEVERE]: 'Severe',
      [PATIENT_SEVERITY_UNKNOWN]: 'Unknown'
    },
    dateOfOnset: 'Date of onset'
  },
  anamnesis: {
    title: 'General anamnesis',
    noPinnedItems:
      'There is no pinned data, you can pin it in General anamnesis under Medical Record.',
    medicalCondition: {
      title: 'Medical conditions',
      removeTitle: '$t(patient:anamnesis.medicalCondition.field)',
      field: 'Medical condition',
      add: '+ add medical condition'
    },
    concomitantDrug: {
      title: 'Concomitant drugs',
      removeTitle: '$t(patient:anamnesis.concomitantDrug.field)',
      field: 'Concomitant drug',
      add: '+ add concomitant drugs'
    },
    allergy: {
      title: 'Allergies',
      removeTitle: '$t(patient:anamnesis.allergy.field)',
      field: 'Allergy',
      add: '+ add allergy'
    },
    vaccination: {
      title: 'Vaccinations',
      removeTitle: '$t(patient:anamnesis.vaccination.field)',
      field: 'Vaccination',
      add: '+ add vaccination'
    },
    implant: {
      title: 'Medical devices and implants',
      removeTitle: '$t(patient:anamnesis.implant.field)',
      field: 'Medical device or implant',
      add: '+ add medical device or implant'
    },
    operation: {
      title: 'Operations',
      removeTitle: '$t(patient:anamnesis.operation.field)',
      field: 'Operation',
      add: '+ add operation'
    },
    other: {
      title: '$t(common:other)',
      removeTitle: '$t(common:other)',
      removeMessage: 'Are you sure you want to remove this anamnesis?',
      add: '+ add other'
    },
    alert: {
      created: 'Anamnesis was successfully created.',
      updated: 'Anamnesis was successfully updated.',
      removed: 'Anamnesis was successfully removed.'
    }
  },
  cave: {
    cave: 'Cave',
    fieldLabel: {
      allergies: 'Allergies',
      medications: 'Medications',
      chronicConditions: 'Chronic conditions',
      other: 'Other',
      note: 'Note'
    },
    missingField: {
      allergies: 'No allergies',
      medications: 'No medications',
      chronicConditions: 'No chronic conditions',
      other: 'Nothing',
      note: 'Nothing'
    },
    noFileYet:
      'Here you can add important medical information about the patient. Click on the card to start editing!'
  },
  note: {
    title: 'Note',
    placeholder: 'Write a note',
    removeNote: 'Remove note',
    confirmNoteRemoval: 'Are you sure you want to remove this note?',
    type: {
      note: 'Note'
    },
    noFileYet:
      'Here you can add non-clinical notes about the patient. E.g. “the patient prefers afternoon appointments',
    alerts: {
      noteCreated: 'Note was successfully created.',
      noteUpdated: 'Note was successfully updated.',
      noteRemoved: 'Note was successfully removed.',
      notePinned: 'Note was successfully pinned.',
      noteUnpinned: 'Note was successfully unpinned.'
    }
  },
  person: {
    firstName: 'First name',
    lastName: 'Last name',
    gender: 'Gender',
    genderTypes: {
      male: 'Male',
      female: 'Female'
    },
    phoneNumber: 'Phone number',
    notifications: {
      title: 'Notification Preferences',
      sms: 'SMS',
      email: 'Email',
      push: 'Push notifications',
      marketing: {
        title: 'Marketing'
      },
      reminders: {
        title: 'Reminders'
      }
    },
    contact: 'Contact',
    identification: 'Identification',
    dateOfBirth: 'Date of birth',
    insuranceId: 'Insurance number',
    shortId: 'Serial number'
  },
  address: {
    address: 'Address',
    street: 'Street',
    town: 'City',
    zip: 'Postal code',
    zipShort: 'ZIP',
    country: 'Country'
  },
  doctor: {
    title: 'Doctor',
    doctor: '$t(patient:doctor.title)',
    choose: 'Choose doctor',
    change: 'Change doctor',
    chooseFor: 'Choose for:',
    chooseForEncounter: 'this encounter only',
    chooseForPatient: 'this and all future encounters',
    doctorInformation: 'Doctor information',
    locationIDs: 'Location',
    prefix: 'dr.'
  },
  therapy: {
    title: 'Drug therapy',
    intake: 'Intake',
    intakeNumber: 'Dose #{{number}}',
    intakeStatus: 'Status'
  },
  bills: {
    tab: {
      invoices: '$t(billing:invoices.title)',
      items: 'Items'
    }
  },
  unbilledItems: {
    title: 'Unbilled Items',
    moreButton: 'Edit details',
    addItem: 'Add item',
    selectedTotal: 'Selected total',
    creatorLabel: {
      addedNow: 'Now (unsaved)'
    },
    alerts: {
      unbilledItemAdded: 'Item successfully appended to the patient.',
      unbilledItemsUpdated: 'Unbilled items successfully updated.'
    }
  },
  viewFullProfile: 'View full profile',
  merging: {
    merge: 'Merge',
    mergePatients: 'Merge patients',
    duplicatedPatients: 'Duplicated patients',
    mergeDuplicatedPatients: 'Merge duplicated patients',
    duplicatedPatientsDescription:
      'System has detected duplicate patients, would you like to merge them?',
    confirmMerge: 'Yes, show me',
    findDuplicatedPatients: 'Find patients with duplicated name',
    pickPrimaryPatient: 'Pick a primary patient:',
    thesePatientsWillBeMerged: 'These are the patients you will merge:',
    mergePatientFormTitle: 'Select the data you want to keep:',
    confirmMsg:
      'Are you sure you want to merge patients? This action is permanent and cannot be undone.',
    identification: {
      firstName: '$t(patient:person.firstName)',
      dateOfBirth: '$t(patient:person.dateOfBirth)',
      lastName: '$t(patient:person.lastName)',
      gender: '$t(patient:person.gender)',
      genderTypes: {
        male: '$t(patient:person.genderTypes.male)',
        female: '$t(patient:person.genderTypes.female)'
      },
      insuranceID: '$t(patient:person.insuranceId)',
      shortID: '$t(patient:person.shortId)'
    },
    notifications: {
      reminders: {
        sms: 'SMS reminders',
        email: 'Email reminders',
        push: 'Reminder push notifications'
      },
      marketing: {
        sms: 'SMS marketing',

        email: 'Email marketing',
        push: 'Marketing push notifications'
      }
    },
    contact: {
      address: {
        address: '$t(patient:address.address)',
        street: '$t(patient:address.street)',
        street1: '$t(patient:address.street)',
        town: '$t(patient:address.town)',
        zip: '$t(patient:address.zip)',
        zipShort: '$t(patient:address.zipShort)',
        country: '$t(patient:address.country)'
      },
      phoneNumber: '$t(patient:person.phoneNumber)',
      email: '$t(patient:person.notifications.email)'
    },
    doctor: {
      doctorID: '$t(patient:doctor.doctor)',
      locationIDs: '$t(patient:doctor.locationIDs)'
    },
    mergeSuccess: 'Patient was successfully merged.'
  },
  widgetHistoryModal: {
    emptyState: 'No historical data available.'
  },
  medicalRecord: {
    title: 'Medical record'
  },
  overlay: {
    editPatient: 'Edit patient profile',
    deletePatient: 'Remove selected patient',
    confirmRemovalMessage:
      'Are you sure you want to remove the attached patient?',
    stats: {
      totalBookings: 'Total bookings',
      totalSales: 'Total sales',
      unusedAdvances: 'Unused advances'
    }
  },
  alerts: {
    patientUpdated: 'Patient successfully updated.',
    patientDeleted: 'Patient was successfully removed.'
  },
  infoDisplay: {
    miniCardInfoShortBirthDate: `{{ dateOfBirth, ${formatting.shortDate} }}`,
    miniCardInfo: `{{ dateOfBirth, ${formatting.longDate} }} · {{ age }} {{ unit }} · {{ gender }}`,
    miniCardInfoWithAddress: `$t(patient:infoDisplay.miniCardInfoShortBirthDate) · {{ address.street1 }}, {{ address.zip }} {{ address.town }}`,
    openProfile: 'open profile'
  },
  export: {
    title: 'Export the patient list',
    patientListWithDate: `Export patient list {{ date, ${formatting.shortDate} }}`,
    inProgress: 'Export might take some time.',
    alert: {
      requestSuccess:
        'Patient export started. We will notify you when it’s ready for download.'
    }
  },
  appointmentList: {
    queueEventsOnDifferentLocations:
      'Patient is on the waiting list on location: ',
    differentLocationWarning:
      'Event is on location {{location}} and cannot be rescheduled. Change the location first and try again.'
  },
  validation: {
    duplicatedPatientOnList: 'The patient already exists in the list.'
  }
};
