export default {
  id: 'vitalSign/BODY_MASS_INDEX/v1',
  formSchema: {
    title: 'widget:vitalSigns.bodyMassIndex.title',
    type: 'object',
    properties: {
      id001: {
        type: 'number',
        title: 'widget:vitalSigns.bodyMassIndex.title',
        'x-calculated': `
          const bodyMass = this.widgetData("vitalSign/BODY_MASS", "id001");
          const bodyHeight = this.widgetData("vitalSign/BODY_HEIGHT", "id001");

          return bodyMass / Math.pow((bodyHeight / 100), 2);
        `
      }
    },
    'x-observes': ['vitalSign/BODY_MASS', 'vitalSign/BODY_HEIGHT'],
    iconClass: 'icon-bolt'
  },
  uiSchema: {
    id001: {
      'ui:widget': 'customNumericLabelWidget',
      'ui:disabled': true,
      'ui:x-includeValue': true,
      'ui:x-labels': {
        '(-INF, 18.5)': {
          label: 'widget:vitalSigns.bodyMassIndex.labels.underweight',
          color: 'warning'
        },
        '[18.5, 25)': {
          label: 'widget:vitalSigns.bodyMassIndex.labels.normalWeight',
          color: 'success'
        },
        '[25, 30)': {
          label: 'widget:vitalSigns.bodyMassIndex.labels.overweight',
          color: 'warning'
        },
        '[30, INF)': {
          label: 'widget:vitalSigns.bodyMassIndex.labels.obesity',
          color: 'danger'
        }
      }
    },
    'x-liveValidate': false,
    'x-customClass': 'vital-signs-widget',
    'x-cancelBtnSize': 'sm',
    'x-saveBtnSize': 'sm',
    'x-readOnly': true
  }
};
