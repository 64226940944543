import React, { useRef, useEffect, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Popover, PopoverBody } from 'reactstrap';

import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  TRIGGER_CLICK,
  TRIGGER_FOCUS,
  TRIGGER_HOVER,
  TRIGGER_NONE
} from '../../../utils/constants/triggerConstants';
import { EVENT_MOUSE_DOWN } from '../../../utils/event';

import { EmptyComponent } from '../HtmlComponents';

export const MoshiPopoverComponent = ({
  targetId,
  isOpen,
  toggleOpen,
  children,
  placement,
  className,
  trigger,
  delay,
  hideArrow,
  fade,
  popperClassName
}) => {
  let contentRef = useRef();
  const [elementExists, setElementExists] = useState(true);

  const handleClick = useCallback(
    (e) => {
      if (
        !_.isEmpty(contentRef) &&
        _.isFunction(contentRef.contains) &&
        !_.isEmpty(e.target)
      ) {
        const clickedInside = contentRef.contains(e.target);
        /*
         * if the toggle button is clicked, don't toggle because the button is already handling the toggle
         */
        const openerClicked =
          e.target.parentElement.id === targetId || e.target.id === targetId;

        if (!clickedInside && !openerClicked) {
          toggleOpen();
        }
      }
    },
    [targetId, toggleOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener(EVENT_MOUSE_DOWN, handleClick, false);
    } else {
      document.removeEventListener(EVENT_MOUSE_DOWN, handleClick, false);
    }
  }, [handleClick, isOpen]);

  useEffect(() => {
    setElementExists(Boolean(document.getElementById(targetId)));
  }, [targetId]);

  if (!elementExists) {
    return <EmptyComponent />;
  }

  return (
    <Popover
      placement={placement}
      className={classnames('moshi-popover', className)}
      target={targetId}
      fade={fade}
      modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
      toggle={toggleOpen}
      trigger={trigger}
      delay={delay}
      isOpen={isOpen}
      hideArrow={hideArrow}
      popperClassName={popperClassName}
    >
      <div
        ref={(ref) => {
          contentRef = ref;
        }}
      >
        <PopoverBody>{children}</PopoverBody>
      </div>
    </Popover>
  );
};

MoshiPopoverComponent.propTypes = {
  targetId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  trigger: PropTypes.oneOf([
    TRIGGER_CLICK,
    TRIGGER_HOVER,
    TRIGGER_FOCUS,
    TRIGGER_NONE
  ]),
  delay: PropTypes.shape(),
  hideArrow: PropTypes.bool,
  fade: PropTypes.bool
};

MoshiPopoverComponent.defaultProps = {
  placement: 'bottom',
  className: undefined,
  popperClassName: undefined,
  trigger: TRIGGER_CLICK,
  delay: {
    show: 0,
    hide: 0
  },
  hideArrow: false,
  fade: false
};

export default MoshiPopoverComponent;
