export default {
  id: 'administrativeData/ATTENDING_PHYSICIAN/v1',
  formSchema: {
    title: 'encounter:widget.administrativeData.attendingPhysician',
    type: 'object',
    required: ['id001'],
    properties: {
      id001: {
        type: 'string',
        title: 'encounter:widget.administrativeData.attendingPhysician'
      }
    }
  },
  uiSchema: {
    id001: {
      'ui:widget': 'customDataDropdownWidget',
      'ui:options': {
        widgetDataType: 'widgetDataType_DOCTORS',
        entityType: 'SUB_ENTITY_TYPE_USER'
      },
      'x-defaultFormDataKey': 'doctorID'
    },
    'x-customClass': 'admin-data-widget',
    'x-cancelBtnSize': 'sm',
    'x-saveBtnSize': 'sm'
  }
};
