import { connectRouter } from 'connected-react-router';

import { combineReducers } from 'redux';

import appConfig from './redux/core/app-config/appConfigReducer';

import analytics from './redux/analytics/analyticsReducer';
import authentication from './redux/auth/authReducer';
import advance from './redux/billing/advance/advanceReducer';
import bill from './redux/billing/bill/billReducer';
import billing from './redux/billing/billingReducer';
import cashRegister from './redux/billing/cash-register/cashRegisterReducer';
import billingCategory from './redux/billing/category/billingCategoryReducer';
import creditNote from './redux/billing/credit-note/creditNoteReducer';
import estimate from './redux/billing/estimate/estimateReducer';
import invoice from './redux/billing/invoice/invoiceReducer';
import booking from './redux/booking/bookingReducer';
import calendar from './redux/calendar/calendarReducer';
import category from './redux/calendar/category/categoryReducer';
import resource from './redux/calendar/resource/resourceReducer';
import closedDates from './redux/closed-dates/closedDatesReducer';
import communication from './redux/communication/communicationReducer';
import app from './redux/core/app/appReducer';
import archive from './redux/core/archive/archiveReducer';
import cache from './redux/core/cache/cacheReducer';
import file from './redux/core/file/fileReducer';
import formik from './redux/core/formik/formikReducer';
import routerHistory from './redux/core/routerHistory/routerHistoryReducer';
import webSocket from './redux/core/web-socket/webSocketReducer';
import rheumatology from './redux/custom/rheumatology/rheumatologyReducer';
import document from './redux/document/documentReducer';
import documentSubmission from './redux/document/submission/documentSubmissionReducer';
import drug from './redux/drug/drugReducer';
import eventLog from './redux/event-log/eventLogReducer';
import flow from './redux/flow/flowReducer';
import form from './redux/form/formReducer';
import location from './redux/location/locationReducer';
import organization from './redux/organization/organizationReducer';
import patientAnamnesis from './redux/patient/anamnesis/patientAnamnesisReducer';
import cave from './redux/patient/cave/caveReducer';
import dentalChart from './redux/patient/dental-chart/dentalChartReducer';
import encounter from './redux/patient/encounter/encounterReducer';
import patientNote from './redux/patient/note/patientNoteReducer';
import patient from './redux/patient/patientReducer';
import widget from './redux/patient/widget/widgetReducer';
import questionnaire from './redux/questionnaire/questionnaireReducer';
import questionnaireSubmission from './redux/questionnaire/submission/questionnaireSubmissionReducer';
import table from './redux/table/tableReducer';
import tags from './redux/tags/tagsReducer';
import therapy from './redux/therapy/therapyReducer';
import user from './redux/user/userReducer';
import workingHours from './redux/working-hours/workingHoursReducer';

/* eslint sort-keys: "error" */
const rootReducer = (history) =>
  combineReducers({
    advance,
    analytics,
    app,
    appConfig,
    archive,
    authentication,
    bill,
    billing,
    billingCategory,
    booking,
    cache,
    calendar,
    cashRegister,
    category,
    cave,
    closedDates,
    communication,
    creditNote,
    dentalChart,
    document,
    documentSubmission,
    drug,
    encounter,
    estimate,
    eventLog,
    file,
    flow,
    form,
    formik,
    invoice,
    location,
    organization,
    patient,
    patientAnamnesis,
    patientNote,
    questionnaire,
    questionnaireSubmission,
    resource,
    rheumatology,
    router: connectRouter(history),
    routerHistory,
    table,
    tags,
    therapy,
    user,
    webSocket,
    widget,
    workingHours
  });

export default rootReducer;
