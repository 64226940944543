import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { useMount } from '../../../../utils/hooks';

import { selectWidgetDataByType } from '../../../../redux/patient/widget/widgetSelectors';

import MoshiDropdown, { DROPDOWN_FORM_LOOK } from '../dropdown/MoshiDropdown';

const CustomDataDropdownWidget = ({
  value,
  disabled,
  onChange,
  options: { widgetDataType, entityType, fetchAction, ...otherOptions },
  enableDeselection,
  id
}) => {
  const dispatch = useDispatch();
  const items = useSelector((store) =>
    selectWidgetDataByType(store, { widgetDataType, entityType })
  );
  const params = useMemo(
    () => _.getNonEmpty(otherOptions, 'componentParams', {}),
    [otherOptions]
  );

  useMount(() => {
    if (!_.isEmpty(fetchAction) && _.isEmptySafe(items)) {
      dispatch({
        type: fetchAction
      });
    }
  });

  return (
    <MoshiDropdown
      {...params}
      look={DROPDOWN_FORM_LOOK}
      toggleClassName={'no-break'}
      value={value}
      disabled={disabled}
      onSelect={(item) => onChange(_.get(item, 'id', null))}
      entityType={entityType}
      items={items}
      enableDeselection={enableDeselection}
      id={id}
    />
  );
};

CustomDataDropdownWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    widgetDataType: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    fetchAction: PropTypes.string,
    componentParams: PropTypes.shape()
  }).isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  enableDeselection: PropTypes.bool
};

CustomDataDropdownWidget.defaultProps = {
  value: null,
  disabled: false,
  enableDeselection: undefined,
  id: undefined
};

export default CustomDataDropdownWidget;
