import { createActionTypeArray } from '../common/actionTypes';

export const GET_QUESTIONNAIRE_LIST = 'questionnaire/GET_QUESTIONNAIRE_LIST';
export const GET_QUESTIONNAIRE = 'questionnaire/GET_QUESTIONNAIRE';
export const UPDATE_QUESTIONNAIRE = 'questionnaire/UPDATE_QUESTIONNAIRE';
export const CREATE_QUESTIONNAIRE = 'questionnaire/CREATE_QUESTIONNAIRE';
export const DELETE_QUESTIONNAIRE = 'questionnaire/DELETE_QUESTIONNAIRE';
export const CLEAR_QUESTIONNAIRE_DETAIL =
  'questionnaire/CLEAR_QUESTIONNAIRE_DETAIL';

export const CREATE_PATIENT_QUESTIONNAIRE =
  'documents/CREATE_PATIENT_QUESTIONNAIRE';
export const DELETE_PATIENT_QUESTIONNAIRE =
  'documents/DELETE_PATIENT_QUESTIONNAIRE';
export const UPDATE_PATIENT_QUESTIONNAIRE =
  'documents/UPDATE_PATIENT_QUESTIONNAIRE';

export const [
  GET_QUESTIONNAIRE_LIST_IN_PROGRESS,
  GET_QUESTIONNAIRE_LIST_SUCCESS,
  GET_QUESTIONNAIRE_LIST_FAILURE
] = createActionTypeArray(GET_QUESTIONNAIRE_LIST);

export const [
  GET_QUESTIONNAIRE_IN_PROGRESS,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(GET_QUESTIONNAIRE);

export const [
  UPDATE_QUESTIONNAIRE_IN_PROGRESS,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(UPDATE_QUESTIONNAIRE);

export const [
  CREATE_QUESTIONNAIRE_IN_PROGRESS,
  CREATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(CREATE_QUESTIONNAIRE);

export const [
  DELETE_QUESTIONNAIRE_IN_PROGRESS,
  DELETE_QUESTIONNAIRE_SUCCESS,
  DELETE_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(DELETE_QUESTIONNAIRE);

export const [
  CREATE_PATIENT_QUESTIONNAIRE_IN_PROGRESS,
  CREATE_PATIENT_QUESTIONNAIRE_SUCCESS,
  CREATE_PATIENT_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(CREATE_PATIENT_QUESTIONNAIRE);

export const [
  UPDATE_PATIENT_QUESTIONNAIRE_IN_PROGRESS,
  UPDATE_PATIENT_QUESTIONNAIRE_SUCCESS,
  UPDATE_PATIENT_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(UPDATE_PATIENT_QUESTIONNAIRE);

export const [
  DELETE_PATIENT_QUESTIONNAIRE_IN_PROGRESS,
  DELETE_PATIENT_QUESTIONNAIRE_SUCCESS,
  DELETE_PATIENT_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(DELETE_PATIENT_QUESTIONNAIRE);
