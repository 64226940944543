import _ from 'lodash';
import moment from 'moment';

import BillingItemLocalizedModel from '../../metadata/model/billing/BillingItemLocalizedModel';

import { INVOICE_STATUS_CANCELED } from '../billing';
import { getCurrencySign } from '../data/currency';
import { guid } from '../gen';

export const mapBillingUnit = (billingUnit) => {
  if (_.isEmpty(billingUnit)) {
    return {};
  }

  if (!_.has(billingUnit, 'companyDetails.taxSubject')) {
    _.set(billingUnit, 'companyDetails.taxSubject', false);
  }

  return billingUnit;
};

export const keywordReplacer = (keyWordData, originalString) => {
  let replacedString = originalString;

  _.forIn(keyWordData, (value, key) => {
    replacedString = _.replace(replacedString, key, value);
  });

  return replacedString;
};

export const remapBillingItemFromLocalizedToBase = (
  billingItemLocalizedObject,
  defaultLocale,
  supportedBillingLocales
) => {
  if (_.isEmpty(billingItemLocalizedObject)) {
    return {};
  }

  const billingItemLocalizedModel = _.nestedAssign(
    BillingItemLocalizedModel,
    billingItemLocalizedObject
  );

  const billingItemObject = {
    ...billingItemLocalizedModel,
    total: _.get(billingItemLocalizedModel, 'total', 0)
  };

  const localizedFields = ['name', 'description', 'unit'];

  _.forEach(localizedFields, (fieldName) => {
    const localizedFieldName = `${fieldName}Localized`;
    const localizedValues = billingItemObject[fieldName];

    billingItemObject[localizedFieldName] = localizedValues;
    billingItemObject[fieldName] = getBillingItemDefaultTranslation(
      billingItemObject,
      fieldName,
      defaultLocale,
      supportedBillingLocales
    );
  });

  return billingItemObject;
};

export const getBillingItemDefaultTranslation = (
  object,
  path,
  defaultLocale,
  supportedBillingLocales
) => {
  let translation = _.get(object, `${path}.${defaultLocale}`, '');

  if (!_.isEmpty(translation)) {
    return translation;
  }

  _.forEach(supportedBillingLocales, (locale) => {
    const currentTranslation = _.get(object, `${path}.${locale}`, '');

    if (!_.isEmpty(currentTranslation)) {
      translation = currentTranslation;

      return false;
    }
  });

  return translation;
};

export const businessPremiseMapper = (item) => {
  if (_.isEmpty(item)) {
    return {};
  }

  const get = (keyName) => _.get(item, keyName, '');

  item.address = `${get('street')} ${get('houseNumber')}${get(
    'houseNumberAdditional'
  )}`;

  return item;
};

const getNetTotal = (item) => {
  const taxAmount = _.get(item, 'totalTax', 0);

  if (taxAmount === item.total) {
    return item.total;
  } else {
    return _.floor(item.total - taxAmount, 4);
  }
};

export const mapBillDocumentItemsWithId = (billDocument) => {
  billDocument.items = billDocument.items.map((item) => ({
    id: guid(),
    ...item
  }));

  return billDocument;
};

export const taxRatesByDocumentMapper = (itemsByTaxRate) => (itemList) => {
  const itemListIds = _.map(itemList, 'id');
  const availableTaxRates = _.keys(itemsByTaxRate);
  const taxRateObject = {};

  _.forEach(availableTaxRates, (taxRate) => {
    const taxRateItems = _.get(itemsByTaxRate, taxRate, []);

    const currentItemsByTaxRate = _.filter(taxRateItems, (taxRateItem) =>
      _.includes(itemListIds, taxRateItem.id)
    );

    const netSum = _.sumBy(currentItemsByTaxRate, (currentItem) =>
      getNetTotal(currentItem)
    );

    taxRateObject[`${taxRate}%`] = _.floor(
      _.sumBy(currentItemsByTaxRate, 'totalTax'),
      4
    );
    taxRateObject[`${taxRate}% of base`] = _.floor(netSum, 4);
  });

  return taxRateObject;
};

export const billItemLocalizedExists = (
  billingItem,
  billingItemList,
  defaultLocale
) => {
  const localizedBililngItemName = _.get(
    remapBillingItemFromLocalizedToBase(billingItem, defaultLocale),
    'name'
  );

  const localizedBillingItemList = billingItemList.map((item) =>
    remapBillingItemFromLocalizedToBase(item, defaultLocale)
  );

  const billingListFiltered = _.filter(
    localizedBillingItemList,
    (item) => item.id !== billingItem.id
  );

  const billingItemNames = _.map(billingListFiltered, (item) =>
    _.toLower(item.name)
  );

  const billingItemName = _.toLower(localizedBililngItemName);

  return _.includes(billingItemNames, billingItemName);
};

export const billDocumentForExportMapper = (itemsByTaxRate) => (
  billDocument
) => {
  const mapTaxRatesByDocument = taxRatesByDocumentMapper(itemsByTaxRate);

  return {
    Type: billDocument.type,
    Number: billDocument.number,
    Date: moment(billDocument.date).format('MM-DD-YYYY'),
    Canceled: _.includes(billDocument.status, INVOICE_STATUS_CANCELED),
    Currency: billDocument.currency,
    Total: getNetTotal(billDocument),
    'Total /w tax': billDocument.total,
    'Tax rates': '',
    ...mapTaxRatesByDocument(billDocument.items),
    'Client name': _.get(billDocument, 'client.name'),
    Address: _.get(billDocument, 'client.address.street1'),
    'Postal code': _.get(billDocument, 'client.address.zip'),
    City: _.get(billDocument, 'client.address.town'),
    Country: _.get(billDocument, 'client.address.country'),
    'Tax number': _.get(billDocument, 'client.taxNumber', ''),
    Note: _.get(billDocument, 'texts.note', '')
  };
};

export const getAdvanceDropdownMapper = (advance) => {
  const totalUnused = _.round(
    _.getNonEmpty(advance, 'total', 0) - _.getNonEmpty(advance, 'totalUsed', 0),
    2
  );

  return {
    id: advance.id,
    total: advance.total,
    name: `${advance.number} (${totalUnused} ${getCurrencySign(
      _.getNonEmpty(advance, 'currency', '/')
    )})`
  };
};
