import PropTypes from 'prop-types';

export const BaseWidgetShape = {
  data: PropTypes.shape().isRequired,
  widgetType: PropTypes.string.isRequired
};

export const CustomWidgetShape = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  widgetType: PropTypes.string.isRequired
};

export const LocalCustomWidgetShape = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  widgetType: PropTypes.string.isRequired,
  localLastModifiedOn: PropTypes.string.isRequired
};

export const DefaultWidgetShape = {
  ...BaseWidgetShape,
  id: PropTypes.string.isRequired
};

export const WidgetInEditModeShape = {
  id: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired
};

const WidgetShape = {
  ...BaseWidgetShape,
  id: PropTypes.string.isRequired,
  versionID: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired
};

export default WidgetShape;
