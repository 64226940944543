import BusinessModel from './BusinessModel';

class BillingCompanyModel extends BusinessModel {
  constructor(name = '', street1 = '', geo = null) {
    super(name, street1, geo);

    this.taxSubject = false;
    this.bank = '';
    this.iban = '';
    this.swift = '';
  }
}

export default BillingCompanyModel;
