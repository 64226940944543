import _ from 'lodash';
import moment from 'moment';

import { cleanPayload } from '../../../utils/redux/requestUtils';

const validRequestFields = [
  'amount',
  'date',
  'customPaymentID',
  'note',
  'tag',
  'type',
  'advancePaymentData'
];

class PaymentModel {
  constructor(
    amount = null,
    type = 'cash',
    date = moment().toISOString(),
    advancePaymentData = {}
  ) {
    this.originalRemainingDue = amount;
    this.advanceMaxAmount = Number.MAX_SAFE_INTEGER;
    this.amount = amount;
    this.type = type;
    this.date = date;
    this.advancePaymentData = advancePaymentData;
  }

  static getPayload(model) {
    const payload = _.cloneDeep(model);

    if (
      _.has(payload, 'advancePaymentData') &&
      _.isEmptySafe(payload, 'advancePaymentData.advanceID')
    ) {
      delete payload.advancePaymentData;
    }

    payload.date = moment(model.date).format('YYYY-MM-DD');

    return cleanPayload(payload, validRequestFields);
  }
}

export default PaymentModel;
