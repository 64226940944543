import produce from 'immer';
import _ from 'lodash';

import {
  GET_BOOKING_TEMPLATES_FAILURE,
  GET_BOOKING_TEMPLATES_SUCCESS,
  GET_BOOKING_TEMPLATES_IN_PROGRESS,
  GET_BOOKING_TEMPLATE_DETAIL_SUCCESS,
  GET_BOOKING_TEMPLATE_DETAIL_FAILURE,
  GET_BOOKING_TEMPLATE_DETAIL_IN_PROGRESS,
  CLEAR_BOOKING_TEMPLATE_DETAIL,
  UPDATE_BOOKING_TEMPLATE_DRAFT_DETAIL,
  GET_BOOKING_SCHEDULES_SUCCESS,
  GET_BOOKING_SCHEDULES_IN_PROGRESS,
  GET_BOOKING_SCHEDULES_FAILURE,
  GET_BOOKING_TERMS_AND_CONDITIONS_SUCCESS,
  GET_BOOKING_TERMS_AND_CONDITIONS_FAILURE,
  GET_BOOKING_TERMS_AND_CONDITIONS_IN_PROGRESS
} from './bookingTypes';

const initialState = {
  templateList: [],
  scheduleList: [],
  loadingScheduleList: false,
  loadingTemplateList: false,
  detail: {},
  loadingDetail: false,
  draftDetail: {},
  generalSettings: {
    termsAndConditions: '',
    areTermsLoading: false
  }
};

// eslint-disable-next-line default-param-last
const bookingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_BOOKING_TEMPLATES_SUCCESS:
        const data = _.getNonEmpty(payload, 'response.data', []);

        draft.templateList = data;
        draft.loadingTemplateList = false;
        break;
      case GET_BOOKING_TEMPLATES_FAILURE:
        draft.templateList = [];
        draft.loadingTemplateList = false;
        break;
      case GET_BOOKING_TEMPLATES_IN_PROGRESS:
        draft.loadingTemplateList = true;
        break;
      case GET_BOOKING_SCHEDULES_IN_PROGRESS:
        draft.loadingScheduleList = true;
        break;
      case GET_BOOKING_SCHEDULES_FAILURE:
        draft.scheduleList = [];
        draft.loadingScheduleList = false;
        break;
      case GET_BOOKING_SCHEDULES_SUCCESS:
        const schedules = _.getNonEmpty(payload, 'data', []);

        draft.scheduleList = schedules;
        draft.loadingScheduleList = false;
        break;
      case GET_BOOKING_TEMPLATE_DETAIL_SUCCESS:
        const responseData = _.getNonEmpty(payload, 'response.data', {});

        draft.detail = responseData;
        draft.draftDetail = responseData;
        draft.loadingDetail = false;
        break;
      case GET_BOOKING_TEMPLATE_DETAIL_FAILURE:
        draft.detail = {};
        draft.loadingDetail = false;
        break;
      case GET_BOOKING_TEMPLATE_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case UPDATE_BOOKING_TEMPLATE_DRAFT_DETAIL:
        const currentDraftDetail = _.getNonEmpty(state, 'draftDetail', {});
        const newDraftData = _.getNonEmpty(payload, 'data');

        draft.draftDetail = { ...currentDraftDetail, ...newDraftData };
        break;
      case GET_BOOKING_TERMS_AND_CONDITIONS_SUCCESS:
        const termsAndConditions = _.getNonEmpty(payload, 'response.data', '');

        draft.generalSettings.termsAndConditions = termsAndConditions;
        draft.generalSettings.areTermsLoading = false;
        break;
      case GET_BOOKING_TERMS_AND_CONDITIONS_FAILURE:
        draft.generalSettings.termsAndConditions = '';
        draft.generalSettings.areTermsLoading = false;
        break;
      case GET_BOOKING_TERMS_AND_CONDITIONS_IN_PROGRESS:
        draft.generalSettings.areTermsLoading = true;
        break;
      case CLEAR_BOOKING_TEMPLATE_DETAIL:
        draft.detail = initialState.detail;
        draft.draftDetail = initialState.draftDetail;
        break;
      default:
    }
  });

export default bookingReducer;
