import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';

import { FULL_WIDTH_TOAST_ID, hideAlertWithId } from '../../utils/alert';
import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../utils/browserStorage';
import {
  calendarCategoriesStorageKey,
  calendarColorSourceStorageKey,
  calendarLocationAssigneesStorageKey,
  calendarStatusesStorageKey,
  calendarStorageKeys,
  calendarViewStorageKey,
  slotDurationStorageKey,
  toggleCalendarAssigneesListStorageKey,
  toggleCalendarCategoryListStorageKey,
  toggleCalendarStatusListStorageKey
} from '../../utils/constants/browserStorageConstants';
import {
  CALENDAR_SEARCH_RESULTS_LIMIT,
  calendarEventStatusesListIds,
  EVENT_COLOR_SOURCE_CATEGORY,
  EVENT_STATUS_SCHEDULED,
  EVENT_TYPE_OTHER,
  EVENT_TYPE_REMINDER
} from '../../utils/constants/calendarConstants';
import { SEARCH_RESULT_ENTITY_TYPE_EVENT } from '../../utils/constants/entityConstants';
import { isValidDate } from '../../utils/date';

import {
  ADD_EVENT,
  CLEAR_CALENDAR_ASSIGNEES_LIST,
  CLEAR_EVENT_MESSAGES,
  CLEAR_EVENT_PATIENT,
  CLEAR_HIGHLIGHT_EVENT_ID,
  CLEAR_PREFILLED_EVENT_DATA,
  CLEAR_SEARCH_PAGINATION_AND_RESULTS,
  CLEAR_SEARCH_QUERY_PAGINATION_AND_RESULTS,
  CLEAR_SELECTED_CALENDAR_ASSIGNEES,
  GET_CALENDAR_ASSIGNEES_FAILURE,
  GET_CALENDAR_ASSIGNEES_IN_PROGRESS,
  GET_CALENDAR_ASSIGNEES_SUCCESS,
  GET_CALENDAR_WAITING_LIST_FAILURE,
  GET_CALENDAR_WAITING_LIST_IN_PROGRESS,
  GET_CALENDAR_WAITING_LIST_SUCCESS,
  GET_EVENT_MESSAGES_SUCCESS,
  GET_EVENT_PATIENT_FAILURE,
  GET_EVENT_PATIENT_IN_PROGRESS,
  GET_EVENT_PATIENT_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_IN_PROGRESS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_WITH_QUERY_FAILURE,
  GET_EVENTS_WITH_QUERY_IN_PROGRESS,
  GET_EVENTS_WITH_QUERY_SUCCESS,
  SET_CALENDAR_EVENT_COLOR_SOURCE,
  SET_CALENDAR_FILTER_SELECTED_DATE_RANGE,
  SET_CALENDAR_SCROLL_TIME,
  SET_CALENDAR_SELECTED_DATE_RANGE,
  SET_CALENDAR_TITLE,
  SET_CURRENT_CALENDAR_VIEW,
  SET_EVENT_PATIENT,
  SET_EVENT_QUERY_STRING,
  SET_HIGHLIGHT_EVENT_ID,
  SET_PREFILLED_EVENT_DATA,
  SET_SEARCH_RESULTS_LIST_PAGINATION,
  SET_SELECTED_CALENDAR_ASSIGNEES,
  REMOVE_SELECTED_CALENDAR_ASSIGNEE,
  SET_SELECTED_CALENDAR_CATEGORIES,
  SET_SELECTED_CALENDAR_SEARCH_ASSIGNEES,
  SET_SELECTED_CALENDAR_SEARCH_CATEGORIES,
  SET_SELECTED_CALENDAR_SEARCH_EVENT_STATUSES,
  SET_SELECTED_CALENDAR_STATUSES,
  SET_SLOT_DURATION,
  TOGGLE_CALENDAR_ASSIGNEES_LIST,
  TOGGLE_CALENDAR_CATEGORY_LIST,
  TOGGLE_CALENDAR_STATUS_LIST,
  GET_CALENDAR_EVENT_WITH_QUERY_IN_PROGRESS,
  GET_CALENDAR_EVENT_WITH_QUERY_FAILURE,
  GET_CALENDAR_EVENT_WITH_QUERY_SUCCESS
} from './calendarTypes';

import { LOGOUT } from '../auth/authReducer';
import { UPDATE_SELECTED_LOCATION } from '../location/locationReducer';

const calendarLocalStorage = () =>
  getLocalStorageValue(calendarStorageKeys, {});

const getInitialSelectedStatuses = () => {
  const allStatusesIds = _.cloneDeep(calendarEventStatusesListIds);

  const blackList = _.getNonEmpty(
    calendarLocalStorage(),
    calendarStatusesStorageKey,
    []
  );

  const whiteList = _.filter(
    allStatusesIds,
    (status) => !_.includes(blackList, status)
  );

  return whiteList;
};

const initialState = () => ({
  toDate: null,
  fromDate: null,
  loadingList: false,
  list: [],
  messageList: [],
  event: null,
  loadingEvent: false,
  eventPatient: {
    data: null,
    loading: false
  },
  highlightEventId: null,
  prefilledEventData: {
    isDataPrefilled: false,
    eventData: {},
    prefillType: null
  },
  currentCalendarView: _.getNonEmpty(
    calendarLocalStorage(),
    calendarViewStorageKey,
    null
  ),
  calendarEventColorSource: _.getNonEmpty(
    calendarLocalStorage(),
    calendarColorSourceStorageKey,
    EVENT_COLOR_SOURCE_CATEGORY
  ),
  selectedAssigneesByLocation: _.getNonEmpty(
    calendarLocalStorage(),
    calendarLocationAssigneesStorageKey,
    {}
  ),
  selectedCategoryIds: _.getNonEmpty(
    calendarLocalStorage(),
    calendarCategoriesStorageKey,
    []
  ),
  selectedStatusIds: getInitialSelectedStatuses(),
  slotDuration: _.getNonEmpty(
    calendarLocalStorage(),
    slotDurationStorageKey,
    null
  ),
  isCalendarAssigneesListToggled: _.getNonEmpty(
    calendarLocalStorage(),
    toggleCalendarAssigneesListStorageKey,
    true
  ),
  isCalendarCategoryListToggled: _.getNonEmpty(
    calendarLocalStorage(),
    toggleCalendarCategoryListStorageKey,
    true
  ),
  isCalendarStatusListToggled: _.getNonEmpty(
    calendarLocalStorage(),
    toggleCalendarStatusListStorageKey,
    true
  ),
  calendarWaitingList: {
    loadingList: false,
    list: []
  },
  queriedEvents: {
    list: [],
    loadingList: false,
    pagination: {
      resultCount: 0,
      pageCount: 0,
      limit: CALENDAR_SEARCH_RESULTS_LIMIT,
      page: 1
    },
    queryString: '',
    filter: {
      categoryIds: [],
      eventStatuses: [],
      fromDate: undefined,
      toDate: undefined,
      assigneeIds: []
    }
  },
  calendarScrollTime: moment().subtract(0.5, 'hours').format('HH:mm:ss'),
  calendarTitle: '',
  assignees: { list: [], loadingList: false }
});

// eslint-disable-next-line default-param-last
const calendarReducer = (state = initialState(), action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;
    let events;

    switch (type) {
      case GET_EVENTS_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_EVENTS_SUCCESS:
        events = _.getNonEmpty(payload, 'response.data', []);

        // TODO: This is here to support switching between event types so that they get proper status if transformed to encounter
        const mappedEvents = _.map(events, (event) => {
          if (_.includes([EVENT_TYPE_OTHER, EVENT_TYPE_REMINDER], event.type)) {
            delete event.confirmed;
            event.status = EVENT_STATUS_SCHEDULED;
          }

          return event;
        });

        draft.loadingList = false;
        draft.list = mappedEvents;
        break;
      case GET_EVENTS_FAILURE:
        draft.loadingList = false;
        break;
      case GET_EVENT_PATIENT_IN_PROGRESS:
        draft.eventPatient.loading = true;
        break;
      case GET_EVENT_PATIENT_SUCCESS:
        const eventPatient = _.get(payload, 'response.data', null);

        draft.eventPatient.data = eventPatient;
        draft.eventPatient.loading = false;
        break;
      case GET_EVENT_PATIENT_FAILURE:
        draft.eventPatient.data = null;
        draft.eventPatient.loading = false;
        break;
      case SET_EVENT_PATIENT:
        draft.eventPatient.data = payload.data;
        break;
      case CLEAR_EVENT_PATIENT:
        draft.eventPatient.data = null;
        break;
      case ADD_EVENT:
        const detail = _.get(payload, 'response.data', {});

        draft.event = detail;
        break;
      case SET_CURRENT_CALENDAR_VIEW:
        draft.currentCalendarView = payload.view;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [calendarViewStorageKey]: draft.currentCalendarView
        });
        break;
      case SET_CALENDAR_EVENT_COLOR_SOURCE:
        draft.calendarEventColorSource = payload.color;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [calendarColorSourceStorageKey]: draft.calendarEventColorSource
        });
        break;
      case TOGGLE_CALENDAR_ASSIGNEES_LIST:
        draft.isCalendarAssigneesListToggled = payload.isToggled;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [toggleCalendarAssigneesListStorageKey]:
            draft.isCalendarAssigneesListToggled
        });
        break;
      case TOGGLE_CALENDAR_CATEGORY_LIST:
        draft.isCalendarCategoryListToggled = payload.isToggled;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [toggleCalendarCategoryListStorageKey]:
            draft.isCalendarCategoryListToggled
        });
        break;
      case TOGGLE_CALENDAR_STATUS_LIST:
        draft.isCalendarStatusListToggled = payload.isToggled;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [toggleCalendarStatusListStorageKey]:
            draft.isCalendarStatusListToggled
        });
        break;
      case SET_SELECTED_CALENDAR_ASSIGNEES:
        const localStorageObj = getLocalStorageValue(calendarStorageKeys, {});

        _.set(
          localStorageObj,
          `${calendarLocationAssigneesStorageKey}.${payload.locationId}`,
          payload.assigneeIds
        );

        setLocalStorageValue(calendarStorageKeys, localStorageObj);

        draft.selectedAssigneesByLocation = _.getNonEmpty(
          localStorageObj,
          calendarLocationAssigneesStorageKey,
          []
        );
        break;
      case REMOVE_SELECTED_CALENDAR_ASSIGNEE:
        const assigneeId = payload.assigneeId;
        const currentLocalStorageObj = getLocalStorageValue(
          calendarStorageKeys,
          {}
        );
        const currentSelectedAssigneesByLocation = _.getNonEmpty(
          currentLocalStorageObj,
          calendarLocationAssigneesStorageKey,
          []
        );

        _.forIn(currentSelectedAssigneesByLocation, (locationAssignees) => {
          _.pull(locationAssignees, assigneeId);
        });

        _.set(
          currentLocalStorageObj,
          calendarLocationAssigneesStorageKey,
          currentSelectedAssigneesByLocation
        );

        setLocalStorageValue(calendarStorageKeys, currentLocalStorageObj);

        draft.selectedAssigneesByLocation = _.getNonEmpty(
          currentLocalStorageObj,
          calendarLocationAssigneesStorageKey,
          []
        );
        break;
      case SET_SELECTED_CALENDAR_CATEGORIES:
        draft.selectedCategoryIds = payload.categoryIds;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [calendarCategoriesStorageKey]: draft.selectedCategoryIds
        });
        break;
      case SET_SELECTED_CALENDAR_STATUSES:
        const allStatusesIds = _.cloneDeep(calendarEventStatusesListIds);

        const blackList = _.filter(
          allStatusesIds,
          (status) => !_.includes(payload.statusIds, status)
        );

        const whiteList = _.filter(
          allStatusesIds,
          (status) => !_.includes(blackList, status)
        );

        draft.selectedStatusIds = whiteList;

        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [calendarStatusesStorageKey]: blackList
        });
        break;
      case SET_SELECTED_CALENDAR_SEARCH_ASSIGNEES:
        draft.queriedEvents.filter.assigneeIds = payload.assigneeIds;
        break;
      case SET_SELECTED_CALENDAR_SEARCH_CATEGORIES:
        draft.queriedEvents.filter.categoryIds = payload.categoryIds;
        break;
      case SET_SELECTED_CALENDAR_SEARCH_EVENT_STATUSES:
        draft.queriedEvents.filter.eventStatuses = payload.eventStatuses;
        break;
      case SET_CALENDAR_FILTER_SELECTED_DATE_RANGE:
        const to = _.get(payload, 'toDate');
        const from = _.get(payload, 'fromDate');

        draft.queriedEvents.filter.fromDate = isValidDate(from)
          ? moment(from).toISOString()
          : undefined;
        draft.queriedEvents.filter.toDate = isValidDate(to)
          ? moment(to).toISOString()
          : undefined;
        break;
      case SET_CALENDAR_SELECTED_DATE_RANGE:
        const toDate = _.get(payload, 'toDate');
        const fromDate = _.get(payload, 'fromDate');

        draft.toDate = toDate;
        draft.fromDate = fromDate;
        break;
      case GET_EVENT_MESSAGES_SUCCESS:
        draft.messageList = _.get(payload, 'response.data', []);
        break;
      case CLEAR_EVENT_MESSAGES:
        draft.messageList = [];
        break;
      case SET_SLOT_DURATION:
        draft.slotDuration = payload.data;
        setLocalStorageValue(calendarStorageKeys, {
          ...getLocalStorageValue(calendarStorageKeys, {}),
          [slotDurationStorageKey]: draft.slotDuration
        });
        break;
      case GET_CALENDAR_WAITING_LIST_IN_PROGRESS:
        draft.calendarWaitingList.loadingList = true;
        break;
      case GET_CALENDAR_WAITING_LIST_FAILURE:
        draft.calendarWaitingList.loadingList = false;
        draft.calendarWaitingList.list = [];
        break;
      case GET_CALENDAR_WAITING_LIST_SUCCESS:
        draft.calendarWaitingList.loadingList = false;
        draft.calendarWaitingList.list = _.get(payload, 'response.data', []);
        break;
      case GET_EVENTS_WITH_QUERY_SUCCESS:
        const queriedEvents = _.get(payload, 'response.data', {});
        const responsePayload = _.getNonEmpty(payload, 'response', payload);

        _.map(queriedEvents, (item) => {
          item.entityType = SEARCH_RESULT_ENTITY_TYPE_EVENT;

          return item;
        });

        const maxResultsRaw = _.getNonEmpty(
          responsePayload,
          'headers.x-total-count',
          draft.queriedEvents.pagination.limit
        );
        const maxResults = _.parseInt(maxResultsRaw);

        draft.queriedEvents.pagination.resultCount = maxResults;
        draft.queriedEvents.pagination.pageCount = _.ceil(
          maxResults / draft.queriedEvents.pagination.limit
        );

        draft.queriedEvents.list = queriedEvents;
        break;
      case SET_SEARCH_RESULTS_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.queriedEvents.pagination[paginationName] = paginationValue;
        }
        break;
      case SET_EVENT_QUERY_STRING:
        draft.queriedEvents.queryString = payload.queryString;
        break;
      case CLEAR_SEARCH_QUERY_PAGINATION_AND_RESULTS:
        draft.queriedEvents.queryString = '';
        draft.queriedEvents.list = [];
        draft.queriedEvents.pagination = {
          resultCount: 0,
          pageCount: 0,
          limit: CALENDAR_SEARCH_RESULTS_LIMIT,
          page: 1
        };

        break;

      case CLEAR_SEARCH_PAGINATION_AND_RESULTS:
        draft.queriedEvents.list = [];
        draft.queriedEvents.pagination = {
          resultCount: 0,
          pageCount: 0,
          limit: CALENDAR_SEARCH_RESULTS_LIMIT,
          page: 1
        };

        break;
      case GET_EVENTS_WITH_QUERY_FAILURE:
        draft.queriedEvents.list = [];
        draft.queriedEvents.loadingList = false;
        break;
      case GET_EVENTS_WITH_QUERY_IN_PROGRESS:
        draft.queriedEvents.loadingList = true;
        break;
      case SET_CALENDAR_SCROLL_TIME:
        draft.calendarScrollTime = moment(payload.scrollTime).format(
          'HH:mm:ss'
        );
        break;
      case SET_PREFILLED_EVENT_DATA:
        draft.prefilledEventData.isDataPrefilled = true;
        draft.prefilledEventData.eventData = _.getNonEmpty(
          payload,
          'data.eventData',
          {}
        );
        draft.prefilledEventData.prefillType = _.getNonEmpty(
          payload,
          'data.prefillType',
          null
        );
        break;
      case CLEAR_PREFILLED_EVENT_DATA:
        draft.prefilledEventData.isDataPrefilled = false;
        draft.prefilledEventData.eventData = {};
        draft.prefilledEventData.prefillType = null;
        break;
      case UPDATE_SELECTED_LOCATION:
        /**
         * Clear any prefilled event data and close the alert when the location
         * was changed
         */
        draft.prefilledEventData.isDataPrefilled = false;
        draft.prefilledEventData.eventData = {};
        draft.prefilledEventData.prefillType = null;
        hideAlertWithId(FULL_WIDTH_TOAST_ID);
        break;
      case SET_CALENDAR_TITLE:
        draft.calendarTitle = payload.title;
        break;
      case SET_HIGHLIGHT_EVENT_ID:
        draft.highlightEventId = _.getNonEmpty(payload, 'id', null);
        break;
      case CLEAR_HIGHLIGHT_EVENT_ID:
        draft.highlightEventId = null;
        break;
      case GET_CALENDAR_ASSIGNEES_IN_PROGRESS:
        draft.assignees.loadingList = true;
        break;
      case GET_CALENDAR_ASSIGNEES_FAILURE:
        draft.assignees.list = [];
        draft.assignees.loadingList = false;
        break;
      case GET_CALENDAR_ASSIGNEES_SUCCESS:
        draft.assignees.list = _.get(payload, 'data', []);
        draft.assignees.loadingList = false;
        break;
      case CLEAR_SELECTED_CALENDAR_ASSIGNEES:
        draft.selectedAssigneesByLocation = {};
        break;
      case CLEAR_CALENDAR_ASSIGNEES_LIST:
        draft.assignees.list = [];
        break;
      case GET_CALENDAR_EVENT_WITH_QUERY_IN_PROGRESS:
        draft.event = null;
        draft.loadingEvent = true;
        break;
      case GET_CALENDAR_EVENT_WITH_QUERY_FAILURE:
        draft.event = null;
        draft.loadingEvent = false;
        break;
      case GET_CALENDAR_EVENT_WITH_QUERY_SUCCESS:
        const event = _.getNonEmpty(payload, 'response.data', {});

        draft.event = event;
        draft.loadingEvent = false;
        break;
      case LOGOUT:
        return initialState();
      default:
    }
  });

export default calendarReducer;
