import moment from 'moment';

export const ONGOING_WEEKLY = 'weekly';
export const ONGOING_NO_REPEAT = 'no-repeat';
export const ONGOING = 'ongoing';
export const ONGOING_TILL_DATE = 'specific-date';

export const OVERWRITE_ALL_ACTION = 'overwrite';
export const OVERWRITE_ONE_TIME_ACTION = 'writeOneTime';

export const DELETE_ONE_TIME_ACTION = 'deleteOneTime';
export const DELETE_ALL_ACTION = 'deleteAllOngoing';
export const DELETE_NON_REPEATING = 'deleteNonRepeat';

export const NEVER_ENDING_DATE = '2120-01-01';

export const WORKING_HOURS_TYPE_HOLIDAY = 'holiday';
export const WORKING_HOURS_TYPE_CLOSED = 'closed';
export const WORKING_HOURS_TYPE_OPEN = 'open';
export const WORKING_HOURS_TYPE_VACATION = 'vacation';

export const DELETED_WORKING_HOUR_TIME = '00:00';

export const isNeverEndingDate = (date) =>
  moment(date).format('YYYY-MM-DD') === NEVER_ENDING_DATE;

const closedDateType = [
  {
    from: DELETED_WORKING_HOUR_TIME,
    to: DELETED_WORKING_HOUR_TIME,
    type: WORKING_HOURS_TYPE_CLOSED
  }
];

export const ALL_CLOSED_DAYS = {
  monday: closedDateType,
  tuesday: closedDateType,
  wednesday: closedDateType,
  thursday: closedDateType,
  friday: closedDateType,
  saturday: closedDateType,
  sunday: closedDateType
};

export const ASSIGNEE_TYPE_LOCATION = 'location';
