import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import axios from 'axios';
import _ from 'lodash';

import { showAlertSuccess } from '../../../utils/alert';
import {
  getDocumentImagesPreviewUrl,
  getOutboundDocumentDetailUrl,
  getOutboundDocumentPreviewUrl,
  getOutboundDocumentUrl
} from '../../../utils/api/apiUrlUtils';
import { MOSHI_FILE_TYPE_PDF } from '../../../utils/constants/fileConstants';
import {
  isDocumentTemplate,
  mapDuplicatedOutboundDocument
} from '../../../utils/documentUtils';

import { EMPTY_ACTION_TYPE } from '../../common/actionTypes';
import {
  downloadFileByUrl,
  printFileByUrl,
  uploadFile
} from '../../core/file/fileActions';
import {
  webSocketSubscribe,
  webSocketUnsubscribe
} from '../../core/web-socket/webSocketAction';
import { getAllDocumentTemplatesList } from '../../form/allDocumentTemplates/allDocumentTemplatesActions';
import { getAnyDocumentTypeFormSchema } from '../../form/allDocumentTypes/allDocumentTypesAction';

import {
  ADD_OUTBOUND_DOCUMENT,
  CLEAR_OUTBOUND_DETAILS,
  CLEAR_OUTBOUND_PREVIEW_PDF_URL,
  DELETE_OUTBOUND_DOCUMENT,
  GET_OUTBOUND_DOCUMENT,
  GET_OUTBOUND_PREVIEW,
  SET_OUTBOUND_PDF_GENERATION_STATUS,
  SET_OUTBOUND_PREVIEW_PDF_URL,
  UPDATE_OUTBOUND_DOCUMENT
} from './outboundDocumentTypes';
import {
  ADD_OUTBOUND_TEMPLATE,
  REMOVE_OUTBOUND_TEMPLATE
} from './template/outboundDocumentTemplateTypes';

import {
  allDocumentRoutes,
  baseListRoutes,
  documentBaseRoute,
  outboundDocumentDetailRoutes,
  patientDocumentBaseRoute
} from '../../../containers/documents/documentRoutes';

export const getOutboundDocument = (documentId, onSuccess = _.noop) => (
  dispatch,
  getState
) => {
  const outboundDocumentDetailUrl = getOutboundDocumentDetailUrl(
    getState,
    documentId
  );

  return dispatch({
    type: GET_OUTBOUND_DOCUMENT,
    apiCall: axios.get(outboundDocumentDetailUrl),
    onSuccess: (unused, response) => {
      onSuccess(response);
    }
  });
};

export const addOutboundDocument = (
  documentData,
  onSuccess = _.noop,
  actionType = ADD_OUTBOUND_DOCUMENT
) => (dispatch, getState) => {
  const outboundDocumentUrl = getOutboundDocumentUrl(getState);

  return dispatch({
    type: actionType,
    apiCall: axios.post(outboundDocumentUrl, documentData),
    onSuccess: (unused, response) => {
      onSuccess(response);
      const alertMsg = isDocumentTemplate(documentData)
        ? 'document:alert.templateCreated'
        : 'document:alert.documentCreated';

      showAlertSuccess(alertMsg);
    }
  });
};

export const updateOutboundDocument = (
  documentId,
  documentData,
  onSuccess = _.noop,
  actionType = UPDATE_OUTBOUND_DOCUMENT
) => (dispatch, getState) => {
  const outboundDocumentDetailUrl = getOutboundDocumentDetailUrl(
    getState,
    documentId
  );

  return dispatch({
    type: actionType,
    apiCall: axios.put(outboundDocumentDetailUrl, documentData),
    onSuccess: (unused, response) => {
      onSuccess(response);
      const alertMsg = isDocumentTemplate(document)
        ? 'document:alert.templateUpdated'
        : 'document:alert.documentUpdated';

      showAlertSuccess(alertMsg);
      dispatch(getOutboundDocument(documentId));
    }
  });
};

export const baseAddOrUpdateOutboundDocument = (
  document,
  onSuccess = undefined,
  addActionType = undefined,
  updateActionType = undefined
) => (dispatch) => {
  const documentId = _.getNonEmpty(document, 'id', null);

  if (!_.isEmptySafe(documentId)) {
    return dispatch(
      updateOutboundDocument(documentId, document, onSuccess, updateActionType)
    );
  }

  return dispatch(addOutboundDocument(document, onSuccess, addActionType));
};

export const addOrUpdateOutboundDocumentWithRedirect = (
  patientId,
  document,
  baseRoute
) => (dispatch) => {
  const onSuccess = (response) => {
    const routes = allDocumentRoutes[baseRoute];

    const detailRoute = generatePath(routes.OUTBOUND_DETAIL, {
      patientId: _.getNonEmpty(response, 'data.patientID', patientId),
      id: _.getNonEmpty(response, 'data.id', null)
    });

    dispatch(setOutboundPdfGenerationStatus(true));
    dispatch(push(detailRoute));
  };

  if (isDocumentTemplate(document)) {
    delete document.id;
    delete document.template;
  }

  return dispatch(baseAddOrUpdateOutboundDocument(document, onSuccess));
};

export const addOrUpdateOutboundDocumentTemplate = (document) => (dispatch) => {
  const documentTemplate = { ...document, template: true };

  delete documentTemplate.patientID;

  const onSuccess = (response) => {
    const detailRoute = generatePath(
      outboundDocumentDetailRoutes.OUTBOUND_NEW_FROM_TEMPLATE,
      {
        id: _.getNonEmpty(response, 'data.id', null)
      }
    );

    dispatch(getAllDocumentTemplatesList());
    dispatch(push(detailRoute));
  };

  return dispatch(
    baseAddOrUpdateOutboundDocument(
      documentTemplate,
      onSuccess,
      ADD_OUTBOUND_TEMPLATE
    )
  );
};

export const saveAsNewOutboundDocumentTemplate = (document) => (dispatch) => {
  delete document.id;

  return dispatch(addOrUpdateOutboundDocumentTemplate(document));
};

export const removeOutboundDocument = (documentId, isTemplate = false) => (
  dispatch,
  getState
) => {
  const outboundDocumentDetailUrl = getOutboundDocumentDetailUrl(
    getState,
    documentId
  );

  const action = isTemplate
    ? REMOVE_OUTBOUND_TEMPLATE
    : DELETE_OUTBOUND_DOCUMENT;

  return dispatch({
    type: action,
    apiCall: axios.delete(outboundDocumentDetailUrl),
    onSuccess: () => {
      if (isTemplate) {
        showAlertSuccess('document:alert.templateRemoved');
      } else {
        showAlertSuccess('document:alert.documentRemoved');
      }
    }
  });
};

export const getOutboundDocumentPreview = (document) => (
  dispatch,
  getState
) => {
  const outboundDocumentPreviewUrl = getOutboundDocumentPreviewUrl(getState);

  const payload = {
    body: _.getNonEmpty(document, 'body', ''),
    patientID: _.getNonEmpty(document, 'patientID', undefined),
    hasHeaderFooter: _.getNonEmpty(document, 'hasHeaderFooter', true)
  };

  return dispatch({
    type: GET_OUTBOUND_PREVIEW,
    apiCall: axios.post(outboundDocumentPreviewUrl, payload, {
      responseType: 'arraybuffer'
    }),
    onSuccess: (unused, response) => {
      const data = _.getNonEmpty(response, 'data', null);

      if (!_.isEmptySafe(data)) {
        dispatch(setPreviewPdfUrl(data));
      }
    }
  });
};

export const setPreviewPdfUrl = (pdf) => (dispatch) => {
  const file = new Blob([pdf], { type: MOSHI_FILE_TYPE_PDF });
  const pdfBlob = URL.createObjectURL(file);

  return dispatch({
    type: SET_OUTBOUND_PREVIEW_PDF_URL,
    pdfBlob
  });
};

export const clearPreviewPdfUrl = () => (dispatch) =>
  dispatch({
    type: CLEAR_OUTBOUND_PREVIEW_PDF_URL
  });

export const clearOutboundDocumentDetails = () => (dispatch) =>
  dispatch({
    type: CLEAR_OUTBOUND_DETAILS
  });

export const downloadOutboundDocumentPdf = (documentUrl, fileName) => (
  dispatch
) => dispatch(downloadFileByUrl(EMPTY_ACTION_TYPE, documentUrl, fileName));

export const printOutboundDocumentPdf = (documentUrl) => (dispatch) =>
  dispatch(printFileByUrl(EMPTY_ACTION_TYPE, documentUrl, MOSHI_FILE_TYPE_PDF));

export const handleOutboundDocumentPdfUrl = (payload) => (dispatch) => {
  const documentId = _.getNonEmpty(payload, 'entityID');

  dispatch(setOutboundPdfGenerationStatus(false));

  return dispatch(getOutboundDocument(documentId));
};

export const subscribeToOutboundDocumentDetail = (documentId) => (
  dispatch,
  getState
) => {
  const websocketChannelId = `OUTBOUND-DOCUMENT-${documentId}`;
  const outboundDocumentUrl = getOutboundDocumentUrl(getState);

  return dispatch(
    webSocketSubscribe(
      websocketChannelId,
      `${outboundDocumentUrl}/${documentId}`
    )
  );
};

export const unsubscribeToOutboundDocumentDetail = (documentId) => (
  dispatch
) => {
  const websocketChannelId = `OUTBOUND-DOCUMENT-${documentId}`;

  return dispatch(webSocketUnsubscribe(websocketChannelId));
};

export const saveOutboundDocumentAsTemplate = (
  document,
  onSuccess = _.noop
) => (dispatch) => {
  const onGetFormSchemaSuccess = (response) => {
    const data = _.getNonEmpty(response, 'data');
    const outboundDocumentData = mapDuplicatedOutboundDocument(data);

    dispatch(clearOutboundDocumentDetails());

    return dispatch(addOutboundDocument(outboundDocumentData, onSuccess));
  };

  return dispatch(
    getAnyDocumentTypeFormSchema(document, onGetFormSchemaSuccess)
  );
};

export const saveOutboundDocumentAsTemplateWithRedirect = (
  patientId,
  routes,
  document
) => (dispatch) => {
  const onSuccess = (response) => {
    const generatedPath = generatePath(routes.OUTBOUND_DETAIL_EDIT, {
      id: _.getNonEmpty(response, 'data.id'),
      patientId
    });

    return dispatch(push(generatedPath));
  };

  dispatch(saveOutboundDocumentAsTemplate(document, onSuccess));
};

export const addOutboundDocumentImage = (file, meta, options) => (
  dispatch,
  getState
) => {
  const outboundDocumentImageUploadUrl = getDocumentImagesPreviewUrl(getState);

  return dispatch(
    uploadFile(outboundDocumentImageUploadUrl, file, { meta }, options)
  );
};

export const closeOutboundDocumentDetail = (baseRoute, patientId) => (
  dispatch
) => {
  let path = baseRoute;

  if (_.includes([documentBaseRoute, patientDocumentBaseRoute], path)) {
    path = `${baseRoute}${baseListRoutes.ALL}`;
  }

  const generatedBackPath = generatePath(path, {
    patientId
  });

  dispatch(push(generatedBackPath));
  dispatch(clearOutboundDocumentDetails());
};

export const closeOutboundDocumentTemplate = (baseRoute, patientId) => (
  dispatch
) => {
  let path = baseRoute;

  // Checks if the path equals default base and returns correct path
  if (_.includes([documentBaseRoute, patientDocumentBaseRoute], path)) {
    const pathSuffix = patientId ? baseListRoutes.ALL : baseListRoutes.TEMPLATE;

    path = `${baseRoute}${pathSuffix}`;
  }

  const generatedBackPath = generatePath(path, {
    patientId
  });

  dispatch(push(generatedBackPath));
  dispatch(clearOutboundDocumentDetails());
};

export const closeDocumentDetailAction = (baseRoute, patientId, isTemplate) => (
  dispatch
) => {
  if (isTemplate) {
    dispatch(closeOutboundDocumentTemplate(baseRoute, patientId));

    return;
  }

  dispatch(closeOutboundDocumentDetail(baseRoute, patientId));
};

export const setOutboundPdfGenerationStatus = (isGenerating) => (dispatch) =>
  dispatch({ type: SET_OUTBOUND_PDF_GENERATION_STATUS, isGenerating });
