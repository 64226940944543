/* eslint-disable camelcase */
import { ENTITY_STATUS_DELETED } from '../../utils/alert';
import {
  THERAPY_STATUS_ACTIVE,
  THERAPY_STATUS_FINISHED,
  THERAPY_STATUS_IN_PROGRESS,
  THERAPY_STATUS_PAUSED,
  THERAPY_STATUS_STOPPED,
  DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED,
  DRUG_INTAKE_STATUS_CONFIRMATION_SENT,
  DRUG_INTAKE_STATUS_LATE,
  DRUG_INTAKE_STATUS_NO_REPLY,
  DRUG_INTAKE_STATUS_NOT_TAKEN,
  DRUG_INTAKE_STATUS_PAUSED,
  DRUG_INTAKE_STATUS_PENDING,
  DRUG_INTAKE_STATUS_REMINDER_SENT,
  DRUG_INTAKE_STATUS_STOPPED,
  DRUG_INTAKE_STATUS_TAKEN_LATE,
  DRUG_INTAKE_STATUS_TAKEN_ON_TIME,
  DRUG_INTAKE_STATUS_UPCOMING,
  THERAPY_TYPE_SCHEDULED,
  THERAPY_TYPE_MANUAL,
  THERAPY_INTAKE_TYPE_GENERATED,
  THERAPY_INTAKE_TYPE_MANUAL,
  DRUG_INTAKE_STATUS_REMINDER_NOT_SENT
} from '../../utils/constants/therapyConstants';

import { formatting } from './date';

export default {
  title: 'Terapije',
  header: 'Terapija',
  removeTitle: 'Terapijo',
  create: 'Nova terapija',
  edit: 'Uredite terapijo',
  drug: '$t(drug:singleTitle)',
  intake: 'Odmerek',
  intakes: 'Odmerki',
  indefiniteTherapy: 'Terapija nima določenega zaključka',
  indefiniteTherapyDescription:
    'Terapija bo tekla, dokler je uporabnik ne zaključi ročno.',
  addIntake: 'Dodajte odmerek',
  routeOfAdmission: 'Pot uporabe',
  notificationSettings: 'Nastavitve obveščanja',
  instructions: 'Navodila',
  eventTimeLabel: 'Čas obvestila',
  resumeEventTimeLabel: 'Čas nadaljevanja',
  sendReminder: 'Pošljite opomnik pacientu pred naslednjim odmerkom.',
  sendReminderDescription:
    'Pacient bo prejel SMS opomnik pred naslednjim predvidenim odmerkom.',
  askForConfirmation: 'Preverite, ali je pacient pravočasno zaužil zdravilo.',
  askForConfirmationDescription:
    'Z izbiro te funkcije bo poslanemu SMS opomniku priložena povezava, ki bo pacienta vodila na spletno stran, ' +
    'kjer bo pacient pozvan, da vnese povratno informacijo, ali je zdravilo zaužil pravočasno',
  patientWillReceiveReminder: 'Pacient bo prejel SMS opomnik.',
  patientWillNotReceiveReminder:
    'SMS opomnik ne bo poslan, ker je datum vnosa danes ali v preteklosti.',
  isScheduled: 'Generirajte odmerke vnaprej',
  isScheduledDescription:
    'Odmerke terapije lahko generirate vnaprej v skladu z definirano frekvenco. Odmerke lahko vedno dodate na terapijo tudi ročno.',
  therapyType: {
    [THERAPY_TYPE_SCHEDULED]: 'Nastavljena',
    [THERAPY_TYPE_MANUAL]: 'Ročna'
  },
  quantitySuffix: {
    mg: 'mg',
    ml: 'ml',
    ie: 'ie',
    pills: 'tableta',
    pills_0: 'tablet',
    pills_1: 'tableta',
    pills_2: 'tableti',
    pills_3: 'tablete',
    pills_4: 'tablete',
    pills_5: 'tablet',
    unknown: 'neznano'
  },
  timeSuffix: {
    hours: 'ura',
    hours_0: 'ur',
    hours_1: 'uro',
    hours_2: 'uri',
    hours_3: 'ure',
    hours_4: 'ure',
    hours_5: 'ur',
    days: 'dan',
    days_0: 'dni',
    days_1: 'dan',
    days_2: 'dni',
    days_3: 'dni',
    days_4: 'dni',
    days_5: 'dni',
    weeks: 'teden',
    weeks_0: 'tednov',
    weeks_1: 'teden',
    weeks_2: 'tedna',
    weeks_3: 'tedne',
    weeks_4: 'tedne',
    weeks_5: 'tednov',
    months: 'mesec',
    months_0: 'mesecev',
    months_1: 'mesec',
    months_2: 'meseca',
    months_3: 'mesece',
    months_4: 'mesece',
    months_5: 'mesecev',
    years: 'leto',
    years_0: 'let',
    years_1: 'leto',
    years_2: 'leti',
    years_3: 'leta',
    years_4: 'leta',
    years_5: 'let'
  },
  timeUnit: {
    hours: '$t(therapy:timeSuffix.hours)',
    days: '$t(therapy:timeSuffix.days)',
    weeks: '$t(therapy:timeSuffix.weeks)',
    months: '$t(therapy:timeSuffix.months)',
    years: '$t(therapy:timeSuffix.years)'
  },
  frequencyPrefix_0: 'vsakih',
  frequencyPrefix_1: 'vsak',
  frequencyPrefix_2: 'vsake',
  frequencyPrefix_3: 'vsake',
  frequencyPrefix_4: 'vsake',
  frequencyPrefix_5: 'vsakih',
  durationPrefix: 'naslednje',
  indefiniteDurationPrefix: 'za',
  indefiniteDuration: 'Nedoločen čas',
  noStartDate: 'Ni datuma začetka',
  frequencyDisplay: '$t(therapy:frequencyPrefix) $t(common:valueWithSuffix)',
  emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih terapij.</0> <1></1>
      <0>Dodajte novo terapijo.</0>
    `,
  pastTherapyList: {
    title: 'Pretekle terapije'
  },
  activeTherapyTile: {
    therapyStatus: 'Status odmerka',
    status: {
      active: 'Aktivna terapija',
      past: 'Pretekla terapija',
      paused: 'Začasno ustavljena terapija',
      stopped: 'Zaključena terapija'
    },
    manuallyStoppedTherapy: 'Terapija ročno zaključena',
    reasonForCancelation: 'Razlog zaključka',
    reasonForCancelationStatus: 'Razlog: {{ reasonForCancelationName }}',
    new: 'Nova terapija',
    edit: 'Uredite terapijo',
    create: 'Dodajte terapijo',
    selectTherapyTemplate: 'Izberite predlogo za terapijo',
    startDate: 'Začetek',
    dateWithDoctor: `{{ date, ${formatting.longDateWithTime} }} ∙ DR. {{ user.firstName }} {{ user.lastName }}`,
    therapyNotStartedYet: 'Se še ni začela',
    infoStart: `ZAČETEK: {{ startAt, ${formatting.shortDate} }} ∙ `,
    infoNoStart: `ZAČETEK: $t(therapy:noStartDate) ∙ `,
    infoEnd: `KONEC: {{ endsAt, ${formatting.shortDate} }} ∙ `,
    info:
      '$t(therapy:activeTherapyTile.infoStart, { "startAt": "{{ startAt }}" })' +
      '$t(therapy:activeTherapyTile.infoEnd, { "endsAt": "{{ endsAt }}" })' +
      'V TERAPIJI: {{ duration }} $t(therapy:timeSuffix.days, { "count": {{ duration }} })',
    infoWithoutDuration:
      `$t(therapy:activeTherapyTile.infoNoStart)` +
      '$t(therapy:activeTherapyTile.infoEnd, { "endsAt": "{{ endsAt }}" })' +
      'V TERAPIJI: ?',
    infoPaused:
      '$t(therapy:activeTherapyTile.infoStart, { "startAt": "{{ startAt }}" })' +
      'USTAVLJENO',
    contactAndTime: `Opomnik bo poslan ob <0>{{ time, ${formatting.time} }}</0> na <0>{{ phoneNumber }}</0>.`,
    intakeProgress: '<0>{{ current }}</0> / {{ target }}',
    pauseTherapy: 'Začasno ustavite',
    resumeTherapy: 'Nadaljujte',
    resumeTherapyAt: 'Datum nadaljevanja terapije',
    endTherapy: 'Zaključite',
    showAllEvents: 'Prikažite vse odmerke',
    hideEvents: 'Skrijte odmerke',
    confirmStopTherapy: {
      title: 'Zaključite terapijo',
      message: 'Želite zaključiti terapijo: <0>{{ itemName }}</0>?'
    },
    confirmResumeTherapy: {
      title: 'Nadaljujte terapijo'
    },
    confirmRemoveTherapy: {
      title: 'Odstranite terapijo',
      message: 'Želite odstraniti terapijo: <0>{{ itemName }}</0>?'
    },
    addManualIntake: 'Dodajte odmerek'
  },
  drugIntake: {
    type: {
      [THERAPY_INTAKE_TYPE_MANUAL]: 'Ročno',
      [THERAPY_INTAKE_TYPE_GENERATED]: 'Generirano'
    },
    status: {
      [DRUG_INTAKE_STATUS_NOT_TAKEN]: 'Ni vzel',
      [DRUG_INTAKE_STATUS_TAKEN_LATE]: 'Vzel z zamudo',
      [DRUG_INTAKE_STATUS_TAKEN_ON_TIME]: 'Vzel pravočasno',
      [DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED]: 'Poslan',
      [DRUG_INTAKE_STATUS_LATE]: 'Čaka na odgovor',
      [DRUG_INTAKE_STATUS_NO_REPLY]: 'Ni odgovora',
      [DRUG_INTAKE_STATUS_PENDING]: `$t(therapy:drugIntake.status.${DRUG_INTAKE_STATUS_LATE})`,
      [DRUG_INTAKE_STATUS_CONFIRMATION_SENT]: `$t(therapy:drugIntake.status.${DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED})`,
      [DRUG_INTAKE_STATUS_REMINDER_SENT]: `$t(therapy:drugIntake.status.${DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED})`,
      [DRUG_INTAKE_STATUS_REMINDER_NOT_SENT]: 'Ni poslan',
      [DRUG_INTAKE_STATUS_PAUSED]: 'Začasno ustavljeno',
      [DRUG_INTAKE_STATUS_STOPPED]: 'Zaključeno',
      [ENTITY_STATUS_DELETED]: 'Odstranjeno',
      [DRUG_INTAKE_STATUS_UPCOMING]: 'Predvideno'
    },
    statusDescription: {
      [DRUG_INTAKE_STATUS_NOT_TAKEN]:
        'Pacient predvidenega odmerka zdravila ni vzel.',
      [DRUG_INTAKE_STATUS_TAKEN_LATE]:
        'Pacient je predviden odmerek zdravila vzel z več kot 24 urno zamudo.',
      [DRUG_INTAKE_STATUS_TAKEN_ON_TIME]:
        'Pacient je predviden odmerek zdravila vzel pravočasno (v 24h od prejema opomnika).',
      [DRUG_INTAKE_STATUS_CONFIRMATION_RECEIVED]: 'Pacient je odgovoril',
      [DRUG_INTAKE_STATUS_LATE]:
        'Pacient še ni dogovoril, ali je vzel zdravilo. Od prejema opomnika je minilo več kot 24 ur.',
      [DRUG_INTAKE_STATUS_NO_REPLY]:
        'Pacient ni odgovoril, ali je vzel zdravilo. Od prejema opomnika je minilo več kot 7 dni.',
      [DRUG_INTAKE_STATUS_PENDING]:
        'Čaka na odgovor pacienta. Od prejema opomnika je minilo manj kot 24 ur.',
      [DRUG_INTAKE_STATUS_CONFIRMATION_SENT]: 'Opomnik je bil poslan.',
      [DRUG_INTAKE_STATUS_REMINDER_SENT]: 'Opomnik je bil poslan.',
      [DRUG_INTAKE_STATUS_REMINDER_NOT_SENT]: 'Opomnik ni bil poslan.',
      [DRUG_INTAKE_STATUS_PAUSED]: 'Terapija je začasno ustavljena.',
      [DRUG_INTAKE_STATUS_STOPPED]: 'Terapija je trajno ustavljena.',
      [ENTITY_STATUS_DELETED]: 'Opomnik ni bil poslan.',
      [DRUG_INTAKE_STATUS_UPCOMING]: 'Opomnik še ni bil poslan.'
    }
  },
  settings: {
    title: 'Nastavitve terapij',
    description:
      'Opomniki so sporočila, ki pacienta obvestijo o naslednjem jemanju zdravila.',
    text: {
      remindPatientHeader: 'Opomnite pacienta o jemanju zdravila',
      remindPatientDescription:
        'To sporočilo bo poslano pacientu na dan, ko mora vzeti zdravilo in v primeru, da ima v nastavitvah vklopljene SMS in e-poštne opomnike.',
      from: 'Od',
      message: 'Sporočilo'
    },
    legend: `
    <0>Legenda</0>
    <1>
    Pametne kode lahko vnesemo v besedilo in bodo
    samodejno zamenjane z ustreznimi podatki:
    </1>
    <1>[drug therapy instructions] - navodila.</1>
    <1>[drug therapy intake date] - datum zaužitja.</1>
    <1>[drug therapy intake time] - čas zaužitja.</1>
    <1>[location name] - lokacija ordinacije.</1>
    <1>[location phone number] - telefonska številka lokacije.</1>
    <1>[organization phone number] - telefonska številka organzacije.</1>
    <1>[doctor full name] - ime in priimek zdravnika.</1>
    <1>[doctor email] - elektronski naslov zdravnika</1>
    <1>[doctor phone number] - telefonska številka zdravnika</1>
    <1>[drug therapy name] - zdravilo.</1>
    <1>[drug therapy dosage] - doziranje.</1>
    <1>[drug therapy ask for confirmation] - vprašajte za potrditev.</1>
    <1>[drug therapy confirmation url] - povezava do potrditve.</1>
    <1>[if drug therapy instructions] - prikažite vsebino v primeru vklopljenih navodil.</1>
    <1>[if drug therapy ask for confirmation] - prikažite vsebino v primeru vklopljene potrditve.</1>
    <1>[end] - konec if bloka.</1>
    `
  },
  patientList: {
    status: {
      [THERAPY_STATUS_ACTIVE]: 'V teku',
      [THERAPY_STATUS_IN_PROGRESS]: `$t(therapy:patientList.status.${THERAPY_STATUS_ACTIVE})`,
      [THERAPY_STATUS_PAUSED]: 'Ustavljeno',
      [THERAPY_STATUS_STOPPED]: 'Prekinjeno',
      [THERAPY_STATUS_FINISHED]: 'Zaključeno'
    }
  },
  history: {
    created: `<0>Ustvaril/a</0> {{ userName }}, {{ date, ${formatting.shortDateWithTime} }}`,
    updated: `<0>Spremenil/a</0> {{ userName }}, {{ date, ${formatting.shortDateWithTime} }}`,
    paused: `<0>Začasno ustavil/a</0> {{ userName }}, {{ date, ${formatting.shortDateWithTime} }}`,
    resumed: `<0>Nadaljeval/a</0> {{ userName }}, {{ date, ${formatting.shortDateWithTime} }}`,
    stopped: `<0>Ustavil/a</0> {{ userName }}, {{ date, ${formatting.shortDateWithTime} }}`
  },
  validation: {
    endDateShorterThanToday: 'Izračunano trajanje se konča pred današnjim dnem'
  },
  warning: {
    endDateShorterThanToday: 'Izbran datum presega trajanje terapije.'
  }
};
