import _ from 'lodash';

import { translateKey } from '../../../../i18n';
import { BILLING_LOCALES } from '../../../constants/languageConstants';

import {
  BILLING_LOCALES_LIST_ACTIVE,
  BILLING_LOCALES_LIST_INACTIVE
} from '../../../data/billing';
import { getBillingLocales } from '../../../language';

export const getBillingLocaleLists = (enabledLocales, defaultLocale) => {
  const availableLocales = getBillingLocales(translateKey);

  return {
    [BILLING_LOCALES_LIST_ACTIVE]: {
      items: mapActiveBillingLocaleItems(
        enabledLocales,
        availableLocales,
        defaultLocale
      )
    },
    [BILLING_LOCALES_LIST_INACTIVE]: {
      title: translateKey('common:inactive.list'),
      items: mapInactiveBillingLocaleItems(enabledLocales, availableLocales)
    }
  };
};

const mapActiveBillingLocaleItems = (
  enabledLocales,
  availableLocales,
  defaultLocale
) => {
  const activeLanguages = _.filter(availableLocales, (locale) =>
    _.includes(enabledLocales, _.getNonEmpty(locale, 'id'))
  );
  const activeLanguageItems = _.map(activeLanguages, (locale) => ({
    ...locale,
    isDefault: _.getNonEmpty(locale, 'id') === defaultLocale
  }));

  return activeLanguageItems;
};

const mapInactiveBillingLocaleItems = (enabledLocales, availableLocales) => {
  const inactiveLocales = _.filter(
    availableLocales,
    (locale) => !_.includes(enabledLocales, _.getNonEmpty(locale, 'id'))
  );
  const inactiveLocaleItems = _.map(inactiveLocales, (locale) => ({
    ...locale,
    isDefault: false
  }));

  return inactiveLocaleItems;
};

export const getEnabledLocalesFromLists = (localeLists) => {
  const activeItems = _.getNonEmpty(
    localeLists,
    `${BILLING_LOCALES_LIST_ACTIVE}.items`
  );

  return _.map(activeItems, 'id');
};

export const getInsertedLocaleIndex = (addedLocale, localeListItems) => {
  const addedLocalePriority = _.indexOf(BILLING_LOCALES, addedLocale);

  let indexToInsert = 0;

  _.forEach(localeListItems, (listItem, index) => {
    const listItemPriority = _.indexOf(BILLING_LOCALES, listItem.id);

    if (listItemPriority > addedLocalePriority) {
      indexToInsert = index;

      return false;
    }

    indexToInsert = index + 1;
  });

  return indexToInsert;
};
