export default {
  title: 'Closed dates',
  closedDate: 'Closed date',
  newClosedDate: 'New closed dates',
  emptyListText: `
      <0>Hmmm. Seems like you don’t have closed dates yet.</0> <1></1>
      <0>Let’s start by adding a new one.</0>
    `,
  numOfDays: 'Number of days',
  descriptionPlaceholder: 'eg. public holiday',
  alerts: {
    deleted: 'Closed date was successfully removed'
  }
};
