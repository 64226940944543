import _ from 'lodash';

import { ASSIGNEE_TYPE_USER } from '../constants/calendarConstants';
import {
  REGISTRY_ANAMNESIS_ALLERGIES,
  REGISTRY_ANAMNESIS_CONCOMITANT_DRUGS,
  REGISTRY_ANAMNESIS_IMPLANTS,
  REGISTRY_ANAMNESIS_MEDICAL_CONDITIONS,
  REGISTRY_ANAMNESIS_OPERATIONS,
  REGISTRY_ANAMNESIS_VACCINATIONS,
  REGISTRY_PAYMENT_TYPES,
  REGISTRY_THERAPY_CANCELATION_REASONS,
  REGISTRY_THERAPY_DRUGS,
  REGISTRY_THERAPY_ROUTE_OF_ADMISSION,
  REGISTRY_THERAPY_TEMPLATES
} from '../constants/redux/registryConstants';

import { selectCachedOrganizationId } from '../../redux/core/cache/cacheSelectors';
import { selectRegistryId } from '../../redux/core/registry/registrySelectors';
import { selectCurrentLocationId } from '../../redux/location/locationSelectors';

import {
  showMissingEncounterIdError,
  showMissingLocationIdError,
  showMissingOrganizationIdError,
  showMissingUserIdError
} from '../alert';
import { mapEventAssignees } from '../mappers/calendar-mappers';

export const getOrganizationUrl = (getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmptySafe(organizationId)) {
    showMissingOrganizationIdError();

    return;
  }

  return `/organizations/${organizationId}`;
};

export const getOrganizationExportUrl = (getState) => {
  const orgUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(orgUrl)) {
    return;
  }

  return `${orgUrl}/export`;
};

export const getVersionsUrl = () => '/versions';

export const getOrganizationLocationsUrl = (getState) => {
  const orgPrefix = getOrganizationUrl(getState);

  if (_.isEmptySafe(orgPrefix)) {
    return;
  }

  return `${orgPrefix}/locations`;
};

export const getLocationUrl = (getState) => {
  const orgPrefix = getOrganizationUrl(getState);

  if (_.isEmptySafe(orgPrefix)) {
    return;
  }

  const locationId = selectCurrentLocationId(getState());

  if (_.isEmptySafe(locationId)) {
    showMissingLocationIdError();

    return;
  }

  return `${orgPrefix}/locations/${locationId}`;
};

export const getLocationByIdUrl = (getState, locationId) => {
  const orgPrefix = getOrganizationUrl(getState);

  if (_.isEmptySafe(orgPrefix)) {
    return;
  }

  if (_.isEmptySafe(locationId)) {
    showMissingLocationIdError();

    return;
  }

  return `${orgPrefix}/locations/${locationId}`;
};

export const getUserListUrl = (getState) => {
  const orgPrefix = getOrganizationUrl(getState);

  if (_.isEmptySafe(orgPrefix)) {
    return;
  }

  return `${orgPrefix}/users`;
};

export const getUserUrl = (getState, userId) => {
  const userListUrl = getUserListUrl(getState);

  if (_.isEmptySafe(userListUrl)) {
    return;
  }

  if (_.isEmptySafe(userId)) {
    showMissingUserIdError();

    return;
  }

  return `${userListUrl}/${userId}`;
};

export const getUserUrlByUser = (getState, user) => {
  const userListUrl = getUserListUrl(getState);
  const userId = _.getNonEmpty(user, 'id');

  if (_.isEmptySafe(userId) || _.isEmptySafe(userListUrl)) {
    return;
  }

  return `${userListUrl}/${userId}`;
};

export const getEncounterUrl = (getState, encounterId) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(encounterId)) {
    showMissingEncounterIdError();

    return;
  }

  return `${organizationUrl}/encounters/${encounterId}`;
};

export const getEncounterWidgetsUrl = (getState, encounterId) => {
  const encounterUrl = getEncounterUrl(getState, encounterId);

  if (_.isEmptySafe(encounterId)) {
    showMissingEncounterIdError();

    return;
  }

  return `${encounterUrl}/widgets`;
};

export const getPatientUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return `${organizationUrl}/patients`;
};

export const getPatientListExport = (getState) => {
  const patientUrl = getPatientUrl(getState);

  if (_.isEmptySafe(patientUrl)) {
    return;
  }

  return `${patientUrl}/export`;
};

export const getPatientLookupUrl = (getState) => {
  const patientUrl = getPatientUrl(getState);

  if (_.isEmptySafe(patientUrl)) {
    return;
  }

  return `${patientUrl}/lookup`;
};

export const getPatientDetailUrl = (getState, patientId) => {
  const patientUrl = getPatientUrl(getState);

  if (_.isEmptySafe(patientId)) {
    return;
  }

  return `${patientUrl}/${patientId}`;
};

export const getPatientNoteUrl = (getState, patientId, noteId) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  if (_.isEmptySafe(patientUrl)) {
    return;
  }

  if (_.isEmptySafe(noteId)) {
    return `${patientUrl}/notes`;
  }

  return `${patientUrl}/notes/${noteId}`;
};

export const getPatientNotePinUrl = (getState, patientId, noteId) => {
  const patientNoteUrl = getPatientNoteUrl(getState, patientId, noteId);

  return `${patientNoteUrl}/pin`;
};

export const getPatientNoteUnpinUrl = (getState, patientId, noteId) => {
  const patientNoteUrl = getPatientNoteUrl(getState, patientId, noteId);

  return `${patientNoteUrl}/unpin`;
};

export const getPatientFileListUrl = (getState, patientId) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  if (_.isEmptySafe(patientId)) {
    return;
  }

  return `${patientUrl}/files`;
};

export const getPatientWidgetFileListUrl = (getState, patientId) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  if (_.isEmptySafe(patientUrl)) {
    return;
  }

  return `${patientUrl}/widgets`;
};

export const getPatientFileUrl = (getState, patientId, fileId) => {
  const patientFilesUrl = getPatientFileListUrl(getState, patientId);

  if (_.isEmptySafe(patientFilesUrl) || _.isEmptySafe(fileId)) {
    return;
  }

  return `${patientFilesUrl}/${fileId}`;
};

export const getPatientTherapyListUrl = (getState, patientId) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  if (_.isEmptySafe(patientUrl)) {
    return;
  }

  return `${patientUrl}/drugTherapies`;
};

export const getPatientTherapyUrl = (getState, patientId, therapyId) => {
  const patientTherapyListUrl = getPatientTherapyListUrl(getState, patientId);

  if (_.isEmptySafe(therapyId)) {
    return;
  }

  return `${patientTherapyListUrl}/${therapyId}`;
};

export const getPausePatientTherapyUrl = (getState, patientId, therapyId) => {
  const patientTherapyUrl = getPatientTherapyUrl(
    getState,
    patientId,
    therapyId
  );

  if (_.isEmptySafe(patientTherapyUrl)) {
    return;
  }

  return `${patientTherapyUrl}/pause`;
};

export const getResumePatientTherapyUrl = (getState, patientId, therapyId) => {
  const patientTherapyUrl = getPatientTherapyUrl(
    getState,
    patientId,
    therapyId
  );

  if (_.isEmptySafe(patientTherapyUrl)) {
    return;
  }

  return `${patientTherapyUrl}/resume`;
};

export const getPatientTherapyIntakeListUrl = (
  getState,
  patientId,
  therapyId
) => {
  const patientTherapyUrl = getPatientTherapyUrl(
    getState,
    patientId,
    therapyId
  );

  if (_.isEmptySafe(patientTherapyUrl)) {
    return;
  }

  return `${patientTherapyUrl}/events`;
};

export const getAllPatientsFilesUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  return `${organizationUrl}/patients/files`;
};

export const getPatientDetailBillingUrl = (getState, patientId) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  if (_.isEmptySafe(patientUrl)) {
    return;
  }

  return `${patientUrl}/billing`;
};

export const getPatientDetailUnbilledItemsUrl = (getState, patientId) => {
  const billingUrl = getPatientDetailBillingUrl(getState, patientId);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/unbilledItems`;
};

export const getPatientDetailUnbilledItemDetailUrl = (
  getState,
  patientId,
  unbilledItemId
) => {
  const unbilledItemsUrl = getPatientDetailUnbilledItemsUrl(
    getState,
    patientId
  );

  if (_.isEmptySafe(unbilledItemsUrl)) {
    return;
  }

  return `${unbilledItemsUrl}/${unbilledItemId}`;
};

export const getRegistryUrl = (getState, registryId) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(registryId)) {
    return;
  }

  return `${organizationUrl}/registries/${registryId}`;
};

export const getRegistryItemsUrl = (getState, registryType) => {
  const registryId = selectRegistryId(getState(), registryType);
  const registryUrl = getRegistryUrl(getState, registryId);

  if (_.isEmptySafe(registryUrl)) {
    return;
  }

  return `${registryUrl}/items`;
};

export const getPaymentTypesUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_PAYMENT_TYPES);

export const getTherapyRouteOfAdmissionListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_THERAPY_ROUTE_OF_ADMISSION);

export const getTherapyCancelationReasonListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_THERAPY_CANCELATION_REASONS);

export const getTherapyTemplateListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_THERAPY_TEMPLATES);

export const getTherapyDrugListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_THERAPY_DRUGS);

export const getPatientMedicalConditionListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_ANAMNESIS_MEDICAL_CONDITIONS);

export const getPatientConcomitantDrugListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_ANAMNESIS_CONCOMITANT_DRUGS);

export const getPatientAllergyListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_ANAMNESIS_ALLERGIES);

export const getPatientVaccinationListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_ANAMNESIS_VACCINATIONS);

export const getPatientOperationListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_ANAMNESIS_OPERATIONS);

export const getPatientImplantListUrl = (getState) =>
  getRegistryItemsUrl(getState, REGISTRY_ANAMNESIS_IMPLANTS);

export const getBillingUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/billing`;
};

export const getInvoiceListUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/invoices`;
};

export const getAdvanceListUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/advances`;
};

export const getAdvanceDetailUrl = (getState, advanceId) => {
  const advanceUrl = getAdvanceListUrl(getState);

  if (_.isEmptySafe(advanceUrl)) {
    return;
  }

  return `${advanceUrl}/${advanceId}`;
};

export const getAdvanceRecheckFursData = (getState, advanceId) => {
  const advanceDetailUrl = getAdvanceDetailUrl(getState, advanceId);

  if (_.isEmptySafe(advanceDetailUrl)) {
    return;
  }

  return `${advanceDetailUrl}/recheckFursData`;
};

export const getBillingUnitUrl = (getState, billingUnitId) => {
  const billingUrl = getBillingUrl(getState);

  return `${billingUrl}/units/${billingUnitId}`;
};

export const getFursUrl = (getState, billingUnitId) => {
  const billingUnitUrl = getBillingUnitUrl(getState, billingUnitId);

  return `${billingUnitUrl}/furs`;
};

export const getFursNumberingStrategyUrl = (getState, billingUnitId) => {
  const fursUrl = getFursUrl(getState, billingUnitId);

  return `${fursUrl}/strategy`;
};

export const getCreditNoteListUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/creditNotes`;
};

export const getEstimateListUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/estimates`;
};

export const getAllBillsListUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/documents`;
};

export const getBillingTaxesUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/taxes`;
};

export const getBillingTaxByIdUrl = (getState, taxId) => {
  const billingTaxesUrl = getBillingTaxesUrl(getState);

  if (_.isEmptySafe(billingTaxesUrl)) {
    return;
  }

  return `${billingTaxesUrl}/${taxId}`;
};

export const getBillingItemsUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/items`;
};

export const getBillingItemsExportUrl = (getState) => {
  const billingItemsUrl = getBillingItemsUrl(getState);

  if (_.isEmptySafe(billingItemsUrl)) {
    return;
  }

  return `${billingItemsUrl}/export`;
};

export const getBillingExportUrl = (getState) => {
  const billingUrl = getBillingUrl(getState);

  if (_.isEmptySafe(billingUrl)) {
    return;
  }

  return `${billingUrl}/documents/export`;
};

// EVENT LOG

export const getEventLogListUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/eventLog`;
};

// FORM TEMPLATE URLS

export const getFormTemplateUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/forms`;
};

export const getFormTemplateDetailUrl = (getState, formId) => {
  const formTemplateListUrl = getFormTemplateUrl(getState);

  if (_.isEmptySafe(formTemplateListUrl)) {
    return;
  }

  return `${formTemplateListUrl}/${formId}`;
};

export const getFormTemplateDetailWithVersionUrl = (
  getState,
  formId,
  version
) => {
  const formTemplateUrl = getFormTemplateUrl(getState);
  const versionPath = _.isEmptySafe(version) ? '' : `/${version}`;

  if (_.isEmpty(formTemplateUrl)) {
    return;
  }

  return `${formTemplateUrl}/${formId}${versionPath}`;
};

// OUTBOUND DOCUMENT URLS

export const getDocumentsUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/documents`;
};

export const getOutboundDocumentUrl = (getState) => {
  const documentsUrl = getDocumentsUrl(getState);

  return `${documentsUrl}/outbound`;
};

export const getOutboundDocumentPreviewUrl = (getState) => {
  const outboundDocumentUrl = getOutboundDocumentUrl(getState);

  if (_.isEmptySafe(outboundDocumentUrl)) {
    return;
  }

  return `${outboundDocumentUrl}/pdfPreview`;
};

export const getDocumentImagesPreviewUrl = (getState) => {
  const documentsUrl = getDocumentsUrl(getState);

  return `${documentsUrl}/images`;
};

export const getOutboundDocumentDetailUrl = (getState, documentId) => {
  const outboundDocumentUrl = getOutboundDocumentUrl(getState);

  if (_.isEmptySafe(outboundDocumentUrl)) {
    return;
  }

  return `${outboundDocumentUrl}/${documentId}`;
};

// FORM SUBMISSION URLS

export const getFormSubmissionListUrl = (getState) => {
  const formsUrl = getFormTemplateUrl(getState);

  if (_.isEmpty(formsUrl)) {
    return;
  }

  return `${formsUrl}/submissions`;
};

export const getFormSubmissionDetailUrl = (
  getState,
  patientId,
  submissionId
) => {
  const patientFormSubmissionUrl = getFormSubmissionUrl(getState, patientId);

  if (_.isEmpty(patientFormSubmissionUrl)) {
    return;
  }

  return `${patientFormSubmissionUrl}/${submissionId}`;
};

// FORM REQUEST URLS

export const getFormRequestListUrl = (getState) => {
  const formsUrl = getFormTemplateUrl(getState);

  if (_.isEmpty(formsUrl)) {
    return;
  }

  return `${formsUrl}/submissionRequests`;
};

export const getFormRequestListPatientUrl = (getState, patientId) => {
  const patientFormSubmissionsUrl = getFormSubmissionUrl(getState, patientId);

  if (_.isEmpty(patientFormSubmissionsUrl)) {
    return;
  }

  return `${patientFormSubmissionsUrl}/requests`;
};

export const getFormRequestDetailUrl = (getState, patientId, requestId) => {
  const patientFormRequestsUrl = getFormRequestListPatientUrl(
    getState,
    patientId
  );

  if (_.isEmpty(patientFormRequestsUrl)) {
    return;
  }

  return `${patientFormRequestsUrl}/${requestId}`;
};

export const getAllDocumentTypesListUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/documents`;
};

export const getFormSubmissionUrl = (getState, patientId) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  if (_.isEmpty(patientUrl)) {
    return;
  }

  return `${patientUrl}/formSubmissions`;
};

export const getFormSubmissionRequestsUrl = (getState, patientId) => {
  const formSubmissionListPatientUrl = getFormSubmissionUrl(
    getState,
    patientId
  );

  if (_.isEmpty(formSubmissionListPatientUrl)) {
    return;
  }

  return `${formSubmissionListPatientUrl}/requests`;
};

export const getFormSubmissionRequestDetailUrl = (
  getState,
  patientId,
  requestId
) => {
  const formSubmissionRequestsUrl = getFormSubmissionRequestsUrl(
    getState,
    patientId
  );

  if (_.isEmpty(formSubmissionRequestsUrl)) {
    return;
  }

  return `${formSubmissionRequestsUrl}/${requestId}`;
};

export const getAllDocumentTemplatesListUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/templates`;
};

export const getOrganizationFileUrl = (getState) => {
  const organizationUrl = getOrganizationUrl(getState);

  if (_.isEmptySafe(organizationUrl)) {
    return;
  }

  return `${organizationUrl}/files`;
};

export const getPublicOrganizationFileUrl = (getState) => {
  const organizationFileUrl = getOrganizationFileUrl(getState);

  if (_.isEmptySafe(organizationFileUrl)) {
    return;
  }

  return `${organizationFileUrl}/public`;
};

export const getOrganizationFileIdUrl = (getState, fileId) => {
  const organizationFileUrl = getOrganizationFileUrl(getState);

  if (_.isEmptySafe(organizationFileUrl)) {
    return;
  }

  return `${organizationFileUrl}/${fileId}`;
};

// CALENDAR URLS

export const getCalendarUrl = (getState) => {
  const orgPrefix = getOrganizationUrl(getState);

  if (_.isEmptySafe(orgPrefix)) {
    return;
  }

  return `${orgPrefix}/calendar`;
};

export const getCalendarEventByIdUrl = (getState, locationId, eventId) => {
  const locationUrl = getLocationByIdUrl(getState, locationId);

  if (_.isEmptySafe(locationUrl)) {
    return;
  }

  return `${locationUrl}/calendar/${eventId}`;
};

export const getCalendarEventByWithNotifyIdUrl = (
  getState,
  locationId,
  eventId
) => {
  const eventIdUrl = getCalendarEventByIdUrl(getState, locationId, eventId);

  return `${eventIdUrl}?notify=true`;
};

export const getCalendarEventCancelByIdUrl = (
  getState,
  locationId,
  eventId
) => {
  const eventUrl = getCalendarEventByIdUrl(getState, locationId, eventId);

  return `${eventUrl}/cancel`;
};

export const getCalendarEventUrl = (getState, eventId) => {
  const locationUrl = getLocationUrl(getState);

  return `${locationUrl}/calendar/${eventId}`;
};

export const getCalendarEventRescheduleByUrlUrl = (
  getState,
  locationId,
  eventId
) => {
  const locationUrl = getLocationByIdUrl(getState, locationId);

  if (_.isEmptySafe(locationUrl)) {
    return;
  }

  return `${locationUrl}/calendar/${eventId}/reschedule`;
};

export const getCalendarAssigneesUrl = (getState) => {
  const calendarUrl = getCalendarUrl(getState);

  if (_.isEmptySafe(calendarUrl)) {
    return;
  }

  return `${calendarUrl}/availableAssignees`;
};

export const getCalendarResourcesUrl = (getState) => {
  const calendarUrl = getCalendarUrl(getState);

  if (_.isEmptySafe(calendarUrl)) {
    return;
  }

  return `${calendarUrl}/resources`;
};

export const getResourceUrl = (getState, resourceId) => {
  const calendarResourcesUrl = getCalendarResourcesUrl(getState);

  if (_.isEmptySafe(calendarResourcesUrl)) {
    return;
  }

  return `${calendarResourcesUrl}/${resourceId}`;
};

export const getTagsUrl = (getState) => {
  const organizationId = getOrganizationUrl(getState);

  return `${organizationId}/tags`;
};

export const getTagDetailUrl = (getState, tagId) => {
  const organizationId = getOrganizationUrl(getState);

  return `${organizationId}/tags/${tagId}`;
};

export const getOrganizationWorkingHoursUrl = (getState) => {
  const organizationPrefix = getOrganizationUrl(getState);

  return `${organizationPrefix}/workingHours`;
};

export const getDeleteWorkingHoursUrl = (getState, workingHoursId) => {
  const orgWorkingHoursPrefix = getOrganizationWorkingHoursUrl(getState);

  return `${orgWorkingHoursPrefix}/${workingHoursId}`;
};

export const getWorkingHoursUrl = (getState) => {
  const locationPrefix = getLocationUrl(getState);

  return `${locationPrefix}/workingHours`;
};

export const getWorkingHoursDetailUrl = (getState, workingHoursId) => {
  const workingHoursUrl = getWorkingHoursUrl(getState);

  return `${workingHoursUrl}/${workingHoursId}`;
};

export const getCalendarWorkingHoursUrl = (getState) => {
  const calendarPrefix = getLocationUrl(getState);

  return `${calendarPrefix}/calendar/workingHours`;
};

export const getLocationWorkingHoursUrl = (getState, locationId) => {
  const locationPrefix = getLocationByIdUrl(getState, locationId);

  return `${locationPrefix}/workingHours`;
};

export const getAssigneesWorkingHoursUrl = (getState, assigneeIds) => {
  const workingHoursUrl = getCalendarWorkingHoursUrl(getState);

  const assignees = mapEventAssignees(
    _.map(assigneeIds, (assigneeId) => ({
      id: assigneeId
    }))
  );

  const mappedAssigneesForPayload = _.map(assignees, (assignee) => {
    const assigneeType = _.getNonEmpty(
      assignee,
      'assignee.type',
      ASSIGNEE_TYPE_USER
    );

    return `${assigneeType}:${assignee.id}`;
  });

  const encodedAssignees = encodeURIComponent(
    mappedAssigneesForPayload.join(',')
  );

  return `${workingHoursUrl}?assignees=${encodedAssignees}`;
};

// Automated booking
export const getBookingUrl = (getState) => {
  const organizationPrefix = getOrganizationUrl(getState);

  return `${organizationPrefix}/bookings`;
};

export const getBookingTemplateUrl = (getState) => {
  const automatedBookingUrl = getBookingUrl(getState);

  return `${automatedBookingUrl}/templates`;
};

export const getAllValidBookingSchedulesUrl = (getState) => {
  const automatedBookingUrl = getBookingUrl(getState);
  const locationId = selectCurrentLocationId(getState());

  return `${automatedBookingUrl}/templates/validSchedules/${locationId}`;
};

export const getBookingScheduleUrl = (getState, templateId) => {
  const automatedBookingUrl = getBookingUrl(getState);

  return `${automatedBookingUrl}/templates/${templateId}/schedules`;
};

export const getBookingTemplateDetailUrl = (getState, templateId) => {
  const bookingTemplateUrl = getBookingTemplateUrl(getState);

  return `${bookingTemplateUrl}/${templateId}`;
};

export const getBookingTemplateActivateUrl = (getState, templateId) => {
  const bookingTemplateDetailUrl = getBookingTemplateDetailUrl(
    getState,
    templateId
  );

  return `${bookingTemplateDetailUrl}/activate`;
};

export const getBookingTemplateDeactivateUrl = (getState, templateId) => {
  const bookingTemplateDetailUrl = getBookingTemplateDetailUrl(
    getState,
    templateId
  );

  return `${bookingTemplateDetailUrl}/deactivate`;
};

export const getUserActivationUrl = (getState, uid) => {
  if (_.isEmptySafe(uid)) {
    return;
  }

  return `/activation/${uid}`;
};

// Communication
export const getCommunicationInboxUrl = (getState) => {
  const organizationPrefix = getOrganizationUrl(getState);

  return `${organizationPrefix}/inbox`;
};

export const getCommunicationListUrl = (getState) => {
  const inboxPrefix = getCommunicationInboxUrl(getState);

  return `${inboxPrefix}/messages`;
};

export const getReassignCalendarItemsUrl = (getState, locationId) => {
  const locationUrl = getLocationByIdUrl(getState, locationId);

  return `${locationUrl}/calendar/reassign`;
};
