import _ from 'lodash';
import { createSelector } from 'reselect';

import { MOSHI_FILE_TYPE_DOCUMENT } from '../../utils/constants/fileConstants';
import {
  addSignatureToDocument,
  getJsonSchemaFormData
} from '../../utils/form';

import { selectCurrentOrganizationOtherSettings } from '../organization/organizationSelectors';
import { selectDocumentSubmissionDetail } from './submission/documentSubmissionSelectors';

export const selectDocumentStore = (store) =>
  _.getNonEmpty(store, 'document', {});

export const selectIsDocumentListLoading = createSelector(
  [selectDocumentStore],
  (document) => _.getNonEmpty(document, 'loadingList', true)
);

export const selectDocumentList = createSelector(
  [selectDocumentStore],
  (document) => {
    const list = _.getNonEmpty(document, 'list', []);

    return list.map((documentItem) => {
      documentItem.type = MOSHI_FILE_TYPE_DOCUMENT;

      return documentItem;
    });
  }
);

export const selectDocumentDetail = createSelector(
  [selectDocumentStore],
  (document) => _.getNonEmpty(document, 'detail', {})
);

export const selectDocumentFormData = createSelector(
  [selectDocumentDetail, selectDocumentSubmissionDetail],
  (documentDetail, submissionDetail) => {
    const documentData = addSignatureToDocument(
      documentDetail,
      submissionDetail
    );
    const formBuilderSchema = _.get(documentData, 'formBuilderSchema', {});

    return formBuilderSchema;
  }
);

export const selectDocumentSchema = createSelector(
  [selectDocumentFormData],
  (documentFormData) => {
    const { schema, uiSchema } = getJsonSchemaFormData(documentFormData);

    return { schema, uiSchema };
  }
);

export const selectDocumentName = createSelector(
  [selectDocumentFormData],
  (documentDetail) => _.getNonEmpty(documentDetail, 'title', '')
);

export const selectDocumentHeaderFooterSettingsIds = createSelector(
  [selectCurrentOrganizationOtherSettings],
  (organizationOtherSettings) =>
    _.getNonEmpty(organizationOtherSettings, 'pdfGeneration', {})
);

export const selectDocumentSettingsHeaderId = createSelector(
  [selectDocumentHeaderFooterSettingsIds],
  (headerFooterSettings) =>
    _.getNonEmpty(headerFooterSettings, 'headerFileID', '')
);

export const selectDocumentSettingsFooterId = createSelector(
  [selectDocumentHeaderFooterSettingsIds],
  (headerFooterSettings) =>
    _.getNonEmpty(headerFooterSettings, 'footerFileID', '')
);
