/* eslint-disable camelcase */
export default {
  diagnosis: {
    title: 'Diagnosis',
    emptyText: 'Undefined',
    mainDiagnosis: {
      title: 'Main diagnosis',
      enum: {
        rheumatoid_arthritis: 'Rheumatoid arthritis',
        spondyloarthritis: 'Spondyloarthritis'
      }
    },
    clinicalOutlook: {
      title: 'Clinical outlook',
      enum: {
        predominantly_axial_spondylarthritis:
          'Predominantly axial spondylarthritis, ax-SpA',
        predominantly_peripheral_spondylarthritis:
          'Predominantly peripheral spondylarthritis, ax-SpA',
        psoriatic_arthritis: 'Psoriatic arthritis, PsA',
        enteropathic_arthritis: 'Enteropathic arthritis, e-SpA',
        reactive_arthritis: 'Reactive arthritis, r-SpA'
      }
    },
    symptomsOnset: {
      title: 'Symptoms onset'
    },
    diagnosisDate: {
      title: 'Diagnosis date'
    }
  },
  socialDiagnosis: {
    title: 'Social anamnesis',
    maritalStatus: {
      title: 'Marital status',
      enum: {
        single: 'Single',
        married: 'Married',
        divorced: 'Divorced',
        widowed: 'Widowed'
      }
    },
    education: {
      title: 'Education Level',
      enum: {
        noFormalEducation: 'No formal education',
        fourYearsOfPrimarySchool: '4 years of primary school',
        primarySchool: 'Primary school',
        middleTradeSchool: 'Middle trade school',
        highSchool: 'High school',
        higherEducation: 'Higher Education',
        universityEducation: 'University education',
        masterOfScience: 'Master of science',
        doctorOfScience: 'Doctor of science'
      }
    },
    employment: {
      title: 'Employment status',
      enum: {
        retired: 'Retired',
        employed: 'Employed',
        selfEmployed: 'Self-employed',
        student: 'Student',
        dependant: 'Dependant',
        unemployed: 'Unemployed'
      }
    },
    disabilityRetirement: 'Disability retirement',
    householdMembers: 'Number of persons in household',
    yearOfRetirement: 'Year of retirement'
  }
};
