import axios from 'axios';
import _ from 'lodash';

import { getAllDocumentTemplatesListUrl } from '../../../utils/api/apiUrlUtils';
import {
  INBOUND_TEMPLATE,
  OUTBOUND_TEMPLATE
} from '../../../utils/constants/documentConstants';
import {
  mapDuplicatedInboundDocument,
  mapDuplicatedOutboundDocument
} from '../../../utils/documentUtils';

import {
  addOutboundDocument,
  clearOutboundDocumentDetails
} from '../../document/outbound/outboundDocumentActions';
import { getAnyDocumentTypeFormSchema } from '../allDocumentTypes/allDocumentTypesAction';
import {
  addFormTemplate,
  clearFormTemplateDetail
} from '../template/formTemplateActions';

import {
  SET_ALL_DOCUMENT_TEMPLATES_LIST_PAGINATION,
  SET_ALL_DOCUMENT_TEMPLATES_LIST_FILTER,
  CLEAR_ALL_DOCUMENT_TEMPLATES_LIST_FILTERS,
  CLEAR_ALL_DOCUMENT_TEMPLATES_LIST,
  SET_ALL_DOCUMENT_TEMPLATES_LIST_ORDER,
  GET_ALL_DOCUMENT_TEMPLATES_LIST
} from './allDocumentTemplatesTypes';

import {
  selectAllDocumentTemplatesFilters,
  selectAllDocumentTemplatesPagination
} from './allDocumentTemplatesSelectors';

export const getAllDocumentTemplatesList = () => (dispatch, getState) => {
  const { limit, page } = selectAllDocumentTemplatesPagination(getState());
  const filters = selectAllDocumentTemplatesFilters(getState());

  const allDocumentTemplatesUrl = getAllDocumentTemplatesListUrl(getState);

  return dispatch({
    type: GET_ALL_DOCUMENT_TEMPLATES_LIST,
    apiCall: axios.get(allDocumentTemplatesUrl, {
      params: {
        ...filters,
        limit,
        page
      }
    })
  });
};

export const setAllDocumentTemplatesPagination = (
  paginationName,
  paginationValue
) => (dispatch) => {
  dispatch({
    type: SET_ALL_DOCUMENT_TEMPLATES_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

  return dispatch(getAllDocumentTemplatesList());
};

export const setAllDocumentTemplatesOrder = (sortBy, order) => (dispatch) => {
  dispatch({ type: SET_ALL_DOCUMENT_TEMPLATES_LIST_ORDER, sortBy, order });

  return dispatch(getAllDocumentTemplatesList());
};

export const setAllDocumentTemplatesFilter = (filters) => (dispatch) => {
  dispatch({
    type: SET_ALL_DOCUMENT_TEMPLATES_LIST_FILTER,
    filters
  });

  return dispatch(getAllDocumentTemplatesList());
};

export const clearAllDocumentTemplatesFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_DOCUMENT_TEMPLATES_LIST_FILTERS
  });

  return dispatch(getAllDocumentTemplatesList());
};

export const clearAllDocumentTemplates = () => (dispatch) =>
  dispatch({
    type: CLEAR_ALL_DOCUMENT_TEMPLATES_LIST
  });

export const duplicateDocumentTemplate = (document, onSuccess) => (
  dispatch
) => {
  const onGetFormSchemaSuccess = (response) => {
    const data = _.getNonEmpty(response, 'data', {});
    const documentType = _.getNonEmpty(document, 'type');
    const documentName = _.getNonEmpty(document, 'name');
    const documentWithNewName = { ...data, name: documentName };

    if (documentType === INBOUND_TEMPLATE) {
      const inboundDocumentData = mapDuplicatedInboundDocument(
        documentWithNewName
      );

      dispatch(addFormTemplate(inboundDocumentData, false, onSuccess));
      dispatch(clearFormTemplateDetail());
    } else if (documentType === OUTBOUND_TEMPLATE) {
      const outboundDocumentData = mapDuplicatedOutboundDocument(
        documentWithNewName
      );

      dispatch(addOutboundDocument(outboundDocumentData, onSuccess));
      dispatch(clearOutboundDocumentDetails());
    }
  };

  return dispatch(
    getAnyDocumentTypeFormSchema(document, onGetFormSchemaSuccess)
  );
};
