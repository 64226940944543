import { take, race, call } from 'redux-saga/effects';

import {
  billingItemListPollSaga,
  pollPaymentTypeListSaga
} from '../../billing/billingSagas';
import { pollBookingTemplatesSaga } from '../../booking/bookingSagas';
import {
  assigneesListSaga,
  resourcesListSaga
} from '../../calendar/calendarSagas';
import { pollOutboundTemplatesSaga } from '../../document/outbound/outboundDocumentSagas';
import { pollEventLogSaga } from '../../event-log/eventLogSagas';
import { pollFormsSaga } from '../../form/formSagas';
import {
  anamnesisAllergyPollSaga,
  anamnesisConcomitantDrugPollSaga,
  anamnesisImplantPollSaga,
  anamnesisMedicalConditionPollSaga,
  anamnesisOperationPollSaga,
  anamnesisVaccinationPollSaga
} from '../../patient/anamnesis/patientAnamnesisSagas';
import { pollTagsSaga } from '../../tags/tagsSagas';
import {
  therapyCancelationReasonSaga,
  therapyDrugListSaga,
  therapyRouteOfAdmissionSaga,
  therapyTemplateListSaga
} from '../../therapy/therapySagas';
import { pollUserListSaga } from '../../user/userSagas';
import { pollAppVersionSaga } from '../app/appSagas';

import {
  POLL_ORGANIZATION,
  START_POLLING,
  START_POLLING_ANONYMOUS,
  STOP_POLLING,
  STOP_POLLING_ANONYMOUS
} from './cacheTypes';

// CAN POLL BEFORE LOGIN
export function* anonymousPollingSagaWatcher() {
  while (true) {
    yield take(START_POLLING_ANONYMOUS);
    yield race([call(pollAppVersionSaga), take(STOP_POLLING_ANONYMOUS)]);
  }
}

// CAN POLL AFTER LOGIN
export function* pollingSagaWatcher() {
  while (true) {
    yield take(START_POLLING);
    yield race([call(pollUserListSaga), take(STOP_POLLING)]);
  }
}

// CAN POLL ONLY AFTER ORG WAS FETCHED
export function* organizationSagaWatcher() {
  while (true) {
    yield take(POLL_ORGANIZATION);
    yield race([
      call(pollPaymentTypeListSaga),
      call(pollFormsSaga),
      call(pollOutboundTemplatesSaga),
      call(therapyRouteOfAdmissionSaga),
      call(therapyCancelationReasonSaga),
      call(therapyTemplateListSaga),
      call(therapyDrugListSaga),
      call(assigneesListSaga),
      call(resourcesListSaga),
      call(pollTagsSaga),
      call(pollBookingTemplatesSaga),
      call(pollEventLogSaga),
      call(anamnesisMedicalConditionPollSaga),
      call(anamnesisConcomitantDrugPollSaga),
      call(anamnesisAllergyPollSaga),
      call(anamnesisVaccinationPollSaga),
      call(anamnesisOperationPollSaga),
      call(anamnesisImplantPollSaga),
      call(billingItemListPollSaga),
      take(STOP_POLLING)
    ]);
  }
}
