import _ from 'lodash';

import {
  BILLING_ITEM_PRICE_TYPE_GROSS,
  BILLING_ITEM_PRICE_TYPE_NET
} from '../../../utils/billing';
import { safeGlobalGetState } from '../../../utils/redux/storeUtils';

import EntityBaseModel from '../EntityBaseModel';
import TaxModel from '../TaxModel';

class BillingItemModel extends EntityBaseModel {
  constructor(id, name = '') {
    super(id);

    this.name = name;
    this.description = '';
    this.taxIDs = [];
    this.price = 0;
    this.priceType = BILLING_ITEM_PRICE_TYPE_NET;
    this.unit = '';
    this.sku = '';
    this.categoryID = null;
    this.enabled = true;
  }

  get tax() {
    return _.first(this.taxIDs);
  }

  set tax(value) {
    if (_.isEmpty(value)) {
      this.taxIDs = [];
    } else {
      this.taxIDs = [value];
    }
  }

  get taxRate() {
    const cachedTaxList = _.getNonEmpty(
      safeGlobalGetState(),
      'cache.tax.list',
      []
    );
    const archivedTaxList = _.getNonEmpty(
      safeGlobalGetState(),
      'archive.tax.list',
      []
    );

    const taxList = [...cachedTaxList, ...archivedTaxList];

    const foundTax = _.findDefault(taxList, { id: this.tax }, {});

    return _.get(foundTax, TaxModel.taxRatePath, 0);
  }

  getTaxAmount(basePrice) {
    if (
      !_.isFinite(this.price) ||
      this.priceType === BILLING_ITEM_PRICE_TYPE_GROSS
    ) {
      return 0;
    }

    const taxRate = this.taxRate;

    const rawTaxAmount = (taxRate / 100) * basePrice;

    return _.round(rawTaxAmount, 4);
  }

  get taxAmount() {
    return this.getTaxAmount(this.price);
  }

  get total() {
    const total = this.taxAmount + this.price;

    return _.round(total, 4);
  }

  set taxAmount(value) {
    // This setter is present so that mappers don't fail
  }

  set total(value) {
    // This setter is present so that mappers don't fail
  }

  static getPayload(model) {
    let payload = _.cloneDeep(model);

    payload = EntityBaseModel.removeEntityMetadata(payload);

    return payload;
  }
}

export default BillingItemModel;
