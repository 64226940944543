export const INBOUND = 'inbound';
export const OUTBOUND = 'outbound';
export const EXTERNAL = 'externalDocument';
export const INBOUND_SUBMISSION = 'formSubmission';
export const INBOUND_REQUEST = 'formSubmissionRequest';
export const INBOUND_TEMPLATE = 'form';
export const OUTBOUND_DOCUMENT = 'outboundDocument';
export const OUTBOUND_TEMPLATE = 'outboundDocumentTemplate';

export const DOCUMENT_FILTER_SEARCH = 'q';
export const DOCUMENT_FILTER_CREATED_BY = 'createdBy';
export const DOCUMENT_FILTER_TYPE = 'includeTypes';
