import _ from 'lodash';
import randomColor from 'randomcolor';

import { getCategoryColorById } from '../category';

export const appendColorMapper = (entity) => {
  if (_.isEmpty(entity)) {
    return {};
  } else if (!_.isEmptySafe(entity, 'color')) {
    return entity;
  }

  entity.color = randomColor({ seed: entity.id });

  return entity;
};

export const appendCategoryColorMapper = (entity) => {
  if (_.isEmptySafe(entity)) {
    return {};
  } else if (!_.isEmptySafe(entity, 'color')) {
    return entity;
  }

  entity.color = getCategoryColorById(entity.categoryID);

  return entity;
};
