import { createActionTypeArray } from '../../common/actionTypes';

export const GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS =
  'questionnaireSubmission/GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS';
export const GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST =
  'questionnaireSubmission/GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST';
export const GET_QUESTIONNAIRE_SUBMISSION_DATA =
  'questionnaireSubmission/GET_QUESTIONNAIRE_SUBMISSION_DATA';
export const UPDATE_QUESTIONNAIRE_SUBMISSION_DATA =
  'questionnaireSubmission/UPDATE_QUESTIONNAIRE_SUBMISSION_DATA';
export const GET_PATIENT_QUESTIONNAIRE_DATA =
  'questionnaireSubmission/GET_PATIENT_QUESTIONNAIRE_DATA';
export const CLEAR_QUESTIONNAIRE_SUBMISSION_DATA =
  'questionnaireSubmission/CLEAR_QUESTIONNAIRE_SUBMISSION_DATA';

export const [
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_IN_PROGRESS,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_SUCCESS,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS_FAILURE
] = createActionTypeArray(GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSIONS);

export const [
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_IN_PROGRESS,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_SUCCESS,
  GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST_FAILURE
] = createActionTypeArray(GET_ENCOUNTER_QUESTIONNAIRE_SUBMISSION_DATA_LIST);

export const [
  GET_QUESTIONNAIRE_SUBMISSION_DATA_IN_PROGRESS,
  GET_QUESTIONNAIRE_SUBMISSION_DATA_SUCCESS,
  GET_QUESTIONNAIRE_SUBMISSION_DATA_FAILURE
] = createActionTypeArray(GET_QUESTIONNAIRE_SUBMISSION_DATA);

export const [
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_IN_PROGRESS,
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_SUCCESS,
  UPDATE_QUESTIONNAIRE_SUBMISSION_DATA_FAILURE
] = createActionTypeArray(UPDATE_QUESTIONNAIRE_SUBMISSION_DATA);

export const [
  GET_PATIENT_QUESTIONNAIRE_DATA_IN_PROGRESS,
  GET_PATIENT_QUESTIONNAIRE_DATA_SUCCESS,
  GET_PATIENT_QUESTIONNAIRE_DATA_FAILURE
] = createActionTypeArray(GET_PATIENT_QUESTIONNAIRE_DATA);
