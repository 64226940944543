import _ from 'lodash';
import { createSelector } from 'reselect';

import { selectFormStore } from '../../../form/formSelectors';

export const selectOutboundTemplateStore = createSelector(
  [selectFormStore],
  (formStore) => _.getNonEmpty(formStore, 'outboundTemplates', {})
);

export const selectOutboundTemplatePagination = createSelector(
  [selectOutboundTemplateStore],
  (templateStore) => _.getNonEmpty(templateStore, 'pagination', {})
);

export const selectOutboundTemplateList = createSelector(
  [selectOutboundTemplateStore],
  (templateStore) => _.getNonEmpty(templateStore, 'list', [])
);
