import _ from 'lodash';

import {
  INBOUND_TEMPLATE,
  OUTBOUND_DOCUMENT,
  OUTBOUND_TEMPLATE
} from './constants/documentConstants';
import { MOSHI_FILE_TYPE_DOCUMENT } from './constants/fileConstants';

import { jsonParse } from './jwt';

export const isDocumentTemplate = (document) =>
  _.getNonEmpty(document, 'template', false);

export const mapDuplicatedInboundDocument = (document) => {
  const name = _.getNonEmpty(document, 'name', '');

  return {
    formBuilderSchema: {
      ...jsonParse(_.getNonEmpty(document, 'formBuilderSchema', '')),
      title: name
    },
    jsonSchema: _.getNonEmpty(document, 'jsonSchema'),
    name,
    type: _.getNonEmpty(document, 'type', MOSHI_FILE_TYPE_DOCUMENT)
  };
};

export const mapDuplicatedOutboundDocument = (document) => {
  const name = _.getNonEmpty(document, 'name', '');

  return {
    hasHeaderFooter: _.getNonEmpty(document, 'hasHeaderFooter', false),
    name,
    body: _.getNonEmpty(document, 'body'),
    template: true
  };
};

export const replaceDocumentName = (document, name, type) => {
  switch (type) {
    case OUTBOUND_DOCUMENT:
    case OUTBOUND_TEMPLATE:
      return { ...document, name };
    case INBOUND_TEMPLATE:
      return {
        ...document,
        formBuilderSchema: {
          ...jsonParse(document.formBuilderSchema),
          title: name
        },
        name
      };

    default:
  }
};
