import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { moshiClassNames } from '../../../../../../../utils/gen';

import { Div } from '../../../../../HtmlComponents';

import { Label } from '../../../../../../reactstrap';
import Visible from '../../../../../layout/Visible';

const ReadOnlyCustomGroupSingleField = ({
  children,
  measurement,
  label,
  className,
  styles
}) => (
  <React.Fragment>
    <Visible
      visible={!_.isEmpty(label)}
      component={Label}
      className={moshiClassNames(className, 'field-label', 'grey-label')}
    >
      {label}
    </Visible>
    <div
      className={moshiClassNames({
        'widget-value': !_.isEmptySafe(measurement),
        [className]: _.isEmpty(label) && !_.isEmpty(className)
      })}
      style={styles}
    >
      {children}
      <Visible
        visible={!_.isEmpty(measurement)}
        component={Div}
        className={'measurement'}
      >
        {measurement}
      </Visible>
    </div>
  </React.Fragment>
);

ReadOnlyCustomGroupSingleField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  measurement: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.shape()
};

ReadOnlyCustomGroupSingleField.defaultProps = {
  measurement: '',
  label: undefined,
  className: undefined,
  styles: undefined
};

export default ReadOnlyCustomGroupSingleField;
