import _ from 'lodash';
import moment from 'moment';

import { storeGetState } from '../configureStore';
import { translateKey } from '../i18n';
import {
  getSubEntityKeysConfig,
  SUB_ENTITY_TYPE_CATEGORY,
  SUB_ENTITY_TYPE_DRUG,
  SUB_ENTITY_TYPE_FLOW,
  SUB_ENTITY_TYPE_LOCATION,
  SUB_ENTITY_TYPE_PATIENT_THERAPY,
  SUB_ENTITY_TYPE_USER
} from './constants/entityConstants';
import {
  THERAPY_STATUS_ACTIVE,
  THERAPY_STATUS_PAUSED
} from './constants/therapyConstants';

import { MOSHI_COLOR_GREEN_500, MOSHI_COLOR_RED_300 } from './color';
import {
  mapTherapyNamePreview,
  therapyMapper
} from './mappers/cleaned/therapy-mappers';
import { getFullName } from './mappers/common-mappers';
import { dropdownItemMapper } from './mappers/dropdown-mappers';

export const getSubEntityDropdownMapper = (entityType) => {
  switch (entityType) {
    case SUB_ENTITY_TYPE_USER:
      return dropdownItemMapper('id', (item) => getFullName(item));
    case SUB_ENTITY_TYPE_PATIENT_THERAPY:
      return (therapy) => {
        let mappedTherapy = mapTherapyNamePreview(therapy);

        mappedTherapy = therapyMapper(mappedTherapy);

        const startDate = _.getNonEmpty(mappedTherapy, 'startsAt');

        const startDateDisplayFormat = translateKey('date:shortDate', {
          date: startDate
        });

        const durationValue = _.get(mappedTherapy, 'duration.value', 0);
        const durationScale = _.get(mappedTherapy, 'duration.scale', 'days');

        const endDate = moment(mappedTherapy.startDate)
          .add(durationValue, durationScale)
          .toISOString();

        const endDateDisplayFormat = mappedTherapy.indefiniteTherapy
          ? translateKey('therapy:indefiniteDuration')
          : translateKey('date:shortDate', { date: endDate });

        const isActive = _.includesAny(mappedTherapy.status, [
          THERAPY_STATUS_ACTIVE,
          THERAPY_STATUS_PAUSED
        ]);

        mappedTherapy.color = isActive
          ? MOSHI_COLOR_GREEN_500
          : MOSHI_COLOR_RED_300;

        mappedTherapy.name = `${mappedTherapy.name}, ${startDateDisplayFormat} - ${endDateDisplayFormat}`;

        return mappedTherapy;
      };
    case SUB_ENTITY_TYPE_LOCATION:
    case SUB_ENTITY_TYPE_FLOW:
    case SUB_ENTITY_TYPE_DRUG:
    case SUB_ENTITY_TYPE_CATEGORY:
    default:
      return dropdownItemMapper();
  }
};

export const getCachedList = (storePath, fromArchive = false) => {
  const subStore = fromArchive ? 'archive' : 'cache';

  return _.get(storeGetState(), `${subStore}.${storePath}.list`, []);
};

export const findEntityInStore = (
  entityType,
  entityId,
  onlyDeleted = false
) => {
  let foundEntity = {};
  const subEntityKeys = getSubEntityKeysConfig();

  const entityKeyConfig = _.find(subEntityKeys, { type: entityType });

  if (!_.isEmpty(entityKeyConfig)) {
    let cacheList = [];

    if (!onlyDeleted) {
      cacheList = getCachedList(entityKeyConfig.storePath);
    }
    const archiveList = getCachedList(entityKeyConfig.storePath, true);

    const wholeList = [...cacheList, ...archiveList];

    foundEntity = _.findDefault(wholeList, { id: entityId }, {});
  }

  return foundEntity;
};
