/* eslint-disable no-template-curly-in-string,no-invalid-this */
import _ from 'lodash';
import moment from 'moment';

import { DOCUMENT_FORM_FIELD_TYPES } from './constants/formConstants';

import logger from './logger';
import { passwordPattern } from './regexPatterns';

const NO_VALIDATION_MESSAGE = 'NO VALIDATION MESSAGE PROVIDED';

export function isNotEmptyYupValidator(msg) {
  return this.test({
    name: 'isNotEmpty',
    exclusive: false,
    message: msg || NO_VALIDATION_MESSAGE,
    test(value) {
      let val = value;

      if (_.isString(value)) {
        val = value.trim();
      }

      return !_.isEmpty(val);
    }
  });
}

export function equalToYupValidator(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || NO_VALIDATION_MESSAGE,
    params: {
      reference: ref.path
    },
    test(value) {
      return value === this.resolve(ref);
    }
  });
}

export function uniqueArrayYupValidator(propPaths, msg) {
  return this.test({
    name: 'uniqueArray',
    exclusive: false,
    message: msg || NO_VALIDATION_MESSAGE,
    test(array) {
      const uniqueArray = _.uniqBy(array, (item) => {
        const propertyValues = _.getByList(item, propPaths, null);

        return propertyValues.join();
      });

      return array.length === uniqueArray.length;
    }
  });
}

export function removeSpacesYupTransform(currentValue) {
  if (_.isString(currentValue)) {
    return currentValue.split(' ').join('');
  } else {
    return currentValue;
  }
}

export function maxDecimalPlacesYupValidator(
  maxDecimalPlaces,
  allowedNull = false,
  allowedUndefined = false
) {
  return this.test({
    name: 'maxDecimalPlaces',
    exclusive: false,
    params: {
      maxDecimalPlaces,
      allowedNull,
      allowedUndefined
    },
    test(value) {
      if (_.isUndefined(value) && allowedUndefined) {
        return true;
      }

      if (_.isNull(value) && allowedNull) {
        return true;
      }

      const stringValue = value.toString();
      const splitValue = stringValue.split('.');
      const decimals = splitValue[1];

      if (!_.has(decimals, 'length')) {
        return true;
      }

      return decimals.length < maxDecimalPlaces + 1;
    }
  });
}

export function passwordYupValidator(errorMessage) {
  return this.test({
    name: 'validatePassword',
    message: errorMessage,
    test(value) {
      if (_.isEmptySafe(value)) {
        return true;
      }
      const stringValue = value.toString();
      const isAtLeast16CharLong = stringValue.length >= 16;
      const matchesPasswordPattern = passwordPattern.test(stringValue);

      return isAtLeast16CharLong || matchesPasswordPattern;
    }
  });
}

export function fromToTimeYupValidator(
  msg,
  startKey = 'startTime',
  endKey = 'endTime'
) {
  return this.test({
    name: 'validateFromToTime',
    message: msg,
    test(value) {
      if (_.isEmptySafe(value)) {
        return true;
      }
      const startTime = _.getNonEmpty(this.parent, startKey);
      const endTime = _.getNonEmpty(this.parent, endKey);

      const start = moment(startTime);
      let end = moment(endTime);

      if (end.format('HH:mm') === '00:00') {
        end = moment('24:00', 'HH:mm');
      }

      return end.isAfter(start) && !end.isSame(start);
    }
  });
}

export function calculatedTherapyEndDateLowerThanToday(msg) {
  return this.test({
    name: 'calculatedTherapyEndDateLowerThanToday',
    message: msg,
    test() {
      const duration = _.getNonEmpty(this, 'parent.duration', null);
      const startDate = _.getNonEmpty(this, 'parent.startDate', null);
      const indefiniteTherapy = _.getNonEmpty(
        this,
        'parent.indefiniteTherapy',
        false
      );

      if (
        _.isEmptySafe(duration) ||
        _.isEmptySafe(startDate) ||
        indefiniteTherapy
      ) {
        return true;
      }

      const targetDate = moment(startDate).add(duration.value, duration.scale);

      return targetDate.isSameOrAfter(moment());
    }
  });
}

export function signatureFieldYupValidator(errorMessage) {
  return this.test({
    name: 'validateSignatureField',
    message: errorMessage,
    test(value) {
      if (_.isEmptySafe(value)) {
        return true;
      }
      const isSignature = (item) =>
        item.type === DOCUMENT_FORM_FIELD_TYPES.SIGNATURE;

      const isSignatureOnly = value.every(isSignature);

      return !isSignatureOnly;
    }
  });
}

export function atLeastOneCheckboxSelectedValidator(errorMessage) {
  return this.test({
    name: 'validateMultipleCheckboxes',
    message: errorMessage,
    test(obj) {
      let isOneSelected = false;

      _.forEach(obj, (item) => {
        if (item) {
          isOneSelected = true;

          return false;
        }
      });

      return isOneSelected;
    }
  });
}

export function dividableByYupValidator(num, msg) {
  return this.test({
    name: 'dividableBy',
    exclusive: false,
    message: msg || NO_VALIDATION_MESSAGE,
    test(value) {
      if (!_.isFinite(num)) {
        logger.error(`dividableByYupValidator: ${num} is not a number`);

        return false;
      }

      return value % num === 0;
    }
  });
}
