import React from 'react';

import PropTypes from 'prop-types';

import LeftFootInteractive from './LeftFootInteractive';
import LeftFootOutline from './LeftFootOutline';

const LeftFoot = ({
  outlineColor,
  disabledColor,
  getColor,
  onChange,
  initialSelectedParts,
  disabled,
  anklesDisabled,
  instepDisabled,
  lowDisabled
}) => (
  <g id={'zoomed-left-foot'}>
    <LeftFootOutline
      outlineColor={outlineColor}
      disabledColor={disabledColor}
      disabled={disabled}
    />
    {!disabled && (
      <LeftFootInteractive
        getColor={getColor}
        onChange={onChange}
        initialSelectedParts={initialSelectedParts}
        anklesDisabled={anklesDisabled}
        instepDisabled={instepDisabled}
        lowDisabled={lowDisabled}
      />
    )}
  </g>
);

LeftFoot.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  anklesDisabled: PropTypes.bool,
  instepDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool
};

LeftFoot.defaultProps = {
  onChange: undefined,
  initialSelectedParts: [],
  disabled: false,
  anklesDisabled: undefined,
  instepDisabled: undefined,
  lowDisabled: undefined
};

export default LeftFoot;
