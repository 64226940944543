class BillingFursModel {
  constructor() {
    this.certificate = false;
    this.businessPremises = false;
    this.electronicDevices = false;
    this.enabled = false;
  }
}

export default BillingFursModel;
