/* eslint sort-keys: "error" */
import {
  ACTIVE_ENCOUNTER_PAGE_PATIENT_SCHEMA,
  APPOINTMENTS_PAGE_PATIENT_SCHEMA,
  BILLS_PAGE_PATIENT_SCHEMA,
  DENTAL_CHART_PAGE_PATIENT_SCHEMA
} from './utils/constants/patientConstants';

import { settingsRoutes, baseRoutes, employeeRoutes } from './routes';

export const ENCOUNTER_FEATURE_MODULE = 'encounter';
export const CALENDAR_FEATURE_MODULE = 'calendar';
export const BILLING_FEATURE_MODULE = 'billing';
export const THERAPY_FEATURE_MODULE = 'therapy';
export const TAGS_FEATURE_MODULE = 'tags';
export const ANALYTICS_FEATURE_MODULE = 'analytics';
export const DENTAL_CHART_FEATURE_MODULE = 'dentalChart';
export const CUSTOM_RHEUMATOLOGY_FEATURE_MODULE = 'customRheumatology';
export const WORKING_HOURS_MODULE = 'workingHours';
export const CLOSED_DATES_MODULE = 'closedDates';
export const RSVP_MODULE = 'rsvp';
export const BOOKING_MODULE = 'booking';
export const COMMUNICATION_FEATURE_MODULE = 'communication';

export const FEATURE_MODULE_LIST = [
  ENCOUNTER_FEATURE_MODULE,
  CALENDAR_FEATURE_MODULE,
  BILLING_FEATURE_MODULE,
  THERAPY_FEATURE_MODULE,
  ANALYTICS_FEATURE_MODULE,
  CUSTOM_RHEUMATOLOGY_FEATURE_MODULE,
  WORKING_HOURS_MODULE,
  TAGS_FEATURE_MODULE,
  CLOSED_DATES_MODULE,
  RSVP_MODULE,
  BOOKING_MODULE,
  COMMUNICATION_FEATURE_MODULE
];

export const FEATURE_MODULE_TILE_LIST = {
  activeEncounter: ENCOUNTER_FEATURE_MODULE,
  activeTherapy: THERAPY_FEATURE_MODULE,
  allPatientWidgets: ENCOUNTER_FEATURE_MODULE,
  appointments: CALENDAR_FEATURE_MODULE,
  bills: BILLING_FEATURE_MODULE,
  dentalChart: DENTAL_CHART_FEATURE_MODULE,
  pastEncounters: ENCOUNTER_FEATURE_MODULE,
  patientPastTherapyList: THERAPY_FEATURE_MODULE,
  rheumatologyDiagnosisCard: CUSTOM_RHEUMATOLOGY_FEATURE_MODULE,
  rheumatologyTreatmentOverview: CUSTOM_RHEUMATOLOGY_FEATURE_MODULE,
  rsvp: RSVP_MODULE,
  unbilledItems: BILLING_FEATURE_MODULE
};

export const FEATURE_MODULE_PATIENT_PAGE_LIST = {
  [ACTIVE_ENCOUNTER_PAGE_PATIENT_SCHEMA]: ENCOUNTER_FEATURE_MODULE,
  [APPOINTMENTS_PAGE_PATIENT_SCHEMA]: CALENDAR_FEATURE_MODULE,
  [BILLS_PAGE_PATIENT_SCHEMA]: BILLING_FEATURE_MODULE,
  [DENTAL_CHART_PAGE_PATIENT_SCHEMA]: DENTAL_CHART_FEATURE_MODULE
};

export const FEATURE_MODULE_ROUTE_LIST = {
  [ANALYTICS_FEATURE_MODULE]: [baseRoutes.analytics],
  [BILLING_FEATURE_MODULE]: [baseRoutes.billing, settingsRoutes.billing.root],
  [CALENDAR_FEATURE_MODULE]: [baseRoutes.calendar, '/appointments'],
  [CLOSED_DATES_MODULE]: [employeeRoutes.closedDates],
  [COMMUNICATION_FEATURE_MODULE]: [baseRoutes.communication],
  [CUSTOM_RHEUMATOLOGY_FEATURE_MODULE]: ['/treatment-overview'],
  [ENCOUNTER_FEATURE_MODULE]: [
    baseRoutes.dashboard,
    '/active-encounter',
    '/past-encounters`',
    '/past-data`'
  ],
  [TAGS_FEATURE_MODULE]: [
    settingsRoutes.account.tags,
    settingsRoutes.account.newTag,
    settingsRoutes.account.tagDetail
  ],
  [THERAPY_FEATURE_MODULE]: [baseRoutes.therapies, '/drug-therapy'],
  [WORKING_HOURS_MODULE]: [employeeRoutes.workingHours]
};
