import React from 'react';
import ReactMarkdown from 'react-markdown';

import PropTypes from 'prop-types';

const MoshiReactMarkdown = ({ source, cellRef }) => (
  <div ref={cellRef} className={'react-markdown-wrapper'}>
    <ReactMarkdown className={'react-markdown-renderer'} source={source} />
  </div>
);

MoshiReactMarkdown.propTypes = {
  source: PropTypes.string.isRequired,
  /** cellRef is used to detect ellipsis text */
  cellRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

MoshiReactMarkdown.defaultProps = {
  cellRef: undefined
};

export default MoshiReactMarkdown;
