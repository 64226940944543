/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { CardFooter } from 'reactstrap';

import PropTypes from 'prop-types';

const CardFooterWrapper = ({ children, className, id }) => (
  <CardFooter className={className} id={id}>
    {children}
  </CardFooter>
);

CardFooterWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
};

CardFooterWrapper.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined
};

export default CardFooterWrapper;
