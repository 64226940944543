import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getInputValue } from '../../../../../utils/form';
import { useImmer } from '../../../../../utils/hooks';

import { CardMoreActionsShape } from '../../../../../metadata/shapes/TableShape';
import { CustomWidgetShape } from '../../../../../metadata/shapes/WidgetShape';
import { JsonSchemaWidgetShape } from '../../../../../metadata/shapes/json-schema/JsonSchemaWidgetShape';

import styles from './HomunculusWidget.module.scss';

import { Row, Col, Form } from '../../../../reactstrap';
import RadioItem from '../../../RadioItem';
import ControlledOverlay from '../../../modal/overlay/ControlledOverlay';
import PureSaveBar from '../../PureSaveBar';
import DefaultWidget from '../DefaultWidget';
import HomunculusList from './HomunculusList';
import HomunculusTable from './HomunculusTable';

export const HOMUNCULUS_ARRAY_FIELD = 'id100';
const HOMUNCULUS_TOTAL_COUNT_FIELD = 'id001';
const HOMUNCULUS_TOGGLE_FIELD = 'id002';

const getTotals = (selectedParts, schema, uiSchema) => {
  const clonedFormData = {};

  const totalCountSchema = _.getNonEmpty(
    schema,
    `properties.${HOMUNCULUS_TOTAL_COUNT_FIELD}.properties`,
    {}
  );

  _.forIn(totalCountSchema, (value, key) => {
    let count = 0;
    const { countField, countWith } = _.getNonEmpty(
      uiSchema,
      `${HOMUNCULUS_TOTAL_COUNT_FIELD}.${key}`,
      {}
    );

    if (!_.isEmptySafe(countField)) {
      const fieldSelectedParts = _.getNonEmpty(selectedParts, countField, []);
      let filteredSelectionList = fieldSelectedParts;

      if (!_.isEmptySafe(countWith)) {
        filteredSelectionList = _.intersection(countWith, fieldSelectedParts);
      }

      count = filteredSelectionList.length;
    }

    _.set(clonedFormData, key, count);
  });

  return clonedFormData;
};

// eslint-disable-next-line max-lines-per-function,max-statements
const HomunculusWidget = (props) => {
  const { jsonSchemaFormProps, editMode, handleCancel } = props;
  const {
    schema,
    uiSchema,
    formData,
    onChange,
    onSubmit
  } = jsonSchemaFormProps;
  const title = _.getNonEmpty(schema, 'title', '/');
  const homunculusSchema = _.getNonEmpty(uiSchema, 'x-homunculusSchema', {});
  const groupDefinitionList = _.getNonEmpty(homunculusSchema, 'list', []);
  const fields = _.getNonEmpty(homunculusSchema, 'fields', []);
  const totalText = _.getNonEmpty(homunculusSchema, 'totalText');
  const totalVisible = _.getNonEmpty(homunculusSchema, 'totalVisible');
  const toggleSchema = _.getNonEmpty(
    schema,
    `properties.${HOMUNCULUS_TOGGLE_FIELD}`
  );
  const toggleEnumValueList = _.getNonEmpty(toggleSchema, 'enum', []);
  const toggleEnumTextList = _.getNonEmpty(toggleSchema, 'enumNames', []);
  const toggleVisible = !_.isEmptySafe(toggleSchema);
  const fallbackToggleValue = _.first(toggleEnumValueList);

  const [data, setData] = useState(formData);
  const [toggleValue, setToggleValue] = useState(
    _.getNonEmpty(formData, HOMUNCULUS_TOGGLE_FIELD, fallbackToggleValue)
  );
  const [selectedParts, setSelectedParts] = useImmer(
    _.getNonEmpty(formData, HOMUNCULUS_ARRAY_FIELD, {})
  );

  const widget = _.getNonEmpty(jsonSchemaFormProps, 'widget');
  const [isOpen, setIsOpen] = useState(editMode);

  useEffect(() => {
    if (isOpen !== editMode) {
      setIsOpen(editMode);
    }
  }, [editMode, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedParts(() =>
        _.getNonEmpty(formData, HOMUNCULUS_ARRAY_FIELD, {})
      );
      setData(formData);
    }
  }, [formData, isOpen, setSelectedParts]);

  useEffect(() => {
    if (_.isEmpty(selectedParts)) {
      return;
    }

    const newFormData = {
      [HOMUNCULUS_TOTAL_COUNT_FIELD]: getTotals(
        selectedParts,
        schema,
        uiSchema
      ),
      [HOMUNCULUS_ARRAY_FIELD]: selectedParts
    };

    if (toggleVisible) {
      _.set(newFormData, HOMUNCULUS_TOGGLE_FIELD, toggleValue);
    }

    if (_.isEqual(newFormData, data)) {
      return;
    }

    setData(newFormData);
    onChange({ formData: newFormData, schema });
  }, [
    data,
    fields,
    onChange,
    schema,
    selectedParts,
    toggleValue,
    toggleVisible,
    uiSchema
  ]);

  const handleToggle = (e) => {
    const value = getInputValue(e);

    setToggleValue(value);
  };

  return (
    <React.Fragment>
      <DefaultWidget {...props} />
      {isOpen && (
        <ControlledOverlay
          isOpen
          title={title}
          onClose={() => handleCancel(data, widget)}
          containerClassName={styles.overlayContainer}
        >
          <div className={'full-width'}>
            {toggleVisible && (
              <Form className={'padding-left-35 d-flex align-items-center'}>
                <h3 className={'margin-right-20'}>
                  {_.getNonEmpty(toggleSchema, 'title', '/')}
                </h3>
                {toggleEnumValueList.map((enumValue, index) => (
                  <RadioItem
                    key={enumValue}
                    groupName={HOMUNCULUS_TOGGLE_FIELD}
                    label={toggleEnumTextList[index]}
                    onChange={handleToggle}
                    value={enumValue}
                    selectedValue={toggleValue}
                    displayAsButton
                    labelClassName={'text-uppercase'}
                  />
                ))}
              </Form>
            )}
            <Row className={classNames('padding-top-20', styles.topBorder)}>
              <Col xs={8} className={styles.rightBorder}>
                <Row>
                  <HomunculusList
                    fields={fields}
                    selectedParts={selectedParts}
                    setSelectedParts={setSelectedParts}
                  />
                </Row>
              </Col>
              <Col xs={4} className={styles.tableWrapper}>
                <HomunculusTable
                  fields={fields}
                  selectedParts={selectedParts}
                  groupDefinitionList={groupDefinitionList}
                  totalVisible={totalVisible}
                  totalText={totalText}
                />
              </Col>
            </Row>
          </div>
          <PureSaveBar
            submitButtonClassName={'ml-2'}
            visible={isOpen}
            onSubmit={() => onSubmit({ formData: data })}
            onCancel={() => handleCancel(data, widget)}
            noFormikIsValid={!_.isEmpty(data)}
          />
        </ControlledOverlay>
      )}
    </React.Fragment>
  );
};

HomunculusWidget.propTypes = {
  widget: PropTypes.shape(CustomWidgetShape).isRequired,
  jsonSchemaFormProps: PropTypes.shape(JsonSchemaWidgetShape).isRequired,
  editMode: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  widgetActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape))
    .isRequired,
  formRef: PropTypes.shape().isRequired
};

export default HomunculusWidget;
