import produce from 'immer';
import _ from 'lodash';

import moshiConfig from '../../../../utils/moshiConfig';
import {
  getListPaginationFromResponse,
  setFiltersToStoreDraft
} from '../../../../utils/redux/listUtils';

import { POLL_FORM_TEMPLATE_LIST_IN_PROGRESS } from '../../../form/template/formTemplateTypes';
import {
  CLEAR_OUTBOUND_TEMPLATE_DETAIL,
  CLEAR_OUTBOUND_TEMPLATE_LIST,
  GET_OUTBOUND_TEMPLATE_DETAIL_FAILURE,
  GET_OUTBOUND_TEMPLATE_DETAIL_IN_PROGRESS,
  GET_OUTBOUND_TEMPLATE_DETAIL_SUCCESS,
  POLL_OUTBOUND_TEMPLATES_LIST_FAILURE,
  POLL_OUTBOUND_TEMPLATES_LIST_SUCCESS,
  SET_OUTBOUND_TEMPLATE_LIST_FILTERS,
  SET_OUTBOUND_TEMPLATE_PAGINATION
} from './outboundDocumentTemplateTypes';

import { LOGOUT } from '../../../auth/authReducer';

const initialState = {
  list: [],
  filter: {},
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  },
  detail: {},
  loadingList: false,
  loadingDetail: false
};

// eslint-disable-next-line default-param-last
const outboundDocumentTemplateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case POLL_FORM_TEMPLATE_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case POLL_OUTBOUND_TEMPLATES_LIST_SUCCESS:
        const templates = _.getNonEmpty(payload, 'data', []);
        const {
          resultCount: templateResults,
          pageCount: templatePages
        } = getListPaginationFromResponse(payload, draft.pagination.limit);

        draft.pagination.resultCount = templateResults;
        draft.pagination.pageCount = templatePages;
        draft.loadingList = false;
        draft.list = _.sortBy(templates, 'name');
        break;
      case POLL_OUTBOUND_TEMPLATES_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case SET_OUTBOUND_TEMPLATE_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', 'page');
        const paginationValue = _.getNonEmpty(
          payload,
          'paginationValue',
          draft.pagination.page
        );

        draft.pagination[paginationName] = paginationValue;
        break;
      case SET_OUTBOUND_TEMPLATE_LIST_FILTERS:
        const filters = _.getNonEmpty(payload, 'filters', []);

        setFiltersToStoreDraft(draft, filters, initialState.pagination.page);
        break;
      case CLEAR_OUTBOUND_TEMPLATE_LIST:
        draft.list = initialState.list;
        draft.pagination = initialState.pagination;
        draft.filter = initialState.filter;
        break;
      case GET_OUTBOUND_TEMPLATE_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_OUTBOUND_TEMPLATE_DETAIL_SUCCESS:
        draft.detail = _.getNonEmpty(payload, 'response.data', {});
        draft.loadingDetail = false;
        break;
      case GET_OUTBOUND_TEMPLATE_DETAIL_FAILURE:
        draft.loadingDetail = false;
        break;
      case CLEAR_OUTBOUND_TEMPLATE_DETAIL:
        draft.detail = initialState.detail;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default outboundDocumentTemplateReducer;
