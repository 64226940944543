export default {
  to: 'To',
  useOnce: 'Use only once',
  useFromNowOn: 'Use from now on',
  message: 'Message',
  addMessagePlaceholder: 'Write custom message...',
  sms: {
    title: 'SMS',
    send: 'Send SMS',
    updatePhoneNumber: "Update patient's phone number"
  },
  email: {
    title: 'Email'
  },
  alert: {
    smsReminderSent: 'Sms was successfully sent.',
    smsReminderDisabled: "Patient doesn't have enabled SMS notifications."
  }
};
