import { createActionTypeArray } from '../../common/actionTypes';

export const GET_ESTIMATE_LIST = 'billing/GET_ESTIMATE_LIST';
export const ADD_ESTIMATE = 'billing/ADD_ESTIMATE';
export const UPDATE_ESTIMATE = 'billing/UPDATE_ESTIMATE';
export const PATCH_ESTIMATE = 'billing/PATCH_ESTIMATE';
export const REMOVE_ESTIMATE = 'billing/REMOVE_ESTIMATE';
export const GET_ESTIMATE_DETAIL = 'billing/GET_ESTIMATE_DETAIL';
export const CLEAR_ESTIMATE_DETAIL = 'billing/CLEAR_ESTIMATE_DETAIL';

export const SET_ESTIMATE_LIST_FILTER = 'billing/SET_ESTIMATE_LIST_FILTER';
export const SET_ESTIMATE_LIST_FILTERS = 'billing/SET_ESTIMATE_LIST_FILTERS';
export const CLEAR_ESTIMATE_LIST_FILTER = 'billing/CLEAR_ESTIMATE_LIST_FILTER';
export const CLEAR_ESTIMATE_LIST_FILTER_NO_POLL =
  'billing/CLEAR_ESTIMATE_LIST_FILTER_NO_POLL';
export const SET_ESTIMATE_LIST_ORDER = 'billing/SET_ESTIMATE_LIST_ORDER';
export const SET_ESTIMATE_LIST_PAGINATION =
  'billing/SET_ESTIMATE_LIST_PAGINATION';
export const SEND_ESTIMATE_VIA_EMAIL = 'billing/SEND_ESTIMATE_VIA_EMAIL';

export const [
  GET_ESTIMATE_LIST_IN_PROGRESS,
  GET_ESTIMATE_LIST_SUCCESS,
  GET_ESTIMATE_LIST_FAILURE
] = createActionTypeArray(GET_ESTIMATE_LIST);

export const [
  GET_ESTIMATE_DETAIL_IN_PROGRESS,
  GET_ESTIMATE_DETAIL_SUCCESS,
  GET_ESTIMATE_DETAIL_FAILURE
] = createActionTypeArray(GET_ESTIMATE_DETAIL);

export const [
  ADD_ESTIMATE_IN_PROGRESS,
  ADD_ESTIMATE_SUCCESS,
  ADD_ESTIMATE_FAILURE
] = createActionTypeArray(ADD_ESTIMATE);

export const [
  UPDATE_ESTIMATE_IN_PROGRESS,
  UPDATE_ESTIMATE_SUCCESS,
  UPDATE_ESTIMATE_FAILURE
] = createActionTypeArray(UPDATE_ESTIMATE);

export const [
  PATCH_ESTIMATE_IN_PROGRESS,
  PATCH_ESTIMATE_SUCCESS,
  PATCH_ESTIMATE_FAILURE
] = createActionTypeArray(PATCH_ESTIMATE);

export const [
  REMOVE_ESTIMATE_IN_PROGRESS,
  REMOVE_ESTIMATE_SUCCESS,
  REMOVE_ESTIMATE_FAILURE
] = createActionTypeArray(REMOVE_ESTIMATE);
