import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { selectEnabledFeatureModules } from '../../redux/organization/organizationSelectors';

export const useGetFeatureModuleEnabledChecker = () => {
  const enabledFeatureModules = useSelector(selectEnabledFeatureModules);

  return useCallback(
    (featureModule) => {
      if (_.isEmptySafe(featureModule)) {
        // If module is not defined, then feature is available
        return true;
      }
      let featureModuleList;

      if (_.isArray(featureModule)) {
        featureModuleList = featureModule;
      } else {
        featureModuleList = [featureModule];
      }

      return _.includesAny(enabledFeatureModules, featureModuleList);
    },
    [enabledFeatureModules]
  );
};

export const useIsFeatureModuleEnabled = (featureModule) => {
  const isFeatureModuleEnabled = useGetFeatureModuleEnabledChecker();

  return useMemo(() => isFeatureModuleEnabled(featureModule), [
    featureModule,
    isFeatureModuleEnabled
  ]);
};
