import React from 'react';

import PropTypes from 'prop-types';

const MoshiDisabledFormDropdownComponent = ({ name, id, prefix, value }) => (
  <input
    type={'text'}
    name={name}
    id={id}
    className={'form-control edit-mode-toggler-disabled no-break'}
    disabled
    value={`${prefix}${value}`}
  />
);

MoshiDisabledFormDropdownComponent.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  prefix: PropTypes.string,
  value: PropTypes.string
};

MoshiDisabledFormDropdownComponent.defaultProps = {
  name: undefined,
  id: undefined,
  prefix: '',
  value: ''
};

export default MoshiDisabledFormDropdownComponent;
