import _ from 'lodash';
import { createSelector } from 'reselect';

import * as registryConstants from '../../../utils/constants/redux/registryConstants';
import logger from '../../../utils/logger';
import { selectProps } from '../../../utils/redux/selectorHelpers';

import { selectCachedOrganization } from '../cache/cacheSelectors';

const ALL_REGISTRY_NAMES = _.values(registryConstants);

export const selectRegistryList = createSelector(
  [selectCachedOrganization],
  (organization) => _.getNonEmpty(organization, 'registries', {})
);

export const selectRegistryId = createSelector(
  [selectRegistryList, selectProps],
  (registryList, registryName) => {
    if (!_.includes(ALL_REGISTRY_NAMES, registryName)) {
      logger.error(`Unrecognized registry name: ${registryName}`);

      return null;
    }

    return _.getNonEmpty(registryList, registryName, null);
  }
);
