import './config/wdyrConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadProgressBar } from 'axios-progress-bar';

// START LIB STYLES IMPORT
import 'focus-visible/dist/focus-visible.min';

import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';
import 'react-phone-number-input/style.css';
// END LIB STYLES IMPORT

// Our main.scss and tailwind-output.css need to be the last in the order
import './styles/main.scss';
import './styles/tailwind-output.css';

import * as serviceWorker from './serviceWorker';

import './i18n';

import './utils/numbroConfig';
import './utils/sentryConfig';
import './utils/axiosConfig';
import './utils/lodashMixins';
import './utils/yupConfig';
import './utils/momentConfig';
import './utils/googleAnalyticsConfig';
import './config/handlebarsConfig';

import ReduxApp from './ReduxApp';
import moshiConfig from './utils/moshiConfig';
import { handleLocalStorageChange } from './utils/events/indexEvents';

loadProgressBar();

ReactDOM.render(<ReduxApp />, document.getElementById('root'));

if (!moshiConfig.disabledUnifiedSession) {
  window.addEventListener('storage', handleLocalStorageChange);
}

// Enable HMR
if (module.hot) {
  module.hot.accept('./ReduxApp', () => {
    ReactDOM.render(<ReduxApp />, document.getElementById('root'));
  });
}

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: http://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
