import React from 'react';

import classNames from 'classnames';

import {
  jsonSchemaDeselectValue,
  jsonSchemaSelectValue
} from '../../../../utils/form';

import { JsonSchemaWidgetShape } from '../../../../metadata/shapes/json-schema/JsonSchemaWidgetShape';

import ConditionalWrap from '../../layout/ConditionalWrap';

const CustomCheckboxesWidget = ({
  id,
  disabled,
  value,
  autofocus,
  readonly,
  onChange,
  options: { enumOptions, inline }
}) => (
  <div className={'checkboxes'} id={id}>
    {enumOptions.map((option, index) => {
      const checked = value.indexOf(option.value) !== -1;
      const disabledCls = disabled || readonly ? 'disabled' : '';
      const checkbox = (
        <label
          className={classNames('checkbox', disabledCls, {
            'checkbox-inline': inline
          })}
        >
          <input
            type={'checkbox'}
            id={`${id}_${index}`}
            checked={checked}
            disabled={disabled || readonly}
            autoFocus={autofocus && index === 0}
            onChange={(event) => {
              const all = enumOptions.map((enumOption) => enumOption.value);

              if (event.target.checked) {
                onChange(jsonSchemaSelectValue(option.value, value, all));
              } else {
                onChange(jsonSchemaDeselectValue(option.value, value));
              }
            }}
          />
          <span className={'checkbox-icon'} />
          <span className={'checkbox-label'}>{option.label}</span>
        </label>
      );

      return (
        <ConditionalWrap
          key={option.value}
          condition={inline === true}
          wrap={(children) => (
            <div className={`checkbox ${disabledCls}`}>{children}</div>
          )}
        >
          {checkbox}
        </ConditionalWrap>
      );
    })}
  </div>
);

CustomCheckboxesWidget.propTypes = JsonSchemaWidgetShape;

CustomCheckboxesWidget.defaultProps = {
  disabled: false,
  autofocus: false
};

export default CustomCheckboxesWidget;
