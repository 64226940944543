import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MOSHI_COLOR_RED_500 } from '../../../../../../utils/color';
import { useImmer } from '../../../../../../utils/hooks';
import {
  HOMUNCULUS_DISABLED_COLOR,
  HOMUNCULUS_OUTLINE_COLOR
} from './constants/homunculusConstants';

import styles from './Homunculus.module.scss';

import Body from './Body';
import { HUMANOID_BODY_ID } from './BodyInteractive';
import LeftFoot from './LeftFoot';
import { HUMANOID_LEFT_FOOT_ID } from './LeftFootInteractive';
import LeftHand from './LeftHand';
import { HUMANOID_LEFT_HAND_ID } from './LeftHandInteractive';
import RightFoot from './RightFoot';
import { HUMANOID_RIGHT_FOOT_ID } from './RightFootInteractive';
import RightHand from './RightHand';
import { HUMANOID_RIGHT_HAND_ID } from './RightHandInteractive';

const colorProps = {
  outlineColor: HOMUNCULUS_OUTLINE_COLOR,
  disabledColor: HOMUNCULUS_DISABLED_COLOR
};

const RADIO_COLOR = MOSHI_COLOR_RED_500;

// eslint-disable-next-line max-lines-per-function
const Homunculus = ({
  onChange,
  className,
  initialSelectedParts,
  bodyDisabled,
  bodyChestDisabled,
  bodyEarsDisabled,
  bodyHipsDisabled,
  bodyShouldersDisabled,
  bodyHeelsDisabled,
  handDisabled,
  handTipsDisabled,
  handLowDisabled,
  handWristDisabled,
  footDisabled,
  footAnklesDisabled,
  footInstepDisabled,
  footLowDisabled,
  colorOverrides
}) => {
  const [selectedParts, setSelectedParts] = useImmer({});

  useEffect(() => {
    onChange(_.flatten(_.valuesIn(selectedParts)));
  }, [onChange, selectedParts]);

  const handleChange = useCallback(
    (id, updatedList) => {
      setSelectedParts((draft) => {
        draft[id] = _.uniq(updatedList);
      });
    },
    [setSelectedParts]
  );

  const filterPartsByPartId = useCallback(
    (partId) =>
      _.filter(initialSelectedParts, (selectedPart) =>
        _.includes(selectedPart, partId)
      ),
    [initialSelectedParts]
  );

  const getColor = useCallback(
    (partId) => {
      let color;

      _.forIn(colorOverrides, (val, key) => {
        if (!_.isEmptySafe(color)) {
          return;
        }

        const safeVal = _.isArray(val) ? val : [];

        if (_.includes(safeVal, partId)) {
          color = key;
        }
      });

      if (!color) {
        color = RADIO_COLOR;
      }

      return color;
    },
    [colorOverrides]
  );

  return (
    <svg
      width={'417'}
      height={'814'}
      className={classNames(className, styles.homunculusSvg)}
      viewBox={'0 0 417 814'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <RightHand
        {...colorProps}
        getColor={getColor}
        onChange={handleChange}
        initialSelectedParts={filterPartsByPartId(HUMANOID_RIGHT_HAND_ID)}
        disabled={handDisabled}
        tipsDisabled={handTipsDisabled}
        lowDisabled={handLowDisabled}
        wristDisabled={handWristDisabled}
      />
      <LeftHand
        {...colorProps}
        getColor={getColor}
        initialSelectedParts={filterPartsByPartId(HUMANOID_LEFT_HAND_ID)}
        onChange={handleChange}
        disabled={handDisabled}
        tipsDisabled={handTipsDisabled}
        lowDisabled={handLowDisabled}
        wristDisabled={handWristDisabled}
      />
      <Body
        {...colorProps}
        getColor={getColor}
        onChange={handleChange}
        initialSelectedParts={filterPartsByPartId(HUMANOID_BODY_ID)}
        disabled={bodyDisabled}
        chestDisabled={bodyChestDisabled}
        shouldersDisabled={bodyShouldersDisabled}
        earsDisabled={bodyEarsDisabled}
        hipsDisabled={bodyHipsDisabled}
        heelsDisabled={bodyHeelsDisabled}
      />
      <LeftFoot
        {...colorProps}
        getColor={getColor}
        onChange={handleChange}
        initialSelectedParts={filterPartsByPartId(HUMANOID_LEFT_FOOT_ID)}
        disabled={footDisabled}
        anklesDisabled={footAnklesDisabled}
        instepDisabled={footInstepDisabled}
        lowDisabled={footLowDisabled}
      />
      <RightFoot
        {...colorProps}
        getColor={getColor}
        onChange={handleChange}
        initialSelectedParts={filterPartsByPartId(HUMANOID_RIGHT_FOOT_ID)}
        disabled={footDisabled}
        anklesDisabled={footAnklesDisabled}
        instepDisabled={footInstepDisabled}
        lowDisabled={footLowDisabled}
      />
    </svg>
  );
};

Homunculus.propTypes = {
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  bodyDisabled: PropTypes.bool,
  bodyChestDisabled: PropTypes.bool,
  bodyShouldersDisabled: PropTypes.bool,
  bodyEarsDisabled: PropTypes.bool,
  bodyHipsDisabled: PropTypes.bool,
  bodyHeelsDisabled: PropTypes.bool,
  handDisabled: PropTypes.bool,
  handTipsDisabled: PropTypes.bool,
  handLowDisabled: PropTypes.bool,
  handWristDisabled: PropTypes.bool,
  footDisabled: PropTypes.bool,
  footAnklesDisabled: PropTypes.bool,
  footInstepDisabled: PropTypes.bool,
  footLowDisabled: PropTypes.bool,
  colorOverrides: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
};

Homunculus.defaultProps = {
  onChange: _.noop,
  initialSelectedParts: [],
  className: undefined,
  bodyDisabled: false,
  bodyChestDisabled: false,
  bodyShouldersDisabled: false,
  bodyEarsDisabled: false,
  bodyHipsDisabled: false,
  bodyHeelsDisabled: false,
  handTipsDisabled: false,
  handLowDisabled: false,
  handWristDisabled: false,
  handDisabled: false,
  footDisabled: false,
  footAnklesDisabled: false,
  footInstepDisabled: false,
  footLowDisabled: false,
  colorOverrides: {}
};

export default Homunculus;
