import _ from 'lodash';

const patientBaseRoute = '/patients';
const patientDetail = `${patientBaseRoute}/:id`;
const patientMedicalRecord = `${patientDetail}/medical-record`;
const patientGeneralAnamnesis = `${patientMedicalRecord}/anamnesis`;
const patientPastEncounters = `${patientMedicalRecord}/past-encounters`;
const patientPastData = `${patientMedicalRecord}/past-data`;

export const patientRoutes = {
  getPatientRoute: (patientId) => `/patients/${patientId}/`,
  getPatientBillsBasePath: (patientId) => `/patients/${patientId}/bills`,
  getPatientBillsNewBillPath: (patientId, billType) =>
    `/patients/${patientId}/bills/${_.kebabCase(billType)}s/new`,
  getPatientPastEncounterRoute: (patientId, encounterId) =>
    `/patients/${patientId}/medical-record/past-encounters/${encounterId}`,
  getPatientActiveEncounterRoute: (patientId) =>
    `/patients/${patientId}/active-encounter`,
  patientBaseRoute,
  patientDetail,
  patientMedicalRecord,
  patientGeneralAnamnesis,
  patientPastEncounters,
  patientPastData
};

export const patientListRoute = '/patients';
export const patientDetailRoute = `${patientListRoute}/:patientId`;
export const patientPersonalInfoRoute = `${patientDetailRoute}/personal-info`;
export const patientEncounterRoute = `${patientDetailRoute}/active-encounter`;
