import _ from 'lodash';
import { createSelector } from 'reselect';

import { selectFormStore } from '../formSelectors';

export const selectFormTemplateStore = createSelector(
  [selectFormStore],
  (formStore) => _.getNonEmpty(formStore, 'template', {})
);

export const selectFormTemplateList = createSelector(
  [selectFormTemplateStore],
  (templateStore) => _.getNonEmpty(templateStore, 'list', [])
);

export const selectFormTemplatePagination = createSelector(
  [selectFormTemplateStore],
  (templateStore) => _.getNonEmpty(templateStore, 'pagination', {})
);

export const selectFormDetailFieldCounter = createSelector(
  [selectFormTemplateStore],
  (templateStore) => _.get(templateStore, 'detailFieldCounter', 1)
);

export const selectFormTemplateDetail = createSelector(
  [selectFormTemplateStore],
  (templateStore) => _.getNonEmpty(templateStore, 'detail', {})
);
