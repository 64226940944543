import _ from 'lodash';

import LocationAddressModel from '../../metadata/model/LocationAddressModel';

export const getLocationNameMapper = (
  locations,
  locationIdPath = 'locationID'
) => (item) => {
  const locationId = _.get(item, locationIdPath, null);
  const location = _.findDefault(locations, { id: locationId }, {});

  return {
    ...item,
    locationName: location.name
  };
};

export const safeLocationAddress = (locationObject) => {
  if (_.isEmpty(locationObject.address)) {
    locationObject.address = new LocationAddressModel('');
  }

  return locationObject;
};
