import { guid } from '../../utils/gen';

import LocationModel from './LocationModel';
import RoleModel from './RoleModel';

class UserRightModel {
  constructor(id = guid()) {
    this.id = id;
    this.role = new RoleModel('');
    this.location = new LocationModel();
  }
}

export default UserRightModel;
