import axios from 'axios';
import _ from 'lodash';

import {
  showMissingOrganizationIdError,
  showMissingPatientIdError,
  showAlertSuccess
} from '../../utils/alert';
import {
  getPatientFileListUrl,
  getPatientDetailUrl,
  getPatientWidgetFileListUrl,
  getPatientListExport,
  getPatientDetailUnbilledItemsUrl,
  getPatientDetailUnbilledItemDetailUrl,
  getPatientLookupUrl
} from '../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../utils/constants/networkConstants';
import {
  PATIENT_SORT_BY_FIRST_LAST_NAME,
  PATIENT_SORT_BY_LAST_CHECK_IN_AT,
  PATIENT_SORT_BY_LAST_FIRST_NAME
} from '../../utils/constants/patientConstants';
import { getDateFromFilterId, getSafeDateFormat } from '../../utils/date';
import { EMPTY_GUID } from '../../utils/gen';
import { isDoctorValid } from '../../utils/mappers/user-mappers';

import { EMPTY_ACTION_TYPE } from '../common/actionTypes';
import { uploadFile } from '../core/file/fileActions';
import { clearPatientAnamnesisList } from './anamnesis/patientAnamnesisActions';
import { clearPatientCave } from './cave/caveActions';

import {
  GET_PATIENT_FILES,
  REMOVE_PATIENT_FILE,
  SET_PATIENT_FILES_ORDER
} from '../core/file/fileTypes';
import {
  GET_PATIENTS,
  ADD_PATIENT,
  DELETE_PATIENT,
  SET_PATIENTS_COUNT,
  GET_PATIENT,
  GET_PATIENT_BILLS,
  UPDATE_PATIENT,
  SET_PATIENT_LIST_FILTER,
  SET_PATIENT_LIST_ORDER,
  SET_PATIENT_LIST_PAGINATION,
  TOGGLE_PATIENT_SIDE_PANEL_FORM,
  SET_PATIENT_BILL_LIST_PAGINATION,
  SET_PATIENT_BILL_LIST_ORDER,
  GET_PATIENT_UNBILLED_ITEMS,
  ADD_PATIENT_UNBILLED_ITEM,
  UPDATE_PATIENT_UNBILLED_ITEMS,
  CLEAR_PATIENT_EVENT_LIST,
  GET_PATIENT_EVENT_LIST,
  GET_PATIENT_STATS,
  CLEAR_PATIENT_STATS,
  GET_ALL_PATIENT_BILLS,
  SET_PATIENT_BILLED_ITEM_LIST_PAGINATION,
  SET_PATIENT_BILLED_ITEM_LIST_ORDER,
  GET_PATIENT_OVERLAY_DETAIL,
  CLEAR_PATIENT_OVERLAY_DETAIL,
  CLEAR_PATIENT_LIST_FILTER,
  GET_MERGE_PATIENT_DRY_RUN,
  MERGE_PATIENT,
  GET_PATIENT_FILE_BY_URL,
  EXPORT_PATIENT_LIST,
  SET_PATIENT_TAGS,
  REMOVE_PATIENT_TAG,
  CLEAR_PATIENT_TAG_LIST,
  UPDATE_PATIENT_UNBILLED_ITEM,
  GET_PATIENT_FOR_CACHE_LIST,
  HIDE_PATIENT_MERGE_TILE
} from './patientTypes';

import { selectCachedOrganizationId } from '../core/cache/cacheSelectors';
import { selectIsTagsModuleEnabled } from '../organization/organizationSelectors';
import {
  selectPatientDetailId,
  selectCachedPatientList
} from './patientSelectors';

import EntityBaseModel from '../../metadata/model/EntityBaseModel';
import BillItemModel from '../../metadata/model/billing/bill/BillItemModel';

const capitalizePatientInfo = (payload) => {
  const patientPayload = _.cloneDeep(payload);
  /**
   * We auto capitalise name, address and city
   */
  const firstName = _.getNonEmpty(patientPayload, 'identification.firstName');
  const lastName = _.getNonEmpty(patientPayload, 'identification.lastName');
  const street = _.getNonEmpty(patientPayload, 'contact.address.street1', '');
  const town = _.getNonEmpty(patientPayload, 'contact.address.town', '');

  _.set(patientPayload, 'contact.address.street1', _.sentenceCase(street));
  _.set(patientPayload, 'contact.address.town', _.sentenceCase(town));
  _.set(patientPayload, 'identification.firstName', _.sentenceCase(firstName));
  _.set(patientPayload, 'identification.lastName', _.sentenceCase(lastName));

  return patientPayload;
};

export const getPatientSortByFields = () => [
  { id: PATIENT_SORT_BY_FIRST_LAST_NAME, name: 'patient:firstNameLastName' },
  { id: PATIENT_SORT_BY_LAST_FIRST_NAME, name: 'patient:lastNameFirstName' },
  { id: PATIENT_SORT_BY_LAST_CHECK_IN_AT, name: 'patient:list.lastVisitOrder' }
];

const preparePatientPayload = (patientData) => {
  const patientPayload = EntityBaseModel.removeEntityMetadata(patientData);

  _.set(
    patientPayload,
    'identification.dateOfBirth',
    getSafeDateFormat(
      patientPayload,
      'identification.dateOfBirth',
      'YYYY-MM-DD',
      undefined
    )
  );

  const doctorId = _.getNonEmpty(patientPayload, 'doctor.doctorID', EMPTY_GUID);

  _.set(
    patientPayload,
    'doctor.doctorID',
    isDoctorValid(doctorId) ? doctorId : EMPTY_GUID
  );

  patientPayload.notifications = _.getNonEmpty(patientPayload, 'notifications');

  // remove unwanted fields
  delete patientPayload.lastCheckInAt;
  delete patientPayload.otherAttributes;

  return capitalizePatientInfo(patientPayload);
};

export const getPatients = (customFilter = {}, actionType = GET_PATIENTS) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  const isTagsModuleEnabled = selectIsTagsModuleEnabled(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const { lastCheckInAtFrom, lastCheckInAtTo, ...patientListFilter } = _.get(
    getState(),
    'patient.filter',
    {}
  );
  const { limit, page } = _.get(getState(), 'patient.pagination', {});

  const safePatientListFilter = {
    ...patientListFilter,
    lastCheckInAtFrom: getDateFromFilterId(lastCheckInAtFrom),
    lastCheckInAtTo: getDateFromFilterId(lastCheckInAtTo),
    include: isTagsModuleEnabled ? 'tags' : undefined
  };

  const filter = _.isEmpty(customFilter) ? safePatientListFilter : customFilter;

  return dispatch({
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/patients`, {
      params: {
        limit,
        page,
        ...filter
      }
    }),
    onSuccess: (notUsed, response) => {
      if (actionType === GET_PATIENTS) {
        dispatch(setPatientsCount(response.headers['x-total-count']));
      }
    }
  });
};

export const setPatientListFilter = (filterName, filterValue) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_LIST_FILTER,
    filterName,
    filterValue
  });

  dispatch(getPatients());
};

export const setPatientListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_LIST_ORDER,
    sortBy,
    order
  });

  dispatch(getPatients());
};

export const setPatientListPagination = (paginationName, paginationValue) => (
  dispatch
) => {
  dispatch({
    type: SET_PATIENT_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

  dispatch(getPatients());
};

export const setPatientsCount = (count) => (dispatch) =>
  dispatch({
    type: SET_PATIENTS_COUNT,
    count: _.toInteger(count)
  });

export const getPatient = (patientId, actionType = GET_PATIENT) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/patients/${patientId}`)
  });
};

export const getPatientBillsBase = (actionType, params) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return dispatch(showMissingOrganizationIdError());
  }

  let safePatientId = _.get(params, 'patientID');

  if (_.isEmpty(safePatientId)) {
    safePatientId = _.get(getState(), 'patient.detail.id', null);
  }

  if (_.isEmpty(safePatientId)) {
    return dispatch(showMissingPatientIdError());
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/billing/documents`, {
      params: { ...params, patientID: safePatientId }
    })
  });
};

export const getPatientBills = (patientId) => (dispatch, getState) => {
  const sortingConfig = _.get(getState(), 'patient.bills.sorting', {});
  const { limit, page } = _.get(getState(), 'patient.bills.pagination', {});

  const billFilters = {
    patientID: patientId,
    documentTypes: 'invoice,advance,credit-note,estimate',
    limit,
    page,
    ...sortingConfig
  };

  return dispatch(getPatientBillsBase(GET_PATIENT_BILLS, billFilters));
};

export const getAllPatientBills = (patientId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (_.isEmpty(organizationId)) {
    return dispatch(showMissingOrganizationIdError());
  }

  const sortingConfig = _.get(getState(), 'patient.bills.sorting', {});

  const billFilters = {
    patientID: patientId,
    documentTypes: 'invoice,advance',
    limit: REQUEST_PAGINATION_MAX_LIMIT,
    ...sortingConfig
  };

  return dispatch(getPatientBillsBase(GET_ALL_PATIENT_BILLS, billFilters));
};

export const loadPatientPatientBillsData = (patientId) => (dispatch) => {
  dispatch(getPatientBills(patientId));
  dispatch(getAllPatientBills(patientId));
};

export const setPatientBillsListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_BILL_LIST_ORDER,
    sortBy,
    order
  });

  dispatch(getPatientBills());
};

export const setPatientBillListPagination = (
  paginationName,
  paginationValue
) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_BILL_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

  dispatch(getPatientBills());
};

export const getPatientUnbilledItems = (patientId) => (dispatch, getState) => {
  let safePatientId = patientId;

  if (_.isEmpty(patientId)) {
    safePatientId = selectPatientDetailId(getState());
  }

  const url = getPatientDetailUnbilledItemsUrl(getState, safePatientId);

  if (_.isEmptySafe(url)) {
    return;
  }

  const filters = {
    limit: REQUEST_PAGINATION_MAX_LIMIT,
    includeBilled: false
  };

  return dispatch({
    type: GET_PATIENT_UNBILLED_ITEMS,
    apiCall: axios.get(url, {
      params: filters
    })
  });
};

export const addPatientUnbilledItem = (patientId, billingItem) => (
  dispatch,
  getState
) => {
  let safePatientId = patientId;

  if (_.isEmpty(patientId)) {
    safePatientId = selectPatientDetailId(getState());
  }

  const url = getPatientDetailUnbilledItemsUrl(getState, safePatientId);

  if (_.isEmptySafe(url)) {
    return;
  }

  return dispatch({
    type: ADD_PATIENT_UNBILLED_ITEM,
    apiCall: axios.post(url, billingItem),
    onSuccess: () => {
      showAlertSuccess('patient:unbilledItems.alerts.unbilledItemAdded');
    }
  });
};

export const updatePatientUnbilledItem = (
  patientId,
  unbilledItemId,
  billingItem
) => (dispatch, getState) => {
  let safePatientId = patientId;

  if (_.isEmpty(patientId)) {
    safePatientId = selectPatientDetailId(getState());
  }

  const url = getPatientDetailUnbilledItemDetailUrl(
    getState,
    safePatientId,
    unbilledItemId
  );

  if (_.isEmptySafe(url)) {
    return;
  }

  const payload = {
    billingItem
  };

  return dispatch({
    type: UPDATE_PATIENT_UNBILLED_ITEM,
    apiCall: axios.put(url, payload),
    onSuccess: () => {
      showAlertSuccess('patient:unbilledItems.alerts.unbilledItemsUpdated');
    }
  });
};

export const addOrUpdatePatientUnbilledItem = (patientId, unbilledItem) => (
  dispatch
) => {
  const unbilledItemId = _.getNonEmpty(unbilledItem, 'id', null);
  const billingItem = _.getNonEmpty(unbilledItem, 'billingItem', null);
  const isNotNew =
    !_.isEmptySafe(unbilledItemId) && !_.isEmptySafe(billingItem);

  if (isNotNew) {
    return dispatch(
      updatePatientUnbilledItem(patientId, unbilledItemId, billingItem)
    );
  } else {
    return dispatch(addPatientUnbilledItem(patientId, unbilledItem));
  }
};

export const prepareUnbilledItemsBulkPayload = (oldItems, newItems) => {
  let createdItems = _.filter(newItems, (item) =>
    _.isEmpty(item.unbilledItemID)
  );

  createdItems = _.map(createdItems, (createdItem) =>
    BillItemModel.getPayload(createdItem)
  );

  const updatedItems = {};

  _.forEach(newItems, (newItem) => {
    const newItemPayload = BillItemModel.getPayload(newItem);

    if (_.isEmpty(newItemPayload.unbilledItemID)) {
      return;
    }
    const oldItem = _.find(oldItems, {
      unbilledItemID: newItem.unbilledItemID
    });
    const oldItemPayload = BillItemModel.getPayload(oldItem);

    if (_.isEqual(newItemPayload, oldItemPayload)) {
      return;
    }

    updatedItems[newItem.unbilledItemID] = {
      billingItem: BillItemModel.getPayload(newItem)
    };
  });

  const deletedItems = _.filter(oldItems, (oldItem) =>
    _.isEmpty(_.find(newItems, { unbilledItemID: oldItem.unbilledItemID }))
  );
  const deletedItemIds = _.map(deletedItems, (item) => item.unbilledItemID);

  return { create: createdItems, update: updatedItems, delete: deletedItemIds };
};

export const updatePatientUnbilledItems = (patientId, oldItems, newItems) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  let safePatientId = patientId;

  if (_.isEmpty(patientId)) {
    safePatientId = _.get(getState(), 'patient.detail.id', null);
  }

  if (_.isEmpty(safePatientId)) {
    return dispatch(showMissingPatientIdError());
  }

  const filters = {
    limit: REQUEST_PAGINATION_MAX_LIMIT,
    includeBilled: false
  };

  const payload = prepareUnbilledItemsBulkPayload(oldItems, newItems);

  return dispatch({
    type: UPDATE_PATIENT_UNBILLED_ITEMS,
    apiCall: axios.put(
      `/organizations/${organizationId}/patients/${safePatientId}/billing/unbilledItems`,
      payload,
      {
        params: filters
      }
    ),
    onSuccess: () => {
      showAlertSuccess('patient:unbilledItems.alerts.unbilledItemsUpdated');
    }
  });
};

export const addPatient = (patientData, refetchPatients = true) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const patientPayload = preparePatientPayload(patientData);

  return dispatch({
    type: ADD_PATIENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/patients/`,
      patientPayload
    ),
    onSuccess: () => {
      if (refetchPatients) {
        dispatch(getPatients());
      }
    }
  });
};

export const updatePatient = (patientData, onSuccessCustomAction = null) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const patientId = patientData.id;
  const patientPayload = preparePatientPayload(patientData);

  return dispatch({
    type: UPDATE_PATIENT,
    apiCall: axios.put(
      `/organizations/${organizationId}/patients/${patientId}`,
      patientPayload
    ),
    onSuccess: () => {
      if (_.isFunction(onSuccessCustomAction)) {
        return dispatch(onSuccessCustomAction(patientId));
      }
      showAlertSuccess('patient:alerts.patientUpdated');
    }
  });
};

export const patchPatient = (patientId, patientData) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: UPDATE_PATIENT,
    apiCall: axios.put(
      `/organizations/${organizationId}/patients/${patientId}`,
      patientData
    )
  });
};

export const deletePatient = (patientId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: DELETE_PATIENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/patients/${patientId}`
    ),
    onSuccess: () => {
      showAlertSuccess('patient:alerts.patientDeleted');
      dispatch(getPatients());
    }
  });
};

export const togglePatientSidepanelForm = (isOpen) => (dispatch) =>
  dispatch({
    type: TOGGLE_PATIENT_SIDE_PANEL_FORM,
    isOpen
  });

export const clearPatientEventList = () => (dispatch) =>
  dispatch({
    type: CLEAR_PATIENT_EVENT_LIST
  });

export const clearPatientTagList = () => (dispatch) =>
  dispatch({
    type: CLEAR_PATIENT_TAG_LIST
  });

export const getPatientEventList = (patientID, includeQueued = false) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const queryObject = {
    includeQueued
  };

  return dispatch({
    type: GET_PATIENT_EVENT_LIST,
    apiCall: axios.get(
      `/organizations/${organizationId}/patients/${patientID}/calendar`,
      {
        params: queryObject
      }
    )
  });
};

export const setPatientBilledItemListOrder = (sortBy, order) => (dispatch) =>
  dispatch({
    type: SET_PATIENT_BILLED_ITEM_LIST_ORDER,
    sortBy,
    order
  });

export const setPatientBilledItemListPagination = (
  paginationName,
  paginationValue
) => (dispatch) =>
  dispatch({
    type: SET_PATIENT_BILLED_ITEM_LIST_PAGINATION,
    paginationName,
    paginationValue
  });

export const getPatientStats = (patientId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: GET_PATIENT_STATS,
    apiCall: axios.get(
      `/organizations/${organizationId}/patients/${patientId}/stats`
    )
  });
};

export const clearPatientStats = () => (dispatch) => {
  dispatch({
    type: CLEAR_PATIENT_STATS
  });
};

export const getPatientOverlayDetail = (patientId) => (dispatch) =>
  dispatch(getPatient(patientId, GET_PATIENT_OVERLAY_DETAIL));

export const clearPatientOverlayDetail = () => (dispatch) =>
  dispatch({ type: CLEAR_PATIENT_OVERLAY_DETAIL });

export const mergePatientDryRun = (targetId, sourceIds = []) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return dispatch(showMissingOrganizationIdError());
  }

  const query = {
    targetID: targetId,
    sourceIDs: sourceIds.join(',')
  };

  return dispatch({
    type: GET_MERGE_PATIENT_DRY_RUN,
    apiCall: axios.get(
      `/organizations/${organizationId}/patients/merge/dryRun`,
      {
        params: query
      }
    )
  });
};

export const mergePatient = (data) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (_.isEmpty(organizationId)) {
    return dispatch(showMissingOrganizationIdError());
  }

  const patientPayload = {
    targetID: data.selectedPatientId,
    patientData: data.values,
    sourceIDs: data.sourceIds
  };

  /**
   * LocationIDs are a comma separated string at this point, so we have to convert
   * it to an array for the API to consume.
   */
  if (!_.isEmptySafe(data, 'values.doctor.locationIDs')) {
    const locationIds = _.get(data, 'values.doctor.locationIDs').split(',');

    _.set(patientPayload, 'patientData.doctor.locationIDs', locationIds);
  }

  return dispatch({
    type: MERGE_PATIENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/patients/merge`,
      patientPayload
    ),
    onSuccess: () => {
      showAlertSuccess('patient:merging.mergeSuccess');
    }
  });
};

export const sendCustomSmsToPatient = (patientId, { phoneNumber, message }) => (
  dispatch,
  getState
) => {
  const patientUrl = getPatientDetailUrl(getState, patientId);

  return dispatch({
    type: EMPTY_ACTION_TYPE,
    apiCall: axios.post(`${patientUrl}/messages`, {
      phoneNumber,
      message,
      force: true
    }),
    onSuccess: () => {
      showAlertSuccess('reminder:alert.smsReminderSent');
    }
  });
};

export const clearPatientListFilters = (refetchPatientList = false) => (
  dispatch
) => {
  dispatch({ type: CLEAR_PATIENT_LIST_FILTER });

  if (refetchPatientList) {
    dispatch(getPatients());
  }
};

// Patient documents
export const sortPatientFiles = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_FILES_ORDER,
    filter: { sortBy, order }
  });
};

export const addPatientFile = (patientId, file, meta, options = {}) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  if (!patientId) {
    return showMissingPatientIdError();
  }

  return dispatch(
    uploadFile(
      `/organizations/${organizationId}/patients/${patientId}/files`,
      file,
      { meta },
      options
    )
  );
};

export const getPatientFiles = (
  patientId,
  metaFilters,
  config = {},
  type = GET_PATIENT_FILES
) => (dispatch, getState) => {
  const url = getPatientFileListUrl(getState, patientId);

  if (_.isEmptySafe(url)) {
    return;
  }

  if (!_.isEmpty(metaFilters)) {
    const currentHeaders = _.getNonEmpty(config, 'headers', {});

    _.set(config, 'headers', {
      ...currentHeaders,
      'X-Meta-Filter': JSON.stringify(metaFilters)
    });
  }

  return dispatch({
    type,
    apiCall: axios.get(url, config)
  });
};

export const removePatientFile = (
  fileId,
  patientId,
  onSuccess = _.noop,
  actionType = REMOVE_PATIENT_FILE
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  if (!patientId) {
    return showMissingPatientIdError();
  }

  return dispatch({
    type: actionType,
    apiCall: axios.delete(
      `/organizations/${organizationId}/patients/${patientId}/files/${fileId}`
    ),
    onSuccess: () => {
      onSuccess();
    }
  });
};

export const getPatientWidgetFiles = (
  patientId,
  config = {},
  type = GET_PATIENT_FILES
) => (dispatch, getState) => {
  const url = getPatientWidgetFileListUrl(getState, patientId);

  if (_.isEmptySafe(url)) {
    return;
  }

  return dispatch({
    type,
    apiCall: axios.get(url, config)
  });
};

export const getPatientFileByUrl = (
  fileUrl,
  actionType = GET_PATIENT_FILE_BY_URL,
  payload = {}
) => (dispatch) =>
  dispatch({
    type: actionType,
    apiCall: axios.get(fileUrl, {
      responseType: 'json'
    }),
    payload
  });

export const exportPatientList = (locationID) => (dispatch, getState) => {
  const patientListExportUrl = getPatientListExport(getState);

  return dispatch({
    type: EXPORT_PATIENT_LIST,
    apiCall: axios.get(patientListExportUrl, { params: { locationID } }),
    onSuccess: () => {
      showAlertSuccess('patient:export.alert.requestSuccess', { closeIn: 5 });
    }
  });
};

// Tags
export const setPatientTags = (tagList) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_TAGS,
    payload: tagList
  });
};

export const removePatientTag = (tagId) => (dispatch) => {
  dispatch({
    type: REMOVE_PATIENT_TAG,
    payload: tagId
  });
};

export const removeDuplicatedPatient = (
  patientId,
  duplicateIdList,
  patientIdToRemove
) => (dispatch) => {
  const data = {
    identification: {
      possibleDuplicates: _.filter(
        duplicateIdList,
        (duplicatedPatientId) => duplicatedPatientId !== patientIdToRemove
      )
    }
  };

  return dispatch(patchPatient(patientId, data));
};

export const clearPatientData = () => (dispatch) => {
  dispatch(clearPatientAnamnesisList());
  dispatch(clearPatientCave());
  dispatch(clearPatientTagList());
};

export const getPatientListToCache = (patientIds) => (dispatch, getState) => {
  const cachedPatientList = selectCachedPatientList(getState());

  let safePatientIds;

  if (_.isArray(patientIds)) {
    safePatientIds = patientIds;
  } else {
    safePatientIds = [patientIds];
  }

  _.forEach(safePatientIds, (patientId) => {
    const existingData = _.getNonEmpty(cachedPatientList, patientId, null);

    if (_.isEmptySafe(existingData)) {
      dispatch(getPatient(patientId, GET_PATIENT_FOR_CACHE_LIST));
    }
  });
};

export const hidePatientPatientMergeTile = (patientId) => (dispatch) => {
  dispatch({
    type: HIDE_PATIENT_MERGE_TILE,
    patientId
  });
};

export const findDuplicatedPatients = (patient) => (dispatch, getState) => {
  const url = getPatientLookupUrl(getState);

  if (_.isEmptySafe(url)) {
    return;
  }

  const params = {
    email: _.getNonEmpty(patient, 'contact.email', null),
    phoneNumber: _.getNonEmpty(patient, 'contact.phoneNumber', null),
    dateOfBirth: _.getNonEmpty(patient, 'identification.dateOfBirth', null)
  };

  return dispatch({
    type: EMPTY_ACTION_TYPE,
    apiCall: axios.get(url, { params })
  });
};
