import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { CardMoreActionsShape } from '../../../../../metadata/shapes/TableShape';
import { CustomWidgetShape } from '../../../../../metadata/shapes/WidgetShape';

import BasicMoshiModal from '../../../modal/BasicMoshiModal';
import CustomDescriptionField from '../CustomDescriptionField';
import DefaultWidget from '../DefaultWidget';
import QuestionnaireWidgetForm from './QuestionnaireWidgetForm';

export const fields = {
  DescriptionField: CustomDescriptionField
};

const QuestionnaireWidget = ({
  jsonSchemaFormProps,
  editMode,
  handleCancel,
  widgetActions,
  widget,
  formRef
}) => {
  const { t: translate } = useTranslation();

  const handleToggleOpened = (newIsOpen) => {
    const safeIsOpen = _.isUndefined(newIsOpen) ? editMode : newIsOpen;

    if (!safeIsOpen) {
      handleCancel();
    }
  };

  const uiSchema = _.getNonEmpty(jsonSchemaFormProps, 'uiSchema', {});
  const defaultWidgetUiSchema = _.cloneDeep(uiSchema);

  _.forIn(defaultWidgetUiSchema, (value, key) => {
    if (_.isPlainObject(value)) {
      _.set(defaultWidgetUiSchema, key, {
        ...value,
        classNames: 'hidden'
      });
    }
  });

  return (
    <React.Fragment>
      <DefaultWidget
        widgetActions={widgetActions}
        handleCancel={handleCancel}
        editMode={false}
        jsonSchemaFormProps={{
          ...jsonSchemaFormProps,
          uiSchema: defaultWidgetUiSchema,
          liveValidate: undefined,
          noHtml5Validate: undefined,
          showErrorList: undefined
        }}
        widget={widget}
        formRef={formRef}
      />
      <BasicMoshiModal
        isOpen={editMode}
        toggleOpened={handleToggleOpened}
        title={translate('document:questionnaires.title')}
        className={'add-questionnaire-widget-modal padded-body'}
      >
        <QuestionnaireWidgetForm
          jsonSchemaFormProps={jsonSchemaFormProps}
          handleCancel={handleToggleOpened}
        />
      </BasicMoshiModal>
    </React.Fragment>
  );
};

QuestionnaireWidget.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  widget: PropTypes.shape(CustomWidgetShape).isRequired,
  jsonSchemaFormProps: PropTypes.shape().isRequired,
  widgetActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape)),
  formRef: PropTypes.shape()
};

QuestionnaireWidget.defaultProps = {
  formRef: undefined,
  widgetActions: []
};

export default QuestionnaireWidget;
