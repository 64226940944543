import {
  BILL_TYPE_ADVANCE,
  BILL_TYPE_CREDIT_NOTE,
  BILL_TYPE_DELIVERY_NOTE,
  BILL_TYPE_ESTIMATE,
  BILL_TYPE_INVOICE,
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_ISSUED,
  INVOICE_STATUS_OVERDUE,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_UNPAID,
  INVOICE_STATUS_DELETED,
  INVOICE_STATUS_PARTIALLY_USED,
  INVOICE_STATUS_USED,
  EXPORT_COLUMN_ITEM_QUANTITY,
  EXPORT_COLUMN_ITEM_TAX,
  EXPORT_COLUMN_ITEM_PRICE,
  EXPORT_COLUMN_ITEM_TOTAL,
  EXPORT_COLUMN_ITEM_CATEGORY_NAME,
  EXPORT_COLUMN_ITEM_NAME,
  EXPORT_COLUMN_ISSUED_AT,
  EXPORT_COLUMN_DOCUMENT_DATE,
  EXPORT_COLUMN_CREATED_AT,
  EXPORT_COLUMN_DUE_DATE,
  EXPORT_COLUMN_PAYMENT_TYPE,
  EXPORT_COLUMN_TOTAL_PAID,
  EXPORT_COLUMN_PAID,
  EXPORT_COLUMN_TAX,
  EXPORT_COLUMN_TOTAL_WITHOUT_TAX,
  EXPORT_COLUMN_TOTAL,
  EXPORT_COLUMN_CANCELED,
  EXPORT_COLUMN_DOCTOR_NAME,
  EXPORT_COLUMN_DOCTOR_ID,
  EXPORT_COLUMN_CLIENT_REGISTRATION_NUMBER,
  EXPORT_COLUMN_CLIENT_TAX_NUMBER,
  EXPORT_COLUMN_CLIENT_NAME,
  EXPORT_COLUMN_CLIENT_ADDRESS,
  EXPORT_COLUMN_PATIENT_NUMBER,
  EXPORT_COLUMN_TYPE,
  EXPORT_REFERENCE_NUMBER,
  EXPORT_COLUMN_NUMBER
} from '../../utils/billing';
import {
  PAYMENT_TYPE_ADVANCE,
  PAYMENT_TYPE_BANK,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_COUPON,
  PAYMENT_TYPE_ONLINE,
  PAYMENT_TYPE_OTHER,
  PAYMENT_TYPE_PAYPAL
} from '../../utils/data/billing';

import { formatting } from './date';

export default {
  types: {
    [BILL_TYPE_INVOICE]: '$t(billing:invoices.invoice)',
    [BILL_TYPE_ESTIMATE]: '$t(billing:estimates.estimate)',
    [BILL_TYPE_ADVANCE]: '$t(billing:advances.advance)',
    [BILL_TYPE_CREDIT_NOTE]: '$t(billing:creditNotes.creditNote)',
    [BILL_TYPE_DELIVERY_NOTE]: '$t(billing:deliveryNotes.deliveryNote)'
  },
  export: {
    typeLabel: 'Kaj želite izvoziti?',
    columnsLabel: 'Katere stolpce želite v izvozu?',
    validation: {
      documentTypesLength: 'Izbrati morate vsaj 1 tip dokumenta'
    },
    exportBillingItems: 'Izvoz predmetov in storitev',
    itemsWithCategories: 'Predmete in storitve s kategorijami',
    includeColumnsDescription: `<0><1>Seznam prikazanih stolpcev v datoteki. Vsi stolpci bodo vključeni, če je ta selekcija prazna. </1>
    <2>Vrstni red stolpcev je enak tej selekciji.</2><2> Stolpci s "predmeti" bodo vključeni, če so vključeni "Predmeti in storitve s kategorijami"</2></0>`
  },
  invoices: {
    title: 'Računi',
    invoice: 'Račun',
    new: 'Nov račun',
    view: 'Račun: {{ number }}',
    viewDraft: 'Osnutek računa: {{ number }}',
    create: 'Ustvarite račun',
    createFromDraft: 'Ustvari račun iz osnutka',
    issue: 'Izdajte račun',
    issueWithoutPayment: 'Izdajte brez plačila',
    issuePartiallyPaid: 'Izdajte delno plačano',
    save: 'Shranite račun',
    saveAsDraft: 'Shranite osnutek',
    savingBill: 'Račun se shranjuje...',
    savingDraft: 'Osnutek se shranjuje...',
    issuingBill: 'Račun se izdaja...',
    referencedDocuments: 'Povezani dokumenti:',
    exportTo: 'Izvozite v račun',
    draft: 'Osnutek računa',
    void: 'Stornirajte',
    details: 'Podrobno',
    number: 'Št. računa',
    previousNumber: 'Prejšnja številka računa: {{ number }}',
    dateIssued: 'Datum izdaje',
    totalAmount: 'Znesek',
    dueDate: 'Rok plačila',
    dueDateDaysPlaceholder: 'Dni do plačila',
    dueDays: '{{number}} dni',
    dateService: 'Datum storitve',
    paid: 'Plačano',
    total: 'Vsota',
    totalWithTax: 'Vsota z davkom',
    totalPerTaxRate: 'Davek {{ taxRate }}% od {{ price }} {{ currency }}',
    billedTo: 'Plačnik:',
    payment: 'Plačilo',
    payments: 'Plačila',
    addPayment: 'Dodaj plačilo',
    editPayment: 'Uredi plačilo',
    paymentType: 'Način plačila',
    disabledPayments:
      '{{ billType }}, izdane pred današnjim dnem, je možno plačati le z bančnim nakazilom ali PayPal-om.',
    disabledPaymentsTooltip:
      'Če želite dodati plačilo, ki ni na voljo, je potrebno račun stornirati in ga ponovno ustvariti.',
    amount: 'Količina',
    remainingDue: 'Neplačano:',
    noPaymentsYet: 'Na račun še ni bilo dodanih plačil.',
    confirmVoiding: {
      title: 'Stornirajte {{ entityName }}',
      message: 'Želite stornirati {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    confirmRemoving: {
      title: 'Prekličite {{ entityName }}',
      message: 'Želite preklicati {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    confirmDeleting: {
      title: 'Izbrišite {{ entityName }}',
      message: 'Želite izbrisati {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    paymentTypes: {
      [PAYMENT_TYPE_CASH]: 'Gotovina',
      [PAYMENT_TYPE_BANK]: 'Bančno nakazilo',
      [PAYMENT_TYPE_CARD]: 'Kartica',
      [PAYMENT_TYPE_PAYPAL]: 'Paypal',
      [PAYMENT_TYPE_COUPON]: 'Kupon',
      [PAYMENT_TYPE_ONLINE]: 'Spletno',
      [PAYMENT_TYPE_OTHER]: 'Drugo',
      [PAYMENT_TYPE_ADVANCE]: 'Avans'
    },
    status: {
      title: 'Status',
      [INVOICE_STATUS_ISSUED]: 'Izdano',
      [INVOICE_STATUS_CANCELED]: 'Preklicano',
      [INVOICE_STATUS_PAID]: 'Plačano',
      [INVOICE_STATUS_PARTIALLY_PAID]: 'Delno plačano',
      [INVOICE_STATUS_DRAFT]: 'Osnutek',
      [INVOICE_STATUS_OVERDUE]: 'Zapadlo',
      [INVOICE_STATUS_UNPAID]: 'Neplačano',
      [INVOICE_STATUS_DELETED]: 'Izbrisano',
      [INVOICE_STATUS_USED]: 'Porabljeno',
      [INVOICE_STATUS_PARTIALLY_USED]: 'Delno porabljeno'
    },
    issuer: 'Izdajatelj',
    zoi: 'ZOI',
    eor: 'EOR',
    validation: {
      itemsLength: 'Izbran mora biti vsaj en predmet ali storitev.',
      total: 'Skupna vsota računa ne sme biti negativna',
      amountHigherThanAdvance:
        'Znesek je večji od razpoložljive vrednosti avansa'
    },
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih računov.</0> <1></1>
      <0>Dodaj nov račun.</0>
    `,
    noPatientSelected: 'Na računu ni dodanega pacienta.',
    alerts: {
      itemAdded: 'Predmet oz. storitev je bila uspešno dodana.',
      invoiceCreated: 'Račun je bil uspešno ustvarjen.',
      draftCreated: 'Osnutek računa je bil uspešno ustvarjen.',
      invoiceUpdated: 'Račun je bil uspešno posodobljen.',
      draftUpdated: 'Osnutek računa je bil uspešno posodobljen.',
      invoiceVoided: 'Račun je bil uspešno storniran.',
      draftRemoved: 'Osnutek računa je bil uspešno izbrisan.',
      invoiceRemoved: 'Račun je bil uspešno preklican.',
      paymentAdded: 'Plačilo je bilo uspešno dodano.',
      paymentRemoved: 'Plačilo je bilo uspešno odstranjeno.',
      pdfSentViaEmail: 'PDF je bil uspešno poslan na izbran e-mail naslov.'
    }
  },
  settings: {
    title: 'Računi',
    billingUnit: 'Podjetje',
    newBillingUnit: 'Novo podjetje',
    addBillingUnit: 'Dodaj podjetje',
    billingUnitDeactivated: '{{name}} (deaktiviran)',
    billingUnitInfo: `
      <0>$t(settings:account.taxNumber)</0> {{ companyDetails.taxNumber }} <0>∙</0>
      <0>$t(settings:account.companyId)</0> {{ companyDetails.companyID }} <0>∙</0>
      <0>$t(billing:settings.general.iban)</0> {{ companyDetails.iban }}
    `,
    removeBillingUnitTitle: 'Odstranite podjetje',
    removeBillingUnitMessage:
      'Želite odstraniti podjetje: <0>{{ billingUnitName }}</0>?',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih podjetij.</0> <1></1>
      <0>Za začetek ustvarite novo podjetje.</0>
    `,
    billType: 'Vrsta računa',
    customization: {
      title: 'Prilagoditve',
      textSettings: 'Nastavitve privzetih besedil',
      logo: 'Logotip',
      signature: 'Podpis',
      paymentDetails: 'Nastavitve plačila',
      missingObjects: {
        noLogo: '$t(billing:settings.customization.logo) ni nastavljen',
        noSignature:
          '$t(billing:settings.customization.signature) ni nastavljen'
      },
      texts: {
        description:
          'Spremembe v besedilu bodo vidne šele na novo izdanih računih. Za urejanje besedil na že izdanih računih morate urediti vsak račun posebej.',
        localeNotSet:
          'Če želite urejati besedila potrebujete izbrati privzeti jezik',
        bodyText: 'Telo besedila',
        invoiceNote: 'Besedilo na računu',
        estimateNote: 'Besedilo na predračunu',
        advanceNote: 'Besedilo na avansu',
        creditNoteNote: 'Besedilo na dobropisu',
        signature: 'Podpis',
        header: 'Glava',
        footer: 'Noga',
        legend: `
          <0>Legenda</0>
          <1>
            Sledeče pametne bližnjice lahko vstavite v tekst in samodejno
            bodo zamenjane za pripadajoči podatek:
          </1>
          <1>[organization name] - ime organizacije.</1>
          <1>[document number] - številke trenutnega dokumenta.</1>
          <1>[IBAN] - IBAN številka.</1>
          <1>[SWIFT] - SWIFT referenca.</1>
          <1>[bank] - ime vaše banke.</1>
          <1>[address] - naslov.</1>
          <1>[address2] - dodatni naslov.</1>
          <1>[zip] - poštna številka.</1>
          <1>[city] - mesto.</1>
          <1>[country] - država sedeža podjetja.</1>
          <1>[tax number] - davčna številka podjetja.</1>
          <1>[company number] - matična številka podjetja.</1>
          <1>
            [document type] - ime dokumenta (npr. račun, predračun, avans, itd.)
          </1>
          <2>Zakaj uporabiti pametne bližnjice?</2>
          <1>
            Z uporabo pametnih bližnjic boste vedno imeli najnovejše podatke.
          </1>
          <1>
            Na primer, če uporabite bližnjico [IBAN] in enkrat v prihodnosti
            zamenjate bančno številko vašega podjetja v nastavitvah, se bo številka
            na izpisanem dokumentu samodejno posodobila.
          </1>
        `
      }
    },
    locale: 'Lokalizacija',
    general: {
      title: 'Osnovne nastavitve',
      iban: 'IBAN',
      ibanInput: '$t(billing:settings.general.iban): {{ iban }}',
      ibanPlaceholder: 'SI56123456789',
      swift: 'SWIFT',
      swiftPlaceholder: 'LJBASI2X',
      bank: 'Ime banke',
      bankPlaceholder: 'NLB',
      defaultLocale: 'Privzeti jezik',
      currency: 'Valuta',
      noTax: 'Brez',
      taxNumber: 'Davčna številka',
      taxId: 'ID za DDV',
      taxNumberPlaceholder: '12345678',
      taxSubject: 'Davčni zavezanec',
      companyId: 'Matična številka',
      companyIdPlaceholder: '1234567890',
      invoiceDueDays: 'Privzet rok računa',
      invoiceDueDaysPlaceholder: '30',
      legalRepresentative: 'Pravni zastopnik',
      estimateValidTillDays: 'Privzeta veljavnost predračuna',
      estimateValidTillDaysPlaceholder: '30',
      confirmIssuingBill: 'Da, izdaj {{ billType, lowercase }}',
      cancelIssuingBill: 'Ne, vrni se nazaj'
    },
    fiscalization: {
      title: 'Davčno potrjevanje',
      location: 'Lokacija',
      device: 'Naprava',
      selectDevices: 'Izberite naprave',
      toggleEnableFiscalization: 'Vklopi davčno potrjevanje',
      disabledFiscalizationWarning:
        'Najprej morate izpolniti osnovne nastavitve, šele nato lahko nadaljujete z nastavitvami za davčno potrjevanje',
      fiscalize: 'Davčno potrdite',
      section: {
        certificate: '1. Certifikat',
        businessPremises: '2. Poslovni prostori',
        electronicDevices: '3. Elektronske naprave',
        numbering: '4. Strategija številčenja',
        act: '5. Akt',
        activate: '6. Vklop'
      },
      numbering: {
        description:
          'Vaše račune lahko številčite ali po poslovnih prostorih ali po elektronskih napravah.',
        dropdownLabel: 'Tip številčenja',
        byPremise: 'Po poslovnih prostorih',
        byDevice: 'Po elektronskih napravah'
      },
      certificate: {
        upload: 'Certifikat',
        password: '$t(common:password)',
        description: `
        <0>
          Za pravilno delovanje davčne blagajne je potrebno pridobiti certifikat Finančne uprave RS preko portala eDavki.
        </0>
        <0>
          V kolikor niste uporabnik portala eDavki in se nanj nimate namena registrirati, lahko za prevzem pooblastite drugo osebo npr. vaše računovodstvo.
        </p>
        `
      },
      status: {
        fiscalised: 'Davčno potrjen',
        notFiscalised: 'Ni davčno potrjen'
      },
      businessPremises: {
        add: 'Dodajte nov prostor',
        label: 'Ime',
        labelLong: 'ID poslovnega prostora',
        zipAndTown: '$t(common:address.zipShort) in $t(common:address.town)',
        street: 'Ulica',
        municipality: 'Občina',
        houseNumber: 'Št.',
        houseNumberAdditional: 'Oznaka',
        cadastralNumber: 'Katasterska',
        cadastralNumberLong: 'Številka katastrske občine',
        buildingNumber: 'Stavba',
        buildingNumberLong: 'Št. stavbe',
        buildingSection: 'Razdelek / Del',
        buildingSectionLong: 'Razdelek / Del stavbe',
        description: `
        <0>
          Poslovni prostor je vsak nepremičen ali premičen prostor, v katerem zavezanec stalno,
          občasno ali začasno izdaja račune za dobave blaga ali storitev pri gotovinskem poslovanju.
          Poslovnemu prostoru je treba dodeliti interno oznako ter ga popisati najmanj do nivoja naslova.
        </0>
        <1>
          Katastrske podatke vaših poslovnih prostorov lahko najdete na tej povezavi:
        </1>
        <2>https://eprostor.gov.si/javni/</2>
        `
      },
      electronicDevices: {
        add: 'Dodajte novo napravo',
        label: 'Ime naprave',
        description:
          'Kot elektronske naprave so označeni posamezni računalniki (blagajne), preko katerih se v poslovnih prostorih izdajajo računi.'
      },
      act: {
        description: `
        <0>
          Spodaj smo glede na vnešene podatke zgenerirali predlog akta, ki opredeljuje vaše poslovne prostore,
          elektronske naprave in način številčenja. Akt morate imeti urejen, pred pričetkom izdajanja
          davčno potrjenih računov. Prosimo preglejte akt, ga uredite po potrebi, ter ga natisnite.
        </0>
        <1>
          Zakon predvideva, da mora izdajatelj računov na vidno mesto postaviti obvestilo o obveznosti
          prevzema in zadržanja računa, obvestilo lahko najdete na naslednji povezavi:
        </1>
        <2>http://www.fu.gov.si/nadzor/podrocja/davcne_blagajne_in_vezane_knjige_racunov_vkr/</2>
        `
      },
      activate: {
        save: 'Shranite nastavitve in aktivirajte',
        description: `
        <0>
          S klikom na sledeči gumb boste aktivirali davčno potrjevanje računov, avansov in dobropisov,
          ki bodo avtomatično kriptografsko podpisani in poslani na FURS, ko jih boste shranili.
        </0>
        `
      },
      invoiceNumberingType: 'Način številčenja',
      invoiceNumberingByPremise: 'Po poslovnih prostorih',
      invoiceNumberingByDevice: 'Po elektroniskih napravah',
      invoiceNumberingByDescription: `
      <0>$t(billing:settings.fiscalization.invoiceNumberingByPremise)</0>
      <1>
        Vaši računi se bodo številčili individualno po posameznem poslovnem prostoru.
      </1>
      <0>$t(billing:settings.fiscalization.invoiceNumberingByDevice)</0>
      <1>
        Vaši računi se bodo številčili individualno po posamezni elektronski napravi.
      </1>
      `
    }
  },
  bulkItems: {
    title: 'Predmete in storitve',
    message: 'Želite odstraniti predmete in storitve?'
  },
  items: {
    title: 'Predmeti in storitve',
    add: 'Dodajte predmet / storitev',
    newItem: 'Nov predmet ali storitev',
    item: 'Predmet',
    category: '$t(settings:billing.category.title)',
    description: 'Opis',
    unit: 'Enota',
    price: 'Neto cena',
    discount: 'Popust',
    total: 'Končna cena',
    sku: 'SKU',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih predmetov/storitev.</0> <1></1>
      <0>Za začetek ustvarite nov predmet/storitev.</0>
    `,
    alerts: {
      billingItemCreated: 'Predmet ali storitev je bil/a ustvarjen/a',
      billingItemUpdated: 'Predmet ali storitev je bil/a posodobljen/a',
      billingItemExists: 'Predmet ali storitev s enakim imenom že obstaja.',
      billingItemRemoved: 'Predmet ali storitev je bil/a odstranjen/a',
      billingItemsRemoved: 'Predmeti ali storitve so odstranjeni/e',
      billingItemsBatchUpdated: 'Predmeti ali storitve so posodobljeni/e'
    },
    searchItems: 'Iščite predmete in storitve',
    editingItems: 'Urejate naslednje predmete/storitve:',
    editingValues: 'Izpolnite vrednosti, ki jih želite urediti:',
    tooltip: {
      itemEnabled:
        // eslint-disable-next-line max-len
        'Z deaktivacijo predmeta ali storitve, ga/jo boste skrili v menu-jih. Predmet ali storitev pa bo še vedno ostala na tem seznamu. Predmet ali storitev lahko kadarkoli ponovno aktivirate.'
    },
    groupBillingItems: 'Seštejte iste predmete in storitve na seznamu'
  },
  taxes: {
    title: 'Davki',
    tax: 'Davek',
    add: 'Dodaj davek',
    newTax: 'Nov davek',
    abbreviation: 'Oznaka',
    rate: 'Stopnja',
    taxValue: '{{ value }}%',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih davkov.</0> <1></1>
      <0>Za začetek ustvarite nov davek.</0>
   `
  },
  estimates: {
    title: 'Predračuni',
    estimate: 'Predračun',
    new: 'Nov predračun',
    view: 'Predračun: {{ number }}',
    create: 'Ustvarite predračun',
    issue: 'Izdajte predračun',
    save: 'Shranite predračun',
    savingBill: 'Predračun se shranuje...',
    issuingBill: 'Predračun se izdaja...',
    exportTo: 'Izvozite v predračun',
    validUntil: 'Veljavno do',
    validUntilPlaceholder: 'Veljavno dni',
    previousNumber: 'Prejšnja številka predračuna: {{ number }}',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih predračunov.</0> <1></1>
      <0>Za začetek ustvarite nov predračun.</0>
    `,
    noPatientSelected: 'Na predračunu ni dodanega pacienta.',
    alerts: {
      estimateCreated: 'Predračun je bil uspešno ustvarjen.',
      estimateUpdated: 'Predračun je bil uspešno posodobljen.',
      estimateRemoved: 'Predračun je bil uspešno izbrisan.'
    },
    validation: {
      total: 'Skupna vsota predračuna ne sme biti negativna'
    }
  },
  advances: {
    title: 'Avansi',
    advance: 'Avans',
    new: 'Nov avans',
    view: 'Avans: {{ number }}',
    create: 'Ustvarite avans',
    issue: 'Izdajte avans',
    save: 'Shranite avans',
    savingBill: 'Avans se shranuje...',
    issuingBill: 'Avans se izdaja...',
    placeholder: 'Izberite avans',
    basedOn: 'Predplačilo na osnovi avansa {{ number }}',
    exportTo: 'Izvozite v avans',
    datePaid: 'Datum plačila',
    previousNumber: 'Prejšnja številka avansa: {{ number }}',
    advancePayment: 'Avansno plačilo',
    addReferencingDocument: 'Dodajte avansno predplačilo',
    noPaymentsYet: 'Na avans še ni bilo dodanih plačil.',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih avansov.</0> <1></1>
      <0>Dodaj nov avans.</0>
    `,
    noPatientSelected: 'Na avansu ni dodanega pacienta.',
    unused: 'Neporabljeno:',
    alerts: {
      advanceCreated: 'Avansni račun je bil uspešno ustvarjen.',
      advanceUpdated: 'Avansni račun je bil uspešno posodobljen.',
      advanceVoided: 'Avansni račun je bil uspešno storniran.',
      advanceRemoved: 'Avansni račun je bil uspešno preklican.'
    },
    validation: {
      total: 'Skupna vsota avansa ne sme biti negativna'
    }
  },
  creditNotes: {
    title: 'Dobropisi',
    creditNote: 'Dobropis',
    new: 'Nov dobropis',
    view: 'Dobropis: {{ number }}',
    create: 'Ustvarite dobropis',
    issue: 'Izdajte dobropis',
    save: 'Shranite dobropis',
    savingBill: 'Dobropis se shranuje...',
    issuingBill: 'Dobropis se izdaja...',
    previousNumber: 'Prejšnja številka dobropisa: {{ number }}',
    noPaymentsYet: 'Na dobropis še ni bilo dodanih plačil.',
    emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih dobropisov.</0> <1></1>
      <0>Dodaj nov dobropis.</0>
    `,
    noPatientSelected: 'Na dobropisu ni dodanega pacienta.',
    alerts: {
      creditNoteCreated: 'Dobropis je bil uspešno ustvarjen.',
      creditNoteUpdated: 'Dobropis je bil uspešno posodobljen.',
      creditNoteVoided: 'Dobropis je bil uspešno storniran.',
      creditNoteRemoved: 'Dobropis je bil uspešno preklican.'
    },
    validation: {
      total: 'Skupna vsota dobropisa ne sme biti pozitivna'
    }
  },
  deliveryNotes: { title: 'Dobavnice', deliveryNotes: 'Dobavnica' },
  cashRegister: {
    title: 'Blagajniški dnevniki',
    listTitle: 'Zaključeni blagajniški dnevniki',
    cashRegisterLog: 'Blagajniški dnevnik',
    confirmComplete: {
      title: 'Zaključite blagajniški dnevnik',
      message: 'Želite zaključiti blagajniški dnevnik <0>{{ logId }}</0>?'
    },
    confirmReopen: {
      title: 'Ponovno odprite {{ entityName }}',
      message: 'Želite ponovno odpreti {{ entityName }}: <0>{{ itemName }}</0>?'
    },
    submittingMessage: 'Blagajniški dnevnik se zaključuje...',
    add: 'Dodaj dnevnik',
    new: 'Nov blagajniški dnevnik',
    logNumber: 'Zaporedna št.',
    logId: 'Št. dnevnika',
    createdBy: 'Ustvaril',
    paymentType: 'Vrsta plačila',
    documentType: 'Tip dok.',
    totalPayment: 'Skupaj {{type, lowercase}}',
    totalPayments: 'Vsota plačil',
    totalAll: 'Vsa plačila',
    taxRate: 'Davek',
    taxBase: 'Osnova za DDV',
    taxAmount: 'Znesek DDV',
    grossAmount: 'Vrednost z DDV',
    includedDocuments: 'Vključeni dokumenti',
    includedDocumentsLabel: '{{ count }}  (od {{ first }} do {{ last }})',
    numOfDocuments: 'Št. dokumentov',
    vat: 'DDV',
    emptyListText:
      'Hmmm, izgleda kot da za to podjetje še ni bilo izdanih blagajniških dnevnikov.',
    emptyInvoiceListText:
      'Hmmm, izgleda kot da na ta dan na tej napravi ni bilo izdanih dokumentov.',
    noTaxableItems: 'Vsi predmeti in storitve so brez davka.',
    noRevenue: 'Za izbran dan ni prometa',
    revenue: 'Promet',
    totalRevenue: 'Celoten promet',
    totalRevenueInline: '$t(billing:cashRegister.totalRevenue): ',
    noLogModal: {
      title: 'Ne morete ustvariti novega dokumenta',
      message: `Izbrana naprava <0>{{ deviceLabel }}</0> ima odprt blagajniški dnevnik za pretekli dan.<1></1>
        Preden lahko izdate nov dokument je potrebno zaključiti vse pretekle dnevnike na tej napravi.`,
      createLog: 'Pojdite na blagajniške dnevnike'
    }
  },
  exportBillDocuments: {
    title: '$t(common:export)',
    billingExportWithDate: `Izvoz računov {{ from, ${formatting.shortDate} }} {{ to, ${formatting.shortDate} }}`,
    billingUnitExport: `{{ billingUnitName }} {{ from, ${formatting.shortDate} }} {{ to, ${formatting.shortDate} }} izvoz`
  },
  payments: {
    backToPayments: 'Nazaj na plačila'
  },
  sendEmail: {
    sendViaEmail: 'Pošljite PDF po e-mailu',
    sendTo: 'Za',
    updatePatient: 'Posodobite pacientov e-mail naslov',
    useOnce: 'Ne, uporabi ta naslov le enkrat',
    useEmail: 'Da, od sedaj uporabljaj ta naslov',
    additionalMessage: 'Dodatno sporočilo'
  },
  translateBill: {
    chooseLanguage: 'Izberite jezik',
    createTranslation: 'Ustvarite prevod'
  },
  duplicate: {
    selectType: 'Kaj želite narediti?',
    copyBill: 'Kopirajte {{ billType, lowercase }}'
  },
  exportColumns: {
    [EXPORT_COLUMN_NUMBER]: 'Številka',
    [EXPORT_REFERENCE_NUMBER]: 'Številka reference',
    [EXPORT_COLUMN_PATIENT_NUMBER]: 'Zaporedna številka pacienta',
    [EXPORT_COLUMN_TYPE]: 'Tip',
    [EXPORT_COLUMN_CLIENT_ADDRESS]: 'Naslov stranke',
    [EXPORT_COLUMN_CLIENT_NAME]: 'Ime stranke',
    [EXPORT_COLUMN_CLIENT_TAX_NUMBER]: 'Davčna številka stranke',
    [EXPORT_COLUMN_CLIENT_REGISTRATION_NUMBER]: 'Matična številka',
    [EXPORT_COLUMN_DOCTOR_ID]: 'Id zdravnika',
    [EXPORT_COLUMN_DOCTOR_NAME]: 'Ime zdravnika',
    [EXPORT_COLUMN_CANCELED]: 'Preklicano',
    [EXPORT_COLUMN_TOTAL]: 'Znesek',
    [EXPORT_COLUMN_TOTAL_WITHOUT_TAX]: 'Znesek brez davka',
    [EXPORT_COLUMN_TAX]: 'Davek',
    [EXPORT_COLUMN_PAID]: 'Plačano',
    [EXPORT_COLUMN_TOTAL_PAID]: 'Znesek plačila',
    [EXPORT_COLUMN_PAYMENT_TYPE]: 'Tip plačila',
    [EXPORT_COLUMN_DUE_DATE]: 'Rok plačila',
    [EXPORT_COLUMN_CREATED_AT]: 'Ustvarjeno',
    [EXPORT_COLUMN_DOCUMENT_DATE]: 'Datum dokumenta',
    [EXPORT_COLUMN_ISSUED_AT]: 'Izdano',
    [EXPORT_COLUMN_ITEM_NAME]: 'Ime predmeta',
    [EXPORT_COLUMN_ITEM_CATEGORY_NAME]: 'Kategorija predmeta',
    [EXPORT_COLUMN_ITEM_TOTAL]: 'Znesek predmeta',
    [EXPORT_COLUMN_ITEM_PRICE]: 'Cena predmeta',
    [EXPORT_COLUMN_ITEM_TAX]: 'Davek predmeta',
    [EXPORT_COLUMN_ITEM_QUANTITY]: 'Količina predmetov'
  },
  pluralBillTypes: {
    [BILL_TYPE_INVOICE]: 'Račune',
    [BILL_TYPE_CREDIT_NOTE]: 'Dobropise',
    [BILL_TYPE_ADVANCE]: 'Avanse',
    [BILL_TYPE_ESTIMATE]: 'Predračune'
  }
};
