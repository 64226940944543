import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getJsonSchemaFormData } from '../../../../../utils/form';
import { useActions } from '../../../../../utils/hooks';

import {
  clearQuestionnaireDetail,
  getQuestionnaire
} from '../../../../../redux/questionnaire/questionnaireActions';

import { selectQuestionnaireFromNormalizeMapById } from '../../../../../redux/questionnaire/questionnaireSelectors';

import { CardMoreActionsShape } from '../../../../../metadata/shapes/TableShape';
import { CustomWidgetShape } from '../../../../../metadata/shapes/WidgetShape';

import CustomDescriptionField from '../CustomDescriptionField';
import QuestionnaireWidget from './QuestionnaireWidget';

export const fields = {
  DescriptionField: CustomDescriptionField
};

const QuestionnaireWidgetWithFetch = (props) => {
  const {
    component: Component,
    widget,
    jsonSchemaFormProps,
    handleCancel,
    withoutTitle,
    disabled
  } = props;
  const [prevQuestionnaireId, setPrevQuestionnaireId] = useState(null);
  const [
    getQuestionnaireDispatch,
    clearQuestionnaireDetailDispatch
  ] = useActions([getQuestionnaire, clearQuestionnaireDetail]);

  // ONLY FOR QUESTIONNAIRE
  const widgetType = _.getNonEmpty(widget, 'widgetType');
  const [, questionnaireId, questionnaireVersion] = widgetType.split('/');

  const questionnaire = useSelector((store) =>
    selectQuestionnaireFromNormalizeMapById(store, questionnaireId)
  );
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});

  useEffect(() => {
    if (
      !_.isEmpty(questionnaireId) &&
      questionnaireId !== prevQuestionnaireId
    ) {
      setPrevQuestionnaireId(questionnaireId);
      getQuestionnaireDispatch(questionnaireId, questionnaireVersion);
    }
  }, [
    getQuestionnaireDispatch,
    prevQuestionnaireId,
    questionnaireId,
    questionnaireVersion
  ]);

  useEffect(() => {
    if (!_.isEmpty(questionnaire) && questionnaireId === prevQuestionnaireId) {
      const jsonSchema = getJsonSchemaFormData(
        _.get(questionnaire, 'formBuilderSchema', {})
      );

      setSchema(jsonSchema.schema);
      setUiSchema(jsonSchema.uiSchema);
    }
  }, [prevQuestionnaireId, questionnaire, questionnaireId]);

  const onCancel = () => {
    clearQuestionnaireDetailDispatch();
    handleCancel();
  };

  const safeJsonSchemaFormProps = {
    ...jsonSchemaFormProps,
    schema,
    uiSchema
  };

  return (
    <Component
      {...props}
      jsonSchemaFormProps={safeJsonSchemaFormProps}
      handleCancel={onCancel}
      disabled={disabled}
      withoutTitle={withoutTitle}
    />
  );
};

QuestionnaireWidgetWithFetch.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  widget: PropTypes.shape(CustomWidgetShape).isRequired,
  jsonSchemaFormProps: PropTypes.shape().isRequired,
  widgetActions: PropTypes.arrayOf(PropTypes.shape(CardMoreActionsShape)),
  formRef: PropTypes.shape(),
  disabled: PropTypes.bool,
  withoutTitle: PropTypes.bool,
  component: PropTypes.func
};

QuestionnaireWidgetWithFetch.defaultProps = {
  widgetActions: undefined,
  formRef: undefined,
  disabled: undefined,
  withoutTitle: undefined,
  component: QuestionnaireWidget
};

export default QuestionnaireWidgetWithFetch;
