/* eslint-disable max-len */
export const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/iu;

export const phoneNumberPattern = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{6,14}$/u;

export const numericPattern = /^[0-9]+$/u;

export const numericDashPattern = /^[0-9\d-]+$/u;

export const alphaNumericPattern = /^[a-zA-Z0-9]+$/u;

export const alphaNumericDashPattern = /^[a-zA-Z\d-]*$/u;

export const countryCodePattern = /^[a-zA-Z]{2}/u;

export const noSpacePattern = /^[\S]+$/u;

export const emptyStringPattern = /ˆ$/u;

export const escapeRegexChars = (string) =>
  string.replace(/[.*+?^${}()|[\]\\]/gu, '\\$&');

export const removeSpecialChars = (string) =>
  string.replace(/[^a-zA-Z0-9]/gu, '');

/**
 * Captures intervals like:
 * (-INF, INF), [-INF, INF], (-INF, -100], [200, INF]
 * [100, 200], (30, 70], [-160, 0)
 * Also captures spaces between brackets and comma ( -100 , 200 )
 */
export const intervalGroupsPattern = /(\(|\[)\s*(-?[0-9.]+|-INF)\s*,\s*(-?[0-9.]+|INF)\s*(\)|\])/u;

/**
 * Pattern checks if password is at least 8 characters long,
 * containing at least one upper case, lower case and one digit.
 */
export const passwordPattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/u;

/*
 * Captures:
 * https://www.iryo.io, http://www.iryo.io,
 * www.iryo.io, iryo.io,
 */
export const urlPattern = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+~#?&//=]*)/u;
