import _ from 'lodash';
import moment from 'moment';

import { translateKey } from '../../i18n';
import {
  dayGridPlugin,
  interactionPlugin,
  resourceTimeGridPlugin,
  resourceTimelinePlugin,
  scrollgrid,
  slLocale,
  timeGridPlugin
} from '../fullCalendarConfig';
import moshiConfig from '../moshiConfig';

import { ENTITY_STATUS_DELETED } from '../alert';
import { EVENT_CLICK, EVENT_HOVER } from '../event';

export const DISPLAY_CALENDAR_EVENT_TYPE_BACKGROUND = 'background';

export const EVENT_TYPE_OTHER = 'other';
export const EVENT_TYPE_ENCOUNTER = 'encounter';
export const EVENT_TYPE_QUEUE = 'queue';
export const EVENT_TYPE_REMINDER = 'reminder';

export const EVENT_AUTOMATED_BOOKING = 'automatedBooking';

export const EVENT_STATUS_RESERVATION = 'reserved';
export const EVENT_STATUS_CONFIRMED = 'confirmed';
export const EVENT_STATUS_ARRIVED = 'arrived';
export const EVENT_STATUS_NO_SHOW = 'noShow';
export const EVENT_STATUS_CANCELED = 'canceled';
export const EVENT_STATUS_SCHEDULED = 'scheduled';
export const EVENT_STATUS_DELETED = ENTITY_STATUS_DELETED;

export const EVENT_PRIORITY_COLOR_RED = '#cf3a1b';
export const EVENT_PRIORITY_COLOR_YELLOW = '#f7c403';
export const EVENT_PRIORITY_COLOR_GREEN = '#4eac88';

export const EVENT_RESCHEDULE_PATIENT_REQUESTED = 'patient';
export const EVENT_RESCHEDULE_DOCTOR_REQUESTED = 'user';
export const EVENT_RESCHEDULE_OTHER = 'other';

export const EVENT_COLOR_SOURCE_ASSIGNEE = 'assignee';
export const EVENT_COLOR_SOURCE_CATEGORY = 'category';

export const CALENDAR_AUDIT_LOG = 'auditLog';
export const CALENDAR_AUDIT_LOG_RESCHEDULED_ACTION = 'rescheduled';
export const CALENDAR_AUDIT_LOG_CREATED_ACTION = 'created';
export const CALENDAR_AUDIT_LOG_UPDATED_ACTION = 'updated';
export const CALENDAR_AUDIT_LOG_CANCELED_ACTION = 'canceled';
export const CALENDAR_AUDIT_LOG_CONFIRMED_ACTION = 'confirmed';
export const CALENDAR_AUDIT_LOG_REQUEST_BY_PATIENT = 'patient';
export const CALENDAR_AUDIT_LOG_REQUEST_BY_USER = 'user';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_FROM = 'from';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_TO = 'to';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_DOCTOR_IDS = 'doctorIDs';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_CATEGORY_ID = 'categoryID';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_NOTE = 'note';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_STATUS = 'status';
export const CALENDAR_AUDIT_LOG_DIFF_FIELD_PATIENT_ID = 'patientID';
export const CALENDAR_AUDIT_LOG_DIFF_EVENT_TYPE = 'type';

export const CALENDAR_CARD_ACTION_RESCHEDULE = 'reschedule';
export const CALENDAR_CARD_ACTION_VIEW_IN_CALENDAR = 'viewInCalendar';
export const CALENDAR_CARD_ACTION_DUPLICATE = 'duplicate';
export const CALENDAR_CARD_ACTION_MOVE_TO_WAITING_LIST = 'moveToWaitingList';
export const CALENDAR_CARD_ACTION_ADD_TO_CALENDAR = 'addToCalendar';
export const CALENDAR_CARD_ACTION_REMOVE = 'remove';

export const eventDropdownStatuses = [
  {
    id: EVENT_STATUS_RESERVATION,
    name: translateKey('calendar:status.reservation')
  },
  {
    id: EVENT_STATUS_SCHEDULED,
    name: translateKey('calendar:status.scheduled')
  },
  {
    id: EVENT_STATUS_CONFIRMED,
    name: translateKey('calendar:status.confirmed')
  },
  { id: EVENT_STATUS_ARRIVED, name: translateKey('calendar:status.arrived') },
  { id: EVENT_STATUS_NO_SHOW, name: translateKey('calendar:status.noShow') }
];

export const calendarEventStatusesList = [
  {
    id: EVENT_STATUS_RESERVATION,
    name: translateKey('calendar:status.reservation')
  },
  {
    id: EVENT_STATUS_SCHEDULED,
    name: translateKey('calendar:status.scheduled')
  },
  {
    id: EVENT_STATUS_CONFIRMED,
    name: translateKey('calendar:status.confirmed')
  },
  { id: EVENT_STATUS_ARRIVED, name: translateKey('calendar:status.arrived') },
  { id: EVENT_STATUS_NO_SHOW, name: translateKey('calendar:status.noShow') },
  {
    id: EVENT_STATUS_CANCELED,
    name: translateKey('calendar:status.canceled')
  },
  {
    id: EVENT_AUTOMATED_BOOKING,
    name: translateKey('booking:title')
  }
];

export const calendarEventStatusesListIds = _.map(
  calendarEventStatusesList,
  'id'
);

export const calendarEventQueuePriorities = [
  {
    id: '1',
    name: translateKey('calendar:priority.1'),
    color: EVENT_PRIORITY_COLOR_RED
  },
  {
    id: '2',
    name: translateKey('calendar:priority.2'),
    color: EVENT_PRIORITY_COLOR_YELLOW
  },
  {
    id: '3',
    name: translateKey('calendar:priority.3'),
    color: EVENT_PRIORITY_COLOR_GREEN
  }
];

export const CALENDAR_WEEK_VIEW = 'timeGridWeek';
export const CALENDAR_DAY_VIEW = 'resourceTimeGridDay';
export const RESOURCE_TIMELINE_WEEK_VIEW = 'resourceTimelineThreeDaysWeek';
export const RESOURCE_TIMELINE_DAY_VIEW = 'resourceTimelineDay';

export const PREFILL_EVENT_TYPE_RESCHEDULE = 'reschedule';
export const PREFILL_EVENT_TYPE_DASHBOARD_PREFILL = 'dashboard-prefill';
export const PREFILL_EVENT_TYPE_PATIENT_PREFILL = 'patient-prefill';
export const PREFILL_EVENT_TYPE_DUPLICATE = 'duplicate';

export const CALENDAR_SEARCH_RESULTS_LIMIT = 10;
export const CALENDAR_EVENT_DEFAULT_DURATION = '30';
export const CALENDAR_EVENT_NO_DURATION = '0';

export const ASSIGNEE_TYPE_USER = 'user';
export const ASSIGNEE_TYPE_RESOURCE = 'resource';

export const weekDays = [
  {
    id: 1,
    abbr: moment().isoWeekday(1).format('dd'),
    name: _.capitalize(moment().isoWeekday(1).format('dddd'))
  },
  {
    id: 2,
    abbr: moment().isoWeekday(2).format('dd'),
    name: _.capitalize(moment().isoWeekday(2).format('dddd'))
  },
  {
    id: 3,
    abbr: moment().isoWeekday(3).format('dd'),
    name: _.capitalize(moment().isoWeekday(3).format('dddd'))
  },
  {
    id: 4,
    abbr: moment().isoWeekday(4).format('dd'),
    name: _.capitalize(moment().isoWeekday(4).format('dddd'))
  },
  {
    id: 5,
    abbr: moment().isoWeekday(5).format('dd'),
    name: _.capitalize(moment().isoWeekday(5).format('dddd'))
  },
  {
    id: 6,
    abbr: moment().isoWeekday(6).format('dd'),
    name: _.capitalize(moment().isoWeekday(6).format('dddd'))
  },
  {
    id: 0,
    abbr: moment().isoWeekday(7).format('dd'),
    name: _.capitalize(moment().isoWeekday(7).format('dddd'))
  }
];

export const calendarSlotDurations = [
  {
    id: 5,
    name: translateKey('calendar:zoom.large')
  },
  {
    id: 10,
    name: translateKey('calendar:zoom.medium')
  },
  {
    id: 15,
    name: translateKey('calendar:zoom.small')
  },
  {
    id: 30,
    name: translateKey('calendar:zoom.extraSmall')
  }
];

export const defaultCalendarViews = [
  {
    id: CALENDAR_WEEK_VIEW,
    name: translateKey('calendar:settings.viewSettings.weekView')
  },
  {
    id: RESOURCE_TIMELINE_WEEK_VIEW,
    name: translateKey('calendar:settings.viewSettings.timelineView')
  }
];

export const popupTriggerRadioOptions = [
  {
    value: EVENT_CLICK,
    label: translateKey('calendar:settings.viewSettings.onClick')
  },
  {
    value: EVENT_HOVER,
    label: translateKey('calendar:settings.viewSettings.onHover')
  }
];

export const EVENT_COLOR_TYPE_FILL = 'fill';
export const EVENT_COLOR_TYPE_OUTLINE = 'outline';

export const defaultEventTypeColoring = {
  encounter: EVENT_COLOR_TYPE_FILL,
  other: EVENT_COLOR_TYPE_OUTLINE,
  reminder: EVENT_COLOR_TYPE_OUTLINE,
  telemedicine: EVENT_COLOR_TYPE_OUTLINE
};

export const calendarColorTypeOptions = [
  {
    id: EVENT_COLOR_TYPE_FILL,
    name: translateKey(
      'calendar:settings.viewSettings.eventTypeColoring.filled'
    )
  },
  {
    id: EVENT_COLOR_TYPE_OUTLINE,
    name: translateKey(
      'calendar:settings.viewSettings.eventTypeColoring.outlined'
    )
  }
];

export const FULL_CALENDAR_DEFAULT_PROPS = {
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
    resourceTimeGridPlugin,
    resourceTimelinePlugin,
    scrollgrid
  ],
  schedulerLicenseKey: moshiConfig.fullCalendarLicenseKey,
  resourceAreaHeaderClassNames: 'moshi-resource-timeline-header',
  resourceOrder: 'title',
  resourceGroupField: 'groupId',
  handleWindowResize: true,
  allDaySlot: false,
  headerToolbar: false,
  scrollTimeReset: false,
  navLinks: true,
  nowIndicator: true,
  weekends: true,
  stickyHeaderDates: true,
  stickyFooterScrollbar: true,
  contentHeight: 'auto',
  editable: true,
  droppable: true,
  selectable: true,
  selectMirror: true,
  nowIndicatorClassNames: 'moshi-now-indicator',
  defaultTimedEventDuration: '00:05:00',
  resourceAreaWidth: 200,
  rerenderDelay: 400,
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false
  },
  views: {
    [RESOURCE_TIMELINE_WEEK_VIEW]: {
      type: 'resourceTimeline',
      duration: { days: 3 }
    }
  },
  locales: ['en', slLocale]
};

export const WORKING_HOURS_FULL_CALENDAR_DEFAULT_PROPS = {
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
    resourceTimeGridPlugin,
    resourceTimelinePlugin,
    scrollgrid
  ],
  schedulerLicenseKey: moshiConfig.fullCalendarLicenseKey,
  initialView: 'resourceTimeline',
  duration: { weeks: 1 },
  slotDuration: { days: 1 },
  eventShortHeight: 100,
  resourceOrder: 'title',
  resourceGroupField: 'groupId',
  eventClassNames: 'moshi-wh-calendar-event tw-font-medium',
  resourceAreaWidth: 200,
  headerToolbar: false,
  eventMinHeight: 100,
  resourceAreaHeaderClassNames: 'tw-hidden',
  handleWindowResize: true,
  contentHeight: 'auto',
  selectable: true,
  titleFormat: {
    month: 'short',
    day: 'numeric',
    year: '2-digit'
  }
};
