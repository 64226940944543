import _ from 'lodash';

import { showAlertSuccess } from '../../../utils/alert';
import {
  getPatientFileListUrl,
  getPatientFileUrl
} from '../../../utils/api/apiUrlUtils';
import { REQUEST_PAGINATION_MAX_LIMIT } from '../../../utils/constants/networkConstants';
import {
  PATIENT_ANAMNESIS_ALLERGY_TYPE,
  PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE,
  PATIENT_ANAMNESIS_IMPLANT_TYPE,
  PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE,
  PATIENT_ANAMNESIS_OPERATION_TYPE,
  PATIENT_ANAMNESIS_OTHER_TYPE,
  PATIENT_ANAMNESIS_VACCINATION_TYPE
} from '../../../utils/constants/patientConstants';
import { createJsonBlobFormData } from '../../../utils/form';
import {
  getAnamnesisType,
  PATIENT_ANAMNESIS_ALL_TYPES
} from '../../../utils/patient/anamnesisUtils';

import { uploadFile } from '../../core/file/fileActions';
import { getPatientWidgetFiles, removePatientFile } from '../patientActions';

import {
  ADD_PATIENT_ANAMNESIS,
  CLEAR_PATIENT_ANAMNESIS_LIST,
  CLEAR_PATIENT_ANAMNESIS_LIST_FILTER,
  CLEAR_UPDATED_PATIENT_ANAMNESIS_ID,
  GET_PATIENT_ANAMNESIS_LIST,
  GET_PINNED_PATIENT_ANAMNESIS_LIST,
  REMOVE_PATIENT_ANAMNESIS,
  SET_PATIENT_ANAMNESIS_LIST_FILTER,
  UPDATE_PATIENT_ANAMNESIS
} from './patientAnamnesisTypes';

export const clearPatientAnamnesisListFilter = () => (dispatch) =>
  dispatch({
    type: CLEAR_PATIENT_ANAMNESIS_LIST_FILTER
  });

export const setPatientAnamnesisListFilter = (filterName, filterValue) => (
  dispatch
) => {
  dispatch({
    type: SET_PATIENT_ANAMNESIS_LIST_FILTER,
    filterName,
    filterValue
  });
};

const getPatientAnamnesisListBase = (
  patientId,
  widgetTypeList = PATIENT_ANAMNESIS_ALL_TYPES,
  config = {},
  actionType = GET_PATIENT_ANAMNESIS_LIST
) => (dispatch) => {
  _.set(config, 'params', {
    includeContent: true,
    widgetIDs: widgetTypeList.join(','),
    limit: REQUEST_PAGINATION_MAX_LIMIT
  });

  return dispatch(getPatientWidgetFiles(patientId, config, actionType));
};

export const getPatientAnamnesisList = (patientId) => (dispatch) =>
  dispatch(getPatientAnamnesisListBase(patientId));

export const getPinnedPatientAnamnesisList = (patientId) => (dispatch) => {
  const config = {
    headers: {
      'X-Meta-Filter': JSON.stringify({ pinned: 'true' })
    }
  };

  return dispatch(
    getPatientAnamnesisListBase(
      patientId,
      undefined,
      config,
      GET_PINNED_PATIENT_ANAMNESIS_LIST
    )
  );
};

export const clearPatientAnamnesisList = () => (dispatch) =>
  dispatch({ type: CLEAR_PATIENT_ANAMNESIS_LIST });

export const clearUpdatedPatientAnamnesisId = () => (dispatch) =>
  dispatch({ type: CLEAR_UPDATED_PATIENT_ANAMNESIS_ID });

const createPatientAnamnesisEntry = (patientId, type, data) => (
  dispatch,
  getState
) => {
  const url = getPatientFileListUrl(getState, patientId);
  const widgetType = getAnamnesisType(type);

  if (_.isEmptySafe(data)) {
    return Promise.reject(new Error('Empty data'));
  }

  if (_.isEmptySafe(url)) {
    return Promise.reject(new Error('Malformed url'));
  }

  if (_.isEmptySafe(widgetType)) {
    return Promise.reject(new Error('Malformed type'));
  }

  const onSuccess = () => {
    dispatch(getPatientAnamnesisList(patientId));
    showAlertSuccess('patient:anamnesis.alert.created');
  };

  const file = createJsonBlobFormData(data);

  return dispatch(
    uploadFile(
      url,
      file,
      {
        meta: {
          widgetType,
          pinned: _.getNonEmpty(data, 'pinned', false).toString()
        }
      },
      { onSuccess, actionType: ADD_PATIENT_ANAMNESIS }
    )
  );
};

const updatePatientAnamnesisEntry = (fileId, patientId, type, data) => (
  dispatch,
  getState
) => {
  const url = getPatientFileUrl(getState, patientId, fileId);
  const widgetType = getAnamnesisType(type);

  if (_.isEmptySafe(data)) {
    return Promise.reject(new Error('Empty data'));
  }

  if (_.isEmptySafe(url)) {
    return Promise.reject(new Error('Malformed url'));
  }

  if (_.isEmptySafe(widgetType)) {
    return Promise.reject(new Error('Malformed type'));
  }

  const onSuccess = () => {
    dispatch(getPatientAnamnesisList(patientId));
    showAlertSuccess('patient:anamnesis.alert.updated');
  };

  const file = createJsonBlobFormData(data);

  return dispatch(
    uploadFile(
      url,
      file,
      {
        meta: {
          widgetType,
          pinned: _.getNonEmpty(data, 'pinned', false).toString()
        }
      },
      { onSuccess, actionType: UPDATE_PATIENT_ANAMNESIS, method: 'put' }
    )
  );
};

export const removePatientAnamnesis = (
  anamnesisId,
  patientId,
  onSuccess = undefined
) => (dispatch) => {
  let safeOnSuccess = onSuccess;

  if (_.isEmptySafe(safeOnSuccess)) {
    safeOnSuccess = () => {
      dispatch(getPatientAnamnesisList(patientId));
      showAlertSuccess('patient:anamnesis.alert.removed');
    };
  }

  return dispatch(
    removePatientFile(
      anamnesisId,
      patientId,
      safeOnSuccess,
      REMOVE_PATIENT_ANAMNESIS
    )
  );
};

export const createPatientMedicalCondition = (patientId, medicalCondition) => (
  dispatch
) =>
  dispatch(
    createPatientAnamnesisEntry(
      patientId,
      PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE,
      medicalCondition
    )
  );

export const updatePatientMedicalCondition = (
  medicalConditionId,
  patientId,
  medicalCondition
) => (dispatch) =>
  dispatch(
    updatePatientAnamnesisEntry(
      medicalConditionId,
      patientId,
      PATIENT_ANAMNESIS_MEDICAL_CONDITION_TYPE,
      medicalCondition
    )
  );

export const createOrUpdatePatientMedicalCondition = (
  patientId,
  medicalCondition
) => (dispatch) => {
  const medicalConditionId = _.getNonEmpty(medicalCondition, 'id', null);

  if (_.isEmptySafe(medicalConditionId)) {
    return dispatch(createPatientMedicalCondition(patientId, medicalCondition));
  } else {
    return dispatch(
      updatePatientMedicalCondition(
        medicalConditionId,
        patientId,
        medicalCondition
      )
    );
  }
};

export const createPatientConcomitantDrug = (patientId, concomitantDrug) => (
  dispatch
) =>
  dispatch(
    createPatientAnamnesisEntry(
      patientId,
      PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE,
      concomitantDrug
    )
  );

export const updatePatientConcomitantDrug = (
  concomitantDrugId,
  patientId,
  concomitantDrug
) => (dispatch) =>
  dispatch(
    updatePatientAnamnesisEntry(
      concomitantDrugId,
      patientId,
      PATIENT_ANAMNESIS_CONCOMITANT_DRUG_TYPE,
      concomitantDrug
    )
  );

export const createOrUpdatePatientConcomitantDrug = (
  patientId,
  concomitantDrug
) => (dispatch) => {
  const concomitantDrugId = _.getNonEmpty(concomitantDrug, 'id', null);

  if (_.isEmptySafe(concomitantDrugId)) {
    return dispatch(createPatientConcomitantDrug(patientId, concomitantDrug));
  } else {
    return dispatch(
      updatePatientConcomitantDrug(
        concomitantDrugId,
        patientId,
        concomitantDrug
      )
    );
  }
};

export const createPatientAllergy = (patientId, allergy) => (dispatch) =>
  dispatch(
    createPatientAnamnesisEntry(
      patientId,
      PATIENT_ANAMNESIS_ALLERGY_TYPE,
      allergy
    )
  );

export const updatePatientAllergy = (allergyId, patientId, allergy) => (
  dispatch
) =>
  dispatch(
    updatePatientAnamnesisEntry(
      allergyId,
      patientId,
      PATIENT_ANAMNESIS_ALLERGY_TYPE,
      allergy
    )
  );

export const createOrUpdatePatientAllergy = (patientId, allergy) => (
  dispatch
) => {
  const allergyId = _.getNonEmpty(allergy, 'id', null);

  if (_.isEmptySafe(allergyId)) {
    return dispatch(createPatientAllergy(patientId, allergy));
  } else {
    return dispatch(updatePatientAllergy(allergyId, patientId, allergy));
  }
};

export const createPatientVaccination = (patientId, vaccination) => (
  dispatch
) =>
  dispatch(
    createPatientAnamnesisEntry(
      patientId,
      PATIENT_ANAMNESIS_VACCINATION_TYPE,
      vaccination
    )
  );

export const createPatientOperation = (patientId, operation) => (dispatch) =>
  dispatch(
    createPatientAnamnesisEntry(
      patientId,
      PATIENT_ANAMNESIS_OPERATION_TYPE,
      operation
    )
  );

export const updatePatientVaccination = (
  vaccinationId,
  patientId,
  vaccination
) => (dispatch) =>
  dispatch(
    updatePatientAnamnesisEntry(
      vaccinationId,
      patientId,
      PATIENT_ANAMNESIS_VACCINATION_TYPE,
      vaccination
    )
  );

export const updatePatientOperation = (operationId, patientId, operation) => (
  dispatch
) =>
  dispatch(
    updatePatientAnamnesisEntry(
      operationId,
      patientId,
      PATIENT_ANAMNESIS_OPERATION_TYPE,
      operation
    )
  );

export const createOrUpdatePatientVaccination = (patientId, vaccination) => (
  dispatch
) => {
  const vaccinationId = _.getNonEmpty(vaccination, 'id', null);

  if (_.isEmptySafe(vaccinationId)) {
    return dispatch(createPatientVaccination(patientId, vaccination));
  } else {
    return dispatch(
      updatePatientVaccination(vaccinationId, patientId, vaccination)
    );
  }
};

export const createOrUpdatePatientOperation = (patientId, operation) => (
  dispatch
) => {
  const operationId = _.getNonEmpty(operation, 'id', null);

  if (_.isEmptySafe(operationId)) {
    return dispatch(createPatientOperation(patientId, operation));
  } else {
    return dispatch(updatePatientOperation(operationId, patientId, operation));
  }
};

export const createPatientImplant = (patientId, implant) => (dispatch) =>
  dispatch(
    createPatientAnamnesisEntry(
      patientId,
      PATIENT_ANAMNESIS_IMPLANT_TYPE,
      implant
    )
  );

export const updatePatientImplant = (implantId, patientId, implant) => (
  dispatch
) =>
  dispatch(
    updatePatientAnamnesisEntry(
      implantId,
      patientId,
      PATIENT_ANAMNESIS_IMPLANT_TYPE,
      implant
    )
  );

export const createOrUpdatePatientImplant = (patientId, implant) => (
  dispatch
) => {
  const implantId = _.getNonEmpty(implant, 'id', null);

  if (_.isEmptySafe(implantId)) {
    return dispatch(createPatientImplant(patientId, implant));
  } else {
    return dispatch(updatePatientImplant(implantId, patientId, implant));
  }
};

export const createPatientOtherAnamnesis = (patientId, other) => (dispatch) =>
  dispatch(
    createPatientAnamnesisEntry(patientId, PATIENT_ANAMNESIS_OTHER_TYPE, other)
  );

export const updatePatientOtherAnamnesis = (otherId, patientId, other) => (
  dispatch
) =>
  dispatch(
    updatePatientAnamnesisEntry(
      otherId,
      patientId,
      PATIENT_ANAMNESIS_OTHER_TYPE,
      other
    )
  );

export const createOrUpdatePatientOtherAnamnesis = (patientId, other) => (
  dispatch
) => {
  const otherId = _.getNonEmpty(other, 'id', null);

  if (_.isEmptySafe(otherId)) {
    return dispatch(createPatientOtherAnamnesis(patientId, other));
  } else {
    return dispatch(updatePatientOtherAnamnesis(otherId, patientId, other));
  }
};
