export default {
  id: 'administrativeData/REASON_FOR_ENCOUNTER/v1',
  formSchema: {
    title: 'encounter:widget.administrativeData.reasonForEncounter.title',
    type: 'object',
    required: ['id001'],
    properties: {
      id001: {
        enum: [
          'id002',
          'id003',
          'id004',
          'id005',
          'id006',
          'id007',
          'id008',
          'id009',
          'id0010',
          'id0011',
          'id0012',
          'id0013',
          'id0014',
          'id0015',
          'id0016'
        ],
        enumNames: [
          'encounter:widget.administrativeData.reasonForEncounter.acuteProblem',
          'encounter:widget.administrativeData.reasonForEncounter.acuteProblemFollowUp',
          'encounter:widget.administrativeData.reasonForEncounter.chronicProblemRoutine',
          'encounter:widget.administrativeData.reasonForEncounter.chronicProblemFollowUp',
          'encounter:widget.administrativeData.reasonForEncounter.prenatalCare',
          'encounter:widget.administrativeData.reasonForEncounter.postnatalCare',
          'encounter:widget.administrativeData.reasonForEncounter.postoperativeCare',
          'encounter:widget.administrativeData.reasonForEncounter.wellAdultExam',
          'encounter:widget.administrativeData.reasonForEncounter.wellChildExam',
          'encounter:widget.administrativeData.reasonForEncounter.familyPlanning',
          'encounter:widget.administrativeData.reasonForEncounter.counselingAdvice',
          'encounter:widget.administrativeData.reasonForEncounter.Immunization',
          'encounter:widget.administrativeData.reasonForEncounter.referredBy',
          'encounter:widget.administrativeData.reasonForEncounter.administrativePurpose',
          'encounter:widget.administrativeData.reasonForEncounter.other'
        ],
        type: 'string',
        title: 'encounter:widget.administrativeData.reasonForEncounter.title'
      }
    }
  },
  uiSchema: {
    id001: { 'ui:widget': 'customDropdownWidget' },
    'x-customClass': 'admin-data-widget',
    'x-cancelBtnSize': 'sm',
    'x-saveBtnSize': 'sm'
  }
};
