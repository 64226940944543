import produce from 'immer';
import _ from 'lodash';

import { mapFormDetail } from '../../utils/mappers/document-mappers';

import {
  CLEAR_DOCUMENT_DETAIL,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_IN_PROGRESS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_IN_PROGRESS,
  GET_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_IN_PROGRESS,
  UPDATE_DOCUMENT_SUCCESS
} from './documentTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  loadingList: false,
  loadingDetail: false,
  list: [],
  detail: null
};

// eslint-disable-next-line default-param-last
const documentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_DOCUMENTS_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_DOCUMENTS_SUCCESS:
        const documents = _.getNonEmpty(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = _.sortBy(documents, 'name');
        break;
      case GET_DOCUMENTS_FAILURE:
        draft.loadingList = false;
        break;
      case LOGOUT:
        return initialState;
      case UPDATE_DOCUMENT_IN_PROGRESS:
      case GET_DOCUMENT_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case UPDATE_DOCUMENT_FAILURE:
      case GET_DOCUMENT_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case CLEAR_DOCUMENT_DETAIL:
        draft.detail = null;
        break;
      case UPDATE_DOCUMENT_SUCCESS:
      case GET_DOCUMENT_SUCCESS:
        draft.loadingDetail = false;
        draft.detail = mapFormDetail(
          _.getNonEmpty(payload, 'response.data', null)
        );
        break;
      default:
    }
  });

export default documentReducer;
