import _ from 'lodash';
import { createSelector } from 'reselect';

import { selectProps } from '../../../utils/redux/selectorHelpers';

import { selectDeletedCategoryList } from '../../core/archive/archiveSelectors';
import { selectCacheCategoriesList } from '../../core/cache/cacheSelectors';

export const selectCategoryStore = (state) => state.category;

export const selectCategoryList = createSelector(
  [selectCategoryStore],
  (category) => _.get(category, 'list', [])
);

export const selectCategoriesById = createSelector(
  [selectCacheCategoriesList, selectProps],
  (categories, categoryId) => _.find(categories, { id: categoryId })
);

export const selectIsCategoryDeleted = createSelector(
  [selectDeletedCategoryList, selectProps],
  (deletedCategories, categoryId) =>
    !_.isEmptySafe(_.find(deletedCategories, { id: categoryId }))
);

export const selectCategoryNameById = createSelector(
  [selectCacheCategoriesList, selectProps],
  (categories, categoryId) => {
    const category = _.find(categories, { id: categoryId });

    return _.getNonEmpty(category, 'name', '/');
  }
);

export const selectUppercaseCategoryList = createSelector(
  [selectCategoryList],
  (categories) =>
    categories.map((category) => {
      category.name = _.sentenceCase(category.name);

      return category;
    })
);
