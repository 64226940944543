import React from 'react';

import PropTypes from 'prop-types';

import RightHandHandInteractive from './RightHandInteractive';
import RightHandHandOutline from './RightHandOutline';

const RightHand = ({
  outlineColor,
  disabledColor,
  getColor,
  onChange,
  initialSelectedParts,
  disabled,
  tipsDisabled,
  lowDisabled,
  wristDisabled
}) => (
  <g id={'zoomed-right-hand'}>
    <RightHandHandOutline
      outlineColor={outlineColor}
      disabledColor={disabledColor}
      disabled={disabled}
    />
    {!disabled && (
      <RightHandHandInteractive
        getColor={getColor}
        onChange={onChange}
        initialSelectedParts={initialSelectedParts}
        tipsDisabled={tipsDisabled}
        lowDisabled={lowDisabled}
        wristDisabled={wristDisabled}
      />
    )}
  </g>
);

RightHand.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  tipsDisabled: PropTypes.bool,
  lowDisabled: PropTypes.bool,
  wristDisabled: PropTypes.bool
};

RightHand.defaultProps = {
  onChange: undefined,
  initialSelectedParts: undefined,
  disabled: false,
  tipsDisabled: undefined,
  lowDisabled: undefined,
  wristDisabled: undefined
};

export default RightHand;
