import { useEffect, useRef } from 'react';

import _ from 'lodash';

import { registerEvents } from '../event';

/**
 * useDomEvent - A hook which helps you register events on a component
 *
 * @param {String[]|String} events - List of events or a single event
 * @param {Function} callback - Callback which will be called once the event fires
 * @param {String} elementAccessor - Property string path to get DOM Element - Default: 'current'
 *
 * @example
 *  const elementRef = useDomEvent(EVENT_CLICK, (e) => console.log('Fired event: ', e));
 *  // OR
 *  const elementRef = useDomEvent([EVENT_CLICK, EVENT_MOUSE_ENTER], (e) => console.log('Fired event: ', e))
 *
 *  return (
 *    <div ref={elementRef} />
 *  );
 *
 * @returns {React.MutableRefObject<any> | React.RefObject<any>}
 */
export const useDomEvent = (events, callback, elementAccessor = 'current') => {
  const componentRef = useRef();

  useEffect(
    () => {
      // We try to get the DOM Element from the ref
      const domElement = _.get(componentRef, elementAccessor, null);

      if (!_.isEmpty(domElement)) {
        // If element was found we register the events
        registerEvents(domElement, events, callback);
      }

      return () => {
        // When component unmounts we need to unregister the events
        if (!_.isEmpty(domElement)) {
          // If element was found we unregister the events by passing true as the 4th parameter
          registerEvents(domElement, events, callback, true);
        }
      };
    },
    // We only care about the component reference - none of the other props should change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [componentRef]
  );

  // We need to return the ref so that it can be passed to targeted component
  return componentRef;
};
