import { createActionTypeArray } from '../../common/actionTypes';

export const GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST =
  'encounter/GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST';

export const CLEAR_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST =
  'encounter/CLEAR_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST';

export const GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST =
  'encounter/GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST';

export const [
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_IN_PROGRESS,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_SUCCESS,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST);

export const [
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST_IN_PROGRESS,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST_SUCCESS,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST);
