import produce from 'immer';
import _ from 'lodash';

import { ENTITY_STATUS_DELETED } from '../../../utils/alert';

import {
  GET_DELETED_USER_SUCCESS,
  GET_DELETED_LOCATION_SUCCESS,
  GET_DELETED_FLOW_SUCCESS,
  GET_DELETED_DRUG_SUCCESS,
  GET_DELETED_CATEGORY_SUCCESS,
  GET_DELETED_QUESTIONNAIRE_SUCCESS,
  GET_DELETED_PAYMENT_TYPE_SUCCESS,
  GET_DELETED_TAX_SUCCESS
} from './archiveTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  user: {
    list: []
  },
  location: {
    list: []
  },
  flow: {
    list: []
  },
  drug: {
    list: []
  },
  category: {
    list: []
  },
  questionnaire: {
    list: []
  },
  paymentType: {
    list: []
  },
  tax: {
    list: []
  }
};

const getEntity = (payload, stateObject, checkIfDeleted = true) => {
  const entity = _.get(payload, 'response.data', {});
  const entityPresent = _.findDefault(
    stateObject.list,
    { id: entity.id },
    null
  );

  if (_.isEmptySafe(entityPresent)) {
    if (checkIfDeleted) {
      return entity.status === ENTITY_STATUS_DELETED ? entity : null;
    } else {
      return entity;
    }
  }

  return null;
};

// eslint-disable-next-line default-param-last
const archiveReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_DELETED_USER_SUCCESS:
        const user = getEntity(payload, draft.user);

        if (!_.isEmptySafe(user)) {
          draft.user.list.push(user);
        }
        break;
      case GET_DELETED_LOCATION_SUCCESS:
        const location = getEntity(payload, draft.location);

        if (!_.isEmptySafe(location)) {
          draft.location.list.push(location);
        }
        break;
      case GET_DELETED_FLOW_SUCCESS:
        const flow = getEntity(payload, draft.flow);

        if (!_.isEmptySafe(flow)) {
          draft.flow.list.push(flow);
        }
        break;
      case GET_DELETED_DRUG_SUCCESS:
        const drug = getEntity(payload, draft.drug);

        if (!_.isEmptySafe(drug)) {
          draft.drug.list.push(drug);
        }
        break;
      case GET_DELETED_CATEGORY_SUCCESS:
        const category = getEntity(payload, draft.category);

        if (!_.isEmptySafe(category)) {
          draft.category.list.push(category);
        }

        break;
      case GET_DELETED_QUESTIONNAIRE_SUCCESS:
        const questionnaire = getEntity(payload, draft.questionnaire, false);

        if (!_.isEmptySafe(questionnaire)) {
          questionnaire.outdated = true;
          draft.questionnaire.list.push(questionnaire);
        }

        break;
      case GET_DELETED_PAYMENT_TYPE_SUCCESS:
        const paymentType = getEntity(payload, draft.paymentType);

        if (!_.isEmptySafe(paymentType)) {
          draft.paymentType.list.push(paymentType);
        }

        break;
      case GET_DELETED_TAX_SUCCESS:
        const tax = getEntity(payload, draft.tax);

        if (!_.isEmptySafe(tax)) {
          draft.tax.list.push(tax);
        }

        break;
      case LOGOUT:
        return initialState;
      default:
        return state;
    }
  });

export default archiveReducer;
