import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import EditModeCustomGroupSingleField from './EditModeCustomGroupSingleField';
import ReadOnlyCustomGroupSingleField from './ReadOnlyCustomGroupSingleField';

const CustomWidgetGroupSingleField = ({
  schema,
  uiSchema,
  properties,
  disabled
}) =>
  properties.map((property) => {
    const name = _.getNonEmpty(property, 'name');
    const measurement = _.getNonEmpty(
      schema,
      `properties.${name}.measurement`,
      null
    );
    const className = _.getNonEmpty(uiSchema, `${name}.classNames`, undefined);
    const styles = _.getNonEmpty(uiSchema, `${name}.x-styles`, undefined);
    const label = _.getNonEmpty(schema, `properties.${name}.label`, undefined);

    let SingleFieldComponent = EditModeCustomGroupSingleField;

    if (disabled) {
      SingleFieldComponent = ReadOnlyCustomGroupSingleField;
    }

    return (
      <SingleFieldComponent
        key={property.content.key}
        measurement={measurement}
        className={className}
        styles={styles}
        label={label}
      >
        {property.content}
      </SingleFieldComponent>
    );
  });

CustomWidgetGroupSingleField.propTypes = {
  schema: PropTypes.shape({}).isRequired,
  uiSchema: PropTypes.shape({}).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool.isRequired
};

export default CustomWidgetGroupSingleField;
