import { takeEvery, put } from 'redux-saga/effects';

import { processWidgetData } from './indexCalculationWidgetSagas';
import { handleRefreshData } from './widgetDependencyDataSagas';

import {
  SET_WIDGET_FORM_DATA_TRIGGER,
  SET_WIDGET_FORM_DATA
} from '../widgetTypes';

export function* saveWidgetLocalData(action) {
  const { widget, formData } = action;

  yield handleRefreshData(widget.widgetType);
  const processedFormData = yield processWidgetData(
    widget.widgetType,
    formData
  );

  yield put({
    type: SET_WIDGET_FORM_DATA,
    widget,
    formData: processedFormData
  });
}

export const widgetLocalDataSagas = [
  takeEvery(SET_WIDGET_FORM_DATA_TRIGGER, saveWidgetLocalData)
];
