import { useCallback } from 'react';

import { FILTER_ALL_ID } from '../constants/dropdownConstants';

export const useUpdateFilter = (updateFilterDispatch) =>
  useCallback(
    (filterName, filterValue) => {
      const finalFilterValue =
        filterValue === FILTER_ALL_ID ? undefined : filterValue;

      updateFilterDispatch(filterName, finalFilterValue);
    },
    [updateFilterDispatch]
  );
