// BASE PATHS
const dashboardBasePath = '/dashboard';
const settingsBasePath = '/settings';
const patientBasePath = '/patients';
const resetPasswordBasePath = '/reset-password';
const eventLogBasePath = '/event-log';

const NEW_ID = 'new';

export const baseRoutes = {
  root: '/',
  dashboard: dashboardBasePath,
  settings: settingsBasePath,
  patients: patientBasePath,
  therapies: '/therapies',
  analytics: '/analytics',
  employees: '/employees',
  user: '/user',
  billing: '/billing',
  documents: '/documents',
  calendar: '/calendar/:date?',
  download: '/download',
  communication: '/communication',
  login: '/login',
  logout: '/logout',
  register: '/register',
  resetPassword: resetPasswordBasePath,
  confirmPassword: `${resetPasswordBasePath}/:uid`,
  feedbackForm: '/f/:id',
  verifyEmail: '/activate-account/:uid',
  verifyChangedEmail: '/confirm-email/:uid',
  verifyEmailAndPassword: '/accept-invite/:uid',
  eventLog: eventLogBasePath
};

export const publicRoutes = [
  baseRoutes.feedbackForm,
  baseRoutes.resetPassword,
  baseRoutes.confirmPassword,
  baseRoutes.register,
  baseRoutes.verifyEmail,
  baseRoutes.verifyChangedEmail,
  baseRoutes.verifyEmailAndPassword
];

// PATIENT ROUTES
const patientDetailPath = `${patientBasePath}/:id`;

export const patientRoutes = {
  list: patientBasePath,
  detail: {
    root: patientDetailPath,
    appointments: `${patientDetailPath}/appointments`
  }
};

// EVENT LOG ROUTES

export const eventLogRoutes = {
  list: eventLogBasePath
};

// SETTINGS ROUTES
const accountSettingsPath = `${settingsBasePath}/account`;
const automatedCheckInPath = `${settingsBasePath}/automated-check-in`;
const billingSettingsPath = `${settingsBasePath}/billing`;
const billingUnitSettingsPath = `${billingSettingsPath}/unit`;
const billingCategorySettingsPath = `${billingSettingsPath}/category`;
const billingPaymentSettingsPath = `${billingSettingsPath}/payments`;
const billingLocalizationSettingsPath = `${billingSettingsPath}/localization`;
const bookingSettingsPath = `${settingsBasePath}/booking`;

const therapySettingsPath = `${settingsBasePath}/therapy`;

export const settingsRoutes = {
  account: {
    root: accountSettingsPath,
    general: `${accountSettingsPath}/general`,
    locations: `${accountSettingsPath}/locations`,
    tags: `${accountSettingsPath}/tags`,
    newTag: `${accountSettingsPath}/tags/new`,
    tagDetail: `${accountSettingsPath}/tags/:tagId`,
    exportData: `${accountSettingsPath}/export`
  },
  automatedCheckIn: {
    root: automatedCheckInPath,
    devices: `${automatedCheckInPath}/devices`,
    greetingScreen: `${automatedCheckInPath}/greeting-screen`,
    signInFields: `${automatedCheckInPath}/sign-in-fields`,
    reasonForVisit: `${automatedCheckInPath}/reason-for-visit`,
    documentsAndQuestionnaires: `${automatedCheckInPath}/documents-and-questionnaires`,
    editDocumentsAndQuestionnaires: `${automatedCheckInPath}/documents-and-questionnaires/edit`,
    finalScreen: `${automatedCheckInPath}/final-screen`
  },
  billing: {
    root: billingSettingsPath,
    unit: billingUnitSettingsPath,
    unitDetail: `${billingUnitSettingsPath}/:id`,
    unitNew: `${billingUnitSettingsPath}/${NEW_ID}`,
    category: billingCategorySettingsPath,
    categoryDetail: `${billingCategorySettingsPath}/:id`,
    categoryNew: `${billingCategorySettingsPath}/${NEW_ID}`,
    payment: billingPaymentSettingsPath,
    paymentNew: `${billingPaymentSettingsPath}/${NEW_ID}`,
    localization: billingLocalizationSettingsPath
  },

  calendar: `${settingsBasePath}/calendar`,
  documents: `${settingsBasePath}/documents`,
  therapy: {
    root: therapySettingsPath,
    category: `${therapySettingsPath}/categories`,
    notification: `${therapySettingsPath}/notifications`
  },
  booking: bookingSettingsPath
};

export const employeeRoutes = {
  workingHours: `${baseRoutes.employees}/working-hours`,
  closedDates: `${baseRoutes.employees}/closed-dates`
};

export const documentRoutes = {
  all: `${baseRoutes.documents}/all`,
  templates: `${baseRoutes.documents}/templates`
};

export const bookingRoutes = {
  newBooking: `${bookingSettingsPath}/new`,
  bookingDetail: `${bookingSettingsPath}/:bookingId`,
  bookingSchedule: `${bookingSettingsPath}/schedule`,
  bookingTemplateList: `${bookingSettingsPath}/template-list`,
  bookingGeneralSettings: `${bookingSettingsPath}/general`
};

export const communicationBaseRoute = `/communication/sms`;
export const communicationBaseSmsRoute = `${communicationBaseRoute}/:type`;
export const communicationRoutes = {
  sms: {
    all: `${communicationBaseRoute}/all`,
    sent: `${communicationBaseRoute}/sent`,
    received: `${communicationBaseRoute}/incoming`,
    failed: `${communicationBaseRoute}/failed`,
    scheduled: `${communicationBaseRoute}/scheduled`
  }
};
