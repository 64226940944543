import produce from 'immer';
import _ from 'lodash';

import { mapFormDetail } from '../../../utils/mappers/document-mappers';
import moshiConfig from '../../../utils/moshiConfig';
import { getListPaginationFromResponse } from '../../../utils/redux/listUtils';

import { GET_QUESTIONNAIRE_SUCCESS } from '../../questionnaire/questionnaireTypes';
import {
  CLEAR_FORM_TEMPLATE_DETAIL,
  GET_FORM_TEMPLATE_DETAIL_FAILURE,
  GET_FORM_TEMPLATE_DETAIL_IN_PROGRESS,
  GET_FORM_TEMPLATE_DETAIL_SUCCESS,
  INCREMENT_FORM_FIELD_COUNT,
  POLL_FORM_TEMPLATE_LIST_FAILURE,
  POLL_FORM_TEMPLATE_LIST_IN_PROGRESS,
  POLL_FORM_TEMPLATE_LIST_SUCCESS,
  RESET_FORM_FIELD_COUNT
} from './formTemplateTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  list: [],
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  },
  detail: {},
  detailFieldCounter: 0,
  loadingList: false,
  loadingDetail: false
};

// eslint-disable-next-line default-param-last
const formTemplateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case POLL_FORM_TEMPLATE_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case POLL_FORM_TEMPLATE_LIST_SUCCESS:
        const formTemplates = _.getNonEmpty(payload, 'data', []);
        const {
          resultCount: templateResults,
          pageCount: templatePages
        } = getListPaginationFromResponse(payload, draft.pagination.limit);

        draft.pagination.resultCount = templateResults;
        draft.pagination.pageCount = templatePages;
        draft.loadingList = false;
        draft.list = _.sortBy(formTemplates, 'name');
        break;
      case POLL_FORM_TEMPLATE_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case GET_FORM_TEMPLATE_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        break;

      // TODO DOCUMENTS REWORK: Remove GET_QUESTIONNAIRE_SUCCESS after document rework (here for detailFieldCounter)
      case GET_QUESTIONNAIRE_SUCCESS:
      case GET_FORM_TEMPLATE_DETAIL_SUCCESS:
        const form = _.getNonEmpty(payload, 'response.data', {});

        draft.detail = mapFormDetail(form);
        draft.detailFieldCounter = _.getNonEmpty(
          draft,
          'detail.formBuilderSchema.fieldCounter',
          0
        );

        draft.loadingDetail = false;
        break;
      case GET_FORM_TEMPLATE_DETAIL_FAILURE:
        draft.loadingDetail = false;
        break;
      case CLEAR_FORM_TEMPLATE_DETAIL:
        draft.detail = initialState.detail;
        break;
      case INCREMENT_FORM_FIELD_COUNT:
        draft.detailFieldCounter++;
        break;
      case RESET_FORM_FIELD_COUNT:
        draft.detailFieldCounter = 0;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default formTemplateReducer;
