import React from 'react';
import Files from 'react-files';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import { showAlertError } from '../../../../utils/alert';
import { MOSHI_COLOR_INFO_NAME } from '../../../../utils/color';
import {
  MOSHI_FILE_DCM_EXTENSION,
  MOSHI_FILE_DICOM_EXTENSION,
  MOSHI_FILE_TYPE_DICOM,
  MOSHI_FILE_TYPE_DOCX,
  MOSHI_FILE_TYPE_GIF,
  MOSHI_FILE_TYPE_JPEG,
  MOSHI_FILE_TYPE_JPG,
  MOSHI_FILE_TYPE_P12,
  MOSHI_FILE_TYPE_PDF,
  MOSHI_FILE_TYPE_PNG,
  MOSHI_FILE_TYPE_XLS,
  MOSHI_FILE_TYPE_XLSX
} from '../../../../utils/constants/fileConstants';
import { useStoreGetSelector } from '../../../../utils/hooks';
import { convertMbToBytes } from '../../../../utils/mappers/files-mapper';
import moshiConfig from '../../../../utils/moshiConfig';

import { Progress } from '../../../reactstrap';

const FILE_ERROR_CODE_TOO_BIG = 2;

const MoshiFiles = ({
  t: translate,
  onFilesAdded,
  isFileUploading,
  filesRef,
  multiple,
  clickable,
  accepts,
  maxFileSize,
  minFileSize,
  hideDroppable,
  dropzoneClassNames,
  children
}) => {
  const uploadPercentageAvailable = useStoreGetSelector(
    'file.uploadPercentageAvailable'
  );
  const uploadPercentage = useStoreGetSelector('file.uploadPercentage');

  const getPercentageValue = () => {
    const availableUploadPercentage = uploadPercentageAvailable
      ? uploadPercentage
      : 100;

    return isFileUploading ? availableUploadPercentage : 0;
  };

  const onError = ({ code }) => {
    if (code === FILE_ERROR_CODE_TOO_BIG) {
      showAlertError('validation:file.maxSizeLimit', {
        translationParams: { maxFileSize }
      });
    }
  };

  const validateAddedFile = (files) => {
    if (!_.isEmpty(files)) {
      onFilesAdded(files);
    }
  };

  return (
    <div className={'moshi-files'}>
      <Files
        ref={filesRef}
        className={classNames(dropzoneClassNames, {
          'files-dropzone': _.isEmpty(children),
          hidden: isFileUploading || hideDroppable
        })}
        onError={onError}
        onChange={_.noop}
        onFilesAdded={validateAddedFile}
        clickable={clickable}
        multiple={multiple}
        maxFileSize={convertMbToBytes(maxFileSize)}
        minFileSize={convertMbToBytes(minFileSize)}
        accepts={accepts}
      >
        {_.isEmpty(children) && translate('document:file.drop')}
        {!_.isEmpty(children) && children}
      </Files>
      <div
        className={classNames('upload-progressbar', {
          hidden: !isFileUploading
        })}
      >
        <div className={'upload-status-text text-center'}>
          {translate('document:file.uploading')}
        </div>
        <Progress
          value={getPercentageValue()}
          color={MOSHI_COLOR_INFO_NAME}
          animated
        />
      </div>
    </div>
  );
};

MoshiFiles.propTypes = {
  t: PropTypes.func.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
  isFileUploading: PropTypes.bool,
  filesRef: PropTypes.shape(),
  multiple: PropTypes.bool,
  clickable: PropTypes.bool,
  accepts: PropTypes.arrayOf(
    PropTypes.oneOf([
      MOSHI_FILE_TYPE_DOCX,
      MOSHI_FILE_TYPE_XLSX,
      MOSHI_FILE_TYPE_XLS,
      MOSHI_FILE_TYPE_PDF,
      MOSHI_FILE_TYPE_JPG,
      MOSHI_FILE_TYPE_JPEG,
      MOSHI_FILE_TYPE_PNG,
      MOSHI_FILE_TYPE_GIF,
      MOSHI_FILE_TYPE_P12,
      MOSHI_FILE_TYPE_DICOM,
      MOSHI_FILE_DCM_EXTENSION,
      MOSHI_FILE_DICOM_EXTENSION
    ])
  ),
  maxFileSize: PropTypes.number,
  minFileSize: PropTypes.number,
  hideDroppable: PropTypes.bool,
  dropzoneClassNames: PropTypes.string,
  children: PropTypes.node
};

MoshiFiles.defaultProps = {
  filesRef: undefined,
  isFileUploading: false,
  multiple: false,
  clickable: false,
  accepts: undefined,
  maxFileSize: moshiConfig.imageUpload.maxFileSize,
  minFileSize: 0,
  hideDroppable: false,
  dropzoneClassNames: undefined,
  children: null
};

export default localize(MoshiFiles);
