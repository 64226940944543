import _ from 'lodash';
import { select, takeLatest, put, takeEvery } from 'redux-saga/effects';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../utils/browserStorage';
import { selectedLocationStorageKey } from '../../utils/constants/browserStorageConstants';
import { EMPTY_GUID } from '../../utils/gen';

import {
  assistRequestsUrl,
  webSocketSubscribe,
  webSocketUnsubscribe,
  webSocketVisitorWaitingListUrl
} from '../core/web-socket/webSocketAction';
import {
  getLocationsWithUserAccess,
  updateSelectedLocation
} from './locationActions';

import {
  ADD_LOCATION_SUCCESS,
  GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS,
  REMOVE_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_SELECTED_LOCATION
} from './locationReducer';

import { selectCurrentOrganizationId } from '../organization/organizationSelectors';
import {
  selectLocationsWithUserAccess,
  selectCurrentLocationId
} from './locationSelectors';

export function* checkLocationIsSelected() {
  const locationId = yield select(selectCurrentLocationId);

  if (!_.isEmpty(locationId) && locationId !== EMPTY_GUID) {
    return;
  }

  const storedLocation = getLocalStorageValue(selectedLocationStorageKey);

  if (!_.isEmpty(storedLocation)) {
    yield put(updateSelectedLocation(storedLocation, false));

    return;
  }

  const availableLocations = yield select(selectLocationsWithUserAccess);

  if (!_.isEmpty(availableLocations)) {
    yield put(updateSelectedLocation(availableLocations.shift(), false));
  }
}

export function* saveLocationToLocalStorage(action) {
  if (!_.isEmpty(action) && !_.isEmpty(action.selectedLocation)) {
    yield setLocalStorageValue(
      selectedLocationStorageKey,
      action.selectedLocation
    );
  }
}

export function* subscribeToLocationWebsockets(action) {
  const previousLocationId = _.get(action, 'previousLocationId');
  const newLocation = _.get(action, 'selectedLocation');

  if (!_.isEmpty(previousLocationId)) {
    yield put(webSocketUnsubscribe(`SAGA-LOC-${previousLocationId}`));
  }

  if (!_.isEmpty(newLocation)) {
    const state = yield select();
    const componentId = `SAGA-LOC-${newLocation.id}`;
    const organizationId = selectCurrentOrganizationId(state);

    yield put(
      webSocketSubscribe(
        componentId,
        webSocketVisitorWaitingListUrl(organizationId, newLocation.id)
      )
    );

    yield put(
      webSocketSubscribe(
        componentId,
        assistRequestsUrl(organizationId, newLocation.id)
      )
    );
  }
}

function* refetchLocationsWithUserAccess() {
  yield put(getLocationsWithUserAccess());
}

export function* ensureLocationIsSelectedSaga() {
  yield takeLatest(
    GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS,
    checkLocationIsSelected
  );
}

export function* watchForUpdatedLocationSaga() {
  yield takeEvery(UPDATE_SELECTED_LOCATION, saveLocationToLocalStorage);
  yield takeEvery(UPDATE_SELECTED_LOCATION, subscribeToLocationWebsockets);
}

export function* userLocationSagaWatcher() {
  yield takeEvery(
    [ADD_LOCATION_SUCCESS, UPDATE_LOCATION_SUCCESS, REMOVE_LOCATION_SUCCESS],
    refetchLocationsWithUserAccess
  );
}
