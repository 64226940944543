import produce from 'immer';
import _ from 'lodash';

import moshiConfig from '../../../utils/moshiConfig';

import {
  CLEAR_CASH_REGISTER_DETAIL,
  CLEAR_CASH_REGISTER_LATEST_LOG_DATA,
  SET_CASH_REGISTER_LATEST_LOG_DATA,
  GET_CASH_REGISTER_LOG_DETAIL_FAILURE,
  GET_CASH_REGISTER_LOG_DETAIL_IN_PROGRESS,
  GET_CASH_REGISTER_LOG_DETAIL_SUCCESS,
  GET_CASH_REGISTER_LOG_LIST_FAILURE,
  GET_CASH_REGISTER_LOG_LIST_IN_PROGRESS,
  GET_CASH_REGISTER_LOG_LIST_SUCCESS,
  SET_CASH_REGISTER_LIST_FILTER,
  SET_CASH_REGISTER_LIST_PAGINATION
} from './cashRegisterTypes';

import { LOGOUT } from '../../auth/authReducer';

import CashRegisterLogModel from '../../../metadata/model/billing/CashRegisterLogModel';

const initialState = {
  list: [],
  filter: {},
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: _.get(moshiConfig, 'list.pagination.limit', 20),
    page: 1
  },
  detail: new CashRegisterLogModel(),
  createdAt: '',
  latestDeviceData: {},
  loadingDetail: false,
  loadingCounters: false,
  loadingList: false,
  loadingBills: false
};

// eslint-disable-next-line default-param-last
const cashRegisterReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_CASH_REGISTER_LOG_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_CASH_REGISTER_LOG_LIST_SUCCESS:
        const cashRegisterLogs = _.get(payload, 'response.data', []);

        const maxResultsRaw = _.get(
          payload,
          'response.headers.x-total-count',
          draft.pagination.limit
        );
        const maxResults = _.parseInt(maxResultsRaw);

        draft.pagination.resultCount = maxResults;
        draft.pagination.pageCount = _.ceil(
          maxResults / draft.pagination.limit
        );

        draft.list = cashRegisterLogs;
        draft.loadingList = false;
        break;
      case GET_CASH_REGISTER_LOG_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case GET_CASH_REGISTER_LOG_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_CASH_REGISTER_LOG_DETAIL_SUCCESS:
        const createdAt = _.get(payload, 'response.headers.x-created-at', '');

        draft.createdAt = createdAt;
        draft.detail = _.nestedAssign(
          CashRegisterLogModel,
          _.get(payload, 'response.data', {})
        );
        draft.loadingDetail = false;
        break;
      case GET_CASH_REGISTER_LOG_DETAIL_FAILURE:
        draft.loadingDetail = false;
        break;
      case SET_CASH_REGISTER_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.get(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case SET_CASH_REGISTER_LIST_FILTER:
        const filterName = _.getNonEmpty(payload, 'filterName', null);
        const filterValue = _.get(payload, 'filterValue', undefined);

        if (!_.isEmpty(filterName)) {
          draft.filter[filterName] = filterValue;
        }
        break;
      case SET_CASH_REGISTER_LATEST_LOG_DATA:
        const bills = _.getNonEmpty(payload, 'bills', []);
        const latestLog = _.getNonEmpty(payload, 'latestLog');
        const electronicDeviceId = _.getNonEmpty(payload, 'electronicDeviceId');
        const billingUnitId = _.getNonEmpty(payload, 'billingUnitId');
        const nextCounters = _.getNonEmpty(payload, 'nextCounters');
        const latestLogFileId = _.getNonEmpty(payload, 'latestLogFileId');

        if (_.isEmpty(electronicDeviceId)) {
          break;
        }

        if (_.isUndefined(draft.latestDeviceData[billingUnitId])) {
          draft.latestDeviceData[billingUnitId] = {};
        }

        draft.latestDeviceData[billingUnitId][electronicDeviceId] = {
          bills,
          latestLog,
          billingUnitId,
          nextCounters,
          latestLogFileId
        };
        break;
      case CLEAR_CASH_REGISTER_LATEST_LOG_DATA:
        draft.latestDeviceData = {};
        break;
      case CLEAR_CASH_REGISTER_DETAIL:
        draft.detail = new CashRegisterLogModel();
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default cashRegisterReducer;
