import React from 'react';

import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MoshiFormik = ({ children, validationSchema, ...rest }) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {(formik) => {
      /*
       * TODO: WORKAROUND FORMIK
       * since formik 2.x, validationSchema is not present on the formik object
       * formik.validationSchema is required by our error components to display dynamic errors,
       * this cna be removed once https://github.com/formik/formik/issues/2337 is resolved
       */
      if (!_.has(formik, 'validationSchema')) {
        formik.validationSchema = validationSchema;
      }

      /*
       * patchValues is used for partial updates of the form, since Formik's setValues function
       * does not support partial form values updates.
       */
      formik.patchValues = (partialValues) => {
        _.forIn(partialValues, (val, key) => {
          _.set(formik.values, key, val);
        });

        formik.setValues(formik.values);
      };

      return _.isFunction(children) ? children(formik) : children;
    }}
  </Formik>
);

MoshiFormik.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  initialValues: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  component: PropTypes.element,
  enableReinitialize: PropTypes.bool,
  initialErrors: PropTypes.shape(),
  initialStatus: PropTypes.shape(),
  initialTouched: PropTypes.shape(),
  onReset: PropTypes.func,
  validate: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validateOnMount: PropTypes.bool
};

MoshiFormik.defaultProps = {
  validationSchema: undefined,
  component: undefined,
  enableReinitialize: undefined,
  initialErrors: undefined,
  initialStatus: undefined,
  initialTouched: undefined,
  onReset: undefined,
  onSubmit: undefined,
  validate: undefined,
  validateOnBlur: undefined,
  validateOnChange: undefined,
  validateOnMount: true
};

export default MoshiFormik;
