import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useIsFeatureModuleEnabled } from '../../../utils/hooks/useIsFeatureModuleEnabled';
import { isAuthenticated } from '../../../utils/jwt';
import { moshiConnect } from '../../../utils/redux';

import { EmptyComponent } from '../HtmlComponents';

import { FEATURE_MODULE_LIST } from '../../../featureModules';

export const ProtectedRouteComponent = ({
  isAuth,
  component: Component,
  componentParams,
  path,
  shouldRender,
  featureModule,
  ...params
}) => {
  const featureEnabled = useIsFeatureModuleEnabled(featureModule);

  if (!isAuth) {
    return <Redirect to={'/logout'} />;
  }

  if (!shouldRender || !featureEnabled) {
    return <EmptyComponent />;
  }

  return (
    <Route {...params} render={() => <Component {...componentParams} />} />
  );
};

ProtectedRouteComponent.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()])
    .isRequired,
  componentParams: PropTypes.shape(),
  shouldRender: PropTypes.bool,
  featureModule: PropTypes.oneOf(FEATURE_MODULE_LIST)
};

ProtectedRouteComponent.defaultProps = {
  componentParams: undefined,
  shouldRender: true,
  featureModule: undefined
};

const mapStateToProps = (state) => ({
  isAuth: isAuthenticated(state.authentication.tokenString)
});

export default moshiConnect(mapStateToProps)(ProtectedRouteComponent);
