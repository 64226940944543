import discharge from './discharge';
import forPatient from './forPatient';
import personal from './personal';

export default {
  groupId: 'notesAndSummaries',
  groupName: 'encounter:widget.notesAndSummaries.title',
  widgets: {
    [forPatient.id]: forPatient,
    [discharge.id]: discharge,
    [personal.id]: personal
  }
};
