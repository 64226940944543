import React from 'react';

import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';
import { MOSHI_COLOR_DANGER_NAME } from '../../../../utils/color';

import { Col, Row } from '../../../reactstrap';
import Icon from '../../Icon';
import MoshiLink from '../../button/MoshiLink';
import Visible from '../../layout/Visible';

const FileListItem = ({ t: translate, file, disabled, onRemove }) => (
  <Row>
    <div className={'files-item full-width'}>
      <Col xs={5} className={'files-item-name'}>
        <Icon name={'file'} size={'md'} />
        {
          // This anchor link will only work in production because of how cookies work
        }
        <a href={file.url} target={'_blank'} rel={'noopener noreferrer'}>
          {file.name}
        </a>
      </Col>
      <Col xs={3}>
        {translate('date:longDateWithTime', {
          date: file.createdAt
        })}
      </Col>
      <Col xs={2}>{file.sizeReadable}</Col>
      <Col xs={2}>
        <Visible
          visible={!disabled}
          component={MoshiLink}
          className={'text-right'}
          color={MOSHI_COLOR_DANGER_NAME}
          onClick={() => onRemove(file)}
        >
          {translate('common:remove')}
        </Visible>
        <Visible visible={disabled}>
          <a
            href={file.url}
            download={file.name}
            className={'text-right transform-uppercase cursor-pointer'}
          >
            {translate('common:download')}
          </a>
        </Visible>
      </Col>
    </div>
  </Row>
);

FileListItem.propTypes = {
  t: PropTypes.func.isRequired,
  file: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default localize(FileListItem);
