import _ from 'lodash';

/**
 * Prepares function for getting props for dropdown item
 * @param {object} params contains list of overridable params
 * | @param {function} onClick provides placeholder in case item is not selected
 * | @param {string} className className to override default
 *
 * @returns {object}
 * | @returns {(item, index) => props} getItemProps: function to get item props
 */
export const useDropdownItemProps = ({ onClick, className } = {}) => {
  const safeClassName = className || 'moshi-rs-dropdown-item';

  const getItemProps = (item, index) => ({
    key: item.id,
    id: `dropdown-item-${index}`,
    className: safeClassName,
    disabled: item.disabled,
    onClick: () => onClick(item),
    header: _.getNonEmpty(item, 'header', false)
  });

  return { getItemProps };
};
