import PropTypes from 'prop-types';

import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../utils/constants/tableConstants';
import {
  MOSHI_COLUMN_ALIGN_CENTER,
  MOSHI_COLUMN_ALIGN_LEFT,
  MOSHI_COLUMN_ALIGN_RIGHT
} from '../../utils/table';

import { FEATURE_MODULE_LIST } from '../../featureModules';

const TableColumnShape = {
  displayKeyCode: PropTypes.string,
  colWidth: PropTypes.number.isRequired,
  name: PropTypes.string,
  cellComponent: PropTypes.func,
  headerComponent: PropTypes.func,
  noHeaderLabel: PropTypes.bool,
  noCellWrapper: PropTypes.bool,
  initiallyHidden: PropTypes.bool
};

export const MoshiTableColumnShape = {
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minWidth: PropTypes.string,
  displayKeyCode: PropTypes.string,
  cellComponent: PropTypes.func,
  cellComponentName: PropTypes.string,
  config: PropTypes.shape(),
  cellClassName: PropTypes.string,
  sortable: PropTypes.bool,
  headerComponent: PropTypes.func,
  headerLabelVisible: PropTypes.bool,
  cellWrapperVisible: PropTypes.bool,
  align: PropTypes.oneOf([
    MOSHI_COLUMN_ALIGN_LEFT,
    MOSHI_COLUMN_ALIGN_CENTER,
    MOSHI_COLUMN_ALIGN_RIGHT
  ])
};

export const CardMoreActionsShape = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.func,
  className: PropTypes.string,
  isHidden: PropTypes.func,
  inline: PropTypes.bool,
  featureModule: PropTypes.oneOf(FEATURE_MODULE_LIST)
};

export const TableDataShape = {
  id: PropTypes.string.isRequired
};

export const TableSortConfigShape = {
  sortBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.func)
  ]).isRequired,
  order: PropTypes.oneOf([ORDER_ASCENDING, ORDER_DESCENDING]).isRequired
};

export const TablePaginationConfigShape = {
  resultCount: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPaginationDispatch: PropTypes.func
};

export default TableColumnShape;
