import produce from 'immer';
import _ from 'lodash';

import { jsonParse } from '../../../utils/jwt';

import {
  POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_OPERATION_LIST_SUCCESS,
  POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST_SUCCESS
} from '../../core/cache/cacheTypes';
import {
  ADD_PATIENT_ANAMNESIS_SUCCESS,
  CLEAR_PATIENT_ANAMNESIS_LIST,
  CLEAR_PATIENT_ANAMNESIS_LIST_FILTER,
  CLEAR_UPDATED_PATIENT_ANAMNESIS_ID,
  GET_PATIENT_ANAMNESIS_LIST_SUCCESS,
  GET_PINNED_PATIENT_ANAMNESIS_LIST_SUCCESS,
  SET_PATIENT_ANAMNESIS_LIST_FILTER,
  UPDATE_PATIENT_ANAMNESIS_SUCCESS
} from './patientAnamnesisTypes';

import { LOGOUT } from '../../auth/authReducer';

export const initialState = {
  filter: {},
  list: [],
  pinnedList: [],
  updatedAnamnesisItemId: null,
  codeList: {
    medicalCondition: [],
    concomitantDrug: [],
    allergy: [],
    vaccination: [],
    implant: [],
    operations: []
  }
};

const mapAnamnesisResponse = (patientAnamnesisEntry) => {
  if (!_.isEmptySafe(patientAnamnesisEntry, 'data')) {
    patientAnamnesisEntry.data = jsonParse(patientAnamnesisEntry.data);
  }

  return patientAnamnesisEntry;
};

// eslint-disable-next-line default-param-last
const patientAnamnesisReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case SET_PATIENT_ANAMNESIS_LIST_FILTER:
        const itemFilterName = _.getNonEmpty(payload, 'filterName', null);
        const itemFilterValue = _.getNonEmpty(payload, 'filterValue', '');

        if (!_.isEmpty(itemFilterName) || !_.isEmpty(itemFilterValue)) {
          draft.filter[itemFilterName] = itemFilterValue;
        }
        break;
      case CLEAR_PATIENT_ANAMNESIS_LIST_FILTER:
        draft.filter = initialState.filter;
        break;
      case GET_PATIENT_ANAMNESIS_LIST_SUCCESS:
        const patientAnamnesisList = _.getNonEmpty(
          payload,
          'response.data',
          []
        );
        const parsedListWithData = _.map(
          patientAnamnesisList,
          mapAnamnesisResponse
        );

        draft.pinnedList = _.filter(parsedListWithData, {
          data: { pinned: true }
        });
        draft.list = parsedListWithData;
        break;
      case GET_PINNED_PATIENT_ANAMNESIS_LIST_SUCCESS:
        const pinnedPatientAnamnesisList = _.getNonEmpty(
          payload,
          'response.data',
          []
        );
        const parsedPinnedListWithData = _.map(
          pinnedPatientAnamnesisList,
          mapAnamnesisResponse
        );

        // TODO: Remove filter once API supports metadata filter on /widgets
        draft.pinnedList = _.filter(parsedPinnedListWithData, {
          data: { pinned: true }
        });
        break;
      case UPDATE_PATIENT_ANAMNESIS_SUCCESS:
      case ADD_PATIENT_ANAMNESIS_SUCCESS:
        const newAnamnesis = _.getNonEmpty(payload, 'response.data', {});

        draft.updatedAnamnesisItemId = newAnamnesis.id;
        break;

      case CLEAR_UPDATED_PATIENT_ANAMNESIS_ID:
        draft.updatedAnamnesisItemId = null;
        break;
      case CLEAR_PATIENT_ANAMNESIS_LIST:
        draft.list = [];
        draft.pinnedList = [];
        break;
      case POLL_REGISTRY_ANAMNESIS_MEDICAL_CONDITION_LIST_SUCCESS:
        const medicalConditionRegistryList = _.getNonEmpty(payload, 'data', []);

        draft.codeList.medicalCondition = medicalConditionRegistryList;
        break;
      case POLL_REGISTRY_ANAMNESIS_CONCOMITANT_DRUG_LIST_SUCCESS:
        const concomitantDrugRegistryList = _.getNonEmpty(payload, 'data', []);

        draft.codeList.concomitantDrug = concomitantDrugRegistryList;
        break;
      case POLL_REGISTRY_ANAMNESIS_ALLERGY_LIST_SUCCESS:
        const allergyRegistryList = _.getNonEmpty(payload, 'data', []);

        draft.codeList.allergy = allergyRegistryList;
        break;
      case POLL_REGISTRY_ANAMNESIS_VACCINATION_LIST_SUCCESS:
        const vaccinationRegistryList = _.getNonEmpty(payload, 'data', []);

        draft.codeList.vaccination = vaccinationRegistryList;
        break;
      case POLL_REGISTRY_ANAMNESIS_OPERATION_LIST_SUCCESS:
        const operationRegistryList = _.getNonEmpty(payload, 'data', []);

        draft.codeList.operations = operationRegistryList;
        break;
      case POLL_REGISTRY_ANAMNESIS_IMPLANT_LIST_SUCCESS:
        const implantsRegistryList = _.getNonEmpty(payload, 'data', []);

        draft.codeList.implant = implantsRegistryList;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default patientAnamnesisReducer;
