import _ from 'lodash';

import EntityBaseModel from './EntityBaseModel';
import NotificationsModel from './NotificationsModel';
import BillingSettingsModel from './billing/BillingSettingsModel';
import BusinessModel from './billing/BusinessModel';

class OrganizationModel extends BusinessModel {
  constructor(
    name = '',
    street1 = '',
    geo = null,
    billingSettings = BillingSettingsModel.getEmptyModel()
  ) {
    super(name, street1, geo);
    this.id = '';
    this.billingSettings = billingSettings;
    this.website = '';
    this.taxNumber = '';
    this.companyID = '';
    this.widgetsConfig = '';
    this.notificationsSettings = new NotificationsModel();
  }

  static capitalizeOrgFields(payload) {
    /**
     * We auto capitalise name, address and city
     */
    payload.name = _.sentenceCase(payload.name);
    if (!_.isEmptySafe(payload, 'address.street1')) {
      payload.address.street1 = _.sentenceCase(payload.address.street1);
    }
    if (!_.isEmptySafe(payload, 'address.town')) {
      payload.address.town = _.sentenceCase(payload.address.town);
    }

    return payload;
  }

  static getPayload(model, includeBillingSettings) {
    let payload = _.cloneDeep(model);

    payload = EntityBaseModel.removeEntityMetadata(payload);

    if (
      includeBillingSettings &&
      _.has(payload, 'billingSettings.invoicingID')
    ) {
      delete payload.billingSettings.invoicingID;
      delete payload.widgetsConfig;
    }

    if (!includeBillingSettings) {
      delete payload.billingSettings;
      delete payload.taxNumber;
      delete payload.website;
    }

    return this.capitalizeOrgFields(payload);
  }
}

export default OrganizationModel;
