import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as SpinnerIcon } from '../../img/icons/spinner.svg';

const Spinner = ({ className }) => (
  <div className={classNames('loading spinner', className)}>
    <SpinnerIcon />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string
};

Spinner.defaultProps = {
  className: undefined
};

export default Spinner;
