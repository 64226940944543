import axios from 'axios';
import _ from 'lodash';

import { storeGetState } from '../../configureStore';
import {
  showAlertSuccess,
  showMissingOrganizationIdError
} from '../../utils/alert';
import { getOrganizationLocationsUrl } from '../../utils/api/apiUrlUtils';
import { getLocalStorageValue } from '../../utils/browserStorage';
import { selectedLocationStorageKey } from '../../utils/constants/browserStorageConstants';
import { NO_ID } from '../../utils/form';
import { getLocationAccessResourceById } from '../../utils/location';

import { validateResources } from '../auth/authActions';
import { getCalendarWaitingList } from '../calendar/calendarActions';
import { refreshWaitingListEncounters } from '../core/cache/encounterWaitingList/waitingListEncounterActions';

import {
  ADD_LOCATION,
  GET_CURRENT_USER_LOCATION_ACCESS,
  GET_CURRENT_USER_LOCATIONS,
  GET_LOCATION,
  GET_LOCATIONS,
  REMOVE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_SELECTED_LOCATION
} from './locationReducer';

import { selectOrganizationIdFromToken } from '../auth/authSelectors';
import { selectCachedLocationList } from '../core/cache/cacheSelectors';
import { selectCurrentLocationId } from './locationSelectors';

const capitalizeLocationFields = (locationData) => {
  const payload = _.cloneDeep(locationData);

  /**
   * We auto capitalise name, address and city
   */
  payload.name = _.sentenceCase(locationData.name);
  payload.address.street1 = _.sentenceCase(payload.address.street1);
  payload.address.town = _.sentenceCase(payload.address.town);

  return payload;
};

export const getLocations = (
  actionType = GET_LOCATIONS,
  onSuccess = _.noop
) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return {
    type: actionType,
    apiCall: axios.get(`/organizations/${organizationId}/locations`),
    onSuccess
  };
};

export const getLocationsWithUserAccess = () => (dispatch, getState) => {
  const onRequestSuccess = (_thunk, { data: locationList }) => {
    const organizationId = selectOrganizationIdFromToken(getState());

    if (!_.isEmpty(locationList)) {
      const resources = locationList.map((location) => ({
        resource: getLocationAccessResourceById(organizationId, location.id)
      }));

      dispatch(validateResources(resources, GET_CURRENT_USER_LOCATION_ACCESS));
    }
  };

  dispatch(getLocations(GET_CURRENT_USER_LOCATIONS, onRequestSuccess));
};

export const getLocation = (locationId, actionType = GET_LOCATION) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return {
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/locations/${locationId}`
    )
  };
};

export const addLocation = (locationData) => (dispatch, getState) => {
  const organizationLocationsUrl = getOrganizationLocationsUrl(getState);

  const payload = capitalizeLocationFields(locationData);

  return dispatch({
    type: ADD_LOCATION,
    apiCall: axios.post(organizationLocationsUrl, payload),
    onSuccess: () => {
      dispatch(getLocations());
      showAlertSuccess('location:alert.created');
    }
  });
};

export const updateLocation = (locationData) => (dispatch, getState) => {
  const organizationLocationsUrl = getOrganizationLocationsUrl(getState);

  const locationId = _.get(locationData, 'id', NO_ID);

  const payload = capitalizeLocationFields(locationData);

  return dispatch({
    type: UPDATE_LOCATION,
    apiCall: axios.put(`${organizationLocationsUrl}/${locationId}`, payload),
    onSuccess: () => {
      dispatch(getLocations());
      showAlertSuccess('location:alert.updated');
    }
  });
};

export const removeLocation = (locationId) => (dispatch) => {
  const organizationId = _.get(
    storeGetState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: REMOVE_LOCATION,
    apiCall: axios.delete(
      `/organizations/${organizationId}/locations/${locationId}`
    ),
    onSuccess: () => {
      dispatch(getLocations());
      showAlertSuccess('location:alert.removed');
    }
  });
};

export const updateSelectedLocation = (location, showAlert = true) => (
  dispatch,
  getState
) => {
  const prevSelectedLocation = selectCurrentLocationId(getState());
  const storageLocation = getLocalStorageValue(selectedLocationStorageKey);

  const prevLocation = _.isEmptySafe(prevSelectedLocation)
    ? storageLocation
    : prevSelectedLocation;

  if (
    _.getNonEmpty(prevSelectedLocation, 'id') !== _.getNonEmpty(location, 'id')
  ) {
    dispatch({
      type: UPDATE_SELECTED_LOCATION,
      selectedLocation: location,
      previousLocationId: prevSelectedLocation
    });
  }

  if (_.getNonEmpty(prevLocation, 'id') !== _.getNonEmpty(location, 'id')) {
    dispatch(refreshWaitingListEncounters());
    dispatch(getCalendarWaitingList());

    if (showAlert) {
      showAlertSuccess('location:alert.selectedLocationUpdated');
    }
  }
};

export const updateSelectedLocationById = (
  locationId,
  showAlert = undefined
) => (dispatch, getState) => {
  const locations = selectCachedLocationList(getState());

  const foundLocation = _.find(locations, { id: locationId });

  if (!_.isEmptySafe(foundLocation)) {
    dispatch(updateSelectedLocation(foundLocation, showAlert));
  }
};
