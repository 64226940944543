import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

const CustomDefaultDatepickerWidget = ({
  value,
  disabled,
  onChange,
  ...props
}) => {
  const handleOnChange = (evt) => {
    onChange(evt.target.value);
  };

  const maxDate = _.get(props, 'options.maxDate', null);

  return (
    <input
      onChange={handleOnChange}
      className={'form-control'}
      value={value}
      type={'date'}
      max={maxDate}
      disabled={disabled}
    />
  );
};

CustomDefaultDatepickerWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

CustomDefaultDatepickerWidget.defaultProps = {
  value: null,
  disabled: false
};

export default CustomDefaultDatepickerWidget;
