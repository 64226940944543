import medication from './medication';
import treatments from './treatments';

export default {
  groupId: 'conclusion',
  groupName: 'encounter:widget.conclusion.title',
  widgets: {
    [treatments.id]: treatments,
    [medication.id]: medication
  }
};
