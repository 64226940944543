import { createActionTypeArray } from '../../common/actionTypes';

export const GET_DELETED_USER = 'archive/GET_DELETED_USER';
export const GET_DELETED_LOCATION = 'archive/GET_DELETED_LOCATION';
export const GET_DELETED_FLOW = 'archive/GET_DELETED_FLOW';
export const GET_DELETED_DRUG = 'archive/GET_DELETED_DRUG';
export const GET_DELETED_CATEGORY = 'archive/GET_DELETED_CATEGORY';
export const GET_DELETED_QUESTIONNAIRE = 'archive/GET_DELETED_QUESTIONNAIRE';
export const GET_DELETED_PAYMENT_TYPE = 'archive/GET_DELETED_PAYMENT_TYPE';
export const GET_DELETED_TAX = 'archive/GET_DELETED_TAX';

export const [
  GET_DELETED_USER_IN_PROGRESS,
  GET_DELETED_USER_SUCCESS,
  GET_DELETED_USER_FAILURE
] = createActionTypeArray(GET_DELETED_USER);

export const [
  GET_DELETED_LOCATION_IN_PROGRESS,
  GET_DELETED_LOCATION_SUCCESS,
  GET_DELETED_LOCATION_FAILURE
] = createActionTypeArray(GET_DELETED_LOCATION);

export const [
  GET_DELETED_FLOW_IN_PROGRESS,
  GET_DELETED_FLOW_SUCCESS,
  GET_DELETED_FLOW_FAILURE
] = createActionTypeArray(GET_DELETED_FLOW);

export const [
  GET_DELETED_DRUG_IN_PROGRESS,
  GET_DELETED_DRUG_SUCCESS,
  GET_DELETED_DRUG_FAILURE
] = createActionTypeArray(GET_DELETED_DRUG);

export const [
  GET_DELETED_CATEGORY_IN_PROGRESS,
  GET_DELETED_CATEGORY_SUCCESS,
  GET_DELETED_CATEGORY_FAILURE
] = createActionTypeArray(GET_DELETED_CATEGORY);

export const [
  GET_DELETED_QUESTIONNAIRE_IN_PROGRESS,
  GET_DELETED_QUESTIONNAIRE_SUCCESS,
  GET_DELETED_QUESTIONNAIRE_FAILURE
] = createActionTypeArray(GET_DELETED_QUESTIONNAIRE);

export const [
  GET_DELETED_PAYMENT_TYPE_IN_PROGRESS,
  GET_DELETED_PAYMENT_TYPE_SUCCESS,
  GET_DELETED_PAYMENT_TYPE_FAILURE
] = createActionTypeArray(GET_DELETED_PAYMENT_TYPE);

export const [
  GET_DELETED_TAX_IN_PROGRESS,
  GET_DELETED_TAX_SUCCESS,
  GET_DELETED_TAX_FAILURE
] = createActionTypeArray(GET_DELETED_TAX);
