import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';

import {
  getLocalStorage,
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue
} from '../../../utils/browserStorage';
import { PATIENT_ANAMNESIS_ALL_TYPES } from '../../../utils/patient/anamnesisUtils';
import { widgetMapper } from '../../../utils/widgets';

import {
  ADD_LOCAL_WIDGET,
  CLEAR_ALL_PATIENT_WIDGET_LIST,
  CLEAR_LOCAL_ENCOUNTER_WIDGET_DATA,
  CLEAR_WIDGET_FORM_DATA,
  GET_ALL_PATIENT_WIDGET_LIST_SUCCESS,
  WIDGET_HISTORY_UPDATE,
  GET_WIDGET_LIST_FAILURE,
  GET_WIDGET_LIST_IN_PROGRESS,
  GET_WIDGET_LIST_SUCCESS,
  REMOVE_LOCAL_WIDGET,
  SET_ALL_PATIENT_WIDGET_FILTER,
  SET_CURRENT_ENCOUNTER_ID,
  SET_WIDGET_EDIT_MODE,
  SET_WIDGET_FORM_DATA,
  UPDATE_WIDGET_LIST_ADD,
  UPDATE_WIDGET_LIST_REMOVE,
  UPDATE_WIDGET_LIST_UPDATE,
  SET_FOCUSED_WIDGET_ID,
  CLEAR_FOCUSED_WIDGET_ID,
  SET_WIDGET_IDS_TO_SUBMIT,
  CLEAR_WIDGET_IDS_TO_SUBMIT
} from './widgetTypes';

const getLocalWidgetDataKeyPrefix = (encounterId) => `widget-${encounterId}`;
const getLocalWidgetDataKey = (encounterId, widgetId) =>
  `${getLocalWidgetDataKeyPrefix(encounterId)}-${widgetId}`;
const getLocalEditModeKey = (encounterId) => `widgetsInEditMode-${encounterId}`;
const getLocalWidgetIdsKey = (encounterId) => `localWidgetIds-${encounterId}`;

/**
 * A function that extracts all widget data that belongs to specific encounter
 * @param encounterId
 */
const getLocalEncounterWidgetData = (encounterId) => {
  const encounterWidgetData = {};
  const widgetDataKeyPrefix = getLocalWidgetDataKeyPrefix(encounterId);

  _.forIn(getLocalStorage(), (value, key) => {
    if (_.includes(key, widgetDataKeyPrefix) && !_.isFunction(value)) {
      const widgetId = _.replace(key, `${widgetDataKeyPrefix}-`, '');
      const widgetData = getLocalStorageValue(key, {});

      _.set(encounterWidgetData, widgetId, widgetData);
    }
  });

  return encounterWidgetData;
};

/**
 * A function that clears all local storage data that belongs to specific encounter
 * @param encounterId
 */
const clearLocalEncounterWidgetData = (encounterId) => {
  const widgetDataKeyPrefix = getLocalWidgetDataKeyPrefix(encounterId);

  _.forIn(getLocalStorage(), (value, key) => {
    if (_.includes(key, widgetDataKeyPrefix) && !_.isFunction(value)) {
      removeLocalStorageValue(key);
    }
  });
};

const widgetWithRawDataMapper = (widget) => widgetMapper(widget, true);

const initialState = {
  fieldData: {},
  loadingFieldData: {},
  widgetList: {},
  localWidgetIdList: [],
  loadingWidgetList: false,
  localWidgetData: {},
  localWidgetsInEditMode: [],
  currentEncounterId: null,
  hasPendingLocalChanges: false,
  widgetHistory: {},
  allPatientWidgetList: [],
  allPatientWidgetListFilter: {},
  focusedWidgetId: null,
  widgetIdsToSubmit: []
};

// eslint-disable-next-line default-param-last,max-statements
const widgetReducer = (state = initialState, action) =>
  // eslint-disable-next-line max-statements
  produce(state, (draft) => {
    const { type, ...payload } = action;
    const fallbackEncounterId = _.get(draft, 'currentEncounterId', null);
    const encounterId = _.get(payload, 'encounterId', fallbackEncounterId);
    const widgetId = _.get(payload, 'widgetId', undefined);

    let widgetDataKey = getLocalWidgetDataKey(encounterId, widgetId);
    const widgetsInEditModeKey = getLocalEditModeKey(encounterId);
    const widgetIdsKey = getLocalWidgetIdsKey(encounterId);

    let widgetList;
    let encounterWidgets;
    let rawWidgets;

    switch (type) {
      case SET_CURRENT_ENCOUNTER_ID:
        const widgetsInEditMode = getLocalStorageValue(
          widgetsInEditModeKey,
          []
        );
        const localWidgetIdList = getLocalStorageValue(widgetIdsKey, []);
        const widgetData = getLocalEncounterWidgetData(encounterId);

        draft.currentEncounterId = encounterId;
        draft.localWidgetsInEditMode = widgetsInEditMode;
        draft.localWidgetIdList = localWidgetIdList;
        draft.localWidgetData = widgetData;
        break;
      case GET_WIDGET_LIST_IN_PROGRESS:
        draft.loadingWidgetList = true;
        break;
      case GET_WIDGET_LIST_SUCCESS:
        rawWidgets = _.get(payload, 'response.data', []);

        encounterWidgets = rawWidgets.map(widgetMapper);

        widgetList = {
          [encounterId]: encounterWidgets
        };

        draft.loadingWidgetList = false;
        draft.widgetList = widgetList;
        break;
      case GET_WIDGET_LIST_FAILURE:
        draft.loadingWidgetList = false;
        break;
      case UPDATE_WIDGET_LIST_ADD:
        const addedWidget = _.get(payload, 'widget', {});

        widgetList = _.cloneDeep(draft.widgetList);
        encounterWidgets = _.get(widgetList, encounterId, []);

        encounterWidgets.push(addedWidget);

        widgetList[encounterId] = encounterWidgets;

        draft.widgetList = widgetList;
        break;
      case UPDATE_WIDGET_LIST_UPDATE:
        const updatedWidget = _.get(payload, 'widget', {});

        widgetList = _.cloneDeep(draft.widgetList);
        encounterWidgets = _.get(widgetList, encounterId, []);
        const updatedWidgetIndex = _.findIndex(encounterWidgets, {
          id: updatedWidget.id
        });

        encounterWidgets[updatedWidgetIndex] = updatedWidget;

        widgetList[encounterId] = encounterWidgets;

        draft.widgetList = widgetList;
        break;
      case UPDATE_WIDGET_LIST_REMOVE:
        widgetList = _.cloneDeep(draft.widgetList);
        encounterWidgets = _.get(widgetList, encounterId, []);

        _.remove(encounterWidgets, {
          id: widgetId
        });

        widgetList[encounterId] = encounterWidgets;

        draft.widgetList = widgetList;
        break;
      case SET_WIDGET_FORM_DATA:
        const formData = _.get(payload, 'formData', undefined);
        const widget = _.get(payload, 'widget', undefined);
        const timestampedWidgetData = {
          ...widget,
          data: formData,
          localLastModifiedOn: moment().toISOString()
        };

        widgetDataKey = getLocalWidgetDataKey(encounterId, widget.id);

        draft.localWidgetData[widget.id] = timestampedWidgetData;
        setLocalStorageValue(widgetDataKey, timestampedWidgetData);
        break;
      case CLEAR_WIDGET_FORM_DATA:
        delete draft.localWidgetData[widgetId];
        removeLocalStorageValue(widgetDataKey);
        break;
      case ADD_LOCAL_WIDGET:
        const newWidgetId = _.get(payload, 'widgetId', {});
        const newLocalWidgetList = _.cloneDeep(draft.localWidgetIdList);

        if (!_.includes(newLocalWidgetList, newWidgetId)) {
          newLocalWidgetList.push(newWidgetId);
          draft.localWidgetIdList = newLocalWidgetList;
          setLocalStorageValue(widgetIdsKey, newLocalWidgetList);
        }
        break;
      case REMOVE_LOCAL_WIDGET:
        const widgetIdToRemove = _.get(payload, 'widgetId', {});
        const updatedLocalWidgetList = _.cloneDeep(draft.localWidgetIdList);

        if (_.includes(updatedLocalWidgetList, widgetIdToRemove)) {
          _.remove(
            updatedLocalWidgetList,
            (localWidgetId) => localWidgetId === widgetIdToRemove
          );
          draft.localWidgetIdList = updatedLocalWidgetList;
          if (_.isEmpty(updatedLocalWidgetList)) {
            removeLocalStorageValue(widgetIdsKey);
          } else {
            setLocalStorageValue(widgetIdsKey, updatedLocalWidgetList);
          }
        }

        break;
      case SET_WIDGET_EDIT_MODE:
        const editMode = _.get(payload, 'editMode', false);
        const newWidgetsInEditMode = _.cloneDeep(draft.localWidgetsInEditMode);

        if (editMode && !_.includes(newWidgetsInEditMode, widgetId)) {
          newWidgetsInEditMode.push(widgetId);
        } else if (!editMode && _.includes(newWidgetsInEditMode, widgetId)) {
          _.remove(
            newWidgetsInEditMode,
            (widgetInEditMode) => widgetInEditMode === widgetId
          );
        }

        draft.localWidgetsInEditMode = newWidgetsInEditMode;
        if (_.isEmpty(newWidgetsInEditMode)) {
          removeLocalStorageValue(widgetsInEditModeKey);
        } else {
          setLocalStorageValue(widgetsInEditModeKey, newWidgetsInEditMode);
        }
        break;
      case CLEAR_LOCAL_ENCOUNTER_WIDGET_DATA:
        draft.localWidgetsInEditMode = initialState.localWidgetsInEditMode;
        draft.localWidgetIdList = initialState.localWidgetIdList;
        draft.localWidgetData = initialState.localWidgetData;

        clearLocalEncounterWidgetData(encounterId);
        removeLocalStorageValue(widgetsInEditModeKey);
        removeLocalStorageValue(widgetIdsKey);
        break;
      case WIDGET_HISTORY_UPDATE:
        const widgetHistory = _.getNonEmpty(payload, 'widgetHistory', []);
        const widgetType = _.getNonEmpty(payload, 'widgetType');

        draft.widgetHistory[widgetType] = widgetHistory;
        break;
      case GET_ALL_PATIENT_WIDGET_LIST_SUCCESS:
        rawWidgets = _.getNonEmpty(payload, 'response.data', []);
        rawWidgets = _.filter(
          rawWidgets,
          (rawWidget) =>
            !_.includes(PATIENT_ANAMNESIS_ALL_TYPES, rawWidget.widgetType)
        );

        const allPatientWidgetList = rawWidgets.map(widgetWithRawDataMapper);

        draft.allPatientWidgetList = allPatientWidgetList;
        break;
      case CLEAR_ALL_PATIENT_WIDGET_LIST:
        draft.allPatientWidgetList = [];
        break;
      case SET_ALL_PATIENT_WIDGET_FILTER:
        const filterName = _.getNonEmpty(payload, 'filterName', null);
        const filterValue = _.get(payload, 'filterValue', '');

        if (!_.isEmpty(filterName) || !_.isEmpty(filterValue)) {
          draft.allPatientWidgetListFilter[filterName] = filterValue;
        }
        break;

      case SET_FOCUSED_WIDGET_ID:
        draft.focusedWidgetId = widgetId;
        break;

      case CLEAR_FOCUSED_WIDGET_ID:
        draft.focusedWidgetId = initialState.focusedWidgetId;
        break;

      case SET_WIDGET_IDS_TO_SUBMIT:
        const widgetIds = _.getNonEmpty(payload, 'widgetIds', []);

        draft.widgetIdsToSubmit = widgetIds;
        break;

      case CLEAR_WIDGET_IDS_TO_SUBMIT:
        draft.widgetIdsToSubmit = initialState.widgetIdsToSubmit;
        break;

      default:
    }

    draft.hasPendingLocalChanges =
      !_.isEmpty(draft.localWidgetData) ||
      !_.isEmpty(draft.localWidgetIdList) ||
      !_.isEmpty(draft.localWidgetsInEditMode);
  });

export default widgetReducer;
