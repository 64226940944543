import React from 'react';

import PropTypes from 'prop-types';

import BodyInteractive from './BodyInteractive';
import BodyOutline from './BodyOutline';

const Body = ({
  outlineColor,
  disabledColor,
  getColor,
  onChange,
  initialSelectedParts,
  disabled,
  chestDisabled,
  shouldersDisabled,
  earsDisabled,
  hipsDisabled,
  heelsDisabled
}) => (
  <g id={'body'}>
    <BodyOutline
      outlineColor={outlineColor}
      disabledColor={disabledColor}
      disabled={disabled}
    />
    {!disabled && (
      <BodyInteractive
        getColor={getColor}
        onChange={onChange}
        initialSelectedParts={initialSelectedParts}
        chestDisabled={chestDisabled}
        shouldersDisabled={shouldersDisabled}
        earsDisabled={earsDisabled}
        hipsDisabled={hipsDisabled}
        heelsDisabled={heelsDisabled}
      />
    )}
  </g>
);

Body.propTypes = {
  outlineColor: PropTypes.string.isRequired,
  disabledColor: PropTypes.string.isRequired,
  getColor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  initialSelectedParts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  chestDisabled: PropTypes.bool,
  shouldersDisabled: PropTypes.bool,
  earsDisabled: PropTypes.bool,
  hipsDisabled: PropTypes.bool,
  heelsDisabled: PropTypes.bool
};

Body.defaultProps = {
  onChange: undefined,
  initialSelectedParts: undefined,
  disabled: false,
  chestDisabled: undefined,
  shouldersDisabled: undefined,
  earsDisabled: undefined,
  hipsDisabled: undefined,
  heelsDisabled: undefined
};

export default Body;
