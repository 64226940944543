/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Progress } from 'reactstrap';

import PropTypes from 'prop-types';

const ProgressWrapper = ({
  children,
  bar,
  multi,
  value,
  max,
  animated,
  striped,
  color,
  barClassName,
  className,
  id
}) => (
  <Progress
    bar={bar}
    multi={multi}
    value={value}
    max={max}
    animated={animated}
    striped={striped}
    color={color}
    barClassName={barClassName}
    className={className}
    id={id}
  >
    {children}
  </Progress>
);

ProgressWrapper.propTypes = {
  children: PropTypes.node,
  bar: PropTypes.bool,
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animated: PropTypes.bool,
  striped: PropTypes.bool,
  color: PropTypes.string,
  barClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};

ProgressWrapper.defaultProps = {
  children: undefined,
  bar: undefined,
  multi: undefined,
  value: undefined,
  max: undefined,
  animated: undefined,
  striped: undefined,
  color: undefined,
  barClassName: undefined,
  className: undefined,
  id: undefined
};

export default ProgressWrapper;
