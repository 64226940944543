import { createActionTypeArray } from '../common/actionTypes';

export const ADD_CLOSED_DATES = 'working-hours/ADD_CLOSED_DATES';
export const GET_CLOSED_DATES = 'working-hours/GET_CLOSED_DATES';
export const DELETE_CLOSED_DATES = 'working-hours/DELETE_CLOSED_DATES';
export const DELETE_MULTIPLE_CLOSED_DATES =
  'working-hours/DELETE_MULTIPLE_CLOSED_DATES';
export const START_CLOSED_DATES_POLLING =
  'working-hours/START_CLOSED_DATES_POLLING';
export const STOP_CLOSED_DATES_POLLING =
  'working-hours/STOP_CLOSED_DATES_POLLING';

export const SET_CLOSED_DATES_LIST_PAGINATION =
  'working-hours/SET_CLOSED_DATES_LIST_PAGINATION';

export const [
  ADD_CLOSED_DATES_IN_PROGRESS,
  ADD_CLOSED_DATES_SUCCESS,
  ADD_CLOSED_DATES_FAILURE
] = createActionTypeArray(ADD_CLOSED_DATES);

export const [
  GET_CLOSED_DATES_IN_PROGRESS,
  GET_CLOSED_DATES_SUCCESS,
  GET_CLOSED_DATES_FAILURE
] = createActionTypeArray(GET_CLOSED_DATES);

export const [
  DELETE_CLOSED_DATES_IN_PROGRESS,
  DELETE_CLOSED_DATES_SUCCESS,
  DELETE_CLOSED_DATES_FAILURE
] = createActionTypeArray(DELETE_CLOSED_DATES);
