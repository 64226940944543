import { matchPath } from 'react-router';

import produce from 'immer';
import _ from 'lodash';

import { getLocationAccessResourceById } from '../../utils/location';
import { safeLocationAddress } from '../../utils/mappers/location-mappers';

import { createActionTypeArray } from '../common/actionTypes';

import { LOGOUT } from '../auth/authReducer';

export const ADD_LOCATION = 'locations/ADD_LOCATION';
export const UPDATE_LOCATION = 'locations/UPDATE_LOCATION';
export const REMOVE_LOCATION = 'locations/REMOVE_LOCATION';
export const GET_LOCATIONS = 'locations/GET_LOCATIONS';
export const GET_LOCATION = 'locations/GET_LOCATION';

export const UPDATE_SELECTED_LOCATION = 'locations/UPDATE_SELECTED_LOCATION';

export const GET_CURRENT_USER_LOCATIONS =
  'locations/GET_CURRENT_USER_LOCATIONS';
export const GET_CURRENT_USER_LOCATION_ACCESS =
  'locations/GET_CURRENT_USER_LOCATION_ACCESS';

export const [
  GET_LOCATIONS_IN_PROGRESS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE
] = createActionTypeArray(GET_LOCATIONS);

export const [
  ADD_LOCATION_IN_PROGRESS,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE
] = createActionTypeArray(ADD_LOCATION);

export const [
  UPDATE_LOCATION_IN_PROGRESS,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE
] = createActionTypeArray(UPDATE_LOCATION);

export const [
  REMOVE_LOCATION_IN_PROGRESS,
  REMOVE_LOCATION_SUCCESS,
  REMOVE_LOCATION_FAILURE
] = createActionTypeArray(REMOVE_LOCATION);

export const [
  GET_CURRENT_USER_LOCATIONS_IN_PROGRESS,
  GET_CURRENT_USER_LOCATIONS_SUCCESS,
  GET_CURRENT_USER_LOCATIONS_FAILURE
] = createActionTypeArray(GET_CURRENT_USER_LOCATIONS);

export const [
  GET_CURRENT_USER_LOCATION_ACCESS_IN_PROGRESS,
  GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS,
  GET_CURRENT_USER_LOCATION_ACCESS_FAILURE
] = createActionTypeArray(GET_CURRENT_USER_LOCATION_ACCESS);

const getInitialState = () => {
  const state = {
    list: [],
    tempLocationsWithUserAccess: [],
    locationsWithUserAccess: [],
    selectedLocation: null
  };

  return state;
};

const locationReducer = produce((draft, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case GET_LOCATIONS_IN_PROGRESS:
      draft.loadingList = true;
      break;
    case GET_LOCATIONS_SUCCESS:
      let locations = _.get(payload, 'response.data', []);

      locations = locations.map(safeLocationAddress);

      draft.loadingList = false;
      draft.list = _.sortBy(locations, 'name');
      break;
    case GET_LOCATIONS_FAILURE:
      draft.loadingList = false;
      break;
    case UPDATE_SELECTED_LOCATION:
      const { selectedLocation: updatedLocation } = payload;

      draft.selectedLocation = updatedLocation;
      break;
    case GET_CURRENT_USER_LOCATIONS_SUCCESS:
      let tempLocationsWithUserAccess = _.get(payload, 'response.data', []);

      tempLocationsWithUserAccess = tempLocationsWithUserAccess.map(
        safeLocationAddress
      );

      draft.tempLocationsWithUserAccess = _.sortBy(
        tempLocationsWithUserAccess,
        'name'
      );
      break;
    case GET_CURRENT_USER_LOCATION_ACCESS_SUCCESS:
      const tempLocations = _.cloneDeep(draft.tempLocationsWithUserAccess);
      const validLocations = _.get(payload, 'response.data', []);
      const matchInner = (path) =>
        matchPath(path, {
          path: getLocationAccessResourceById(':organizationId', ':locationId')
        });

      const locationAccessList = validLocations.map((locationAccess) => {
        const path = _.get(locationAccess, 'query.resource', 'NOT_FOUND');
        const locationId = _.get(matchInner(path), 'params.locationId', null);

        return {
          locationId,
          hasAccess: locationAccess.result
        };
      });

      const locationsWithUserAccess = tempLocations.map((location) => {
        const foundLocationAccessItem = _.find(locationAccessList, {
          locationId: location.id
        });

        if (!_.isEmpty(foundLocationAccessItem)) {
          location.hasAccess = foundLocationAccessItem.hasAccess;
        }

        return location;
      });

      draft.locationsWithUserAccess = locationsWithUserAccess;
      break;
    case LOGOUT:
      return getInitialState();
    default:
  }
}, getInitialState());

export default locationReducer;
