import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { selectCachedFlowById } from '../../../../redux/core/cache/cacheSelectors';

import DisplayWithFallback from '../../view/DisplayWithFallback';

export const CustomFlowWidget = ({ value: flowId }) => {
  const currentFlow = useSelector((store) =>
    selectCachedFlowById(store, flowId)
  );
  const flowName = useMemo(() => _.get(currentFlow, 'name', '/'), [
    currentFlow
  ]);

  return (
    <div className={'read-only-widget'}>
      <DisplayWithFallback displayValue={flowName} />
    </div>
  );
};

CustomFlowWidget.propTypes = {
  /* flowID used for finding the flow in cache */
  value: PropTypes.string
};

CustomFlowWidget.defaultProps = {
  value: null
};

export default CustomFlowWidget;
