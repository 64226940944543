import { useCallback, useState } from 'react';

import produce from 'immer';

/**
 * useImmer - A hook which helps you with object in state
 *
 * @param {Object} initialValue - Initial object value
 *
 * @example
 *  const [person, updatePerson] = useImmer({
 *    name: "Michel",
 *    age: 33
 *  });
 *
 *  updatePerson((draft) => {
 *    draft.name = name;
 *  });
 *
 * @returns {Array}
 */
export const useImmer = (initialValue) => {
  const [val, updateValue] = useState(initialValue);

  const updateCallback = useCallback((updater) => {
    const immutableResult = produce(updater);

    updateValue(immutableResult);
  }, []);

  return [val, updateCallback];
};
