export default {
  language: 'Jezik',
  timezone: 'Časovni pas',
  currency: 'Valuta',
  defaultLanguage: 'Privzeti jezik',
  defaultTimezone: 'Privzeti časovni pas',
  tooltip: {
    defaultLanguage:
      'Nastavitev privzetega jezika za aplikacijo. Vsi uporabniki bodo imeli ta jezik kot privzet (vsak uporabnik lahko še vedno spremeni svoj jezik kasneje).',
    invoiceLanguage: 'Računi bodo privzeto izdani v izbranem jeziku.'
  }
};
