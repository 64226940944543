import produce from 'immer';
import _ from 'lodash';

import { createActionTypeArray } from '../../common/actionTypes';

import { LOGOUT } from '../../auth/authReducer';

export const GET_ENCOUNTER_DOCUMENT_SUBMISSIONS =
  'documentSubmission/GET_ENCOUNTER_DOCUMENT_SUBMISSIONS';
export const UPDATE_ENCOUNTER_DOCUMENT_SUBMISSIONS =
  'documentSubmission/UPDATE_ENCOUNTER_DOCUMENT_SUBMISSIONS';
export const ADD_ENCOUNTER_DOCUMENT_SUBMISSIONS =
  'documentSubmission/ADD_ENCOUNTER_DOCUMENT_SUBMISSIONS';
export const REMOVE_ENCOUNTER_DOCUMENT_SUBMISSIONS =
  'documentSubmission/REMOVE_ENCOUNTER_DOCUMENT_SUBMISSIONS';
export const GET_DOCUMENT_SUBMISSION_DATA =
  'documentSubmission/GET_SUBMISSION_DATA';
export const GET_PATIENT_DOCUMENT_DETAIL =
  'documents/GET_PATIENT_DOCUMENT_DETAIL';
export const CLEAR_DOCUMENT_SUBMISSION_DATA =
  'documents/CLEAR_DOCUMENT_SUBMISSION_DATA';

export const [
  GET_ENCOUNTER_DOCUMENT_SUBMISSIONS_IN_PROGRESS,
  GET_ENCOUNTER_DOCUMENT_SUBMISSIONS_SUCCESS,
  GET_ENCOUNTER_DOCUMENT_SUBMISSIONS_FAILURE
] = createActionTypeArray(GET_ENCOUNTER_DOCUMENT_SUBMISSIONS);

export const [
  GET_DOCUMENT_SUBMISSION_DATA_IN_PROGRESS,
  GET_DOCUMENT_SUBMISSION_DATA_SUCCESS,
  GET_DOCUMENT_SUBMISSION_DATA_FAILURE
] = createActionTypeArray(GET_DOCUMENT_SUBMISSION_DATA);

export const [
  GET_PATIENT_DOCUMENT_DETAIL_IN_PROGRESS,
  GET_PATIENT_DOCUMENT_DETAIL_SUCCESS,
  GET_PATIENT_DOCUMENT_DETAIL_FAILURE
] = createActionTypeArray(GET_PATIENT_DOCUMENT_DETAIL);

const initialState = {
  list: [],
  loadingList: false,
  detail: null,
  loadingDetail: false
};

// eslint-disable-next-line default-param-last
const documentSubmissionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_ENCOUNTER_DOCUMENT_SUBMISSIONS_IN_PROGRESS:
        draft.loadingList = true;
        draft.list = [];
        break;
      case GET_ENCOUNTER_DOCUMENT_SUBMISSIONS_SUCCESS:
        const encounterSubmissions = _.get(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = encounterSubmissions;
        break;
      case GET_ENCOUNTER_DOCUMENT_SUBMISSIONS_FAILURE:
        draft.loadingList = false;
        draft.list = [];
        break;
      case GET_DOCUMENT_SUBMISSION_DATA_IN_PROGRESS:
        draft.loadingDetail = true;
        draft.detail = null;
        break;
      case GET_DOCUMENT_SUBMISSION_DATA_SUCCESS:
        const submission = _.get(payload, 'response.data', null);

        draft.loadingDetail = false;
        draft.detail = submission;
        break;
      case GET_DOCUMENT_SUBMISSION_DATA_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case GET_PATIENT_DOCUMENT_DETAIL_SUCCESS:
        draft.loadingDetail = false;
        draft.detail = _.get(payload, 'response.data', null);
        break;
      case GET_PATIENT_DOCUMENT_DETAIL_IN_PROGRESS:
        draft.loadingDetail = true;
        draft.detail = null;
        break;
      case GET_PATIENT_DOCUMENT_DETAIL_FAILURE:
        draft.loadingDetail = false;
        draft.detail = null;
        break;
      case CLEAR_DOCUMENT_SUBMISSION_DATA:
        draft.detail = null;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default documentSubmissionReducer;
