import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import styles from './HomunculusWidget.module.scss';

import { Col } from '../../../../reactstrap';
import Visible from '../../../layout/Visible';
import Homunculus from './homunculus/Homunculus';

const HomunculusList = ({ fields, selectedParts, setSelectedParts }) =>
  fields.map((field) => {
    const fieldSelectedParts = _.getNonEmpty(selectedParts, field.id, []);

    return (
      <Col
        key={field.id}
        xs={field.homunculusColSize}
        className={styles.homunculusWrapper}
      >
        <Visible visible={!_.isEmptySafe(field, 'title')}>
          <span className={styles.boldText}>
            {`${field.title} (${fieldSelectedParts.length})`}
          </span>
        </Visible>
        <Homunculus
          {...field.homunculusProps}
          initialSelectedParts={fieldSelectedParts}
          className={'margin-top-35'}
          onChange={(newList) => {
            if (!_.isEqual(fieldSelectedParts, newList)) {
              setSelectedParts((draft) => {
                draft[field.id] = newList;
              });
            }
          }}
        />
      </Col>
    );
  });

HomunculusList.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      homunculusColSize: PropTypes.number.isRequired,
      homunculusProps: PropTypes.shape().isRequired,
      title: PropTypes.string
    })
  ).isRequired,
  selectedParts: PropTypes.shape().isRequired,
  setSelectedParts: PropTypes.func.isRequired
};

export default HomunculusList;
