import _ from 'lodash';

import { getNumbroDisplayValue } from '../../components/common/form/I18nNumericInput';

import EntityBaseModel from './EntityBaseModel';

class TaxModel extends EntityBaseModel {
  constructor(id, name = '', abbreviation = '', taxRates = []) {
    super(id);

    this.name = name;
    this.abbreviation = abbreviation;
    this.taxRates = taxRates;
  }

  static get taxRatePath() {
    return 'taxRates.0.rate';
  }

  get displayRate() {
    const rateValue = _.get(this, TaxModel.taxRatePath, 0);

    return getNumbroDisplayValue(rateValue);
  }

  static getPayload(model) {
    let payload = _.cloneDeep(model);

    payload = EntityBaseModel.removeEntityMetadata(payload);

    return payload;
  }
}

export default TaxModel;
