import administrativeData from './administrativeData';
import conclusion from './conclusion';
import diagnosis from './diagnosis';
import documents from './documents';
import healthHistory from './healthHistory';
import indexes from './indexes';
import notesAndSummaries from './notesAndSummaries';
import problemDescription from './problemDescription';
import procedures from './procedures';
import vitalSigns from './vitalSigns';

/**
 * Example `templates`
 * templates: [
 *  {
 *    id: 'template/THYROID_CHECKUP/v1',
 *    name: 'Thyroid checkup',
 *    widgets: [
 *      'vitalSign/HEART_RATE/v1',
 *      'vitalSign/RESPIRATION_RATE/v1',
 *      'problemDescription/SYMPTOMS_BY_DOCTOR/v1',
 *    ]
 *  }
 * ]
 */
export default {
  groups: [
    {
      id: administrativeData.groupId,
      name: administrativeData.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'administrativeData/FLOW/v1' },
        { order: 20, id: 'administrativeData/DATE/v1' },
        { order: 30, id: 'administrativeData/ATTENDING_PHYSICIAN/v1' },
        { order: 40, id: 'administrativeData/TYPE_OF_ENCOUNTER/v1' },
        { order: 50, id: 'administrativeData/REASON_FOR_ENCOUNTER/v1' }
      ]
    },
    {
      id: vitalSigns.groupId,
      name: vitalSigns.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'vitalSign/BODY_HEIGHT/v1' },
        { order: 20, id: 'vitalSign/BODY_MASS/v1' },
        { order: 30, id: 'vitalSign/BODY_TEMPERATURE/v1' },
        { order: 40, id: 'vitalSign/HEART_RATE/v1' },
        { order: 50, id: 'vitalSign/OXYGEN_SATURATION/v1' },
        { order: 60, id: 'vitalSign/RESPIRATION_RATE/v1' },
        { order: 70, id: 'vitalSign/BLOOD_GLUCOSE/v1' },
        { order: 80, id: 'vitalSign/BLOOD_PRESSURE/v1' },
        { order: 90, id: 'vitalSign/BODY_MASS_INDEX/v1' }
      ]
    },
    {
      id: problemDescription.groupId,
      name: problemDescription.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'problemDescription/CHIEF_COMPLAINT/v1' },
        { order: 20, id: 'problemDescription/HISTORY_OF_CHIEF_COMPLAINT/v1' },
        { order: 30, id: 'problemDescription/SYMPTOMS_BY_PATIENT/v1' },
        { order: 40, id: 'problemDescription/SYMPTOMS_BY_DOCTOR/v1' }
      ]
    },
    {
      id: healthHistory.groupId,
      name: healthHistory.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'healthHistory/PAST_ILLNESS/v1' },
        { order: 20, id: 'healthHistory/CURRENT_ILLNESS/v1' },
        { order: 30, id: 'healthHistory/CHRONIC_CONDITIONS/v1' },
        { order: 40, id: 'healthHistory/SURGICAL_HISTORY/v1' },
        { order: 50, id: 'healthHistory/FAMILY_HISTORY/v1' },
        { order: 60, id: 'healthHistory/CHILD_DISEASES/v1' },
        { order: 70, id: 'healthHistory/CHIEF/v1' },
        { order: 80, id: 'healthHistory/ALLERGIES/v1' },
        { order: 90, id: 'healthHistory/SEXUAL_HISTORY/v1' },
        { order: 100, id: 'healthHistory/SOCIO_ECONOMIC_DATA/v1' },
        { order: 110, id: 'healthHistory/ENVIRONMENTAL_DATA/v1' }
      ]
    },
    {
      id: procedures.groupId,
      name: procedures.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'procedures/UNDERTAKEN/v1' },
        { order: 20, id: 'procedures/TESTS_UNDERTAKEN/v1' }
      ]
    },
    {
      id: diagnosis.groupId,
      name: diagnosis.groupName,
      systemWidgetGroup: true,
      widgets: [{ order: 10, id: 'diagnosis/DESCRIPTION/v1' }]
    },
    {
      id: conclusion.groupId,
      name: conclusion.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'conclusion/TREATMENTS/v1' },
        { order: 20, id: 'conclusion/MEDICATION/v1' }
      ]
    },
    {
      id: notesAndSummaries.groupId,
      name: notesAndSummaries.groupName,
      systemWidgetGroup: true,
      widgets: [
        { order: 10, id: 'notesAndSummaries/FOR_PATIENT/v1' },
        { order: 20, id: 'notesAndSummaries/DISCHARGE/v1' },
        { order: 30, id: 'notesAndSummaries/PERSONAL/v1' }
      ]
    },
    {
      id: documents.groupId,
      name: documents.groupName,
      systemWidgetGroup: true,
      widgets: [{ order: 10, id: 'attachedDocuments/EXTERNAL_DOCUMENTS/v1' }]
    }
  ],
  templates: [],
  widgets: {
    ...vitalSigns.widgets,
    ...administrativeData.widgets,
    ...conclusion.widgets,
    ...diagnosis.widgets,
    ...documents.widgets,
    ...healthHistory.widgets,
    ...problemDescription.widgets,
    ...notesAndSummaries.widgets,
    ...procedures.widgets,
    ...indexes.widgets
  },
  exportSchema: [
    {
      id: 'diagnosis/DESCRIPTION/v1',
      order: 50,
      template: '{{> widgetExportStandard }}'
    },
    {
      id: 'problemDescription/CHIEF_COMPLAINT/v1',
      order: 40,
      template: '{{> widgetExportStandard }}'
    },
    {
      id: 'administrativeData/TYPE_OF_ENCOUNTER/v1',
      order: 30,
      template: `<p>
        <strong>{{uppercase (translate schema.properties.id001.title) }}: </strong>
        {{findEnum data.id001 schema.properties.id001.enum schema.properties.id001.enumNames }}
      </p>`
    },
    {
      id: 'administrativeData/ATTENDING_PHYSICIAN/v1',
      order: 20,
      template: `<p>
        <strong>{{uppercase (translate schema.properties.id001.title) }}: </strong>
        {{ encounter.doctor.firstName }} {{ encounter.doctor.lastName }}
      </p>`
    },
    {
      id: 'administrativeData/FLOW/v1',
      order: 10,
      template: `<p>
        <strong>{{uppercase (translate schema.properties.id001.title) }}: </strong>
        {{ encounter.flow.name }}
      </p>`
    },
    {
      id: 'vitalSign/BLOOD_PRESSURE/v1',
      order: 70,
      template: `<p><strong>{{uppercase (translate schema.properties.id001.title) }}: </strong>{{ data.id001 }}/{{ data.id002 }} {{ schema.measurement }}</p>`
    },
    {
      id: 'vitalSign/BODY_HEIGHT/v1',
      order: 60,
      template: `<p><strong>{{uppercase (translate schema.properties.id001.title) }}: </strong>{{ data.id001 }} {{ schema.measurement }}</p>`
    }
  ],
  defaultWidgetsIds: [
    'administrativeData/ATTENDING_PHYSICIAN/v1',
    'administrativeData/DATE/v1',
    'administrativeData/FLOW/v1'
  ]
};
