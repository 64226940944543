import { createActionTypeArray } from '../../common/actionTypes';

export const CLEAR_PATIENT_ANAMNESIS_LIST_FILTER =
  'patients/CLEAR_PATIENT_ANAMNESIS_LIST_FILTER';
export const SET_PATIENT_ANAMNESIS_LIST_FILTER =
  'patients/SET_PATIENT_ANAMNESIS_LIST_FILTER';

export const GET_PATIENT_ANAMNESIS_LIST = 'patients/GET_PATIENT_ANAMNESIS_LIST';

export const ADD_PATIENT_ANAMNESIS = 'patients/ADD_PATIENT_ANAMNESIS';
export const UPDATE_PATIENT_ANAMNESIS = 'patients/UPDATE_PATIENT_ANAMNESIS';
export const REMOVE_PATIENT_ANAMNESIS = 'patients/REMOVE_PATIENT_ANAMNESIS';

export const GET_PINNED_PATIENT_ANAMNESIS_LIST =
  'patients/GET_PINNED_PATIENT_ANAMNESIS_LIST';
export const CLEAR_PATIENT_ANAMNESIS_LIST =
  'patients/CLEAR_PATIENT_ANAMNESIS_LIST';
export const CLEAR_UPDATED_PATIENT_ANAMNESIS_ID =
  'patients/CLEAR_UPDATED_PATIENT_ANAMNESIS_ID';

export const [
  GET_PATIENT_ANAMNESIS_LIST_IN_PROGRESS,
  GET_PATIENT_ANAMNESIS_LIST_SUCCESS,
  GET_PATIENT_ANAMNESIS_LIST_FAILURE
] = createActionTypeArray(GET_PATIENT_ANAMNESIS_LIST);

export const [
  ADD_PATIENT_ANAMNESIS_IN_PROGRESS,
  ADD_PATIENT_ANAMNESIS_SUCCESS,
  ADD_PATIENT_ANAMNESIS_FAILURE
] = createActionTypeArray(ADD_PATIENT_ANAMNESIS);

export const [
  UPDATE_PATIENT_ANAMNESIS_IN_PROGRESS,
  UPDATE_PATIENT_ANAMNESIS_SUCCESS,
  UPDATE_PATIENT_ANAMNESIS_FAILURE
] = createActionTypeArray(UPDATE_PATIENT_ANAMNESIS);

export const [
  REMOVE_PATIENT_ANAMNESIS_IN_PROGRESS,
  REMOVE_PATIENT_ANAMNESIS_SUCCESS,
  REMOVE_PATIENT_ANAMNESIS_FAILURE
] = createActionTypeArray(REMOVE_PATIENT_ANAMNESIS);

export const [
  GET_PINNED_PATIENT_ANAMNESIS_LIST_IN_PROGRESS,
  GET_PINNED_PATIENT_ANAMNESIS_LIST_SUCCESS,
  GET_PINNED_PATIENT_ANAMNESIS_LIST_FAILURE
] = createActionTypeArray(GET_PINNED_PATIENT_ANAMNESIS_LIST);
