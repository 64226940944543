import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { translateKey } from '../../i18n';
import { getDateDifference } from '../../utils/date';
import { moshiConnect } from '../../utils/redux';

import { getPatientListToCache } from '../../redux/patient/patientActions';

import { selectPatientDetailLoading } from '../../redux/patient/patientSelectors';

import Icon from '../../components/common/Icon';
import InitialsBadge from '../../components/common/InitialsBadge';
import Visible from '../../components/common/layout/Visible';
import MoshiNavLink from '../../components/common/navigation/MoshiNavLink';
import DisplayFullName from '../../components/common/view/DisplayFullName';

export const getReadablePersonalData = (
  patientData,
  displayAddress = false
) => {
  if (_.isEmpty(patientData)) {
    return;
  }

  const data = _.getNonEmpty(patientData, 'identification', patientData);
  const address = _.getNonEmpty(patientData, 'contact.address', {});
  const diff = getDateDifference(data.dateOfBirth);
  const age = diff.count;
  const unit = translateKey(`date:${diff.unit}`).charAt(0);
  const gender = translateKey(
    `common:person.genderTypes.${data.gender}`,
    '/'
  ).charAt(0);
  const email = _.getNonEmpty(patientData, 'contact.email');
  const phoneNumber = _.getNonEmpty(patientData, 'contact.phoneNumber');

  let oneLineData = {
    dateOfBirth: new Date(data.dateOfBirth),
    age,
    unit,
    gender,
    email,
    phoneNumber
  };

  if (!data.dateOfBirth) {
    oneLineData = {
      ...oneLineData,
      dateOfBirth: '/',
      age: '',
      unit: '/'
    };
  }

  if (displayAddress) {
    oneLineData = {
      ...oneLineData,
      address
    };
  }

  return oneLineData;
};

export const PatientInfoMiniCardComponent = ({
  getPatientDispatch,
  patientId,
  fetchPatient,
  loadingData,
  patientData,
  horizontal,
  displayAddress,
  displayEmail,
  displayPhoneNumber,
  displayLinkToProfile
}) => {
  const { t: translate } = useTranslation();
  const [prevPatientId, setPrevPatientId] = useState(null);

  useEffect(() => {
    if (prevPatientId !== patientId && fetchPatient) {
      getPatientDispatch(patientId);
      setPrevPatientId(patientId);
    }
  }, [patientData, patientId, prevPatientId, getPatientDispatch, fetchPatient]);

  if (loadingData || _.isEmpty(patientData)) {
    return null;
  }

  const oneLineData = getReadablePersonalData(patientData, displayAddress);
  const addressTranslation = () => {
    const street1 = _.getNonEmpty(oneLineData, 'address.street1', '');
    const zip = _.getNonEmpty(oneLineData, 'address.zip', '');
    const town = _.getNonEmpty(oneLineData, 'address.town', '');

    if (street1 && zip && town) {
      return translate(
        'patient:infoDisplay.miniCardInfoWithAddress',
        oneLineData
      );
    }

    return translate(
      'patient:infoDisplay.miniCardInfoShortBirthDate',
      oneLineData
    );
  };

  return (
    <div className={'patient-info-mini-card'}>
      <InitialsBadge
        person={patientData}
        className={classNames({
          horizontal
        })}
      />
      <div className={'patient-details'}>
        <div
          className={classNames('full-name-line font-weight-bold', {
            horizontal
          })}
        >
          <DisplayFullName
            person={patientData}
            emptyText={translate('common:missingObject.noPatient')}
          />
        </div>
        <div
          className={classNames('one-line-data', {
            'font-weight-bold': !horizontal
          })}
        >
          <Visible visible={!displayAddress}>
            {translate('patient:infoDisplay.miniCardInfo', oneLineData)}
          </Visible>
          <Visible visible={displayAddress}>{addressTranslation()}</Visible>
        </div>
        {displayEmail && (
          <div className={'tw-text-md tw-text-black'}>
            {_.getNonEmpty(patientData, 'contact.email', '/')}
          </div>
        )}
        {displayPhoneNumber && (
          <div className={'tw-text-md tw-text-black'}>
            {_.getNonEmpty(patientData, 'contact.phoneNumber', '/')}
          </div>
        )}
      </div>
      <Visible visible={displayLinkToProfile}>
        <div className={'link-to-profile'}>
          <MoshiNavLink to={`/patients/${patientId}`}>
            <Icon name={'user'} className={'user-icon'} />
            <span>{translate('patient:infoDisplay.openProfile')}</span>
          </MoshiNavLink>
        </div>
      </Visible>
    </div>
  );
};

PatientInfoMiniCardComponent.propTypes = {
  getPatientDispatch: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,
  patientId: PropTypes.string,
  patientData: PropTypes.shape(),
  horizontal: PropTypes.bool,
  fetchPatient: PropTypes.bool,
  displayAddress: PropTypes.bool,
  displayEmail: PropTypes.bool,
  displayPhoneNumber: PropTypes.bool,
  displayLinkToProfile: PropTypes.bool
};

PatientInfoMiniCardComponent.defaultProps = {
  patientData: {},
  patientId: null,
  horizontal: false,
  fetchPatient: true,
  displayAddress: false,
  displayEmail: false,
  displayPhoneNumber: false,
  displayLinkToProfile: false
};

const mapStateToProps = (store, ownProps) => {
  const patientId = _.getNonEmpty(ownProps, 'patientId', {});
  const ownPatientData = _.getNonEmpty(ownProps, 'patientData', {});
  const safePatientId = _.getNonEmpty(ownPatientData, 'id', patientId);
  const cachedPatientList = _.getNonEmpty(
    store,
    'patient.cachedPatient.patientList'
  );

  const patientDetail = _.getNonEmpty(cachedPatientList, safePatientId);
  const loadingData = selectPatientDetailLoading(store);

  const patientData = _.isEmpty(ownPatientData)
    ? patientDetail
    : ownPatientData;

  return {
    loadingData,
    patientData
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPatientDispatch: getPatientListToCache
    },
    dispatch
  );

export default moshiConnect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInfoMiniCardComponent);
