import { push } from 'connected-react-router';

import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  showMissingLocationIdError,
  showMissingOrganizationIdError,
  showAlertSuccess
} from '../../../utils/alert';
import { getOrganizationUrl } from '../../../utils/api';
import {
  BILL_TYPE_INVOICE,
  getDateFilterParametersPayload,
  INVOICE_STATUS_DRAFT,
  isBillFiscalized
} from '../../../utils/billing';
import { EMPTY_GUID } from '../../../utils/gen';

import { patchPatient } from '../../patient/patientActions';
import { clearBillItemDescription } from '../bill/billActions';
import {
  getBillKeywordData,
  updateUserPreferredBillDataInput
} from '../billingActions';

import {
  GET_INVOICE_LIST,
  SET_INVOICE_LIST_FILTER,
  SET_INVOICE_LIST_FILTERS,
  SET_INVOICE_LIST_ORDER,
  GET_INVOICE_DETAIL,
  UPDATE_INVOICE,
  PATCH_INVOICE,
  ADD_INVOICE,
  VOID_INVOICE,
  REMOVE_INVOICE,
  SET_INVOICE_LIST_PAGINATION,
  ADD_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENTS_LIST,
  CLEAR_INVOICE_LIST_FILTER,
  CLEAR_INVOICE_LIST_FILTER_NO_POLL,
  CLEAR_INVOICE_DETAIL,
  CLEAR_INVOICE_PAYMENTS_LIST,
  REMOVE_INVOICE_PAYMENT,
  RECHECK_INVOICE_FURS_STATUS,
  SEND_INVOICE_VIA_EMAIL,
  SET_INVOICE_LIST_INCLUDE_PARAM
} from './invoiceTypes';

import { selectCachedOrganizationId } from '../../core/cache/cacheSelectors';
import { selectCurrentLocationId } from '../../location/locationSelectors';
import { selectDefaultBillingLocale } from '../../organization/organizationSelectors';
import {
  selectInvoiceDetail,
  selectInvoiceFilter,
  selectInvoiceIncludeParam,
  selectInvoicePagination
} from './invoiceSelectors';

import InvoiceModel from '../../../metadata/model/billing/bill/InvoiceModel';

export const clearInvoiceDetail = () => (dispatch, getState) => {
  const bill = selectInvoiceDetail(getState());
  const billId = _.getNonEmpty(bill, 'id', EMPTY_GUID);

  dispatch(clearBillItemDescription(billId));

  return dispatch({
    type: CLEAR_INVOICE_DETAIL
  });
};

export const clearInvoicePaymentList = () => (dispatch) =>
  dispatch({
    type: CLEAR_INVOICE_PAYMENTS_LIST
  });

export const resetInvoiceStoreData = () => (dispatch) => {
  dispatch(clearInvoiceDetail());
  dispatch(clearInvoicePaymentList());
};

export const getInvoiceList = () => (dispatch, getState) => {
  const store = getState();
  const organizationUrl = getOrganizationUrl(() => store);
  const include = selectInvoiceIncludeParam(store);
  const { limit, page } = selectInvoicePagination(store);

  let customFilter = selectInvoiceFilter(store);

  customFilter = getDateFilterParametersPayload(customFilter);

  return dispatch({
    type: GET_INVOICE_LIST,
    apiCall: axios.get(`${organizationUrl}/billing/invoices`, {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    })
  });
};

export const getInvoice = (invoiceId, actionType = GET_INVOICE_DETAIL) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: actionType,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}`
    ),
    onSuccess: () => {
      dispatch(getBillKeywordData(BILL_TYPE_INVOICE));
    }
  });
};

export const setInvoiceListFilter = (filterName, filterValue) => (dispatch) =>
  dispatch({
    type: SET_INVOICE_LIST_FILTER,
    filterName,
    filterValue
  });

export const setInvoiceListFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_LIST_FILTERS,
    filters
  });
};

export const setInvoiceListIncludeParam = (includeValue) => (dispatch) =>
  dispatch({
    type: SET_INVOICE_LIST_INCLUDE_PARAM,
    includeValue
  });

export const setInvoiceListOrder = (sortBy, order) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_LIST_ORDER,
    sortBy,
    order
  });
};

export const setInvoiceListPagination = (paginationName, paginationValue) => (
  dispatch
) => {
  dispatch({
    type: SET_INVOICE_LIST_PAGINATION,
    paginationName,
    paginationValue
  });
};

export const addInvoice = (invoice, customListAction, formId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const locationId = selectCurrentLocationId(state);

  if (_.isEmpty(locationId)) {
    return dispatch(showMissingLocationIdError());
  }

  const payload = InvoiceModel.getPayload(invoice, defaultLocale);

  payload.locationID = locationId;
  // A unique form id that is generated when we open the add invoice form.
  payload.formID = formId;

  return dispatch({
    type: ADD_INVOICE,
    apiCall: axios.post(`${organizationUrl}/billing/invoices`, payload),
    onSuccess: () => {
      const isDraft = _.get(payload, 'draft', false);

      showAlertSuccess(
        `billing:invoices.alerts.${isDraft ? 'draftCreated' : 'invoiceCreated'}`
      );

      dispatch(updateUserPreferredBillDataInput(invoice));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getInvoiceList());
    }
  });
};

export const updateInvoice = (invoice, customListAction) => (
  dispatch,
  getState
) => {
  const state = getState();

  const organizationUrl = getOrganizationUrl(() => state);
  const defaultLocale = selectDefaultBillingLocale(state);
  const payload = InvoiceModel.getPayload(invoice, defaultLocale);

  return dispatch({
    type: UPDATE_INVOICE,
    apiCall: axios.put(
      `${organizationUrl}/billing/invoices/${invoice.id}`,
      payload
    ),
    onSuccess: () => {
      const isDraft = _.get(payload, 'draft', false);

      showAlertSuccess(
        `billing:invoices.alerts.${isDraft ? 'draftUpdated' : 'invoiceUpdated'}`
      );
      dispatch(updateUserPreferredBillDataInput(invoice));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getInvoiceList());
    }
  });
};

export const addOrUpdateInvoice = (invoice, customListAction, formId) => (
  dispatch
) => {
  let promiseObject;

  if (_.isEmpty(invoice.id)) {
    promiseObject = dispatch(addInvoice(invoice, customListAction, formId));
  } else {
    promiseObject = dispatch(updateInvoice(invoice, customListAction));
  }

  return promiseObject;
};

export const patchInvoice = (
  invoiceId,
  patchedFields,
  customListAction = undefined
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: PATCH_INVOICE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/reassign`,
      patchedFields
    ),
    onSuccess: () => {
      showAlertSuccess(`billing:invoices.alerts.invoiceUpdated`);

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getInvoiceList());
    }
  });
};

export const removeInvoice = (invoiceId, basePath) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_INVOICE,
    apiCall: axios.delete(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}`
    ),
    onSuccess: () => {
      showAlertSuccess(`billing:invoices.alerts.draftRemoved`);
      dispatch(push(basePath));
      dispatch(clearInvoiceDetail());
    }
  });
};

export const voidInvoice = (invoiceId, isFiscalized = false) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: VOID_INVOICE,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/cancel`
    ),
    onSuccess: () => {
      showAlertSuccess(
        `billing:invoices.alerts.${
          isFiscalized ? 'invoiceVoided' : 'invoiceRemoved'
        }`
      );
    }
  });
};

export const voidOrRemoveInvoice = (invoice, basePath, customListAction) => (
  dispatch
) => {
  let promiseObject;
  const isDraft = _.includes(invoice.status, INVOICE_STATUS_DRAFT);

  if (isDraft) {
    promiseObject = dispatch(removeInvoice(invoice.id, basePath));
  } else {
    promiseObject = dispatch(
      voidInvoice(invoice.id, isBillFiscalized(invoice))
    );
  }

  promiseObject.then(() => {
    if (_.isFunction(customListAction)) {
      dispatch(customListAction());
    } else {
      dispatch(getInvoiceList());
    }
  });

  return promiseObject;
};

export const getInvoicePayments = (invoiceId) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: GET_INVOICE_PAYMENTS_LIST,
    apiCall: axios.get(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/payments`
    )
  });
};

export const addInvoicePayment = (invoiceId, payload, customListAction) => (
  dispatch,
  getState
) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  payload.date = moment(payload.date).format('YYYY-MM-DD');

  return dispatch({
    type: ADD_INVOICE_PAYMENT,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/payments`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.paymentAdded');
      dispatch(getInvoicePayments(invoiceId));
      dispatch(getInvoice(invoiceId));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getInvoiceList());
    }
  });
};

export const removeInvoicePayment = (
  invoiceId,
  paymentId,
  customListAction
) => (dispatch, getState) => {
  const organizationId = _.get(
    getState(),
    'authentication.token.organizationID',
    null
  );

  if (!organizationId) {
    return dispatch(showMissingOrganizationIdError());
  }

  return dispatch({
    type: REMOVE_INVOICE_PAYMENT,
    apiCall: axios.delete(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/payments/${paymentId}`
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.paymentRemoved');
      dispatch(getInvoicePayments(invoiceId));
      dispatch(getInvoice(invoiceId));

      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getInvoiceList());
    }
  });
};

export const recheckInvoiceFursData = (invoiceId, customListAction) => (
  dispatch,
  getState
) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  return dispatch({
    type: RECHECK_INVOICE_FURS_STATUS,
    apiCall: axios.put(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/recheckFursData`
    ),
    onSuccess: () => {
      if (_.isFunction(customListAction)) {
        return dispatch(customListAction());
      }

      return dispatch(getInvoiceList());
    }
  });
};

export const createInvoiceFromDraft = (invoiceId, customListAction) => (
  dispatch
) =>
  dispatch(getInvoice(invoiceId)).then((itemDetail) =>
    dispatch(
      addOrUpdateInvoice(InvoiceModel.fromDto(itemDetail), customListAction)
    )
  );

export const sendInvoiceViaEmail = (
  invoiceId,
  email,
  note = undefined,
  patientIdToUpdate = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const payload = { email, note };

  return dispatch({
    type: SEND_INVOICE_VIA_EMAIL,
    apiCall: axios.post(
      `/organizations/${organizationId}/billing/invoices/${invoiceId}/sendPdf`,
      payload
    ),
    onSuccess: () => {
      showAlertSuccess('billing:invoices.alerts.pdfSentViaEmail');

      if (!_.isEmpty(patientIdToUpdate) && patientIdToUpdate !== EMPTY_GUID) {
        dispatch(patchPatient(patientIdToUpdate, { contact: { email } }));
      }
    }
  });
};

export const clearInvoiceListFilters = (refetchInvoiceList = true) => (
  dispatch
) => {
  if (refetchInvoiceList) {
    dispatch({ type: CLEAR_INVOICE_LIST_FILTER });
  } else {
    dispatch({ type: CLEAR_INVOICE_LIST_FILTER_NO_POLL });
  }
};
