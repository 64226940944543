export default {
  confirmEntityRemoval: {
    title: 'Remove {{ entityName }}',
    message:
      'Are you sure you want to remove this {{ entityName }}: <0>{{ itemName }}</0>?'
  },
  confirmItemRemoval: {
    message: 'Are you sure you want to remove: <0>{{ itemName }}</0>?'
  },
  confirmEventUpdate: {
    message:
      'Are you sure you want to update event time for: <0>{{ eventTitle }}</0>?'
  },
  confirmEventQueueUpdate: {
    message: 'Are you sure you want to add the event: <0>{{ eventTitle }}</0>?',
    title: 'Add patient from waiting list'
  },
  confirmDiscardUnsaved: {
    title: 'Warning: Unsaved changes',
    message:
      'You have unsaved changes. You can discard the changes and continue or go back.',
    discardButton: 'Discard changes and continue'
  },
  supportModal: {
    title: 'Support',
    message: 'For help turn to:'
  }
};
