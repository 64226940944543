export default {
  title: {
    settings: 'Nastavitve',
    account: 'Nastavitve organizacije',
    calendar: 'Nastavitve koledarja',
    billing: 'Nastavitve računov',
    automatedCheckIn: 'Registracija prihoda'
  },
  billing: {
    billingUnit: {
      title: 'Podjetje'
    },
    category: {
      title: 'Kategorija',
      list: 'Kategorije storitev',
      entity: 'kategorijo',
      add: 'Dodajte $t(settings:billing.category.entity)',
      edit: 'Uredite $t(settings:billing.category.entity)',
      name: 'Ime kategorije'
    },
    payments: {
      title: 'Načini plačila',
      paymentType: 'Način plačila',
      add: 'Dodajte način plačila',
      new: 'Nov način plačila'
    },
    localization: {
      title: 'Lokalizacija'
    },
    locale: {
      title: 'Prevodi',
      basicSettingsTitle: 'Osnovne nastavitve',
      setDefault: 'Nastavite kot privzeti'
    }
  },
  notifications: {
    SMSnotifications: 'SMS obveščanje',
    newAppointmentAddedSMSTemplate: 'Predloga za nov pregled',
    appointmentRescheduledSMSTemplate: 'Predloga za prestavljen preged',
    appointmentReminderSMSTemplate: 'Predloga za opomnik',
    customAppointmentMessageSMSTemplate: 'Predloga po meri',
    rsvp: 'Zahtevek za potrditev prihajajočega termina (RSVP)',
    rsvpText:
      'To besedilo bo poslano skupaj z opomnikom za prihajajoči termin kot dodatek na koncu.',
    rsvpTooltipText: 'Če želite spremeniti to besedilo, kontaktirajte podporo.',
    rsvpResponseConfirmedText:
      'To besedilo bo poslano, če pacient potrdi prihajajoči termin.',
    rsvpResponseCanceledText:
      'To besedilo bo poslano, če pacient zavrne prihajajoči termin.',
    notifyPatientWhenRescheduling: 'Ob opomniku zahtevajte potrditev',
    reminderPeriods: 'Čas obveščanja',
    senderName: 'Ime pošiljatelja sporočila',
    senderPhoneNumber: 'Telefonska številka pošiljatelja sporočila',
    send: 'Pošlji',
    firstReminder: 'Prvi opomnik',
    secondReminder: 'Drugi opomnik',
    sendRequestConfirmation: 'Pošljite zahtevek za potrditev',
    addNewReminder: 'Dodajte nov opomnik',
    modal: {
      title: 'Zahtevek za potrditev',
      description: `
        <0>Poleg opomnika za prihajajoči termin bo pacientu poslana tudi zahteva 
        za potrditev termina. Prejemnik bo z odgovorom na prejeto sporočilo 
        lahko potrdil ali zavrnil termin, aplikacija pa bo samodejno posodobila 
        status termina na “Potrjen” ali “Preklican”.</0>
        <0>Če se pacient na zahtevo za potrditev ne odzove, status termina ostane nespremenjen.</0>
        `
    },
    periods: {
      oneHour: '1 ura prej',
      twelweHours: '12 ur prej',
      oneDay: '1 dan prej',
      twoDays: '2 dni prej',
      sevenDays: '7 dni prej',
      fourteenDays: '14 dni prej'
    },
    validation: {
      duplicatedTimePeriod: 'Čas opomnikov ne sme biti podvojen'
    }
  },
  automatedCheckIn: {
    title: 'Registracija prihoda',
    tabs: {
      devices: 'Naprave',
      greetingScreen: 'Pozdravna stran',
      signInFields: 'Vpisna polja',
      documentsAndQuestionnaires: 'Dokumenti & Vprašalniki',
      finalScreen: 'Zaključna stran'
    },
    greetingScreen: {
      welcomeGraphic: {
        title: 'Pozdravna slika',
        description:
          'Izberite sliko, ki se bo pojavila na uvodnem zaslonu. Priporočamo sliko v velikosti 1000 x 1000 px.',
        changeImage: 'Zamenjajte sliko'
      },
      titleComment: {
        title: 'Naslov in nagovor',
        description:
          'Vpišite naslov in nagovor, s katerim želite pozdravitit svoje obiskovalce.',
        commentLabel: 'Nagovor'
      },
      buttonColor: {
        title: 'Barva gumbov',
        description:
          'Izberite barvo, ki se bo pojavila kot podlaga gumbov in kot detajl izbranih polj.',
        buttonText: 'Spremenite barvo'
      }
    },
    finalScreen: {
      titleComment: {
        commentLabel: 'Zaključek'
      }
    },
    documentsAndQuestionnaires: {
      editDocumentsAndQuestionnaires: 'Uredite dokumente in vprašalnike',
      mandatoryIf: 'če je izbrana opcija {{ flowName }}',
      mandatory: 'Obvezno ',
      addDocumentOrQuestionnaire: 'Dodajte dokument ali vprašalnik'
    },
    signInFields: {
      newPatient: {
        title: 'Nov pacient',
        addFields: 'Dodajte vpisno polje',
        fields: {
          name: 'Ime in priimek',
          email: 'E-naslov',
          phoneNumber: 'Telefonska številka',
          dateOfBirth: 'Datum rojstva',
          address: 'Naslov',
          gender: 'Spol'
        }
      },
      existingPatient: {
        title: 'Obstoječi pacient',
        expressSignIn: 'Hitri vpis',
        expressSignInDescription:
          'Potrebna je le kombinacija elektronskega naslova (ali telefonske številke) ter datuma rojstva.',
        fullSignIn: 'Poln vpis',
        fullSignInDescription: 'Enako kot ob prvem vpisu'
      }
    },
    devices: {
      title: 'Naprave',
      subTitle: 'Vse naprave, ki so povezane z vašim sistemom.',
      deviceImageAlt: 'Slika naprave',
      status: {
        online: 'Povezana',
        offline: 'Nepovezana',
        disconnected: 'Brez povezave'
      },
      addDevice: 'Dodajte napravo',
      removeDevice: 'Odstranite napravo',
      removeModal: {
        title: 'Želite odstraniti napravo {{deviceName}}?',
        remove: 'Odstranite',
        tabletAlt: 'Slika tabličnega računalnika z rdečim križcem'
      },
      device: {
        type: 'Tip naprave',
        version: 'iOS verzija',
        wiFiNetwork: 'Wi-Fi omrežje',
        cellularNetwork: 'Telefonsko omrežje',
        ipAddress: 'IP naslov',
        appVersion: 'Iryo Moshi verzija'
      },
      addDeviceModal: {
        title: 'Dodajte novo napravo',
        download: {
          title: '1. Naložite Iryo Moshi mobilno aplikacijo',
          stepOne:
            'Na iPadu ali Android tablici odprite App Store ali Play Store in poiščite aplikacijo Iryo Moshi.',
          stepTwo:
            'Do aplikacije lahko pridete tudi tako, da s kamero skenirate QR kodo na desni.',
          imageAlt:
            'Slika QR kode, ki vas odpelje na Iryo Moshi applikacijo na App Storu.'
        },
        otp: {
          title: '2. Vnesite kodo',
          stepOne: 'Na iPadu odprite aplikacijo Iryo Moshi.',
          stepTwo: 'Pritisnite na gumb: "Imam Iryo Moshi račun".',
          stepThree:
            'V spodnje okno vpišite kodo, ki se je prikazala na iPadu.',
          enterCodePlaceholder: 'Vpišite 6-mestno kodo',
          imageAlt: 'Slika iPada z vzorčno kodo',
          deviceOtp: '6-mestna koda'
        },
        location: {
          title: '3. Izberite lokacijo',
          dropdownPlaceholder: 'Izberite lokacijo, kjer bo naprava nameščena.'
        },
        success: {
          title: '{{deviceName}} uspešno povezan.',
          tabletAlt: 'Slika tabličnega računalnika z ok simbolom'
        }
      }
    }
  },
  account: {
    title: 'Organizacija',
    locationTab: 'Lokacije',
    location: 'Lokacija',
    notificationTab: 'Sporočanje',
    organizationTitle: 'Podatki podjetja',
    organizationLabel: 'Ime podjetja',
    logoLabel: 'Logotip',
    logoDescription:
      'Naložite logotip, ki so bo pojavil v mobilni aplikaciji in v elektronski pošti. Priporočamo sliko v velikosti 1000 x 1000 px.',
    changeLogoButton: 'Zamenjate sliko',
    deleteLogoButton: 'Odstranite',
    locationsDescription: 'Imena in naslovi vseh poslovalnic podjetja.',
    locationRemoveButton: 'Odstranite lokacijo',
    addLocation: 'Dodajte lokacijo',
    locationNameLabel: 'Ime lokacije',
    removeLocationTitle: 'Odstranite lokacijo',
    removeLocationMessage:
      'Želite odstraniti lokacijo: <0>{{ locationName }}</0>?',
    companyId: 'Matična številka',
    companyIdPlaceholder: '123456789',
    taxNumber: 'Davčna številka',
    taxNumberInput: '$t(settings:account.taxNumber): {{ taxNumber }}',
    taxIdInput: '$t(billing:settings.general.taxId): {{ taxNumber }}',
    taxNumberPlaceholder: 'SI26146665',
    website: 'Spletna stran',
    websitePlaceholder: 'https://www.google.si'
  },
  documents: {
    title: 'Nastavitve dokumentov',
    header: 'Glava',
    footer: 'Noga',
    description:
      'Glave in noge so zgornji in spodnji del dokumenta. ' +
      'So ločeni odseki glavnega dokumenta in se pogosto uporabljajo za opombe, številke strani, naslove in druge informacije. ' +
      'Informacije v glavi ali nogi se ponovijo v vsaki glavi ali nogi v dokumentu.',
    legend: `
    <0>Legenda</0>
    <1>
      Sledeče pametne bližnjice lahko vstavite v tekst in samodejno
      bodo zamenjane za pripadajoči podatek:
    </1>
    <1>[current date] - današnji datum</1>
    <1>[document title] - naslov dokumenta</1>
    <1>[patient full name] - polno ime pacienta</1>
    <1>[patient first name] - ime pacienta</1>  
    <1>[patient last name] - priimek pacienta</1>     
    <1>[patient gender] - pacientov spol</1>
    <1>[patient dob] - datum rojstva pacienta</1>
    <1>[patient full address] - pacientov celoten naslov (ulica, poštna številka, kraj, država)</1>
    <1>[patient street] - ulica pacienta</1>
    <1>[patient zip] - poštna številka pacienta</1>
    <1>[patient city] - kraj pacienta</1>
    <1>[patient country] - država pacienta</1>
    <1>[patient email] - pacientov email</1>  
    <1>[patient phone number] - pacientova telefonska številka</1>
    <1>[patient insurance] - pacientova številka zavarovanja</1>
    <1>[patient next appointment date] - pacientov prihajajoči termin</1>
    <1>[patient last appointment date] - pacientov zadnji termin</1>
    <1>[organization name] - ime organizacije</1>
    <1>[organization address] - naslov organizacije</1>
    <1>[organization address2] - drugi naslov organizacije</1>
    <1>[organization zip] - poštna številka organizacije</1>
    <1>[organization city] - kraj organizacije</1>
    <1>[organization country] - država organizacije</1>  
    <1>[organization phone number] - telefonska številka organizacije</1>  
    <1>[organization email] - email organizacije</1>  
    `,
    alert: {
      saved: 'Nastavitve dokumentov so bile uspešno shranjene.'
    }
  }
};
