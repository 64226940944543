import _ from 'lodash';

import EntityBaseModel from '../EntityBaseModel';

class BillingSettingsModel {
  constructor(currency = '', defaultLocale = '', supportedLocales = ['si']) {
    this.currency = currency;
    this.defaultLocale = defaultLocale;
    this.supportedLocales = supportedLocales;
  }

  static getEmptyModel() {
    return new BillingSettingsModel('', '', []);
  }

  static getPayload(model) {
    let payload = _.cloneDeep(model);

    payload = EntityBaseModel.removeEntityMetadata(payload);

    if (_.has(payload, 'billingSettings.invoicingID')) {
      delete payload.invoicingID;
    }

    return payload;
  }
}

export default BillingSettingsModel;
