import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { WIDGET_CALCULATION_ERRORS } from '../../../../../utils/constants/widgetConstants';

import CalculationSimpleErrorMessage from './errors/CalculationSimpleErrorMessage';
import NoFormValueError from './errors/NoFormValueError';
import NoHistoryWidgetValueError from './errors/NoHistoryWidgetValueError';
import NoWidgetValueError from './errors/NoWidgetValueError';

const CustomCalculationErrorWidget = ({ value, formContext }) => {
  const firstError = _.first(value);
  const widgetType = _.getNonEmpty(formContext, 'widget.widgetType');

  switch (firstError.error) {
    case WIDGET_CALCULATION_ERRORS.NO_FORM_VALUE:
      return <NoFormValueError {...firstError} widgetType={widgetType} />;

    case WIDGET_CALCULATION_ERRORS.NO_WIDGET_VALUE:
      return <NoWidgetValueError {...firstError} />;

    case WIDGET_CALCULATION_ERRORS.NO_HISTORY_WIDGET_VALUE:
      return <NoHistoryWidgetValueError {...firstError} />;

    case WIDGET_CALCULATION_ERRORS.NO_MAIN_DIAGNOSIS:
    case WIDGET_CALCULATION_ERRORS.NO_CLINICAL_OUTLOOK:
    case WIDGET_CALCULATION_ERRORS.CALCULATION_ERROR:
      return <CalculationSimpleErrorMessage error={firstError.error} />;

    default:
      return (
        <CalculationSimpleErrorMessage
          error={WIDGET_CALCULATION_ERRORS.CALCULATION_ERROR}
        />
      );
  }
};

CustomCalculationErrorWidget.propTypes = {
  formContext: PropTypes.shape().isRequired,
  value: PropTypes.arrayOf(PropTypes.shape())
};

CustomCalculationErrorWidget.defaultProps = {
  value: null
};

export default CustomCalculationErrorWidget;
