class BillClientAddressModel {
  constructor(street1 = '') {
    this.street1 = street1;
    this.town = '';
    this.zip = '';
    this.country = '';
  }

  static getPayload(model) {
    return { ...model };
  }
}

export default BillClientAddressModel;
