import React from 'react';
import ReactDOM from 'react-dom';

import _ from 'lodash';

import { storeDispatch } from '../../../configureStore';
import { asyncPromiseWrapper } from '../../../utils/gen';
import { jsonParse } from '../../../utils/jwt';

import MoshiControlledDiscardChangesPrompt from '../modal/MoshiControlledDiscardChangesPrompt';

// TODO: Think about how to cover this with tests
export const userConfirmation = (configString, callback) => {
  const container = document.createElement('div');
  const config = jsonParse(configString, {});

  container.setAttribute('custom-confirmation-navigation', '');

  document.body.appendChild(container);
  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    if (callbackState && !_.isEmptySafe(config.confirmActionType)) {
      storeDispatch({ type: config.confirmActionType });
    }
    callback(callbackState);
  };

  ReactDOM.render(
    <MoshiControlledDiscardChangesPrompt
      isOpen
      onClose={() => closeModal(true)}
      onCancel={() => closeModal(false)}
    />,
    container
  );
};

export const asyncUserConfirmation = async (configString) => {
  const promise = new Promise((resolve) => {
    const callback = (callbackState) => {
      resolve(callbackState);
    };

    userConfirmation(configString, callback);
  });

  const [callbackState] = await asyncPromiseWrapper(promise);

  return callbackState;
};
