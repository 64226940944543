import axios from 'axios';

import { showAlertSuccess } from '../../../utils/alert';
import { getFormSubmissionDetailUrl } from '../../../utils/api/apiUrlUtils';

import {
  REMOVE_FORM_SUBMISSION,
  GET_FORM_SUBMISSION_DETAIL,
  CLEAR_FORM_SUBMISSION_DETAIL
} from './formSubmissionTypes';

export const getFormSubmissionDetail = (patientId, submissionId) => (
  dispatch,
  getState
) => {
  const formSubmissionDetailUrl = getFormSubmissionDetailUrl(
    getState,
    patientId,
    submissionId
  );

  return dispatch({
    type: GET_FORM_SUBMISSION_DETAIL,
    apiCall: axios.get(formSubmissionDetailUrl)
  });
};

export const removeFormSubmission = (patientId, submissionId) => (
  dispatch,
  getState
) => {
  const formSubmissionDetailUrl = getFormSubmissionDetailUrl(
    getState,
    patientId,
    submissionId
  );

  return dispatch({
    type: REMOVE_FORM_SUBMISSION,
    apiCall: axios.delete(formSubmissionDetailUrl),
    onSuccess: () => {
      showAlertSuccess('document:alert.submissionRemoved');
    }
  });
};

export const clearFormSubmissionDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_FORM_SUBMISSION_DETAIL
  });
