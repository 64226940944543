import axios from 'axios';

import {
  showMissingOrganizationIdError,
  showMissingRegistryIdError
} from '../../../utils/alert';

import { EMPTY_ACTION_TYPE } from '../../common/actionTypes';

import { selectCachedOrganizationId } from '../cache/cacheSelectors';
import { selectRegistryId } from './registrySelectors';

export const getRegistryItemList = (
  registryName,
  type = EMPTY_ACTION_TYPE,
  config = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const registryId = selectRegistryId(getState(), registryName);

  if (!registryId) {
    return showMissingRegistryIdError();
  }

  return dispatch({
    type,
    apiCall: axios.get(
      `/organizations/${organizationId}/registries/${registryId}/items`,
      config
    )
  });
};

export const getRegistryItem = (
  registryName,
  id,
  type = EMPTY_ACTION_TYPE,
  config = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const registryId = selectRegistryId(getState(), registryName);

  if (!registryId) {
    return showMissingRegistryIdError();
  }

  return dispatch({
    type,
    apiCall: axios.get(
      `/organizations/${organizationId}/registries/${registryId}/items/${id}`,
      config
    )
  });
};

export const addRegistryItem = (
  registryName,
  item,
  type = EMPTY_ACTION_TYPE,
  onSuccess = undefined
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const registryId = selectRegistryId(getState(), registryName);

  if (!registryId) {
    return showMissingRegistryIdError();
  }

  return dispatch({
    type,
    apiCall: axios.post(
      `/organizations/${organizationId}/registries/${registryId}/items`,
      item
    ),
    onSuccess
  });
};

export const updateRegistryItem = (
  registryName,
  id,
  item,
  type = EMPTY_ACTION_TYPE
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const registryId = selectRegistryId(getState(), registryName);

  if (!registryId) {
    return showMissingRegistryIdError();
  }

  return dispatch({
    type,
    apiCall: axios.put(
      `/organizations/${organizationId}/registries/${registryId}/items/${id}`,
      item
    )
  });
};

export const removeRegistryItem = (
  registryName,
  id,
  type = EMPTY_ACTION_TYPE
) => (dispatch, getState) => {
  const organizationId = selectCachedOrganizationId(getState());

  if (!organizationId) {
    return showMissingOrganizationIdError();
  }

  const registryId = selectRegistryId(getState(), registryName);

  if (!registryId) {
    return showMissingRegistryIdError();
  }

  return dispatch({
    type,
    apiCall: axios.delete(
      `/organizations/${organizationId}/registries/${registryId}/items/${id}`
    )
  });
};
