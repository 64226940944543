import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { convertFromSemanticColor } from '../../../../utils/color';
import { MOSHI_SIZE_MD } from '../../../../utils/constants/sizeConstants';
import { useMathInterval } from '../../../../utils/hooks/mathIntervals';
import { getSafeTranslation } from '../../../../utils/language';
import CustomI18nNumericWidget from './CustomI18nNumericWidget';

import { JsonSchemaWidgetOptionsShape } from '../../../../metadata/shapes/json-schema/JsonSchemaWidgetShape';

import Icon from '../../Icon';

const CustomNumericIconLabelWidget = (props) => {
  const { value, disabled, options } = props;
  const labels = _.getNonEmpty(options, 'x-labels', {});
  const className = _.getNonEmpty(options, 'className', '');
  const findInterval = useMathInterval(labels);

  if (!disabled) {
    return <CustomI18nNumericWidget {...props} />;
  }

  const interval = findInterval(value);
  const label = _.getNonEmpty(interval, 'label', value);
  const semanticColor = _.getNonEmpty(interval, 'color', 'default');
  const iconName = _.getNonEmpty(interval, 'icon', 'circle');

  return (
    <div className={classNames(className, 'widget-icon-label')}>
      <Icon
        name={iconName}
        size={MOSHI_SIZE_MD}
        color={convertFromSemanticColor(semanticColor)}
        className={'mr-2'}
      />
      {getSafeTranslation(label)}
    </div>
  );
};

CustomNumericIconLabelWidget.propTypes = {
  options: PropTypes.shape(JsonSchemaWidgetOptionsShape).isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

CustomNumericIconLabelWidget.defaultProps = {
  value: null,
  disabled: false
};

export default CustomNumericIconLabelWidget;
