import produce from 'immer';
import _ from 'lodash';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../../utils/browserStorage';
import { collapsedSidebarStorageKey } from '../../../utils/constants/browserStorageConstants';

import { createActionTypeArray } from '../../common/actionTypes';

import { LOGOUT } from '../../auth/authReducer';

export const GET_APP_CONFIG = 'appConfig/GET_APP_CONFIG';
export const UPDATE_APP_CONFIG = 'appConfig/UPDATE_APP_CONFIG';

export const TOGGLE_SIDEBAR = 'appConfig/TOGGLE_SIDEBAR';

export const [
  GET_APP_CONFIG_IN_PROGRESS,
  GET_APP_CONFIG_SUCCESS,
  GET_APP_CONFIG_FAILURE
] = createActionTypeArray(GET_APP_CONFIG);

export const [
  UPDATE_APP_CONFIG_IN_PROGRESS,
  UPDATE_APP_CONFIG_SUCCESS,
  UPDATE_APP_CONFIG_FAILURE
] = createActionTypeArray(UPDATE_APP_CONFIG);

const initialState = {
  detail: null,
  loadingDetail: false,
  isSidebarCollapsed: getLocalStorageValue(collapsedSidebarStorageKey, false)
};

// eslint-disable-next-line default-param-last
const appConfigReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_APP_CONFIG_IN_PROGRESS:
      case UPDATE_APP_CONFIG_IN_PROGRESS:
        draft.loadingDetail = true;
        break;
      case GET_APP_CONFIG_SUCCESS:
      case UPDATE_APP_CONFIG_SUCCESS:
        const appConfig = _.get(payload, 'response.data', null);

        draft.detail = appConfig;
        draft.loadingDetail = false;
        break;
      case GET_APP_CONFIG_FAILURE:
      case UPDATE_APP_CONFIG_FAILURE:
        draft.detail = null;
        draft.loadingDetail = false;
        break;
      case TOGGLE_SIDEBAR:
        const isCollapsed = getLocalStorageValue(collapsedSidebarStorageKey);

        draft.isSidebarCollapsed = !isCollapsed;

        setLocalStorageValue(collapsedSidebarStorageKey, !isCollapsed);
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default appConfigReducer;
