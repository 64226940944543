/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { FormGroup } from 'reactstrap';

import PropTypes from 'prop-types';

const FormGroupWrapper = ({
  children,
  row,
  check,
  inline,
  disabled,
  className,
  id
}) => (
  <FormGroup
    row={row}
    check={check}
    inline={inline}
    disabled={disabled}
    className={className}
    id={id}
  >
    {children}
  </FormGroup>
);

FormGroupWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  row: PropTypes.bool,
  check: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

FormGroupWrapper.defaultProps = {
  row: undefined,
  check: undefined,
  inline: undefined,
  disabled: undefined,
  className: undefined,
  id: undefined
};

export default FormGroupWrapper;
