import cheifComplaint from './cheifComplaint';
import historyOfCheifComplaint from './historyOfCheifComplaint';
import symptomsByDoctor from './symptomsByDoctor';
import symptomsByPatient from './symptomsByPatient';

export default {
  groupId: 'problemDescription',
  groupName: 'encounter:widget.problemDescription.title',
  widgets: {
    [cheifComplaint.id]: cheifComplaint,
    [historyOfCheifComplaint.id]: historyOfCheifComplaint,
    [symptomsByPatient.id]: symptomsByPatient,
    [symptomsByDoctor.id]: symptomsByDoctor
  }
};
