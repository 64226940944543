export default {
  title: 'Drugs',
  singleTitle: 'Drug',
  create: 'New drug',
  edit: 'Edit drug',
  drugName: 'Drug name',
  emptyListText: `
  <0>Hmmm. Seems like you don’t have any drugs yet.</0> <1></1>
  <0>Let’s start by creating a new one.</0>
`
};
