export default {
  title: {
    settings: 'Settings',
    account: 'Account settings',
    calendar: 'Calendar settings',
    billing: 'Invoice settings',
    automatedCheckIn: 'Automated check-in'
  },
  billing: {
    billingUnit: {
      title: 'Unit'
    },
    category: {
      title: 'Category',
      list: 'Item categories',
      entity: 'category',
      add: 'Add new $t(settings:billing.category.entity)',
      edit: 'Edit $t(settings:billing.category.entity)',
      name: 'Category name'
    },
    payments: {
      title: 'Payment types',
      paymentType: 'Payment type',
      add: 'Add payment type',
      new: 'New payment type'
    },
    localization: {
      title: 'Localization'
    },
    locale: {
      title: 'Translations',
      basicSettingsTitle: 'Basic settings',
      setDefault: 'Set default'
    }
  },
  notifications: {
    SMSnotifications: 'SMS notifications',
    newAppointmentAddedSMSTemplate: 'New appointment template',
    appointmentRescheduledSMSTemplate: 'Appointment rescheduled template',
    appointmentReminderSMSTemplate: 'Appointment reminder template',
    customAppointmentMessageSMSTemplate: 'Custom appointment template',
    rsvp: 'Request for upcoming appointment confirmation (RSVP)',
    rsvpText:
      'This text will be sent along with the reminder for an upcoming appointment as an appendix.',
    rsvpTooltipText: 'Contact support if you want to edit this text.',
    rsvpResponseConfirmedText:
      'This text will be sent if the patient confirmed the upcoming appointment.',
    rsvpResponseCanceledText:
      'This text will be sent if the patient declines the upcoming appointment.',
    notifyPatientWhenRescheduling: `Request patient's confirmation when rescheduling`,
    reminderPeriods: 'Time period',
    senderName: "Sender's name",
    senderPhoneNumber: 'Telefonska številka pošiljatelja sporočila',
    send: 'Send',
    firstReminder: 'First reminder',
    secondReminder: 'Second reminder',
    sendRequestConfirmation: 'Send confirmation request',
    addNewReminder: 'Add new reminder',
    modal: {
      title: 'RSVP confirmation',
      description: `
        <0>The app will send an RSVP confirmation along with the appointment reminder. 
        The recipient will be able to reply directly to the message and confirm or cancel the appointment. 
        The app will then automatically update the appointment status to “Confirmed” or “Canceled”, based on the reply.</0>
        <0>If there is no answer, the status of the appointment will remain unchanged.</0>
        `
    },
    periods: {
      oneHour: '1 hour before',
      twelweHours: '12 hours before',
      oneDay: '1 day before',
      twoDays: '2 days before',
      sevenDays: '7 days before',
      fourteenDays: '14 days before'
    },
    validation: {
      duplicatedTimePeriod: 'Reminder periods should not be duplicated'
    }
  },
  automatedCheckIn: {
    title: 'Automated check-in',
    tabs: {
      devices: 'Devices',
      greetingScreen: 'Greeting Screen',
      signInFields: 'Sign-in Fields',
      documentsAndQuestionnaires: 'Documents & Questionnaires',
      finalScreen: 'Final Screen'
    },
    greetingScreen: {
      welcomeGraphic: {
        title: 'Welcome Graphic',
        description:
          'Choose an image that will be displayed on the greeting screen. We recommend an image of size 1000 x 1000 px.',
        changeImage: 'Change image'
      },
      titleComment: {
        title: 'Title and welcome message',
        description:
          'Enter title and welcome message that will greet your visitors.',
        commentLabel: 'Welcome message'
      },
      buttonColor: {
        title: 'Button color',
        description:
          'Choose the color that will the base of buttons and will be used as a detail in selected fields.',
        buttonText: 'Change color'
      }
    },
    finalScreen: {
      titleComment: {
        commentLabel: 'Ending'
      }
    },
    documentsAndQuestionnaires: {
      editDocumentsAndQuestionnaires: 'Edit documents & questionnaires',
      mandatoryIf: 'if option {{ flowName }} selected',
      mandatory: 'Mandatory ',
      addDocumentOrQuestionnaire: 'Add document or questionnaire'
    },
    signInFields: {
      newPatient: {
        title: 'New patient',
        addFields: 'Add sign-in fields',
        fields: {
          name: 'Full name',
          email: 'Email address',
          phoneNumber: 'Phone number',
          dateOfBirth: 'Date of birth',
          address: 'Street address',
          gender: 'Gender'
        }
      },
      existingPatient: {
        title: 'Existing patient',
        expressSignIn: 'Express sign-in',
        expressSignInDescription:
          'Only a combination of email (or phone number) and birth date required ',
        fullSignIn: 'Full sign-in',
        fullSignInDescription: 'Same as new patient'
      }
    },
    devices: {
      title: 'Devices',
      subTitle: 'All devices connected to your system.',
      deviceImageAlt: 'Image of the device',
      status: {
        online: 'Online',
        offline: 'Offline',
        disconnected: 'Disconnected'
      },
      addDevice: 'Add device',
      removeDevice: 'Remove device',
      removeModal: {
        title: 'Do you wish to remove device {{deviceName}}?',
        tabletAlt: 'A picture of a tabled with a red cross'
      },
      device: {
        type: 'Device type',
        version: 'iOS version',
        wiFiNetwork: 'Wi-Fi network',
        cellularNetwork: 'Cellular network',
        ipAddress: 'IP address',
        appVersion: 'Iryo Moshi version'
      },
      addDeviceModal: {
        title: 'Add new device',
        download: {
          title: '1. Download Iryo Moshi mobile application',
          stepOne:
            'On the iPad or Android Tablet open the App Store or Play Store and search for the application Iryo Moshi.',
          stepTwo:
            'You can also get the your Iryo Moshi by scanning the QR code on the right side of the screen with the camera.',
          imageAlt:
            'Image of the QR code that takes you to the Iryo Moshi app on the App Store'
        },
        otp: {
          title: '2. Enter the code',
          stepOne: 'On the iPad open the application Iryo Moshi.',
          stepTwo: 'Press the button saying "I have the Iryo Moshi account".',
          stepThree: 'Enter the code from the iPad into the window below.',
          enterCodePlaceholder: 'enter 6-digit code',
          imageAlt: 'Image of an iPad with a code',
          deviceOtp: '6-digit code'
        },
        location: {
          title: '3. Choose location',
          dropdownPlaceholder:
            'Choose the location where the device will be situated'
        },
        success: {
          title: '{{deviceName}} connected successfully',
          tabletAlt: 'Picture of a tablet with a thumbs up'
        }
      }
    }
  },
  account: {
    title: 'Account',
    locationTab: 'Locations',
    tagsTab: 'Tags',
    location: 'Location',
    notificationTab: 'Messaging',
    organizationTitle: 'Organization info',
    organizationLabel: 'Organization name',
    logoLabel: 'Logo',
    logoDescription:
      'Please upload the logo, which will be shown on the iPad and in eMails. We recommended to use the resolution 1000 x 1000 px.',
    changeLogoButton: 'Change picture',
    deleteLogoButton: 'Remove',
    locationsDescription:
      'Location names and addresses of your organization branches.',
    locationRemoveButton: 'Remove location',
    addLocation: 'Add location',
    locationNameLabel: 'Location name',
    removeLocationTitle: 'Remove location',
    removeLocationMessage:
      'Are you sure you want to remove this location: <0>{{ locationName }}</0>?',
    companyId: 'Registration number',
    companyIdInput: '$t(settings:account.companyId): {{ companyId }}',
    companyIdPlaceholder: '123456789',
    taxNumber: 'Tax Number',
    taxNumberInput: '$t(settings:account.taxNumber): {{ taxNumber }}',
    taxIdInput: '$t(billing:settings.general.taxId): {{ taxNumber }}',
    taxNumberPlaceholder: 'GB123456789',
    website: 'Website',
    websitePlaceholder: 'https://www.google.com'
  },
  documents: {
    title: 'Document settings',
    header: 'Header',
    footer: 'Footer',
    description:
      'Headers and footers are the top and bottom sections of the document respectively. ' +
      'They are separate sections from the main document and are often used to hold footnotes, page numbers, titles, and other information. ' +
      'The information in the header or footer is repeated in every header or footer in the document.',
    legend: `
    <0>Legend</0>
    <1>
    The following smart codes can be entered into texts and will be
    automatically swapped with their respective data:
    </1>
    <1>[current date] - today's date</1>
    <1>[document title] - document's title</1>
    <1>[patient full name] - patient's full name</1>
    <1>[patient first name] - patient's first name</1>
    <1>[patient last name] - patient's last name</1>
    <1>[patient gender] - patient's gender</1>
    <1>[patient dob] - patient's date of birth</1>
    <1>[patient full address] - patient's entire address (street, zip, city, country)</1>
    <1>[patient street] - patient's street address</1>
    <1>[patient zip] - patient's zip (post) code</1>
    <1>[patient city] - patient's city</1>
    <1>[patient country] - patient's country</1>
    <1>[patient email] - patient's email</1>  
    <1>[patient phone number] - patient's phone number</1>
    <1>[patient insurance] - patient's insurance number</1>
    <1>[patient next appointment date] - patient's next upcoming appointment</1>
    <1>[patient last appointment date] - patient's last appointment</1>
    <1>[organization name] - organization's name</1>
    <1>[organization address] - organization's address</1>
    <1>[organization address2] - organization's second address</1>
    <1>[organization zip] - organization's zip code</1>
    <1>[organization city] - organization's city</1>
    <1>[organization country] - organization's country</1>    
    <1>[organization phone number] - organization's phone number</1>
    <1>[organization email] - organization's email</1>    
    `,
    alert: {
      saved: 'Document settings were successfully saved.'
    }
  }
};
