import produce from 'immer';
import _ from 'lodash';

import {
  CLEAR_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_SUCCESS,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST_SUCCESS
} from './rheumatologyTypes';

import { LOGOUT } from '../../auth/authReducer';

const initialState = {
  pastEncounters: [],
  pastEncounterWidgets: {}
};

// eslint-disable-next-line default-param-last
const rheumatologyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_SUCCESS:
        const pastEncounters = _.getNonEmpty(payload, 'response.data', []);

        draft.pastEncounters = pastEncounters;
        break;
      case CLEAR_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST:
        draft.pastEncounters = [];
        break;
      case GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST_SUCCESS:
        const pastEncounterWidgets = _.getNonEmpty(payload, 'data', []);
        const pastEncounterId = _.getNonEmpty(
          payload,
          'additionalData.encounterId'
        );

        if (!_.isEmpty(pastEncounterId)) {
          draft.pastEncounterWidgets[pastEncounterId] = pastEncounterWidgets;
        }
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default rheumatologyReducer;
