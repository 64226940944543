import {
  createActionTypeArray,
  createSanitizableActionTypeArray
} from '../common/actionTypes';

export const GET_THERAPY_LIST = 'therapy/GET_THERAPY_LIST';
export const GET_THERAPY_DETAIL = 'therapy/GET_THERAPY_DETAIL';
export const ADD_THERAPY_DETAIL = 'therapy/ADD_THERAPY_DETAIL';
export const UPDATE_THERAPY_DETAIL = 'therapy/UPDATE_THERAPY_DETAIL';
export const DELETE_THERAPY_DETAIL = 'therapy/DELETE_THERAPY_DETAIL';
export const CLEAR_THERAPY_DETAIL = 'therapy/CLEAR_THERAPY_DETAIL';

export const GET_PATIENT_THERAPY_LIST = 'therapy/GET_PATIENT_THERAPY_LIST';
export const FETCH_PATIENT_THERAPY_LIST = 'therapy/FETCH_PATIENT_THERAPY_LIST';
export const GET_PATIENT_THERAPY = 'therapy/GET_PATIENT_THERAPY';
export const GET_PATIENT_THERAPY_DETAIL = 'therapy/GET_PATIENT_THERAPY_DETAIL';
export const ADD_PATIENT_THERAPY = 'therapy/ADD_PATIENT_THERAPY';
export const UPDATE_PATIENT_THERAPY = 'therapy/UPDATE_PATIENT_THERAPY';
export const REMOVE_PATIENT_THERAPY = 'therapy/REMOVE_PATIENT_THERAPY';
export const SET_ACTIVE_PATIENT_THERAPY_LIST =
  'therapy/SET_ACTIVE_PATIENT_THERAPY_LIST';
export const UPDATE_ACTIVE_PATIENT_THERAPY_LIST_ITEM =
  'therapy/UPDATE_ACTIVE_PATIENT_THERAPY_LIST_ITEM';
export const SET_PAST_PATIENT_THERAPY_LIST =
  'therapy/SET_PAST_PATIENT_THERAPY_LIST';
export const UPDATE_PAST_PATIENT_THERAPY_LIST_ITEM =
  'therapy/UPDATE_PAST_PATIENT_THERAPY_LIST_ITEM';
export const SET_PATIENT_THERAPY_DETAIL = 'therapy/SET_PATIENT_THERAPY_DETAIL';

export const SET_THERAPY_LIST_FILTER = 'therapy/SET_THERAPY_LIST_FILTER';
export const SET_THERAPY_LIST_ORDER = 'therapy/SET_THERAPY_LIST_ORDER';
export const SET_THERAPY_LIST_PAGINATION =
  'therapy/SET_THERAPY_LIST_PAGINATION';

export const GET_FORM_SUBMISSION_DETAIL = 'therapy/GET_FORM_SUBMISSION_DETAIL';
export const ADD_FORM_SUBMISSION_DETAIL = 'therapy/ADD_FORM_SUBMISSION_DETAIL';

export const [
  GET_THERAPY_LIST_IN_PROGRESS,
  GET_THERAPY_LIST_SUCCESS,
  GET_THERAPY_LIST_FAILURE
] = createActionTypeArray(GET_THERAPY_LIST);

export const [
  GET_THERAPY_DETAIL_IN_PROGRESS,
  GET_THERAPY_DETAIL_SUCCESS,
  GET_THERAPY_DETAIL_FAILURE
] = createActionTypeArray(GET_THERAPY_DETAIL);

export const [
  ADD_THERAPY_DETAIL_IN_PROGRESS,
  ADD_THERAPY_DETAIL_SUCCESS,
  ADD_THERAPY_DETAIL_FAILURE
] = createActionTypeArray(ADD_THERAPY_DETAIL);

export const [
  UPDATE_THERAPY_DETAIL_IN_PROGRESS,
  UPDATE_THERAPY_DETAIL_SUCCESS,
  UPDATE_THERAPY_DETAIL_FAILURE
] = createActionTypeArray(UPDATE_THERAPY_DETAIL);

export const [
  DELETE_THERAPY_DETAIL_IN_PROGRESS,
  DELETE_THERAPY_DETAIL_SUCCESS,
  DELETE_THERAPY_DETAIL_FAILURE
] = createActionTypeArray(DELETE_THERAPY_DETAIL);

export const [
  GET_PATIENT_THERAPY_LIST_TRIGGER,
  GET_PATIENT_THERAPY_LIST_IN_PROGRESS,
  GET_PATIENT_THERAPY_LIST_SUCCESS,
  GET_PATIENT_THERAPY_LIST_FAILURE,
  GET_PATIENT_THERAPY_LIST_SANITIZED,
  GET_PATIENT_THERAPY_LIST_UPDATED
] = createSanitizableActionTypeArray(GET_PATIENT_THERAPY_LIST);

export const [
  FETCH_PATIENT_THERAPY_LIST_IN_PROGRESS,
  FETCH_PATIENT_THERAPY_LIST_SUCCESS,
  FETCH_PATIENT_THERAPY_LIST_FAILURE
] = createActionTypeArray(FETCH_PATIENT_THERAPY_LIST);

export const [
  GET_PATIENT_THERAPY_TRIGGER,
  GET_PATIENT_THERAPY_IN_PROGRESS,
  GET_PATIENT_THERAPY_SUCCESS,
  GET_PATIENT_THERAPY_FAILURE,
  GET_PATIENT_THERAPY_SANITIZED,
  GET_PATIENT_THERAPY_UPDATED
] = createSanitizableActionTypeArray(GET_PATIENT_THERAPY);

export const [
  GET_PATIENT_THERAPY_DETAIL_TRIGGER,
  GET_PATIENT_THERAPY_DETAIL_IN_PROGRESS,
  GET_PATIENT_THERAPY_DETAIL_SUCCESS,
  GET_PATIENT_THERAPY_DETAIL_FAILURE
] = createSanitizableActionTypeArray(GET_PATIENT_THERAPY_DETAIL);

export const [
  GET_FORM_SUBMISSION_DETAIL_IN_PROGRESS,
  GET_FORM_SUBMISSION_DETAIL_SUCCESS,
  GET_FORM_SUBMISSION_DETAIL_FAILURE
] = createActionTypeArray(GET_FORM_SUBMISSION_DETAIL);
