export default {
  title: 'Dela prosti dnevi',
  closedDate: 'Dela prost dan',
  newClosedDate: 'Nov dela prost dan',
  emptyListText: `
      <0>Hmmm, izgleda kot da še ni nobenih dela prostih dni.</0> <1></1>
      <0>Dodajte nov dela prost dan.</0>
    `,
  numOfDays: 'Število dni',
  descriptionPlaceholder: 'npr. državni praznik',
  alerts: {
    deleted: 'Dela prost dan je bil uspešno odstranjen'
  }
};
