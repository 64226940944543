import moment from 'moment';
import { take, race, call, select } from 'redux-saga/effects';

import { getAllBillsListUrl } from '../../../utils/api/apiUrlUtils';
import {
  getDateFilterParametersPayload,
  getPaymentTypeFilterParametersPaylad
} from '../../../utils/billing';
import { pollGenerator } from '../../../utils/redux/sagaUtils';

import {
  START_BILL_POLLING,
  STOP_BILL_POLLING,
  STOP_POLLING
} from '../../core/cache/cacheTypes';
import {
  CLEAR_BILL_LIST_FILTERS,
  GET_BILL_LIST,
  SET_BILL_LIST_FILTER,
  SET_BILL_LIST_FILTERS,
  SET_BILL_LIST_INCLUDE_PARAM,
  SET_BILL_LIST_ORDER,
  SET_BILL_LIST_PAGINATION
} from './billTypes';

import {
  selectBillFilter,
  selectBillPagination,
  selectBillIncludeParam
} from './billSelectors';

function* pollAllBillsSaga() {
  function* configGetter() {
    const state = yield select();
    const { limit, page } = selectBillPagination(state);
    const include = selectBillIncludeParam(state);
    let customFilter = selectBillFilter(state);

    customFilter = getDateFilterParametersPayload(customFilter);
    customFilter = getPaymentTypeFilterParametersPaylad(customFilter);

    return {
      params: {
        ...customFilter,
        include,
        limit,
        page
      }
    };
  }

  yield pollGenerator(GET_BILL_LIST, getAllBillsListUrl, {
    timer: moment.duration(10, 'minutes').asMilliseconds(),
    retriggerActions: [
      SET_BILL_LIST_FILTER,
      SET_BILL_LIST_FILTERS,
      SET_BILL_LIST_INCLUDE_PARAM,
      SET_BILL_LIST_ORDER,
      SET_BILL_LIST_PAGINATION,
      CLEAR_BILL_LIST_FILTERS
    ],
    configGetter
  });
}

export function* billSagaWatcher() {
  while (true) {
    yield take(START_BILL_POLLING);
    yield race([
      call(pollAllBillsSaga),
      take(STOP_POLLING),
      take(STOP_BILL_POLLING)
    ]);
  }
}
