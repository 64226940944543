import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { localize } from '../../../../i18n';

import { Div, EmptyComponent } from '../../HtmlComponents';

import { Input } from '../../../reactstrap';
import Icon from '../../Icon';
import Visible from '../../layout/Visible';
import ClearButton from '../button/ClearButton';

const MoshiDropdownFilter = ({
  t: translate,
  isOpen,
  filterEnabled,
  enteredFilter,
  setEnteredFilter,
  menuItemLength,
  width,
  fullWidthInputField
}) => {
  if (!isOpen) return <EmptyComponent />;

  return (
    <React.Fragment>
      <Visible
        visible={filterEnabled}
        component={Div}
        className={classNames('dropdown-filter', {
          'no-results': menuItemLength === 0,
          'show-scrollbar': menuItemLength >= 8
        })}
        style={width ? { width } : undefined}
      >
        <div className={'filter-search-icon'}>
          <Icon name={'search'} />
        </div>
        <Visible visible={enteredFilter.length > 0}>
          <ClearButton onClick={() => setEnteredFilter('')} />
        </Visible>
        <Input
          innerRef={(input) => input && input.focus()}
          type={'text'}
          name={'filter'}
          value={enteredFilter}
          onChange={(e) => setEnteredFilter(_.toLower(e.target.value))}
          className={classNames('form-control', {
            'w-100 margin-left-0': fullWidthInputField
          })}
          placeholder={`${translate('common:search')}...`}
          autoComplete={'off'}
        />
      </Visible>
      <Visible
        visible={filterEnabled}
        component={Div}
        className={'shadow-caster'}
        style={{ width }}
      />
    </React.Fragment>
  );
};

MoshiDropdownFilter.propTypes = {
  /* Boolean for indicating if dropdown is opened */
  isOpen: PropTypes.bool.isRequired,
  /* Callback function called on every character entered into filter */
  setEnteredFilter: PropTypes.func.isRequired,
  /* Currently entered text in the filter input */
  enteredFilter: PropTypes.string.isRequired,
  /* Toggles filter's visibility */
  filterEnabled: PropTypes.bool,
  /* Length of menu items, used to toggle menu scrollbar when exceding the limit of 340px (8 items)  */
  menuItemLength: PropTypes.number,
  /** Forces the width (in px) of the filter component */
  width: PropTypes.number,
  /** i18next translation function */
  t: PropTypes.func.isRequired,
  /** Sets the width of the search input to the width of the dropdown toggle */
  fullWidthInputField: PropTypes.bool
};

MoshiDropdownFilter.defaultProps = {
  filterEnabled: true,
  menuItemLength: undefined,
  width: undefined,
  fullWidthInputField: false
};

export default localize(MoshiDropdownFilter);
