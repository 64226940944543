/* eslint-disable id-blacklist */
import BadgeWrapper from './BadgeWrapper';
import ButtonDropdownWrapper from './ButtonDropdownWrapper';
import ButtonGroupWrapper from './ButtonGroupWrapper';
import ButtonWrapper from './ButtonWrapper';
import CardBodyWrapper from './CardBodyWrapper';
import CardFooterWrapper from './CardFooterWrapper';
import CardTitleWrapper from './CardTitleWrapper';
import CardWrapper from './CardWrapper';
import ColWrapper from './ColWrapper';
import ContainerWrapper from './ContainerWrapper';
import DropdownItemWrapper from './DropdownItemWrapper';
import DropdownMenuWrapper from './DropdownMenuWrapper';
import DropdownToggleWrapper from './DropdownToggleWrapper';
import DropdownWrapper from './DropdownWrapper';
import FormGroupWrapper from './FormGroupWrapper';
import FormWrapper from './FormWrapper';
import InputGroupAddonWrapper from './InputGroupAddonWrapper';
import InputGroupWrapper from './InputGroupWrapper';
import InputWrapper from './InputWrapper';
import LabelWrapper from './LabelWrapper';
import ModalBodyWrapper from './ModalBodyWrapper';
import ModalFooterWrapper from './ModalFooterWrapper';
import ModalHeaderWrapper from './ModalHeaderWrapper';
import ProgressWrapper from './ProgressWrapper';
import RowWrapper from './RowWrapper';

export const Button = ButtonWrapper;
export const Row = RowWrapper;
export const Col = ColWrapper;
export const Badge = BadgeWrapper;
export const Progress = ProgressWrapper;
export const ButtonGroup = ButtonGroupWrapper;
export const Card = CardWrapper;
export const CardTitle = CardTitleWrapper;
export const CardBody = CardBodyWrapper;
export const CardFooter = CardFooterWrapper;
export const Form = FormWrapper;
export const FormGroup = FormGroupWrapper;
export const Label = LabelWrapper;
export const Input = InputWrapper;
export const InputGroup = InputGroupWrapper;
export const InputGroupAddon = InputGroupAddonWrapper;
export const Container = ContainerWrapper;
export const ModalHeader = ModalHeaderWrapper;
export const ModalBody = ModalBodyWrapper;
export const ModalFooter = ModalFooterWrapper;
export const DropdownMenu = DropdownMenuWrapper;
export const DropdownToggle = DropdownToggleWrapper;
export const ButtonDropdown = ButtonDropdownWrapper;
export const DropdownItem = DropdownItemWrapper;
export const Dropdown = DropdownWrapper;
