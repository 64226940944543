import React from 'react';

import PropTypes from 'prop-types';

import { doSchemaPropertiesContainMeasurement } from '../../../../../../utils/widgets';

import CustomWidgetGroupAllFields from './CustomWidgetGroupAllFields';
import CustomWidgetGroupSingleField from './singleField/CustomWidgetGroupSingleField';

const CustomWidgetGroup = (props) => {
  const { schema } = props;

  const doPropertiesContainMeasurement = doSchemaPropertiesContainMeasurement(
    schema
  );

  let WidgetGroupComponent = CustomWidgetGroupAllFields;

  if (doPropertiesContainMeasurement) {
    WidgetGroupComponent = CustomWidgetGroupSingleField;
  }

  return <WidgetGroupComponent {...props} />;
};

CustomWidgetGroup.propTypes = {
  schema: PropTypes.shape({}).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool.isRequired,
  measurement: PropTypes.string
};

CustomWidgetGroup.defaultProps = {
  measurement: 'PropTypes.string'
};

export default CustomWidgetGroup;
