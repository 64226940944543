export default {
  title: 'Oznake',
  entity: 'Oznaka',
  removeEntity: 'Oznako',
  addTag: '+ Dodajte oznako',
  newTag: 'Nova oznaka',
  editTag: 'Uredite oznako',
  tagName: 'Ime oznake',
  tagColor: 'Barva oznake',
  assignTags: 'Dodelite oznake',
  assignTagsOnPatients: 'Dodelite oznake na paciente',
  assignTagsOnPatient: 'Dodelite oznake na pacienta',
  createNewTag: 'Dodajte novo oznako "{{ name }}"',
  alerts: {
    tagCreated: 'Oznaka je bila uspešno ustvarjena',
    tagUpdated: 'Oznaka je bila uspešno posodbljena',
    tagDeleted: 'Oznaka je bila uspešno izbrisana'
  }
};
