import React from 'react';

import Icon from '../../../../components/common/Icon';
import CellWithIcon from '../../../../components/common/table/CellWithIcon';

export const getDocumentNameColumn = () => ({
  name: 'name',
  cellComponent: CellWithIcon(
    () => <Icon name={'apps'} size={'lg'} className={'icon-color-gray'} />,
    'name',
    (value, entity) => (
      <React.Fragment>
        <span className={'type'}>
          {entity.type === 'questionnaire'
            ? entity.type
            : `legal ${entity.type}`}
        </span>
        <br />
        <span>{value}</span>
      </React.Fragment>
    )
  ),
  colWidth: 7,
  noHeaderLabel: true
});

export const documentVersionColumn = {
  name: 'version',
  displayKeyCode: 'common:versionLabel',
  cellComponent: (document) => (
    <div className={'version'}>{`v${document.version}`}</div>
  ),
  colWidth: 4
};

export const getRemoveColumn = (removeCb) => ({
  name: 'remove',
  cellComponent: (document) => (
    <button
      type={'button'}
      className={'remove-btn btn moshi-link btn-link-danger btn-link-lg'}
      onClick={() => removeCb(document)}
    >
      <Icon name={'bin'} size={'md'} />
    </button>
  ),
  colWidth: 1
});
