/* eslint-disable id-blacklist */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { ButtonGroup } from 'reactstrap';

import PropTypes from 'prop-types';

const ButtonGroupWrapper = ({
  children,
  role,
  size,
  vertical,
  className,
  id
}) => (
  <ButtonGroup
    role={role}
    size={size}
    vertical={vertical}
    className={className}
    id={id}
  >
    {children}
  </ButtonGroup>
);

ButtonGroupWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
  size: PropTypes.string,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

ButtonGroupWrapper.defaultProps = {
  role: undefined,
  size: undefined,
  vertical: undefined,
  className: undefined,
  id: undefined
};

export default ButtonGroupWrapper;
