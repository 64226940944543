import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  DOCUMENT_FILTER_CREATED_BY,
  DOCUMENT_FILTER_SEARCH,
  DOCUMENT_FILTER_TYPE
} from '../../../utils/constants/documentConstants';
import { FILTER_ALL_ID } from '../../../utils/constants/dropdownConstants';
import { ORDER_DESCENDING } from '../../../utils/constants/tableConstants';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../../utils/table';

import { initialState } from './allDocumentTypesReducer';

import { selectFormStore } from '../formSelectors';

export const selectAllDocumentTypesStore = createSelector(
  [selectFormStore],
  (formStore) => _.getNonEmpty(formStore, 'allDocumentTypes', {})
);

export const selectAllDocumentTypesList = createSelector(
  [selectAllDocumentTypesStore],
  (allDocumentTypesStore) => _.getNonEmpty(allDocumentTypesStore, 'list', [])
);

export const selectAllDocumentTypesPagination = createSelector(
  [selectAllDocumentTypesStore],
  (allDocumentTypesStore) =>
    _.getNonEmpty(allDocumentTypesStore, 'pagination', {})
);

export const selectAllDocumentTypesFilters = createSelector(
  [selectAllDocumentTypesStore],
  (allDocumentTypesStore) => _.getNonEmpty(allDocumentTypesStore, 'filter', {})
);

export const selectIsFilterInitial = createSelector(
  [selectAllDocumentTypesFilters],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectAllDocumentTypesSortConfig = createSelector(
  [selectAllDocumentTypesFilters],
  (filters) => {
    const sortBy = _.getNonEmpty(
      filters,
      'sortBy',
      ENTITY_FIELD_CREATED_AT_NAME
    );
    const order = _.getNonEmpty(filters, 'order', ORDER_DESCENDING);

    return { sortBy, order };
  }
);

export const selectAllDocumentTypesFilterSearchValue = createSelector(
  [selectAllDocumentTypesFilters],
  (filters) => _.getNonEmpty(filters, DOCUMENT_FILTER_SEARCH, '')
);

export const selectAllDocumentTypesFilterTypeValue = createSelector(
  [selectAllDocumentTypesFilters],
  (filters) => _.getNonEmpty(filters, DOCUMENT_FILTER_TYPE, FILTER_ALL_ID)
);

export const selectAllDocumentTypesFilterUserIdValue = createSelector(
  [selectAllDocumentTypesFilters],
  (filters) => _.getNonEmpty(filters, DOCUMENT_FILTER_CREATED_BY, FILTER_ALL_ID)
);
