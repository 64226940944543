import _ from 'lodash';

export const cleanPayload = (payload, validFields = undefined) => {
  const newPayload = _.pickBy(payload, (value) => value !== undefined);

  if (validFields === undefined) {
    return newPayload;
  }

  return _.pick(newPayload, validFields);
};
