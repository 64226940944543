import produce from 'immer';
import _ from 'lodash';

import {
  getLocalStorageValue,
  setLocalStorageValue
} from '../../utils/browserStorage';
import { lastEventLogFetchStorageKey } from '../../utils/constants/browserStorageConstants';
import { ORDER_DESCENDING } from '../../utils/constants/tableConstants';
import moshiConfig from '../../utils/moshiConfig';
import { getListPaginationFromResponse } from '../../utils/redux/listUtils';
import { ENTITY_FIELD_CREATED_AT_NAME } from '../../utils/table';

import { POLL_EVENT_LOGS_SUCCESS } from '../core/cache/cacheTypes';
import {
  GET_EVENT_LOG_LIST_IN_PROGRESS,
  GET_EVENT_LOG_LIST_SUCCESS,
  GET_EVENT_LOG_LIST_FAILURE,
  SET_EVENT_LOG_LIST_PAGINATION,
  CLEAR_NEW_EVENT_LOG_ID_LIST,
  CLEAR_ALL_NEW_EVENT_LOG_ID_LIST
} from './eventLogTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  loadingList: false,
  list: [],
  newEventLogIdList: [],
  lastListFetch: getLocalStorageValue(lastEventLogFetchStorageKey, null),
  filter: {
    sortBy: ENTITY_FIELD_CREATED_AT_NAME,
    order: ORDER_DESCENDING
  },
  pagination: {
    resultCount: 0,
    pageCount: 0,
    limit: moshiConfig.list.pagination.limit,
    page: 1
  }
};

// eslint-disable-next-line default-param-last
const eventLogReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (action.type) {
      case GET_EVENT_LOG_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_EVENT_LOG_LIST_SUCCESS:
        const response = _.getNonEmpty(payload, 'response', {});
        const eventLogs = _.getNonEmpty(response, 'data', []);

        const { resultCount, pageCount } = getListPaginationFromResponse(
          response,
          draft.pagination.limit
        );

        draft.pagination.resultCount = resultCount;
        draft.pagination.pageCount = pageCount;
        draft.loadingList = false;
        draft.list = eventLogs;
        break;
      case GET_EVENT_LOG_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case SET_EVENT_LOG_LIST_PAGINATION:
        const paginationName = _.getNonEmpty(payload, 'paginationName', null);
        const paginationValue = _.getNonEmpty(payload, 'paginationValue', '');

        if (!_.isEmpty(paginationName)) {
          draft.pagination[paginationName] = paginationValue;
        }
        break;
      case POLL_EVENT_LOGS_SUCCESS:
        const newEventLogs = _.getNonEmpty(payload, 'data', []);
        const lastListFetch = new Date().toISOString();

        setLocalStorageValue(lastEventLogFetchStorageKey, lastListFetch);

        if (!_.isEmptySafe(newEventLogs)) {
          draft.newEventLogIdList = _.map(newEventLogs, 'id');
        }
        draft.lastListFetch = lastListFetch;
        break;
      case CLEAR_NEW_EVENT_LOG_ID_LIST:
        const safeNewEventLogIdList = _.getAndClone(
          draft,
          'newEventLogIdList',
          []
        );
        const eventLogIds = _.getNonEmpty(payload, 'eventLogIds', []);

        if (!_.isEmptySafe(eventLogIds)) {
          const filteredNewEventLogs = _.filter(
            safeNewEventLogIdList,
            (newEventLogId) => !_.includes(eventLogIds, newEventLogId)
          );

          draft.newEventLogIdList = filteredNewEventLogs;
        }
        break;
      case CLEAR_ALL_NEW_EVENT_LOG_ID_LIST:
        draft.newEventLogIdList = [];
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default eventLogReducer;
