import _ from 'lodash';
import { createSelector } from 'reselect';

import { getJsonSchemaFormData } from '../../utils/form';
import { questionnaireWidgetMapper } from '../../utils/mappers/cleaned/widgetMappers';
import { selectProps } from '../../utils/redux/selectorHelpers';

import { selectDeletedQuestionnaireList } from '../core/archive/archiveSelectors';
import { selectCachedQuestionnaireList } from '../core/cache/cacheSelectors';

export const selectQuestionnaireStore = (store) => store.questionnaire;

export const selectIsQuestionnaireListLoading = createSelector(
  [selectQuestionnaireStore],
  (questionnaireStore) => _.getNonEmpty(questionnaireStore, 'loadingList', true)
);

export const selectQuestionnaireList = createSelector(
  [selectQuestionnaireStore, selectCachedQuestionnaireList],
  (questionnaireStore, cachedQuestionnaireList) =>
    _.getNonEmpty(questionnaireStore, 'list', cachedQuestionnaireList)
);

export const selectQuestionnaireWidgetList = createSelector(
  [selectCachedQuestionnaireList, selectDeletedQuestionnaireList],
  (cachedQuestionnaireList, deletedQuestionnaireList) => {
    const questionnaireList = _.uniqBy(
      [...cachedQuestionnaireList, ...deletedQuestionnaireList],
      (questionnaire) => `${questionnaire.id}/${questionnaire.version}`
    );

    const clinicalQuestionnaireList = _.filter(
      questionnaireList,
      (questionnaire) => _.getNonEmpty(questionnaire, 'clinical', false)
    );

    return clinicalQuestionnaireList.map(questionnaireWidgetMapper);
  }
);

// NORMALIZED MAP

export const selectQuestionnaireNormalizeMap = createSelector(
  [selectQuestionnaireStore],
  (questionnaireStore) => _.getNonEmpty(questionnaireStore, 'entities', {})
);

export const selectQuestionnaireFromNormalizeMapById = createSelector(
  [selectQuestionnaireNormalizeMap, selectProps],
  (questionnaireNormalizeMap, questionnaireId) =>
    _.getNonEmpty(questionnaireNormalizeMap, questionnaireId, {})
);

export const selectQuestionnaireDetail = createSelector(
  [selectQuestionnaireStore],
  (questionnaireStore) => _.getNonEmpty(questionnaireStore, 'detail', {})
);

export const selectQuestionnaireFormData = createSelector(
  [selectQuestionnaireDetail],
  (questionnaireDetail) => {
    const formBuilderSchema = _.getNonEmpty(
      questionnaireDetail,
      'formBuilderSchema',
      {}
    );

    return formBuilderSchema;
  }
);

export const selectQuestionnaireSchema = createSelector(
  [selectQuestionnaireFormData],
  (questionnaireFormData) => {
    const { schema, uiSchema } = getJsonSchemaFormData(questionnaireFormData);

    return { schema, uiSchema };
  }
);

export const selectQuestionnaireName = createSelector(
  [selectQuestionnaireFormData],
  (questionnaireDetail) => _.get(questionnaireDetail, 'title', '')
);
