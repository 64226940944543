import _ from 'lodash';

import { ROLE_DOCTOR } from '../constants/userConstants';

export const isUserDoctor = (user) => {
  if (_.isEmpty(user)) {
    return false;
  }
  const locationsDoctors = _.filter(user.locationsRoles, (locationRoles) =>
    _.includes(locationRoles.roles, ROLE_DOCTOR)
  );

  return !_.isEmpty(locationsDoctors);
};
