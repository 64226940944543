import React, { useMemo } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  MOSHI_SIZE_XL,
  MOSHI_SIZE_LG,
  MOSHI_SIZE_MD,
  MOSHI_SIZE_SM
} from '../../utils/constants/sizeConstants';

/**
 * The common Icon component
 *
 * @example ./__examples__/Icon.md
 */
const IconComponent = ({
  name,
  size,
  bold,
  className,
  onClick,
  children,
  disabled,
  color,
  id,
  style
}) => {
  const combineProps = onClick && {
    disabled,
    onClick: disabled ? undefined : onClick,
    role: 'button',
    tabIndex: 0
  };

  const combinedStyles = useMemo(() => {
    if (_.isEmptySafe(style) && _.isEmptySafe(color)) {
      return undefined;
    }

    return { color, ...style };
  }, [color, style]);

  return (
    <i
      id={id}
      className={classNames(`icon-${name}`, className, {
        [`icon-size-${size}`]: !_.isEmpty(size),
        'icon-weight-bold': bold,
        'cursor-pointer': onClick && !disabled,
        disabled
      })}
      style={combinedStyles}
      {...combineProps}
    >
      {children && <div className={'icon-children-wrapper'}>{children}</div>}
    </i>
  );
};

IconComponent.propTypes = {
  /** The name of an icon */
  name: PropTypes.string.isRequired,
  /** Icon size */
  size: PropTypes.oneOf([
    MOSHI_SIZE_XL,
    MOSHI_SIZE_LG,
    MOSHI_SIZE_MD,
    MOSHI_SIZE_SM
  ]),
  /** If true adds bold property to an icon */
  bold: PropTypes.bool,
  /** If true disables onClick */
  disabled: PropTypes.bool,
  /** Additional classNames */
  className: PropTypes.string,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** exposed children */
  children: PropTypes.node,
  /** color */
  color: PropTypes.string,
  /** custom inline styles */
  style: PropTypes.shape(),
  /** id **/
  id: PropTypes.string
};

IconComponent.defaultProps = {
  size: undefined,
  bold: false,
  disabled: false,
  className: undefined,
  onClick: undefined,
  children: undefined,
  color: undefined,
  id: undefined,
  style: undefined
};

export default IconComponent;
