export const formatting = {
  shortDate: 'D. M. YYYY',
  longDate: 'Do MMMM YYYY',
  time: 'HH:mm',
  shortDateWithTime: 'D. M. YYYY, HH:mm',
  longDateWithTime: 'Do MMMM YYYY, HH:mm',
  longDateWithWeekDay: 'dddd, D. MMMM YYYY',
  longDateWithWeekDayAndTime: 'dddd, D. MMMM YYYY, HH:mm',
  shortDateWithWeekDay: 'dddd, D. M. YYYY',
  datePicker: 'd. M. y'
};

export default {
  date: 'Datum',
  dateFrame: 'Časovni okvir',
  string: '{{ day }}/{{ month }}/{{ year }}',
  stringFormat: 'DD/MM/YYYY',
  stringFormatReadable: 'DD/MM/LLLL',
  shortDate: `{{ date, ${formatting.shortDate} }}`,
  longDate: `{{ date, ${formatting.longDate} }}`,
  longDateWithTime: `{{ date, ${formatting.longDateWithTime} }}`,
  time: `{{ date, ${formatting.time} }}`,
  today: 'Danes',
  day: 'dan',
  week: 'teden',
  month: 'mesec',
  year: 'leto',
  rangeLessThen: {
    week: 'Manj kot teden',
    month: 'Manj kot mesec',
    halfYear: 'Manj kot pol leta',
    year: 'Manj kot eno leto'
  },
  rangeMoreThan: {
    week: 'Več kot teden',
    month: 'Več kot mesec',
    halfYear: 'Več kot pol leta',
    year: 'Več kot eno leto'
  },
  selectDay: 'Izberite dan:',
  selectMonth: 'Izberite mesec:',
  selectYear: 'Izberite leto:',
  from: 'Od',
  to: 'Do',
  datePickerFormat: formatting.datePicker,
  shortDateWithTime: `{{ date, ${formatting.shortDateWithTime} }}`,
  longDateWithWeekDay: `{{ date, ${formatting.longDateWithWeekDay} }}`,
  longDateWithWeekDayAndTime: `{{ date, ${formatting.longDateWithWeekDayAndTime} }}`,
  shortDateWithWeekDay: `{{ date, ${formatting.shortDateWithWeekDay} }}`,
  dateTimeAndLocation: `{{ date, ${formatting.shortDate} }} · {{ fromTime, ${formatting.time} }} - {{ toTime, ${formatting.time} }} · {{ location }}`,
  prefixes: {
    at: 'Ob'
  },
  periodShortDate: `{{ from, ${formatting.shortDate} }} - {{ to, ${formatting.shortDate} }}`,
  dateRange: {
    startDate: 'Začetek',
    endDate: 'Konec',
    dateSelectShortcut: {
      today: 'Danes',
      yesterday: 'Včeraj',
      last7Days: 'Zadnjih 7 dni',
      last30Days: 'Zadnjih 30 dni',
      last90Days: 'Zadnjih 90 dni',
      lastMonth: 'Pretekli mesec',
      lastYear: 'Preteklo leto',
      weekToDate: 'Tekoči teden',
      monthToDate: 'Tekoči mesec',
      quarterToDate: 'Tekoče četrtletje',
      yearToDate: 'Tekoče leto',
      allTime: 'Celoten čas'
    }
  },
  abbreviation: {
    minutes: 'min',
    minutesWithValue: '{{minutes}} min'
  },
  placeholder: {
    day: 'dd',
    month: 'mm',
    year: 'yyyy'
  }
};
