import _ from 'lodash';
import { createSelector } from 'reselect';

import { selectProps } from '../../utils/redux/selectorHelpers';

export const selectLocationStore = (state) => state.location;

export const selectCurrentLocation = createSelector(
  [selectLocationStore],
  (location) => _.getNonEmpty(location, 'selectedLocation', {})
);

export const selectLocationList = createSelector(
  [selectLocationStore],
  (location) => _.getNonEmpty(location, 'list', [])
);

export const selectCurrentLocationId = createSelector(
  [selectCurrentLocation],
  (selectedLocation) => _.getNonEmpty(selectedLocation, 'id', null)
);

export const selectLocationsWithUserAccess = createSelector(
  [selectLocationStore],
  (location) => {
    const locations = _.get(location, 'locationsWithUserAccess', []);

    return _.filter(locations, { hasAccess: true });
  }
);

export const selectMappedLocationsForCheckboxes = createSelector(
  [selectLocationsWithUserAccess],
  (locationsWithuserAccess) => {
    const mappedLocations = _.map(locationsWithuserAccess, (location) => [
      location.id,
      true
    ]);

    return _.fromPairs(mappedLocations);
  }
);

export const selectLocationById = createSelector(
  [selectLocationsWithUserAccess, selectProps],
  (locations, locationId) => _.find(locations, { id: locationId })
);

export const selectLocationNameById = createSelector(
  [selectLocationsWithUserAccess, selectProps],
  (locations, locationId) => {
    const location = _.find(locations, { id: locationId });
    const locationName = _.getNonEmpty(location, 'name', '/');

    return locationName;
  }
);
