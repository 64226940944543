export default {
  id: 'attachedDocuments/EXTERNAL_DOCUMENTS/v1',
  formSchema: {
    title: 'encounter:widget.attachedDocuments.documents',
    type: 'object',
    required: [],
    properties: {
      id001: { type: 'string', title: '' },
      id002: {
        type: 'array',
        title: 'files',
        items: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            meta: {
              type: 'object',
              properties: {
                encounterID: { type: 'string' },
                sourceWidgetType: { type: 'string' }
              }
            },
            contentType: { type: 'string' },
            localStatus: { type: 'string' },
            name: { type: 'string' },
            patientID: { type: 'string' },
            size: { type: 'number' },
            sizeReadable: { type: 'string' },
            updatedAt: { type: 'string' },
            updatedBy: { type: 'string' },
            url: { type: 'string' },
            versionID: { type: 'string' },
            versionedUrl: { type: 'string' },
            createdBy: { type: 'string' },
            createdAt: { type: 'string' }
          }
        }
      }
    }
  },
  uiSchema: {
    id001: { 'ui:widget': 'customCKEditorWidget' },
    id002: { 'ui:field': 'customFilesField' },
    'x-customClass': 'problem-description-widget'
  }
};
