export default {
  confirmEntityRemoval: {
    title: 'Odstranite {{ entityName }}',
    message: 'Želite odstraniti {{ entityName }}: <0>{{ itemName }}</0>?'
  },
  confirmItemRemoval: {
    message: 'Želite odstraniti: <0>{{ itemName }}</0>?'
  },
  confirmEventUpdate: {
    message:
      'Ste prepričani, da želite spremeniti čas termina za: <0>{{ eventTitle }}</0>?'
  },
  confirmEventQueueUpdate: {
    message:
      'Ste prepričani, da želite dodati termin za: <0>{{ eventTitle }}</0>?',
    title: 'Dodajte pacienta iz čakalne vrste'
  },
  confirmDiscardUnsaved: {
    title: 'Pozor: Neshranjene spremembe',
    message:
      'Imate neshranjene spremembe. Lahko zavržete spremembe in nadaljujete ali greste nazaj.',
    discardButton: 'Zavrzite spremembe in nadaljujte'
  },
  supportModal: {
    title: 'Podpora',
    message: 'Za pomoč se obrnite na:'
  }
};
