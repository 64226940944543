import PropTypes from 'prop-types';

import {
  LINK_CELL_ROUTE_POPULATOR,
  LINK_CELL_TEMPLATE_POPULATOR
} from '../../utils/constants/tableConstants';

export const BaseCellConfigShape = {
  className: PropTypes.string,
  emptyText: PropTypes.string
};

export const BasicCellShape = {
  name: PropTypes.string,
  cellComponent: PropTypes.string,
  config: PropTypes.shape(BaseCellConfigShape)
};

export const FirstLastNameCellConfigShape = {
  ...BaseCellConfigShape,
  path: PropTypes.string,
  displayBadge: PropTypes.bool
};

export const DurationCellConfigShape = {
  ...BaseCellConfigShape,
  negativeDurationText: PropTypes.string
};

export const DateCellConfigShape = {
  ...BaseCellConfigShape,
  format: PropTypes.oneOf([
    'shortDate',
    'longDate',
    'longDateWithTime',
    'time',
    'shortDateWithTime'
  ])
};

export const EnumCellConfigShape = {
  default: PropTypes.shape({
    id: PropTypes.isRequired,
    name: PropTypes.string,
    icon: PropTypes.shape({
      colorClassname: PropTypes.string,
      name: PropTypes.string
    })
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.isRequired,
      name: PropTypes.string,
      icon: PropTypes.shape({
        colorClassname: PropTypes.string,
        name: PropTypes.string
      })
    })
  ),
  iconOnly: PropTypes.bool
};

export const MultipleCellConfigShape = {
  cells: PropTypes.arrayOf(PropTypes.shape(BasicCellShape))
};

export const LinkCellConfigShape = {
  path: PropTypes.string,
  cell: PropTypes.shape(BasicCellShape),
  paramMap: PropTypes.shape(),
  populator: PropTypes.oneOf([
    LINK_CELL_ROUTE_POPULATOR,
    LINK_CELL_TEMPLATE_POPULATOR
  ])
};

export const MonetaryCellConfigShape = {
  ...BaseCellConfigShape,
  currencyIdFieldName: PropTypes.string,
  showCurrencyIfEmpty: PropTypes.bool
};

export const NumericCellConfigShape = {
  ...BaseCellConfigShape,
  mantissa: PropTypes.number,
  suffix: PropTypes.string,
  showSuffixIfEmpty: PropTypes.bool,
  trimMantissa: PropTypes.bool
};

export const UserCellConfigShape = {
  ...BaseCellConfigShape,
  pathToUserIdField: PropTypes.string
};

export const PatientCellConfigShape = {
  ...FirstLastNameCellConfigShape,
  suffixPath: PropTypes.string,
  lastNameFirst: PropTypes.bool
};

export const TranslateCellConfigShape = {
  translatePath: PropTypes.string
};

export const ChildComponentCellShape = {
  component: PropTypes.shape({
    cellComponentName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    config: PropTypes.shape()
  })
};

export const PatientPopupCellConfigShape = {
  ...ChildComponentCellShape,
  targetIdPath: PropTypes.string
};

export const DispatchCellConfigShape = {
  actionType: PropTypes.string.isRequired,
  payload: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape()
  ]),
  ...ChildComponentCellShape
};

export const BillTypeCellConfigShape = {
  type: PropTypes.string,
  showText: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string
};

export const ConditionCellConfigShape = {
  condition: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
  }),
  truthy: PropTypes.shape({
    cellComponentName: PropTypes.string,
    name: PropTypes.string,
    config: PropTypes.shape()
  }),
  falsy: PropTypes.shape({
    cellComponentName: PropTypes.string,
    name: PropTypes.string,
    config: PropTypes.shape()
  })
};

export const IconCellConfigShape = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string
};
