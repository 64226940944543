import produce from 'immer';
import _ from 'lodash';

import { mapFormDetail } from '../../utils/mappers/document-mappers';

import { POLL_QUESTIONNAIRES } from '../core/cache/cacheTypes';
import {
  CLEAR_QUESTIONNAIRE_DETAIL,
  CREATE_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILURE,
  GET_QUESTIONNAIRE_IN_PROGRESS,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_LIST_FAILURE,
  GET_QUESTIONNAIRE_LIST_IN_PROGRESS,
  GET_QUESTIONNAIRE_LIST_SUCCESS,
  UPDATE_QUESTIONNAIRE_SUCCESS
} from './questionnaireTypes';

import { LOGOUT } from '../auth/authReducer';

const initialState = {
  detailModalOpened: false,
  list: [],
  detail: null,
  entities: {}
};

const normalizeData = (draft, payload) => {
  /**
   * This is a bit messy now since we need to still support the old way,
   * when we manage to switch to complete data normalization the flow will be cleaner.
   * Maybe we could create a middleware for data normalization.
   */
  const questionnaireList = _.getNonEmpty(payload, 'data', []);
  const questionnaireData = _.getNonEmpty(
    payload,
    'response.data',
    questionnaireList
  );

  if (_.isArray(questionnaireData)) {
    draft.entities = _.nestedAssign(
      draft.entities,
      _.keyBy(questionnaireData, 'id')
    );
  } else {
    const questionnaireId = _.getNonEmpty(questionnaireData, 'id', null);

    if (!_.isEmptySafe(questionnaireId)) {
      /**
       * TODO: IMMER Figure out why nested assign adds new fields.
       * @type {Document}
       */
      draft.entities[questionnaireId] = mapFormDetail(questionnaireData);
    }
  }
};

// eslint-disable-next-line default-param-last
const questionnaireReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, ...payload } = action;

    switch (type) {
      case GET_QUESTIONNAIRE_LIST_IN_PROGRESS:
        draft.loadingList = true;
        break;
      case GET_QUESTIONNAIRE_LIST_SUCCESS:
        const questionnaires = _.get(payload, 'response.data', []);

        draft.loadingList = false;
        draft.list = questionnaires;
        break;
      case GET_QUESTIONNAIRE_LIST_FAILURE:
        draft.loadingList = false;
        break;
      case GET_QUESTIONNAIRE_IN_PROGRESS:
        draft.loadingItem = true;
        break;
      case GET_QUESTIONNAIRE_SUCCESS:
        const questionnaire = _.getNonEmpty(payload, 'response.data', {});

        draft.loadingItem = false;
        draft.detail = mapFormDetail(questionnaire);
        normalizeData(draft, payload);
        break;
      case CREATE_QUESTIONNAIRE_SUCCESS:
      case UPDATE_QUESTIONNAIRE_SUCCESS:
      case POLL_QUESTIONNAIRES:
        normalizeData(draft, payload);

        break;
      case GET_QUESTIONNAIRE_FAILURE:
        draft.loadingItem = false;
        break;
      case CLEAR_QUESTIONNAIRE_DETAIL:
        draft.detail = null;
        break;
      case LOGOUT:
        return initialState;
      default:
    }
  });

export default questionnaireReducer;
