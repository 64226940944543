import _ from 'lodash';

import { FORM_SUBMISSION_REQUEST_TYPES } from '../../constants/formConstants';

import { getNewFieldId } from '../../../redux/form/template/formTemplateActions';

import { getJsonSchemaFormData } from '../../form';
import { guid } from '../../gen';
import { isNotEmptyFilter } from '../common-mappers';

export const isValidDocumentObject = (obj) => !_.isEmpty(obj.id);

export const mapPatientFileData = (file) => ({
  id: _.get(file, 'id'),
  name: _.get(file, 'meta.fileName', '/'),
  type: _.get(file, 'contentType'),
  url: _.get(file, 'url'),
  description: _.get(file, 'meta.description', ''),
  patientId: _.get(file, 'patientID'),
  createdAt: _.get(file, 'createdAt'),
  createdBy: _.get(file, 'createdBy'),
  firstName: _.get(file, 'patient.firstName'),
  lastName: _.get(file, 'patient.lastName'),
  size: _.get(file, 'size')
});

export const getAddFormsToDataMapper = (forms, patientId) => (
  data,
  formIdFieldName = 'formID',
  formVersionFieldName = 'formVersion',
  idFieldName = undefined
) => {
  const id = _.getNonEmpty(data, idFieldName);

  const formId = _.getNonEmpty(data, formIdFieldName);
  const version = _.getNonEmpty(data, formVersionFieldName);
  const form = _.find(forms, { id: formId });

  return {
    id,
    patientId,
    ...data,
    form: {
      ...form,
      version
    }
  };
};

export const getAllPatientsFiles = (files, cache) => {
  const documents = _.get(cache, 'document.list', []);
  const questionnaires = _.get(cache, 'questionnaire.list', []);
  const documentAndSubmissionMapper = mapAllFiles(documents, questionnaires);

  const submitedFiles = _.map(files, documentAndSubmissionMapper)
    .filter(isNotEmptyFilter)
    .filter(isValidDocumentObject);

  const loading = _.get(files, 'loadingList', true);

  return {
    loading,
    files: _.map(submitedFiles, (file) => ({ ...file, tableKey: guid() }))
  };
};

export const mapAllFiles = (documents, questionnaires) => (object) => {
  const CONTENT_TYPE_JSON = 'application/json';

  if (object.contentType === CONTENT_TYPE_JSON) {
    return getAddFormsToDataMapper([...documents, ...questionnaires])(object);
  }

  return mapPatientFileData(object);
};

export const mapDocumentDetailFromResponse = (item, isDocument = true) => {
  if (isDocument) {
    return {
      id: item.documentID,
      version: item.documentVersion,
      submissionId: item.submissionID,
      isDocument: true
    };
  }

  return {
    id: item.questionnaireID,
    version: item.questionnaireVersion,
    submissionId: item.submissionID,
    isDocument: false
  };
};

export const duplicateQuestionnaireField = (index, fields, insert) => {
  const fieldToDuplicate = fields[index];

  if (_.isEmpty(fieldToDuplicate)) {
    return;
  }

  const remappedField = _.deepMapKeys(fieldToDuplicate, (value, key) => {
    if (_.includes(['id', 'groupId'], key)) {
      return getNewFieldId();
    }

    return value;
  });

  insert(index + 1, remappedField);

  return remappedField.id;
};

export const getFormPayload = (formData) => {
  const formPayload = _.cloneDeep(formData) || {};
  const formBuilderSchema = _.get(formData, 'formBuilderSchema', {});
  const { schema: jsonSchema } = getJsonSchemaFormData(formBuilderSchema);

  formPayload.name = _.get(formBuilderSchema, 'title', '/');
  formPayload.formBuilderSchema = JSON.stringify({
    ...formBuilderSchema,
    fieldCounter: _.get(formBuilderSchema, 'fieldCounter', 2)
  });
  formPayload.jsonSchema = JSON.stringify(jsonSchema);

  return formPayload;
};

export const getFormFromDto = (formDto, fallbackValues = {}) => {
  const id = _.getNonEmpty(formDto, 'id', fallbackValues.id);
  const name = _.getNonEmpty(formDto, 'name', fallbackValues.name);
  const version = _.getNonEmpty(formDto, 'version', fallbackValues.version);
  const clinical = _.getNonEmpty(formDto, 'clinical', fallbackValues.clinical);
  const createdAt = _.getNonEmpty(
    formDto,
    'createdAt',
    fallbackValues.createdAt
  );

  let formBuilderSchema = _.getNonEmpty(
    formDto,
    'formBuilderSchema',
    fallbackValues.formBuilderSchema
  );

  if (_.isString(formBuilderSchema)) {
    formBuilderSchema = JSON.parse(formBuilderSchema);
  }

  return { id, name, version, clinical, formBuilderSchema, createdAt };
};

export const getFormRequestPayload = (formId, formVersion) => ({
  forms: [{ formId, formVersion }],
  type: FORM_SUBMISSION_REQUEST_TYPES.NEXT_CHECK_IN
});
