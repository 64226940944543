import { createActionTypeArray } from '../common/actionTypes';

export const ADD_DOCUMENT = 'documents/ADD_DOCUMENT';

export const UPDATE_DOCUMENT = 'documents/UPDATE_DOCUMENT';
export const GET_DOCUMENT = 'documents/GET_DOCUMENT';
export const DELETE_DOCUMENT = 'documents/DELETE_DOCUMENT';
export const CLEAR_DOCUMENT_DETAIL = 'documents/CLEAR_DOCUMENT_DETAIL';

export const CREATE_PATIENT_DOCUMENT = 'documents/CREATE_PATIENT_DOCUMENT';
export const UPDATE_PATIENT_DOCUMENT = 'documents/UPDATE_PATIENT_DOCUMENT';
export const DELETE_PATIENT_DOCUMENT = 'documents/DELETE_PATIENT_DOCUMENT';

export const GET_DOCUMENTS = 'documents/GET_DOCUMENTS';
export const [
  GET_DOCUMENTS_IN_PROGRESS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE
] = createActionTypeArray(GET_DOCUMENTS);

export const [
  ADD_DOCUMENT_IN_PROGRESS,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAILURE
] = createActionTypeArray(ADD_DOCUMENT);

export const [
  UPDATE_DOCUMENT_IN_PROGRESS,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE
] = createActionTypeArray(UPDATE_DOCUMENT);

export const [
  GET_DOCUMENT_IN_PROGRESS,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE
] = createActionTypeArray(GET_DOCUMENT);

export const [
  DELETE_DOCUMENT_IN_PROGRESS,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE
] = createActionTypeArray(DELETE_DOCUMENT);

export const [
  CREATE_PATIENT_DOCUMENT_IN_PROGRESS,
  CREATE_PATIENT_DOCUMENT_SUCCESS,
  CREATE_PATIENT_DOCUMENT_FAILURE
] = createActionTypeArray(CREATE_PATIENT_DOCUMENT);

export const [
  UPDATE_PATIENT_DOCUMENT_IN_PROGRESS,
  UPDATE_PATIENT_DOCUMENT_SUCCESS,
  UPDATE_PATIENT_DOCUMENT_FAILURE
] = createActionTypeArray(UPDATE_PATIENT_DOCUMENT);

export const [
  DELETE_PATIENT_DOCUMENT_IN_PROGRESS,
  DELETE_PATIENT_DOCUMENT_SUCCESS,
  DELETE_PATIENT_DOCUMENT_FAILURE
] = createActionTypeArray(DELETE_PATIENT_DOCUMENT);
