import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { FILTER_ALL_ID } from '../../utils/constants/dropdownConstants';
import {
  ORDER_ASCENDING,
  ORDER_DESCENDING
} from '../../utils/constants/tableConstants';
import { isValidDate } from '../../utils/date';
import { mapEventsForCalendar } from '../../utils/mappers/calendar-mappers';
import { patientRemoveNullEmailAndPhoneNumber } from '../../utils/mappers/patient-mappers';
import { selectProps } from '../../utils/redux/selectorHelpers';

import { initialState } from './patientReducer';

import {
  selectCachedAndDeletedCategoriesList,
  selectCachedAndDeletedDrugList
} from '../core/cache/cacheSelectors';
import {
  selectCurrentLocationId,
  selectLocationsWithUserAccess
} from '../location/locationSelectors';

export const PATIENT_LIST_FILTER_SEARCH = 'searchQuery';
export const PATIENT_LIST_FILTER_LAST_CHECK_IN_FROM = 'lastCheckInAtFrom';
export const PATIENT_LIST_FILTER_LAST_CHECK_IN_TO = 'lastCheckInAtTo';
export const PATIENT_LIST_FILTER_DOCTOR = 'doctorID';
export const PATIENT_LIST_FILTER_DRUG = 'therapies.therapy_drug';
export const PATIENT_LIST_FILTER_THERAPY_STATUS = 'therapies.therapy_status';
export const PATIENT_LIST_FILTER_TAG_IDS = 'tagIDs';
export const PATIENT_LIST_ORDER = 'order';
export const PATIENT_LIST_SORT_BY = 'sortBy';

export const selectPatientStore = (state) => state.patient;

// LIST SELECTORS
export const selectPatientList = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'list', [])
);

export const selectIsPatientListLoading = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'loadingList', true)
);

export const selectPatientListFilter = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'filter', {})
);

export const selectIsPatientListFilterInitial = createSelector(
  [selectPatientListFilter],
  (currentFilter) => {
    const initialFilter = _.get(initialState, 'filter', {});
    const clearedInitialFilter = _.pickBy(initialFilter, _.identity);
    const clearedCurrentFilter = _.pickBy(currentFilter, _.identity);

    return _.isEqual(clearedInitialFilter, clearedCurrentFilter);
  }
);

export const selectPatientListPagination = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'pagination', {})
);

export const selectPatientCount = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'allPatientsCount', 0)
);

export const selectPatientListFilterSearch = createSelector(
  [selectPatientListFilter],
  (patientFilter) =>
    _.getNonEmpty(patientFilter, PATIENT_LIST_FILTER_SEARCH, '')
);

export const selectPatientListFilterLastCheckInFrom = createSelector(
  [selectPatientListFilter],
  (patientFilter) =>
    _.getNonEmpty(
      patientFilter,
      PATIENT_LIST_FILTER_LAST_CHECK_IN_FROM,
      FILTER_ALL_ID
    )
);

export const selectPatientListFilterLastCheckInTo = createSelector(
  [selectPatientListFilter],
  (patientFilter) =>
    _.getNonEmpty(
      patientFilter,
      PATIENT_LIST_FILTER_LAST_CHECK_IN_TO,
      FILTER_ALL_ID
    )
);

export const selectPatientListFilterDoctor = createSelector(
  [selectPatientListFilter],
  (patientFilter) =>
    _.getNonEmpty(patientFilter, PATIENT_LIST_FILTER_DOCTOR, FILTER_ALL_ID)
);

export const selectPatientListFilterDrug = createSelector(
  [selectPatientListFilter, selectCachedAndDeletedDrugList],
  (patientFilter, drugList) => {
    const drugName = _.getNonEmpty(patientFilter, PATIENT_LIST_FILTER_DRUG);

    const foundDrug = _.findDefault(
      drugList,
      { name: drugName },
      { id: FILTER_ALL_ID }
    );

    return foundDrug.id;
  }
);

export const selectPatientListFilterTherapyStatus = createSelector(
  [selectPatientListFilter],
  (patientFilter) =>
    _.getNonEmpty(
      patientFilter,
      PATIENT_LIST_FILTER_THERAPY_STATUS,
      FILTER_ALL_ID
    )
);

export const selectPatientListFilterTagValue = createSelector(
  [selectPatientListFilter],
  (patientFilter) => {
    const tagFilter = _.getNonEmpty(
      patientFilter,
      PATIENT_LIST_FILTER_TAG_IDS,
      [FILTER_ALL_ID]
    );

    return _.isArray(tagFilter) ? tagFilter.join(',') : tagFilter;
  }
);

export const selectPatientListSortConfig = createSelector(
  [selectPatientListFilter],
  (patientFilter) => ({
    order: _.get(patientFilter, PATIENT_LIST_ORDER, ORDER_ASCENDING),
    sortBy: _.get(patientFilter, PATIENT_LIST_SORT_BY, 'firstNameLastName')
  })
);

// DETAIL SELECTORS
export const selectPatientDetail = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'detail', {})
);

export const selectPatientDetailId = createSelector(
  [selectPatientDetail],
  (patientDetail) => _.getNonEmpty(patientDetail, 'id')
);

export const selectPatientDetailLoading = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'loadingPatient', false)
);

export const selectPatientDetailWithNullEmailAndPhoneNumber = createSelector(
  [selectPatientDetail],
  (patientDetail) => patientRemoveNullEmailAndPhoneNumber(patientDetail)
);

export const selectConditionalPatientData = createSelector(
  [
    selectPatientDetailWithNullEmailAndPhoneNumber,
    selectCurrentLocationId,
    selectProps
  ],
  (patientData, currentLocationId, ownProps) => {
    const isCreatingNewPatient = _.get(ownProps, 'isCreatingNewPatient', false);

    return isCreatingNewPatient
      ? { doctor: { locationIDs: [currentLocationId] } }
      : patientData;
  }
);

export const selectIsPatientDeleted = createSelector(
  [selectPatientDetail],
  (patientDetail) => {
    const patientStatus = _.getNonEmpty(patientDetail, 'status', []);

    return _.includes(patientStatus, 'deleted');
  }
);

export const selectPatientDefaultDoctorId = createSelector(
  [selectPatientDetail],
  (patientDetail) => _.get(patientDetail, 'doctor.doctorID')
);

export const selectPatientNotifications = createSelector(
  [selectPatientDetail],
  (patientDetail) => _.get(patientDetail, 'notifications')
);

export const selectHasPatientSmsEnabled = createSelector(
  [selectPatientNotifications],
  (patientNotifications) => _.get(patientNotifications, 'reminders.sms', false)
);

// BILLS SELECTORS
export const selectPatientBills = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'bills', {})
);

export const selectPatientBillList = createSelector(
  [selectPatientBills],
  (patientBillsStore) => _.get(patientBillsStore, 'list', [])
);

export const selectPatientLoadingBills = createSelector(
  [selectPatientBills],
  (patientBillsStore) => _.get(patientBillsStore, 'loadingList', [])
);

export const selectPatientBillPagination = createSelector(
  [selectPatientBills],
  (patientBillsStore) => _.get(patientBillsStore, 'pagination', {})
);

export const selectPatientBillSortConfig = createSelector(
  [selectPatientBills],
  (patientBillsStore) => _.get(patientBillsStore, 'sorting', {})
);

// UNBILLED ITEMS SELECTORS
export const selectPatientUnbilledItems = createSelector(
  [selectPatientStore],
  (patientBillsStore) => _.get(patientBillsStore, 'unbilledItems', {})
);

export const selectPatientUnbilledItemsList = createSelector(
  [selectPatientUnbilledItems],
  (patientBillsStore) => _.get(patientBillsStore, 'list', [])
);

// BILLED ITEMS SELECTORS
export const selectPatientBilledItemStore = createSelector(
  [selectPatientStore],
  (patientStore) => _.getNonEmpty(patientStore, 'billedItems', {})
);

export const selectPatientBilledItemListLoading = createSelector(
  [selectPatientBilledItemStore],
  (billedItemStore) => _.getNonEmpty(billedItemStore, 'loadingList', false)
);

export const selectPatientBilledItemList = createSelector(
  [selectPatientBilledItemStore],
  (billedItemStore) => _.getNonEmpty(billedItemStore, 'list', [])
);

export const selectPatientBilledItemFilter = createSelector(
  [selectPatientBilledItemStore],
  (billedItemStore) => _.getNonEmpty(billedItemStore, 'filter', {})
);

export const selectPatientBilledItemListSort = createSelector(
  [selectPatientBilledItemFilter],
  (filter) => ({
    sortBy: _.get(filter, 'sortBy', 'name'),
    order: _.get(filter, 'order', ORDER_ASCENDING)
  })
);

export const selectPatientSortedBilledItemList = createSelector(
  [selectPatientBilledItemList, selectPatientBilledItemListSort],
  (list, { sortBy, order }) =>
    _.orderBy(
      list,
      (item) => {
        const itemField = _.getNonEmpty(item, sortBy, '');

        if (isValidDate(itemField)) {
          return moment(itemField).valueOf();
        }

        return itemField;
      },
      order
    )
);

export const selectPatientBilledItemPagination = createSelector(
  [selectPatientBilledItemStore, selectPatientSortedBilledItemList],
  (billedItemStore, list) => {
    const pagination = _.getNonEmpty(billedItemStore, 'pagination', {});
    const resultCount = list.length;
    const pageCount = _.ceil(resultCount / pagination.limit);

    return { ...pagination, resultCount, pageCount };
  }
);

export const selectPatientMappedBilledItemList = createSelector(
  [selectPatientSortedBilledItemList, selectPatientBilledItemPagination],
  (list, pagination) => {
    const { limit, page } = pagination;

    const paginatedList = _.slice(list, limit * page - limit, limit * page);

    return paginatedList;
  }
);

// EVENTS SELECTORS
export const selectPatientEventsList = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'events.list', [])
);

export const selectPatientQueueEventsList = createSelector(
  [selectPatientStore],
  (patientStore) => _.getNonEmpty(patientStore, 'events.queueList', [])
);

export const selectPatientProfileAppointmentEvents = createSelector(
  [selectPatientEventsList, selectCachedAndDeletedCategoriesList],
  (patientEventsList, categoriesList) => {
    const eventMapper = mapEventsForCalendar(categoriesList);
    const events = patientEventsList.map(eventMapper);

    /**
     * waiting list events don't have from and to dates
     */
    const filteredEvents = _.filter(events, (event) => !_.isEmpty(event.from));

    return _.orderBy(filteredEvents, 'from', ORDER_DESCENDING);
  }
);

export const selectPatientProfileQueueEventsList = createSelector(
  [selectPatientQueueEventsList, selectCachedAndDeletedCategoriesList],
  (patientQueueEventsList, categoriesList) => {
    const eventMapper = mapEventsForCalendar(categoriesList);
    const events = patientQueueEventsList.map(eventMapper);

    return _.orderBy(events, 'createdAt', ORDER_DESCENDING);
  }
);

export const selectPatientProfileUpcomingEventsList = createSelector(
  [selectPatientProfileAppointmentEvents],
  (patientQueueEventsList) =>
    _.filter(patientQueueEventsList, (event) => !moment(event.start).isBefore())
);

export const selectPatientProfilePastEventsList = createSelector(
  [selectPatientProfileAppointmentEvents],
  (patientQueueEventsList) =>
    _.filter(patientQueueEventsList, (event) => moment(event.start).isBefore())
);

export const selectPatientProfileLocationQueueEventsList = createSelector(
  [selectPatientProfileQueueEventsList, selectCurrentLocationId],
  (patientQueueEventsList, currentLocationId) =>
    _.filter(
      patientQueueEventsList,
      (event) => event.locationID === currentLocationId
    )
);

export const selectPatientQueueEventsLocations = createSelector(
  [
    selectPatientProfileQueueEventsList,
    selectLocationsWithUserAccess,
    selectCurrentLocationId
  ],
  (patientQueueEventsList, locations, currentLocation) => {
    const uniqueLocations = _.uniqBy(patientQueueEventsList, 'locationID');
    const uniqueLocationIds = _.map(uniqueLocations, 'locationID');
    const filterCurrentLocation = _.filter(
      uniqueLocationIds,
      (locationId) => locationId !== currentLocation
    );

    const locationNames = _.map(filterCurrentLocation, (locationId) => {
      const location = _.find(locations, { id: locationId });
      const locationName = _.getNonEmpty(location, 'name', '/');

      return locationName;
    });

    return locationNames.join(', ');
  }
);

// PATIENT OVERLAY DETAIL
export const selectPatientOverlayDetail = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'overlayDetail.data', {})
);

export const selectPatientOverlayDetailLoading = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'overlayDetail.loadingDetail', false)
);

// PATIENT STATS
export const selectPatientStats = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'stats.data', {})
);

// PATIENT ADD FORM SIDEBAR
export const selectIsPatientSidepanelFormOpen = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'isPatientSidepanelFormOpen')
);

// PATIENT MERGE
export const selectMergePatientDryRunData = createSelector(
  [selectPatientStore],
  (patientStore) => _.get(patientStore, 'merge.patientData')
);

// DIAGNOSIS
export const selectPatientDiagnosis = createSelector(
  [selectPatientDetail],
  (patient) => _.getNonEmpty(patient, 'diagnosis', null)
);

export const selectPatientMainDiagnosis = createSelector(
  [selectPatientDiagnosis],
  (diagnosis) => _.getNonEmpty(diagnosis, 'mainDiagnosis', null)
);

export const selectPatientClinicalOutlook = createSelector(
  [selectPatientDiagnosis],
  (diagnosis) => _.getNonEmpty(diagnosis, 'clinicalOutlook', null)
);

export const selectIsPatientMainDiagnosisSpondyloarthritis = createSelector(
  [selectPatientMainDiagnosis],
  (mainDiagnosis) => mainDiagnosis === 'spondyloarthritis'
);

export const selectPatientTagsList = createSelector(
  [selectPatientDetail],
  (patientDetail) => _.getNonEmpty(patientDetail, 'tags', [])
);

export const selectIsPatientExportLoading = createSelector(
  [selectPatientStore],
  (patientStore) => _.getNonEmpty(patientStore, 'export.loadingExport', false)
);

export const selectCachedPatient = createSelector(
  [selectPatientStore],
  (patient) => _.getNonEmpty(patient, 'cachedPatient', {})
);

export const selectCachedPatientList = createSelector(
  [selectCachedPatient],
  (cachedPatient) => _.getNonEmpty(cachedPatient, 'patientList', {})
);

export const selectHiddenPatientMergeTileList = createSelector(
  [selectCachedPatient],
  (cachedPatient) => _.getNonEmpty(cachedPatient, 'hiddenMergeTiles', [])
);
