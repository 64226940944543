import React from 'react';
import { Prompt } from 'react-router-dom';

import PropTypes from 'prop-types';

export const MoshiRoutedPromptComponent = ({ when, ...props }) => {
  const promptPropsDto = JSON.stringify(props);

  return <Prompt when={when} message={promptPropsDto} />;
};

/* eslint-disable react/require-default-props */
MoshiRoutedPromptComponent.propTypes = {
  when: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  confirmActionType: PropTypes.string,
  submitButtonText: PropTypes.string,
  submitButtonColor: PropTypes.string,
  submitButtonInvisible: PropTypes.bool,
  cancelButtonText: PropTypes.string
};
/* eslint-enable react/require-default-props */

MoshiRoutedPromptComponent.defaultProps = {
  confirmActionType: undefined,
  submitButtonText: undefined,
  submitButtonColor: undefined,
  cancelButtonText: undefined,
  submitButtonInvisible: false
};

export default MoshiRoutedPromptComponent;
