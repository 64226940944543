import axios from 'axios';
import _ from 'lodash';
import { select, all, takeEvery, call } from 'redux-saga/effects';

import { getEncounterWidgetsUrl } from '../../../utils/api/apiUrlUtils';
import { apiCall } from '../../../utils/redux/sagaUtils';

import {
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_SUCCESS,
  GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST
} from './rheumatologyTypes';

import { selectIsPatientMainDiagnosisSpondyloarthritis } from '../../patient/patientSelectors';

export const PATIENT_TREATMENT_DEFAULT_WIDGET_LIST = [
  'labResults/CRP_TEST/v1',
  'labResults/SR_TEST/v1',
  'homunculus/TJC_SJC/v1',
  'rheumatology/HAQ_100/v1',
  'rheumatology/EGA/v1',
  'rheumatology/PGA/v1',
  'rheumatology/CDAI/v1',
  'rheumatology/SDAI/v1',
  'rheumatology/DAS28SR/v1'
];

export const PATIENT_TREATMENT_SPONDILOARTRITIS_WIDGET_LIST = [
  'rheumatology/DAPSA28_INDEX/v1',
  'rheumatology/DAPSA_INDEX/v1',
  'rheumatology/BASDAI-INDEX/v1',
  'rheumatology/BASFI-INDEX/v1',
  'rheumatology/ASDAS-CRP/v1',
  'homunculus/LEEDS_ENTHESITIS/v1',
  'homunculus/TENDER_DACTYLITIS_COUNT/v1',
  'rheumatology/UVEITIS_INDEX/v1'
];

export function* fetchPatientTreatmentEncounterWidgetsSaga(
  encounter,
  isSpondiloartritis = false
) {
  const state = yield select();
  const encounterId = encounter.id;

  const encounterWidgetsUrl = getEncounterWidgetsUrl(() => state, encounterId);

  let widgetToQuery = PATIENT_TREATMENT_DEFAULT_WIDGET_LIST;

  if (isSpondiloartritis) {
    widgetToQuery = [
      ...widgetToQuery,
      ...PATIENT_TREATMENT_SPONDILOARTRITIS_WIDGET_LIST
    ];
  }

  yield apiCall(
    GET_PATIENT_TREATMENT_PAST_ENCOUNTER_WIDGET_LIST,
    axios.get,
    [encounterWidgetsUrl, { params: { types: widgetToQuery.join(',') } }],
    { encounterId }
  );
}

export function* fetchPatientTreatmentAllPastEncounterWidgetsSaga(action) {
  const isSpondiloartritis = yield select(
    selectIsPatientMainDiagnosisSpondyloarthritis
  );
  const pastEncounters = _.getNonEmpty(action, 'response.data', []);

  yield all(
    pastEncounters.map((pastEncounter) =>
      call(
        fetchPatientTreatmentEncounterWidgetsSaga,
        pastEncounter,
        isSpondiloartritis
      )
    )
  );
}

export function* watchPatientTreatmentPastEncountersSaga() {
  yield takeEvery(
    GET_PATIENT_TREATMENT_PAST_ENCOUNTER_LIST_SUCCESS,
    fetchPatientTreatmentAllPastEncounterWidgetsSaga
  );
}
