import _ from 'lodash';
import moment from 'moment';

import { INVOICE_STATUS_ISSUED } from '../../../../utils/billing';
import { getDateFormat } from '../../../../utils/date';

import PaymentModel from '../PaymentModel';
import BillBaseModel from './BillBaseModel';

class CreditNoteModel extends BillBaseModel {
  constructor(id, status = [INVOICE_STATUS_ISSUED]) {
    super(id);

    this.status = status;
    this.dueDate = this.date;
    this.dateService = this.date;
  }

  setCreditNotePreferredDataInput(
    billingUnitList,
    preferredBillingData,
    defaultLocale
  ) {
    const billingUnit = super.setPreferredDataInput(
      billingUnitList,
      preferredBillingData,
      defaultLocale
    );

    const defaultDueDate = _.get(billingUnit, 'paymentDetails.invoiceDueDays');

    this.texts.note = '';
    this.dueDate = moment().add(defaultDueDate, 'days').format('YYYY/MM/DD');
  }

  static fromDto(dto) {
    const assignedBillBase = BillBaseModel.fromDto(dto);
    const assignedInvoice = _.nestedAssign(
      new CreditNoteModel(null, []),
      assignedBillBase
    );

    assignedInvoice.dueDate = moment(assignedInvoice.dueDate).toISOString();
    assignedInvoice.dateService = moment(
      assignedInvoice.dateService
    ).toISOString();

    return assignedInvoice;
  }

  static getPayload(model, defaultLocale) {
    const payload = BillBaseModel.getPayload(model, defaultLocale);

    payload.dueDate = getDateFormat(model.dueDate, 'YYYY-MM-DD');
    payload.dateService = getDateFormat(model.dateService, 'YYYY-MM-DD');

    if (_.has(payload, 'payments')) {
      payload.payments = _.map(payload.payments, (payment) =>
        PaymentModel.getPayload(payment)
      );
    }

    return payload;
  }
}

export default CreditNoteModel;
